import { AxiosResponse as ARes } from 'axios';
import HTTP from 'config/axios';
import Horse from 'models/Horse';
import { IResWithPagination, TFetchedOptions } from 'resources/types/commonTypes';
import { IHorseFetchParams, IHorseRes } from 'resources/types/horsesTypes';

class HorseService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async fetch(horseId: number | string) {
    const response: ARes<IHorseRes> = await HTTP.get(`${this.apiBaseURL}/horses/${horseId}`);
    return response.data;
  }

  async fetchAll(params: IHorseFetchParams) {
    const response: ARes<IResWithPagination<IHorseRes[]>> = await HTTP.get(`${this.apiBaseURL}/horses`, {
      params,
    });
    return response.data;
  }

  async fetchOptions(keyword: string, additional: { page: number }, params: IHorseFetchParams = {}) {
    const page = additional?.page || 1;
    const { horses, pagination } = await this.fetchAll({
      per_page: 20,
      keyword,
      page,
      ...params,
    });

    const mappedOptions = horses.map(horseRes => {
      const horse = new Horse(horseRes);
      return {
        key: horse.id,
        value: horse.id,
        label: horse.getName(),
        type: 'horse',
        data: {
          image: horse.getAvatar('thumb'),
          skill: horse.getSkill(),
          fatigue: horse.fatigue,
          healthStatus: horse.getHealthStatus(),
        },
      };
    });

    return {
      options: mappedOptions,
      hasMore: pagination.pages > page,
      additional: { page: pagination.next },
    } as TFetchedOptions;
  }
}

export default new HorseService();
