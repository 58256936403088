/**
 * @module NewsProvider
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import { isEmpty } from 'lodash';

import Error404 from '@old/components/error/Error404';
import Spinner from '@old/components/common/Spinner';
import { setPagination, fetchNews } from 'store/actions';
import { getPaginationId } from 'old/utils';
import PagePagination from '@old/components/old/PagePagination';
import FlexColumn from '@old/components/common/FlexColumn';
import NewsList from '@old/components/view/list/News';
import config from '@old/config';

/**
 * @typedef   {Object} PaginationStore
 * @property  {Number} current current page number
 * @property  {String} pathname current url without host
 */
/**
 * @typedef   {Object} Pagination
 * @property  {Number} count number of items
 * @property  {Number} current current page number
 * @property  {Number} next next page number
 * @property  {Number} pages number of pages
 * @property  {Number} per_page number of items per single page
 * @property  {Number} previous previous page number
 * @property  {Function} push
 */
/**
 * Component that provides news and passes them view component
 * @param  {Object} [props.fetchParams] params for news that will be fetched
 * @param  {Boolean} props.loading specifies wether new news are being fetch
 * @param  {Boolean} props.error specifies wether an error occured when the news were being fetch
 * @param  {News[]} [props.news] fetched news
 * @param  {Pagination} [props.newsPagination] pagination fetched with news
 * @param  {PaginationStore} props.pagination pagination from the store(set after fetched news pagination)
 * @param  {String} [props.pagKey] string passed to create uniq pagination id - passed when tabs are being used
 * @param  {History} props.history
 * @param  {Function} props.fetchNews function that fetches news and saves them to strone
 * @param  {Function} props.setPagination function that sets pagination and saves it to store
 */

const NewsProvider = ({
  fetchParams,
  loading,
  error,
  news,
  newsPagination,
  pagination,
  pagKey,
  history,
  ...actions
}) => {
  const { minItemsPerPage } = config;

  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname} ${JSON.stringify(pagKey)}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    // eslint-disable-line
    if (isEmpty(pagination) || pagination.current !== undefined) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const fetch = async () => {
        await actions.fetchNews(
          {
            ...fetchParams,
            per_page: minItemsPerPage,
            page: getActivePage() || 1,
          },
          source.token
        );
      };
      fetch();
      return () => source.cancel();
    }
  }, [fetchParams, history.location.state, pagKey, pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (newsPagination) {
      const pag = { ...newsPagination };
      // When you delete last element on page, go back to previous one
      const lastPage = Math.ceil(pag.count / pag.per_page);
      if (pag.per_page * pag.current > pag.count) {
        if (lastPage && pag.current > lastPage) {
          pag.current = lastPage;
          history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
        }
        if (pag.current === 0) {
          pag.current = 1;
        }
      }
      actions.setPagination(pag, getPaginationKey());
    }
  }, [newsPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Error404 />;
  if (loading && !news.length) {
    return <Spinner noDelay />;
  }

  return (
    <div>
      <FlexColumn>
        <React.Fragment>
          <NewsList data={news} fetchParams={fetchParams} />
          <PagePagination paginationKey={getPaginationKey()} />
        </React.Fragment>
      </FlexColumn>
    </div>
  );
};

NewsProvider.defaultProps = {
  news: [],
  newsPagination: {},
  fetchParams: {},
  pagKey: '',
};

NewsProvider.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  newsPagination: PropTypes.shape({
    count: PropTypes.number,
    current: PropTypes.number,
    next: PropTypes.number,
    pages: PropTypes.number,
    per_page: PropTypes.number,
    previous: PropTypes.number,
  }),
  pagination: PropTypes.shape({
    current: PropTypes.number,
  }).isRequired,
  fetchParams: PropTypes.shape({}),
  pagKey: PropTypes.string,
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({}),
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pagination, fetchingData: { news, newsPagination, loading, error } }, { pagKey }) => {
  const paginationKey = getPaginationId(`${window.location.pathname} ${JSON.stringify(pagKey || '')}`);

  return {
    news,
    newsPagination,
    loading: loading.news,
    error: error.news,
    pagination: pagination[paginationKey] || {},
  };
};

export default withRouter(connect(mapStateToProps, { fetchNews, setPagination })(NewsProvider));
