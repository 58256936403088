import { Icons } from 'components/elements';
import { components } from 'react-select';

// MultiValue container used in select inputs
const DropdownIndicator = ({ children, ...props }: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons.ArrowDown squareSize={16} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
