/* eslint-disable max-len */
import React from 'react';

const PaymentSettings = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 22.61"
    >
      <path d="M11.38,8.08V6.49H1.62A1.61,1.61,0,0,0,0,8.08v3.2H11.72A13.9,13.9,0,0,1,11.38,8.08Z" transform="translate(0 -1.69)" />
      <path d="M25.93,14.72a14.42,14.42,0,0,1-5.22,4.48,2.52,2.52,0,0,1-2.42,0,14.59,14.59,0,0,1-5.22-4.48H0v8a1.62,1.62,0,0,0,1.62,1.6H24.38A1.62,1.62,0,0,0,26,22.71v-8ZM7.31,19.51H4.06a.8.8,0,1,1,0-1.59H7.31a.8.8,0,1,1,0,1.59Z" transform="translate(0 -1.69)" />
      <path d="M18.81,17.56a.77.77,0,0,0,.41.11.71.71,0,0,0,.4-.11c4.45-2.51,6.1-5.07,6.1-9.48V4.89a.82.82,0,0,0-.49-.74L19.54,1.76a.79.79,0,0,0-.64,0L13.21,4.15a.81.81,0,0,0-.49.74V8.08C12.72,12.48,14.37,15.05,18.81,17.56ZM16.33,8.22a.83.83,0,0,1,1.13.22l1,1.49L21,6.79a.82.82,0,0,1,1.14-.13.79.79,0,0,1,.13,1.12l-3.25,4a.83.83,0,0,1-.63.3h0a.79.79,0,0,1-.64-.36L16.1,9.33A.8.8,0,0,1,16.33,8.22Z" transform="translate(0 -1.69)" />
    </svg>
  );
};

export default PaymentSettings;
/* eslint-enable max-len */
