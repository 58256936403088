import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import { CalendarDataContext } from '@old/hooks';
import {
  CalendarItem,
  mapEventToCalendarItem,
  SpecialItem,
  getRangeLabel,
} from '@old/components/view/calendar/CalendarItem';
import HoursGrid from '@old/components/view/calendar/CalendarHoursGrid';
import config from '@old/config';
import Model from '@old/model';
import { getCalendarKey } from 'old/utils';

const { pxPer5Min, hours } = config;
const setTime = (h, date) => moment(date).hour(h).minute(0).second(0).millisecond(0);
const getNumberHour = h => h.split(':')[0];

const WeekExtended = ({ weekStart, match, refreshCounter, history, addEventButton, addProposalButton }) => {
  const events = useContext(CalendarDataContext);
  const [absences, setAbsences] = useState([]);
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    const fetchAdditional = async () => {
      if (match.path === '/instructors/:id') {
        const [shiftsData] = await Model.Members.getShifts(match.params.id, { per_page: 9999 });
        setShifts(shiftsData);
      }
      if (match.path === '/horses/:id') {
        const [absencesData] = await Model.Horses.getAbsences(match.params.id, { per_page: 9999 });
        setAbsences(absencesData);
      }
    };
    fetchAdditional();
  }, [match.path, match.params.id, refreshCounter]);

  const getCalendarItems = (dayRange, dayStart) => {
    const eventsItems = events
      .sort((itemA, itemB) => {
        return itemA.startDate.isAfter(itemB.startDate) ? 1 : -1;
      })
      .map(event => mapEventToCalendarItem(event, dayStart))
      .filter(({ range }) => range.overlaps(dayRange));
    const absencesItems = absences
      .sort((itemA, itemB) => {
        return itemA.beginning.isAfter(itemB.beginning) ? 1 : -1;
      })
      .map(item => {
        const startDate = moment(item.beginning).second(0).millisecond(0);
        const endDate = moment(item.ending).second(0).millisecond(0);

        return {
          range: dayStart.isBefore(startDate) ? moment.range(startDate, endDate) : moment.range(dayStart, endDate),
          rangeLabel: getRangeLabel(startDate, endDate),
          id: item.id,
          special: 'absence',
        };
      })
      .filter(({ range }) => range.overlaps(dayRange));
    const shiftsItems = shifts
      .sort((itemA, itemB) => {
        return itemA.beginning.isAfter(itemB.beginning) ? 1 : -1;
      })
      .map(item => {
        const startDate = item.beginning;
        const endDate = item.ending;

        return {
          range: dayStart.isBefore(startDate) ? moment.range(startDate, endDate) : moment.range(dayStart, endDate),
          rangeLabel: getRangeLabel(startDate, endDate),
          id: item.id,
          special: 'shift',
        };
      })
      .filter(({ range }) => range.overlaps(dayRange));
    return [...eventsItems, ...absencesItems, ...shiftsItems];
  };

  const getExtraItems = (beforeRange, afterRange, dayStart) => {
    const itemsBefore = [
      ...events
        .sort((itemA, itemB) => {
          return itemA.startDate.isAfter(itemB.startDate) ? 1 : -1;
        })
        .map(event => mapEventToCalendarItem(event, dayStart)),
      ...shifts
        .sort((itemA, itemB) => {
          return itemA.beginning.isAfter(itemB.beginning) ? 1 : -1;
        })
        .map(item => {
          const startDate = item.beginning;
          const endDate = item.ending;

          return {
            range: dayStart.isBefore(startDate) ? moment.range(startDate, endDate) : moment.range(dayStart, endDate),
            rangeLabel: getRangeLabel(startDate, endDate),
            id: item.id,
            special: 'shift',
          };
        }),
      ...absences
        .sort((itemA, itemB) => {
          return itemA.beginning.isAfter(itemB.beginning) ? 1 : -1;
        })
        .map(item => {
          const startDate = moment(item.beginning).second(0).millisecond(0);
          const endDate = moment(item.ending).second(0).millisecond(0);

          return {
            range: dayStart.isBefore(startDate) ? moment.range(startDate, endDate) : moment.range(dayStart, endDate),
            rangeLabel: getRangeLabel(startDate, endDate),
            id: item.id,
            special: 'absence',
          };
        }),
    ].filter(({ range }) => range.overlaps(beforeRange));
    const itemsAfter = [
      ...events
        .sort((itemA, itemB) => {
          return itemA.startDate.isAfter(itemB.startDate) ? 1 : -1;
        })
        .map(event => mapEventToCalendarItem(event, dayStart)),
      ...shifts
        .sort((itemA, itemB) => {
          return itemA.beginning.isAfter(itemB.beginning) ? 1 : -1;
        })
        .map(item => {
          const startDate = item.beginning;
          const endDate = item.ending;

          return {
            range: dayStart.isBefore(startDate) ? moment.range(startDate, endDate) : moment.range(dayStart, endDate),
            rangeLabel: getRangeLabel(startDate, endDate),
            id: item.id,
            special: 'shift',
          };
        }),
      ...absences
        .sort((itemA, itemB) => {
          return itemA.beginning.isAfter(itemB.beginning) ? 1 : -1;
        })
        .map(item => {
          const startDate = moment(item.beginning).second(0).millisecond(0);
          const endDate = moment(item.ending).second(0).millisecond(0);

          return {
            range: dayStart.isBefore(startDate) ? moment.range(startDate, endDate) : moment.range(dayStart, endDate),
            rangeLabel: getRangeLabel(startDate, endDate),
            id: item.id,
            special: 'absence',
          };
        }),
    ].filter(({ range }) => range.overlaps(afterRange));
    return [itemsBefore, itemsAfter];
  };

  const hoursList = hours.map((h, index) => {
    const hour = getNumberHour(h);
    let isExpanded = false;
    [1, 2, 3, 4, 5, 6, 7].forEach(dayIndex => {
      const dayStart = moment(weekStart).isoWeekday(dayIndex);
      const hourRange = moment.range(
        setTime(hour, dayStart),
        setTime(hours[index + 1] ? getNumberHour(hours[index + 1]) : hour + 1, dayStart).subtract(1, 'miliseconds')
      );

      const calendarItems = getCalendarItems(
        moment.range(
          setTime(getNumberHour(hours[0]), dayStart),
          setTime(parseInt(getNumberHour(hours[hours.length - 1]), 10) + 1, dayStart)
        ),
        dayStart
      );
      if (calendarItems.some(item => item.range.overlaps(hourRange))) {
        isExpanded = true;
      }
    });

    return { isExpanded };
  });

  const itemsByDay = [1, 2, 3, 4, 5, 6, 7].map(dayIndex => {
    const dayStart = moment(weekStart).isoWeekday(dayIndex);
    const dayRange = moment.range(
      setTime(getNumberHour(hours[0]), dayStart),
      setTime(parseInt(getNumberHour(hours[hours.length - 1]), 10) + 1, dayStart)
    );

    return getCalendarItems(dayRange, dayStart);
  });

  const extraItemsByDay = [1, 2, 3, 4, 5, 6, 7].map(dayIndex => {
    const dayStart = moment(weekStart).isoWeekday(dayIndex);
    const beforeRange = moment.range(dayStart, setTime(8, dayStart).subtract(1, 'milisecond'));
    const afterRange = moment.range(setTime(21, dayStart), moment(dayStart).endOf('day'));

    return getExtraItems(beforeRange, afterRange, dayStart);
  });

  const getHeightOfElement = (range, hList) => {
    let bigMinutes = 0;
    hList.forEach(({ isExpanded, range: hRange }) => {
      if (isExpanded) {
        const inter = range.intersect(hRange);
        bigMinutes += inter ? inter.valueOf() / 1000 / 60 : 0;
      }
    });
    const startHours = range.start.hours();
    const endHours = range.end.hours();
    const extraPixels = endHours - startHours; // we need to add 1px for each crossed border line
    const durationInMinutes = range.valueOf() / 1000 / 60;

    return Math.ceil(durationInMinutes / 5) * pxPer5Min + extraPixels + Math.ceil(bigMinutes / 5) * pxPer5Min * 2;
  };

  const getPositionOfElement = (range, hList) => {
    const height = getHeightOfElement(range, hList);
    const top = getHeightOfElement(moment.range(setTime(8, range.start), range.start), hList);
    return [height, top];
  };

  const { length } = hours;
  const numberOfExpandedHours = hoursList.filter(item => item.isExpanded).length;
  const hourHeight = pxPer5Min * 12;
  const maxElementHeight = length * hourHeight + numberOfExpandedHours * hourHeight * 2 + length;
  const border = '1px solid #ccc';

  return (
    <React.Fragment>
      <div className="flex flex-row" style={{ top: 0, border, borderBottom: 'none', borderRightWidth: 0 }}>
        <div style={{ flex: '1 0', borderRight: border }} className="bg-grey-light centered pt-1 pb-1" />
        <div style={{ flex: '9 0 0' }}>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', height: '100%' }}>
            {[1, 2, 3, 4, 5, 6, 7].map(dayIndex => {
              const dayStart = moment(weekStart).isoWeekday(dayIndex);
              return (
                <div key={dayIndex} className="bg-grey-light centered p-1" style={{ borderRight: border }}>
                  {dayStart.format('dd DD.MM')}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div style={{ position: 'relative', zIndex: 5 }}>
        <HoursGrid
          hoursList={hoursList}
          extraItems={extraItemsByDay}
          addEventButton={addEventButton}
          addProposalButton={addProposalButton}
          weekView
        />
        <div className="flex flex-row absolute h-full w-full" style={{ top: 0, border, borderRightWidth: 0 }}>
          <div style={{ flex: '1 0', borderRight: border }} />
          <div style={{ flex: '9 0 0' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', height: '100%' }}>
              {itemsByDay.map((items, dayIndex) => {
                return (
                  <div key={dayIndex} style={{ position: 'relative', borderRight: border }}>
                    {items.map((item, itemIndex) => {
                      const hList = hoursList.map((h, index) => {
                        const dayStart = moment(weekStart).isoWeekday(dayIndex + 1);
                        return {
                          ...h,
                          range: moment.range(
                            setTime(getNumberHour(hours[index]), dayStart),
                            setTime(hours[index + 1] ? getNumberHour(hours[index + 1]) : 24, dayStart)
                          ),
                        };
                      });

                      const [height, top] = getPositionOfElement(item.range, hList);

                      if (!height) return null;

                      if (item.special) {
                        return (
                          <SpecialItem
                            height={height}
                            top={top}
                            item={item}
                            key={item.id}
                            maxElementHeight={maxElementHeight}
                            type={item.special}
                          />
                        );
                      }

                      return (
                        <CalendarItem
                          index={itemIndex}
                          height={height}
                          top={top}
                          item={item}
                          key={item.id}
                          history={history}
                          maxElementHeight={maxElementHeight}
                          weekView
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

WeekExtended.defaultProps = {
  addEventButton: {},
  addProposalButton: {},
};

const mapStateToProps = ({ calendar, fetchingData }, { location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;

  return {
    weekStart: calendarState.weekStart,
    refreshCounter: fetchingData.refreshCounter,
  };
};

export default withRouter(connect(mapStateToProps)(WeekExtended));
