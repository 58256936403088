import { AsyncPaginate } from 'react-select-async-paginate';
import { OptionTypeBase, Styles as SelectStyles, Props as SelectProps } from 'react-select';
import theme from 'config/theme';
import MultiValueContainer from '../select/MultiValueContainer';
import MultiValueLabel from '../select/MultiValueLabel';
import MultiValueRemove from '../select/MultiValueRemove';
import DropdownIndicator from '../select/DropdownIndicator';
import ClearIndicator from '../select/ClearIndicator';
import t from 'resources/translations';
import { isMobile as isMobileDevices } from 'react-device-detect';
import { useAppSelector } from 'utils/storeUtils';

const customStyles = (isSmallScreen: boolean) =>
  ({
    input: provided => ({
      ...provided,
      '& input': {
        minHeight: 'auto',
        height: 'auto',
      },
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '0 5px 0 20px',
      '& input': {
        minHeight: 'auto',
        height: 'auto',
      },
    }),
    container: provided => ({
      ...provided,
    }),
    clearIndicator: provided => ({
      ...provided,
      padding: 0,
      color: theme.colors['teal-light'],
      cursor: 'pointer',
      margin: '0 15px',
      '&:hover': {
        color: theme.colors['teal-dark'],
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: theme.colors['teal-light'],
      cursor: 'pointer',
      margin: '0 15px',
      padding: 0,
      '&:hover': {
        color: theme.colors['teal-dark'],
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      backgroundColor: theme.colors['new-grey-light'],
    }),
    control: (provided, { isFocused, selectProps }) => ({
      ...provided,
      boxShadow: 'none',
      borderRadius: 8,
      minHeight: 45,
      borderColor: theme.colors[selectProps.menuIsOpen || isFocused ? 'teal' : 'new-grey-light'],
      '&:hover': {
        boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)',
      },
    }),
    menu: provided => ({
      ...provided,
      margin: '0 0 10px',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: isSmallScreen ? 'calc((var(--vh, 1vh) * 100) - 320px)' : '400px',
      padding: '0 10px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
      lineHeight: 1,
      cursor: 'pointer',
      padding: isSmallScreen ? '10px 25px' : '15px 20px',
      borderBottom: `1px solid ${theme.colors['grey-light']}`,
      backgroundColor: 'white',
      color: state?.isSelected ? theme.colors['teal-dark'] : 'initial',
      '&:hover': {
        color: theme.colors['teal-light'],
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    multiValue: provided => ({
      ...provided,
      margin: '8px 8px 8px 0',
      backgroundColor: 'white',
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: '16px',
      color: theme.colors['new-grey-light'],
    }),
    multiValueLabel: () => ({}),
  } as Partial<SelectStyles<OptionTypeBase, true>>);

const SelectAsync = ({
  value,
  name,
  placeholder,
  onChange,
  options,
  loadOptions,
  ...selectOptions
}: SelectProps<OptionTypeBase, true>) => {
  const isSmallScreen = useAppSelector(({ app }) => app.isMobile || app.isTablet);

  return (
    <>
      <AsyncPaginate
        id={name}
        inputId={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={value}
        loadOptions={loadOptions}
        noOptionsMessage={() => t('message.noOptions')}
        loadingMessage={() => t('general.loading')}
        isSearchable={!isMobileDevices}
        {...selectOptions}
        styles={{ ...customStyles(isSmallScreen), ...selectOptions.styles }}
        components={{
          MultiValueContainer: MultiValueContainer,
          MultiValueLabel: MultiValueLabel,
          MultiValueRemove: MultiValueRemove,
          // @ts-ignore
          DropdownIndicator: DropdownIndicator,
          // @ts-ignore
          ClearIndicator: ClearIndicator,
          ...selectOptions.components,
        }}
      />
    </>
  );
};

SelectAsync.defaultProps = {
  options: [],
};

export default SelectAsync;
