import InputWrapper from 'components/combinations/forms/InputWrapper';
import { TInputProps } from 'resources/types/elementsTypes';
import { InputAmount } from 'components/elements';
import { CurrencyInputProps } from 'react-currency-input-field/dist/components/CurrencyInputProps';

type TNumerInput = TInputProps & CurrencyInputProps;

const AmountInput = ({
  value,
  name,
  placeholder,
  onChange,
  children,
  marginInlineStart,
  mt,
  ...amountInputProps
}: TNumerInput) => {
  return (
    <InputWrapper marginInlineStart={marginInlineStart} mt={mt}>
      <InputAmount
        id={name}
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        px={20}
        {...amountInputProps}
      />
      {!!children && <>{children}</>}
    </InputWrapper>
  );
};

export default AmountInput;
