import { connect } from 'react-redux';

import PageTitle from '@old/components/guide/PageTitle';
import FloatingButton from '@old/components/custom/FloatingButton';
import t from 'resources/translations';
import Message from '@old/components/common/Message';
import FlexColumn from '@old/components/common/FlexColumn';
import ProposalListItem from '@old/components/view/listItem/Proposal';
import Allow from '@old/components/common/Allow';
import { useAddProposalButton } from 'old/buttonHooks';
import PagePagination from '@old/components/old/PagePagination';
import { useOldLoggedMember } from 'utils/storeUtils';

const ProposalEventsList = ({ data, paginationKey, loading }) => {
  const loggedMember = useOldLoggedMember();
  const addProposal = useAddProposalButton({
    label: t('proposalEventsList.addProposal'),
    initState: {
      initValues: {
        participations: loggedMember.isClient() ? [{ value: loggedMember.id, label: loggedMember.getFullName() }] : [],
      },
    },
  });

  return (
    <div>
      <PageTitle title={t('model.eventsProposals')} loading={loading} />
      <FlexColumn>
        {data.map(proposal => (
          <ProposalListItem key={proposal.id} proposal={proposal} />
        ))}
      </FlexColumn>
      {data.length === 0 && <Message info>{t('proposalEventsList.empty')}</Message>}
      <PagePagination paginationKey={paginationKey} />
      <Allow permissionKey="proposals.create">
        <FloatingButton buttons={[addProposal]} />
      </Allow>
    </div>
  );
};

ProposalEventsList.defaultProps = {
  paginationKey: '',
};

const mapStateToProps = ({ fetchingData: { loading } }) => {
  return {
    loading: loading.proposals,
  };
};

export default connect(mapStateToProps)(ProposalEventsList);
