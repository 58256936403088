import { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalButtons,
  AvatarWithLabel,
} from 'components/combinations';
import { Box, Button, Flex, MotionBox, Stack, Text, Icons, Span } from 'components/elements';
import { getErrorMessage, notify } from 'old/utils';
import Select from 'components/combinations/inputs/Select';
import AmountInput from 'components/combinations/inputs/Amount';
import { Divider } from 'components/combinations';
import { DATE_FORMAT, PAYMENT_STATUS_COMPACT } from 'config/constans';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import t from 'resources/translations';
import { BillService, TicketService } from 'services';
import { useMutation } from 'react-query';
import { TWrappedModal, TStoreStateModals } from 'views/modals';
import Bill from 'models/Bill';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import { IBillRes } from 'resources/types/billsTypes';
import Ticket from 'models/Ticket';
import { TImageProps } from 'resources/types/elementsTypes';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import Member from 'models/Member';
import moment from 'moment';
import FormLabel from 'components/combinations/forms/FormLabel';
import axios from 'axios';
import { useBillChangeDueAmount, yup } from 'utils';
import { useShallowSelector } from 'old/hooks';
import { CSSObject } from 'styled-components';
import { isNumber } from 'lodash';

const avatarStyles: TImageProps = {
  p: 2,
  width: 50,
  height: 50,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'teal-dark',
  borderRadius: 9999,
};

type TPaymentModalProps = {
  bill?: Bill;
  ticket?: Ticket;
  onSubmitCb: (billRes: IBillRes) => void;
  onChangeDueCb?: (billRes: IBillRes) => void;
};

const BillSetPaymentTicket = ({ onUpdate, onSubmitAndClose, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const { bill, ticket, onSubmitCb, onChangeDueCb } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['PAYMENT_TICKET'].props
  ) as TPaymentModalProps;
  const [paymentType, setPaymentType] = useState(PAYMENT_STATUS_COMPACT[0]);
  const setPayment = (billId: number) => BillService.addPaymentByBillId(billId, paymentType.value);
  const mutAddPayment = useMutation(setPayment, { onSuccess: onSubmitCb });
  const dueAmountState = useBillChangeDueAmount(bill, billRes => {
    !!onChangeDueCb && onChangeDueCb(billRes);
    onUpdate({ bill: new Bill(billRes) });
  });

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const fetchBill = async () => {
      if (ticket?.billId) {
        const billRes = await BillService.fetch(ticket.billId, source.token);
        const newBill = new Bill(billRes);
        onUpdate({ bill: newBill });
      }
    };
    const fetchTicket = async () => {
      if (bill?.billableId) {
        const ticket = await TicketService.fetch(bill.billableId, source.token);
        onUpdate({ ticket: new Ticket(ticket) });
      }
    };
    fetchBill();
    fetchTicket();
    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    try {
      if (bill?.ticket?.id) {
        await mutAddPayment.mutateAsync(bill.id);
        notify(t('message.succesSave'));
      }
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };

  const billed = (bill ? bill.billed : ticket?.membership) as Member;
  const itemName = (bill ? bill.getName() : ticket?.getName()) as string;
  const isBlockedModal = dueAmountState.mutation.isLoading || isSubmitting;

  const validationSchema = yup.object().shape({
    price: yup.string().maxNumberFromString(4000).required(t('error.fieldIsRequired')),
  });

  const isValid = validationSchema.isValidSync({ price: dueAmountState.inputProps.value });
  const daysToExpired = moment(ticket?.expireAt).diff(ticket?.activeAt, 'days') + 1;
  return (
    <Modal isHidden={isHidden} onClose={onClose} isBlocked={isBlockedModal} isOpen>
      <ModalHeader>{t('bills.customerPayment')}</ModalHeader>
      <ModalCloseButton onClose={onClose} disabled={isBlockedModal} />
      <ModalContent isMakingRequest={isSubmitting}>
        <Flex px={[0, 'base']} pt={[0, 'base']}>
          <AvatarWithLabel
            src={billed.getAvatar('small')}
            alt={billed.getFullName()}
            title={billed.getFullName()}
            spacing="md"
            {...avatarStyles}
          >
            <Text fontSize="md" fontWeight="medium">
              {billed.getFullName()}
            </Text>
          </AvatarWithLabel>
        </Flex>

        <ModalDescription>
          <Stack spacing="base">
            <Stack alignItems="center" spacing="sm" isHorizontal>
              <Icons.TicketCircle fill="white" bgColor="orange" size="sm" />
              <Text fontWeight="medium">{itemName}</Text>
            </Stack>

            {ticket ? (
              <Stack fontSize="base" spacing="sm">
                <Text fontWeight="medium">
                  {`${t('paymentTicketModal.periodValidity')}: `}
                  <Span fontWeight="regular">
                    {daysToExpired} {t('tickets.days', { count: daysToExpired })}
                  </Span>
                </Text>
                <Text fontWeight="medium">
                  {`${t('paymentTicketModal.dateActiveTicket')}: `}
                  <Span fontWeight="regular">{moment(ticket.activeAt).format(DATE_FORMAT)}</Span>
                </Text>
                <Text fontWeight="medium">
                  {`${t('paymentTicketModal.eventTypes')}: `}
                  <Span fontWeight="regular">{ticket.eventTypes.map(type => type.getName()).join(', ')}</Span>
                </Text>
              </Stack>
            ) : (
              <Box maxWidth={380}>
                <SkeletonTheme color="#F5F5F5" highlightColor="#EBEBEB">
                  <Skeleton height={19} style={{ marginBottom: 5 }} delay={1.2} width={280} />
                  <Skeleton height={19} style={{ marginBottom: 5 }} delay={1.4} />
                  <Skeleton height={19} style={{ marginBottom: 5 }} delay={1.6} width={320} />
                </SkeletonTheme>
              </Box>
            )}
          </Stack>
        </ModalDescription>
        <Box>
          <FormLabel htmlFor="dueAmount">{t('labels.due')}:</FormLabel>
          {!bill && <Skeleton height={47} />}
          {!!bill && (
            <AmountInput
              name="dueAmount"
              intlConfig={{ locale: 'pl', currency: 'PLN' }}
              groupSeparator=" "
              maxLength={11}
              allowNegativeValue={false}
              decimalScale={2}
              {...dueAmountState.inputProps}
            />
          )}
          {!isValid && (
            <Flex mt="sm">
              <Text color="orange" fontSize="sm">
                {t('error.lowerThanOrEqualPrice', { count: 4000 })}
              </Text>
            </Flex>
          )}
        </Box>
        {!!bill && dueAmountState.mutation.isLoading && (
          <Stack spacing="xs" mt="xs" alignItems="center" isHorizontal>
            <MotionBox
              width="auto"
              transition={{ ease: 'linear', duration: 2, repeat: Infinity }}
              animate={{ rotate: 360 }}
            >
              <Icons.Spinner fill="teal-dark" />
            </MotionBox>
            <Text fontSize="sm">{t('message.saving')}...</Text>
          </Stack>
        )}
        {!!bill && dueAmountState.statuses.isReadyToSave && isValid && (
          <Button variant="link" mt="xs" w="auto" onClick={dueAmountState.onSubmitDueAmount}>
            <Stack spacing="xs" alignItems="center" isHorizontal>
              <Icons.Accept borderColor="currentColor" borderWidth={1} borderRadius={9999} p={2} />
              <Text fontSize="sm">{t('buttons.saveChanges')}</Text>
            </Stack>
          </Button>
        )}
        {!!bill && dueAmountState.statuses.isChanged && (
          <Stack spacing="xs" mt="xs" alignItems="center" isHorizontal>
            <Icons.Accept size={10} fill="teal-dark" />
            <Text color="teal-dark" fontSize="sm">
              {t('message.dueAmountChanged')}
            </Text>
          </Stack>
        )}
        <Divider variant="dashed" color="grey-light" />
        <Box>
          <FormLabel htmlFor="paymentType">{t('bills.paymentMethod')}:</FormLabel>
          <Box>
            <Select
              name="paymentType"
              value={paymentType}
              options={PAYMENT_STATUS_COMPACT.filter(payment => payment.value !== 'ticket')}
              onChange={setPaymentType}
              isSearchable={false}
              menuPortalTarget={document.body}
              customStyles={{
                menuPortal: (base: CSSObject) => ({
                  ...base,
                  zIndex: 9999,
                  top: isNumber(base.top) ? base.top - 63 : 0,
                }),
              }}
              maxMenuHeight={150}
            />
          </Box>
        </Box>
      </ModalContent>
      <ModalButtons>
        <Button onClick={() => onSubmitAndClose(onSubmit)} disabled={dueAmountState.mutation.isLoading}>
          {isSubmitting ? <SpinnerDots label={t('bills.addingPayment')} inline /> : t('bills.addPayment')}
        </Button>
        <Button onClick={onClose} variant="outline">
          {t('general.cancel')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default BillSetPaymentTicket;
