/**
 * @module FormField
 */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniqueId } from 'lodash';
import cls from 'classnames';
import t from 'resources/translations';
import { validateField } from 'store/actions';
import { isEmptyExtended } from 'old/utils';
import { useShallowSelector } from '@old/hooks';
/**
 * @typedef   {Object[]} FieldValidation
 * @property  {Function} condition validation conditions
 * @property  {String} [errorHint] text of error displayed when field is invalid
 */
/**
 * Wrapper for form fields
 * @param  {String|Number|Array|Boolean|Object} [props.value] Value of the form field
 * @param  {String} props.name Name of the form field
 * @param  {*} props.children
 * @param  {String} props.formName is the unique name of the form
 * @param  {Boolean} [props.required] specifies if the field is required
 * @param  {FieldValidation} [props.validation] object with valifation conditions and erros
 * @param  {Function} props.setValidate funtion that validates field
 * @param  {Boolean} props.inputIsValid
 * @param  {Object} props.formState object that contains form data
 * @param  {Function} [props.afterChanges] specifies what happens after field change
 * @param  {*} props.firstMasterFieldsValue value of the first field in form that current field is depended from
 * @param  {Boolean} [props.anyMasterFieldIsChanged] specifies if any field in form that current field is depended from has changed
 */
const FormField = ({ value, name, children, formName, required, validation, setValidate, afterChanges }) => {
  const { inputIsValid, formState, firstMasterFieldsValue, anyMasterFieldIsChanged } = useShallowSelector(
    ({ form }) => {
      const masterFields = Object.values(form[formName]).filter(
        field => Array.isArray(field.dependentFields) && field.dependentFields.includes(name)
      );

      return {
        inputIsValid: form[formName].validate[name],
        formState: form[formName],
        firstMasterFieldsValue: masterFields.map(masterField => masterField)[0],
        anyMasterFieldIsChanged: masterFields.some(field => field.isChanged),
      };
    }
  );
  const [errorList, setErrorList] = useState([]);
  const showError = (formState[name] && formState[name].isChanged) || anyMasterFieldIsChanged;
  useEffect(() => {
    const errors = [];
    const isEmptyAndNotRequired = !required && isEmptyExtended(value);

    if (!isEmptyAndNotRequired) {
      // input validation
      if (required) {
        errors.push({
          isValid: !isEmptyExtended(value),
          hint: t('error.fieldIsRequired'),
        });
      }
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!validation) {
        errors.push(
          ...validation.map(validationItem => ({
            isValid: validationItem.condition(value, formState),
            hint: validationItem.errorHint,
          }))
        );
      }
    }

    setErrorList(errors);
    setValidate({
      formName,
      fieldName: name,
      isValidate: !errors.some(error => !error.isValid),
    });
  }, [value, firstMasterFieldsValue, anyMasterFieldIsChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showError && !!afterChanges) {
      afterChanges({ value, name, formState });
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={cls('form-field relative', { error: !inputIsValid && showError })}>
      {children}
      {showError && errorList.some(error => !error.isValid) && (
        <ul className="errors-hint">
          {errorList.map(
            error =>
              !error.isValid && (
                <li key={uniqueId('error_')} className="text-warning">
                  {error.hint}
                </li>
              )
          )}
        </ul>
      )}
    </div>
  );
};

FormField.defaultProps = {
  validation: null,
  value: null,
  required: false,
  options: null,
  afterChanges: null,
  formState: {},
};

FormField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  options: PropTypes.shape({}),
  setValidate: PropTypes.func.isRequired,
  afterChanges: PropTypes.func,
  formState: PropTypes.shape({}), // state of form
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.func.isRequired,
      errorHint: PropTypes.string,
    })
  ),
};

export default connect(null, { setValidate: validateField })(FormField);
