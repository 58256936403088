import { Box, Button, Content, Icons, MotionBox, Stack, Ternary, Text } from 'components/elements';
import { useIsFetching, useMutation, useQuery } from 'react-query';
import { useFetchBills } from 'actions/events';
import _ from 'lodash';
import {
  useFilters,
  useNewLayoutColor,
  mapBillFiltersToParams,
  useDisclosure,
  useSearchQuery,
  usePagination,
} from 'utils';
import { Card, PageTitle, Divider, CardContent } from 'components/combinations';
import t from 'resources/translations';
import BillsFilters from 'views/Bills/BillsFilters';
import Error404 from 'old/components/error/Error404';
import moment from 'moment';
import TextInput from 'components/combinations/forms/TextInput';
import SummaryBills from './SummaryBills';
import { BillService } from 'services';
import { IBillFetchParams, TBillFilters } from 'resources/types/billsTypes';
import { useCallback, useEffect } from 'react';
import BillsFiltersTags from './BillsFiltersTags';
import FormLabel from 'components/combinations/forms/FormLabel';
import { APIPagination } from 'resources/types/commonTypes';
import BillClientStatement from './BillClientStatement';
import BillsList from './BillsList';
import BillClientStatementMobile from './BillClientStatementMobile';
import BillsListMobile from './BillsListMobile';
import {
  BillHistoryPaymentsModal,
  PaymentEventModal,
  PaymentOnlineModal,
  PaymentReturnModal,
  PaymentTicketModal,
} from 'views/modals';
import { useAppStore, useLoggedMember } from 'utils/storeUtils';
import { notify } from 'old/utils';
import { usePermissions } from 'Permissions';

const Bills = () => {
  useNewLayoutColor();
  const formDisclosure = useDisclosure();
  const isFetching = !!useIsFetching();
  const loggedMember = useLoggedMember();
  const { isMobile } = useAppStore();
  const { page, setPage } = usePagination();
  const canIDownloadXmlx = usePermissions('BILL_DOWNLOAD_XMLX')();
  const { query, isTouched, searchQuery, setQuery } = useSearchQuery();
  const { filters, onChangeFilters } = useFilters<TBillFilters>('BILLS');
  const defaultFilters = useCallback(() => {
    const isFetchingMonth = _.isEmpty(filters) && !loggedMember.isClient();
    const additionalParams = isFetchingMonth
      ? { start_after: moment().startOf('month').format('DD.MM.YYYY'), end_before: moment().format('DD.MM.YYYY') }
      : {};
    return { ...additionalParams };
  }, [filters, loggedMember]);

  const fetchParams = {
    ...defaultFilters(),
    ...mapBillFiltersToParams(filters),
    ...(searchQuery ? { event_name: searchQuery } : {}),
  } as IBillFetchParams;

  const [billsState, bills] = useFetchBills(
    ['BILLS', { ...fetchParams, page }],
    { ...fetchParams, page },
    {
      onSuccess: ({ pagination }: { pagination: APIPagination }) => {
        if (page > pagination.pages) {
          setPage(pagination.pages);
        }
      },
    }
  );
  const summaryBillsState = useQuery(['SUMMARY_BILLS', fetchParams], () => BillService.fetchSummary(fetchParams), {
    initialData: {
      dueAmount: 0,
      paidAmount: 0,
      outstandingAmount: 0,
      overpaidAmount: 0,
    },
  });

  const downloadReportMutation = useMutation<Boolean>(
    'DOWNLOAD_REPORT',
    () => BillService.downloadReport(fetchParams),
    {
      onSuccess: () => {
        notify(t('bills.reportDownloadedSuccessfully'));
      },
      onError: () => {
        notify(t('bills.reportDownloadError'), { type: 'error' });
      },
    }
  );

  useEffect(() => {
    if (isTouched) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouched, searchQuery]);

  useEffect(() => {
    if (!_.isEmpty(filters)) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const nameInTitle = loggedMember.isInstructor() ? ` / ${loggedMember.getFullName()}` : '';
  const dateInTitle =
    !loggedMember.isClient() && _.isEmpty(filters)
      ? ` / ${moment().startOf('month').format('DD.MM')} - ${moment().format('DD.MM')}`
      : '';
  const title = `${t('bills.billing')} ${nameInTitle} ${dateInTitle}`;
  const billsIsEmpty = bills.length === 0;

  if (billsState.isError) return <Error404 />;

  if (formDisclosure.isOpen) {
    return <BillsFilters onAfterSubmit={() => setQuery('')} onClose={formDisclosure.onClose} />;
  }

  return (
    <Content>
      <PageTitle title={title} loading={isFetching} mb="xl" />
      <Stack spacing="2xl">
        <Card py="lg" px="md">
          <Stack alignItems="center" isHorizontal>
            <Icons.BillsSummaryCircle bgColor="grey-lighter-40" />
            <Text fontWeight="medium">{t('bills.summary')}</Text>
          </Stack>
          <Divider color="new-grey-light-30" mt="md" />
          <SummaryBills summaryBills={summaryBillsState.data} />
          <Divider color="new-grey-light-30" mb="lg" />
          <Stack alignItems="flex-end" px={0} spacing="base" isHorizontal>
            <Button variant="outline" px="sm" onClick={() => formDisclosure.onOpen()}>
              <Icons.Filter />
            </Button>
            <Box w="full">
              <FormLabel htmlFor="search" fontWeight="medium">
                {t('labels.search')}
              </FormLabel>
              <TextInput
                name="search"
                value={query}
                onChange={e => setQuery(e.target.value)}
                placeholder={t('placeholders.searchByEventOrTicketName')}
              >
                <Button variant="icon" ml="sm" onClick={() => setQuery('')} px={20}>
                  {query ? <Icons.Cancel squareSize="lg" /> : <Icons.Search squareSize="lg" />}
                </Button>
              </TextInput>
            </Box>
          </Stack>
          <BillsFiltersTags filters={filters} onChangeFilters={onChangeFilters} />
        </Card>
        {canIDownloadXmlx && (
          <Card>
            <CardContent alignItems="flex-end">
              <Ternary cond={!downloadReportMutation.isLoading}>
                <Button
                  fontSize="base"
                  variant="link"
                  onClick={() => downloadReportMutation.mutate()}
                  disabled={billsIsEmpty}
                >
                  <Stack isHorizontal spacing="sm" alignItems="center">
                    <Icons.Download
                      size="sm"
                      bgColor="white"
                      border={{ borderWidth: 1, borderColor: billsIsEmpty ? 'grey' : 'teal-light' }}
                    />
                    <Text>{t('bills.downloadReport')}</Text>
                  </Stack>
                </Button>
                <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                  <MotionBox
                    transition={{ ease: 'linear', duration: 2, repeat: Infinity }}
                    animate={{ rotate: 360 }}
                    display="flex"
                    flex="none"
                    width="auto"
                    alignItems="center"
                  >
                    <Icons.Spinner size="lg" fill="teal-dark" />
                  </MotionBox>
                  <Text color="teal-dark">{t('bills.generatingReport')}</Text>
                </Stack>
              </Ternary>
            </CardContent>
          </Card>
        )}
        <Box>
          <Ternary cond={!loggedMember.isClient()}>
            <Ternary cond={!isMobile}>
              <BillsList bills={bills} pagination={billsState.data?.pagination} isLoading={billsState.isFetching} />
              <BillsListMobile
                bills={bills}
                pagination={billsState.data?.pagination}
                isLoading={billsState.isFetching}
              />
            </Ternary>
            <Ternary cond={!isMobile}>
              <BillClientStatement bills={bills} pagination={billsState.data?.pagination} />
              <BillClientStatementMobile bills={bills} pagination={billsState.data?.pagination} />
            </Ternary>
          </Ternary>
        </Box>
      </Stack>
      <PaymentReturnModal />
      <PaymentTicketModal />
      <PaymentEventModal />
      <PaymentOnlineModal />
      <BillHistoryPaymentsModal />
    </Content>
  );
};

export default Bills;
