import React from 'react';
import PropTypes from 'prop-types';
import FlexRow from '@old/components/common/FlexRow';
import Subtitle from '@old/components/common/Subtitle';
import t from 'resources/translations';

const PairHeader = ({ availabilityCounter }) => {
  return (
    <FlexRow cols="1/none/1" separated="small">
      <div>
        <Subtitle>{t('eventPairs.pairedHorses')}</Subtitle>
      </div>
      <div style={{ width: 40 }} />
      <div>
        <Subtitle>{t('eventPairs.participants', { ...availabilityCounter })}</Subtitle>
      </div>
    </FlexRow>
  );
};

PairHeader.propTypes = {
  availabilityCounter: PropTypes.shape({
    participating: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
};

export default PairHeader;
