import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isMobile as isMobileDevices } from 'react-device-detect';
import cls from 'classnames';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { compact } from 'lodash';

import Link from '@old/components/common/Link';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import { useOutsideClick } from '@old/hooks';

const FloatingButton = ({ isMobile, ...props }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [buttonHover, setButtonHover] = useState(null);

  const wrapperHeight = collapsed ? 60 : (props.buttons.length + 1) * 60 + 20;
  const onLeave = debounce(() => setCollapsed(true), 1000);
  const onEnter = debounce(() => setCollapsed(false), 1000, { leading: true });
  const refFloatingWrapper = useRef();

  useEffect(() => {
    return () => {
      onLeave.cancel();
      onEnter.cancel();
    };
  });

  useOutsideClick(refFloatingWrapper, onLeave);

  // eslint-disable-next-line react/prop-types
  const renderButton = ({ to, onClick, iconName, index, big, label }) => {
    const IconComponent = Icon[iconName];
    const onClickButton = () => {
      setCollapsed(true);
      onClick();
    };

    const onMouseLeave = () => {
      setButtonHover(null);
      return onLeave;
    };

    if (to) {
      return (
        <Link to={to} onMouseEnter={() => setButtonHover(index)} onMouseLeave={onMouseLeave}>
          <label className={cls(isMobile && 'text-sm')}>{label}</label>
          <IconComponent big={big} circle />
        </Link>
      );
    }

    return (
      <div onMouseEnter={() => setButtonHover(index)} onMouseLeave={onMouseLeave}>
        <ButtonSimple name={iconName} onClick={() => onClickButton()}>
          <label className={cls(isMobile && 'text-sm')}>{label}</label>
          <IconComponent big={big} circle />
        </ButtonSimple>
      </div>
    );
  };
  const buttons = compact(props.buttons);

  if (buttons.length < 1) return null;

  if (buttons.length === 1) {
    return (
      <FloatingWrapper one isMobile={isMobile} wrapperHeight={wrapperHeight}>
        {renderButton({
          ...buttons[0],
          big: true,
          index: 0,
          label: buttons[0].label,
        })}
      </FloatingWrapper>
    );
  }

  return (
    <FloatingWrapper
      onLeave={onLeave}
      collapsed={collapsed}
      isMobile={isMobile}
      wrapperHeight={wrapperHeight}
      setCollapsed={setCollapsed}
    >
      {buttons.map((button, index) => (
        <div
          ref={refFloatingWrapper}
          key={index}
          className={cls('floating-button', { active: index === buttonHover })}
          style={{ bottom: `${70 + index * 60}px` }}
        >
          {renderButton({
            ...button,
            label: button.label,
            index,
          })}
        </div>
      ))}

      <div className="toggle-button">
        <ButtonSimple onClick={() => setCollapsed(prevCollapsed => !prevCollapsed)}>
          <Icon.MoreHorizontal circle big />
        </ButtonSimple>
      </div>
    </FloatingWrapper>
  );
};

FloatingButton.defaultProps = {
  buttons: [],
};

FloatingButton.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      iconName: PropTypes.node.isRequired,
      label: PropTypes.string.isRequired,
      to: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.shape({
          pathname: PropTypes.string.isRequired,
        }).isRequired,
      ]),
    }),
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      iconName: PropTypes.node.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ])),
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(FloatingButton);

const FloatingWrapper = ({ onLeave, one, collapsed, children, isMobile, wrapperHeight }) => {
  const otherProps = onLeave ? {
    onMouseEnter: () => onLeave.cancel(),
    onMouseLeave: onLeave,
  } : {};

  return (
    <>
      <div className={cls('floating-wrapper', isMobileDevices && 'touch-device')}>
        <div
          className={cls('floating', { one, collapsed })}
          style={{ width: 70, height: wrapperHeight }}
          {...otherProps}
        >
          {children}
        </div>
      </div>
      {!isMobile ? <div className="h-24" /> : <div className="h-16" />} {/* adding margin bottom to floating button */}
    </>
  );
};

FloatingWrapper.defaultProps = {
  one: false,
  collapsed: false,
  onLeave: false,
  wrapperHeight: 0,
};

FloatingWrapper.propTypes = {
  onLeave: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  one: PropTypes.bool,
  wrapperHeight: PropTypes.number,
  collapsed: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
