/**
 * @module TagCircle
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import config from '@old/config';
/**
 * Component for displaying tag in circle format
 * @param  {Boolean} [props.small] specifies wether the circle should be smaller than the standard one
 * @param  {Boolean} [props.large] specifies wether the circle should be larger than the standard one
 * @param  {String|Node} [props.children] content of the tag circle
 * @param  {String} [props.color] specifies color of the tag circle
 */
const TagCircle = ({ small, large, children, color }) => {
  return (
    <div className={cls('circle-tag', { large, small })} style={{ backgroundColor: color }}>
      <span>{children}</span>
    </div>
  );
};

TagCircle.defaultProps = {
  children: null,
  small: false,
  large: false,
  color: config.color.white,
};

TagCircle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  color: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
};

export default TagCircle;
