import { CHANGE_RATING } from 'store/actions/ratingsActions';

const initialState = {
  rating: 0,
  comment: '',
};

const ratingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_RATING:
      return {
        ...state,
        rating: action.rating,
        comment: action.comment,
      };
    default:
      return state;
  }
};

export default ratingsReducer;
