/**
 * @module HorsesProvider
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import { throttle, isEmpty } from 'lodash';

import Error404 from '@old/components/error/Error404';
import Spinner from '@old/components/common/Spinner';
import { setPagination, fetchHorses, resetPage } from 'store/actions';
import { getPaginationId } from 'old/utils';
import PagePagination from '@old/components/old/PagePagination';
import FlexColumn from '@old/components/common/FlexColumn';
import HorsesList from '@old/components/view/list/Horse';
import HorsesGrid from '@old/components/view/grid/Horse';
import SearchInput from '@old/components/custom/SearchInput';
import t from 'resources/translations';
import Message from '@old/components/common/Message';
import config from '@old/config';

/**
 * @typedef   {Object} PaginationStore
 * @property  {Number} current current page number
 * @property  {String} pathname current url without host
 */
/**
 * @typedef   {Object} Pagination
 * @property  {Number} count number of items
 * @property  {Number} current current page number
 * @property  {Number} next next page number
 * @property  {Number} pages number of pages
 * @property  {Number} per_page number of items per single page
 * @property  {Number} previous previous page number
 * @property  {Function} push
 */
/**
 * Component that provides horses and passes them view component
 * @param  {Object} [props.fetchParams] params for horses that will be fetched
 * @param  {Boolean} props.loading specifies wether new horses are being fetch
 * @param  {Boolean} props.error specifies wether an error occured when the horses were being fetch
 * @param  {Horse[]} [props.horses] fetched horses
 * @param  {Pagination} [props.horsesPagination] pagination fetched with horses
 * @param  {PaginationStore} props.pagination pagination from the store(set after fetched horses pagination)
 * @param  {String} [props.pagKey] string passed to create uniq pagination id - passed when tabs are being used
 * @param  {History} props.history
 * @param  {Function} props.fetchHorses function that fetches horses and saves them to strone
 * @param  {Function} props.setPagination function that sets pagination and saves it to store
 * @param  {Boolean} props.isMobile specifies wether mobile device is being used
 * @param  {Boolean} props.isSearchable specifies wether search input should be displayed
 */

const HorsesProvider = ({
  fetchParams,
  loading,
  error,
  horses,
  horsesPagination,
  pagination,
  pagKey,
  history,
  isMobile,
  isSearchable,
  ...actions
}) => {
  const [searchQuery, setSearchQuery] = useState();
  const [keyword, setKeyword] = useState('');
  const [wasSearched, setWasSearched] = useState(false);
  const { minItemsPerPage } = config;
  const onChangeQuery = val => {
    actions.resetPage(getPaginationKey());
    setSearchQuery(val);
    searchByKeyword(val);
    setWasSearched(true);
  };

  const searchByKeyword = throttle(value => {
    setKeyword(value);
  }, 500);

  useEffect(() => {
    setSearchQuery('');
    setKeyword('');
  }, [pagKey]);

  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname} ${JSON.stringify(pagKey)}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    // eslint-disable-line
    if (isEmpty(pagination) || pagination.current !== undefined) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const fetch = async () => {
        await actions.fetchHorses(
          {
            ...fetchParams,
            per_page: minItemsPerPage,
            page: getActivePage() || 1,
            keyword,
          },
          source.token
        );
      };
      fetch();
      return () => source.cancel();
    }
  }, [fetchParams, history.location.state, pagKey, keyword, pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (horsesPagination) {
      const pag = { ...horsesPagination };
      // When you delete last element on page, go back to previous one
      const lastPage = Math.ceil(pag.count / pag.per_page);
      if (pag.per_page * pag.current > pag.count) {
        if (lastPage && pag.current > lastPage) {
          pag.current = lastPage;
          history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
        }
        if (pag.current === 0) {
          pag.current = 1;
        }
      }
      actions.setPagination(pag, getPaginationKey());
    }
  }, [horsesPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Error404 />;
  if (loading && !horses.length && !wasSearched) {
    return <Spinner noDelay />;
  }

  if (!loading && !wasSearched && !horses.length) {
    return <Message info>{t('horsesProvider.noHorses')}</Message>;
  }

  return (
    <div>
      <FlexColumn>
        {isSearchable && (
          <SearchInput
            name="HorsesSearch"
            value={searchQuery}
            onChange={onChangeQuery}
            label={t('labels.search')}
            placeholder={t('placeholders.search')}
            clear={() => onChangeQuery('')}
          />
        )}
        {wasSearched && !loading && horses.length === 0 ? (
          <Message info>{t('message.noResults')}</Message>
        ) : (
          <React.Fragment>
            {isMobile ? <HorsesList data={horses} /> : <HorsesGrid data={horses} />}
            <PagePagination paginationKey={getPaginationKey()} />
          </React.Fragment>
        )}
      </FlexColumn>
    </div>
  );
};

HorsesProvider.defaultProps = {
  horses: [],
  horsesPagination: {},
  fetchParams: {},
  pagKey: '',
  isMobile: false,
  isSearchable: false,
};

HorsesProvider.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  horsesPagination: PropTypes.shape({
    count: PropTypes.number,
    current: PropTypes.number,
    next: PropTypes.number,
    pages: PropTypes.number,
    per_page: PropTypes.number,
    previous: PropTypes.number,
  }),
  pagination: PropTypes.shape({
    current: PropTypes.number,
  }).isRequired,
  fetchParams: PropTypes.shape({}),
  pagKey: PropTypes.string,
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({}),
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isSearchable: PropTypes.bool,
  resetPage: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
};

const mapStateToProps = (
  { pagination, fetchingData: { horses, horsesPagination, loading, error }, search },
  { pagKey }
) => {
  const paginationKey = getPaginationId(`${window.location.pathname} ${JSON.stringify(pagKey || '')}`);

  return {
    horses,
    horsesPagination,
    loading: loading.horses,
    error: error.horses,
    pagination: pagination[paginationKey] || {},
    query: search.query,
  };
};

export default withRouter(connect(mapStateToProps, { fetchHorses, setPagination, resetPage })(HorsesProvider));
