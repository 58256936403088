import React from 'react';
import PropTypes from 'prop-types';

const FadeText = ({ children }) => <span className="text-fade">{children}</span>;

FadeText.propTypes = {
  children: PropTypes.string.isRequired,
};

export default FadeText;
