import { useHistory } from 'react-router';
import Modal from '@old/components/common/Modal';
import t from 'resources/translations';
import FlexColumn from '@old/components/common/FlexColumn';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';
import { getErrorMessage, notify } from 'old/utils';
import { useOldLoggedMember } from 'utils/storeUtils';
import { MemberService } from 'services';

const ConfirmDeleteModal = ({
  doc,
  user,
  membership,
  goBack,
  onClose,
  afterSubmit,
  customRedirect,
  content,
  header,
  approveLabel,
}) => {
  const loggedMember = useOldLoggedMember();
  const history = useHistory();
  const redirectOrRefresh = () => {
    if (customRedirect) return history.replace(customRedirect);
    if (goBack) return history.goBack();
    return () => {};
  };

  const deleteUser = async () => {
    if (loggedMember.id === doc.id) {
      await MemberService.deleteUser();
    } else {
      notify(t('confirmDeleteModal.cantDeleteOtherAccount'), { type: 'error' });
    }
  };

  // fixme Member here is the new Model
  const onSubmit = async () => {
    try {
      if (user) {
        await deleteUser();
      } else if (membership) {
        await MemberService.delete(doc.id);
      } else {
        await doc.model.delete(doc.id);
        notify(t(`model.delete.${doc.modelName}`));
      }
      afterSubmit();
      redirectOrRefresh();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      onClose();
    }
  };

  if (!doc) return null;

  return (
    <Modal header={header || t('confirmDeleteModal.confirmationBeforeDeleting')} onClose={onClose} isOpen>
      <FlexColumn>
        <div>{content || t('message.confirmDelete', { title: doc.getName() })}</div>
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button onClick={onSubmit} fluid async>
            {approveLabel || t('general.delete')}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

ConfirmDeleteModal.defaultProps = {
  customRedirect: null,
  goBack: false,
  doc: null,
  user: false,
  membership: false,
  content: null,
  header: '',
  afterSubmit: () => {},
};

export default ConfirmDeleteModal;
