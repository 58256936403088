/* eslint-disable max-len */
import React from 'react';

const Policy = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <path d="M18.9,6.3h3.4l-5.2-5.4v3.6C17.1,5.5,17.9,6.3,18.9,6.3z" />
      <path d="M18.9,7.4c-1.6,0-2.9-1.3-2.9-2.9V0H5.4C4,0,3,1.1,3,2.5v21C3,24.9,4,26,5.4,26h15.1c1.4,0,2.5-1.1,2.5-2.5L23,9V7.4H18.9z
        M7.3,11.9h7c0.3,0,0.5,0.3,0.5,0.5c0,0.3-0.3,0.6-0.5,0.6h-7c-0.3,0-0.6-0.3-0.6-0.6C6.7,12.2,7,11.9,7.3,11.9z M18.7,20H7.3
        c-0.3,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.5,0.6-0.5h11.4c0.3,0,0.5,0.3,0.5,0.5C19.2,19.8,19,20,18.7,20z M18.7,16.5H7.3
        c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.5,0.6-0.5h11.4c0.3,0,0.5,0.3,0.5,0.5C19.3,16.3,19,16.5,18.7,16.5z"
      />
    </svg>
  );
};

export default Policy;
/* eslint-enable max-len */
