/* eslint-disable max-len */
import React from 'react';

const Quote = () => {
  return (
    <svg width="100%" viewBox="0 0 26 26">
      <g>
        <path d="M14.86,1.86V13h7.43c0,4.1-3.33,7.43-7.43,7.43v3.71C21,24.14,26,19.14,26,13V1.86L14.86,1.86z" />
        <path d="M0,13h7.43c0,4.1-3.33,7.43-7.43,7.43v3.71c6.14,0,11.14-5,11.14-11.14V1.86H0V13z" />
      </g>
    </svg>
  );
};

export default Quote;
/* eslint-enable max-len */
