import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

const ToggleBlock = ({ children, style, isOpen, isAutoScrollable }) => {
  return (
    <Measure bounds>
      {({ measureRef, _, contentRect }) => {
        if (isAutoScrollable && isOpen) {
          window.scrollTo({ top: window.scrollY + contentRect.bounds.height, behavior: 'smooth' });
        }
        return (
          <div
            className={cls('toggle-block', { open: isOpen })}
            style={{ ...style, height: isOpen ? contentRect.bounds.height : 0 }}
          >
            <div className="flex flex-1" ref={measureRef}>
              {children}
            </div>
          </div>
        );
      }}
    </Measure>
  );
};

ToggleBlock.defaultProps = {
  style: {},
  isAutoScrollable: false,
};

ToggleBlock.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  isAutoScrollable: PropTypes.bool,
  style: PropTypes.shape({
    order: PropTypes.number,
  }),
};

export default ToggleBlock;
