import { baseStyledProps, blockStyleProps, typographyStyleProps } from 'config/styledProps';
import theme from 'config/theme';
import React from 'react';
import { TFlexProps } from 'resources/types/elementsTypes';
import styled, { css } from 'styled-components';
import { flexbox } from 'styled-system';

const Wrapper = styled('div')<TFlexProps>(baseStyledProps, typographyStyleProps, blockStyleProps, flexbox, {
  minHeight: 45,
  color: theme.colors['grey-dark'],
  borderStyle: 'solid',
  display: 'flex',
  borderWidth: 1,
  '&:focus-within': {
    borderColor: theme.colors.teal,
  },
  '&:hover': {
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)',
  },
});

type TFormField = {
  children: JSX.Element | (JSX.Element | false)[];
  innerRef?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
} & TFlexProps;

const InputWrapper = ({ children, innerRef, disabled, ...flexStyleProps }: TFormField) => {
  return (
    <Wrapper
      ref={innerRef}
      borderRadius={8}
      borderColor={theme.colors['new-grey-light']}
      {...flexStyleProps}
      css={css({
        ...(disabled
          ? {
              color: theme.colors['new-grey-light'],
              '&:hover': {
                boxShadow: 'none',
              },
            }
          : {}),
      })}
    >
      {children}
    </Wrapper>
  );
};

export default InputWrapper;
