/**
 * @module Box
 */

import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import FlexColumn from '@old/components/common/FlexColumn';

/**
 * Wrapper with grey background and optionally centered content
 * @param {*}       props.children
 * @param {Boolean} [props.centerX]     specifies whether children should be centerted horizontally
 * @param {Boolean} [props.centerY]     specifies whether children should be centerted vertically
 * @param {Boolean} [props.fullHeight]  [todo description]
 * @param {Object<string, string|number>} [props.backgroundStyle] custom styles
 */
const Box = ({ children, centerX, centerY, fullHeight, fullWidth, backgroundStyle }) => {
  const className = cls('box relative', {
    'h-full': fullHeight,
    'w-full': fullWidth,
    flex: centerX || centerY,
    'justify-center': centerX,
    'items-center': centerY,
  });
  const content = React.Children.count(children) > 1 ? <FlexColumn separated={false}>{children}</FlexColumn> : children;

  return <div className={className} style={backgroundStyle}>{content}</div>;
};

Box.defaultProps = {
  centerX: false,
  centerY: false,
  fullHeight: false,
  fullWidth: false,
  backgroundStyle: null,
};

Box.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  centerX: PropTypes.bool,
  centerY: PropTypes.bool,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  backgroundStyle: PropTypes.shape({}),
};

export default Box;
