import React, { useState } from 'react';
import moment from 'moment';

import Modal from '@old/components/common/Modal';
import { notify } from 'old/utils';
import t from 'resources/translations';
import RenderDate from '@old/components/old/RenderDate';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';
import FlexColumn from '@old/components/common/FlexColumn';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Subtitle from '@old/components/common/Subtitle';
import Input from '@old/components/input';
import config from '@old/config';
import { useDisclosure } from '@old/hooks';

const SelectDateRangeModal = ({ dateRange, onSave }) => {
  const modalDisclosure = useDisclosure();
  const [beginning, setBeginning] = useState(dateRange.start.format(config.dateFormat));
  const [ending, setEnding] = useState(dateRange.end.format(config.dateFormat));
  const saveRange = () => {
    const start = beginning ? moment(beginning, config.dateFormat).startOf('day') : null;
    const end = ending ? moment(ending, config.dateFormat).endOf('day') : null;
    if (start && end) {
      onSave({ start, end });
      modalDisclosure.onClose();
    } else {
      notify(t('message.invalidRange'), { type: 'warning' });
    }
  };
  const isValid = !moment(beginning, config.dateFormat).isAfter(moment(ending, config.dateFormat));

  return (
    <React.Fragment>
      <Modal header={t('selectDateRangeModal.selectDateRange')} {...modalDisclosure} small>
        <FlexColumn>
          <Input.DateValue
            name="beginning"
            label={t('labels.start')}
            placeholder={t('placeholders.start')}
            value={beginning}
            onChange={value => setBeginning(value)}
          />
          <Input.DateValue
            name="ending"
            label={t('labels.end')}
            placeholder={t('placeholders.end')}
            value={ending}
            onChange={value => setEnding(value)}
          />
          {!isValid && <div className="text-left text-warning">{t('error.dateStartAfterDateEnd')}</div>}
          <FlexRow noShrink>
            <ButtonOutline onClick={modalDisclosure.onClose} fluid>
              {t('general.cancel')}
            </ButtonOutline>
            <Button onClick={saveRange} fluid disabled={!isValid}>
              {t('general.save')}
            </Button>
          </FlexRow>
        </FlexColumn>
      </Modal>
      <Subtitle>
        <ButtonSimple onClick={modalDisclosure.onOpen}>
          <div className="text-bold-teal">
            <RenderDate dateFormat={config.monthFormat} startDate={dateRange.start} endDate={dateRange.end} />
          </div>
        </ButtonSimple>
      </Subtitle>
    </React.Fragment>
  );
};

export default SelectDateRangeModal;
