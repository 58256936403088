import cls from 'classnames';
import { withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';

import t from 'resources/translations';
import FlexRow from '@old/components/common/FlexRow';
import ImageCircle from '@old/components/guide/ImageCircle';
import Icon from '@old/components/icon';
import { openModal } from 'store/actions';
import { Button, Stack, Text } from '@elements';
import { initApp } from 'store/app';

const FarmListItem = ({ farm, history, isMine, openModalByName }) => {
  const dispatch = useDispatch();

  const openFarm = () => {
    history.push(`/${farm.slug}/`);
    dispatch(initApp());
  };

  const onFarmSelect = () => {
    if (isMine) {
      openFarm();
    } else {
      openModalByName('confirmModal', {
        header: t('selectFarm.joiningStable'),
        content: t('selectFarm.confirmJoin', { farm: farm.getName() }),
        approveLabel: t('buttons.join'),
        onSubmitConfirm: () => openFarm(),
      });
    }
  };

  return (
    <Button variant="link" width="100%" onClick={onFarmSelect}>
      <div className="farm-listitem">
        <FlexRow cols="none/1" alignCenter noShrink>
          <div className={cls(isMine && 'border-solid border-2 border-teal rounded-full relative')}>
            <ImageCircle src={farm.getAvatar('thumb')} small />
            {isMine && (
              <div className="is-mine">
                <Icon.Check circle small />
              </div>
            )}
          </div>
          <Stack spacing="xs" textAlign="start">
            <Text fontWeight="medium">{farm.getName(35)}</Text>
            <Text color="black">{farm.slug}</Text>
          </Stack>
        </FlexRow>
      </div>
    </Button>
  );
};
export default withRouter(connect(null, { openModalByName: openModal })(FarmListItem));
