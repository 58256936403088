/* eslint-disable max-len */
import React from 'react';

const Underline = () => {
  return (
    <svg viewBox="0 0 26 26" width="100%">
      <g>
        <path d="M13,20.2c4.8,0,8.7-3.9,8.7-8.7V0h-1.2h-2.4h-0.7v11.2c0,2.7-2,4.9-4.3,4.9c-2.4,0-4.3-2.2-4.3-4.9V0H7.9H5.7H4.3v11.6
          C4.3,16.3,8.2,20.2,13,20.2z"
        />
        <rect x="2.9" y="21.9" width="20.2" height="4.1" />
      </g>
    </svg>
  );
};

export default Underline;
/* eslint-enable max-len */
