import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import axios from 'axios';

import PageTitle from '@old/components/guide/PageTitle';
import t from 'resources/translations';
import Spinner from '@old/components/common/Spinner';
import FlexRow from '@old/components/common/FlexRow';
import Box from '@old/components/common/Box';
import BoxWhite from '@old/components/common/BoxWhite';
import FloatingButton from '@old/components/custom/FloatingButton';
import SearchUsersInvited from '@old/components/old/SearchUsersInvited';
import FormInvitationEmails from '@old/components/form/InvitationEmails';
import EmailPreview from '@old/components/old/EmailPreview';
import { fetchFarm, fetchUsers } from 'store/actions';
import store from 'store';
import Error404 from '@old/components/error/Error404';
import { useSendInvitationFormButton } from 'old/buttonHooks';
import { useOldLoggedMember } from 'utils/storeUtils';

const InvitationEmails = ({ farm, users, loading, error, isMobile, isTablet, ...actions }) => {
  const loggedMember = useOldLoggedMember();
  const [showForm, setShowForm] = useState(false);
  const fetchParams = {
    with_role: 'client',
    with_status: ['invited'],
    per_page: 9999,
  };

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const fetch = async () => {
      actions.fetchFarm(source.token);
      actions.fetchUsers(fetchParams, source.token);
    };
    fetch();
    return () => source.cancel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // prevent users on invited list from flashing when other users list was cached before
  const {
    fetchingData: { usersFetchParams },
  } = store.getState();
  let invitedUsers = users;
  if (!isEqual(usersFetchParams, fetchParams)) {
    invitedUsers = [];
  }

  const sendInvitationFormButton = useSendInvitationFormButton(() => setShowForm(true), farm);

  if (error) return <Error404 />;
  if (loading && isEmpty(farm)) return <Spinner />;
  if (isEmpty(farm)) return null;

  const smallScreens = isMobile || isTablet;

  return (
    <div>
      {!smallScreens ? (
        <React.Fragment>
          <PageTitle title={t('invitationEmails.welcomeEmail')} loading={loading} />
          <FlexRow cols="10/6" stretched>
            <Box>
              <FlexRow cols="4/12">
                <FormInvitationEmails members={invitedUsers}>
                  <EmailPreview farmName={farm.name} />
                </FormInvitationEmails>
              </FlexRow>
            </Box>
            <BoxWhite fullHeight>{!loggedMember.isClient() && <SearchUsersInvited members={invitedUsers} />}</BoxWhite>
          </FlexRow>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PageTitle title={t('invitationEmails.invitations')} />
          {!loggedMember.isClient() && (
            <BoxWhite fullHeight>
              {showForm ? (
                <FormInvitationEmails hidden={!showForm} members={invitedUsers} hideForm={() => setShowForm(false)}>
                  <EmailPreview farmName={farm.name} />
                </FormInvitationEmails>
              ) : (
                <SearchUsersInvited members={invitedUsers} />
              )}
            </BoxWhite>
          )}
          {loggedMember.isClient() && (
            <FormInvitationEmails hidden={!showForm} members={invitedUsers} hideForm={() => setShowForm(false)}>
              <EmailPreview farmName={farm.name} />
            </FormInvitationEmails>
          )}
          {!showForm && <FloatingButton buttons={[sendInvitationFormButton]} />}
        </React.Fragment>
      )}
    </div>
  );
};

InvitationEmails.defaultProps = {
  farm: null,
  users: null,
};

InvitationEmails.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};
const mapStateToProps = ({ app: { isMobile, isTablet }, fetchingData: { farm, users, loading, error } }) => ({
  isMobile,
  isTablet,
  users,
  farm,
  loading: loading.farm || loading.users,
  error: error.farm,
});

export default connect(mapStateToProps, { fetchFarm, fetchUsers })(InvitationEmails);
