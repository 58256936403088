import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';

import Tag from '@old/components/common/Tag';
import FormField from '@old/components/common/FormField';
import Modal from '@old/components/common/Modal';
import Button from '@old/components/guide/Button';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import SearchInput from '@old/components/custom/SearchInput';
import OptionLoader from '@old/components/custom/OptionLoader';
import FlexRow from '@old/components/common/FlexRow';
import OptionsList from '@old/components/view/list/Options';
import Option from '@old/components/view/listItem/SelectOption';
import InputWrapper from '@old/components/common/InputWrapper';
import t from 'resources/translations';
import {
  useAddParticipantsLogic, useDisclosure,
} from '@old/hooks';
import { changeFieldState } from 'store/actions';
import Icon from '@old/components/icon';
import ButtonSimple from '@old/components/guide/ButtonSimple';

const AddParticipantsField = (
  { value, name, formName, formState, validation, afterChanges, myForwardedRef, label, ...actions },

) => {
  const [
    query,
    changeQuery,
    fetchMore,
    isOptionSelected,
    isOptionDisabled,
    selectedParticipants,
    setSelectedParticipants,
    newParticipantsIds,
    options,
    fetchOptionStatus,
    toggleSelectedOption,
  ] = useAddParticipantsLogic({
    eventIsPast: false,
    joinedParticipantsIds: [],
    joinedParticipantsOptions: [],
    participantsIds: [
      ...(formState.inviteParticipants ? formState.inviteParticipants.value.map(option => option.value) : []),
    ],
    defaultSelected: value || [],
  });
  const modalDisclosure = useDisclosure();

  useImperativeHandle(myForwardedRef, () => ({
    afterAddGuest(guestData) {
      if (guestData) {
        const guestOption = {
          key: guestData.membership.id,
          value: guestData.membership.id,
          label: guestData.membership.nickname,
          image: '/img/guest-placeholder.png',
          isGuest: true,
          isDeleted: false,
        };
        toggleSelectedOption(guestOption, false);
        actions.changeFieldState({
          formName,
          fieldName: name,
          fieldState: [
            ...uniqBy(
              [...selectedParticipants, { value: guestOption.value, label: guestOption.label }],
              option => option.value,
            ),
          ],
        });
        changeQuery(' ');
        changeQuery('');
      }
    },
  }));

  const onModalConfirm = () => {
    actions.changeFieldState({
      formName,
      fieldName: name,
      fieldState: [
        ...uniqBy(
          selectedParticipants.map(option => ({ value: option.value, label: option.label })),
          option => option.value,
        ),
      ],
    });
    modalDisclosure.onClose();
  };

  const onDeleteOption = async (deletedOption, e) => {
    e.stopPropagation();
    actions.changeFieldState({
      formName,
      fieldName: name,
      fieldState: [
        ...value.filter(option => option.value !== deletedOption.key),
      ],
    });
    setSelectedParticipants(prevOptions => prevOptions.filter(option => option.value !== deletedOption.key));
  };

  const onDeleteParticipant = async () => {
    actions.changeFieldState({
      formName,
      fieldName: name,
      fieldState: [],
    });
  };

  const limitExceeded = formState.attendees ? newParticipantsIds.length > formState.attendees.value[1] : false;

  const iconValuesRemove = (
    <div className="pr-2">
      <ButtonSimple onClick={onDeleteParticipant}>
        <Icon.Cancel small />
      </ButtonSimple>
    </div>
  );

  return (
    <FormField name={name} formName={formName} validation={validation} value={value} afterChanges={afterChanges}>
      <Modal
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        header={t('model.add.participants')}
      >
        <SearchInput
          name="membersSearch"
          label={t('general.search')}
          value={query}
          onChange={changeQuery}
          clear={() => changeQuery('')}
          placeholder={t('placeholders.searchByName')}
        />
        <OptionsList fetchMore={fetchMore}>
          {options.map((option) => {
            const isSelected = isOptionSelected(option);
            const isDisabled = isOptionDisabled(option);
            const onClick = !isDisabled ? () => toggleSelectedOption(option, isSelected) : () => { };
            const extendedLabel = option.isGroup ? option.getExtendedLabel(newParticipantsIds) : null;
            return (
              <Option.MembersAndGroups
                key={option.key}
                data={{ ...option, extendedLabel }}
                innerProps={{ onClick }}
                isSelected={isSelected}
                isDisabled={isDisabled}
              />
            );
          })}
          {fetchOptionStatus.isPending && <OptionLoader />}
        </OptionsList>
        {limitExceeded && (
          <ul className="errors-hint">
            <li className="text-warning">{t('addParticipantModal.limitExceeded')}</li>
          </ul>
        )}
        <FlexRow separated="small" noShrink>
          <ButtonOutline onClick={modalDisclosure.onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button onClick={onModalConfirm} fluid>
            {t('general.save')}
          </Button>
        </FlexRow>
      </Modal>
      <InputWrapper label={label || t('model.add.participants')} icon={value.length ? iconValuesRemove : null}>
        <div className="add-participant-input" onClick={modalDisclosure.onOpen}>
          {value.map(({ value: key, label: optionLabel }) => (
            <Tag key={key} item={{ key, label: optionLabel }} onDelete={onDeleteOption} />
          ))}
        </div>
        <input
          placeholder={value.length === 0 ? t('placeholders.selectParticipants') : ''}
          onClick={modalDisclosure.onOpen}
          readOnly
        />
      </InputWrapper>
    </FormField>
  );
};

AddParticipantsField.defaultProps = {
  afterChanges: null,
  label: null,
  validation: [],
  value: [],
};

AddParticipantsField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  formState: PropTypes.shape({
    attendees: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.number),
    }),
    inviteParticipants: PropTypes.shape({
      value: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  validation: PropTypes.arrayOf(PropTypes.shape({
    condition: PropTypes.func,
    errorHint: PropTypes.string,
  })),
  afterChanges: PropTypes.func,
  myForwardedRef: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return {
    value: inputValue,
    formState: form[props.formName],
  };
};

const ConnectedAddParticipantsField = connect(mapStateToProps, { changeFieldState })(AddParticipantsField);

// eslint-disable-next-line react/display-name
export default forwardRef((props, ref) => <ConnectedAddParticipantsField {...props} myForwardedRef={ref} />);
