/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

export const LogoHmNotextIcon = ({ width = 64, height = 64, color = '#fff' }) => (
  <svg
    id="LogoHmNoText"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 64 64"
    x="0px"
    y="0px"
  >
    <path fill={color} d="M63.101,40.519c-0.451,0.43-1.719,1.604-3.726,3.165c-2.554,1.986-6.958,6.115-16.685,3.915 c-9.532-2.156-9.703-10.213-9.703-10.95c0-0.739,0.964-2.227,1.078-2.816s-0.057-1.554-0.057-1.554s2.61,3.064,5.902,3.631 c3.291,0.568,5.336-0.567,5.336-0.567s1.244,1.021,2.665,1.476c1.42,0.454,3.292,1.021,4.369,1.759 c1.078,0.738,0.851,1.364,1.986,1.874c1.134,0.511,1.418,0.452,2.156,0.397c0.737-0.059,0.908-0.1,1.419,0.035 c0.511,0.134,1.645-0.888,1.985-1.512c0.339-0.624,0.397-2.393,1.079-2.784c0.679-0.393,1.135-2.096,0.795-3.458 c-0.343-1.362-0.795-2.497-1.477-2.668c-0.68-0.17-1.363-0.283-1.704-1.021c-0.339-0.737-4.538-6.581-4.991-7.262 c-0.457-0.682-0.866-1.375-0.938-1.93c-0.06-0.483-1.027-2.394-2.708-4.469c-1.933-2.383-4.702-4.964-4.685-4.937 c0,0,1.805-2.51,2.03-4.439c0.228-1.928-0.285-3.008-0.854-2.554c-0.564,0.455-3.629,3.405-3.629,3.405s0.113-1.738-0.058-3.508 c-0.17-1.769-0.736-2.79-1.249-2.28C40.933,1.976,36.95,6.93,36.95,6.93S32.191,5.38,26.916,5.268 c-5.278-0.115-11.123,1.474-11.123,1.474l5.617,1.589c0,0-4.71,0.228-8.795,1.249c-3.802,1.192-6.355,3.405-6.355,3.405l4.71-0.454 c0,0-4.314,2.78-6.243,5.674c-1.929,2.895-2.44,5.335-2.44,5.335s1.872-1.25,3.518-1.815c1.645-0.568,3.291-1.022,3.291-1.022 s-3.461,2.497-5.617,4.994c-0.907,1.048-1.721,2.438-2.362,3.696c0,0.056-0.002,0.113-0.002,0.169 c0,6.849,2.155,13.192,5.822,18.394c0.129-0.93,0.314-2.006,0.571-3.078C8.189,42.038,8.7,40.337,8.7,40.337s0,3.347,0.228,6.809 c0.071,1.096,0.256,2.491,0.477,3.89c5.846,6.438,14.285,10.483,23.668,10.483C46.872,61.519,58.629,52.77,63.101,40.519" />
  </svg>
);

export const LogoHmIcon = ({ width = 64, height = 64, color = '#fff' }) => (
  <svg
    id="LogoHm"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 283.5 283.5"
    x="0px"
    y="0px"
  >
    <path fill={color} d="M261.4,152.5c-1.7,1.6-6.4,6.1-13.9,12c-9.5,7.5-25.9,23.1-62.1,14.8c-35.5-8.1-36.1-38.6-36.1-41.4c0-2.8,3.6-8.4,4-10.6c0.4-2.2-0.2-5.9-0.2-5.9s9.7,11.6,22,13.7c12.3,2.1,19.9-2.1,19.9-2.1s4.6,3.9,9.9,5.6c5.3,1.7,12.3,3.9,16.3,6.6c4,2.8,3.2,5.1,7.4,7.1c4.2,1.9,5.3,1.7,8,1.5c2.7-0.2,3.4-0.4,5.3,0.1c1.9,0.5,6.1-3.3,7.4-5.7c1.3-2.4,1.5-9,4-10.5c2.5-1.5,4.2-7.9,3-13.1c-1.3-5.1-3-9.4-5.5-10.1c-2.5-0.6-5.1-1.1-6.3-3.9c-1.3-2.8-16.9-24.9-18.6-27.4c-1.7-2.6-3.2-5.2-3.5-7.3c-0.2-1.8-3.8-9-10.1-16.9c-7.2-9-17.5-18.8-17.4-18.7c0,0,6.7-9.5,7.6-16.8c0.8-7.3-1.1-11.4-3.2-9.6c-2.1,1.7-13.5,12.9-13.5,12.9s0.4-6.6-0.2-13.3c-0.6-6.7-2.7-10.5-4.6-8.6C178.9,6.9,164,25.6,164,25.6s-17.7-5.9-37.4-6.3c-19.7-0.4-41.4,5.6-41.4,5.6l20.9,6c0,0-17.5,0.9-32.8,4.7c-14.2,4.5-23.7,12.9-23.7,12.9l17.5-1.7c0,0-16.1,10.5-23.2,21.4c-7.2,10.9-9.1,20.2-9.1,20.2s7-4.7,13.1-6.9c6.1-2.1,12.3-3.9,12.3-3.9S47.4,87,39.4,96.5c-3.4,4-6.4,9.2-8.8,14c0,0.2,0,0.4,0,0.6c0,25.9,8,49.8,21.7,69.5c0.5-3.5,1.2-7.6,2.1-11.6c2.5-10.7,4.4-17.2,4.4-17.2s0,12.6,0.8,25.7c0.3,4.1,1,9.4,1.8,14.7c21.8,24.3,53.2,39.6,88.1,39.6C201,231.8,244.8,198.8,261.4,152.5" />
    <g>
      <path fill={color} d="M26.5,184.3l4.4,6.1l-9.9,7.2l6.6,9l9.9-7.2l4.4,6.1l-24.8,18.1l-4.4-6.1l9.7-7.1l-6.6-9l-9.7,7.1l-4.4-6.1L26.5,184.3z" />
      <path fill={color} d="M56,217.5c7.1,6,7.8,15.7,2,22.4c-5.9,6.9-15.6,7.8-22.7,1.8c-7.1-6-7.7-15.8-1.8-22.7C39.3,212.3,48.9,211.5,56,217.5z M39.8,236.5c3.5,3,8.9,2.4,12.3-1.6c3.3-3.8,2.9-9.1-0.6-12.1c-3.5-3-8.8-2.5-12,1.3C36.1,228.1,36.3,233.5,39.8,236.5z" />
      <path fill={color} d="M70.3,229.4l9.5,5c2.8,1.5,4,2.4,4.9,3.4c2.5,2.8,2.9,6.6,0.9,10.6c-1.5,2.9-4.6,5.3-8,5.3l0,0.1c0,0,0.1,0.7,0.2,2.2l0.3,12.3l-7.4-3.9l0-11.7l-3.1-1.6l-4.8,9.2l-6.7-3.5L70.3,229.4z M73.4,246.7c2.1,1.1,4.1,0.6,5.2-1.5c1.1-2.1,1-3.7-2.4-5.4l-2.2-1.2l-3.5,6.6L73.4,246.7z" />
      <path fill={color} d="M90.2,262.2c0,0,2.4,4.1,6.1,5.2c1.5,0.5,3.3,0.4,3.8-1.4c1.1-3.5-12.2-7.4-9.5-16.5c1.7-5.4,7.3-7.6,13.1-5.8c6.3,1.9,8.4,6.2,8.4,6.2l-4.8,5c0,0-2.2-3.5-5.7-4.6c-1.5-0.5-3.3-0.3-3.8,1.3c-1.1,3.7,12.3,7.1,9.5,16.4c-1.5,4.9-6.5,8-13.1,6c-6.9-2.1-9.5-7.5-9.5-7.5L90.2,262.2z" />
      <path fill={color} d="M116.8,247.7l19.1,2.4l-0.8,6.4l-11.7-1.4l-0.7,5.5l9.3,1.1l-0.8,6.4L122,267l-0.7,5.8l12.2,1.5l-0.8,6.4l-19.7-2.4L116.8,247.7z" />
      <path fill={color} d="M140.2,259.8l2.9-0.3l0.3,3.2c0.1,0.9,0.1,1.7,0.1,1.7l0.1,0c0.7-3,3.9-5.9,7.3-6.2c4-0.4,6.2,1.3,7.2,4.7l0.1,0c0.9-3,3.8-5.8,7.4-6.2c5.2-0.5,7.4,2.3,8,7.7l1.3,13.7l-2.9,0.3l-1.3-13c-0.3-3.5-1.4-6.3-5.3-6c-3.3,0.3-5.7,3.5-6.2,6.7c-0.2,1-0.2,2-0.1,3.2l1,10.2l-2.9,0.3l-1.3-13c-0.3-3.3-1.1-6.4-5.2-6c-3.5,0.3-5.9,3.6-6.4,7c-0.1,0.9-0.1,1.9,0,2.9l1,10.2l-2.9,0.3L140.2,259.8z" />
      <path fill={color} d="M192,258.8l1.1-0.4l-0.2-0.5c-1.3-4-3.9-4.6-6.8-3.7c-3.4,1.1-5.2,3.8-5.2,3.8l-2-1.7c0,0,2-3.2,6.6-4.7c5-1.6,8.6,0.3,10.2,5.4l4.2,13.2l-2.7,0.9l-0.7-2.3c-0.3-1-0.5-1.7-0.5-1.7l-0.1,0c0,0-0.3,5-5.4,6.7c-3.4,1.1-7.6,0.2-9-3.9C179.5,263,188.5,260,192,258.8z M190.4,271.3c3.9-1.3,5-6.1,3.9-9.7l-0.3-0.9l-1.2,0.4c-3.2,1-9.7,3.2-8.2,7.8C185.1,270.8,187.3,272.3,190.4,271.3z" />
      <path fill={color} d="M199.3,247.4l2.6-1.3l1.5,2.9c0.4,0.8,0.7,1.6,0.7,1.6l0.1,0c-0.2-2.1,0.6-6.5,5.2-8.8c4.7-2.4,7.8-0.6,10.3,4.1l6.3,12.2l-2.6,1.3l-5.9-11.5c-1.6-3-3.4-5.5-7.1-3.5c-3.3,1.7-5,5.4-4.2,9c0.2,0.9,0.6,1.8,1,2.8l4.7,9.2l-2.6,1.3L199.3,247.4z" />
      <path fill={color} d="M235.4,235.1l1-0.7l-0.3-0.4c-2.5-3.3-5.2-3.1-7.6-1.3c-2.9,2.1-3.7,5.3-3.7,5.3l-2.4-1c0,0,0.8-3.7,4.7-6.6c4.2-3.1,8.2-2.5,11.4,1.8l8.3,11.1l-2.2,1.7l-1.4-1.9c-0.6-0.9-1-1.5-1-1.5l-0.1,0.1c0,0,1.4,4.8-3,8.1c-2.9,2.2-7.2,2.7-9.7-0.7C224.9,243.1,232.4,237.3,235.4,235.1z M237.9,247.4c3.3-2.5,2.7-7.4,0.5-10.4l-0.6-0.8l-1,0.7c-2.7,2-8.1,6.2-5.2,10C232.8,248.7,235.3,249.4,237.9,247.4z" />
      <path fill={color} d="M264,236c2.9-3,3.8-6.8,0.4-10.2l-1.4-1.4c-0.6-0.6-1.1-1.1-1.1-1.1l-0.1,0.1c0.8,2.6,0.3,5.1-2,7.5c-4,4.2-10,3.7-14.7-0.8s-5.4-10.2-1.3-14.3c4-4.1,7.6-2.6,7.6-2.6l0.1-0.1c0,0-0.2-0.1-0.6-0.5l-1.7-1.6l2-2l15.2,14.7c4.9,4.8,3.3,10.3-0.3,14.1c-1.7,1.8-3.9,3.1-6.2,3.9l-1-2.5C258.8,239.1,261.7,238.3,264,236z M256.6,218.2c-4.5-4.4-8-3.6-10.7-0.8c-2.9,3-2.4,6.9,1.3,10.5c3.7,3.6,8.1,3.9,11.1,0.8C260.6,226.3,261.1,222.7,256.6,218.2z" />
      <path fill={color} d="M260.4,195.4c3.5-5.2,10.4-6.8,15.7-3.2c5.4,3.6,6.6,10.6,3.1,15.9c-3.5,5.2-10.4,6.8-15.9,3.1C258,207.6,256.9,200.6,260.4,195.4z M277,206.6c2.6-3.9,1.6-9.1-2.6-11.9c-4.1-2.7-9.2-1.6-11.8,2.2s-1.7,9,2.4,11.8 C269.2,211.4,274.4,210.4,277,206.6z" />
    </g>
  </svg>
);

export const GoogleIcon = ({ className }) => (
  <svg className={className} width="18" height="18" xmlns="http://www.w3.org/2000/svg">
    <g fill="#000" fillRule="evenodd">
      <path
        d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
        fill="#EA4335"
      />
      <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4" />
      <path
        d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
        fill="#FBBC05"
      />
      <path
        d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
        fill="#34A853"
      />
      <path fill="none" d="M0 0h18v18H0z" />
    </g>
  </svg>
);

export const Four = () => (
  <svg id="four" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455.05 693.16" style={{ width: '100%' }}>
    <title>Four</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon className="cls-1" points="404.29 294.53 404.29 484.78 384.66 484.78 384.66 278.48 404.29 294.53" />
        <polygon className="cls-1" points="455.05 500.83 455.05 588.84 404.29 588.84 404.29 693.16 311.62 693.16 291.99 677.11 384.66 677.11 384.66 572.79 435.42 572.79 435.42 484.78 455.05 500.83" />
        <polygon className="cls-1" points="291.99 572.79 291.99 588.84 19.63 588.84 0 572.79 291.99 572.79" />
        <polygon className="cls-1" points="344.68 16.05 130.35 484.78 103.39 484.78 317.71 16.05 325.05 0 344.68 16.05" />
        <polygon className="cls-2" points="435.42 484.78 435.42 572.79 384.66 572.79 384.66 677.11 291.99 677.11 291.99 572.79 0 572.79 0 490.37 227.26 0 325.05 0 317.71 16.05 103.39 484.78 291.99 484.78 291.99 278.48 384.66 278.48 384.66 484.78 435.42 484.78" />
      </g>
    </g>
  </svg>
);

export const Unicorn = () => (
  <svg id="unicorn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 743.05 769.42" style={{ width: '100%' }}>
    <defs>
      <radialGradient id="Gradient" cx="45.87" cy="106.25" r="45.87" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#d8d8d8" />
        <stop offset="0.17" stopColor="#d6d6d6" stopOpacity="0.99" />
        <stop offset="0.31" stopColor="#d1d1d1" stopOpacity="0.94" />
        <stop offset="0.44" stopColor="#c7c7c7" stopOpacity="0.86" />
        <stop offset="0.56" stopColor="#bababa" stopOpacity="0.75" />
        <stop offset="0.68" stopColor="#a8a8a8" stopOpacity="0.6" />
        <stop offset="0.79" stopColor="#939393" stopOpacity="0.43" />
        <stop offset="0.9" stopColor="#7b7b7b" stopOpacity="0.22" />
        <stop offset="1" stopColor="#606060" stopOpacity="0" />
      </radialGradient>
    </defs>
    <title>Unicorn</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="45.87" cy="106.25" r="45.87" />
        <path className="cls-2" d="M220.15,87.06s-40.57-1.26-32.88,49.64c0,0,16.54,117.38-13.23,158,0,0-67.86,89-98.87,46.3,0,0-16.11-27.45,5.54-51,0,0-64.92,48.42-15.33,78.95,0,0,10,6.42,29.94,6.29a1.6,1.6,0,0,1,.69,3.05c-19.85,9.21-66.72,37-59,91.37,0,0,5,34.66,36.64,48.85,0,0-27-27.55-14.88-59.49,0,0,12.66-48.45,75.27-71.18a3.73,3.73,0,0,1,3.52,6.48C117.18,409.57,87.85,437,83,471.74c0,0,29.27-42,88.66-61.62,0,0,56.77-27.44,71.28-37.25,0,0-33.22,33.17-30.26,85.85,0,0,5.68,47,47.73,54.11,0,0-56.65-61.78-18.46-96.15,0,0,45-34,66.49-64,0,0,75.32-92.43,37.35-130.26L367,219.65l22.11,22.14s-25.7,100.82-63.57,114.32c0,0,68.72-24.49,69.44-57.94,0,0,31.61-81.27,3.09-116.94Z" />
        <path className="cls-3" d="M283.9,89.4s121.54,4.15,142.28,26.65c0,0,41,22.44,47.05,30L423.91,329.93s-100.4-84.32-145.37-127.82c0,0-9.64,7.95-13.13,9.14,0,0-.13,30.53-18.63,34.78,0,0-11.86,40.49-21.66,41.32,0,0-4.68,8.47-4.38,12,0,0,6.25,10.24-8.68,19.58,0,0-10.1,8-14.26,1.2,0,0-10,9.36-24.3-6,0,0-20.54,1.27-15.65-20.68,0,0-2.81-33.43,2-45.51,0,0-8-36.8-5.27-52.07,0,0,.47-4.62,1.48-11.23.09-.59.18-1.18.28-1.79,2.57-16.12,8.11-41.72,17.58-43.93C173.93,138.92,215.29,91.26,283.9,89.4Z" />
        <path className="cls-2" d="M287.1,106.91s3.21,33.5,31.79,43l22.71-3.15s22.91-8.17,13.47,14.58c0,0-12.66,12.48-9.82,23.07,1.1,4.09,10.39,12.6,16.78,13.86,0,0,29.9,5.92,13.78-20,0,0-15.18-2.54-14.3,3.73,0,0,14-4.94,10.23,5.93,0,0-2.3,9-14.64.47,0,0-5.58-13.29,7.95-19.06,0,0,32.71-10.29,9.25-31,0,0-13-15.05-34.4-3,0,0-10.65,10.4-20.69-14.66,0,0-9.68-18.05-2.83-22.94C316.38,97.82,286.19,102.29,287.1,106.91Z" />
        <path className="cls-4" d="M283.26,206.63s-1.39-34.7-41.21-48c0,0-14.94-14.9-20.27-30.59,0,0-1.33-8.25,2.2-3.9,5.08,6.25,10.87,20.38,21,27,0,0,23.22,12.71,46.44,39.81,23.55,27.48,57.13,47.87,76.82,42,12.1-3.61-16.22,25-11.66,39.31L293.26,216S289.17,212.18,283.26,206.63Z" />
        <path className="cls-2" d="M208,100.9s7.72-49.55,58.69-40.44C297.79,66,297.35,82,327,89.19c14.67,3.54,31.68-15.19,50.95-13,8.84,1,37.19,17.68,63.41,38.31,22.47,17.69,49.7,3.84,65.77,12.19,0,0-11.16,3.08-6.89,12.41,0,0-22.63,5.29-58.72,26.8,0,0-36,5.68-104.87-46.32C336.62,119.53,284.26,139.16,208,100.9Z" />
        <path className="cls-5" d="M208.17,177.54s-.54,5.51-1,5.94c0,0-2.77,9.32-8.6,12.2,0,0-7.93,4.15-12.07,3.22,0,0-6.41.76-8.46-2,0,0-1.56-1.42-.43-5.45,0,0,1.73-7.39,7-11,0,0,7-6.12,10.38-6C195,174.48,203.93,172,208.17,177.54Z" />
        <path className="cls-6" d="M199.37,179.14l2.7-3s-11.4-1-19.33,14.23Z" />
        <path className="cls-6" d="M187.69,195.72s15.36-.12,12-16.78c0,0-7.38-6.2-16.91,11.42,0,0-2.51,5.44-1,7.29C181.75,197.65,182.39,198.51,187.69,195.72Z" />
        <image className="cls-7" width="16" height="17" transform="translate(184.53 178.12)" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAACXBIWXMAAAsSAAALEgHS3X78AAAAcElEQVQ4T6WTwQ3AIAzEnLaLMtnNxiT000hRCyoE/0DYhAfWWmOVUkoDkGQ2G3DpzdXbjIxEZxj4E51PYFZ0jrhYlSEEMjI8gawMrydkOGut6dthcwJJthWAjQkkGWwEnFTAb4eJzxSJonP0NnuMzt0nlyfodqy0OQAAAABJRU5ErkJggg==" />
        <g className="cls-8">
          <path className="cls-9" d="M183.35,191a8.32,8.32,0,0,0,1.19,2.45,6.41,6.41,0,0,0,2,1.73v0a2.86,2.86,0,0,1-1.32-.53,4.19,4.19,0,0,1-.93-1.08,7.49,7.49,0,0,1-1-2.6Z" />
        </g>
        <path className="cls-3" d="M465.05,391.44s-12.24,139.35,0,165.27c0,0-1.14,13.31,2.16,20.49,0,0-3.13,12,6,42.46l7.66,54,15.84-46s18-36.43-.14-51.41c0,0,8.92-39.63,17.84-35.23l-7.62-71.85,8.59,81.07A16.1,16.1,0,0,0,512.77,567a15.33,15.33,0,0,1,.13,10.62c-3.08,8.86-6.84,27.7,2.5,54.56l27.6,4.11v-4.11s13.32-51.49,2-70.82c0,0,12.19-76.33-26.69-111.58S465.05,391.44,465.05,391.44Z" />
        <path className="cls-6" d="M405.66,641.71Q356.54,591,356.53,500.14V192.33q0-91.28,49.36-141.8T549.79,0q95,0,144.13,50.29t49.13,142V500.14q0,91.27-49.36,141.8t-143.9,50.53Q454.79,692.47,405.66,641.71Zm218.41-65.89q24-26.31,24-75.68V192.33q0-49.37-23.75-75.68T549.79,90.34q-50.76,0-74.51,26.31t-23.75,75.68V500.14q0,49.36,24,75.68t74.28,26.31Q600.09,602.13,624.07,575.82Z" />
        <path className="cls-3" d="M492.28,485.08s-28.8-25.92-25.92-63.36c0,0-154.67-209.83,37.44-293.76,0,0,35.41-15.88,57.73.68,0,0,37.31-18,74.75,13.72,0,0,103.68,80.64,49,201.6,0,0-33.84,107.64-58.68,117.36,0,0,48.8,47.79,44.25,104.36,0,0-15,34.92-20.49,72.76,0,0-6.18,15.51-1.49,28.15,4.24,11.41-3.75,18.21-8.23,20.45,0,0,30.24,20.52,4.32,50.76,0,0-22.68,17.28-34.56-9.72,0,0-14-25.92,2.16-37.8,0,0,3.24-30.24,11.88-30.24,0,0,4.92-81.87-11.28-79.71l-16.8,44.07s-4.32,20.52,0,25.92c0,0,15.12,37.8,0,50.76,0,0-7.56,10.8-6.48,19.44,0,0,18,12.37,8.78,21a13.24,13.24,0,0,1-2.3,1.72s10.8,23.72-15.12,25.88c0,0-51.51-2.56-48.27-19.84,0,0,18.23-17.25,18.72-16.17l-8.25-1.79s23.76-15.12,25.92-29.16c0,0-10.8-46.44-3.24-54,0,0-1.08-54-6.48-68,0,0-18.36-18.36-18.36-30.24C541.24,549.88,509.2,489.4,492.28,485.08Z" />
        <path className="cls-4" d="M607.45,587.76s-12.84,25.68-16.68,37.68c0,0-.34,3.76-1.38,9.7a4.25,4.25,0,0,1-4.18,3.5h0a.33.33,0,0,1-.33-.32c-.14-2.74-1.06-22.48.82-24.93a4.88,4.88,0,0,0,1-1.86c1.28-5.65,4.47-29.89,11.92-27.61A35.61,35.61,0,0,1,607.45,587.76Z" />
        <path className="cls-4" d="M645.46,642s13.67-61.84,21.35-75.28c0,0,2.4-15.36,0-20.64l2.4,5.28s-14.11,3.47-15.69,10.62C653.52,561.94,643.79,631.33,645.46,642Z" />
        <path className="cls-4" d="M579.19,186.48h0c8.91,24.16,28.9,77.41-1,123.75,0,0-24.7,58.17-92.74,44.13,0,0-30.61-1.86-56.34-44.13,0,0-13.86-33.16-9.54-66.34,0,0-15.95,28.44,4.38,86,0,0,28.23,67.31,40,79.75,8.45,8.9,14.41-15.85,28.32-13.44,2.82.48,12.58-4.76,15.6-4.8,0,0,46.5-15.2,75.84-50.4C583.69,341,649.39,355.24,579.19,186.48Z" />
        <path className="cls-2" d="M711.79,434.92c15.08,86.82-47,119.89-47,119.89-64.51,57.47-118.53,19.94-118.53,19.94-75.47-17.6-64.85,23.07-64.85,23.07,3.52,23.85,28.54,6.64,28.54,6.64,8.09-4.22-3.33-14.46-3.33-14.46,23.06,3.26,7.46,18.4,7.46,18.4-38.17,30.24-39.47-15.14-39.47-15.14-3-59,84.61-26.72,84.61-26.72,23.26,7.78,57-17.67,76.07-38.75-38.69,26.26-141.76,22.32-141.76,22.32-30.5-2.34-66.86,34-66.86,34C388,624,440.76,649.82,440.76,649.82h27L524,651c37.54,2.35,45.75,34,45.75,34,10.56,44.58-54,12.91-54,12.91-36.36-14.08-71.56,15.24-71.56,15.24-10.82,13.08,30.05,10.56,30.05,10.56-30.79,22.44-45.29,0-45.29,0-16.43-37.54,71.55-31.67,71.55-31.67,45.75,5.87,28.15-21.62,28.15-21.62-18.48-22.67-54.41-13.57-54.41-13.57-44.95,19.18-54.68,8.21-54.68,8.21-51.61-8.21-41.06-68-41.06-68-8.21-14.07,7-44.57,7-44.57C380.19,565.79,373,562.1,373,562.1c11.34-25.42,12-20.11,16.76-27.87,0,0,30.27-49.21,34.14-69.1,0,0,8.3-70.49,112.27-86.91,0,0,47.49-2,43.52-89.21-9.16,34-25.37,36.34-25.37,36.34C585.53,314.89,562,183,561.59,181.8c19.48,9.22,8.95-15.45,20.95-15.45,4.79,10.72,34.17,22.47,34.17,22.47,27.7,13,38.44,31.53,52.07,46.51,49.18,54.09,53.5,98.29,53.5,98.29,12.91,89.68-7.55,90.76-7.55,90.76S696.44,346.54,711.79,434.92Z" />
        <path className="cls-3" d="M280.05,193.12s18.16,50.77-36.06,61.59c0,0-9.07,31.81-18.87,32.64,0,0-4.68,8.47-4.38,12,0,0,6.25,10.24-8.68,19.58,0,0-10.1,8-14.26,1.2,0,0-10,9.36-24.3-6,0,0-20.54,1.27-15.65-20.68,0,0-2.81-33.43,2-45.51C162.89,245.27,270.93,171.48,280.05,193.12Z" />
        <path className="cls-3" d="M150.69,98.4s2.82,10.34,5.79,13.1c5.55,5.12,21.85,6,27.19,25.32,0,0-3.19,5.2-12.13,4-7-1-19.23-6.89-24.78-15.63C146.76,125.16,136.36,107,150.69,98.4Z" />
        <path className="cls-6" d="M203.6,75.32s6,33.14-5.36,39.53l-3.8,19.34,6.24,8s-.72-3.58,8.58-4.36c0,0,20.43-.55,16.11-23.06C225.37,114.74,213.94,82.05,203.6,75.32Z" />
        <path className="cls-10" d="M46.65,105.91c-.33-.28,12.61,3.63,13.57,5.6C60.22,111.51,57.07,114.72,46.65,105.91Z" />
        <path className="cls-10" d="M57.67,112.1s4.45-2.07,7.07,1.43C64.74,113.53,61.9,116.6,57.67,112.1Z" />
        <path className="cls-10" d="M62.2,114.21c-.26-.28,4.2-1.83,7.44,1C69.64,115.17,67.85,120.34,62.2,114.21Z" />
        <path className="cls-10" d="M66.11,116.89s2.11-.73,2.27-1.28a4.1,4.1,0,0,1,5.4,1.23s-.76,5-3.49,2.2C70.29,119,65.8,118,66.11,116.89Z" />
        <path className="cls-10" d="M70.29,119A1.57,1.57,0,0,0,72,117.78s3.91-3.39,7.76,1.45c0,0-1.89,5.16-4.6,2.45C75.13,121.68,71,121.11,70.29,119Z" />
        <path className="cls-10" d="M75.12,121.79c-.09-.32,1.79.14,3.35-2a6,6,0,0,1,7.52,1s-2.35,5.93-6.06,3.9C79.93,124.72,75.74,124.05,75.12,121.79Z" />
        <path className="cls-10" d="M79.87,124.7c-.09-.25,2.27-.52,2.85-2.14,0,0,3.61-3.89,7.9.22,0,0-2.4,6.45-6.58,4.19C84,127,80.77,127.16,79.87,124.7Z" />
        <path className="cls-10" d="M84,127c-.1-.24,3.08-.59,3.88-2.92,0,0,3.45-3.48,7.76.91,0,0-.65,7.15-6.52,4.64C89.16,129.6,85.42,129.93,84,127Z" />
        <path className="cls-10" d="M89.16,129.6a3.24,3.24,0,0,0,3.53-2.11s3.51-6.25,9.6.05c0,0-1.38,7-7.49,4.79A7.28,7.28,0,0,1,89.16,129.6Z" />
        <path className="cls-10" d="M93.3,132s4,.3,4.92-2.82c0,0,4.51-4.5,10.33.66,0,0-2.53,9.23-7.57,6.5C101,136.32,93.92,135.08,93.3,132Z" />
        <path className="cls-10" d="M101,136.32c-.23-.4,3.38.27,4.2-2.16.2-.58,2.33-7.72,9.92-2.73,0,0-2.89,11.17-6.47,7.66C108.63,139.09,103.49,140.49,101,136.32Z" />
        <path className="cls-10" d="M106.52,139.31s2.5.64,4.43-2.77c0,0,2.57-6.69,6.56-4.5,0,0,2.94.89,3.82,2.23,0,0-4.62,10.83-8.18,8.46C113.15,142.73,107.81,143,106.52,139.31Z" />
        <path className="cls-10" d="M113.15,142.73c-.1-.33,2.93-.48,3.69-3.5,0,0,2.53-7.06,7.9-4.63,0,0,5,2.39,6.19,3.67,0,0-7.42,11.52-12.05,8.8C118.88,147.07,114,145.3,113.15,142.73Z" />
        <path className="cls-10" d="M118.88,147.07s3.84-.87,4-3c0,0,.86-5.41,9-5.88,0,0,4.44,0,6.67,2.56,0,0-7.13,11.46-12,9.74C126.51,150.48,125.19,152.7,118.88,147.07Z" />
        <path className="cls-10" d="M126.51,150.48c0-.82,2.77-.36,3.78-4.57,0,0,3.75-6.37,8.25-5.17,0,0,8,.83,7.65,3.22,0,0-7.88,13.86-11.68,11.1C134.51,155.06,126.62,153.64,126.51,150.48Z" />
        <path className="cls-10" d="M134.51,155.06s4.56-1.27,5.32-5.3c0,0,5.17-10.75,13.68-3.47l-1,3.66-6.7,7.74-4.59,1S135.41,158.27,134.51,155.06Z" />
        <path className="cls-10" d="M160.17,149.8s-3.36-8.5-12.24.13c0,0-2.8,2.33-3.08,4.48,0,0-1.19,3.71-3.64,4.25,0,0,6.24,3.83,8.73,2.52Z" />
        <path className="cls-10" d="M166.21,151.53s-7.41-4.81-10.66-1.06a11.63,11.63,0,0,0-4,7.8,4.07,4.07,0,0,1-4.77,2.71S155.4,176.75,166.21,151.53Z" />
        <path className="cls-10" d="M166.21,151.53s-3,7.05-3.25,12.82c0,0-.79,10.55-11.08,1.42a4.22,4.22,0,0,0,5.75-3.46S162.78,148.48,166.21,151.53Z" />
        <path className="cls-2" d="M166.29,144.17s-33.38,17.91-34,50c0,0,0,12.08,2.82,20.81a14.38,14.38,0,0,0,1.71,3.5c3.38,5.09,14.39,22.62,10.35,28.82,0,0-4.66,7.6-11.46-2.13,0,0-3.54-4.11.91-6.28,0,0-10.24-3,.79,14.89,0,0,17,12.23,19.59-9.11,0,0-.19-19.75-4.71-32.54a16.67,16.67,0,0,1-.56-9.28c2.72-11.9,13.11-44.18,25.47-56.78,0,0,29.9-8.73,20.18-26.63C197.34,119.43,167.53,113.53,166.29,144.17Z" />
        <path className="cls-11" d="M622,690.28s11.83-14,21.93-3.24c0,0,19.35,17.22,8.68,40.81,0,0-3.33,16.13-18.25,15.36,0,0-8.62,2.88-16-20.74C618.39,722.47,611.31,697.25,622,690.28Z" />
        <path className="cls-6" d="M648.42,696.8s8.94,14.89,2,32.32c0,0-6.22,14-14.63,9.91,0,0-17.1-5.84-10.14-32.79a19.89,19.89,0,0,1,1.81-8.09s4.46-1.35,5.5,4.49c0,0,6.07,15.67,8.35,8.54,0,0,3.49-11.67,4.28-13C645.62,698.16,645.81,695.52,648.42,696.8Z" />
        <path className="cls-11" d="M551.65,733.11l-9,5.37s-9.78,7.55-9.84,11.9c-.08,5.76,6.23,13.74,21.54,15.55,0,0,46.5,13.66,44.18-13.09l-.9-10.12s-7.28-4.06-8.36,3.32c0,0-.72-6.3-3.06-5.4,0,0-2.52-.72-2.34,1.62,0,0-.72-2.1-1.8-2.49,0,0-2.81-.47-4.86,6.15-.21.69-.77-7.37-1.59-7.59-1.28-.34-5.59,2.91-5.43,1.44.36-3.27-1.61-3.71-3-4.35-3.19-1.44-6.34,4-6.34,2.7,0-3.57-2.62-3.62-3.57-2.77,0,0-4,1.33-6.69,6.55C550,743.08,549.35,732.88,551.65,733.11Z" />
        <path className="cls-3" d="M203.6,75.32S210.5,95,204.8,101c0,0-2.28,17.53-.48,19.86,0,0,2.55,20.67-3.64,21.33,0,0-13.11-9-2.81-25.43C197.87,116.73,202.78,83.34,203.6,75.32Z" />
        <path className="cls-4" d="M178.22,308.09s-15.36-10.56,0-19.2c0,0,13.92-8.64,16.8,9.6C195,298.49,196.94,316.25,178.22,308.09Z" />
        <path className="cls-3" d="M209.26,137.8a14,14,0,0,0,11.68-11.23s3.47-15.57-3.25-23.19c0,0-9.93-24.49-14.09-28.06,0,0,14.08,11.36,21.77,39.42C225.37,114.74,231.06,143.84,209.26,137.8Z" />
        <path className="cls-12" d="M182.84,289s7.83,1.18,6.16,8.27c0,0-3.33,6.62-7.82,5.2,0,0-.24,4-2.84,3.55,0,0-5.67-1.18-4.25-5.91C174.09,300.12,175,288.77,182.84,289Z" />
        <path className="cls-4" d="M244,254.71s-9.32,1.55-16.51-2.72l-12.66,35.55a9.12,9.12,0,0,0,6.12,8.66c.86.35.14-2,4.18-8.85C225.12,287.35,234,288.43,244,254.71Z" />
        <path className="cls-4" d="M163.39,311.76s-8.81-3.84-5.54-18.31c0,0,1.52-2.85,9.2,8C167.05,301.48,181.47,320.63,163.39,311.76Z" />
        <path className="cls-12" d="M158.67,298.09c2.38-1.76,8.05,9.21,8.84,11.82.48,1.61-2.15,2.54-3.33,2.32,0,0-5.65-2.87-6.61-8.62C157.57,303.61,155.76,300.24,158.67,298.09Z" />
        <path className="cls-13" d="M196.43,192.3c-3.53,2.9-8.25,3-10.55.21s-.68-6.54,2.85-9.45,7.64-3.86,9.93-1.07S200,189.39,196.43,192.3Z" />
        <circle className="cls-14" cx="193.71" cy="183.48" r="1.15" />
        <path className="cls-15" d="M220.74,299.39s4.69,7.27-3.69,15.89c0,0-13.85,13.19-19.25,4.89,0,0-3.48,3.32-10.11,2.89a25.67,25.67,0,0,1-14.19-8.93c-.73-1-1.67.19-2.11-.05l1.37-1.82c7,0,4.21-4.49,6.44-3.76,5.25,1.73,5.25,1.73,10,1.73a10.16,10.16,0,0,0,5.66-7.88c.26-1.62,1.41-5.4,3.14-4.45,14.86,8.17,14.81-9.18,16.79-10.36,0,0,0,5.57,6.12,8.66C221.55,296.51,220.42,298.75,220.74,299.39Z" />
        <path className="cls-16" d="M197,244.61c-7.78,24.15-8.65,24.15-9,34.38a4.18,4.18,0,0,0,5-.16s5.67-28.2,5.51-33.42C198.48,245.41,197.59,242.62,197,244.61Z" />
        <circle className="cls-17" cx="272.96" cy="206.53" r="2.02" />
        <circle className="cls-17" cx="278.5" cy="216.51" r="3.51" />
        <circle className="cls-17" cx="273.5" cy="222.59" r="2.56" />
        <circle className="cls-17" cx="274.51" cy="231.47" r="1.55" />
        <circle className="cls-17" cx="466.6" cy="154.25" r="2.97" />
        <circle className="cls-17" cx="476.15" cy="151.28" r="2.92" />
        <circle className="cls-16" cx="488.18" cy="142.84" r="1.24" />
        <circle className="cls-17" cx="495.94" cy="138.09" r="1.53" />
        <circle className="cls-17" cx="489.69" cy="143.49" r="2.22" />
        <circle className="cls-17" cx="497.73" cy="146.92" r="3.32" />
        <circle className="cls-17" cx="506.6" cy="138.79" r="2.46" />
        <circle className="cls-17" cx="510.65" cy="129.78" r="1.95" />
        <path className="cls-4" d="M701.05,270.84s3.24-69.48-60.9-125.18c-1.39-1.21,26.34,24.5,49,83.29a3.35,3.35,0,0,1-2,4.36c-3.3,1.19-9.41,1.26-18.59-6.75C668.57,226.56,701.05,264.6,701.05,270.84Z" />
        <path className="cls-4" d="M600.25,689.16s-.24,6.12-4.26,9.36c0,0-9.06,12.24-8.7,21.24,0,0-1.92-2.88-2.76,0s-1.92,0-1.92,0,4.26-18.36,8.82-19.8c0,0,6.57-3.9,8.82-14.76Z" />
        <path className="cls-4" d="M646.62,671.06a1.58,1.58,0,0,1,1.43-1.48c.41,0,.78,0,.86.14.82,1.59,1.9,6.59-2.71,12.19a1.57,1.57,0,0,1-2.32.16c-.17-.19-.27-.37-.19-.53A24.27,24.27,0,0,0,646.62,671.06Z" />
        <path className="cls-6" d="M198,177.24,184.14,190l1.57-4.35s.64-1.46,7.65-6.74A10.56,10.56,0,0,1,198,177.24Z" />
        <path className="cls-18" d="M165.11,327.08s-1.23-2-.73-3.56a1.78,1.78,0,0,1,.14-.36,1.51,1.51,0,0,1,.24-.34,1.54,1.54,0,0,1,.17-.19,2.11,2.11,0,0,1,.23-.19h0a1.37,1.37,0,0,1,.2-.16l.15-.12.13-.1.26-.26.18-.2a1.8,1.8,0,0,0,.21-.33h0a.63.63,0,0,0,0-.61,1,1,0,0,0-.78-.47s-1.55-.34-3,1.53c0,0-3,2.19-4.64-.7,0,0-.81-1.15,1-1.81,0,0,.44-.18.41-.5h0c0-.38-.43-.7-2.05-.28a4.42,4.42,0,0,1-3.38-1s-2.25-1.82-6.61-.44c0,0-4,1.08-3.14,4.56,0,0,.2.68.38.78-.13-.06-2.1-1-4.77.84,0,0-5.69,4.33-.43,8a3.73,3.73,0,0,0,2.62.57A6.26,6.26,0,0,0,139.7,338a8.05,8.05,0,0,0,.69,1.74,5.38,5.38,0,0,0,3.47,2.44,7.92,7.92,0,0,0,2.4.14,5.06,5.06,0,0,0,1.28-.2L148,342a3.05,3.05,0,0,0,.61-.34l.18-.15h0a.18.18,0,0,1,0,.06s0,0,0,.06-.06.19-.1.32a.17.17,0,0,1,0,.07.13.13,0,0,0,0,.06.07.07,0,0,0,0,.05.08.08,0,0,0,0,.05.09.09,0,0,0,0,0l0,.21a.08.08,0,0,0,0,0,3.62,3.62,0,0,0,.12,1.6,2.17,2.17,0,0,0,.15.35.6.6,0,0,0,.09.15l.14.22a2.2,2.2,0,0,0,.2.23l.19.16a1.94,1.94,0,0,0,.33.23,3.55,3.55,0,0,0,.39.2h0a1.57,1.57,0,0,0,.3.12l.32.1h.08l.08,0a14.58,14.58,0,0,0,5.23.45l.35-.05a7.29,7.29,0,0,0,5.78-5.3s.74-3.51-1.54-5h.1c.54.14,3.79.9,5.62-1.06a1.54,1.54,0,0,0,.17-.19,4.46,4.46,0,0,0,.44-.66,5.54,5.54,0,0,0,.42-1.13S168.79,329,165.11,327.08Z" />
        <path className="cls-19" d="M167.47,333c-1.18,4.68-6.74,3-6.74,3,2.28,1.44,1.53,4.94,1.53,4.94-2.67,8-11.51,4.86-11.51,4.86-3.42-.87-2-4.33-2-4.33a4.33,4.33,0,0,1-2.49.83,7.61,7.61,0,0,1-2.4-.14h1.87l2.07-1.76c-1.84.25-3.69-1.7-3.69-1.7-3.1-2.59-.7-6.48-.7-6.48,1.27-.53.44-1,.44-1-2.57-.21-3-3.77-3-3.77,0-3.52,3.5-3.29,4.53-3a4,4,0,0,0,.33,3.06s1.23-5.73,5.43-3.59c0,0-1.92,2-.39,3.85,0,0,1.09-6.34,7.18-2.05,0,0,.31-4.38-5.52-3.07a4.61,4.61,0,0,0-4.67-.73c-.17-1.65,1.91-2.89,1.91-2.89,1.54-1.12,4.82-.14,4.82-.14a4.58,4.58,0,0,0,3.77,0c.2-.33.72-.3,1-.24,0,.32-.41.51-.41.51-1.8.65-1,1.8-1,1.8,1.62,2.89,4.64.7,4.64.7,1.47-1.87,3-1.53,3-1.53a1,1,0,0,1,.78.46c.45.74-1.18,1.81-1.18,1.81-2,1.45,0,4.64,0,4.64C168.79,329,167.47,333,167.47,333Z" />
        <path className="cls-18" d="M167.49,333c-1.19,4.68-6.75,3-6.75,3,2.28,1.44,1.54,4.94,1.54,4.94-2,7.91-11.52,4.86-11.52,4.86-3.41-.87-2-4.33-2-4.33a4.37,4.37,0,0,1-2.5.83,7.61,7.61,0,0,1-2.4-.14l1.8-.13,2.14-1.63h0a7.6,7.6,0,0,0,1.57-1.48s1.31,1.09,2.4.52c-.09.16-3.22,5.72,3.6,5.25,0,0,7.18-1,3.59-6.65,0,0-.57-1-1.4-.77a4.53,4.53,0,0,1-3.72-.67,3.39,3.39,0,0,1-4.47-.18c3.15.74,4.25-1,4.25-1,2.89,1.49,4.07-.44,4.07-.44,1.14-.91,1-4.46,1-4.46a5.43,5.43,0,0,1,1.43,3.61h0a2.4,2.4,0,0,0,3.08.23s2.5-1.79,1.79-3.85c0,0-2.46-5.53-1.05-7.71,0,0,1.48-2.45,2.4-2.19.46.74-1.17,1.81-1.17,1.81-2,1.45,0,4.64,0,4.64C168.8,329,167.49,333,167.49,333Z" />
        <path className="cls-3" d="M159.29,318.67a1.36,1.36,0,0,0-1,.24,4.6,4.6,0,0,1-3.77,0s-3.28-1-4.81.14c0,0-2.09,1.24-1.92,2.89a4.61,4.61,0,0,1,4.67.73c5.83-1.31,5.52,3.07,5.52,3.07-6.08-4.29-7.18,2.05-7.18,2.05-1.53-1.83.4-3.85.4-3.85-4.21-2.14-5.43,3.59-5.43,3.59a4,4,0,0,1-.34-3.06c-1-.32-4.58-.55-4.52,3,0,0,.33,3.38,3,3.77,0,0,.84.48-.43,1,0,0-2.41,3.89.7,6.48a6.59,6.59,0,0,0,3.68,1.7l-1.44,1.22a2,2,0,0,1-1.44.54h-1.06a5.38,5.38,0,0,1-3.47-2.44,8,8,0,0,1-.68-1.74,6.27,6.27,0,0,1,2.21-6.27,3.75,3.75,0,0,1-2.62-.57c-5.26-3.64.44-8,.44-8,2.66-1.82,4.64-.9,4.76-.84-.17-.1-.38-.78-.38-.78-.83-3.47,3.15-4.56,3.15-4.56,4.35-1.38,6.61.44,6.61.44a4.43,4.43,0,0,0,3.37,1C158.86,318,159.26,318.29,159.29,318.67Z" />
        <path className="cls-18" d="M192.79,375.1a.62.62,0,0,1-.44.39,3,3,0,0,1-2.06,0c1.93-3-.62-6-.62-6,.71,5.8-3.22,7.32-4.86,7.7a3.77,3.77,0,0,1-.68.11,2.34,2.34,0,0,0,.33.09,5.7,5.7,0,0,0,5-.92.29.29,0,0,1,.28-.05,1.69,1.69,0,0,1,1.22,1.74.2.2,0,0,1,0,.11c-.32.55-3.57,5.74-10.09,1.75a10.72,10.72,0,0,1-2.18-1.61.26.26,0,0,0-.4,0,1.06,1.06,0,0,1-1.59.15,4.33,4.33,0,0,1-2.36,2.76,7.62,7.62,0,0,1-3.82.6l.07.15a5.82,5.82,0,0,1-4.37-6.52c.24-2.19-.84-1.45-.84-1.45-3.39,2.06-6.81-1.1-6.91-1.18h0c-6.51-6.9-2.24-16.66-2.24-16.66a9.61,9.61,0,0,1,4-3.84,23.4,23.4,0,0,1,4.72-1.33,4.86,4.86,0,0,0,2.65-1.49,11.82,11.82,0,0,0,3.45-7.88,18.73,18.73,0,0,1,.79-3.93,5.14,5.14,0,0,1,.66-1.16,2.94,2.94,0,0,1,1.25-.43h0a1.88,1.88,0,0,1,1,.37,1.14,1.14,0,0,1,.21,1.75,11.76,11.76,0,0,0-1.3,2.59h0c0,.06-2.24,7.95,0,9,0,0,1.86.91,2.43-.13h0c1.91-3.9,2.56-26.36,7.28-24.43-.15,0-1.17-.22-1.24,1.54,0,0-4.18,22.72-.35,25.12a4.73,4.73,0,0,0,3.59.88,6.85,6.85,0,0,1,2.6,0s4.75,1.73,3.75,5.95h0c0,.12-.92,2.74-5.62,4.61a.25.25,0,0,0,0,.49c1.36.28,3.89.91,4.49,1.75,0,0,2.69,2,2.69,4.55A10.53,10.53,0,0,1,192.79,375.1Z" />
        <path className="cls-18" d="M170.57,357.57s-6.17-.63-6.63,4.95c0,0-2.64-2.54.92-5.31A4.48,4.48,0,0,1,170.57,357.57Z" />
        <path className="cls-18" d="M171.7,355.71S169,357,170.27,361c0,0,.26-5.18,7.31-5C177.58,356,175.74,353.45,171.7,355.71Z" />
        <path className="cls-18" d="M179,377.56s5.45,1.47,6.88-1.56a5.55,5.55,0,0,0,.57-3.79S185.46,377.23,179,377.56Z" />
        <path className="cls-18" d="M188.54,382.77c-5.31,1.77-10.13-2.75-10.13-2.75a5.09,5.09,0,0,1-3.6,2.24,10.29,10.29,0,0,1-2.3.14,9.53,9.53,0,0,1-2-.36l-.07-.15a7.05,7.05,0,0,0,3.82-.6,4.36,4.36,0,0,0,2.36-2.76c.88.82,1.73-.28,1.88-.49-.25.49,2.29,2,2.29,2,6.88,4.21,10.11-1.8,10.11-1.8a1.72,1.72,0,0,0-1.36-1.85,5.73,5.73,0,0,1-5.12,1,2.34,2.34,0,0,1-.33-.09,3.77,3.77,0,0,0,.68-.11c1.64-.38,5.57-1.9,4.86-7.7,0,0,2.55,3,.62,6a3.5,3.5,0,0,0,2.35-.09,10.79,10.79,0,0,0,.59-5.23c-.2-2.55-2.69-4.55-2.69-4.55-1.37-1.12-5-1.84-5.34-1.91,0,0,0,0,0,0,5.46-1.86,6.42-4.84,6.45-4.92,1-4.22-3.75-6-3.75-6a6.74,6.74,0,0,0-2.6,0,4.73,4.73,0,0,1-3.59-.88c-3.83-2.4.35-25.12.35-25.12.07-1.76,1.09-1.57,1.24-1.54v0a2.63,2.63,0,0,1,.43.55s.57,1.37-.36,4c0,0-1.84,11.75-1.19,18,.45,3.22,3.62,2.16,3.62,2.16a7,7,0,0,1,8.21,4c2.84,5.66-4.07,9-4.83,9.34,0,0,0,0,0,0,3.86-.51,6.23,3.68,6.28,3.77h0C201,378.11,188.54,382.77,188.54,382.77Z" />
        <path className="cls-18" d="M175,380.86l-.68,1.47a9,9,0,0,1-3.81-.29,5.82,5.82,0,0,1-4.37-6.52c.27-2.15-.84-1.45-.84-1.45-3.53,2.18-6.91-1.18-6.91-1.18-6.51-6.91-2.24-16.67-2.24-16.67a9.61,9.61,0,0,1,4-3.84A23.32,23.32,0,0,1,165,351a4.68,4.68,0,0,0,2.51-1.41,11.93,11.93,0,0,0,3.51-7.95,18.73,18.73,0,0,1,.79-3.93,5.14,5.14,0,0,1,.66-1.16,1.34,1.34,0,0,1,1.25-.43h0c.12.08,1,.65,0,2a7.52,7.52,0,0,0-1.79,4.67s.11,5.77-1.6,9.16c0,0-1.08,3.06-6.07,3.52a3,3,0,0,0,1.21,1.33,2.34,2.34,0,0,0,1.64.22l-2.9,2.14A9.88,9.88,0,0,0,164,358a2.88,2.88,0,0,0-1-1.93s-4,1.69-4.53,4.72c0,0-2,9.08,5.19,10.24,0,0,4.35.86,6.66.16,0,0-5.4,8.6,2.91,10.22A2.57,2.57,0,0,0,175,380.86Z" />
        <path className="cls-19" d="M192.79,375a.63.63,0,0,1-.44.4,3.16,3.16,0,0,1-2.06,0c1.93-3-.62-6-.62-6,.72,5.8-3.22,7.32-4.86,7.7a5.36,5.36,0,0,1-.68.11l.33.09a5.72,5.72,0,0,0,5-.93.26.26,0,0,1,.27,0,1.68,1.68,0,0,1,1.22,1.74.23.23,0,0,1,0,.11c-.32.55-3.57,5.73-10.09,1.75a10.72,10.72,0,0,1-2.18-1.61.25.25,0,0,0-.4,0,1.07,1.07,0,0,1-1.59.16,4.33,4.33,0,0,1-2.36,2.76,7.6,7.6,0,0,1-3.82.6l.07.15a5.82,5.82,0,0,1-4.37-6.53c.24-2.18-.84-1.44-.84-1.44-3.39,2.05-6.81-1.1-6.91-1.18h0c-6.51-6.9-2.24-16.66-2.24-16.66a9.61,9.61,0,0,1,4-3.84,23.4,23.4,0,0,1,4.72-1.33,4.93,4.93,0,0,0,2.65-1.49,11.84,11.84,0,0,0,3.45-7.88,18.3,18.3,0,0,1,.8-3.93,4.92,4.92,0,0,1,.65-1.17,3.1,3.1,0,0,1,1.25-.43h0a2,2,0,0,1,1,.38,1.15,1.15,0,0,1,.22,1.75,11.53,11.53,0,0,0-1.31,2.59h0c0,.05-2.24,7.95,0,9,0,0,1.86.91,2.43-.13h0c1.91-3.9,2.57-26.36,7.28-24.43-.15,0-1.17-.22-1.24,1.54,0,0-4.18,22.72-.34,25.11a4.67,4.67,0,0,0,3.58.89,6.65,6.65,0,0,1,2.6-.05s4.75,1.73,3.75,5.95h0c0,.12-.93,2.74-5.63,4.61a.26.26,0,0,0,0,.49c1.36.28,3.89.9,4.49,1.75,0,0,2.69,2,2.7,4.54A10.61,10.61,0,0,1,192.79,375Z" />
        <path className="cls-3" d="M170.57,357.52s-6.17-.64-6.63,5c0,0-2.64-2.54.92-5.31A4.48,4.48,0,0,1,170.57,357.52Z" />
        <path className="cls-3" d="M171.7,355.66s-2.72,1.26-1.43,5.32c0,0,.26-5.18,7.31-5.05C177.58,355.93,175.74,353.4,171.7,355.66Z" />
        <path className="cls-18" d="M179,377.51s5.45,1.47,6.88-1.56a5.57,5.57,0,0,0,.57-3.79S185.47,377.18,179,377.51Z" />
        <path className="cls-18" d="M188.54,382.72c-5.31,1.77-10.13-2.76-10.13-2.76a5.06,5.06,0,0,1-3.6,2.24,10,10,0,0,1-2.3.15,9.53,9.53,0,0,1-2-.36l-.07-.15a7.05,7.05,0,0,0,3.82-.6,4.38,4.38,0,0,0,2.36-2.76c.88.82,1.73-.28,1.88-.49-.25.49,2.29,2,2.29,2,6.88,4.2,10.11-1.8,10.11-1.8a1.74,1.74,0,0,0-1.36-1.86,5.68,5.68,0,0,1-5.12,1l-.33-.09a5.36,5.36,0,0,0,.68-.11c1.64-.38,5.58-1.9,4.86-7.7,0,0,2.55,3,.62,6a3.47,3.47,0,0,0,2.35-.1,10.86,10.86,0,0,0,.6-5.23c-.21-2.54-2.7-4.54-2.7-4.54-1.37-1.12-5-1.85-5.34-1.92h0c5.46-1.87,6.42-4.84,6.45-4.92,1-4.23-3.75-6-3.75-6a6.74,6.74,0,0,0-2.6.05,4.67,4.67,0,0,1-3.58-.89c-3.84-2.39.34-25.11.34-25.11.07-1.76,1.09-1.57,1.24-1.54h0a3.12,3.12,0,0,1,.42.56s.57,1.37-.36,4c0,0-1.84,11.75-1.19,18,.45,3.22,3.62,2.16,3.62,2.16a7,7,0,0,1,8.21,4c2.84,5.66-4.07,9-4.83,9.35,0,0,0,0,0,0,3.86-.51,6.23,3.68,6.28,3.77h0C201,378.05,188.54,382.72,188.54,382.72Z" />
        <path className="cls-3" d="M175,380.8l-.68,1.48a9,9,0,0,1-3.81-.29,5.82,5.82,0,0,1-4.37-6.53c.27-2.14-.84-1.44-.84-1.44-3.52,2.18-6.91-1.18-6.91-1.18-6.51-6.91-2.24-16.67-2.24-16.67a9.61,9.61,0,0,1,4-3.84A23.93,23.93,0,0,1,165,351a4.6,4.6,0,0,0,2.51-1.4,11.88,11.88,0,0,0,3.51-7.95,18.3,18.3,0,0,1,.8-3.93,4.92,4.92,0,0,1,.65-1.17,1.36,1.36,0,0,1,1.25-.43h0c.12.08,1,.65,0,2a7.48,7.48,0,0,0-1.79,4.67s.12,5.77-1.6,9.15c0,0-1.08,3.07-6.07,3.53a3,3,0,0,0,1.21,1.33,2.34,2.34,0,0,0,1.64.22l-2.9,2.14a9.88,9.88,0,0,0-.14-1.25,2.84,2.84,0,0,0-1-1.93s-4,1.68-4.53,4.71c0,0-2,9.09,5.2,10.25,0,0,4.35.86,6.65.16,0,0-5.4,8.59,2.91,10.22A2.55,2.55,0,0,0,175,380.8Z" />
      </g>
    </g>
  </svg>
);

export const Link = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.35" height="8.375" viewBox="0 0 19.35 8.375">
      <g transform="translate(-262.566 -627.123)">
        <path style={{ fill: '#00a0ad' }} d="M354.063,635.39a3.409,3.409,0,0,1-1.879.337c-.665-.016-1.332,0-2,0-.538,0-.835-.234-.837-.651s.3-.667.822-.668c.817,0,1.634-.021,2.449.009a1.111,1.111,0,0,0,1.061-.559,4.829,4.829,0,0,0-.1-4.725.986.986,0,0,0-.858-.435c-2.512.011-5.025.02-7.537-.006a1.136,1.136,0,0,0-1.111.666,4.815,4.815,0,0,0-.327,3.615.723.723,0,0,1-.22.876.635.635,0,0,1-.983-.33,5.733,5.733,0,0,1,.776-5.355,1.935,1.935,0,0,1,1.6-.78c2.651,0,5.3.006,7.952-.007a4.339,4.339,0,0,1,1.137.282l.64.64c-.029.177.143.243.212.366a6.173,6.173,0,0,1,.65,3.8,6.43,6.43,0,0,1-.809,2.283Z" transform="translate(-73.65 -0.233)" />
        <path style={{ fill: '#00a0ad' }} d="M264.145,627.423c1.32-.519,2.7-.193,4.047-.262a.625.625,0,0,1,.6.644.641.641,0,0,1-.677.656c-.779.014-1.559.006-2.338.007-.993,0-1.257.153-1.578,1.1a4.68,4.68,0,0,0,.364,4.19.854.854,0,0,0,.785.422q3.884-.008,7.769,0a1.025,1.025,0,0,0,.936-.639,4.786,4.786,0,0,0,.371-3.54c-.023-.1-.059-.192-.076-.291a.639.639,0,0,1,.447-.766.62.62,0,0,1,.792.392,5.69,5.69,0,0,1-.826,5.446,1.878,1.878,0,0,1-1.518.711c-2.677,0-5.355,0-8.033,0a2.608,2.608,0,0,1-1.175-.387l-.693-.693c.06-.1-.032-.166-.068-.234a6.128,6.128,0,0,1-.291-5.109c.109-.309.273-.6.412-.9Z" />
      </g>
    </svg>
  );
};

export const FacebookIcon = ({ width = 30, height = 30, color = '#475993' }) => (
  <svg
    id="FacebookIcon"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 30 30"
    x="0px"
    y="0px"
  >
    <path fill={color} d="M22.426 0H3.494A3.492 3.492 0 0 0 0 3.492v18.932a3.492 3.492 0 0 0 3.492 3.492h9.337l.016-9.261h-2.404a.568.568 0 0 1-.568-.566L9.862 13.1a.568.568 0 0 1 .568-.57h2.4V9.65c0-3.347 2.044-5.17 5.031-5.17h2.45a.568.568 0 0 1 .568.568v2.517a.568.568 0 0 1-.567.568h-1.5c-1.624 0-1.938.772-1.938 1.9v2.5h3.568a.568.568 0 0 1 .564.635l-.354 2.985a.568.568 0 0 1-.564.5h-3.2l-.016 9.261h5.556a3.492 3.492 0 0 0 3.492-3.492V3.492A3.492 3.492 0 0 0 22.426 0z" transform="translate(-.002)" />
  </svg>
);

export const FacebookIconCircle = ({ className }) => (
  <svg className={className} x="0" y="0" width="18" height="18" viewBox="0, 0, 1024, 1024">
    <defs>
      <clipPath id="Clip_1">
        <path d="M0.06,0.04 L1024,0.04 L1024,1024 L0.06,1024 z" />
      </clipPath>
    </defs>
    <g id="Layer_1">
      <g clipPath="url(#Clip_1)">
        <path d="M1024,512 C1024,229.23 794.77,-0 512,-0 C229.23,-0 0,229.23 0,512 C0,767.554 187.231,979.37 432,1017.78 L432,660 L302,660 L302,512 L432,512 L432,399.2 C432,270.88 508.438,200 625.39,200 C681.407,200 740,210 740,210 L740,336 L675.438,336 C611.835,336 592,375.467 592,415.957 L592,512 L734,512 L711.3,660 L592,660 L592,1017.78 C836.769,979.37 1024,767.554 1024,512" fill="#188EF5" />
      </g>
      <path d="M711.3,660 L734,512 L592,512 L592,415.957 C592,375.467 611.835,336 675.438,336 L740,336 L740,210 C740,210 681.407,200 625.39,200 C508.438,200 432,270.88 432,399.2 L432,512 L302,512 L302,660 L432,660 L432,1017.78 C458.067,1021.87 484.784,1024 512,1024 C539.216,1024 565.933,1021.87 592,1017.78 L592,660 L711.3,660" fill="#FFFFFF" />
    </g>
  </svg>
);

export const InstagramIcon = ({ width = 30, height = 30 }) => (
  <svg
    id="InstagramIcon"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 30 30"
    x="0px"
    y="0px"
  >
    <defs>
      <linearGradient id="linear-gradient" x1=".5" x2=".5" y1=".008" y2=".998" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#e09b3d" />
        <stop offset=".3" stopColor="#c74c4d" />
        <stop offset=".6" stopColor="#c21975" />
        <stop offset="1" stopColor="#7024c4" />
      </linearGradient>
      <linearGradient id="linear-gradient-2" y1="-.451" y2="1.462" xlinkHref="#linear-gradient" />
      <linearGradient id="linear-gradient-3" y1="-1.396" y2="6.586" xlinkHref="#linear-gradient" />
    </defs>
    <path
      d="M18.2 0H7.721A7.729 7.729 0 0 0 0 7.721V18.2a7.729 7.729 0 0 0 7.721 7.721H18.2a7.729 7.729 0 0 0 7.717-7.721V7.721A7.73 7.73 0 0 0 18.2 0zm5.11 18.2a5.114 5.114 0 0 1-5.11 5.11H7.721a5.114 5.114 0 0 1-5.114-5.11V7.721a5.114 5.114 0 0 1 5.114-5.114H18.2a5.114 5.114 0 0 1 5.11 5.114V18.2z"
      fill="url(#linear-gradient)"
    />
    <path
      d="M139.7 133a6.7 6.7 0 1 0 6.7 6.7 6.711 6.711 0 0 0-6.7-6.7zm0 10.8a4.1 4.1 0 1 1 4.1-4.1 4.1 4.1 0 0 1-4.1 4.1z"
      transform="translate(-126.745 -126.745)"
      fill="url(#linear-gradient-2)"
    />
    <circle
      cx="1.606"
      cy="1.606"
      r="1.606"
      transform="translate(18.068 4.7)"
      fill="url(#linear-gradient-3)"
    />
  </svg>
);
