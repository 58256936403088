import { SET_TAB, RESET_TABS_STATE } from 'store/actions/tabsActions';

const initialState = {};

const tabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAB:
      return {
        ...state,
        [action.key]: action.activeTab,
      };
    case RESET_TABS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default tabsReducer;
