/* eslint-disable max-len */
import React from 'react';

const WrongSms = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 41.2 40.3"
    >
      <path d="M41,14.67a1.33,1.33,0,0,0,1.34-1.33l0-6.16a1.34,1.34,0,1,0-2.67,0l0,6.15A1.33,1.33,0,0,0,41,14.67ZM41,3.38a8.88,8.88,0,0,0-8.87,8.87,8.47,8.47,0,0,0,.08,1.13H11a2.31,2.31,0,0,0-2.32,2.31V34.86A2.32,2.32,0,0,0,11,37.17h3.41v6.51l7.74-6.51h17a2.3,2.3,0,0,0,2.31-2.31V21.1A8.87,8.87,0,0,0,41,3.38ZM17.84,28a2,2,0,1,1,2-2A2,2,0,0,1,17.84,28ZM25,28a2,2,0,1,1,2-2A2,2,0,0,1,25,28Zm7.18,0a2,2,0,1,1,2-2A2,2,0,0,1,32.21,28ZM41,20.12a7.87,7.87,0,1,1,7.87-7.87A7.88,7.88,0,0,1,41,20.12Zm0-4.43a1.48,1.48,0,1,0,1.48,1.48A1.48,1.48,0,0,0,41,15.69Z" transform="translate(-8.63 -3.38)" />
    </svg>
  );
};

export default WrongSms;
/* eslint-enable max-len */
