import { components } from 'react-select';
import { TagLabel } from '..';

// MultiValue container used in select inputs
const MultiValueLabel = ({ children, ...props }: any) => {
  return (
    <components.MultiValueLabel {...props}>
      <TagLabel>{children}</TagLabel>
    </components.MultiValueLabel>
  );
};

export default MultiValueLabel;
