/**
 * @module Button
 */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { getErrorMessage } from 'old/utils';
import SpinnerDots from '@old/components/guide/SpinnerDots';
import Icon from '@old/components/icon';
/**
 * Component that generates button
 * @param  {Function} [props.onClick] specifies what happens after button click
 * @param  {Boolean} [props.disabled] specifies wether the button should be disabled
 * @param  {String|Node} [props.children]
 * @param  {Boolean|'simple'} [props.async] specifies wether the onClick function should be called asynchronously
 * @param  {String} [props.className]
 * @param  {'submit'|'button'|'reset'} [props.type] specifies type of the button
 * @param  {String} [props.tabIndex] {@link https://developer.mozilla.org/pl/docs/Web/HTML/Global_attributes/tabindex}
 * @param  {Boolean} [props.fluid] specifies wether the button should have full width of it's parent
 * @param  {Boolean} props.isMobile
 */
const Button = ({ onClick, disabled, children, async, className, name, type, tabIndex, fluid, isMobile }) => {
  const [loading, setLoading] = useState(false);

  const onButtonClick = async () => {
    if (!loading) {
      setLoading(true);
      try {
        await onClick();
      } catch (e) {
        getErrorMessage(e, { type: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const loadingComponent = ((isMobile || async === 'simple')
    ? <Icon.Spinner className="fill-current rotate" />
    : <SpinnerDots />
  );

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type} // eslint-disable-line react/button-has-type
      name={name}
      tabIndex={tabIndex}
      className={cls('btn', { loading, fluid }, className)}
      disabled={disabled}
      onClick={async ? onButtonClick : onClick}
    >
      {loading ? loadingComponent : children}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  async: false,
  children: null,
  className: '',
  onClick: () => { },
  type: 'button',
  tabIndex: 0,
  fluid: false,
  name: '',
};

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  async: PropTypes.oneOf([true, false, 'simple']),
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  tabIndex: PropTypes.number,
  fluid: PropTypes.bool,
  name: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(Button);
