import React from 'react';
import PropTypes from 'prop-types';

import useAccordionContext from './useAccordionContext';

const Collapse = ({
  element: Component,
  eventKey,
  children,
  ...otherProps
}) => {
  const { activeEventKey } = useAccordionContext();

  return activeEventKey === eventKey ? (
    <Component {...otherProps}>{children}</Component>
  ) : null;
};

Collapse.propTypes = {
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.any.isRequired, // eslint-disable-line
};

Collapse.defaultProps = {
  element: 'div',
};

export default Collapse;
