interface ICombineQueryData<TData> {
  data: TData[];
}

/**
 * Combine data from useInfinityQuery
 * @param {ICombineQueryData<TData>} data - fetched data by useInfinityQuery;
 * @return {Array} combined data
 */
export const combineQueryData = <TData>(data: ICombineQueryData<TData>[] = []) => {
  const combinedData = (data || []).reduce<TData[]>((prev, next) => [...prev, ...next.data], []);
  return combinedData;
};
