import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Model from '@old/model';
import PageTitle from '@old/components/guide/PageTitle';
import t from 'resources/translations';
import FloatingButton from '@old/components/custom/FloatingButton';
import ViewPager from '@old/components/guide/ViewPager';
import { getTabsId } from 'old/utils';
import { useTabs } from '@old/hooks';
import HorsesStatistics from '@old/components/custom/HorseStatistics';
import HorsesProvider from '@old/components/custom/HorsesProvider';
import { useAddHorseButton } from 'old/buttonHooks';

const HorsesPage = ({ isMobile, activeTab, loading }) => {
  const addHorse = useAddHorseButton();
  const tabs = [{
    name: t('horseStatistics.list'),
    view: HorsesProvider,
    props: {
      isSearchable: true,
      isMobile,
    },
    permissionKey: 'horses.index',
  }, {
    name: t('horseStatistics.statistics'),
    view: HorsesStatistics,
    props: {
      model: Model.Horses,
    },
    permissionKey: 'horse_statistics.events',
  }];

  const horseTabs = useTabs(tabs);

  return (
    <React.Fragment>
      <PageTitle title={t('model.horses')} loading={loading} />
      <ViewPager tabs={horseTabs} />
      {activeTab === 0 && (
        <FloatingButton buttons={[addHorse]} />
      )}
    </React.Fragment>
  );
};

HorsesPage.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  activeTab: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { isMobile }, tabs, fetchingData: { loading } }, { match }) => ({
  isMobile,
  activeTab: tabs[getTabsId(match.path)] || 0,
  loading: loading.horses,
});

export default (connect(mapStateToProps)(HorsesPage));
