import { Divider } from 'components/combinations';
import { Button, Flex, Icons, Stack, Text } from 'components/elements';
import Event from 'models/Event';
import Participation from 'models/Participation';
import t from 'resources/translations';
import { usePermissions } from 'Permissions';
import { useQueryClient } from 'react-query';
import { IParticipationRes } from 'resources/types/participationsTypes';
import { BillOpenReturnPaymentAction, BillOpenSetPaymentAction } from 'actions/bill';
import { ParticipationAcceptAction, ParticipationRejectAction } from 'actions/participant';
import { useAppStore } from 'utils/storeUtils';

type TParticipantActionsProps = {
  event: Event;
  participation: Participation;
  customRefreshData?: (participation?: IParticipationRes) => void;
};

const ParticipantActions = ({ event, participation, customRefreshData }: TParticipantActionsProps) => {
  const queryClient = useQueryClient();
  const { isMobile } = useAppStore();
  const canISetPayment = usePermissions('BILL_SET_PAYMENT')(participation.bill, participation);
  const canIReturnPayment = usePermissions('BILL_RETURN_PAYMENT')(participation.bill, participation);
  const canIReject = usePermissions('PARTICIPATION_REJECT')(event, participation);
  const canIAccept = usePermissions('PARTICIPATION_ACCEPT')(event, participation);
  const refreshData = !!customRefreshData
    ? customRefreshData
    : () => queryClient.invalidateQueries(['event'], { refetchActive: true });

  if (participation.status === 'joined' && (canIReject || canISetPayment || canIReturnPayment)) {
    return (
      <>
        <Divider color="grey" />
        <Stack px="md" py="sm" justifyContent="flex-end" alignItems="center" isHorizontal={!isMobile}>
          {canISetPayment && participation.bill && (
            <BillOpenSetPaymentAction bill={participation.bill} onChangeDueCb={refreshData} onSubmitCb={refreshData}>
              {({ onClick }) => (
                <Button fontSize="base" variant="link" onClick={onClick}>
                  <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                    <Icons.PaymentWallet size={12} />
                    <Text>{t('buttons.settle')}</Text>
                  </Stack>
                </Button>
              )}
            </BillOpenSetPaymentAction>
          )}
          {canIReturnPayment && participation.bill && (
            <BillOpenReturnPaymentAction bill={participation.bill} onSubmitCb={refreshData}>
              {({ onClick }) => (
                <Button fontSize="base" variant="link" onClick={onClick}>
                  <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                    <Icons.Return size={12} />
                    <Text>{t('buttons.return')}</Text>
                  </Stack>
                </Button>
              )}
            </BillOpenReturnPaymentAction>
          )}
          {canIReject && (
            <ParticipationRejectAction eventId={event.id} participation={participation} onSuccess={refreshData}>
              {action => (
                <Button fontSize="base" variant="link" onClick={() => action.mutateAsync()} disabled={action.isLoading}>
                  <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                    <Icons.Cancel size={12} />
                    <Text>{t('buttons.remove')}</Text>
                  </Stack>
                </Button>
              )}
            </ParticipationRejectAction>
          )}
        </Stack>
      </>
    );
  }

  if (participation.status === 'invitation_requested' && (canIReject || canIAccept)) {
    return (
      <>
        <Divider color="grey" />
        <Stack px="md" py="sm" justifyContent="flex-end" alignItems="center" isHorizontal={!isMobile}>
          {canIAccept && (
            <ParticipationAcceptAction eventId={event.id} participation={participation} onSuccess={refreshData}>
              {action => (
                <Button fontSize="base" variant="link" onClick={() => action.mutateAsync()} disabled={action.isLoading}>
                  <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                    <Icons.Accept size={12} />
                    <Text>{t('buttons.accept')}</Text>
                  </Stack>
                </Button>
              )}
            </ParticipationAcceptAction>
          )}
          {canIReject && (
            <ParticipationRejectAction eventId={event.id} participation={participation} onSuccess={refreshData}>
              {action => (
                <Button fontSize="base" variant="link" onClick={() => action.mutateAsync()} disabled={action.isLoading}>
                  <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                    <Icons.Cancel size={12} />
                    <Text>{t('buttons.reject')}</Text>
                  </Stack>
                </Button>
              )}
            </ParticipationRejectAction>
          )}
        </Stack>
      </>
    );
  }

  if (participation.status === 'invited' && canIReject) {
    return (
      <>
        <Divider color="grey" />
        <Flex px="md" py="sm" alignItems="center" justifyContent={['center', 'flex-end']}>
          <ParticipationRejectAction eventId={event.id} participation={participation} onSuccess={refreshData}>
            {action => (
              <Button fontSize="base" variant="link" onClick={() => action.mutateAsync()} disabled={action.isLoading}>
                <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                  <Icons.Cancel size={12} />
                  <Text>{t('buttons.reject')}</Text>
                </Stack>
              </Button>
            )}
          </ParticipationRejectAction>
        </Flex>
      </>
    );
  }

  if (participation.status === 'rejected' && canIAccept) {
    return (
      <>
        <Divider color="grey" />
        <Flex px="md" py="sm" alignItems="center" justifyContent={['center', 'flex-end']}>
          <ParticipationAcceptAction eventId={event.id} participation={participation} onSuccess={refreshData}>
            {action => (
              <Button fontSize="base" variant="link" onClick={() => action.mutateAsync()} disabled={action.isLoading}>
                <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                  <Icons.Accept size={12} />
                  <Text>{t('buttons.inviteAgain')}</Text>
                </Stack>
              </Button>
            )}
          </ParticipationAcceptAction>
        </Flex>
      </>
    );
  }

  return null;
};

export default ParticipantActions;
