import { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PageTitle from '@old/components/guide/PageTitle';
import FloatingButton from '@old/components/custom/FloatingButton';
import t from 'resources/translations';
import { useTabs } from '@old/hooks';
import ViewPager from '@old/components/guide/ViewPager';
import NewsProvider from '@old/components/custom/NewsProvider';
import { useAddNewsButton } from 'old/buttonHooks';

const NewsPage = ({ loading }) => {
  const tabs = useMemo(
    () => [
      {
        name: t('model.news'),
        view: NewsProvider,
        permissionKey: 'news.index',
        props: {
          pagKey: 'news',
        },
      },
      {
        name: t('menu.draft'),
        view: NewsProvider,
        permissionKey: 'news.drafts',
        props: {
          pagKey: 'drafts',
          fetchParams: {
            drafts: true,
          },
        },
      },
    ],
    []
  );

  const newsTabs = useTabs(tabs);
  const addNews = useAddNewsButton();

  return (
    <>
      <PageTitle title={t('model.news')} loading={loading} />
      <ViewPager tabs={newsTabs} />
      <FloatingButton buttons={[addNews]} />
    </>
  );
};

NewsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ fetchingData: { loading } }) => ({
  loading: loading.news,
});

export default connect(mapStateToProps)(NewsPage);
