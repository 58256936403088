import React from 'react';
import Model from '@old/model';
import t from 'resources/translations';
import { orderBy } from 'lodash';
import { notify } from 'old/utils';
import { useShallowSelector } from '@old/hooks';
import { Box, Button, Span, Text, TextTruncate } from '@elements';
import { Modal, ModalHeader, ModalCloseButton, ModalContent } from '@combinations';
import { wrappedModalPropTypes } from '@old/components/modals';
import SpinnerDots from 'old/components/guide/SpinnerDots';

const CancelPaymentModal = ({ name, onSubmitAndClose, onClose, isSubmitting, isHidden }) => {
  const { bill, callback } = useShallowSelector(({ modal }) => modal[name]?.props || {});
  const onSubmit = async () => {
    try {
      const transferPaymentsList = bill.payments.filter(payment => payment.status === 'unprocessed');
      const lastTransferPayment = orderBy(transferPaymentsList, ['created_at'], ['desc'])[0];
      await Model.Bills.cancelPayment(bill.id, lastTransferPayment.transactions[0].id);
      callback();
      notify(t('message.succesSave'));
    } catch (error) {
      throw error;
    }
  };
  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{t('bills.cancelPayment')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting} my="xl">
        <Box mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" p="md">
          <Text>
            {t('bills.areYouSureCancel')}
            <TextTruncate lines={2} fontWeight="bold">
              {bill.event.name}
            </TextTruncate>
            <Span>?</Span>
          </Text>
        </Box>
        <Button onClick={() => onSubmitAndClose(onSubmit)}>
          {isSubmitting ? <SpinnerDots label={t('bills.cancelingPayment')} inline /> : t('bills.cancelPayment')}
        </Button>
        <Button onClick={onClose} variant="outline">
          {t('general.cancel')}
        </Button>
      </ModalContent>
    </Modal>
  );
};

CancelPaymentModal.propTypes = wrappedModalPropTypes;

export default CancelPaymentModal;
