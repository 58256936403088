import React from 'react';
import PropTypes from 'prop-types';

const Subtitle = ({ children }) => <span className="text-title-md">{children}</span>;

Subtitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

export default Subtitle;
