import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageTitle from '@old/components/guide/PageTitle';
import t from 'resources/translations';
import { useTabs } from '@old/hooks';
import Model from '@old/model';
import FarmView from '@old/components/custom/FarmView';
import ViewPager from '@old/components/guide/ViewPager';
import { getTabsId } from 'old/utils';
import FloatingButton from '@old/components/custom/FloatingButton';
import PlacesProvider from '@old/components/custom/PlacesProvider';
import EventTypesProvider from '@old/components/custom/EventTypesProvider';
import { useAddPlaceButton, useAddEventTypeButton } from 'old/buttonHooks';

const FarmDetails = ({ isMobile, activeTab, loading }) => {
  const addPlace = useAddPlaceButton();
  const addEventType = useAddEventTypeButton();
  const tabs = [
    {
      name: t('general.details'),
      view: FarmView,
      props: {
        model: Model.Farms,
      },
      permissionKey: 'alwaysAllowed', // ??
    },
    {
      name: t('model.places'),
      view: PlacesProvider,
      props: {
        pagKey: 'places',
        isSearchable: true,
        isMobile,
      },
      permissionKey: 'places.index',
    },
    {
      name: t('model.eventTypes'),
      view: EventTypesProvider,
      props: {
        pagKey: 'event_types',
        isSearchable: true,
        isMobile,
      },
      permissionKey: 'event_types.index',
    },
  ];

  const farmTabs = useTabs(tabs);

  return (
    <>
      <PageTitle title={t('farmDetails.farmAbout')} loading={loading} />
      <ViewPager tabs={farmTabs} />
      {activeTab === 1 && <FloatingButton buttons={[addPlace]} />}
      {activeTab === 2 && <FloatingButton buttons={[addEventType]} />}
    </>
  );
};

FarmDetails.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  activeTab: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app, tabs, fetchingData: { loading } }, { match }) => ({
  isMobile: app.isMobile,
  activeTab: tabs[getTabsId(match.path)] || 0,
  loading: loading.places || loading.eventTypes || loading.farm,
});

export default connect(mapStateToProps)(FarmDetails);
