import React, { useCallback } from 'react';
import { AvatarWithLabel, Divider, LinkMember } from 'components/combinations';
import { Box, Button, Flex, Grid, Icons, Link, Span, Stack, Ternary, Text, Tooltip } from 'components/elements';
import { parseToCurrencyString } from 'old/utils';
import t from 'resources/translations';
import PaymentsList from 'views/Bills/PaymentsList';
import { IBillInstructor, IBillRes } from 'resources/types/billsTypes';
import { usePermissions } from 'Permissions';
import Bill from 'models/Bill';
import { useQueryClient } from 'react-query';
import theme from 'config/theme';
import { css } from 'styled-components';
import { TFlexProps } from 'resources/types/elementsTypes';
import _ from 'lodash';
import { mBillInBills, mFetchBillInBills, doubleTernary, useDisclosure } from 'utils';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { TicketService } from 'services';
import { useMutation } from 'react-query';
import { useModal } from 'old/hooks';
import { useLoggedMember } from 'utils/storeUtils';

type TBillsListItemProps = TFlexProps & {
  bill: Bill;
};

const BillsListItem = ({ bill, ...flexProps }: TBillsListItemProps) => {
  const loggedMember = useLoggedMember();
  const queryClient = useQueryClient();
  const expadedProps = useDisclosure();
  const isTicket = bill.billableType === 'Ticket';

  const canISetPayment = usePermissions('BILL_SET_PAYMENT')(bill);
  const canIReturnPayment = usePermissions('BILL_RETURN_PAYMENT')(bill);
  const canIRemoveTicket = usePermissions('TICKET_REMOVE')(bill.status === 'unpaid') && isTicket;

  const modalConfirm = useModal('CONFIRM');
  const modalReturnPayment = useModal('PAYMENT_RETURN');
  const modalPayment = useModal(isTicket ? 'PAYMENT_TICKET' : 'PAYMENT_EVENT');

  const getOtherInstructors = useCallback(
    (instructors: IBillInstructor[]) => instructors.filter(instructor => instructor.id !== loggedMember.id),
    [loggedMember.id]
  );

  const refreshData = () => queryClient.invalidateQueries('BILLS', { refetchActive: true });
  const refreshSummaryBillsData = () => queryClient.invalidateQueries('SUMMARY_BILLS', { refetchActive: true });
  const actionRemoveTicket = useMutation(async () => TicketService.delete(bill.billableId), {
    onSuccess: () => {
      refreshData();
      refreshSummaryBillsData();
    },
  });

  const onOpenPaymenModal = () => {
    const onSubmitCb = (billRes: IBillRes) => {
      mBillInBills(queryClient, ['BILLS'], billRes);
      refreshSummaryBillsData();
    };
    const onChangeDueCb = (billRes: IBillRes) => {
      mBillInBills(queryClient, ['BILLS'], billRes);
      refreshSummaryBillsData();
    };
    return modalPayment.onOpen({ bill, onSubmitCb, onChangeDueCb });
  };

  const onOpenReturnPaymenModal = () => {
    const onSubmitCb = () => {
      mFetchBillInBills(queryClient, 'BILLS', bill.id);
      refreshSummaryBillsData();
    };
    modalReturnPayment.onOpen({ bill, onSubmitCb });
  };

  const onOpenDeleteModal = () => {
    modalConfirm.onOpen({
      title: t('tickets.deleteTicket'),
      content: (
        <ModalDescription spacing="xl">
          <Stack spacing="sm">
            <Text>{t('tickets.deleteUnpaidTicket')}:</Text>
            <Text>
              <Span fontWeight="medium">{bill.getName()}</Span>?
            </Text>
          </Stack>
          <Text fontWeight="medium">{t('tickets.rememberTicketDeleted')}</Text>
        </ModalDescription>
      ),
      action: {
        mutation: actionRemoveTicket,
        label: t('buttons.yesIWantDeleteTicket'),
        cancelLabel: t('buttons.noGetMyOutOfHere'),
        loadingLabel: t('message.removed'),
      },
    });
  };

  const isPaid = bill.getDue() === bill.getPaidAmount();
  const getPaymentStatus = useCallback(() => {
    const isUnpaid = bill.getDue() > bill.getPaidAmount();
    const paymentStatus: string = doubleTernary(isPaid, isUnpaid, [
      t('bills.statementList.paid'),
      t('bills.statementList.unpaid'),
      t('bills.statementList.overpaid'),
    ]);
    return paymentStatus;
  }, [bill, isPaid]);

  const url = isTicket ? `/tickets/${bill.ticket.id}` : `/events/${bill.event.id}`;
  const instructors = loggedMember.isInstructor() ? getOtherInstructors(bill.instructors) : bill.instructors;
  return (
    <>
      <Grid
        gridTemplateColumns="1fr 30px 3.5fr 100px 100px 120px 40px"
        gridTemplateRows="minmax(80px, auto)"
        gridColumnGap={30}
        alignItems="center"
        px={['md', 'xl']}
        css={css({
          '&:hover': {
            backgroundColor: theme.colors['teal-light-5'],
          },
        })}
        {...flexProps}
      >
        {!!bill.billed ? (
          <AvatarWithLabel
            src={bill.billed.getAvatar('thumb')}
            title={bill.billed.getFullName()}
            alt={bill.billed.getFullName()}
            squareSize={26}
          >
            <LinkMember member={bill.billed}>{bill.billed.getFullName()}</LinkMember>
          </AvatarWithLabel>
        ) : (
          <Text>{t('general.userDeleted')}</Text>
        )}
        <Box>
          {isTicket ? (
            <Tooltip content={t('labels.ticket')} placement="top-start">
              <Box>
                <Icons.TicketCircle bgColor="body-color" fill="teal-dark" />
              </Box>
            </Tooltip>
          ) : (
            <Tooltip content={t('labels.event')} placement="top-start">
              <Box>
                <Icons.HorseRiderCircle bgColor="body-color" fill="teal-dark" />
              </Box>
            </Tooltip>
          )}
        </Box>
        <Box>
          <Ternary cond={!!bill.ticket.name || !!bill.event.name}>
            <Link to={url} wordBreak="break-all" py="sm">
              {isTicket ? bill.ticket.name : bill.event.name}
            </Link>
            <Text>{t('model.delete.events')}</Text>
          </Ternary>
        </Box>
        <Text whiteSpace="nowrap" textAlign="right">
          {parseToCurrencyString(bill.dueAmount)}
        </Text>
        <Text whiteSpace="nowrap" textAlign="right">
          {parseToCurrencyString(bill.paidAmount)}
        </Text>
        <Text color={isPaid ? 'grey-dark' : 'orange'}>{getPaymentStatus().toLowerCase()}</Text>
        <Flex justifyContent="center">
          <Button
            variant="icon"
            onClick={() => expadedProps.onToggle()}
            css={css(expadedProps.isOpen ? { color: theme.colors['teal-dark'] } : {})}
          >
            {expadedProps.isOpen ? <Icons.ArrowUp size="md" /> : <Icons.ArrowDown size="md" />}
          </Button>
        </Flex>
        {expadedProps.isOpen && (
          <Grid gridColumn="8 / 1" pb="md">
            <Stack>
              {!_.isEmpty(instructors) && (
                <Stack spacing="xs">
                  <Text>
                    <Span fontWeight="medium">{`${
                      loggedMember.isInstructor() ? t('bills.otherEmployees') : t('general.instructors')
                    }: `}</Span>
                    <Span>
                      {instructors.map((instructor, i) => (
                        <>
                          {i !== 0 ? ', ' : ''}
                          <Link to={`/instructors/${instructor.id}`} forceRender>
                            {instructor.name}
                          </Link>
                        </>
                      ))}
                    </Span>
                  </Text>
                </Stack>
              )}
              <PaymentsList bill={bill} />
              <Stack isHorizontal justifyContent="flex-end">
                {!loggedMember.isClient() && bill.billableType === 'Participation' && bill.event.id && (
                  <Link to={`/bills/${bill.event.id}`}>{t('bills.viewBillingDetails')}</Link>
                )}
                {canIRemoveTicket && (
                  <Button variant="link" onClick={() => onOpenDeleteModal()} width={['100%', 'initial']}>
                    <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                      <Icons.Cancel size={12} />
                      <Text>{t('tickets.deleteTicket')}</Text>
                    </Stack>
                  </Button>
                )}
                {canISetPayment && (
                  <Button variant="link" onClick={() => onOpenPaymenModal()} width={['100%', 'initial']}>
                    <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                      <Icons.PaymentWallet size={16} />
                      <Text>{t('buttons.settle')}</Text>
                    </Stack>
                  </Button>
                )}
                {canIReturnPayment && (
                  <Button variant="link" onClick={() => onOpenReturnPaymenModal()} width={['100%', 'initial']}>
                    <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                      <Icons.Return size={16} />
                      <Text>{t('buttons.return')}</Text>
                    </Stack>
                  </Button>
                )}
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
      <Box w="full" px={['md', 'xl']}>
        <Divider color="new-grey-light-30" my={0} />
      </Box>
    </>
  );
};

export default React.memo(BillsListItem);
