import { connect } from 'react-redux';
import moment from 'moment';
import { split, isEmpty } from 'lodash';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';
import RowAligner from '@old/components/common/RowAligner';
import t from 'resources/translations';
import { isCorrectFormat } from 'old/utils';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import FlexColumn from '@old/components/common/FlexColumn';
import config from '@old/config';

const DateTimeWithDurationField = ({
  onChangeField,
  placeholder,
  validation,
  label,
  masterFieldValue,
  timeIntervals,
  ...props
}) => {
  const { name, formName, required, pickerOptions, dataTest } = props;

  const dateTime = split(props.value, ' ');
  const date = dateTime[0];
  const time = dateTime[1];

  const onChangeDate = value => {
    const fieldState = `${value} ${time || ''}`;
    onChangeField({ formName, fieldName: name, fieldState });
  };

  const onChangeTime = value => {
    const fieldState = `${date || ''} ${value}`;
    onChangeField({ formName, fieldName: name, fieldState });
  };

  const onChangeDateTime = value => {
    const fieldState = `${value[0]} ${value[1]}`;
    onChangeField({ formName, fieldName: name, fieldState });
  };

  const validationFuncs = [
    ...validation,
    {
      condition: () => isCorrectFormat(props.value, config.dateTimeFormat),
      errorHint: t('error.badDateFormatDDMMYYYYHHMM'),
    },
  ];

  const incrementValues = !isEmpty(timeIntervals) ? timeIntervals : ['01:00', '01:30', '02:00'];

  return (
    <FlexColumn>
      <FormField validation={validationFuncs} {...props}>
        <RowAligner className="items-end">
          <Input.DateValue
            value={date}
            name={name}
            label={label}
            onChange={onChangeDate}
            placeholder={placeholder}
            pickerOptions={pickerOptions}
            required={required}
          />
          <Input.Time dataTest={dataTest} value={time} onChange={onChangeTime} required={required} />
        </RowAligner>
      </FormField>
      {incrementValues && incrementValues.length && (
        <RowAligner separated="small" className="event-type-intervals">
          {incrementValues.map(increment => {
            const timeIncrement = moment(increment, 'HH:mm');
            const minutes = timeIncrement.hour() * 60 + timeIncrement.minute();
            const datetime = moment(masterFieldValue, config.dateTimeFormat).add(minutes, 'minutes');

            return (
              <ButtonOutline
                key={increment}
                className="rounded-full"
                onClick={() => onChangeDateTime([datetime.format(config.dateFormat), datetime.format('HH:mm')])}
              >
                {timeIncrement.format('H[h] mm[m]')}
              </ButtonOutline>
            );
          })}
        </RowAligner>
      )}
    </FlexColumn>
  );
};

DateTimeWithDurationField.defaultProps = {
  value: '',
  masterFieldValue: '',
  timeIntervals: false,
  label: '',
  validation: [],
  placeholder: '',
  required: false,
  dataTest: '',
  pickerOptions: {
    minDate: null,
    maxDate: null,
  },
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;

  const masterFieldValue = form[props.formName][props.durations.masterField].value;
  const dependentField = form[props.formName][props.durations.dependentField].value;
  const timeIntervals = dependentField && dependentField.timeIntervals;

  return { value: inputValue, masterFieldValue, timeIntervals };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(DateTimeWithDurationField);
