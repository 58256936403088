import _ from 'lodash';
import { TPrivilege, TSkills } from 'resources/types/commonTypes';
import { IAttendees, IEventReq, IEventRes, TEventStatuses, TEventVisibility } from 'resources/types/eventsTypes';
import { TParticipationStatus } from 'resources/types/participationsTypes';
import EventType from './EventType';
import Horse from './Horse';
import Member from './Member';
import Participation from './Participation';
import Place from './Place';
import config from 'old/config';
import moment from 'moment';
import { DATE_TIME_FORMAT, EVENT_STATUS_PROPS } from 'config/constans';

class Event {
  id: number;
  name: string;
  description: string;
  visibility: TEventVisibility;
  startDate: Date;
  endDate: Date;
  difficulty: TSkills;
  special: boolean;
  attendees: IAttendees;
  instructors: Member[];
  creator: Member;
  places: Place[];
  horses: Horse[];
  type: EventType;
  participations: Participation[];
  status: TEventStatuses;
  privileges: TPrivilege[];
  unjoinTimeout: number;
  dueAmount: number;
  paidAmount: number;
  edited: boolean;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IEventRes) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.visibility = data.visibility;
    this.startDate = new Date(data.start_at);
    this.endDate = new Date(data.end_at);
    this.difficulty = data.difficulty;
    this.special = data.special;
    this.attendees = { min: data.attendees_min, max: data.attendees_max };
    this.instructors = data.instructors.map(instructor => new Member(instructor));
    this.creator = new Member(data.creator);
    this.places = (data.places || []).map(place => new Place(place));
    this.horses = data.horses.map(horse => new Horse(horse));
    this.type = new EventType(data.type);
    this.participations = data.participations.map(p => new Participation(p));
    this.status = data.status;
    this.privileges = data.meta.privileges;
    this.unjoinTimeout = data.unjoin_timeout;
    this.dueAmount = parseFloat(data.due_amount);
    this.paidAmount = parseFloat(data.paid_amount);
    this.edited = data.edited;
    this.deleted = data.deleted;
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
  }

  static mapToSave = (data: Event) => {
    if (data instanceof Event) {
      const dataToSave: IEventReq = {
        name: data.name,
        description: data.description,
        start_at: data.startDate.toISOString(),
        end_at: data.endDate.toISOString(),
        difficulty: data.difficulty,
        special: data.special,
        attendees_min: data.attendees.min,
        attendees_max: data.attendees.max,
        event_type_id: data.type.id,
        instructor_ids: data.instructors.map(i => i.id),
        horse_ids: data.horses.map(h => h.id),
        participant_ids: data.participations.map(p => p.member.id),
        place_ids: data.places.map(p => p.id),
        visibility: data.visibility,
      };

      const dataKeys = Object.keys(dataToSave) as Array<keyof IEventReq>;
      dataKeys.forEach(fieldName => {
        if (dataToSave[fieldName] === undefined) delete dataToSave[fieldName];
      });

      return dataToSave;
    }
  };

  isReadyToApprove = () => {
    return (
      this.type &&
      this.name &&
      this.difficulty &&
      this.startDate &&
      this.endDate &&
      this.attendees.min &&
      this.attendees.max &&
      this.places.length > 0 &&
      this.instructors.length > 0
    );
  };

  getDifficulty() {
    return this.difficulty || 'missing';
  }

  getName(limit: number = 0) {
    return limit ? _.truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  canI(permissionKey: TPrivilege) {
    return this.privileges.includes(permissionKey);
  }

  getInstructors() {
    return this.instructors;
  }

  getHorses() {
    return this.horses;
  }

  getInstructorsIds() {
    return this.instructors.map(i => i.id);
  }

  getParticipations() {
    return this.participations;
  }

  getParticipationsByStatus(status: TParticipationStatus = 'joined') {
    return this.participations.filter(p => p.status === status);
  }

  getPlace() {
    return this.places[0];
  }

  getStartDate(format: string = config.dateTimeFormat) {
    return moment(this.startDate).format(format);
  }

  getEndDate(format: string = config.dateTimeFormat) {
    return moment(this.endDate).format(format);
  }

  getStatus() {
    return this.status;
  }

  getStatusColor() {
    return EVENT_STATUS_PROPS[this.status || 'none'];
  }

  getStatuses(memberId: number) {
    const userParticipation = this.participations.find(p => p.member.id === memberId);
    const instructorIds = this.getInstructorsIds();
    return {
      memberIsJoined: userParticipation?.status === 'joined',
      memberIsInvited: userParticipation?.status === 'invited',
      memberRequestInvitation: userParticipation?.status === 'invitation_requested',
      memberIsEventInstructor: instructorIds.includes(memberId),
      isFinished: this.status === 'finished',
      isOngoing: this.status === 'ongoing',
      isAwaiting: this.status === 'awaiting',
      isActive: this.status === 'active',
      isCancelled: this.status === 'cancelled',
      isProposed: this.status === 'proposed',
      isFull: this.participations.length >= this.attendees.max,
    };
  }

  getUrl() {
    return this.status === 'proposed' ? `proposals/${this.id}` : `/events/${this.id}`;
  }

  isPast() {
    return moment(this.endDate, DATE_TIME_FORMAT).isBefore(new Date());
  }

  isFull() {
    return this.getParticipationsByStatus('joined').length >= this.attendees.max;
  }

  canUnjoin() {
    if (this.isPast()) return false;
    const timeToEvent = moment(this.startDate).diff(moment(), 'seconds');
    if (timeToEvent > this.unjoinTimeout) {
      return false;
    }
    return true;
  }
}

export default Event;
