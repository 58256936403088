import { useState } from 'react';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { cloneDeep, unset } from 'lodash';

import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Option from '@old/components/view/listItem/SelectOption';
import Field from '@old/components/field';
import { FORM_PROPOSAL } from 'old/constants/formNames';
import { changeFieldState, validateField } from 'store/actions';
import config from '@old/config';
import {
  getDuration,
  isCorrectFormat,
  isLengthBetween,
  notify,
  getChanges,
  mapFormStateToData,
  getInitValues,
  parseToCurrencyString,
} from 'old/utils';

const ProposalsForm = ({ doc, confirm, onChangeField, history, isMobile, initValues, ...props }) => {
  const formName = FORM_PROPOSAL(doc ? doc.id : '');

  const filterInitValues = initialVals => {
    // fixme - maybe move to utils?
    const filteredInitVals = cloneDeep(initialVals);
    if (doc) unset(filteredInitVals, 'participations');
    return filteredInitVals;
  };

  const [eventCost, setEventCost] = useState(doc?.type?.defaultEventCost || null);
  const initialValues = filterInitValues(
    getInitValues({
      formName: 'proposal',
      customInitValues: doc ? Model.Proposal.mapToFormData(doc) : initValues,
    })
  );

  const setEndDateAfterFieldChange = ({ formState }) => {
    setNameAfterFieldChange({ formState });
    if (isCorrectFormat(formState.startDate.value, config.dateTimeFormat)) {
      const timeIntervals = formState.type.value ? formState.type.value.timeIntervals : [];
      let datetime = '';
      if (timeIntervals && timeIntervals.length > 0) {
        const timeIncrement = moment(timeIntervals[0], 'HH:mm');
        const minutes = timeIncrement.hour() * 60 + timeIncrement.minute();
        datetime = moment(formState.startDate.value, config.dateTimeFormat)
          .add(minutes, 'minutes')
          .format(config.dateTimeFormat);
      } else {
        datetime = moment(formState.startDate.value, config.dateTimeFormat)
          .add(60, 'minutes')
          .format(config.dateTimeFormat);
      }
      onChangeField({ formName, fieldName: 'endDate', fieldState: datetime });
    }
  };

  const setNameAfterFieldChange = ({ formState }) => {
    const typeName = formState.type.value && formState.type.value.label;
    const date =
      formState.startDate.value && formState.endDate.value
        ? getDuration(
            moment(formState.startDate.value, config.dateTimeFormat),
            moment(formState.endDate.value, config.dateTimeFormat)
          )
        : '';
    const placesName = formState.places.value && formState.places.value.label;

    return onChangeField({
      formName,
      fieldName: 'proposalName',
      fieldState: [typeName, date, placesName].filter(Boolean).join(' / '),
    });
  };

  const setFieldAfterEventTypeChange = ({ formState }) => {
    setNameAfterFieldChange({ formState });
    setEndDateAfterFieldChange({ formState });
    setPlacesAfterEventTypeChange({ formState });
    setCostAfterEventTypeChange({ formState });
  };

  const setCostAfterEventTypeChange = ({ formState }) => {
    const eventType = formState.type.value;
    setEventCost(eventType?.eventCost);
  };

  const setPlacesAfterEventTypeChange = ({ formState }) => {
    const eventType = formState.type.value;
    if (eventType && eventType.defaultPlace) {
      onChangeField({ formName, fieldName: 'places', fieldState: eventType.defaultPlace });
    }
  };
  const onApprove = async formState => {
    await Model.Proposals.approve(doc.id, Model.Proposal.mapToSaveData(formState));
    notify(t('message.succesSave'));
    history.push(`/events/${doc.id}`);
  };

  const onSubmit = formState => {
    const values = doc
      ? getChanges(formState, mapFormStateToData({ formState: initialValues, initValue: true }))
      : formState;
    return props.onSubmit(Model.Proposal.mapToSaveData(values), { redirectTo: '/proposals' });
  };

  const validations = {
    name: [
      {
        condition: value => isLengthBetween({ value, min: 5, max: 120 }),
        errorHint: t('error.fieldMustHaveLengthBetween', { min: 5, max: 120 }),
      },
    ],
    endDate: [
      {
        condition: (value, formState) => {
          const startDate = formState.startDate.value;
          const endDate = value;
          if (isCorrectFormat(startDate, config.dateTimeFormat) && isCorrectFormat(endDate, config.dateTimeFormat)) {
            const momentStartDate = moment(startDate, config.dateTimeFormat);
            return !moment(endDate, config.dateTimeFormat).isSameOrBefore(momentStartDate);
          }
          return true;
        },
        errorHint: t('error.after_start'),
      },
    ],
    startDate: [
      {
        condition: value => {
          if (isCorrectFormat(value, config.dateTimeFormat)) {
            return moment().isSameOrBefore(moment(value, config.dateTimeFormat), 'days');
          }
          return true;
        },
        errorHint: t('error.ongoing'),
      },
    ],
  };

  let submitText = '';

  if (!doc) {
    submitText = t('model.add.proposals');
  } else {
    submitText = confirm ? t('proposalsForm.confirm') : t('general.saveChanges');
  }

  const dependentFields = {
    endDate: ['startDate'],
    attendees: ['participants'],
  };

  return (
    <Form formName={formName} initValues={initialValues} dependentFields={dependentFields}>
      <Field.AsyncSelect
        name="type"
        label={t('labels.eventType')}
        placeholder={t('placeholders.selectEventType')}
        loadOptions={Model.EventTypes.fetchOptions}
        afterChanges={setFieldAfterEventTypeChange}
        required
        customOptionComponents={Option.EventType}
      />

      {eventCost && (
        <div className="flex ml-1 -mt-3">
          <div className="font-bold">{t('eventListItem.pricePerShare')}:</div>
          <div className="ml-1">{parseToCurrencyString(eventCost)}</div>
        </div>
      )}

      <Field.DateTime
        name="startDate"
        label={t('labels.start')}
        afterChanges={setEndDateAfterFieldChange}
        pickerOptions={{ minDate: moment().subtract(1, 'days') }}
        validation={validations.startDate}
        placeholder={t('placeholders.date')}
        required
      />

      <Field.DateTimeWithDuration
        name="endDate"
        label={t('labels.end')}
        validation={validations.endDate}
        afterChanges={setNameAfterFieldChange}
        pickerOptions={{ minDate: moment(props.formStartDateValue, config.dateTimeFormat).subtract(1, 'days') }}
        durations={{
          masterField: 'startDate',
          dependentField: 'type',
        }}
        placeholder={t('placeholders.date')}
        required
      />

      <Field.AsyncSelect
        name="places"
        label={t('labels.place')}
        placeholder={t('placeholders.selectPlace')}
        loadOptions={Model.Places.fetchOptions}
        afterChanges={setNameAfterFieldChange}
        required={confirm}
        customOptionComponents={Option.Place}
        isSearchable={false}
      />

      <Field.AsyncMultiSelect
        name="instructors"
        label={t('labels.instructor')}
        placeholder={t('placeholders.selectInstructors')}
        loadOptions={Model.Members.fetchInstructorOptions}
        required
        customOptionComponents={Option.Member}
        isSearchable={false}
      />

      <Field.Select
        name="difficulty"
        label={t('labels.difficulty')}
        placeholder={t('placeholders.selectDifficulty')}
        options={config.difficulty.map(skill => ({
          key: skill.value,
          value: skill.value,
          label: skill.label,
          slug: skill.slug,
          color: skill.color,
        }))}
        required={confirm}
        customOptionComponents={Option.Difficulty}
      />

      <Field.NumberRange
        name="attendees"
        label={t('labels.numberParticipants')}
        validation={validations.attendees}
        options={{
          min: 1,
          max: 50,
          minLabel: isMobile ? t('labels.min') : t('labels.minNumberOfParticipants'),
          maxLabel: isMobile ? t('labels.max') : t('labels.maxNumberOfParticipants'),
        }}
        required={confirm}
      />

      {!doc && (
        <Field.AsyncMultiSelect
          name="participations"
          label={t('labels.inviteParticipants')}
          placeholder={t('placeholders.selectParticipants')}
          loadOptions={Model.Members.fetchClientOptions}
          customOptionComponents={Option.Member}
          options={{
            customFilter: option => !option.isGuest,
          }}
        />
      )}

      <Field.AsyncMultiSelect
        name="horses"
        label={t('labels.horses')}
        placeholder={t('placeholders.selectHorses')}
        loadOptions={Model.Horses.fetchOptions}
        customOptionComponents={Option.Horse}
      />

      <Field.Text name="proposalDescription" label={t('labels.additionalNotes')} />

      <Field.Text name="proposalName" label={t('labels.name')} validation={validations.name} required={confirm} />

      <Field.Radio
        name="special"
        label={t('labels.typeOfEvent')}
        options={[
          { value: false, label: t('labels.normal').toLowerCase() },
          { value: true, label: t('labels.special').toLowerCase() },
        ]}
      />

      <Field.Radio
        name="visibility"
        label={t('labels.visibility')}
        options={[
          { value: true, label: t('visibility.public').toLowerCase() },
          { value: false, label: t('visibility.private').toLowerCase() },
        ]}
      />

      <FormButton
        formName={formName}
        onClick={confirm && doc ? onApprove : onSubmit}
        availableBeforeFormIsChanged
        async
      >
        {submitText}
      </FormButton>
    </Form>
  );
};

const mapStateToProps = ({ app: { isMobile }, form }, { doc }) => {
  const formName = FORM_PROPOSAL(doc ? doc.id : '');

  return {
    formStartDateValue: form[formName] && form[formName].startDate.value,
    isMobile,
  };
};

ProposalsForm.defaultProps = {
  onSubmit: null,
  doc: null,
  confirm: false,
  formStartDateValue: '',
  initValues: {},
};

export default withRouter(
  connect(mapStateToProps, {
    onChangeField: changeFieldState,
    setValidate: validateField,
  })(ProposalsForm)
);
