/* eslint-disable max-len */
import React from 'react';

const Search = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M2,7.1c0-2.7,2.2-4.9,4.9-4.9c0,0,0.1,0,0.1,0h0c0.1,0,0.3,0,0.4,0c2.7,0,4.9,2.2,4.9,4.9s-2.2,4.9-4.9,4.9
        c-0.2,0-0.3,0-0.5,0l0,0c0,0-0.1,0-0.1,0C4.2,11.9,2,9.8,2,7.1L2,7.1L2,7.1z M16,14.4l-3.3-3.2c0.9-1.1,1.4-2.5,1.4-4.1c0,0,0,0,0,0
        v0c0-3.8-3.1-6.8-6.9-6.8c-0.1,0-0.1,0-0.2,0h0c0,0-0.1,0-0.1,0C3.1,0.2,0,3.3,0,7v0c0,3.8,3.1,6.8,6.9,6.8c0,0,0.1,0,0.1,0h0
        c0,0,0,0,0,0c1.6,0,3-0.5,4.2-1.4l0,0l3.3,3.2L16,14.4z"
      />
    </svg>
  );
};

export default Search;
/* eslint-enable max-len */
