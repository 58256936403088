/**
 * @module FlexRow
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import FlexColumn from '@old/components/common/FlexColumn';
import RowAligner from '@old/components/common/RowAligner';

/**
 * wrapper for horizontally arranged elements with default margin between them
 * if number of children is greather than number of values in 'cols' extra children will be omitted, pass 'none' to force that child will not grow
 * @param  {*} [props.children]
 * @param  {String} [props.cols] string of values (separated by slash) that determines width of each child, if not provided then each child has same width
 * @param  {Boolean} [props.stretched] pass 'stretched: true' to force same height of all children
 * @param  {Boolean|"small"} [props.separated] specifies if items inside should be separated
 * @param  {Boolean} props.isMobile
 * @param  {Boolean} [props.noShrink] specifies wether children should be aligned vertically or horizontally on mobile device
 * @param  {Boolean} [props.shrinkTablet] specifies wether children should be aligned vertically or horizontally on tablet device
 * @param  {Boolean} props.isTablet
 * @param  {Boolean|"small"} [props.marginBottom] specifies if there should be bottom margin
 * @param  {Boolean} [props.alignCenter] specifies whether the children element should be centered
 */
const FlexRow = ({
  children,
  cols,
  stretched,
  separated,
  isMobile,
  noShrink,
  shrinkTablet,
  isTablet,
  marginBottom,
  alignCenter,
}) => {
  const flexGrowValues = cols ? cols.split('/') : [];

  const renderItem = (child, index) => {
    if (cols) {
      const childFlexGrowValue = flexGrowValues[index];

      if (!childFlexGrowValue) return null;

      return (
        <div
          className="row-item"
          style={{ flex: childFlexGrowValue === 'none' ? 'unset' : flexGrowValues[index], minWidth: 0 }}
        >
          {child}
        </div>
      );
    }

    return (
      <div className={cls('row-item flex-1')}>
        {child}
      </div>
    );
  };

  if ((isMobile || (shrinkTablet && isTablet)) && !noShrink) {
    return <FlexColumn separated={separated} marginBottom={marginBottom}>{children}</FlexColumn>;
  }

  if (!alignCenter) {
    return (
      <div
        className={
          cls('flex hm-flex-row', {
            stretched,
            separated: separated === true,
            'separated-small': separated === 'small',
            'mb-4': marginBottom === true,
            'mb-2': marginBottom === 'small',
          })}
      >
        {React.Children.map(children, (child, index) => renderItem(child, index))}
      </div>
    );
  }

  return (
    <RowAligner separated={separated} marginBottom={marginBottom} className={cls('flex hm-flex-row', { stretched })}>
      {React.Children.map(children, (child, index) => renderItem(child, index))}
    </RowAligner>
  );
};

FlexRow.defaultProps = {
  cols: null,
  stretched: false,
  separated: true,
  noShrink: false,
  marginBottom: false,
  alignCenter: false,
  shrinkTablet: false,
};

FlexRow.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  cols: PropTypes.string,
  stretched: PropTypes.bool,
  marginBottom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['small']),
  ]),
  separated: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['small']),
  ]),
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  noShrink: PropTypes.bool,
  alignCenter: PropTypes.bool,
  shrinkTablet: PropTypes.bool,
};

const mapStateToProps = ({ app: { isMobile, isTablet } }) => ({ isMobile, isTablet });

FlexRow.displayName = 'FlexRow';

export default connect(mapStateToProps)(FlexRow);
