import Event from 'models/Event';
import ActiveEventParticipant from 'views/Dashboard/ActiveEventParticipant';
import _ from 'lodash';
import t from 'resources/translations';
import { Button, Flex, Stack, Ternary, Text, Icons } from 'components/elements';
import { Divider } from 'components/combinations';
import { TFlexProps } from 'resources/types/elementsTypes';
import { mEventInEvents, useDisclosure } from 'utils';
import { usePermissions } from 'Permissions';
import { useModal } from 'old/hooks';

const headerStyles: TFlexProps = {
  flex: 'none',
  position: 'relative',
  px: ['md', 'sm'],
  py: 'sm',
  mx: [-20, 0],
  mt: 'md',
  bgColor: '#F6F6F6',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const ActiveEventParticipants = ({ event, isSingleMode }: { event: Event; isSingleMode: boolean }) => {
  const { isOpen, onToggle } = useDisclosure();
  const participations = event.getParticipationsByStatus('joined');
  const modalMassivePayments = useModal('PAYMENT_MASSIVE');
  const checkPayment = usePermissions('BILL_SET_PAYMENT');
  const participationsToPaid = event.getParticipationsByStatus('joined').filter(p => checkPayment(p.bill, p));
  const sortedParticipations = _.sortBy(participations, p => p.member.getFirstName());
  const showedParticipations = isOpen ? sortedParticipations : [...sortedParticipations].splice(0, 3);
  const isNoParticipants = participations.length === 0;

  const onOpenMassivePaymenModal = () => {
    return modalMassivePayments.onOpen({
      event,
      mutation: {
        keys: ['DASBOARD_EVENTS_TODAY', 'DASHBOARD_EVENTS'],
        fn: mEventInEvents,
      },
    });
  };

  return (
    <Stack flex={1}>
      <Flex {...headerStyles} mx={-30} px="xl">
        <Text fontSize="sm" fontWeight="medium">{`${t('dashboard.participants')}:`}</Text>
        {participationsToPaid.length > 0 && (
          <Button mr="md" variant="link" onClick={onOpenMassivePaymenModal}>
            <Text>{t('labels.settleEveryone')}</Text>
          </Button>
        )}
      </Flex>
      {isNoParticipants && (
        <Flex justifyContent="center" py="xs">
          <Text color="grey" fontWeight="medium">{`${t('dashboard.noJoinedParticipantsInEvent')}.`}</Text>
        </Flex>
      )}
      <Stack spacing="sm" flexDirection="column" divider={<Divider color="new-grey-light-20" />}>
        {showedParticipations.map(participation => (
          <ActiveEventParticipant
            key={participation.id}
            event={event}
            participation={participation}
            isSingleMode={isSingleMode}
          />
        ))}
      </Stack>
      {participations.length > 3 && (
        <>
          <Divider color="new-grey-light-20" />
          <Flex mt="md" justifyContent="flex-end" alignItems="flex-end">
            <Button onClick={onToggle} variant="clean">
              <Ternary cond={!isOpen}>
                <Stack spacing="sm" alignItems="center" isHorizontal>
                  <Text>{t('dashboard.expand')}</Text>
                  <Icons.ArrowDown fill="teal-light" />
                </Stack>
                <Stack spacing="sm" alignItems="center" isHorizontal>
                  <Text>{t('dashboard.collapse')}</Text>
                  <Icons.ArrowUp fill="teal-light" />
                </Stack>
              </Ternary>
            </Button>
          </Flex>
        </>
      )}
    </Stack>
  );
};

export default ActiveEventParticipants;
