import { connect } from 'react-redux';
import axios from 'axios';

import Modal from '@old/components/common/Modal';
import { fetchProposals } from 'store/actions';
import Model from '@old/model';
import { notify } from 'old/utils';
import t from 'resources/translations';
import FlexColumn from '@old/components/common/FlexColumn';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';

const RejectProposalModal = ({ proposal, onClose, proposalsFetchParams, ...actions }) => {
  let source;
  const onClick = async () => {
    const { CancelToken } = axios;
    if (source) {
      source.close();
    }

    source = CancelToken.source();
    try {
      await Model.Proposals.reject(proposal.id);
      actions.fetchProposals(proposalsFetchParams, source.token);
      notify(t('message.proposalRejected'));
    } catch (err) {
      notify(t('error.proposalRejectingFailed'), { type: 'error' });
    }
    onClose();
  };

  if (!proposal) return null;

  return (
    <Modal header={proposal.name} onClose={onClose} isOpen>
      <FlexColumn>
        <div>{t('rejectProposalModal.confirmRejectEvent', { title: proposal.getName() })}</div>
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button onClick={onClick} fluid async>
            {t('buttons.reject')}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

RejectProposalModal.defaultProps = {
  proposal: null,
};

const mapStateToProps = ({ fetchingData: { proposalsFetchParams } }) => ({ proposalsFetchParams });

export default connect(mapStateToProps, { fetchProposals })(RejectProposalModal);
