/* eslint-disable max-len */
import React from 'react';

const Unchecked = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,0C3.6,0,0,3.5,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0C8,0,8,0,8,0z M12,10.9L10.9,12L8,9.1L5.1,12L4,10.9L6.9,8L4,5.1L5.1,4L8,6.9L10.9,4L12,5.1L9.1,8L12,10.9z" />
    </svg>
  );
};

export default Unchecked;
/* eslint-enable max-len */
