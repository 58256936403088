import React from 'react';
import { Flex } from '../elements';
import { ResponsiveValue, ThemeValue } from 'styled-system';
import { DefaultTheme } from 'styled-components';
import { TFlexProps } from 'resources/types/elementsTypes';

type TDot = TFlexProps & {
  children?: React.ReactNode;
  size: ResponsiveValue<ThemeValue<'space', DefaultTheme> | number>;
};
const Dot = ({ children, size, ...flexStyleProps }: TDot) => {
  return (
    <Flex squareSize={size} justifyContent="center" alignItems="center" radius="full" {...flexStyleProps}>
      {children}
    </Flex>
  );
};

Dot.defaultProps = {
  children: null,
};

export default Dot;
