import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';

const Numeric = ({ onChangeField, placeholder, label, newWrapper, ...props }) => {
  const { name, formName, required } = props;

  const onChange = async number => {
    onChangeField({ formName, fieldName: name, fieldState: number });
  };

  return (
    <FormField {...props}>
      <Input.Numeric
        name={name}
        value={props.value}
        label={label}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        newWrapper={newWrapper}
      />
    </FormField>
  );
};

Numeric.defaultProps = {
  value: '',
  placeholder: '',
  label: '',
  required: false,
  newWrapper: false,
};

Numeric.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  newWrapper: PropTypes.bool,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return { value: inputValue };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(Numeric);
