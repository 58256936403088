import React from 'react';

const Home = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M6.4,14.8V10h3.2v4.8h4V8.4H16L8,1.2L0,8.4h2.4v6.4H6.4L6.4,14.8z" />
    </svg>
  );
};

export default Home;
