/**
 * @module RatingField
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';
import { changeFieldState } from 'store/actions';
/**
 * @typedef {Object} Rating
 * @property {String} name field name in the form
 * @property {String} value
 * @property {String} formName
 * @property {Function} onChange
 */

/**
* @typedef {Object} Validation
* @property {String} errorHint
* @property {Function} condition condition to be fulfilled for validation to be carried out
*/

/** A field in the forms with which we add the rating
 * @param  {Function} props.onChangeField function called when field value has changed
 * @param  {String} props.label
 * @param  {Boolean} props.required
 * @param  {Validation} [props.validation]
 * @param  {Rating} props.fieldProps
 */
const RatingField = ({ onChangeField, label, required, validation, ...fieldProps }) => {
  const { name, formName } = fieldProps;
  const onChange = (value) => {
    onChangeField({ formName, fieldName: name, fieldState: value });
  };

  const validationFuncs = [
    ...validation,
  ];

  return (
    <FormField validation={validationFuncs} {...fieldProps}>
      <Input.Rating
        value={fieldProps.value}
        label={label}
        changeRating={onChange}
        required={required}
      />
    </FormField>
  );
};

RatingField.defaultProps = {
  validation: [],
};

RatingField.propTypes = {
  value: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  validation: PropTypes.arrayOf(PropTypes.shape({
    condition: PropTypes.func,
    errorHint: PropTypes.string,
  })),
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value || 0;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(RatingField);
