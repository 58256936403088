import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

import 'simplebar-react/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tippy/dist/tippy.css';
import './resources/styles/cordeo-ui.css';

import App from 'App';
import store from 'store';
import { Provider } from 'react-redux';

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorkerRegistration.register();
