import React from 'react';
import PropTypes from 'prop-types';
import Rollbar from 'rollbar';

import t from 'resources/translations';
import PageTitle from '@old/components/guide/PageTitle';

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_SERVER,
  },
  autoInstrument: {
    log: false, // https://github.com/rollbar/rollbar.js/issues/575#issuecomment-365793796
  },
});

/**
 * Component to catch errors, log those errors, and display a fallback UI
 * @see {@link https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries|Error Boundaries}
 */
class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  /**
   * Lifecycle function to catch error and send info to Rollbar
   * @see {@link https://pl.reactjs.org/docs/react-component.html#componentdidcatch|componentDidCatch docs}
   */
  componentDidCatch(error, _errorInfo) {
    rollbar.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <PageTitle title={t('general.error')} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ErrorBoundary;
