import t from 'resources/translations';
import { getErrorMessage, notify } from 'old/utils';
import { Box, Button } from 'components/elements';
import { Modal, ModalHeader, ModalCloseButton, ModalContent, ModalButtons } from 'components/combinations';
import { TStoreStateModals, TWrappedModal } from '.';
import { TReactNode } from 'resources/types/commonTypes';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import { useShallowSelector } from 'old/hooks';

type TReturnPaymentModalProps = {
  title: string;
  content: TReactNode | string;
  action: {
    mutation: any;
    label: TReactNode | string;
    loadingLabel: string;
    cancelLabel?: string;
  };
  notifications?: {
    success?: string;
    error?: string;
  };
};

const ConfirmModal = ({ onSubmitAndClose, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const { title, content, action, notifications } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['CONFIRM'].props
  ) as TReturnPaymentModalProps;

  const onSubmit = async () => {
    try {
      await action.mutation.mutateAsync();
      notify(notifications?.success || t('message.succesSave'));
    } catch (error) {
      notify(getErrorMessage(error), { type: 'error' });
    }
  };

  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting}>
        <Box>{content}</Box>
      </ModalContent>
      <ModalButtons>
        {!action.mutation.isError && (
          <Button onClick={() => onSubmitAndClose(onSubmit)}>
            {isSubmitting ? <SpinnerDots label={action.loadingLabel} inline /> : action.label}
          </Button>
        )}
        <Button onClick={onClose} variant="outline">
          {action.cancelLabel || <>{!action.mutation.isError ? t('general.cancel') : t('general.close')}</>}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default ConfirmModal;
