import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { truncate } from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';
import moment from 'moment';
import { datesIsSame, parseToCurrencyString } from 'old/utils';
import Error404 from 'old/components/error/Error404';
import Spinner from 'old/components/common/Spinner';
import t from 'resources/translations';
import { Stack, Box, Text, Link, Span, Icons, Flex, Tooltip, Ternary, Content } from 'components/elements';
import { AvatarWithLabel, Card, CardContent, Divider, LinkMember, PageTitle, Wrap } from 'components/combinations';
import { useAppSelector, useNewLayoutColor, useLoggedMember } from 'utils';
import { EventService } from 'services';
import Event from 'models/Event';
import { IEventRes } from 'resources/types/eventsTypes';
import { DATE_FORMAT } from 'config/constans';
import BillDetailsTransactions from './BillDetailsTransactions';
import { TFlexProps } from 'resources/types/elementsTypes';
import { BillHistoryPaymentsModal, PaymentEventModal, PaymentReturnModal } from 'views/modals';

const styles: { container: TFlexProps } = {
  container: {
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: 'white',
    boxShadow: 'base',
    radius: ['sm', 'base'],
  },
};
const BillDetails = () => {
  useNewLayoutColor();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const loggedMember = useLoggedMember();
  const isMobile = useAppSelector(({ app }) => app.isMobile);

  const eventState = useQuery(['BILL_DETAILS', { id }], () => EventService.fetch(id)) as UseQueryResult<IEventRes>;
  const event = eventState.data ? new Event(eventState.data) : null;
  const eventInstructorOrOwner = loggedMember?.isOwner() || event?.getInstructorsIds().includes(loggedMember?.id);

  useEffect(() => {
    if (!eventInstructorOrOwner && !!event) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInstructorOrOwner, history]);

  if (eventState.isError) return <Error404 />;
  if (eventState.isLoading || !eventInstructorOrOwner) return <Spinner />;
  if (!event) return null;

  const { place } = event.getPlace().getSlugInfo();

  const renderDate = `${moment(event.startDate).format(DATE_FORMAT)}${
    !datesIsSame(moment(event.startDate), moment(event.endDate))
      ? ` / ${moment(event.endDate).format(DATE_FORMAT)}`
      : ''
  }`;
  const getOtherInstructors = event.getInstructors().filter(instructor => instructor.id !== loggedMember?.id);
  const getInstructors = loggedMember?.isInstructor() ? getOtherInstructors : event.getInstructors();

  return (
    <Content>
      <Stack spacing="xl">
        <PageTitle wordBreak="break-word" title={event.getName()} back loading={eventState.isLoading} />
        <Card>
          <CardContent p="md" spacing="md">
            <Stack alignItems="center" isHorizontal>
              <Icons.HorseRiderCircle bgColor="grey-lighter-40" color="cod-gray" />
              <Text fontWeight="medium">{t('bills.event')}</Text>
            </Stack>
            <Divider color="rgba(209, 209, 208, 0.3)" variant="solid" />
            <Stack isHorizontal={!isMobile} mb="sm">
              <Stack flex={3} spacing="md">
                <Text fontWeight="medium">
                  {`${t('labels.date')}: `}
                  <Span fontWeight="normal">{renderDate}</Span>
                </Text>
                <Text fontWeight="medium">
                  {`${t('bills.duration')}: `}
                  <Span fontWeight="normal">
                    {moment(event.startDate).format('HH:mm')} - {moment(event.endDate).format('HH:mm')}
                  </Span>
                </Text>
              </Stack>
              <Stack flex={5}>
                <Text fontWeight="medium">
                  {`${t('bills.eventCost')}: `}
                  <Span fontWeight="regular">{parseToCurrencyString(event.dueAmount)}</Span>
                </Text>
                <Text fontWeight="medium">
                  {`${t('bills.deposit')}: `}
                  <Span fontWeight="regular">{parseToCurrencyString(event.paidAmount)}</Span>
                </Text>
              </Stack>
              <Stack flex={2} spacing="sm" justifyContent="center">
                <Stack spacing="sm" alignItems="center" isHorizontal>
                  <Icons.PlaceWithoutFillingCirlce bgColor="new-grey-light" color="white" />
                  <Box w="initial">
                    <Link fontWeight="medium" to={`/places/${place.id}`}>
                      {truncate(place.name, { length: 25 })}
                    </Link>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
            {getInstructors.length !== 0 && (
              <Stack spacing="sm">
                <Text fontWeight="medium">
                  {loggedMember?.isInstructor() ? `${t('bills.otherEmployees')}:` : `${t('bills.employees')}:`}
                </Text>
                <Wrap spacing="sm">
                  {getInstructors.map(instructor => (
                    <AvatarWithLabel
                      key={instructor.id}
                      src={instructor.getAvatar('medium')}
                      title={instructor.getFullName()}
                      alt={instructor.getFullName()}
                    >
                      <LinkMember member={instructor} mr="md" fontSize="md">
                        {instructor.getFullName()}
                      </LinkMember>
                    </AvatarWithLabel>
                  ))}
                </Wrap>
              </Stack>
            )}
          </CardContent>
        </Card>
        {!isMobile && (
          <Flex {...styles.container}>
            <Stack alignItems="center" px="lg" py="md" isHorizontal>
              <Icons.UsersCircle bgColor="grey-lighter-40" />
              <Text fontWeight="medium">{t('bills.participantTransactions')}</Text>
            </Stack>
            <Flex bgColor="grey-lighter-40" p="md">
              <Flex flex={4} pl="sm">
                <Text fontWeight="medium">{t('general.client')}</Text>
              </Flex>
              <Flex flex={3}>
                <Text fontWeight="medium">{t('bills.due')}</Text>
              </Flex>
              <Flex flex={3}>
                <Text fontWeight="medium">{t('bills.deposit')}</Text>
              </Flex>
              <Flex flex={3}>
                <Text fontWeight="medium">{t('bills.paymentStatus')}</Text>
              </Flex>
              <Flex flex={4} />
            </Flex>
            <Box>
              {(event.participations || []).map((participation, index) => {
                const isDivider = event.participations.length !== index + 1 && !isMobile;
                return <BillDetailsTransactions key={index} participation={participation} isDivider={isDivider} />;
              })}
            </Box>
          </Flex>
        )}
        {isMobile && (
          <Stack spacing="sm">
            <Card>
              <CardContent p="md" spacing="md">
                <Stack alignItems="center" isHorizontal>
                  <Icons.UsersCircle bgColor="grey-lighter-40" />
                  <Text fontWeight="medium">{t('bills.participantTransactions')}</Text>
                </Stack>
              </CardContent>
            </Card>
            {(event.participations || []).map((participation, index) => {
              const isDivider = event.participations.length !== index + 1 && !isMobile;
              return <BillDetailsTransactions key={index} participation={participation} isDivider={isDivider} />;
            })}
          </Stack>
        )}
        {event.horses.length !== 0 && (
          <Flex {...styles.container}>
            <Stack alignItems="center" px="lg" py="md" isHorizontal>
              <Icons.HorseCircle bgColor="grey-lighter-40" color="cod-gray" />
              <Text fontWeight="medium">{t('bills.horses')}</Text>
            </Stack>
            <Ternary cond={!isMobile}>
              <Flex bgColor="grey-lighter-40" p="md">
                <Flex flex={3} pl="sm">
                  <Text fontWeight="medium">{t('general.name')}</Text>
                </Flex>
                <Flex flex={7}>
                  <Text fontWeight="medium">{t('general.level')}</Text>
                </Flex>
              </Flex>
              <Box px="md">
                <Divider width="100%" color="new-grey-light-30" />
              </Box>
            </Ternary>
            {event.horses.map((horse, index) => (
              <Box px="md">
                <Flex py="md" w="100%">
                  <Flex flex={3} pl="sm">
                    <AvatarWithLabel
                      title={horse.getName()}
                      src={horse.getAvatar('medium')}
                      alt={horse.getName()}
                      squareSize={26}
                    >
                      <Link to={`/horses/${horse.id}`}>{horse.getName()}</Link>
                    </AvatarWithLabel>
                  </Flex>
                  <Flex flex={7} justifyContent={['flex-end', 'flex-start']}>
                    {/* @ts-ignore */}
                    <Tooltip content={t(`skills.${horse.getSkill()}`)}>
                      <Flex flex="none" w={26} h={26} ml="sm">
                        <Flex w={26} bgColor={horse.getSlugInfo().color} flex={1} borderRadius={100}>
                          <Flex alignSelf="center" w="100%" justifyContent="center">
                            <Text color="white" fontSize="xs">
                              {horse.getSlugInfo().text}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Tooltip>
                  </Flex>
                </Flex>
                {event.horses.length !== index + 1 && <Divider width="100%" color="new-grey-light-30" />}
              </Box>
            ))}
          </Flex>
        )}
      </Stack>
      <PaymentEventModal />
      <PaymentReturnModal />
      <BillHistoryPaymentsModal />
    </Content>
  );
};

export default BillDetails;
