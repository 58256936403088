import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';

const NumberRangeField = ({ onChangeField, options, ...props }) => {
  const { name, value, formName } = props;

  const onChange = (values) => {
    onChangeField({ formName, fieldName: name, fieldState: values });
  };

  return (
    <FormField {...props}>
      <Input.NumberRange value={value} onChange={onChange} {...options} />
    </FormField>
  );
};

NumberRangeField.defaultProps = {
  value: [0, 5],
  options: {},
};

NumberRangeField.propTypes = {
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.shape({}), // options are additional button props
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(NumberRangeField);
