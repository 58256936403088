import { AxiosResponse as ARes } from 'axios';
import HTTP from 'config/axios';
import Member from 'models/Member';
import { IResWithPagination, TFetchedOptions, TOption } from 'resources/types/commonTypes';
import { IFarmRes } from 'resources/types/farmsTypes';
import {
  IMemberFetchParams,
  IMemberReq,
  IMemberRes,
  IProfileRes,
  IUserReq,
  IUserRes,
} from 'resources/types/membersTypes';

type TLoginToFarmRes = { profile: IProfileRes; farm: IFarmRes };

class MemberService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async fetch(memberId: number | string) {
    const response: ARes<IMemberRes> = await HTTP.get(`${this.apiBaseURL}/memberships/${memberId}`);
    return response.data;
  }

  async fetchProfile() {
    const response: ARes<IProfileRes> = await HTTP.get(`${this.apiBaseURL}/profile`);
    return response.data;
  }

  async fetchAll(params: IMemberFetchParams) {
    const response: ARes<IResWithPagination<IMemberRes[]>> = await HTTP.get(`${this.apiBaseURL}/memberships`, {
      params,
    });
    return response.data;
  }

  async update(memberId: number | string, changes: IMemberReq) {
    const response: ARes<IMemberRes> = await HTTP.patch(`${this.apiBaseURL}/memberships/${memberId}`, changes);
    return response.data;
  }

  async deleteUser() {
    const response: ARes<IMemberRes> = await HTTP.delete(`/api/${process.env.REACT_APP_API_VERSION}/users/`);
    return response;
  }

  async delete(memberId: number) {
    const response: ARes<null> = await HTTP.delete(`${this.apiBaseURL}/memberships/${memberId}`);
    return response.data;
  }

  async updateUser(userId: number | string, changes: IUserReq) {
    const response: ARes<IMemberRes> = await HTTP.patch(`/api/${process.env.REACT_APP_API_VERSION}/users/${userId}`, {
      user: changes,
    });
    return response.data;
  }

  async logout() {
    const response: ARes<null> = await HTTP.delete(`/logout`);
    return response.data;
  }

  async login(email: string, password: string, cancelToken?: any): Promise<[string, IUserRes]> {
    const response: ARes<IUserRes> = await HTTP.post(`/login`, { user: { email, password } }, { cancelToken });
    const token = response.headers.authorization;
    return [token, response.data];
  }

  async sendSmsActivation() {
    const response: ARes<{ sending_sms_window_at: string }> = await HTTP.post('/login/send_sms');
    return response.data;
  }

  async confirmPhone(code: string) {
    const response = await HTTP.post('/login/verify_sms_code', { sms_code: code });
    return response.data;
  }

  async loginToFarm(email: string, password: string, cancelToken?: any): Promise<[string, TLoginToFarmRes]> {
    const slug = window.location.pathname.split('/')[1];
    const data = {
      user: { email, password },
    };
    const options = { cancelToken };
    const response: ARes<{ identity: IProfileRes; farm: IFarmRes }> = await HTTP.post(
      `/farms/${slug}/login`,
      data,
      options
    );
    const token = response.headers.authorization;
    return [token, { profile: response.data.identity, farm: response.data.farm }];
  }

  async socialLogin(
    service: 'facebook' | 'google',
    idToken: string,
    user?: Partial<IUserReq>,
    cancelToken?: any
  ): Promise<[string, IUserRes]> {
    const params = {
      id_token: idToken,
      ...(user ? { user } : {}),
    };
    const response: ARes<IUserRes> = await HTTP.post(`/login/${service}`, params, { cancelToken });
    const token = response.headers.authorization;
    return [token, response.data];
  }

  async fetchOptions(keyword: string, additional: { page: number }, params: IMemberFetchParams) {
    const page = additional?.page || 1;
    const { memberships, pagination } = await this.fetchAll({
      per_page: 20,
      keyword,
      page,
      ...params,
    });

    const mappedOptions = memberships.map(membership => {
      const member = new Member(membership);
      return {
        value: member.id,
        label: member.getFullName(),
        type: 'member',
        data: {
          image: member.getAvatar('thumb'),
          skill: member.getSkill(),
          isGuest: member.isGuest(member.identity),
          isDeleted: member.isDeleted(),
        },
      } as TOption;
    });

    const optionsData: TFetchedOptions = {
      options: mappedOptions,
      hasMore: pagination.pages > page,
      additional: { page: pagination.next },
    };

    return optionsData;
  }
}

export default new MemberService();
