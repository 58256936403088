import React from 'react';
import PropTypes from 'prop-types';

const TitleWhite = ({ children }) => <div className="text-title-white">{children}</div>;

TitleWhite.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

export default TitleWhite;
