import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '../common/FormField';
import { changeFieldState } from '../../../store/actions';
import Input from '../input';

const AddAvatarField = ({ onChangeField, ...props }) => {
  const { formName, name, avatars, label } = props;
  const onChange = (value) => {
    onChangeField({ formName, fieldName: name, fieldState: value });
  };

  return (
    <FormField {...props}>
      <Input.AddAvatar
        value={props.value}
        onChange={onChange}
        avatars={avatars}
        label={label}
      />
    </FormField>
  );
};

AddAvatarField.defaultProps = {
  value: null,
  label: '',
};

AddAvatarField.propTypes = {
  value: PropTypes.string,
  formName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([
        PropTypes.string,
      ]).isRequired,
    }),
  ).isRequired,
  label: PropTypes.string,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(AddAvatarField);
