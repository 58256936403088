import moment from 'moment';
import { sortBy } from 'lodash';
import SimpleBar from 'simplebar-react';

import Modal from '@old/components/common/Modal';
import Icon from '@old/components/icon';
import t from 'resources/translations';
import RowAligner from '@old/components/common/RowAligner';
import TagCircle from '@old/components/common/TagCircle';
import FlexRow from '@old/components/common/FlexRow';
import FlexColumn from '@old/components/common/FlexColumn';
import Link from '@old/components/common/Link';
import { useModelItems } from '@old/hooks';
import Spinner from '@old/components/common/Spinner';
import config from '@old/config';

const HorseStatisticModal = ({ date, onClose, horseName, horseId }) => {
  const [events, eventsStatus] = useModelItems('events', {
    in: moment(date).format('YYYY-MM-DD'),
    with_horse: [horseId],
    with_status: ['finished', 'ongoing', 'awaiting', 'active'],
  });

  const eventsSortedByDate = sortBy(events, event => event.getStartDateTime());
  const initValues = {
    horses: [{ value: horseId, label: horseName }],
    startDate: date
      .hour(moment().hour() + 1)
      .startOf('hour')
      .format(config.dateTimeFormat),
  };

  if (!horseId) return null;

  return (
    <Modal header={`${horseName} / ${date.format('dddd, DD.MM.YYYY')}`} onClose={onClose} isOpen>
      <Link to={{ pathname: '/events/add', state: { initValues } }}>
        <RowAligner separated="small" marginBottom>
          <Icon.Add circle />
          <div className="text-highlight">{t('model.add.events')}</div>
        </RowAligner>
      </Link>

      {eventsStatus.isPending && (
        <div className="relative h-24">
          <Spinner noDelay />
        </div>
      )}

      {eventsStatus.isSuccess && events.length && (
        <SimpleBar>
          {eventsSortedByDate.map(event => (
            <StatisticsItem key={event.id} startDate={event.startDate} endDate={event.endDate} event={event} />
          ))}
        </SimpleBar>
      )}
    </Modal>
  );
};

export default HorseStatisticModal;

const StatisticsItem = ({ startDate, endDate, event }) => {
  return (
    <div className="horse-statistic-item">
      <FlexRow cols="1/none">
        <FlexColumn separated="small">
          <div className="text-title-teal font-bold">
            {`${moment(startDate).format('HH:mm')} - ${moment(endDate).format('HH:mm')}`}
          </div>
          <div className="text-title-md">{event.name}</div>
        </FlexColumn>
        <TagCircle small color={event.type.getColor()}>
          {event.type.slug}
        </TagCircle>
      </FlexRow>
    </div>
  );
};
