import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openModal } from 'store/actions';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import ImageCircle from '@old/components/guide/ImageCircle';
import Icon from '@old/components/icon';
import Allow from '@old/components/common/Allow';
import Center from '@old/components/common/Center';

const EditableAvatar = ({ modelItem, openModalByName, refreshAction }) => {
  return (
    <Center className="rounded-full inline-block bg-cover relative">
      <ImageCircle src={modelItem.getAvatar()} large />
      <Allow permissionKey={`${modelItem.modelName}.update`}>
        <ButtonSimple onClick={() => openModalByName('editAvatar', { modelItem, refreshAction })}>
          <div className="absolute z-10 bg-white h-10 w-10 rounded-full flex justify-center top-2 right-1">
            <Icon.Edit className="fill-teal" small />
          </div>
        </ButtonSimple>
      </Allow>
    </Center>
  );
};

EditableAvatar.propTypes = {
  openModalByName: PropTypes.func.isRequired,
  refreshAction: PropTypes.func.isRequired,
};

export default connect(null, { openModalByName: openModal })(EditableAvatar);
