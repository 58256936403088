/* eslint-disable max-len */
import React from 'react';

const Cake = () => {
  return (
    <svg width="100%" viewBox="0 0 25 25">
      <path d="M21,5.1c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.1,1.6-3.5,1.9-4c0,0,0.1-0.1,0.1-0.1 c0.2-0.2,0.5-0.1,0.7,0.1C19.4,1.6,21,4,21,5.1z" />
      <path d="M8.6,5.1c0,1.3-1,2.3-2.3,2.3S4,6.3,4,5.1C4,4,5.9,1.2,5.9,1.2c0.3-0.5,0.6-0.3,0.8-0.1C7,1.6,8.6,4,8.6,5.1z" />
      <path d="M14.8,4.2c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.1,1.6-3.5,1.9-4c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.1,0.7,0.1C13.2,0.7,14.8,3,14.8,4.2z" />
      <path d="M24.8,18v-3.5c0-1.1-0.9-2-2.1-2h-1.6V8.6c0-0.2-0.2-0.4-0.4-0.4h-2.5c-0.2,0-0.4,0.2-0.4,0.4v3.9h-3.7V7.8
        c0-0.2-0.2-0.4-0.4-0.4h-2.5c-0.2,0-0.4,0.2-0.4,0.4v4.7H7.2V8.6c0-0.2-0.2-0.4-0.4-0.4H4.3c-0.2,0-0.4,0.2-0.4,0.4v3.9H2.2
        c-1.1,0-2,0.9-2.1,2V18c0,0.9,0.5,1.6,1.2,2v4.2H0.6c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h23.8c0.2,0,0.4-0.2,0.4-0.4
        c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0h-0.8V20C24.3,19.6,24.8,18.8,24.8,18z M23,19.4c-0.6,0.3-1.3,0.1-1.8-0.3
        c-0.3-0.3-0.5-0.7-0.5-1.1v-1.6c0-1.5-1.6-1.6-1.6-1.6c0,0-1.7-0.1-1.7,1.6v0.8c0,0.9-0.8,1.6-1.7,1.6c-0.8,0-1.5-0.7-1.6-1.6v-0.8
        c0-0.6-0.2-1.5-1.5-1.5c-1.6,0-1.8,1.1-1.8,1.5v2.3c0,0.9-0.8,1.6-1.7,1.6c-0.8,0-1.5-0.7-1.6-1.6v-2.3c0-1.6-1.6-1.6-1.6-1.6
        c-0.9,0-1.6,0.7-1.6,1.6v1.6c0,0.4-0.2,0.8-0.5,1.1c-0.4,0.4-1,0.5-1.5,0.4C1.7,19.3,1,18.9,1,18v-3.5c0-0.7,0.6-1.2,1.2-1.2h20.5
        c0.7,0,1.2,0.5,1.2,1.2V18C24,18.6,23.6,19.2,23,19.4z"
      />
    </svg>
  );
};

export default Cake;
/* eslint-enable max-len */
