import { useEffect, useLayoutEffect } from 'react';
import ReactGA from 'react-ga';
import queryString from 'query-string';
import config from './old/config';
import { useActions } from './old/hooks';
import { closeModal } from './store/actions/modalsActions';
import { useAppSelector, useFilters } from './utils';
import { useHistory, useLocation } from 'react-router';
import { TBillFilters } from 'resources/types/billsTypes';
import { setBreakpoints, setRedirect } from 'store/app';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const AppEffects = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { onChangeFilters } = useFilters<TBillFilters>('BILLS');
  const [redirectTo, modalsList] = useAppSelector(({ app, modals }) => [app.redirectTo, modals]);
  const close = useActions(closeModal);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1199 });

  useEffect(() => {
    dispatch(setBreakpoints({ isMobile, isTablet }));
  }, [isMobile, isTablet, dispatch]);

  useEffect(() => {
    if (config.googleAnalyticsEnabled) {
      if (['production', 'testing'].includes(process.env.REACT_APP_SERVER)) {
        const enviroments = process.env.REACT_APP_SERVER === 'production' ? 'production' : 'testing';
        ReactGA.initialize(config.googleAnalyticsCodes[enviroments]);
      }
    }
  }, []);

  useLayoutEffect(() => {
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', setViewportHeight);
    setViewportHeight();
    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  useEffect(() => {
    if (redirectTo) {
      dispatch(setRedirect(null));
      history.push(redirectTo);
    }
  }, [redirectTo, dispatch, history]);

  useEffect(() => {
    modalsList.map(modal => close(modal.name));
    if (!location.pathname.includes('bills')) {
      onChangeFilters({});
    }
  }, [location.pathname, close]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
    if (config.googleAnalyticsEnabled) {
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile) {
      document.body.classList.add('is-mobile');
    } else {
      document.body.classList.remove('is-mobile');
    }
  }, [isMobile]);

  useEffect(() => {
    if (queryString.parse(window.location.search).error === 'token_expired') {
      history.replace('/');
    }
  }, [window.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return children;
};

export default AppEffects;
