import React from 'react';

import PageTitle from '@old/components/guide/PageTitle';
import t from 'resources/translations';

const AccessDenied = () => (
  <PageTitle title={t('message.accessDenied')} />
);

export default AccessDenied;
