import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changeFieldState } from 'store/actions';
import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';

const CheckboxMultiField = ({ onChangeField, label, options, ...props }) => {
  const { formName, name, value, required } = props;

  const onChange = (isChecked, inputName, inputLabel) => {
    const fieldState = isChecked
      ? [...value, { value: inputName, label: inputLabel }]
      : [...value].filter(item => item.value !== inputName);
    onChangeField({ formName, fieldName: name, fieldState });
  };

  return (
    <FormField {...props}>
      {label && (
        <label className="block font-bold text-md mb-2">
          {label}{required && <span className="asterisk" />}
        </label>
      )}
      <div>
        {options.map((option) => {
          const isChecked = !!value.find(val => val.value === option.value);
          return (
            <Input.Checkbox
              key={`checkbox-${option.value}`}
              onChange={onChange}
              name={option.value}
              label={option.label}
              value={isChecked}
            />
          );
        })}
      </div>
    </FormField>
  );
};

CheckboxMultiField.defaultProps = {
  value: [],
  name: null,
  required: false,
};

CheckboxMultiField.propTypes = {
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    }),
  ),
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = ({ form }, props) => {
  return {
    value: form[props.formName][props.name]?.value,
  };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(CheckboxMultiField);
