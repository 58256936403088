import React from 'react';
import { Box, Flex, Link, Span, Stack, Ternary, Text } from 'components/elements';
import { DATE_FORMAT, DATE_TIME_FORMAT_WITH_COMMA } from 'config/constans';
import moment from 'moment';
import t from 'resources/translations';
import { ITicketAudit } from 'resources/types/TicketTypes';
import { useAppSelector, useLoggedMember } from 'utils';

type TTicketItem = {
  audit: ITicketAudit;
  index: number;
};

const TicketAudtitItem = ({ audit, index }: TTicketItem) => {
  const loggedMember = useLoggedMember();
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const auditDateExpireAt = moment(audit.old.expireAt).format(DATE_FORMAT);
  const auditDateActivation = moment(audit.old.activeAt).format(DATE_FORMAT);
  return (
    <Box
      p={['sm', 'md']}
      mx={['-10px', '-20px']}
      key={index}
      bgColor={(index + 1) % 2 === 0 ? 'new-grey-light-10' : 'white'}
    >
      <Stack spacing="sm">
        {
          {
            update: (
              <Stack spacing="xs" width={isMobile ? '100%' : 'auto'}>
                {audit.old.count || audit.new.count ? (
                  <>
                    {isMobile && (
                      <Flex justifyContent="space-between" width="100%">
                        <Text fontWeight="light">{moment(audit.createdAt).format(DATE_TIME_FORMAT_WITH_COMMA)}</Text>
                        <Stack spacing="xs" justifyContent="center" alignSelf="start">
                          <Text fontSize="sm" color="teal-dark">
                            {t('ticketDetails.ridesAvailable')}:
                          </Text>
                          <Text fontWeight="bold" fontSize="md" textAlign="right">
                            {audit.new.count}
                          </Text>
                        </Stack>
                      </Flex>
                    )}
                    <Flex justifyContent="space-between">
                      <Stack spacing="sm">
                        {!isMobile && (
                          <Text fontWeight="light">{moment(audit.createdAt).format(DATE_TIME_FORMAT_WITH_COMMA)}</Text>
                        )}
                        {audit.associated ? (
                          <Stack isHorizontal={!isMobile} spacing="xs">
                            <Text>
                              {(audit.old.count || 0) > (audit.new.count || 0)
                                ? t('ticketDetails.paymentTicketForEvent')
                                : t('ticketDetails.returnForEvent')}
                              :
                            </Text>
                            <Link to={`/events/${audit.associated.id}`}>{audit.associated?.name}</Link>
                          </Stack>
                        ) : (
                          <Text>{t('ticketDetails.changedNumberRides')}</Text>
                        )}
                        <Stack isHorizontal spacing="xs">
                          <Text>
                            {audit.associated
                              ? (audit.old.count || 0) > (audit.new.count || 0)
                                ? t('ticketDetails.paidBy')
                                : t('bills.returnedBy')
                              : t('ticketDetails.editedBy')}
                            :
                          </Text>
                          {loggedMember?.isClient() ? (
                            <Text>{audit.moderator?.fullName}</Text>
                          ) : (
                            <Link to={`/instructors/${audit.moderator?.membershipId}`}>
                              {audit.moderator?.fullName}
                            </Link>
                          )}
                        </Stack>
                      </Stack>
                      {!isMobile && (
                        <Stack spacing="xs" justifyContent="center" alignSelf="start">
                          <Text fontSize="sm" color="teal-dark">
                            {t('ticketDetails.ridesAvailable')}:
                          </Text>
                          <Text fontWeight="bold" fontSize="md" textAlign="right">
                            {audit.new.count}
                          </Text>
                        </Stack>
                      )}
                    </Flex>
                  </>
                ) : (
                  <Text fontWeight="light">{moment(audit.createdAt).format(DATE_TIME_FORMAT_WITH_COMMA)}</Text>
                )}
                {((!audit.old.expireAt && audit.old.activeAt) || (audit.old.expireAt && !audit.old.activeAt)) && (
                  <Stack spacing="xs">
                    <Text>
                      {audit.old.expireAt
                        ? t('ticketDetails.changeDateTicket')
                        : t('ticketDetails.changeDateActivationTicket')}
                    </Text>
                    <Stack isHorizontal spacing="xs" mb="sm">
                      <Text>{t('ticketDetails.editedBy')}:</Text>
                      {loggedMember?.isClient() ? (
                        <Text>{audit.moderator?.fullName}</Text>
                      ) : (
                        <Link to={`/instructors/${audit.moderator?.membershipId}`}>{audit.moderator?.fullName}</Link>
                      )}
                    </Stack>
                    <Text fontWeight="medium">
                      {audit.new.expireAt
                        ? `${t('ticketDetails.newExpirationDate')}: `
                        : `${t('ticketDetails.newActivationDate')}: `}
                      <Span fontWeight="normal">
                        {audit.new.expireAt
                          ? moment(audit.new.expireAt).format(DATE_FORMAT)
                          : moment(audit.new.activeAt).format(DATE_FORMAT)}
                      </Span>
                    </Text>
                  </Stack>
                )}
                {audit.old.expireAt && audit.old.activeAt && (
                  <Stack spacing="xs">
                    <Text>{t('tickets.periodValidityOfTicketChanged')}</Text>
                    <Stack isHorizontal spacing="xs" mb="sm">
                      <Text>{t('ticketDetails.editedBy')}:</Text>
                      {loggedMember?.isClient() ? (
                        <Text> {audit.moderator?.fullName}</Text>
                      ) : (
                        <Link to={`/instructors/${audit.moderator?.membershipId}`}>{audit.moderator?.fullName}</Link>
                      )}
                    </Stack>
                    <Stack spacing="xs">
                      <Text fontWeight="medium">
                        {`${t('ticketDetails.newExpirationDate')}: `}
                        <Span fontWeight="normal">{moment(audit.new.expireAt).format(DATE_FORMAT)}</Span>
                      </Text>
                      <Text fontWeight="medium">
                        {`${t('ticketDetails.newActivationDate')}: `}
                        <Span fontWeight="normal">{moment(audit.new.activeAt).format(DATE_FORMAT)}</Span>
                      </Text>
                    </Stack>
                  </Stack>
                )}
                {audit.old?.status && (
                  <Stack spacing="xs">
                    <Text>
                      {audit.old?.status?.includes('unpaid')
                        ? t('ticketDetails.ticketActivated')
                        : t('ticketDetails.ticketReturn')}
                    </Text>
                    {audit.moderator?.fullName ? (
                      <Stack isHorizontal spacing="xs">
                        <Text>
                          {audit.old?.status?.includes('unpaid') ? t('ticketDetails.paidBy') : t('bills.returnedBy')}:
                        </Text>
                        {loggedMember?.isClient() ? (
                          <Text>{audit.moderator?.fullName}</Text>
                        ) : (
                          <Link to={`/instructors/${audit.moderator?.membershipId}`}>{audit.moderator?.fullName}</Link>
                        )}
                      </Stack>
                    ) : (
                      <Text>{t('ticketDetails.paidWithOnlinePayment')}</Text>
                    )}
                  </Stack>
                )}
              </Stack>
            ),
            create: (
              <Flex justifyContent="space-between">
                <Stack spacing="sm" width={isMobile ? '100%' : 'auto'}>
                  <Ternary cond={isMobile}>
                    <Flex justifyContent="space-between" width="100%">
                      <Text fontWeight="light">{moment(audit.createdAt).format(DATE_TIME_FORMAT_WITH_COMMA)}</Text>
                      <Stack spacing="xs" justifyContent="center" alignSelf="start">
                        <Text fontSize="sm" color="teal-dark">
                          {t('ticketDetails.ridesAvailable')}:
                        </Text>
                        <Text fontWeight="bold" fontSize="md" textAlign="right">
                          {audit.old.countLimit}
                        </Text>
                      </Stack>
                    </Flex>
                    <Text fontWeight="light">{moment(audit.createdAt).format(DATE_TIME_FORMAT_WITH_COMMA)}</Text>
                  </Ternary>
                  <Text>{t('ticketDetails.ticketBennAssigned')}</Text>
                  <Stack isHorizontal spacing="xs">
                    <Text>{`${t('ticketDetails.ticketCreate')}: `}</Text>
                    {audit.moderator && (
                      <>
                        {loggedMember?.isClient() ? (
                          <Text ml="xs">{audit.moderator?.fullName}</Text>
                        ) : (
                          <Link ml="xs" to={`/instructors/${audit.moderator.membershipId}`}>
                            {audit.moderator?.fullName}
                          </Link>
                        )}
                      </>
                    )}
                  </Stack>
                  <Text fontWeight="medium">
                    {`${t('ticketDetails.expirationDate')}: `}
                    <Span fontWeight="normal">{auditDateExpireAt}</Span>
                  </Text>
                  <Text fontWeight="medium">
                    {`${t('ticketDetails.activationDate')}: `}
                    <Span fontWeight="normal">{auditDateActivation}</Span>
                  </Text>
                </Stack>
                {!isMobile && (
                  <Stack spacing="xs" justifyContent="center" alignSelf="start">
                    <Text fontSize="sm" color="teal-dark">
                      {t('ticketDetails.ridesAvailable')}:
                    </Text>
                    <Text fontWeight="bold" fontSize="md" textAlign="right">
                      {audit.old.countLimit}
                    </Text>
                  </Stack>
                )}
              </Flex>
            ),
          }[audit.action]
        }
        {audit.comment && (
          <Text fontWeight="medium">
            {`${t('ticketDetails.reason')}: `}
            <Span fontWeight="normal">{audit.comment}</Span>
          </Text>
        )}
      </Stack>
    </Box>
  );
};

export default React.memo(TicketAudtitItem);
