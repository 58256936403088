import InputWrapper from 'components/combinations/forms/InputWrapper';
import { Input } from 'components/elements';
import { TReactNode } from 'resources/types/commonTypes';

type TTextInput = {
  value: string;
  name: string;
  placeholder: string;
  children?: TReactNode;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextInput = ({ value, name, placeholder, onChange, children, ...inputProps }: TTextInput) => {
  return (
    <InputWrapper>
      <Input
        id={name}
        type="text"
        name={name}
        value={value}
        onChange={e => onChange(e)}
        placeholder={placeholder}
        {...inputProps}
        pl={20}
        pr={!!children ? 0 : 20}
      />
      {!!children && <>{children}</>}
    </InputWrapper>
  );
};

TextInput.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
};

export default TextInput;
