import { SET_PAGINATION, CHANGE_CURRENT_PAGE, RESET_CURRENT_PAGE } from 'store/actions/paginationActions';

const initialState = {};

const paginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGINATION:
      return {
        ...state,
        [action.key]: { ...action.pagination },
      };
    case CHANGE_CURRENT_PAGE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          current: action.current,
        },
      };
    case RESET_CURRENT_PAGE:
      if (state[action.key].current === 1) return state;
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          current: 1,
        },
      };
    default:
      return state;
  }
};

export default paginationReducer;
