import React from 'react';
import t from 'resources/translations';
import Event from 'models/Event';
import MyEventBar from 'views/Dashboard/MyEventBar';
import MyEventInfo from 'views/Dashboard/MyEventInfo';
import MyEventParticipants from 'views/Dashboard/MyEventParticipants';
import MyEventClientManage from 'views/Dashboard/MyEventClientManage';
import { Button, Flex, Stack, Ternary, Text, Icons } from 'components/elements';
import { Accordion, AccordionContent, AccordionHeader, Divider } from 'components/combinations';
import { useAppSelector, useLoggedMember } from 'utils';
import { TFlexProps } from 'resources/types/elementsTypes';

const styles: { container: TFlexProps } = {
  container: {
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: 'white',
    boxShadow: 'base',
    radius: 8,
  },
};

type TMyEvents = {
  events: Event[];
  statuses: { isTodayFetched: boolean; isLoading: boolean; isLoadingMore: boolean; isNoMoreEvents: boolean };
  onMoreEvents: () => void;
};

const MyEvents = ({ events, statuses, onMoreEvents, ...flexProps }: TMyEvents) => {
  const loggedMember = useLoggedMember();
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const isNoEvents = !events.length && !statuses.isLoading && !statuses.isLoadingMore;
  const checkIsActivityInEvents = (event: Event) => {
    const requested = event.getParticipationsByStatus('invitation_requested');
    const isRequestedToAccepted = requested.length > 0 && requested.some(p => p.privileges.includes('accept'));
    const { memberIsInvited, isAwaiting, isActive, isFull } = event.getStatuses(loggedMember?.id);
    const isActiveInvited = memberIsInvited && (isAwaiting || isActive) && !isFull && event.canI('join');
    return loggedMember?.isClient() ? isActiveInvited : isRequestedToAccepted;
  };

  return (
    <Flex {...styles.container} {...flexProps}>
      <Stack alignItems="center" p="md" isHorizontal>
        <Icons.EventsCircle bgColor="grey-lighter-40" />
        <Text fontWeight="medium">{t('dashboard.myEvents')}</Text>
      </Stack>
      {isNoEvents && <Divider color="grey-lighter-40" />}
      {!isMobile && (
        <Flex bgColor="grey-lighter-40" p="md">
          <Flex flex={2} pl="2xl">
            <Text fontWeight="medium">{t('labels.date')}</Text>
          </Flex>
          <Flex flex={6}>
            <Text fontWeight="medium">{t('labels.event')}</Text>
          </Flex>
          <Flex flex={3}>
            <Text fontWeight="medium">{t('labels.status')}</Text>
          </Flex>
          <Flex flex={3}>
            <Text fontWeight="medium">{t('labels.instructors')}</Text>
          </Flex>
          <Ternary cond={!!loggedMember?.isClient()}>
            <Flex flex={3} />
            <Flex flex={3}>
              <Text fontWeight="medium">{t('labels.joinedParticipants')}</Text>
            </Flex>
          </Ternary>
          {/* Space for warning icons for instructors or owners */}
          {!loggedMember?.isClient() && <Flex />}
        </Flex>
      )}

      {isNoEvents ? (
        <Text my="md" textAlign="center" color="grey">
          {statuses.isTodayFetched ? t('message.noEventsToday') : t('message.eventLoading')}
        </Text>
      ) : (
        <React.Fragment>
          <Accordion p={[0, 'md']}>
            {(events || []).map((event, index) => {
              const isShowBell = checkIsActivityInEvents(event);
              const mobileBgColor = index % 2 === 0 && isMobile ? 'new-grey-light-15' : 'white';
              return [
                <AccordionHeader key={`header-${event.id}`} position="relative" bgColor={mobileBgColor}>
                  {accordionProps => {
                    const isDivider = events.length !== index + 1 && !isMobile;
                    return (
                      <>
                        <MyEventBar key={event.id} event={event} isShowBell={isShowBell} {...accordionProps} />
                        {isDivider && !accordionProps.isExpanded && <Divider width="100%" color="new-grey-light-30" />}
                      </>
                    );
                  }}
                </AccordionHeader>,
                <AccordionContent key={`content-${event.id}`}>
                  <MyEventInfo event={event} isShowBell={isShowBell}>
                    <Ternary cond={!loggedMember?.isClient()}>
                      <MyEventParticipants event={event} />
                      <MyEventClientManage event={event} />
                    </Ternary>
                  </MyEventInfo>
                  <Divider width="full" color="new-grey-light-30" />
                </AccordionContent>,
              ];
            })}
          </Accordion>
        </React.Fragment>
      )}
      {statuses.isTodayFetched && (
        <Flex justifyContent="center" py="lg">
          <Ternary cond={statuses.isLoadingMore}>
            <Text color="grey" fontWeight="medium">{`${t('message.searching').toUpperCase()}...`}</Text>
            <Ternary cond={statuses.isNoMoreEvents}>
              <Text fontWeight="medium" color="grey">
                {t('message.noResults').toUpperCase()}
              </Text>
              <Button onClick={onMoreEvents} variant="link">
                <Stack spacing="sm" alignItems="center" isHorizontal>
                  <Text fontWeight="medium">{t('buttons.showMore').toUpperCase()}</Text>
                  <Icons.LoadMore size="base" />
                </Stack>
              </Button>
            </Ternary>
          </Ternary>
        </Flex>
      )}
    </Flex>
  );
};

export default MyEvents;
