/* eslint-disable max-len */
import React from 'react';

const InviteHeart = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M6.8,0.4L1,5C0.4,5.5,0,6.3,0,7.1c0,0,0,0,0,0v0v6.7C0,15,0.9,16,2.1,16l0,0h11.7c1.2,0,2.2-1,2.2-2.2l0,0V6.9
        c0-0.7-0.3-1.3-0.8-1.7l0,0l-6-4.8C8.8,0.1,8.4,0,8,0C7.5,0,7.1,0.1,6.8,0.4L6.8,0.4L6.8,0.4z M5,4.4C5.1,3.6,5.8,3,6.6,3h0
        C7.2,3,7.7,3.3,8,3.9l0,0c0,0,0,0,0,0c0.2-0.6,0.8-1,1.4-1h0c0.8,0,1.5,0.7,1.6,1.5l0,0c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0
        c0,0.4-0.1,0.8-0.4,1.1l0,0l-0.1,0.2l-2,2.2C8.4,8.2,8.2,8.3,8,8.3c-0.2,0-0.4-0.1-0.5-0.2l0,0l-2-2.2L5.3,5.8
        C5.1,5.5,5,5.1,4.9,4.7v0C5,4.6,5,4.5,5,4.4L5,4.4L5,4.4z M1.7,7l4,2.8C5.9,9.9,6,10.1,6,10.4c0,0.2-0.1,0.4-0.3,0.6l0,0l-4,3.3
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4,0-0.7-0.3-0.7-0.7v0V7.5c0-0.4,0.3-0.7,0.7-0.7C1.4,6.8,1.6,6.9,1.7,7L1.7,7L1.7,7z M2.5,14.2l5-4
        C7.7,10,7.8,10,8,10s0.3,0.1,0.5,0.2l0,0l5,4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.4-0.3,0.7-0.7,0.7h0H3c-0.4,0-0.7-0.3-0.7-0.7
        C2.3,14.5,2.4,14.3,2.5,14.2L2.5,14.2L2.5,14.2z M14.3,14.2l-4-3.3c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.2,0.1-0.4,0.3-0.6l0,0l4-2.8
        c0.1-0.1,0.3-0.1,0.4-0.1c0.4,0,0.7,0.3,0.7,0.7v0v6.1c0,0.4-0.3,0.7-0.7,0.7C14.6,14.3,14.4,14.3,14.3,14.2L14.3,14.2L14.3,14.2z"
      />
    </svg>
  );
};

export default InviteHeart;
/* eslint-enable max-len */
