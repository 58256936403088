import { components } from 'react-select';
import { Tag } from '..';

// MultiValue container used in select inputs
const MultiValueContainer = ({ children, onTouchEnd, ...props }: any) => {
  return (
    <components.MultiValueContainer {...props}>
      <Tag>{children}</Tag>
    </components.MultiValueContainer>
  );
};

export default MultiValueContainer;
