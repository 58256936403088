import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '../common/FormField';
import { changeFieldState } from '../../../store/actions';
import Input from '../input';

const AddAvatarUser = ({ onChangeField, ...props }) => {
  const { formName, name } = props;
  const onChange = (value) => {
    onChangeField({ formName, fieldName: name, fieldState: value });
  };

  return (
    <FormField {...props}>
      <Input.AddAvatarUser
        value={props.value}
        onChange={onChange}
      />
    </FormField>
  );
};

AddAvatarUser.defaultProps = {
  value: null,
};

AddAvatarUser.propTypes = {
  value: PropTypes.string,
  formName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(AddAvatarUser);
