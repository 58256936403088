/**
 * @module NumberRangeInput
 */

import React from 'react';
import PropTypes from 'prop-types';

import Numeric from '@old/components/input/Number';
import RowAligner from '@old/components/common/RowAligner';

/**
 * The component generating two input fields adapted to the numerical value with a range of min max
 * The maximum value depends on the minimum value. If the minimum value is equal to or greater than the maximum value,
 * the maximum value is changed to the value of the minimum field
 *
 * @param  {Number} [props.min] Specifies the minimum value that can be set in the field
 * @param  {Number} [props.max] Specifies the maximum value that can be set in the field
 * @param  {String} [props.minLabel]
 * @param  {String} [props.maxLabel]
 * @param  {Function} props.onChange
 * @param  {Number[]} [props.value]
 */
const NumberRangeInput = ({ min, max, minLabel, maxLabel, onChange, value }) => {
  const checkValuesAfterChange = (values) => {
    let [minVal, maxVal] = values;

    if (minVal > max) minVal = max;
    if (maxVal > max) maxVal = max;

    if (minVal < min) minVal = min;
    if (maxVal < min) maxVal = min;

    return [minVal, maxVal];
  };

  const check = (values) => {
    let minVal = parseInt(values[0], 10);
    let maxVal = parseInt(values[1], 10);
    maxVal = minVal > maxVal ? minVal : maxVal;
    minVal = maxVal < minVal ? maxVal : minVal;

    onChange([minVal, maxVal]);
  };

  const changeStart = (val) => {
    const newValue = !val ? 0 : val;
    check(checkValuesAfterChange([newValue, value[1]]));
  };

  const changeEnd = (val) => {
    const newValue = !val ? 0 : val;
    check(checkValuesAfterChange([value[0], newValue]));
  };

  return (
    <RowAligner>
      <div className="flex flex-row select-none">
        <Numeric label={minLabel} value={value[0]} onChange={changeStart} spinButtons />
      </div>
      <div className="flex flex-row select-none">
        <Numeric label={maxLabel} value={value[1]} onChange={changeEnd} spinButtons />
      </div>
    </RowAligner>
  );
};

NumberRangeInput.defaultProps = {
  min: 0,
  max: 50,
  value: [0, 5],
  minLabel: '',
  maxLabel: '',
};

NumberRangeInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
};

export default NumberRangeInput;
