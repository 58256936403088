import FormField from 'components/combinations/forms/FormField';
import FormLabel from 'components/combinations/forms/FormLabel';
import SelectAsync from 'components/combinations/forms/SelectAsync';
import TicketValidityInput from 'components/combinations/forms/TicketValidityInput';
import AmountInput from 'components/combinations/forms/AmountInput';
import NumberInput from 'components/combinations/forms/NumberInput';
import TextInput from 'components/combinations/forms/TextInput';
import RadioInputGropup from 'components/combinations/forms/RadioGroupInput';
import TextareaInput from 'components/combinations/forms/TextareaInput';
import { yup } from 'utils';
import t from 'resources/translations';
import { useCallback, useEffect, useState } from 'react';
import { TTicketOffersFormState } from 'resources/types/TicketOfferTypes';
import { useHistory } from 'react-router';
import { Button, Stack } from 'components/elements';
import { useFormik } from 'formik';
import { EventTypeService } from 'services';
import DefaultOption from 'components/combinations/select/DefaultOption';
import SelectWithModal from 'components/combinations/forms/SelectWithModal';
import { TOption } from 'resources/types/commonTypes';
import _ from 'lodash';
import { useAppStore } from 'utils/storeUtils';

type TTicketOffersForm = {
  initialValues: TTicketOffersFormState;
  onSubmit: (values: TTicketOffersFormState) => void;
  submitLabels: {
    loading: string;
    default: string;
  };
};

const TicketOffersForm = ({ initialValues, onSubmit, submitLabels }: TTicketOffersForm) => {
  const { isMobile } = useAppStore();
  const history = useHistory();
  const [isAutoName, setIsAutoName] = useState(false);

  const validationSchema = yup.object().shape({
    eventTypes: yup.array().required().min(1, t('error.fieldIsRequired')),
    validity: yup
      .number()
      .required(t('error.fieldIsRequired'))
      .min(1, t('error.greaterThanOrEqual', { count: 1 })),
    price: yup.string().maxNumberFromString(4000).required(t('error.fieldIsRequired')),
    count: yup
      .number()
      .required(t('error.fieldIsRequired'))
      .min(1, t('error.greaterThanOrEqual', { count: 1 })),
    visibility: yup.string().required(t('error.fieldIsRequired')),
    description: yup.string().max(5000, t('error.infoMaxLength', { count: 5000 })),
    name: yup.string().required(t('error.fieldIsRequired')).min(1),
  });

  const { values, touched, errors, getFieldProps, setFieldValue, setFieldTouched, ...props } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const generateName = useCallback(() => {
    const eventType = !!values.eventTypes.length ? `${values.eventTypes[0].label}` : '';
    const numberOfRides = values.count ? ` / ${values.count} ${t('tickets.rides', { count: values.count })}` : '';
    const numberOfDays = values.validity
      ? ` / ${values.validity} ${t('tickets.days', { count: values.validity })}`
      : '';
    return `${eventType}${numberOfRides}${numberOfDays}`;
  }, [values.eventTypes, values.validity, values.count]);

  useEffect(() => {
    const generatedName = generateName();
    setIsAutoName(generatedName === values.name || !values.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!touched.name && !_.isEmpty(touched) && isAutoName) {
      setFieldValue('name', `${generateName()}`);
    }
  }, [generateName, touched, isAutoName, setFieldValue]);

  const fetchEventTypesOptions = async (keyword: string, _: any, addidional: { page: number }) => {
    return EventTypeService.fetchOptions(keyword, addidional);
  };

  return (
    <form style={{ maxWidth: 790 }}>
      <FormField errors={(touched.eventTypes ? errors.eventTypes : '') as string}>
        <FormLabel htmlFor="eventTypes" info={t('ticketOffers.selectTypesForTicketOffert')} required>
          {t('labels.eventTypes')}
        </FormLabel>

        {!isMobile ? (
          <SelectAsync
            {...getFieldProps('eventTypes')}
            placeholder={t('placeholders.selectTypesEvents')}
            onChange={(values: any) => {
              setFieldTouched('eventTypes');
              setFieldValue('eventTypes', values);
            }}
            loadOptions={fetchEventTypesOptions}
            components={{
              Option: DefaultOption,
            }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isMulti
          />
        ) : (
          <SelectWithModal
            name="eventTypes"
            placeholder={t('placeholders.selectTypesEvents')}
            value={values.eventTypes}
            loadOptions={({ pageParam, keyword }) => {
              return EventTypeService.fetchOptions(keyword, { page: pageParam });
            }}
            onChange={(values: TOption[]) => {
              setFieldTouched('eventTypes');
              setFieldValue('eventTypes', values);
            }}
          />
        )}
      </FormField>

      <FormField errors={touched.validity ? errors.validity : ''}>
        <FormLabel htmlFor="validity" required>
          {t('labels.ticketValidity')}
        </FormLabel>
        <TicketValidityInput
          {...getFieldProps('validity')}
          onChange={days => {
            setFieldTouched('validity');
            setFieldValue('validity', days);
          }}
          isError={errors.validity}
        />
      </FormField>

      <Stack spacing={[0, 'md']} isHorizontal={!isMobile}>
        <FormField errors={touched.price ? errors.price : ''}>
          <FormLabel htmlFor="price" required>
            {t('labels.price')}
          </FormLabel>
          <AmountInput
            name="price"
            value={values.price}
            placeholder={t('placeholders.priceTicket500')}
            intlConfig={{ locale: 'pl', currency: 'PLN' }}
            groupSeparator=" "
            maxLength={7}
            allowNegativeValue={false}
            decimalScale={2}
            onValueChange={(value: string | undefined) => {
              setFieldTouched('price');
              setFieldValue('price', value);
            }}
          />
        </FormField>

        <FormField errors={touched.count ? errors.count : ''}>
          <FormLabel htmlFor="count" required>
            {t('labels.numbersOfRides')}
          </FormLabel>
          <NumberInput
            {...getFieldProps('count')}
            placeholder={t('placeholders.numberOfTrips8')}
            // @ts-ignore
            onClick={e => e.target.select()}
            onChange={(value: number | null) => {
              setFieldTouched('count');
              setFieldValue('count', value);
            }}
            showSpinButtons
            min={1}
            max={100000}
            strict
          />
        </FormField>
      </Stack>

      <FormField errors={touched.visibility ? errors.visibility : ''}>
        <FormLabel htmlFor="visibility" info={t('ticketOffers.allowsBuyTicketByClient')} required>
          {t('labels.visibilityForClients')}
        </FormLabel>
        <RadioInputGropup
          value={values.visibility}
          name="visibility"
          onChange={(_, value) => {
            setFieldTouched('visibility');
            setFieldValue('visibility', value);
          }}
          options={[
            { value: 'hidden', label: t('general.disabled').toLowerCase() },
            { value: 'visible', label: t('general.enabled').toLowerCase() },
          ]}
          containerStyleProps={{ isHorizontal: true, spacing: 'lg' }}
        />
      </FormField>

      <FormField errors={touched.description ? errors.description : ''}>
        <FormLabel htmlFor="description">{t('labels.additionalNotes')}</FormLabel>
        <TextareaInput
          {...getFieldProps('description')}
          onChange={e => {
            setFieldTouched('description');
            setFieldValue('description', e.target.value);
          }}
          placeholder={t('placeholders.addTicketNote')}
        />
      </FormField>

      <FormField>
        <FormLabel htmlFor="activable" required>
          {t('labels.activationTicket')}
        </FormLabel>
        <RadioInputGropup
          value={values.activable}
          name="activable"
          onChange={(_, value) => {
            setFieldTouched('activable');
            setFieldValue('activable', value);
          }}
          options={[
            { value: 'false', label: t('ticketOffers.ticketIsActivatedAuto').toLowerCase() },
            { value: 'true', label: t('ticketOffers.activationDateIsPaymentDate').toLowerCase() },
          ]}
        />
      </FormField>

      <FormField errors={touched.name ? errors.name : ''}>
        <FormLabel htmlFor="name" required>
          {t('labels.ticketName')}
        </FormLabel>
        <TextInput {...getFieldProps('name')} placeholder={t('placeholders.ticketOffertName')} />
      </FormField>

      {!isMobile ? (
        <Stack w="full" maxW={600} isHorizontal>
          <Button width="100%" variant="outline" onClick={() => history.push('/tickets')}>
            {t('general.cancel')}
          </Button>
          <Button width="100%" disabled={!(props.isValid && props.dirty)} onClick={props.submitForm}>
            {props.isSubmitting ? submitLabels.loading : submitLabels.default}
          </Button>
        </Stack>
      ) : (
        <Stack w="full" maxW={600}>
          <Button width="100%" disabled={!(props.isValid && props.dirty)} onClick={props.submitForm}>
            {props.isSubmitting ? submitLabels.loading : submitLabels.default}
          </Button>
          <Button width="100%" variant="outline" onClick={() => history.push('/tickets')}>
            {t('general.cancel')}
          </Button>
        </Stack>
      )}
    </form>
  );
};

export default TicketOffersForm;
