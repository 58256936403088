/* eslint-disable */
import React from 'react';

const PhoneSmsSmall = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 41.527 59.644"
    >
      <g transform="translate(-7611.157 -4468.761)">
        <path d="M7652.671 4500.321v-23.656a7.9 7.9 0 0 0-7.9-7.9h-19.912a7.869 7.869 0 0 0-3.036.607c-.084.035-.167.07-.25.108l-.03.014a7.906 7.906 0 0 0-2.188 1.482h-6.669a1.526 1.526 0 0 0-1.524 1.524v10.218a1.526 1.526 0 0 0 1.524 1.524h4.218v15.392h-.014v20.866a7.9 7.9 0 0 0 7.9 7.9h19.979a7.936 7.936 0 0 0 7.917-7.931v-20.006a.811.811 0 0 0-.015-.142zm-30.122-29.476a6.237 6.237 0 0 1 2.254-.42l19.965-.022a6.27 6.27 0 0 1 6.24 5.755h-17.783v-3.658a1.526 1.526 0 0 0-1.524-1.524h-9.441c.094-.047.19-.091.289-.131zm-10.219 11.87V4472.5a.352.352 0 0 1 .352-.352h19.018a.352.352 0 0 1 .352.352v10.1a.469.469 0 0 1-.469.469h-1.566a.589.589 0 0 0-.415.172l-2.679 2.679v-2.264a.586.586 0 0 0-.586-.587h-13.655a.351.351 0 0 1-.352-.354zm38.7 37.787a6.217 6.217 0 0 1-6.249 6.229H7624.8a6.259 6.259 0 0 1-6.262-6.256v-.736h32.489zm0-2.431h-32.489v-33.832h7.21v3.093a.586.586 0 0 0 .361.542.592.592 0 0 0 .225.044.583.583 0 0 0 .414-.171l3.508-3.508h1.324a1.644 1.644 0 0 0 1.642-1.642v-4.806l17.806.008z"/>
        <path d="M7824.6 4505.525h3.4a.938.938 0 0 0 .936-1 .959.959 0 0 0-.968-.875h-3.368a.938.938 0 0 0 0 1.876z" transform="translate(-187.59 -30.797)"/>
        <path d="M7770.094 4928.879h8.083a.938.938 0 0 0 0-1.876h-8.083a.938.938 0 0 0 0 1.876z" transform="translate(-139.475 -404.515)"/>
        <path d="M7636.115 4517.943h15.085a.586.586 0 1 0 0-1.172h-15.088a.586.586 0 0 0 0 1.172z" transform="translate(-21.515 -42.381)"/>
        <path d="M7636.115 4538.879h15.085a.586.586 0 1 0 0-1.172h-15.088a.586.586 0 0 0 0 1.172z" transform="translate(-21.515 -60.862)"/>
        <path d="M7643.57 4560.686h-7.455a.586.586 0 0 0 0 1.172h7.455a.586.586 0 0 0 0-1.172z" transform="translate(-21.515 -81.147)"/>
    </g>
    </svg>
  );
};

export default PhoneSmsSmall;
/* eslint-enable */
