/**
 * @module Avatar
 */
import React from 'react';
import PropTypes from 'prop-types';
import ImageCircle from '@old/components/guide/ImageCircle';
import TagCircle from '@old/components/common/TagCircle';

/**
 * Optional level of advancement info
 * @typedef   {Object} SlugInfo
 * @property  {String} text - text displayed inside the slug circle
 * @property  {String} color - color of the slug circle
 */
/**
 * Component that displays avatar image with optional level of advancement info
 * @param  {SlugInfo} [props.slugInfo]
 * @param  {String} props.src source link of an image
 * @param  {Boolean} [props.small] size of the image
 * @param  {Boolean} [props.big] size of the image
 * @param  {Boolean} [props.large] size of the image
 */
const Avatar = ({ slugInfo, ...imageProps }) => {
  return (
    <div className="avatar">
      <ImageCircle {...imageProps} />
      {slugInfo && <TagCircle color={slugInfo.color} small>{slugInfo.text}</TagCircle>}
    </div>
  );
};

Avatar.defaultProps = {
  slugInfo: null,
  large: false,
  small: false,
  big: false,
};

Avatar.propTypes = {
  slugInfo: PropTypes.shape({
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
  src: PropTypes.string.isRequired,
  small: PropTypes.bool,
  large: PropTypes.bool,
  big: PropTypes.bool,
};

export default Avatar;
