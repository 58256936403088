import moment from 'moment';

import Icon from '@old/components/icon';
import t from 'resources/translations';

const config = {
  dateFormat: 'DD.MM.YYYY',
  timeFormat: 'HH:mm',
  monthFormat: 'DD.MM',
  dateTimeFormatWithComma: 'DD.MM.YYYY, HH:mm',
  dateTimeFormat: 'DD.MM.YYYY HH:mm',
  languageCode: 'pl',
  maxMobileWidth: 767,
  maxTabletWidth: 1199,
  pxPer5Min: 5,
  minSizeImage: 300,
  hours: [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
  ],
  weekDays: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
  minItemsPerPage: 24,
  minGridItemWidth: 217,
  sidebarWidth: 240,
  customUUIDNamespace: 'f1d395a0-7bc5-11e9-9631-615ed40fe1fc',
  color: {
    tealDark: '#005E65',
    teal: '#00A0AD',
    tealLight: '#B6E0E3',
    redDark: '#bf4338',
    red: '#DC4E41',
    black: '#000000',
    green: '#0ab43d',
  },
  heatMapColors: ['#701E1E', '#C74C4C', '#E28C52', '#EEDF60', '#EFE79F'],
  heatMapHours: [
    '08:00-09:00',
    '09:00-10:00',
    '10:00-11:00',
    '11:00-12:00',
    '12:00-13:00',
    '13:00-14:00',
    '14:00-15:00',
    '15:00-16:00',
    '16:00-17:00',
    '17:00-18:00',
    '18:00-19:00',
    '19:00-20:00',
    '20:00-21:00',
    '21:00-22:00',
    '22:00-23:00',
  ],
  accentColorsPlaces: [
    '#FEFE00',
    '#FEBF00',
    '#905D48',
    '#5A3A7D',
    '#5D2818',
    '#9297F3',
    '#39780D',
    '#2B3209',
    '#71B512',
    '#9F1419',
  ],
  accentColorsEventTypes: [
    '#BC2430',
    '#5D2D21',
    '#3E3F43',
    '#8F726C',
    '#A6A7C3',
    '#25331C',
    '#988B68',
    '#5C7444',
    '#6B0537',
    '#865544',
  ],
  difficulty: [
    { label: t('skills.undefined'), value: 'undefined', slug: 'N', color: '#B9B9BA' },
    { label: t('skills.beginner'), value: 'beginner', slug: 'L1', color: 'green' },
    { label: t('skills.intermediate'), value: 'intermediate', slug: 'L2', color: '#f9b600' },
    { label: t('skills.advanced'), value: 'advanced', slug: 'L3', color: 'red' },
  ],
  keyCodes: {
    esc: 27,
    arrowRight: 39,
    arrowLeft: 37,
    arrowUp: 38,
    arrowDown: 40,
    enter: 13,
  },
  currency: {
    pln: {
      name: t('currency.pln.name'),
      shortName: t('currency.pln.shortName'),
    },
  },
  eventStatusProps: {
    none: { color: 'bg-grey', status: t('eventStatus.none') },
    awaiting: { color: 'bg-teal-dark', status: t('eventStatus.awaiting') },
    active: { color: 'bg-teal-light', status: t('eventStatus.active') },
    ongoing: { color: 'bg-green-light', status: t('eventStatus.ongoing') },
    finished: { color: 'bg-grey-dark', status: t('eventStatus.finished') },
    cancelled: { color: 'bg-grey-dark', status: t('eventStatus.cancelled') },
    proposed: { color: 'bg-grey-dark', status: t('eventStatus.proposed') },
  },
  progressStatusBar: [
    { statusName: 'none', icon: <Icon.Edit />, label: t('eventStatus.none') },
    { statusName: 'awaiting', icon: <Icon.Add />, label: t('eventStatus.awaiting') },
    { statusName: 'active', icon: <Icon.Check />, label: t('eventStatus.active') },
    { statusName: 'ongoing', icon: <Icon.Calendar />, label: t('eventStatus.ongoing') },
    { statusName: 'finished', icon: <Icon.CheckList />, label: t('eventStatus.finished') },
    { statusName: 'cancelled', icon: <Icon.CheckList />, label: t('eventStatus.cancelled') },
  ],
  directionalStates: [
    { id: '1', value: '+01' },
    { id: '2', value: '+02' },
    { id: '3', value: '+03' },
    { id: '4', value: '+04' },
    { id: '5', value: '+05' },
    { id: '6', value: '+06' },
    { id: '7', value: '+48' },
    { id: '8', value: '+49' },
    { id: '9', value: '+44' },
    { id: '10', value: '+33' },
  ],
  defaultDirectional: { value: '+48' },
  userRoles: [
    { label: t('general.owner'), name: 'owner', value: 1 },
    { label: t('general.instructor'), name: 'instructor', value: process.env.REACT_APP_SERVER === 'testing' ? 2 : 3 },
    { label: t('general.client'), name: 'client', value: process.env.REACT_APP_SERVER === 'testing' ? 3 : 2 },
  ],
  paymentStatus: [
    { label: t('paymentStatus.unpaid'), value: 'unpaid' },
    { label: t('paymentStatus.cash'), value: 'cash' },
    { label: t('paymentStatus.card'), value: 'card' },
    { label: t('paymentStatus.ticket'), value: 'ticket' },
    { label: t('paymentStatus.barter'), value: 'barter' },
    { label: t('paymentStatus.transfer'), value: 'transfer' },
    { label: t('paymentStatus.prepaid'), value: 'prepaid' },
    { label: t('paymentStatus.volunteering'), value: 'volunteering', isFree: true },
    { label: t('paymentStatus.other'), value: 'other' },
    { label: t('paymentStatus.other/volunteering'), value: 'other/volunteering' },
    { label: t('paymentStatus.other/ticket'), value: 'other/ticket' },
  ],
  paymentStatusCompact: [
    { label: t('paymentStatus.cash'), value: 'cash' },
    { label: t('paymentStatus.other'), value: 'other' },
    { label: t('paymentStatus.other/volunteering'), value: 'other/volunteering' },
    { label: t('paymentStatus.other/ticket'), value: 'other/ticket' },
  ],
  billsStatusOptions: [
    { label: t('billsFilters.statuses.paid'), value: 'paid' },
    { label: t('billsFilters.statuses.unpaid'), value: 'unpaid' },
    { label: t('billsFilters.statuses.overpaid'), value: 'overpaid' },
  ],
  eventTypeAvatars: [
    {
      image: '/img/eventTypeAvatars/derkowanie.png',
    },
    {
      image: '/img/eventTypeAvatars/elektrolity.png',
    },
    {
      image: '/img/eventTypeAvatars/karmienie_niestandardowe.png',
    },
    {
      image: '/img/eventTypeAvatars/karmienie_rano.png',
    },
    {
      image: '/img/eventTypeAvatars/karmienie_wieczorem.png',
    },
    {
      image: '/img/eventTypeAvatars/karuzela.png',
    },
    {
      image: '/img/eventTypeAvatars/komunikacja.png',
    },
    {
      image: '/img/eventTypeAvatars/kucie.png',
    },
    {
      image: '/img/eventTypeAvatars/lonża.png',
    },
    {
      image: '/img/eventTypeAvatars/oprowadzki.png',
    },
    {
      image: '/img/eventTypeAvatars/padokowanie.png',
    },
    {
      image: '/img/eventTypeAvatars/spacer.png',
    },
    {
      image: '/img/eventTypeAvatars/teren.png',
    },
    {
      image: '/img/eventTypeAvatars/trening_dosiad.png',
    },
    {
      image: '/img/eventTypeAvatars/trening_skoki.png',
    },
    {
      image: '/img/eventTypeAvatars/trening_ujeżdżenie.png',
    },
    {
      image: '/img/eventTypeAvatars/trening.png',
    },
    {
      image: '/img/eventTypeAvatars/werkowanie.png',
    },
  ],
  userAvatars: [
    {
      name: t('addAvatarUsers.women'),
      avatars: [
        {
          image: '/img/userAvatars/women1.png',
        },
        {
          image: '/img/userAvatars/women2.png',
        },
        {
          image: '/img/userAvatars/women3.png',
        },
        {
          image: '/img/userAvatars/women4.png',
        },
        {
          image: '/img/userAvatars/women5.png',
        },
        {
          image: '/img/userAvatars/women6.png',
        },
        {
          image: '/img/userAvatars/women7.png',
        },
        {
          image: '/img/userAvatars/women8.png',
        },
        {
          image: '/img/userAvatars/women9.png',
        },
        {
          image: '/img/userAvatars/women10.png',
        },
      ],
    },
    {
      name: t('addAvatarUsers.men'),
      avatars: [
        {
          image: '/img/userAvatars/men1.png',
        },
        {
          image: '/img/userAvatars/men2.png',
        },
        {
          image: '/img/userAvatars/men3.png',
        },
        {
          image: '/img/userAvatars/men4.png',
        },
        {
          image: '/img/userAvatars/men5.png',
        },
        {
          image: '/img/userAvatars/men6.png',
        },
        {
          image: '/img/userAvatars/men7.png',
        },
        {
          image: '/img/userAvatars/men8.png',
        },
        {
          image: '/img/userAvatars/men9.png',
        },
        {
          image: '/img/userAvatars/men10.png',
        },
      ],
    },
    {
      name: t('addAvatarUsers.kids'),
      avatars: [
        {
          image: '/img/userAvatars/kid1.png',
        },
        {
          image: '/img/userAvatars/kid2.png',
        },
        {
          image: '/img/userAvatars/kid3.png',
        },
        {
          image: '/img/userAvatars/kid4.png',
        },
        {
          image: '/img/userAvatars/kid5.png',
        },
        {
          image: '/img/userAvatars/kid6.png',
        },
        {
          image: '/img/userAvatars/kid7.png',
        },
        {
          image: '/img/userAvatars/kid8.png',
        },
        {
          image: '/img/userAvatars/kid9.png',
        },
        {
          image: '/img/userAvatars/kid10.png',
        },
      ],
    },
  ],
  bmPaymentIcons: [
    {
      image: '/img/bmIcons/payment1.gif',
    },
    {
      image: '/img/bmIcons/payment2.gif',
    },
    {
      image: '/img/bmIcons/payment3.gif',
    },
    {
      image: '/img/bmIcons/payment4.gif',
    },
    {
      image: '/img/bmIcons/payment5.gif',
    },
    {
      image: '/img/bmIcons/payment6.gif',
    },
    {
      image: '/img/bmIcons/payment7.gif',
    },
    {
      image: '/img/bmIcons/payment8.gif',
    },
    {
      image: '/img/bmIcons/payment9.gif',
    },
    {
      image: '/img/bmIcons/payment10.gif',
    },
    {
      image: '/img/bmIcons/payment11.gif',
    },
    {
      image: '/img/bmIcons/payment12.gif',
    },
    {
      image: '/img/bmIcons/payment13.gif',
    },
    {
      image: '/img/bmIcons/payment14.gif',
    },
    {
      image: '/img/bmIcons/payment15.gif',
    },
    {
      image: '/img/bmIcons/payment16.gif',
    },
    {
      image: '/img/bmIcons/payment17.png',
    },
    {
      image: '/img/bmIcons/payment18.gif',
    },
    {
      image: '/img/bmIcons/payment19.png',
    },
    {
      image: '/img/bmIcons/payment20.gif',
    },
    {
      image: '/img/bmIcons/payment21.gif',
    },
    {
      image: '/img/bmIcons/payment22.gif',
    },
    {
      image: '/img/bmIcons/payment23.jpg',
    },
  ],
  googleAnalyticsEnabled: process.env.REACT_APP_SERVER === 'production' || process.env.REACT_APP_SERVER === 'testing',
  googleAnalyticsCodes: {
    production: 'UA-149881877-2',
    testing: 'UA-149881877-3',
  },
  releaseBillingDate: moment('11.03.2021 12:00', 'DD.MM.YYYY HH:mm'),
  emails: {
    support: 'kontakt@horsemanago.com',
  },
};

export default config;
