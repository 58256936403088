/* eslint-disable max-len */
import React from 'react';

const Farm = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M11.2,15L8,12.5l-3.2,2.4L11.2,15L11.2,15z M12,14.4V9.5l-3.2,2.4L12,14.4z M4,9.5v4.9L7.2,12C7.2,12,4,9.5,4,9.5z M8,11.3
        L11,9H5L8,11.3L8,11.3z M13.3,2.7L8,1L2.8,2.7L0,7h1v8h2V8h10v7h2V7h1C16,7,13.3,2.7,13.3,2.7z M8,5C7.4,5,7,4.5,7,4s0.4-1,1-1
        s1,0.4,1,1C9,4.5,8.6,5,8,5z"
      />
    </svg>
  );
};

export default Farm;
/* eslint-enable max-len */
