import { connect } from 'react-redux';
import axios from 'axios';
import { cloneDeep, unset } from 'lodash';

import Modal from '@old/components/common/Modal';

import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_MANAGE_GROUP } from 'old/constants/formNames';
import { getErrorMessage, getChanges, notify, mapFormStateToData, getInitValues } from 'old/utils';
import { fetchGroup, fetchGroups } from 'store/actions';

const ManageGroupModal = ({ group, fetchSingle, onClose, groupsFetchParams, ...actions }) => {
  let source;

  const updateGroup = async values => {
    await group.update({
      name: values.groupName,
      description: values.groupDescription,
      membership_ids: values.membersIds,
    });
    notify(t('message.editingGroupSuccess'));
  };

  const addGroup = async values => {
    await Model.MemberGroups.create({
      name: values.groupName,
      description: values.groupDescription,
      membership_ids: values.membersIds,
    });
    notify(t('message.addingGroupSuccess'));
  };

  const filterInitValues = initialVals => {
    const filteredInitVals = cloneDeep(initialVals);
    if (group) unset(filteredInitVals, 'membersIds');
    return filteredInitVals;
  };

  const initialValues = filterInitValues(
    getInitValues({
      formName: 'groups',
      customInitValues: group
        ? {
            groupName: group.name,
            groupDescription: group.description,
            membersIds: group.getMembersOptions(),
          }
        : {},
    })
  );

  const prepareData = formState => {
    return group ? getChanges(formState, mapFormStateToData({ formState: initialValues, initValue: true })) : formState;
  };

  const onSubmit = async ({ ...formState }) => {
    const values = prepareData(formState);
    const { CancelToken } = axios;
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    try {
      if (group) {
        await updateGroup(values);
      } else {
        await addGroup(values);
      }
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      if (fetchSingle) {
        actions.fetchGroup(group.id, source.token);
      } else {
        actions.fetchGroups(groupsFetchParams, source.token);
      }
    }
  };
  const header = group ? t('model.edit.ridingGroups') : t('model.add.ridingGroups');
  const submitText = group ? t('general.save') : t('general.add');

  return (
    <Modal name="manageGroup" header={header} onClose={onClose} isOpen>
      <Form formName={FORM_MANAGE_GROUP} initValues={initialValues} refreshAlways noPadding>
        <Field.Text name="groupName" label={t('labels.name')} placeholder={t('placeholders.name')} required />
        <Field.Text
          name="groupDescription"
          label={t('labels.description')}
          placeholder={t('placeholders.description')}
        />
        {!group && (
          <Field.AsyncMultiSelect
            name="membersIds"
            label={t('labels.clients')}
            placeholder={t('placeholders.selectClients')}
            loadOptions={Model.Members.fetchClientOptions}
          />
        )}
        <FormButton formName={FORM_MANAGE_GROUP} onClick={onSubmit} fluid async>
          {submitText}
        </FormButton>
      </Form>
    </Modal>
  );
};

ManageGroupModal.defaultProps = {
  group: null,
  fetchSingle: false,
};

const mapStateToProps = ({ fetchingData: { groupsFetchParams } }) => ({ groupsFetchParams });
export default connect(mapStateToProps, { fetchGroup, fetchGroups })(ManageGroupModal);
