import { DIFFICULTY } from 'config/constans';
import t from 'resources/translations';
import { IImage, TImageSize, TPrivilege, TSkills, IPermissions } from 'resources/types/commonTypes';
import {
  IGuestRes,
  IMemberRes,
  IUserRes,
  IProfileRes,
  TMemberIdentity,
  TMemberPermittedAtrs,
  TMemberRoles,
  TMemberStatuses,
} from 'resources/types/membersTypes';

export class Guest {
  id: number;
  name: string;
  phone: string;
  friendlyName: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IGuestRes) {
    this.id = data.id;
    this.name = data.name;
    this.phone = data.phone;
    this.friendlyName = data.friendly_name;
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
  }
}

export class User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  birthday: Date | null;
  parentId: number | null;
  phone: string;
  phoneVerified: boolean;
  avatar: IImage | null;
  createdAt: Date;
  updatedAt: Date;
  deleted?: boolean;
  privateProfile: boolean;

  constructor(data: IUserRes | IProfileRes) {
    this.id = data.id;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.email = data.email;
    this.birthday = data.birthday ? new Date(data.birthday) : null;
    this.phone = data.phone;
    this.phoneVerified = data.phone_verified;
    this.parentId = data.parent_id;
    this.privateProfile = data.private_data_hidden;
    if (isUserRes(data)) {
      this.deleted = data.deleted;
    }
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
    this.avatar = data.avatar || null;
  }

  isDeleted() {
    if (this.deleted) {
      return this.deleted;
    }
    return false;
  }
  getFirstName() {
    if (this.isDeleted()) {
      return t('general.userDeleted');
    }
    return this.firstname;
  }

  getLastName() {
    if (this.isDeleted()) {
      return t('general.userDeleted');
    }
    return this.lastname;
  }

  getAvatar(size: TImageSize) {
    if (!this.avatar?.url) {
      return '/img/user-avatar-placeholder.png';
    }
    return this.avatar.url[size] || this.avatar.url.medium;
  }
}

const isUserRes = (identity: any): identity is IUserRes => {
  return identity?.firstname && identity?.lastname;
};

const isMembershipRes = (profile: any): profile is IMemberRes => {
  return !profile?.membership;
};

const isProfileRes = (profile: any): profile is IProfileRes => {
  return !!profile?.membership;
};

class Member {
  id: number;
  nickname: string | null;
  rating: number | null;
  role: TMemberRoles;
  status: TMemberStatuses;
  skill: TSkills;
  aboutMe?: string | null;
  userNote?: string | null;
  identity: User | Guest;
  permittedAttributes: TMemberPermittedAtrs[] | null;
  identityType: TMemberIdentity;
  privileges?: TPrivilege[];
  permissions?: IPermissions;

  constructor(data: IMemberRes | IProfileRes) {
    if (isProfileRes(data)) {
      this.id = data.membership.id;
      this.nickname = data.membership.nickname || null;
      this.rating = data.membership.rating;
      this.role = data.membership.role.abilities[0];
      this.status = data.membership.status;
      this.skill = data.membership.skill;
      this.rating = data.membership.rating || null;
      this.permittedAttributes = data.membership.permitted_attributes;
      this.permissions = data.membership.role.permissions;
      this.identityType = 'User';
      this.identity = new User(data);
    } else if (isMembershipRes(data)) {
      this.id = data.id;
      this.nickname = data.nickname;
      this.rating = data.rating;
      this.role = data.role;
      this.status = data.status;
      this.skill = data.skill;
      this.rating = data.rating || null;
      this.aboutMe = this.role === 'instructor' ? data.additional_info : null;
      this.userNote = this.role === 'client' ? data.additional_info : null;
      this.identityType = data.identity_type;
      this.permittedAttributes = data.permitted_attributes;
      this.privileges = data.meta.privileges;
      if (isUserRes(data.identity)) {
        this.identity = new User(data.identity);
      } else {
        this.identity = new Guest(data.identity);
      }
    } else {
      throw new Error('Unprocessable Entity');
    }
  }

  isGuest(_identity: User | Guest): _identity is Guest {
    return this.identityType === 'Guest';
  }

  isUser(_identity: User | Guest): _identity is User {
    return this.identityType === 'User';
  }

  isPending() {
    return this.status === 'pending';
  }

  isBlocked() {
    return this.status === 'blocked';
  }

  isRejected() {
    return this.status === 'rejected';
  }

  isActive() {
    return this.status === 'active';
  }

  isInstructor() {
    return this.role === 'instructor';
  }

  isDeleted() {
    if (this.identity instanceof User) {
      return this.identity.deleted;
    }
    return false;
  }

  getAvatar(size: TImageSize) {
    if (this.identity instanceof Guest) {
      return '/img/guest-placeholder.png';
    }
    if (!this.identity?.avatar?.url) {
      return '/img/user-avatar-placeholder.png';
    }
    return this.identity.avatar.url[size] || this.identity.avatar.url.medium;
  }

  getFirstName() {
    if (this.isDeleted()) {
      return t('general.userDeleted');
    }
    if (this.isGuest(this.identity)) {
      return this.nickname || this.identity.name || t('general.guest');
    }
    return this.identity.firstname;
  }

  getLastName() {
    if (this.isDeleted()) {
      return t('general.userDeleted');
    }
    if (this.isGuest(this.identity)) {
      return this.nickname || this.identity.name || t('general.guest');
    }
    return this.identity.lastname;
  }

  getFullName(): string {
    if (this.isDeleted()) {
      return t('general.userDeleted');
    }
    if (this.isGuest(this.identity)) {
      return this.nickname || this.identity.name || t('general.guest');
    }
    return `${this.identity.firstname} ${this.identity.lastname}`;
  }

  getProfileUrl() {
    if (this.isInstructor()) {
      return `/instructors/${this.id}`;
    }
    return `/users/${this.id}`;
  }

  getSkill() {
    return this.skill || 'missing';
  }

  isClient() {
    return this.role === 'client';
  }

  isOwner() {
    return this.role === 'owner';
  }

  getSkillInfo() {
    const difficulty = DIFFICULTY.find(d => this.skill === d.value) || DIFFICULTY[0];
    return difficulty;
  }

  getPermission(permissionKey: TPrivilege) {
    return this.privileges ? this.privileges.includes(permissionKey) : false;
  }

  getAccesByKey(permModel: keyof IPermissions, permAction: string) {
    //@ts-ignore
    return this.permissions ? this.permissions[permModel][permAction] : false;
  }
}

export default Member;
