import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';

const TagField = ({ onChangeField, placeholder, label, validation, options, defaultValue, tagsOptions, ...props }) => {
  const { name, formName, value, required } = props;
  const onChange = (tags) => {
    onChangeField({ formName, fieldName: name, fieldState: tags });
  };
  return (
    <FormField validation={validation} {...props}>
      <Input.Tag
        value={value}
        onChange={onChange}
        label={label}
        required={required}
        placeholder={placeholder}
        defaultValue={defaultValue}
        options={options}
        tagsOptions={tagsOptions}
      />
    </FormField>
  );
};

TagField.defaultProps = {
  value: [],
  validation: [],
  placeholder: '',
  currentQuery: '',
  label: '',
  required: false,
  options: {},
  tagsOptions: {},
  defaultValue: '',
};

TagField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  })),
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  currentQuery: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.func.isRequired,
      errorHint: PropTypes.string,
    }),
  ),
  options: PropTypes.shape({
    validationsInput: PropTypes.arrayOf(
      PropTypes.shape({
        condition: PropTypes.func.isRequired,
        errorHint: PropTypes.string,
      }),
    ),
    type: PropTypes.oneOf([
      'Email',
      'Time',
      'Text',
    ]).isRequired,
  }), // options are additional button props
  tagsOptions: PropTypes.shape({}), // options are additional tags components props
};

const mapStateToProps = ({ form }, props) => {
  const { value } = form[props.formName][props.name];
  return ({ value });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(TagField);
