/* eslint-disable max-len */
import React from 'react';

const EventSettings = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 25.96 24.75"
    >
      <path d="M20.21,18a1.61,1.61,0,1,0,1.61,1.6h0A1.61,1.61,0,0,0,20.21,18Z" />
      <path d="M24.14,17.24a1.87,1.87,0,0,1,.52-1.32,6.64,6.64,0,0,0-.75-.75,1.93,1.93,0,0,1-2,.39,1.9,1.9,0,0,1-1.16-1.71,6.14,6.14,0,0,0-1.05,0,1.88,1.88,0,0,1-1.16,1.67,1.93,1.93,0,0,1-2-.38,6,6,0,0,0-.76.75,1.86,1.86,0,0,1,.38,2,1.93,1.93,0,0,1-1.7,1.14,4.79,4.79,0,0,0,0,1.07,1.84,1.84,0,0,1,1.67,1.16,1.87,1.87,0,0,1-.38,2,6.64,6.64,0,0,0,.75.75,2,2,0,0,1,2-.39,1.89,1.89,0,0,1,1.16,1.71,6.14,6.14,0,0,0,1.05,0,1.88,1.88,0,0,1,1.16-1.67,1.93,1.93,0,0,1,2,.38,6,6,0,0,0,.76-.75,1.87,1.87,0,0,1,.06-2.64A1.81,1.81,0,0,1,26,20.15a4.79,4.79,0,0,0,0-1.07A1.88,1.88,0,0,1,24.14,17.24Zm-3.92,5h0a2.58,2.58,0,1,1,2.57-2.58,2.59,2.59,0,0,1-2.57,2.58Z" />
      <path d="M18.22,25.25h.43a.89.89,0,0,0-1-.81.84.84,0,0,0-.6.32.82.82,0,0,1-1.14.08,6.67,6.67,0,0,1-1.07-1.12A.89.89,0,0,1,15,22.55a1,1,0,0,0,.23-1,.88.88,0,0,0-.83-.58.92.92,0,0,1-.89-.69,7.09,7.09,0,0,1,0-1.66.83.83,0,0,1,.88-.76.94.94,0,0,0,1-.9A.89.89,0,0,0,15,16.2.9.9,0,0,1,14.91,15,6.48,6.48,0,0,1,16,13.9a.82.82,0,0,1,1.13.1.89.89,0,0,0,1.57-.67.87.87,0,0,1,.75-.88,6.44,6.44,0,0,1,1.52,0,.86.86,0,0,1,.74.91.89.89,0,0,0,1.57.66.84.84,0,0,1,.49-.26V4.89a2.42,2.42,0,0,0-2.47-2.37H19.74V.63H17.81V2.52H5.52V.63H3.78V2.52H2.86A3,3,0,0,0,0,5.36V22.41a2.84,2.84,0,0,0,2.66,2.84ZM1.85,7.82V5.36a.94.94,0,0,1,.92-.94h1V6.31H5.61V4.42H17.9V6.31h1.93V4.42H21a1.07,1.07,0,0,1,1.19.94v.1c0,.76-.09,2.37-.09,2.37Z" />
    </svg>
  );
};

export default EventSettings;
/* eslint-enable max-len */
