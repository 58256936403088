/* eslint-disable max-len */
import React from 'react';

const Settings = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M15.9,7.1c-0.1-0.2-0.3-0.4-0.5-0.4c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-1,0-1.8-0.8-1.8-1.8c0-0.5,0.2-1,0.6-1.3l0,0
        c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3l0,0c-0.4-0.5-0.8-0.9-1.2-1.3l0,0c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.1
        l0,0c-0.3,0.4-0.8,0.6-1.3,0.6c-0.2,0-0.5,0-0.7-0.1l0,0C9.8,2,9.3,1.4,9.3,0.6c0,0,0-0.1,0-0.1v0c0,0,0,0,0,0
        c0-0.2-0.2-0.4-0.4-0.4h0C8.7,0,8.3,0,8,0S7.4,0,7.1,0.1l0,0c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0v0c0,0,0,0,0,0.1
        c0,0.7-0.4,1.4-1.1,1.6l0,0C5.5,2.3,5.2,2.3,5,2.3c-0.5,0-1-0.2-1.3-0.6l0,0C3.6,1.7,3.5,1.6,3.3,1.6c-0.1,0-0.2,0-0.3,0.1l0,0
        C2.6,2.1,2.1,2.5,1.8,3l0,0C1.7,3.1,1.7,3.2,1.7,3.3c0,0.1,0.1,0.2,0.1,0.3l0,0c0.4,0.3,0.6,0.8,0.6,1.3c0,0.2-0.1,0.5-0.1,0.7l0,0
        C2,6.2,1.3,6.7,0.6,6.7c0,0,0,0-0.1,0h0c0,0,0,0,0,0C0.3,6.7,0.1,6.8,0,7.1v0C0,7.3,0,7.7,0,8s0,0.6,0.1,0.9l0,0
        c0.1,0.2,0.3,0.4,0.5,0.4c0,0,0,0,0,0h0c0,0,0,0,0,0c0.7,0,1.4,0.5,1.6,1.1l0,0c0.1,0.2,0.1,0.4,0.1,0.7c0,0.5-0.2,1-0.6,1.3l0,0
        c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3l0,0c0.4,0.5,0.8,0.9,1.2,1.3l0,0c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.1l0,0
        c0.3-0.4,0.8-0.6,1.3-0.6c0.2,0,0.5,0,0.7,0.1l0,0c0.6,0.3,1.1,0.9,1.1,1.6c0,0,0,0.1,0,0.1v0c0,0,0,0,0,0c0,0.2,0.2,0.4,0.4,0.4h0
        C7.3,16,7.7,16,8,16c0.3,0,0.6,0,0.9-0.1l0,0c0.2,0,0.4-0.2,0.4-0.4c0,0,0,0,0,0v0c0,0,0,0,0-0.1c0-0.7,0.5-1.4,1.1-1.6l0,0
        c0.2-0.1,0.4-0.1,0.6-0.1c0.5,0,1,0.2,1.3,0.6l0,0c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l0,0c0.5-0.4,0.9-0.8,1.3-1.3l0,0
        c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.1-0.3l0,0c-0.4-0.3-0.6-0.8-0.6-1.3c0-0.2,0.1-0.5,0.1-0.7l0,0c0.3-0.6,0.9-1.1,1.6-1.1
        h0.1c0,0,0,0,0,0c0.2,0,0.4-0.2,0.4-0.4v0C16,8.7,16,8.3,16,8C16,7.7,16,7.4,15.9,7.1L15.9,7.1L15.9,7.1z M8,10.7
        c-1.5,0-2.7-1.2-2.7-2.7S6.5,5.3,8,5.3s2.7,1.2,2.7,2.7l0,0C10.7,9.5,9.5,10.7,8,10.7L8,10.7z"
      />
    </svg>
  );
};

export default Settings;
/* eslint-enable max-len */
