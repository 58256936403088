/* eslint-disable max-len */
import React from 'react';

const CalendarPlus = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M13.6,1.6h-0.8V0h-1.6v1.6H4.8V0H3.2v1.6H2.4C2,1.6,1.6,1.8,1.3,2.1C1,2.4,0.8,2.8,0.8,3.2v11.2c0,0.9,0.7,1.6,1.6,1.6h11.2
        c0.9,0,1.6-0.7,1.6-1.6V3.2C15.2,2.3,14.5,1.6,13.6,1.6z M13.6,14.4H2.4V5.6h11.2V14.4z M7.6,13h1.2v-2.4h2.4V9.4H8.8V7H7.6v2.4H5.1
        v1.2h2.4C7.6,10.6,7.6,13,7.6,13z"
      />
    </svg>
  );
};

export default CalendarPlus;
/* eslint-enable max-len */
