/* eslint-disable max-len */
import React from 'react';

const AddHorse = () => {
  return (
    <svg
      viewBox="0 0 26 26"
      width="100%"
      className="icon"
    >
      <g>
        <path d="M21.7,15.1V18h-2.9v1.4h2.9v2.9h1.4v-2.9H26V18h-2.9v-2.9H21.7z" />
        <path d="M3,18.7c-3.1-4.6-0.9-8.1-0.9-8.1c-0.6-0.1-2.1,1-2.1,1c0.9-3.3,6-6.1,6-6.1C5.4,5.1,3.9,5.6,3.9,5.6
          C5.6,3.8,8,2.8,10.7,2.8c0.9,0,1.6,0.1,2.3,0.3l0,0c0-0.9,2.6-2.4,2.6-2.4c-0.3,0.3-0.3,1.6-0.3,1.6c0.3-0.4,0.7-0.7,1-0.9l0,0
          c-0.4,1,0,3.4,0,3.4c1.3,1.3,2.9,5.9,2.9,5.9l0.6,0.9c0.7,1,0.4,2.6-0.3,3c-2.6,1.4-2.9-1.1-2.9-1.1c-2.3-0.4-3.9-2.9-3.9-2.9
          c-3,1.6-1.4,5.9,0,7.4c1.6,2.1,0.4,5.6,0.4,5.6S6.1,23.3,3,18.7L3,18.7z"
        />
      </g>
    </svg>
  );
};

export default AddHorse;
/* eslint-enable max-len */
