import { useState } from 'react';

import Modal from '@old/components/common/Modal';
import t from 'resources/translations';
import { getErrorMessage, notify } from 'old/utils';
import Input from '@old/components/input';
import FlexColumn from '@old/components/common/FlexColumn';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';

const AddPicture = ({ onClose, modelItem, afterSubmit }) => {
  const [description, setDescription] = useState('');
  const [image, onChangeImage] = useState('');

  const addPictures = async () => {
    try {
      await modelItem.updatePicture({ pictures: [{ file: image, description }] });
      notify(t('message.photoSuccesSave'));
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      afterSubmit();
    }
  };

  return (
    <Modal header={t('general.addPicture')} onClose={onClose} isOpen>
      <FlexColumn>
        <Input.ImageUploader label={t('labels.photo')} onChange={onChangeImage} value={image} required />
        <Input.Textarea
          name="description"
          label={t('labels.description')}
          placeholder={t('placeholders.description')}
          value={description}
          onChange={val => setDescription(val)}
        />
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button onClick={addPictures} fluid disabled={!image} async>
            {t('general.save')}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

AddPicture.defaultProps = {
  modelItem: null,
  afterSubmit: () => {},
};

export default AddPicture;
