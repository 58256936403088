/* eslint-disable max-len */
import React from 'react';

const Delete = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M11.1,0.9L10.2,0H5.8L4.9,0.9H1.8v1.8h12.4V0.9H11.1z M4.8,7.9l1.3-1.3L8,8.5l1.9-1.9l1.3,1.3L9.3,9.8l1.9,1.9l-1.3,1.3L8,11l-1.9,1.9l-1.3-1.3l1.9-1.9L4.8,7.9z M2.7,14.2c0,1,0.8,1.8,1.8,1.8l0,0h7.1l0,0c1,0,1.8-0.8,1.8-1.8V3.5H2.7L2.7,14.2z" />
    </svg>
  );
};

export default Delete;
/* eslint-enable max-len */
