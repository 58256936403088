import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { withRouter } from 'react-router';
import Carousel from 'nuka-carousel';

import PageTitle from '@old/components/guide/PageTitle';
import t from 'resources/translations';
import FlexRow from '@old/components/common/FlexRow';
import Box from '@old/components/common/Box';
import FlexColumn from '@old/components/common/FlexColumn';
import Subtitle from '@old/components/common/Subtitle';
import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import Divider from '@old/components/common/Divider';
import config from '@old/config';
import Center from '@old/components/common/Center';
import BoxWhite from '@old/components/common/BoxWhite';
import RenderDate from '@old/components/old/RenderDate';
import TextValue from '@old/components/common/TextValue';
import DateLabel from '@old/components/custom/DateLabel';
import Model from '@old/model';
import {
  getErrorMessage,
  notify,
  getPaginationId,
  isAllowed,
  parseToCurrencyString,
  parseToFormattedNumberString,
} from 'old/utils';
import { setPagination, openModal } from 'store/actions';
import PagePagination from '@old/components/old/PagePagination';
import Button from '@old/components/guide/Button';
import Message from '@old/components/common/Message';
import Indicator from '@old/components/common/Indicator';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Allow from '@old/components/common/Allow';
import { useStatistics } from '@old/hooks';
import SelectDateRange from '@old/components/modals/SelectDateRange';

const SmsPage = ({ isMobile, pagination, history, ...action }) => {
  const { dateFormat, dateTimeFormat, minItemsPerPage } = config;
  const [buyHistory, setBuyHistory] = useState();
  const [smsPacks, setSmsPacks] = useState([]);
  const [smsCount, setSmsCount] = useState(999999);
  const [loading, setLoading] = useState(true);
  const [loadingPackets, setLoadingPackets] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(isMobile ? 0 : null);
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(30, 'days').startOf('day'),
    end: moment().endOf('day'),
  });
  const [stats, statsReady] = useStatistics(0, 'farms', dateRange);

  const sendSmsCount = stats ? stats.total_count : 0;

  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    setLoading(true);

    const fetchData = async () => {
      try {
        const [totalSms, result, buyPagination] = await Model.Farms.fetchAccountInformation({
          page: getActivePage() || 1,
          per_page: minItemsPerPage,
          cancelToken: source.token,
        });
        // When you delete last element on page, go back to previous one
        const pag = { ...buyPagination };
        const lastPage = Math.ceil(pag.count / pag.per_page);
        if (pag.per_page * pag.current > pag.count) {
          if (lastPage && pag.current > lastPage) {
            pag.current = lastPage;
            history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
          }
          if (pag.current === 0) {
            pag.current = 1;
          }
        }
        action.setPagination(pag, getPaginationKey());
        setSmsCount(totalSms);
        setBuyHistory(result);
        setLoading(false);
      } catch (e) {
        if (!axios.isCancel(e)) {
          notify(getErrorMessage(e), { type: 'error' });
          setLoading(false);
        }
      }
    };

    fetchData();
    return () => source.cancel();
  }, [pagination.current, history.location.state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    setLoadingPackets(true);

    const fetchData = async () => {
      if (isAllowed('sms_pack.buy')) {
        try {
          const [fetchedSmsPacks] = await Model.Farms.fetchAvailableSmsPacks({
            page: 1,
            per_page: 9999,
            cancelToken: source.token,
          });
          setSmsPacks(fetchedSmsPacks);
          setLoadingPackets(false);
        } catch (e) {
          if (!axios.isCancel(e)) {
            notify(getErrorMessage(e), { type: 'error' });
            setLoadingPackets(false);
          }
        }
      }
    };

    fetchData();
    return () => source.cancel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClick = () => {
    action.openModalByName('confirmModal', {
      header: t('smsManagementPage.buyConfirmation'),
      content: (
        <FlexColumn>
          <div className="font-bold">
            {t('smsManagementPage.youChosePacket', {
              count: smsPacks[selectedIndex].amount,
              price: smsPacks[selectedIndex].price,
            })}
          </div>
          <div>{t('smsManagementPage.doYouConfirm')}</div>
        </FlexColumn>
      ),
      approveLabel: t('buttons.buy'),
      onSubmitConfirm: async () => {
        await Model.Farms.requestSmsPack(smsPacks[selectedIndex].id);
        history.push('/sms/confirm', { selectedPackage: smsPacks[selectedIndex] });
      },
    });
  };

  if (isMobile) {
    return (
      <>
        <PageTitle title={t('menu.sms')} />
        <FlexColumn>
          <FlexRow cols="2/1">
            <Box fullHeight>
              <FlexColumn separated="small">
                <Subtitle>{t('smsManagementPage.howNotificationsWork')}</Subtitle>
                <FlexColumn separated={false}>
                  <div className="text-highlight">{t('smsManagementPage.smsIsSendWhen')}</div>
                  {t('smsManagementPage.beforeRide')}
                  {t('smsManagementPage.instructorCancel')}
                </FlexColumn>
                <FlexColumn separated={false}>
                  <div className="text-highlight">{t('smsManagementPage.benefitsFromNotifications')}</div>
                  {t('smsManagementPage.noExcuses')}
                  {t('smsManagementPage.everyoneRemembers')}
                  {t('smsManagementPage.dontWorry')}
                </FlexColumn>
              </FlexColumn>
            </Box>
            <BoxWhite>
              <FlexRow noShrink>
                <FlexColumn>
                  <Center>
                    <Subtitle>{t('smsManagementPage.smsSend')}</Subtitle>
                  </Center>
                  <Center>
                    <div className="p-4 border rounded border-teal">
                      {statsReady ? (
                        parseToFormattedNumberString(sendSmsCount)
                      ) : (
                        <Icon.Spinner className="fill-teal rotate small" />
                      )}
                    </div>
                  </Center>
                  <Center>
                    <SelectDateRange dateRange={dateRange} onSave={setDateRange} />
                  </Center>
                </FlexColumn>
                <FlexColumn>
                  <Center>
                    <Subtitle>{t('smsManagementPage.smsLeft')}</Subtitle>
                  </Center>
                  <Center>
                    <div
                      className={cls('p-4 border rounded border-teal', {
                        'border-orange': smsCount < 200,
                      })}
                    >
                      {loading ? (
                        <Icon.Spinner className="fill-teal rotate small" />
                      ) : (
                        parseToFormattedNumberString(smsCount)
                      )}
                    </div>
                  </Center>
                  <Center>{t('smsManagementPage.asOf', { date: moment().format(dateFormat) })}</Center>
                </FlexColumn>
              </FlexRow>
            </BoxWhite>
          </FlexRow>
          <Allow permissionKey="sms_pack.buy">
            <FlexColumn>
              <BoxWhite>
                <Subtitle>{t('smsManagementPage.buySmsPackage')}</Subtitle>
              </BoxWhite>
              {loadingPackets && (
                <Center>
                  <Icon.Spinner className="fill-teal rotate big" />
                </Center>
              )}
              {!loadingPackets && (
                <FlexColumn>
                  <Carousel
                    withoutControls
                    slideIndex={selectedIndex}
                    afterSlide={i => setSelectedIndex(i)}
                    enableKeyboardControls={false}
                  >
                    {smsPacks.map((item, index) => {
                      return (
                        <Box key={index}>
                          <FlexColumn>
                            <Center>
                              <Subtitle>
                                {parseToFormattedNumberString(item.amount)} {t('smsManagementPage.sms')}
                              </Subtitle>
                            </Center>
                            <Center className="text-teal">
                              {item.amount < 1000 && <Icon.PhoneSmsSmall className="big" />}
                              {item.amount >= 1000 && item.amount <= 3000 && <Icon.PhoneSms className="big" />}
                              {item.amount > 3000 && <Icon.PhoneSmsLarge className="big" />}
                            </Center>
                            <Center>
                              <div>{parseToCurrencyString(item.price)}</div>
                            </Center>
                          </FlexColumn>
                        </Box>
                      );
                    })}
                  </Carousel>
                  <Center>
                    <div>
                      <RowAligner separated="small">
                        {[...Array(smsPacks.length)].map((_, index) => (
                          <ButtonSimple key={index} onClick={() => setSelectedIndex(index)}>
                            <Indicator className="dots" active={selectedIndex === index} />
                          </ButtonSimple>
                        ))}
                      </RowAligner>
                    </div>
                  </Center>
                </FlexColumn>
              )}
              <BoxWhite>
                <Button onClick={onClick} fluid className="ml-auto mr-0 px-20">
                  {t('buttons.buy')}
                </Button>
              </BoxWhite>
            </FlexColumn>
          </Allow>
          <BoxWhite>
            <Subtitle>{t('smsManagementPage.purcharseHistory')}</Subtitle>
          </BoxWhite>
          {loading && (
            <Center>
              <Icon.Spinner className="fill-teal rotate big" />
            </Center>
          )}
          {!loading && (
            <>
              <div className="relative">
                <FlexColumn>
                  {buyHistory.map((item, index) => (
                    <FlexRow key={index} cols="none/1">
                      <DateLabel date={moment(item.created_at).format(dateTimeFormat)} />
                      <Box>
                        <FlexColumn separated="small">
                          <Subtitle>
                            {t('smsManagementPage.smsPacket', { count: parseToFormattedNumberString(item.amount) })}
                          </Subtitle>
                          <FlexColumn separated={false}>
                            <TextValue text={t('smsManagementPage.price')} value={parseToCurrencyString(item.price)} />
                            <TextValue text={t('smsManagementPage.description')} value={item.description} />
                          </FlexColumn>
                        </FlexColumn>
                      </Box>
                    </FlexRow>
                  ))}
                </FlexColumn>
              </div>
              {isEmpty(buyHistory) && (
                <Message className="mx-4 md:mx-6" info>
                  {t('message.noResults')}
                </Message>
              )}
              <PagePagination paginationKey={getPaginationKey()} />
            </>
          )}
          <BoxWhite>
            <FlexColumn separated="small">
              <Subtitle>{t('smsManagementPage.help')}</Subtitle>
              {t('smsManagementPage.doYouNeedHelp')}
              <RowAligner separated="small">
                <Icon.Mail small className="fill-teal" />
                <a href="mailto:kontakt@horsemanago.com">kontakt@horsemanago.com</a>
              </RowAligner>
            </FlexColumn>
          </BoxWhite>
        </FlexColumn>
      </>
    );
  }

  return (
    <>
      <PageTitle title={t('menu.sms')} />
      <FlexColumn>
        <FlexRow cols="2/1" stretched>
          <Box fullHeight>
            <FlexColumn separated="small">
              <Subtitle>{t('smsManagementPage.howNotificationsWork')}</Subtitle>
              <FlexColumn separated={false}>
                <div className="text-highlight">{t('smsManagementPage.smsIsSendWhen')}</div>
                {t('smsManagementPage.beforeRide')}
                {t('smsManagementPage.instructorCancel')}
              </FlexColumn>
              <FlexColumn separated={false}>
                <div className="text-highlight">{t('smsManagementPage.benefitsFromNotifications')}</div>
                {t('smsManagementPage.noExcuses')}
                {t('smsManagementPage.everyoneRemembers')}
                {t('smsManagementPage.dontWorry')}
              </FlexColumn>
            </FlexColumn>
          </Box>
          <FlexColumn>
            <Box fullHeight>
              <FlexRow cols="3/1">
                <RowAligner>
                  <FlexColumn>
                    <Subtitle>{t('smsManagementPage.smsSend')}</Subtitle>
                    <SelectDateRange dateRange={dateRange} onSave={setDateRange} />
                  </FlexColumn>
                  <div className="p-4 border rounded border-teal">
                    {statsReady ? (
                      parseToFormattedNumberString(sendSmsCount)
                    ) : (
                      <Icon.Spinner className="fill-teal rotate small" />
                    )}
                  </div>
                </RowAligner>
              </FlexRow>
            </Box>
            <Box fullHeight>
              <FlexRow cols="3/1">
                <RowAligner>
                  <FlexColumn>
                    <Subtitle>{t('smsManagementPage.smsLeft')}</Subtitle>
                    <div className="text">{t('smsManagementPage.asOf', { date: moment().format(dateFormat) })}</div>
                  </FlexColumn>
                  <div
                    className={cls('p-4 border rounded border-teal', {
                      'border-orange': smsCount < 200,
                    })}
                  >
                    {loading ? (
                      <Icon.Spinner className="fill-teal rotate small" />
                    ) : (
                      parseToFormattedNumberString(smsCount)
                    )}
                  </div>
                </RowAligner>
              </FlexRow>
            </Box>
          </FlexColumn>
        </FlexRow>
        <Allow permissionKey="sms_pack.buy">
          <FlexColumn>
            <Subtitle>{t('smsManagementPage.buySmsPackage')}</Subtitle>
            {loadingPackets && (
              <Center>
                <Icon.Spinner className="fill-teal rotate big" />
              </Center>
            )}
            {!loadingPackets && (
              <FlexRow>
                {smsPacks.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={cls('box cursor-pointer hover:bg-teal-transparent', {
                        'bg-teal-transparent shadow-box': selectedIndex === index,
                      })}
                      onClick={() => {
                        setSelectedIndex(index);
                      }}
                    >
                      <FlexColumn>
                        <Center>
                          <Subtitle>
                            {parseToFormattedNumberString(item.amount)} {t('smsManagementPage.sms')}
                          </Subtitle>
                        </Center>
                        <Center className="text-teal">
                          {item.amount < 1000 && <Icon.PhoneSmsSmall className="big" />}
                          {item.amount >= 1000 && item.amount <= 3000 && <Icon.PhoneSms className="big" />}
                          {item.amount > 3000 && <Icon.PhoneSmsLarge className="big" />}
                        </Center>
                        <Center>
                          <div>{parseToCurrencyString(item.price)}</div>
                        </Center>
                      </FlexColumn>
                    </div>
                  );
                })}
              </FlexRow>
            )}
            <Button onClick={onClick} disabled={selectedIndex === null} className="ml-auto mr-0 px-20">
              {t('buttons.buy')}
            </Button>
          </FlexColumn>
        </Allow>
        <Divider />
        <Subtitle>{t('smsManagementPage.purcharseHistory')}</Subtitle>
        {loading && (
          <Center>
            <Icon.Spinner className="fill-teal rotate big" />
          </Center>
        )}
        {!loading && (
          <>
            <div className="relative">
              <div className="line-time" />
              <FlexColumn>
                {buyHistory.map((item, index) => (
                  <FlexRow key={index} cols="none/1">
                    <div style={{ width: 200, minWidth: 200 }}>
                      <FlexColumn>
                        <BoxWhite padding>
                          <div className="square-small absolute" />
                          <RenderDate
                            className="text-title-md text-right float-right"
                            withWeekDay
                            startDate={moment(item.created_at)}
                          />
                        </BoxWhite>
                      </FlexColumn>
                    </div>
                    <Box>
                      <FlexColumn separated="small">
                        <Subtitle>
                          {t('smsManagementPage.smsPacket', { count: parseToFormattedNumberString(item.amount) })}
                        </Subtitle>
                        <FlexColumn separated={false}>
                          <TextValue text={t('smsManagementPage.price')} value={parseToCurrencyString(item.price)} />
                          <TextValue text={t('smsManagementPage.description')} value={item.description} />
                        </FlexColumn>
                      </FlexColumn>
                    </Box>
                  </FlexRow>
                ))}
              </FlexColumn>
            </div>
            {isEmpty(buyHistory) && (
              <Message className="mx-4 md:mx-6" info>
                {t('message.noResults')}
              </Message>
            )}
            <PagePagination paginationKey={getPaginationKey()} />
          </>
        )}
        <FlexColumn separated="small">
          <Subtitle>{t('smsManagementPage.help')}</Subtitle>
          {t('smsManagementPage.doYouNeedHelp')}
          <RowAligner separated="small">
            <Icon.Mail small className="fill-teal" />
            <a href="mailto:kontakt@horsemanago.com">kontakt@horsemanago.com</a>
          </RowAligner>
        </FlexColumn>
      </FlexColumn>
    </>
  );
};

SmsPage.propTypes = {
  openModalByName: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number,
  }).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({}),
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ pagination, app: { isMobile } }) => {
  const paginationKey = getPaginationId(`${window.location.pathname}`);
  return {
    isMobile,
    pagination: pagination[paginationKey] || {},
  };
};

export default withRouter(connect(mapStateToProps, { setPagination, openModalByName: openModal })(SmsPage));
