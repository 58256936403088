export default class BaseModel {
  constructor(options) {
    this.name = options.modelName;
    this.client = options.client;
    this.farmId = options.farmId;
    this.ItemClass = options.ItemClass;

    this.originBasePath = options.basePath;
    this.basePath = this.farmId ? options.basePath.raplace('{farmId}', this.farmId) : options.basePath;
    this.dataKey = options.dataKey || options.modelName;
    this.updateKey = options.updateKey || options.modelName;
  }

  refreshTokenAndFarmId({ client, farmId }) {
    this.client = client;
    this.farmId = farmId;
    this.basePath = farmId ? this.originBasePath.replace('{farmId}', farmId) : this.originBasePath;
  }

  parse = (rawData, options) => {
    const { ItemClass } = this;
    const modelItem = new ItemClass(rawData, options);
    modelItem.modelName = this.name;
    modelItem.model = this; // FIXMENOW: check reference when farmId changed
    return modelItem;
  };

  fetch = async (itemId, cancelToken) => {
    const response = await this.client.get(`${this.basePath}/${itemId}`, { cancelToken });
    return this.parse(response.data);
  };

  fetchAll = async ({ cancelToken, ...params }) => {
    const response = await this.client.get(this.basePath, { cancelToken, params });
    return [response.data[this.dataKey].map(item => this.parse(item)), response.data.pagination];
  };

  create = async (newItem) => {
    const response = await this.client.post(this.basePath, { [this.updateKey]: newItem });
    return this.parse(response.data);
  };

  update = async (itemId, changes) => {
    const response = await this.client.patch(`${this.basePath}/${itemId}`, { [this.updateKey]: changes });
    return this.parse(response.data);
  };

  delete = async itemId => this.client.delete(`${this.basePath}/${itemId}`);

  mapFiltersData = () => ({});
}
