import { Button, ListItem, Span } from '../elements';
import { TDisclosure, TReactNode } from 'resources/types/commonTypes';
import { useContext } from 'react';
import { ContextMenuContext } from '../combinations/ContextMenu';
import css from '@styled-system/css';

type TContextMenuItem = {
  children: TReactNode;
  onClick: () => void;
};
const ContextMenuItem = ({ children, onClick }: TContextMenuItem) => {
  const contextMenuDisclosure = useContext(ContextMenuContext) as TDisclosure;
  const onClickAndClose = () => {
    onClick();
    contextMenuDisclosure.onClose();
  };
  return (
    <ListItem cursor="pointer" minW="140px">
      <Button css={css({ textAlign: 'left' })} variant="clean" onClick={onClickAndClose} padding={10} width="100%">
        <Span>{children}</Span>
      </Button>
    </ListItem>
  );
};

export default ContextMenuItem;
