import { Select } from 'components/combinations';
import InputWrapper from 'components/combinations/forms/InputWrapper';
import { Box, Input, Stack } from 'components/elements';
import { DEFAULT_DIRECTIONAL } from 'config/constans';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import t from 'resources/translations';

import FormLabel from './FormLabel';

type TTextInput = {
  value: string;
  name: string;
  onChange: Function;
};
type onChangeOption = {
  key: string;
  value: string;
  label: string;
};
const PhoneInput = ({ value, name, onChange }: TTextInput) => {
  const defaultDirectionalValue = DEFAULT_DIRECTIONAL.value;
  const initialDirectional = () => {
    return value ? value.substring(0, 3) : defaultDirectionalValue;
  };

  const initialNumberPhone = () => {
    return value ? value.substring(3) : '';
  };

  const [directional, setDirectional] = useState(initialDirectional);
  const [numberPhone, setNumberPhone] = useState(initialNumberPhone);

  const onChangeDirectional = (directionalNumber: string) => {
    setDirectional(directionalNumber);
    if (!isEmpty(numberPhone)) {
      setFullNumber(directionalNumber, numberPhone);
    }
  };

  const onChangePhoneNumber = (number: string) => {
    setNumberPhone(number);
    setFullNumber(directional, number);
  };

  const setFullNumber = (newDirectional: string, newNumberPhone: number | string) => {
    const fullNumberPhone = `${newDirectional}${newNumberPhone}`;
    onChange(fullNumberPhone, name);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = new RegExp('^$|^\\d+$');
    if (reg.test(e.target.value)) {
      onChangePhoneNumber(e.target.value);
    }
  };
  return (
    <Stack isHorizontal>
      <Box minWidth={120}>
        <FormLabel htmlFor="prefix" required>
          {t('labels.prefix')}
        </FormLabel>
        <Select
          name="prefix"
          options={[
            {
              key: DEFAULT_DIRECTIONAL.value,
              value: DEFAULT_DIRECTIONAL.value,
              label: DEFAULT_DIRECTIONAL.value,
            },
          ]}
          onChange={(selected: onChangeOption) => onChangeDirectional(selected.value)}
          value={{ label: directional, value: directional }}
          isSearchable={false}
          isDisabled
        />
      </Box>
      <Box minWidth={120}>
        <FormLabel htmlFor="phone" required>
          {t('labels.phone')}
        </FormLabel>
        <InputWrapper>
          <Input type="tel" value={numberPhone} onChange={onInputChange} placeholder={t('placeholders.phone')} />
        </InputWrapper>
      </Box>
    </Stack>
  );
};

export default PhoneInput;
