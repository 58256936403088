/**
 * @module AsyncSelectInput
 * */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AsyncPaginate } from 'react-select-async-paginate';
import SelectOptionList from '@old/components/view/list/SelectOption';
import InputWrapper from '@old/components/common/InputWrapper';
import ModalSimple from '@old/components/common/ModalSimple';
import t from 'resources/translations';

/**
 * @typedef {Object} SelectOption
 * @property {String|Number} value
 * @property {String} label
 */

/**
 * Component displays select and allows users to tick only one option.
 * Options are loaded asynchronously by loadOptions passed to component.
 * Component renders AsyncSelectInputDesktop or AsyncSelectInputMobile based on current screen width.
 *
 * @param  {SelectOption[]} [props.value]
 * @param  {Function} props.onChange function used after changes
 * @param  {String} [props.placeholder]
 * @param  {Function} props.loadOptions Function to fetch options from model
 * @param  {Function} [props.customFilter] Additional filter
 * @param  {Object} [props.customOptions] Additional options
 * @param  {*} [props.customOptionComponents] Option with custom: label, slug, color
 * @param  {Boolean} props.isMobile
 * @param  {Boolean} props.isTablet
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 * @param  {Boolean} [props.isSearchable]
 */
const AsyncSelectInput = ({
  value,
  onChange,
  placeholder,
  loadOptions,
  customFilter,
  customOptions,
  customOptionComponents,
  isMobile,
  isTablet,
  label,
  required,
  isSearchable,
  name,
}) => {
  const selectDesktop = (
    <AsyncSelectInputDesktop
      value={value}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      loadOptions={loadOptions}
      customOptions={customOptions}
      customFilter={customFilter}
      customOptionComponents={customOptionComponents}
      isSearchable={isSearchable}
    />
  );

  const selectMobileModal = (
    <AsyncSelectInputMobile
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      loadOptions={loadOptions}
      customFilter={customFilter}
      customOptionComponents={customOptionComponents}
      isSearchable={isSearchable}
    />
  );

  return (
    <InputWrapper label={label} required={required}>
      {(isMobile || isTablet) ? selectMobileModal : selectDesktop}
    </InputWrapper>
  );
};

AsyncSelectInput.defaultProps = {
  value: '',
  placeholder: '',
  label: '',
  required: false,
  customOptionComponents: null,
  customOptions: {},
  customFilter: () => true,
  isSearchable: true,
  name: '',
};

AsyncSelectInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  customOptions: PropTypes.shape({}), // custom options from https://react-select.com/props
  loadOptions: PropTypes.func.isRequired,
  customFilter: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isSearchable: PropTypes.bool,
  name: PropTypes.string,
};

const mapStateToProps = ({ app: { isMobile, isTablet } }) => ({ isMobile, isTablet });

export default connect(mapStateToProps)(AsyncSelectInput);

/**
 * @module AsyncSelectInputDesktop
 */

/**
 * @typedef {Object} SelectOption
 * @property {String|Number} value
 * @property {String} label
 */

/**
 * Desktop version of asynchronous select input. Usually you won't need to use this component directly and you should use AsyncSelectInput.
 *
 * @param  {SelectOption[]} [props.value]
 * @param  {Function} props.onChange function used after changes
 * @param  {String} [props.placeholder]
 * @param  {Function} props.loadOptions Function to fetch options from model
 * @param  {Function} [props.customFilter] Additional filter
 * @param  {Object} [props.customOptions] Additional options
 * @param  {*} [props.customOptionComponents] Option with custom: label, slug, color
 * @param  {Boolean} [props.isSearchable]
 */
const AsyncSelectInputDesktop = ({
  value,
  onChange,
  placeholder,
  loadOptions,
  customFilter,
  customOptions,
  customOptionComponents,
  isSearchable,
}) => {
  const OptionComponent = customOptionComponents ? { Option: customOptionComponents } : {};
  return (
    <AsyncPaginate
      className="select"
      classNamePrefix="select"
      components={{ ...OptionComponent }}
      placeholder={placeholder}
      loadOptions={(keyword, _, { page }) => loadOptions(keyword, page)}
      noOptionsMessage={() => <div>{t('message.noResults')}</div>}
      loadingMessage={() => <div>{t('general.loading')}</div>}
      onChange={onChange}
      value={value}
      debounceTimeout={500}
      defaultOptions
      cacheOptions
      {...customOptions}
      filterOption={option => customFilter(option.data)}
      additional={{ page: 1 }}
      isSearchable={isSearchable}
    />
  );
};

AsyncSelectInputDesktop.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  label: '',
  required: false,
  customOptionComponents: null,
  customOptions: {},
  customFilter: () => true,
  isSearchable: true,
};

AsyncSelectInputDesktop.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  customOptions: PropTypes.shape({}), // custom options from https://react-select.com/props
  customFilter: PropTypes.func,
  loadOptions: PropTypes.func.isRequired,
  isSearchable: PropTypes.bool,
};
/**
 * @module AsyncSelectInputMobile
 */

/**
 * @typedef {Object} SelectOption
 * @property {String|Number} value
 * @property {String} label
 */

/**
 * Mobile version of asynchronous select input. Usually you won't need to use this component directly and you should use AsyncSelectInput.
 *
 * @param  {SelectOption[]} [props.value]
 * @param  {Function} props.onChange function used after changes
 * @param  {String} [props.placeholder]
 * @param  {Function} props.loadOptions Function to fetch options from model
 * @param  {Function} [props.customFilter] Additional filter
 * @param  {*} [props.customOptionComponents] Option with custom: label, slug, color
 * @param  {Boolean} [props.isSearchable]
 * @param  {String} [props.name]
 */
const AsyncSelectInputMobile = ({
  value,
  onChange,
  placeholder,
  loadOptions,
  customFilter,
  customOptionComponents,
  isSearchable,
  name,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(value);

  const onSubmit = () => {
    setIsOpen(false);
    onChange(selectedOptions);
  };

  const isEmptyValue = !value;

  return (
    <>
      {isEmptyValue
        ? (<input readOnly placeholder={placeholder} onClick={() => setIsOpen(true)} />)
        : (<div className="input-content" onClick={() => setIsOpen(true)}>{value.label}</div>)}
      <ModalSimple close={() => setIsOpen(false)} open={isOpen}>
        <ModalSimple.Header>{placeholder}</ModalSimple.Header>
        <ModalSimple.Body>
          <SelectOptionList
            name={`${name}Search`}
            loadOptions={loadOptions}
            optionComponent={customOptionComponents}
            customFilter={customFilter}
            onChange={setSelectedOptions}
            value={selectedOptions}
            isSearchable={isSearchable}
          />
        </ModalSimple.Body>
        <ModalSimple.Action close={() => setIsOpen(false)} onSubmit={onSubmit} />
      </ModalSimple>
    </>
  );
};

AsyncSelectInputMobile.defaultProps = {
  value: '',
  customOptionComponents: null,
  placeholder: '',
  customFilter: () => true,
  isSearchable: true,
};

AsyncSelectInputMobile.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  loadOptions: PropTypes.func.isRequired,
  customFilter: PropTypes.func,
  isSearchable: PropTypes.bool,
  name: PropTypes.string.isRequired,
};
