import React, { useState } from 'react';
import axios from 'axios';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import { getErrorMessage, notify } from 'old/utils';
import t from 'resources/translations';
import { fetchGroup } from 'store/actions';
import store from 'store';
import SelectOptionList from '@old/components/view/list/SelectOption';
import Option from '@old/components/view/listItem/SelectOption';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';

const AddRiderToGroup = ({ group, onClose }) => {
  const [selectedRiders, setSelectedRiders] = useState([]);
  const membersOptions = group.getMembersOptions();
  let source;

  const addRiders = async () => {
    const { CancelToken } = axios;
    if (source) {
      source.close();
    }

    source = CancelToken.source();

    try {
      const membersIds = membersOptions.map(member => member.value);
      const selectedMembersIds = selectedRiders.map(member => member.value);
      await group.update({ membership_ids: [...membersIds, ...selectedMembersIds] });
      notify(selectedRiders.length === 1 ? t('message.addUserSuccess') : t('message.addUsersSuccess'));
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      store.dispatch(fetchGroup(group.id, source.token));
    }
  };

  if (!group) return null;

  return (
    <Modal header={t('model.add.participants')} onClose={onClose} isOpen>
      <SelectOptionList
        name="membersSearch"
        onChange={setSelectedRiders}
        loadOptions={Model.Members.fetchClientOptions}
        optionComponent={Option.Member}
        value={selectedRiders}
        isOptionDisabled={optionData =>
          membersOptions.map(membersOption => membersOption.value).includes(optionData.value)
        }
        isMulti
      />
      <FlexRow separated="small" noShrink>
        <ButtonOutline onClick={onClose} fluid>
          {t('general.cancel')}
        </ButtonOutline>
        <Button onClick={addRiders} fluid async>
          {t('general.add')}
        </Button>
      </FlexRow>
    </Modal>
  );
};

AddRiderToGroup.defaultProps = {
  group: null,
};

export default AddRiderToGroup;
