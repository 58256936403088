import t from 'resources/translations';
import moment from 'moment';
import Event from 'models/Event';
import { Link, Span, Stack, Flex, Box, Text, Icons } from 'components/elements';
import { AvatarWithLabel, Wrap } from 'components/combinations';
import { TReactNode } from 'resources/types/commonTypes';
import { TFlexProps, TIconProps, TTextProps } from 'resources/types/elementsTypes';
import { parseToCurrencyString } from 'old/utils';
import EventDateSquare from '../../components/combinations/EventDateSquare';
import { useAppStore } from 'utils/storeUtils';

type TStyleProps = {
  eventName: TTextProps;
  eventNameBox: TFlexProps;
  warningIcon: TIconProps;
};

const styles = {
  eventName: {
    overflow: 'hidden',
    maxW: '100%',
    display: '-webkit-box',
    lineHeight: '1.625',
    lineClamp: 3,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  },
  eventNameBox: {
    alignItems: 'center',
  },
  warningIcon: {
    size: 20,
    fill: 'orange',
  },
} as TStyleProps;

type TMyEventInfo = {
  event: Event;
  children: TReactNode;
  isShowBell: boolean;
};

const MyEventInfo = ({ event, children, isShowBell }: TMyEventInfo) => {
  const { isMobile } = useAppStore();
  const instructors = event.getInstructors();

  if (isMobile) {
    return (
      <>
        <Flex w="full" pl={60} pr={10} minH={70}>
          <Flex {...styles.eventNameBox}>
            <Text {...styles.eventName}>
              <Link to={event.getUrl()} fontSize="md">
                {event.getName()}
              </Link>
            </Text>
          </Flex>
          <Flex flex="none" alignItems="center" justifyContent="center">
            {isShowBell && <Icons.BellTwo {...styles.warningIcon} />}
          </Flex>
        </Flex>
        <Stack isHorizontal>
          {/* fix me */}
          <EventDateSquare
            statusColor={event.getStatusColor()}
            startDate={moment(event.startDate)}
            endDate={moment(event.endDate)}
            eventStatus={event.status}
            myEventsMobile
          />
          <Stack spacing="sm" pt="sm">
            <Stack spacing="sm" alignItems="center" isHorizontal>
              <Icons.Price fill="teal-dark" size="base" />
              <Text fontWeight="medium">
                {`${t('dashboard.priceForParticipation')}: `}
                <Span fontWeight="regular">{parseToCurrencyString(event.type.defaultEventCost)}</Span>
              </Text>
            </Stack>
            {event.getPlace() && (
              <Stack spacing="sm" alignItems="center" isHorizontal>
                <Icons.Place fill="teal-dark" size="base" />
                <Text fontWeight="medium">
                  {`${t('general.place')}: `}
                  <Span fontWeight="regular">{event.getPlace().getName()}</Span>
                </Text>
              </Stack>
            )}
            {/* @ts-ignore */}
            <Text fontSize="sm">{t(`eventStatus.${event.status}`).toUpperCase()}</Text>
          </Stack>
        </Stack>
        <Stack p="sm" spacing="sm">
          <Text fontWeight="medium">{t('general.instructors')}:</Text>
          <Stack spacing="sm">
            {instructors.map(instructor => (
              <AvatarWithLabel
                key={instructor.id}
                src={instructor.getAvatar('small')}
                alt={instructor.getFullName()}
                title={instructor.getFullName()}
                squareSize={26}
              >
                <Link to={instructor.getProfileUrl()} fontSize="base" mr="lg">
                  {instructor.getFullName()}
                </Link>
              </AvatarWithLabel>
            ))}
          </Stack>
        </Stack>
        {children}
      </>
    );
  }

  return (
    <Box w="full" my="base">
      <Stack w="full" pl="2xl" mb="md" isHorizontal>
        <EventDateSquare
          startDate={moment(event.startDate)}
          endDate={moment(event.endDate)}
          eventStatus={event.status}
          statusColor={event.getStatusColor()}
        />
        <Stack width="100%" isHorizontal>
          <Stack flex={1}>
            <Flex {...styles.eventNameBox}>
              <Link to={event.getUrl()} {...styles.eventName}>
                {event.getName()}
              </Link>
            </Flex>
            <Stack spacing="sm">
              <Text fontWeight="medium">{t('general.instructors')}:</Text>
              <Wrap spacing="xs">
                {instructors.map(instructor => (
                  <AvatarWithLabel
                    key={instructor.id}
                    src={instructor.getAvatar('small')}
                    alt={instructor.getFullName()}
                    title={instructor.getFullName()}
                    squareSize={26}
                  >
                    <Link to={instructor.getProfileUrl()} fontSize="sm" mr="lg">
                      {instructor.getFullName()}
                    </Link>
                  </AvatarWithLabel>
                ))}
              </Wrap>
            </Stack>
          </Stack>
          <Stack flex={1} spacing="sm">
            <Stack spacing="sm" alignItems="center" isHorizontal>
              <Icons.Price fill="teal-dark" size="base" />
              <Text fontWeight="medium">
                {`${t('dashboard.priceForParticipation')}: `}
                <Span fontWeight="regular">{parseToCurrencyString(event.type.defaultEventCost)}</Span>
              </Text>
            </Stack>
            {event.getPlace() && (
              <Stack spacing="sm" alignItems="center" isHorizontal>
                <Icons.Place fill="teal-dark" size="base" />
                <Text fontWeight="medium">
                  {`${t('general.place')}: `}
                  <Span fontWeight="regular">{event.getPlace().getName()}</Span>
                </Text>
              </Stack>
            )}
          </Stack>
          <Stack flex={1} justifyContent="flex-end" spacing="sm" isHorizontal>
            {isShowBell && <Icons.BellTwo {...styles.warningIcon} />}
          </Stack>
        </Stack>
      </Stack>
      {children}
    </Box>
  );
};

export default MyEventInfo;
