import axios, { AxiosError, AxiosResponse } from 'axios';
import localStorageService from '../services/storage/localStorageService';

const HTTP = axios.create({ baseURL: process.env.REACT_APP_API_URL });

HTTP.interceptors.request.use(
  config => {
    const token = localStorageService._getToken();
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default HTTP;
