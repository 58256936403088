import React from 'react';

const ArrowLeft = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      width="100%"
      className="icon"
    >
      <path d="M12.9,1.9L6.8,8l6.1,6.1L11.1,16l-8-8l8-8L12.9,1.9z" />
    </svg>
  );
};

export default ArrowLeft;
