import t from 'resources/translations';
import { IHorseFatique } from 'resources/types/horsesTypes';
import { Box, Text } from 'components/elements';
import { useAppStore } from 'utils/storeUtils';

const HorseFatigue = ({ fatigue }: { fatigue: IHorseFatique }) => {
  const { isMobile } = useAppStore();
  if (!fatigue) return null;
  let fatigueColor = '';
  if (fatigue.total < 12) fatigueColor = '#00bc1f';
  else if (fatigue.total < 22) fatigueColor = '#e2c707';
  else if (fatigue.total < 32) fatigueColor = '#ff7900';
  else fatigueColor = '#b22302';
  const { weekly, tridaily, daily } = fatigue;
  const roundedWeekly = weekly.toFixed(0);
  const roundedTridaily = tridaily.toFixed(0);
  const roundedDaily = daily.toFixed(0);
  return (
    <Box style={{ backgroundColor: fatigueColor }} radius="sm" py={1} px={3}>
      <Text color="white" whiteSpace="nowrap">
        {!isMobile
          ? t('general.fatigue', {
              hours_weekly: roundedWeekly,
              hours_tridaily: roundedTridaily,
              hours_daily: roundedDaily,
            })
          : `${roundedWeekly}h / ${roundedTridaily}h / ${roundedDaily}h`}
      </Text>
    </Box>
  );
};

HorseFatigue.defaultProps = {
  fatigue: null,
};

export default HorseFatigue;
