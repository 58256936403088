import moment from 'moment';
import truncate from 'lodash/truncate';
import BaseModel from '@old/model/BaseModel';
import config from '@old/config';
import { Place } from '@old/model/Place';

class EventTypes extends BaseModel {
  constructor(options) {
    super({
      dataKey: 'event_types',
      modelName: 'eventTypes',
      updateKey: 'event_type',
      basePath: '/api/v1/farms/{farmId}/event_types',
      ItemClass: EventType,
      ...options,
    });
  }

  fetchOptions = async (keyword, page, fetchParams) => {
    const [eventTypes, pagination] = await this.fetchAll({ per_page: 20, page, keyword, ...fetchParams });
    const mappedOptions = eventTypes.map(type => ({
      key: type.id,
      value: type.id,
      label: type.getName(),
      image: type.getImage('thumb'),
      color: type.getColor(),
      slug: type.slug,
      timeIntervals: type.timeIntervals,
      isDeleted: type.isDeleted(),
      defaultPlace: type.getDefaultPlace() ? type.getDefaultPlaceOption() : null,
      eventCost: type.defaultEventCost,
    }));

    return {
      options: mappedOptions,
      hasMore: pagination.pages > page,
      additional: { page: page + 1 },
    };
  };
}

class EventType {
  constructor(data) {
    const timeIntervals = data.time_intervals ? data.time_intervals.map((timeInterval) => {
      const duration = moment.duration(timeInterval, 'minutes');
      const hours = moment.utc(duration.asMilliseconds()).format('HH');
      const minutes = moment.utc(duration.asMilliseconds()).format('mm');
      return `${hours}:${minutes}`;
    }) : [];

    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.timeIntervals = timeIntervals;
    this.primaryPictureId = data.primary_picture_id;
    this.timeIntervalsDuration = data.time_intervals;
    this.deleted = data.deleted;
    this.picture = data.picture || null;
    this.defaultEventCost = data.default_event_cost || null;
    this.defaultPlace = data.default_place ? new Place(data.default_place) : null;
    this.privileges = data.meta ? data.meta.privileges : [];
  }

  static mapToSaveData = (data) => {
    const timeIntervals = data.timeIntervals && data.timeIntervals.map((timeInterval) => {
      const arrTimes = timeInterval.split(':');
      return parseInt(arrTimes[0], 10) * 60 + parseInt(arrTimes[1], 10);
    });

    const eventType = {
      name: data.eventTypeName,
      slug: data.eventTypeSlug,
      time_intervals: timeIntervals,
      primary_picture_id: data.primaryPictureId,
      default_event_cost: data.defaultEventCost ? parseFloat(data.defaultEventCost) : undefined,
      place_id: data.defaultEventPlace,
      picture_attributes: data.picture && {
        file: data.picture,
      },
    };

    Object.keys(eventType).forEach((fieldName) => {
      if (eventType[fieldName] === undefined) delete eventType[fieldName];
    });

    return eventType;
  };

  static mapToFormData = (data) => {
    const eventTypeData = {
      eventTypeName: data.name,
      eventTypeSlug: data.slug,
      timeIntervals: data.timeIntervals.map(time => ({ key: time, value: time, label: time })),
      defaultEventCost: data.defaultEventCost,
      defaultEventPlace: data.getDefaultPlaceOption(),
      picture: data.getImage(),
    };
    return eventTypeData;
  };

  update = async changes => this.model.update(this.id, changes);

  getName(limit = false) {
    return limit ? truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getImage(size) {
    if (!this.picture || !this.picture.url) {
      return '/img/event-type-placeholder.png';
    }

    return this.picture.url[size] || this.picture.url.medium;
  }

  getSlug() {
    return this.slug || '';
  }

  getColor() {
    return config.accentColorsEventTypes[this.id % config.accentColorsEventTypes.length];
  }

  isDeleted() {
    return this.deleted;
  }

  getDefaultPlace() {
    return this.defaultPlace;
  }

  getDefaultPlaceOption() {
    return this.defaultPlace ? {
      value: this.getDefaultPlace().id,
      label: this.getDefaultPlace().getName(),
    } : null;
  }

  getPermission(permissionKey) {
    return this.privileges.includes(permissionKey);
  }
}

export { EventType, EventTypes };
