import { useState } from 'react';
import t from 'resources/translations';

import { openMailClient, parseToCurrencyString, parseTransactionsToString } from 'old/utils';
import { Icons, Flex, Text, Stack, Button, Box, A, Tooltip } from 'components/elements';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Divider,
  LinkMember,
  CopyButton,
} from 'components/combinations';
import config from 'old/config';
import { useModalDisclosure } from 'utils';
import { useAppSelector, useLoggedMember } from 'utils/storeUtils';
import { TFlexProps, TIconCircleProps, TStackProps, TIconProps } from 'resources/types/elementsTypes';
import Payment from 'models/Payment';
import Transaction from 'models/Transaction';
import Bill from 'models/Bill';

type TStyleProps = {
  iconStatus: TIconCircleProps;
  iconInfo: TIconCircleProps;
  iconCancel: TIconProps;
  modalContent: TStackProps;
  transactionPropertyContainer: TFlexProps;
  paymentContainer: TFlexProps;
};
const styleProps = (isMobile = false, bgGrey = false) => {
  return {
    iconStatus: {
      size: 'sm',
      fill: 'teal-dark',
      border: { borderWidth: 1, borderColor: 'teal-dark' },
      bgColor: 'white',
    },
    iconCancel: {
      size: 24,
      fill: 'teal-dark',
    },
    iconInfo: {
      size: 'xs',
      border: { borderWidth: 1, borderColor: 'currentColor' },
      bgColor: 'transparent',
    },
    modalContent: {
      minHeight: 120,
      maxHeight: ['calc(100vh - 600px)', 'calc(100vh - 530px)'],
      px: 0,
      mx: 'lg',
      spacing: 0,
    },
    transactionPropertyContainer: {
      py: 'sm',
      px: 'md',
      bgColor: bgGrey ? 'grey-light' : 'transparent',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paymentContainer: {
      flexDirection: 'column',
      w: isMobile ? 'calc(100% + 40px)' : 'calc(100% + 60px)',
      mx: isMobile ? -20 : -30,
      paddingInner: 30,
    },
  } as TStyleProps;
};

type TPaymetntsList = TStackProps & {
  bill: Bill;
  paddingInner?: number;
  noLabel?: boolean;
  quickTransactionDetails?: boolean;
};

const PaymentsList = ({
  bill,
  noLabel = false,
  quickTransactionDetails = false,
  paddingInner,
  ...stackProps
}: TPaymetntsList) => {
  const loggedMember = useLoggedMember();
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const modalDisclosure = useModalDisclosure();
  const [transactionsDetails, setTransactionsDetails] = useState<Transaction[]>([]);
  const transactionsString = parseTransactionsToString(transactionsDetails);

  const renderIcon = (payment: Payment): JSX.Element => {
    if (payment.getStatus() === 'refunded') {
      return <Icons.ReturnCircle {...styleProps().iconStatus} />;
    }
    if (payment.getStatus() === 'booked') {
      return <Icons.AcceptCircle {...styleProps().iconStatus} />;
    }
    if (payment.getStatus() === 'unprocessed' && payment.getLatestTransaction()?.getStatus() === 'pending') {
      return <Icons.PendingCircle {...styleProps().iconStatus} />;
    }
    return <Icons.CancelPaymentOutline {...styleProps().iconCancel} />;
  };

  const onShowDetails = (payment: Payment) => {
    setTransactionsDetails(payment.getTransactions());
    modalDisclosure.onOpen();
  };

  const checkPaymentIsCancelled = (payment: Payment) => {
    return payment.getLatestTransaction()?.getStatus() === 'cancelled';
  };

  const checkPaymentIsPending = (payment: Payment) => {
    return payment.getLatestTransaction()?.getStatus() === 'pending';
  };

  const onOpenMailClient = () => {
    return openMailClient(
      config.emails.support,
      `${t('bills.problemWithPayment')} - ${loggedMember?.getFullName()}`,
      transactionsString
    );
  };

  if (!bill.payments?.length) return null;

  return (
    <Stack spacing="xs" pb="md" {...stackProps}>
      {!noLabel && <Text fontWeight="medium">{t('bills.paymentsHistory')}:</Text>}
      <Flex {...styleProps(isMobile).paymentContainer}>
        {bill.payments.map((payment, index) => {
          const bgColor = index % 2 === 1 || bill.payments.length === 1 ? 'transparent' : 'white';
          const isCancelled = checkPaymentIsCancelled(payment);
          const isPending = checkPaymentIsPending(payment);
          const isBooked = payment.getStatus() === 'booked';
          const isRefunded = payment.getStatus() === 'refunded';
          const payer = payment.getPayer();
          const refunder = payment.getRefunder();
          return (
            <Flex
              key={payment.id}
              bgColor={bgColor}
              flexDirection="row"
              px={paddingInner}
              w="full"
              minH={45}
              alignItems={['flex-start', 'center']}
            >
              <Flex flex="none" pr="md">
                {renderIcon(payment)}
              </Flex>
              <Flex flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                <Flex flex="none" pr="md" order={[0, 0]}>
                  <Text fontWeight="light">{payment.getUpdatedDate()}</Text>
                </Flex>
                {(isBooked || isRefunded || isCancelled || isPending) && (
                  <Flex flex={2} order={[1, 0]} pt={['sm', 0]} pr="md">
                    {isRefunded && (
                      <Text>
                        {`${t('bills.returnedBy')}: `}
                        <LinkMember member={refunder}>{refunder?.getFullName()}</LinkMember>
                      </Text>
                    )}
                    {(isBooked || isCancelled || isPending) && payer && (
                      <Text>
                        {`${t('bills.addedBy')}: `}
                        <LinkMember member={payer} forceRender>
                          {payer?.getFullName()}
                        </LinkMember>
                      </Text>
                    )}
                  </Flex>
                )}
                {!(isBooked || isRefunded || isCancelled || isPending) && !isMobile && <Flex flex={3} pr="md" />}
                <Flex flex={2} order={[1, 0]} pt={['sm', 0]} pr="md">
                  <Text opacity={isCancelled && !isMobile ? 0.4 : 1}>
                    {/* @ts-ignore */}
                    {`${t(`paymentStatus.${payment.getPaymentType()}`)}, ${parseToCurrencyString(payment.getAmount())}`}
                  </Text>
                </Flex>
                <Stack
                  isHorizontal={!isMobile}
                  flex={2}
                  order={[3, 0]}
                  pt={['sm', 0]}
                  alignItems={['flex-start', 'center']}
                  justifyContent="flex-end"
                  spacing="xs"
                  mb={['sm', 0]}
                >
                  {isCancelled && (
                    <Text pr="md" color="orange" textAlign="right">
                      {t('bills.paymentCancelled').toLowerCase()}
                    </Text>
                  )}
                  {isPending && (
                    <Text pr="md" color="orange" textAlign="right">
                      {t('bills.paymentStarted').toLowerCase()}
                    </Text>
                  )}
                  <Stack alignItems="center" isHorizontal>
                    {!!payment.getTransactions().length && (
                      <Flex alignItems="center">
                        <Tooltip content={t('bills.onlinePaymentDetails')}>
                          {quickTransactionDetails ? (
                            <CopyButton
                              variant="link"
                              fontSize="base"
                              content={parseTransactionsToString(payment.getTransactions())}
                            >
                              {(isCopied: boolean) => (
                                <Text color={isCopied ? 'new-grey-light' : 'teal-light'}>
                                  {isCopied ? `${t('message.copied')}!` : t('buttons.copyToClipboard')}
                                </Text>
                              )}
                            </CopyButton>
                          ) : (
                            <Button variant="link" onClick={() => onShowDetails(payment)} fontSize="base">
                              <Stack spacing="sm" alignItems="center" isHorizontal>
                                <Icons.InfoCircle {...styleProps().iconInfo} />
                                <Text>{t('buttons.details')}</Text>
                              </Stack>
                            </Button>
                          )}
                        </Tooltip>
                      </Flex>
                    )}
                  </Stack>
                </Stack>
              </Flex>
            </Flex>
          );
        })}
        {modalDisclosure.isOpen && !quickTransactionDetails && (
          <Modal {...modalDisclosure}>
            <ModalHeader>{t('bills.onlinePaymentDetails')}</ModalHeader>
            <ModalCloseButton onClose={modalDisclosure.onClose} />
            <Stack w="full" px="xl" pb="xl">
              <Text lineHeight="lg">
                {`${t('bills.ifProblemCopyDetailsAndSendEmail')} `}
                <Button variant="link" onClick={onOpenMailClient}>
                  {config.emails.support}
                </Button>
              </Text>
              <Text lineHeight="lg">{t('bills.youCanAlsoContactSupport')}</Text>
            </Stack>
            <ModalContent {...styleProps().modalContent}>
              {transactionsDetails.map((tDetails: Transaction, index: number) => {
                const truncateRedirectUrl = tDetails.getRedirect().split('/').pop();
                return (
                  <Box key={tDetails.id} w="full">
                    {index !== 0 && <Divider variant="dashed" margin="xl" color="new-grey-light" />}
                    <Flex {...styleProps(true).transactionPropertyContainer}>
                      <Text fontWeight="medium">{t('labels.amount')}</Text>
                      <Text textAlign="right">{parseToCurrencyString(tDetails.getAmount())}</Text>
                    </Flex>
                    <Flex {...styleProps(true).transactionPropertyContainer}>
                      <Text fontWeight="medium" whiteSpace="nowrap" pr="xs">
                        {t('labels.transactionId')}
                      </Text>
                      <Text textAlign="right" wordBreak="break-all">
                        {tDetails.getOrderId()}
                      </Text>
                    </Flex>
                    <Flex {...styleProps(true).transactionPropertyContainer}>
                      <Text fontWeight="medium">{t('labels.remoteId')}</Text>
                      <Text textAlign="right">{tDetails.getRemoteId()}</Text>
                    </Flex>
                    <Flex {...styleProps().transactionPropertyContainer}>
                      <Text fontWeight="medium">{t('labels.paymentStatus')}</Text>
                      <Text textTransform="capitalize" textAlign="right">
                        {tDetails.getStatus()}
                      </Text>
                    </Flex>
                    {!tDetails.isSuccess() && (
                      <Flex {...styleProps(true).transactionPropertyContainer}>
                        <Text fontWeight="medium">{t('labels.redirectUrl')}</Text>
                        <A href={tDetails.getRedirect()} isExternal>
                          {truncateRedirectUrl}
                        </A>
                      </Flex>
                    )}
                    <Flex {...styleProps(tDetails.isSuccess()).transactionPropertyContainer}>
                      <Text fontWeight="medium">{t('labels.updatedDate')}</Text>
                      <Text textAlign="right">{tDetails.getUpdatedDate()}</Text>
                    </Flex>
                    <Flex {...styleProps(!tDetails.isSuccess()).transactionPropertyContainer}>
                      <Text fontWeight="medium">{t('labels.createDate')}</Text>
                      <Text textAlign="right">{tDetails.getCreatedDate()}</Text>
                    </Flex>
                  </Box>
                );
              })}
            </ModalContent>
            <Stack w="full" px="xl" py="xl">
              <CopyButton content={transactionsString} isAfterDisabled>
                {(isCopied: boolean) => (
                  <Text>{isCopied ? `${t('message.copied')}!` : t('buttons.copyToClipboard')}</Text>
                )}
              </CopyButton>
              <Button variant="outline" onClick={modalDisclosure.onClose}>
                {t('buttons.close')}
              </Button>
            </Stack>
          </Modal>
        )}
      </Flex>
    </Stack>
  );
};

PaymentsList.defaultProps = {
  paddingInner: 30,
};
export default PaymentsList;
