import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_FARM } from 'old/constants/formNames';
import { notify, getChanges, mapFormStateToData, getErrorMessage, getInitValues, removeHTMLTags } from 'old/utils';
import config from '@old/config';
import PageTitle from '@old/components/guide/PageTitle';
import Spinner from '@old/components/common/Spinner';
import Subtitle from '@old/components/common/Subtitle';

const FarmForm = ({ history, initValues }) => {
  const [farm, setFarm] = useState(false);
  const [isPending, setPending] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPending(true);
        const result = await Model.Farms.fetch();
        setFarm(result);
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      } finally {
        setPending(false);
      }
    };
    fetchData();
  }, []);

  const formName = FORM_FARM;

  const getInitFormState = (formState, staff, farmDescription) => ({
    farmName: formState?.farmName,
    phone: formState?.phone,
    email: formState?.email,
    www: formState?.www,
    farmDescription: farmDescription ?? formState?.farmDescription,
    staff: staff ?? formState?.staff,
    address: formState?.address,
    postcode: formState?.postcode,
    city: formState?.city,
    monday: formState?.monday,
    tuesday: formState?.tuesday,
    wednesday: formState?.wednesday,
    thursday: formState?.thursday,
    friday: formState?.friday,
    saturday: formState?.saturday,
    sunday: formState?.sunday,
    coordinates: formState?.coordinates,
  });

  const initialValues = getInitValues({
    formName: 'farm',
    customInitValues: farm ? Model.Farm.mapToFormData(farm) : initValues,
  });

  const onSubmit = async formState => {
    const staff = removeHTMLTags(formState.staff);
    const farmDescription = removeHTMLTags(formState.farmDescription);
    const values = farm
      ? getChanges(
          getInitFormState(formState, staff, farmDescription),
          mapFormStateToData({ formState: initialValues, initValue: true })
        )
      : getInitFormState(formState, staff, farmDescription);

    if (Object.keys(values).length === 0) {
      notify(t('message.noChange'), { type: 'warning', autoClose: 1500 });
      return;
    }

    try {
      const mappedFarm = Model.Farm.mapToSaveData(values);
      await Model.Farms.update(farm.id, mappedFarm);
      notify(t('message.succesSave'));
      history.replace('/farm');
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  if (isPending) return <Spinner />;
  if (!farm) return null;

  return (
    <>
      <PageTitle title={t('model.edit.farms')} back="/farm" />
      <Form formName={formName} initValues={initialValues}>
        <Field.Text name="farmName" label={t('labels.name')} placeholder={t('placeholders.name')} required />
        <Field.Phone
          name="phone"
          label={t('labels.phone')}
          placeholder={t('placeholders.phone')}
          defaultDirectionalValue={config.defaultDirectional.value}
          required
        />
        <Field.Email name="email" label={t('labels.email')} placeholder={t('placeholders.email')} required />
        <Field.Text name="www" label={t('labels.www')} placeholder={t('placeholders.www')} />
        <Field.RichTextEditor
          name="farmDescription"
          label={t('labels.description')}
          placeholder={t('placeholders.description')}
          required
        />
        <Field.RichTextEditor name="staff" label={t('labels.staff')} placeholder={t('placeholders.staff')} />
        <Field.Text
          name="address"
          label={t('labels.addressHome')}
          placeholder={t('placeholders.addressHome')}
          required
        />
        <Field.Text
          name="city"
          label={`${t('labels.town')} / ${t('labels.post')}`}
          placeholder={t('placeholders.town')}
          required
        />
        <Field.Text
          name="postcode"
          label={`${t('labels.postalCode')}`}
          placeholder={t('placeholders.postalCode')}
          required
        />
        <Field.GoogleMap name="coordinates" label={`${t('labels.localization')}`} />
        <Subtitle>{t('labels.openingHours')}:</Subtitle>
        <Field.DoubleTime
          name="monday"
          label={`${t('week.mo')}`}
          placeholder={t('placeholders.postalCode')}
          withCheckbox
          required
        />
        <Field.DoubleTime
          name="tuesday"
          label={`${t('week.tu')}`}
          placeholder={t('placeholders.postalCode')}
          withCheckbox
          required
        />
        <Field.DoubleTime
          name="wednesday"
          label={`${t('week.we')}`}
          placeholder={t('placeholders.postalCode')}
          withCheckbox
          required
        />
        <Field.DoubleTime
          name="thursday"
          label={`${t('week.th')}`}
          placeholder={t('placeholders.postalCode')}
          withCheckbox
          required
        />
        <Field.DoubleTime
          name="friday"
          label={`${t('week.fr')}`}
          placeholder={t('placeholders.postalCode')}
          withCheckbox
          required
        />
        <Field.DoubleTime
          name="saturday"
          label={`${t('week.sa')}`}
          placeholder={t('placeholders.postalCode')}
          withCheckbox
          required
        />
        <Field.DoubleTime
          name="sunday"
          label={`${t('week.su')}`}
          placeholder={t('placeholders.postalCode')}
          withCheckbox
          required
        />
        <FormButton formName={formName} onClick={onSubmit} async>
          {t('general.saveChanges')}
        </FormButton>
      </Form>
    </>
  );
};

FarmForm.defaultProps = {
  initValues: {},
};

FarmForm.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  initValues: PropTypes.shape({}),
};

export default FarmForm;
