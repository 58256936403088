import React from 'react';
import { Box } from '../../elements';
import { TBoxProps } from 'resources/types/elementsTypes';

type TChildrenProps = {
  isExpanded: boolean;
  setExpanded: Function;
};

type TAccordionHeader = {
  expandedIndex: number;
  index: number;
  children: ({ isExpanded, setExpanded }: TChildrenProps) => React.ReactNode;
  setExpanded: Function;
} & TBoxProps;

const AccordionHeader = ({ children, expandedIndex, index, setExpanded, ...boxProps }: TAccordionHeader) => {
  const isExpanded = expandedIndex === index;
  const setIndex = () => {
    setExpanded(isExpanded ? -1 : index);
  };

  return <Box {...boxProps}>{children({ isExpanded, setExpanded: setIndex })}</Box>;
};

AccordionHeader.defaultProps = {
  expandedIndex: 0,
  index: 0,
  setExpanded: () => {},
};

AccordionHeader.displayName = 'AccordionHeader';
export default AccordionHeader;
