/**
 * @module CodeField
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import t from 'resources/translations';

/**
 * @typedef {Object} Validation
 * @property {Function} condition validation function. If it returns true, i.e. the value is correct
 * @property {String} errorHint error information displayed after incorrect validation
 */

/**
 * A component that generates a form field with a 5 character code
 *
 * @param {String[]} [props.value]
 * @param {Function} props.onChangeField function activated after changing the value
 * @param {Validation[]} [props.validation] array of shapes with validation functions and error messages
 * @param {String} [props.label]
 * @param {String} props.name
 * @param {String} props.formName
 * @param {Boolean} [props.required]
 */

const CodeField = ({ onChangeField, validation, label, name, formName, value, required }) => {
  const inputsRef = useRef([]);

  const parseStringToCode = string =>
    [0, 1, 2, 3, 4, 5].map(index => {
      if (string[index]) {
        return string[index];
      }
      return '';
    });

  const code = Array.isArray(value) ? value : parseStringToCode(value);

  const onPaste = (e, startIndex) => {
    e.preventDefault();
    e.stopPropagation();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pasteContent = clipboardData.getData('Text');
    [0, 1, 2, 3, 4, 5].splice(startIndex).forEach((codeIndex, index) => {
      if (pasteContent.length > index) {
        code[codeIndex] = pasteContent[index];
      }
    });

    return onChangeField({ formName, fieldName: name, fieldState: [...code] });
  };

  const onChange = (val, index) => {
    code[index] = val[val.length - 1] ? val[val.length - 1] : '';
    if (code[index]) {
      inputsRef.current[index < 5 ? index + 1 : index].focus();
    }
    return onChangeField({ formName, fieldName: name, fieldState: [...code] });
  };

  const validationFuncs = [
    ...validation,
    {
      condition: val => val && val.length && val.every(item => item),
      errorHint: t('error.fieldIsRequired'),
    },
  ];

  return (
    <FormField name={name} formName={formName} validation={validationFuncs} value={value}>
      <div className="basic input-code">
        {label && (
          <label className="font-bold">
            {label}
            {required && <span className="asterisk" />}
          </label>
        )}
        <div className="input-code-container">
          {[0, 1, 2, 3, 4, 5].map(index => [
            <input
              key={index}
              ref={ref => {
                inputsRef.current[index] = ref;
              }}
              value={code[index]}
              type="text"
              onMouseUp={() => inputsRef.current[index].select()}
              onChange={e => onChange(e.target.value, index, e)}
              onPaste={e => onPaste(e, index)}
              autoCapitalize="off"
            />,
            <div className="code-spacer" key={`spacer${index}`}>
              -
            </div>,
          ])}
        </div>
      </div>
    </FormField>
  );
};

CodeField.defaultProps = {
  value: ['', '', '', '', '', ''],
  validation: [],
  label: '',
  required: false,
};

CodeField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  label: PropTypes.string,
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.func.isRequired,
      errorHint: PropTypes.string,
    })
  ),
  required: PropTypes.bool,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return { value: inputValue };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(CodeField);
