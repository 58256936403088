import { Stack } from '../../elements';
import { TReactNode } from 'resources/types/commonTypes';
import { IStack } from 'resources/types/elementsTypes';

type TCardContent = IStack & {
  children: TReactNode;
};

const CardContent = ({ children, ...stackProps }: TCardContent) => {
  return (
    <Stack spacing="base" py="md" px={['md', 'xl']} {...stackProps}>
      {children}
    </Stack>
  );
};

export default CardContent;
