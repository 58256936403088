import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

// MultiValue container used in select inputs
const SelectMultiValueContainer = (props) => {
  return (
    <components.MultiValueContainer {...props}>
      <div className="tag">
        {props.children}
      </div>
    </components.MultiValueContainer>
  );
};

SelectMultiValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SelectMultiValueContainer;
