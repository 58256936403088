import t from 'resources/translations';
import { getErrorMessage, notify, parseToCurrencyString } from 'old/utils';
import { Box, Button, MotionBox, Span, Stack, Text, Icons } from 'components/elements';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  AvatarWithLabel,
  ModalButtons,
} from 'components/combinations';

import { TStoreStateModals, TWrappedModal } from '.';
import { useQueryClient } from 'react-query';
import { TicketService } from 'services';
import Ticket from 'models/Ticket';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import FormLabel from 'components/combinations/forms/FormLabel';
import FormField from 'components/combinations/forms/FormField';
import { useFormik } from 'formik';
import { yup } from 'utils';
import DateInput from 'components/combinations/forms/DateInput';
import { DATE_FORMAT } from 'config/constans';
import moment from 'moment';
import Bill from 'models/Bill';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import { useShallowSelector } from 'old/hooks';

type TTicketRidesEditModalProps = {
  ticket: Ticket;
  bill?: Bill;
  callback?: Function;
};

const TicketValidityEditModal = ({ onSubmitAndClose, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const queryClient = useQueryClient();
  const { ticket, bill, callback } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['TICKET_VALIDITY_EDIT']?.props || {}
  ) as TTicketRidesEditModalProps;

  const validationSchema = yup.object().shape({
    expire_at: yup
      .string()
      .format(DATE_FORMAT, true)
      .required(t('error.fieldIsRequired'))
      .test({
        message: t('error.activationTicketAfterExpired'),
        exclusive: false,
        params: {},
        test: function (value) {
          return moment(value, DATE_FORMAT).isAfter(moment(this.parent.active_at, DATE_FORMAT).subtract(1, 'days'));
        },
      }),
    active_at: yup.string().required(t('error.fieldIsRequired')).format(DATE_FORMAT, true),
  });

  const onSubmit = async () => {
    try {
      await TicketService.update(ticket.id, {
        active_at: moment(values.active_at, DATE_FORMAT).startOf('day').toISOString(),
        expire_at: moment(values.expire_at, DATE_FORMAT).endOf('day').toISOString(),
        audit_comment: t('tickets.periodValidityOfTicketChanged'),
      });
      queryClient.invalidateQueries('TICKETS');
      callback && callback();
      notify(t('message.succesSave'));
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };

  const { values, setFieldValue, setFieldTouched, ...formik } = useFormik({
    initialValues: {
      active_at: moment(ticket.activeAt).format(DATE_FORMAT),
      expire_at: moment(ticket.expireAt).format(DATE_FORMAT),
    },
    validationSchema,
    onSubmit,
  });

  let days = 0;
  const validityInDays = moment(values.expire_at, DATE_FORMAT).diff(moment(values.active_at, DATE_FORMAT), 'days') + 1;
  const notNaNValidity = isNaN(validityInDays) ? 0 : validityInDays;
  if (formik.isValid) {
    days = notNaNValidity < 0 ? 0 : notNaNValidity;
  }
  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{t('tickets.changePeriodOfValidity')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting}>
        <ModalDescription mb="base">
          <Text fontWeight="medium">{ticket.getName()}</Text>
          <Box>
            <AvatarWithLabel
              title={ticket.membership.getFullName()}
              alt={ticket.membership.getFullName()}
              src={ticket.membership.getAvatar('thumb')}
              squareSize={26}
            >
              <Text>{ticket.membership.getFullName()}</Text>
            </AvatarWithLabel>
          </Box>
          <Stack spacing="sm">
            <Text fontWeight="medium">{t('labels.eventTypes')}:</Text>
            <Text>
              {ticket.eventTypes.map((eventType, index) => (
                <Span>
                  {index !== 0 && ', '}
                  {eventType.getName()}
                </Span>
              ))}
            </Text>
          </Stack>
          <Text fontWeight="medium">
            {t('tickets.numberOfRidesInTicket')}: <Span fontWeight="regular">{ticket.count}</Span>
          </Text>
          <Stack spacing="sm" isHorizontal alignItems="center">
            <Text fontWeight="medium">
              {`${t('labels.price')}: `}
              {bill && <Span fontWeight="regular">{parseToCurrencyString(bill.getDue())}</Span>}
            </Text>
            {!bill && (
              <MotionBox
                transition={{ ease: 'linear', duration: 2, repeat: Infinity }}
                animate={{ rotate: 360 }}
                squareSize={16}
                display="inline-block"
              >
                <Icons.Spinner fill="teal" />
              </MotionBox>
            )}
          </Stack>
        </ModalDescription>
        <Box>
          <FormField errors={formik.touched.active_at ? formik.errors.active_at : ''}>
            <FormLabel htmlFor="active_at" required>
              {t('labels.ticketActiveFrom')}
            </FormLabel>
            <Box maxW={360}>
              <DateInput
                name="active_at"
                placeholder={t('placeholders.date')}
                value={values.active_at}
                onChange={(value: string) => {
                  setFieldValue('active_at', value);
                  setFieldTouched('expire_at', true);
                  setFieldTouched('active_at', true);
                }}
                pickerPosition="top"
                pickerOptions={{ maxDate: moment(values.expire_at, DATE_FORMAT) }}
              />
            </Box>
          </FormField>
          <FormField errors={formik.touched.expire_at ? formik.errors.expire_at : ''}>
            <FormLabel htmlFor="reason" required>
              {t('labels.ticketActiveTo')}
            </FormLabel>
            <Box maxW={360}>
              <DateInput
                name="expire_at"
                placeholder={t('placeholders.date')}
                value={values.expire_at}
                onChange={(value: string) => {
                  setFieldValue('expire_at', value);
                  setFieldTouched('expire_at', true);
                }}
                pickerPosition="top"
                pickerOptions={{ minDate: moment(values.active_at, DATE_FORMAT) }}
              />
            </Box>
          </FormField>
          <>
            <Box display="inline-block" pb="xs" mb="sm" borderBottomWidth={1} borderColor="grey-light">
              <Text>
                {formik.isValid && formik.dirty
                  ? t('ticketOffers.ticketWillBeValidFor')
                  : t('ticketOffers.ticketIsValidFor')}
                <Span fontWeight="bold">{` ${days} `}</Span>
                {t('tickets.days', { count: days })}.
              </Text>
            </Box>
          </>
        </Box>
      </ModalContent>
      <ModalButtons>
        <Button onClick={() => onSubmitAndClose(formik.submitForm)} disabled={!(formik.isValid && formik.dirty)}>
          {isSubmitting ? <SpinnerDots label={t('general.loading2')} inline /> : t('tickets.changePeriodOfValidity')}
        </Button>
        <Button onClick={onClose} variant="outline">
          {t('buttons.dontSaveChanges')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default TicketValidityEditModal;
