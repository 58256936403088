import React from 'react';
import PropTypes from 'prop-types';

const RoundedBar = ({ fill, x, y, width, height }) => {
  if (height < 2) {
    return null;
  }
  return <rect x={x} y={y} rx="5" ry="5" width={width} height={height - 2} fill={fill} />;
};

RoundedBar.defaultProps = {
  fill: '',
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

RoundedBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default RoundedBar;
