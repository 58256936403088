import { AxiosResponse as ARes } from 'axios';
import HTTP from 'config/axios';
import Place from 'models/Place';
import { IResWithPagination, TFetchedOptions, TOption } from 'resources/types/commonTypes';
import { IPlaceFetchParams, IPlaceRes } from 'resources/types/placesTypes';

class PlaceService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async fetch(placeId: number | string) {
    const response: ARes<IPlaceRes> = await HTTP.get(`${this.apiBaseURL}/places/${placeId}`);
    return response.data;
  }

  async fetchAll(params: IPlaceFetchParams) {
    const response: ARes<IResWithPagination<IPlaceRes[]>> = await HTTP.get(`${this.apiBaseURL}/places`, {
      params,
    });
    return response.data;
  }

  async fetchOptions(keyword: string, additional: { page: number }, params: IPlaceFetchParams = {}) {
    const page = additional?.page || 1;
    const { places, pagination } = await this.fetchAll({
      per_page: 20,
      keyword,
      page,
      ...params,
    });

    const mappedOptions = places.map(placeRes => {
      const place = new Place(placeRes);
      return {
        key: place.id,
        value: place.id,
        label: place.getName(),
        type: 'place',
        data: {
          image: place.getAvatar('thumb'),
          color: place.getColor(),
          slug: place.slug,
          isDeleted: place.deleted,
        },
      } as TOption;
    });

    return {
      options: mappedOptions,
      hasMore: pagination.pages > page,
      additional: { page: pagination.next },
    } as TFetchedOptions;
  }
}

export default new PlaceService();
