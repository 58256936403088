import moment from 'moment';
import t from 'resources/translations';
import * as yup from 'yup';
import { Message } from 'yup/lib/types';
import { parseAmountStringToFloat } from './formsUtils';

/**
 * Overrided yup method
 */
yup.addMethod(yup.string, 'format', function (format, parseStrict, message: Message) {
  return this.test('isDateFormatValid', message, function (value) {
    if (!value) {
      return true;
    }
    const { path, createError } = this;
    const isValid = moment(value, format, parseStrict).isValid();
    if (!isValid) {
      return createError({ path, message: message ?? t('error.badDateFormatDDMMYYYY') });
    }
    return true;
  });
});

yup.addMethod(yup.string, 'maxNumberFromString', function (max, message: Message) {
  return this.test('maxNumber', message, function (value) {
    const { path, createError } = this;
    if (parseAmountStringToFloat(value || '0') > max) {
      return createError({ path, message: message ?? t('error.lowerThanOrEqualPrice', { count: max }) });
    }
    return true;
  });
});
export default yup;
