/* eslint-disable max-len */
import React from 'react';

const Block = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,14.4C8,14.4,8,14.4,8,14.4c-1.5,0-2.9-0.5-3.9-1.4l0,0l9-9c0.8,1.1,1.3,2.4,1.3,3.9c0,0,0,0,0,0v0
        C14.4,11.5,11.5,14.4,8,14.4L8,14.4L8,14.4z M1.6,8c0-3.5,2.9-6.4,6.4-6.4l0,0c1.5,0,2.8,0.5,3.9,1.4l0,0l-9,9
        C2.1,10.9,1.6,9.5,1.6,8C1.6,8,1.6,8,1.6,8L1.6,8L1.6,8z M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8c4.4,0,8-3.6,8-8v0c0,0,0,0,0,0
        C16,3.6,12.4,0,8,0L8,0C8,0,8,0,8,0z"
      />
    </svg>
  );
};

export default Block;
/* eslint-enable max-len */
