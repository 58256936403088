import exif from 'exif-js';
import { toast } from 'react-toastify';
import { v5 as uuidv5 } from 'uuid';
import { Converter } from 'showdown';

import config from '@old/config';
import { isEmpty, compact, uniqBy } from 'lodash';

const converter = new Converter({ smartIndentationFix: true, ghCodeBlocks: false });

/**
 * Function removes exif data from images to prevent unwanted images rotation
 * @function
 * @memberof Utils
 * @see {@link https://github.com/chrisborrowdale/orientation-exif-blob/blob/master/src/index.js|Removing exif data }
 * @param {File|Blob|MediaSource} image
 */
export const resetImageOrientation = image => (
  new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      exif.getData(img, () => {
        const { width, height } = img;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const srcOrientation = exif.getTag(img, 'Orientation');

        if ([5, 6, 7, 8].indexOf(srcOrientation) > -1) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        switch (srcOrientation) {
          case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
          case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
          case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
          case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
          case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
          case 7: ctx.transform(0, -1, -1, 0, height, width); break;
          case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
          default: ctx.transform(1, 0, 0, 1, 0, 0);
        }

        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/jpeg');
        resolve(dataURL);
      });
    };

    img.src = URL.createObjectURL(image);
  })
);

/**
 * @see {@link https://www.npmjs.com/package/uuid#version-5|UUID v5}
 * @function
 * @memberof Utils
 * @param  {String} string custom namespace
 * @return {String}        return a RFC4122 v5 UUID
 */
export const getPaginationId = string => uuidv5(string, config.customUUIDNamespace);

export const getTabsId = string => uuidv5(string, config.customUUIDNamespace);

export const parseMarkdownToHTML = (markdown) => {
  return converter.makeHtml(markdown || '');
};

export const parseHTMLToMarkdown = (html) => {
  return html ? converter.makeMarkdown(html) : html;
};

export const convertMDtoPlainText = (htmlValue) => {
  return htmlValue
    .replace(/<[^>]+>/g, '')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/href\s*=\s*(['"])(https?:\/\/.+?)\1/g, '');
};

export const removeHTMLTags = (html) => {
  return html.replace(/(<([^>]+)>)/ig, '') === '' ? '' : html;
};

/**
 * A function to get an object with a minimum value by key
 * @function
 * @memberof Utils
 * @param {Object[]} array Contains Model data
 * @param {String} key
 * @return {Object}
 */
export const getObjectWithMinValueByKey = (array, key) => {
  return array.reduce((minIdItem, nextItem) => (nextItem[key] < minIdItem[key] ? nextItem : minIdItem), array[0]);
};

/**
 * @function
 * @memberof Utils
 * @param {Object[]} pictures Contains Images object
 * @param {String} placeholderLink e.g. "/img/farm-placeholder.png"
 * @return {Object}
 */
export const mapModelPictures = (pictures, placeholderLink) => {
  const mapPlaceholders = () => {
    return {
      thumb: placeholderLink,
      small: placeholderLink,
      medium: placeholderLink,
      large: placeholderLink,
    };
  };

  return pictures ? pictures.map(picture => (
    {
      id: picture.id,
      description: picture.description,
      url: picture.url || mapPlaceholders(),
    }
  )) : [];
};

/**
 * [todo description]
 * @function
 * @memberof Utils
 * @param  {Number}  width             [todo description]
 * @param  {Boolean} [itemWidth=false] [todo description]
 * @return {Number}                    [todo description]
 */
export const countGridColumns = (width, itemWidth = false) => {
  const elementWidth = itemWidth || config.minGridItemWidth;
  const numberOfColumns = Math.round(width / elementWidth);
  if (!numberOfColumns) return 1;
  if (config.minItemsPerPage % numberOfColumns !== 0) return numberOfColumns - 1;
  return numberOfColumns;
};

/**
 * Function that displays notifications for users
 * @function
 * @memberof Utils
 * @param  {String|Node} content text or JSX to display
 * @param  {Object} [options={}] see link below
 * @see {@link  https://github.com/fkhadra/react-toastify#toast|All options}
 */
export const notify = (content, options = {}) => toast(content, {
  ...options,
  type: options.type || 'success',
  autoClose: options.type ? 3000 : 1500,
  position: options.position || 'bottom-right',
});

/**
 * Function that checks if error with the specifi key exists in the API response
 * @function
 * @memberof Utils
 * @param  {Object} error Server error response caught by try catch
 * @param  {String} key Key for which the function should check errors
 * @param  {String} errorKey Error sought { ex. 'taken', 'less_than' }
 */
export const errorByKeyExists = (error, key, errorKey) => {
  const errorObject = error.response.data && error.response.data[key];
  return errorObject && errorObject[0] && errorObject[0].errors && errorObject[0].errors.includes(errorKey);
};

/**
 * Function that maps form states to filters object
 * @function
 * @memberof Utils
 * @param  {Object} formState Form state from store
 */
export const mapFormStateToFilters = (formState = {}) => {
  const data = {};

  Object.keys(formState).forEach((key) => {
    if (key === 'validate' || isEmpty(formState[key].value)) {
      return false;
    }
    data[key] = formState[key].value;
    return formState[key].value;
  });

  return data;
};

export const parseStringNumberToFloat = (value) => {
  // count commas and dots in string
  const countCommas = value.split(',').length - 1;
  const countDots = value.split('.').length - 1;

  // throw error if there is more than one dot/comma in string or if there is any combination of both
  if ((countCommas && countDots) || countCommas > 1 || countDots > 1) {
    throw new Error(
      'Invalid arguments in parseStringNumberToFloat.',
    );
  }
  return parseFloat(value.replace(',', '.'));
};

export const parseToFormattedNumberString = (value, isCurrency = false) => {
  let floatValue = value;
  if (typeof floatValue === 'string') {
    floatValue = parseStringNumberToFloat(floatValue);
  }

  let formattedValue = floatValue;
  if (isCurrency) {
    formattedValue = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(floatValue);
  } else {
    formattedValue = new Intl.NumberFormat().format(floatValue);
  }

  // workaround for Intl.NumberFormat - if the number is between 1000 and 9999 (positive and negative) add space after the first number
  if (floatValue >= 1000 && floatValue < 10000) {
    return addSpaceToStringAtIndex(formattedValue, 1);
  } else if (floatValue <= -1000 && floatValue > -10000) {
    return addSpaceToStringAtIndex(formattedValue, 2);
  }
  return formattedValue;
};

export const addSpaceToStringAtIndex = (string, index) => {
  return `${string.slice(0, index)} ${string.slice(index)}`;
};

/**
 * Function that used pure javascript to redirect inside the app not including farm slugname
 * @function
 * @memberof Utils
 * @param  {String} [pathname] path to redirect
 */
export const redirectTo = (pathname) => {
  const prefix = window.location.host.startsWith('http') ? '' : 'http://';
  window.location.replace(`${prefix}${window.location.host}/${pathname}`);
};

/**
 * Function that opens e-mail client with default content
 * @function
 * @memberof Utils
 * @param  {String} to recipient e-mail address
 * @param  {String} [subject] title of message
 * @param  {String} [content] content of message
 */
export const openMailClient = (to, subject = '', content = '') => {
  window.location.href = `mailto:${to}?subject=${subject}&body=${encodeURIComponent(content)}`;
};

/**
 * Function that searches cache (react-query) by given querry key and id
 * Returns found data
 * @function
 * @memberof Utils
 * @param  {Object} queryClient recipient e-mail address
 * @param  {String} key querry key
 * @param  {String} id id of the element that we want to get
 * @return {Object}
 */
 export const findDataInCacheById = (queryClient, key, id) => {
  if (!queryClient || !key || !id) {
    throw new Error('Missing arguments in findDataInCacheById.');
  }
  const keyQueries = queryClient?.getQueryCache().getAll().filter(query => query.queryHash.includes(key));
  const findMatchingData = keyQueries.map(query => (
    (Array.isArray(query?.state?.data) ? query.state.data[0] : []).find(o => String(o.id) === String(id))
  ));
  const filteredData = (compact(uniqBy(findMatchingData, (e) => {
    return e?.id;
  })));

  return Array.isArray(filteredData) ? filteredData[0] : null;
};
