import { useEffect, useState, useRef } from 'react';
import { sortBy, isEmpty } from 'lodash';
import SimpleBar from 'simplebar-react';

import { LogoHmNotextIcon } from 'old/assets/svg';
import FlexColumn from '@old/components/common/FlexColumn';
import BoxShadow from '@old/components/common/BoxShadow';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import FarmListItem from '@old/components/view/listItem/Farm';
import SearchInput from '@old/components/custom/SearchInput';
import Icon from '@old/components/icon';
import Model from '@old/model';
import t from 'resources/translations';
import { getErrorMessage, notify } from 'old/utils';
import Spinner from '@old/components/common/Spinner';
import RowAligner from '@old/components/common/RowAligner';
import { useHistory } from 'react-router';

const SelectFarm = ({ availableFarms }) => {
  const [mineFarms, setMineFarms] = useState([]);
  const [loading, setLoading] = useState(null);
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [openAllFarms, setOpenAllFarms] = useState(false);
  const [allFarms, setAllFarms] = useState(availableFarms || []);
  const scrollRef = useRef();

  useEffect(() => {
    if (mineFarms.length === 0) {
      setOpenAllFarms(true);
    } else {
      setOpenAllFarms(false);
    }
  }, [mineFarms]);

  useEffect(() => {
    if (isEmpty(availableFarms)) {
      const fetchAllFarms = async () => {
        try {
          const [farms] = await Model.Farms.fetchAll({ per_page: 9999 });
          setAllFarms(farms);
        } catch (e) {
          notify(getErrorMessage(e), { type: 'error' });
        }
      };
      fetchAllFarms();
    }
  }, [openAllFarms, availableFarms]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [fetchedMineFarms] = await Model.Farms.fetchMineFarms({ per_page: 9999 });
        return setMineFarms(fetchedMineFarms);
      } catch (e) {
        if (e.response.status === 401) {
          // handling case: expired token / Signature verification raised
          return history.push('/logout');
        }
        return notify(getErrorMessage(e), { type: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeList = () => {
    setOpenAllFarms(!openAllFarms);
    setQuery('');
    if (scrollRef) {
      scrollRef.current.contentWrapperEl.scrollTop = 0;
    }
  };

  const clearQuery = () => {
    setQuery('');
  };

  const onSearch = value => {
    setOpenAllFarms(true);
    setQuery(value.toLowerCase());
  };

  const filterByQuery = farm => farm.getName().toLowerCase().includes(query) || query === '';

  const myFarmsIds = mineFarms.map(mineFarm => mineFarm.id);

  const farms = sortBy((openAllFarms ? allFarms : mineFarms).filter(filterByQuery), item => item.name);

  return (
    <BoxShadow className="text-left page-farm-select">
      <div className="login-banner text-white relative">
        <LogoHmNotextIcon width={120} height={120} />
        <div className="absolute bottom-2 right-2">
          <ButtonSimple onClick={() => history.push('/logout')}>
            <RowAligner separated="small">
              <span>{t('menu.logout')}</span>
              <Icon.Logout />
            </RowAligner>
          </ButtonSimple>
        </div>
      </div>

      <FlexColumn padded="large">
        <div>
          <h1 className="text-headline">{t('selectFarm.selectStable')}</h1>
          <div className="fade text-sm">
            {openAllFarms ? t('selectFarm.additionToNewStable') : t('selectFarm.farmSignIn')}
          </div>
        </div>

        <div>
          <div className="flex justify-between">
            <div className="text-teal">{t('selectFarm.searchStable')}</div>
            <ButtonSimple onClick={changeList}>
              <div className="text-link">{openAllFarms ? t('selectFarm.showMine') : t('selectFarm.showAll')}</div>
            </ButtonSimple>
          </div>
          <SearchInput
            name="selectFarmSearch"
            value={query}
            onChange={onSearch}
            clear={clearQuery}
            placeholder={t('placeholders.searchByName')}
          />
        </div>

        {loading && <Spinner />}

        {!loading && (
          <SimpleBar style={{ maxHeight: 'calc(100vh - 450px)' }} ref={scrollRef}>
            <div className="thin-border rounded">
              {farms.map(farm => (
                <FarmListItem key={farm.id} farm={farm} isMine={myFarmsIds.includes(farm.id)} />
              ))}
            </div>
          </SimpleBar>
        )}
      </FlexColumn>
    </BoxShadow>
  );
};

SelectFarm.defaultProps = {
  availableFarms: [],
};

export default SelectFarm;
