/* eslint-disable */
import React from 'react';

const UserCircular = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 50 50"
    >
      <path d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0z M25,12.1c1,0,1.9,0.9,1.9,1.9
        S26,15.9,25,15.9S23.1,15,23.1,14S24,12.1,25,12.1z M30.4,25c-0.2,1.4-2.3,1.5-2.2,0c0.2-1.6,0-3.1-0.7-4.6v5.2
        c0,3.8,0,7.5,0.2,11.3c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3C25,34,24.9,31,24.9,28.1h-0.4c0,3,0,5.9-0.1,8.8
        c0,0.6-0.6,1.2-1.2,1.2c-0.7,0-1.3-0.5-1.3-1.2C22,33.1,22,29.4,22,25.6c0,0,0,0,0-0.1c0,0,0-0.1,0-0.2v-4.7
        c-0.5,1.4-0.7,2.9-0.5,4.3c0.2,1.5-2,1.4-2.2,0c-0.3-3.3,0.8-7.7,4.5-8.1c0.5-0.1,1.1-0.1,1.7,0C29.5,17.1,30.8,21.6,30.4,25z"
      />
    </svg>
  );
};

export default UserCircular;
/* eslint-enable */
