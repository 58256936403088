import { Box, Flex, Image, Stack, Text } from 'components/elements';
import theme from 'config/theme';
import HorseFatigue from 'components/combinations/HorseFatigue';
import t from 'resources/translations';
import styled from 'styled-components';
import { TOption } from 'resources/types/commonTypes';
import { TOptionGroups } from 'resources/types/memberGroupsTypes';
import React from 'react';

const styleImage = (isSelected: boolean) => ({
  borderWidth: isSelected ? 2 : 0,
  borderColor: 'teal-light',
  borderStyle: 'solid',
  padding: 2,
  squareSize: isSelected ? 36 : 30,
  borderRadius: 9999,
});

const styleImageRidingGroups = (isSelected: boolean) => ({
  bgColor: 'grey-light',
  alignItems: 'center',
  justifyContent: 'center',
  color: isSelected ? 'teal-light' : 'grey-dark',
});

type TDefaultOption = {
  onClick?: () => void;
  isSelected: boolean;
  isDisabled: boolean;
  data: TOption | TOptionGroups;
  innerProps?: any;
  customDescription?: string;
};

type TStyleOption = {
  isDisabled: boolean;
  isSelected: boolean;
};

const Option = styled('div')<TStyleOption>(props => {
  return {
    opacity: props.isDisabled ? 0.4 : 1,
    width: '100%',
    borderBottom: `1px solid ${theme.colors['grey-light']}`,
    display: 'flex',
    minHeight: 50,
    alignItems: 'center',
    color: props.isSelected ? theme.colors['teal-dark'] : theme.colors['teal-light'],
    cursor: props.isDisabled ? 'default' : 'pointer',
    '&:hover': {
      color: theme.colors['teal-dark'],
    },
  };
});

const DefaultOption = ({ onClick, isSelected, isDisabled, data, innerProps, customDescription }: TDefaultOption) => {
  return (
    <Option onClick={onClick} isDisabled={isDisabled} isSelected={isSelected} {...innerProps}>
      <Stack width="100%" spacing="xs" alignItems="center" isHorizontal>
        <Stack spacing="base" alignItems="center" width="100%" isHorizontal>
          {data.type && (
            <Flex alignItems="center" justifyContent="center" squareSize={36}>
              {data.type === 'riding_group' ? (
                <Flex {...styleImage(isSelected)} {...styleImageRidingGroups(isSelected)}>
                  G
                </Flex>
              ) : (
                <Image title={data.label} alt={data.label} src={data.data.image} {...styleImage(isSelected)} />
              )}
            </Flex>
          )}
          <Box>
            <Text wordBreak="break-word">{data.label}</Text>
          </Box>
        </Stack>
        <>
          {data.type === 'horse' && <HorseFatigue fatigue={data.data.fatigue} />}
          {(data.type === 'place' || data.type === 'eventType') && (
            <Text color="grey" textTransform="uppercase" whiteSpace="nowrap">
              {data.data.slug}
            </Text>
          )}
          {data.type === 'member' && (
            <>
              {data.data.isDeleted && (
                <Text color="orange" whiteSpace="nowrap">
                  {t('model.delete.users')}
                </Text>
              )}
              <Text color="grey" whiteSpace="nowrap">
                {/* @ts-ignore */}
                {t(`skills.${data.data.skill}`)}
              </Text>
            </>
          )}
          {customDescription && (
            <Text color="grey" whiteSpace="nowrap">
              {customDescription}
            </Text>
          )}
        </>
      </Stack>
    </Option>
  );
};

export default React.memo(DefaultOption);
