import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import t from 'resources/translations';
import { formatDuration } from 'old/utils';

const PieChartLegend = ({ chartData, duration }) => {
  return chartData.map((item, index) => (
    <div className="text-center" key={index}>
      <span className="font-bold text-md mr-1" key={index}>{item.value}</span>
      <span className="mr-1">{t(`pieChartLegend.${item.name}`)}</span>
      {`(${formatDuration(moment.duration(duration[item.name], 'minutes'))})`}
    </div>
  ));
};

PieChartLegend.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOf([ // if you want to add something here, add also to locales in the 'legend' key
      'worked',
      'cancelled',
    ]).isRequired,
    color: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  duration: PropTypes.shape({
    worked: PropTypes.number,
    cancelled: PropTypes.number,
  }).isRequired,
};

export default PieChartLegend;
