import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  setNotifications,
  toggleNotificationsWidget,
  openAllNotifications,
  subscribeNotifications,
  unsubscribeNotifications,
  fetchNotifications,
} from 'store/actions';
import t from 'resources/translations';
import Link from '@old/components/common/Link';
import NotificationListItem from '@old/components/view/listItem/Notification';
import { useOutsideClick, usePrevious } from '@old/hooks';
import BellIcon from '@old/assets/lottieIcons/bell.json';
import LottieIcon from '@old/components/old/LottieIcon';
import Center from '@old/components/common/Center';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import FlexColumn from '@old/components/common/FlexColumn';
import RowAligner from '@old/components/common/RowAligner';
import Message from '@old/components/common/Message';
import store from 'store';

const NotificationsWidget = ({
  subscribeNotifications: subscribe,
  unsubscribeNotifications: unsubscribe,
  openAllNotifications: openAll,
  toggleNotificationsWidget: toggleNotify,
  setNotifications: setNotify,
  notifications,
  isMobile,
  isOpen,
  ...actions
}) => {
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => toggleNotify(false));
  const location = useLocation();

  useEffect(() => {
    toggleNotify(false);
  }, [location.pathname, isMobile, toggleNotify]);

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    };
  }, [subscribe, unsubscribe]);

  useEffect(() => {
    const refreshNotifications = async () => {
      await setNotify({ per_page: 110 });
    };

    refreshNotifications();
  }, [setNotify]);

  const handleOpenAll = async () => {
    await openAll();
    const {
      fetchingData: { notificationsFetchParams },
    } = store.getState();
    actions.fetchNotifications(notificationsFetchParams);
    await setNotify({ per_page: 110 });
  };

  return (
    <Center className="select-none text-left" ref={wrapperRef}>
      <NotificationsWidgetIcon
        unreadNotifications={notifications.filter(notification => !notification.openedAt).length}
        toggleNotify={toggleNotify}
        isOpen={isOpen}
      />
      <div
        style={{ width: isMobile ? '100%' : 420 }}
        className={cls(
          'notifications-widget border-none rounded-none md:rounded md:border h-full md:h-auto fixed md:absolute',
          { open: isOpen }
        )}
      >
        {!isMobile && <div className="arrow-up" />}
        <FlexColumn padded>
          <RowAligner separated="small" className="text-sm text-grey-dark justify-between">
            <div className="font-bold flex-1">{t('notificationsWidget.notifications')}</div>
            {!!notifications.length && (
              <ButtonSimple onClick={handleOpenAll}>
                <div>{t('notificationsWidget.markAsRead')}</div>
              </ButtonSimple>
            )}
            <ButtonSimple onClick={() => toggleNotify(false)}>
              <Icon.Cancel small className="fill-teal" />
            </ButtonSimple>
          </RowAligner>
          {!notifications.length && <Message info>{t('notificationsList.empty')}</Message>}
        </FlexColumn>

        <div
          className="notifications-widget-list relative overflow-y-auto border-t border-b border-grey-light"
          style={{ maxHeight: isMobile ? 'calc(100% - 130px)' : 450 }}
        >
          {notifications.map(notification => (
            <NotificationListItem key={notification.id + isOpen} notification={notification} basic />
          ))}
        </div>
        {!!notifications.length && (
          <Center className="py-1">
            <Link className="text-link" to="/notifications">
              {t('notificationsWidget.showAll')}
            </Link>
          </Center>
        )}
      </div>
    </Center>
  );
};

const mapStateToProps = ({ notifications: { data, widgetIsOpen }, app: { isMobile } }) => ({
  notifications: data,
  isOpen: widgetIsOpen,
  isMobile,
});

export default connect(mapStateToProps, {
  setNotifications,
  toggleNotificationsWidget,
  subscribeNotifications,
  unsubscribeNotifications,
  openAllNotifications,
  fetchNotifications,
})(NotificationsWidget);

const NotificationsWidgetIcon = ({ unreadNotifications, toggleNotify, isOpen }) => {
  const iconRef = useRef();
  const prevUnreadNotifications = usePrevious(unreadNotifications);
  let unreadLabel = unreadNotifications.toString();

  useEffect(() => {
    if (iconRef.current && unreadNotifications > prevUnreadNotifications) {
      iconRef.current.triggerAnimations();
    }
  });

  if (parseInt(unreadNotifications, 10) > 99) {
    unreadLabel = '99+';
  }

  const toggleMenu = () => {
    if (!isOpen) {
      iconRef.current.triggerAnimations();
    }
    toggleNotify(!isOpen);
  };

  const fontSize = unreadLabel.length === 1 ? 12 : 10;

  return (
    <Center className="cursor-pointer select-none">
      <ButtonSimple onClick={toggleMenu}>
        <div className="relative">
          {unreadLabel !== '0' && (
            <div className="notifications-indicator absolute bg-red rounded-full z-20" style={{ fontSize }}>
              <span>{unreadLabel}</span>
            </div>
          )}
          <LottieIcon ref={iconRef} iconData={BellIcon} />
        </div>
      </ButtonSimple>
    </Center>
  );
};

NotificationsWidgetIcon.defaultProps = {
  unreadNotifications: 0,
  isOpen: false,
};

NotificationsWidgetIcon.propTypes = {
  unreadNotifications: PropTypes.number,
  toggleNotify: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};
