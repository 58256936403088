/* eslint-disable max-len */
import React from 'react';

const Phone = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M15.9 12.7l-.08-.241a2.255 2.255 0 0 0-1.375-1.3l-2.1-.573a2.065 2.065 0 0 0-1.8.469l-.758.764a8.07 8.07 0 0 1-5.68-5.68l.761-.761a2.055 2.055 0 0 0 .469-1.8l-.573-2.1A2.247 2.247 0 0 0 3.463.1L3.222.015a1.907 1.907 0 0 0-1.777.419L.311 1.578a2.1 2.1 0 0 0-.335.781 13.431 13.431 0 0 0 13.553 13.583 2.125 2.125 0 0 0 .818-.329l1.137-1.137A1.916 1.916 0 0 0 15.9 12.7z" />
    </svg>
  );
};

export default Phone;
/* eslint-enable max-len */
