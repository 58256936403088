import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';
import { isLengthBetween } from 'old/utils';
import t from 'resources/translations';

const PasswordField = ({ onChangeField, placeholder, label, validation, ...props }) => {
  const { name, formName, required } = props;

  const onChange = async (password) => {
    onChangeField({ formName, fieldName: name, fieldState: password });
  };

  const validationFuncs = [
    ...validation,
    {
      condition: value => isLengthBetween({ value, min: 6, max: 128 }),
      errorHint: t('error.fieldMustHaveLengthBetween', { min: 6, max: 128 }),
    },
  ];

  return (
    <FormField validation={validationFuncs} {...props}>
      <Input.Password
        name={name}
        label={label}
        value={props.value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
      />
    </FormField>
  );
};

PasswordField.defaultProps = {
  value: '',
  placeholder: '',
  validation: [],
  required: false,
  label: '',
};

PasswordField.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.func.isRequired,
      errorHint: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name] ? form[props.formName][props.name].value : '';
  return {
    value: inputValue,
  };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(PasswordField);
