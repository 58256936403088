import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changeFieldState } from 'store/actions';
import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';

const AsyncMultiSelectField = ({
  onChangeField,
  customOptionComponents,
  placeholder,
  loadOptions,
  options,
  label,
  isSearchable,
  ...props
}) => {
  const { name, formName, required, formState } = props;

  const onChange = async (selectedOptions) => {
    const value = (selectedOptions || []).map(option => ({ value: option.value, label: option.label }));
    onChangeField({ formName, fieldName: name, fieldState: value });
  };

  const inputOptions = {
    ...options,
    isOptionDisabled: options.isOptionDisabled ? option => options.isOptionDisabled(option, formState) : () => {},
  };

  return (
    <FormField {...props}>
      <Input.AsyncMultiSelect
        name={name}
        value={props.value}
        onChange={onChange}
        placeholder={placeholder}
        loadOptions={loadOptions}
        customOptionComponents={customOptionComponents}
        label={label}
        required={required}
        isSearchable={isSearchable}
        {...inputOptions}
      />
    </FormField>
  );
};

AsyncMultiSelectField.defaultProps = {
  value: [],
  placeholder: '',
  customOptionComponents: null,
  options: {},
  required: false,
  label: '',
  customFilter: () => true,
  isSearchable: true,
};

AsyncMultiSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  isSearchable: PropTypes.bool,
  onChangeField: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  loadOptions: PropTypes.func.isRequired,
  customFilter: PropTypes.func,
  options: PropTypes.shape({
    customFilter: PropTypes.func,
    isOptionDisabled: PropTypes.func,
  }), // https://react-select.com/async
  required: PropTypes.bool,
  label: PropTypes.string,
  formState: PropTypes.shape({}).isRequired, // global Form state
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue, formState: form[props.formName] });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(AsyncMultiSelectField);
