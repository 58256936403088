import { withRouter } from 'react-router';
import cls from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import Sticky from 'react-sticky-el';

import { setDay, setWeek, setMonth, resetCalendarState } from 'store/actions';
import { getCalendarKey } from 'old/utils';
import t from 'resources/translations';
import Icon from '@old/components/icon';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import RowAligner from '@old/components/common/RowAligner';
import FlexColumn from '@old/components/common/FlexColumn';
import Button from '@old/components/guide/Button';
import GroupButton from '@old/components/guide/GroupButton';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Center from '@old/components/common/Center';
import WeekSelector from '@old/components/custom/WeekSelector';
import config from '@old/config';
import { useOldLoggedMember } from 'utils/storeUtils';

const DateSwitch = ({
  isMobile,
  isTablet,
  mode,
  setMode,
  onlyDefaultMode,
  date,
  setDate,
  resetCalendar,
  calendarKey,
  weekStart,
  setWeekCalendar,
}) => {
  const memberIsClient = useOldLoggedMember().isClient();
  const formatDate = {
    month: () => date.format('MMMM YYYY'),
    week: () => `${date.format(config.dateFormat)} - ${moment(date).endOf('week').format(config.dateFormat)}`,
    day: () => date.format('DD.MM.YYYY, dddd'),
  };

  const dateFormat = 'YYYY-MM-DD';

  const dateRange = {
    start: weekStart.format(dateFormat),
    end: moment(weekStart).add(6, 'day').format(dateFormat),
  };

  const addWeek = () => {
    setWeekCalendar(moment(weekStart).add(1, 'week'), calendarKey);
  };

  const subtractWeek = () => {
    setWeekCalendar(moment(weekStart).subtract(1, 'week'), calendarKey);
  };

  if (isMobile || isTablet) {
    return (
      <div className="calendar-header">
        <FlexColumn>
          {!onlyDefaultMode && !memberIsClient && (
            <div className="float-right mr-4">
              <GroupButton>
                <Button className={cls({ outline: mode !== 'week' })} onClick={() => setMode('week')}>
                  {mode === 'week' ? t('dateSwitch.week').toUpperCase() : t('dateSwitch.week').charAt(0).toUpperCase()}
                </Button>
                <Button className={cls({ outline: mode !== 'month' })} onClick={() => setMode('month')}>
                  {mode === 'month'
                    ? t('dateSwitch.month').toUpperCase()
                    : t('dateSwitch.month').charAt(0).toUpperCase()}
                </Button>
              </GroupButton>
            </div>
          )}
          <Sticky
            topOffset={-70}
            className="sticky-calendar-header mobile"
            // https://github.com/gm0t/react-sticky-el/issues/41
            isIOSFixEnabled={false}
            stickyStyle={{ top: '64px' }}
          >
            <Center>
              {mode !== 'week' ? (
                <RowAligner className="justify-center">
                  <ButtonSimple onClick={resetCalendar}>
                    <Icon.CalendarShadow className="fill-teal" />
                  </ButtonSimple>
                  <ButtonSimple onClick={() => setDate(moment(date).subtract(1, mode), calendarKey)}>
                    <Icon.ArrowLeft className="fill-teal" />
                  </ButtonSimple>
                  <Center>
                    <div style={{ width: '160px' }}>{formatDate[mode]()}</div>
                  </Center>
                  <ButtonSimple onClick={() => setDate(moment(date).add(1, mode), calendarKey)}>
                    <Icon.ArrowRight className="fill-teal" />
                  </ButtonSimple>
                </RowAligner>
              ) : (
                <Center>
                  <RowAligner separated="small">
                    <ButtonSimple onClick={resetCalendar}>
                      <Icon.CalendarShadow className="fill-teal" />
                    </ButtonSimple>
                    <WeekSelector weekDate={dateRange} addWeek={addWeek} subtractWeek={subtractWeek} />
                  </RowAligner>
                </Center>
              )}
            </Center>
          </Sticky>
        </FlexColumn>
      </div>
    );
  }

  return (
    <Sticky
      topOffset={-70}
      stickyClassName="sticky-calendar-header"
      // https://github.com/gm0t/react-sticky-el/issues/41
      isIOSFixEnabled={false}
    >
      <div className="calendar-header">
        <RowAligner>
          {mode !== 'week' ? (
            <>
              <ButtonOutline onClick={resetCalendar}>
                <Icon.Calendar />
                <div>{t('dateSwitch.today')}</div>
              </ButtonOutline>
              <RowAligner className="flex-1">
                <ButtonSimple onClick={() => setDate(moment(date).subtract(1, mode), calendarKey)}>
                  <Icon.ArrowLeft className="fill-teal" />
                </ButtonSimple>
                <div className="text-button" style={{ width: '200px' }}>
                  {formatDate[mode]()}
                </div>
                <ButtonSimple onClick={() => setDate(moment(date).add(1, mode), calendarKey)}>
                  <Icon.ArrowRight className="fill-teal" />
                </ButtonSimple>
              </RowAligner>
            </>
          ) : (
            <>
              <ButtonOutline onClick={resetCalendar}>
                <Icon.Calendar />
                <div>{t('dateSwitch.today')}</div>
              </ButtonOutline>
              <WeekSelector weekDate={dateRange} addWeek={addWeek} subtractWeek={subtractWeek} />
            </>
          )}
          {!onlyDefaultMode && !memberIsClient && (
            <GroupButton>
              <Button className={cls({ outline: mode !== 'week' })} onClick={() => setMode('week')}>
                {mode === 'week' ? t('dateSwitch.week').toUpperCase() : t('dateSwitch.week').charAt(0).toUpperCase()}
              </Button>
              <Button className={cls({ outline: mode !== 'month' })} onClick={() => setMode('month')}>
                {mode === 'month' ? t('dateSwitch.month').toUpperCase() : t('dateSwitch.month').charAt(0).toUpperCase()}
              </Button>
            </GroupButton>
          )}
        </RowAligner>
      </div>
    </Sticky>
  );
};

const mapStateToProps = ({ calendar }, { location, mode }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;

  return {
    date: calendarState[`${mode}Start`],
    calendarKey,
    weekStart: calendarState.weekStart,
  };
};

const mapDispatchToProps = (dispatch, { mode, location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const setters = {
    setday: setDay,
    setweek: setWeek,
    setmonth: setMonth,
  };

  return {
    setDate: date => dispatch(setters[`set${mode}`](date, calendarKey)),
    setWeekCalendar: date => dispatch(setWeek(date, calendarKey)),
    resetCalendar: () => dispatch(resetCalendarState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DateSwitch));
