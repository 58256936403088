import { useState } from 'react';

import moment from 'moment';
import { useHistory } from 'react-router';

import config from '@old/config';
import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import t from 'resources/translations';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import FlexRow from '@old/components/common/FlexRow';
import FlexColumn from '@old/components/common/FlexColumn';
import { Select } from '@old/components/input';
import Button from '@old/components/guide/Button';
import { getErrorMessage, mapFormStateToData, notify } from 'old/utils';

const RepeatEventModal = ({ event, onClose }) => {
  const [repeatEventIn, setRepeatEventIn] = useState({ value: 1, label: t('repeatEventModal.days.one', { value: 1 }) });
  const history = useHistory();
  if (!event) return null;

  const getNewEventData = () => ({
    eventName: event.name,
    eventDescription: event.description,
    startDate: moment(event.getStartDateTime(), 'DD.MM.YYYY HH:mm').add(repeatEventIn.value, 'days'),
    endDate: moment(event.getEndDateTime(), 'DD.MM.YYYY HH:mm').add(repeatEventIn.value, 'days'),
    type: {
      value: event.type.id,
      label: event.type.getName(),
      timeIntervals: event.type.timeIntervals,
    },
    places: event.getPlacesOption(),
    horses: event.getHorsesOptions(),
    participants: event.getJoinedParticipantsOptions(false),
    inviteParticipants: event.getInvitedParticipantsOptions(false),
    difficulty: config.difficulty.find(d => d.value === String(event.difficulty)),
    instructors: event.getInstructorsOptions(),
    visibility: String(event.visibility),
    special: String(event.special),
    attendees: Object.values(event.attendees),
  });

  const onSubmit = async () => {
    try {
      const values = mapFormStateToData({ formState: getNewEventData(), initValue: true });
      const addedEvent = await Model.Events.create(Model.Event.mapToSaveData(values));
      history.push(`/events/${addedEvent.id}`);
      notify(t('message.addingEventSuccess'));
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    onClose();
  };

  const options = [
    { key: 1, value: 1, label: t('repeatEventModal.days.one', { value: 1 }) },
    { key: 2, value: 2, label: t('repeatEventModal.days.few', { value: 2 }) },
    { key: 3, value: 7, label: t('repeatEventModal.days.few', { value: 7 }) },
    { key: 4, value: 30, label: t('repeatEventModal.days.few', { value: 30 }) },
  ];

  const handleChange = selectedOption => {
    setRepeatEventIn(selectedOption);
  };

  return (
    <Modal header={event.name} onClose={onClose} isOpen>
      <FlexColumn>
        <Select
          options={options}
          onChange={handleChange}
          value={repeatEventIn}
          label={t('repeatEventModal.repeatIn')}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          isClearable={false}
          required
        />
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button fluid onClick={onSubmit} async>
            {t('general.add')}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

RepeatEventModal.defaultProps = {
  event: null,
};

export default RepeatEventModal;
