import { THrProps } from 'resources/types/elementsTypes';
import { TSpacesProps } from 'resources/types/styledTypes';
import { Hr } from '../elements';

type TStyleProps = {
  color?: string;
  orientation?: 'horizontal' | 'vertical';
  variant?: string;
  size?: string;
  margin?: TSpacesProps;
};
const styleProps = ({ variant, size, orientation, color, margin }: TStyleProps) => ({
  borderStyle: variant,
  borderColor: color,
  borderImage: 'initial',
  borderWidth: 0,
  ...(orientation === 'horizontal'
    ? { width: '100%', borderBottomWidth: size, borderTopWidth: 0, my: margin }
    : { height: '100%', width: 'initial', borderLeftWidth: size, borderRightWidth: 0, mx: margin }),
});

type TDivider = THrProps & {
  color?: string;
  orientation?: 'horizontal' | 'vertical';
  variant?: string;
  size?: string;
  margin?: TSpacesProps;
};

const Divider = ({ variant, size, orientation, color, margin, ...hrProps }: TDivider) => {
  return (
    <Hr aria-orientation={orientation} {...styleProps({ variant, size, orientation, color, margin })} {...hrProps} />
  );
};

Divider.defaultProps = {
  color: 'initial',
  orientation: 'horizontal',
  variant: 'solid',
  size: 1,
  margin: 1,
};

export default Divider;
