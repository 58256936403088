/**
 * @module AsyncMultiSelectInput
 * */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';

import SelectMultiValueContainer from '@old/components/old/SelectMultiValueContainer';
import SelectMultiValueRemove from '@old/components/old/SelectMultiValueRemove';
import InputWrapper from '@old/components/common/InputWrapper';
import t from 'resources/translations';
import Tags from '@old/components/common/Tags';
import ModalSimple from '@old/components/common/ModalSimple';
import SelectOptionList from '@old/components/view/list/SelectOption';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';

/**
 * @typedef {Object} SelectOption
 * @property {String|Number} value
 * @property {String} label
 */

/**
 * Component displays select and allows users to tick multiple options.
 * Options are loaded asynchronously by loadOptions passed to component.
 * Component renders AsyncMultiSelectInputDesktop or AsyncMultiSelectInputMobile based on current screen width.
 *
 * @param  {SelectOption[]} [props.value]
 * @param  {Function} props.onChange function used while set value
 * @param  {String} [props.placeholder]
 * @param  {Function} props.loadOptions Function to fetch options from model
 * @param  {Function} [props.customFilter] Additional filter
 * @param  {*} [props.customOptionComponents] Option with custom: label, slug, color
 * @param  {Boolean} props.isMobile
 * @param  {Function} [props.isOptionDisabled] information about what value might be disabled
 * @param  {Boolean} props.isTablet
 * @param  {String} [props.label]
 * @param  {String} [props.name]
 * @param  {Boolean} [props.required]
 * @param  {Boolean} [props.isSearchable]
 */
const AsyncMultiSelectInput = ({
  value,
  onChange,
  placeholder,
  loadOptions,
  customFilter,
  customOptionComponents,
  isMobile,
  isOptionDisabled,
  isTablet,
  label,
  required,
  isSearchable,
  name,
}) => {
  if (isMobile || isTablet) {
    return (
      <AsyncMultiSelectInputMobile
        name={name}
        key={value.length}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        loadOptions={loadOptions}
        customFilter={customFilter}
        customOptionComponents={customOptionComponents}
        isOptionDisabled={isOptionDisabled}
        isSearchable={isSearchable}
        label={label}
        required={required}
      />
    );
  }
  return (
    <AsyncMultiSelectInputDesktop
      value={value}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      loadOptions={loadOptions}
      customFilter={customFilter}
      customOptionComponents={customOptionComponents}
      isOptionDisabled={isOptionDisabled}
      isSearchable={isSearchable}
      label={label}
      required={required}
    />
  );
};

AsyncMultiSelectInput.defaultProps = {
  value: '',
  placeholder: '',
  label: '',
  required: false,
  customOptionComponents: null,
  customFilter: () => true,
  isOptionDisabled: null,
  isSearchable: true,
  name: '',
};

AsyncMultiSelectInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  isSearchable: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  loadOptions: PropTypes.func.isRequired,
  customFilter: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isOptionDisabled: PropTypes.func,
  name: PropTypes.string,
};

const mapStateToProps = ({ app: { isMobile, isTablet } }) => ({ isMobile, isTablet });

export default connect(mapStateToProps)(AsyncMultiSelectInput);
/**
 * @module AsyncMultiSelectInputDesktop
 */

/**
 * @typedef {Object} SelectOption
 * @property {String|Number} value
 * @property {String} label
 */

/**
 * Desktop version of asynchronous multi select input.
 * Usually you won't need to use this component directly and you should use AsyncMultiSelectInput.
 *
 * @param  {SelectOption[]} [props.value]
 * @param  {Function} props.onChange function used after changes
 * @param  {String} [props.placeholder]
 * @param  {Function} props.loadOptions Function to fetch options from model
 * @param  {Function} [props.customFilter] Additional filter
 * @param  {Object} [props.customOptions] custom options from https://react-select.com/props
 * @param  {*} [props.customOptionComponents] Option with custom: label, slug, color
 * @param  {Function} [props.isOptionDisabled] information about what value might be disabled
 * @param  {Boolean} [props.isSearchable]
 */
const AsyncMultiSelectInputDesktop = ({
  value,
  onChange,
  placeholder,
  loadOptions,
  customFilter,
  customOptions,
  customOptionComponents,
  isOptionDisabled,
  isSearchable,
  label,
  required,
  name,
}) => {
  const OptionComponent = customOptionComponents ? { Option: customOptionComponents } : {};
  const MultiValueContainer = { MultiValueContainer: SelectMultiValueContainer };
  const MultiValueRemove = { MultiValueRemove: SelectMultiValueRemove };

  return (
    <InputWrapper label={label} required={required} name={name}>
      <AsyncPaginate
        className="select"
        classNamePrefix="select"
        components={{
          ...OptionComponent,
          ...MultiValueContainer,
          ...MultiValueRemove,
        }}
        placeholder={placeholder}
        loadOptions={(keyword, _, { page }) => loadOptions(keyword, page)}
        filterOption={({ data }) => customFilter(data)}
        onChange={onChange}
        noOptionsMessage={() => <div>{t('message.noResults')}</div>}
        loadingMessage={() => <div>{t('general.loading')}</div>}
        value={value}
        closeMenuOnSelect={false}
        debounceTimeout={500}
        menuShouldScrollIntoView
        defaultOptions
        isOptionDisabled={isOptionDisabled}
        cacheOptions
        isMulti
        {...customOptions}
        additional={{ page: 1 }}
        isSearchable={isSearchable}
      />
    </InputWrapper>
  );
};

AsyncMultiSelectInputDesktop.defaultProps = {
  value: '',
  customOptionComponents: null,
  customOptions: {},
  placeholder: '',
  customFilter: () => true,
  isOptionDisabled: null,
  isSearchable: true,
  required: false,
  label: '',
  name: '',
};

AsyncMultiSelectInputDesktop.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  customOptions: PropTypes.shape({}), // custom options from https://react-select.com/props
  customFilter: PropTypes.func,
  loadOptions: PropTypes.func.isRequired,
  isOptionDisabled: PropTypes.func,
  isSearchable: PropTypes.bool,
  name: PropTypes.string,
};

/**
 * @module AsyncMultiSelectInputMobile
 */

/**
 * @typedef {Object} SelectOption
 * @property {String|Number} value
 * @property {String} label
 */

/**
 * Mobile version of asynchronous multi select input. Usually you won't need to use this component directly and you should use AsyncMultiSelectInput.
 *
 * @param  {SelectOption[]} [props.value]
 * @param  {Function} props.onChange function used after changes
 * @param  {String} [props.placeholder]
 * @param  {Function} props.loadOptions Function to fetch options from model
 * @param  {*} [props.customOptionComponents] Option with custom: label, slug, color
 * @param  {Function} [props.customFilter] Additional filter
 * @param  {Function} [props.isOptionDisabled] information about what value might be disabled
 * @param  {Boolean} [props.isSearchable]
 */
const AsyncMultiSelectInputMobile = ({
  value,
  onChange,
  placeholder,
  loadOptions,
  customOptionComponents,
  customFilter,
  isOptionDisabled,
  isSearchable,
  name,
  label,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(value);

  const onSubmit = () => {
    setIsOpen(false);
    onChange(selectedOptions);
  };

  const onDelete = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(selectedOptions.filter(selectedOption => selectedOption.value !== item.key));
  };

  const isEmptyValue = !value.length;
  const onValueRemove = () => {
    onChange([]);
  };

  const iconValuesRemove = (
    <div className="pr-2">
      <ButtonSimple onClick={onValueRemove}>
        <Icon.Cancel className="fill-teal" small />
      </ButtonSimple>
    </div>
  );
  return (
    <>
      <InputWrapper label={label} required={required} icon={isEmptyValue ? null : iconValuesRemove}>
        {isEmptyValue
          ? (
            <input readOnly placeholder={placeholder} onClick={() => setIsOpen(true)} />
          )
          : (
            <div className="input-content-multivalue" onClick={() => setIsOpen(true)}>
              <Tags items={value.map(val => ({ key: val.value, label: val.label }))} onDelete={onDelete} />
            </div>
          )}
      </InputWrapper>
      <ModalSimple close={() => setIsOpen(false)} open={isOpen}>
        <ModalSimple.Header>{placeholder}</ModalSimple.Header>
        <ModalSimple.Body>
          <SelectOptionList
            name={`${name}Search`}
            loadOptions={loadOptions}
            customFilter={customFilter}
            optionComponent={customOptionComponents}
            onChange={setSelectedOptions}
            value={selectedOptions}
            isOptionDisabled={isOptionDisabled}
            isSearchable={isSearchable}
            isMulti
          />
        </ModalSimple.Body>
        <ModalSimple.Action close={() => setIsOpen(false)} onSubmit={onSubmit} />
      </ModalSimple>
    </>
  );
};

AsyncMultiSelectInputMobile.defaultProps = {
  value: '',
  customOptionComponents: null,
  placeholder: '',
  customFilter: () => true,
  isOptionDisabled: null,
  isSearchable: true,
  label: '',
  required: false,
};

AsyncMultiSelectInputMobile.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  customFilter: PropTypes.func,
  loadOptions: PropTypes.func.isRequired,
  isOptionDisabled: PropTypes.func,
  isSearchable: PropTypes.bool,
  name: PropTypes.string.isRequired,
};
