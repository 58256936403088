import _ from 'lodash';
import config from 'old/config';
import { getObjectWithMinValueByKey } from 'old/utils';
import { IImage, TImageSize, TPrivilege } from 'resources/types/commonTypes';
import { IPlaceRes } from 'resources/types/placesTypes';

class Place {
  id: number;
  name: string;
  slug: string | null;
  primaryPictureId: number | null;
  deleted: boolean;
  privileges: TPrivilege[];
  pictures: IImage[];
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IPlaceRes) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.primaryPictureId = data.primary_picture_id;
    this.deleted = data.deleted;
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
    this.privileges = data.meta.privileges || [];
    this.pictures = data.pictures || [];
  }

  getName(limit: number = 0) {
    return limit ? _.truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getColor() {
    return config.accentColorsPlaces[this.id % config.accentColorsPlaces.length];
  }

  getAvatar(size: TImageSize) {
    if (!this.pictures || this.pictures.length === 0) {
      return '/img/place-avatar-placeholder.png';
    }

    let mainPicture = null;
    if (!this.primaryPictureId) {
      mainPicture = getObjectWithMinValueByKey(this.pictures, 'id') as IImage;
    } else {
      mainPicture = this.pictures.find(picture => picture.id === this.primaryPictureId) as IImage;
    }

    return mainPicture && mainPicture.url
      ? mainPicture.url[size] || mainPicture.url.medium
      : '/img/place-avatar-placeholder.png';
  }

  getSlugInfo() {
    const place = {
      id: this.id,
      name: this.getName(),
      color: this.getColor(),
      slug: this.slug,
    };

    return { place };
  }
}

export default Place;
