import truncate from 'lodash/truncate';

import BaseModel from '@old/model/BaseModel';
import { Members, Member } from '@old/model/Member';
import t from 'resources/translations';

class MemberGroups extends BaseModel {
  constructor(options) {
    super({
      dataKey: 'riding_groups',
      modelName: 'ridingGroups',
      updateKey: 'riding_group',
      basePath: '/api/v1/farms/{farmId}/riding_groups',
      ItemClass: MemberGroup,
      ...options,
    });
  }

  // List of logged member joined groups
  fetchMineGroups = async ({ cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/mine`, { cancelToken, params });

    return [response.data.riding_groups.map(item => this.parse(item)), response.data.pagination];
  };

  fetchOptions = async () => {
    const [groups] = await this.fetchAll({
      per_page: 100,
      page: 1,
    });

    const mappedOptions = groups.map(group => ({
      key: group.id,
      value: group.getMembers().map(Members.mapToSelectOption),
      label: group.getName(),
      size: group.getSize(),
      isGroup: true,
      getExtendedLabel: (ids) => {
        const members = group.getMembers();
        return `${members.filter(member => ids.includes(member.id)).length} / ${members.length}`;
      },
    }));

    return {
      options: mappedOptions.filter(option => option.size > 0),
      hasMore: false,
    };
  };
}

class MemberGroup {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.members = data.memberships ? data.memberships.map(member => new Member(member)) : [];
    this.privileges = data.meta ? data.meta.privileges : [];
  }

  static mapToSaveData = (data) => {
    const userGroup = {
      id: data.id,
      name: data.groupName,
      description: data.groupDescription,
      membership_ids: data.members,
    };

    Object.keys(userGroup).forEach((fieldName) => {
      if (userGroup[fieldName] === undefined) delete userGroup[fieldName];
    });

    return userGroup;
  };

  update = async changes => this.model.update(this.id, changes);

  getGroupUrl() {
    return `/riding-groups/${this.id}`;
  }

  getName(limit = false) {
    return limit ? truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getSize() {
    return this.members.length;
  }

  getMembersIds() {
    return this.members.map(member => member.id);
  }

  getDescription() {
    return this.description || t('general.noDescription');
  }

  getMembers() {
    return this.members || [];
  }

  mapToOption = item => ({
    value: item.id,
    label: item.getFullName(),
    image: item.getAvatar(),
    skill: item.getSkill(),
  });

  getMembersOptions() {
    return this.getMembers().map(this.mapToOption);
  }

  getPermission(permissionKey) {
    return this.privileges.includes(permissionKey);
  }
}

export { MemberGroup, MemberGroups };
