import { TPrivilege } from 'resources/types/commonTypes';
import {
  IParticipationRes,
  TParticipationAttendance,
  TParticipationStatus,
} from '../resources/types/participationsTypes';
import Bill from './Bill';
import Horse from './Horse';
import Member from './Member';

class Participation {
  id: number;
  status: TParticipationStatus;
  attendance: TParticipationAttendance;
  optOut: boolean | null;
  privileges: TPrivilege[];
  deleted: boolean;
  member: Member;
  horse: Horse | null;
  bill: Bill | null;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IParticipationRes) {
    this.id = data.id;
    this.status = data.status;
    this.attendance = data.attendance;
    this.optOut = data.opt_out;
    this.privileges = data.meta.privileges || [];
    this.deleted = data.deleted;
    this.member = new Member(data.membership);
    this.horse = data.horse ? new Horse(data.horse) : null;
    this.bill = data.bill ? new Bill(data.bill) : null;
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
  }

  getStatus() {
    return this.status || 'none';
  }

  canI(permissionKey: TPrivilege) {
    return this.privileges.includes(permissionKey);
  }
}

export default Participation;
