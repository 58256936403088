import { QueryClient } from 'react-query';
import { TCacheKey } from 'resources/types/cacheTypes';
import { APIPagination } from 'resources/types/commonTypes';
import { IEventRes } from 'resources/types/eventsTypes';
import { IParticipationRes } from 'resources/types/participationsTypes';
import { BillService } from 'services';
import { IBillRes } from 'resources/types/billsTypes';
import _ from 'lodash';
import Bill from 'models/Bill';

interface IQueryData<T> {
  data?: T[];
  pages?: { data: T[] }[];
  pagination?: APIPagination;
}

/**
 * Replace event in events list cache
 * @param {QueryClient} qClient - useQueryClient();
 * @param {TCacheKey[]} qKeys - key that defines query name
 * @param {IEventRes} event - updated participation
 */
export const mEventInEvents = (qClient: QueryClient, qKeys: TCacheKey[], event: IEventRes) => {
  qKeys.map(qKey => {
    const queryState = qClient.getQueryData<IQueryData<IEventRes>>(qKey);
    if (queryState?.data) {
      const eventIndex = queryState.data.findIndex(e => e.id === event.id);
      if (eventIndex >= 0) {
        queryState.data[eventIndex] = event;
        return qClient.setQueryData(qKey, () => queryState);
      }
    } else {
      queryState?.pages?.forEach(page => {
        const eventIndex = page.data.findIndex(e => e.id === event.id);
        if (eventIndex >= 0) {
          page.data[eventIndex] = event;
          return qClient.setQueryData(qKey, () => queryState);
        }
      });
    }
    return {};
  });
};

/**
 * Replace participation in events list cache
 * @param {QueryClient} qClient - useQueryClient();
 * @param {TCacheKey[]} qKeys - key that defines query name
 * @param {IParticipationRes} participation - updated participation
 */
export const mParticipationToEvents = (qClient: QueryClient, qKeys: TCacheKey[], participation: IParticipationRes) => {
  function getIndexes(events: IEventRes[]) {
    const eIndex = events.findIndex(event => event.participations.find(p => p.id === participation.id));
    const pIndex = eIndex >= 0 ? events[eIndex].participations.findIndex(p => p.id === participation.id) : -1;
    return { eIndex, pIndex };
  }
  const checkIfIndexesExists = ({ eIndex, pIndex }: { eIndex: number; pIndex: number }) => eIndex >= 0 && pIndex >= 0;

  qKeys.map(qKey => {
    const queryState = qClient.getQueryData<IQueryData<IEventRes>>(qKey);
    let indexes = { eIndex: -1, pIndex: -1 };
    if (queryState?.data) {
      indexes = getIndexes(queryState.data);
      if (checkIfIndexesExists(indexes)) {
        queryState.data[indexes.eIndex].participations[indexes.pIndex] = participation;
        return qClient.setQueryData(qKey, () => queryState);
      }
    } else {
      queryState?.pages?.forEach(page => {
        indexes = getIndexes(page.data);
        if (checkIfIndexesExists(indexes)) {
          page.data[indexes.eIndex].participations[indexes.pIndex] = participation;
          return qClient.setQueryData(qKey, () => queryState);
        }
      });
    }

    return {};
  });
};

type TFetchBillInEventsIndexes = { eIndex: number; pIndex: number; page: number };

/**
 * Replace bill in events list cache
 * @param {QueryClient} qClient - useQueryClient();
 * @param {TCacheKey[]} qKeys - key that defines query name
 * @param {IBillRes | Bill} bill - updated bill
 */
export const mFetchedBillInEvents = async (qClient: QueryClient, qKeys: TCacheKey[], bill: IBillRes | Bill) => {
  function getIndexes(events: IEventRes[]) {
    let pIndex = -1;
    const eIndex = events.findIndex(event => {
      pIndex = event.participations.findIndex(p => (p?.bill?.id || 0) === bill?.id);
      return pIndex >= 0;
    });
    return { eIndex, pIndex, page: -1 };
  }

  const getIndexesFromPages = (pages: any[] | undefined) => {
    let indexes: TFetchBillInEventsIndexes = { eIndex: -1, pIndex: -1, page: -1 };
    (pages || []).forEach((page, index) => {
      if (indexes.eIndex >= 0 && indexes.pIndex >= 0) return indexes;
      indexes = getIndexes(page.data);
      indexes.page = index;
    });

    return indexes;
  };

  await Promise.all(
    qKeys.map(async qKey => {
      const queryState = qClient.getQueryData<IQueryData<IEventRes>>(qKey);
      const indexes = queryState?.data ? getIndexes(queryState.data) : getIndexesFromPages(queryState?.pages);
      if (indexes.eIndex >= 0 && indexes.pIndex >= 0) {
        const newBill = await BillService.fetch(bill.id);
        if (queryState?.data) {
          queryState.data[indexes.eIndex].participations[indexes.pIndex].bill = newBill;
        }
        if (queryState?.pages) {
          queryState.pages[indexes.page].data[indexes.eIndex].participations[indexes.pIndex].bill = newBill;
        }
        return qClient.setQueryData(qKey, () => queryState);
      }
    })
  );
};

/**
 * Replace bill in event cache
 * @param {QueryClient} qClient - useQueryClient();
 * @param {TCacheKey[]} qKeys - key that defines query name
 * @param {IBillRes} bill - updated bill
 */
export const mBillInEvents = async (qClient: QueryClient, qKeys: TCacheKey[], bill: IBillRes) => {
  function getIndexes(events: IEventRes[]) {
    let pIndex = -1;
    const eIndex = events.findIndex(event => {
      pIndex = event.participations.findIndex(p => (p?.bill?.id || 0) === bill?.id);
      return pIndex >= 0;
    });
    return { eIndex, pIndex, page: -1 };
  }

  const getIndexesFromPages = (pages: any[] | undefined) => {
    let indexes: TFetchBillInEventsIndexes = { eIndex: -1, pIndex: -1, page: -1 };
    (pages || []).forEach((page, index) => {
      if (indexes.eIndex >= 0 && indexes.pIndex >= 0) return indexes;
      indexes = getIndexes(page.data);
      indexes.page = index;
    });

    return indexes;
  };

  await Promise.all(
    qKeys.map(async qKey => {
      const queryState = qClient.getQueryData<IQueryData<IEventRes>>(qKey);
      const indexes = queryState?.data ? getIndexes(queryState.data) : getIndexesFromPages(queryState?.pages);
      if (indexes.eIndex >= 0 && indexes.pIndex >= 0) {
        if (queryState?.data) {
          queryState.data[indexes.eIndex].participations[indexes.pIndex].bill = bill;
        }
        if (queryState?.pages) {
          queryState.pages[indexes.page].data[indexes.eIndex].participations[indexes.pIndex].bill = bill;
        }
        return qClient.setQueryData(qKey, () => queryState);
      }
    })
  );
};

/**
 * Fetch bill by given id and replace it in bills cache
 * @param {QueryClient} qClient - useQueryClient();
 * @param {TCacheKey[]} qKeys - key that defines query name
 * @param {number} billId - bill id
 */
export const mFetchBillInBills = async (qClient: QueryClient, qKey: TCacheKey, billId: number) => {
  const queriesKeys = qClient
    .getQueryCache()
    .getAll()
    .filter(query => query.queryHash.includes(qKey));
  await Promise.all(
    queriesKeys.map(async query => {
      const queryState = qClient.getQueryData<IQueryData<IBillRes>>(query.queryKey);
      if (queryState?.data) {
        const billIndex = queryState.data.findIndex(e => e.id === billId);
        if (billIndex >= 0) {
          const bill = await BillService.fetch(billId);
          queryState.data[billIndex] = bill;
          return qClient.setQueryData(query.queryKey, () => queryState);
        }
      } else {
        queryState?.pages?.forEach(async page => {
          const billIndex = page.data.findIndex(e => e.id === billId);
          if (billIndex >= 0) {
            const bill = await BillService.fetch(billId);
            page.data[billIndex] = bill;
            return qClient.setQueryData(query.queryKey, () => queryState);
          }
        });
      }
      return {};
    })
  );
};

/**
 * Replace bill in bills cache
 * @param {QueryClient} qClient - useQueryClient();
 * @param {TCacheKey[]} qKeys - key that defines query name
 * @param {IBillRes} bill - bill from API
 */
export const mBillInBills = (qClient: QueryClient, qKeys: TCacheKey[], bill: IBillRes) => {
  const queriesKeys = qClient
    .getQueryCache()
    .getAll()
    .filter(query => query.queryHash.includes(qKeys[0]));

  queriesKeys.map(query => {
    const queryState = qClient.getQueryData<IQueryData<IBillRes>>(query.queryKey);
    if (queryState?.data) {
      const billIndex = queryState.data.findIndex(e => e.id === bill.id);
      if (billIndex >= 0) {
        queryState.data[billIndex] = bill;
        return qClient.setQueryData(query.queryKey, () => queryState);
      }
    } else {
      queryState?.pages?.forEach(page => {
        const billIndex = page.data.findIndex(e => e.id === bill.id);
        if (billIndex >= 0) {
          page.data[billIndex] = bill;
          return qClient.setQueryData(query.queryKey, () => queryState);
        }
      });
    }
    return {};
  });
};

/**
 * Remove event from events list in cache
 * @param {QueryClient} qClient - useQueryClient();
 * @param {TCacheKey[]} qKeys - key that defines query namey
 * @param {number} eventId - id of an event
 */
export const mRemoveEventFromEvents = async (qClient: QueryClient, qKeys: TCacheKey[], eventId: number) => {
  await Promise.all(
    qKeys.map(async qKey => {
      const queryState = qClient.getQueryData<IQueryData<IEventRes>>(qKey);
      if (queryState?.data) {
        queryState.data = _.remove(queryState.data, event => event.id !== eventId);
        return qClient.setQueryData(qKey, () => queryState);
      }
      if (queryState?.pages) {
        const pages = queryState.pages.map(page => {
          return { ...page, data: page.data.filter(event => event.id !== eventId) };
        });
        queryState.pages = pages;
        return qClient.setQueryData(qKey, () => queryState);
      }
    })
  );
};
