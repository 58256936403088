/* eslint-disable */
import React from 'react';

const PhoneSms = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 41.743 59.953"
    >
      <g transform="translate(-7157.54 -4468.761)">
        <path d="M7199.27 4500.485v-23.779a7.945 7.945 0 0 0-7.945-7.945h-20.015a7.911 7.911 0 0 0-3.053.611c-.084.035-.167.071-.25.108l-.031.014a7.956 7.956 0 0 0-2.2 1.49h-6.7a1.533 1.533 0 0 0-1.532 1.532v10.271a1.534 1.534 0 0 0 1.532 1.532h4.239v4.629h-3.878a1.534 1.534 0 0 0-1.532 1.532v10.271a1.534 1.534 0 0 0 1.532 1.532h3.865v18.487a7.944 7.944 0 0 0 7.944 7.944h20.082a7.979 7.979 0 0 0 7.959-7.972v-20.109a.867.867 0 0 0-.017-.148zm-30.279-29.628a6.254 6.254 0 0 1 2.266-.423l20.068-.022a6.3 6.3 0 0 1 6.272 5.785h-17.875v-3.681a1.534 1.534 0 0 0-1.533-1.532h-9.49c.096-.044.193-.084.292-.128zm-10.272 11.931v-10.271a.353.353 0 0 1 .354-.353h19.116a.353.353 0 0 1 .354.353v10.153a.472.472 0 0 1-.472.471h-1.571a.588.588 0 0 0-.417.173l-2.693 2.692v-2.276a.589.589 0 0 0-.589-.589h-13.725a.353.353 0 0 1-.357-.354zm.361 17.963v-10.271a.353.353 0 0 1 .353-.353h19.117a.354.354 0 0 1 .354.353v10.153a.472.472 0 0 1-.471.471h-1.575a.586.586 0 0 0-.416.173l-2.693 2.692v-2.275a.589.589 0 0 0-.589-.59h-13.726a.353.353 0 0 1-.354-.354zm38.539 20.019a6.249 6.249 0 0 1-6.281 6.261h-20.082a6.292 6.292 0 0 1-6.295-6.289V4520h32.658zm0-2.443h-32.658v-16.043h7.608v3.109a.59.59 0 0 0 .364.544.58.58 0 0 0 .225.045.591.591 0 0 0 .417-.173l3.525-3.526h1.331a1.652 1.652 0 0 0 1.65-1.65v-10.153a1.534 1.534 0 0 0-1.532-1.532h-13.589v-4.629h7.248v3.109a.589.589 0 0 0 1.005.417l3.526-3.526h1.33a1.652 1.652 0 0 0 1.65-1.649v-4.831l17.9.007z"/>
        <path d="M7370.987 4505.535h3.418a.943.943 0 0 0 .941-1.006.965.965 0 0 0-.973-.88h-3.386a.943.943 0 0 0 0 1.886z" transform="translate(-187.46 -30.776)"/>
        <path d="M7316.482 4928.889h8.124a.943.943 0 1 0 0-1.886h-8.124a.943.943 0 1 0 0 1.886z" transform="translate(-139.379 -404.237)"/>
        <path d="M7182.5 4517.95h15.167a.59.59 0 0 0 0-1.179H7182.5a.59.59 0 0 0 0 1.179z" transform="translate(-21.5 -42.352)"/>
        <path d="M7182.5 4538.885h15.167a.589.589 0 0 0 0-1.178H7182.5a.589.589 0 1 0 0 1.178z" transform="translate(-21.5 -60.82)"/>
        <path d="M7190 4560.687h-7.5a.589.589 0 1 0 0 1.179h7.5a.589.589 0 1 0 0-1.179z" transform="translate(-21.5 -81.092)"/>
        <path d="M7185.566 4670.374h15.166a.589.589 0 1 0 0-1.178h-15.166a.589.589 0 1 0 0 1.178z" transform="translate(-24.203 -176.813)"/>
        <path d="M7185.566 4691.311h15.166a.589.589 0 1 0 0-1.178h-15.166a.589.589 0 1 0 0 1.178z" transform="translate(-24.203 -195.282)"/>
        <path d="M7193.059 4713.111h-7.493a.589.589 0 0 0 0 1.179h7.493a.589.589 0 0 0 0-1.179z" transform="translate(-24.203 -215.553)"/>
    </g>
    </svg>
  );
};

export default PhoneSms;
/* eslint-enable */
