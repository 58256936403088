/* eslint-disable */
import React from 'react';

const Human = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <g transform="translate(-59.65 -1.731)">
        <g transform="translate(59.65 1.731)">
          <circle cx="13" cy="1.9" r="1.9"/>
          <path d="M13.7,4.7c-0.6-0.1-1.1-0.1-1.7,0c-3.6,0.4-4.8,4.8-4.5,8.1c0.1,1.4,2.3,1.4,2.2,0
            c-0.2-1.5,0-2.9,0.6-4.3v4.7c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,3.7,0,7.5-0.2,11.2c0,0.7,0.6,1.2,1.3,1.2c0.6,0,1.2-0.5,1.2-1.2
            c0.1-2.9,0.1-5.9,0.2-8.8c0.1,0,0.2,0,0.4,0c0,2.9,0,5.9,0.2,8.8c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2
            c-0.1-3.7-0.2-7.5-0.2-11.2c0-0.1,0-0.2,0-0.3c0-1.6-0.1-3.3,0-4.9c0.6,1.4,0.9,3,0.7,4.6c-0.1,1.4,2,1.4,2.2,0
            C18.8,9.5,17.5,5,13.7,4.7z"/>
        </g>
      </g>
    </svg>
  );
};

export default Human;
/* eslint-enable */
