/* eslint-disable max-len */
import React from 'react';

const CalendarList = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      width="100%"
      className="icon"
    >
      <path d="M13.6,1.6h-0.8V0h-1.6v1.6H4.8V0H3.2v1.6H2.4C2,1.6,1.6,1.8,1.3,2.1S0.8,2.8,0.8,3.2v11.2c0,0.4,0.2,0.8,0.5,1.1
        C1.6,15.8,2,16,2.4,16h11.2c0.9,0,1.6-0.7,1.6-1.6V3.2C15.2,2.3,14.5,1.6,13.6,1.6z M13.6,14.4H2.4V5.6h11.2V14.4z M3.7,7.2h1.7v1.2
        H3.7V7.2z M3.7,9.4h1.7v1.2H3.7V9.4z M3.7,11.6h1.7v1.2H3.7V11.6z M6.6,7.2h4.6v1.2H6.6L6.6,7.2z M6.6,9.4h4.6v1.2H6.6L6.6,9.4z
        M6.6,11.6h4.6v1.2H6.6L6.6,11.6z"
      />
    </svg>
  );
};

export default CalendarList;
/* eslint-enable max-len */
