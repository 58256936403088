import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changeFieldState } from 'store/actions';
import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';

const AsyncSelectField = ({
  onChangeField,
  customOptionComponents,
  placeholder,
  customOptions,
  loadOptions,
  label,
  isSearchable,
  ...props
}) => {
  const { name, formName, required } = props;

  const onChange = async (selectedOption) => {
    onChangeField({
      formName,
      fieldName: name,
      fieldState: selectedOption,
    });
  };
  return (
    <FormField {...props}>
      <Input.AsyncSelect
        name={name}
        customOptionComponents={customOptionComponents}
        loadOptions={loadOptions}
        placeholder={placeholder}
        label={label}
        value={props.value}
        onChange={onChange}
        required={required}
        customOptions={customOptions}
        isSearchable={isSearchable}
      />
    </FormField>
  );
};

AsyncSelectField.defaultProps = {
  value: '',
  placeholder: '',
  customOptionComponents: null,
  label: '',
  required: false,
  customOptions: {},
  isSearchable: true,
};

AsyncSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  loadOptions: PropTypes.func.isRequired,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.string,
  required: PropTypes.bool,
  customOptions: PropTypes.shape({}), // custom options from https://react-select.com/props
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(AsyncSelectField);
