/* eslint-disable max-len */
import React from 'react';

const EventType = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M14.6,13.6c0,0.1,0,0.2,0,0.2c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0h0h-2.4v0.8c-0.1,0.4-0.4,0.7-0.8,0.7s-0.7-0.3-0.8-0.7l0,0
        v-0.8H7.6c0,0,0,0,0,0c-0.4,0-0.7-0.3-0.7-0.7c0-0.1,0-0.2,0-0.2l0,0l0.7-1.7H7.6c0,0,0,0-0.1,0c-0.4,0-0.7-0.3-0.7-0.7
        c0-0.1,0-0.2,0.1-0.3l0,0l1.1-2.5C7.8,8.5,7.6,8.2,7.6,7.9c0-0.1,0-0.1,0-0.2l0,0l2.8-7.5C10.6,0.1,10.7,0,10.8,0s0.3,0.1,0.4,0.2
        l0,0l2.7,7.5c0,0.1,0,0.1,0,0.2c0,0.3-0.2,0.5-0.4,0.6l0,0l1.1,2.5c0,0.1,0,0.1,0,0.2c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0h0h0
        L14.6,13.6z M5.4,15c0.1,0.2-0.1,0.4-0.4,0.4H3.8v0.3c0,0.2-0.2,0.3-0.4,0.3S3,15.9,3,15.7v-0.3H1.8c-0.3,0-0.4-0.2-0.4-0.4l0.4-0.7
        h0c-0.3,0-0.4-0.2-0.4-0.4L2,13c-0.2,0-0.3-0.2-0.2-0.3l1.5-3.1c0-0.1,0.1-0.1,0.2-0.1s0.1,0,0.2,0.1l0,0L5,12.7
        c0.1,0.1,0,0.3-0.2,0.3l0.6,1c0.1,0.2-0.1,0.4-0.4,0.4h0L5.4,15z"
      />
    </svg>
  );
};

export default EventType;
/* eslint-enable max-len */
