/* eslint-disable max-len */
import React from 'react';

const ArrowPaginationDoubleLeft = () => {
  return (
    <svg
      viewBox="0 0 28 26"
      width="100%"
      className="icon"
    >
      <path d="M25,26L12,13L25,0L28,3.1L18.1,13l9.9,9.9L25,26z" />
      <path d="M13,26L0,13L13,0L16,3.1L6.1,13L16,23L13,26z" />
    </svg>
  );
};

export default ArrowPaginationDoubleLeft;
/* eslint-enable max-len */
