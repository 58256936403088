/**
 * @module Popover
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TippyTooltip } from 'react-tippy';
import { isMobile as isMobileDevices } from 'react-device-detect';
/**
 * Wrapper that shows popover on hover of the children
 * @param  {*} props.children
 * @param  {Node} props.content content of the tooltip
 * @param  {Boolean} [props.dark] specifies wether the theme color of tooltip should be dark or light
 */
const Popover = ({ children, content, dark }) => {
  const popperOptions = {
    modifiers: {
      preventOverflow: {
        enabled: true,
        boundariesElement: 'viewport',
      },
    },
  };

  const optionsForMobiles = isMobileDevices ? {
    stickyDuration: 0,
    sticky: true,
  } : {};

  return (
    <TippyTooltip
      html={<div className="popover">{content}</div>}
      style={{ display: 'block', height: '100%' }}
      popperOptions={popperOptions}
      theme={dark ? 'dark' : 'light'}
      unmountHTMLWhenHide
      useContext
      interactive
      distance={0}
      {...optionsForMobiles}
    >
      {children}
    </TippyTooltip>
  );
};

Popover.defaultProps = {
  dark: false,
};

Popover.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  content: PropTypes.node.isRequired,
  dark: PropTypes.bool,
};

export default Popover;
