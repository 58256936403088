import { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';

import Error404 from '@old/components/error/Error404';
import Spinner from '@old/components/common/Spinner';
import PageTitle from '@old/components/guide/PageTitle';
import FlexRow from '@old/components/common/FlexRow';
import Box from '@old/components/common/Box';
import t from 'resources/translations';
import FloatingButton from '@old/components/custom/FloatingButton';
import Center from '@old/components/common/Center';
import ImageCircle from '@old/components/guide/ImageCircle';
import RowAligner from '@old/components/common/RowAligner';
import FlexColumn from '@old/components/common/FlexColumn';
import Subtitle from '@old/components/common/Subtitle';
import BoxSwitcher from '@old/components/common/BoxSwitcher';
import ColorBar from '@old/components/common/ColorBar';
import { parseToCurrencyString } from 'old/utils';
import Message from '@old/components/common/Message';
import Icon from '@old/components/icon';
import { fetchEventType } from 'store/actions';
import { useEventTypeEditButton, useEventTypeDeleteButton } from 'old/buttonHooks';

const EventTypeDetails = ({ match, isMobile, loading, error, eventType, ...actions }) => {
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchEventType(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  const editEventType = useEventTypeEditButton(eventType);
  const deleteEventType = useEventTypeDeleteButton(eventType, { customRedirect: '/farm' });

  // [API-3.0] [Start] look at this after API 3.0 is released
  const eventTypeButtons = eventType && !eventType.isDeleted() ? [editEventType, deleteEventType] : [];
  // [API-3.0] [End] look at this after API 3.0 is released

  if (error) return <Error404 />;
  if (loading && !eventType) return <Spinner />;
  if (!eventType) return null;

  const time = eventType.timeIntervalsDuration.map(interval =>
    moment.utc(moment.duration(interval, 'minutes').asMilliseconds()).format('H [godz] mm [min]')
  );
  return (
    <div>
      <PageTitle title={eventType.getName()} back="/farm" loading={loading} />
      {eventType.isDeleted() && (
        <Message deleted>
          <RowAligner>
            <Icon.WarningOutline className="fill-teal" />
            <span>{t('model.deleteNotify.eventType')}</span>
          </RowAligner>
        </Message>
      )}
      <FlexRow marginBottom cols="12/4" stretched>
        <FlexRow cols="none/1" stretched separated={false}>
          {!isMobile && (
            <div className="bg-grey py-6 px-2 items-center">
              <ColorBar color={eventType.getColor()} vertical />
            </div>
          )}
          <BoxSwitcher>
            <FlexRow cols="none/none/1" stretched>
              <Center>
                <ImageCircle src={eventType.getImage()} large />
              </Center>
              {isMobile && (
                <Center>
                  <ColorBar color={eventType.getColor()} />
                </Center>
              )}
              <div>
                <RowAligner>
                  <div className="text-highlight">{t('labels.slug')}:</div>
                  <div className="leading-tight uppercase">{eventType.getSlug()}</div>
                </RowAligner>
                <RowAligner>
                  {eventType.defaultPlace && (
                    <RowAligner>
                      <div className="text-highlight">{t('labels.place')}:</div>
                      <div>{eventType.defaultPlace.name}</div>
                    </RowAligner>
                  )}
                </RowAligner>
              </div>
            </FlexRow>
          </BoxSwitcher>
        </FlexRow>
        <Box>
          <FlexColumn>
            <Subtitle>{t('eventTypes.duration')}</Subtitle>
            <FlexColumn separated="small">
              {time.map(interval => (
                <div key={interval}>{interval}</div>
              ))}
            </FlexColumn>
          </FlexColumn>
        </Box>
      </FlexRow>
      <BoxSwitcher>
        <Subtitle>{t('eventTypes.priceList')}</Subtitle>
        <div>{parseToCurrencyString(eventType.defaultEventCost)}</div>
      </BoxSwitcher>
      <FloatingButton buttons={eventTypeButtons} />
    </div>
  );
};

EventTypeDetails.defaultProps = {
  eventType: null,
};

const mapStateToProps = ({ fetchingData: { eventTypes, loading, error }, app: { isMobile } }, { match }) => {
  return {
    isMobile,
    eventType: eventTypes.find(eventType => String(eventType.id) === String(match.params.id)),
    loading: loading.eventTypes,
    error: error.eventTypes,
  };
};

export default connect(mapStateToProps, { fetchEventType })(EventTypeDetails);
