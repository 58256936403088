/* eslint-disable max-len */
import React from 'react';

const Www = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <path d="M12.2,12.2V8.4C10.7,8.3,9.3,8,7.9,7.4c-0.4,1.5-0.6,3.1-0.7,4.7H12.2z" />
      <path d="M12.2,17.6v-3.8h-5c0.1,1.7,0.3,3.3,0.7,4.7C9.3,18,10.7,17.7,12.2,17.6z" />
      <path d="M8.4,5.9c1.2,0.5,2.5,0.7,3.8,0.8V1.4C10.4,1.9,9.1,4.1,8.4,5.9z" />
      <path d="M17.7,20.1c-1.2-0.5-2.5-0.7-3.8-0.8v5.3C15.7,24.1,17,21.9,17.7,20.1z" />
      <path d="M5.6,12.2c0.1-1.9,0.3-3.8,0.8-5.4C5.2,6.1,4.2,5.4,3.2,4.5c-1.9,2.2-3,4.8-3.1,7.7H5.6z" />
      <path d="M20.6,13.8c-0.1,1.9-0.3,3.8-0.8,5.4c1.2,0.6,2.2,1.4,3.2,2.3c1.9-2.2,3-4.8,3.1-7.7L20.6,13.8z" />
      <path d="M5.6,13.8H0.1c0.2,2.9,1.3,5.5,3.1,7.7c1-0.9,2-1.7,3.2-2.3C5.9,17.6,5.6,15.7,5.6,13.8L5.6,13.8z" />
      <path d="M12.2,24.6v-5.3c-1.3,0.1-2.6,0.4-3.8,0.8C9.1,21.9,10.4,24.1,12.2,24.6z" />
      <path d="M19.2,20.8c-0.8,2-2.1,4-3.9,5c2.4-0.4,4.6-1.5,6.5-3.1C21,22,20.1,21.3,19.2,20.8L19.2,20.8z" />
      <path d="M6.9,20.8C6,21.3,5.1,22,4.4,22.7c1.8,1.7,4.1,2.7,6.5,3.1C9,24.9,7.7,22.8,6.9,20.8L6.9,20.8z" />
      <path d="M6.9,5.2c0.8-2,2.1-4,3.9-5C8.4,0.6,6.2,1.6,4.4,3.3C5.1,4,6,4.7,6.9,5.2L6.9,5.2z" />
      <path d="M13.9,13.8v3.8c1.5,0.1,2.9,0.4,4.3,0.9c0.4-1.5,0.6-3.1,0.7-4.7H13.9z" />
      <path d="M19.7,6.7c0.5,1.7,0.8,3.5,0.8,5.4h5.5c-0.2-2.9-1.3-5.5-3.1-7.7C22,5.4,20.9,6.1,19.7,6.7L19.7,6.7z" />
      <path d="M13.9,8.4v3.8h5c-0.1-1.7-0.3-3.3-0.7-4.7C16.8,8,15.4,8.3,13.9,8.4z" />
      <path d="M13.9,1.4v5.3c1.3-0.1,2.6-0.4,3.8-0.8C17,4.1,15.7,1.9,13.9,1.4z" />
      <path d="M15.3,0.2c1.9,1,3.1,3,3.9,5C20.1,4.7,21,4,21.8,3.3C19.9,1.6,17.7,0.6,15.3,0.2L15.3,0.2z" />
    </svg>
  );
};

export default Www;
/* eslint-enable max-len */
