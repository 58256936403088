/**
 * @module EditableSelect
 */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobile as isMobileDevices } from 'react-device-detect';

import Icon from '@old/components/icon';
import Input from '@old/components/input';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import { useOutsideClick, useKey } from '@old/hooks';
import RowAligner from '@old/components/common/RowAligner';
import { Text } from 'components/elements';
import { useShallowSelector } from 'old/hooks';
/**
 * Options for the select input
 * @typedef   {Object} Options
 * @property  {String|Number} key - unique key
 * @property  {String} label
 * @property  {String|Number} value
 */
/**
 * Component displaying value and giving the option of editing
 * @param  {Object} [props.value]
 * @param  {Function} props.onSubmit submit handler
 * @param  {String} [props.label]
 * @param  {Options[]} props.options
 */
const EditableSelect = ({ value, onSubmit, label, options }) => {
  const [selectValue, setSelectValue] = useState(value || {});
  const [editing, setIsEditing] = useState(false);
  const innerRef = useRef();
  const { isMobile } = useShallowSelector(({ app }) => app);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  useOutsideClick(innerRef, () => {
    cancelEdit();
  });

  const onEscapeCancelEdit = useKey(() => cancelEdit(), 'esc');

  const onChange = val => {
    setSelectValue(val);
  };

  const onSave = () => {
    setIsEditing(false);
    onSubmit(selectValue.value);
  };

  const cancelEdit = () => {
    setSelectValue(selectValue);
    setIsEditing(false);
  };

  /**
   * @name getSaveIcon
   * @function
   * @description Function that add onTouchStart because onClick doesn't work with react-select library on mobile devices
   */
  const getSaveIcon = () => {
    const iconOptions = isMobile || isMobileDevices ? { onTouchStart: onSave } : {}; // leave both, some devices are not recognized correctly

    return (
      <div {...iconOptions}>
        <ButtonSimple onClick={onSave}>
          <Icon.Save className="fill-teal" />
        </ButtonSimple>
      </div>
    );
  };

  return (
    <div>
      <div className="editable">
        {!editing && (
          <div>
            <RowAligner separated="small" className="text-input-content">
              <Text color="teal-dark" fontWeight="medium">
                {label}:
              </Text>
              <Text>{value.label}</Text>
              <ButtonSimple onClick={() => setIsEditing(true)}>
                {/* modification that fixes a cut icon on Iphone */}
                <div className="ml-2">
                  <Icon.Edit className="fill-teal" small />
                </div>
              </ButtonSimple>
            </RowAligner>
          </div>
        )}
        {editing && (
          <div ref={innerRef}>
            <Input.Select
              label={label}
              value={selectValue}
              onChange={onChange}
              icon={getSaveIcon()}
              autoFocus
              options={options}
              onKeyDown={onEscapeCancelEdit}
              menuIsOpen
            />
          </div>
        )}
      </div>
    </div>
  );
};

EditableSelect.defaultProps = {
  value: null,
  label: '',
};

EditableSelect.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object]),
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
};

export default EditableSelect;
