import React from 'react';

const ArrowRight = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      width="100%"
      className="icon"
    >
      <path d="M4.9,0l8,8l-8,8l-1.9-1.9L9.2,8L3.1,1.9L4.9,0z" />
    </svg>
  );
};

export default ArrowRight;
