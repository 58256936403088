import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { isEmpty } from 'lodash';
import axios from 'axios';

import PageTitle from '@old/components/guide/PageTitle';
import FlexRow from '@old/components/common/FlexRow';
import RowAligner from '@old/components/common/RowAligner';
import t from 'resources/translations';
import Box from '@old/components/common/Box';
import FloatingButton from '@old/components/custom/FloatingButton';
import Message from '@old/components/common/Message';
import FlexColumn from '@old/components/common/FlexColumn';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Spinner from '@old/components/common/Spinner';
import Error404 from '@old/components/error/Error404';
import { useShiftsAddButton, useShiftsEditButton, useShiftsDeleteButton } from 'old/buttonHooks';
import { setPagination, fetchShifts, fetchUser } from 'store/actions';
import { getPaginationId } from 'old/utils';
import PagePagination from '@old/components/old/PagePagination';
import config from '@old/config';

const Shifts = ({ loading, error, shifts, shiftsPagination, pagination, history, match, member, ...actions }) => {
  const { minItemsPerPage } = config;

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchUser(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    // eslint-disable-line
    if (isEmpty(pagination) || pagination.current !== undefined) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const fetch = async () => {
        await actions.fetchShifts(
          parseInt(match.params.id, 10),
          {
            page: getActivePage() || 1,
            per_page: minItemsPerPage,
          },
          source.token
        );
      };
      fetch();
      return () => source.cancel();
    }
  }, [history.location.state, match.params.id, pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shiftsPagination) {
      const pag = { ...shiftsPagination };
      // When you delete last element on page, go back to previous one
      const lastPage = Math.ceil(pag.count / pag.per_page);
      if (pag.per_page * pag.current > pag.count) {
        if (lastPage && pag.current > lastPage) {
          pag.current = lastPage;
          history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
        }
        if (pag.current === 0) {
          pag.current = 1;
        }
      }
      actions.setPagination(pag, getPaginationKey());
    }
  }, [shiftsPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  const editShifts = useShiftsEditButton(member);
  const addShifts = useShiftsAddButton(member);
  const deleteShifts = useShiftsDeleteButton(member);

  const formatDate = date => moment(date).format(config.dateTimeFormatWithComma);

  if (error) return <Error404 />;
  if (loading && (!member || !shifts.length)) {
    return <Spinner noDelay />;
  }
  if (!member) return null;

  return (
    <div>
      <PageTitle title={t('shifts.shiftInstructor')} back={`/instructors/${match.params.id}`} loading={loading} />
      {!shifts.length && <Message info>{t('message.noShifts')}</Message>}
      <FlexColumn>
        {shifts.map(shift => {
          return (
            <Box key={shift.id}>
              <FlexRow cols="1/none" noShrink>
                {t('shifts.shifts', { from: formatDate(shift.beginning), to: formatDate(shift.ending) })}
                <RowAligner>
                  {editShifts && (
                    <ButtonSimple onClick={() => editShifts.onClick(shift)}>
                      <div className="teal-hover">{editShifts.icon}</div>
                    </ButtonSimple>
                  )}
                  {deleteShifts && (
                    <ButtonSimple onClick={() => deleteShifts.onClick(shift.id)}>
                      <div className="teal-hover">{deleteShifts.icon}</div>
                    </ButtonSimple>
                  )}
                </RowAligner>
              </FlexRow>
            </Box>
          );
        })}
        <PagePagination paginationKey={getPaginationKey()} />
      </FlexColumn>
      <FloatingButton buttons={[addShifts]} />
    </div>
  );
};

Shifts.defaultProps = {
  shifts: [],
  shiftsPagination: {},
  match: {
    params: {
      model: null,
    },
  },
  member: null,
};

const mapStateToProps = (
  { pagination, fetchingData: { users, shifts, shiftsPagination, loading, error } },
  { match }
) => {
  const paginationKey = getPaginationId(`${window.location.pathname}`);
  return {
    shifts,
    shiftsPagination,
    member: users.find(user => String(user.id) === String(match.params.id)),
    loading: loading.users || loading.shifts,
    error: error.users || error.shifts,
    pagination: pagination[paginationKey] || {},
  };
};

export default withRouter(connect(mapStateToProps, { fetchShifts, setPagination, fetchUser })(Shifts));
