import { AvatarWithLabel, ContextMenu, ContextMenuItem, Divider, IconButton } from 'components/combinations';
import { Box, Button, Flex, Icons, Link, Stack, Text } from 'components/elements';
import { truncate } from 'lodash';
import Participation from 'models/Participation';
import t from 'resources/translations';
import { parseToCurrencyString } from 'old/utils';
import { usePermissions } from 'Permissions';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import PaymentsList from './PaymentsList';
import { TBoxProps } from 'resources/types/elementsTypes';
import css from '@styled-system/css';
import theme from 'config/theme';
import { useModal } from 'old/hooks';
import { useAppSelector } from 'utils';

const boxStyles: TBoxProps = {
  fontWeight: 'bold',
  border: 'orange',
  borderWidth: 1,
  width: 20,
  height: 20,
  borderStyle: 'solid',
  borderRadius: '9999px',
  textAlign: 'center',
};

type TBillDetailsTransactions = {
  participation: Participation;
  isDivider: boolean;
};

const BillDetailsTransactions = ({ participation, isDivider, ...stackProps }: TBillDetailsTransactions) => {
  const [showPaymentsList, setShowPaymentsList] = useState<Boolean>(false);
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const queryClient = useQueryClient();
  const isPaymentAllowed = usePermissions('BILL_SET_PAYMENT');
  const isReturnAllowed = usePermissions('BILL_RETURN_PAYMENT');
  const paymentModal = useModal('PAYMENT_EVENT');
  const modalReturnPayment = useModal('PAYMENT_RETURN');
  const modalHistoryPayments = useModal('BILL_HISTORY_PAYMENTS');

  const refreshDetails = () => queryClient.invalidateQueries(['BILL_DETAILS'], { refetchActive: true });

  const onOpenPaymenModal = () => {
    paymentModal.onOpen({ bill: participation.bill, onSubmitCb: refreshDetails, onChangeDueCb: refreshDetails });
  };
  const onOpenReturnPaymenModal = () => {
    modalReturnPayment.onOpen({ bill: participation.bill, onSubmitCb: refreshDetails });
  };

  const onOpenHistoryPaymentsModal = (participation: Participation) => {
    return modalHistoryPayments.onOpen({ bill: participation.bill, isOptOut: participation.optOut });
  };

  const getStatusColor = useCallback((dueAmount, paidAmount) => {
    if (dueAmount === paidAmount) return <Text>{t('general.paid').toLowerCase()}</Text>;
    if (dueAmount > paidAmount) return <Text color="orange">{t('bills.overpaid').toLowerCase()}</Text>;
    return <Text color="orange">{t('paymentStatus.unpaid').toLowerCase()}</Text>;
  }, []);

  if (!participation.bill) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <Stack bgColor="white" p="md" spacing="sm" boxShadow="base" radius={['sm', 'base']} {...stackProps}>
          <Stack spacing="xs" isHorizontal>
            <AvatarWithLabel
              key={participation.member.id}
              src={participation.member.getAvatar('small')}
              alt={participation.member.getFullName()}
              title={participation.member.getFullName()}
              squareSize={26}
              radius="full"
            >
              <Link to={participation.member.getProfileUrl()} forceRender>
                {truncate(participation.member.getFullName(), { length: 45, omission: '...' })}
              </Link>
            </AvatarWithLabel>
            <ContextMenu>
              {isPaymentAllowed(participation.bill, participation) && (
                <ContextMenuItem onClick={() => onOpenPaymenModal()}>
                  <Text>{t('buttons.settle')}</Text>
                </ContextMenuItem>
              )}
              {isReturnAllowed(participation.bill, participation) && (
                <ContextMenuItem onClick={() => onOpenReturnPaymenModal()}>
                  <Text>{t('buttons.return')}</Text>
                </ContextMenuItem>
              )}
              {!!participation?.bill?.payments.length && (
                <ContextMenuItem onClick={() => onOpenHistoryPaymentsModal(participation)}>
                  <Text>{t('bills.paymentsHistory')}</Text>
                </ContextMenuItem>
              )}
            </ContextMenu>
          </Stack>
          <Text>
            {`${t('bills.due')}: `}
            {parseToCurrencyString(participation?.bill?.getDue() || 0)}
          </Text>
          <Flex width="100%">
            <Text>
              {`${t('bills.deposit')}: `}
              {parseToCurrencyString(participation?.bill?.getPaidAmount() || 0)}
            </Text>
            <Flex justifyContent="flex-end">
              {getStatusColor(participation?.bill?.paidAmount || 0, participation?.bill?.dueAmount || 0)}
            </Flex>
          </Flex>
          {!participation?.bill?.payments.length && isPaymentAllowed(participation.bill, participation) && (
            <Stack pt="sm" mx={-20}>
              <Divider width="100%" color="new-grey-light-30" />
              <Button variant="link" onClick={() => onOpenPaymenModal()}>
                <Text>{t('buttons.settle')}</Text>
              </Button>
            </Stack>
          )}
        </Stack>
      ) : (
        <Box
          w="full"
          css={css({
            '&:hover': {
              backgroundColor: theme.colors['teal-light-5'],
            },
          })}
        >
          <Box position="relative" px="xl">
            <Flex py="md" alignItems="center">
              <Flex flex={4}>
                <Stack spacing="xs" isHorizontal>
                  <AvatarWithLabel
                    key={participation.member.id}
                    src={participation.member.getAvatar('small')}
                    alt={participation.member.getFullName()}
                    title={participation.member.getFullName()}
                    squareSize={26}
                    radius="full"
                  >
                    <Link to={participation.member.getProfileUrl()} forceRender>
                      {truncate(participation.member.getFullName(), { length: 45, omission: '...' })}
                    </Link>
                  </AvatarWithLabel>
                </Stack>
              </Flex>
              <Flex flex={3}>
                <Text>{parseToCurrencyString(participation?.bill?.getDue() || 0)}</Text>
              </Flex>
              <Flex flex={3}>
                <Text>{parseToCurrencyString(participation?.bill?.getPaidAmount() || 0)}</Text>
              </Flex>
              <Flex flex={3} textAlign="center">
                {getStatusColor(participation?.bill?.paidAmount || 0, participation?.bill?.dueAmount || 0)}
              </Flex>
              <Flex flex={4}>
                <Flex minW={80} pr="md" width="100%" justifyContent="center">
                  {isPaymentAllowed(participation.bill, participation) && (
                    <Button variant="link" onClick={() => onOpenPaymenModal()}>
                      <Stack py="sm" spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                        <Icons.PaymentWallet size={16} />
                        <Text>{t('buttons.settle')}</Text>
                      </Stack>
                    </Button>
                  )}
                  {isReturnAllowed(participation.bill, participation) && (
                    <Button variant="link" onClick={() => onOpenReturnPaymenModal()}>
                      <Stack py="sm" spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                        <Icons.Return size={16} />
                        <Text whiteSpace="nowrap">{t('buttons.return')}</Text>
                      </Stack>
                    </Button>
                  )}
                </Flex>
                <Flex justifyContent="flex-end">
                  {!!participation?.bill?.payments.length && (
                    <IconButton
                      icon={showPaymentsList ? 'ArrowUp' : 'ArrowDown'}
                      onClick={() => setShowPaymentsList(prevShowPaymentsList => !prevShowPaymentsList)}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
            {isDivider && !showPaymentsList && <Divider width="100%" color="new-grey-light-30" />}
          </Box>
          {showPaymentsList && (
            <Box px="xl">
              {participation.optOut && (
                <Stack isHorizontal spacing="sm" mb="xl" alignItems="center">
                  <Box {...boxStyles}>
                    <Text color="orange">!</Text>
                  </Box>
                  <Text color="orange">{t('eventRiderAction.rejectInvitation')}</Text>
                </Stack>
              )}
              <PaymentsList bill={participation?.bill} />
              <Divider width="full" color="new-grey-light-30" />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
export default BillDetailsTransactions;
