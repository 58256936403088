/**
 * @module AddRatingModal
 */
import { connect } from 'react-redux';
import axios from 'axios';

import { fetchRatings } from 'store/actions';
import t from 'resources/translations';
import { getErrorMessage, notify, getModelByName } from 'old/utils';
import Form from '@old/components/common/Form';
import Field from '@old/components/field';
import Modal from '@old/components/common/Modal';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import FormButton from '@old/components/common/FormButton';
import { FORM_ADD_RATING } from 'old/constants/formNames';
/**
 *@typedef {Object} Rating
 *@property {String} comment
 *@property {Number} rating
 */

/**
 * A component that displays a modal window for adding rating to horses, instructors and farms.
 * @param  {'members'|'horses'|'farms'} [props.modelName]
 * @param  {Member|Horse|Farm|Rating} [props.modelItem]
 * @param  {Function} props.onClose Modal closing function The parameter must contain the name of the modal
 * @param  {Function} props.ratingsFetchParams Init fetch params for ratings
 */
const AddRatingModal = ({ modelName, modelItem, ratingsFetchParams, onClose, ...actions }) => {
  const CurrentModel = getModelByName(modelName);
  let source;

  const onSubmit = async formState => {
    const { CancelToken } = axios;
    if (source) {
      source.cancel();
    }
    source = CancelToken.source();

    try {
      await CurrentModel.rate(modelItem.id, { ...formState });
      const { page, per_page } = ratingsFetchParams;
      actions.fetchRatings(CurrentModel, modelItem.id, { page, per_page }, source.token);
      notify(t('message.ratingSuccessSave'));
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const validationTextarea = [
    {
      condition: value => !(value.length > 500),
      errorHint: t('error.commentMaxLenght'),
    },
  ];

  const validationRating = [
    {
      condition: value => !(value === 0),
    },
  ];

  if (!modelItem || !CurrentModel) return null;
  const header = `${t('addRatingModal.rating')} ${modelItem.getName()}`;

  const initValues = {
    stars: 0,
    comment: '',
  };

  return (
    <Modal header={header} onClose={onClose} isOpen>
      <Form formName={FORM_ADD_RATING} initValues={initValues} refreshAlways noPadding>
        <Field.Rating name="stars" label={t('addRatingModal.rating')} required validation={validationRating} />
        <Field.TextArea
          placeholder={t('placeholders.typeYourComment')}
          name="comment"
          label={t('addRatingModal.comment')}
          validation={validationTextarea}
        />
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <FormButton formName={FORM_ADD_RATING} onClick={onSubmit} fluid async>
            {t('general.add')}
          </FormButton>
        </FlexRow>
      </Form>
    </Modal>
  );
};

AddRatingModal.defaultProps = {
  modelItem: {},
  modelName: null,
};

const mapStateToProps = ({ fetchingData: { ratingsFetchParams } }) => ({ ratingsFetchParams });
export default connect(mapStateToProps, { fetchRatings })(AddRatingModal);
