import { connect } from 'react-redux';
import moment from 'moment';
import { cloneDeep } from 'lodash';

import Calendar from '@old/components/view/calendar/Calendar';
import PageTitle from '@old/components/guide/PageTitle';
import FloatingButton from '@old/components/custom/FloatingButton';
import t from 'resources/translations';
import { useAddEventButton } from 'old/buttonHooks';
import { getCalendarKey } from 'old/utils';
import config from '@old/config';
import { useOldLoggedMember } from 'utils/storeUtils';

const Timetable = ({ calendarDayStart }) => {
  const loggedMember = useOldLoggedMember();
  const addEvent = useAddEventButton({
    initState: {
      initValues: {
        startDate: calendarDayStart,
        instructors: loggedMember.isInstructor() ? [{ value: loggedMember.id, label: loggedMember.getFullName() }] : [],
      },
    },
  });

  const fetchParams = {
    with_status: ['awaiting', 'ongoing', 'active', 'finished'],
  };

  return (
    <>
      <PageTitle title={t('timetable.myCalendar')} />
      <Calendar fetchParams={fetchParams} />
      <FloatingButton buttons={[addEvent]} />
    </>
  );
};

const mapStateToProps = ({ calendar }, props) => {
  const calendarKey = getCalendarKey(props.location.pathname);
  const calendarState = cloneDeep(calendar[calendarKey] || calendar.default);

  return {
    calendarDayStart: calendarState.dayStart
      .hour(moment().hour() + 1)
      .startOf('hour')
      .format(config.dateTimeFormat),
  };
};

export default connect(mapStateToProps)(Timetable);
