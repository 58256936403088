import { connect } from 'react-redux';
import axios from 'axios';

import Modal from '@old/components/common/Modal';
import BoxWhite from '@old/components/common/BoxWhite';
import Model from '@old/model';
import { getErrorMessage, notify } from 'old/utils';
import t from 'resources/translations';
import { fetchUsers } from 'store/actions';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_EDIT_GUEST } from 'old/constants/formNames';
import config from '@old/config';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';

const EditGuestModal = ({ member, onClose, usersFetchParams, ...actions }) => {
  let source;
  const onSubmit = async ({ phone }) => {
    const { CancelToken } = axios;
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();
    try {
      await Model.Members.editGuest(member.identityId, { phone });
      actions.fetchUsers(usersFetchParams, source.token);
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  if (!member) return null;

  return (
    <Modal header={t('editGuestModal.editGuestTitle')} onClose={onClose} isOpen>
      <BoxWhite>
        <Form formName={FORM_EDIT_GUEST} initValues={{ phone: member.phone || '' }} refreshAlways noPadding>
          <Field.Phone
            name="phone"
            label={t('labels.phone')}
            defaultDirectionalValue={config.defaultDirectional.value}
            placeholder={t('placeholders.phone')}
          />
          <FlexRow noShrink>
            <ButtonOutline onClick={onClose} fluid>
              {t('general.cancel')}
            </ButtonOutline>
            <FormButton formName={FORM_EDIT_GUEST} onClick={onSubmit} fluid>
              {t('general.save')}
            </FormButton>
          </FlexRow>
        </Form>
      </BoxWhite>
    </Modal>
  );
};

EditGuestModal.defaultProps = {
  member: null,
};

const mapStateToProps = ({ fetchingData: { usersFetchParams } }) => ({ usersFetchParams });

export default connect(mapStateToProps, { fetchUsers })(EditGuestModal);
