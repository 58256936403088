import { AvatarWithLabel, ContextMenu, ContextMenuItem, Divider, LinkMember } from 'components/combinations';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { Box, Flex, Icons, Link, Span, Stack, Text } from 'components/elements';
import { DATE_FORMAT } from 'config/constans';
import Bill from 'models/Bill';
import Ticket from 'models/Ticket';
import moment from 'moment';
import t from 'resources/translations';
import { getErrorMessage, isAllowed, notify } from 'old/utils';
import React from 'react';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { TFlexProps, TIconCircleProps, TTextProps } from 'resources/types/elementsTypes';
import { TColorsProps } from 'resources/types/styledTypes';
import { BillService, TicketService } from 'services';
import { useModal } from 'old/hooks';
import { useAppStore } from 'utils';

const styleProps = {
  iconRectangle: (iconColor: TColorsProps) => ({
    bgColor: iconColor,
    color: 'white',
    size: 'sm',
    border: { borderRadius: 3 },
  }),
  textTruncate: {
    minW: 0,
    maxW: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} as {
  iconRectangle: (iconColor: TColorsProps) => TIconCircleProps;
  textTruncate: TTextProps;
};

type TTicketItem = {
  ticket: Ticket;
  iconColor?: TColorsProps;
  flexProps?: TFlexProps;
};

const TicketItem = ({ ticket, iconColor = 'teal', ...flexProps }: TTicketItem) => {
  const { isMobile } = useAppStore();
  const queryClient = useQueryClient();

  const confirmDeleteModal = useModal('CONFIRM');
  const editRidesModal = useModal('TICKET_RIDES_EDIT');
  const editValidityModal = useModal('TICKET_VALIDITY_EDIT');
  const paymentTicketModal = useModal('PAYMENT_TICKET');
  const history = useHistory();

  const deleteTicket = async () => await TicketService.delete(ticket.id);
  const refreshTickets = () => queryClient.invalidateQueries('TICKETS');
  const deleteMutation = useMutation(deleteTicket, { onSuccess: refreshTickets });

  const onOpenDeleteModal = () => {
    confirmDeleteModal.onOpen({
      title: t('tickets.deleteTicket'),
      content: (
        <ModalDescription>
          <Stack spacing="xl">
            <Stack spacing="sm">
              <Text>{t('tickets.deleteAssignedTicket')}:</Text>
              <Text>
                <Span fontWeight="medium">{ticket.getName()}</Span>?
              </Text>
            </Stack>
            <Stack spacing="sm" alignItems={['flex-start', 'center']} isHorizontal={!isMobile}>
              <Text whiteSpace="nowrap">{t('tickets.assignedClient')}:</Text>
              <AvatarWithLabel
                spacing="sm"
                squareSize={26}
                src={ticket.membership.getAvatar('thumb')}
                alt={ticket.membership.getFullName()}
                title={ticket.membership.getFullName()}
              >
                <Text mt={['sm', 0]} fontWeight="medium">
                  {ticket.membership.getFullName()}
                </Text>
              </AvatarWithLabel>
            </Stack>
            <Text fontWeight="medium">{t('tickets.rememberTicketDeletedFromProfile')}</Text>
          </Stack>
        </ModalDescription>
      ),
      action: {
        mutation: deleteMutation,
        label: t('buttons.yesIWantDeleteTicket'),
        cancelLabel: t('buttons.noGetMyOutOfHere'),
        loadingLabel: t('message.removed'),
      },
    });
  };

  const onOpenEditRides = () => {
    editRidesModal.onOpen({ ticket });
  };

  const onOpenEditValidity = async () => {
    try {
      editValidityModal.onOpen({ ticket });
      const billResult = await BillService.fetch(ticket.billId);
      editValidityModal.onUpdate({ bill: new Bill(billResult) });
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const onOpenPaymenModal = async () => {
    const onSubmitCb = () => queryClient.invalidateQueries('TICKETS', { refetchActive: true });
    try {
      paymentTicketModal.onOpen({ ticket, onSubmitCb });
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  return (
    <Flex borderColor="new-grey-light" borderWidth={1} borderRadius={8} p="md" {...flexProps}>
      <Flex flexDirection={['column', 'row']} minW={0} overflow="hidden">
        <Stack flex={2} minW={0} overflow="hidden">
          <Stack spacing="sm" alignItems="center" pr="sm" flex={1} minW={0} overflow="hidden" isHorizontal>
            {!isMobile && <Icons.TicketCircle {...styleProps.iconRectangle(iconColor)} />}
            <Flex minW={0} overflow="hidden" w="full">
              <Link to={`/tickets/${ticket.id}`} fontSize="md" fontWeight="medium" {...styleProps.textTruncate} allowed>
                {ticket.getName()}
              </Link>
            </Flex>
          </Stack>
          <Box>
            <AvatarWithLabel
              spacing="sm"
              squareSize={26}
              src={ticket.membership.getAvatar('thumb')}
              title="tytul"
              alt="alt"
            >
              <LinkMember member={ticket.membership}>{ticket.membership.getFullName()}</LinkMember>
            </AvatarWithLabel>
          </Box>
        </Stack>
        <Flex flex="none" mt={['md', 0]} minW={['auto', 300]}>
          <Stack spacing="sm">
            <Box w="full">
              <Text fontWeight="medium">{t('tickets.periodValidityOfTickets')}:</Text>
              <Text color="#202020" mt={2}>
                {moment(ticket.activeAt).format(DATE_FORMAT)} - {moment(ticket.expireAt).format(DATE_FORMAT)}
              </Text>
            </Box>
            <Text>
              <Span fontWeight="medium">{t('tickets.assignmentDate')}: </Span>
              {moment(ticket.createdAt).format(DATE_FORMAT)}
            </Text>
          </Stack>
        </Flex>
      </Flex>
      <Flex flex="none" alignSelf="center" justifyContent="center" pl="md">
        <ContextMenu>
          <ContextMenuItem onClick={() => history.push(`/tickets/${ticket.id}`)}>
            <Text>{t('tickets.showHistoryOfTicket')}</Text>
          </ContextMenuItem>
          {isAllowed('tickets.update') && (
            <ContextMenuItem onClick={() => onOpenEditRides()}>
              <Text>{t('tickets.changeNumberOfRides')}</Text>
            </ContextMenuItem>
          )}
          {isAllowed('tickets.update') && (
            <ContextMenuItem onClick={() => onOpenEditValidity()}>
              <Text>{t('tickets.changePeriodOfValidity')}</Text>
            </ContextMenuItem>
          )}
          {ticket.status === 'unpaid' && (
            <>
              <ContextMenuItem onClick={() => onOpenPaymenModal()}>
                <Text>{t('buttons.settle')}</Text>
              </ContextMenuItem>
              <Divider orientation="vertical" size="2px" color="grey" />
              <ContextMenuItem onClick={() => onOpenDeleteModal()}>
                <Text>{t('tickets.deleteTicket')}</Text>
              </ContextMenuItem>
            </>
          )}
        </ContextMenu>
      </Flex>
    </Flex>
  );
};

export default React.memo(TicketItem);
