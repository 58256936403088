/**
 * @module ButtonWithArrow
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import Button from '@old/components/guide/Button';
import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
/**
 * Properties of the button
 * @typedef   {Object} ButtonProps
 * @property  {Function} [onClick] specifies what happens after button click
 * @property  {Boolean} [disabled] specifies wether the button should be disabled
 * @property  {Boolean|'simple'} [async] specifies wether the onClick function should be called asynchronously
 * @property  {String} [tabIndex] {@link https://developer.mozilla.org/pl/docs/Web/HTML/Global_attributes/tabindex}
 * @property  {'submit'|'button'|'reset'} [type] specifies type of the button
 */
/**
 * Component that generates button with outline and no background color
 * @param  {String} props.className
 * @param  {String|Node} props.children
 * @param  {ButtonProps} [props.buttonProps]
 */
const ButtonWithArrow = ({ className, children, ...buttonProps }) => {
  return (
    <Button {...buttonProps} className={cls(className, 'outline-grey')}>
      <RowAligner>
        {children}
        <Icon.ArrowRight small />
      </RowAligner>
    </Button>
  );
};

ButtonWithArrow.defaultProps = {
  className: '',
  buttonProps: {},
};

ButtonWithArrow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  buttonProps: PropTypes.shape({ // as in <Button />
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    async: PropTypes.bool,
    tabIndex: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
  }),
};

export default ButtonWithArrow;
