import React from 'react';
import cls from 'classnames';

// forwardRef doesn't support prop types
// eslint-disable-next-line react/prop-types
const Center = ({ children, className }, ref) => {
  return <div className={cls('centered', className)} ref={ref}>{children}</div>;
};

export default React.forwardRef(Center);
