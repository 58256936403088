import React, { useMemo } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';

import store from 'store';
import {
  fetchEvent,
  fetchEvents,
  deleteEvent,
  setLoadingEvent,
  openModal,
  closeModal,
  fetchUser,
  fetchUsers,
  fetchRatings,
  deleteRating,
  deleteEventType,
  fetchEventTypes,
  deletePlace,
  fetchPlaces,
  fetchFarm,
  fetchNews,
  fetchSingleNews,
  deleteNews,
  deleteGroup,
  fetchGroups,
  fetchGroup,
  deleteHorse,
  fetchHorses,
  fetchAbsences,
  fetchShifts,
  fetchProposals,
  deleteProposal,
  setTab,
} from 'store/actions';
import Icon from '@old/components/icon';
import {
  isAllowed,
  notify,
  getErrorMessage,
  getModelByName,
  getRoutePermission,
  getPermissionCategoryByName,
  fetchByModelName,
  getTabsId,
} from 'old/utils';
import Model from '@old/model';
import t from 'resources/translations';
import { useModal, useShallowSelector } from '@old/hooks';
import { MODAL_PAYMENT, MODAL_RETURN_PAYMENT, MODAL_CANCEL_PAYMENT } from 'old/constants/modalNames';
import { useFarm } from 'utils';
import { Image, Stack, Text } from 'components/elements';

export const useEventPairParticipantsWithHorsesButton = (event, participations, unpairedHorses, cb) => {
  const buttonProps = useMemo(() => {
    const participantsWithoutHorses = participations.filter(participation => participation.horse === null);
    if (!event || isEmpty(participantsWithoutHorses) || isEmpty(unpairedHorses) || event.isDeleted()) {
      return null;
    }
    const onClick = async () => {
      try {
        await Promise.all(
          participantsWithoutHorses.map(async (participant, index) => {
            try {
              if (unpairedHorses[index]) {
                await Model.Events.attachHorse(event.id, participant.id, unpairedHorses[index].id);
                return true;
              }
              return false;
            } catch (e) {
              notify(getErrorMessage(e), { type: 'error' });
              return false;
            }
          })
        );
        await cb();
        notify(t('message.horsesPairedWithParticipants'));
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    if (isAllowed('participations.attach_horse', { modelItem: event })) {
      return {
        key: 'eventPairParticipantsWithHorses',
        label: t('buttons.pairAutomatically'),
        onClick,
        iconName: 'LinkAnchor',
        icon: <Icon.LinkAnchorCircular big />,
      };
    }

    return null;
  }, [event, participations, unpairedHorses]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useAcceptInvitationButton = (event, participation, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || !participation || event.isDeleted()) {
      return null;
    }

    const onClick = async () => {
      try {
        store.dispatch(setLoadingEvent(event.id));
        await Model.Events.acceptRequestedInvitation(event.id, participation.id);
        await cb();
      } catch (e) {
        store.dispatch(setLoadingEvent(false));
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    if (participation.getPermission('accept')) {
      return {
        key: 'accept',
        label: t('buttons.accept'),
        onClick,
        iconName: 'Checked',
        icon: <Icon.Checked />,
      };
    }

    return null;
  }, [event, participation]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useRejectInvitationButton = (event, participation, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || !participation || event.isDeleted()) {
      return null;
    }

    const onClick = async () => {
      try {
        store.dispatch(setLoadingEvent(event.id));
        await Model.Events.rejectRequestedInvitation(event.id, participation.id);
        await cb();
      } catch (e) {
        store.dispatch(setLoadingEvent(false));
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    if (!event.isPast() && participation.getPermission('reject')) {
      return {
        key: 'reject',
        label: t('buttons.removeParticipant'),
        onClick,
        iconName: 'CancelOutline',
        icon: <Icon.CancelOutline />,
      };
    }

    return null;
  }, [event, participation]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useBillPaymentButton = (bill, callback) => {
  const { onOpen } = useModal(MODAL_PAYMENT);

  const buttonProps = useMemo(() => {
    const isInactive =
      bill?.isPaid() ||
      bill?.isBooked() ||
      bill?.getPaidAmount() !== 0 ||
      bill?.getCurrentTransaction()?.status === 'pending';
    if (isInactive) {
      return null;
    }

    return {
      key: 'setPaymentBill',
      label: t('buttons.settle'),
      onClick: () => onOpen({ bill, callback }),
    };
  }, [bill, callback, onOpen]);

  return buttonProps;
};

export const useBillReturnPaymentButton = (bill, callback = () => {}) => {
  const { onOpen } = useModal(MODAL_RETURN_PAYMENT);
  const { member: loggedMember } = useShallowSelector(({ app }) => app);
  const buttonProps = useMemo(() => {
    const isActive = bill?.isPaid() || bill?.isOverpaid() || (bill?.isSettled() && !!bill?.getBookedPayments().length);
    if (!isActive || loggedMember.isClient()) {
      return null;
    }

    return {
      key: 'setReturnPaymentBill',
      label: t('bills.return'),
      onClick: () => onOpen({ bill, callback }),
    };
  }, [bill, onOpen, loggedMember, callback]);

  return buttonProps;
};

export const useBillCancelPaymentButton = (bill, callback = () => {}) => {
  const { onOpen } = useModal(MODAL_CANCEL_PAYMENT);
  const { member: loggedMember } = useShallowSelector(({ app }) => app);
  const buttonProps = useMemo(() => {
    const isActive = bill.getCurrentTransaction()?.isPending();
    if (!isActive || !loggedMember.isClient()) {
      return null;
    }

    return {
      key: 'setReturnPaymentBill',
      label: t('bills.return'),
      onClick: () => onOpen({ bill, callback }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill]);

  return buttonProps;
};

export const useSetParticipationsPresentButton = (event, participations) => {
  const buttonProps = useMemo(() => {
    const participantsWithoutPresent = participations.filter(participation => participation.attendance === 'absent');
    if (!event || !participations || isEmpty(participantsWithoutPresent) || event.isDeleted()) {
      return null;
    }

    // // we can not provide memberId here because 'isCancelled' is not related to member
    const { isCancelled } = event.getStatuses();
    const onClick = async () => {
      store.dispatch(setLoadingEvent(event.id));
      await Promise.all(
        participantsWithoutPresent.map(async participation => {
          try {
            await event.presentParticipation(participation.id);
            return true;
          } catch (e) {
            notify(getErrorMessage(e), { type: 'error' });
            return false;
          }
        })
      );
      store.dispatch(fetchEvent(event.id));
    };
    const permissionPresent =
      participantsWithoutPresent.some(participation => participation.getPermission('present')) ||
      participantsWithoutPresent.some(participation => participation.getPermission('present_incl_finished')) ||
      participantsWithoutPresent.some(participation =>
        participation.getPermission('present_incl_finished_if_is_instructor')
      );
    if (!isCancelled && permissionPresent) {
      return {
        key: 'present',
        label: t('labels.addAttendanceToAttendees'),
        onClick,
        iconName: 'Check',
        icon: <Icon.Check />,
      };
    }
    return null;
  }, [event, participations]);

  return buttonProps;
};

export const useSetParticipationPresentButton = (event, participation, cb) => {
  const buttonProps = useMemo(() => {
    if (!event || !participation || event.isDeleted()) {
      return null;
    }
    const permissionPresent =
      participation.getPermission('present') ||
      participation.getPermission('present_if_is_instructor') ||
      participation.getPermission('present_incl_finished') ||
      participation.getPermission('present_incl_finished_if_is_instructor');

    // we can not provide memberId here because 'isCancelled' is not related to member
    const { isCancelled } = event.getStatuses();

    const onClick = async () => {
      try {
        store.dispatch(setLoadingEvent(event.id));
        await event.presentParticipation(participation.id);
        await cb();
      } catch (e) {
        store.dispatch(setLoadingEvent(false));
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    if (!isCancelled && permissionPresent) {
      return {
        key: 'present',
        label: t('buttons.setAttending'),
        onClick,
        iconName: 'Check',
        icon: <Icon.Check />,
      };
    }

    return null;
  }, [event, participation]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useSetParticipationAbsentButton = (event, participation, cb) => {
  const buttonProps = useMemo(() => {
    if (!event || !participation || event.isDeleted()) {
      return null;
    }

    // we can not provide memberId here because 'isCancelled' is not related to member
    const { isCancelled } = event.getStatuses();
    const onClick = async () => {
      try {
        store.dispatch(setLoadingEvent(event.id));
        await event.absentParticipation(participation.id);
        await cb();
      } catch (e) {
        store.dispatch(setLoadingEvent(false));
        notify(getErrorMessage(e), { type: 'error' });
      }
    };
    const permissionAbsent =
      participation.getPermission('absent') ||
      participation.getPermission('absent_if_is_instructor') ||
      participation.getPermission('absent_incl_finished') ||
      participation.getPermission('absent_incl_finished_if_is_instructor');

    if (!isCancelled && permissionAbsent) {
      return {
        key: 'absent',
        label: t('buttons.setAbsent'),
        onClick,
        iconName: 'Cancel',
        icon: <Icon.Cancel />,
      };
    }

    return null;
  }, [event, participation]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventActivateButton = (event, options = {}, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || event.isDeleted()) {
      return null;
    }

    const onClick = async () => {
      try {
        store.dispatch(setLoadingEvent(event.id));
        await Model.Events.activate(event.id);
        notify(t('message.activated'));
        if (options.newCache) {
          await cb();
        } else store.dispatch(fetchEvent(event.id));
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    const { isCancelled, isFinished } = event.getStatuses();
    if (isCancelled && !isFinished && event.getPermission('activate')) {
      return {
        key: 'eventActivate',
        label: t('buttons.activate'),
        onClick,
        iconName: 'Checked',
      };
    }

    return null;
  }, [event, cb, options]);

  return buttonProps;
};

export const useEventDeleteButton = (event, options = {}, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || event.isDeleted()) {
      return null;
    }

    const { isCancelled } = event.getStatuses();
    if (isCancelled && !event.isPast() && event.getPermission('destroy')) {
      return {
        key: 'eventDelete',
        label: t('general.delete'),
        onClick: () => store.dispatch(openModal('confirmDeleteEvent', { event, ...options, cb })),
        iconName: 'Delete',
      };
    }

    return null;
  }, [event, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventCancelButton = (event, options = {}, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || event.isPast() || event.isDeleted()) {
      return null;
    }

    const { isAwaiting, isActive } = event.getStatuses();
    if ((isAwaiting || isActive) && event.getPermission('cancel')) {
      return {
        key: 'eventCancel',
        label: t('general.cancel'),
        onClick: () => store.dispatch(openModal('cancelEvent', { event, ...options, cb })),
        iconName: 'Cancel',
      };
    }

    return null;
  }, [event, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventEditButton = event => {
  const buttonProps = useMemo(() => {
    if (!event || event.isDeleted()) {
      return null;
    }

    if (!event.isPast() && event.getPermission('update')) {
      return {
        key: 'eventEdit',
        label: t('general.edit'),
        to: { pathname: `/events/${event.id}/edit`, state: { prevPathname: window.location.pathname } },
        iconName: 'Edit',
      };
    }

    return null;
  }, [event]);

  return buttonProps;
};

export const useEventCopyButton = event => {
  const buttonProps = useMemo(() => {
    if (!event || event.isDeleted()) {
      return null;
    }

    const path = '/events/add';
    if (!event.isPast() && getRoutePermission(path)) {
      return {
        key: 'eventCopy',
        label: t('buttons.copyEvent'),
        to: { pathname: path, state: { docId: event.id } },
        iconName: 'Doc',
      };
    }
    // [API-3.0] [End] look at this after API 3.0 is released

    return null;
  }, [event]);

  return buttonProps;
};

export const useEventRepeatButton = event => {
  const buttonProps = useMemo(() => {
    if (!event || event.isDeleted()) {
      return null;
    }

    // [API-3.0] [Start] look at this after API 3.0 is released
    if (getRoutePermission('/events/add')) {
      return {
        key: 'repeatEvent',
        label: t('repeatEventModal.repeat'),
        onClick: () => store.dispatch(openModal('repeatEvent', { event })),
        iconName: 'RepeatEvent',
      };
    }
    // [API-3.0] [End] look at this after API 3.0 is released

    return null;
  }, [event]);

  return buttonProps;
};

export const useEventJoinButton = (event, options = {}, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || !options?.loggedMember || event.isDeleted()) {
      return null;
    }
    const onClick = async () => {
      try {
        store.dispatch(setLoadingEvent(event.id));
        await Model.Events.join(event.id);
        if (options.newCache) {
          await cb();
        } else store.dispatch(fetchEvent(event.id));
        notify(t('message.joined'));
      } catch (e) {
        store.dispatch(setLoadingEvent(false));
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    const { memberIsJoined, memberIsInvited, memberRequestInvitation, isAwaiting, isFull, isActive } =
      event.getStatuses(options.loggedMember.id);

    const active =
      !memberRequestInvitation && !memberIsInvited && !memberIsJoined && !isFull && (isAwaiting || isActive);

    if (event.getPermission('join') && active) {
      return {
        key: 'eventJoin',
        label: t('buttons.signUp'),
        onClick,
        iconName: 'Check',
      };
    }

    return null;
  }, [event, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventUnjoinButton = (event, options = {}, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || !options?.loggedMember || event.isDeleted()) {
      return null;
    }
    const unjoinTimeout = event.unjoinTimeout / 3600;

    const onClick = async () => {
      try {
        store.dispatch(setLoadingEvent(event.id));
        await Model.Events.unjoin(event.id);
        if (options.newCache) {
          await cb();
        } else {
          if (options?.deleteFromList) {
            store.dispatch(deleteEvent(event.id));
            const {
              fetchingData: { eventsFetchParams },
            } = store.getState();
            store.dispatch(fetchEvents(eventsFetchParams));
          } else {
            store.dispatch(fetchEvent(event.id));
          }
        }
        notify(t('message.unjoined'));
      } catch (e) {
        store.dispatch(setLoadingEvent(false));
        if (unjoinTimeout > 0) {
          notify(t('error.unjoin_impossible_from_joined'), { type: 'error' });
        } else {
          notify(getErrorMessage(e), { type: 'error' });
        }
      }
    };

    const { memberIsJoined, isAwaiting, isActive } = event.getStatuses(options.loggedMember.id);
    const active = memberIsJoined && (isAwaiting || isActive) && !event.canUnjoin();

    if (event.getPermission('unjoin') && active) {
      return {
        key: 'eventUnjoin',
        label: t('buttons.signOff'),
        onClick,
        iconName: 'Cancel',
      };
    }

    return null;
  }, [event, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventRejectInvitationButton = (event, options = {}, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || !options?.loggedMember || event.isDeleted()) {
      return null;
    }

    const onClick = async () => {
      try {
        store.dispatch(setLoadingEvent(event.id));
        await Model.Events.reject(event.id);
        if (options.newCache) {
          await cb();
        } else {
          if (options?.deleteFromList) {
            store.dispatch(deleteEvent(event.id));
            const {
              fetchingData: { eventsFetchParams },
            } = store.getState();
            store.dispatch(fetchEvents(eventsFetchParams));
          } else {
            store.dispatch(fetchEvent(event.id));
          }
        }
        notify(t('message.invitationRejected'));
      } catch (e) {
        store.dispatch(setLoadingEvent(false));
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    const { memberIsInvited, isAwaiting, isActive } = event.getStatuses(options.loggedMember.id);

    const active = memberIsInvited && (isAwaiting || isActive);

    if (event.getPermission('reject') && active) {
      return {
        key: 'eventRejectInvitation',
        label: t('buttons.reject'),
        onClick,
        iconName: 'Cancel',
        icon: <Icon.Cancel small />,
      };
    }

    return null;
  }, [event, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventAcceptInvitationButton = (event, options = {}, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || !options?.loggedMember || event.isDeleted()) {
      return null;
    }

    const onClick = async () => {
      try {
        store.dispatch(setLoadingEvent(event.id));
        await Model.Events.join(event.id);
        if (options.newCache) {
          await cb();
        } else {
          if (options?.deleteFromList) {
            store.dispatch(deleteEvent(event.id));
            const {
              fetchingData: { eventsFetchParams },
            } = store.getState();
            store.dispatch(fetchEvents(eventsFetchParams));
          } else {
            store.dispatch(fetchEvent(event.id));
          }
        }
        notify(t('message.invitationAccepted'));
      } catch (e) {
        store.dispatch(setLoadingEvent(false));
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    const { memberIsInvited, isAwaiting, isActive, isFull } = event.getStatuses(options.loggedMember.id);

    const active = memberIsInvited && (isAwaiting || isActive) && !isFull;

    if (event.getPermission('join') && active) {
      return {
        key: 'eventAcceptInvitation',
        label: t('general.accept'),
        onClick,
        iconName: 'Check',
        icon: <Icon.Check small />,
      };
    }

    return null;
  }, [event, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventDeleteHorseButton = (event, cb) => {
  const buttonProps = useMemo(() => {
    if (!event || event.isDeleted()) {
      return null;
    }

    const onClick = async horseId => {
      const horsesIds = event.getHorses().map(horse => horse.id);
      try {
        store.dispatch(setLoadingEvent(event.id));
        await Model.Events.update(event.id, { horse_ids: horsesIds.filter(id => id !== horseId) });
        await cb();
        notify(t('model.delete.horses'));
      } catch (e) {
        store.dispatch(setLoadingEvent(false));
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    if (event.getPermission('update')) {
      return {
        key: 'eventDeleteHorseButton',
        label: t('buttons.deleteHorse'),
        onClick,
        icon: <Icon.Delete className="fill-teal" />,
      };
    }

    return null;
  }, [event]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventAddHorseButton = (event, cb) => {
  const buttonProps = useMemo(() => {
    if (!event || event.isDeleted()) {
      return null;
    }

    if (event.getPermission('update')) {
      return {
        key: 'eventAddHorse',
        label: t('model.add.horses'),
        onClick: () => store.dispatch(openModal('addHorse', { event, cb })),
        iconName: 'AddHorse',
        icon: <Icon.HorseAloneCircular big />,
      };
    }

    return null;
  }, [event]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventAddParticipantButton = (event, cb = () => {}) => {
  const buttonProps = useMemo(() => {
    if (!event || event.isDeleted()) {
      return null;
    }

    const isFull = event.getAttendeesMax() <= event.getJoinedParticipants().length;

    if (!isFull && event.getPermission('add')) {
      return {
        key: 'eventAddParticipant',
        label: t('model.add.participants'),
        onClick: () => store.dispatch(openModal('addParticipant', { event, cb })),
        iconName: 'AddUser',
        icon: <Icon.UserCircular big />,
      };
    }

    return null;
  }, [event]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventInviteParticipantButton = (event, cb) => {
  const buttonProps = useMemo(() => {
    if (!event || event.isDeleted()) {
      return null;
    }

    const { isFull } = event.getStatuses();

    if (!isFull && !event.isPast() && event.getPermission('invite')) {
      return {
        key: 'eventInviteParticipant',
        label: t('general.inviteParticipants'),
        onClick: () => store.dispatch(openModal('inviteParticipants', { event, cb })),
        iconName: 'Invite',
        icon: <Icon.InviteUser circle />,
      };
    }

    return null;
  }, [event]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useDeleteFarmPolicyButton = () => {
  const onClick = () => {
    store.dispatch(
      openModal('confirmModal', {
        header: t('showPolicyModal.policyDelete'),
        content: t('showPolicyModal.policyDeleteContent'),
        onSubmitConfirm: async () => {
          try {
            await Model.Farms.deleteFarmPolicy();
            notify(t('message.deleteFarmPolicySuccess'));
            store.dispatch(fetchFarm());
            store.dispatch(closeModal('showPolicy'));
          } catch (e) {
            notify(getErrorMessage(e), { type: 'error' });
          }
        },
        approveLabel: t('general.delete'),
      })
    );
  };

  if (isAllowed('farm_policy.destroy')) {
    return {
      key: 'policyDelete',
      label: t('showPolicyModal.deletePolicy'),
      onClick,
    };
  }

  return null;
};

export const useUserEditButton = (member, isCurrentUser) => {
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    if (isCurrentUser && isAllowed('memberships.update', { id: member.id })) {
      return {
        key: 'userEdit',
        label: t('general.edit'),
        to: `/users/${member.id}/edit`,
        iconName: 'Edit',
      };
    }

    return null;
  }, [member, isCurrentUser]);

  return buttonProps;
};

export const useUserEditShiftButton = member => {
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    // fixme: (status.isInstructor && (status.isCurrentUser || status.currentIsOwner)) && !status.isPending,
    if (!member.isPending() && member.isInstructor()) {
      return {
        key: 'editShift',
        iconName: 'Shift',
        to: `/instructors/${member.id}/shifts`,
        label: t('buttons.addShift'),
      };
    }

    return null;
  }, [member]);

  return buttonProps;
};

export const useMemberManagementButton = (
  member,
  status,
  fetchSingle = false,
  callback = () => {},
  label = t('general.manage')
) => {
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }
    // ???
    if (((status.currentIsClient && status.isCurrentUser) || !status.currentIsClient) && !status.isPending) {
      return {
        key: 'memberManagement',
        label,
        onClick: () => store.dispatch(openModal('manageMember', { member, fetchSingle, callback })),
        iconName: 'Settings',
      };
    }
    return null;
  }, [member, status, fetchSingle, callback, label]);
  return buttonProps;
};

export const useGuestEditButton = member => {
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    // ???
    if (member.isGuest() && member.getPermission('update')) {
      return {
        key: 'editGuest',
        label: t('buttons.editPhone'),
        onClick: () => store.dispatch(openModal('editGuest', { member })),
        iconName: 'Edit',
      };
    }

    return null;
  }, [member]);

  return buttonProps;
};

export const usePendingAcceptButton = (member, fetchSingle = false) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    if (member.isPending() && member.getPermission('accept')) {
      return {
        key: 'pendingAccept',
        label: t('general.accept'),
        onClick: () =>
          Model.Members.accept(member.id)
            .then(() => {
              notify(t('message.userAccepted'));
              if (fetchSingle) {
                store.dispatch(fetchUser(member.id));
              } else {
                const {
                  fetchingData: { usersFetchParams },
                } = store.getState();
                store.dispatch(fetchUsers(usersFetchParams, source.token));
              }
              return () => source.cancel();
            })
            .catch(() => {
              notify(t('error.userAcceptingFailed'), { type: 'error' });
            }),
        iconName: 'Add',
      };
    }

    return null;
  }, [member, fetchSingle]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const usePendingRejectButton = (member, fetchSingle = false, redirect = () => {}) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    if (member.isPending() && member.getPermission('reject')) {
      return {
        key: 'pendingDelete',
        label: t('general.cancel'),
        onClick: () =>
          Model.Members.reject(member.id)
            .then(() => {
              notify(t('message.userRejected'));
              if (fetchSingle) {
                store.dispatch(fetchUser(member.id));
                redirect();
              } else {
                const {
                  fetchingData: { usersFetchParams },
                } = store.getState();
                store.dispatch(fetchUsers(usersFetchParams, source.token));
              }
              return () => source.cancel();
            })
            .catch(() => {
              notify(t('error.userRejectingFailed'), { type: 'error' });
            }),
        iconName: 'Delete',
      };
    }

    return null;
  }, [member, fetchSingle, redirect]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

const refreshPage = () => {
  let prefix = '';
  if (!window.location.host.startsWith('http')) {
    prefix = `${window.location.protocol}//`;
  }
  window.location.replace(`${prefix}${window.location.host}/`);
  Model.refresh();
};

const afterDeleteUser = editingOwnProfile => {
  if (editingOwnProfile) {
    localStorage.clear();
    refreshPage();
  }
};

export const useMemberDeleteMembershipButton = (
  member,
  editingOwnProfile,
  goBack,
  fetchSingle = false,
  callback = () => {}
) => {
  const farm = useFarm();
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }
    const { CancelToken } = axios;
    const source = CancelToken.source();

    if (isAllowed('memberships.destroy', { id: member.id })) {
      return {
        key: 'memberDeleteMembership',
        onClick: () =>
          store.dispatch(
            openModal('confirmDelete', {
              doc: member,
              goBack,
              membership: true,
              header: t('memberManagement.unsubscribeFarm'),
              approveLabel: t('buttons.signOff'),
              content: (
                <Stack spacing="xl">
                  <Text>{t('memberManagement.unsubscribeFromTheFarm', { farmName: farm.getName() })}</Text>
                  <Image
                    key={farm.id}
                    src={farm.getAvatar('large')}
                    alt={farm.getName()}
                    title={farm.getName()}
                    squareSize={120}
                    radius="full"
                  />
                </Stack>
              ),
              afterSubmit: () => {
                afterDeleteUser(editingOwnProfile);
                if (!fetchSingle) {
                  const {
                    fetchingData: { usersFetchParams },
                  } = store.getState();
                  store.dispatch(fetchUsers(usersFetchParams, source.token));
                  callback();
                }
                return () => source.cancel();
              },
            })
          ),
        label: t('memberManagement.unsubscribeFarm'),
        iconName: 'UnsubscribeFarm',
      };
    }
    return null;
  }, [member, editingOwnProfile, goBack, fetchSingle, callback]); // eslint-disable-line react-hooks/exhaustive-deps
  return buttonProps;
};

export const useMemberDeleteAccountButton = (member, edtingOwnProfile) => {
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    if (isAllowed('memberships.destroy', { id: member.id }) && edtingOwnProfile) {
      return {
        key: 'memberDeleteAccount',
        onClick: () =>
          store.dispatch(
            openModal('confirmDelete', {
              doc: member,
              afterSubmit: () => afterDeleteUser(edtingOwnProfile),
              user: true,
              header: t('memberManagement.deletingAccount'),
              approveLabel: t('memberManagement.deleteAccount'),
              content: (
                <Stack spacing="xl">
                  <Text fontWeight="medium">{t('memberManagement.ifYouDeleteAccount')}</Text>
                  <Stack spacing="sm">
                    <Text>{t('memberManagement.deleteAccountAreYouSure', { memberName: member.getName() })}</Text>
                    <Image
                      key={member.id}
                      src={member.getAvatar('large')}
                      alt={member.getName()}
                      title={member.getName()}
                      squareSize={120}
                      radius="full"
                    />
                  </Stack>
                </Stack>
              ),
            })
          ),
        label: t('memberManagement.deleteAccount'),
        iconName: 'DeleteOutline',
      };
    }
    return null;
  }, [member, edtingOwnProfile]);

  return buttonProps;
};

export const useMemberBlockButton = (member, edtingOwnProfile, fetchSingle = false, callback = () => {}) => {
  const buttonProps = useMemo(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    if (!member) {
      return null;
    }
    const active = !member.isBlocked() && !member.isGuest() && !edtingOwnProfile;
    if (active && member.getPermission('block')) {
      return {
        key: 'memberBlock',
        onClick: () =>
          store.dispatch(
            openModal('blockUser', {
              doc: member,
              afterSubmit: () => {
                if (fetchSingle) {
                  store.dispatch(fetchUser(member.id));
                } else {
                  const {
                    fetchingData: { usersFetchParams },
                  } = store.getState();
                  store.dispatch(fetchUsers(usersFetchParams, source.token));
                  callback();
                }
                return () => source.cancel();
              },
            })
          ),
        label: t('memberManagement.blockUser'),
        iconName: 'BlockOutline',
      };
    }
    return null;
  }, [member, edtingOwnProfile, fetchSingle, callback]);

  return buttonProps;
};

export const useMemberUnblockButton = (member, edtingOwnProfile, fetchSingle = false, callback = () => {}) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    const active = member.isBlocked() && !member.isGuest() && !edtingOwnProfile;
    if (active && member.getPermission('update')) {
      return {
        key: 'memberUnblock',
        onClick: () =>
          store.dispatch(
            openModal('blockUser', {
              doc: member,
              afterSubmit: () => {
                if (fetchSingle) {
                  store.dispatch(fetchUser(member.id));
                } else {
                  const {
                    fetchingData: { usersFetchParams },
                  } = store.getState();
                  store.dispatch(fetchUsers(usersFetchParams, source.token));
                  callback();
                }
                return () => source.cancel();
              },
            })
          ),
        label: t('memberManagement.unblock'),
        iconName: 'Block',
      };
    }
    return null;
  }, [member, edtingOwnProfile, fetchSingle, callback]); // eslint-disable-line react-hooks/exhaustive-deps
  return buttonProps;
};

export const useMemberDeleteGuestButton = (member, callback = () => {}) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    if (member.isGuest() && isAllowed('guests.destroy')) {
      return {
        key: 'memberDeleteGuest',
        onClick: () =>
          store.dispatch(
            openModal('deleteGuest', {
              member,
              afterSubmit: () => {
                const {
                  fetchingData: { usersFetchParams },
                } = store.getState();
                store.dispatch(fetchUsers(usersFetchParams, source.token));
                callback();
                return () => source.cancel();
              },
            })
          ),
        label: t('general.delete'),
        iconName: 'DeleteOutline',
      };
    }
    return null;
  }, [member, callback]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useFarmEditButton = farm => {
  const buttonProps = useMemo(() => {
    if (!farm) {
      return null;
    }
    if (farm.getPermission('update')) {
      return {
        key: 'editFarm',
        label: t('general.edit'),
        to: '/farm/edit',
        iconName: 'Edit',
      };
    }

    return null;
  }, [farm]);

  return buttonProps;
};

export const useAddFarmPolicyButton = () => {
  const buttonProps = useMemo(() => {
    if (isAllowed('farm_policy.create')) {
      return {
        key: 'addFarmPolicy',
        label: t('buttons.addFarmPolicy'),
        to: '/policy/edit',
        iconName: 'Policy',
      };
    }

    return null;
  }, []);

  return buttonProps;
};

export const useEditFarmPolicyButton = () => {
  const buttonProps = useMemo(() => {
    if (isAllowed('farm_policy.update')) {
      return {
        key: 'editFarmPolicy',
        label: t('buttons.editFarmPolicy'),
        to: '/policy/edit',
        iconName: 'EditFarmPolicy',
      };
    }

    return null;
  }, []);

  return buttonProps;
};
export const useAddPictureButton = modelItem => {
  const buttonProps = useMemo(() => {
    if (!modelItem) {
      return null;
    }
    const CurrentModel = getModelByName(modelItem.modelName);
    if (modelItem.getPermission('update')) {
      return {
        key: 'addPicture',
        label: t('general.addPicture'),
        onClick: () =>
          store.dispatch(
            openModal('addPicture', {
              modelItem,
              model: CurrentModel,
              afterSubmit: () => {
                fetchByModelName(modelItem.id, modelItem.modelName);
              },
            })
          ),
        iconName: 'Camera',
      };
    }

    return null;
  }, [modelItem]);

  return buttonProps;
};

export const useAddGuestButton = loggedMember => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    const active = loggedMember.isOwner() || loggedMember.isInstructor();
    if (active && isAllowed('guests.create')) {
      return {
        key: 'addGuest',
        label: t('usersPage.addGuest'),
        onClick: () =>
          store.dispatch(
            openModal('addGuest', {
              afterAddGuest: () => {
                const {
                  fetchingData: { usersFetchParams },
                } = store.getState();
                store.dispatch(fetchUsers(usersFetchParams, source.token));
                return () => source.cancel();
              },
            })
          ),
        iconName: 'Add',
      };
    }
    return null;
  }, [loggedMember]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useEventTypeEditButton = eventType => {
  const buttonProps = useMemo(() => {
    if (!eventType) {
      return null;
    }

    if (eventType.getPermission('update')) {
      return {
        key: 'eventTypeEdit',
        label: t('general.edit'),
        to: `/event-types/${eventType.id}/edit`,
        iconName: 'Edit',
      };
    }

    return null;
  }, [eventType]);

  return buttonProps;
};

export const useNewsEditButton = news => {
  const buttonProps = useMemo(() => {
    if (!news) {
      return null;
    }
    if (news.getPermission('update')) {
      return {
        key: 'newsEdit',
        label: t('general.edit'),
        to: { pathname: `/news/${news.id}/edit`, state: { prevPathname: window.location.pathname } },
        iconName: 'Edit',
      };
    }
    return null;
  }, [news]);
  return buttonProps;
};

export const useAddNewsButton = () => {
  const buttonProps = useMemo(() => {
    const path = '/news/add';
    if (getRoutePermission(path)) {
      return {
        key: 'addNews',
        label: t('general.add'),
        to: path,
        iconName: 'Add',
      };
    }
    return null;
  }, []);

  return buttonProps;
};

export const useNewsDeleteButton = newsItem => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!newsItem) {
      return null;
    }

    const onClick = () => {
      store.dispatch(
        openModal('confirmDelete', {
          doc: newsItem,
          customRedirect: '/news',
          afterSubmit: () => {
            const {
              fetchingData: { newsFetchParams },
            } = store.getState();
            store.dispatch(deleteNews(newsItem.id));
            store.dispatch(fetchNews(newsFetchParams, source.token));
            return () => source.cancel();
          },
        })
      );
    };
    if (newsItem.getPermission('destroy')) {
      return {
        key: 'newsDelete',
        label: t('general.delete'),
        onClick,
        iconName: 'Delete',
      };
    }
    return null;
  }, [newsItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useAddGroupButton = loggedMember => {
  const buttonProps = useMemo(() => {
    const active = loggedMember.isOwner() || loggedMember.isInstructor();
    if (active && isAllowed('guests.create')) {
      return {
        key: 'addGroup',
        label: t('usersPage.addGroup'),
        onClick: () => store.dispatch(openModal('manageGroup')),
        permission: 'riding_groups.create',
        active: loggedMember.isOwner() || loggedMember.isInstructor(), // fixme
        iconName: 'Add',
      };
    }

    return null;
  }, [loggedMember]);

  return buttonProps;
};

export const useEventTypeDeleteButton = (eventType, options = {}) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!eventType) {
      return null;
    }

    if (eventType.getPermission('destroy')) {
      return {
        key: 'eventTypeDelete',
        label: t('general.delete'),
        onClick: () =>
          store.dispatch(
            openModal('confirmDelete', {
              doc: eventType,
              customRedirect: options.customRedirect,
              afterSubmit: () => {
                store.dispatch(deleteEventType(eventType.id));
                const {
                  fetchingData: { eventTypesFetchParams },
                } = store.getState();
                store.dispatch(fetchEventTypes(eventTypesFetchParams, source.token));
                return () => source.cancel();
              },
            })
          ),
        iconName: 'Delete',
      };
    }
    return null;
  }, [eventType, options.goBack]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useNewsPublishButton = (newsItem, history, fetchSingle = false) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!newsItem) {
      return null;
    }

    const onClick = async () => {
      try {
        const tabsId = getTabsId('/news');
        await newsItem.publish();
        if (fetchSingle) {
          history.replace(`/news/${newsItem.id}`);
          store.dispatch(fetchSingleNews(newsItem.id, source.token));
        } else {
          const {
            fetchingData: { newsFetchParams },
          } = store.getState();
          store.dispatch(deleteNews(newsItem.id));
          store.dispatch(fetchNews(newsFetchParams, source.token, true));
        }
        store.dispatch(setTab(0, tabsId));
        notify(
          <>
            <div>{t('add.news')}:</div>
            {newsItem.title}
          </>
        );
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      }
      return () => source.cancel();
    };

    if (newsItem.getPermission('publish') && newsItem.isDraft()) {
      return {
        key: 'draftPublish',
        label: t('newsForm.publish'),
        onClick,
        iconName: 'News',
      };
    }

    return null;
  }, [newsItem, history, fetchSingle]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};
export const usePlaceEditButton = place => {
  const buttonProps = useMemo(() => {
    if (!place || place.isDeleted()) {
      return null;
    }

    if (place.getPermission('update')) {
      return {
        key: 'placeEdit',
        label: t('general.edit'),
        iconName: 'Edit',
        to: { pathname: `/places/${place.id}/edit`, state: { prevPathname: window.location.pathname } },
      };
    }

    return null;
  }, [place]);

  return buttonProps;
};

export const useShiftsAddButton = member => {
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    if (isAllowed('work_shifts.create', member)) {
      return {
        key: 'shiftsAdd',
        label: t('buttons.addShifts'),
        onClick: () => store.dispatch(openModal('manageShift', { memberId: member.id })),
        iconName: 'Add',
      };
    }
    return null;
  }, [member]);

  return buttonProps;
};

export const usePlaceDeleteButton = (place, options = {}) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!place || place.isDeleted()) {
      return null;
    }

    if (place.getPermission('destroy')) {
      return {
        key: 'placeDelete',
        label: t('general.delete'),
        iconName: 'Delete',
        onClick: () =>
          store.dispatch(
            openModal('confirmDelete', {
              doc: place,
              goBack: options.goBack,
              afterSubmit: () => {
                store.dispatch(deletePlace(place.id));
                const {
                  fetchingData: { placesFetchParams },
                } = store.getState();
                store.dispatch(fetchPlaces(placesFetchParams, source.token));
                return () => source.cancel();
              },
            })
          ),
      };
    }

    return null;
  }, [place, options.goBack]); // eslint-disable-line react-hooks/exhaustive-deps
  return buttonProps;
};

export const useShiftsEditButton = member => {
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }

    if (isAllowed('work_shifts.update', member)) {
      return {
        key: 'shiftsEdit',
        label: t('buttons.editShifts'),
        onClick: shift => store.dispatch(openModal('manageShift', { memberId: member.id, shift })),
        iconName: 'Edit',
        icon: <Icon.Edit small />,
      };
    }

    return null;
  }, [member]);

  return buttonProps;
};

export const useShiftsDeleteButton = member => {
  const buttonProps = useMemo(() => {
    if (!member) {
      return null;
    }
    const deleteShift = shiftId =>
      Model.Members.deleteShift(member.id, shiftId)
        .then(() => {
          notify(t('message.removalSuccessShift'));
          const {
            fetchingData: {
              shiftsFetchParams: { page, per_page },
            },
          } = store.getState();
          store.dispatch(fetchShifts(member.id, { page, per_page }));
        })
        .catch(() => notify(t('message.removalFailedShift', { type: 'error' })));

    if (isAllowed('work_shifts.destroy', member)) {
      return {
        key: 'shiftsDelete',
        label: t('general.delete'),
        onClick: shiftId =>
          store.dispatch(
            openModal('confirmModal', {
              header: t('confirmDeleteModal.confirmationBeforeDeleting'),
              content: t('message.deleteShifts'),
              onSubmitConfirm: () => deleteShift(shiftId),
              approveLabel: t('general.delete'),
            })
          ),
        icon: <Icon.Delete small />,
        iconName: 'Delete',
      };
    }

    return null;
  }, [member]);

  return buttonProps;
};

export const useAbsencesAddButton = horseId => {
  const buttonProps = useMemo(() => {
    if (!horseId) {
      return null;
    }

    if (isAllowed('horse_absence.create')) {
      return {
        key: 'absencesAdd',
        label: t('absences.addAbsence'),
        onClick: () => store.dispatch(openModal('manageAbsence', { horseId })),
        iconName: 'Add',
      };
    }
    return null;
  }, [horseId]);

  return buttonProps;
};

export const useAbsencesEditButton = horseId => {
  const buttonProps = useMemo(() => {
    if (!horseId) {
      return null;
    }

    if (isAllowed('horse_absence.update')) {
      return {
        key: 'absencesEdit',
        label: t('general.edit'),
        onClick: absence => store.dispatch(openModal('manageAbsence', { absence, horseId })),
        iconName: 'Edit',
        icon: <Icon.Edit small />,
      };
    }

    return null;
  }, [horseId]);

  return buttonProps;
};

export const useAbsencesDeleteButton = horseId => {
  const buttonProps = useMemo(() => {
    if (!horseId) {
      return null;
    }

    const deleteAbsences = absenceId =>
      Model.Horses.deleteAbsence(horseId, absenceId)
        .then(() => {
          notify(t('message.removalSuccessAbsence'));
          const {
            fetchingData: {
              absencesFetchParams: { page, per_page },
            },
          } = store.getState();
          store.dispatch(fetchAbsences(parseInt(horseId, 10), { page, per_page }));
        })
        .catch(e => notify(getErrorMessage(e), { type: 'error' }));

    if (isAllowed('horse_absence.destroy')) {
      return {
        key: 'absencesDelete',
        label: t('general.delete'),
        onClick: absenceId =>
          store.dispatch(
            openModal('confirmModal', {
              header: t('confirmDeleteModal.confirmationBeforeDeleting'),
              content: t('message.deleteAbsence'),
              onSubmitConfirm: () => deleteAbsences(absenceId),
              approveLabel: t('general.delete'),
            })
          ),
        icon: <Icon.Delete small />,
        iconName: 'Delete',
      };
    }

    return null;
  }, [horseId]);

  return buttonProps;
};

export const useAddRateButton = modelItem => {
  const buttonProps = useMemo(() => {
    if (!modelItem) {
      return null;
    }

    if (isAllowed(`${getPermissionCategoryByName(modelItem.modelName)}.rate`)) {
      return {
        key: 'rate',
        label: t('buttons.addRate'),
        onClick: () => store.dispatch(openModal('addRating', { modelItem, modelName: modelItem.modelName })),
        iconName: 'Add',
      };
    }

    return null;
  }, [modelItem]);

  return buttonProps;
};

export const useDeleteRateButton = modelItem => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!modelItem) {
      return null;
    }
    const CurrentModel = getModelByName(modelItem.modelName);
    if (isAllowed(`${getPermissionCategoryByName(modelItem.modelName)}.destroy_rate`)) {
      return {
        key: 'deleteRate',
        label: t('buttons.deleteRate'),
        onClick: ratingId =>
          CurrentModel.deleteRating(modelItem.id, ratingId)
            .then(() => {
              notify(t('message.deleteRating'));
              store.dispatch(deleteRating(ratingId));
              const {
                fetchingData: {
                  ratingsFetchParams: { page, per_page },
                },
              } = store.getState();
              store.dispatch(fetchRatings(CurrentModel, modelItem.id, { page, per_page }, source.token));
              return () => source.cancel();
            })
            .catch(e => {
              notify(getErrorMessage(e), { type: 'error' });
            }),
        iconName: 'Delete',
      };
    }

    return null;
  }, [modelItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useHideRateButton = modelItem => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!modelItem) {
      return null;
    }
    const CurrentModel = getModelByName(modelItem.modelName);
    if (isAllowed(`${getPermissionCategoryByName(modelItem.modelName)}.toggle_rate`)) {
      return {
        key: 'hideRate',
        label: t('buttons.hideRate'),
        onClick: ratingId =>
          CurrentModel.toggleRating(modelItem.id, ratingId)
            .then(() => {
              notify(t('message.hideRating'));
              const {
                fetchingData: {
                  ratingsFetchParams: { page, per_page },
                },
              } = store.getState();
              store.dispatch(fetchRatings(CurrentModel, modelItem.id, { page, per_page }, source.token));
              return () => source.cancel();
            })
            .catch(e => {
              notify(getErrorMessage(e), { type: 'error' });
            }),
        iconName: 'VisibilityOff',
      };
    }

    return null;
  }, [modelItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useShowRateButton = modelItem => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!modelItem) {
      return null;
    }
    const CurrentModel = getModelByName(modelItem.modelName);
    if (isAllowed(`${getPermissionCategoryByName(modelItem.modelName)}.toggle_rate`)) {
      return {
        key: 'showRate',
        label: t('buttons.showRate'),
        onClick: ratingId =>
          CurrentModel.toggleRating(modelItem.id, ratingId)
            .then(() => {
              notify(t('message.showRating'));
              const {
                fetchingData: {
                  ratingsFetchParams: { page, per_page },
                },
              } = store.getState();
              store.dispatch(fetchRatings(CurrentModel, modelItem.id, { page, per_page }, source.token));
              return () => source.cancel();
            })
            .catch(e => {
              notify(getErrorMessage(e), { type: 'error' });
            }),
        iconName: 'VisibilityOn',
      };
    }

    return null;
  }, [modelItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useGroupEditButton = (group, fetchSingle = false) => {
  const buttonProps = useMemo(() => {
    if (!group) {
      return null;
    }

    if (group.getPermission('update')) {
      return {
        key: 'groupEdit',
        label: t('buttons.editGroup'),
        onClick: () => store.dispatch(openModal('manageGroup', { group, fetchSingle })),
        iconName: 'Edit',
      };
    }

    return null;
  }, [group, fetchSingle]);

  return buttonProps;
};

export const useGroupDeleteButton = (group, goBack = false) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!group) {
      return null;
    }

    if (group.getPermission('destroy')) {
      return {
        key: 'groupDelete',
        label: t('buttons.deleteGroup'),
        onClick: () =>
          store.dispatch(
            openModal('confirmDelete', {
              doc: group,
              goBack,
              afterSubmit: () => {
                store.dispatch(deleteGroup(group.id));
                const {
                  fetchingData: { groupsFetchParams },
                } = store.getState();
                store.dispatch(fetchGroups(groupsFetchParams, source.token));
                return () => source.cancel();
              },
            })
          ),
        iconName: 'Delete',
      };
    }

    return null;
  }, [group, goBack]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useGroupAddMemberButton = group => {
  const buttonProps = useMemo(() => {
    if (!group) {
      return null;
    }

    if (group.getPermission('update')) {
      return {
        key: 'groupAddMember',
        onClick: () => store.dispatch(openModal('addRiderToGroup', { group })),
        icon: <Icon.UserCircular big />,
        iconName: 'Add',
        label: t('buttons.addMembers'),
      };
    }

    return null;
  }, [group]);

  return buttonProps;
};

export const useGroupDeleteMemberButton = memberGroup => {
  const buttonProps = useMemo(() => {
    if (!memberGroup) {
      return null;
    }

    const deleteMember = async (group, memberId) => {
      try {
        await group.update({
          membership_ids: [...group.getMembersIds().filter(id => memberId !== id)],
        });
        notify(t('message.deleteUserSuccess'));
        store.dispatch(fetchGroup(group.id));
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      }
    };

    if (memberGroup.getPermission('update')) {
      return {
        key: 'groupDeleteMember',
        onClick: (group, memberId) =>
          store.dispatch(
            openModal('confirmModal', {
              header: t('confirmDeleteModal.deleteClient'),
              content: t('message.deleteRideFromGroup'),
              onSubmitConfirm: () => deleteMember(group, memberId),
              approveLabel: t('general.delete'),
            })
          ),
        icon: <Icon.Delete />,
        iconName: 'Delete',
        label: t('buttons.deleteMember'),
      };
    }

    return null;
  }, [memberGroup]);

  return buttonProps;
};

export const useHorseEditButton = horse => {
  const buttonProps = useMemo(() => {
    if (!horse || horse.isDeleted()) {
      return null;
    }

    if (horse.getPermission('update')) {
      return {
        key: 'horseEdit',
        label: t('general.edit'),
        to: `/horses/${horse.id}/edit`,
        iconName: 'Edit',
      };
    }

    return null;
  }, [horse]);

  return buttonProps;
};

export const useHorseDeleteButton = (horse, options = {}) => {
  const buttonProps = useMemo(() => {
    if (!horse || horse.isDeleted()) {
      return null;
    }

    if (horse.getPermission('destroy')) {
      return {
        key: 'horseDelete',
        label: t('general.delete'),
        onClick: () =>
          store.dispatch(
            openModal('confirmDelete', {
              doc: horse,
              customRedirect: options.customRedirect,
              afterSubmit: () => {
                store.dispatch(deleteHorse(horse.id));
                const {
                  fetchingData: { horsesFetchParams },
                } = store.getState();
                store.dispatch(fetchHorses(horsesFetchParams));
              },
            })
          ),
        iconName: 'Delete',
      };
    }
    return null;
  }, [horse, options.customRedirect]);

  return buttonProps;
};

export const useAbsenceHorseButton = horse => {
  const buttonProps = useMemo(() => {
    if (!horse || horse.isDeleted()) {
      return null;
    }

    const path = `/horses/${horse.id}/absences`;
    if (getRoutePermission(path)) {
      return {
        key: 'horseAbsence',
        label: t('buttons.absenceHorse'),
        to: path,
        iconName: 'Availability',
      };
    }
    return null;
  }, [horse]);

  return buttonProps;
};

export const useHistoryHorseRidingButton = horse => {
  const buttonProps = useMemo(() => {
    if (!horse || horse.isDeleted()) {
      return null;
    }

    const path = `/horses/${horse.id}/history`;
    if (getRoutePermission(path)) {
      return {
        key: 'horseHistory',
        label: t('buttons.historyHorseRiding'),
        to: path,
        iconName: 'History',
      };
    }
    return null;
  }, [horse]);

  return buttonProps;
};

export const useHorseHistoryRidingButton = horse => {
  const buttonProps = useMemo(() => {
    if (!horse) {
      return null;
    }

    const path = `/horses/${horse.id}/history`;
    if (getRoutePermission(path)) {
      return {
        key: 'horseHistory',
        label: t('buttons.historyHorseRiding'),
        to: path,
        iconName: 'History',
      };
    }
    return null;
  }, [horse]);

  return buttonProps;
};

export const useProposalsEditButton = proposal => {
  const buttonProps = useMemo(() => {
    if (!proposal) {
      return null;
    }
    if (isAllowed('proposals.update', { modelItem: proposal })) {
      return {
        key: 'proposalsEdit',
        label: t('general.edit'),
        to: `/proposals/${proposal.id}/edit`,
        iconName: 'Edit',
      };
    }

    return null;
  }, [proposal]);

  return buttonProps;
};

export const useProposalsDeleteButton = proposal => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!proposal) {
      return null;
    }

    if (isAllowed('proposals.destroy', { modelItem: proposal })) {
      return {
        key: 'proposalsDelete',
        label: t('general.delete'),
        onClick: () =>
          store.dispatch(
            openModal('confirmDelete', {
              doc: proposal,
              afterSubmit: () => {
                store.dispatch(deleteProposal(proposal.id));
                const {
                  fetchingData: { proposalsFetchParams },
                } = store.getState();
                store.dispatch(fetchProposals(proposalsFetchParams, source.token));
                return () => source.cancel();
              },
            })
          ),
        iconName: 'Delete',
      };
    }
    return null;
  }, [proposal]); // eslint-disable-line react-hooks/exhaustive-deps

  return buttonProps;
};

export const useProposalsRejectButton = proposal => {
  const buttonProps = useMemo(() => {
    if (!proposal) {
      return null;
    }

    if (isAllowed('proposals.reject', { modelItem: proposal })) {
      return {
        key: 'proposals.reject',
        label: t('proposalEventsList.reject'),
        onClick: () => store.dispatch(openModal('rejectProposal', { proposal })),
        iconName: 'Cancel',
      };
    }
    return null;
  }, [proposal]);

  return buttonProps;
};

export const useProposalsUpdateAndConfirmButton = (proposal, loggedMember) => {
  const buttonProps = useMemo(() => {
    if (!proposal) {
      return null;
    }

    const isInstructedByLoggedMember = proposal.getInstructorsIds().includes(loggedMember.id);
    const active = loggedMember.isOwner() || isInstructedByLoggedMember;

    if (active && isAllowed('proposals.approve', { modelItem: proposal })) {
      return {
        key: 'proposalsUpdateAndConfirmEvent',
        label: t('proposalEventsList.updateAndConfirmEvent'),
        to: {
          pathname: `/proposals/${proposal.id}/edit`,
          state: { confirm: true },
        },
        iconName: 'Check',
      };
    }
    return null;
  }, [proposal, loggedMember]);

  return buttonProps;
};

export const useProposalsConfirmButton = proposal => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const buttonProps = useMemo(() => {
    if (!proposal) {
      return null;
    }

    const approveProposal = item => {
      Model.Proposals.approve(item.id, Model.Proposal.mapToSaveData(item))
        .then(() => {
          notify(t('message.proposalApproved'));
          const {
            fetchingData: { proposalsFetchParams },
          } = store.getState();
          store.dispatch(fetchProposals(proposalsFetchParams), source.token);
          return () => source.cancel();
        })
        .catch(e => {
          notify(getErrorMessage(e), { type: 'error' });
        });
    };

    if (isAllowed('proposals.approve', { modelItem: proposal })) {
      return {
        key: 'proposalsConfirm',
        label: t('proposalEventsList.confirm'),
        onClick: () => approveProposal(proposal),
        iconName: 'Check',
      };
    }
    return null;
  }, [proposal]); // eslint-disable-line react-hooks/exhaustive-deps
  return buttonProps;
};

export const useAddHorseButton = () => {
  const buttonProps = useMemo(() => {
    if (isAllowed('horses.create')) {
      return {
        key: 'addHorse',
        label: t('horsesPage.addNewHorse'),
        to: '/horses/add',
        iconName: 'Add',
      };
    }
    return null;
  }, []);

  return buttonProps;
};

export const useAddProposalButton = ({ initState = {}, label }) => {
  const buttonProps = useMemo(() => {
    if (!initState) {
      return null;
    }
    if (isAllowed('proposals.create')) {
      return {
        key: 'addProposal',
        label,
        to: { pathname: '/proposals/add', state: initState },
        iconName: 'EventAdd',
      };
    }
    return null;
  }, [initState, label]);

  return buttonProps;
};

export const useAddEventButton = ({ initState, label }) => {
  const buttonProps = useMemo(() => {
    if (!initState) {
      return null;
    }

    const path = '/events/add';
    if (getRoutePermission(path)) {
      return {
        key: 'eventAdd',
        to: { pathname: path, state: initState },
        label: label || t('buttons.addEvent'),
        iconName: 'EventAdd',
      };
    }
    return null;
  }, [initState, label]);

  return buttonProps;
};

export const useAddPlaceButton = () => {
  const buttonProps = useMemo(() => {
    const path = '/places/add';

    if (getRoutePermission(path)) {
      return {
        key: 'addPlace',
        label: t('model.add.places'),
        to: path,
        iconName: 'Add',
      };
    }
    return null;
  }, []);

  return buttonProps;
};

export const useAddEventTypeButton = () => {
  const buttonProps = useMemo(() => {
    const path = '/event-types/add';

    if (getRoutePermission(path)) {
      return {
        key: 'addType',
        label: t('eventTypes.addEventTypes'),
        to: path,
        iconName: 'Add',
      };
    }
    return null;
  }, []);

  return buttonProps;
};

export const useEditCommentButton = (editModeOn, authorByCurrentUser) => {
  const buttonProps = useMemo(() => {
    if (!authorByCurrentUser) {
      return null;
    }

    if (isAllowed('comments.update', authorByCurrentUser)) {
      return {
        key: 'editComment',
        label: t('general.edit'),
        onClick: editModeOn,
        iconName: 'Edit',
      };
    }

    return null;
  }, [editModeOn, authorByCurrentUser]);

  return buttonProps;
};

export const useDeleteCommentButton = (deleteComment, authorByCurrentUser, loggedMember) => {
  const buttonProps = useMemo(() => {
    if (!authorByCurrentUser || !loggedMember) {
      return null;
    }

    if (loggedMember.isOwner() || isAllowed('comments.destroy', authorByCurrentUser)) {
      return {
        key: 'deleteComment',
        label: t('general.delete'),
        onClick: deleteComment,
        iconName: 'Delete',
      };
    }

    return null;
  }, [authorByCurrentUser, loggedMember, deleteComment]);

  return buttonProps;
};

export const useEditPostButton = (editModeOn, authorByCurrentUser) => {
  const buttonProps = useMemo(() => {
    if (!authorByCurrentUser) {
      return null;
    }

    if (isAllowed('posts.update', authorByCurrentUser)) {
      return {
        key: 'editPost',
        label: t('general.edit'),
        onClick: editModeOn,
        iconName: 'Edit',
      };
    }

    return null;
  }, [editModeOn, authorByCurrentUser]);

  return buttonProps;
};

export const useDeletePostButton = (deletePost, post, loggedMember) => {
  const buttonProps = useMemo(() => {
    if (!post || !loggedMember) {
      return null;
    }

    if (loggedMember.isOwner() || isAllowed('comments.destroy', post.author)) {
      return {
        key: 'deleteComment',
        label: t('general.delete'),
        onClick: () => deletePost(post.id),
        iconName: 'Delete',
      };
    }

    return null;
  }, [deletePost, post, loggedMember]);

  return buttonProps;
};

export const useSendInvitationFormButton = onClick => {
  const buttonProps = useMemo(() => {
    if (isAllowed('farms.invite')) {
      return {
        key: 'sentInvitationForm',
        iconName: 'Add',
        onClick,
        label: t('invitationEmails.sentInvitation'),
      };
    }

    return null;
  }, [onClick]);

  return buttonProps;
};

export const useShowProfileButton = loggedMember => {
  const buttonProps = useMemo(() => {
    if (!loggedMember) {
      return null;
    }
    if (isAllowed('memberships.show', loggedMember && { id: loggedMember.id })) {
      return {
        key: 'showProfile',
        to: loggedMember.getProfileUrl(),
        label: t('menu.showProfile'),
      };
    }

    return null;
  }, [loggedMember]);

  return buttonProps;
};

export const useEditProfileButton = loggedMember => {
  const buttonProps = useMemo(() => {
    if (!loggedMember) {
      return null;
    }
    if (isAllowed('memberships.update', loggedMember && { id: loggedMember.id })) {
      return {
        key: 'editProfile',
        to: `/users/${loggedMember.id}/edit`,
        label: t('menu.editProfile'),
      };
    }

    return null;
  }, [loggedMember]);

  return buttonProps;
};

export const useChangeFarmButton = () => {
  return {
    key: 'changeFarm',
    onClick: () => {
      window.location.replace(`${window.location.protocol}//${window.location.host}`);
    },
    label: t('menu.changeFarm'),
  };
};

export const useLogoutButton = () => {
  return {
    key: 'logout',
    to: '/logout',
    label: t('menu.logout'),
  };
};

export const useSettingsButton = farmPolicy => {
  const buttonProps = useMemo(() => {
    if (getRoutePermission('/farm/edit')) {
      return {
        key: 'settings',
        label: t('farmDetails.settings'),
        onClick: () => store.dispatch(openModal('settings', { farmPolicy })),
        iconName: 'Settings',
      };
    }

    return null;
  }, [farmPolicy]);

  return buttonProps;
};
