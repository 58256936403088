/* eslint-disable max-len */
import React from 'react';

const Proposals = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M14.1,1.2h-0.7V0h-1.2v1.2H3.8V0H2.6v1.2H1.9C0.8,1.2,0,2.1,0,3.1l0,0v11.1c0,1,0.9,1.8,1.8,1.8h12.2c1,0,1.8-0.9,1.8-1.8
        v-11C16,2.1,15.2,1.2,14.1,1.2C14.1,1.2,14.1,1.2,14.1,1.2z M1.9,2.5h0.7v1.2h1.2V2.5h8.2v1.2h1.2V2.5h0.8c0.4,0,0.6,0.3,0.6,0.6
        v1.5H1.2V3.1C1.2,2.7,1.5,2.5,1.9,2.5L1.9,2.5z M14.1,14.7H1.9c-0.4,0-0.6-0.3-0.6-0.6V5.9h13.5v8.2C14.7,14.4,14.4,14.7,14.1,14.7
        L14.1,14.7z M7.9,6.9c1.2,0,2.3,0.6,2.3,1.8c0,1.2-1.4,1.7-1.7,2.1c-0.2,0.3-0.1,0.8-0.7,0.8c-0.4,0-0.6-0.3-0.6-0.6
        c0-1.1,1.6-1.4,1.6-2.2C8.8,8.3,8.5,8,8,8C6.8,8,7.2,9.3,6.4,9.3c-0.3,0-0.6-0.2-0.6-0.6C5.8,7.7,6.8,6.9,7.9,6.9L7.9,6.9z
        M7.8,12.2c0.4,0,0.7,0.4,0.7,0.7s-0.4,0.7-0.7,0.7c-0.4,0-0.7-0.4-0.7-0.7S7.4,12.2,7.8,12.2z"
      />
    </svg>
  );
};

export default Proposals;
/* eslint-enable max-len */
