/* eslint-disable max-len */
import React from 'react';

const CalendarChecked = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M13.6,14.4H2.4V5.6h11.2V14.4z M13.6,1.6h-0.8V0h-1.6v1.6H4.8V0H3.2v1.6H2.4l0,0c-0.9,0-1.6,0.7-1.6,1.6v11.2
        c0,0.9,0.7,1.6,1.6,1.6l0,0h11.2c0.9,0,1.6-0.7,1.6-1.6l0,0V3.2C15.2,2.3,14.5,1.6,13.6,1.6L13.6,1.6L13.6,1.6L13.6,1.6z M11.6,8.1
        l-0.8-0.9l-3.9,3.9L5.2,9.4l-0.9,0.9l2.6,2.5L11.6,8.1z"
      />
    </svg>
  );
};

export default CalendarChecked;
/* eslint-enable max-len */
