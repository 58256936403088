import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';

import Link from '@old/components/common/Link';
import NotificationsWidget from '@old/components/old/NotificationsWidget';
import MenuMobile from '@old/components/old/MenuMobile';
import ImageCircle from '@old/components/guide/ImageCircle';
import Dropdown from '@old/components/old/Dropdown';
import { pathIsAllowed, notify, getErrorMessage } from 'old/utils';
import { menuItemsNavbar } from '@old/menu';
import RowAligner from '@old/components/common/RowAligner';
import Button from '@old/components/guide/Button';
import {
  useShowProfileButton,
  useEditProfileButton,
  useChangeFarmButton,
  useLogoutButton,
  useMemberManagementButton,
} from 'old/buttonHooks';
import { useMemberStatus } from '@old/hooks';
import Model from '@old/model';
import t from 'resources/translations';
import Icon from '@old/components/icon';
import { Flex } from '@elements';
import { useAppStore, useFarm, useOldLoggedMember } from 'utils/storeUtils';
import { MemberService } from 'services';

const styleProps = {
  coinIcon: isBillingOpen =>
    isBillingOpen
      ? {
          p: 1,
          borderWidth: 1,
          borderColor: 'white',
          borderRadius: 9999,
        }
      : {},
};

const PageHeader = () => {
  const [member, setMember] = useState();
  const farm = useFarm();
  const loggedMember = useOldLoggedMember();
  const { isTablet, isMobile } = useAppStore();
  const isBillingOpen = !!useRouteMatch('/bills');
  const canOpenBillingPage = loggedMember?.isActive();
  useEffect(() => {
    if (loggedMember?.isActive()) {
      const fetch = async () => {
        try {
          const result = await MemberService.fetch(loggedMember.id);
          setMember(new Model.Member(result));
        } catch (e) {
          notify(getErrorMessage(e), { type: 'error' });
        }
      };
      fetch();
    }
  }, [loggedMember]);

  const status = useMemberStatus(member, loggedMember);

  const showProfileButton = useShowProfileButton(loggedMember);
  const editProfileButton = useEditProfileButton(loggedMember);
  const manageMemberButton = useMemberManagementButton(member, status, true, () => {}, t('menu.accountManage'));
  const changeFarmButton = useChangeFarmButton();
  const logoutButton = useLogoutButton();

  if (!loggedMember || !farm) return null;

  const userMenuTrigger = (
    <React.Fragment>
      <ImageCircle src={loggedMember.getAvatar('thumb')} small />
      <div className="px-4">{loggedMember.getName()}</div>
    </React.Fragment>
  );
  if (isMobile || isTablet) {
    return <MobileHeader loggedMember={loggedMember} farm={farm} />;
  }

  const buttons = [showProfileButton, editProfileButton, manageMemberButton, changeFarmButton, logoutButton];

  return (
    <header className="header">
      <div className="flex">
        <HeaderItemLink to="/farm">
          <RowAligner className="max-w-md">
            <ImageCircle src={farm.getAvatar('thumb')} small />
            <div className="truncate w-full">{farm.getName()}</div>
          </RowAligner>
        </HeaderItemLink>
      </div>
      <div className="flex">
        <HeaderItem>
          <NotificationsWidget />
        </HeaderItem>
        {canOpenBillingPage && (
          <HeaderItem>
            <div className="self-center">
              <Link to="/bills">
                <Flex {...styleProps.coinIcon(isBillingOpen)}>
                  <Icon.CoinCircle className="fill-white" />
                </Flex>
              </Link>
            </div>
          </HeaderItem>
        )}
        <div className="hm-header-item flex relative text-white">
          <Dropdown trigger={userMenuTrigger} fluid menuItems={buttons} />
        </div>
      </div>
    </header>
  );
};

export default PageHeader;

const MobileHeader = ({ loggedMember }) => {
  const isBillingOpen = !!useRouteMatch('/bills');
  const canOpenBillingPage = loggedMember?.isActive();
  const permittedMenuItems = items => items.filter(([_name, path]) => pathIsAllowed(path));

  return (
    <header className="header">
      <div className="flex">
        <HeaderItemLink to={`/users/${loggedMember.id}`}>
          <RowAligner>
            <ImageCircle src={loggedMember.getAvatar('thumb')} small />
          </RowAligner>
        </HeaderItemLink>
      </div>
      <div className="flex">
        <HeaderItem>
          <NotificationsWidget />
        </HeaderItem>
        {canOpenBillingPage && (
          <HeaderItem>
            <div className="self-center">
              <Link to="/bills">
                <Flex {...styleProps.coinIcon(isBillingOpen)}>
                  <Icon.CoinCircle className="fill-white" />
                </Flex>
              </Link>
            </div>
          </HeaderItem>
        )}
        <HeaderItem>
          <MenuMobile member={loggedMember} items={permittedMenuItems(menuItemsNavbar)} />
        </HeaderItem>
      </div>
    </header>
  );
};

const HeaderItem = ({ children }) => (
  <div className="flex">
    <div className="hm-header-item flex relative">{children}</div>
  </div>
);

HeaderItem.propTypes = {
  children: PropTypes.node.isRequired,
};

const HeaderItemButton = ({ children, onClick }) => (
  <div className="flex">
    <div className="hm-header-item flex relative">
      <Button onClick={onClick}>{children}</Button>
    </div>
  </div>
);

HeaderItemButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const HeaderItemLink = ({ children, to }) => (
  <div className="flex">
    <Link to={to} className="hm-header-item flex relative">
      {children}
    </Link>
  </div>
);

HeaderItemLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
