import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Flex, Stack, Text, Icons, Box, Grid, Ternary, Span, Link } from 'components/elements';
import { TFlexProps } from 'resources/types/elementsTypes';
import t from 'resources/translations';
import TicketOffer from 'models/TicketOffer';
import { Divider } from 'components/combinations';
import TicketOffersItem from './TicketOffersItem';
import { useAppStore, useFarm, useLoggedMember } from 'utils/storeUtils';

type TStyleProps = {
  container: TFlexProps;
};

const styles = {
  container: {
    flexDirection: 'column',
    bgColor: 'white',
    boxShadow: '0 2px 12px 0 rgba(37, 51, 66, 0.15)',
    radius: 8,
  },
} as TStyleProps;

type TTicketOffersProps = TFlexProps & {
  ticketOffers: TicketOffer[];
  isLoading: boolean;
};

const TicketOffers = ({ ticketOffers, isLoading, ...flexProps }: TTicketOffersProps) => {
  const { isMobile } = useAppStore();
  const loggedMember = useLoggedMember();
  const farm = useFarm();

  const isAddOnlinePayment = farm?.isAdvancedPaymentEnabled();
  const [isOpen, setIsOpen] = useState(true);
  const isEmptyTickets = _.isEmpty(ticketOffers);

  useEffect(() => {
    if (!isLoading && !isEmptyTickets) {
      setIsOpen(false);
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex {...styles.container} {...flexProps}>
      <Flex flexDirection="row" px="xl" py="md" justifyContent="space-between">
        <Stack alignItems="center" isHorizontal>
          <Icons.TicketCircle bgColor="grey-lighter-40" />
          <Text fontWeight="medium">{t('ticketOffers.ticketOffers')}</Text>
        </Stack>
        <Button variant="link" onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}>
          {isOpen ? <Icons.ArrowUp /> : <Icons.ArrowDown />}
        </Button>
      </Flex>
      {isOpen && (
        <>
          {isLoading ? (
            <Text my="md" textAlign="center" color="grey">
              {t('message.ticketOffersLoading')}
            </Text>
          ) : (
            <Ternary cond={isEmptyTickets}>
              <>
                <Divider orientation="vertical" size="1px" color="grey-lighter-50" />
                <Box textAlign="center" backgroundColor="grey-lighter-50" my="xl" p={['sm', 'md']}>
                  <Flex justifyContent="center" mb="md">
                    <Icons.MyTickets fill="teal-dark" squareSize={24} />
                  </Flex>
                  <Ternary cond={!loggedMember.isClient()}>
                    <Stack spacing="md">
                      <Text>{t('ticketOffers.notAdded')}</Text>
                      <Link fontSize="md" fontWeight="medium" to="/tickets/add">
                        {t('ticketOffers.AddFirstOffer')}
                      </Link>
                    </Stack>
                    <Box>
                      <Text>{t('ticketOffers.notFoundTickets')}</Text>
                    </Box>
                  </Ternary>
                </Box>
              </>
              <Stack px={['sm', 'md']}>
                <Box backgroundColor="grey-light" p={'md'}>
                  <Ternary cond={!loggedMember.isClient()}>
                    <Stack spacing="xl">
                      <Text>{t('ticketOffers.customersWillSee')}</Text>
                      <Stack isHorizontal alignItems="center" spacing="sm">
                        <Icons.CrossedEyeCircle bgColor="teal-dark" color="white" size="sm" />
                        <Text color="teal-dark">{t('ticketOffers.ticketMarked')}</Text>
                      </Stack>
                    </Stack>
                    <Ternary cond={isMobile}>
                      <Stack>
                        <Text>{t('ticketOffers.checkAvailable')}</Text>
                        {isAddOnlinePayment ? (
                          <Text>{t('ticketOffers.selectAndBuyTIcket')}</Text>
                        ) : (
                          <Text>{t('ticketOffers.contactAnEmployee')}</Text>
                        )}
                      </Stack>
                      <Text>
                        {`${t('ticketOffers.checkAvailable')} `}
                        <Ternary cond={isAddOnlinePayment}>
                          <Span>{t('ticketOffers.selectAndBuyTIcket')}</Span>
                          <Span>{t('ticketOffers.contactAnEmployee')}</Span>
                        </Ternary>
                      </Text>
                    </Ternary>
                  </Ternary>
                </Box>
                <Box py="sm">
                  <Grid gridTemplateColumns={['1fr', 'repeat(2, 1fr)']} gridGap="md">
                    {(ticketOffers || []).map((offer, index) => {
                      return <TicketOffersItem key={index} offer={offer} />;
                    })}
                  </Grid>
                </Box>
              </Stack>
            </Ternary>
          )}
        </>
      )}
    </Flex>
  );
};

export default TicketOffers;
