import React from 'react';
import PropTypes from 'prop-types';

const SubtitleWhite = ({ children }) => <div className="text-title-md-white">{children}</div>;

SubtitleWhite.propTypes = {
  children: PropTypes.string.isRequired,
};

export default SubtitleWhite;
