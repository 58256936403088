import React, { useRef } from 'react';
import cls from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import sum from 'lodash/sum';
import orderBy from 'lodash/orderBy';
import SimpleBar from 'simplebar-react';

import { openModal, closeModal } from 'store/actions';
import Modal from '@old/components/common/Modal';
import HMTable from '@old/components/old/Table';
import Spinner from '@old/components/common/Spinner';
import FlexRow from '@old/components/common/FlexRow';
import Divider from '@old/components/common/Divider';
import { formatDurationWithoutDays, parseToCurrencyString } from 'old/utils';
import RenderDate from '@old/components/old/RenderDate';
import t from 'resources/translations';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';
import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Center from '@old/components/common/Center';
import FlexColumn from '@old/components/common/FlexColumn';
import config from '@old/config';
import { useDisclosure } from '@old/hooks';

const EventsSummaryModal = ({ events, dateRange, farm, instructor, isMobile, loading }) => {
  const eventSummaryDisclosure = useDisclosure();
  const componentRef = useRef();
  return (
    <>
      <Modal large {...eventSummaryDisclosure}>
        <SimpleBar style={{ maxHeight: 'calc(100vh - 100px)' }}>
          <EventsSummaryHeader dateRange={dateRange} farm={farm} instructor={instructor} isMobile={isMobile} />
          {loading && <Spinner />}
          {!isMobile && !loading && <EventsSummaryContentDesktop events={events} />}
          {isMobile && !loading && <EventsSummaryContentMoblie events={events} />}
          <EventsSummaryFooter events={events} isMobile={isMobile} />
          <RowAligner className="justify-end">
            <ButtonOutline onClick={() => eventSummaryDisclosure.onClose()}>{t('general.cancel')}</ButtonOutline>
            <ReactToPrint
              bodyClass={isMobile ? 'm-6' : 'm-2'}
              content={() => componentRef.current}
              trigger={() => (
                <div>
                  <Button>{t('eventsSummaryModal.saveAsPDF')}</Button>
                </div>
              )}
            />
          </RowAligner>
        </SimpleBar>
      </Modal>
      <div className="hidden">
        <div ref={componentRef} className={cls(isMobile && 'padding-right-print')}>
          <EventsSummaryHeader dateRange={dateRange} farm={farm} instructor={instructor} isMobile={isMobile} />
          <EventsSummaryContentDesktop events={events} />
          <EventsSummaryFooter events={events} isMobile={isMobile} />
        </div>
      </div>
      <Center>
        <ButtonSimple onKeyPress={eventSummaryDisclosure.onOpen} onClick={eventSummaryDisclosure.onOpen}>
          <Icon.Bill className="fill-teal" big />
        </ButtonSimple>
      </Center>
    </>
  );
};

const mapStateToProps = ({ app: { farm, isMobile } }) => ({ farm, isMobile });

export default connect(mapStateToProps, { openModalByName: openModal, closeModalByName: closeModal })(
  EventsSummaryModal
);

const EventsSummaryHeader = ({ dateRange, farm, instructor, isMobile }) => {
  return (
    <div className="text-sm">
      <div
        style={{ backgroundImage: 'url(/img/horsemanago.png)', backgroundSize: '100px 100px' }}
        className="image-logo bg-no-repeat bg-top md:bg-right lg:bg-right md:text-left color-adjust"
      >
        {isMobile && <div style={{ height: '100px' }} />}
        <div className="text-lg font-bold">{t('eventsSummaryModal.summaryOf')}</div>
        <div className="text-lg mb-8 font-bold">
          <RenderDate dateFormat={config.dateFormat} startDate={dateRange.start} endDate={dateRange.end} />
        </div>
        <div className="text-link text-md md:pr-32 mb-4 padding-print">{farm?.getName()}</div>
        <div className="text-link mb-8 text-md">{instructor?.getName()}</div>
      </div>
    </div>
  );
};

const EventsSummaryFooter = ({ isMobile, events }) => {
  const finishedEvents = events.filter(event => event.isFinished).sort((a, b) => a.startDate.diff(b.startDate));
  const totalPaid = sum(finishedEvents.map(event => event.getTotalPaidAmount()));
  const unpaidCounter = sum(finishedEvents.map(event => (event.hasUnpaidParticipation() ? 1 : 0)));
  const totalTimeEvents = formatDurationWithoutDays(sum(finishedEvents.map(event => event.getDuration())));
  const totalTimeParticipations = formatDurationWithoutDays(
    sum(finishedEvents.map(event => event.getTotalParticipantsTime()))
  );

  const participationsCounter = sum(
    finishedEvents.map(event =>
      event.getNumberOfParticipations(participation => participation.attendance === 'attending')
    )
  );
  return (
    <div style={{ pageBreakInside: 'avoid' }} className="mb-2">
      <FlexColumn>
        <div
          className="float-right mt-8 text-lg text-left font-bold"
          style={{ borderBottom: '1px solid #333', padding: 3, minWidth: isMobile ? 100 : 400 }}
        >
          {t('eventsSummaryModal.summary')}
        </div>
        <div style={{ clear: 'both' }}>
          <div className="flex justify-end leading-loose text-sm md:text-md">
            <span className="mr-2">{t('message.eventsCount')}:</span>
            <span className="font-bold">{finishedEvents.length}</span>
          </div>
          <div className="flex justify-end leading-loose text-sm md:text-md">
            <span className="mr-2">{t('message.unpaid')}:</span>
            <span className="font-bold">{unpaidCounter}</span>
          </div>
          <div className="flex justify-end leading-loose text-sm md:text-md">
            <span className="mr-2">{t('message.totalPaid')}:</span>
            <span className="font-bold">{`${parseToCurrencyString(totalPaid)}`}</span>
          </div>
          <div className="flex justify-end leading-loose text-sm md:text-md">
            <span className="mr-2">{t('message.totalParticipationCounts')}:</span>
            <span className="font-bold">{participationsCounter}</span>
          </div>
          <div className="flex justify-end leading-loose text-sm md:text-md">
            <span className="mr-2">{t('message.totalHourEvents')}:</span>
            <span className="font-bold">{totalTimeEvents}</span>
          </div>
          <div className="flex justify-end leading-loose text-sm md:text-md">
            <span className="mr-2">{t('message.totalTimeParticipations')}:</span>
            <span className="font-bold">{totalTimeParticipations}</span>
          </div>
        </div>
      </FlexColumn>
    </div>
  );
};

const EventsSummaryContentDesktop = ({ events }) => {
  const mapToTableData = (event, index) => {
    const eventParticipations = orderBy(
      event.getParticipantsByAttendanceAndPaid('attending'),
      ['paidAmount', 'paymentStatus'],
      ['desc', 'desc']
    );

    return [
      index + 1,
      event.getName(),
      moment(event.startDate).format(config.dateFormat),
      event.getDuration(),
      eventParticipations.map((participation, i) => {
        const isAbsentParticipant = participation.attendance === 'absent';
        return (
          <FlexRow key={i} noMargin noShrink>
            <div className={cls({ 'line-through': isAbsentParticipant })}>
              {`${i + 1}. ${participation.member.getName(20)}`}
            </div>
            <div className="float-right">{parseToCurrencyString(participation.paidAmount || 0)}</div>
          </FlexRow>
        );
      }),
      eventParticipations.map(participation => {
        if (participation.paymentStatus === 'unpaid') {
          return null;
        }
        return <div key={participation.id}>{t(`paymentStatus.${participation.paymentStatus}`)}</div>;
      }),
      <div key={index}>{parseToCurrencyString(event.getTotalPaidAmount())}</div>,
    ];
  };

  const columns = [
    { header: t('eventsSummaryModal.ordinalNumber'), justify: 'center' },
    { header: t('general.name') },
    { header: t('general.date') },
    { header: t('eventsSummaryModal.time'), justify: 'center' },
    { header: t('eventsSummaryModal.payment'), singleLine: true },
    { header: t('eventsSummaryModal.method') },
    { header: t('eventsSummaryModal.sum') },
  ];

  const finishedEvents = events.filter(event => event.isFinished).sort((a, b) => a.startDate.diff(b.startDate));

  return <HMTable columns={columns} rows={finishedEvents.map(mapToTableData)} />;
};

const EventsSummaryContentMoblie = ({ events }) => {
  const finishedEvents = events.filter(event => event.isFinished).sort((a, b) => a.startDate.diff(b.startDate));

  return (
    <>
      <div className="font-bold">{t('model.events')}</div>
      <Divider />
      {finishedEvents.map((event, index) => {
        const eventParticipations = orderBy(
          event.getParticipantsByAttendanceAndPaid('attending'),
          ['paidAmount', 'paymentStatus'],
          ['desc', 'desc']
        );
        return (
          <React.Fragment key={index}>
            <div className="font-bold break-words">{`${index + 1}. ${event.getName()}`}</div>
            <FlexRow noShrink>
              <span>
                {t('general.date')}: {event.getStartDate()}
              </span>
              <span>
                {t('eventsSummaryModal.time')}: {event.getDuration()}
              </span>
            </FlexRow>
            <span>
              {t('eventsSummaryModal.type')}: {event.getTypeName()}
            </span>
            <div className="font-bold">{t('eventsSummaryModal.payments')}: </div>
            {eventParticipations.map((participation, participantIndex) => {
              const className = cls('flex justify-between', { 'bg-grey-light': participantIndex % 2 === 1 });
              return (
                <div className={className} key={participantIndex}>
                  <span>{`${participantIndex + 1}. ${participation.member.getName(18)}`}</span>
                  <span>
                    {participation.paymentStatus === 'unpaid' ? '' : t(`paymentStatus.${participation.paymentStatus}`)}
                    {` ${parseToCurrencyString(participation.paidAmount)}`}
                  </span>
                </div>
              );
            })}
            <div className="flex justify-end font-bold mt-4 mb-8">
              {`${t('eventsSummaryModal.sum')}: ${parseToCurrencyString(event.getTotalPaidAmount())}`}
            </div>
            <Divider />
          </React.Fragment>
        );
      })}
    </>
  );
};
