import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import t from 'resources/translations';
import RatingStars from '@old/components/old/RatingStars';
import FloatingButton from '@old/components/custom/FloatingButton';
import FlexColumn from '@old/components/common/FlexColumn';
import Message from '@old/components/common/Message';
import PageTitle from '@old/components/guide/PageTitle';
import RowAligner from '@old/components/common/RowAligner';
import Subtitle from '@old/components/common/Subtitle';
import RatingListItem from '@old/components/view/listItem/Rating';
import TitleList from '@old/components/common/TitleList';
import PagePagination from '@old/components/old/PagePagination';
import { useAddRateButton, useDeleteRateButton, useHideRateButton, useShowRateButton } from 'old/buttonHooks';

const RatingList = ({ modelItem, ratings, loading, pagKey }) => {
  const CurrentModel = modelItem && modelItem.model;
  const notDeleted = modelItem.modelName === 'farms' || (!isEmpty(modelItem) && !modelItem.isDeleted());
  const addRate = useAddRateButton(modelItem);
  const deleteRate = useDeleteRateButton(modelItem);
  const hideRate = useHideRateButton(modelItem);
  const showRate = useShowRateButton(modelItem);

  if (!modelItem || !CurrentModel) return false;

  const mapButtons = (arrButtons, ratingId) =>
    arrButtons
      .map(
        (button, index) =>
          button && {
            key: index,
            label: button.label,
            onClick: () => button.onClick(ratingId),
          }
      )
      .filter(Boolean);

  const pageTitle = `${t('ratingList.ratings')}: ${modelItem.getName()}`;

  return (
    <div>
      <PageTitle title={pageTitle} back loading={loading} />
      <FlexColumn>
        <TitleList>
          <RowAligner>
            <Subtitle>{t('ratingList.averageRatings')}:</Subtitle>
            <RatingStars rating={modelItem.rating} />
          </RowAligner>
        </TitleList>
        {!ratings.length && <Message info>{t('message.noRatings')}</Message>}
        {ratings.map(rating => {
          const toggleButton = rating.hidden ? showRate : hideRate;
          const contextMenuButtons = mapButtons([toggleButton, deleteRate], rating.id);
          return <RatingListItem key={rating.id} buttons={notDeleted && contextMenuButtons} rating={rating} />;
        })}
        <PagePagination paginationKey={pagKey} />
      </FlexColumn>
      <FloatingButton buttons={[notDeleted && addRate]} />
    </div>
  );
};

RatingList.defaultProps = {
  ratings: [],
  modelItem: null,
  pagKey: '',
};

const mapStateToProps = ({ fetchingData: { loading } }) => {
  return {
    loading: loading.ratings,
  };
};

export default connect(mapStateToProps, null)(RatingList);
