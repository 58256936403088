/* eslint-disable max-len */
import React from 'react';

const LinkAnchor = () => {
  return (
    <svg width="100%" viewBox="0 0 26 26">
      <g transform="translate(-494 -3921)">
        <g transform="translate(493.998 3921)">
          <path d="M24,11.8l-4.9,4.9c-2.6,2.7-7,2.8-9.7,0.1c-0.5-0.4-0.9-1-1.2-1.5l2.3-2.3c0.1-0.1,0.2-0.2,0.4-0.2
            c0.2,0.6,0.4,1.1,0.9,1.5c1.3,1.3,3.5,1.3,4.9,0l4.9-4.9c1.4-1.3,1.5-3.5,0.2-4.9S18.3,3,16.9,4.3c-0.1,0.1-0.1,0.1-0.2,0.2
            l-1.7,1.7c-1.4-0.5-2.9-0.7-4.4-0.5L14.2,2c2.7-2.7,7.1-2.6,9.8,0.1C26.6,4.8,26.6,9.1,24,11.8L24,11.8z M11.1,19.8l-1.7,1.7
            c-1.4,1.3-3.6,1.2-4.9-0.2c-1.2-1.3-1.2-3.3,0-4.6l4.9-4.9c1.3-1.3,3.5-1.3,4.9,0c0.4,0.4,0.7,0.9,0.9,1.5
            c0.1-0.1,0.3-0.2,0.4-0.3l2.3-2.3c-0.3-0.5-0.6-1-1-1.4c-2.7-2.7-7.1-2.7-9.8,0c0,0,0,0,0,0L2,14.2c-2.7,2.7-2.6,7.1,0.1,9.8
            c2.7,2.6,7,2.6,9.7,0l3.7-3.7C14,20.5,12.5,20.3,11.1,19.8z"
          />
        </g>
        <rect x="494" y="3921" width="26" height="26" style={{ fill: 'none' }} />
      </g>
    </svg>
  );
};

export default LinkAnchor;
/* eslint-enable max-len */
