/* eslint-disable max-len */
import React from 'react';

const EventLevel = () => {
  return (
    <svg
      viewBox="0 0 26 26"
      width="100%"
      className="icon"
    >
      <path d="M17.4,25.6l-2.5-5.4c2.9-0.5,5.5-2.2,7.2-4.6l3,6.4c0.1,0.3,0.1,0.6-0.1,0.8c-0.2,0.2-0.4,0.4-0.7,0.4
        c-0.1,0-0.2,0-0.3-0.1l-3.5-1.3L20.3,22L19,25.5c-0.1,0.3-0.4,0.6-0.8,0.6C17.9,26.1,17.6,25.9,17.4,25.6z M7.1,25.5L5.8,22
        l-0.3-0.1L2,23.1c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.2-0.7-0.4C0.9,22.5,0.9,22.2,1,22l3-6.4c1.7,2.4,4.3,4.1,7.2,4.6l-2.5,5.4
        c-0.1,0.3-0.4,0.5-0.8,0.5C7.5,26.1,7.2,25.8,7.1,25.5L7.1,25.5z M3.7,9.3C3.7,4.2,7.9,0,13.1,0c5.1,0,9.3,4.2,9.3,9.3
        c0,5.1-4.2,9.3-9.3,9.3C7.9,18.7,3.7,14.5,3.7,9.3L3.7,9.3z"
      />
    </svg>
  );
};

export default EventLevel;
/* eslint-enable max-len */
