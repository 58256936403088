/* eslint-disable max-len */
import React from 'react';

const Place = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,7.6C8,7.6,8,7.6,8,7.6c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2l0,0c0,0,0,0,0,0C10,6.7,9.1,7.6,8,7.6L8,7.6C8,7.6,8,7.6,8,7.6z M8,0C8,0,8,0,8,0C4.9,0,2.4,2.5,2.4,5.6c0,0,0,0,0,0v0C2.4,9.8,8,16,8,16s5.6-6.2,5.6-10.4c0,0,0,0,0,0C13.6,2.5,11.1,0,8,0C8,0,8,0,8,0L8,0L8,0z" />
    </svg>
  );
};

export default Place;
/* eslint-enable max-len */
