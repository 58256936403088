import { useState } from 'react';
import moment from 'moment';
import t from 'resources/translations';
import { getErrorMessage, notify, parseToCurrencyString } from 'old/utils';
import { Box, Button, Flex, Label, Stack, Text, TextTruncate } from 'components/elements';
import {
  AvatarWithLabel,
  Wrap,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  Select,
  ModalButtons,
} from 'components/combinations';
import { TStoreStateModals, TWrappedModal } from '.';
import { TCacheKey } from 'resources/types/cacheTypes';
import { usePermissions } from 'Permissions';
import Event from 'models/Event';
import { useMutation, useQueryClient } from 'react-query';
import { ParticipationService } from 'services';
import { PAYMENT_STATUS_COMPACT } from 'config/constans';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import { useShallowSelector } from 'old/hooks';
import { CSSObject } from '@styled-system/css';
import { isNumber } from 'lodash';
import PaymentTypesOption from 'components/combinations/select/PaymentTypesOption';

type TPaymentsMassiveModalProps = {
  event: Event;
  callback: Function;
  mutation: {
    keys: TCacheKey[];
    fn: Function;
  };
};

const PaymentsMassiveModal = ({ onSubmitAndClose, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const { event, mutation } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['PAYMENT_MASSIVE'].props
  ) as TPaymentsMassiveModalProps;
  const queryClient = useQueryClient();
  const [paymentType, setPaymentType] = useState(PAYMENT_STATUS_COMPACT[0]);
  const checkPayment = usePermissions('BILL_SET_PAYMENT');
  const participationsToPaid = event.getParticipationsByStatus('joined').filter(p => checkPayment(p.bill));

  const batchPayments = useMutation(
    async () => {
      return await ParticipationService.batchPayments(event.id, {
        participation: {
          ids: participationsToPaid.map(p => p.id),
          paid_at: moment().toISOString(),
          payment_status: paymentType.value,
        },
      });
    },
    {
      onSuccess: data => mutation.fn(queryClient, mutation.keys, data),
    }
  );

  const onSubmit = async () => {
    try {
      await batchPayments.mutateAsync();
      notify(t('message.succesSave'));
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };

  const totalAmount = participationsToPaid.reduce((prev, nextP) => prev + (nextP.bill?.getDue() || 0), 0);

  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{t('bills.customersPayment')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting}>
        <Flex flex="none" justifyContent="center" textAlign="center">
          <Wrap spacing="xs">
            {participationsToPaid.map(({ bill }) => {
              if (!bill) return null;
              if (!bill.billed) return <Text>{t('general.userDeleted')}</Text>;
              return (
                <AvatarWithLabel
                  key={bill.id}
                  squareSize={26}
                  src={bill.billed.getAvatar('medium')}
                  title={bill.billed.getFullName()}
                  alt={bill.billed.getFullName()}
                >
                  <Text>{bill.billed.getFullName()}</Text>
                </AvatarWithLabel>
              );
            })}
          </Wrap>
        </Flex>
        <Box mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" p="md">
          <Stack spacing="sm">
            <Text fontWeight="bold">{t('bills.event')}:</Text>
            <Text>{event.getStartDate('DD.MM.YYYY, dddd')}</Text>
            <TextTruncate fontSize="base" lines={2}>
              {event.getName()}
            </TextTruncate>
          </Stack>
        </Box>
        <Stack spacing="xs">
          <Label htmlFor="paymentType">{t('bills.paymentMethod')}</Label>
          <Box>
            <Select
              name="paymentType"
              value={paymentType}
              options={PAYMENT_STATUS_COMPACT.filter(payment => payment.value !== 'ticket')}
              onChange={setPaymentType}
              isSearchable={false}
              menuPortalTarget={document.body}
              maxMenuHeight={180}
              components={{ Option: PaymentTypesOption }}
              customStyles={{
                menuPortal: (base: CSSObject) => ({
                  ...base,
                  zIndex: 9999,
                  top: isNumber(base.top) ? base.top - 63 : 0,
                }),
              }}
            />
          </Box>
        </Stack>
        <Stack spacing="xs">
          <Flex alignItems="flex-end" justifyContent="space-between">
            <Text fontSize="sm">{t('labels.numberParticipants')}:</Text>
            <Text fontSize="sm">{participationsToPaid.length}</Text>
          </Flex>
          <Flex alignItems="flex-end" pt="base" pb="sm" justifyContent="space-between">
            <Text fontSize="md" fontWeight="bold">
              {t('bills.toPay')}:
            </Text>
            <Text fontSize="lg" fontWeight="bold" color="orange">
              {parseToCurrencyString(totalAmount)}
            </Text>
          </Flex>
        </Stack>
      </ModalContent>
      <ModalButtons>
        <Button onClick={() => onSubmitAndClose(onSubmit)}>
          {isSubmitting ? <SpinnerDots label={t('bills.addingPayment')} inline /> : t('bills.addPayment')}
        </Button>
        <Button onClick={onClose} variant="outline">
          {t('general.cancel')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default PaymentsMassiveModal;
