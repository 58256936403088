/**
 * @module TextEditor
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import cls from 'classnames';

import Icon from '@old/components/icon';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import RowAligner from '@old/components/common/RowAligner';

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const enableFormats = ['bold', 'italic', 'underline', 'blockquote', 'list', 'bullet'];

/*
 * Object that define text editor toolbar
 */
export const toolbarFormats = {
  text: [
    { type: 'bold', iconName: 'Bold' },
    { type: 'italic', iconName: 'Italic' },
  ],
  list: [
    { type: 'bullet', iconName: 'List' },
    { type: 'ordered', iconName: 'ListOrdered' },
  ],
};

/**
 * Component that generates rich text editor
 * @param  {String} [value]
 * @param  {Function} onChange Function called when the value of the input has changed
 * @param  {String} [placeholder]
 */

const TextEditor = ({ value, placeholder, onChange }) => {
  const editorRef = useRef();
  const [activeFormats, setActiveFormats] = useState({});

  const isFormatByType = (formatType) => {
    const editor = editorRef.current.getEditor();
    return editor.getFormat()[formatType];
  };

  const toggleTextFormat = (formatType) => {
    const editor = editorRef.current.getEditor();
    editor.format(formatType, !isFormatByType(formatType));
    setActiveFormatOnSelection(true, 'user');
  };

  const toggleListFormat = (listType) => {
    const editor = editorRef.current.getEditor();
    const newFormat = (isFormatByType('list') !== listType || !listType) ? listType : false;

    editor.format('list', newFormat);
    setActiveFormatOnSelection(true, 'user');
  };

  const setActiveFormatOnSelection = (range, source) => {
    if (editorRef && editorRef.current && range && source === 'user') {
      const formats = editorRef.current.getEditor().getFormat();
      const arrayOfFormats = [];
      toolbarFormats.text.forEach((format) => { arrayOfFormats[format.type] = formats[format.type]; });
      toolbarFormats.list.forEach((format) => { arrayOfFormats[format.type] = formats.list === format.type; });
      setActiveFormats(arrayOfFormats);
    }
  };

  return (
    <div className="text-editor">
      <CustomToolbar>
        {toolbarFormats.text.map(format => (
          <CustomToolbar.Button
            key={format.type}
            onClick={() => toggleTextFormat(format.type)}
            iconName={format.iconName}
            formatType={format.type}
            isActive={activeFormats[format.type]}
          />
        ))}
        {toolbarFormats.list.map(format => (
          <CustomToolbar.Button
            key={format.type}
            onClick={() => toggleListFormat(format.type)}
            iconName={format.iconName}
            formatType={format.type}
            isActive={activeFormats[format.type]}
          />
        ))}
      </CustomToolbar>
      <ReactQuill
        ref={editorRef}
        className="p-2 text-content"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        modules={{ toolbar: { container: '#toolbar' } }}
        formats={enableFormats}
        onChangeSelection={setActiveFormatOnSelection}
      />
    </div>
  );
};

TextEditor.defaultProps = {
  placeholder: '',
  value: '',
};

TextEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default TextEditor;

const CustomToolbarButton = ({ formatType, onClick, iconName, isActive }) => {
  const IconComponent = Icon[iconName];
  return (
    <ButtonSimple key={formatType} onClick={onClick}>
      <IconComponent
        className={cls('button-text-editor', { 'text-teal-light fill-teal-light': isActive })}
        small
      />
    </ButtonSimple>
  );
};

CustomToolbarButton.defaultProps = {
  isActive: false,
};

CustomToolbarButton.propTypes = {
  formatType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

const CustomToolbar = ({ children }) => {
  return (
    <div id="toolbar">
      <RowAligner seperated="small" className="justify-end">
        {children}
      </RowAligner>
    </div>
  );
};

CustomToolbar.Button = CustomToolbarButton;

CustomToolbar.propTypes = {
  children: PropTypes.node.isRequired,
};
