import { datesIsSame } from 'old/utils';
import { Box, Flex, Span, Stack, Text } from 'components/elements';
import { useAppSelector } from 'utils/storeUtils';
import { TEventsColorStatuses, TEventStatuses } from 'resources/types/eventsTypes';
import { TFlexProps } from 'resources/types/elementsTypes';
import { DATE_FORMAT } from 'config/constans';
import t from 'resources/translations';

type TStyles = {
  eventSqure: TFlexProps;
};

const styles = (myEventsMobile = false) => {
  return {
    eventSqure: {
      p: 'xs',
      color: 'white',
      position: 'relative',
      textAlign: 'center',
      alignItems: 'center',
      w: myEventsMobile ? 135 : 144,
      minW: myEventsMobile ? 'auto' : 144,
      h: myEventsMobile ? 'auto' : 144,
      maxW: myEventsMobile ? 135 : 144,
    },
  } as TStyles;
};

type TEventDateSquare = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  myEventsMobile: boolean;
  eventStatus: TEventStatuses;
  statusColor: TEventsColorStatuses;
};

const EventDateSquare = ({ startDate, endDate, eventStatus, myEventsMobile, statusColor }: TEventDateSquare) => {
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const renderDate = (
    <Box>
      {startDate.format(DATE_FORMAT)}
      {!datesIsSame(startDate, endDate) && (
        <>
          <Span> {'/'}</Span>
          <Text>{endDate.format(DATE_FORMAT)}</Text>
        </>
      )}
    </Box>
  );

  if (isMobile && !myEventsMobile) {
    return (
      <>
        <Text fontSize="md" fontWeight="bold">
          {startDate.format('HH:mm')} - {endDate.format('HH:mm')}
        </Text>
        {renderDate}
      </>
    );
  }

  return (
    <Flex bgColor={statusColor} justifyContent="center" {...styles(myEventsMobile).eventSqure}>
      <Stack spacing="sm">
        <Stack spacing="xs">
          <Text fontSize="md" fontWeight="bold">
            {startDate.format('HH:mm')} - {endDate.format('HH:mm')}
          </Text>
          {renderDate}
        </Stack>
        {/* @ts-ignore */}
        {!myEventsMobile && <Text>{t(`eventStatus.${eventStatus}`).toUpperCase()}</Text>}
      </Stack>
    </Flex>
  );
};

EventDateSquare.defaultProps = {
  eventStatus: null,
  myEventsMobile: false,
};

export default EventDateSquare;
