/* eslint-disable max-len */
import React from 'react';

const UncheckedOutline = () => {
  return (
    <svg width="100%" viewBox="0 0 26 26">
      <path d="M13,26A13,13,0,1,1,26,13,13,13,0,0,1,13,26ZM13,2A11,11,0,1,0,24,13,11,11,0,0,0,13,2Z" />
      <path d="M14.83,13,20,18.17,18.17,20,13,14.83,7.83,20,6,18.17,11.17,13,6,7.83,7.83,6,13,11.17,18.17,6,20,7.83Z" />
    </svg>
  );
};

export default UncheckedOutline;
/* eslint-enable max-len */
