/**
 * @module EmailInput
 */

import React from 'react';
import PropTypes from 'prop-types';

import InputWrapper from '@old/components/common/InputWrapper';

/**
 * Component that generate input adapted to email value
 * @param  {String} [props.value]
 * @param  {Function} props.onChange
 * @param  {String} [props.name]
 * @param  {String} [props.placeholder]
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 */
const EmailInput = ({ value, onChange, name, placeholder, label, required, ...props }) => {
  const onChangeEmail = e => onChange(e.target.value, name);
  return (
    <InputWrapper label={label} required={required}>
      <input
        type="email"
        name={name}
        value={value}
        onChange={onChangeEmail}
        placeholder={placeholder}
        onKeyDown={props.onKeyDown}
      />
    </InputWrapper>
  );
};

EmailInput.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  label: '',
  required: false,
  onKeyDown: () => false,
};

EmailInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

export default EmailInput;
