/**
 * @module ImageUploader
 */

import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import config from '@old/config';
import { notify, getErrorMessage } from 'old/utils';
import { openModal } from 'store/actions';
import InputWrapper from '@old/components/common/InputWrapper';
import Icon from '@old/components/icon';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import RowAligner from '@old/components/common/RowAligner';
import Center from '@old/components/common/Center';
import t from 'resources/translations';
import { useDisclosure } from '@old/hooks';
import Modal from '@old/components/common/Modal';
import FlexColumn from '@old/components/common/FlexColumn';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';

/**
 * @typedef {Object} CropperOptions
 * @property {Number} aspectRatio Define the initial aspect ratio of the crop box. By default, it is the same as the aspect ratio of the canvas (image wrapper)
 * @property {Number} wheelZoomRatio Define zoom ratio when zooming the image by wheeling mouse
 * @property {'crop'|'move'|'none'} dragMode Define the dragging mode of the cropper. 'crop': create a new crop box 'move': move the canvas 'none': do nothing
 *
 * @typedef {Object} DropzoneOptions
 * @property {String|Node} promptMessage prompt Message
 */

/**
 * Components that display input with possibility to upload a picture
 *
 * @param  {Function} props.openCropperModal
 * @param  {Function} props.onChange
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 * @param  {String} [props.value]
 * @param  {CropperOptions} [props.cropperOptions] custom cropper options more info: {@link https://www.npmjs.com/package/cropperjs}
 * @param  {DropzoneOptions} [props.dropzoneOptions] custom drop zone options more info: {@link https://www.npmjs.com/package/react-dropzone}
 */
const ImageUploader = ({ onChange, label, required, value, ...props }) => {
  const { promptMessage, ...dropzoneOptions } = props.dropzoneOptions;
  const [image, setImage] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const cropModalDisclosure = useDisclosure();
  const cropperRef = useRef();

  const onDrop = (acceptedImages) => {
    if (image) {
      // Revokes url data to avoid memory leaks
      URL.revokeObjectURL(image);
    }
    const img = document.createElement('img');
    const blob = URL.createObjectURL(acceptedImages[0]);
    img.src = blob;
    img.onload = () => {
      const w = img.width;
      const h = img.height;
      try {
        if (w > config.minSizeImage && h > config.minSizeImage) {
          setImage(URL.createObjectURL(acceptedImages[0]));
          cropModalDisclosure.onOpen();
          setIsUploaded(true);
        } else {
          notify(t('error.minSizeImage'), { type: 'error' });
        }
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      }
    };
  };

  const onSubmit = () => {
    const croppedImage = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL();
    if (croppedImage) {
      onChange(croppedImage);
      cropModalDisclosure.onClose();
    }
  };

  const zooming = (e) => {
    if (e.detail.ratio > 2) e.preventDefault();
    if (e.detail.ratio < 0.1) e.preventDefault();
  };

  const resetImage = () => {
    onChange('');
  };

  const renderPreview = () => (
    <RowAligner>
      <ButtonSimple onClick={isUploaded ? cropModalDisclosure.onOpen : () => { }}>
        <div className="dropzone relative">
          <img src={value} className="rounded" alt="current" />
        </div>
      </ButtonSimple>
      <div className="self-start">
        <ButtonSimple onClick={resetImage}>
          <Icon.Cancel circle small />
        </ButtonSimple>
      </div>
    </RowAligner>
  );

  return (
    <>
      <InputWrapper className="basic" label={label} required={required}>
        <div className="upload-wrapper">
          {!value && (
            <Dropzone
              onDrop={onDrop}
              multiple={false}
              accept="image/*"
              rejectClassName="rejected"
              acceptClassName="accepted"
              {...dropzoneOptions}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Center>{promptMessage}</Center>
                </section>
              )}
            </Dropzone>
          )}
          {value && renderPreview()}
        </div>
      </InputWrapper>
      {cropModalDisclosure.isOpen && (
        <Modal header={t('general.adjustPhoto')} {...cropModalDisclosure}>
          <FlexColumn>
            <Cropper
              ref={cropperRef}
              src={image}
              style={{ height: 400 }}
              minCropBoxWidth={200}
              minCropBoxHeight={200 / (props.cropperOptions.aspectRatio || 1)}
              zoom={e => zooming(e)}
              {...props.cropperOptions}
            />
            <RowAligner className="centered">
              <ButtonSimple onClick={() => cropperRef?.current?.cropper.zoom(-0.5)}>
                <Icon.ZoomOut className="fill-teal hover:fill-dark" />
              </ButtonSimple>
              <ButtonSimple onClick={() => cropperRef?.current?.cropper.zoom(0.5)}>
                <Icon.ZoomIn className="fill-teal hover:fill-dark" />
              </ButtonSimple>
            </RowAligner>
            <FlexRow noShrink>
              <ButtonOutline onClick={cropModalDisclosure.onClose} fluid>
                {t('general.cancel')}
              </ButtonOutline>
              <Button onClick={onSubmit} fluid>
                {t('general.addPicture')}
              </Button>
            </FlexRow>
          </FlexColumn>
        </Modal>
      )}
    </>
  );
};

ImageUploader.defaultProps = {
  label: '',
  value: '',
  required: false,
  cropperOptions: {
    aspectRatio: 1,
    dragMode: 'move',
    wheelZoomRatio: 0.3,
    guide: true,
  },
  dropzoneOptions: {
    promptMessage: t('general.dropFiles'),
  },
};

ImageUploader.propTypes = {
  dropzoneOptions: PropTypes.shape({
    promptMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  }),
  cropperOptions: PropTypes.shape({ // other props for cropper: https://www.npmjs.com/package/cropperjs
    aspectRatio: PropTypes.number,
    dragMode: PropTypes.oneOf(['crop', 'move', 'none']),
    wheelZoomRatio: PropTypes.number,
  }),
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  openCropperModal: PropTypes.func.isRequired,
};

export default connect(null, { openCropperModal: openModal })(ImageUploader);
