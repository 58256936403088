import React, { useState } from 'react';
import { Box } from '../../elements';
import { TBoxProps } from 'resources/types/elementsTypes';
import { TReactNode } from 'resources/types/commonTypes';

type TAccordian = TBoxProps & {
  children: TReactNode;
};

const Accordion = ({ children, ...boxProps }: TAccordian) => {
  const [expandedIndex, setExpanded] = useState(-1);

  const injectPropsToChildren = (child: TReactNode, index: number) => {
    const id = index % 2 !== 0 ? index - 1 : index;
    return React.cloneElement(child as React.ReactElement, { expandedIndex, index: id, setExpanded });
  };

  return (
    <Box w="full" {...boxProps}>
      {React.Children.map(children, injectPropsToChildren)}
    </Box>
  );
};

export default Accordion;
