/**
 * @module RatingInput
 */

import React from 'react';
import PropTypes from 'prop-types';
import Ratings from '@old/components/common/Rating';

import InputWrapper from '@old/components/common/InputWrapper';

/**
 * Component that display ratings stars
 * @param  {String} [props.value] value of input field
 * @param  {Function} props.changeRating function detects when the value of an input element changes and run function delegated in props
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 */
const RatingInput = ({ value, changeRating, label, required }) => {
  return (
    <InputWrapper className="basic" label={label} required={required}>
      <Ratings
        widgetSpacings="2px"
        widgetDimensions="20px"
        rating={value}
        changeRating={changeRating}
        widgetRatedColors="#00a0ad"
        widgetEmptyColors="transparent"
        widgetHoverColors="#005e65"
      >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
      </Ratings>
    </InputWrapper>
  );
};

RatingInput.defaultProps = {
  label: '',
  value: '',
  required: false,
};

RatingInput.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.number,
  label: PropTypes.string,
  changeRating: PropTypes.func.isRequired,
};

export default RatingInput;
