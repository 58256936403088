import React from 'react';
import PropTypes from 'prop-types';

const HMTable = ({ columns, rows }) => {
  const prepareCellStyle = (index, isHeaderCell = false) => {
    const { justify, singleLine } = columns[index];

    const cellStyles = {
      textAlign: justify || 'left',
      borderRight: index === columns.length - 1 ? 'none' : '1px solid #ccc',
      padding: '5px 2px',
      minWidth: '30px',
      maxWidth: '250px',
      overflowWrap: 'break-word',
    };

    if (!isHeaderCell) {
      return {
        borderTop: '1px solid #ccc',
        verticalAlign: 'top',
        whiteSpace: singleLine ? 'nowrap' : 'normal',
        ...cellStyles,
      };
    }

    return cellStyles;
  };

  if (rows.length === 0) {
    return null;
  }

  return (
    <table className="w-full">
      <thead>
        <tr>
          {columns.map(({ header }, index) => (
            <th key={index} style={prepareCellStyle(index, true /* isHeaderCell */)}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => {
          return (
            <tr
              key={index}
              className="color-adjust"
              style={{ background: index % 2 === 1 ? '#efefef' : '#fff' }}
            >
              {row.map((cellContent, cellIndex) => (
                <td style={prepareCellStyle(cellIndex)} key={cellIndex}>
                  {cellContent}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

HMTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      justify: PropTypes.oneOf(['left', 'center', 'right']),
      singleLine: PropTypes.bool,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
};

export default HMTable;
