import React from 'react';
import moment from 'moment';
import t from 'resources/translations';
import Event from 'models/Event';
import Participation from 'models/Participation';
import { datesIsSame, parseToCurrencyString } from 'old/utils';
import { Flex, Link, Span, Stack, Ternary, Text, Icons, Button, Box } from 'components/elements';
import { useAppSelector, useLoggedMember } from 'utils';
import { TFlexProps } from 'resources/types/elementsTypes';
import { TStackProps } from 'resources/types/elementsTypes';
import { usePermissions } from 'Permissions';
import { ILinkStyledProps } from 'resources/types/styledTypes';
import { DATE_TIME_FORMAT } from 'config/constans';
import { useModal } from 'old/hooks';

type TStyles = {
  containerDivider: object;
  infoContainer: TStackProps;
  eventName: ILinkStyledProps;
  placeIcon: TFlexProps;
};

const styles = (isSingleMode = false) => {
  return {
    containerDivider: {
      color: 'new-grey-light-30',
      margin: isSingleMode ? '2xl' : 'md',
    },
    infoContainer: {
      flex: 7,
      mx: isSingleMode ? 'lg' : 0,
    },
    placeIcon: {
      squareSize: 28,
      radius: 9999,
      justifyContent: 'center',
      alignItems: 'center',
    },
    eventName: {
      fontSize: 'md',
      color: 'teal-light',
      fontWeight: 'medium',
      overflow: 'hidden',
      maxW: '100%',
      display: '-webkit-box',
      lineHeight: '1.625',
      lineClamp: 3,
      boxOrient: 'vertical',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
    },
  } as TStyles;
};

const ActiveEventInfo = ({ event, isSingleMode }: { event: Event; isSingleMode: boolean }) => {
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const loggedMember = useLoggedMember();
  const canIAddPayment = usePermissions('BILL_ADD_PAYMENT');
  const modalPayment = useModal('PAYMENT_ONLINE');
  const isOneDayEvent = datesIsSame(
    moment(event.getStartDate(), DATE_TIME_FORMAT),
    moment(event.getEndDate(), DATE_TIME_FORMAT)
  );

  const onOpenPaymenModal = (participation: Participation) =>
    modalPayment.onOpen({
      bill: participation.bill,
    });

  const isClientMode = loggedMember?.isClient();
  const loggedClientParticipation = event.getParticipations().find(p => p.member.id === loggedMember?.id);

  return (
    <Stack>
      <Stack {...styles().infoContainer}>
        <Flex>
          <Link to={event.getUrl()} {...styles(isSingleMode).eventName}>
            {event.getName().toUpperCase()}
          </Link>
        </Flex>
        <Box mx={-30} backgroundColor="new-grey-light-20" width="calc(100% + 60px)" px="xl" py="sm">
          <Stack isHorizontal={isSingleMode} spacing={isSingleMode ? '2xl' : 'sm'}>
            <Stack spacing="sm" isHorizontal>
              <Flex flex="0" alignItems="center">
                <Icons.Time />
              </Flex>
              <Stack>
                <Text mb="xs" fontWeight="medium">
                  {`${event.getStartDate('HH:mm')} - ${event.getEndDate('HH:mm')}`}
                </Text>
                <Ternary cond={isOneDayEvent}>
                  <Text fontSize="sm">
                    <Span textTransform="capitalize">{event.getStartDate('dddd, DD.MM.YYYY')} </Span>
                  </Text>
                  <Flex flexDirection="column">
                    <Text fontSize="sm">
                      {`${t('general.from')}: `}
                      <Span textTransform="capitalize">{event.getStartDate('dddd, DD.MM.YYYY')}</Span>
                    </Text>
                    <Text fontSize="sm">
                      {`${t('general.to')}: `}
                      <Span textTransform="capitalize">{event.getEndDate('dddd, DD.MM.YYYY')}</Span>
                    </Text>
                  </Flex>
                </Ternary>
              </Stack>
            </Stack>
            <Stack isHorizontal alignItems="center" spacing="sm">
              <Flex flex="0">
                <Icons.PlaceWithoutFilling />
              </Flex>
              <Link to={`/places/${event.getPlace().id}`}>{event.getPlace().getName()}</Link>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      {isClientMode && (
        <Stack isHorizontal={!isMobile} alignItems="baseline">
          <Stack width="100%" isHorizontal spacing="sm" alignItems="baseline">
            <Flex flex={isMobile ? 9 : 0}>
              <Text fontSize="md" fontWeight="medium">{`${t('dashboard.due')}: `}</Text>
            </Flex>
            <Flex>
              <Text fontSize="md" color="orange" fontWeight="regular">
                {parseToCurrencyString(event.type.defaultEventCost)}
              </Text>
            </Flex>
          </Stack>
          {loggedClientParticipation && canIAddPayment(loggedClientParticipation?.bill) && (
            <Button
              width={isMobile ? '100%' : 'auto'}
              onClick={() => onOpenPaymenModal(loggedClientParticipation)}
              px="md"
              fontSize="base"
              whiteSpace="nowrap"
            >
              {t('buttons.pay')}
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};

ActiveEventInfo.defaultProps = {
  isSingleMode: false,
};

export default React.memo(ActiveEventInfo);
