import { Box, Button, Flex, MotionBox } from 'components/elements';
import theme from 'config/theme';
import { TBoxProps } from 'resources/types/elementsTypes';
import { css } from 'styled-components';

const styleProps = {
  tab: (isActive: boolean) => ({
    fontSize: 'base',
    py: 'sm',
    width: '100%',
    fontWeight: isActive ? 'medium' : 'regular',
  }),
  tabIndicator: {
    bgColor: 'teal-dark',
    borderRadius: 8,
    h: 5,
    position: 'absolute',
  },
} as {
  tab: (isActive: boolean) => TBoxProps;
  tabIndicator: TBoxProps;
};

type TTabs = {
  tabs: {
    [key: string]: {
      label: string;
      onClick?: (key: string | number, index: number) => void;
    };
  };
} & {
  activeTab: string;
};

const Tabs = ({ tabs, activeTab }: TTabs) => {
  const tabKeys = Object.keys(tabs);
  const index = tabKeys.findIndex(key => key === activeTab);
  const tabsLength = 100 / tabKeys.length;

  const onClickTab = (key: string | number, i: number, cb?: (key: string | number, index: number) => void) => {
    if (cb) {
      cb(key, i);
    }
  };

  return (
    <Box w="full">
      <Flex>
        {Object.keys(tabs).map((tabKey, i) => {
          const isActive = tabKey === activeTab;
          return (
            <Flex key={`${tabKey}-${i}`}>
              <Button
                onClick={() => onClickTab(tabKey, i, tabs[tabKey].onClick)}
                variant="link"
                {...styleProps.tab(isActive)}
                css={css({
                  color: isActive ? theme.colors['teal-dark'] : theme.colors.teal,
                })}
              >
                {tabs[tabKey].label}
              </Button>
            </Flex>
          );
        })}
      </Flex>
      <Box w="full" position="relative" h={5} bgColor="new-grey-light-70">
        <MotionBox
          transition={{ ease: 'easeOut', duration: 0.2 }}
          animate={{ left: `${tabsLength * index}%` }}
          w={`${tabsLength}%`}
          {...styleProps.tabIndicator}
        />
      </Box>
    </Box>
  );
};

export default Tabs;
