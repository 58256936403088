/* eslint-disable */
import React from 'react';

const AddUser = () => {
  return (
    <svg
      viewBox="0 0 26 26"
      width="100%"
      className="icon"
    >
      <polygon points="7.3,12 10.5,12 10.5,13.6 7.3,13.6 7.3,16.8 5.7,16.8 5.7,13.6 2.5,13.6 2.5,12 5.7,12 5.7,8.8 
        7.3,8.8 "/>
      <path d="M16,1.9C16,0.8,16.9,0,17.9,0s1.9,0.8,1.9,1.9c0,1.1-0.9,1.9-1.9,1.9S16,3,16,1.9z"/>
      <path d="M23.4,12.8c-0.1,1.4-2.3,1.5-2.2,0c0.2-1.6,0-3.1-0.7-4.6v5.2c0,3.8,0,7.5,0.2,11.3c0,0.7-0.6,1.3-1.3,1.3
        c-0.7,0-1.3-0.6-1.3-1.3c-0.1-2.9-0.1-5.9-0.2-8.8h-0.4c0,3,0,5.9-0.2,8.8c0,0.6-0.5,1.2-1.2,1.2c-0.7,0-1.3-0.5-1.3-1.2
        C15,20.9,15,17.2,15,13.4c0,0,0,0,0-0.1c0,0,0-0.1,0-0.2V8.4c-0.5,1.4-0.7,2.9-0.5,4.3c0.1,1.5-2,1.4-2.2,0C12,9.4,13.2,5,16.8,4.6
        c0.6-0.1,1.1-0.1,1.7,0C22.5,5,23.8,9.5,23.4,12.8z" />
    </svg>
  );
};

export default AddUser;
/* eslint-enable */
