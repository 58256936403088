import { Card, CardHeader } from 'components/combinations';
import { Icons, Stack, Text } from 'components/elements';
import PagePagination from 'components/modules/PagePagination';
import Bill from 'models/Bill';
import t from 'resources/translations';
import { memo } from 'react';
import { APIPagination } from 'resources/types/commonTypes';
import { IStack } from 'resources/types/elementsTypes';
import BillClientStatementItemMobile from './BillClientStatementItemMobile';

const BillClientStatement = ({
  bills,
  pagination,
  ...stackProps
}: {
  bills: Bill[];
  pagination?: APIPagination;
  stackProps?: IStack;
}) => {
  return (
    <>
      <Stack {...stackProps}>
        <Card>
          <CardHeader icon={<Icons.CircleChartCircle fill="grey-dark-80" bgColor="grey-lighter-50" />}>
            <Text>{t('bills.statement')}</Text>
          </CardHeader>
        </Card>
        {(bills || []).map(bill => (
          <BillClientStatementItemMobile key={bill.id} bill={bill} />
        ))}
        {pagination && <PagePagination pagination={pagination} />}
      </Stack>
    </>
  );
};

export default memo(BillClientStatement);
