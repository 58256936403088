/**
 * @module GroupButton
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
/**
 * Components that connects multiple buttons in one block
 * @param  {String|Node} [props.children]
 * @param  {Boolean} [props.vertical] specifies wether the children should be positioned vertically
 * @param  {Boolean} [props.small] specifies wether the button has a small paddings
 */
const GroupButton = ({ children, vertical, small }) => (
  <div className={cls('group-btn', { vertical, small })}>
    {children}
  </div>
);

GroupButton.defaultProps = {
  vertical: false,
  children: null,
  small: false,
};

GroupButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  vertical: PropTypes.bool,
  small: PropTypes.bool,
};

export default GroupButton;
