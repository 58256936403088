import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Redirect } from 'react-router';

import Button from '@old/components/guide/Button';
import BoxShadow from '@old/components/common/BoxShadow';
import Center from '@old/components/common/Center';
import BoxWhite from '@old/components/common/BoxWhite';
import FlexColumn from '@old/components/common/FlexColumn';
import { LogoHmNotextIcon } from 'old/assets/svg';
import { getErrorMessage, notify } from 'old/utils';
import Model from '@old/model';
import t from 'resources/translations';
import FormButton from '@old/components/common/FormButton';
import { FORM_NEW_PASSWORD } from 'old/constants/formNames';
import Field from '@old/components/field';
import Form from '@old/components/common/Form';

const NewPassword = ({ location, password, passwordConfirmation, history }) => {
  const [resetPasswordDone, setResetPasswordDone] = useState(false);

  const [resetPasswordToken] = useMemo(
    () => [queryString.parse(location.search).reset_password_token || null],
    [location.search]
  );

  const onSubmit = async () => {
    const user = {
      password,
      reset_password_token: resetPasswordToken,
      password_confirmation: passwordConfirmation,
    };

    try {
      await Model.Members.changePassword({ user });
      notify(t('message.changePassword'));
      setResetPasswordDone(true);
    } catch (e) {
      const errors = e.response.data;
      const errorBirthday = 'birthday';
      const userNotExists = Object.keys(errors).some(errorKey => errorBirthday.includes(errorKey));
      if (userNotExists) {
        notify(t('error.incompleteRegistration'), { type: 'error' });
      } else {
        notify(getErrorMessage(e), { type: 'error' });
      }
    }
  };

  const validations = {
    password: [
      {
        condition: (value, formState) => {
          if (!value) return false;
          return value === formState.password.value;
        },
        errorHint: t('error.passwordsMustBeSame'),
      },
    ],
  };
  const redirectToLoginPage = async () => {
    history.push('/');
  };

  if (!resetPasswordToken) {
    return <Redirect to="/" />;
  }

  const initValues = {
    password: '',
    passwordConfirmation: '',
  };

  return (
    <BoxShadow className="page-new-password">
      <div className="login-banner">
        <LogoHmNotextIcon width={120} height={120} />
      </div>
      <BoxWhite padding>
        <FlexColumn>
          <Center>
            <h1 className="text-headline">{t('newPassword.remindPassword')}</h1>
          </Center>
          {!resetPasswordDone && (
            <Form formName={FORM_NEW_PASSWORD} initValues={initValues} noPadding>
              <Field.Password
                name="password"
                label={t('labels.password')}
                placeholder={t('placeholders.password')}
                required
              />
              <Field.Password
                name="passwordConfirmation"
                label={t('labels.confirmPassword')}
                placeholder={t('placeholders.repeatPassword')}
                validation={validations.password}
                required
              />
              <FormButton formName={FORM_NEW_PASSWORD} onClick={onSubmit} fluid>
                {t('labels.changePassword')}
              </FormButton>
            </Form>
          )}
          {resetPasswordDone && (
            <FlexColumn>
              <Center>{t('newPassword.changePassword')}</Center>
              <Button onClick={redirectToLoginPage} fluid>
                {t('newPassword.login')}
              </Button>
            </FlexColumn>
          )}
        </FlexColumn>
      </BoxWhite>
    </BoxShadow>
  );
};

NewPassword.defaultProps = {
  location: {},
  password: '',
  passwordConfirmation: '',
};

NewPassword.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  password: PropTypes.string,
  passwordConfirmation: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = ({ form }) => {
  const formValues = form[FORM_NEW_PASSWORD];
  return {
    password: formValues && formValues.password && formValues.password.value,
    passwordConfirmation: formValues && formValues.passwordConfirmation && formValues.passwordConfirmation.value,
  };
};

export default connect(mapStateToProps)(NewPassword);
