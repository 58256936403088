import { Text } from 'components/elements';
import { baseStyledProps, blockStyleProps, typographyStyleProps } from 'config/styledProps';
import { TFlexProps, TTextProps } from 'resources/types/elementsTypes';
import styled from 'styled-components';
import { flexbox } from 'styled-system';

const Field = styled('div')<TFlexProps>(baseStyledProps, typographyStyleProps, blockStyleProps, flexbox, {
  display: 'flex',
  flexDirection: 'column',
});

type TFormField = {
  children: JSX.Element | JSX.Element[];
  errors?: string | string[];
  hideErrorsBlock?: boolean;
  errorStyleProps?: TTextProps;
} & TFlexProps;

const FormField = ({
  children,
  errors,
  hideErrorsBlock = false,
  errorStyleProps = {},
  ...flexStyleProps
}: TFormField) => {
  const msgErrors = Array.isArray(errors) ? errors : [errors];
  return (
    <Field {...flexStyleProps} flex={flexStyleProps.flex || 1}>
      {children}
      {!hideErrorsBlock &&
        msgErrors.map(error => (
          <Text fontSize="sm" minH={26} mt="xs" color="orange" {...errorStyleProps}>
            {error}
          </Text>
        ))}
    </Field>
  );
};

export default FormField;
