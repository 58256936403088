type TTernary = {
  children: JSX.Element[];
  cond: boolean;
};
const Ternary = ({ cond, children }: TTernary) => {
  if (children?.length !== 2) {
    return null;
  }

  if (cond) {
    return children[0];
  }
  return children[1];
};

export default Ternary;
