/* eslint-disable max-len */
import React from 'react';

const ListOrdered = () => {
  return (
    <svg viewBox="0 0 26 26" width="100%">
      <rect x="6.3" y="19.8" width="19.7" height="2.8" />
      <rect x="6.3" y="3" width="19.7" height="2.8" />
      <rect x="6.3" y="11.4" width="19.7" height="2.8" />
      <path d="M0.1,5.5h1V3.3H0.3V2.8c0.5-0.1,0.7-0.2,1-0.4H2v3.1h0.9v0.7H0.1V5.5z" />
      <path d="M0.1,13.9c1.1-0.9,1.8-1.6,1.8-2.1c0-0.4-0.2-0.6-0.6-0.6c-0.3,0-0.6,0.2-0.8,0.4L0,11.1c0.4-0.4,0.8-0.6,1.4-0.6
        c0.8,0,1.3,0.5,1.3,1.2c0,0.6-0.7,1.3-1.4,2c0.2,0,0.5,0,0.7,0h0.9v0.7H0.1V13.9z"
      />
      <path d="M0,22.6L0.4,22c0.3,0.2,0.6,0.4,0.9,0.4c0.4,0,0.7-0.2,0.7-0.5s-0.3-0.5-1.1-0.5v-0.6c0.7,0,1-0.2,1-0.5s-0.2-0.4-0.6-0.4
        c-0.3,0-0.5,0.1-0.8,0.3l-0.4-0.5c0.4-0.3,0.8-0.5,1.3-0.5c0.8,0,1.4,0.4,1.4,1c0,0.4-0.3,0.7-0.7,0.8l0,0c0.5,0.1,0.8,0.4,0.8,0.9
        C2.8,23.6,0.5,23.2,0,22.6z"
      />
    </svg>
  );
};

export default ListOrdered;
/* eslint-enable max-len */
