import i18n from 'i18n-js';
import moment from 'moment';
import 'moment/locale/pl';

import PolishTranslations from './pl.json';
import EnglishTranslations from './en.json';
const dict = {
  ...PolishTranslations,
} as const; // Alternative: leave out `as const`/declare the type explicitely

i18n.translations = {
  pl: PolishTranslations,
  en: EnglishTranslations,
};

i18n.defaultLocale = 'pl';

i18n.locale = 'pl'; // FIXME
// if (process.env.REACT_APP_SERVER !== 'production') {
//   i18n.missingTranslation = test => console.log(test); // eslint-disable-line no-console
// }
i18n.pluralization.pl = (count: number) => {
  const mod10 = count % 10;
  const mod100 = count % 100;
  if (count === 0) return ['zero'];
  else if (count === 1) return ['one'];
  else if (mod10 >= 2 && mod10 <= 4 && (mod100 < 10 || mod100 > 20)) return ['few'];
  else return ['many'];
};

i18n.pluralization.en = (count: number) => {
  if (count === 0) return ['zero'];
  else if (count === 1) return ['one'];
  else if (count >= 2) return ['few'];
  else return ['many'];
};

moment.updateLocale('pl', {
  week: {
    dow: 1,
    doy: 1,
  },
});

type DeepKeys<T, S extends string> = T extends object
  ? S extends `${infer I1}.${infer I2}`
    ? I1 extends keyof T
      ? `${I1}.${DeepKeys<T[I1], I2>}`
      : keyof T & string
    : S extends keyof T
    ? `${S}`
    : keyof T & string
  : '';

const t = <S extends string>(scope: DeepKeys<typeof dict, S>, option?: any) => i18n.t(scope, option) as string;

export default t;
