import { useEffect, useState } from 'react';
import t from 'resources/translations';
import queryString from 'query-string';
import Model from '@old/model';
import { useHistory, useLocation } from 'react-router';
import { redirectTo } from 'old/utils';
import { Box, Button, Icons, Flex, Stack, Text } from '@elements';
import { Divider, Modal, ModalContent } from '@combinations';
import Spinner from '@old/components/common/Spinner';
import { compact } from 'lodash';
import { ModalButtons } from 'components/combinations';

const PaymentSuccess = () => {
  const [redirectPathname, setRedirectPathname] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const fetchStatus = async () => {
      const urlParams = queryString.parse(location.search);
      setLoading(true);
      try {
        const { farm_id } = await Model.Bills.paymentStatus(urlParams);
        const result = await Model.Farms.fetchBySlugname(farm_id);
        const farm = compact(result)[0];
        const pathname = Array.isArray(farm) ? `/${farm[0]?.slug}/bills` : `${farm?.slug}/bills`;
        setRedirectPathname(pathname);
      } catch (e) {
        history.replace('/');
      }
      setLoading(false);
    };
    fetchStatus();
  }, [history, location.search]);

  if (isLoading) return <Spinner />;
  return (
    <Modal isOpen isHidden={false} onClose={() => {}}>
      <ModalContent>
        <Flex justifyContent="center" textAlign="center" mb="sm" mt={['md', 'xl']}>
          <Stack spacing="sm" alignItems="center">
            <Icons.PaymentVerification size={60} fill="teal-dark" />
            <Text fontWeight="bold" fontSize={24} color="teal-dark">
              {t('paymentSuccessModal.PaymentStatusVerification')}
            </Text>
          </Stack>
        </Flex>
        <Divider variant="dashed" color="grey" margin={['xs', 'md']} />
        <Box mb={['sm', 'xl']} mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" p="xl">
          <Stack spacing="xl" textAlign="center">
            <Text fontSize="md">{t('paymentSuccessModal.verifyingPayment')}.</Text>
            <Text lineHeight="1.8" fontSize="md" fontWeight="medium">
              {t('paymentSuccessModal.checkedDetailsTransaction')}.
            </Text>
          </Stack>
        </Box>
        <Divider variant="dashed" color="grey" margin={['xs', 'md']} />
      </ModalContent>
      <ModalButtons>
        <Button onClick={() => redirectTo(redirectPathname)}>{t('paymentSuccessModal.goToApplication')}</Button>
      </ModalButtons>
    </Modal>
  );
};

export default PaymentSuccess;
