import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changeFieldState } from 'store/actions';
import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';

const CheckboxField = ({ onChangeField, label, ...props }) => {
  const { formName, name, value, required } = props;

  const onChange = (isChecked) => {
    onChangeField({ formName, fieldName: name, fieldState: isChecked });
  };

  return (
    <FormField {...props}>
      <Input.Checkbox onChange={onChange} label={label} name={name} value={value} required={required} />
    </FormField>
  );
};

CheckboxField.defaultProps = {
  value: null,
  name: null,
  required: false,
};

CheckboxField.propTypes = {
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
};

const mapStateToProps = ({ form }, props) => {
  return {
    value: form[props.formName][props.name].value,
  };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(CheckboxField);
