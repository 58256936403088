/* eslint-disable max-len */
import React from 'react';

const Info = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <circle cx="12.9" cy="3.3" r="3.3" />
      <path d="M13,8.7c-1.9,0-3.5,1.6-3.5,3.5v10.3c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5V12.2C16.5,10.3,15,8.7,13,8.7z" />
    </svg>
  );
};

export default Info;
/* eslint-enable max-len */
