import moment from 'moment';
import truncate from 'lodash/truncate';
// import { Converter } from 'showdown';

import BaseModel from '@old/model/BaseModel';
import { Member } from '@old/model/Member';
import { parseHTMLToMarkdown, parseMarkdownToHTML } from 'old/utils/Common';

class News extends BaseModel {
  constructor(options) {
    super({
      modelName: 'news',
      updateKey: 'news_item',
      basePath: '/api/v1/farms/{farmId}/news',
      ItemClass: NewsItem,
      ...options,
    });
  }

  // override BaseModel.fetchAll()
  fetchAll = async ({ drafts, cancelToken, ...params }) => {
    if (drafts) {
      return this.fetchDrafts(params, cancelToken);
    }

    const response = await this.client.get(this.basePath, { cancelToken, params });
    return [response.data.news.map(item => this.parse(item)), response.data.pagination];
  };

  fetchDrafts = async (params, cancelToken) => {
    const response = await this.client.get(`${this.basePath}/drafts`, { cancelToken, params });

    return [response.data.news.map(item => this.parse(item)), response.data.pagination];
  };

  // override BaseModel.create()
  create = async (newItem, params) => {
    const response = await this.client.post(this.basePath, { news_item: newItem, ...params });
    return this.parse(response.data);
  };

  // override BaseModel.update()
  update = async (itemId, changes, params) => {
    const response = await this.client.patch(`${this.basePath}/${itemId}`, { news_item: changes, ...params });
    return this.parse(response.data);
  };

  publish = async (newsId) => {
    const response = await this.client.patch(`${this.basePath}/${newsId}/publish`);
    return this.parse(response.data);
  };
}

class NewsItem {
  constructor(data) {
    this.id = data.id;
    this.title = data.title;
    this.createdAt = moment(data.created_at);
    this.updatedAt = moment(data.updated_at);
    this.publishedAt = moment(data.published_at);
    this.content = data.content;
    this.author = new Member(data.author);
    this.image = data.image;
    this.status = data.status;
    this.privileges = data.meta ? data.meta.privileges : [];
  }

  static mapToSaveData = (data) => {
    const newsItem = {
      title: data.newsTitle,
      content: parseHTMLToMarkdown(data.content),
      image_attributes: {
        file: data.image,
      },
    };

    Object.keys(newsItem).forEach((fieldName) => {
      if (newsItem[fieldName] === undefined) delete newsItem[fieldName];
    });

    if (newsItem.image_attributes.file === undefined) delete newsItem.image_attributes;

    return newsItem;
  };

  static mapToFormData = (data) => {
    const newsData = {
      newsTitle: data.title,
      content: data.getContent(),
      image: data.getImage(),
    };
    return newsData;
  };

  isDraft() {
    return this.status === 'draft';
  }

  update = async (changes, params) => this.model.update(this.id, changes, params);

  publish = async () => this.model.publish(this.id);

  getName(limit = false) {
    return limit ? truncate(this.title, { length: limit, omission: '...' }) : this.title;
  }

  getImage(size) {
    if (!this.image || !this.image.url) return ''; // we need image placeholder
    return this.image.url[size] || this.image.url.medium;
  }

  getAuthorName() {
    return this.author ? this.author.getName() : '';
  }

  getCreatedAt(format = 'DD-MM-YYYY HH:mm') {
    return this.createdAt.format(format);
  }

  getUpdated(format = 'DD-MM-YYYY HH:mm') {
    return this.updatedAt.format(format);
  }

  getUpdateDate() {
    return this.getUpdated('DD-MM-YYYY');
  }

  getUpdateTime() {
    return this.getUpdated('HH:mm');
  }

  getContent() {
    return parseMarkdownToHTML(this.content) || '';
  }

  getPermission(permissionKey) {
    return this.privileges.includes(permissionKey);
  }
}

export { NewsItem, News };
