import Link from '@old/components/common/Link';
import RatingStars from '@old/components/old/RatingStars';
import t from 'resources/translations';
import Box from '@old/components/common/Box';
import Subtitle from '@old/components/common/Subtitle';
import RowAligner from '@old/components/common/RowAligner';
import TagCircle from '@old/components/common/TagCircle';
import ImageCircle from '@old/components/guide/ImageCircle';
import { useHorseEditButton, useHorseDeleteButton, useHorseHistoryRidingButton } from 'old/buttonHooks';
import { ContextTrigger } from 'old/components/common/ContextMenu';

const HorseListItem = ({ horse }) => {
  const age = horse.getAge();
  const horseEdit = useHorseEditButton(horse);
  const horseDelete = useHorseDeleteButton(horse);
  const historyHorseRiding = useHorseHistoryRidingButton(horse);
  const slugInfo = horse.getSlugInfo();
  const horseButtons = [horseEdit, horseDelete, historyHorseRiding];

  return (
    <ContextTrigger menuItems={horseButtons}>
      <Box>
        <div className="absolute slug-left-corner z-20">
          <TagCircle small color={slugInfo.color}>
            {slugInfo.text}
          </TagCircle>
        </div>
        <RowAligner>
          <Link to={`/horses/${horse.id}`}>
            <ImageCircle src={horse.getAvatar()} big />
          </Link>
          <div className="ml-4">
            <Link to={`/horses/${horse.id}`}>
              <Subtitle>{horse.getName(40)}</Subtitle>
            </Link>
            <div className="mb-4">
              {horse.breed}, <span className="whitespace-no-wrap">{t('age', { age, count: age })}</span>
            </div>
            <Link to={`/horses/${horse.id}/rating`}>
              <RatingStars rating={horse.rating || 0} />
            </Link>
          </div>
        </RowAligner>
      </Box>
    </ContextTrigger>
  );
};

export default HorseListItem;
