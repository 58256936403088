import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { CancelToken } from 'axios';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import cls from 'classnames';
import t from 'resources/translations';
import WeekHeader from '@old/components/view/calendar/CalendarWeekHeader';
import { setDay, setWeek } from 'store/actions';
import { getRowsInMonth, getCalendarKey } from 'old/utils';
import Icon from '@old/components/icon';
import Model from '@old/model';

const moment = extendMoment(Moment);
const Month = ({ setMode, hideWeekSelect, switchModeOnClick, monthStart, fetchParams, calendarRange }) => {
  const monthEnd = moment(monthStart).endOf('month');
  const rowsNumber = getRowsInMonth({
    daysInMonth: monthEnd.date(),
    dayOfWeekInFirstMonthDay: monthStart.isoWeekday(),
  });

  const queryFn = ({ start, end }) => {
    const source = CancelToken.source();
    const data = Model.Events.fetchCountEvents({
      per_page: 9999,
      'in_interval[start]': start.toDate(),
      'in_interval[end]': end.toDate(),
      ...fetchParams,
      cancelToken: source.token,
    });
    data.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };
    return data;
  };

  const resultCountEvents = useQuery(['calendarCount', { ...calendarRange, ...fetchParams }], () =>
    queryFn(calendarRange)
  );
  const countEvents = resultCountEvents.status === 'success' ? resultCountEvents.data : {};

  return (
    <div
      className="calendar-grid"
      style={{
        gridTemplateRows: `repeat(${rowsNumber + 1}, 50px)`,
        gridTemplateColumns: `repeat(${hideWeekSelect ? 7 : 8}, 1fr)`,
      }}
    >
      <WeekHeader firstElement={t('month.chooseWeek')} hideWeekSelect={hideWeekSelect} />
      {[...Array(rowsNumber)].map((_, index) => (
        <ConnectedMonthRow
          hideWeekSelect={hideWeekSelect}
          key={index}
          weekIndex={index}
          setMode={setMode}
          switchModeOnClick={switchModeOnClick}
          countEvents={countEvents}
        />
      ))}
    </div>
  );
};

Month.defaultProps = {
  hideWeekSelect: false,
  fetchParams: {},
};

const mapStateToMonthProps = ({ calendar }, { location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;

  return {
    monthStart: calendarState.monthStart,
    calendarRange: calendarState.calendarRange,
  };
};

export default withRouter(connect(mapStateToMonthProps)(Month));

const MonthRow = ({
  weekIndex,
  setMode,
  hideWeekSelect,
  switchModeOnClick,
  setWeekDate,
  weekStart,
  monthStart,
  calendarKey,
  countEvents,
}) => {
  const newWeekStart = moment(monthStart).startOf('week').add(weekIndex, 'week');
  if (newWeekStart.isAfter(moment(monthStart).endOf('month'))) return null;

  const selected = newWeekStart.format('DD-MM-YYYY') === weekStart.format('DD-MM-YYYY');

  const clickRow = () => {
    setWeekDate(newWeekStart, calendarKey);
    setMode('week');
  };

  return (
    <React.Fragment>
      {!hideWeekSelect && (
        <div className="cursor-pointer bg-white" onClick={clickRow}>
          <div className={cls('centered w-full h-full', { 'bg-teal-transparent': selected })}>
            <div
              className={cls('ml-2 h-5 w-5 border border-teal rounded-full inline-block', {
                'bg-teal': selected,
                'bg-white': !selected,
              })}
            />
          </div>
        </div>
      )}
      {[0, 1, 2, 3, 4, 5, 6].map(index => (
        <ConnectedMonthCell
          key={index}
          day={moment(newWeekStart).add(index, 'days')}
          selected={selected && !hideWeekSelect}
          setMode={setMode}
          switchModeOnClick={switchModeOnClick}
          countEvents={countEvents}
        />
      ))}
    </React.Fragment>
  );
};

MonthRow.defaultProps = {
  hideWeekSelect: false,
  countEvents: {},
};
const mapStateToMonthRowProps = ({ calendar }, { location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;

  return {
    weekStart: calendarState.weekStart,
    monthStart: calendarState.monthStart,
    calendarKey,
  };
};

const ConnectedMonthRow = withRouter(connect(mapStateToMonthRowProps, { setWeekDate: setWeek })(MonthRow));

const MonthCell = ({
  day,
  selected,
  setMode,
  switchModeOnClick,
  setDayDate,
  monthStart,
  dayStart,
  calendarKey,
  countEvents,
}) => {
  const currentDayCount = !isEmpty(countEvents) && countEvents[day.format('YYYY-MM-DD')];
  const clickDay = () => {
    setDayDate(day, calendarKey);
    if (switchModeOnClick) {
      setMode('week');
    }
  };

  if (day.isSameOrAfter(moment(monthStart).startOf('month')) && day.isSameOrBefore(moment(monthStart).endOf('month'))) {
    const hasSpecialEvents = currentDayCount?.special;
    const currentDay = day.isSame(moment(), 'day');
    return (
      <div
        className={cls('centered relative cursor-pointer bg-white', {
          'border-teal': currentDay,
          'border-2': currentDay,
        })}
        onClick={clickDay}
      >
        <div
          className={cls('centered w-full h-full', { 'bg-teal-transparent': selected || day.isSame(dayStart, 'day') })}
        >
          {day.date()}
          {currentDayCount?.count && (
            <div className="rounded-badge absolute top-0 right-0">{currentDayCount?.count}</div>
          )}
          {hasSpecialEvents && <Icon.Star small className="fill-teal absolute top-0 left-0" />}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white cursor-pointer">
      <div className={cls('centered w-full h-full', { 'bg-teal-transparent': selected })} />
    </div>
  );
};

MonthCell.defaultProps = {
  countEvents: {},
};

const mapStateToMonthCellProps = ({ calendar }, { location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;

  return {
    dayStart: calendarState.dayStart,
    monthStart: calendarState.monthStart,
    calendarKey,
  };
};

const ConnectedMonthCell = withRouter(connect(mapStateToMonthCellProps, { setDayDate: setDay })(MonthCell));
