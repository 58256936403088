import React from 'react';
import PropTypes from 'prop-types';

const SimpleLayout = ({ children }) => (
  <div className="content">{children}</div>
);

SimpleLayout.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

SimpleLayout.displayName = 'SimpleLayout';

export default SimpleLayout;
