import React from 'react';
import { Stack } from '../../elements';
import { useAppSelector } from 'utils';
import { IStack } from 'resources/types/elementsTypes';
import { TReactNode } from 'resources/types/commonTypes';
type TCardActions = IStack & {
  children: TReactNode | TReactNode[];
};
const CardActions = ({ children, ...stackProps }: TCardActions) => {
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const compactedChildrens = React.Children.toArray(children).filter(Boolean);

  return (
    <Stack
      spacing={[0, 'lg']}
      px="md"
      justifyContent="flex-end"
      alignItems="center"
      borderTopWidth={1}
      borderColor="new-grey-light-30"
      isHorizontal={!isMobile}
      {...stackProps}
    >
      {compactedChildrens}
    </Stack>
  );
};

export default CardActions;
