import React, { useState } from 'react';
import Model from '@old/model';
import t from 'resources/translations';
import { orderBy } from 'lodash';
import { notify } from 'old/utils';
import { useShallowSelector } from '@old/hooks';
import { Box, Button, Span, Text } from '@elements';
import { Modal, ModalHeader, ModalCloseButton, ModalContent } from '@combinations';
import { wrappedModalPropTypes } from '@old/components/modals';
import SpinnerDots from 'old/components/guide/SpinnerDots';

const ReturnPaymentModal = ({ name, onSubmitAndClose, onClose, isSubmitting, isHidden }) => {
  const { bill, callback } = useShallowSelector(({ modal }) => modal[name]?.props || {});
  const [failedReturnPayment, setFailedReturnPayment] = useState(false);

  const onSubmit = async () => {
    try {
      const bookedPaymentsList = bill.payments.filter(payment => payment.status === 'booked');
      const lastBookedPaymentId = orderBy(bookedPaymentsList, ['created_at'], ['desc'])[0].id;
      await Model.Bills.returnPayment(bill.id, lastBookedPaymentId);
      callback();
      notify(t('message.succesSave'));
    } catch (error) {
      setFailedReturnPayment(true);
      throw error;
    }
  };

  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{t('bills.returnPayment')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting}>
        <Box mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" p="md">
          <Text>
            {t('bills.areYouSureReturn')}
            <Span fontWeight="bold">{bill.billed.getFullName()}</Span>
            <Span>?</Span>
          </Text>
        </Box>
        {!failedReturnPayment && (
          <Button onClick={() => onSubmitAndClose(onSubmit)}>
            {isSubmitting ? <SpinnerDots label={t('bills.makingReturn')} inline /> : t('bills.makeAReturn')}
          </Button>
        )}
        <Button onClick={onClose} variant="outline">
          {!failedReturnPayment ? t('general.cancel') : t('general.close')}
        </Button>
      </ModalContent>
    </Modal>
  );
};

ReturnPaymentModal.propTypes = wrappedModalPropTypes;

export default ReturnPaymentModal;
