import PropTypes from 'prop-types';
import { compact } from 'lodash';
import Axios from 'axios';

import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_EVENT_TYPE } from 'old/constants/formNames';
import Option from '@old/components/view/listItem/SelectOption';
import { changeFieldState, openModal } from 'store/actions';
import {
  isLengthBetween,
  getChanges,
  mapFormStateToData,
  isEmptyExtended,
  getInitValues,
  notify,
  getErrorMessage,
} from 'old/utils';
import config from '@old/config';
import { useActions, useShallowSelector } from '@old/hooks';

const EventTypesForm = ({ doc, initValues, onSubmit }) => {
  const formName = FORM_EVENT_TYPE(doc ? doc.id : '');
  const [isMobile, isTablet] = useShallowSelector(({ app }) => [app.isMobile, app.isTablet]);
  const [onChangeField, openModalByName] = useActions([changeFieldState, openModal]);
  const initialValues = getInitValues({
    formName: 'eventType',
    customInitValues: doc ? Model.EventType.mapToFormData(doc) : initValues,
  });

  const getInitFormState = (formState, avatar) => ({
    eventTypeName: formState?.eventTypeName,
    eventTypeSlug: formState?.eventTypeSlug,
    timeIntervals: formState?.timeIntervals,
    defaultEventCost: formState?.defaultEventCost,
    defaultEventPlace: formState?.defaultEventPlace,
    picture: avatar ?? formState?.picture,
  });

  const getBase64 = async avatar => {
    const b64 = await Axios.get(avatar, {
      responseType: 'arraybuffer',
    }).then(response => {
      const image = Buffer.from(response.data, 'binary').toString('base64');
      return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
    });
    return b64;
  };

  const approveSubmit = formState => {
    if (formState.avatar && formState.picture) {
      return openModalByName('confirmModal', {
        header: t('eventTypeForm.changePicture'),
        content: `${t('eventTypeForm.changeAvatar')} ${t('eventTypeForm.areYouSureWantContinue')}`,
        approveLabel: t('buttons.chooseTheDefaultPhoto'),
        onSubmitConfirm: () => onSubmitForm(formState),
      });
    }
    return onSubmitForm(formState);
  };

  const onSubmitForm = async formState => {
    try {
      const avatar = formState.avatar ? await getBase64(formState.avatar) : null;
      const values = doc
        ? getChanges(
          getInitFormState(formState, avatar),
          mapFormStateToData({ formState: initialValues, initValue: true })
        )
        : getInitFormState(formState, avatar);

      return onSubmit(Model.EventType.mapToSaveData(values));
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const setSlugAfterNameChange = ({ value }) => {
    let newSlug = '';
    const eventTypeNameString = value.trim();
    if (eventTypeNameString.indexOf(' ') !== -1) {
      const eventTypeNameCharsArray = compact(eventTypeNameString.split(' '));
      newSlug = `${eventTypeNameCharsArray[0][0]}${eventTypeNameCharsArray[1][0]}`;
    } else {
      newSlug = eventTypeNameString ? eventTypeNameString.slice(0, 2) : '';
    }

    onChangeField({
      formName,
      fieldName: 'eventTypeSlug',
      fieldState: newSlug,
    });
  };

  const validations = {
    slug: [
      {
        condition: value => isLengthBetween({ value, min: 1, max: 2 }),
        errorHint: t('error.fieldMustHaveLengthBetween', { min: 1, max: 2 }),
      },
    ],
    tag: [
      {
        condition: value => !isEmptyExtended(value) && value.length === 5,
        errorHint: t('error.blank'),
      },
      {
        condition: value => value !== '00:00',
        errorHint: t('error.prohibitedValue', { value: '00:00' }),
      },
    ],
    cost: [
      {
        condition: value => value >= 0 && value <= 4000,
        errorHint: t('error.fieldMustHavePriceBetween', { min: 0, max: 4000 }),
      },
      {
        condition: value => {
          const [, b] = value.split('.');
          if (b && b.length > 2) {
            return false;
          }
          return true;
        },
        errorHint: t('error.fieldCanContain2DecimalPlaces'),
      },
    ],
  };

  return (
    <Form formName={formName} initValues={initialValues}>
      <Field.Text
        name="eventTypeName"
        label={t('labels.name')}
        placeholder={t('placeholders.name')}
        afterChanges={setSlugAfterNameChange}
        required
      />

      <Field.Text
        name="eventTypeSlug"
        label={t('labels.slug')}
        placeholder={t('placeholders.slug')}
        validation={validations.slug}
        required
      />

      <Field.Tag
        label={t('labels.durationEvent')}
        name="timeIntervals"
        tagsOptions={{
          customRenderTag: item => {
            const [hours, minutes] = (item.value || ':').split(':');
            return `${hours}h ${minutes}m`;
          },
        }}
        defaultValue="00:30"
        options={{
          header: t('labels.durationEvent'),
          type: 'Time',
          validationsInput: validations.tag,
          hidePicker: !(isMobile || isTablet),
          minutesLabel: 'm',
          hoursLabel: 'h',
        }}
      />

      <Field.Numeric
        label={t('labels.defaultEventCost')}
        name="defaultEventCost"
        placeholder={t('placeholders.defaultEventCost')}
        validation={validations.cost}
        required
      />

      <Field.AsyncSelect
        name="defaultEventPlace"
        label={t('labels.defaultEventPlace')}
        placeholder={t('placeholders.selectPlaceEvent')}
        loadOptions={Model.Places.fetchOptions}
        customOptionComponents={Option.Place}
      />

      <Field.ImageUploader name="picture" label={t('labels.photo')} placeholder={t('labels.dropFile')} />

      <Field.AddAvatar name="avatar" avatars={config.eventTypeAvatars} label={t('eventTypeForm.orChooseFromDefault')} />

      <FormButton formName={formName} onClick={approveSubmit} async>
        {doc ? t('general.saveChanges') : t('model.add.eventTypes')}
      </FormButton>
    </Form>
  );
};

EventTypesForm.defaultProps = {
  doc: null,
  initValues: {},
};

EventTypesForm.propTypes = {
  initValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  doc: PropTypes.instanceOf(Model.EventType),
};

export default EventTypesForm;
