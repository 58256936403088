import { AxiosResponse as ARes } from 'axios';
import HTTP from 'config/axios';
import { IResponseRoles, IResWithPagination } from 'resources/types/commonTypes';
import { IFarmRes } from 'resources/types/farmsTypes';

class FarmService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
  }

  async fetch(farmId: number | string) {
    const response: ARes<IFarmRes> = await HTTP.get(`${this.apiBaseURL}/farms/${farmId || this.farmId}`);
    return response.data;
  }

  async fetchAll() {
    const response: ARes<IResWithPagination<IFarmRes[]>> = await HTTP.get(`${this.apiBaseURL}/farms`);
    return response.data;
  }

  async fetchMine() {
    const response: ARes<IResWithPagination<IFarmRes[]>> = await HTTP.get(`${this.apiBaseURL}/farms/mine`);
    return response.data;
  }

  async fetchRoles() {
    const response: ARes<IResponseRoles[]> = await HTTP.get(`${this.apiBaseURL}/farms/${this.farmId}/roles`);
    return response.data;
  }

  async fetchBySlugname(name: string) {
    const response: ARes<IFarmRes> = await HTTP.get(`${this.apiBaseURL}/farms/${name}`);

    return response.data;
  }
}

export default new FarmService();
