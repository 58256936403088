import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { A, Flex, Span, Text } from 'components/elements';
import t from 'resources/translations';

import Link from '@old/components/common/Link';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import { pathIsAllowed } from 'old/utils';
import Model from '@old/model';
import { menuItemsSidebar } from '@old/menu';
import { useAppDispatch, useAppStore, useOldLoggedMember } from 'utils/storeUtils';
import { useHistory, useLocation } from 'react-router';
import { toggleSidebar } from 'store/app';
import { version } from '../../../../package.json';

const Sidebar = () => {
  const [apiVersion, setApiVersion] = useState();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isTablet, isMobile, sidebarIsOpen } = useAppStore();
  const onToggle = () => dispatch(toggleSidebar(!sidebarIsOpen));
  const loggedMember = useOldLoggedMember();

  useEffect(() => {
    const fetchData = async () => {
      const result = await Model.Farms.apiVersion();
      setApiVersion(result.version);
    };
    fetchData();
  }, []);

  const logoMemorized = useMemo(
    () => <img src="/img/hm-logo-200x200.svg" className="cursor-pointer" alt="HORSEmanago" role="presentation" />,
    []
  );
  const logoHorizontalMemorized = useMemo(
    () => <img src="/img/hm-logo-horizontal.svg" className="cursor-pointer" alt="HORSEmanago" role="presentation" />,
    []
  );

  const allowedMenuItems = items =>
    items.reduce((memo, [name, path, key, icon]) => {
      if (pathIsAllowed(path)) {
        memo.push([name, path, key, icon]);
      }
      return memo;
    }, []);

  const renderSidebarItem = ([name, link, index, icon]) => {
    const active = link === location.pathname;
    return <SidebarItem key={index} onClick={() => history.push(link)} icon={icon} name={name} active={active} />;
  };

  if (!loggedMember || isTablet || isMobile) return null;

  return (
    <div className={cls('sidebar', { close: !sidebarIsOpen })}>
      <Link to="/" className={cls('hm-sidebar-logo', { full: sidebarIsOpen })}>
        <span className={cls('flex w-full', { hidden: sidebarIsOpen })}>{logoMemorized}</span>
        <span className={cls('flex w-full', { hidden: !sidebarIsOpen })}>{logoHorizontalMemorized}</span>
      </Link>
      <div className="hm-sidebar-toggler">
        <ButtonSimple onClick={onToggle}>
          {sidebarIsOpen ? (
            <Icon.ArrowLeft circle className="fill-teal" />
          ) : (
            <Icon.ArrowRight circle className="fill-teal" />
          )}
        </ButtonSimple>
      </div>
      <div className="hm-sidebar-menu mb-8 overflow-auto">
        {allowedMenuItems(menuItemsSidebar).map(renderSidebarItem)}
      </div>
      <div className="bottom-0 absolute text-center text-sm w-full pb-8">
        <Flex justifyContent="center" pb={sidebarIsOpen ? 0 : '2xl'}>
          <Text>
            <A href="https://horsemanago.com/pl/terms-of-service/" fontSize="xs" isExternal>
              {t('register.terms')}
            </A>
            <Span color="#454545" fontSize="xs">{` | `}</Span>
            <A href="https://horsemanago.com/pl/privacy-policy/" fontSize="xs" isExternal>
              {t('register.privacyPolicy')}
            </A>
          </Text>
        </Flex>
      </div>
      <div style={{ color: '#454545' }} className="bottom-0 absolute text-center text-sm w-full pb-2">
        Version {version} / API {apiVersion}
      </div>
    </div>
  );
};

export default Sidebar;

const SidebarItem = ({ name, icon, onClick, active }) => {
  return (
    <button type="button" className={cls('hm-sidebar-item', { active })} onClick={onClick}>
      <RowAligner separated="small">
        <span className="hm-sidebar-item-icon">{icon}</span>
        <div className="hm-sidebar-item-label">{name}</div>
      </RowAligner>
    </button>
  );
};

SidebarItem.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};
