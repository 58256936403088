import { truncate } from 'lodash';

import t from 'resources/translations';
import GroupMembersGrid from '@old/components/view/grid/GroupMember';
import Box from '@old/components/common/Box';
import { useGroupEditButton, useGroupDeleteButton } from 'old/buttonHooks';
import { Flex, Link, Span, Stack, Text } from 'components/elements';
import { ContextMenu, ContextMenuItem } from 'components/combinations';

const styles = {
  groupName: {
    fontWeight: 'medium',
    whiteSpace: 'nowrap',
    minW: 0,
    maxW: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
const GroupListItem = ({ group }) => {
  const editGroup = useGroupEditButton(group);
  const deleteGroup = useGroupDeleteButton(group);
  return (
    <Box>
      <Stack>
        <Flex justifyContent="space-between" alignItems="center">
          <Text {...styles.groupName} color="teal-light">
            <Link to={group.getGroupUrl()}>{group.name} </Link>
          </Text>
          <ContextMenu ml="sm">
            {editGroup && (
              <ContextMenuItem onClick={() => editGroup.onClick()}>
                <Text>{editGroup.label}</Text>
              </ContextMenuItem>
            )}
            {deleteGroup && (
              <ContextMenuItem onClick={() => deleteGroup.onClick()}>
                <Text>{deleteGroup.label}</Text>
              </ContextMenuItem>
            )}
          </ContextMenu>
        </Flex>
        <Text fontSize="sm" fontStyle={!group.description ? 'italic' : 'normal'}>
          {truncate(group.getDescription(), { length: 100, omission: '...' })}
        </Text>
        <Stack>
          <Text fontSize="sm" fontWeight="medium" color="teal-light">
            {`${t('groupsGridItem.numberOfPeople')}: `}
            <Span color="grey-dark" fontWeight="normal">
              {group.getSize()}
            </Span>
          </Text>
          <Box>
            <GroupMembersGrid members={group.members} />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default GroupListItem;
