import { useHistory } from 'react-router';
import { Button, Icons, Link, MotionBox, Stack, Text } from 'components/elements';
import { TStackProps } from 'resources/types/elementsTypes';
import { useIsFetching } from 'react-query';

type TPageTitle = TStackProps & {
  title: string;
  loading?: boolean;
  back: (() => void) | boolean | string;
};

const PageTitle = ({ title, back, loading, ...stackProps }: TPageTitle) => {
  const history = useHistory();
  const isFetching = useIsFetching();
  return (
    <Stack alignItems="center" isHorizontal spacing="sm" {...stackProps}>
      {(typeof back === 'string' || typeof back === 'object') && (
        <Link to={back}>
          <Icons.ArrowLeft />
        </Link>
      )}
      {typeof back === 'boolean' && back && (
        <Button variant="icon" onClick={() => history.goBack()}>
          <Icons.ArrowLeft />
        </Button>
      )}
      {typeof back === 'function' && (
        <Button variant="icon" onClick={back}>
          <Icons.ArrowLeft />
        </Button>
      )}
      <Text fontSize="lg" fontWeight="medium" color="teal">
        {title}
      </Text>
      {(loading || !!isFetching) && (
        <MotionBox
          transition={{ ease: 'linear', duration: 2, repeat: Infinity }}
          animate={{ rotate: 360 }}
          display="flex"
          flex="none"
          width="auto"
          alignItems="center"
        >
          <Icons.Spinner fill="teal" />
        </MotionBox>
      )}
    </Stack>
  );
};

PageTitle.defaultProps = {
  back: false,
  loading: false,
};

export default PageTitle;
