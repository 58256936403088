import { ACCENT_COLORS_EVENT_TYPES } from 'config/constans';
import _ from 'lodash';
import { IImage, TImageSize, TPrivilege } from 'resources/types/commonTypes';
import { ITypeRes } from 'resources/types/eventTypesTypes';
import Place from './Place';

class EventType {
  id: number;
  name: string;
  slug: string | null;
  timeIntervals: number[];
  defaultEventCost: string;
  deleted: boolean;
  defaultPlace: Place | null;
  privileges: TPrivilege[];
  picture: IImage;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: ITypeRes) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug || null;
    this.timeIntervals = data.time_intervals || [];
    this.defaultEventCost = data.default_event_cost;
    this.deleted = data.deleted;
    this.defaultPlace = data.default_place ? new Place(data.default_place) : null;
    this.privileges = data.meta.privileges || [];
    this.picture = data.picture;
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
  }

  getName(limit: number = 0) {
    return limit ? _.truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getImage(size: TImageSize) {
    if (!this.picture || !this.picture.url) {
      return '/img/event-type-placeholder.png';
    }

    return this.picture.url[size] || this.picture.url.medium;
  }

  getSlug() {
    return this.slug || '';
  }

  getColor() {
    return ACCENT_COLORS_EVENT_TYPES[this.id % ACCENT_COLORS_EVENT_TYPES.length];
  }

  static mapToOption(eventType: EventType) {
    return {
      key: eventType.id,
      value: eventType.id,
      label: eventType.getName(),
      slug: eventType.getSlug(),
      image: eventType.getImage('thumb'),
    };
  }
}

export default EventType;
