/**
 * @module Tooltip
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TippyTooltip } from 'react-tippy';
import { connect } from 'react-redux';
/**
 * Wrapper that shows tooltip on hover of the children
 * @param  {*} props.children
 * @param  {Node} props.content content of the tooltip
 * @param  {"top"|"top-end"|"top-start"|"bottom"|"bottom-end"|"bottom-start"|"left"|"left-end"|"left-start"|"right"|"right-end"|"right-start"} [props.position] specifies position of the tooltip in tailwind format
 * @param  {Boolean} props.isMobile specifies whether component is running on mobile device
 * @param  {Boolean} props.isTablet specifies whether component is running on tablet device
 */
const Tooltip = ({ children, content, position, isMobile, isTablet }) => (
  <TippyTooltip
    title={content}
    className="tooltip"
    unmountHTMLWhenHide
    position={position}
    theme="dark"
    animation="fade"
    transitionFlip={false}
    offset={10}
    duration={0}
    arrow
    disabled={isMobile || isTablet}
  >
    {children}
  </TippyTooltip>
);

Tooltip.defaultProps = {
  position: 'bottom',
};

Tooltip.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  content: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  position: PropTypes.oneOf([
    'top',
    'top-end',
    'top-start',
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
  ]),
};

const mapStateToProps = ({ app: { isMobile, isTablet } }) => ({ isMobile, isTablet });

export default connect(mapStateToProps)(Tooltip);
