import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router';

import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import Option from '@old/components/view/listItem/SelectOption';
import FieldGroupIcons from '@old/components/common/FieldGroupIcons';
import { FORM_EVENT_EDIT } from 'old/constants/formNames';
import config from '@old/config';
import {
  getDuration,
  isCorrectFormat,
  isLengthBetween,
  getChanges,
  mapFormStateToData,
  getInitValues,
  parseToCurrencyString,
} from 'old/utils';
import { openModal, changeFieldState } from 'store/actions';
import { useActions, useShallowSelector } from '@old/hooks';

const EventForm = ({ doc, onSubmit }) => {
  const history = useHistory();
  const isMobile = useShallowSelector(({ app }) => app.isMobile);
  const [onChangeField, openModalByName] = useActions([changeFieldState, openModal]);

  const [eventCost, setEventCost] = useState(doc.type.defaultEventCost || null);
  const { dateTimeFormat } = config;
  const formName = FORM_EVENT_EDIT(doc.id);

  const isEventFinished = doc && doc.isPast();

  // when event is finished prevent from editing and redirect to event details page
  if (isEventFinished) {
    history.replace(`/events/${doc.id}`);
  }

  const setNameAfterFieldChange = ({ formState }) => {
    const typeName = formState.type.value && formState.type.value.label;
    const date =
      formState.startDate.value && formState.endDate.value
        ? getDuration(
            moment(formState.startDate.value, dateTimeFormat),
            moment(formState.endDate.value, dateTimeFormat)
          )
        : '';
    const placesName = formState.places.value && formState.places.value.label;

    return onChangeField({
      formName,
      fieldName: 'eventName',
      fieldState: [typeName, date, placesName].filter(Boolean).join(' / '),
    });
  };
  const setFieldsAfterEventTypeChange = ({ formState }) => {
    setNameAfterFieldChange({ formState });
    setEndDateAfterFieldChange({ formState });
    setPlacesAfterEventTypeChange({ formState });
    setCostAfterEventTypeChange({ formState });
  };

  const setCostAfterEventTypeChange = ({ formState }) => {
    const eventType = formState.type.value;
    setEventCost(eventType?.eventCost);
  };

  const setPlacesAfterEventTypeChange = ({ formState }) => {
    const eventType = formState.type.value;
    if (eventType && eventType.defaultPlace) {
      onChangeField({ formName, fieldName: 'places', fieldState: eventType.defaultPlace });
    }
  };

  const setEndDateAfterFieldChange = ({ formState }) => {
    if (isCorrectFormat(formState.startDate.value, dateTimeFormat)) {
      const timeIntervals = formState.type.value ? formState.type.value.timeIntervals : [];
      let datetime = '';
      if (timeIntervals && timeIntervals.length > 0) {
        const timeIncrement = moment(timeIntervals[0], 'HH:mm');
        const minutes = timeIncrement.hour() * 60 + timeIncrement.minute();
        datetime = moment(formState.startDate.value, dateTimeFormat).add(minutes, 'minutes').format(dateTimeFormat);
      } else {
        datetime = moment(formState.startDate.value, dateTimeFormat).add(60, 'minutes').format(dateTimeFormat);
      }
      onChangeField({ formName, fieldName: 'endDate', fieldState: datetime });
    }
  };

  const approveSubmit = formState => {
    const startDateValue = moment(formState.startDate, dateTimeFormat);

    if (moment().isAfter(startDateValue, 'seconds') || isEventFinished) {
      return openModalByName('confirmModal', {
        header: t('eventForm.confirmationChanges'),
        content: `${t('eventForm.changesInPast')} ${t('eventForm.areYouSureWantSave')}`,
        onSubmitConfirm: () => onSubmitForm(formState),
      });
    }
    return onSubmitForm(formState);
  };

  const initialValues = getInitValues({ formName: 'events_edit', customInitValues: Model.Event.mapToFormData(doc) });

  const onSubmitForm = formState => {
    const values = getChanges(formState, mapFormStateToData({ formState: initialValues, initValue: true }));
    return onSubmit(Model.Event.mapToSaveData(values));
  };

  const validations = {
    eventName: [
      {
        condition: value => isLengthBetween({ value, min: 5, max: 120 }),
        errorHint: t('error.fieldMustHaveLengthBetween', { min: 5, max: 120 }),
      },
    ],
    endDate: [
      {
        condition: (value, formState) => {
          const startDate = formState.startDate.value;
          const endDate = value;
          if (isCorrectFormat(startDate, dateTimeFormat) && isCorrectFormat(endDate, dateTimeFormat)) {
            const momentStartDate = moment(startDate, dateTimeFormat);
            return !moment(endDate, dateTimeFormat).isSameOrBefore(momentStartDate);
          }
          return true;
        },
        errorHint: t('error.after_start'),
      },
    ],
    startDate: [
      {
        condition: (value, formState) => {
          const startDate = value;
          const endDate = formState.endDate.value;
          if (isCorrectFormat(startDate, dateTimeFormat) && isCorrectFormat(endDate, dateTimeFormat)) {
            const momentStartDate = moment(startDate, dateTimeFormat);
            return !moment(endDate, dateTimeFormat).isSameOrBefore(momentStartDate);
          }
          return true;
        },
        errorHint: t('error.after_start'),
      },
    ],
    attendees: [
      {
        condition: value => {
          const isMaxLimitTooLow = value[1] < doc.getJoinedParticipants().length;
          return !isMaxLimitTooLow;
        },
        errorHint: t('error.maxLimitCannotBeLowerThan', { max: doc.getJoinedParticipants().length }),
      },
    ],
  };

  const getFetchParamsForMembers = () => {
    if (doc.isPast()) {
      return { with_status: ['pending', 'active', 'deleted'] };
    }
    return { with_status: ['pending', 'active'] };
  };

  const loadOptionsForInstructors = (keyword, page) => {
    return Model.Members.fetchInstructorOptions(keyword, page, getFetchParamsForMembers());
  };

  const getFetchParams = () => {
    return { with_deleted: doc.isPast() };
  };

  const dependentFields = {
    startDate: ['endDate'],
    endDate: ['startDate'],
    attendees: ['participants'],
  };

  return (
    <React.Fragment>
      <Form formName={formName} initValues={initialValues} dependentFields={dependentFields}>
        <Field.AsyncSelect
          name="type"
          label={t('labels.eventType')}
          placeholder={t('placeholders.selectEventType')}
          loadOptions={(keyword, page) => Model.EventTypes.fetchOptions(keyword, page, getFetchParams())}
          afterChanges={setFieldsAfterEventTypeChange}
          customOptionComponents={Option.EventType}
          required
        />
        {eventCost && (
          <div className="flex ml-1 -mt-3">
            <div className="font-bold">{t('eventListItem.pricePerShare')}:</div>
            <div className="ml-1">{parseToCurrencyString(eventCost)}</div>
          </div>
        )}
        <Field.DateTime
          name="startDate"
          dataTest="starttime"
          label={t('labels.start')}
          validation={validations.startDate}
          afterChanges={setEndDateAfterFieldChange}
          pickerOptions={{ defaultIsOpen: false }}
          required
        />

        <Field.DateTimeWithDuration
          name="endDate"
          dataTest="endtime"
          label={t('labels.end')}
          validation={validations.endDate}
          afterChanges={setNameAfterFieldChange}
          durations={{
            masterField: 'startDate',
            dependentField: 'type',
          }}
          required
        />

        <Field.AsyncSelect
          name="places"
          label={t('labels.place')}
          placeholder={t('placeholders.selectPlace')}
          loadOptions={(keyword, page) => Model.Places.fetchOptions(keyword, page, getFetchParams())}
          afterChanges={setNameAfterFieldChange}
          customOptionComponents={Option.Place}
          isSearchable={false}
          required
        />

        <Field.AsyncMultiSelect
          name="instructors"
          label={t('labels.instructor')}
          placeholder={t('placeholders.selectInstructor')}
          loadOptions={loadOptionsForInstructors}
          customOptionComponents={Option.Member}
          isSearchable={false}
          required
        />

        <Field.Radio
          name="visibility"
          label={t('labels.visibility')}
          options={[
            { value: 'true', label: t('visibility.public').toLowerCase(), dataTest: 'public' },
            { value: 'false', label: t('visibility.private').toLowerCase(), dataTest: 'private' },
          ]}
        />

        <Field.Text name="eventName" label={t('labels.name')} validation={validations.eventName} required />

        <FieldGroupIcons label={t('general.other')}>
          <Field.NumberRange
            iconName="NumberUsers"
            name="attendees"
            label={t('labels.numberParticipants')}
            validation={validations.attendees}
            options={{
              min: 1,
              max: 50,
              minLabel: isMobile ? t('labels.min') : t('labels.minNumberOfParticipants'),
              maxLabel: isMobile ? t('labels.max') : t('labels.maxNumberOfParticipants'),
            }}
          />

          <Field.AsyncMultiSelect
            iconName="AddHorse"
            name="horses"
            label={t('labels.horses')}
            placeholder={t('placeholders.selectHorses')}
            loadOptions={(keyword, page) => Model.Horses.fetchOptions(keyword, page, getFetchParams())}
            customOptionComponents={Option.Horse}
          />

          <Field.Text iconName="Info" name="eventDescription" label={t('labels.additionalNotes')} />

          <Field.Radio
            iconName="Star"
            name="special"
            label={t('labels.typeOfEvent')}
            options={[
              { value: 'false', label: t('labels.normal').toLowerCase() },
              { value: 'true', label: t('labels.special').toLowerCase() },
            ]}
          />

          <Field.Select
            iconName="EventLevel"
            name="difficulty"
            label={t('labels.difficulty')}
            placeholder={t('placeholders.selectDifficulty')}
            options={config.difficulty.map(skill => ({
              key: skill.value,
              value: skill.value,
              label: skill.label,
              slug: skill.slug,
              color: skill.color,
            }))}
            customOptionComponents={Option.Difficulty}
          />
        </FieldGroupIcons>
        <FormButton formName={formName} onClick={approveSubmit} async>
          {t('general.save')}
        </FormButton>
      </Form>
    </React.Fragment>
  );
};

EventForm.defaultProps = {
  doc: null,
  onSubmit: null,
  initValues: null,
};

EventForm.propTypes = {
  onSubmit: PropTypes.func,
  initValues: PropTypes.shape({}),
};

export default EventForm;
