import React from 'react';
import PropTypes from 'prop-types';

import t from 'resources/translations';

const PieChartLabel = ({ value, viewBox: { cx, cy }, labelKey, small }) => {
  return (
    <React.Fragment>
      <text x={cx} y={cy - 7} textAnchor="middle" fontWeight="bold" fontSize={small ? '12px' : '14px'}>
        {t('eventCount', { count: value, value })}
      </text>
      <text x={cx} y={cy + 7} textAnchor="middle" fontSize={small ? '12px' : '14px'}>
        {t(`message.${labelKey}`, { count: value, value })}
      </text>
    </React.Fragment>
  );
};

PieChartLabel.defaultProps = {
  viewBox: {},
  small: false,
};

PieChartLabel.propTypes = {
  value: PropTypes.number.isRequired,
  viewBox: PropTypes.shape({
    cx: PropTypes.number,
    cy: PropTypes.number,
  }),
  labelKey: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

export default PieChartLabel;
