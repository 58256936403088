import { configureStore } from '@reduxjs/toolkit';
import promise from 'redux-promise';

import cableMiddleware from './middlewares/cableMiddleware';
import reducers from './reducers';

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(promise)
      .concat(cableMiddleware()),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
