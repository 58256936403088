/* eslint-disable max-len */
import React from 'react';

const Copy = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <path d="M25.6,6c-0.3-0.3-0.6-0.4-1-0.4h-6c-0.7,0-1.3,0.2-1.9,0.6V1.4c0-0.4-0.1-0.7-0.4-1
        C16,0.1,15.7,0,15.3,0h-6C8.8,0,8.4,0.1,8,0.3C7.6,0.4,7.2,0.7,6.9,1L1,6.9C0.7,7.2,0.4,7.6,0.3,8C0.1,8.4,0,8.8,0,9.3V19
        c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4h7.9v4.2c0,0.8,0.6,1.4,1.4,1.4c0,0,0,0,0,0h13.9c0.8,0,1.4-0.6,1.4-1.4c0,0,0,0,0,0V7
        C26,6.6,25.9,6.2,25.6,6z M16.7,8.7V13h-4.3L16.7,8.7z M7.4,3.1v4.3H3.1L7.4,3.1z M10.3,12.5c-0.3,0.3-0.5,0.7-0.7,1.1
        c-0.2,0.4-0.3,0.8-0.3,1.3v3.7H1.9V9.3h6c0.8,0,1.4-0.6,1.4-1.4c0,0,0,0,0,0v-6h5.6v6L10.3,12.5z M24.1,24.1h-13v-9.3h6
        c0.8,0,1.4-0.6,1.4-1.4c0,0,0,0,0,0v-6h5.6L24.1,24.1L24.1,24.1L24.1,24.1z"
      />
    </svg>
  );
};

export default Copy;
/* eslint-enable max-len */
