import { CHANGE_FIELD_STATE, SET_INIT_VALUES, VALIDATE_FIELD, CLEAR_FORM } from 'store/actions/formActions';

const initialState = {};

const formReducer = (state = initialState, action) => {
  const { formName, fieldName, fieldState, initValues, isValidate, dependentFields } = action;
  const values = [];
  const validates = [];
  switch (action.type) {
    case CHANGE_FIELD_STATE:
      return {
        ...state,
        [formName]: {
          ...state[formName],
          [fieldName]: {
            ...state[formName][fieldName],
            value: fieldState,
            isChanged: true,
          },
        },
      };
    case SET_INIT_VALUES:
      if (state[formName] && !action.force) {
        return state;
      }

      Object.entries(initValues).forEach(([field, value]) => {
        values[field] = {
          value,
          isChanged: false,
          dependentFields: action.force ? state[formName][field]?.dependentFields : dependentFields[field],
        };
        validates[field] = !!action.force;
      });

      return {
        ...state,
        [formName]: {
          ...values,
          validate: {
            ...validates,
          },
        },
      };
    case VALIDATE_FIELD:
      return {
        ...state,
        [formName]: {
          ...state[formName],
          validate: {
            ...(state[formName] ? state[formName].validate : []),
            [fieldName]: isValidate,
          },
        },
      };
    case CLEAR_FORM:
      return {
        ...state,
        [formName]: null,
      };

    default:
      return state;
  }
};

export default formReducer;
