/**
 * @module SelectInput
 * */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import InputWrapper from '@old/components/common/InputWrapper';
import SelectMenuList from '@old/components/old/SelectMenuList';
import t from 'resources/translations';

const animatedComponents = makeAnimated();

/**
 * @typedef  {Object} SelectOption
 * @property {String|Number} value
 * @property {String} label
 *
 * @typedef  {Object} InputOptions
 * @property {String|Number} key
 * @property {String} label
 * @property {String|Number} value
 */

/**
 * Component displays select and allows users to tick only one option.
 * @param  {SelectOption[]} [props.value] value of input field
 * @param  {Function} props.onChange function detects when the value of an input changes and run function delegated in props
 * @param  {InputOptions[]} props.options options with custom field key, value, label
 * @param  {String} [props.placeholder]
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 * @param  {*} [props.customOptionComponents] Option with custom: label, slug, color
 * @param  {String} [props.name]
 * @param  {Node} [props.icon]
 * @param  {Boolean} [props.isSearchable]
 * @param  {Boolean} [props.isDisabled]
 */
const SelectInput = ({
  value,
  onChange,
  options,
  placeholder,
  label,
  required,
  customOptionComponents,
  name,
  icon,
  isSearchable,
  isDisabled = false,
}) => {
  const OptionComponent = customOptionComponents ? { Option: customOptionComponents } : {};
  const MenuListComponent = { MenuList: SelectMenuList };

  const DropdownIndicator = () => {
    return <div className="px-2">{icon}</div>;
  };

  const OptionIndicator = icon ? { DropdownIndicator } : {};

  return (
    <InputWrapper label={label} required={required} name={name}>
      <Select
        className="select"
        classNamePrefix="select"
        options={options}
        components={{ ...OptionIndicator, animatedComponents, ...MenuListComponent, ...OptionComponent }}
        onChange={val => onChange(val, name)}
        placeholder={placeholder}
        noOptionsMessage={() => <div>{t('message.noResults')}</div>}
        loadingMessage={() => <div>{t('general.loading')}</div>}
        value={value}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
      />
    </InputWrapper>
  );
};

SelectInput.defaultProps = {
  value: '',
  placeholder: '',
  label: '',
  required: false,
  customOptionComponents: null,
  name: '',
  isSearchable: false,
  icon: null,
};

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  isSearchable: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
};

export default SelectInput;
