/**
 * @module IndicatorColor
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
/**
 * Indicator component with custom color
 * @param  {"bg-grey"|"bg-green-light"|"bg-orange"} [props.color] Color of the indicator in tailwind format (it will be replaced by hexColor if hexColor is also provided)
 * @param  {String} [props.hexColor] Custom color of the indicator in hex format
 * @param  {Boolean} [props.large] Size of the indicator
 * @param  {Boolean} [props.border] Custom border of the indicator
 */
const IndicatorColor = ({ color, hexColor, large, border }) => (
  <div className={cls('indicator-color', color, { large, border })} style={{ backgroundColor: hexColor }} />);

IndicatorColor.defaultProps = {
  color: null,
  hexColor: '',
  large: null,
  border: null,
};

IndicatorColor.propTypes = {
  color: PropTypes.oneOf([
    'bg-grey',
    'bg-green-light',
    'bg-orange',
  ]),
  hexColor: PropTypes.string,
  large: PropTypes.bool,
  border: PropTypes.bool,
};

export default IndicatorColor;
