import { connect } from 'react-redux';
import cls from 'classnames';

import Link from '@old/components/common/Link';
import t from 'resources/translations';
import TextValue from '@old/components/common/TextValue';
import RenderDate from '@old/components/old/RenderDate';
import Divider from '@old/components/common/Divider';
import ImageCircle from '@old/components/guide/ImageCircle';
import Box from '@old/components/common/Box';
import FlexColumn from '@old/components/common/FlexColumn';
import RowAligner from '@old/components/common/RowAligner';
import Subtitle from '@old/components/common/Subtitle';
import MemberLink from '@old/components/custom/MemberLink';
import {
  useProposalsEditButton,
  useProposalsDeleteButton,
  useProposalsRejectButton,
  useProposalsUpdateAndConfirmButton,
  useProposalsConfirmButton,
} from 'old/buttonHooks';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Allow from '@old/components/common/Allow';
import { parseToCurrencyString } from 'old/utils';
import Icon from '@old/components/icon';
import { ContextTrigger } from 'old/components/common/ContextMenu';
import { useOldLoggedMember } from 'utils/storeUtils';

const ProposalListItem = ({ proposal, isMobile }) => {
  const loggedMember = useOldLoggedMember();
  const editProposal = useProposalsEditButton(proposal);
  const deleteProposal = useProposalsDeleteButton(proposal);
  const rejectProposal = useProposalsRejectButton(proposal);
  const updateAndConfirmProposal = useProposalsUpdateAndConfirmButton(proposal, loggedMember);
  const confirmButton = useProposalsConfirmButton(proposal);

  const proposalButtons = [editProposal, deleteProposal, confirmButton];
  const isInstructedByLoggedMember = proposal.getInstructorsIds().includes(loggedMember.id);
  const active = loggedMember.isOwner() || isInstructedByLoggedMember;

  return (
    <ContextTrigger menuItems={proposalButtons}>
      <Box>
        <div className="w-full inline-flex mb-4">
          <RowAligner className={cls({ 'w-1/2': !isMobile })}>
            <ImageCircle src={proposal.creator.getAvatar('thumb')} />
            <div>
              <MemberLink forceRender member={proposal.creator}>
                <Subtitle>{proposal.creator.getFullName()}</Subtitle>
              </MemberLink>
              <div>{t(`skills.${proposal.creator.getSkill()}`)}</div>
            </div>
          </RowAligner>
          {!isMobile && (
            <RowAligner className="float-right w-1/2 justify-end">
              {!loggedMember.isClient() && (
                <Allow permissionKey="proposals.reject" permissionParams={{ modelItem: proposal }}>
                  <div className="w-1/2">
                    <ButtonOutline onClick={rejectProposal.onClick} fluid>
                      {rejectProposal.label}
                    </ButtonOutline>
                  </div>
                </Allow>
              )}
              {active && (
                <Allow permissionKey="proposals.approve" permissionParams={{ modelItem: proposal }}>
                  <div className="w-1/2">
                    <Link className="btn" to={updateAndConfirmProposal.to}>
                      {updateAndConfirmProposal.label}
                    </Link>
                  </div>
                </Allow>
              )}
            </RowAligner>
          )}
        </div>
        <FlexColumn separated="small">
          <RowAligner separated="small" className="py-2">
            <Icon.EventCost small className="fill-teal-dark" />
            <div className="flex">
              <div className="font-bold">{t('eventListItem.pricePerShare')}:</div>
              <div className="ml-1">{parseToCurrencyString(proposal.type.defaultEventCost)}</div>
            </div>
          </RowAligner>
          <div>
            {proposal.name && <TextValue text={t('model.event')} value={proposal.getName()} />}
            {proposal.startDate && (
              <div>
                <TextValue
                  text={t('general.date')}
                  value={<RenderDate startDate={proposal.startDate} endDate={proposal.endDate} sameDateHours />}
                />
              </div>
            )}
            {proposal.type && <TextValue text={t('model.eventType')} value={proposal.type.getName()} />}
            {proposal.difficulty && (
              <TextValue text={t('general.level')} value={t(`skills.${proposal.getDifficulty()}`)} />
            )}
            {proposal.places.length > 0 && (
              <TextValue
                text={proposal.places.length > 1 ? t('model.places') : t('model.place')}
                value={<span>{proposal.getPlacesString()}</span>}
              />
            )}
          </div>
          <Divider />
          {proposal.instructors.length && (
            <div>
              <div className="text-highlight">
                {t('proposalEventsList.instructors', { count: proposal.instructors.length })}:
              </div>
              <div>
                {proposal.instructors.map(instructor => (
                  <div key={instructor.id}>
                    <Link to={instructor.getProfileUrl()}>{instructor.getName()}</Link>
                  </div>
                ))}
              </div>
            </div>
          )}
          {proposal.participations.length && (
            <div>
              <Divider />
              <div className="text-highlight">
                {t('proposalEventsList.participants', { count: proposal.participations.length })}:
              </div>
              <div>
                {proposal.participations
                  .filter(participation => participation.member != null)
                  .map(participation => (
                    <div key={participation.member.id}>
                      <MemberLink member={participation.member} forceRender>
                        {participation.member.getName()}
                      </MemberLink>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {proposal.horses.length > 0 && (
            <div>
              <Divider />
              <div className="text-highlight">{t('proposalEventsList.horses', { count: proposal.horses.length })}:</div>
              <div>
                {proposal.horses.map(horse => (
                  <div key={horse.id}>
                    <Link to={`/horses/${horse.id}`}>{horse.name}</Link>
                  </div>
                ))}
              </div>
            </div>
          )}
          {proposal.description && (
            <FlexColumn separated="small">
              <Divider />
              <TextValue text={t('proposalEventsList.additionalRemarks')} value={proposal.description} />
            </FlexColumn>
          )}
          {isMobile && (
            <div className="mt-4">
              <FlexColumn>
                {!loggedMember.isClient() && (
                  <Allow permissionKey="proposals.reject" permissionParams={{ modelItem: proposal }}>
                    <ButtonOutline onClick={rejectProposal.onClick} fluid>
                      {rejectProposal.label}
                    </ButtonOutline>
                  </Allow>
                )}
                {active && (
                  <Allow permissionKey="proposals.approve" permissionParams={{ modelItem: proposal }}>
                    <Link className="btn w-full" to={updateAndConfirmProposal.to}>
                      {updateAndConfirmProposal.label}
                    </Link>
                  </Allow>
                )}
              </FlexColumn>
            </div>
          )}
        </FlexColumn>
      </Box>
    </ContextTrigger>
  );
};

const mapStateToProps = ({ app: { isMobile } }) => {
  return {
    isMobile,
  };
};

export default connect(mapStateToProps)(ProposalListItem);
