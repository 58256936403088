import { DefaultTheme } from 'styled-components';
import {
  ISpace,
  IFontSizes,
  IFontWeights,
  IShadows,
  ILineHeights,
  IColors,
  IBorderRadius,
} from '../resources/types/styledTypes';

const space: ISpace = {
  0: '0',
  px: '1px',
  xs: '5px',
  sm: '10px',
  base: '15px',
  md: '20px',
  lg: '25px',
  xl: '30px',
  '2xl': '40px',
  full: '100%',
};

const fontSizes: IFontSizes = {
  xs: 12,
  sm: 14,
  base: 16,
  md: 18,
  lg: 24,
  xl: 30,
};

const fontWeights: IFontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

const shadows: IShadows = {
  base: '0 2px 12px 0 rgba(37, 51, 66, 0.15)',
};

const lineHeights: ILineHeights = {
  lg: '28px',
};

const colors: IColors = {
  'body-color': '#f5f5f5',
  'body-color-30': 'rgba(235, 235, 235, 0.3)',
  'body-color-50': 'rgba(235, 235, 235, 0.5)',
  transparent: 'transparent',
  'transparent-dark': 'rgba(0,0,0,0.5)',
  'black-transparent': 'rgba(0, 0, 0, 0.70)',
  black: '#000000',
  'black-light': '#0f0f0f',
  'white-transparent': 'rgba(255, 255, 255, 0.40)',
  white: '#ffffff',
  'teal-transparent': 'rgba(0, 94, 101, 0.15)',
  'teal-light': '#00a0ad',
  'teal-light-5': 'rgba(0, 160, 173, 0.05)',
  'teal-light-10': 'rgba(0, 160, 173, 0.1)',
  'teal-light-15': 'rgba(0, 160, 173, 0.15)',
  teal: '#3fa1ad',
  'teal-dark': '#005e65',
  'grey-light': '#ebebeb',
  grey: '#d1d0d0',
  'grey-dark': '#1E1E1E',
  'grey-dark-80': 'rgba(30, 30, 30, 0.8)',
  shadow: 'rgba(0, 0, 0, 0.30)',
  'shadow-dark': 'rgba(0, 0, 0, 0.50)',
  orange: '#ff8000',
  'orange-dark': '#f96700',
  green: '#689f00',
  'green-light': '#0ab43d',
  red: '#DC4E41',
  'new-green': '#28a820',
  'new-grey': '#d8d6de',
  'grey-lighter': '#ebebeb',
  'grey-lighter-40': 'rgba(235, 235, 235, 0.4)',
  'grey-lighter-50': 'rgba(235, 235, 235, 0.5)',
  'new-grey-light': '#d1d1d0',
  'new-grey-light-10': 'rgba(209, 209, 208, 0.1)',
  'new-grey-light-15': 'rgba(209, 209, 208, 0.15)',
  'new-grey-light-15-filled': '#f8f8f8',
  'new-grey-light-20': 'rgba(209, 209, 208, 0.20)',
  'new-grey-light-30': 'rgba(209, 209, 208, 0.30)',
  'new-grey-light-70': 'rgba(209, 209, 208, 0.70)',
  'new-black-light-40': 'rgba(0, 0, 0, 0.40)',
  'new-red': '#c82024',
  'new-red-lighter': '#ffecf0',
  'new-red-light': '#fed5db',
  'new-blue': '#2c5ec6',
  'new-blue-lighter': '#ccdaf9',
  'new-blue-light': '#b3c0dd',
  'cod-gray': 'rgba(30, 30, 30, 0.8)',
};

const borderRadius: IBorderRadius = {
  full: 9999,
  sm: 4,
  base: 8,
};

const breakpoints: Array<String> = ['768px', '1200px', '1600px'];

const theme: DefaultTheme = {
  colors,
  borderRadius,
  space,
  fontSizes,
  fontWeights,
  shadows,
  lineHeights,
  breakpoints,
};

export default theme;
