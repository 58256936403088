import { Icons, MotionBox, Stack, Text } from 'components/elements';
import theme from 'config/theme';
import styled from 'styled-components';
import { TOption } from 'resources/types/commonTypes';
import React from 'react';
import { doubleTernary } from 'utils';

type TDefaultOption = {
  onClick: () => void;
  isSelected: boolean;
  isDisabled: boolean;
  data: TOption & {
    loadingMessage?: string;
    loading?: boolean;
    disabled?: boolean;
  };
  innerProps?: any;
  customDescription?: string;
};

type TStyleOption = {
  isDisabled: boolean;
  isSelected: boolean;
};

const Option = styled('div')<TStyleOption>(props => {
  return {
    width: '100%',
    borderBottom: `1px solid ${theme.colors['grey-light']}`,
    display: 'flex',
    minHeight: 50,
    alignItems: 'center',
    color: doubleTernary(props.isSelected, props.isDisabled, [
      theme.colors['teal-dark'],
      theme.colors['new-grey-light'],
      theme.colors['black'],
    ]),
    cursor: props.isDisabled ? 'default' : 'pointer',
    '&:hover': {
      color: props.isDisabled ? theme.colors['new-grey-light'] : theme.colors['teal-light'],
    },
  };
});

const DefaultOption = ({ onClick, isSelected, isDisabled, data, innerProps, ...restProps }: TDefaultOption) => {
  return (
    <Option isDisabled={isDisabled} isSelected={isSelected} {...innerProps}>
      <Stack width="100%" spacing="xs" alignItems="center" isHorizontal>
        <Stack spacing="base" alignItems="center" width="100%" isHorizontal>
          <Text wordBreak="break-word">{data.label}</Text>
          {data.loading && (
            <Stack spacing="sm" isHorizontal>
              <Text wordBreak="break-word" color="new-grey-light">
                {data.loadingMessage}
              </Text>
              <MotionBox
                width="auto"
                transition={{ ease: 'linear', duration: 2, repeat: Infinity }}
                animate={{ rotate: 360 }}
              >
                <Icons.Spinner fill="new-grey-light" />
              </MotionBox>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Option>
  );
};

export default React.memo(DefaultOption);
