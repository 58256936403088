import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getErrorMessage, notify } from 'old/utils';
import { refreshCustomData } from 'store/actions';
import Model from '@old/model';
import t from 'resources/translations';
import Comment from '@old/components/view/listItem/Comment';
import FlexColumn from '@old/components/common/FlexColumn';
import FlexRow from '@old/components/common/FlexRow';
import InputWithAction from '@old/components/common/InputWithAction';
import Input from '@old/components/input';
import Icon from '@old/components/icon';
import Button from '@old/components/guide/Button';

const EventCommentsList = ({ eventId, postId, comments, isMobile, refreshPosts }) => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  const addComment = async () => {
    if (!loading) {
      setLoading(true);
      try {
        await Model.Events.createComment(eventId, postId, {
          content,
          commentable_type: 'Post',
          commentable_id: postId,
        });
        refreshPosts('posts');
        notify(t('message.addComment'));
        setContent('');
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      }
      setLoading(false);
    }
  };

  return (
    <FlexColumn>
      <div className={isMobile ? 'ml-4' : 'ml-8'}>
        <FlexColumn separated="small">
          {comments.map(comment => (
            <Comment key={comment.id} comment={comment} eventId={eventId} postId={postId} />
          ))}
        </FlexColumn>
      </div>
      <FlexRow cols="1/none" noShrink>
        <InputWithAction>
          <Input.Text
            label={t('labels.comment')}
            value={content}
            onChange={val => setContent(val)}
            placeholder={`${t('placeholders.reply')}...`}
          />
          <Button async="simple" onClick={addComment}><Icon.Send /></Button>
        </InputWithAction>
      </FlexRow>
    </FlexColumn>
  );
};

EventCommentsList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  postId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  refreshPosts: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps, { refreshPosts: refreshCustomData })(EventCommentsList);
