import PlacesListItem from '@old/components/view/listItem/Place';
import FlexColumn from '@old/components/common/FlexColumn';

const PlacesList = ({ data }) => (
  <div>
    <FlexColumn>
      {data.map(place => (
        <PlacesListItem key={place.id} place={place} />
      ))}
    </FlexColumn>
  </div>
);

export default PlacesList;
