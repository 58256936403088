import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Divider = ({ large, small }) => (
  <div className={cls('divider', { large, small })} />
);

Divider.defaultProps = {
  large: false,
  small: false,
};

Divider.propTypes = {
  large: PropTypes.bool,
  small: PropTypes.bool,
};

export default Divider;
