/* eslint-disable max-len */
import React from 'react';

const Cancel = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M2.3,16L2.3,16L0,13.7L5.7,8L0,2.3L2.3,0L8,5.7L13.7,0L16,2.3L10.3,8l5.7,5.7L13.7,16L8,10.3L2.3,16L2.3,16z" />
    </svg>
  );
};

export default Cancel;
/* eslint-enable max-len */
