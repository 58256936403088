/* eslint-disable max-len */
import React from 'react';

const LinkAnchorCircular = () => {
  return (
    <svg width="100%" viewBox="0 0 50 50">
      <path d="M25.13,0a25,25,0,1,0,25,25A25,25,0,0,0,25.13,0Zm-.24,30.69-3.84,3.84a4.2,4.2,0,1,1-5.94-5.94L19,24.75A4.2,4.2,0,0,1,23.81,24L22.3,25.49l-.11.13a2.08,2.08,0,0,0-1.77.6l-3.85,3.84a2.12,2.12,0,0,0,3,3l3.85-3.84A2.11,2.11,0,0,0,24,27.45l.13-.11,1.52-1.52A4.21,4.21,0,0,1,24.89,30.69Zm2-9.29a1,1,0,0,1,1.36,0h0a1,1,0,0,1,0,1.36L22.89,28.1a1,1,0,0,1-1.36,0h0a1,1,0,0,1,0-1.36Zm7.79-.49-3.84,3.84a4.2,4.2,0,0,1-4.86.77L27.47,24a1.34,1.34,0,0,0,.11-.14,2.1,2.1,0,0,0,1.77-.59l3.84-3.84a2.12,2.12,0,0,0-3-3l-3.84,3.84a2.1,2.1,0,0,0-.59,1.77l-.14.11-1.51,1.51a4.19,4.19,0,0,1,.77-4.86L28.72,15h0a4.2,4.2,0,0,1,5.94,5.94Z" transform="translate(-0.13)" />
    </svg>
  );
};

export default LinkAnchorCircular;
/* eslint-enable max-len */
