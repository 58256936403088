import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';
import { baseStyledProps, blockStyleProps, typographyStyleProps } from 'config/styledProps';
import { TInputProps } from 'resources/types/elementsTypes';

const InputAmount = styled(CurrencyInput).withConfig({
  shouldForwardProp: prop => !['marginInlineStart'].includes(prop),
})<TInputProps>(baseStyledProps, typographyStyleProps, blockStyleProps);

InputAmount.defaultProps = {
  px: 0,
};

export default InputAmount;
