import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const withIconWrapper = (WrappedIcon) => {
  const IconWrapper = ({ className, big, small, circle }) => {
    return (
      <div className={cls('icon', className, { small, big, circle })}>
        <WrappedIcon />
      </div>
    );
  };

  IconWrapper.defaultProps = {
    className: 'fill-current',
    big: false,
    small: false,
    circle: false,
  };

  IconWrapper.propTypes = {
    className: PropTypes.string,
    big: PropTypes.bool,
    small: PropTypes.bool,
    circle: PropTypes.bool,
  };

  return IconWrapper;
};

export default withIconWrapper;
