import axios from 'axios';
import { unionBy, orderBy } from 'lodash';

import Model from '@old/model';

export const FETCHING_EVENT = 'FETCHING_EVENT';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILED = 'FETCH_EVENT_FAILED';
export const DELETING_EVENT = 'DELETING_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED';
export const FETCH_EVENT_FINISHED = 'FETCH_EVENT_FINISHED';
export const FETCHING_EVENTS = 'FETCHING_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILED = 'FETCH_EVENTS_FAILED';
export const FETCH_EVENTS_FINISHED = 'FETCH_EVENTS_FINISHED';
export const FETCH_EVENTS_FIRST_PART_SUCCESS = 'FETCH_EVENTS_FIRST_PART_SUCCESS';
export const FETCH_EVENTS_STARTED = 'FETCH_EVENTS_STARTED';
export const FETCH_EVENTS_NEXT_SUCCESS = 'FETCH_EVENTS_NEXT_SUCCESS';
export const SET_LOADING_EVENT_ID = 'SET_LOADING_EVENT_ID';

export const FETCHING_HORSE = 'FETCHING_HORSE';
export const FETCH_HORSE_SUCCESS = 'FETCH_HORSE_SUCCESS';
export const FETCH_HORSE_FAILED = 'FETCH_HORSE_FAILED';
export const FETCH_HORSE_FINISHED = 'FETCH_HORSE_FINISHED';
export const DELETING_HORSE = 'DELETING_HORSE';
export const DELETE_HORSE_SUCCESS = 'DELETE_HORSE_SUCCESS';
export const DELETE_HORSE_FAILED = 'DELETE_HORSE_FAILED';
export const FETCHING_HORSES = 'FETCHING_HORSES';
export const FETCH_HORSES_SUCCESS = 'FETCH_HORSES_SUCCESS';
export const FETCH_HORSES_FAILED = 'FETCH_HORSES_FAILED';
export const FETCH_HORSES_FINISHED = 'FETCH_HORSES_FINISHED';

export const FETCHING_PLACE = 'FETCHING_PLACE';
export const FETCH_PLACE_SUCCESS = 'FETCH_PLACE_SUCCESS';
export const FETCH_PLACE_FAILED = 'FETCH_PLACE_FAILED';
export const FETCH_PLACE_FINISHED = 'FETCH_PLACE_FINISHED';
export const DELETING_PLACE = 'DELETING_PLACE';
export const DELETE_PLACE_SUCCESS = 'DELETE_PLACE_SUCCESS';
export const DELETE_PLACE_FAILED = 'DELETE_PLACE_FAILED';
export const FETCHING_PLACES = 'FETCHING_PLACES';
export const FETCH_PLACES_SUCCESS = 'FETCH_PLACES_SUCCESS';
export const FETCH_PLACES_FAILED = 'FETCH_PLACES_FAILED';
export const FETCH_PLACES_FINISHED = 'FETCH_PLACES_FINISHED';

export const DELETING_RATING = 'DELETING_RATING';
export const DELETE_RATING_SUCCESS = 'DELETE_RATING_SUCCESS';
export const DELETE_RATING_FAILED = 'DELETE_RATING_FAILED';
export const FETCHING_RATINGS = 'FETCHING_RATINGS';
export const FETCH_RATINGS_SUCCESS = 'FETCH_RATINGS_SUCCESS';
export const FETCH_RATINGS_FAILED = 'FETCH_RATINGS_FAILED';
export const FETCH_RATINGS_FINISHED = 'FETCH_RATINGS_FINISHED';

export const FETCHING_EVENT_TYPE = 'FETCHING_EVENT_TYPE';
export const FETCH_EVENT_TYPE_SUCCESS = 'FETCH_EVENT_TYPE_SUCCESS';
export const FETCH_EVENT_TYPE_FAILED = 'FETCH_EVENT_TYPE_FAILED';
export const FETCH_EVENT_TYPE_FINISHED = 'FETCH_EVENT_TYPE_FINISHED';
export const DELETING_EVENT_TYPE = 'DELETING_EVENT_TYPE';
export const DELETE_EVENT_TYPE_SUCCESS = 'DELETE_EVENT_TYPE_SUCCESS';
export const DELETE_EVENT_TYPE_FAILED = 'DELETE_EVENT_TYPE_FAILED';
export const FETCHING_EVENT_TYPES = 'FETCHING_EVENT_TYPES';
export const FETCH_EVENT_TYPES_SUCCESS = 'FETCH_EVENT_TYPES_SUCCESS';
export const FETCH_EVENT_TYPES_FAILED = 'FETCH_EVENT_TYPES_FAILED';
export const FETCH_EVENT_TYPES_FINISHED = 'FETCH_EVENT_TYPES_FINISHED';

export const FETCHING_USER = 'FETCHING_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';
export const FETCH_USER_FINISHED = 'FETCH_USER_FINISHED';
export const DELETING_USER = 'DELETING_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const FETCHING_USERS = 'FETCHING_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const FETCH_USERS_FINISHED = 'FETCH_USERS_FINISHED';

export const FETCHING_GROUP = 'FETCHING_GROUP';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_FAILED = 'FETCH_GROUP_FAILED';
export const FETCH_GROUP_FINISHED = 'FETCH_GROUP_FINISHED';
export const DELETING_GROUP = 'DELETING_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';
export const FETCHING_GROUPS = 'FETCHING_GROUPS';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED';
export const FETCH_GROUPS_FINISHED = 'FETCH_GROUPS_FINISHED';

export const FETCHING_FARM = 'FETCHING_FARM';
export const FETCH_FARM_SUCCESS = 'FETCH_FARM_SUCCESS';
export const FETCH_FARM_FAILED = 'FETCH_FARM_FAILED';
export const FETCH_FARM_FINISHED = 'FETCH_FARM_FINISHED';

export const FETCHING_SINGLE_NEWS = 'FETCHING_SINGLE_NEWS';
export const FETCH_SINGLE_NEWS_SUCCESS = 'FETCH_SINGLE_NEWS_SUCCESS';
export const FETCH_SINGLE_NEWS_FAILED = 'FETCH_SINGLE_NEWS_FAILED';
export const FETCH_SINGLE_NEWS_FINISHED = 'FETCH_SINGLE_NEWS_FINISHED';
export const DELETING_NEWS = 'DELETING_NEWS';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILED = 'DELETE_NEWS_FAILED';
export const PUBLISHING_NEWS = 'PUBLISHING_NEWS';
export const PUBLISH_NEWS_SUCCESS = 'PUBLISH_NEWS_SUCCESS';
export const PUBLISH_NEWS_FAILED = 'PUBLISH_NEWS_FAILED';
export const FETCHING_NEWS = 'FETCHING_NEWS';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAILED = 'FETCH_NEWS_FAILED';
export const FETCH_NEWS_FINISHED = 'FETCH_NEWS_FINISHED';

export const FETCHING_ABSENCES = 'FETCHING_ABSENCES';
export const FETCH_ABSENCES_SUCCESS = 'FETCH_ABSENCES_SUCCESS';
export const FETCH_ABSENCES_FAILED = 'FETCH_ABSENCES_FAILED';
export const FETCH_ABSENCES_FINISHED = 'FETCH_ABSENCES_FINISHED';
export const DELETING_ABSENCE = 'DELETING_ABSENCE';
export const DELETE_ABSENCE_SUCCESS = 'DELETE_ABSENCE_SUCCESS';
export const DELETE_ABSENCE_FAILED = 'DELETE_ABSENCE_FAILED';

export const FETCHING_SHIFTS = 'FETCHING_SHIFTS';
export const FETCH_SHIFTS_SUCCESS = 'FETCH_SHIFTS_SUCCESS';
export const FETCH_SHIFTS_FAILED = 'FETCH_SHIFTS_FAILED';
export const FETCH_SHIFTS_FINISHED = 'FETCH_SHIFTS_FINISHED';
export const DELETING_SHIFT = 'DELETING_SHIFT';
export const DELETE_SHIFT_SUCCESS = 'DELETE_SHIFT_SUCCESS';
export const DELETE_SHIFT_FAILED = 'DELETE_SHIFT_FAILED';

export const FETCHING_PROPOSALS = 'FETCHING_PROPOSALS';
export const FETCH_PROPOSALS_SUCCESS = 'FETCH_PROPOSALS_SUCCESS';
export const FETCH_PROPOSALS_FAILED = 'FETCH_PROPOSALS_FAILED';
export const FETCH_PROPOSALS_FINISHED = 'FETCH_PROPOSALS_FINISHED';
export const DELETING_PROPOSAL = 'DELETING_PROPOSAL';
export const DELETE_PROPOSAL_SUCCESS = 'DELETE_PROPOSAL_SUCCESS';
export const DELETE_PROPOSAL_FAILED = 'DELETE_PROPOSAL_FAILED';
export const FETCH_PROPOSALS_STARTED = 'FETCH_PROPOSALS_STARTED';
export const FETCH_PROPOSALS_NEXT_SUCCESS = 'FETCH_PROPOSALS_NEXT_SUCCESS';

export const FETCHING_NOTIFICATIONS = 'FETCHING_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILED = 'FETCH_NOTIFICATIONS_FAILED';
export const FETCH_NOTIFICATIONS_FINISHED = 'FETCH_NOTIFICATIONS_FINISHED';

export const FETCHING_BILLS = 'FETCHING_BILLS';
export const FETCH_BILLS_SUCCESS = 'FETCH_BILLS_SUCCESS';
export const FETCH_BILLS_FAILED = 'FETCH_BILLS_FAILED';
export const FETCH_BILLS_FINISHED = 'FETCH_BILLS_FINISHED';
export const FETCHING_BILL = 'FETCHING_BILL';
export const FETCH_BILL_SUCCESS = 'FETCH_BILL_SUCCESS';
export const FETCH_BILL_FAILED = 'FETCH_BILL_FAILED';
export const FETCH_BILL_FINISHED = 'FETCH_BILL_FINISHED';

export const REFRESH_CUSTOM_DATA = 'REFRESH_CUSTOM_DATA';

export const refreshCustomData = key => ({ type: REFRESH_CUSTOM_DATA, key });

export const setLoadingEvent = id => async (dispatch) => {
  dispatch({ type: SET_LOADING_EVENT_ID, id });
};

export const fetchEvent = (id, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_EVENT, sourceToken, id });
  try {
    const event = await Model.Events.fetch(id, sourceToken);
    dispatch({ type: FETCH_EVENT_SUCCESS, event });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_EVENT_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_EVENT_FINISHED, sourceToken });
    }
  }
};

export const deleteEvent = id => async (dispatch) => {
  dispatch({ type: DELETING_EVENT });
  try {
    dispatch({ type: DELETE_EVENT_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_EVENT_FAILED });
  }
};

export const fetchEvents = async (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_EVENTS, fetchParams, sourceToken });
  try {
    const [events, pagination] = await Model.Events.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({ type: FETCH_EVENTS_SUCCESS, events, eventsPagination: pagination, fetchParams });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_EVENTS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_EVENTS_FINISHED, sourceToken });
    }
  }
};

export const fetchMoreEvents = (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCH_EVENTS_STARTED, sourceToken });
  try {
    const [events, pagination] = await Model.Events.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({ type: FETCH_EVENTS_NEXT_SUCCESS, events, pagination, fetchParams });
  } catch (e) {
    if (!axios.isCancel(e)) {
      dispatch({ type: FETCH_EVENTS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_EVENTS_FINISHED, sourceToken });
    }
  }
};

export const fetchCombinedEvents = async (fetchParamsCombined, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_EVENTS, fetchParams: fetchParamsCombined, sourceToken });
  try {
    const [eventsClosest] = await Model.Events.fetchAll({
      ...fetchParamsCombined[0],
      cancelToken: sourceToken,
    });

    dispatch({ type: FETCH_EVENTS_FIRST_PART_SUCCESS, events: eventsClosest, fetchParams: fetchParamsCombined });

    const [events_month] = await Model.Events.fetchAll({
      ...fetchParamsCombined[1],
      cancelToken: sourceToken,
    });

    let events = unionBy(eventsClosest, events_month, 'id');
    events = orderBy((events || []), 'id');

    dispatch({ type: FETCH_EVENTS_SUCCESS, events, fetchParams: fetchParamsCombined });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_EVENTS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_EVENTS_FINISHED, sourceToken });
    }
  }
};

export const fetchHorse = (id, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_HORSE, sourceToken });
  try {
    const horse = await Model.Horses.fetch(id, sourceToken);
    dispatch({ type: FETCH_HORSE_SUCCESS, horse });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_HORSE_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_HORSE_FINISHED, sourceToken });
    }
  }
};

export const deleteHorse = id => async (dispatch) => {
  dispatch({ type: DELETING_HORSE });
  try {
    dispatch({ type: DELETE_HORSE_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_HORSE_FAILED });
  }
};

export const fetchHorses = async (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_HORSES, fetchParams, sourceToken });
  try {
    const [horses, pagination] = await Model.Horses.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({ type: FETCH_HORSES_SUCCESS, horses, horsesPagination: pagination, fetchParams });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_HORSES_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_HORSES_FINISHED, sourceToken });
    }
  }
};

export const fetchPlace = (id, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_PLACE, sourceToken });
  try {
    const place = await Model.Places.fetch(id, sourceToken);
    dispatch({ type: FETCH_PLACE_SUCCESS, place });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_PLACE_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_PLACE_FINISHED, sourceToken });
    }
  }
};

export const deletePlace = id => async (dispatch) => {
  dispatch({ type: DELETING_PLACE });
  try {
    dispatch({ type: DELETE_PLACE_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_PLACE_FAILED });
  }
};

export const fetchPlaces = async (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_PLACES, fetchParams, sourceToken });
  try {
    const [places, pagination] = await Model.Places.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({ type: FETCH_PLACES_SUCCESS, places, placesPagination: pagination, fetchParams });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_PLACES_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_PLACES_FINISHED, sourceToken });
    }
  }
};

export const fetchRatings = async (model, id, fetchParams, sourceToken) => async (dispatch) => {
  dispatch({
    type: FETCHING_RATINGS,
    fetchParams: {
      model,
      id,
      ...fetchParams,
    },
    sourceToken,
  });
  try {
    const ratingsModel = await model.fetch(id, sourceToken);
    const [ratings, pagination] = await model.fetchRatings(id, {
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({
      type: FETCH_RATINGS_SUCCESS,
      ratings,
      ratingsPagination: pagination,
      fetchParams: {
        model,
        id,
        ...fetchParams,
      },
      ratingsModel,
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_RATINGS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_RATINGS_FINISHED, sourceToken });
    }
  }
};

export const deleteRating = id => async (dispatch) => {
  dispatch({ type: DELETING_RATING });
  try {
    dispatch({ type: DELETE_PLACE_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_PLACE_FAILED });
  }
};

export const fetchEventType = (id, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_EVENT_TYPE, sourceToken });
  try {
    const eventType = await Model.EventTypes.fetch(id, sourceToken);
    dispatch({ type: FETCH_EVENT_TYPE_SUCCESS, eventType });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_EVENT_TYPE_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_EVENT_TYPE_FINISHED, sourceToken });
    }
  }
};

export const deleteEventType = id => async (dispatch) => {
  dispatch({ type: DELETING_EVENT_TYPE });
  try {
    dispatch({ type: DELETE_EVENT_TYPE_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_EVENT_TYPE_FAILED });
  }
};

export const fetchEventTypes = async (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_EVENT_TYPES, fetchParams, sourceToken });
  try {
    const [eventTypes, pagination] = await Model.EventTypes.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({ type: FETCH_EVENT_TYPES_SUCCESS, eventTypes, eventTypesPagination: pagination, fetchParams });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_EVENT_TYPES_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_EVENT_TYPES_FINISHED, sourceToken });
    }
  }
};

export const fetchUser = (id, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_USER, sourceToken });
  try {
    const user = await Model.Members.fetch(id, sourceToken);
    dispatch({ type: FETCH_USER_SUCCESS, user });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_USER_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_USER_FINISHED, sourceToken });
    }
  }
};

export const deleteUser = id => async (dispatch) => {
  dispatch({ type: DELETING_USER });
  try {
    dispatch({ type: DELETE_USER_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_USER_FAILED });
  }
};

export const fetchUsers = async (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_USERS, fetchParams, sourceToken });
  try {
    const [users, pagination] = await Model.Members.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({ type: FETCH_USERS_SUCCESS, users, usersPagination: pagination, fetchParams });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_USERS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_USERS_FINISHED, sourceToken });
    }
  }
};

export const fetchGroup = (id, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_GROUP, sourceToken });
  try {
    const group = await Model.MemberGroups.fetch(id, sourceToken);
    dispatch({ type: FETCH_GROUP_SUCCESS, group });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_GROUP_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_GROUP_FINISHED, sourceToken });
    }
  }
};

export const deleteGroup = id => async (dispatch) => {
  dispatch({ type: DELETING_GROUP });
  try {
    dispatch({ type: DELETE_GROUP_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_GROUP_FAILED });
  }
};

export const fetchGroups = async (fetchParams, sourceToken, fetchMine) => async (dispatch) => {
  dispatch({
    type: FETCHING_GROUPS,
    sourceToken,
    fetchParams: {
      fetchMine,
      ...fetchParams,
    },
  });
  try {
    let groups;
    let pagination;
    if (fetchMine) {
      [groups, pagination] = await Model.MemberGroups.fetchMineGroups({
        ...fetchParams,
        cancelToken: sourceToken,
      });
    } else {
      [groups, pagination] = await Model.MemberGroups.fetchAll({
        ...fetchParams,
        cancelToken: sourceToken,
      });
    }
    dispatch({
      type: FETCH_GROUPS_SUCCESS,
      groups,
      groupsPagination: pagination,
      fetchParams: {
        fetchMine,
        ...fetchParams,
      },
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_GROUPS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_GROUPS_FINISHED, sourceToken });
    }
  }
};

export const fetchFarm = sourceToken => async (dispatch) => {
  dispatch({ type: FETCHING_FARM, sourceToken });
  try {
    const farm = await Model.Farms.fetch(0, sourceToken);
    dispatch({ type: FETCH_FARM_SUCCESS, farm });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_FARM_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_FARM_FINISHED, sourceToken });
    }
  }
};

export const fetchSingleNews = (id, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_SINGLE_NEWS, sourceToken });
  try {
    const news = await Model.News.fetch(id, sourceToken);
    dispatch({ type: FETCH_SINGLE_NEWS_SUCCESS, news });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_SINGLE_NEWS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_SINGLE_NEWS_FINISHED, sourceToken });
    }
  }
};

export const deleteNews = id => async (dispatch) => {
  dispatch({ type: DELETING_NEWS });
  try {
    dispatch({ type: DELETE_NEWS_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_NEWS_FAILED });
  }
};

export const publishNews = id => async (dispatch) => {
  dispatch({ type: PUBLISHING_NEWS });
  try {
    dispatch({ type: PUBLISH_NEWS_SUCCESS, id });
  } catch (error) {
    dispatch({ type: PUBLISH_NEWS_FAILED });
  }
};

export const fetchNews = async (fetchParams, sourceToken, drafts) => async (dispatch) => {
  dispatch({ type: FETCHING_NEWS, fetchParams, sourceToken });
  try {
    const [news, pagination] = await Model.News.fetchAll({
      drafts,
      cancelToken: sourceToken,
      ...fetchParams,
    });
    dispatch({ type: FETCH_NEWS_SUCCESS, news, newsPagination: pagination, fetchParams });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_NEWS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_NEWS_FINISHED, sourceToken });
    }
  }
};

export const fetchShifts = async (id, fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_SHIFTS, fetchParams: { id, ...fetchParams }, sourceToken });
  try {
    const [shifts, pagination] = await Model.Members.getShifts(
      id,
      {
        ...fetchParams,
      },
      sourceToken,
    );
    dispatch({ type: FETCH_SHIFTS_SUCCESS, shifts, shiftsPagination: pagination, fetchParams: { id, ...fetchParams } });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_SHIFTS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_SHIFTS_FINISHED, sourceToken });
    }
  }
};

export const deleteShift = id => async (dispatch) => {
  dispatch({ type: DELETING_SHIFT });
  try {
    dispatch({ type: DELETE_SHIFT_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_SHIFT_FAILED });
  }
};

export const fetchAbsences = async (id, fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_ABSENCES, fetchParams: { id, ...fetchParams }, sourceToken });
  try {
    const [absences, pagination] = await Model.Horses.getAbsences(
      id,
      {
        ...fetchParams,
      },
      sourceToken,
    );
    dispatch({
      type: FETCH_ABSENCES_SUCCESS,
      absences,
      absencesPagination: pagination,
      fetchParams: { id, ...fetchParams },
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_ABSENCES_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_ABSENCES_FINISHED, sourceToken });
    }
  }
};

export const deleteAbsence = id => async (dispatch) => {
  dispatch({ type: DELETING_ABSENCE });
  try {
    dispatch({ type: DELETE_ABSENCE_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_ABSENCE_FAILED });
  }
};

export const fetchProposals = async (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_PROPOSALS, fetchParams, sourceToken });
  try {
    const [proposals, pagination] = await Model.Proposals.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({ type: FETCH_PROPOSALS_SUCCESS, proposals, proposalsPagination: pagination, fetchParams });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_PROPOSALS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_PROPOSALS_FINISHED, sourceToken });
    }
  }
};

export const fetchMoreProposals = (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCH_PROPOSALS_STARTED, sourceToken });
  try {
    const [proposals, pagination] = await Model.Proposals.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({ type: FETCH_PROPOSALS_NEXT_SUCCESS, proposals, pagination, fetchParams });
  } catch (e) {
    if (!axios.isCancel(e)) {
      dispatch({ type: FETCH_PROPOSALS_FINISHED, sourceToken });
    } else {
      dispatch({ type: FETCH_PROPOSALS_FINISHED, sourceToken });
    }
  }
};

export const fetchBills = async (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_BILLS, fetchParams, sourceToken });
  try {
    const [bills, pagination] = await Model.Bills.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });

    dispatch({ type: FETCH_BILLS_SUCCESS, bills, billsPagination: pagination, fetchParams });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_BILLS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_BILLS_FINISHED, sourceToken });
    }
  }
};

export const fetchBill = (id, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_BILL, sourceToken, id });
  try {
    const bill = await Model.Bills.fetch(id, sourceToken);
    dispatch({ type: FETCH_BILL_SUCCESS, bill });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_BILL_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_BILL_FINISHED, sourceToken });
    }
  }
};

export const deleteProposal = id => async (dispatch) => {
  dispatch({ type: DELETING_PROPOSAL });
  try {
    dispatch({ type: DELETE_PROPOSAL_SUCCESS, id });
  } catch (error) {
    dispatch({ type: DELETE_PROPOSAL_FAILED });
  }
};

export const fetchNotifications = async (fetchParams, sourceToken) => async (dispatch) => {
  dispatch({ type: FETCHING_NOTIFICATIONS, fetchParams, sourceToken });
  try {
    const [notifications, pagination] = await Model.Notifications.fetchAll({
      ...fetchParams,
      cancelToken: sourceToken,
    });
    dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, notifications, notificationsPagination: pagination, fetchParams });
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({ type: FETCH_NOTIFICATIONS_FAILED, sourceToken });
    } else {
      dispatch({ type: FETCH_NOTIFICATIONS_FINISHED, sourceToken });
    }
  }
};
