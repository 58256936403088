import { useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { cloneDeep } from 'lodash';

import FloatingButton from '@old/components/custom/FloatingButton';
import Calendar from '@old/components/view/calendar/Calendar';
import PageTitle from '@old/components/guide/PageTitle';
import ViewPager from '@old/components/guide/ViewPager';
import { useTabs } from '@old/hooks';
import EventsProvider from '@old/components/custom/EventsProvider';
import { useAddEventButton } from 'old/buttonHooks';
import { getCalendarKey } from 'old/utils';
import config from '@old/config';
import t from 'resources/translations';
import { useOldLoggedMember } from 'utils/storeUtils';

const EventsPage = ({ location, calendarDayStart }) => {
  const loggedMember = useOldLoggedMember();
  const addEvent = useAddEventButton({
    initState: {
      initValues: {
        startDate: calendarDayStart,
        instructors: loggedMember.isInstructor() ? [{ value: loggedMember.id, label: loggedMember.getFullName() }] : [],
      },
    },
  });
  const defaultFetchParams = {
    sorted_by: 'start_at_asc',
    start_after: moment().format('YYYY-MM-DD'),
  };

  const tabs = useMemo(
    () => [
      {
        name: t('eventsPage.calendar'),
        view: Calendar,
        props: {
          defaultMode: 'month',
          onlyDefaultMode: true,
          hideWeekSelect: true,
          list: true,
          switchModeOnClick: false,
          fetchParams: {
            with_status: ['awaiting', 'ongoing', 'active', 'finished'],
          },
        },
        permissionKey: 'events.index',
      },
      {
        name: t('eventsPage.list'),
        view: EventsProvider,
        props: {
          pagKey: 'eventsList',
          fetchParams: { ...defaultFetchParams },
          showDateLabels: true,
        },
        permissionKey: 'events.index', // ?
        active: loggedMember.isOwner(),
      },
      {
        name: t('eventsPage.myEvents'),
        view: EventsProvider,
        props: {
          pagKey: 'eventsMine',
          fetchParams: {
            ...defaultFetchParams,
            mine: true,
            with_participation_status: !loggedMember.isInstructor() ? 'joined' : null,
          },
          showDateLabels: true,
        },
        permissionKey: 'events.mine', // ?
        active: !loggedMember.isOwner(),
      },
      {
        name: t('eventsPage.invitations'),
        view: EventsProvider,
        props: {
          pagKey: 'eventsInvitations',
          fetchParams: {
            ...defaultFetchParams,
            mine: true,
            with_participation_status: 'invited',
          },
          showDateLabels: true,
        },
        permissionKey: 'events.mine', // ?
        active: loggedMember.isClient(),
      },
    ],
    [location, loggedMember] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const userTabs = useTabs(tabs);

  return (
    <>
      <PageTitle title={t('model.events')} />
      <ViewPager tabs={userTabs} />
      <FloatingButton buttons={[addEvent]} />
    </>
  );
};

const mapStateToProps = ({ calendar }, props) => {
  const calendarKey = getCalendarKey(props.location.pathname);
  const calendarState = cloneDeep(calendar[calendarKey] || calendar.default);

  return {
    calendarDayStart: calendarState.dayStart
      .hour(moment().hour() + 1)
      .startOf('hour')
      .format(config.dateTimeFormat),
    calendarState,
  };
};

export default connect(mapStateToProps)(EventsPage);
