/**
 * @module AddParticipantModal
 */

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import Button from '@old/components/guide/Button';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import FlexRow from '@old/components/common/FlexRow';
import SearchInput from '@old/components/custom/SearchInput';
import OptionLoader from '@old/components/custom/OptionLoader';
import OptionsList from '@old/components/view/list/Options';
import Option from '@old/components/view/listItem/SelectOption';
import t from 'resources/translations';
import { getErrorMessage, notify } from 'old/utils';
import { useAddParticipantsLogic } from '@old/hooks';

/**
 * A component that displays a modal window for adding participant to event. Displays list of:
 * - participants available for selection (can be added),
 * - participants already joined to event displayed as disabled option,
 * - participants unavailable for selection displayed ad disabled option,
 * - groups of members - clicking on that option will selects/deselects all options available for selection/deselection.
 * Use only if event is stored in redux, otherwise event will not be refreshed after adding participant.
 * @param  {Model.Event} [props.event] Contains Event data
 * @param  {Function} props.onClose Modal closing function. The parameter must contain the name of the modal.
 */
const AddParticipantModal = ({ event, onClose, cb }) => {
  const [
    query,
    changeQuery,
    fetchMore,
    isOptionSelected,
    isOptionDisabled,
    selectedParticipants,
    setSelectedParticipants,
    newParticipantsIds,
    options,
    fetchOptionStatus,
    toggleSelectedOption,
  ] = useAddParticipantsLogic({
    eventIsPast: event && event.isPast(),
    joinedParticipantsIds: event ? event.getJoinedParticipantsIds(false) : [],
    joinedParticipantsOptions: event ? event.getJoinedParticipantsOptions(false) : [],
    participantsIds: event ? event.getParticipantsIds(false) : [],
  });

  const addParticipants = async () => {
    if (event) {
      try {
        await Model.Events.addParticipants(
          event.id,
          selectedParticipants.map(option => option.value)
        );
        cb();
        setSelectedParticipants([]);
        onClose();
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      }
    }
  };

  const limitExceeded = event ? newParticipantsIds.length > event.attendees.max : false;

  return (
    <Modal header={t('model.add.participants')} onClose={onClose} isOpen>
      <SearchInput
        name="membersSearch"
        label={t('general.search')}
        value={query}
        onChange={changeQuery}
        clear={() => changeQuery('')}
        placeholder={t('placeholders.searchByName')}
      />
      <OptionsList fetchMore={fetchMore}>
        {options.map(option => {
          const isSelected = isOptionSelected(option);
          const isDisabled = isOptionDisabled(option);
          const onClick = !isDisabled ? () => toggleSelectedOption(option, isSelected) : () => { };
          const extendedLabel = option.isGroup ? option.getExtendedLabel(newParticipantsIds) : null;
          return (
            <Option.MembersAndGroups
              key={option.key}
              data={{ ...option, extendedLabel }}
              innerProps={{ onClick }}
              isSelected={isSelected}
              isDisabled={isDisabled}
            />
          );
        })}
        {fetchOptionStatus.isPending && <OptionLoader />}
      </OptionsList>
      {limitExceeded && (
        <ul className="errors-hint">
          <li className="text-warning">{t('addParticipantModal.limitExceeded')}</li>
        </ul>
      )}
      <FlexRow separated="small" noShrink>
        <ButtonOutline onClick={onClose} fluid>
          {t('general.cancel')}
        </ButtonOutline>
        <Button onClick={addParticipants} disabled={limitExceeded} fluid async>
          {t('general.add')}
        </Button>
      </FlexRow>
    </Modal>
  );
};

AddParticipantModal.defaultProps = {
  event: null,
};

export default AddParticipantModal;
