import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { connect } from 'react-redux';
import cls from 'classnames';

const OptionsList = ({ fetchMore, children, isMobile }) => {
  const throttleEventHandler = (...args) => {
    const throttled = throttle(...args);
    return (e) => {
      e.persist();
      return throttled(e);
    };
  };

  const onScroll = throttleEventHandler(fetchMore, 50);

  return (
    <div
      className={cls('select-list-container', { mobile: isMobile })}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
};

OptionsList.propTypes = {
  fetchMore: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(OptionsList);
