/* eslint-disable max-len */
import React from 'react';

const Coin = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <g transform="translate(-516 -461)">
        <path d="M528.2,472c0,0.9-0.3,1.7-0.9,2.3c-0.7,0.6-1.5,1-2.4,1.1v1.6h-1.4v-1.6c-1,0-2-0.5-2.7-1.3c-0.7-0.7-1-1.7-1-2.7h2.4
          c0,0.6,0.2,1.1,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5c0.5,0,0.8-0.1,1.2-0.4c0.5-0.6,0.7-1.4,0-2.1c-0.4-0.3-0.8-0.6-1.3-0.8
          c-0.6-0.3-1.2-0.5-1.6-0.7c-0.3-0.2-0.7-0.4-1-0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.5-0.6-0.6-0.9c-0.2-0.5-0.3-1-0.3-1.5
          c0-0.9,0.3-1.7,0.9-2.3c0.7-0.6,1.5-1,2.4-1.1V461h1.4v1.8c2,0.3,3.4,2,3.2,4h-2.4c0-0.5-0.1-1.1-0.4-1.6c-0.4-0.4-0.7-0.5-1.2-0.5
          c-0.7-0.1-1.4,0.5-1.5,1.2c0,0.1,0,0.2,0,0.2c0.1,0.5,0.3,0.8,0.6,1c0.5,0.3,0.9,0.6,1.4,0.8c0.6,0.2,1.1,0.5,1.6,0.8
          c0.4,0.2,0.7,0.5,1.1,0.8c0.3,0.3,0.5,0.7,0.7,1.1C528.2,471.2,528.2,471.4,528.2,472z"
        />
      </g>
    </svg>
  );
};

export default Coin;
/* eslint-enable max-len */
