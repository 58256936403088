import React, { useRef } from 'react';
import { compact } from 'lodash';
import { List, Flex, Ternary, Button } from '../elements';
import { Divider, IconButton } from '../combinations';
import { TBoxProps, TListProps } from 'resources/types/elementsTypes';
import { TReactNode } from 'resources/types/commonTypes';
import { useDisclosure, useOutsideClick } from 'utils';

const styleProps = (isOpen = false) =>
  ({
    backgroundColor: 'white',
    position: 'absolute',
    right: 0,
    top: '100%',
    overflow: 'hidden',
    display: isOpen ? 'flex' : 'none',
    flexDirection: 'column',
    height: 'auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 200,
    borderRadius: 5,
    width: 'max-content',
    color: 'teal-light',
  } as TListProps);

type TContextMenu = TBoxProps & {
  children: TReactNode;
  trigger?: TReactNode;
};
export const ContextMenuContext = React.createContext({});

const ContextMenu = ({ children, trigger = null, ...boxProps }: TContextMenu) => {
  const ref = useRef<HTMLDivElement>(null);
  const disclosure = useDisclosure();
  const childrens = Array.isArray(children) ? children : [children];

  useOutsideClick(ref, disclosure.onClose);

  if (compact(childrens).length === 0) return null;

  return (
    <ContextMenuContext.Provider value={disclosure}>
      <Flex ref={ref} position="relative" width="initial" flex="none" {...boxProps}>
        <Ternary cond={!!trigger}>
          <Button variant="clean" onClick={disclosure.onToggle}>
            {trigger}
          </Button>
          <IconButton icon="MoreVertical" onClick={disclosure.onToggle} />
        </Ternary>
        <List {...styleProps(disclosure.isOpen)}>
          {childrens.length === 1
            ? children
            : React.Children.map(
                childrens,
                (child, index) =>
                  child && [
                    child,
                    index + 1 !== childrens.length && (
                      <Divider orientation="vertical" size="1px" margin={0} color="grey" />
                    ),
                  ]
              )}
        </List>
      </Flex>
    </ContextMenuContext.Provider>
  );
};

ContextMenu.defaultProps = {
  trigger: null,
};

export default ContextMenu;
