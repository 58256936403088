import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';

import Link from '@old/components/common/Link';
import PageTitle from '@old/components/guide/PageTitle';
import Allow from '@old/components/common/Allow';
import PlaceStatisticsPieChart from '@old/components/view/charts/PlaceStatisticsPieChart';
import SelectDateRangeModal from '@old/components/modals/SelectDateRange';
import FlexRow from '@old/components/common/FlexRow';
import Spinner from '@old/components/common/Spinner';
import Box from '@old/components/common/Box';
import Icon from '@old/components/icon';
import {
  usePlaceEditButton,
  usePlaceDeleteButton,
  useAddPictureButton,
  useAddEventButton,
  useAddProposalButton,
} from 'old/buttonHooks';
import GalleryViewer from '@old/components/modals/GalleryViewer';
import t from 'resources/translations';
import FloatingButton from '@old/components/custom/FloatingButton';
import Center from '@old/components/common/Center';
import FlexColumn from '@old/components/common/FlexColumn';
import OverflowItem from '@old/components/common/OverflowItem';
import Error404 from '@old/components/error/Error404';
import RowAligner from '@old/components/common/RowAligner';
import BoxSwitcher from '@old/components/common/BoxSwitcher';
import ColorBar from '@old/components/common/ColorBar';
import Message from '@old/components/common/Message';
import { fetchPlace } from 'store/actions';
import Calendar from '@old/components/view/calendar/Calendar';
import EditableAvatar from '@old/components/common/editable/EditableAvatar';
import { useLoggedMember } from 'utils/storeUtils';

const PlaceDetails = ({ match, isMobile, loading, error, place, ...actions }) => {
  const loggedMember = useLoggedMember();
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchPlace(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  const BoxGalery = loggedMember.isOwner() ? BoxSwitcher : Box;

  const placeEdit = usePlaceEditButton(place);
  const placeDelete = usePlaceDeleteButton(place, { goBack: true });
  const addPicture = useAddPictureButton(place);
  const addEvent = useAddEventButton({
    ...(place
      ? {
          initState: {
            initValues: {
              places: { value: place.id, label: place.getName() },
              instructors: loggedMember.isInstructor()
                ? [{ value: loggedMember.id, label: loggedMember.getFullName() }]
                : [],
            },
          },
        }
      : {}),
    label: t('buttons.addEventWithPlace'),
  });
  const addProposal = useAddProposalButton({
    ...(place
      ? {
          initState: {
            initValues: {
              places: { value: place.id, label: place.getName() },
              participations: loggedMember.isClient()
                ? [{ value: loggedMember.id, label: loggedMember.getFullName() }]
                : [],
            },
          },
        }
      : {}),
    label: t('buttons.addProposalWithPlace'),
  });

  // [API-3.0] [Start] look at this after API 3.0 is released
  const placeButtons = place && !place.isDeleted() ? [addEvent, addProposal, placeEdit, placeDelete, addPicture] : [];
  // [API-3.0] [End] look at this after API 3.0 is released

  const [pieChartDateRange, pieChartSetDateRange] = useState({
    start: moment().subtract(30, 'days').startOf('day'),
    end: moment().endOf('day'),
  });

  if (error) return <Error404 />;
  if (loading && !place) return <Spinner />;
  if (!place) return null;

  return (
    <div>
      <PageTitle title={place.getName()} back loading={loading} />
      {place.isDeleted() && (
        <Message deleted>
          <RowAligner>
            <Icon.WarningOutline className="fill-teal" />
            <span>{t('model.deleteNotify.place')}</span>
          </RowAligner>
        </Message>
      )}
      <FlexRow marginBottom cols={loggedMember.isOwner() ? '10/6' : '1'} stretched>
        <FlexRow cols="none/1" stretched separated={false}>
          {!isMobile && (
            <div className="py-16 px-2 bg-grey text-center">
              <ColorBar color={place.getColor()} vertical />
            </div>
          )}
          <BoxSwitcher>
            <FlexColumn stretched>
              {isMobile ? (
                <FlexRow>
                  <Center>
                    <EditableAvatar modelItem={place} refreshAction={actions.fetchPlace} />
                  </Center>
                  <Center>
                    <ColorBar color={place.getColor()} />
                  </Center>
                </FlexRow>
              ) : (
                <EditableAvatar modelItem={place} refreshAction={actions.fetchPlace} />
              )}
              <div>
                <RowAligner>
                  <div className="text-highlight">{t('labels.slug')}:</div>
                  <div className="leading-tight uppercase">{place.slug}</div>
                </RowAligner>
              </div>
            </FlexColumn>
          </BoxSwitcher>
        </FlexRow>
        <Allow permissionKey="place_statistics.index">
          <Box>
            <Center>
              <FlexColumn>
                <OverflowItem>
                  <Link className="text-link" to={`${match.url}/statistics`}>
                    <Icon.VisibilityOn />
                  </Link>
                </OverflowItem>
                <PlaceStatisticsPieChart placeId={match.params.id} basic dateRange={pieChartDateRange} />
                <SelectDateRangeModal dateRange={pieChartDateRange} onSave={pieChartSetDateRange} />
              </FlexColumn>
            </Center>
          </Box>
        </Allow>
      </FlexRow>
      <FlexColumn>
        {place.pictures.length > 0 ? (
          <BoxGalery>
            <GalleryViewer
              modelItem={place}
              perView={isMobile ? 1 : 6}
              permissionKey="places.update"
              refreshAction={actions.fetchPlace}
            />
          </BoxGalery>
        ) : (
          <GalleryViewer modelItem={place} permissionKey="places.update" refreshAction={actions.fetchPlace} />
        )}
        <Allow permissionKey="events.index">
          <Calendar
            fetchParams={{ with_place: [place.id] }}
            defaultMode="week"
            addEventButton={addEvent}
            addProposalButton={addProposal}
            onlyDefaultMode
            extended
            displayedRange="week"
          />
        </Allow>
      </FlexColumn>
      <FloatingButton buttons={placeButtons} />
    </div>
  );
};

PlaceDetails.defaultProps = {
  place: null,
};

const mapStateToProps = ({ fetchingData: { places, loading, error }, app: { isMobile } }, { match }) => {
  return {
    isMobile,
    place: places.find(place => String(place.id) === String(match.params.id)),
    loading: loading.places,
    error: error.places,
  };
};

export default connect(mapStateToProps, { fetchPlace })(PlaceDetails);
