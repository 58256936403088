import { useTicketOffers, useTickets } from 'actions/ticketOffers';
import { PageTitle } from 'components/combinations';
import { Box, Content, Stack } from 'components/elements';
import FloatingButton from 'old/components/custom/FloatingButton';
import t from 'resources/translations';
import { useIsFetching } from 'react-query';
import {
  PaymentBuyTicketModal,
  PaymentOnlineModal,
  PaymentTicketModal,
  TicketAssignModal,
  TicketOfferDetailsModal,
  TicketRidesEditModal,
  TicketValidityEditModal,
} from 'views/modals';
import TicketOffers from './TicketOffers';
import TicketsUsers from './TicketsUsers';
import { pathIsAllowed } from 'old/utils';
import TicketsList from './TicketsList';
import { ITicketFetchParams, TTicketFilters } from 'resources/types/TicketTypes';
import { TColorsProps } from 'resources/types/styledTypes';
import {} from 'utils';
import {
  useFilters,
  useNewLayoutColor,
  mapTicketsFiltersToParams,
  useLoggedMember,
  useSearchQuery,
  useTabPagination,
} from 'utils';
import Tabs from 'components/combinations/Tabs';
import { APIPagination } from 'resources/types/commonTypes';

const Tickets = () => {
  useNewLayoutColor();
  const search = useSearchQuery();
  const isFetching = useIsFetching();
  const { page, activeTab, setPage, setTab } = useTabPagination(['active', 'unpaid', 'used']);
  const loggedMember = useLoggedMember();

  const listProps: {
    [key: string]: {
      iconColor: TColorsProps;
      params: ITicketFetchParams;
      noResultLabel: string;
    };
  } = {
    active: {
      iconColor: 'teal',
      params: { with_status: 'active', sorted_by: 'created_at_desc' },
      noResultLabel: loggedMember?.isClient()
        ? t('tickets.youArentUsingTickets')
        : t('tickets.clientsArentUsingTickets'),
    },
    unpaid: {
      iconColor: 'orange',
      params: { with_status: 'unpaid', sorted_by: 'created_at_desc' },
      noResultLabel: loggedMember?.isClient()
        ? t('tickets.yourPaymentsForTicketsSettled')
        : t('tickets.paymentsForTicketsAreSettled'),
    },
    used: {
      iconColor: 'grey',
      params: { with_status: 'used', sorted_by: 'created_at_desc' },
      noResultLabel: loggedMember?.isClient() ? t('tickets.youDidntUseTickets') : t('tickets.clientsDidNotUseTickets'),
    },
  };
  const { params, noResultLabel, iconColor } = listProps[activeTab];
  const { filters } = useFilters<TTicketFilters>('TICKETS');
  const [ticketOffersState, ticketOffers] = useTicketOffers('TICKET_OFFERS', { per_page: 9999 });
  const ticketsParams = {
    page,
    per_page: 24,
    ...params,
    keyword: search.searchQuery,
    ...mapTicketsFiltersToParams(filters),
  };
  const [ticketsState, tickets] = useTickets(['TICKETS', ticketsParams], ticketsParams, {
    onSuccess: ({ pagination }: { pagination: APIPagination }) => {
      if (pagination.pages !== 0 && page > pagination.pages) {
        setPage(pagination.pages);
      }
    },
  });

  const tabs = {
    active: {
      label: t('tickets.inUse'),
      onClick: () => {
        setTab('active');
      },
    },
    unpaid: {
      label: t('tickets.unpaid'),
      onClick: () => {
        setTab('unpaid');
      },
    },
    used: {
      label: t('tickets.used'),
      onClick: () => {
        setTab('used');
      },
    },
  };

  return (
    <Content>
      <Stack>
        <PageTitle title={t('menu.tickets')} loading={!!isFetching} />
        <Stack spacing="xl">
          <TicketOffers isLoading={ticketOffersState.isLoading} ticketOffers={ticketOffers} />
          <TicketsUsers>
            <Box w="full" my="md" px={['sm', 'md']}>
              <Tabs tabs={tabs} activeTab={activeTab} />
            </Box>
            <TicketsList
              isLoading={ticketsState.isLoading}
              isFetching={ticketsState.isFetching}
              tickets={tickets || []}
              iconColor={iconColor}
              noResultLabel={noResultLabel}
              pagination={ticketsState.data?.pagination}
              search={search}
            />
          </TicketsUsers>
        </Stack>
        {pathIsAllowed('/tickets/add') && (
          <FloatingButton
            buttons={[
              {
                key: 'addTicketOffer',
                label: t('model.add.ticketOffer'),
                to: '/tickets/add',
                iconName: 'Add',
              },
            ]}
          />
        )}
      </Stack>
      <TicketValidityEditModal />
      <TicketOfferDetailsModal />
      <TicketRidesEditModal />
      <TicketAssignModal />
      <PaymentTicketModal />
      <PaymentBuyTicketModal />
      <PaymentOnlineModal />
    </Content>
  );
};

export default Tickets;
