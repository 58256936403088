import { space, color, layout, shadow, typography, position, border, system, compose, variant } from 'styled-system';

export const baseStyledProps = compose(
  space,
  system({
    marginInlineStart: {
      properties: ['marginInlineStart'],
      scale: 'space',
    },
    w: {
      properties: ['width'],
      scale: 'space',
    },
    h: {
      properties: ['height'],
      scale: 'space',
    },
    minW: {
      properties: ['minWidth'],
      scale: 'space',
    },
    maxW: {
      properties: ['maxWidth'],
      scale: 'space',
    },
    minH: {
      properties: ['minHeight'],
      scale: 'space',
    },
    maxH: {
      properties: ['maxHeight'],
      scale: 'space',
    },
    cursor: true,
    willChange: true,
    userSelect: true,
  })
);

export const typographyStyleProps = compose(
  typography,
  color,
  system({
    lineClamp: {
      // @ts-ignore
      properties: ['-webkit-line-clamp', '-moz-line-clamp'],
    },
    boxOrient: {
      // @ts-ignore
      properties: ['-webkit-box-orient', '-moz-box-orient'],
    },
    textOverflow: true,
    overflow: true,
    wordBreak: true,
    visibility: true,
    whiteSpace: true,
    textTransform: true,
  })
);

export const blockStyleProps = compose(
  layout,
  border,
  shadow,
  position,
  system({
    radius: {
      properties: ['borderRadius'],
      scale: 'borderRadius',
    },
    squareSize: {
      properties: ['width', 'minWidth', 'maxWidth', 'height', 'minHeight', 'maxHeight'],
      scale: 'space',
    },
    bgColor: {
      properties: ['backgroundColor'],
      scale: 'colors',
    },
    borderTopRadius: {
      properties: ['borderTopLeftRadius', 'borderTopRightRadius'],
      scale: 'space',
    },
    borderBottomRadius: {
      properties: ['borderBottomLeftRadius', 'borderBottomRightRadius'],
      scale: 'space',
    },
    visibility: true,
  })
);

export const linkStyleProps = compose(
  baseStyledProps,
  typographyStyleProps,
  variant({
    variants: {
      primary: {
        '&:focus': {
          color: 'teal-dark',
        },
        color: 'teal-light',
        '&:hover': {
          color: 'teal-dark',
        },
      },
    },
  })
);
