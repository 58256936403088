/* eslint-disable */
import React from 'react';

const HorseAloneCircular = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 50 50"
    >
      <path d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0z M36.7,21.4c-0.1,0-0.1,0-0.2-0.1
        c-0.1-0.1-0.1-0.1-0.2-0.1c-0.4-0.3-2.7-1.6-3.6-0.1c-0.4,0.6-0.6,1.3-0.7,2c-0.1,0.8-0.3,1.5-0.6,2.2l1.6,4
        c0.2,0.3,0.3,0.7,0.1,1.1c0,0-0.7,4.7-2,4c0,0-0.7-0.2-0.6-1.3l0.7-3c-0.6-1-1.4-1.9-2.3-2.7l-0.6,7.1c0,0-0.1,1.1-0.7,1.2
        c0,0-0.7,0.1-1-1.2l-0.2-6.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.8,0.1-1.6,0.1-2.4-0.1h-0.3l-1-0.2c0.3,0.7,0.6,1.4,0.7,2.1
        c0,0-0.4,4.8-2.1,4.7c0,0-1.4-0.3-0.4-2.1l0.6-2.1l-1.9-2.2v2.4c0,0-0.6,5.2-1.9,5.1c0,0-1.1-0.1-0.8-1c0,0,0.4-3.5,0.6-4.2
        c0,0-2.5-5.8-1.5-7.7c0,0-1.2-0.5-0.7,2.7c0,0,1,3.9-0.7,5.6c0.1-1.6-0.2-3.2-1-4.6c0,0-1.8-4.6,3.2-5.1c-0.3,0,1.3-1.5,5.4-0.5
        c0,0,0.1,0,0.3,0c1.1,0.1,4.7,0.3,6.7-2c1.5-1.3,3.2-2.3,5.1-3.1c0,0,1.5-1.6,2.8-1.6c0,0-1.1,1.4-0.3,2.1c0,0,2.4,3.5,2.5,3.8
        C38.2,20.3,38,21.8,36.7,21.4z"
      />
    </svg>
  );
};

export default HorseAloneCircular;
/* eslint-enable */
