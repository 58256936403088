import React from 'react';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import config from '@old/config';
import FlexColumn from '@old/components/common/FlexColumn';
import Popover from '@old/components/common/Popover';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import { getCalendarKey } from 'old/utils';

const { hours, pxPer5Min } = config;

const HoursGrid = ({ hoursList, extraItems, weekView, history, weekStart, addEventButton, addProposalButton }) => {
  const days = weekView ? [0, 1, 2, 3, 4, 5, 6] : [0];
  const border = '1px solid #ccc';
  const standardHourHeight = 12 * pxPer5Min;

  const renderCalendarItem = item => (
    <div key={uniqueId()}>
      {item.special && item.rangeLabel}
      {!item.special && (
        <ButtonSimple onClick={() => history.push(`/events/${item.id}`)} fluid>
          {item.name} - {item.rangeLabel}
        </ButtonSimple>
      )}
    </div>
  );

  const renderCalendarItems = items => (
    <div className="bg-white rounded border border-grey-light text p-2">
      <FlexColumn separated="small">{items.map(renderCalendarItem)}</FlexColumn>
    </div>
  );

  return (
    <React.Fragment>
      <div key="first" className="flex flex-row" style={{ border, borderBottomWidth: 0 }}>
        <div
          style={{
            borderRight: border,
            height: 30,
            lineHeight: '30px',
            flex: '1 0',
            textAlign: 'center',
          }}
        >
          *
        </div>
        <div style={{ flex: '9 0 0', height: 30, zIndex: 1 }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${days.length}, 1fr)`,
              gridTemplateRows: '30px',
              alignItems: 'center',
              justifyItems: 'center',
            }}
          >
            {days.map(dayIndex => {
              const [itemsBefore] = extraItems[dayIndex];
              const key = `itemsBefore-${dayIndex}`;
              const specialItem = itemsBefore.find(item => !!item.special);
              let backgroundColor = '#fff';
              if (specialItem) {
                backgroundColor = specialItem.special === 'absence' ? '#FAD1D1' : '#CDEED9';
              }
              if (itemsBefore.length > 0) {
                return (
                  <div key={key} className="centered h-full" style={{ width: 'calc(100% - 1px)', backgroundColor }}>
                    <Popover content={renderCalendarItems(itemsBefore)}>
                      <div className="rounded-badge text-center">{itemsBefore.length}</div>
                    </Popover>
                  </div>
                );
              }

              return <div key={key} />;
            })}
          </div>
        </div>
      </div>
      {hours.map((item, index) => {
        const { isExpanded } = hoursList[index];
        const height = isExpanded ? standardHourHeight * 3 : standardHourHeight;

        return (
          <div key={item} className="flex flex-row" style={{ border, borderBottomWidth: 0 }}>
            <div style={{ borderRight: border, height, lineHeight: `${height}px`, flex: '1 0', textAlign: 'center' }}>
              {item}
            </div>
            <div style={{ flex: '9 0 0', height }}>
              {(addEventButton || addProposalButton) && (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${days.length}, 1fr)`,
                    gridTemplateRows: height,
                    alignItems: 'center',
                    justifyItems: 'center',
                  }}
                >
                  {days.map(dayIndex => {
                    const dayOfTheWeek = moment(weekStart).isoWeekday(dayIndex + 1);
                    const dateStartEvent = `${dayOfTheWeek.format('DD.MM.YYYY')} ${item}`;
                    return (
                      <div className="add-event-in-calendar" key={dayIndex} style={{ zIndex: '1' }}>
                        <Link
                          className="w-full h-full flex justify-center"
                          to={
                            addEventButton
                              ? {
                                  pathname: addEventButton.to.pathname,
                                  state: {
                                    initValues: { ...addEventButton.to.state.initValues, startDate: dateStartEvent },
                                  },
                                }
                              : {
                                  pathname: addProposalButton.to.pathname,
                                  state: {
                                    initValues: {
                                      ...addProposalButton.to.state.initValues,
                                      startDate: dateStartEvent,
                                    },
                                  },
                                }
                          }
                        >
                          <Icon.Add className="fill-teal-light" small />
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div key="last" className="flex flex-row" style={{ border, borderBottomWidth: 1 }}>
        <div
          style={{
            borderRight: border,
            height: 30,
            lineHeight: '30px',
            flex: '1 0',
            textAlign: 'center',
          }}
        >
          *
        </div>
        <div className="z-30" style={{ flex: '9 0 0', height: 30, position: 'relative', zIndex: 50 }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${days.length}, 1fr)`,
              gridTemplateRows: '30px',
              alignItems: 'center',
              justifyItems: 'center',
            }}
          >
            {days.map(dayIndex => {
              const [, itemsAfter] = extraItems[dayIndex];
              const key = `itemsAfter-${dayIndex}`;
              const specialItem = itemsAfter.find(item => !!item.special);
              let backgroundColor = '#fff';
              if (specialItem) {
                backgroundColor = specialItem.special === 'absence' ? '#FAD1D1' : '#CDEED9';
              }

              if (itemsAfter.length > 0) {
                return (
                  <div key={key} className="centered h-full" style={{ width: 'calc(100% - 1px)', backgroundColor }}>
                    <Popover content={renderCalendarItems(itemsAfter)}>
                      <div className="rounded-badge text-center">{itemsAfter.length}</div>
                    </Popover>
                  </div>
                );
              }

              return <div key={key} />;
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

HoursGrid.defaultProps = {
  weekView: false,
  addEventButton: null,
  addProposalButton: null,
};

const mapStateToProps = ({ calendar }, { location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;

  return {
    weekStart: calendarState.weekStart,
  };
};

export default withRouter(connect(mapStateToProps)(HoursGrid));
