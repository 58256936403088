/* eslint-disable max-len */
import React from 'react';

const AddOutline = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      width="100%"
      className="icon"
    >
      <path d="M8,0c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S3.6,0,8,0z M8.8,4.2H7.2v3.1H4.1v1.5h3.1v3.1h1.6V8.8h3.1V7.3H8.8C8.8,7.3,8.8,4.2,8.8,4.2z" />
    </svg>
  );
};

export default AddOutline;
/* eslint-enable max-len */
