import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import { deleteEvent, fetchEvents } from 'store/actions';
import t from 'resources/translations';
import { getErrorMessage, notify } from 'old/utils';
import FlexColumn from '@old/components/common/FlexColumn';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';

const ConfirmDeleteEventModal = ({
  event,
  history,
  onClose,
  eventsFetchParams,
  redirect,
  cb,
  newCache,
  ...actions
}) => {
  const onSubmit = async () => {
    try {
      await Model.Events.delete(event.id);
      if (!newCache) {
        actions.deleteEvent(event.id);
        actions.fetchEvents(eventsFetchParams);
      } else {
        cb();
      }
      notify(t('model.delete.events'));
      if (redirect) {
        history.push(redirect);
      }
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };
  if (!event) return null;

  return (
    <Modal header={t('confirmDeleteModal.confirmationBeforeDeleting')} onClose={onClose} isOpen>
      <FlexColumn>
        <div>{t('confirmDeleteEventModal.canAffectStatistics')}</div>
        <div>{t('confirmDeleteEventModal.confirmDelete')}</div>
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button name="delete" onClick={onSubmit} fluid async>
            {t('general.delete')}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

ConfirmDeleteEventModal.defaultProps = {
  event: null,
  newCache: false,
  redirect: '',
};

const mapStateToProps = ({ fetchingData: { eventsFetchParams } }) => ({ eventsFetchParams });

export default withRouter(connect(mapStateToProps, { deleteEvent, fetchEvents })(ConfirmDeleteEventModal));
