/**
 * @module Numeric
 */
import React from 'react';
import PropTypes from 'prop-types';

import Button from '@old/components/guide/Button';
import Icon from '@old/components/icon';
import InputWrapper from '@old/components/common/InputWrapper';
import InputWrapperNew from '@old/components/common/InputWrapperNew';
import GroupButton from '@old/components/guide/GroupButton';

/**
 * Component that generate input field adapted for Number value
 * @param  {Number} [props.value]
 * @param  {String} [props.name]
 * @param  {Function} props.onChange
 * @param  {Boolean} [props.placeholder]
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 * @param  {Boolean} [props.spinButtons] if true render buttons to increase and reduce value
 * @param  {Number} [props.min] Specifies the minimum value that can be set in the field
 * @param  {Number} [props.max] Specifies the maximum value that can be set in the field
 */
const Numeric = ({
  value,
  name,
  onChange,
  placeholder,
  label,
  required,
  spinButtons,
  min,
  max,
  disabled,
  newWrapper,
}) => {
  const onInputChange = e => {
    // fixme: deny 'e'
    onChange(e.target.value, name);
  };

  const increaseValue = () => {
    const val = parseInt(value, 10) + 1;
    if (val <= max || !max) {
      onChange(val);
    }
  };

  const lowerValue = () => {
    const val = parseInt(value, 10) - 1;
    if (val >= min || min === null) {
      onChange(val);
    }
  };

  const WrapperComponent = newWrapper ? InputWrapperNew : InputWrapper;

  return (
    <WrapperComponent label={label} required={required} relative disabled={disabled}>
      <input
        type="number"
        name={name}
        value={value}
        onChange={onInputChange}
        placeholder={placeholder}
        onKeyDown={e => e.key === 'e' && e.preventDefault()}
        min={min}
        max={max}
      />
      {spinButtons && (
        <GroupButton vertical>
          <Button disabled={disabled} onClick={increaseValue}>
            <Icon.ArrowUp />
          </Button>
          <Button disabled={disabled} onClick={lowerValue}>
            <Icon.ArrowDown />
          </Button>
        </GroupButton>
      )}
    </WrapperComponent>
  );
};

Numeric.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  label: '',
  required: false,
  spinButtons: false,
  min: null,
  max: null,
  disabled: false,
  newWrapper: false,
};

Numeric.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  required: PropTypes.bool,
  spinButtons: PropTypes.bool,
  disabled: PropTypes.bool,
  newWrapper: PropTypes.bool,
};

export default Numeric;
