import { Image, Stack, Box } from '../elements';
import { TReactNode } from 'resources/types/commonTypes';
import { TSpacesProps } from 'resources/types/styledTypes';
import { TImageProps } from 'resources/types/elementsTypes';

type TAvatarWithLabel = Omit<TImageProps, 'width' | 'height'> & {
  spacing: TSpacesProps;
  children: TReactNode;
  src: string;
  title: string;
  alt: string;
};

const AvatarWithLabel = ({ children, spacing, ...imageStyleProps }: TAvatarWithLabel) => {
  return (
    <Stack alignItems="center" spacing={spacing} width="100%" isHorizontal>
      <Image {...imageStyleProps} />
      <Box>{children}</Box>
    </Stack>
  );
};

AvatarWithLabel.defaultProps = {
  squareSize: 48,
  borderRadius: 9999,
  spacing: 'base',
};

export default AvatarWithLabel;
