/**
 * @module EditableTextarea
 */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Icon from '@old/components/icon';
import Input from '@old/components/input';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import RowAligner from '@old/components/common/RowAligner';
import { useOutsideClick, useKey } from '@old/hooks';
import t from 'resources/translations';
import { Span, Text } from 'components/elements';
/**
 * Component displaying value of textarea and giving the option of editing it
 * @param  {String} [props.defaultValue]
 * @param  {Function} props.onSubmit submit handler
 * @param  {String} [props.placeholder]
 * @param  {String} [props.label]
 */
const EditableTextarea = ({ defaultValue, onSubmit, placeholder, label }) => {
  const [value, setValue] = useState(defaultValue || '');
  const [editing, setIsEditing] = useState(false);

  const innerRef = useRef();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  /**
   * @name useOutsideClick
   * @function
   * @description hook used to end edit on click outside field area
   */
  useOutsideClick(innerRef, () => {
    cancelEdit();
  });

  const onEscapeCancelEdit = useKey(() => cancelEdit(), 'esc');

  const onChange = val => {
    setValue(val);
  };

  const onSave = () => {
    setIsEditing(false);
    onSubmit(value.trim());
  };

  const cancelEdit = () => {
    setValue(defaultValue);
    setIsEditing(false);
  };

  /**
   * @name setFocus
   * @function
   * @description Function to set cursor at the end of input value after entering in edit mode
   * @see {@link https://stackoverflow.com/questions/35951771/react-autofocus-sets-curser-to-beginning-of-input-value|focus on end}
   * @param {*} e
   */
  const setFocus = e => {
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  };

  const icon = (
    <div className="absolute right-0 top-0 p-2">
      <ButtonSimple onClick={onSave}>
        <Icon.Save className="fill-teal" />
      </ButtonSimple>
    </div>
  );

  return (
    <div>
      <div className="editable">
        {!editing && (
          <div>
            <RowAligner separated="small" className="text-input-content">
              <Text color="teal-dark" fontWeight="medium">
                {label}:
              </Text>
              <ButtonSimple onClick={() => setIsEditing(true)}>
                {/* modification that fixes a cut icon on Iphone */}
                <div className="ml-2">
                  <Icon.Edit className="fill-teal" small />
                </div>
              </ButtonSimple>
            </RowAligner>
            <Text>{value || <Span color="grey">{t('editableTextarea.clickToEdit')}</Span>}</Text>
          </div>
        )}
        {editing && (
          <div ref={innerRef}>
            <Input.Textarea
              label={label}
              value={value}
              onChange={onChange}
              icon={icon}
              onKeyDown={onEscapeCancelEdit}
              autoFocus
              onFocus={setFocus}
              placeholder={placeholder}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EditableTextarea.defaultProps = {
  defaultValue: '',
  placeholder: '',
  label: '',
};

EditableTextarea.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export default EditableTextarea;
