import React from 'react';
import cls from 'classnames';

// forwardRef doesn't support prop types
// eslint-disable-next-line
const InputWrapperNew = ({ children, className, relative, icon, label, required, name, disabled }, ref) => (
  <React.Fragment>
    {label && (
      <div className="p-1 font-bold text-md">
        {label}
        {required && <span className="asterisk" />}
      </div>
    )}
    <div name={name} className={cls('input-wrapper new', className, { relative }, disabled && 'inactive')} ref={ref}>
      {children}
      <span className="input-icon">{icon}</span>
    </div>
  </React.Fragment>
);

export default React.forwardRef(InputWrapperNew);
