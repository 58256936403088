import { useState, useEffect, useRef, useCallback } from 'react';
import { isIOS } from 'react-device-detect';
import cls from 'classnames';
import { useLocation } from 'react-router-dom';
import _, { compact } from 'lodash';

import { useListener, useOutsideClick, useLongTouchPress } from '@old/hooks';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Link from '@old/components/common/Link';
import { useAppDispatch, useContextMenuStore } from 'utils/storeUtils';
import { setContent, setCords } from 'store/contextMenu';

export const ContextTrigger = ({ children, menuItems, disabled }) => {
  const refComponent = useRef();
  const dispatch = useAppDispatch();
  const setContext = content => dispatch(setContent(content));
  const setMenuCords = cords => dispatch(setCords(cords));
  const onContextTrigger = e => {
    e.preventDefault();
    e.stopPropagation();
    const { clientX, clientY } = e;
    if (!_.isEmpty(menuItems)) {
      setContext(_.compact(menuItems));
      setMenuCords({ x: clientX, y: clientY });
    }
  };
  const onTouchTrigger = cords => {
    const { clientX, clientY } = cords;
    if (!_.isEmpty(menuItems)) {
      setContext(_.compact(menuItems));
      setMenuCords({ x: clientX + 25, y: clientY + 25 });
    }
  };

  const touchEvents = useLongTouchPress(onTouchTrigger, 200);

  if (disabled) return children;

  return (
    <div
      ref={refComponent}
      className="context-menu-triger"
      onContextMenu={onContextTrigger}
      onTouchStart={isIOS ? touchEvents.onTouchStart : () => {}}
      onTouchEnd={isIOS ? touchEvents.onTouchEnd : () => {}}
    >
      {children}
    </div>
  );
};

export const ContextContainer = () => {
  const dispatch = useAppDispatch();
  const setContext = content => dispatch(setContent(content));
  const setMenuCords = cords => dispatch(setCords(cords));
  const { cords, content } = useContextMenuStore();
  const refDropdown = useRef();
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const location = useLocation();
  const clearContextMenu = useCallback(
    () => {
      if (!_.isEmpty(content)) {
        setContext([]);
        setMenuCords({ x: 0, y: 0 });
      }
    },
    [location.pathname, content] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useListener('scroll', clearContextMenu);
  useOutsideClick(refDropdown, clearContextMenu);
  useEffect(() => {
    clearContextMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const clientSize = { x: refDropdown.current.clientWidth, y: refDropdown.current.clientHeight };
    const windowSize = { x: window.innerWidth, y: window.innerHeight };
    const getPos = axis => {
      if (clientSize[axis] + cords[axis] >= windowSize[axis]) {
        return cords[axis] - clientSize[axis];
      }
      return cords[axis];
    };
    setPos({ x: getPos('x'), y: getPos('y') });
  }, [refDropdown, cords]);

  const checkIsInitial = () => pos.x !== 0 && pos.y !== 0;
  return (
    <div
      ref={refDropdown}
      className={cls('context-menu', { active: checkIsInitial() })}
      style={{ left: pos.x, top: pos.y }}
    >
      <ContextMenu clearContextMenu={clearContextMenu} menuItems={content} />
    </div>
  );
};

const ContextMenu = ({ menuItems, clearContextMenu }) => {
  const onClick = buttonOnClick => {
    clearContextMenu();
    buttonOnClick();
  };

  const refDropdown = useRef();
  const renderMenu = () => (
    <div>
      {compact(menuItems)
        .map(menuItem => {
          if (menuItem.onClick) {
            return (
              <ButtonSimple key={menuItem.key} onClick={() => onClick(menuItem.onClick)} fluid>
                <div className="context-menu-item">{menuItem.label}</div>
              </ButtonSimple>
            );
          }
          if (menuItem.to) {
            return (
              <Link key={menuItem.key} to={menuItem.to} className="context-menu-item" allowed>
                {menuItem.label}
              </Link>
            );
          }
          return null;
        })
        .filter(Boolean)}
    </div>
  );

  return <div ref={refDropdown}>{menuItems.length !== 0 && renderMenu()}</div>;
};

ContextMenu.defaultProps = {
  menuItems: [],
};
