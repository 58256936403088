import Measure from 'react-measure';

import { countGridColumns } from 'old/utils';
import HorseGridItem from '@old/components/view/gridItem/Horse';
import { Grid } from '@elements';

const HorsesGrid = ({ data }) => {
  return (
    <Measure bounds>
      {({ measureRef: itemRef, _, contentRect }) => {
        const columns = countGridColumns(contentRect.bounds.width);
        return (
          <div ref={itemRef}>
            <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap="md">
              {data.map(horse => (
                <HorseGridItem key={horse.id} horse={horse} />
              ))}
            </Grid>
          </div>
        );
      }}
    </Measure>
  );
};

export default HorsesGrid;
