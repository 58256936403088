import { Box, Button, Flex, Input, Label, Span, Stack } from 'components/elements';

import { TReactNode } from 'resources/types/commonTypes';
import { TFlexProps } from 'resources/types/elementsTypes';
import { IButtonStyledProps } from 'resources/types/styledTypes';

type TRadioInput = IButtonStyledProps & {
  id: string;
  value: string | number;
  name: string;
  isChecked: boolean;
  onChange: (name: string, value: string | number) => void;
  children?: TReactNode;
};

const styleRadioBox = (isChecked: boolean) =>
  ({
    squareSize: 24,
    padding: 3,
    radius: 'full',
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: isChecked ? 'teal-dark' : 'teal-light',
  } as TFlexProps);

const RadioInput = ({ id, name, onChange, value, children, isChecked, ...buttonStyleProps }: TRadioInput) => {
  return (
    <Button variant="clean" onClick={() => onChange(name, value)} width="fit-content" {...buttonStyleProps}>
      <Label htmlFor={id} cursor="pointer" color="#454545" fontWeight="normal" fontSize="base">
        <Stack spacing="sm" alignItems="center" isHorizontal>
          <Flex {...styleRadioBox(isChecked)}>
            <Input id={id} type="radio" name={name} checked={isChecked} hidden />
            {isChecked && <Box squareSize={16} radius="full" backgroundColor="teal-dark" />}
          </Flex>
          <Span textAlign="left">{children}</Span>
        </Stack>
      </Label>
    </Button>
  );
};

export default RadioInput;
