/* eslint-disable max-len */
import React from 'react';

const CancelOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <g transform="translate(-650 -776)">
        <g>
          <path d="M663,777c6.6,0,12,5.4,12,12c0,6.6-5.4,12-12,12s-12-5.4-12-12S656.4,777,663,777L663,777 M663,776
            C663,776,663,776,663,776c-7.2,0-13,5.8-13,13s5.8,13,13,13s13-5.8,13-13C676,781.8,670.2,776,663,776L663,776z"
          />
        </g>
      </g>
      <g>
        <path d="M8.7,19L8.7,19L7,17.3l4.3-4.3L7,8.7L8.7,7l4.3,4.3L17.3,7L19,8.7L14.7,13l4.3,4.3L17.3,19L13,14.7L8.7,19
          L8.7,19z"
        />
      </g>
    </svg>
  );
};

export default CancelOutline;
/* eslint-enable max-len */
