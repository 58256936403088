/* eslint-disable max-len */
import React from 'react';

const ClockCircularOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8l0,0C16,3.6,12.4,0,8,0C8,0,8,0,8,0z M8,14.3c-3.5,0-6.3-2.8-6.3-6.3S4.5,1.7,8,1.7c3.5,0,6.3,2.8,6.3,6.3l0,0C14.3,11.5,11.5,14.3,8,14.3C8,14.3,8,14.3,8,14.3z" />
      <path d="M12.2,8H8.4V3.3c0,0,0,0,0,0c0-0.4-0.3-0.7-0.7-0.7S7,2.9,7,3.3c0,0,0,0,0,0v0v5.4c0,0.4,0.3,0.7,0.7,0.7h4.6c0.4,0,0.7-0.3,0.7-0.7S12.6,8,12.2,8L12.2,8L12.2,8z" />
    </svg>
  );
};

export default ClockCircularOutline;
/* eslint-enable max-len */
