import React from 'react';
import PropTypes from 'prop-types';
import Ratings from '@old/components/common/Rating';

const RatingStars = ({ rating }) => (
  <Ratings
    widgetSpacings="2px"
    widgetDimensions="20px"
    rating={rating || 0}
    widgetRatedColors="#00a0ad"
    widgetEmptyColors="transparent"
    typeOfWidget=""
  >
    <Ratings.Widget />
    <Ratings.Widget />
    <Ratings.Widget />
    <Ratings.Widget />
    <Ratings.Widget />
  </Ratings>
);

RatingStars.defaultProps = {
  rating: 0,
};

RatingStars.propTypes = {
  rating: PropTypes.number,
};

export default RatingStars;
