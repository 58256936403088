import cls from 'classnames';

import t from 'resources/translations';
import RowAligner from '@old/components/common/RowAligner';
import RenderDate from '@old/components/old/RenderDate';

const NewsItemFooter = ({ newsItem, className, isDraft }) => (
  <RowAligner className={cls('justify-between', className)}>
    <div>
      <RenderDate startDate={isDraft ? newsItem.createdAt : newsItem.publishedAt} />
    </div>
    <div>
      {t('newsItemFooter.author')}: {newsItem.author.getName()}
    </div>
  </RowAligner>
);

NewsItemFooter.defaultProps = {
  className: '',
};

export default NewsItemFooter;
