import _ from 'lodash';
import FormLabel from 'components/combinations/forms/FormLabel';
import SelectWithModal from 'components/combinations/forms/SelectWithModal';
import TextInput from 'components/combinations/forms/TextInput';
import { Box, Button, Flex, Icons, MotionBox, Stack, Text } from 'components/elements';
import PagePagination from 'components/modules/PagePagination';
import Ticket from 'models/Ticket';
import t from 'resources/translations';
import { useState } from 'react';
import { APIPagination } from 'resources/types/commonTypes';
import { TColorsProps } from 'resources/types/styledTypes';
import { TTicketFilters } from 'resources/types/TicketTypes';
import { MemberService } from 'services';
import { useFilters, useLoggedMember } from 'utils';
import TicketItem from './TicketItem';
import TicketItemClient from './TicketItemClient';

type TTicketsList = {
  tickets: Ticket[];
  noResultLabel: string;
  iconColor?: TColorsProps;
  isLoading: boolean;
  isFetching: boolean;
  pagination?: APIPagination;
  search: {
    query: string;
    isTouched: boolean;
    searchQuery: string;
    setQuery: React.Dispatch<React.SetStateAction<string>>;
  };
};

const TicketsList = ({
  tickets,
  iconColor = 'teal',
  noResultLabel,
  isLoading,
  isFetching,
  search,
  pagination,
}: TTicketsList) => {
  const loggedMember = useLoggedMember();
  const { filters, onChangeFilters } = useFilters<TTicketFilters>('TICKETS');
  const ItemComponent = loggedMember?.isClient() ? TicketItemClient : TicketItem;
  const spinnerIsShow = isLoading || (isFetching && !tickets.length);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const { query, searchQuery, setQuery } = search;
  const isFilteredByUser = !_.isEmpty(filters?.with_user);

  return (
    <>
      <Stack spacing="lg">
        <Stack alignItems="flex-end" px={['sm', 'md']} w="100%" spacing="base" isHorizontal>
          {!loggedMember?.isClient() && (
            <Button
              variant={!isOpenFilters && isFilteredByUser ? 'primary' : 'outline'}
              px="sm"
              onClick={() => setIsOpenFilters(prevState => !prevState)}
            >
              <Icons.Filter />
            </Button>
          )}
          <Flex flexDirection="column">
            <FormLabel htmlFor="search" fontWeight="normal">
              {t('labels.searchTicket')}
            </FormLabel>
            <Box w="full">
              <TextInput
                name="search"
                value={query}
                onChange={e => {
                  setQuery(e.target.value);
                }}
                placeholder={t('placeholders.ticketName')}
              >
                <Button variant="icon" ml="sm" onClick={() => setQuery('')} px={20}>
                  {query ? <Icons.Cancel /> : <Icons.Search />}
                </Button>
              </TextInput>
            </Box>
          </Flex>
        </Stack>
        {isOpenFilters && !loggedMember?.isClient() && (
          <Stack px={['sm', 'md']}>
            <Flex flexDirection="column">
              <FormLabel htmlFor="search" fontWeight="normal">
                {t('labels.searchClients')}
              </FormLabel>
              <Box w="full">
                <SelectWithModal
                  name="withUser"
                  value={filters.with_user || []}
                  placeholder={t('placeholders.selectClients')}
                  onChange={values => {
                    onChangeFilters({ with_user: values });
                  }}
                  translations={{
                    searchLabel: t('labels.searchClient'),
                    searchPlaceholder: t('placeholders.searchClient'),
                  }}
                  loadOptions={({ pageParam, keyword }) => {
                    return MemberService.fetchOptions(
                      keyword,
                      { page: pageParam },
                      { with_status: ['active', 'invited'], with_role: 'client' }
                    );
                  }}
                />
              </Box>
            </Flex>
          </Stack>
        )}
      </Stack>
      {spinnerIsShow ? (
        <Box textAlign="center" backgroundColor="grey-lighter-50" my="xl" p={['sm', 'md']}>
          <Flex justifyContent="center" mb="sm">
            <MotionBox
              transition={{ ease: 'linear', duration: 1.2, repeat: Infinity }}
              animate={{ rotate: 360 }}
              display="flex"
              flex="none"
              width="auto"
              alignItems="center"
            >
              <Icons.Spinner fill="teal-dark" squareSize={30} />
            </MotionBox>
          </Flex>
          <Text>{t('general.loading')}...</Text>
        </Box>
      ) : (
        <>
          {!tickets.length && (
            <Box textAlign="center" backgroundColor="grey-lighter-50" my="xl" p={['sm', 'md']}>
              <Flex justifyContent="center" mb="sm">
                <Icons.SearchOutline fill="teal-dark" squareSize={30} />
              </Flex>
              <Text>{searchQuery || isFilteredByUser ? t('tickets.noTicketsFound') : noResultLabel}</Text>
            </Box>
          )}
          <Stack w="full" my="lg" px={['sm', 'md']}>
            {tickets.map(ticket => (
              <ItemComponent key={ticket.id} ticket={ticket} iconColor={iconColor} />
            ))}
          </Stack>
          {!!tickets.length && pagination && <PagePagination pagination={pagination} />}
        </>
      )}
    </>
  );
};

export default TicketsList;
