import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LogoHmNotextIcon } from 'old/assets/svg';
import { getErrorMessage, notify } from 'old/utils';
import Model from '@old/model';
import t from 'resources/translations';
import Button from '@old/components/guide/Button';
import Form from '@old/components/common/Form';
import Field from '@old/components/field';
import BoxShadow from '@old/components/common/BoxShadow';
import Center from '@old/components/common/Center';
import FormButton from '@old/components/common/FormButton';
import BoxWhite from '@old/components/common/BoxWhite';
import FlexColumn from '@old/components/common/FlexColumn';
import { FORM_FORGOT_PASSWORD } from 'old/constants/formNames';

const ForgotPassword = ({ history }) => {
  const [resetPasswordDone, setResetPasswordDone] = useState(false);

  const onSubmit = async (formState) => {
    try {
      await Model.Members.sendEmailWIthNewPassword(formState.email);
      notify(t('message.messageSent'));
      setResetPasswordDone(true);
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };
  const redirectToLoginPage = async () => {
    history.push('/');
  };

  return (
    <BoxShadow className="page-forgot-password">
      <div className="login-banner">
        <LogoHmNotextIcon width={120} height={120} />
      </div>
      <BoxWhite padding>
        <FlexColumn>
          <h1 className="text-headline">{t('forgotPassword.forgotPassword')}</h1>

          {!resetPasswordDone && (
            <Form formName={FORM_FORGOT_PASSWORD} initValues={{ email: '' }} noPadding>
              <Field.Email name="email" label={t('labels.email')} placeholder={t('placeholders.email')} required />
              <FormButton formName={FORM_FORGOT_PASSWORD} onClick={onSubmit} fluid>
                {t('general.send')}
              </FormButton>
            </Form>
          )}

          {resetPasswordDone && (
            <FlexColumn>
              <Center>{t('forgotPassword.succesfullyChangePassword')}</Center>
              <Button onClick={redirectToLoginPage} fluid>
                {t('forgotPassword.login')}
              </Button>
            </FlexColumn>
          )}
        </FlexColumn>
      </BoxWhite>
    </BoxShadow>
  );
};

export default ForgotPassword;

ForgotPassword.defaultProps = {
  location: {},
};

ForgotPassword.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};
