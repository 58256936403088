import AsyncMultiSelect from './AsyncMultiSelect';
import AsyncSelect from './AsyncSelect';
import Checkbox from './Checkbox';
import DateValue from './Date';
import Time from './Time';
import DateTime from './DateTime';
import DateTimeWithDuration from './DateTimeWithDuration';
import Email from './Email';
import ImageUploader from './ImageUploader';
import AddParticipants from './AddParticipants';
import Numeric from './Number';
import NumberRange from './NumberRange';
import Password from './Password';
import Phone from './Phone';
import Radio from './Radio';
import RichTextEditor from './RichTextEditor';
import Select from './Select';
import Tag from './Tag';
import Text from './Text';
import GoogleMap from './Map';
import DoubleTime from './DoubleTime';
import TextArea from './TextArea';
import Rating from './Rating';
import Code from './Code';
import AddAvatar from './AddAvatar';
import AddAvatarUser from './AddAvatarUser';
import CheckboxMulti from './CheckboxMulti';
import MultiSelect from './MultiSelect';
import Switch from './Switch';

const Field = {
  AsyncMultiSelect,
  AsyncSelect,
  Checkbox,
  DateValue,
  Time,
  DateTime,
  DateTimeWithDuration,
  Email,
  ImageUploader,
  AddParticipants,
  MultiSelect,
  Numeric,
  NumberRange,
  Password,
  Phone,
  Radio,
  RichTextEditor,
  Select,
  Tag,
  Text,
  GoogleMap,
  DoubleTime,
  TextArea,
  Rating,
  Code,
  AddAvatar,
  AddAvatarUser,
  CheckboxMulti,
  Switch,
};

export default Field;