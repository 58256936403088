import { Button, Box } from '../../elements';
import Icon from '../../../old/components/icon';
type TTagRemoveButton = {
  onClick: Function;
};
const TagRemoveButton = ({ onClick, ...innerProps }: TTagRemoveButton) => {
  return (
    <Box position="absolute" top={-1} right={-1} h={26} width="initial" {...innerProps}>
      <Button
        variant="link"
        onTouchEnd={e => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }}
      >
        <Icon.Cancel circle small />
      </Button>
    </Box>
  );
};

export default TagRemoveButton;
