import { Flex, Tooltip } from 'components/elements';
import Member from 'models/Member';
import t from 'resources/translations';

const SkillIndicator = ({ member }: { member: Member }) => {
  return (
    <Tooltip
      /* @ts-ignore */
      content={t(`skills.${member.getSkill()}`)}
    >
      <Flex flex="none" width={27} backgroundColor="grey" px="sm" py="md">
        <Flex backgroundColor={member.getSkillInfo().color} flex={1} borderRadius={100} />
      </Flex>
    </Tooltip>
  );
};

export default SkillIndicator;
