import { TextTruncate } from '@elements';
import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import t from 'resources/translations';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import FlexRow from '@old/components/common/FlexRow';
import Button from '@old/components/guide/Button';
import FlexColumn from '@old/components/common/FlexColumn';
import { getErrorMessage, notify } from 'old/utils';
import { fetchEvent } from 'store/actions';
import { useActions } from 'old/hooks';

const CancelEventModal = ({ event, onClose, newCache, cb }) => {
  const fetchEventAction = useActions(fetchEvent, []);
  const onSubmit = async () => {
    try {
      await Model.Events.cancel(event.id);
      notify(t('message.cancelledEvent'));
      if (newCache) cb();
      else fetchEventAction(event.id);
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    onClose();
  };

  if (!event) return null;

  return (
    <Modal header={t('cancelEventModal.cancelEvent')} onClose={onClose} isOpen>
      <FlexColumn>
        <TextTruncate lines={2} ellipsis="...?">
          {t('cancelEventModal.confirmCancel', { title: event.getName() })}
        </TextTruncate>
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button name="cancel" onClick={onSubmit} fluid async>
            {t('cancelEventModal.cancel')}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

CancelEventModal.defaultProps = {
  event: null,
  newCache: false,
};

export default CancelEventModal;
