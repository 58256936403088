/* eslint-disable max-len */
import React from 'react';

const UnsubscribeFarm = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 50 50"
    >
      <path d="M26.7,18.4c0.1,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6C26,16.8,26.7,17.5,26.7,18.4z" />

      <path d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0z M23.8,31.4l-5.2,3.9v-7.9L23.8,31.4z
        M20.8,26.7h9.3l-4.6,3.6L20.8,26.7z M19.9,36.2l5.2-3.9l5.1,3.9H19.9z M31.6,35l-4.9-3.8l4.9-3.7V35z M36.5,23.3v12
        c0,0.6-0.5,1-1,1h-2.3V24.9H16.9v11.4h-2.2c-0.6,0-1-0.5-1-1v-12h-1.6l4.5-6.9l8.5-2.9l8.6,2.9l4.4,6.9H36.5z"
      />

      <polygon points="30.3,36.2 30.3,36.3 30.2,36.2 " />
    </svg>
  );
};

export default UnsubscribeFarm;
/* eslint-enable max-len */
