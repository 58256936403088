/* eslint-disable max-len */
import React from 'react';

const Star = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <polygon points="8,0.4 10.5,5.4 16,6.2 12,10.1 12.9,15.6 8,13 3.1,15.6 4,10.1 0,6.2 5.5,5.4" />
    </svg>
  );
};

export default Star;
/* eslint-enable max-len */
