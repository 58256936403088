import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import axios from 'axios';

import t from 'resources/translations';
import Model from '@old/model';
import { isEmail, notify } from 'old/utils';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_INVITATIONS } from 'old/constants/formNames';
import { fetchFarm, fetchUsers } from 'store/actions';
import store from 'store';

const FormInvitationEmails = ({ children, hideForm, members, recipientsValue }) => {
  const [formCounter, setFormCounter] = useState(0);
  let source;

  const invitedUsers = [...members.map(member => member.email)];

  const invalidEmails =
    recipientsValue &&
    recipientsValue.filter(recipient => invitedUsers.includes(recipient.value)).map(recipient => recipient.value);

  const validationTag = [
    {
      condition: value => isEmail({ value }),
      errorHint: t('error.badEmailFormat'),
    },
    {
      condition: (value, tags) => !includes(tags, value),
      errorHint: t('error.emailAddressExists'),
    },
  ];

  const validation = [
    {
      condition: value => {
        return !value.find(oneValue => includes(invitedUsers, oneValue.value));
      },
      errorHint: t('error.emailAlreadySend', {
        email: invalidEmails.join(', '),
      }),
    },
  ];
  const onSendInvitations = async formState => {
    const { CancelToken } = axios;
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    try {
      await Model.Farms.inviteByEmails(formState.recipients);
      notify(t('message.messageSent'));
      store.dispatch(fetchFarm());
      const {
        fetchingData: { usersFetchParams },
      } = store.getState();
      store.dispatch(fetchUsers(usersFetchParams, source.token));
      setFormCounter(prev => prev + 1);
      hideForm();
    } catch (e) {
      notify(t('error.failedSendMessage'), { type: 'error' });
    }
  };

  const initValues = {
    recipients: [],
  };

  return (
    <Form key={formCounter} formName={FORM_INVITATIONS} initValues={initValues} refreshAlways>
      <Field.Tag
        name="recipients"
        label={t('labels.emailAddress')}
        placeholder={t('placeholders.emailAddressUser')}
        options={{
          type: 'Email',
          validationsInput: validationTag,
        }}
        validation={validation}
        required
      />
      {children}
      <FormButton formName={FORM_INVITATIONS} onClick={onSendInvitations} async>
        {t('general.send')}
      </FormButton>
    </Form>
  );
};

FormInvitationEmails.defaultProps = {
  children: null,
  hideForm: () => {},
  recipientsValue: [],
};

FormInvitationEmails.propTypes = {
  children: PropTypes.node,
  hideForm: PropTypes.func,
  recipientsValue: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

const mapStateToProps = ({ app, form }) => ({
  isMobile: app.isMobile,
  recipientsValue:
    form[FORM_INVITATIONS] && form[FORM_INVITATIONS].recipients ? form[FORM_INVITATIONS].recipients.value : [],
});

export default connect(mapStateToProps)(FormInvitationEmails);
