import moment from 'moment';
import t from 'resources/translations';
import { TPaymentType } from 'resources/types/billsTypes';
import { TOption } from 'resources/types/commonTypes';
import { TEventsColorStatuses, TEventStatuses } from 'resources/types/eventsTypes';

export const DATE_FORMAT: string = 'DD.MM.YYYY';
export const TIME_FORMAT: string = 'HH:mm';
export const MONTH_FORMAT: string = 'DD.MM';
export const DATE_FORMAT_WITH_COMMA: string = 'DD.MM.YYYY, dddd';
export const DATE_TIME_FORMAT_WITH_COMMA: string = 'DD.MM.YYYY, HH:mm';
export const DATE_TIME_FORMAT: string = 'DD.MM.YYYY HH:mm';
export const LANGUAGE_CODE: string = 'pl';
export const MAX_MOBILE_WIDTH: number = 767;
export const MAX_TABLET_WIDTH: number = 1199;
export const MIN_SIZE_IMAGE: number = 300;
export const PX_PER_5_MIN: number = 5;
export const MIN_ITEMS_PER_PAGE: number = 24;
export const MIN_GRID_ITEM_WIDTH: number = 217;
export const SIDEBAR_WIDTH: number = 240;
export const CUSTOM_UUIDN_NAME_SPACE: string = 'f1d395a0-7bc5-11e9-9631-615ed40fe1fc';
export const HOURS: Array<string> = [
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
];
export const WEEK_DAYS: Array<string> = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
export const HEAT_MAP_COLORS: Array<string> = ['#701E1E', '#C74C4C', '#E28C52', '#EEDF60', '#EFE79F'];
export const HEAT_MAP_HOURS: Array<string> = [
  '08:00-09:00',
  '09:00-10:00',
  '10:00-11:00',
  '11:00-12:00',
  '12:00-13:00',
  '13:00-14:00',
  '14:00-15:00',
  '15:00-16:00',
  '16:00-17:00',
  '17:00-18:00',
  '18:00-19:00',
  '19:00-20:00',
  '20:00-21:00',
  '21:00-22:00',
  '22:00-23:00',
];
export const ACCENT_COLORS_PACLES: Array<string> = [
  '#FEFE00',
  '#FEBF00',
  '#905D48',
  '#5A3A7D',
  '#5D2818',
  '#9297F3',
  '#39780D',
  '#2B3209',
  '#71B512',
  '#9F1419',
];
export const ACCENT_COLORS_EVENT_TYPES: Array<string> = [
  '#BC2430',
  '#5D2D21',
  '#3E3F43',
  '#8F726C',
  '#A6A7C3',
  '#25331C',
  '#988B68',
  '#5C7444',
  '#6B0537',
  '#865544',
];

type TColor = {
  teal: string;
  tealLight: string;
  red: string;
  black: string;
};
export const COLOR: TColor = {
  teal: '#00A0AD',
  tealLight: '#B6E0E3',
  red: '#DC4E41',
  black: '#000000',
};

type TDifficulty = {
  label: string;
  value: string;
  slug: string;
  color: string;
};
export const DIFFICULTY: Array<TDifficulty> = [
  { label: t('skills.undefined'), value: 'undefined', slug: 'N', color: '#B9B9BA' },
  { label: t('skills.beginner'), value: 'beginner', slug: 'L1', color: 'green' },
  { label: t('skills.intermediate'), value: 'intermediate', slug: 'L2', color: '#f9b600' },
  { label: t('skills.advanced'), value: 'advanced', slug: 'L3', color: 'red' },
];

interface IPln {
  name: string;
  shortName: string;
}
type TCurrency = 'pln';
export const CURRENCY: Record<TCurrency, IPln> = {
  pln: {
    name: t('currency.pln.name'),
    shortName: t('currency.pln.shortName'),
  },
};

type TEventStatusProps = Record<TEventStatuses, TEventsColorStatuses>;

export const EVENT_STATUS_PROPS: TEventStatusProps = {
  none: 'bg-grey',
  awaiting: 'teal-dark',
  active: 'teal-light',
  ongoing: 'green-light',
  finished: 'grey-dark',
  cancelled: 'new-black-light-40',
  proposed: 'grey-dark',
};

type TDirectionalStates = {
  id: string;
  value: string;
};
export const DIRECTIONAL_STATES: TDirectionalStates[] = [
  { id: '1', value: '+01' },
  { id: '2', value: '+02' },
  { id: '3', value: '+03' },
  { id: '4', value: '+04' },
  { id: '5', value: '+05' },
  { id: '6', value: '+06' },
  { id: '7', value: '+48' },
  { id: '8', value: '+49' },
  { id: '9', value: '+44' },
  { id: '10', value: '+33' },
];

export const DEFAULT_DIRECTIONAL = { value: '+48' };

type TUserRoles = {
  label: string;
  name: string;
  value: number;
};
export const USER_ROLES: TUserRoles[] = [
  { label: t('general.owner'), name: 'owner', value: 1 },
  { label: t('general.instructor'), name: 'instructor', value: 2 },
  { label: t('general.client'), name: 'client', value: 3 },
];
type TPaymentStatus = {
  label: string;
  value: string;
  isFree?: boolean;
};
export const PAYMENT_STATUS: Array<TPaymentStatus> = [
  { label: t('paymentStatus.unpaid'), value: 'unpaid' },
  { label: t('paymentStatus.cash'), value: 'cash' },
  { label: t('paymentStatus.card'), value: 'card' },
  { label: t('paymentStatus.ticket'), value: 'ticket' },
  { label: t('paymentStatus.barter'), value: 'barter' },
  { label: t('paymentStatus.transfer'), value: 'transfer' },
  { label: t('paymentStatus.prepaid'), value: 'prepaid' },
  { label: t('paymentStatus.volunteering'), value: 'volunteering', isFree: true },
  { label: t('paymentStatus.other'), value: 'other' },
  { label: t('paymentStatus.other/volunteering'), value: 'other/volunteering' },
  { label: t('paymentStatus.other/ticket'), value: 'other/ticket' },
];

export type TPaymentStatusCompact = {
  label: string;
  value: Exclude<TPaymentType, 'transfer' | 'unpaid'>;
};
export const PAYMENT_STATUS_COMPACT: TPaymentStatusCompact[] = [
  { label: t('paymentStatus.cash'), value: 'cash' },
  { label: t('paymentStatus.ticket'), value: 'ticket' },
  { label: t('paymentStatus.other'), value: 'other' },
  { label: t('paymentStatus.other/volunteering'), value: 'other/volunteering' },
  { label: t('paymentStatus.other/ticket'), value: 'other/ticket' },
];

export const BILLS_STATUS_OPTIONS: TOption[] = [
  { label: t('billsFilters.statuses.paid'), value: 'paid' },
  { label: t('billsFilters.statuses.unpaid'), value: 'unpaid' },
  { label: t('billsFilters.statuses.overpaid'), value: 'overpaid' },
];
export const EVENT_TYPE_AVATARS: Array<{ image: string }> = [
  { image: '/img/eventTypeAvatars/derkowanie.png' },
  { image: '/img/eventTypeAvatars/elektrolity.png' },
  { image: '/img/eventTypeAvatars/karmienie_niestandardowe.png' },
  { image: '/img/eventTypeAvatars/karmienie_rano.png' },
  { image: '/img/eventTypeAvatars/karmienie_wieczorem.png' },
  { image: '/img/eventTypeAvatars/karuzela.png' },
  { image: '/img/eventTypeAvatars/komunikacja.png' },
  { image: '/img/eventTypeAvatars/kucie.png' },
  { image: '/img/eventTypeAvatars/lonża.png' },
  { image: '/img/eventTypeAvatars/oprowadzki.png' },
  { image: '/img/eventTypeAvatars/padokowanie.png' },
  { image: '/img/eventTypeAvatars/spacer.png' },
  { image: '/img/eventTypeAvatars/teren.png' },
  { image: '/img/eventTypeAvatars/trening_dosiad.png' },
  { image: '/img/eventTypeAvatars/trening_skoki.png' },
  { image: '/img/eventTypeAvatars/trening_ujeżdżenie.png' },
  { image: '/img/eventTypeAvatars/trening.png' },
  { image: '/img/eventTypeAvatars/werkowanie.png' },
];
export const BM_PAYMENT_ICONS: Array<{ image: string }> = [
  { image: '/img/bmIcons/payment1.gif' },
  { image: '/img/bmIcons/payment2.gif' },
  { image: '/img/bmIcons/payment3.gif' },
  { image: '/img/bmIcons/payment4.gif' },
  { image: '/img/bmIcons/payment5.gif' },
  { image: '/img/bmIcons/payment6.gif' },
  { image: '/img/bmIcons/payment7.gif' },
  { image: '/img/bmIcons/payment8.gif' },
  { image: '/img/bmIcons/payment9.gif' },
  { image: '/img/bmIcons/payment10.gif' },
  { image: '/img/bmIcons/payment11.gif' },
  { image: '/img/bmIcons/payment12.gif' },
  { image: '/img/bmIcons/payment13.gif' },
  { image: '/img/bmIcons/payment14.gif' },
  { image: '/img/bmIcons/payment15.gif' },
  { image: '/img/bmIcons/payment16.gif' },
  { image: '/img/bmIcons/payment17.png' },
  { image: '/img/bmIcons/payment18.gif' },
  { image: '/img/bmIcons/payment19.png' },
  { image: '/img/bmIcons/payment20.gif' },
  { image: '/img/bmIcons/payment21.gif' },
  { image: '/img/bmIcons/payment22.gif' },
  { image: '/img/bmIcons/payment23.jpg' },
];
type TUsersAvatars = {
  name: string;
  avatars: Array<{ image: string }>;
};
export const UsersAvatars: Array<TUsersAvatars> = [
  {
    name: t('addAvatarUsers.women'),
    avatars: [
      { image: '/img/userAvatars/women1.png' },
      { image: '/img/userAvatars/women2.png' },
      { image: '/img/userAvatars/women3.png' },
      { image: '/img/userAvatars/women4.png' },
      { image: '/img/userAvatars/women5.png' },
      { image: '/img/userAvatars/women6.png' },
      { image: '/img/userAvatars/women7.png' },
      { image: '/img/userAvatars/women8.png' },
      { image: '/img/userAvatars/women9.png' },
      { image: '/img/userAvatars/women10.png' },
    ],
  },
  {
    name: t('addAvatarUsers.men'),
    avatars: [
      { image: '/img/userAvatars/men1.png' },
      { image: '/img/userAvatars/men2.png' },
      { image: '/img/userAvatars/men3.png' },
      { image: '/img/userAvatars/men4.png' },
      { image: '/img/userAvatars/men5.png' },
      { image: '/img/userAvatars/men6.png' },
      { image: '/img/userAvatars/men7.png' },
      { image: '/img/userAvatars/men8.png' },
      { image: '/img/userAvatars/men9.png' },
      { image: '/img/userAvatars/men10.png' },
    ],
  },
  {
    name: t('addAvatarUsers.kids'),
    avatars: [
      { image: '/img/userAvatars/kid1.png' },
      { image: '/img/userAvatars/kid2.png' },
      { image: '/img/userAvatars/kid3.png' },
      { image: '/img/userAvatars/kid4.png' },
      { image: '/img/userAvatars/kid5.png' },
      { image: '/img/userAvatars/kid6.png' },
      { image: '/img/userAvatars/kid7.png' },
      { image: '/img/userAvatars/kid8.png' },
      { image: '/img/userAvatars/kid9.png' },
      { image: '/img/userAvatars/kid10.png' },
    ],
  },
];
type TGoogleCode = {
  production: string;
  testing: string;
};
export const GOOGLE_ANALYTICS_CODES: TGoogleCode = {
  production: 'UA-149881877-2',
  testing: 'UA-149881877-3',
};
export const RELEASE_BILLING_DATE: moment.Moment = moment('11.03.2021 12:00', 'DD.MM.YYYY HH:mm');
export const EMAILS: { support: string } = {
  support: 'kontakt@horsemanago.com',
};
export const GOOGLE_ANALYTICS_ENABLED: boolean =
  process.env.REACT_APP_SERVER === 'production' || process.env.REACT_APP_SERVER === 'testing';
