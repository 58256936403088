import config from '@old/config';
import t from 'resources/translations';
import { parseToFormattedNumberString } from './Common';

export const isPaymentAvailable = ({ bill }) => {
  return bill?.status === 'unpaid' && parseFloat(bill.paidAmount) === 0;
};

export const isReturnPaymentAvailable = ({ bill }) => {
  return !!bill?.paidAt && parseFloat(bill.paidAmount) > 0;
};

export const getPaymentLabelByType = (paymentType) => {
  return config.paymentStatus.find(payment => payment.value === paymentType).label;
};

export const parseToCurrencyString = (value) => {
  return parseToFormattedNumberString(value, true);
};

export const parseTransactionsToString = (transactions) => {
  let transactionsString = '';
  if (!transactions.length) return '';
  transactions.forEach((tDetails, index) => {
    transactionsString = `
      ${transactionsString}${index === 0 ? '' : `
      -------------------------------`}
      ${t('labels.amount')}: ${parseToCurrencyString(tDetails.getAmount())}
      ${t('labels.transactionId')}: ${tDetails.getOrderId()}
      ${t('labels.remoteId')}: ${tDetails.getRemoteId()}
      ${t('labels.paymentStatus')}: ${tDetails.getStatus()}${tDetails.isSuccess() ? '' : `
      ${t('labels.redirectUrl')}: ${tDetails.getRedirect()}`}
      ${t('labels.updatedDate')}: ${tDetails.getUpdatedDate()}
      ${t('labels.createDate')}: ${tDetails.getCreatedDate()}`;
  });
  return transactionsString.replace(/  +/g, '').trim();
};
