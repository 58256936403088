import { Divider } from 'components/combinations';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { Box, Button, Flex, Grid, Icons, Link, Span, Stack, Text, TextTruncate, Tooltip } from 'components/elements';
import { DATE_FORMAT, TIME_FORMAT } from 'config/constans';
import theme from 'config/theme';
import _ from 'lodash';
import Bill from 'models/Bill';
import moment from 'moment';
import { useModal } from 'old/hooks';
import t from 'resources/translations';
import { parseToCurrencyString } from 'old/utils';
import { usePermissions } from 'Permissions';
import { memo, useCallback } from 'react';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { BillService, TicketService } from 'services';
import { css } from 'styled-components';
import { doubleTernary, useDisclosure } from 'utils';
import PaymentsList from './PaymentsList';

const BillClientStatementItem = ({ bill }: { bill: Bill }) => {
  const expadedProps = useDisclosure();
  const queryClient = useQueryClient();
  const isTicket = bill.billableType === 'Ticket';

  const canIAddPayment = usePermissions('BILL_ADD_PAYMENT')(bill);
  const canICancelPayment = usePermissions('BILL_CANCEL_PAYMENT')(bill);
  const canIRemoveTicket = usePermissions('TICKET_REMOVE')(bill.status === 'unpaid') && isTicket;

  const modalPayment = useModal('PAYMENT_ONLINE');
  const modalConfirm = useModal('CONFIRM');

  const isPaid = bill.getDue() === bill.getPaidAmount();
  const getPaymentStatus = useCallback(() => {
    const isUnpaid = bill.getDue() > bill.getPaidAmount();
    const paymentStatus: string = doubleTernary(isPaid, isUnpaid, [
      t('bills.statementList.paid'),
      t('bills.statementList.unpaid'),
      t('bills.statementList.overpaid'),
    ]);
    return paymentStatus;
  }, [bill, isPaid]);

  const getPaymentLabel = useCallback(() => {
    const currentTransaction = bill.getCurrentTransaction();
    const paymentIsPending = currentTransaction?.status === 'pending';
    const paymentIsFailure = currentTransaction?.status === 'failure';

    const paymentLabel: string = doubleTernary(paymentIsPending, paymentIsFailure, [
      t('buttons.completePayment'),
      t('buttons.retryPayment'),
      t('buttons.pay'),
    ]);
    return paymentLabel;
  }, [bill]);

  const actionRemoveTicket = useMutation(async () => TicketService.delete(bill.billableId), {
    onSuccess: () => queryClient.invalidateQueries('BILLS', { refetchActive: true }),
  });

  const actionCancelPayment = useMutation(
    async () => {
      const transferPaymentsList = bill.payments.filter(payment => payment.status === 'unprocessed');
      const lastTransferPayment = _.orderBy(transferPaymentsList, ['created_at'], ['desc'])[0];
      return await BillService.cancelPayment(bill.id, lastTransferPayment.transactions[0].id);
    },
    { onSuccess: () => queryClient.invalidateQueries('BILLS', { refetchActive: true }) }
  );

  const onOpenPaymenModal = () => {
    return modalPayment.onOpen({ bill });
  };

  const onOpenCancelPaymenModal = () => {
    modalConfirm.onOpen({
      title: t('bills.cancelPayment'),
      content: (
        <ModalDescription>
          <Text>
            {t('bills.areYouSureCancel')}
            <TextTruncate ellipsis="...?" lines={2} fontWeight="bold">
              {bill.getName()}?
            </TextTruncate>
          </Text>
        </ModalDescription>
      ),
      action: {
        mutation: actionCancelPayment,
        label: t('buttons.yesCancelPayment'),
        cancelLabel: t('buttons.noGetMyOutOfHere'),
        loadingLabel: t('message.removed'),
      },
    });
  };

  const onOpenDeleteModal = () => {
    modalConfirm.onOpen({
      title: t('tickets.deleteTicket'),
      content: (
        <ModalDescription spacing="xl">
          <Stack spacing="sm">
            <Text>{t('tickets.deleteUnpaidTicket')}:</Text>
            <Text>
              <Span fontWeight="medium">{bill.getName()}</Span>?
            </Text>
          </Stack>
          <Text fontWeight="medium">{t('tickets.rememberTicketDeleted')}</Text>
        </ModalDescription>
      ),
      action: {
        mutation: actionRemoveTicket,
        label: t('buttons.yesIWantDeleteTicket'),
        cancelLabel: t('buttons.noGetMyOutOfHere'),
        loadingLabel: t('message.removed'),
      },
    });
  };

  const url = isTicket ? `/tickets/${bill.ticket.id}` : `/events/${bill.event.id}`;

  return (
    <>
      <Grid
        gridTemplateColumns="90px 30px 1fr 100px 100px 120px 40px"
        gridTemplateRows="minmax(80px, auto)"
        gridColumnGap={30}
        alignItems="center"
        px="xl"
        css={css({
          '&:hover': {
            backgroundColor: theme.colors['teal-light-5'],
          },
        })}
      >
        <Flex flexDirection="column">
          {isTicket ? (
            <>
              <Text>{moment(bill.createdAt).format(DATE_FORMAT)}</Text>
              <Text>{moment(bill.createdAt).format(TIME_FORMAT)}</Text>
            </>
          ) : (
            <>
              <Text>{moment(bill.eventStartAt).format(DATE_FORMAT)}</Text>
              <Text>{moment(bill.eventStartAt).format(TIME_FORMAT)}</Text>
            </>
          )}
        </Flex>
        <Box>
          {isTicket ? (
            <Tooltip content={t('labels.ticket')}>
              <Box>
                <Icons.TicketCircle bgColor="body-color" fill="teal-dark" />
              </Box>
            </Tooltip>
          ) : (
            <Tooltip content={t('labels.event')}>
              <Box>
                <Icons.HorseRiderCircle bgColor="body-color" fill="teal-dark" />
              </Box>
            </Tooltip>
          )}
        </Box>
        <Box>
          <Link to={url} wordBreak="break-all" py="sm">
            {isTicket ? bill.ticket.name : bill.event.name}
          </Link>
        </Box>
        <Text whiteSpace="nowrap" textAlign="right">
          {parseToCurrencyString(bill.dueAmount)}
        </Text>
        <Text whiteSpace="nowrap" textAlign="right">
          {parseToCurrencyString(bill.paidAmount)}
        </Text>
        <Text color={isPaid ? 'grey-dark' : 'orange'}>{getPaymentStatus().toLowerCase()}</Text>
        <Flex justifyContent="center">
          <Button variant="icon" onClick={() => expadedProps.onToggle()}>
            {expadedProps.isOpen ? <Icons.ArrowUp size="md" /> : <Icons.ArrowDown size="md" />}
          </Button>
        </Flex>
        {expadedProps.isOpen && (
          <Grid gridColumn="8 / 1" pb="md">
            {!_.isEmpty(bill.instructors) && (
              <Stack spacing="xs" pb="md">
                <Text fontWeight="medium">{t('general.instructors')}</Text>
                <Text>
                  {bill.instructors.map((instructor, i) => (
                    <>
                      {i !== 0 ? ', ' : ''}
                      <Link to={`/instructors/${instructor.id}`} forceRender>
                        {instructor.name}
                      </Link>
                    </>
                  ))}
                </Text>
              </Stack>
            )}
            <PaymentsList bill={bill} />
            {(canIAddPayment || canICancelPayment || canIRemoveTicket) && (
              <Stack isHorizontal justifyContent="flex-end" pt="md">
                {canIRemoveTicket && (
                  <Button variant="link" onClick={() => onOpenDeleteModal()} width={['100%', 'initial']}>
                    <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                      <Icons.Cancel size={12} />
                      <Text>{t('tickets.deleteTicket')}</Text>
                    </Stack>
                  </Button>
                )}
                {canIAddPayment && (
                  <Button variant="link" onClick={() => onOpenPaymenModal()}>
                    <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                      <Icons.PaymentWallet size={12} />
                      <Text>{getPaymentLabel()}</Text>
                    </Stack>
                  </Button>
                )}
                {canICancelPayment && (
                  <Button variant="link" onClick={() => onOpenCancelPaymenModal()} width={['100%', 'initial']}>
                    <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
                      <Icons.Cancel size={12} />
                      <Text>{t('buttons.cancelPayment')}</Text>
                    </Stack>
                  </Button>
                )}
              </Stack>
            )}
          </Grid>
        )}
      </Grid>
      <Box w="full" px={['md', 'xl']}>
        <Divider color="new-grey-light-30" my={0} />
      </Box>
    </>
  );
};

export default memo(BillClientStatementItem);
