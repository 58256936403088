import React from 'react';
import PropTypes from 'prop-types';

import Center from '@old/components/common/Center';

const DateLabel = ({ date }) => (
  <div className="relative mx-4">
    <Center><span className="bg-white z-10 px-4 font-bold">{date}</span></Center>
    <div className="border-b-2 border-grey absolute w-full" style={{ top: 'calc(50% - 1px)' }} />
  </div>
);

DateLabel.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateLabel;
