/* eslint-disable max-len */
import React from 'react';

const ZoomOut = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M16,14.2l-3.3-3.1c0.9-1.1,1.4-2.4,1.4-3.9c0,0,0,0,0,0v0c0-3.7-3.1-6.6-7-6.6S0,3.4,0,7.1s3.1,6.6,7,6.6c0,0,0,0,0,0
        c1.6,0,3-0.5,4.2-1.3l0,0l3.3,3.1L16,14.2z M2,7.1c0-2.6,2.2-4.7,5-4.7s5,2.1,5,4.7s-2.2,4.7-5,4.7S2,9.7,2,7.1z M4,6.6h5.8v1.3H4L4,6.6z"
      />
    </svg>
  );
};

export default ZoomOut;
/* eslint-enable max-len */
