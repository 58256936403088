import { isEqual, unionBy } from 'lodash';

import {
  REFRESH_CUSTOM_DATA,
  FETCHING_EVENT,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILED,
  FETCHING_EVENTS,
  FETCH_EVENTS_FIRST_PART_SUCCESS,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILED,
  DELETING_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILED,
  FETCH_EVENTS_FINISHED,
  FETCH_EVENT_FINISHED,
  FETCHING_HORSE,
  FETCH_HORSE_SUCCESS,
  FETCH_HORSE_FAILED,
  FETCH_HORSE_FINISHED,
  FETCHING_HORSES,
  FETCH_HORSES_SUCCESS,
  FETCH_HORSES_FAILED,
  DELETING_HORSE,
  DELETE_HORSE_SUCCESS,
  DELETE_HORSE_FAILED,
  FETCH_HORSES_FINISHED,
  FETCHING_PLACE,
  FETCH_PLACE_SUCCESS,
  FETCH_PLACE_FAILED,
  FETCH_PLACE_FINISHED,
  FETCHING_PLACES,
  FETCH_PLACES_SUCCESS,
  FETCH_PLACES_FAILED,
  DELETING_PLACE,
  DELETE_PLACE_SUCCESS,
  DELETE_PLACE_FAILED,
  FETCH_PLACES_FINISHED,
  FETCHING_RATINGS,
  FETCH_RATINGS_SUCCESS,
  FETCH_RATINGS_FAILED,
  DELETING_RATING,
  DELETE_RATING_SUCCESS,
  DELETE_RATING_FAILED,
  FETCH_RATINGS_FINISHED,
  FETCHING_EVENT_TYPE,
  FETCH_EVENT_TYPE_SUCCESS,
  FETCH_EVENT_TYPE_FAILED,
  FETCH_EVENT_TYPE_FINISHED,
  FETCHING_EVENT_TYPES,
  FETCH_EVENT_TYPES_SUCCESS,
  FETCH_EVENT_TYPES_FAILED,
  DELETING_EVENT_TYPE,
  DELETE_EVENT_TYPE_SUCCESS,
  DELETE_EVENT_TYPE_FAILED,
  FETCH_EVENT_TYPES_FINISHED,
  FETCHING_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  FETCH_USER_FINISHED,
  FETCHING_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILED,
  DELETING_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  FETCH_USERS_FINISHED,
  FETCHING_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_FAILED,
  FETCH_GROUP_FINISHED,
  FETCHING_GROUPS,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILED,
  DELETING_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
  FETCH_GROUPS_FINISHED,
  FETCHING_FARM,
  FETCH_FARM_SUCCESS,
  FETCH_FARM_FAILED,
  FETCH_FARM_FINISHED,
  FETCHING_SINGLE_NEWS,
  FETCH_SINGLE_NEWS_SUCCESS,
  FETCH_SINGLE_NEWS_FAILED,
  FETCH_SINGLE_NEWS_FINISHED,
  FETCHING_NEWS,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_FAILED,
  DELETING_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAILED,
  FETCH_NEWS_FINISHED,
  FETCHING_ABSENCES,
  FETCH_ABSENCES_SUCCESS,
  FETCH_ABSENCES_FAILED,
  FETCH_ABSENCES_FINISHED,
  DELETING_ABSENCE,
  DELETE_ABSENCE_SUCCESS,
  DELETE_ABSENCE_FAILED,
  FETCHING_SHIFTS,
  FETCH_SHIFTS_SUCCESS,
  FETCH_SHIFTS_FAILED,
  FETCH_SHIFTS_FINISHED,
  DELETING_SHIFT,
  DELETE_SHIFT_SUCCESS,
  DELETE_SHIFT_FAILED,
  FETCHING_PROPOSALS,
  FETCH_PROPOSALS_SUCCESS,
  FETCH_PROPOSALS_FAILED,
  FETCH_PROPOSALS_FINISHED,
  DELETING_PROPOSAL,
  DELETE_PROPOSAL_SUCCESS,
  DELETE_PROPOSAL_FAILED,
  FETCHING_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  FETCH_NOTIFICATIONS_FINISHED,
  FETCHING_BILLS,
  FETCH_BILLS_SUCCESS,
  FETCH_BILLS_FAILED,
  FETCH_BILLS_FINISHED,
  SET_LOADING_EVENT_ID,
  FETCHING_BILL,
  FETCH_BILL_SUCCESS,
  FETCH_BILL_FAILED,
  FETCH_BILL_FINISHED,
  FETCH_EVENTS_STARTED,
  FETCH_EVENTS_NEXT_SUCCESS,
  FETCH_PROPOSALS_STARTED,
  FETCH_PROPOSALS_NEXT_SUCCESS,
} from 'store/actions/dataActions';

const initialState = {
  refreshCounter: 0,
  events: [],
  eventsFetchParams: {},
  eventsPagination: {
    current: 1,
  },
  horses: [],
  horsesFetchParams: {},
  horsesPagination: {
    current: 1,
  },
  places: [],
  placesFetchParams: {},
  placesPagination: {
    current: 1,
  },
  ratings: [],
  ratingsFetchParams: {},
  ratingsPagination: {
    current: 1,
  },
  ratingsModel: {},
  eventTypes: [],
  eventTypesFetchParams: {},
  eventTypesPagination: {
    current: 1,
  },
  users: [],
  usersFetchParams: {},
  usersPagination: {
    current: 1,
  },
  news: [],
  newsFetchParams: {},
  newsPagination: {
    current: 1,
  },
  groups: [],
  groupsFetchParams: {},
  groupsPagination: {
    current: 1,
  },
  farm: null,
  absences: [],
  absencesFetchParams: {},
  absencesPagination: {
    current: 1,
  },
  shifts: [],
  shiftsFetchParams: {},
  shiftsPagination: {
    current: 1,
  },
  proposals: [],
  proposalsFetchParams: {},
  proposalsPagination: {
    current: 1,
  },
  bills: [],
  billsFetchParams: {},
  billsPagination: {
    current: 1,
  },
  notifications: [],
  notificationsFetchParams: {},
  notificationsPagination: {
    current: 1,
  },
  sourceToken: {
    events: {},
    horses: {},
    places: {},
    ratings: {},
    eventTypes: {},
    users: {},
    groups: {},
    farm: {},
    news: {},
    absences: {},
    shifts: {},
    proposals: {},
    notifications: {},
    bills: {},
  },
  loading: {
    events: false,
    horses: false,
    places: false,
    ratings: false,
    eventTypes: false,
    users: false,
    groups: false,
    farm: false,
    news: false,
    absences: false,
    shifts: false,
    proposals: false,
    notifications: false,
    bills: false,
  },
  error: {
    events: false,
    horses: false,
    places: false,
    ratings: false,
    eventTypes: false,
    users: false,
    groups: false,
    farm: false,
    news: false,
    absences: false,
    shifts: false,
    proposals: false,
    notifications: false,
    bills: false,
  },
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_CUSTOM_DATA:
      return {
        ...state,
        [action.key]: state[action.key] ? state[action.key] + 1 : 1,
      };
    case SET_LOADING_EVENT_ID:
      return {
        ...state,
        loading: { ...state.loading, events: action.id && [action.id] },
      };
    case FETCHING_EVENT:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, events: action.sourceToken },
        loading: { ...state.loading, events: [action.id] },
        error: { ...state.error, events: false },
      };
    case FETCH_EVENT_SUCCESS:
      const changedEventIndex = state.events.findIndex(event => event.id === action.event.id);
      if (changedEventIndex === -1) {
        return {
          ...state,
          events: [...state.events.filter(event => event.id !== action.event.id), action.event],
          loading: { ...state.loading, events: false },
          error: { ...state.error, events: false },
        };
      }
      const newEvents = [...state.events];
      newEvents[changedEventIndex] = action.event;
      return {
        ...state,
        events: newEvents,
        loading: { ...state.loading, events: false },
        error: { ...state.error, events: false },
      };
    case FETCH_EVENT_FAILED:
      if (action.sourceToken === state.sourceToken.events) {
        return {
          ...state,
          loading: { ...state.loading, events: false },
          error: { ...state.error, events: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_EVENT_FINISHED:
      if (action.sourceToken === state.sourceToken.events) {
        return {
          ...state,
          loading: { ...state.loading, events: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_EVENT:
      return {
        ...state,
        loading: { ...state.loading, events: [] },
        error: { ...state.error, events: false },
      };
    case DELETE_EVENT_SUCCESS:
      const deletedEventIndex = state.events.findIndex(event => event.id === action.id);
      const afterDeleteEvents = [...state.events];
      afterDeleteEvents.splice(deletedEventIndex, 1);
      return {
        ...state,
        events: afterDeleteEvents,
        loading: { ...state.loading, events: false },
        error: { ...state.error, events: false },
      };
    case DELETE_EVENT_FAILED:
      return {
        ...state,
        loading: { ...state.loading, events: false },
        error: { ...state.error, events: true },
      };
    case FETCHING_EVENTS:
      const isEventsFetchParamsEqual = isEqual(state.eventsFetchParams, action.fetchParams);
      return {
        ...state,
        events: isEventsFetchParamsEqual ? state.events : [],
        sourceToken: { ...state.sourceToken, events: action.sourceToken },
        loading: { ...state.loading, events: [] },
        error: { ...state.error, events: false },
      };
    case FETCH_EVENTS_NEXT_SUCCESS:
      return {
        ...state,
        events: unionBy(state.events, action.events, 'id'),
        eventsPagination: action.pagination,
        eventsFetchParams: action.fetchParams,
        loading: { ...state.loading, events: false },
        error: { ...state.error, events: false },
      };
    case FETCH_EVENTS_FIRST_PART_SUCCESS:
      const mergedEvents = unionBy(state.events, action.events, 'id');
      return {
        ...state,
        events: [...mergedEvents],
        eventsFetchParams: action.fetchParams,
        loading: { ...state.loading },
        error: { ...state.error, events: false },
      };
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: [...action.events],
        eventsPagination: action.eventsPagination,
        eventsFetchParams: action.fetchParams,
        loading: { ...state.loading, events: false },
        error: { ...state.error, events: false },
      };
    case FETCH_EVENTS_FAILED:
      if (action.sourceToken === state.sourceToken.events) {
        return {
          ...state,
          events: [],
          eventsFetchParams: state.eventsFetchParams,
          loading: { ...state.loading, events: false },
          error: { ...state.error, events: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_EVENTS_STARTED:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, events: action.sourceToken },
        loading: { ...state.loading, events: true },
      };
    case FETCH_EVENTS_FINISHED:
      if (action.sourceToken === state.sourceToken.events) {
        return {
          ...state,
          loading: { ...state.loading, events: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_HORSE:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, horses: action.sourceToken },
        loading: { ...state.loading, horses: true },
        error: { ...state.error, horses: false },
      };
    case FETCH_HORSE_SUCCESS:
      const changedHorseIndex = state.horses.findIndex(horse => horse.id === action.horse.id);
      if (changedHorseIndex === -1) {
        return {
          ...state,
          horses: [...state.horses.filter(horse => horse.id !== action.horse.id), action.horse],
          loading: { ...state.loading, horses: false },
          error: { ...state.error, horses: false },
        };
      }
      const newHorses = [...state.horses];
      newHorses[changedHorseIndex] = action.horse;
      return {
        ...state,
        horses: newHorses,
        loading: { ...state.loading, horses: false },
        error: { ...state.error, horses: false },
      };
    case FETCH_HORSE_FAILED:
      if (action.sourceToken === state.sourceToken.horses) {
        return {
          ...state,
          loading: { ...state.loading, horses: false },
          error: { ...state.error, horses: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_HORSE_FINISHED:
      if (action.sourceToken === state.sourceToken.horses) {
        return {
          ...state,
          loading: { ...state.loading, horses: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_HORSE:
      return {
        ...state,
        loading: { ...state.loading, horses: true },
        error: { ...state.error, horses: false },
      };
    case DELETE_HORSE_SUCCESS:
      const deletedHorseIndex = state.horses.findIndex(horse => horse.id === action.id);
      const afterDeleteHorses = [...state.horses];
      afterDeleteHorses.splice(deletedHorseIndex, 1);
      return {
        ...state,
        horses: afterDeleteHorses,
        loading: { ...state.loading, horses: false },
        error: { ...state.error, horses: false },
      };
    case DELETE_HORSE_FAILED:
      return {
        ...state,
        loading: { ...state.loading, horses: false },
        error: { ...state.error, horses: true },
      };
    case FETCHING_HORSES:
      const isHorsesFetchParamsEqual = isEqual(state.horsesFetchParams, action.fetchParams);
      return {
        ...state,
        horses: isHorsesFetchParamsEqual ? state.horses : [],
        sourceToken: { ...state.sourceToken, horses: action.sourceToken },
        loading: { ...state.loading, horses: true },
        error: { ...state.error, horses: false },
      };
    case FETCH_HORSES_SUCCESS:
      return {
        ...state,
        horses: [...action.horses],
        horsesPagination: action.horsesPagination,
        horsesFetchParams: action.fetchParams,
        loading: { ...state.loading, horses: false },
        error: { ...state.error, horses: false },
      };
    case FETCH_HORSES_FAILED:
      if (action.sourceToken === state.sourceToken.horses) {
        return {
          ...state,
          horses: [],
          horsesFetchParams: state.horsesFetchParams,
          loading: { ...state.loading, horses: false },
          error: { ...state.error, horses: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_HORSES_FINISHED:
      if (action.sourceToken === state.sourceToken.horses) {
        return {
          ...state,
          loading: { ...state.loading, horses: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_PLACE:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, places: action.sourceToken },
        loading: { ...state.loading, places: true },
        error: { ...state.error, places: false },
      };
    case FETCH_PLACE_SUCCESS:
      const changedPlaceIndex = state.places.findIndex(place => place.id === action.place.id);
      if (changedPlaceIndex === -1) {
        return {
          ...state,
          places: [...state.places.filter(place => place.id !== action.place.id), action.place],
          loading: { ...state.loading, places: false },
          error: { ...state.error, places: false },
        };
      }
      const newPlaces = [...state.places];
      newPlaces[changedPlaceIndex] = action.place;
      return {
        ...state,
        places: newPlaces,
        loading: { ...state.loading, places: false },
        error: { ...state.error, places: false },
      };
    case FETCH_PLACE_FAILED:
      if (action.sourceToken === state.sourceToken.places) {
        return {
          ...state,
          loading: { ...state.loading, places: false },
          error: { ...state.error, places: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_PLACE_FINISHED:
      if (action.sourceToken === state.sourceToken.places) {
        return {
          ...state,
          loading: { ...state.loading, places: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_PLACE:
      return {
        ...state,
        loading: { ...state.loading, places: true },
        error: { ...state.error, places: false },
      };
    case DELETE_PLACE_SUCCESS:
      const deletedPlaceIndex = state.places.findIndex(place => place.id === action.id);
      const afterDeletePlaces = [...state.places];
      afterDeletePlaces.splice(deletedPlaceIndex, 1);
      return {
        ...state,
        places: afterDeletePlaces,
        loading: { ...state.loading, places: false },
        error: { ...state.error, places: false },
      };
    case DELETE_PLACE_FAILED:
      return {
        ...state,
        loading: { ...state.loading, places: false },
        error: { ...state.error, places: true },
      };
    case FETCHING_PLACES:
      const isPlacesFetchParamsEqual = isEqual(state.placesFetchParams, action.fetchParams);
      return {
        ...state,
        places: isPlacesFetchParamsEqual ? state.places : [],
        sourceToken: { ...state.sourceToken, places: action.sourceToken },
        loading: { ...state.loading, places: true },
        error: { ...state.error, places: false },
      };
    case FETCH_PLACES_SUCCESS:
      return {
        ...state,
        places: [...action.places],
        placesPagination: action.placesPagination,
        placesFetchParams: action.fetchParams,
        loading: { ...state.loading, places: false },
        error: { ...state.error, places: false },
      };
    case FETCH_PLACES_FAILED:
      if (action.sourceToken === state.sourceToken.places) {
        return {
          ...state,
          places: [],
          placesFetchParams: state.placesFetchParams,
          loading: { ...state.loading, places: false },
          error: { ...state.error, places: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_PLACES_FINISHED:
      if (action.sourceToken === state.sourceToken.places) {
        return {
          ...state,
          loading: { ...state.loading, places: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_RATINGS:
      const isRatingsFetchParamsEqual = isEqual(state.ratingsFetchParams, action.fetchParams);
      return {
        ...state,
        ratings: isRatingsFetchParamsEqual ? state.ratings : [],
        ratingsModel: isRatingsFetchParamsEqual ? state.ratingsModel : {},
        sourceToken: { ...state.sourceToken, ratings: action.sourceToken },
        loading: { ...state.loading, ratings: true },
        error: { ...state.error, ratings: false },
      };
    case FETCH_RATINGS_SUCCESS:
      return {
        ...state,
        ratings: [...action.ratings],
        ratingsPagination: action.ratingsPagination,
        ratingsFetchParams: action.fetchParams,
        ratingsModel: action.ratingsModel,
        loading: { ...state.loading, ratings: false },
        error: { ...state.error, ratings: false },
      };
    case FETCH_RATINGS_FAILED:
      if (action.sourceToken === state.sourceToken.ratings) {
        return {
          ...state,
          ratings: [],
          ratingsFetchParams: state.ratingsFetchParams,
          ratingsModel: {},
          loading: { ...state.loading, ratings: false },
          error: { ...state.error, ratings: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_RATINGS_FINISHED:
      if (action.sourceToken === state.sourceToken.ratings) {
        return {
          ...state,
          loading: { ...state.loading, ratings: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_RATING:
      return {
        ...state,
        loading: { ...state.loading, ratings: true },
        error: { ...state.error, ratings: false },
      };
    case DELETE_RATING_SUCCESS:
      const deletedRatingIndex = state.ratings.findIndex(rating => rating.id === action.id);
      const afterDeleteRatings = [...state.ratings];
      afterDeleteRatings.splice(deletedRatingIndex, 1);
      return {
        ...state,
        ratings: afterDeleteRatings,
        loading: { ...state.loading, ratings: false },
        error: { ...state.error, ratings: false },
      };
    case DELETE_RATING_FAILED:
      return {
        ...state,
        loading: { ...state.loading, ratings: false },
        error: { ...state.error, ratings: true },
      };
    case FETCHING_EVENT_TYPE:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, eventTypes: action.sourceToken },
        loading: { ...state.loading, eventTypes: true },
        error: { ...state.error, eventTypes: false },
      };
    case FETCH_EVENT_TYPE_SUCCESS:
      const changedEventTypeIndex = state.eventTypes.findIndex(eventType => eventType.id === action.eventType.id);
      if (changedEventTypeIndex === -1) {
        return {
          ...state,
          eventTypes: [...state.eventTypes.filter(eventType => eventType.id !== action.eventType.id), action.eventType],
          loading: { ...state.loading, eventTypes: false },
          error: { ...state.error, eventTypes: false },
        };
      }
      const newEventTypes = [...state.eventTypes];
      newEventTypes[changedEventTypeIndex] = action.eventType;
      return {
        ...state,
        eventTypes: newEventTypes,
        loading: { ...state.loading, eventTypes: false },
        error: { ...state.error, eventTypes: false },
      };
    case FETCH_EVENT_TYPE_FAILED:
      if (action.sourceToken === state.sourceToken.eventTypes) {
        return {
          ...state,
          loading: { ...state.loading, eventTypes: false },
          error: { ...state.error, eventTypes: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_EVENT_TYPE_FINISHED:
      if (action.sourceToken === state.sourceToken.eventTypes) {
        return {
          ...state,
          loading: { ...state.loading, eventTypes: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_EVENT_TYPE:
      return {
        ...state,
        loading: { ...state.loading, eventTypes: true },
        error: { ...state.error, eventTypes: false },
      };
    case DELETE_EVENT_TYPE_SUCCESS:
      const deletedEventTypeIndex = state.eventTypes.findIndex(eventType => eventType.id === action.id);
      const afterDeleteEventTypes = [...state.eventTypes];
      afterDeleteEventTypes.splice(deletedEventTypeIndex, 1);
      return {
        ...state,
        eventTypes: afterDeleteEventTypes,
        loading: { ...state.loading, eventTypes: false },
        error: { ...state.error, eventTypes: false },
      };
    case DELETE_EVENT_TYPE_FAILED:
      return {
        ...state,
        loading: { ...state.loading, eventTypes: false },
        error: { ...state.error, eventTypes: true },
      };
    case FETCHING_EVENT_TYPES:
      const isEventTypesFetchParamsEqual = isEqual(state.eventTypesFetchParams, action.fetchParams);
      return {
        ...state,
        eventTypes: isEventTypesFetchParamsEqual ? state.eventTypes : [],
        sourceToken: { ...state.sourceToken, eventTypes: action.sourceToken },
        loading: { ...state.loading, eventTypes: true },
        error: { ...state.error, eventTypes: false },
      };
    case FETCH_EVENT_TYPES_SUCCESS:
      return {
        ...state,
        eventTypes: [...action.eventTypes],
        eventTypesPagination: action.eventTypesPagination,
        eventTypesFetchParams: action.fetchParams,
        loading: { ...state.loading, eventTypes: false },
        error: { ...state.error, eventTypes: false },
      };
    case FETCH_EVENT_TYPES_FAILED:
      if (action.sourceToken === state.sourceToken.eventTypes) {
        return {
          ...state,
          eventTypes: [],
          eventTypesFetchParams: state.eventTypesFetchParams,
          loading: { ...state.loading, eventTypes: false },
          error: { ...state.error, eventTypes: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_EVENT_TYPES_FINISHED:
      if (action.sourceToken === state.sourceToken.eventTypes) {
        return {
          ...state,
          loading: { ...state.loading, eventTypes: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_USER:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, users: action.sourceToken },
        loading: { ...state.loading, users: true },
        error: { ...state.error, users: false },
      };
    case FETCH_USER_SUCCESS:
      const changedUserIndex = state.users.findIndex(user => user.id === action.user.id);
      if (changedUserIndex === -1) {
        return {
          ...state,
          users: [...state.users.filter(user => user.id !== action.user.id), action.user],
          loading: { ...state.loading, users: false },
          error: { ...state.error, users: false },
        };
      }
      const newUsers = [...state.users];
      newUsers[changedUserIndex] = action.user;
      return {
        ...state,
        users: newUsers,
        loading: { ...state.loading, users: false },
        error: { ...state.error, users: false },
      };
    case FETCH_USER_FAILED:
      if (action.sourceToken === state.sourceToken.users) {
        return {
          ...state,
          loading: { ...state.loading, users: false },
          error: { ...state.error, users: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_USER_FINISHED:
      if (action.sourceToken === state.sourceToken.users) {
        return {
          ...state,
          loading: { ...state.loading, users: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_USER:
      return {
        ...state,
        loading: { ...state.loading, users: true },
        error: { ...state.error, users: false },
      };
    case DELETE_USER_SUCCESS:
      const deletedUserIndex = state.users.findIndex(user => user.id === action.id);
      const afterDeleteUsers = [...state.users];
      afterDeleteUsers.splice(deletedUserIndex, 1);
      return {
        ...state,
        users: afterDeleteUsers,
        loading: { ...state.loading, users: false },
        error: { ...state.error, users: false },
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        loading: { ...state.loading, users: false },
        error: { ...state.error, users: true },
      };
    case FETCHING_USERS:
      const isUsersFetchParamsEqual = isEqual(state.usersFetchParams, action.fetchParams);
      return {
        ...state,
        users: isUsersFetchParamsEqual ? state.users : [],
        sourceToken: { ...state.sourceToken, users: action.sourceToken },
        loading: { ...state.loading, users: true },
        error: { ...state.error, users: false },
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: [...action.users],
        usersPagination: action.usersPagination,
        usersFetchParams: action.fetchParams,
        loading: { ...state.loading, users: false },
        error: { ...state.error, users: false },
      };
    case FETCH_USERS_FAILED:
      if (action.sourceToken === state.sourceToken.users) {
        return {
          ...state,
          users: [],
          usersFetchParams: state.usersFetchParams,
          loading: { ...state.loading, users: false },
          error: { ...state.error, users: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_USERS_FINISHED:
      if (action.sourceToken === state.sourceToken.users) {
        return {
          ...state,
          loading: { ...state.loading, users: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_GROUP:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, groups: action.sourceToken },
        loading: { ...state.loading, groups: true },
        error: { ...state.error, groups: false },
      };
    case FETCH_GROUP_SUCCESS:
      const changedGroupIndex = state.groups.findIndex(group => group.id === action.group.id);
      if (changedGroupIndex === -1) {
        return {
          ...state,
          groups: [...state.groups.filter(group => group.id !== action.group.id), action.group],
          loading: { ...state.loading, groups: false },
          error: { ...state.error, groups: false },
        };
      }
      const newGroups = [...state.groups];
      newGroups[changedGroupIndex] = action.group;
      return {
        ...state,
        groups: newGroups,
        loading: { ...state.loading, groups: false },
        error: { ...state.error, groups: false },
      };
    case FETCH_GROUP_FAILED:
      if (action.sourceToken === state.sourceToken.groups) {
        return {
          ...state,
          loading: { ...state.loading, groups: false },
          error: { ...state.error, groups: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_GROUP_FINISHED:
      if (action.sourceToken === state.sourceToken.groups) {
        return {
          ...state,
          loading: { ...state.loading, groups: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_GROUP:
      return {
        ...state,
        loading: { ...state.loading, groups: true },
        error: { ...state.error, groups: false },
      };
    case DELETE_GROUP_SUCCESS:
      const deletedGroupIndex = state.groups.findIndex(group => group.id === action.id);
      const afterDeleteGroups = [...state.groups];
      afterDeleteGroups.splice(deletedGroupIndex, 1);
      return {
        ...state,
        groups: afterDeleteGroups,
        loading: { ...state.loading, groups: false },
        error: { ...state.error, groups: false },
      };
    case DELETE_GROUP_FAILED:
      return {
        ...state,
        loading: { ...state.loading, groups: false },
        error: { ...state.error, groups: true },
      };
    case FETCHING_GROUPS:
      const isGroupsFetchParamsEqual = isEqual(state.groupsFetchParams, action.fetchParams);
      return {
        ...state,
        groups: isGroupsFetchParamsEqual ? state.groups : [],
        sourceToken: { ...state.sourceToken, groups: action.sourceToken },
        loading: { ...state.loading, groups: true },
        error: { ...state.error, groups: false },
      };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        groups: [...action.groups],
        groupsFetchParams: action.fetchParams,
        groupsPagination: action.groupsPagination,
        loading: { ...state.loading, groups: false },
        error: { ...state.error, groups: false },
      };
    case FETCH_GROUPS_FAILED:
      if (action.sourceToken === state.sourceToken.groups) {
        return {
          ...state,
          groups: [],
          usersFetchParams: state.usersFetchParams,
          loading: { ...state.loading, groups: false },
          error: { ...state.error, groups: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_GROUPS_FINISHED:
      if (action.sourceToken === state.sourceToken.groups) {
        return {
          ...state,
          loading: { ...state.loading, groups: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_FARM:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, farm: action.sourceToken },
        loading: { ...state.loading, farm: true },
        error: { ...state.error, farm: false },
      };
    case FETCH_FARM_SUCCESS:
      return {
        ...state,
        farm: action.farm,
        loading: { ...state.loading, farm: false },
        error: { ...state.error, farm: false },
      };
    case FETCH_FARM_FAILED:
      if (action.sourceToken === state.sourceToken.farm) {
        return {
          ...state,
          loading: { ...state.loading, farm: false },
          error: { ...state.error, farm: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_FARM_FINISHED:
      if (action.sourceToken === state.sourceToken.farm) {
        return {
          ...state,
          loading: { ...state.loading, farm: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_ABSENCE:
      return {
        ...state,
        loading: { ...state.loading, absences: true },
        error: { ...state.error, absences: false },
      };
    case DELETE_ABSENCE_SUCCESS:
      const deletedAbsenceIndex = state.absences.findIndex(absence => absence.id === action.id);
      const afterDeleteAbsences = [...state.absences];
      afterDeleteAbsences.splice(deletedAbsenceIndex, 1);
      return {
        ...state,
        absences: afterDeleteAbsences,
        loading: { ...state.loading, absences: false },
        error: { ...state.error, absences: false },
      };
    case DELETE_ABSENCE_FAILED:
      return {
        ...state,
        loading: { ...state.loading, absences: false },
        error: { ...state.error, absences: true },
      };
    case FETCHING_ABSENCES:
      const isAbsencesFetchParamsEqual = isEqual(state.absencesFetchParams, action.fetchParams);
      return {
        ...state,
        absences: isAbsencesFetchParamsEqual ? state.absences : [],
        sourceToken: { ...state.sourceToken, absences: action.sourceToken },
        loading: { ...state.loading, absences: true },
        error: { ...state.error, absences: false },
      };
    case FETCH_ABSENCES_SUCCESS:
      return {
        ...state,
        absences: [...action.absences],
        absencesPagination: action.absencesPagination,
        absencesFetchParams: action.fetchParams,
        loading: { ...state.loading, absences: false },
        error: { ...state.error, absences: false },
      };
    case FETCH_ABSENCES_FAILED:
      if (action.sourceToken === state.sourceToken.absences) {
        return {
          ...state,
          absences: [],
          absencesFetchParams: state.absencesFetchParams,
          loading: { ...state.loading, absences: false },
          error: { ...state.error, absences: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_ABSENCES_FINISHED:
      if (action.sourceToken === state.sourceToken.absences) {
        return {
          ...state,
          loading: { ...state.loading, absences: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_SINGLE_NEWS:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, news: action.sourceToken },
        loading: { ...state.loading, news: true },
        error: { ...state.error, news: false },
      };
    case FETCH_SINGLE_NEWS_SUCCESS:
      const changedNewsIndex = state.news.findIndex(singleNews => singleNews.id === action.news.id);
      if (changedNewsIndex === -1) {
        return {
          ...state,
          news: [...state.news.filter(singleNews => singleNews.id !== action.news.id), action.news],
          loading: { ...state.loading, news: false },
          error: { ...state.error, news: false },
        };
      }
      const newNews = [...state.news];
      newNews[changedNewsIndex] = action.news;
      return {
        ...state,
        news: newNews,
        loading: { ...state.loading, news: false },
        error: { ...state.error, news: false },
      };
    case FETCH_SINGLE_NEWS_FAILED:
      if (action.sourceToken === state.sourceToken.news) {
        return {
          ...state,
          loading: { ...state.loading, news: false },
          error: { ...state.error, news: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_SINGLE_NEWS_FINISHED:
      if (action.sourceToken === state.sourceToken.news) {
        return {
          ...state,
          loading: { ...state.loading, news: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_NEWS:
      return {
        ...state,
        loading: { ...state.loading, news: true },
        error: { ...state.error, news: false },
      };
    case DELETE_NEWS_SUCCESS:
      const deletedNewsIndex = state.news.findIndex(singleNews => singleNews.id === action.id);
      const afterDeleteNews = [...state.news];
      afterDeleteNews.splice(deletedNewsIndex, 1);
      return {
        ...state,
        news: afterDeleteNews,
        loading: { ...state.loading, news: false },
        error: { ...state.error, news: false },
      };
    case DELETE_NEWS_FAILED:
      return {
        ...state,
        loading: { ...state.loading, news: false },
        error: { ...state.error, news: true },
      };
    case FETCHING_NEWS:
      const isNewsFetchParamsEqual = isEqual(state.newsFetchParams, action.fetchParams);
      return {
        ...state,
        news: isNewsFetchParamsEqual ? state.news : [],
        sourceToken: { ...state.sourceToken, news: action.sourceToken },
        loading: { ...state.loading, news: true },
        error: { ...state.error, news: false },
      };
    case FETCH_NEWS_SUCCESS:
      return {
        ...state,
        news: [...action.news],
        newsPagination: action.newsPagination,
        newsFetchParams: action.fetchParams,
        loading: { ...state.loading, news: false },
        error: { ...state.error, news: false },
      };
    case FETCH_NEWS_FAILED:
      if (action.sourceToken === state.sourceToken.news) {
        return {
          ...state,
          news: [],
          newsFetchParams: state.newsFetchParams,
          loading: { ...state.loading, news: false },
          error: { ...state.error, news: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_NEWS_FINISHED:
      if (action.sourceToken === state.sourceToken.news) {
        return {
          ...state,
          loading: { ...state.loading, news: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_SHIFT:
      return {
        ...state,
        loading: { ...state.loading, shifts: true },
        error: { ...state.error, shifts: false },
      };
    case DELETE_SHIFT_SUCCESS:
      const deletedShiftIndex = state.shifts.findIndex(shift => shift.id === action.id);
      const afterDeleteShifts = [...state.shifts];
      afterDeleteShifts.splice(deletedShiftIndex, 1);
      return {
        ...state,
        shifts: afterDeleteShifts,
        loading: { ...state.loading, shifts: false },
        error: { ...state.error, shifts: false },
      };
    case DELETE_SHIFT_FAILED:
      return {
        ...state,
        loading: { ...state.loading, shifts: false },
        error: { ...state.error, shifts: true },
      };
    case FETCHING_SHIFTS:
      const isShiftsFetchParamsEqual = isEqual(state.shiftsFetchParams, action.fetchParams);
      return {
        ...state,
        shifts: isShiftsFetchParamsEqual ? state.shifts : [],
        sourceToken: { ...state.sourceToken, shifts: action.sourceToken },
        loading: { ...state.loading, shifts: true },
        error: { ...state.error, shifts: false },
      };
    case FETCH_SHIFTS_SUCCESS:
      return {
        ...state,
        shifts: [...action.shifts],
        shiftsPagination: action.shiftsPagination,
        shiftsFetchParams: action.fetchParams,
        loading: { ...state.loading, shifts: false },
        error: { ...state.error, shifts: false },
      };
    case FETCH_SHIFTS_FAILED:
      if (action.sourceToken === state.sourceToken.shifts) {
        return {
          ...state,
          shifts: [],
          shiftsFetchParams: state.shiftsFetchParams,
          loading: { ...state.loading, shifts: false },
          error: { ...state.error, shifts: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_SHIFTS_FINISHED:
      if (action.sourceToken === state.sourceToken.shifts) {
        return {
          ...state,
          loading: { ...state.loading, shifts: false },
        };
      }
      return {
        ...state,
      };
    case DELETING_PROPOSAL:
      return {
        ...state,
        loading: { ...state.loading, proposals: true },
        error: { ...state.error, proposals: false },
      };
    case DELETE_PROPOSAL_SUCCESS:
      const deletedProposalIndex = state.proposals.findIndex(proposal => proposal.id === action.id);
      const afterDeleteProposals = [...state.proposals];
      afterDeleteProposals.splice(deletedProposalIndex, 1);
      return {
        ...state,
        proposals: afterDeleteProposals,
        loading: { ...state.loading, proposals: false },
        error: { ...state.error, proposals: false },
      };
    case DELETE_PROPOSAL_FAILED:
      return {
        ...state,
        loading: { ...state.loading, proposals: false },
        error: { ...state.error, proposals: true },
      };
    case FETCHING_PROPOSALS:
      const isProposalsFetchParamsEqual = isEqual(state.proposalsFetchParams, action.fetchParams);
      return {
        ...state,
        proposals: isProposalsFetchParamsEqual ? state.proposals : [],
        sourceToken: { ...state.sourceToken, proposals: action.sourceToken },
        loading: { ...state.loading, proposals: true },
        error: { ...state.error, proposals: false },
      };
    case FETCH_PROPOSALS_STARTED:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, proposals: action.sourceToken },
        loading: { ...state.loading, proposals: true },
      };
    case FETCH_PROPOSALS_NEXT_SUCCESS:
      return {
        ...state,
        proposals: unionBy(state.proposals, action.proposals, 'id'),
        proposalsPagination: action.pagination,
        proposalsFetchParams: action.fetchParams,
        loading: { ...state.loading, proposals: false },
        error: { ...state.error, proposals: false },
      };
    case FETCH_PROPOSALS_SUCCESS:
      return {
        ...state,
        proposals: [...action.proposals],
        proposalsPagination: action.proposalsPagination,
        proposalsFetchParams: action.fetchParams,
        loading: { ...state.loading, proposals: false },
        error: { ...state.error, proposals: false },
      };
    case FETCH_PROPOSALS_FAILED:
      if (action.sourceToken === state.sourceToken.proposals) {
        return {
          ...state,
          proposals: [],
          proposalsFetchParams: state.proposalsFetchParams,
          loading: { ...state.loading, proposals: false },
          error: { ...state.error, proposals: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_PROPOSALS_FINISHED:
      if (action.sourceToken === state.sourceToken.proposals) {
        return {
          ...state,
          loading: { ...state.loading, proposals: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_NOTIFICATIONS:
      const isNotificationsFetchParamsEqual = isEqual(state.notificationsFetchParams, action.fetchParams);
      return {
        ...state,
        notifications: isNotificationsFetchParamsEqual ? state.notifications : [],
        sourceToken: { ...state.sourceToken, notifications: action.sourceToken },
        loading: { ...state.loading, notifications: true },
        error: { ...state.error, notifications: false },
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: [...action.notifications],
        notificationsPagination: action.notificationsPagination,
        notificationsFetchParams: action.fetchParams,
        loading: { ...state.loading, notifications: false },
        error: { ...state.error, notifications: false },
      };
    case FETCH_NOTIFICATIONS_FAILED:
      if (action.sourceToken === state.sourceToken.notifications) {
        return {
          ...state,
          notifications: [],
          notificationsFetchParams: state.notificationsFetchParams,
          loading: { ...state.loading, notifications: false },
          error: { ...state.error, notifications: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_NOTIFICATIONS_FINISHED:
      if (action.sourceToken === state.sourceToken.notifications) {
        return {
          ...state,
          loading: { ...state.loading, notifications: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_BILLS:
      const isBillsFetchParamsEqual = isEqual(state.billsFetchParams, action.fetchParams);
      return {
        ...state,
        bills: isBillsFetchParamsEqual ? state.bills : [],
        sourceToken: { ...state.sourceToken, bills: action.sourceToken },
        loading: { ...state.loading, bills: true },
        error: { ...state.error, bills: false },
      };
    case FETCH_BILLS_SUCCESS:
      return {
        ...state,
        bills: [...action.bills],
        billsPagination: action.billsPagination,
        billsFetchParams: action.fetchParams,
        loading: { ...state.loading, bills: false },
        error: { ...state.error, bills: false },
      };
    case FETCH_BILLS_FAILED:
      if (action.sourceToken === state.sourceToken.bills) {
        return {
          ...state,
          bills: [],
          billsFetchParams: state.billsFetchParams,
          loading: { ...state.loading, bills: false },
          error: { ...state.error, bills: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_BILLS_FINISHED:
      if (action.sourceToken === state.sourceToken.bills) {
        return {
          ...state,
          loading: { ...state.loading, bills: false },
        };
      }
      return {
        ...state,
      };
    case FETCHING_BILL:
      return {
        ...state,
        sourceToken: { ...state.sourceToken, bills: action.sourceToken },
        loading: { ...state.loading, bills: [action.id] },
        error: { ...state.error, bills: false },
      };
    case FETCH_BILL_SUCCESS:
      const changedBillIndex = state.bills.findIndex(bill => bill.id === action.bill.id);
      if (changedBillIndex === -1) {
        return {
          ...state,
          bills: [...state.bills.filter(bill => bill.id !== action.bill.id), action.bill],
          loading: { ...state.loading, bills: false },
          error: { ...state.error, bills: false },
        };
      }
      const newBills = [...state.bills];
      newBills[changedBillIndex] = action.bill;
      return {
        ...state,
        bills: newBills,
        loading: { ...state.loading, bills: false },
        error: { ...state.error, bills: false },
      };
    case FETCH_BILL_FAILED:
      if (action.sourceToken === state.sourceToken.bills) {
        return {
          ...state,
          loading: { ...state.loading, bills: false },
          error: { ...state.error, bills: true },
        };
      }
      return {
        ...state,
      };
    case FETCH_BILL_FINISHED:
      if (action.sourceToken === state.sourceToken.bills) {
        return {
          ...state,
          loading: { ...state.loading, bills: false },
        };
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default dataReducer;
