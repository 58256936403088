import { connect } from 'react-redux';
import moment from 'moment';

import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';
import { changeFieldState } from 'store/actions';
import t from 'resources/translations';
import { isCorrectFormat } from 'old/utils';
import config from '@old/config';

const DateValue = ({ onChangeField, label, placeholder, validation, required, minDate, maxDate, ...fieldProps }) => {
  const { name, formName } = fieldProps;

  const onChange = value => {
    onChangeField({ formName, fieldName: name, fieldState: value });
  };

  const validationFuncs = [
    ...validation,
    {
      condition: () => isCorrectFormat(fieldProps.value, config.dateFormat),
      errorHint: t('error.badDateFormatDDMMYYYY'),
    },
    {
      condition: () => {
        if (isCorrectFormat(fieldProps.value, config.dateFormat)) {
          const selectedDate = moment(fieldProps.value, config.dateFormat);
          const correctForMinDate = !minDate || selectedDate.isAfter(minDate);
          const correctForMaxDate = !maxDate || selectedDate.isBefore(maxDate);
          return correctForMinDate && correctForMaxDate;
        }
        return true;
      },
      errorHint: t('error.pleaseSelectDifferentDate'),
    },
  ];

  const pickerOptions = {
    minDate,
    maxDate,
    defaultDate: fieldProps.defaultDate,
  };

  return (
    <FormField validation={validationFuncs} required={required} {...fieldProps}>
      <Input.DateValue
        onChange={onChange}
        name={name}
        value={fieldProps.value}
        label={label}
        placeholder={placeholder}
        required={required}
        pickerOptions={pickerOptions}
      />
    </FormField>
  );
};

DateValue.defaultProps = {
  value: '',
  validation: [],
  minDate: null,
  maxDate: null,
  defaultDate: undefined,
  required: false,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name]?.value;
  return { value: inputValue };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(DateValue);
