import { connect } from 'react-redux';
import { split } from 'lodash';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';
import RowAligner from '@old/components/common/RowAligner';
import t from 'resources/translations';
import { isCorrectFormat } from 'old/utils';
import config from '@old/config';

const DateTimeField = ({ onChangeField, placeholder, validation, label, pickerOptions, ...props }) => {
  const { name, formName, required, dataTest } = props;
  const dateTime = split(props.value, ' ');
  const date = dateTime[0];
  const time = dateTime[1];

  const onChangeDate = value => {
    const fieldState = `${value} ${time}`;
    onChangeField({ formName, fieldName: name, fieldState });
  };

  const onChangeTime = value => {
    const fieldState = `${date} ${value}`;
    onChangeField({ formName, fieldName: name, fieldState });
  };

  const validationFuncs = [
    ...validation,
    {
      condition: () => isCorrectFormat(props.value, config.dateTimeFormat),
      errorHint: t('error.badDateFormatDDMMYYYYHHMM'),
    },
  ];

  return (
    <FormField validation={validationFuncs} {...props}>
      <RowAligner className="items-baseline">
        <Input.DateValue
          value={date}
          name={name}
          label={label}
          onChange={onChangeDate}
          placeholder={placeholder}
          required={required}
          pickerOptions={pickerOptions}
        />
        <Input.Time dataTest={dataTest} value={time} onChange={onChangeTime} required={required} />
      </RowAligner>
    </FormField>
  );
};

DateTimeField.defaultProps = {
  value: '',
  label: '',
  validation: [],
  placeholder: '',
  required: false,
  dataTest: '',
  pickerOptions: {
    minDate: null,
    maxDate: null,
    defaultIsOpen: false,
  },
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return { value: inputValue };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(DateTimeField);
