import t from 'resources/translations';
import { Button, Content, Flex, Icons, Stack, Text } from 'components/elements';
import { Tag, TagLabel, TagRemoveButton, Wrap } from 'components/combinations';
import { TBillFilters } from 'resources/types/billsTypes';
import _ from 'lodash';

type TBillsFiltersTagsProps = {
  filters: TBillFilters;
  onChangeFilters: (val: TBillFilters) => void;
};

const BillsFiltersTags = ({ filters, onChangeFilters }: TBillsFiltersTagsProps) => {
  const onDeleteTag = (type: keyof TBillFilters, key: string) => {
    const currentFilters = _.cloneDeep(filters);
    const isArray = Array.isArray(currentFilters[type]);
    const isGroup = type === key;
    if (isArray && !isGroup) {
      // @ts-ignore
      currentFilters[type] = (currentFilters[type] || []).filter(filter => key !== filter.value);
      if (currentFilters[type]?.length === 0) {
        delete currentFilters[type];
      }
    } else {
      delete currentFilters[type];
    }
    onChangeFilters(currentFilters);
  };
  const getFiltersTagsItem = () => {
    const filtersKeys = Object.keys(filters) as Array<keyof TBillFilters>;
    const items = filtersKeys.map(key => {
      const isArray = Array.isArray(filters[key]);
      const isGrouped = isArray && (filters[key]?.length || 0) > 3;
      if (isGrouped) {
        return {
          key,
          type: key,
          /* @ts-ignore */
          label: `${t(`bills.filters.${key}`)} (${filters[key]?.length})`,
        };
      }
      if (isArray) {
        // @ts-ignore
        return (filters[key] || []).map(filterItem => ({
          key: filterItem.value,
          type: key,
          label: filterItem.label,
        }));
      }
      if (filters[key])
        return {
          key: filters[key],
          type: key,
          label: ['start_after', 'end_before'].includes(key)
            ? /* @ts-ignore */
              `${t(`bills.filters.${key}`)}: ${filters[key]}`
            : filters[key],
        };
      return [];
    });
    return _.flatten(items) as { key: string; type: keyof TBillFilters; label: string }[];
  };

  if (!!getFiltersTagsItem().length) {
    return (
      <Content px="0">
        <Stack spacing="sm" mt="md">
          <Flex>
            <Button variant="link" py="sm" onClick={() => onChangeFilters({})}>
              <Stack spacing="sm" isHorizontal alignItems="center">
                <Icons.TrashCircle bgColor="teal-light-15" />
                <Text fontSize="base">{t('bills.clearFilters')}</Text>
              </Stack>
            </Button>
          </Flex>
          <Wrap spacing="xs">
            {getFiltersTagsItem().map(
              (itemTag, index) =>
                itemTag.label && (
                  <Tag key={`filter-${index}`}>
                    <TagLabel>{itemTag.label}</TagLabel>
                    <TagRemoveButton onClick={() => onDeleteTag(itemTag.type, itemTag.key)} />
                  </Tag>
                )
            )}
          </Wrap>
        </Stack>
      </Content>
    );
  }
  return null;
};

export default BillsFiltersTags;
