import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import Spinner from '@old/components/common/Spinner';
import t from 'resources/translations';
import PageTitle from '@old/components/guide/PageTitle';
import { useTabs } from '@old/hooks';
import Error404 from '@old/components/error/Error404';
import { fetchHorse } from 'store/actions';

import ViewPager from '@old/components/guide/ViewPager';
import HorseView from '@old/components/custom/HorseView';
import Absences from 'old/pages/Absences';

const HorseDetails = ({ match, loading, error, horse, ...actions }) => {
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchHorse(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  const tabs = [
    {
      name: t('horseDetails.details'),
      view: HorseView,
      props: {
        match,
        horse,
        loading,
        ...actions,
      },
      permissionKey: 'horses.show',
    },
    {
      name: t('horseDetails.absences'),
      view: Absences,
      props: {
        match,
      },
      permissionKey: 'horse_absence.show',
    },
  ];

  const horseTabs = useTabs(tabs);

  if (error) return <Error404 />;
  if (loading && !horse) return <Spinner />;
  if (!horse) return null;

  return (
    <React.Fragment>
      <PageTitle title={horse.getName()} back="/horses" />
      <ViewPager tabs={horseTabs} />
    </React.Fragment>
  );
};
HorseDetails.defaultProps = {
  horse: null,
};

const mapStateToProps = ({ fetchingData: { horses, loading, error } }, { match }) => {
  return {
    horse: horses.find(horse => String(horse.id) === String(match.params.id)),
    loading: loading.horses,
    error: error.horses,
  };
};

export default connect(mapStateToProps, { fetchHorse })(HorseDetails);
