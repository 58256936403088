import styled from 'styled-components';
import { flexbox } from 'styled-system';
import { baseStyledProps, typographyStyleProps, blockStyleProps } from 'config/styledProps';
import { TLabelProps } from 'resources/types/elementsTypes';

const Label = styled('label')<TLabelProps>(baseStyledProps, typographyStyleProps, blockStyleProps, flexbox);

Label.defaultProps = {
  fontWeight: 'medium',
  lineHeight: 1,
  fontSize: 'base',
};

export default Label;
