import Confirm from 'views/modals/Confirm';
import TicketOfferDetails from 'views/modals/TicketOfferDetails';
import TicketAssign from 'views/modals/TicketAssign';
import TicketRidesEdit from 'views/modals/TicketRidesEdit';
import TicketValidityEdit from 'views/modals/TicketValidityEdit';
import BillHistoryPayments from 'views/modals/BillHistoryPayments';
import PaymentTicket from 'views/modals/PaymentTicket';
import PaymentEvent from 'views/modals/PaymentEvent';
import PaymentOnline from 'views/modals/PaymentOnline';
import PaymentReturn from 'views/modals/PaymentReturn';
import PaymentMassive from 'views/modals/PaymentMassive';
import PaymentBuyTicket from 'views/modals/PaymentBuyTicket';
import { useModal } from 'old/hooks';

export type TModalName =
  | 'PAYMENT_EVENT'
  | 'PAYMENT_TICKET'
  | 'PAYMENT_BUY_TICKET'
  | 'PAYMENT_ONLINE'
  | 'PAYMENT_RETURN'
  | 'PAYMENT_MASSIVE'
  | 'TICKET_ASSIGN'
  | 'TICKET_OFFER_DETAILS'
  | 'TICKET_RIDES_EDIT'
  | 'TICKET_VALIDITY_EDIT'
  | 'BILL_HISTORY_PAYMENTS'
  | 'CONFIRM';

type TModal = {
  onUpdate: Function;
  onSubmit: Function;
  onSubmitAndClose: Function;
  onClose: () => void;
  isSubmitting: boolean;
  isHidden: boolean;
  isOpen: boolean;
};

export type TWrappedModal = TModal & {
  name: TModalName;
};

export type TStoreStateModals = Record<
  TModalName,
  {
    isOpen: boolean;
    isHidden: boolean;
    props: object;
  }
>;

export function withModalController(WrappedModal: React.ComponentType<TWrappedModal>, { name }: { name: TModalName }) {
  const displayName = WrappedModal.displayName || WrappedModal.name || 'Component';
  const ModalController = () => {
    const { isOpen, ...restModalProps }: TModal = useModal(name);

    if (!isOpen) {
      return null;
    }
    return <WrappedModal name={name} {...restModalProps} isOpen />;
  };

  ModalController.displayName = `withModal(${displayName})`;
  return ModalController;
}

export const ConfirmModal = withModalController(Confirm, { name: 'CONFIRM' });
export const BillHistoryPaymentsModal = withModalController(BillHistoryPayments, { name: 'BILL_HISTORY_PAYMENTS' });
export const TicketOfferDetailsModal = withModalController(TicketOfferDetails, { name: 'TICKET_OFFER_DETAILS' });
export const TicketRidesEditModal = withModalController(TicketRidesEdit, { name: 'TICKET_RIDES_EDIT' });
export const TicketValidityEditModal = withModalController(TicketValidityEdit, { name: 'TICKET_VALIDITY_EDIT' });
export const TicketAssignModal = withModalController(TicketAssign, { name: 'TICKET_ASSIGN' });
export const PaymentTicketModal = withModalController(PaymentTicket, { name: 'PAYMENT_TICKET' });
export const PaymentEventModal = withModalController(PaymentEvent, { name: 'PAYMENT_EVENT' });
export const PaymentOnlineModal = withModalController(PaymentOnline, { name: 'PAYMENT_ONLINE' });
export const PaymentBuyTicketModal = withModalController(PaymentBuyTicket, { name: 'PAYMENT_BUY_TICKET' });
export const PaymentReturnModal = withModalController(PaymentReturn, { name: 'PAYMENT_RETURN' });
export const PaymentMassiveModal = withModalController(PaymentMassive, { name: 'PAYMENT_MASSIVE' });
