import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { connect } from 'react-redux';
import axios from 'axios';

import WeekSwitch from '@old/components/old/WeekSwitch';
import t from 'resources/translations';
import HorseStatisticsBarChart from '@old/components/view/charts/HorseStatisticsBarChart';
import HorseStatisticsPieChart from '@old/components/view/charts/HorseStatisticsPieChart';
import HeatMap from '@old/components/view/charts/HeatMap';
import SelectDateRangeModal from '@old/components/modals/SelectDateRange';
import Spinner from '@old/components/common/Spinner';
import PageTitle from '@old/components/guide/PageTitle';
import FlexRow from '@old/components/common/FlexRow';
import FlexColumn from '@old/components/common/FlexColumn';
import EventsList from '@old/components/view/list/Event';
import Box from '@old/components/common/Box';
import { usePieChartData, useBarChartData, useEventsWithChart, useHeatMap } from '@old/hooks';
import RowAligner from '@old/components/common/RowAligner';
import Error404 from '@old/components/error/Error404';
import { fetchHorse } from 'store/actions';

const moment = extendMoment(Moment);

const HorseStatistics = ({ match, loading, error, horse, ...actions }) => {
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchHorse(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  const [barChartDateRange, setBarChartDateRange] = useState({
    start: moment().startOf('week'),
    end: moment().endOf('week'),
  });
  const [barChartData, loadingBarChart] = useBarChartData(match.params.id, 'horses', barChartDateRange);
  const eventsGroupedByDay = useEventsWithChart(barChartDateRange, {
    'with_horse[]': horse ? horse.id : 0,
  });

  const [selectedDay, setSelectedDay] = useState('');

  const [pieChartDateRange, pieChartSetDateRange] = useState({
    start: moment().subtract(30, 'days').startOf('day'),
    end: moment().endOf('day'),
  });
  const [pieChartData] = usePieChartData(match.params.id, 'horses', pieChartDateRange);

  const heatMapProps = useHeatMap(match.params.id, 'horses', pieChartDateRange);

  if (error) return <Error404 />;

  if (loading && !horse) {
    return <Spinner />;
  }

  if (!horse) return null;

  const onWeekChange = value => {
    setBarChartDateRange(value);
    setSelectedDay('');
  };

  const onBarClick = barProps => {
    const activeLabel = barProps ? barProps.activeLabel : '';
    const dayData = barChartData.find(item => item.name === activeLabel);
    setSelectedDay(dayData ? dayData.label : '');
  };

  return (
    <React.Fragment>
      <PageTitle title={horse.getName()} back loading={loading} />
      <SelectDateRangeModal dateRange={pieChartDateRange} onSave={pieChartSetDateRange} />
      <FlexRow cols="10/6" stretched marginBottom>
        <Box>
          <div className="mb-4">{t('horseStatistics.frequentHours')}</div>
          <HeatMap {...heatMapProps} />
        </Box>
        <FlexColumn>
          <Box centerX centerY fullHeight>
            <HorseStatisticsPieChart data={pieChartData} />
          </Box>
          <Box centerX centerY fullHeight>
            <div>
              <div className="flex items-center mb-4">
                <div className="bg-red legend-square mr-2" /> {t('horseStatistics.limitExceeded')}
              </div>
              <div className="flex items-center">
                <div className="bg-teal legend-square mr-2" /> {t('horseStatistics.limitNotExceeded')}
              </div>
            </div>
          </Box>
        </FlexColumn>
      </FlexRow>
      <Box>
        <RowAligner className="justify-around text-center">
          {t('horseStatistics.hoursStatLabel')}
          <WeekSwitch dateRange={barChartDateRange} setDateRange={onWeekChange} />
        </RowAligner>
        {loadingBarChart && (
          <div style={{ minHeight: 350 }}>
            <Spinner />
          </div>
        )}
        {!loadingBarChart && (
          <HorseStatisticsBarChart data={barChartData} activeDay={selectedDay} onBarClick={onBarClick} />
        )}
      </Box>
      {selectedDay && (
        <div className="text-heading mt-4">{moment(selectedDay, 'YYYY-MM-DD').format('dddd, DD.MM.YYYY')}</div>
      )}
      <EventsList
        hideEmptyMessage
        fetchedEvents={(eventsGroupedByDay[selectedDay] || [])
          .sort((a, b) => a.startDate.diff(b.startDate))
          .filter(event => event.isFinished || event.isCancelled)}
      />
    </React.Fragment>
  );
};

HorseStatistics.defaultProps = {
  horse: null,
};

const mapStateToProps = ({ fetchingData: { horses, loading, error } }, { match }) => {
  return {
    horse: horses.find(horse => String(horse.id) === String(match.params.id)),
    loading: loading.horses,
    error: error.horses,
  };
};

export default connect(mapStateToProps, { fetchHorse })(HorseStatistics);
