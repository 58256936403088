import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import times from 'lodash/times';
import cls from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Center from '@old/components/common/Center';
import BoxShadow from '@old/components/common/BoxShadow';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import FlexRow from '@old/components/common/FlexRow';
import ModalSimple from '@old/components/common/ModalSimple';
import { getRowsInMonth, getCalendarKey } from 'old/utils';
import { useScrollBottomOfBox } from '@old/hooks';
import { setWeek } from 'store/actions';

const dateFormat = 'YYYY-MM-DD';
const WeekPicker = ({
  value,
  onChange,
  close,
  maxDate,
  minDate,
  defaultDate,
  isMobile,
  isTablet,
  showPicker,
  setWeekDate,
  calendarKey,
}) => {
  const selectedDate = moment(value.start, dateFormat, true).isValid() ? moment(value.start, dateFormat) : defaultDate;
  const [activeDate, setActiveDate] = useState(selectedDate);
  const innerRef = useScrollBottomOfBox(30);
  useEffect(() => {
    setActiveDate(selectedDate);
  }, [selectedDate.format(dateFormat)]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDayClick = dayDate => {
    setWeekDate(moment(dayDate, dateFormat).startOf('isoWeek'), calendarKey);
    onChange(getWeek(dayDate.format(dateFormat)));
    if (!(isMobile || isTablet)) {
      close();
    }
  };
  const getWeek = date => {
    const startWeek = moment(date, dateFormat).startOf('isoWeek').format(dateFormat);
    const endWeek = moment(date, dateFormat).endOf('isoWeek').format(dateFormat);
    return { start: startWeek, end: endWeek };
  };

  const daysInMonth = activeDate.daysInMonth();
  const startOfMonth = moment(activeDate).startOf('month').isoWeekday(); // 1 - monday, 2 - tuesday, ..., 7 - sunday
  const endOfMonth = moment(activeDate).endOf('month').isoWeekday();

  const renderDays = () =>
    times(daysInMonth, i => {
      const dayDate = moment(activeDate).date(i + 1);
      const dayIsSelected =
        moment(value.start, dateFormat).isSameOrBefore(dayDate, 'day') &&
        moment(value.end, dateFormat).isSameOrAfter(dayDate, 'day');
      const isFirstDay = moment(value.end, dateFormat).isSame(dayDate, 'day');
      const isLastDay = moment(value.start, dateFormat).isSame(dayDate, 'day');
      const isBeforeOrAfterCurrentMonth = (minDate && dayDate < minDate) || (maxDate && dayDate > maxDate);
      return (
        <Day
          key={i}
          date={dayDate}
          disabled={isBeforeOrAfterCurrentMonth}
          selected={dayIsSelected}
          end={isLastDay}
          start={isFirstDay}
          onClick={() => onDayClick(dayDate)}
        />
      );
    });

  const arr = times(startOfMonth - 1, () => null);
  const renderPastMonthDays = () =>
    times(startOfMonth - 1, i => {
      const dayDate = moment(activeDate).date(-arr.length + 1 + i);
      const dayIsSelected =
        moment(value.start, dateFormat).isSameOrBefore(dayDate, 'day') &&
        moment(value.end, dateFormat).isSameOrAfter(dayDate, 'day');
      const isFirstDay = moment(value.end, dateFormat).isSame(dayDate, 'day');
      const isLastDay = moment(value.start, dateFormat).isSame(dayDate, 'day');
      return (
        <Day
          key={i}
          date={dayDate}
          selected={dayIsSelected}
          end={isLastDay}
          start={isFirstDay}
          onClick={() => onDayClick(dayDate)}
          past
        />
      );
    });

  const renderFutureMonthDays = () =>
    times(7 - endOfMonth, i => {
      const dayDate = moment(activeDate)
        .endOf('month')
        .add(1 + i, 'd');
      const dayIsSelected =
        moment(value.start, dateFormat).isSameOrBefore(dayDate, 'day') &&
        moment(value.end, dateFormat).isSameOrAfter(dayDate, 'day');
      const isFirstDay = moment(value.end, dateFormat).isSame(dayDate, 'day');
      const isLastDay = moment(value.start, dateFormat).isSame(dayDate, 'day');
      return (
        <Day
          key={i}
          date={dayDate}
          selected={dayIsSelected}
          end={isLastDay}
          start={isFirstDay}
          onClick={() => onDayClick(dayDate)}
          past
        />
      );
    });

  const rowsNumber = getRowsInMonth({ daysInMonth, dayOfWeekInFirstMonthDay: startOfMonth });
  return (
    <div ref={innerRef}>
      {isMobile || isTablet ? (
        <ModalSimple close={close} open={showPicker} small basic>
          <ModalSimple.Body>
            <BoxShadow>
              <DateSwitcher date={activeDate} onChange={setActiveDate} />
              <div className="date-picker" style={{ gridTemplateRows: `50px repeat(${rowsNumber}, 40px)` }}>
                {times(7, i => (
                  <Center key={i} className="uppercase text-bold-teal h-full">
                    {moment()
                      .isoWeekday(i + 1)
                      .format('dd')}
                  </Center>
                ))}
                {renderPastMonthDays()}
                {renderDays()}
                {renderFutureMonthDays()}
              </div>
            </BoxShadow>
          </ModalSimple.Body>
          <ModalSimple.Action close={close} onSubmit={close} />
        </ModalSimple>
      ) : (
        <BoxShadow>
          <DateSwitcher date={activeDate} onChange={setActiveDate} />
          <div className="date-picker" style={{ gridTemplateRows: `50px repeat(${rowsNumber}, 40px)` }}>
            {times(7, i => (
              <Center key={i} className="uppercase text-bold-teal h-full">
                {moment()
                  .isoWeekday(i + 1)
                  .format('dd')}
              </Center>
            ))}
            {renderPastMonthDays()}
            {renderDays()}
            {renderFutureMonthDays()}
          </div>
        </BoxShadow>
      )}
    </div>
  );
};

WeekPicker.defaultProps = {
  minDate: false,
  maxDate: false,
  defaultDate: moment(),
  showPicker: false,
  setWeekDate: () => {},
  calendarKey: '',
};

WeekPicker.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  value: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showPicker: PropTypes.bool,
  setWeekDate: PropTypes.func,
  calendarKey: PropTypes.string,
};

const Day = ({ selected, date, onClick, disabled, start, end, past }) => {
  if (!date) return <div />;
  const isCurrentDay = date && date.isSame(moment(), 'day');
  const className = cls('day h-full', {
    'text-highlight dot': isCurrentDay,
    'bg-teal-dark text-white font-bold': selected,
    'bg-teal-dark text-white font-bold rounded-l-full': end,
    'bg-teal-dark text-white font-bold rounded-r-full': start,
    'text-fade': past,
    disabled,
  });

  return (
    <Center className={className}>
      <ButtonSimple onClick={!disabled ? onClick : () => {}} fluid>
        {date.format('D')}
      </ButtonSimple>
    </Center>
  );
};

Day.defaultProps = {
  selected: false,
  start: false,
  end: false,
  date: null,
  onClick: () => {},
  disabled: false,
  past: false,
};

const DateSwitcher = ({ date, onChange }) => {
  const [mode, setMode] = useState('month');
  const [monthSelectOpen, setMonthSelectOpen] = useState(false);
  const onMonthClick = monthIndex => {
    setMonthSelectOpen(false);
    setMode('month');
    onChange(moment(date).month(monthIndex));
  };
  const prevMonth = () => onChange(moment(date).subtract(1, mode));
  const nextMonth = () => onChange(moment(date).add(1, mode));
  const changeMode = () => {
    setMode(mode === 'month' ? 'year' : 'month');
    setMonthSelectOpen(prevMonthSelectOpen => !prevMonthSelectOpen);
  };

  return (
    <div className="relative">
      <Center className="bg-teal rounded text-md-white py-1 px-2">
        <FlexRow cols="none/1/none" alignCenter stretched separated={false} noShrink>
          <ButtonSimple onClick={prevMonth}>
            <Icon.ArrowLeft small />
          </ButtonSimple>
          <ButtonSimple onClick={changeMode}>
            {mode === 'month' ? date.format('MMMM, YYYY') : date.format('YYYY')}
          </ButtonSimple>
          <ButtonSimple onClick={nextMonth}>
            <Icon.ArrowRight small />
          </ButtonSimple>
        </FlexRow>
      </Center>
      {monthSelectOpen && (
        <div className="month-picker">
          {times(12, i => {
            return (
              <ButtonSimple key={i} onClick={() => onMonthClick(i)}>
                {moment(date).month(i).format('MMM')}
              </ButtonSimple>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ app: { isMobile, isTablet } }, { location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  return {
    calendarKey,
    isMobile,
    isTablet,
  };
};

export default withRouter(connect(mapStateToProps, { setWeekDate: setWeek })(WeekPicker));
