import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';

const GoogleMap = ({ onChangeField, ...props }) => {
  const { name, formName } = props;

  const onChange = async (value) => {
    onChangeField({ formName, fieldName: name, fieldState: value });
  };

  return (
    <FormField {...props}>
      <Input.GoogleMap
        name={name}
        value={props.value}
        onChange={onChange}
      />
    </FormField>
  );
};

GoogleMap.defaultProps = {
  value: '',
};

GoogleMap.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(GoogleMap);
