/**
 * @module PageTitle
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useIsFetching } from 'react-query';
import { useHistory } from 'react-router';

import Link from '@old/components/common/Link';
import RowAligner from '@old/components/common/RowAligner';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';

/**
 * component that shows page title
 * @param  {String} props.title text displayed as title
 * @param  {String|Boolean} [props.back] specifies wether there should be left arrow next to the title that allows you to go back in browser history or to the page passed to it(in string format)
 * @param  {Boolean} [props.loading] specifies wether there should be spinner next to the title
 */
const PageTitle = ({ title, back, loading }) => {
  const history = useHistory();
  const isFetching = useIsFetching();
  return (
    <RowAligner className="page-title">
      {back && (typeof back === 'string' || typeof back === 'object') && (
        <Link className="flex" to={back}><Icon.ArrowLeft /></Link>
      )}
      {back && typeof back === 'boolean' && (
        <ButtonSimple onClick={() => history.goBack()} basic>
          <Icon.ArrowLeft />
        </ButtonSimple>
      )}
      {back && typeof back === 'function' && (
        <ButtonSimple onClick={back} basic>
          <Icon.ArrowLeft />
        </ButtonSimple>
      )}
      <div className="truncate">{title}</div>
      {(loading || !!isFetching) && <Icon.Spinner className="fill-teal rotate small" />}
    </RowAligner>
  );
};

PageTitle.defaultProps = {
  back: false,
  loading: false,
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  back: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  loading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.number,
    ),
  ]),
};

export default PageTitle;
