import PropTypes from 'prop-types';
import { truncate } from 'lodash';

import FlexRow from '@old/components/common/FlexRow';
import Box from '@old/components/common/Box';
import FlexColumn from '@old/components/common/FlexColumn';
import RowAligner from '@old/components/common/RowAligner';
import Avatar from '@old/components/common/Avatar';
import TagCircle from '@old/components/common/TagCircle';
import EventPairs from '@old/components/view/pairs/Pairs';
import Posts from '@old/components/view/list/Posts';
import Link from '@old/components/common/Link';
import Icon from '@old/components/icon';
import TextValue from '@old/components/common/TextValue';
import FlexSection from '@old/components/common/FlexSection';
import Tooltip from '@old/components/common/Tooltip';
import EventInvitationStatistics from '@old/components/custom/EventInvitationStatistics';
import EventRiderAction from '@old/components/custom/EventRiderAction';
import EventDateSquare from '@old/components/custom/EventDateSquare';
import t from 'resources/translations';
import { parseToCurrencyString } from 'old/utils';

const EventInfo = ({ event, loggedMember, buttons, isEventDeleted, cb }) => {
  const statusBarProps = event.getStatusBarProps();
  const slugInfos = Object.values(event.getSlugInfo());
  const limitInstructors = event.getInstructors().length > 2;
  const [eventRiderButtons, eventPairButtons] = buttons;

  return (
    <FlexSection>
      <FlexRow cols="10/4" shrinkTablet stretched>
        <Box>
          {!isEventDeleted && (
            <Link to={{ pathname: '/events/add', state: { docId: event.id } }}>
              <div className="absolute right-0 top-0 m-4 md:m-6">
                <Tooltip content={t('buttons.copyEvent')}>
                  <Icon.Copy className="fill-teal hover:fill-teal-dark" />
                </Tooltip>
              </div>
            </Link>
          )}
          <FlexRow cols="none/1/1" alignCenter>
            <EventDateSquare
              startDate={event.startDate}
              endDate={event.endDate}
              statusBarProps={statusBarProps}
              eventStatus={event.status}
            />
            <FlexColumn separated="small">
              {event.getInstructors(true).map(instructor => (
                <RowAligner key={instructor.id}>
                  <Avatar src={instructor.getAvatar()} small={limitInstructors} />
                  <Link to={instructor.getProfileUrl()}>{instructor.getFullName()}</Link>
                </RowAligner>
              ))}
            </FlexColumn>
            <FlexColumn separated="small">
              <RowAligner>
                <Icon.EventCost small className="fill-teal-dark" />
                <div className="flex">
                  <div className="font-bold">{t('eventListItem.pricePerShare')}:</div>
                  <div className="ml-1">{parseToCurrencyString(event.type.defaultEventCost)}</div>
                </div>
              </RowAligner>
              {event.special && (
                <RowAligner>
                  <Icon.Star small className="fill-teal-dark" />
                  <div>{t('labels.special')}</div>
                </RowAligner>
              )}
              {slugInfos.map(slugInfo => (
                <RowAligner key={slugInfo.name}>
                  <TagCircle color={slugInfo.color} small />
                  <div>{truncate(slugInfo.name, { length: 25 })}</div>
                </RowAligner>
              ))}
            </FlexColumn>
          </FlexRow>
          {event.edited && (
            <div className="absolute text-teal bottom-2 right-4">
              <Tooltip content={t('eventListItem.editedEvent')}>
                <Icon.Warning />
              </Tooltip>
            </div>
          )}
        </Box>
        {loggedMember.isClient() ? (
          <EventRiderAction event={event} buttons={eventRiderButtons} />
        ) : (
          <EventInvitationStatistics event={event} />
        )}
      </FlexRow>
      {event.description && (
        <Box>
          <TextValue text={t('labels.additionalNotes')} value={event.description} />
        </Box>
      )}
      <EventPairs event={event} buttons={eventPairButtons} cb={cb} />
      {!isEventDeleted && (
        <FlexRow cols="10/4">
          <Posts eventId={event.id} />
          <div />
        </FlexRow>
      )}
    </FlexSection>
  );
};

EventInfo.defaultProps = {
  buttons: [[], []],
  isEventDeleted: false,
};

EventInfo.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        iconName: PropTypes.string,
        icon: PropTypes.node,
      })
    )
  ),
  isEventDeleted: PropTypes.bool,
};

export default EventInfo;
