import React, { useState, useEffect } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@old/components/icon';
import RowAligner from '@old/components/common/RowAligner';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import ToggleBlock from '@old/components/common/ToggleBlock';

const FieldGroupIcons = ({ children, formName, label }) => {
  const [fields, setFields] = useState([]);
  const [fieldsOrder, setFieldsOrder] = useState({});

  useEffect(() => {
    const tmpFields = [];
    const tmpFieldsOrder = {};

    React.Children.map(children, (child, index) => {
      if (child) {
        const { props: { name, iconName } } = child;
        if (name) {
          tmpFields.push({
            name,
            node: React.cloneElement(child, { formName }),
            iconName: iconName || 'Add',
            active: false,
          });
          tmpFieldsOrder[name] = index + 1;
        }
      }
    });

    setFieldsOrder(tmpFieldsOrder);
    setFields(tmpFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formName]);

  const toggleField = (name) => {
    if (!isOpen(name)) {
      const lastPosition = Math.max(...Object.keys(fieldsOrder).map(fieldName => fieldsOrder[fieldName]));
      setFieldsOrder(prevFieldsOrder => ({ ...prevFieldsOrder, [name]: lastPosition + 1 }));
    }
    setFields(prevFields => prevFields.map((field) => {
      if (field.name === name) {
        return { ...field, active: !field.active, order: prevFields.length };
      }
      return { ...field };
    }));
  };

  const isOpen = name => !!fields.find(field => field.name === name && field.active);

  return (
    <>
      <div className="flex flex-col">
        {fields.map(({ name, node }) => {
          const isFieldOpen = isOpen(name);
          return (
            <ToggleBlock key={name} style={{ order: fieldsOrder[name] }} isOpen={isFieldOpen} isAutoScrollable>
              <div className="toggle-field-container flex-1">
                {node}
              </div>
            </ToggleBlock>
          );
        })}
      </div>
      <div>
        <div className="text-input-label text-grey-dark pl-2 mb-2">{label}</div>
        <div className="w-full overflow-hidden relative" style={{ height: 50 }}>
          <RowAligner className="absolute overflow-x-auto pb-4">
            {fields.map(({ iconName, name }) => {
              const IconComponent = Icon[iconName];
              return (
                <ButtonSimple name={name} key={name} onClick={() => toggleField(name)}>
                  <IconComponent className={cls('fill-white', { active: isOpen(name) })} circle />
                </ButtonSimple>
              );
            })}
          </RowAligner>
        </div>
      </div>
    </>
  );
};

FieldGroupIcons.defaultProps = {
  formName: '',
};

FieldGroupIcons.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  formName: PropTypes.string,
};

FieldGroupIcons.displayName = 'FieldGroupIcons';

export default FieldGroupIcons;
