import _ from 'lodash';
import { IBillFetchParams, TBillFilters } from 'resources/types/billsTypes';
import { IUserReq, TUsersFormState } from 'resources/types/membersTypes';
import { ITicketOffersCreateReq, TTicketOffersFormState } from 'resources/types/TicketOfferTypes';
import { ITicketFetchParams, TTicketFilters } from 'resources/types/TicketTypes';

/**
 * Parses string amount to float value
 * @param {string} amount
 * @return {float}
 */
export const parseAmountStringToFloat = (amount: string) => {
  const parsedAmount = parseFloat(amount.replace(',', '.'));
  return parsedAmount;
};

/**
 * Deletes empty values from object
 * @param {object} data
 * @return {object}
 */
export const pickOnlyExisting = (data: object) => {
  return _.pickBy(data, value => !_.isEmpty(value) || typeof value !== 'boolean');
};

/**
 * Map bill filters to fetch params
 * @param {TBillFilters} billFilters - bill filters
 * @return {IBillFetchParams} mapped bill filters
 */
export const mapBillFiltersToParams = (billFilters: TBillFilters): IBillFetchParams =>
  _.pickBy(
    {
      start_after: billFilters.start_after,
      end_before: billFilters.end_before,
      status: (billFilters.statuses || []).map(({ value }) => value),
      payment_type: (billFilters.payment_type || []).map(({ value }) => value),
      instructor_id: (billFilters.instructors || []).map(({ value }) => value),
      user_id: (billFilters.participants || []).map(({ value }) => value),
      place_id: (billFilters.places || []).map(({ value }) => value),
      horse_id: (billFilters.horses || []).map(({ value }) => value),
      event_name: billFilters.event_name,
      billable_type: (billFilters.billable_type || []).map(({ value }) => value),
    },
    value => !_.isEmpty(value)
  );

/**
 * Map ticket filters to fetch params
 * @param {TTicketFilters} ticketsFilters - ticket filters
 * @return {ITicketFetchParams} mapped ticket filters
 */
export const mapTicketsFiltersToParams = (ticketsFilters: TTicketFilters): ITicketFetchParams =>
  _.pickBy(
    {
      with_user: (ticketsFilters.with_user || []).map(({ value }) => value),
      with_event_type: (ticketsFilters.with_event_type || []).map(({ value }) => value),
      bought_by: (ticketsFilters.with_event_type || []).map(({ value }) => value),
      with_days: ticketsFilters.with_days,
      with_status: ticketsFilters.with_status,
    },
    value => !_.isEmpty(value)
  );

/**
 * Map ticket offets to fetch params
 * @param {TTicketOffersFormState} ticketOffers - ticket
 * @return {ITicketOffersCreateReq} mapped ticket offers
 */
export const mapOffersFormToParams = (ticketOffers: TTicketOffersFormState): ITicketOffersCreateReq => {
  const { name, description, price, count, validity, visibility, activable, eventTypes } = ticketOffers;
  return {
    name,
    description,
    price: typeof price === 'number' ? price : parseAmountStringToFloat(price || ''),
    count: typeof count === 'number' ? count : parseInt(count || '', 10),
    validity: validity || 1,
    visibility: visibility === 'visible',
    activable: activable === 'true',
    event_type_ids: eventTypes.map(type => {
      if (typeof type.value === 'string') {
        return parseInt(type.value, 10);
      }
      return type.value;
    }),
  };
};

export const mapUserFormToParams = (user: TUsersFormState, defaultAvatar: string | null): IUserReq => {
  const { firstname, lastname, email, phone, birthday, privateProfile, password, avatar } = user;
  return {
    firstname,
    lastname,
    email,
    phone,
    birthday,
    private_data_hidden: privateProfile,
    password,
    ...(avatar || defaultAvatar
      ? {
          avatar_attributes: { file: defaultAvatar ?? avatar },
        }
      : {}),
  };
};
