import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
} from 'recharts';
import flatten from 'lodash/flatten';

import RoundedBar from '@old/components/view/charts/RoundedBar';

const PlaceStatisticsBarChart = ({ activeDay, data, onBarClick }) => {
  const bars = flatten(data.map((item) => {
    return Object.keys(item).map((key) => {
      if (key !== 'name' && key !== 'label' && key !== 'exceeded' && key !== 'count') {
        return ({
          day: item.label || null,
          dataKey: key,
        });
      }

      return null;
    });
  })).filter(Boolean);

  return (
    <ResponsiveContainer height={350}>
      <BarChart width={730} data={data} cursor="pointer" onClick={onBarClick}>
        <XAxis dataKey="name" tickLine={false} />
        <Tooltip
          isAnimationActive={false}
          content={({ payload }) => {
            if (payload) {
              return (
                <div className="like-popup" style={{ position: 'static' }}>
                  {[...payload].reverse().map((item, index) => {
                    const [, time] = item.name.split('/');
                    return <div key={index}>{time}</div>;
                  })}
                </div>
              );
            }
            return null;
          }}
        />
        {bars.map(({ dataKey, day }, index) => {
          return (
            <Bar
              key={`${dataKey}${index}`}
              dataKey={dataKey}
              stackId={1}
              fill={day === activeDay ? '#005E65' : '#00A0AD'}
              shape={<RoundedBar />}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default React.memo(PlaceStatisticsBarChart);

PlaceStatisticsBarChart.defaultProps = {
  activeDay: '',
};

PlaceStatisticsBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  onBarClick: PropTypes.func.isRequired,
  activeDay: PropTypes.string,
};
