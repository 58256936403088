import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import t from 'resources/translations';
import { isEmail } from 'old/utils';
import Input from '@old/components/input';

const EmailField = ({ onChangeField, placeholder, validation, label, ...props }) => {
  const { name, formName, value, required } = props;

  const onChange = (email) => {
    onChangeField({ formName, fieldName: name, fieldState: email });
  };

  const validationFuncs = [
    ...validation,
    {
      condition: email => isEmail({ value: email }),
      errorHint: t('error.badEmailFormat'),
    },
  ];

  return (
    <FormField validation={validationFuncs} {...props}>
      <Input.Email
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        label={label}
        required={required}
      />
    </FormField>
  );
};

EmailField.defaultProps = {
  value: '',
  placeholder: '',
  validation: [],
  label: '',
  required: false,
};

EmailField.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.func.isRequired,
      errorHint: PropTypes.string,
    }),
  ),
  required: PropTypes.bool,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(EmailField);
