import moment from 'moment';
import Event from 'models/Event';
import PendingApproval from 'old/components/error/PendingApproval';
import t from 'resources/translations';
import Message from 'old/components/common/Message';
import ActiveEvents from 'views/Dashboard/ActiveEvents';
import MyEvents from 'views/Dashboard/MyEvents';
import MyProposals from 'views/Dashboard/MyProposals';
import { useFetchEvents, useFetchEventsInfinity, useFetchProposalsInfinity } from 'actions/events';
import { Box, Content, Icons, Stack, Text } from 'components/elements';
import { useIsFetching } from 'react-query';
import { useNewLayoutColor } from 'utils';
import _, { unionBy } from 'lodash';
import { PageTitle } from 'components/combinations';
import { useEffect } from 'react';
import Member from 'models/Member';
import { PaymentEventModal, PaymentMassiveModal, PaymentOnlineModal, PaymentReturnModal } from 'views/modals';
import { useFarm, useLoggedMember } from 'utils/storeUtils';

const Dashboard = () => {
  useNewLayoutColor();
  const loggedMember = useLoggedMember();
  const farm = useFarm();
  const isFetching = useIsFetching();
  const [todayEventsState, todayEvents] = useFetchEvents(
    'DASBOARD_EVENTS_TODAY',
    {
      per_page: 9999,
      'in_interval[start]': moment().startOf('day').toISOString(),
      'in_interval[end]': moment().endOf('day').toISOString(),
      sorted_by: 'start_at_asc',
      ...(loggedMember.isOwner() ? {} : { for: [loggedMember.id] }),
    },
    {
      enabled: loggedMember.isActive(),
    }
  );

  const [nextEventsState, nextEvents] = useFetchEventsInfinity(
    'DASHBOARD_EVENTS',
    {
      per_page: 10,
      'in_interval[start]': moment().endOf('day').toISOString(),
      'in_interval[end]': moment().add(100, 'days').startOf('day').toISOString(),
      sorted_by: 'start_at_asc',
      ...(loggedMember.isOwner() ? {} : { for: [loggedMember.id] }),
    },
    { enabled: false }
  );

  const [proposalsState, proposals] = useFetchProposalsInfinity(
    'PROPOSALS',
    {
      per_page: 10,
      sorted_by: 'start_at_asc',
      ...(loggedMember.isOwner() ? {} : { for: [loggedMember.id] }),
    },
    {
      enabled: loggedMember.isActive(),
    }
  );

  useEffect(() => {
    return () => nextEventsState.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventsUnion = unionBy(todayEvents, nextEvents, event => event.id);
  const sortEventsByStartDate = (a: Event, b: Event) => a.startDate.valueOf() - b.startDate.valueOf();
  const events = eventsUnion.sort(sortEventsByStartDate);

  const filterActiveEventsForMember = (member: Member | null, event: Event) => {
    const ongoingEvent = event.getStatus() === 'ongoing';
    if (member?.isClient()) {
      const isParticipate = event.getParticipationsByStatus().some(p => p.member.id === member.id);
      return ongoingEvent && isParticipate;
    }
    return ongoingEvent;
  };
  const activeEvents = events.filter(event => filterActiveEventsForMember(loggedMember, event));

  // fixme: find me new home
  if (loggedMember.isPending()) {
    return <PendingApproval currentFarm={farm} />;
  }

  // fixme: find me new home
  if (loggedMember.isRejected() || loggedMember.isBlocked()) {
    return (
      <Box>
        <PageTitle title={t('menu.dashboard')} />
        <Message deleted>
          <Stack alignItems="center" isHorizontal>
            <Icons.WarningCircle
              size="sm"
              fill="teal-dark"
              border={{ borderWidth: 1, borderColor: 'teal-dark' }}
              bgColor="transparent"
            />
            <Text>{loggedMember.isRejected() ? t('message.rejectedMember') : t('message.blockedMember')}</Text>
          </Stack>
        </Message>
      </Box>
    );
  }

  return (
    <Content>
      <PageTitle title={t('menu.dashboard')} loading={!!isFetching} mb="xl" />
      <Stack spacing="2xl">
        {!_.isEmpty(activeEvents) && <ActiveEvents activeEvents={activeEvents} />}
        {!_.isEmpty(proposals) && (
          <MyProposals
            proposals={proposals}
            onMoreProposals={proposalsState.fetchNextPage}
            statusesProposals={{
              isFetching: proposalsState.isFetchingNextPage,
              isHasNextPage: !!proposalsState.hasNextPage,
            }}
          />
        )}
        <MyEvents
          events={events}
          onMoreEvents={() => {
            if (nextEventsState.hasNextPage) {
              nextEventsState.fetchNextPage();
            }
            if (nextEventsState.isIdle) {
              nextEventsState.refetch();
            }
          }}
          statuses={{
            isTodayFetched: todayEventsState.isFetched,
            isLoading: nextEventsState.isLoading,
            isNoMoreEvents: !nextEventsState.hasNextPage && !nextEventsState.isIdle,
            isLoadingMore: nextEventsState.isFetching,
          }}
        />
      </Stack>
      <PaymentEventModal />
      <PaymentReturnModal />
      <PaymentOnlineModal />
      <PaymentMassiveModal />
    </Content>
  );
};

export default Dashboard;
