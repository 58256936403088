import { Card, CardActions, CardContent, ContextMenu, ContextMenuItem } from 'components/combinations';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { Box, Button, Flex, Icons, Link, Span, Stack, Text, TextTruncate } from 'components/elements';
import { DATE_FORMAT, TIME_FORMAT } from 'config/constans';
import _ from 'lodash';
import Bill from 'models/Bill';
import moment from 'moment';
import t from 'resources/translations';
import { parseToCurrencyString } from 'old/utils';
import { usePermissions } from 'Permissions';
import { memo, useCallback } from 'react';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { TStackProps } from 'resources/types/elementsTypes';
import { BillService, TicketService } from 'services';
import { doubleTernary } from 'utils';
import { useModal } from 'old/hooks';

const BillClientStatementItem = ({ bill, ...stackProps }: { bill: Bill } & TStackProps) => {
  const queryClient = useQueryClient();
  const isTicket = bill.billableType === 'Ticket';

  const canIAddPayment = usePermissions('BILL_ADD_PAYMENT')(bill);
  const canICancelPayment = usePermissions('BILL_CANCEL_PAYMENT')(bill);
  const canIRemoveTicket = usePermissions('TICKET_REMOVE')(bill.status === 'unpaid') && isTicket;

  const modalPayment = useModal('PAYMENT_ONLINE');
  const modalHistoryPayments = useModal('BILL_HISTORY_PAYMENTS');
  const modalConfirm = useModal('CONFIRM');

  const isPaid = bill.getDue() === bill.getPaidAmount();
  const getPaymentStatus = useCallback(() => {
    const isUnpaid = bill.getDue() > bill.getPaidAmount();
    const paymentStatus: string = doubleTernary(isPaid, isUnpaid, [
      t('bills.statementList.paid'),
      t('bills.statementList.unpaid'),
      t('bills.statementList.overpaid'),
    ]);
    return paymentStatus;
  }, [bill, isPaid]);

  const getPaymentLabel = useCallback(() => {
    const currentTransaction = bill.getCurrentTransaction();
    const paymentIsPending = currentTransaction?.status === 'pending';
    const paymentIsFailure = currentTransaction?.status === 'failure';

    const paymentLabel: string = doubleTernary(paymentIsPending, paymentIsFailure, [
      t('buttons.completePayment'),
      t('buttons.retryPayment'),
      t('buttons.pay'),
    ]);
    return paymentLabel;
  }, [bill]);

  const actionCancelPayment = useMutation(
    async () => {
      const transferPaymentsList = bill.payments.filter(payment => payment.status === 'unprocessed');
      const lastTransferPayment = _.orderBy(transferPaymentsList, ['created_at'], ['desc'])[0];
      return await BillService.cancelPayment(bill.id, lastTransferPayment.transactions[0].id);
    },
    { onSuccess: () => queryClient.invalidateQueries('BILLS', { refetchActive: true }) }
  );

  const actionRemoveTicket = useMutation(async () => TicketService.delete(bill.billableId), {
    onSuccess: () => queryClient.invalidateQueries('BILLS', { refetchActive: true }),
  });

  const onOpenPaymenModal = () => {
    return modalPayment.onOpen({ bill });
  };

  const onOpenHistoryPaymentsModal = () => {
    return modalHistoryPayments.onOpen({ bill });
  };

  const onOpenCancelPaymenModal = () => {
    modalConfirm.onOpen({
      title: t('bills.cancelPayment'),
      content: (
        <ModalDescription>
          <Text>
            {t('bills.areYouSureCancel')}
            <TextTruncate ellipsis="...?" lines={2} fontWeight="bold">
              {bill.getName()}?
            </TextTruncate>
          </Text>
        </ModalDescription>
      ),
      action: {
        mutation: actionCancelPayment,
        label: t('buttons.yesCancelPayment'),
        cancelLabel: t('buttons.noGetMyOutOfHere'),
        loadingLabel: t('message.removed'),
      },
    });
  };

  const onOpenDeleteModal = () => {
    modalConfirm.onOpen({
      title: t('tickets.deleteTicket'),
      content: (
        <ModalDescription spacing="xl">
          <Stack spacing="sm">
            <Text>{t('tickets.deleteUnpaidTicket')}:</Text>
            <Text>
              <Span fontWeight="medium">{bill.getName()}</Span>?
            </Text>
          </Stack>
          <Text fontWeight="medium">{t('tickets.rememberTicketDeleted')}</Text>
        </ModalDescription>
      ),
      action: {
        mutation: actionRemoveTicket,
        label: t('buttons.yesIWantDeleteTicket'),
        cancelLabel: t('buttons.noGetMyOutOfHere'),
        loadingLabel: t('message.removed'),
      },
    });
  };

  const url = isTicket ? `/tickets/${bill.ticket.id}` : `/events/${bill.event.id}`;

  const getActions = useCallback(() => {
    const paymentsActions: { label: string; onClick: Function }[] = [];
    const ticketsActions: { label: string; onClick: Function }[] = [];
    if (canIAddPayment) {
      paymentsActions.push({
        label: getPaymentLabel(),
        onClick: onOpenPaymenModal,
      });
    }
    if (canICancelPayment) {
      paymentsActions.push({ label: t('buttons.cancelPayment'), onClick: onOpenCancelPaymenModal });
    }
    if (bill.payments.length > 0) {
      paymentsActions.push({ label: t('bills.paymentsHistory'), onClick: onOpenHistoryPaymentsModal });
    }
    if (canIRemoveTicket) {
      ticketsActions.push({ label: t('tickets.deleteTicket'), onClick: onOpenDeleteModal });
    }
    return { payments: paymentsActions, tickets: ticketsActions };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill]);

  const actions = getActions();
  const numberOfActions = actions.payments.length + actions.tickets.length;

  return (
    <Card {...stackProps}>
      <Flex justifyContent="space-between" py="xl" px="md">
        <Stack alignItems="center" isHorizontal>
          {isTicket ? (
            <Icons.TicketCircle bgColor="body-color" fill="teal-dark" />
          ) : (
            <Icons.HorseRiderCircle bgColor="body-color" fill="teal-dark" />
          )}
          <Flex flexDirection="column">
            <Text>{`${moment(bill.createdAt).format(DATE_FORMAT)},`}</Text>
            <Text>{moment(bill.createdAt).format(TIME_FORMAT)}</Text>
          </Flex>
        </Stack>
        <ContextMenu>
          {[...actions.payments, ...actions.tickets].map(action => (
            <ContextMenuItem key={action.label} onClick={() => action.onClick()}>
              {action.label}
            </ContextMenuItem>
          ))}
        </ContextMenu>
      </Flex>
      <CardContent bgColor="body-color-30" borderColor="new-grey-light-30" borderTopWidth={1} borderBottomWidth={1}>
        <Box>
          <Link to={url} wordBreak="break-all" py="sm">
            {isTicket ? bill.ticket.name : bill.event.name}
          </Link>
        </Box>
      </CardContent>
      <CardContent>
        <Text whiteSpace="nowrap">{`${t('bills.due')}: ${parseToCurrencyString(bill.dueAmount)}`}</Text>
        <Flex justifyContent="space-between">
          <Text whiteSpace="nowrap">{`${t('bills.deposit')}: ${parseToCurrencyString(bill.paidAmount)}`}</Text>
          <Text color={isPaid ? 'grey-dark' : 'orange'}>{getPaymentStatus().toLowerCase()}</Text>
        </Flex>
        {!_.isEmpty(bill.instructors) && (
          <Stack spacing="sm">
            <Text fontWeight="medium">{`${t('labels.instructors')}: `}</Text>
            <Text>
              {bill.instructors.map((instructor, i) => (
                <>
                  {i !== 0 ? ', ' : ''}
                  <Link to={`/instructors/${instructor.id}`} forceRender>
                    {instructor.name}
                  </Link>
                </>
              ))}
            </Text>
          </Stack>
        )}
      </CardContent>
      {numberOfActions === 1 && (
        <CardActions>
          {[...actions.payments, ...actions.tickets].map(action => (
            <Button key={action.label} variant="link" onClick={() => action.onClick()} py="md" width="100%">
              <Text>{action.label}</Text>
            </Button>
          ))}
        </CardActions>
      )}
    </Card>
  );
};

export default memo(BillClientStatementItem);
