import _ from 'lodash';
import { IImage, IImageUrl, IPicturesAttributes, TDays, TPrivilege } from 'resources/types/commonTypes';
import { IFarmUpdateReq, IFarmRes, IOpeningHours, TFarmStatus, TPaymentsPlan } from 'resources/types/farmsTypes';
import { parseHTMLToMarkdown, parseMarkdownToHTML } from '../old/utils/Common';

const placeholders = {
  farm: '/img/farm-placeholder.png',
};

// fix me - i need real home
const getObjectWithMinValueByKey = (array: IImage[], key: keyof IImage): IImage => {
  if (key === 'description') {
    throw Error('The description key cannot be used');
  }

  return array.reduce((minIdItem, nextItem: IImage) => {
    return nextItem[key] < minIdItem[key] ? nextItem : minIdItem;
  }, array[0]);
};

class Farm {
  id: number;
  name: string;
  slug: string;
  description: string;
  www: string;
  latitude: number;
  longitude: number;
  phone: string;
  email: string;
  postcode: string;
  address: string;
  city: string;
  rating: number;
  pictures: IImage[];
  pictureId: number;
  staff: string;
  status: TFarmStatus;
  openingHours: { [key in TDays]: IOpeningHours } | null;
  privileges: TPrivilege[];
  paymentPlan: TPaymentsPlan;

  constructor(data: IFarmRes) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.description = data.description;
    this.www = data.www;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.phone = data.phone;
    this.email = data.email;
    this.postcode = data.postcode;
    this.address = data.address;
    this.city = data.city;
    this.rating = data.rating;
    this.pictures = data.pictures;
    this.pictureId = data.primary_picture_id;
    this.staff = data.staff;
    this.status = data.status;
    this.openingHours = data.opening_hours
      ? {
          monday: {
            open: data.opening_hours[0][0],
            close: data.opening_hours[0][1],
          },
          tuesday: {
            open: data.opening_hours[1][0],
            close: data.opening_hours[1][1],
          },
          wednesday: {
            open: data.opening_hours[2][0],
            close: data.opening_hours[2][1],
          },
          thursday: {
            open: data.opening_hours[3][0],
            close: data.opening_hours[3][1],
          },
          friday: {
            open: data.opening_hours[4][0],
            close: data.opening_hours[4][1],
          },
          saturday: {
            open: data.opening_hours[5][0],
            close: data.opening_hours[5][1],
          },
          sunday: {
            open: data.opening_hours[6][0],
            close: data.opening_hours[6][1],
          },
        }
      : null;
    this.privileges = data?.meta?.privileges || [];
    this.paymentPlan = data.event_payment_plan;
  }

  static mapToSaveData = (data: any) => {
    const farmRequest: IFarmUpdateReq = {
      name: data.farmName,
      description: _.has(data, 'farmDescription') ? parseHTMLToMarkdown(data.farmDescription) : undefined,
      www: data.www,
      latitude: data.coordinates && data.coordinates[0] && parseFloat(data.coordinates[0]),
      longitude: data.coordinates && data.coordinates[1] && parseFloat(data.coordinates[1]),
      status: data.status,
      phone: data.phone,
      email: data.email,
      address: data.address,
      postcode: data.postcode,
      city: data.city,
      staff: _.has(data, 'staff') ? parseHTMLToMarkdown(data.staff) : undefined,
      open_0: data?.monday.active ? data.monday.hours[0] : null,
      close_0: data?.monday.active ? data.monday.hours[1] : null,
      open_1: data?.tuesday.active ? data.tuesday.hours[0] : null,
      close_1: data?.tuesday.active ? data.tuesday.hours[1] : null,
      open_2: data?.wednesday.active ? data.wednesday.hours[0] : null,
      close_2: data?.wednesday.active ? data.wednesday.hours[1] : null,
      open_3: data?.thursday.active ? data.thursday.hours[0] : null,
      close_3: data?.thursday.active ? data.thursday.hours[1] : null,
      open_4: data?.friday.active ? data.friday.hours[0] : null,
      close_4: data?.friday.active ? data.friday.hours[1] : null,
      open_5: data?.saturday.active ? data.saturday.hours[0] : null,
      close_5: data?.saturday.active ? data.saturday.hours[1] : null,
      open_6: data?.sunday.active ? data.sunday.hours[0] : null,
      close_6: data?.sunday.active ? data.sunday.hours[1] : null,
      primary_picture_id: data.primaryPictureId,
      pictures_attributes:
        data.pictures &&
        data.pictures.map((picture: IPicturesAttributes) => ({
          id: picture.id,
          description: picture.description,
          file: picture.file,
          _destroy: picture._destroy,
        })),
    };

    const farmRequestKeys = Object.keys(farmRequest) as Array<keyof IFarmUpdateReq>;
    farmRequestKeys.forEach((fieldName: keyof IFarmUpdateReq) => {
      if (farmRequest[fieldName] === undefined) delete farmRequest[fieldName];
    });

    return farmRequestKeys;
  };

  getName(limit: number = 0) {
    return limit ? _.truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getAvatar(size: keyof IImageUrl = 'thumb') {
    return this.getImage(size);
  }

  getImage(size: keyof IImageUrl): string {
    if (!this.pictures?.length) {
      return placeholders.farm;
    }

    let mainPicture: IImage | undefined;
    if (!this.pictureId) {
      mainPicture = getObjectWithMinValueByKey(this.pictures, 'id');
    } else {
      mainPicture = this.pictures.find(p => p.id === this.pictureId);
    }

    if (mainPicture?.url) {
      return mainPicture.url[size] || mainPicture.url?.medium;
    }

    return placeholders.farm;
  }

  getStaff() {
    // remove all newlines, comments etc. -> For react-quill library
    return parseMarkdownToHTML(this.staff).replace(/(\r\n|\n|\r|<!--(.*?)-->)/gm, '') || '';
  }

  getDescription() {
    // remove all newlines, comments etc. -> For react-quill library
    return parseMarkdownToHTML(this.description).replace(/(\r\n|\n|\r|<!--(.*?)-->)/gm, '') || '';
  }

  canI(permissionKey: TPrivilege) {
    return this.privileges.includes(permissionKey);
  }

  isAdvancedPaymentEnabled() {
    return this.paymentPlan === 'advanced';
  }
}

export default Farm;
