import { TBillFilters } from 'resources/types/billsTypes';

export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

export type TFilterType = 'BILLS' | 'TICKETS';
export type TFilters = TBillFilters;

export const setFilters = (filtersType: TFilterType, filters: TFilters) => ({
  type: SET_FILTERS,
  filtersType,
  filters,
});
export const resetFilters = (filtersType: TFilterType) => ({ type: RESET_FILTERS, filtersType });
