import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import cls from 'classnames';

import t from 'resources/translations';
import { getCalendarKey } from 'old/utils';
import config from '@old/config';

const WeekHeader = ({ firstElement, onClickHeader, weekView, hideWeekSelect, dayStart, weekStart }) => {
  const selectedDayIsInSelectedWeek = dayStart.isSame(weekStart, 'week');
  const selectedDayIndex = dayStart.isoWeekday();

  return (
    <React.Fragment>
      {!hideWeekSelect && <div className="bg-teal text-white centered">{firstElement}</div>}
      {config.weekDays.map((weekday, index) => {
        return (
          <div
            key={weekday}
            onClick={() => onClickHeader && onClickHeader(index)}
            className={cls('bg-teal text-white centered', {
              'cursor-pointer': weekView && onClickHeader,
              'bg-teal-dark': selectedDayIsInSelectedWeek && weekView && selectedDayIndex - 1 === index,
            })}
          >
            {t(`week.short.${weekday}`)}
          </div>
        );
      })}
    </React.Fragment>
  );
};

WeekHeader.defaultProps = {
  firstElement: '',
  onClickHeader: null,
  weekView: false,
  hideWeekSelect: false,
};

const mapStateToProps = ({ calendar }, { location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;

  return {
    dayStart: calendarState.dayStart,
    weekStart: calendarState.weekStart,
  };
};

export default withRouter(connect(mapStateToProps)(WeekHeader));
