import moment from 'moment';

import { datesIsSame, getWeekDayFromDate } from 'old/utils';
import t from 'resources/translations';
import config from '@old/config';
import { Span } from '@elements';

const RenderDate = ({ startDate, endDate, dateFormat, withWeekDay, className, sameDateHours }) => {
  const startDateWeekDay = withWeekDay ? t(`week.${getWeekDayFromDate(startDate)}`) : '';
  if (startDate && !endDate) {
    return (
      <Span className={className}>
        {`
          ${startDate.format(dateFormat)}
          ${startDateWeekDay.toLowerCase()}
        `}
      </Span>
    );
  }

  const endDateHours = endDate.format('HH:mm');
  const endDateWeekDay = withWeekDay ? `${t(`week.${getWeekDayFromDate(endDate)}`)}` : '';

  if (datesIsSame(startDate, endDate)) {
    if (sameDateHours) {
      return (
        <Span className={className}>
          {`
            ${startDate.format(dateFormat)}
            ${endDateWeekDay.toLowerCase()}
            - ${endDateHours}
          `}
        </Span>
      );
    }

    return <Span className={className}>{startDate.format(dateFormat)}</Span>;
  } else {
    return (
      <Span className={className}>
        {`
          ${startDate.format(dateFormat)}
          ${startDateWeekDay.toLowerCase()}
          -
          ${endDate.format(dateFormat)}
          ${endDateWeekDay.toLowerCase()}
        `}
      </Span>
    );
  }
};

RenderDate.defaultProps = {
  startDate: moment(),
  endDate: null,
  children: null,
  dateFormat: config.dateTimeFormatWithComma,
  onlyDate: false,
  withWeekDay: false,
  className: '',
  sameDateHours: false,
};

export default RenderDate;
