import { Icons, Button } from '../elements';
import { IButtonStyledProps } from 'resources/types/styledTypes';
import { TIcon } from 'resources/types/commonTypes';
type TIconButton = IButtonStyledProps & {
  icon: TIcon;
  onClick: Function;
};

const IconButton = ({ icon, onClick, ...buttonProps }: TIconButton) => {
  const IconComponent = Icons[icon] || Icons.CloseCircle;

  return (
    <Button onClick={() => onClick()} variant="icon" {...buttonProps}>
      <IconComponent />
    </Button>
  );
};

export default IconButton;
