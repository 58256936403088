/* eslint-disable max-len */
import React from 'react';

const ZoomIn = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M6.4,4.4v2.4H4v1.2h2.4v2.3h1.2V7.9h2.3V6.8H7.6V4.4L6.4,4.4z M16,14.2l-3.3-3.1c0.9-1.1,1.4-2.4,1.4-3.9c0,0,0,0,0,0v0
        c0-3.7-3.1-6.6-7-6.6S0,3.4,0,7.1s3.1,6.6,7,6.6c0,0,0,0,0,0c1.6,0,3-0.5,4.2-1.3l0,0l3.3,3.1L16,14.2z M2,7.1c0-2.6,2.2-4.7,5-4.7
        s5,2.1,5,4.7s-2.2,4.7-5,4.7S2,9.7,2,7.1z"
      />
    </svg>
  );
};

export default ZoomIn;
/* eslint-enable max-len */
