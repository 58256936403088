/**
 * @module SpinnerDots
 */
import React from 'react';
import PropTypes from 'prop-types';
import t from 'resources/translations';
import cls from 'classnames';
/**
 * Component used for loading animation - 3 dots with flashing animation
 * @param  {Boolean} [props.withLabel] specifies wether the spinner should have 'loading' label
 * @param  {String} [props.label] Loading label
 * @param  {Boolean} [props.inline] specifies wether label and dots should be displayed inline
 */
const SpinnerDots = ({ withLabel, label, inline }) => (
  <div className={cls(inline ? "inline-flex" : "flex")}>
    <div>{withLabel && label}</div>
    <div className="spinner-wrapper">
      <div className="spinner-dots" />
    </div>
  </div>
);

SpinnerDots.defaultProps = {
  withLabel: true,
  label: t('general.loading'),
  inline: false,
};

SpinnerDots.propTypes = {
  withLabel: PropTypes.bool,
  label: PropTypes.string,
  inline: PropTypes.bool,
};

export default SpinnerDots;
