/* eslint-disable max-len */
import React from 'react';

const InviteUser = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 25.09 26"
    >
      <polygon
        points="16.5 17.89 25.09 24.84 25.09 10.27 16.5 17.89"
      />
      <polygon
        points="12.54 21.37 9.84 18.96 1.16 26 23.93 26 15.24 18.96 12.54 21.37"
      />
      <polygon
        points="0 10.27 0 24.84 8.59 17.89 0 10.27"
      />
      <path
        d="M13.08,18.33l6.46-5.79,3-2.6V1.25A1.28,1.28,0,0,0,21.28,0H4.88A1.28,1.28,0,0,0,3.62,1.25V9.84l3,2.7Zm0-17.68a2.62,2.62,0,1,1-2.62,2.62A2.62,2.62,0,0,1,13.08.65ZM8.61,9.43A3.37,3.37,0,0,1,12,6.07h2.22a3.36,3.36,0,0,1,3.35,3.36v2.72h0l-.18.08a9.27,9.27,0,0,1-4,.75,15.33,15.33,0,0,1-4.57-.73l-.18-.06V9.43Z"
        transform="translate(-0.62)"
      />
    </svg>
  );
};

export default InviteUser;
/* eslint-enable max-len */
