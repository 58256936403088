import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { useOutsideClick } from '@old/hooks';
import RowAligner from '@old/components/common/RowAligner';
import Link from '@old/components/common/Link';

const Dropdown = ({ trigger, menuItems, fluid, basic }) => {
  const [isOpen, setIsOpen] = useState(false);
  const refDropdown = useRef();
  const itemClassName = 'block w-full px-6 p-3 text-grey-dark hover:bg-grey-light text-left';

  useOutsideClick(refDropdown, () => setIsOpen(false));

  const renderedItems = menuItems.map((params, index) => {
    if (!params) return null;
    if (params.onClick) {
      return (
        <button key={index} type="button" onClick={params.onClick} className={itemClassName}>
          {params.label}
        </button>
      );
    }

    if (params.to) {
      return <Link key={index} to={params.to} className={itemClassName}>{params.label}</Link>;
    }

    return null;
  }).filter(Boolean);

  if (!renderedItems || renderedItems.length === 0) {
    return null;
  }

  return (
    <div ref={refDropdown} className="hm-dropdown" onClick={() => setIsOpen(prevState => !prevState)}>
      <div className="dropdown-trigger flex">
        <RowAligner>
          {trigger}
          {!basic && <div className={cls('tirangle white', { up: isOpen })} />}
        </RowAligner>
      </div>
      {isOpen && (
        <div className={cls('dropdown-menu', { 'w-full': fluid })}>
          {renderedItems}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

Dropdown.defaultProps = {
  trigger: '',
  menuItems: [],
  children: null,
  fluid: false,
  basic: false,
};

Dropdown.propTypes = {
  trigger: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  menuItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.shape({
          pathname: PropTypes.string,
        }),
      ]),
    }),
  ])),
  fluid: PropTypes.bool,
  basic: PropTypes.bool,
  children: PropTypes.any, // eslint-disable-line
};
