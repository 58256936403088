/* eslint-disable max-len */
import React from 'react';

const Schedule = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M14.2,1.2h-0.7V0h-1.2v1.2H3.9V0H2.7v1.2H1.9C0.9,1.2,0,2.1,0,3.1v11.1c0,1,0.7,1.8,1.7,1.8h12.6c0.9-0.1,1.7-0.9,1.7-1.8
        v-11C16,2.1,15.2,1.2,14.2,1.2L14.2,1.2z M13.6,13.4H9.9V9.7h3.7V13.4z M14.8,4.7H1.3V3.1c0-0.4,0.2-0.6,0.6-0.6h0.7v1.2h1.2V2.5
        h8.2v1.2h1.2V2.5h0.9c0.4,0,0.6,0.2,0.6,0.6L14.8,4.7L14.8,4.7z"
      />
    </svg>
  );
};

export default Schedule;
/* eslint-enable max-len */
