import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

import config from '@old/config';

const ProgressBar = ({ status }) => {
  const statusBar = config.progressStatusBar.filter((statusProps) => {
    if (status === 'cancelled') {
      return statusProps.statusName !== 'finished';
    }
    return statusProps.statusName !== 'cancelled';
  });

  return (
    <div className='progressbar w-4/5 md:w-3/5 md:px-0'>
      {statusBar.map((statusProps) => {
        const { icon, statusName, label } = statusProps;
        let active = false;
        if (status === statusName) {
          active = true;
        }

        return (
          <div
            key={statusName}
            className={cls('progressbar-item', { active })}
          >
            {icon}
            {active && (
              <div
                className="absolute font-bold text-center md:w-64 md:pin-none text-teal-dark"
                style={{ top: '50px' }}
              >
                {label}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ProgressBar;
