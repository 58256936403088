import React from 'react';

import { Four, Unicorn } from 'old/assets/svg';
import t from 'resources/translations';

const Error404 = () => (
  <div className="error-info">
    <div className="page-error">
      <div className="error-404">
        <div style={{ flex: 0.29 }}><Four /></div>
        <div style={{ flex: 0.42 }}><Unicorn /></div>
        <div style={{ flex: 0.29, paddingLeft: '10%' }}><Four /></div>
      </div>
      <div className="text-error-page mb-4">{t('error404.twoThingsDontExist')}</div>
      <div className="text-lg leading-normal">{t('error404.missingPage')}&nbsp;
        <a className="text-teal" href="https://www.app.horsemanago.com">www.app.horsemanago.com</a>
      </div>
    </div>
  </div>
);

export default Error404;
