import EventTypesListItem from '@old/components/view/listItem/EventType';
import FlexColumn from '@old/components/common/FlexColumn';

const EventTypesList = ({ data }) => {
  return (
    <FlexColumn>
      {data.map(eventType => (
        <EventTypesListItem key={eventType.id} eventType={eventType} />
      ))}
    </FlexColumn>
  );
};

export default EventTypesList;
