import { Box, Flex, Span, Stack, Text } from 'components/elements';
import t from 'resources/translations';
import { useState } from 'react';
import NumberInput from './NumberInput';
import RadioInput from './RadioInput';

type TTicketValidity = {
  value: number | null;
  name: string;
  onChange: (days: number | null) => void;
  isError?: string | undefined;
};

const TicketValidityInput = ({ value, name, onChange, isError }: TTicketValidity) => {
  const [selected, setSelected] = useState(`days-${name}`);
  const [days, setDays] = useState(value);
  const [weeks, setWeeks] = useState(value);
  const dayName = `days-${name}`;
  const weeksName = `weeks-${name}`;
  const isDaysSelected = selected === dayName;
  const isWeeksSelected = selected === weeksName;

  const onChangeRange = (nameRange: string) => {
    if (nameRange === dayName) {
      onChange(days || 1);
    }
    if (nameRange === weeksName) {
      onChange(weeks ? weeks * 7 : 1);
    }
    setSelected(nameRange);
  };

  const onChangeDays = (count: number | null) => {
    setDays(count);
    onChange(count || 1);
  };

  const onChangeWeeks = (weeks: number | null) => {
    setWeeks(weeks);
    onChange(weeks ? Math.round(weeks) * 7 : 1);
  };

  const onBlurDays = () => {
    if (!days) {
      onChangeDays(1);
    }
  };

  const onBlurWeekes = () => {
    if (!weeks) {
      onChangeWeeks(1);
    }
  };

  return (
    <Box>
      <Stack alignItems="center" isHorizontal>
        <Flex maxW={110} h={48} alignItems="center">
          <RadioInput id={dayName} value={days || 0} name={dayName} onChange={onChangeRange} isChecked={isDaysSelected}>
            {t('general.days')}
          </RadioInput>
        </Flex>
        {isDaysSelected && (
          <NumberInput
            name={`${dayName}-count`}
            value={days || ''}
            onChange={onChangeDays}
            precision={0}
            min={1}
            onBlur={onBlurDays}
            strict
          />
        )}
      </Stack>
      <Stack alignItems="center" isHorizontal>
        <Flex maxW={110} h={48} alignItems="center">
          <RadioInput
            id={weeksName}
            value={weeks || 0}
            name={weeksName}
            onChange={onChangeRange}
            isChecked={isWeeksSelected}
          >
            {t('general.weeks')}
          </RadioInput>
        </Flex>
        {isWeeksSelected && (
          <NumberInput
            name={`${weeksName}-count`}
            value={weeks || ''}
            onChange={onChangeWeeks}
            precision={0}
            min={1}
            onBlur={onBlurWeekes}
            strict
          />
        )}
      </Stack>
      {!isError && (
        <Box display="inline-block" pt="sm" pb="xs" borderBottomWidth={1} borderColor="grey-light">
          <Text>
            {t('ticketOffers.ticketWillBeValidFor')} <Span fontWeight="bold">{value}</Span>
            {` ${t('tickets.days', { count: value }).toLowerCase()}`}.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default TicketValidityInput;
