import UserListItem from '@old/components/view/listItem/User';
import t from 'resources/translations';
import FlexColumn from '@old/components/common/FlexColumn';
import Message from '@old/components/common/Message';

const UsersList = ({ pending, data }) => {
  return (
    <FlexColumn>
      {pending && <Message info>{t('message.acceptingAccountNotify')}</Message>}
      {data.map(member => (
        <UserListItem key={member.id} member={member} />
      ))}
    </FlexColumn>
  );
};

UsersList.defaultProps = {
  data: [],
  pending: false,
};

export default UsersList;
