import moment from 'moment';
import { Member } from '@old/model/Member';
import { Bill } from '@old/model/Bill';
import { Horse } from './Horse';

class Participation {
  constructor(data) {
    this.id = data.id;
    this.member = new Member(data.membership || {});
    this.horse = data.horse ? new Horse(data.horse) : null;
    this.status = data.status;
    this.attendance = data.attendance;
    this.paidAmount = Number(data.paid_amount) || 0;
    this.paidAt = data.paid_at && moment(data.paid_at);
    this.paymentStatus = data.payment_status;
    this.updatedAt = data.updated_at && moment(data.updated_at);
    this.bill = data.bill ? new Bill(data.bill) : null;
    this.optOut = data.opt_out || false;
    this.privileges = data.meta ? data.meta.privileges : [];
  }

  getPrivileges() {
    return this.privileges;
  }

  getPermission(permissionKey) {
    return this.privileges.includes(permissionKey);
  }

  isInvited() {
    return this.status === 'invited';
  }

  isRejected() {
    return this.status === 'rejected';
  }

  isInvitedRequested() {
    return this.status === 'invitation_requested';
  }

  isJoined() {
    return this.status === 'joined';
  }

  isAttending() {
    return this.attendance === 'attending';
  }

  isAbsent() {
    return this.attendance === 'absent';
  }

  getParticipant() {
    return this.member;
  }

  getStatus() {
    return this.status;
  }
}

export default Participation;
