import { connect } from 'react-redux';
import NewsListItem from '@old/components/view/listItem/News';
import { Grid } from '@elements';
import Message from '@old/components/common/Message';

import t from 'resources/translations';

const NewsList = props => {
  const { data, fetchParams } = props;
  const isEmpty = !data.length;
  const columns = props.isMobile ? 1 : 2;
  if (isEmpty) {
    return <Message info>{!fetchParams.drafts ? t('newsDetails.noNews') : t('newsDetails.noDrafts')}</Message>;
  }

  return (
    <div className="lg:px-12 mt-4">
      <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap="md">
        {data.slice(0).map(newsItem => (
          <NewsListItem key={newsItem.id} newsItem={newsItem} />
        ))}
      </Grid>
    </div>
  );
};

NewsList.defaultProps = {
  fetchParams: {
    drafts: false,
  },
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(NewsList);
