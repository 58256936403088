/* eslint-disable max-len */
import React from 'react';

const InfoOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 27 27"
    >
      <path d="M13.5 10.37a1.73 1.73 0 0 0-1.76 1.7v7.74a1.76 1.76 0 0 0 3.52 0v-7.74a1.73 1.73 0 0 0-1.76-1.7zm0-1a1.7 1.7 0 0 0 1.24-.56 2 2 0 0 0 0-2.7 1.66 1.66 0 0 0-2.34-.15l-.14.15a2 2 0 0 0 0 2.7 1.73 1.73 0 0 0 1.24.52zm0-9.37A13.5 13.5 0 1 0 27 13.5 13.5 13.5 0 0 0 13.5 0zm0 26A12.5 12.5 0 1 1 26 13.5 12.5 12.5 0 0 1 13.5 26z" />
    </svg>
  );
};

export default InfoOutline;
/* eslint-enable max-len */
