import { useEffect, useState } from 'react';
import cls from 'classnames';
import _, { isEmpty } from 'lodash';

import { Modal, ModalHeader, ModalCloseButton, ModalContent, Divider, ModalButtons } from 'components/combinations';
import { Text, Span, Button, A, Stack, Box, Ternary } from 'components/elements';
import Icon from '@old/components/icon';
import RowAligner from '@old/components/common/RowAligner';
import t from 'resources/translations';
import FlexColumn from '@old/components/common/FlexColumn';
import Input from '@old/components/input';
import FlexRow from '@old/components/common/FlexRow';
import ButtonWithArrow from '@old/components/guide/ButtonWithArrow';
import Model from '@old/model';
import { getErrorMessage, notify } from 'old/utils';
import { fetchFarm } from 'store/actions';
import Spinner from '@old/components/common/Spinner';
import { useShallowSelector } from '@old/hooks';
import SpinnerDots from '@old/components/guide/SpinnerDots';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';
import FormField from 'components/combinations/forms/FormField';
import FormLabel from 'components/combinations/forms/FormLabel';
import TextInput from 'components/combinations/forms/TextInput';
import { useActions } from 'old/hooks';
import { yup } from 'utils';
import { FarmService } from 'services';

const SettingsModal = ({ onClose }) => {
  const { isMobile } = useShallowSelector(({ app }) => app);
  const [settingsEvent, setSettingsEvent] = useState(false);
  const [settingsPayment, setSettingsPayment] = useState(false);
  const farmSettingsState = useQuery('farm_settings', Model.Farms.getFarmSettings);
  const actionFetchFarm = useActions(fetchFarm);

  const farmState = useQuery('farm', () => FarmService.fetch());
  const farm = farmState.data || {};
  const isAdvancedPaymentEnabled = farm.event_payment_plan === 'advanced';

  const farmSettings = farmSettingsState.data?.data || {};
  const convertSecToHour = farmSettings.unjoin_timeout / 3600;

  const paymentsInitialValues = {
    payments_enabled: isAdvancedPaymentEnabled,
    bluemedia_service_id: farmSettings.bluemedia_service_id,
    bluemedia_service_key: farmSettings.bluemedia_service_key,
  };

  const eventInitialValues = {
    radioValue: convertSecToHour === (null || 0),
    timeValue: convertSecToHour || 0,
  };

  useEffect(() => {
    formikPaymentsSettings.setValues(paymentsInitialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmSettingsState.data, farmState.data]);

  useEffect(() => {
    formikEventSettings.setValues(eventInitialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmSettingsState.data]);

  const formikPaymentsSettings = useFormik({
    initialValues: paymentsInitialValues,
    validationSchema: yup.object().shape({
      bluemedia_service_id: yup.string().required(t('error.fieldIsRequired')),
      bluemedia_service_key: yup.string().required(t('error.fieldIsRequired')),
    }),
    onSubmit: async values => {
      try {
        if (_.isEqual(paymentsInitialValues, values)) {
          notify(t('message.noChange'), { type: 'warning', autoClose: 1500 });
          return;
        }
        if (values.payments_enabled !== isAdvancedPaymentEnabled) {
          await Model.Farms.updatePaymentPlan(values.payments_enabled ? 'advanced' : 'basic');
        }

        if (!isEmpty(values)) {
          await Model.Farms.updateFarmSettings({
            bluemedia_service_id: values.bluemedia_service_id,
            bluemedia_service_key: values.bluemedia_service_key,
          });
        }
        notify(t('message.succesSave'));
        // actionRefreshFarm();
        actionFetchFarm();
        onClose();
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      }
    },
  });

  const formikEventSettings = useFormik({
    initialValues: eventInitialValues,
    onSubmit: async values => {
      const timeToApi = values.radioValue ? 0 : values.timeValue * 3600;
      try {
        await Model.Farms.updateFarmSettings({ unjoin_timeout: timeToApi });
        notify(t('message.succesSave'));
        onClose();
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      }
    },
  });

  const showPaymentSetting = () => {
    setSettingsPayment(true);
  };

  const showEventSetting = () => {
    setSettingsEvent(true);
  };

  const hideSettings = () => {
    formikPaymentsSettings.setTouched({});
    formikPaymentsSettings.setValues(paymentsInitialValues);
    formikEventSettings.setTouched({});
    formikEventSettings.setValues(eventInitialValues);
    setSettingsPayment(false);
    setSettingsEvent(false);
  };

  const eventButtonIsShow = settingsEvent && !_.isEmpty(formikEventSettings.touched);
  const paymentsButtonIsShow = settingsPayment && !_.isEmpty(formikPaymentsSettings.touched);
  const eventButtonIsDisabled = !formikEventSettings.isValid && eventButtonIsShow;
  const paymentsButtonIsDisabled = !formikPaymentsSettings.isValid && paymentsButtonIsShow;

  return (
    <Modal isHidden={false} onClose={onClose} isOpen innerModalProps={{ overflow: 'hidden' }}>
      <ModalHeader>{t('farmDetails.settings')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={formikEventSettings.isSubmitting || formikPaymentsSettings.isSubmitting}>
        {farmSettingsState.isFetching && <Spinner />}
        {!settingsEvent && !settingsPayment && (
          <FlexColumn>
            <ButtonWithArrow fluid onClick={() => showEventSetting()} className="text-left">
              <RowAligner separated={false}>
                <div className="flex justify-center" style={{ width: 45, height: 45 }}>
                  <Icon.EventSettings />
                </div>
                <Span fontWeight="medium">{t('buttons.eventsSettings')}</Span>
              </RowAligner>
            </ButtonWithArrow>
            <ButtonWithArrow fluid onClick={() => showPaymentSetting()} className="text-left">
              <RowAligner separated={false}>
                <div className="flex justify-center" style={{ width: 45, height: 45 }}>
                  <Icon.PaymentSettings />
                </div>
                <Span fontWeight="medium">{t('buttons.paymentManage')}</Span>
              </RowAligner>
            </ButtonWithArrow>
          </FlexColumn>
        )}
        {settingsPayment && (
          <SettingsPaymentForm formikProps={formikPaymentsSettings} hideSettings={hideSettings} isMobile={isMobile} />
        )}
        {settingsEvent && (
          <SettingsEventForm hideSettings={hideSettings} formikProps={formikEventSettings} isMobile={isMobile} />
        )}
      </ModalContent>
      {(eventButtonIsShow || paymentsButtonIsShow) && (
        <ModalButtons>
          <Button
            width="100%"
            onClick={eventButtonIsShow ? formikEventSettings.submitForm : formikPaymentsSettings.submitForm}
            disabled={eventButtonIsDisabled || paymentsButtonIsDisabled}
          >
            {formikPaymentsSettings.isSubmitting || formikEventSettings.isSubmitting ? (
              <SpinnerDots label={t('general.loading2')} inline />
            ) : (
              t('general.saveChanges')
            )}
          </Button>
          <Button variant="outline" width="100%" onClick={onClose}>
            {t('general.cancel')}
          </Button>
        </ModalButtons>
      )}
    </Modal>
  );
};

export default SettingsModal;

const SettingsPaymentForm = ({ hideSettings, formikProps }) => {
  const { farm } = useShallowSelector(({ app }) => app);
  const onBackClick = () => {
    hideSettings();
  };

  return (
    <Stack>
      <Divider color="grey-light" />
      <Button variant="icon" onClick={onBackClick} display="inline-flex">
        <Icon.ArrowLeft className="fill-current" small />
        <Text fontWeight="bold" fontSize="md" marginLeft="sm">
          {t('farmDetails.paymentsSettings')}
        </Text>
      </Button>
      <Text fontSize="sm">
        <Span>{t('farmDetails.currentState').toUpperCase()}</Span>
        <Ternary cond={farm?.isAdvancedPaymentEnabled()}>
          <Span color="#24a148">{t('farmDetails.paymentEnabled').toUpperCase()}</Span>
          <Span>{t('farmDetails.paymentDisabled').toUpperCase()}</Span>
        </Ternary>
      </Text>
      <Text>
        {t('farmDetails.paymentDocs')}
        <Span fontWeight="bold">{t('farmDetails.paymentManual')}</Span>
        <br />
        <A href="/pdf/instrukcja_konfiguracji_płatnosci_online.pdf" isExternal>
          [{t('farmDetails.downloadPdf')}]
        </A>
      </Text>
      <form>
        <Box my="md">
          <Input.Switch
            name="payments_enabled"
            onLabel={t('farmDetails.disablePayments')}
            offLabel={t('farmDetails.enablePayments')}
            value={formikProps.values.payments_enabled}
            onChange={value => {
              formikProps.setFieldValue('payments_enabled', value);
              formikProps.setFieldTouched('payments_enabled');
            }}
          />
        </Box>
        {formikProps.values.payments_enabled && (
          <Box>
            <FormField errors={formikProps.errors.bluemedia_service_id} errorStyleProps={{ minH: 2, mb: 'xs' }}>
              <FormLabel htmlFor="bluemedia_service_id" required>
                {t('labels.id')}
              </FormLabel>
              <TextInput
                {...formikProps.getFieldProps('bluemedia_service_id')}
                onChange={e => {
                  formikProps.setFieldValue('bluemedia_service_id', e.target.value);
                  formikProps.setFieldTouched('bluemedia_service_id');
                }}
              />
            </FormField>

            <FormField errors={formikProps.errors.bluemedia_service_key} errorStyleProps={{ minH: 2, mb: 'xs' }}>
              <FormLabel htmlFor="bluemedia_service_key" required>
                {t('labels.key')}
              </FormLabel>
              <TextInput
                {...formikProps.getFieldProps('bluemedia_service_key')}
                onChange={e => {
                  formikProps.setFieldValue('bluemedia_service_key', e.target.value);
                  formikProps.setFieldTouched('bluemedia_service_key');
                }}
              />
            </FormField>
          </Box>
        )}
      </form>
    </Stack>
  );
};

const SettingsEventForm = ({ hideSettings, isMobile, formikProps }) => {
  const handleChange = value => {
    formikProps.setFieldValue('radioValue', value);
    formikProps.setFieldTouched('radioValue');
  };

  const onChangeNumber = value => {
    if (value <= 100) {
      formikProps.setFieldValue('timeValue', value);
      formikProps.setFieldTouched('timeValue');
    }
  };

  return (
    <FlexColumn>
      <Divider color="grey-light" />
      <Button variant="icon" onClick={hideSettings} display="inline-flex">
        <Icon.ArrowLeft className="fill-current" small />
        <Text fontWeight="bold" fontSize="md" marginLeft="sm">
          {t('farmDetails.eventsSettings')}
        </Text>
      </Button>
      <Input.Radio
        name="blockEvent"
        label={t('labels.unsubscribeFromEvents')}
        value={formikProps.values.radioValue}
        options={[
          { value: true, label: t('labels.dontBlock').toLowerCase(), dataTest: 'dontBlock' },
          { value: false, label: t('labels.block').toLowerCase(), dataTest: 'block' },
        ]}
        onChange={(value, label) => handleChange(value, label)}
      />
      {!formikProps.values.radioValue && (
        <FlexRow alignCenter={!isMobile}>
          <Input.Numeric
            onChange={value => onChangeNumber(value)}
            value={formikProps.values.timeValue}
            label={t('labels.BlockUnsubscribingOn')}
            required
            spinButtons
            min={0}
            max={100}
            newWrapper
          />
          <div className={cls('text-input-content', { 'mt-6': !isMobile })}>
            <b>{`${t('general.ShortcutHour')} `}</b>
            {t('labels.beforeTheEventStarts')}
          </div>
        </FlexRow>
      )}
    </FlexColumn>
  );
};
