import { RESET_SEARCH, SEARCH } from 'store/actions/searchActions';

const initialState = {
  query: '',
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SEARCH:
      return {
        ...state,
        query: '',
      };
    case SEARCH:
      return {
        ...state,
        query: action.query,
      };
    default:
      return state;
  }
};

export default searchReducer;
