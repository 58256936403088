import { IMeta } from 'resources/types/commonTypes';
import { ITicketRes, TTicketStatus } from 'resources/types/TicketTypes';
import EventType from './EventType';
import Member from './Member';

class Ticket {
  id: number;
  name: string;
  count: number;
  countLimit: number;
  status: TTicketStatus;
  description: string;
  activeAt: Date;
  expireAt: Date;
  createdAt: Date;
  updatedAt: Date;
  eventTypes: EventType[];
  buyer: Member;
  membership: Member;
  meta: IMeta;
  billId: number;

  constructor(data: ITicketRes) {
    this.id = data.id;
    this.name = data.name;
    this.count = data.count;
    this.countLimit = data.count_limit;
    this.status = data.status;
    this.description = data.description;
    this.activeAt = new Date(data.active_at);
    this.expireAt = new Date(data.expire_at);
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
    this.eventTypes = (data.event_types || []).map(type => new EventType(type));
    this.buyer = new Member(data.buyer);
    this.membership = new Member(data.membership);
    this.meta = data.meta;
    this.billId = data.bill_id;
  }

  getName() {
    return this.name || '';
  }
}

export default Ticket;
