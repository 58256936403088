import React, { useState, useEffect } from 'react';
import cls from 'classnames';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import { isMobile as isMobileDevices } from 'react-device-detect';

import { openModal } from 'store/actions';
import Allow from '@old/components/common/Allow';
import Modal from '@old/components/common/Modal';
import Slider from '@old/components/old/Slider';
import { getErrorMessage, notify } from 'old/utils';
import t from 'resources/translations';
import Spinner from '@old/components/common/Spinner';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import config from '@old/config';
import { useDisclosure, useFocus } from '@old/hooks';

const GalleryViewer = ({
  openModalByName,
  modelItem,
  isMobile,
  modalsList,
  rounded,
  perView,
  permissionKey,
  refreshAction,
}) => {
  const [currentPictureIndex, setCurrentPicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputRef, setInputFocus] = useFocus();
  const pictures = sortBy(modelItem.pictures || [], ['id']);
  const galleryDisclosure = useDisclosure();

  useEffect(() => {
    if (!isMobileDevices) setInputFocus();
  }, [modalsList.length, setInputFocus]);

  const editPicture = async picture => {
    openModalByName('editPicture', { modelItem, picture, refreshAction });
  };

  const deletePicture = async pictureId => {
    setLoading(true);
    try {
      const data = { id: pictureId, destroy: true };
      await modelItem.updatePicture({ pictures: [data] });
      const newPictureIndex = currentPictureIndex - 1;
      setCurrentPicture(newPictureIndex > 0 ? newPictureIndex : 0);
      notify(t('message.photoSuccesDeleted'));
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      refreshAction(modelItem.modelName !== 'farms' && modelItem.id);
    }

    if (pictures.length < 1) {
      galleryDisclosure.onClose();
    }
    setLoading(false);
  };

  const onHandlePicture = ({ index }) => {
    setCurrentPicture(index - 1);
    galleryDisclosure.onOpen();
  };

  const setInputDesktop = () => {
    if (!isMobileDevices) setInputFocus();
  };

  const prevPicture = () => {
    setInputDesktop();
    setCurrentPicture(currentPictureIndex - 1);
  };

  const nextPicture = () => {
    setInputDesktop();
    setCurrentPicture(currentPictureIndex + 1);
  };

  const rightArrowVisible = currentPictureIndex < pictures.length - 1;
  const leftArrowVisible = currentPictureIndex > 0;

  const changePictureHandler = e => {
    if (e.keyCode === config.keyCodes.arrowRight && rightArrowVisible) {
      setCurrentPicture(currentPictureIndex + 1);
    }
    if (e.keyCode === config.keyCodes.arrowLeft && leftArrowVisible) {
      setCurrentPicture(currentPictureIndex - 1);
    }
  };

  const picture = pictures[currentPictureIndex];
  const propsGallery = !isMobileDevices ? { onClick: setInputFocus() } : {};

  return (
    <React.Fragment>
      {pictures.length > 0 && (
        <Slider
          key={pictures.length}
          images={pictures}
          onClick={onHandlePicture}
          perView={perView}
          centerMode={isMobile}
          rounded={rounded}
          arrows
        />
      )}

      {galleryDisclosure.isOpen && picture && (
        <div {...propsGallery}>
          <Modal
            header={`${t('galleryViewer.photo')} ${currentPictureIndex + 1}/${pictures.length}`}
            onClose={galleryDisclosure.onClose}
            isOpen={galleryDisclosure.isOpen}
          >
            <div className="relative">
              {loading && <Spinner />}
              {!isMobileDevices && (
                <input
                  style={{ transform: 'scale(0)', position: 'absolute' }}
                  ref={inputRef}
                  onKeyDown={e => !isMobileDevices && changePictureHandler(e)}
                />
              )}
              {leftArrowVisible && (
                <div
                  className={cls('flex items-center left-0 top-0 absolute z-20 cursor-pointer')}
                  onClick={prevPicture}
                  style={{ top: 'calc(50% - 40px)' }}
                >
                  <Icon.ArrowLeft className="fill-white" big />
                </div>
              )}
              <div className="relative" style={{ lineHeight: 0 }}>
                <img key={picture.id} src={picture.url.large} className="w-full" alt="" />
                <div className="absolute top-2 right-2 z-20">
                  <Allow permissionKey={permissionKey}>
                    <ButtonSimple onClick={() => deletePicture(picture.id)}>
                      <Icon.Delete circle small />
                    </ButtonSimple>
                  </Allow>
                </div>
                <div
                  className="absolute left-0 bottom-0 py-4 pl-2 pr-6 text-white w-full truncate select-none"
                  style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
                >
                  {picture.description || t('galleryViewer.noDescription')}
                  <div className="absolute top-1 right-1 z-20">
                    <Allow permissionKey={permissionKey}>
                      <ButtonSimple onClick={() => editPicture(picture)} fluid>
                        <Icon.Edit circle small />
                      </ButtonSimple>
                    </Allow>
                  </div>
                </div>
              </div>
              {rightArrowVisible && (
                <div
                  className={cls('flex items-center right-0 top-0 absolute z-20 cursor-pointer')}
                  onClick={nextPicture}
                  style={{ top: 'calc(50% - 40px)' }}
                >
                  <Icon.ArrowRight className="fill-white" big />
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
};

GalleryViewer.defaultProps = {
  rounded: false,
  perView: 5,
  refreshAction: () => {},
  modalsList: [],
};

const mapStateToProps = ({ app: { isMobile }, modals }) => ({ isMobile, modalsList: modals });
export default connect(mapStateToProps, { openModalByName: openModal })(GalleryViewer);
