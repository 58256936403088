import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Redirect } from 'react-router';
import { cloneDeep, unset } from 'lodash';

import BoxShadow from '@old/components/common/BoxShadow';
import { LogoHmNotextIcon } from 'old/assets/svg';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import Field from '@old/components/field';
import FormButton from '@old/components/common/FormButton';
import FlexColumn from '@old/components/common/FlexColumn';
import BoxWhite from '@old/components/common/BoxWhite';
import config from '@old/config';
import { socialLogin } from 'store/auth';
import { A, Box } from 'components/elements';
import { FORM_SOCIAL_REGISTER } from 'old/constants';

const SocialLogin = ({ history }) => {
  const dispatch = useDispatch();
  const { requiredFields, accessToken, service } = history.location.state;
  const minBirthdayDate = moment().subtract(120, 'years');
  const maxBirthdayDate = moment().subtract(6, 'years');

  const onSubmit = async formState => {
    const { pathname } = window.location;
    const user = {
      phone: formState.phone,
      birthday: moment(formState.birthday, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      terms_accepted: formState.term && formState.policy,
    };
    dispatch(socialLogin({ service, accessToken, farmSlug: pathname, history, userData: user }));
  };

  if (!accessToken && !requiredFields) {
    return <Redirect to="/" />;
  }

  const filterInitValues = initialVals => {
    const filteredInitVals = cloneDeep(initialVals);

    if (!requiredFields.includes('phone')) unset(filteredInitVals, 'phone');
    if (!requiredFields.includes('birthday')) unset(filteredInitVals, 'birthday');

    return filteredInitVals;
  };

  const initValues = filterInitValues({
    phone: '',
    birthday: '',
    term: false,
    policy: false,
  });

  return (
    <BoxShadow className="page-social-login">
      <div className="login-banner">
        <LogoHmNotextIcon width={120} height={120} />
      </div>
      <BoxWhite padding>
        <FlexColumn>
          <h1 className="text-headline">{t('socialLogin.fillData')}</h1>
          <Form formName={FORM_SOCIAL_REGISTER} initValues={initValues} noPadding>
            {requiredFields.includes('phone') && (
              <Field.Phone
                name="phone"
                label={t('labels.phone')}
                placeholder={t('placeholders.phone')}
                defaultDirectionalValue={config.defaultDirectional.value}
                required
              />
            )}
            {requiredFields.includes('birthday') && (
              <Field.DateValue
                name="birthday"
                label={t('labels.birthDate')}
                placeholder={t('placeholders.date')}
                minDate={minBirthdayDate}
                maxDate={maxBirthdayDate}
                defaultDate={moment().subtract(18, 'years')}
                required
              />
            )}
            <Field.Checkbox name="term" label={t('labels.termsAndConditionsDeclare')} required />
            <Field.Checkbox name="policy" label={t('labels.policyAndConditionsDeclare')} required />

            <Box>
              <A href="https://horsemanago.com/pl/terms-of-service/" fontSize="sm" isExternal>
                {t('register.terms')}
              </A>
              <span>{` ${t('general.and')} `}</span>
              <A href="https://horsemanago.com/pl/privacy-policy/" fontSize="sm" isExternal>
                {t('register.privacyPolicy')}
              </A>
            </Box>

            <FormButton formName={FORM_SOCIAL_REGISTER} onClick={onSubmit} fluid>
              {t('socialLogin.createAccount')}
            </FormButton>
          </Form>
        </FlexColumn>
      </BoxWhite>
    </BoxShadow>
  );
};

export default SocialLogin;
