import RowAligner from '@old/components/common/RowAligner';
import Box from '@old/components/common/Box';
import BoxWhite from '@old/components/common/BoxWhite';
import Center from '@old/components/common/Center';
import FlexColumn from '@old/components/common/FlexColumn';
import Title from '@old/components/common/Title';
import t from 'resources/translations';
import { useShallowSelector } from 'old/hooks';

const EventInvitationStatistics = ({ event }) => {
  const [isMobile, isTablet] = useShallowSelector(({ app }) => [app.isMobile, app.isTablet]);
  if (!event) return null;

  const backgroundStyle = {
    backgroundImage: 'url(/img/plane.svg), url(/img/plane_small.svg)',
    backgroundPosition: 'right bottom, left bottom',
    backgroundRepeat: 'no-repeat, no-repeat',
    backgroundSize: '100px, 80px',
  };
  const invitationsStats = {
    joinedParticipations: event.getJoinedParticipations().length,
    participations: event.getParticipations().length,
  };

  const Wrapper = !isMobile && !isTablet ? Box : BoxWhite;
  const renderCounter = (value, label) => {
    if (isTablet) {
      return (
        <RowAligner className="py-3 px-10 w-full border rounded border-teal">
          <div>{value}</div>
          <Center>{label}</Center>
        </RowAligner>
      );
    }
    return (
      <FlexColumn separated="small">
        <div className="square">{value}</div>
        <Center>{label}</Center>
      </FlexColumn>
    );
  };

  return (
    <Wrapper backgroundStyle={backgroundStyle}>
      <FlexColumn>
        <Title>{t('eventInvitationStatistics.invitationStatistics')}</Title>
        <Center>
          <div className="pr-4">
            {renderCounter(invitationsStats.participations, t('eventInvitationStatistics.sent').toLowerCase())}
          </div>
          <div className="pl-4">
            {renderCounter(
              invitationsStats.joinedParticipations,
              t('eventInvitationStatistics.accepted').toLowerCase()
            )}
          </div>
        </Center>
      </FlexColumn>
    </Wrapper>
  );
};

export default EventInvitationStatistics;
