import Event from 'models/Event';
import ActiveEventInfo from 'views/Dashboard/ActiveEventInfo';
import ActiveEventParticipants from 'views/Dashboard/ActiveEventParticipants';
import { useEffect, useRef, useState } from 'react';
import { Box, Flex, Grid, Icons, Stack, Text } from 'components/elements';
import { TBoxProps, TFlexProps } from 'resources/types/elementsTypes';
import { useAppSelector, useLoggedMember, useWindowWidth } from 'utils';
import t from 'resources/translations';

const containerStyleProps: TFlexProps = {
  bgColor: 'white',
  boxShadow: '0 2px 12px 0 rgba(37, 51, 66, 0.15)',
  radius: 8,
  py: 'md',
  px: 'xl',
  justifyContent: 'space-between',
  mb: 'md',
};

interface IActiveEventsProps extends TBoxProps {
  activeEvents: Event[];
}

const ActiveEvents = ({ activeEvents, mt, ...flexProps }: IActiveEventsProps) => {
  const loggedMember = useLoggedMember();
  const isMobile = useAppSelector(({ app }) => app.isMobile);

  const [columns, setColumns] = useState(1);
  const width = useWindowWidth(250);
  const contentRef = useRef<HTMLInputElement>(null);
  const maxColumnWidth = 368;

  useEffect(() => {
    if (contentRef.current?.offsetWidth) {
      setColumns(parseInt((contentRef.current.offsetWidth / maxColumnWidth).toString()));
    }
  }, [width, activeEvents, contentRef]);

  const isSingleMode = !isMobile && activeEvents.length === 1;
  const gridColumns = activeEvents.length < columns ? activeEvents.length : columns;

  if (!activeEvents.length) {
    return null;
  }

  return (
    <Box w="full" ref={contentRef} mt={mt}>
      <Flex {...containerStyleProps} {...flexProps}>
        <Stack alignItems="center" isHorizontal>
          <Icons.ActiveEventsCircle bgColor="grey-lighter-40" />
          <Text fontWeight="medium">{t('dashboard.activeEvents')}</Text>
        </Stack>
      </Flex>
      <Grid gridTemplateColumns={['1fr', `repeat(${gridColumns}, 1fr)`]} gridGap="md">
        {activeEvents.map(activeEvent => {
          return (
            <Flex
              bgColor="white"
              p="md"
              flexDirection="column"
              overflow="hidden"
              radius={8}
              boxShadow="0 2px 12px 0 rgba(37, 51, 66, 0.15)"
            >
              <ActiveEventInfo event={activeEvent} isSingleMode={isSingleMode} />
              {!loggedMember?.isClient() && <ActiveEventParticipants event={activeEvent} isSingleMode={isSingleMode} />}
            </Flex>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ActiveEvents;
