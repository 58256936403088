import { useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import cls from 'classnames';

import { Grid } from '@elements';
import PageTitle from '@old/components/guide/PageTitle';
import Spinner from '@old/components/common/Spinner';
import Box from '@old/components/common/Box';
import t from 'resources/translations';
import EventParticipationItem from '@old/components/view/listItem/EventParticipation';
import EditableTextarea from '@old/components/common/editable/EditableTextarea';
import Subtitle from '@old/components/common/Subtitle';
import FloatingButton from '@old/components/custom/FloatingButton';
import FlexColumn from '@old/components/common/FlexColumn';
import Error404 from '@old/components/error/Error404';
import PlaceholderListItem from '@old/components/view/listItem/Placeholder';
import { getErrorMessage, notify } from 'old/utils';
import { fetchGroup } from 'store/actions';
import store from 'store';
import {
  useGroupAddMemberButton,
  useGroupDeleteMemberButton,
  useGroupEditButton,
  useGroupDeleteButton,
} from 'old/buttonHooks';
import { Button, Icons, Stack, Text } from 'components/elements';
import { useAppSelector } from 'utils';

const UserGroups = ({ match, loading, error, group, ...actions }) => {
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchGroup(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  const editGroup = useGroupEditButton(group, true);
  const deleteGroup = useGroupDeleteButton(group, true);
  const groupAddMember = useGroupAddMemberButton(group);
  const groupDeleteMember = useGroupDeleteMemberButton(group);
  const groupButtons = [editGroup, deleteGroup];
  let source;

  if (error) return <Error404 />;
  if (loading && !group) return <Spinner />;
  if (!group) return null;

  const onSubmit = async value => {
    const { CancelToken } = axios;
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    try {
      await group.update({ description: value.trim() });
      notify(t('message.succesSave'));
      store.dispatch(fetchGroup(group.id, source.token));
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const columns = isMobile ? 1 : 2;
  return (
    <div>
      <PageTitle title={group.name} back loading={loading} />
      <FlexColumn>
        <Box>
          {group.getPermission('update') ? (
            <EditableTextarea
              onSubmit={value => onSubmit(value, 'aboutMe')}
              defaultValue={group.description}
              placeholder={t('editableTextarea.addNoteAboutGroup')}
              label={t('general.description')}
            />
          ) : (
            <FlexColumn separated="small">
              <div className="text-highlight">{t('general.description')}</div>
              <span className="text break-all">{group.description}</span>
            </FlexColumn>
          )}
        </Box>
        <div className={cls({ 'pl-2': isMobile })}>
          <Subtitle>
            {t('general.clients')}: {group.getSize()}
          </Subtitle>
        </div>
        <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap="md">
          {group.members.map(member => (
            <EventParticipationItem key={member.id} modelItem={member}>
              {groupDeleteMember && (
                <Button onClick={() => groupDeleteMember.onClick(group, member.id)} variant="link">
                  <Stack spacing="sm" isHorizontal alignItems="center">
                    <Icons.Cancel squareSize="sm" />
                    <Text fontSize="base">{t('general.delete')}</Text>
                  </Stack>
                </Button>
              )}
            </EventParticipationItem>
          ))}
          {groupAddMember && (
            <Button onClick={groupAddMember.onClick} variant="link">
              <PlaceholderListItem className="teal-hover border-teal-light">
                {groupAddMember.icon}
                <Text fontSize="base">{groupAddMember.label}</Text>
              </PlaceholderListItem>
            </Button>
          )}
        </Grid>
        <FloatingButton buttons={groupButtons} />
      </FlexColumn>
    </div>
  );
};

UserGroups.defaultProps = {
  group: null,
};

const mapStateToProps = ({ fetchingData: { groups, loading, error } }, { match }) => ({
  group: groups.find(group => String(group.id) === String(match.params.id)),
  loading: loading.groups,
  error: error.groups,
});

export default connect(mapStateToProps, { fetchGroup })(UserGroups);
