import truncate from 'lodash/truncate';

import Avatar from '@old/components/common/Avatar';
import BoxSmall from '@old/components/common/BoxSmall';
import Model from '@old/model';
import Link from '@old/components/common/Link';
import RowAligner from '@old/components/common/RowAligner';
import { LinkMember } from 'components/combinations';

const EventParticipationItem = ({ modelItem, content, children }) => {
  if (!modelItem) return null;

  let linkToModelItem;
  if (modelItem instanceof Model.Horse) {
    linkToModelItem = (
      <Link to={modelItem.getProfileUrl()} forceRender>
        {truncate(modelItem.getName(), { length: 45, omission: '...' })}
      </Link>
    );
  }

  if (modelItem instanceof Model.Member) {
    linkToModelItem = (
      <LinkMember forceRender member={modelItem}>
        {truncate(modelItem.getName(), { length: 45, omission: '...' })}
      </LinkMember>
    );
  }

  return (
    <div className="flex h-full w-full">
      <SkillIndicator color={modelItem.getSlugInfo().color} />
      <BoxSmall smallPadding>
        <RowAligner separated="small">
          <Avatar src={modelItem.getAvatar()} />
          <div className="flex-1">
            {linkToModelItem}
            {content && <div>{content}</div>}
          </div>
          <div>{children}</div>
        </RowAligner>
      </BoxSmall>
    </div>
  );
};

EventParticipationItem.defaultProps = {
  children: null,
  content: false,
  modelItem: '',
};

export default EventParticipationItem;

const SkillIndicator = ({ color }) => (
  <div className="skill-indicator">
    <div style={{ backgroundColor: color }} />
  </div>
);
