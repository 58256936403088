import styled from 'styled-components';
import { linkStyleProps } from 'config/styledProps';
import { ILinkStyledProps } from 'resources/types/styledTypes';

type TAnchor = ILinkStyledProps & {
  children: React.ReactNode;
  href: string;
  isExternal: boolean;
};

const A = styled('a')<ILinkStyledProps>(linkStyleProps);
A.defaultProps = { variant: 'primary', fontSize: 'base' };

const Anchor = ({ children, href, isExternal, ...aStyleProps }: TAnchor) => {
  const externalProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <A href={href} {...externalProps} {...aStyleProps}>
      {children}
    </A>
  );
};

Anchor.defaultProps = {
  variant: 'primary',
  fontSize: 'base',
  lineHeight: 1,
  isExternal: false,
};

export default Anchor;
