import moment from 'moment';

import config from '@old/config';
import t from 'resources/translations';

export const datesIsSame = (date, date2) => date.isSame(date2, 'day');

/**
 * A function that returns a string with date range in our custom format
 * @function
 * @memberof Utils
 * @param  {Moment}  startDate
 * @param  {Moment}  endDate
 * @param  {Boolean} [withDate=true] pass false to show only the time if the beginning and the end are on the same day
 * @return {String} date range string
 */
export const getDateRange = (startDate, endDate, withDate = true) => {
  const { dateTimeFormatWithComma, timeFormat } = config;

  if (!startDate || !endDate) {
    return '';
  }

  if (datesIsSame(startDate, endDate)) {
    return `${startDate.format(withDate ? dateTimeFormatWithComma : timeFormat)} - ${endDate.format(timeFormat)}`;
  }

  return `${startDate.format(dateTimeFormatWithComma)} - ${endDate.format(dateTimeFormatWithComma)}`;
};

/**
 * Returns the time difference between two dates in human readable format
 * @memberof Utils
 * @function
 * @see {@link https://momentjs.com/docs/#/durations/creating/|Creating moment duration}
 * @param  {Moment} startDate [description]
 * @param  {Moment} endDate   [description]
 * @return {String} duration as string like '2d 3h 5m'
 */
export const getDuration = (startDate, endDate) => {
  const duration = moment.duration(endDate.diff(startDate));
  return formatDuration(duration);
};

/**
 * Function that converts moment duration object to string like '2d 3h 5m'
 * @memberof Utils
 * @function
 * @see {@link https://momentjs.com/docs/#/durations/creating/|Creating moment duration}
 * @param  {Duration} duration moment duration
 * @return {String}
 */
export const formatDuration = duration => {
  const numberOfdays = Math.floor(duration.asDays());
  const numberOfHours = parseInt(moment.utc(duration.asMilliseconds()).format('H'), 10);
  const numberOfMinutes = parseInt(moment.utc(duration.asMilliseconds()).format('m'), 10);
  const days = numberOfdays !== 0 ? `${numberOfdays}d` : '';
  const hours = numberOfHours !== 0 ? `${numberOfHours}h` : '';
  const minutes = numberOfMinutes !== 0 ? `${numberOfMinutes}m` : '';

  const result = [days, hours, minutes].filter(Boolean).join(' ');

  return result || '0m';
};

/**
 * Function that converts number of minutes to string like '39h 5m'
 * @memberof Utils
 * @function
 * @see {@link formatDuration}
 * @param  {Number} timeInMinutes
 * @return {String}
 */
export const formatDurationWithoutDays = timeInMinutes => {
  const numberOfHours = Math.floor(timeInMinutes / 60);
  const numberOfMinutes = timeInMinutes % 60;

  const hours = numberOfHours !== 0 ? `${numberOfHours}h` : '';
  const minutes = numberOfMinutes !== 0 ? `${numberOfMinutes}m` : '';
  const result = [hours, minutes].filter(Boolean).join(' ');

  return result || '0m';
};

/**
 * Calculates the number of week lines to show on the calendar
 * @function
 * @memberof Utils
 * @param  {Number} daysInMonth number of days in month
 * @param  {Number} dayOfWeekInFirstMonthDay what day of the week is the first day of the month eg. monday - 1, tuesday - 2 etc.
 * @return {Number}
 */
export const getRowsInMonth = ({ daysInMonth, dayOfWeekInFirstMonthDay }) => {
  const sum = daysInMonth + dayOfWeekInFirstMonthDay;
  if (sum < 30) return 4;
  if (sum > 36) return 6;
  return 5;
};

/**
 * Function that sorts time strings like 'HH:mm'
 * @function
 * @memberof Utils
 * @example ['10:10', '11:09', '08:12'].sort(sortByTimeString);
 * @param  {String} timeStartA time in format HH:mm
 * @param  {String} timeStartB time in format HH:mm
 */
export const sortByTimeString = (timeStartA, timeStartB) => {
  const [hoursA, minutesA] = timeStartA.split(':');
  const [hoursB, minutesB] = timeStartB.split(':');

  if (hoursA < hoursB) return -1;
  if (hoursA > hoursB) return 1;
  if (minutesA < minutesB) return -1;
  if (minutesA > minutesB) return 1;
  return 0;
};

/**
 * Function that checks if the given date string is properly parsed by moment (and if moment can create valid object using strict parsing)
 * @function
 * @memberof Utils
 * @param  {String} dateString string with date
 * @param  {String} format expected date format
 * @return {Boolean}
 */
export const isCorrectFormat = (dateString, format) => {
  return moment(dateString, format, true).isValid();
};

/**
 * Helper function that creates unique key for calendar based on current pathname. Used to store current state for each calendar.
 * @function
 * @memberof Utils
 * @param  {String} pathname
 * @return {String}
 */
export const getCalendarKey = pathname => {
  return pathname.split('/').filter(Boolean).join('-') || 'home';
};

/**
 * Returns short key for weekday
 * @function
 * @memberof Utils
 * @param  {Moment} date
 * @return {String} one of: 'mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'
 */
export const getWeekDayFromDate = date => {
  return config.weekDays[date.isoWeekday() - 1];
};

/**
 * Function that converts miliseconds to string like '2 days 3 hours 5 minutes 60 seconds'
 * @memberof Utils
 * @function
 * @return {String}
 */
export const fortmatDateWithSeconds = date => {
  const numberOfdays = Math.floor(date / (1000 * 60 * 60 * 24));
  const numberOfHours = Math.floor((date / (1000 * 60 * 60)) % 24);
  const numberOfMinutes = Math.floor((date / 1000 / 60) % 60);
  const numberOfSecunds = Math.floor((date / 1000) % 60);

  const days = numberOfdays !== 0 ? `${numberOfdays} ${t('fortmatDateWithSeconds.days')}` : '';
  const hours = numberOfHours !== 0 ? `${numberOfHours} ${t('fortmatDateWithSeconds.hours')}` : '';
  const minutes = numberOfMinutes !== 0 ? `${numberOfMinutes} ${t('fortmatDateWithSeconds.minutes')}` : '';
  const secunds = `${numberOfSecunds} ${t('fortmatDateWithSeconds.seconds')}`;

  const result = [days, hours, minutes, secunds].filter(Boolean).join(' ');

  return result;
};
