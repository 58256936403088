import React from 'react';
import theme from 'config/theme';
import { List, ListItem, Box } from '../elements';
import { TReactNode } from 'resources/types/commonTypes';
import { TBoxProps } from 'resources/types/elementsTypes';
import { ISpace } from 'resources/types/styledTypes';

type TWrap = TBoxProps & {
  children: TReactNode[];
  spacing: keyof ISpace;
};

const Wrap = ({ children, spacing, ...boxProps }: TWrap) => {
  const spacingSize = theme.space[spacing] || spacing;
  if (children.length === 0) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <List display="flex" flexWrap="wrap" style={{ margin: `calc(-${spacingSize}` }} p={0}>
        {React.Children.map(
          children,
          (child, index) =>
            child && (
              <ListItem display="flex" maxW="100%" alignItems="flex-start" key={index} style={{ margin: spacingSize }}>
                {child}
              </ListItem>
            )
        )}
      </List>
    </Box>
  );
};

Wrap.defaultProps = {
  spacing: 'sm',
  children: [],
};

export default Wrap;
