/**
 * @module ModalSimple
 */
import { useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Measure from 'react-measure';
import cls from 'classnames';
import { RemoveScroll } from 'react-remove-scroll';
import { isMobile as isMobileDevices } from 'react-device-detect';
import { motion } from 'framer-motion';

import { useListener } from '@old/hooks';
import FlexColumn from '@old/components/common/FlexColumn';
import BoxShadow from '@old/components/common/BoxShadow';
import Center from '@old/components/common/Center';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Icon from '@old/components/icon';
import Button from '@old/components/guide/Button';
import config from '@old/config';
/**
 * Component that generates simpler modals(without shadow, no full width)
 * @param  {Node} props.children
 * @param  {Function} props.close function that closes the modal
 * @param  {Boolean} props.open specifies if modal is opened
 * @param  {Boolean} [props.small] specifies if modal should be smaller than standard one
 * @param  {Boolean} [props.basic] change style for body no-padding no-margin 500px width
 */
const ModalSimple = ({ children, close, open, small, basic }) => {
  const innerRef = useRef();
  const handler = useCallback(
    e => {
      if (!isMobileDevices && open && e.keyCode === config.keyCodes.esc) {
        close();
      }
    },
    [open, close]
  );

  useListener('keydown', handler, isMobileDevices ? document : window);

  useEffect(() => {
    return () => close();
  }, []); // eslint-disable-line

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <RemoveScroll enabled={open}>
      <Measure bounds>
        {({ measureRef: modalRef, _, contentRect }) => {
          const notCentered = contentRect.bounds.height >= window.innerHeight;
          return (
            <motion.div
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className={cls('modal-dimmer opacity-0', { open, basic, centered: !notCentered })}
            >
              <div className={cls('fixed left-0 top-0 h-full w-full', { hidden: !open })} onMouseUp={close} />
              {open && (
                <motion.div
                  ref={modalRef}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.1 }}
                  variants={{
                    visible: { y: 0 },
                    hidden: { y: 300 },
                  }}
                  className={cls('modal-simple', { small, basic })}
                >
                  <div ref={innerRef}>{children}</div>
                </motion.div>
              )}
            </motion.div>
          );
        }}
      </Measure>
    </RemoveScroll>,
    document.getElementById('hm-portal')
  );
};

ModalSimple.defaultProps = {
  small: false,
  basic: false,
};

/**
 * @module ModalSimpleHeader
 */
/**
 * Sets header in modal simple
 * @param  {String | Node} props.children
 */
const Header = ({ children }) => (
  <BoxShadow className="modal-simple-header text-left">
    <Center className="bg-teal rounded text-md-white py-1 px-2">{children}</Center>
  </BoxShadow>
);

/**
 * @module ModalSimpleBody
 */
/**
 * Sets body in modal simple
 * @param  {String | Node} props.children
 */
const Body = ({ children }) => (
  <div className="modal-simple-body">
    <FlexColumn separated="small">{children}</FlexColumn>
  </div>
);

/**
 * @module ModalSimpleAction
 */
/**
 * Sets buttons in modal simple
 * @param  {Function} props.close function that closes the modal
 * @param  {Function} props.onSubmit function that specifies what happens on submit button click
 * @param  {Boolean} [props.async] specifies wether the onClick function should be asynchronous
 */
const Action = ({ close, onSubmit, async }) => (
  <div className="modal-simple-action">
    <FlexRow noShrink>
      <ButtonOutline className="white-background" fluid onClick={close}>
        <Icon.Cancel className="fill-teal" />
      </ButtonOutline>
      <Button onClick={onSubmit} fluid async={async}>
        <Icon.Check />
      </Button>
    </FlexRow>
  </div>
);

Action.defaultProps = {
  async: false,
};

ModalSimple.Header = Header;
ModalSimple.Body = Body;
ModalSimple.Action = Action;

export default ModalSimple;
