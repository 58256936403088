import t from 'resources/translations';
import Event from 'models/Event';
import { Icons, Flex, Link, Stack, Text, Button, Box } from 'components/elements';
import { IconButton, AvatarWithLabel } from 'components/combinations';
import { useModal } from 'old/hooks';
import { usePermissions } from 'Permissions';
import { useMutation, useQueryClient } from 'react-query';
import { EventService } from 'services';
import { mRemoveEventFromEvents } from 'utils';
import { TCacheKey } from 'resources/types/cacheTypes';
import { useAppStore } from 'utils/storeUtils';

type TMyProposalBar = {
  proposal: Event;
  isExpanded: boolean;
  setExpanded: Function;
};

const MyProposalBar = ({ proposal, isExpanded, setExpanded }: TMyProposalBar) => {
  const { isMobile } = useAppStore();
  const queryClient = useQueryClient();
  const cacheKeys: TCacheKey[] = ['PROPOSALS'];
  const canIUpdate = usePermissions('PROPOSAL_UPDATE');
  const canIReject = usePermissions('PROPOSAL_REJECT');
  const canIApprove = usePermissions('PROPOSAL_APPROVE');
  const confirmModal = useModal('CONFIRM');

  const rejectProposal = async () => await EventService.rejectProposal(proposal.id);
  const approveProposal = async () =>
    await EventService.approveProposal(proposal.id, { event: Event.mapToSave(proposal) });

  const mutateRejectOnSuccess = () => mRemoveEventFromEvents(queryClient, cacheKeys, proposal.id);

  const rejectMutation = useMutation(rejectProposal, { onSuccess: mutateRejectOnSuccess });
  const approveMutation = useMutation(approveProposal, { onSuccess: mutateRejectOnSuccess });

  const onOpenConfirmReject = () =>
    confirmModal.onOpen({
      title: proposal.getName(),
      content: (
        <Box mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" p="xl">
          <Text>{t('rejectProposalModal.confirmRejectEvent', { title: proposal.getName() })}</Text>
        </Box>
      ),
      action: {
        mutation: rejectMutation,
        label: t('buttons.reject'),
        loadingLabel: t('message.rejection'),
      },
    });

  const actions = (
    <Stack isHorizontal={!isMobile} {...(isMobile ? { mb: 'md' } : {})}>
      {canIUpdate(proposal) && (
        <Link to={`${proposal.getUrl()}/edit`} allowed>
          <Stack spacing="sm" alignItems="center" isHorizontal>
            <Icons.Edit size={12} />
            <Text>{t('general.edit')}</Text>
          </Stack>
        </Link>
      )}
      {canIReject() && (
        <Button onClick={onOpenConfirmReject} variant="link">
          <Stack spacing="sm" alignItems="center" isHorizontal>
            <Icons.Cancel size={12} />
            <Text>{t('proposalEventsList.reject')}</Text>
          </Stack>
        </Button>
      )}
      {canIApprove() && proposal.isReadyToApprove() && (
        <Button variant="link" onClick={() => approveMutation.mutateAsync()} disabled={approveMutation.isLoading}>
          <Stack spacing="sm" alignItems="center" isHorizontal>
            <Icons.Accept size={12} />
            <Text>{t('buttons.accept')}</Text>
          </Stack>
        </Button>
      )}
    </Stack>
  );

  if (isMobile) {
    return (
      <>
        <Flex p="sm" alignItems="center">
          <Flex flex="none" alignSelf="flex-start">
            <IconButton m="sm" icon={isExpanded ? 'ArrowDown' : 'ArrowRight'} onClick={setExpanded} />
          </Flex>
          <Flex>
            <Stack w="full">
              <AvatarWithLabel
                src={proposal.creator.getAvatar('thumb')}
                alt={proposal.creator.getFullName()}
                title={proposal.creator.getFullName()}
                squareSize={30}
              >
                <Link to={proposal.creator.getProfileUrl()} fontSize="sm">
                  <Text>{proposal.creator.getFullName()}</Text>
                </Link>
                {/* @ts-ignore */}
                <Text fontSize="small">{t(`skills.${proposal.creator.getSkill()}`)}</Text>
              </AvatarWithLabel>
              <Flex alignItems="center">
                <Text fontSize="base">{proposal.getName()}</Text>
              </Flex>
              {!isExpanded && <Flex alignSelf="center">{actions}</Flex>}
            </Stack>
          </Flex>
        </Flex>
      </>
    );
  }

  return (
    <Flex p="sm" alignItems="center" flex="1">
      <IconButton icon={isExpanded ? 'ArrowDown' : 'ArrowRight'} onClick={setExpanded} />
      <Flex flex="4">
        <AvatarWithLabel
          ml="md"
          src={proposal.creator.getAvatar('thumb')}
          alt={proposal.creator.getFullName()}
          title={proposal.creator.getFullName()}
          squareSize={26}
        >
          <Link to={proposal.creator.getProfileUrl()} fontSize="sm">
            <Text>{proposal.creator.getFullName()}</Text>
          </Link>
          {/* @ts-ignore */}
          <Text fontSize="small">{t(`skills.${proposal.creator.getSkill()}`)}</Text>
        </AvatarWithLabel>
      </Flex>
      <Flex flex="6" alignItems="center">
        <Text>{proposal.getName()}</Text>
      </Flex>
      <Flex flex="none" pr="sm">
        {actions}
      </Flex>
    </Flex>
  );
};

MyProposalBar.defaultProps = {
  isExpanded: false,
  setExpanded: () => {},
};

export default MyProposalBar;
