import PlaceholderListItem from '@old/components/view/listItem/Placeholder';
import EventParticipationItem from '@old/components/view/listItem/EventParticipation';
import Allow from '@old/components/common/Allow';
import DropItem from '@old/components/common/DropItem';
import t from 'resources/translations';
import Icon from '@old/components/icon';

const PairHorse = ({ horse, event, participationId, setPair }) => {
  const placeholderNoHorse = (
    <PlaceholderListItem>
      <Icon.HorseAlone />
      <span>{t('placeholders.noAssignedHorse')}</span>
    </PlaceholderListItem>
  );
  const placeholderCanDrag = (
    <PlaceholderListItem>
      <Icon.Drag />
      <span>{t('placeholders.dragUnpairHorse')}</span>
    </PlaceholderListItem>
  );
  const renderHorseItem = placeholder => (horse ? <EventParticipationItem modelItem={horse} /> : placeholder);

  return (
    <Allow
      permissionKey={['participations.attach_horse']}
      fallback={renderHorseItem(placeholderNoHorse)}
      permissionParams={{ modelItem: event }}
      additionalCheck={!event?.isDeleted()}
    >
      <DropItem onDrop={draggedItem => setPair(participationId, draggedItem.horseId)}>
        {renderHorseItem(placeholderCanDrag)}
      </DropItem>
    </Allow>
  );
};

PairHorse.defaultProps = {
  horse: null,
  setPair: () => {},
};

export default PairHorse;
