import React from 'react';
import cls from 'classnames';
import { connect } from 'react-redux';

import { datesIsSame } from 'old/utils';
import Title from '@old/components/common/Title';
import FlexColumn from '@old/components/common/FlexColumn';
import TitleWhite from '@old/components/common/TitleWhite';
import config from '@old/config';
import { Text } from '@elements';

const EventDateSquare = ({ startDate, endDate, statusBarProps, isMobile, eventStatus, myEventsMobile }) => {
  const isProposal = eventStatus === 'proposed';
  const TitleComponent = isProposal ? Title : TitleWhite;
  const renderDate = (
    <div>
      {startDate.format(config.dateFormat)}
      {!datesIsSame(startDate, endDate) && (
        <span>
          {' '}
          /<div>{endDate.format(config.dateFormat)}</div>
        </span>
      )}
    </div>
  );

  if (isMobile && !myEventsMobile) {
    return (
      <React.Fragment>
        <Title>
          {startDate.format('HH:mm')} - {endDate.format('HH:mm')}
        </Title>
        <div>{renderDate}</div>
      </React.Fragment>
    );
  }

  if (myEventsMobile) {
    return (
      <div
        className={cls('relative p-2 self-center', isProposal ? 'proposal' : statusBarProps.color)}
        style={{ width: 135 }}
      >
        {eventStatus === 'cancelled' && (
          <div className="pointer-events-none bg-white-transparent left-0 top-0 z-10 absolute w-full h-full" />
        )}
        <FlexColumn>
          <div className="flex-1 text-center text-white">
            <Text color="white">
              {startDate.format('HH:mm')} - {endDate.format('HH:mm')}
            </Text>
            {renderDate}
          </div>
        </FlexColumn>
      </div>
    );
  }

  return (
    <div className={cls('event-date-square relative', isProposal ? 'proposal' : statusBarProps.color)}>
      {eventStatus === 'cancelled' && (
        <div className="pointer-events-none bg-white-transparent left-0 top-0 z-10 absolute w-full h-full" />
      )}
      <FlexColumn>
        <div className="flex-1">
          <TitleComponent>
            {startDate.format('HH:mm')} - {endDate.format('HH:mm')}
          </TitleComponent>
          {renderDate}
        </div>
        <div>{statusBarProps.status.toUpperCase()}</div>
      </FlexColumn>
    </div>
  );
};

EventDateSquare.defaultProps = {
  eventStatus: null,
  myEventsMobile: false,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });
export default connect(mapStateToProps)(EventDateSquare);
