/* eslint-disable max-len */
import React from 'react';

const Save = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M12.4,0H1.8C0.8,0,0,0.8,0,1.8v12.4c0,1,0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8V3.5C16,3.5,12.4,0,12.4,0z M8,14.2c-1.5,0-2.6-1.2-2.6-2.6S6.5,8.9,8,8.9s2.6,1.2,2.6,2.6S9.5,14.2,8,14.2z M10.6,5.3H1.8V1.8h8.9V5.3z" />
    </svg>
  );
};

export default Save;
/* eslint-enable max-len */
