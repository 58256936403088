import Measure from 'react-measure';

import Link from '@old/components/common/Link';
import ImageCircle from '@old/components/guide/ImageCircle';
import RowAligner from '@old/components/common/RowAligner';
import TagCircle from '@old/components/common/TagCircle';
import Tooltip from '@old/components/common/Tooltip';

const EventParticipationItemsGrid = ({ event, itemUrl }) => {
  const joinedParticipations = event.getJoinedParticipations();
  const items = itemUrl === '/users' ? joinedParticipations.map(item => item.member).filter(Boolean) : event.horses;

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Measure bounds>
      {({ measureRef: itemRef, _, contentRect }) => {
        const limitParticipants = Math.floor(contentRect.bounds.width / 50) - 2;

        const maxDisplayedParticipants = items.length === limitParticipants ? limitParticipants : limitParticipants - 1;
        const truncateParticipants = items.length > maxDisplayedParticipants;

        return (
          <div ref={itemRef}>
            <RowAligner separated="small">
              {items.slice(0, maxDisplayedParticipants).map((item, index) => {
                return (
                  <Tooltip key={index} content={item.getName()} position="bottom-start">
                    <Link to={`${itemUrl}/${item.id}`}>
                      <ImageCircle src={item.getAvatar('thumb')} />
                    </Link>
                  </Tooltip>
                );
              })}
              {truncateParticipants && (
                <TagCircle color="#fff" large>{`+${items.length - maxDisplayedParticipants}`}</TagCircle>
              )}
            </RowAligner>
          </div>
        );
      }}
    </Measure>
  );
};

export default EventParticipationItemsGrid;
