import Measure from 'react-measure';

import MemberLink from '@old/components/custom/MemberLink';
import ImageCircle from '@old/components/guide/ImageCircle';
import TagCircle from '@old/components/common/TagCircle';
import { Grid } from '@elements';
import Tooltip from '@old/components/common/Tooltip';

const GroupMembersGrid = ({ members }) => {
  const rows = 2;
  const widthParticipantItem = 70;
  const countMembers = members.length;

  return (
    <Measure bounds>
      {({ measureRef: itemRef, _, contentRect }) => {
        const numberOfColumns = Math.round(contentRect.bounds.width / widthParticipantItem);
        const numberOfItems = numberOfColumns * rows - 1;
        const hiddenMembers = countMembers - numberOfItems;
        const membersToShow = members.slice(0, numberOfItems);
        return (
          <div ref={itemRef}>
            <Grid gridTemplateColumns={`repeat(${numberOfColumns}, 1fr)`} gridGap="md">
              {membersToShow.map((member, index) => (
                <Tooltip key={index} content={member.getName()}>
                  <MemberLink forceRender member={member}>
                    <ImageCircle src={member.getAvatar()} />
                  </MemberLink>
                </Tooltip>
              ))}
              {hiddenMembers > 0 && <TagCircle color="#fff" large>{`+${hiddenMembers}`}</TagCircle>}
            </Grid>
          </div>
        );
      }}
    </Measure>
  );
};

export default GroupMembersGrid;
