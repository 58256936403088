import Measure from 'react-measure';

import { Box, Grid } from '@elements';
import UserGridItem from '@old/components/view/gridItem/User';
import t from 'resources/translations';
import Message from '@old/components/common/Message';
import FlexColumn from '@old/components/common/FlexColumn';
import { countGridColumns } from 'old/utils';

const UsersGrid = ({ data: members, pending }) => {
  return (
    <FlexColumn>
      <Measure bounds>
        {({ measureRef: itemRef, _, contentRect }) => {
          const columns = countGridColumns(contentRect.bounds.width);
          return (
            <div ref={itemRef}>
              <FlexColumn>
                {pending && <Message info>{t('message.acceptingAccountNotify')}</Message>}
                <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap="md">
                  {members.map(member => {
                    return (
                      <Box key={member.id} minW={0}>
                        <UserGridItem member={member} />
                      </Box>
                    );
                  })}
                </Grid>
              </FlexColumn>
            </div>
          );
        }}
      </Measure>
    </FlexColumn>
  );
};

UsersGrid.defaultProps = {
  data: [],
  pending: false,
};

export default UsersGrid;
