import { matchPath } from 'react-router';

import store from 'store';
import { routes } from 'App';
import { Member } from 'old/model/Member';

/**
 * An additional function that checks permissions for the user's role in the application
 * @function
 * @memberof Utils
 */
const additionalCheck = {
  /**
   * Function that checks permissions for the owner role
   * @function
   * @param  {String} permissionKey
   * @param  {*} params additional options like model item or model item id
   * @return {Boolean}
   */
  isOwn: (permissionKey, params) => {
    const {
      auth: { profile },
    } = store.getState();
    const [permissionCategory] = permissionKey.split('.');
    if (['memberships', 'membership_statistics', 'work_shifts', 'comments', 'posts'].includes(permissionCategory)) {
      return params.id == profile.membership.id; // eslint-disable-line
    } else {
      return false; // not supported yet
    }
  },
  /**
   * Function that checks permissions for the instructor role
   * @function
   * @param  {String} permissionKey
   * @param  {*} params additional options like model item or model item id
   * @return {Boolean}
   */
  is_instructor: (permissionKey, params) => {
    const {
      auth: { profile },
    } = store.getState();
    const { modelItem } = params;
    const [permissionCategory] = permissionKey.split('.');
    if (['events', 'proposals', 'participations'].includes(permissionCategory)) {
      const instructorsIds = modelItem && modelItem.getInstructorsIds ? modelItem.getInstructorsIds() : [];
      return instructorsIds.includes(profile.membership.id);
    } else {
      return false; // not supported yet
    }
  },
  /**
   * Function that checks permissions if we are a creator, e.g. news
   * @function
   * @param  {String} permissionKey
   * @param  {*} params additional options like model item or model item id
   * @return {Boolean}
   */
  is_creator: (permissionKey, params) => {
    const {
      auth: { profile },
    } = store.getState();
    const { modelItem } = params;
    const [permissionCategory] = permissionKey.split('.');
    if (['events', 'proposals', 'participations'].includes(permissionCategory)) {
      return modelItem && modelItem.getCreatorId && profile.membership.id == modelItem.getCreatorId(); // eslint-disable-line
    } else {
      return false; // not supported yet
    }
  },
  /**
   * The function checks if I have the rights to manage the event
   * @function
   * @param  {String} permissionKey
   * @param  {*} params additional options like model item or model item id
   * @return {Boolean}
   */
  is_event_manager: (permissionKey, params) => {
    const {
      auth: { profile },
    } = store.getState();
    const [permissionCategory] = permissionKey.split('.');
    if (['events', 'proposals'].includes(permissionCategory)) {
      const { modelItem } = params;
      if (!modelItem) {
        return false;
      }
      const instructorsIds = modelItem.getInstructorsIds ? modelItem.getInstructorsIds() : [];
      return [...instructorsIds, modelItem.creator && modelItem.creator.id].includes(profile.membership.id);
    } else {
      return false;
    }
  },
};

/**
 * Checks whether the logged in user has permission to the given permission key and, if necessary, checks additional conditions like "is creator" etc.
 * @function
 * @memberof Utils
 * @param  {String} permissionKey
 * @param  {*} params additional options like model item or model item id
 * @param  {Boolean} shallow forces shallow checking (assuming that all additional conditions are met)
 * @return {Boolean}
 */
export const isAllowed = (permissionKey, params = {}, shallow = false) => {
  if (permissionKey === 'alwaysAllowed') {
    return true;
  }

  const {
    auth: { profile },
  } = store.getState();

  const member = new Member(profile, { fromUserData: true });
  if (!member || !member.isActive()) {
    return false;
  }

  return (typeof permissionKey === 'string' ? [permissionKey] : permissionKey).some(singlePermissionKey => {
    if (!member.permissions[singlePermissionKey]) {
      return false;
    }
    const { allowedIf, allowed } = member.permissions[singlePermissionKey];
    if (shallow) {
      return allowed || allowedIf;
    } else {
      return (
        allowed ||
        (allowedIf &&
          allowedIf.some(key => {
            return additionalCheck[key] && additionalCheck[key](singlePermissionKey, params || {});
          }))
      );
    }
  });
};

/**
 * Function that check if the given path is allowed for a logged in user based on his permissions.
 * @function
 * @memberof Utils
 * @param  {String} path destination path
 * @return {Boolean}
 */
export const pathIsAllowed = path => {
  if (!path) return false;
  const pathname = typeof path === 'string' ? path : path.pathname;

  const matchedRoute = routes.find(route => {
    const result = matchPath(pathname, { path: route.path, exact: route.exact });

    return result;
  });

  if (matchedRoute) {
    const { params } = matchPath(pathname, matchedRoute);
    const permissionKey = matchedRoute.getPermissionKey(params);
    return isAllowed(permissionKey, params, true /* shallow */);
  }

  return false;
};

/**
 * Function that maps model name from path to permission category key used by API
 * @function
 * @memberof Utils
 * @param  {String} name model name used in paths
 * @return {String} model name used by API
 */
export const getPermissionCategoryByName = name => {
  switch (name) {
    case 'event-types':
      return 'event_types';
    case 'users':
      return 'memberships';
    case 'members':
      return 'memberships';
    default:
      return name;
  }
};

/**
 * Function that check if the given path is allowed for a logged in user based on his permissions.
 * @function
 * @memberof Utils
 * @param  {String} path destination path
 * @return {Boolean}
 */

export const getRoutePermission = path => {
  if (!path) return false;
  const pathname = typeof path === 'string' ? path : path.pathname;

  const matchedRoute = routes.find(route => matchPath(pathname, route));
  if (matchedRoute) {
    const { params } = matchPath(pathname, matchedRoute);
    const permissionKey = matchedRoute.getPermissionKey(params);
    const {
      auth: { profile },
    } = store.getState();

    const member = new Member(profile, { fromUserData: true });
    if (!member || !member.isActive()) {
      return false;
    }

    // [API-3.0] [Start] look at this after API 3.0 is released
    return (typeof permissionKey === 'string' ? [permissionKey] : permissionKey).some(singlePermissionKey => {
      if (!member.permissions[singlePermissionKey]) {
        return false;
      }
      const { allowed } = member.permissions[singlePermissionKey];
      return allowed;
    });
    // [API-3.0] [End] look at this after API 3.0 is released
  }
  return false;
};
