import moment from 'moment';

import BaseModel from '@old/model/BaseModel';
import { Member } from '@old/model/Member';

class Notifications extends BaseModel {
  constructor(options) {
    super({
      modelName: 'notifications',
      updateKey: 'notification',
      basePath: '/api/v1/notifications',
      ItemClass: Notification,
      ...options,
    });
  }

  open = async notificationId => this.client.put(`${this.basePath}/${notificationId}/open`);

  openAll = async () => this.client.post(`${this.basePath}/open_all`);
}

class Notification {
  constructor(data) {
    this.notifier = data.notifier ? new Member(data.notifier, { fromUserData: true }) : null;
    this.id = data.id;
    this.key = data.key;
    this.createdAt = moment(data.created_at);
    this.farm = this.getFarm(data.notifiable);
    this.notifiable = data.notifiable ? this.getNotifable(data.notifiable, data.notifiable_type) : null;
    this.notifiableType = data.notifiable_type;
    this.openedAt = data.opened_at ? moment(data.opened_at) : null;
    this.parameters = data.parameters;
  }

  update = async changes => this.model.update(this.id, changes);

  getFarm(notifiable) {
    if (!notifiable) return null;
    if (!notifiable.farm) return null;
    return ({
      id: notifiable.farm.id || 0,
      name: notifiable.farm.name || '',
      slug: notifiable.farm.slug || '',
    });
  }

  getNotifable(notifiable, type) {
    switch (type) {
      case 'NewsItem':
        return ({
          id: notifiable.id,
          title: notifiable.title,
          content: notifiable.content,
          urlToRedirect: `/news/${notifiable.id}`,
        });
      case 'Participation':
        return ({
          id: notifiable.id,
          title: notifiable.event.name,
          content: null,
          urlToRedirect: `/events/${notifiable.event.id}`,
          member: `${notifiable.membership.identity.firstname} ${notifiable.membership.identity.lastname}`,
        });
      case 'Post':
        return ({
          id: notifiable.id,
          title: notifiable.postable.name,
          content: notifiable.content,
          urlToRedirect: `/events/${notifiable.postable.id}`,
        });
      case 'Comment':
        return ({
          id: notifiable.id,
          title: notifiable.commentable.content,
          content: notifiable.content,
          urlToRedirect: `/events/${notifiable.commentable.postable_id}`,
        });
      case 'Event':
        return ({
          id: notifiable.id,
          title: notifiable.name,
          content: null,
          urlToRedirect: `/events/${notifiable.id}`,
          proposalStatus: notifiable.status ?? '',
        });
      default:
        return ({
          id: notifiable.id,
          title: notifiable.commentable.content,
          content: notifiable.content,
          urlToRedirect: `/events/${notifiable.commentable.postable_id}`,
        });
    }
  }
}

export { Notifications, Notification };
