import { OPEN_MODAL, CLOSE_MODAL, REFRESH_MODAL_OPTIONS } from 'store/actions/modalsActions';

const initialState = [];

const modalsReducer = (state = initialState, action) => {
  const { name, payload } = action;
  switch (action.type) {
    case OPEN_MODAL:
      if (state.find(modal => modal.name === name)) {
        return state;
      }
      return [...state, { name, payload }];
    case CLOSE_MODAL:
      return [...state.filter(modal => modal.name !== name)];
    case REFRESH_MODAL_OPTIONS:
      const modalIndex = state.findIndex(modal => modal.name === name);
      if (modalIndex === -1) {
        return state;
      }
      const newModals = [...state];
      newModals.splice(modalIndex, 1, { ...state[modalIndex], payload: { ...state[modalIndex].payload, ...payload } });
      return newModals;
    default:
      return state;
  }
};

export default modalsReducer;
