import { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import cls from 'classnames';
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { isIOS } from 'react-device-detect';
import { useQuery } from 'react-query';
import { CancelToken } from 'axios';

import EventsList from '@old/components/view/list/Event';
import { CalendarDataContext, useActions } from '@old/hooks';
import Button from '@old/components/guide/Button';
import DateSwitch from '@old/components/view/calendar/CalendarDateSwitch';
import Month from '@old/components/view/calendar/CalendarMonth';
import Week from '@old/components/view/calendar/CalendarWeek';
import WeekExtended from '@old/components/view/calendar/CalendarWeekExtended';
import { getCalendarKey } from 'old/utils';
import FlexColumn from '@old/components/common/FlexColumn';
import t from 'resources/translations';
import Model from '@old/model';
import { setLoadingEvent } from 'store/actions';

const moment = extendMoment(Moment);

const Calendar = ({
  dayRange,
  dayStart,
  calendarRange,
  weekRange,
  displayedRange,
  fetchParams,
  defaultMode,
  onlyDefaultMode,
  hideWeekSelect,
  list,
  switchModeOnClick,
  extended,
  isMobile,
  isTablet,
  addEventButton,
  addProposalButton,
}) => {
  const setLoadingEventAction = useActions(setLoadingEvent, []);
  const queryFn = ({ start, end }) => {
    const source = CancelToken.source();
    const data = Model.Events.fetchAll({
      per_page: 9999,
      sorted_by: 'start_at_asc',
      'in_interval[start]': start.toDate(),
      'in_interval[end]': end.toDate(),
      ...fetchParams,
      cancelToken: source.token,
    });
    data.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };
    return data;
  };
  let range = dayRange;
  if (displayedRange === 'week') {
    range = weekRange;
  } else if (displayedRange === 'calendar') {
    range = calendarRange;
  }
  const result = useQuery(['events', { ...range, ...fetchParams }], () => queryFn(range), {
    onSuccess: () => {
      setLoadingEventAction(false);
    },
  });
  const events = result.isSuccess ? result.data[0] : [];

  const showButtonFinishedEvents = events.filter(event => event.endDate < moment()).length > 0;
  const [showFinishedEvents, setShowFinishedEvents] = useState(false);
  const [mode, setMode] = useState(defaultMode);

  useEffect(() => {
    // wee need this to close popup on iOS
    // https://github.com/kentor/react-click-outside#not-working-on-ios
    if (isIOS) {
      document.body.style.cursor = 'pointer';
    }
  }, []);

  const WeekComponent = extended ? WeekExtended : Week;

  const filterFromNow = event => {
    return event.endDate > moment();
  };

  const currentDayIsSelected = dayStart.isSame(moment(), 'day');
  let eventsFilteredBySelectedDay = events;

  if (currentDayIsSelected && !showFinishedEvents) {
    eventsFilteredBySelectedDay = events.filter(filterFromNow);
  }

  return (
    <CalendarDataContext.Provider value={(events || []).filter(event => !event.isCancelled)}>
      <div>
        <FlexColumn>
          <DateSwitch
            mode={mode}
            setMode={setMode}
            onlyDefaultMode={onlyDefaultMode}
            isMobile={isMobile}
            isTablet={isTablet}
          />
          <FlexColumn>
            {mode === 'month' && (
              <Month
                setMode={setMode}
                hideWeekSelect={hideWeekSelect}
                switchModeOnClick={switchModeOnClick}
                fetchParams={fetchParams}
              />
            )}
            {mode === 'week' && (
              <WeekComponent
                setMode={setMode}
                onlyDefaultMode={onlyDefaultMode}
                addEventButton={addEventButton}
                addProposalButton={addProposalButton}
              />
            )}
            {currentDayIsSelected && showButtonFinishedEvents && list && (
              <Button
                className={cls('inverted-teal', { outline: !showFinishedEvents })}
                onClick={() => setShowFinishedEvents(!showFinishedEvents)}
              >
                {!showFinishedEvents ? t('buttons.showFinished') : t('buttons.hideFinished')}
              </Button>
            )}
            {list && (result.data?.length || result.isLoading) && (
              <EventsList
                fetchedEvents={eventsFilteredBySelectedDay}
                newCache
                fetchParams={{ ...range, ...fetchParams }}
              />
            )}
          </FlexColumn>
        </FlexColumn>
      </div>
    </CalendarDataContext.Provider>
  );
};

Calendar.defaultProps = {
  fetchParams: {},
  defaultMode: 'week',
  onlyDefaultMode: false,
  hideWeekSelect: false,
  list: false,
  switchModeOnClick: true,
  extended: false,
  addEventButton: {},
  addProposalButton: {},
  displayedRange: '',
};

const mapStateToProps = ({ calendar, app: { isMobile, isTablet } }, props) => {
  const calendarKey = getCalendarKey(props.location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;
  return {
    calendarRange: calendarState.calendarRange,
    weekRange: calendarState.weekRange,
    dayRange: calendarState.dayRange,
    dayStart: calendarState.dayStart,
    isMobile,
    isTablet,
  };
};

export default withRouter(connect(mapStateToProps)(Calendar));
