import { useState } from 'react';
import moment from 'moment';
import { useShallowSelector, useKey } from '@old/hooks';
import Modal from '@combinations/modals/Modal';
import ModalHeader from '@combinations/modals/ModalHeader';
import ModalCloseButton from '@combinations/modals/ModalCloseButton';
import ModalContent from '@combinations/modals/ModalContent';
import { Box, Button, Flex, Image, Label, MotionBox, Stack, Text, Icons, TextTruncate } from '@elements';
import { wrappedModalPropTypes } from '@old/components/modals';
import { getErrorMessage, notify, parseToCurrencyString } from 'old/utils';
import Select from '@combinations/inputs/Select';
import AmountInput from '@combinations/inputs/Amount';
import { Divider } from '@combinations';
import config from '@old/config';
import Model from '@old/model';
import t from 'resources/translations';
import { BillService } from 'services';
import { useMutation } from 'react-query';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import { Bill } from '@old/model/Bill';
import { ModalButtons } from 'components/combinations';

const styleProps = {
  avatar: {
    width: 128,
    height: 128,
    p: 6,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'teal-dark',
    borderRadius: 9999,
  },
};

const PaymentsModal = ({ name, onUpdate, onSubmitAndClose, onClose, isSubmitting, isHidden }) => {
  const { bill, callback } = useShallowSelector(({ modal }) => modal[name]?.props || {});
  const [loggedMember, farm] = useShallowSelector(({ app }) => [app.member, app.farm]);
  const [paymentType, setPaymentType] = useState(config.paymentStatusCompact[0]);
  const [amount, setAmount] = useState(bill.getDue());
  const [isChanged, setIsChanged] = useState(false);
  const onAddPaymentMutation = useMutation(() =>
    BillService.addPayment(bill.eventId, bill.billableId, {
      participation: {
        payment_status: paymentType.value,
        paid_at: new Date().toISOString(),
        paid_amount: bill.getDue(),
      },
    })
  );

  const onChangeDueMutation = useMutation(
    async () => {
      const parsedAmount = parseFloat(String(amount).replace(',', '.'), 2);
      return await BillService.update(bill.id, { amount: parsedAmount });
    },
    {
      onSuccess: billRes => {
        onUpdate({ bill: new Bill(billRes) });
      },
    }
  );

  const onSubmit = async () => {
    try {
      await onAddPaymentMutation.mutateAsync();
      callback && callback();
      notify(t('message.succesSave'));
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };

  const onChangeDueAmount = async () => {
    try {
      await onChangeDueMutation.mutateAsync();
      setIsChanged(true);
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };

  const remainingAmount = bill.getDue() - bill.getPaidAmount();
  const totalAmount = remainingAmount > 0 ? remainingAmount : 0;

  const onRedirectToBM = async () => {
    const currentTransaction = bill.getCurrentTransaction();
    if (currentTransaction) {
      window.location.href = currentTransaction.getRedirect();
    } else {
      const { redirect_url } = await Model.Bills.onlinePayment(bill.id);
      window.location.href = redirect_url;
    }
  };

  const onEnter = useKey(() => onChangeDueAmount(), 'enter');

  const checkIsAmountsSame = (amount1, amount2) => {
    const parsedAmount1 = parseFloat(String(amount1).replace(',', '.'), 2);
    const parsedAmount2 = parseFloat(String(amount2).replace(',', '.'), 2);
    return parsedAmount1 === parsedAmount2;
  };

  const isSameAmounts = checkIsAmountsSame(amount, bill.getDue());
  const showSaveChangesLabel = !onChangeDueMutation.isLoading && !isSameAmounts;
  const showSavedLabel = !onChangeDueMutation.isLoading && isSameAmounts && isChanged;

  if (loggedMember.isClient()) {
    return (
      <Modal isHidden={isHidden} onClose={onClose} isOpen>
        <ModalHeader>{t('bills.payForEvent')}</ModalHeader>
        <ModalCloseButton onClose={onClose} />
        <ModalContent isMakingRequest={isSubmitting}>
          <Flex justifyContent="center">
            <Stack alignItems="center">
              <Image src={farm.getAvatar()} alt={farm.getName()} {...styleProps.avatar} />
              <Text>{farm.getName()}</Text>
            </Stack>
          </Flex>
          <Box mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" px="xl" py="md">
            <Stack spacing="sm">
              <Text fontWeight="bold">{t('bills.event')}:</Text>
              <Text>{moment(bill.eventStartAt).format('DD.MM.YYYY, dddd')}</Text>
              <TextTruncate lines={2}>{bill.eventName}</TextTruncate>
            </Stack>
          </Box>
          <Stack spacing="sm" w="full">
            <Text fontWeight="medium">{t('bills.paymentMethod')}</Text>
            <Text fontSize="sm">{t('bills.onlinePayment')}</Text>
          </Stack>
          <Flex alignItems="flex-end" pt="base" pb="sm" justifyContent="space-between">
            <Text fontSize="md" fontWeight="bold">
              {t('bills.toPay')}:
            </Text>
            <Text fontSize="lg" color={totalAmount > 0 ? 'orange' : 'grey-dark'} fontWeight="bold">
              {parseToCurrencyString(totalAmount)}
            </Text>
          </Flex>
          <Button onClick={() => onSubmitAndClose(onRedirectToBM)} mt="xl">
            {isSubmitting ? <SpinnerDots label={t('bills.redirectInProgress')} inline /> : t('bills.goToPayments')}
          </Button>
          <Button onClick={onClose} variant="outline">
            {t('general.cancel')}
          </Button>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isHidden={isHidden} onClose={onClose} onSubmit={onSubmit} isBlocked={onChangeDueMutation.isLoading} isOpen>
      <ModalHeader>{t('bills.customerPayment')}</ModalHeader>
      <ModalCloseButton onClose={onClose} disabled={onChangeDueMutation.isLoading} />
      <ModalContent isMakingRequest={isSubmitting}>
        <Flex justifyContent="center">
          <Stack alignItems="center">
            <Image src={bill.billed.getAvatar()} alt={bill.billed.getFullName()} {...styleProps.avatar} />
            <Text>{bill.billed.getFullName()}</Text>
          </Stack>
        </Flex>
        <Box mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" px="xl" py="md">
          <Stack spacing="sm">
            <Text fontWeight="bold">{t('bills.event')}:</Text>
            <Text>{moment(bill.eventStartAt).format('DD.MM.YYYY, dddd')}</Text>
            <TextTruncate lines={2}>{bill.eventName}</TextTruncate>
          </Stack>
        </Box>
        <Stack mb="lg">
          <Stack spacing="xs">
            <Label htmlFor="dueAmount">{t('labels.due')}:</Label>
            <AmountInput
              name="dueAmount"
              intlConfig={{ locale: 'pl', currency: 'PLN' }}
              groupSeparator=" "
              maxLength={11}
              onBlur={() => setAmount(prevAmount => prevAmount || 0)}
              value={amount}
              onKeyUp={onEnter}
              onValueChange={value => {
                setIsChanged(false);
                setAmount(value);
              }}
              allowNegativeValue={false}
              decimalScale={2}
            />
            <Box>
              {onChangeDueMutation.isLoading && (
                <Stack spacing="xs" mt="xs" alignItems="center" color="teal-dark" isHorizontal>
                  <MotionBox
                    width="auto"
                    transition={{ ease: 'linear', duration: 2, repeat: Infinity }}
                    animate={{ rotate: 360 }}
                  >
                    <Icons.Spinner className="rotate" />
                  </MotionBox>
                  <Text fontSize="sm">{t('message.saving')}...</Text>
                </Stack>
              )}
              {showSavedLabel && (
                <Stack spacing="xs" mt="xs" alignItems="center" color="teal-dark" isHorizontal>
                  <Icons.Accept size={10} />
                  <Text fontSize="sm">{t('message.dueAmountChanged')}</Text>
                </Stack>
              )}
              {showSaveChangesLabel && (
                <Button variant="link" mt="xs" w="auto" onClick={onChangeDueAmount}>
                  <Stack spacing="xs" alignItems="center" isHorizontal>
                    <Icons.Accept borderColor="currentColor" borderWidth={1} borderRadius={9999} p={2} />
                    <Text fontSize="sm">{t('buttons.saveChanges')}</Text>
                  </Stack>
                </Button>
              )}
            </Box>
          </Stack>
          <Divider variant="dashed" color="grey-light" />
          <Stack spacing="xs">
            <Label htmlFor="paymentType">{t('bills.paymentMethod')}</Label>
            <Box>
              <Select
                name="paymentType"
                value={paymentType}
                options={config.paymentStatusCompact}
                onChange={setPaymentType}
                isSearchable={false}
              />
            </Box>
          </Stack>
        </Stack>
      </ModalContent>
      <ModalButtons>
        <Button onClick={() => onSubmitAndClose(onSubmit)} disabled={onChangeDueMutation.isLoading}>
          {isSubmitting ? <SpinnerDots label={t('bills.addingPayment')} inline /> : t('bills.addPayment')}
        </Button>
        <Button onClick={onClose} variant="outline">
          {t('general.cancel')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

PaymentsModal.propTypes = wrappedModalPropTypes;

export default PaymentsModal;
