import t from 'resources/translations';
import Icon from '@old/components/icon';
import { Icons } from '@elements';

export const menuItemsSidebar = [
  [t('menu.dashboard'), '/', 0, <Icons.Dashboard key="timetable" size={18} />],
  [t('menu.timetable'), '/timetable', 1, <Icon.Schedule key="timetable" small />],
  [t('menu.news'), '/news', 2, <Icon.News key="news" small />],
  [t('menu.events'), '/events', 3, <Icon.Events key="events" small />],
  [t('menu.eventsProposals'), '/proposals', 4, <Icon.Proposals key="eventsProposals" small />],
  [t('menu.users'), '/users', 5, <Icon.User key="users" small />],
  [t('menu.horses'), '/horses', 6, <Icon.Horse key="horses" small />],
  [t('menu.farmData'), '/farm', 7, <Icon.Farm key="farm" small />],
  [t('menu.farmInvitations'), '/farm/invitations', 8, <Icon.Invite key="invitations" small />],
  [t('menu.sms'), '/sms', 9, <Icon.Sms key="sms" small />],
  [t('menu.tickets'), '/tickets', 10, <Icons.Ticket key="tickets" size={18} />],
];

export const menuItemsNavbar = [
  [t('menu.dashboard'), '/', 0, <Icons.Dashboard key="timetable" size={26} />],
  [t('menu.timetable'), '/timetable', 1, <Icon.Schedule key="timetable" />],
  [t('menu.news'), '/news', 2, <Icon.News key="news" />],
  [t('menu.events'), '/events', 3, <Icon.Events key="events" />],
  [t('menu.eventsProposals'), '/proposals', 4, <Icon.Proposals key="eventsProposals" />],
  [t('menu.users'), '/users', 5, <Icon.User key="users" />],
  [t('menu.horses'), '/horses', 6, <Icon.Horse key="horses" />],
  [t('menu.farmData'), '/farm', 7, <Icon.Farm key="farm" />],
  [t('menu.farmInvitations'), '/farm/invitations', 8, <Icon.Invite key="invitations" />],
  [t('menu.sms'), '/sms', 9, <Icon.Sms key="sms" />],
  [t('menu.tickets'), '/tickets', 10, <Icons.Ticket key="tickets" size={26} />],
];
