/* eslint-disable max-len */
import React from 'react';
import { Svg } from '../elements';
import styled from 'styled-components';
import { border as b, BorderProps, color, ColorProps, layout, space, SpaceProps, LayoutProps } from 'styled-system';
import { TIconCircleProps, TIconProps } from 'resources/types/elementsTypes';

const Plus = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 16 16">
    <path d="M6.4,0v6.4H0v3.2h6.4V16h3.2V9.6H16V6.4H9.6V0C9.6,0,6.4,0,6.4,0z" />
  </Svg>
);

const Minus = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <rect y="10.5" width="26" height="5" />
  </Svg>
);

const Accept = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M4.55,11.39,9,16a.91.91,0,0,0,1.18,0L21.69,4.24a2.55,2.55,0,0,1,3.58,0h0a2.51,2.51,0,0,1,.14,3.36L11.94,21.29c-.9.9-2.62,2.1-4.49.2S2.66,16.56,1,14.83c-.62-.63-1.6-2.25-.34-3.58C1.27,10.55,2.68,9.5,4.55,11.39Z"
      transform="translate(0)"
    />
  </Svg>
);

const ArrowDown = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M13,20.5A1.81,1.81,0,0,1,11.71,20L.53,8.65A1.87,1.87,0,0,1,.53,6,1.81,1.81,0,0,1,3.11,6l9.89,10L22.89,6a1.81,1.81,0,0,1,2.58,0,1.87,1.87,0,0,1,0,2.61L14.29,20A1.81,1.81,0,0,1,13,20.5Z"
      transform="translate(0)"
    />
  </Svg>
);

const ArrowRight = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M20.5,13a1.82,1.82,0,0,1-.5,1.29L8.68,25.45a1.87,1.87,0,0,1-2.64,0H6a1.81,1.81,0,0,1,0-2.56l0,0L16,13,6,3.11A1.81,1.81,0,0,1,6,.55l0,0a1.85,1.85,0,0,1,2.6,0L20,11.7A1.82,1.82,0,0,1,20.5,13Z"
      transform="translate(0 0)"
    />
  </Svg>
);

const ArrowUp = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M13,5.5A1.81,1.81,0,0,1,14.29,6L25.47,17.35a1.87,1.87,0,0,1,0,2.61,1.81,1.81,0,0,1-2.58,0L13,10,3.11,20A1.81,1.81,0,0,1,.53,20a1.87,1.87,0,0,1,0-2.61L11.71,6A1.81,1.81,0,0,1,13,5.5Z" />
  </Svg>
);

const BellTwo = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M15.56,23.4a2.63,2.63,0,0,1-.75,1.84,2.54,2.54,0,0,1-3.62,0,2.63,2.63,0,0,1-.75-1.84ZM13,0a1.27,1.27,0,0,1,.9.38,1.32,1.32,0,0,1,.38.92V2.7a7.78,7.78,0,0,1,6.39,7.7v7.8l3.83,3.9H1.5l3.83-3.9V10.4a7.78,7.78,0,0,1,6.39-7.7V1.3A1.32,1.32,0,0,1,12.1.38,1.27,1.27,0,0,1,13,0Z" />
  </Svg>
);

const Cancel = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M25.25,25.25a2.66,2.66,0,0,1-3.7,0L13,16.69,4.45,25.25a2.66,2.66,0,0,1-3.7,0,2.66,2.66,0,0,1,0-3.7L9.31,13,.75,4.45a2.66,2.66,0,0,1,0-3.7,2.66,2.66,0,0,1,3.7,0L13,9.31,21.55.75a2.66,2.66,0,0,1,3.7,0,2.66,2.66,0,0,1,0,3.7L16.69,13l8.56,8.55A2.66,2.66,0,0,1,25.25,25.25Z"
      transform="translate(0 0)"
    />
  </Svg>
);

const Close = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M16.51,13l8.76-8.77a2.47,2.47,0,0,0-3.5-3.5L13,9.49,4.23.73a2.47,2.47,0,0,0-3.5,3.5L9.49,13,.73,21.77a2.47,2.47,0,1,0,3.5,3.5L13,16.51l8.77,8.76a2.47,2.47,0,0,0,3.5-3.5Z"
      transform="translate(0 0)"
    />
  </Svg>
);

const Dashboard = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M10,0H1.9A1.9,1.9,0,0,0,0,1.9V6.77a1.9,1.9,0,0,0,1.9,1.9H10a1.9,1.9,0,0,0,1.9-1.9V1.9A1.9,1.9,0,0,0,10,0Z"
      transform="translate(0)"
    />
    <path
      d="M10,10.83H1.9A1.9,1.9,0,0,0,0,12.73V24.1A1.9,1.9,0,0,0,1.9,26H10a1.9,1.9,0,0,0,1.9-1.9V12.73A1.9,1.9,0,0,0,10,10.83Z"
      transform="translate(0)"
    />
    <path
      d="M24.1,17.33H16a1.9,1.9,0,0,0-1.9,1.9V24.1A1.9,1.9,0,0,0,16,26H24.1A1.9,1.9,0,0,0,26,24.1V19.23A1.9,1.9,0,0,0,24.1,17.33Z"
      transform="translate(0)"
    />
    <path
      d="M24.1,0H16a1.9,1.9,0,0,0-1.9,1.9V13.27a1.9,1.9,0,0,0,1.9,1.9H24.1a1.9,1.9,0,0,0,1.9-1.9V1.9A1.9,1.9,0,0,0,24.1,0Z"
      transform="translate(0)"
    />
  </Svg>
);

const Edit = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M25.6,5.9c0.5-0.8,0.5-1.6,0-2.1l-3.4-3.4c-0.5-0.5-1.3-0.5-2.1,0L17.5,3L23,8.5L25.6,5.9z M0,20.8v4.4C0,25.7,0.3,26,0.8,26h4.4c0.3,0,0.3,0,0.5-0.3l15.7-15.7l-5.5-5.5L0.5,20.2C0.3,20.4,0,20.5,0,20.8L0,20.8z" />
  </Svg>
);

const EventProposal = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M15.26,0a1,1,0,0,0-.69.29,1,1,0,0,0-.29.71V11a1.05,1.05,0,0,0,.29.71,1,1,0,0,0,.69.29H25a1,1,0,0,0,.69-.29A1,1,0,0,0,26,11a11.13,11.13,0,0,0-3.15-7.77A10.57,10.57,0,0,0,15.26,0Zm1,10V2.06a8.73,8.73,0,0,1,5.23,2.58A9.05,9.05,0,0,1,24,10Z"
      transform="translate(0)"
    />
    <path
      d="M20.51,14.08H11.72V5a1,1,0,0,0-.28-.71A1,1,0,0,0,10.75,4a10.45,10.45,0,0,0-4.19.87A10.77,10.77,0,0,0,3,7.33,11.12,11.12,0,0,0,.73,11,11.07,11.07,0,0,0,3.17,22.74,10.78,10.78,0,0,0,10.42,26h.33a10.62,10.62,0,0,0,7.56-3.19,11.19,11.19,0,0,0,3.18-7.72,1,1,0,0,0-.08-.39,1,1,0,0,0-.21-.33,1,1,0,0,0-.32-.22.9.9,0,0,0-.37-.07Zm-3.67,7.4a8.61,8.61,0,0,1-2.93,1.92,8.4,8.4,0,0,1-3.43.6,8.79,8.79,0,0,1-5.93-2.67A9.19,9.19,0,0,1,2,15.26,9.06,9.06,0,0,1,4.11,9.1a8.71,8.71,0,0,1,5.66-3v9a1,1,0,0,0,.28.71,1,1,0,0,0,.7.29h8.72a9,9,0,0,1-2.63,5.4Z"
      transform="translate(0)"
    />
  </Svg>
);

const Events = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M21.67,26H4.33a2.54,2.54,0,0,1-2.47-2.6V5.2A2.54,2.54,0,0,1,4.33,2.6H6.81V0H9.29V2.6h7.42V0h2.48V2.6h2.48a2.54,2.54,0,0,1,2.47,2.6V23.4A2.54,2.54,0,0,1,21.67,26ZM4.33,10.4v13H21.67v-13Zm0-5.2V7.8H21.67V5.2ZM19.19,20.8H16.71V18.2h2.48Zm-5,0H11.76V18.2h2.48Zm-5,0H6.81V18.2H9.29Zm9.9-5.2H16.71V13h2.48Zm-5,0H11.76V13h2.48Zm-5,0H6.81V13H9.29Z"
      transform="translate(0 0)"
    />
  </Svg>
);

const ExternalLink = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M24.82,0H15.36a1.18,1.18,0,1,0,0,2.36H22L9.8,14.53a1.18,1.18,0,1,
        0,1.67,1.67L23.64,4v6.6a1.18,1.18,0,0,0,2.36,0V1.18A1.18,1.18,0,0,0,24.82,0Z"
      transform="translate(0 0)"
    />
    <path
      d="M20.09,11.82A1.18,1.18,0,0,0,18.91,13V23.64H2.36V7.09H13a1.18,1.18,0
    ,0,0,0-2.36H1.18A1.18,1.18,0,0,0,0,5.91V24.82A1.18,1.18,0,0,0,1.18,26H20.09a1.
    18,1.18,0,0,0,1.18-1.18V13A1.18,1.18,0,0,0,20.09,11.82Z"
      transform="translate(0 0)"
    />
  </Svg>
);

const Filter = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 16 16">
    <path d="M2.7,8.9h10.7V7.1H2.7L2.7,8.9z M0,2.6v1.8h16V2.6H0z M6.2,13.3h3.5v-1.8H6.2L6.2,13.3z" />
  </Svg>
);

const Info = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <ellipse cx="13" cy="2.51" rx="2.5" ry="2.51" />
    <path d="M13,8.92a2.48,2.48,0,0,0-2.5,2.44V23.54a2.5,2.5,0,0,0,5,0V11.36A2.48,2.48,0,0,0,13,8.92Z" />
  </Svg>
);

const LoadMore = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M11.7,7.32v6.45l6.12,3.52,1-1.61-5.2-3V7.32Zm2-6.32A12.38,12.38,0,0,0,3,7L0,4.16v8.21H8.45L4.88,8.89a9.7,9.7,0,0,1,8.77-5.36A9.84,9.84,0,0,1,20.54,6.3a9.28,9.28,0,0,1,0,13.4,9.84,9.84,0,0,1-6.89,2.77,9.72,9.72,0,0,1-9.18-6.31H1.74A12.29,12.29,0,0,0,13.65,25,12.18,12.18,0,0,0,26,13a11.67,11.67,0,0,0-.94-4.59,12,12,0,0,0-2.68-3.9,12.55,12.55,0,0,0-4-2.6A12.75,12.75,0,0,0,13.65,1Z" />
  </Svg>
);

const MoreVertical = ({ ...svgProps }) => (
  <Svg width="100%" viewBox="0 0 16 16" {...svgProps}>
    <path
      d="M10,8c0-1.1-0.9-2-2-2S6,6.9,6,8c0,1.1,0.9,2,2,2l0,0C9.1,10,10,9.1,10,8L10,8z M10,14C10,14,10,14,10,14c0-1.1-0.9-2-2-2
        s-2,0.9-2,2c0,1.1,0.9,2,2,2l0,0l0,0C9.1,16,10,15.1,10,14L10,14z M10,2c0-1.1-0.9-2-2-2S6,0.9,6,2c0,1.1,0.9,2,2,2l0,0C9.1,4,10,3.1,10,2L10,2z"
    />
  </Svg>
);

const Return = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M13,4.67V0L6.5,6.67,13,13.33v-6a8,8,0,0,1,0,16,7.93,7.93,0,0,1-7.8-8H2.6A10.57,10.57,0,0,0,13,26,10.57,10.57,0,0,0,23.4,15.33,10.57,10.57,0,0,0,13,4.67Z"
      transform="translate(0)"
    />
  </Svg>
);

const ArrowLeft = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 16 16">
    <path d="M12.9,1.9L6.8,8l6.1,6.1L11.1,16l-8-8l8-8L12.9,1.9z" />
  </Svg>
);

const PaymentVerification = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M25,4.73,9.08,1.33a1.24,1.24,0,0,0-1.48,1l-.3,2L25.67,8.19l.3-2A1.26,1.26,0,0,0,25,4.73Z"
      transform="translate(0 0)"
    />
    <path
      d="M18.31,8.61a1.25,1.25,0,0,0-1.55-.89l-3,.85L6.61,7l-.79,3.74L.93,12.15a1.28,1.28,0,0,0-.88,1.57l2.76,10a1.26,1.26,0,0,0,1.55.88l15.83-4.43a1.28,1.28,0,0,0,.88-1.57l-.47-1.73,1.77.38a1.25,1.25,0,0,0,1.49-1L25,11,18.58,9.6Zm1.83,5.1.39-1.87a.51.51,0,0,1,.61-.4l1.85.4a.51.51,0,0,1,.4.61L23,14.32a.52.52,0,0,1-.61.4l-1.85-.39A.52.52,0,0,1,20.14,13.71ZM1.2,13.16l4.38-1.23L15.92,9,17,8.72h.06a.24.24,0,0,1,.23.18l.13.47.41,1.49L1.58,15.41l-.54-2A.23.23,0,0,1,1.2,13.16Zm18.88,5.77a.19.19,0,0,1,0,.17.22.22,0,0,1-.13.12L4.09,23.65H4a.24.24,0,0,1-.23-.17L2.19,17.63l16.28-4.56,1,3.61Z"
      transform="translate(0 0)"
    />
    <path
      d="M6.64,19.33A.52.52,0,0,0,6,19l-1.85.52a.53.53,0,0,0-.36.64L4.31,22a.53.53,0,0,0,.64.36l1.85-.52a.52.52,0,0,0,.36-.64Z"
      transform="translate(0 0)"
    />
  </Svg>
);

const PaymentWallet = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M25,7.16a1,1,0,0,0,1-1V5.08A5.09,5.09,0,0,0,20.92,0H5A5.18,5.18,0,0,0,1.4,1.6,5,5,0,0,0,0,5.26V19.9A6.11,6.11,0,0,0,6.1,26H20.92A5.09,5.09,0,0,0,26,20.92V13.21a5.09,5.09,0,0,0-5.08-5.08H5.08A3,3,0,0,1,2.87,3,3.17,3.17,0,0,1,5.14,2H20.92a3.06,3.06,0,0,1,3,3.05V6.15A1,1,0,0,0,25,7.16Zm-19.9,3H20.92a3.06,3.06,0,0,1,3,3.05v7.71a3.06,3.06,0,0,1-3,3H6.1A4.07,4.07,0,0,1,2,19.9V9.15A5.12,5.12,0,0,0,5.08,10.16ZM21.94,5.08a1,1,0,0,0-1-1H5.08a1,1,0,0,0,0,2H20.92A1,1,0,0,0,21.94,5.08Z"
      transform="translate(0 0)"
    />
    <rect x="15.6" y="15.6" width="5.2" height="2.6" rx="1.3" />
  </Svg>
);

const Pending = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M21.89,23.83H20.78V20.29a6.14,6.14,0,0,0-2.12-4.63L16.78,14a1.34,1.34,0,0,1,0-2l1.88-1.65a6.14,6.14,0,0,0,2.12-4.63V2.17h1.11A1.1,1.1,0,0,0,23,1.08,1.1,1.1,0,0,0,21.89,0H4.11A1.1,1.1,0,0,0,3,1.08,1.1,1.1,0,0,0,4.11,2.17H5.22V5.71a6.14,6.14,0,0,0,2.12,4.63L9.22,12a1.34,1.34,0,0,1,0,2L7.34,15.66a6.14,6.14,0,0,0-2.12,4.63v3.54H4.11a1.09,1.09,0,1,0,0,2.17H21.89a1.09,1.09,0,1,0,0-2.17ZM8.56,5.15a.83.83,0,0,1,.83-.82h7.22a.83.83,0,0,1,.83.82A4,4,0,0,1,16.22,8l-2.63,2.56a.85.85,0,0,1-1.18,0L9.78,8A4,4,0,0,1,8.56,5.15Zm0,18.23a2.9,2.9,0,0,1,.89-2.11l3-2.89a.86.86,0,0,1,1.18,0l3,2.89a2.91,2.91,0,0,1,.9,2.11v.45H8.56Z" />
  </Svg>
);

const Place = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M2.17,10.83a10.83,10.83,0,0,1,21.66,0c0,8-9.86,14.74-10.18,15A1.52,1.52,0,0,1,13,26a1.52,1.52,0,0,1-.65-.22C12,25.57,2.17,18.85,2.17,10.83Zm2.16,0c0,5.85,6.61,11.27,8.67,12.79,2.06-1.52,8.67-6.94,8.67-12.79a8.67,8.67,0,0,0-17.34,0ZM13,6.5a4.34,4.34,0,1,0,4.33,4.33A4.34,4.34,0,0,0,13,6.5Zm-2.17,4.33A2.17,2.17,0,1,0,13,8.67,2.18,2.18,0,0,0,10.83,10.83Z" />
  </Svg>
);

const Price = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M24.15,3.18h0v9.08a1.72,1.72,0,0,1-.48,1.2l-10.3,10.8.16.17a1.8,1.8,0,0,0,2.54.09l.09-.09,9.3-9.75A2,2,0,0,0,26,13.3V5.13A1.9,1.9,0,0,0,24.15,3.18Z"
      transform="translate(0)"
    />
    <path
      d="M22.49,11.37V3a2,2,0,0,0-1.94-2H12.47a1.84,1.84,0,0,0-1.36.59L.59,12.48a2,2,0,0,0,0,2.83h0l8.08,8.38a1.88,1.88,0,0,0,2.66.07l.07-.07,10.52-10.9A2,2,0,0,0,22.49,11.37Zm-7.61.18a.55.55,0,0,1-.77,0l0,0L12.38,9.84a1.2,1.2,0,0,0-1.7,0l0,0a1.3,1.3,0,0,0,0,1.8l.86.86a2.51,2.51,0,0,1,0,3.44A2.29,2.29,0,0,1,8.26,16l-.08-.08-.86.92a.55.55,0,0,1-.76,0l0,0a.59.59,0,0,1,0-.82l.86-.91-.89-.89a.61.61,0,0,1,0-.82.56.56,0,0,1,.79,0L9,15.19a1.2,1.2,0,0,0,1.7,0l0,0a1.3,1.3,0,0,0,0-1.8l-.85-.86a2.5,2.5,0,0,1-.05-3.44A2.29,2.29,0,0,1,13.09,9l.08.08.84-1a.54.54,0,0,1,.77,0h0a.6.6,0,0,1,0,.81L14,9.85l.88.89A.6.6,0,0,1,14.88,11.55ZM17.18,8a1.5,1.5,0,1,1,1.44-1.55v0A1.47,1.47,0,0,1,17.18,8Z"
      transform="translate(0)"
    />
  </Svg>
);

const Send = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M0,26,26,13,0,0V10.11L18.57,13,0,15.89Z" transform="translate(0 0)" />
  </Svg>
);

const Spinner = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M13,8.45a1.69,1.69,0,0,0,1.69-1.69V1.69a1.69,1.69,0,1,0-3.38,0V6.76A1.69,1.69,0,0,0,13,8.45Z"
      transform="translate(0 0)"
      style={{ opacity: 1 }}
    />
    <path
      d="M19.45,4.16,15.86,7.75a1.69,1.69,0,0,0,2.39,2.39l3.59-3.59a1.69,1.69,0,0,0-2.39-2.39Z"
      transform="translate(0 0)"
      style={{ opacity: 0.3 }}
    />
    <path
      d="M17.55,13a1.69,1.69,0,0,0,1.69,1.69h5.07a1.69,1.69,0,1,0,0-3.38H19.24A1.69,1.69,0,0,0,17.55,13Z"
      transform="translate(0 0)"
      style={{ opacity: 0.4 }}
    />
    <path
      d="M21.84,21.84a1.69,1.69,0,0,0,0-2.39l-3.59-3.59a1.69,1.69,0,0,0-2.39,2.39l3.59,3.59A1.69,1.69,0,0,0,21.84,21.84Z"
      transform="translate(0 0)"
      style={{ opacity: 0.5 }}
    />
    <path
      d="M13,26a1.69,1.69,0,0,0,1.69-1.69V19.24a1.69,1.69,0,0,0-3.38,0v5.07A1.69,1.69,0,0,0,13,26Z"
      transform="translate(0 0)"
      style={{ opacity: 0.6 }}
    />
    <path
      d="M6.55,21.84l3.59-3.59a1.69,1.69,0,0,0-2.39-2.39L4.16,19.45a1.69,1.69,0,0,0,2.39,2.39Z"
      transform="translate(0 0)"
      style={{ opacity: 0.7 }}
    />
    <path
      d="M0,13a1.69,1.69,0,0,0,1.69,1.69H6.76a1.69,1.69,0,1,0,0-3.38H1.69A1.69,1.69,0,0,0,0,13Z"
      transform="translate(0 0)"
      style={{ opacity: 0.8 }}
    />
    <path
      d="M4.16,4.16a1.69,1.69,0,0,0,0,2.39l3.59,3.59a1.69,1.69,0,0,0,2.39-2.39L6.55,4.16A1.69,1.69,0,0,0,4.16,4.16Z"
      transform="translate(0 0)"
      style={{ opacity: 0.9 }}
    />
  </Svg>
);

const Trash = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M19.67,26H6.33a2.63,2.63,0,0,1-2.66-2.6V6.5H2.33A1.32,1.32,0,0,1,1,5.2H1A1.32,1.32,0,0,1,2.33,3.9h4V2.6A2.64,2.64,0,0,1,9,0h8a2.64,2.64,0,0,1,2.67,2.6V3.9h4A1.32,1.32,0,0,1,25,5.2h0a1.32,1.32,0,0,1-1.33,1.3H22.33V23.4A2.63,2.63,0,0,1,19.67,26ZM6.33,6.5V23.4H19.67V6.5ZM9,2.6V3.9h8V2.6Zm8,16.9a1.32,1.32,0,0,1-1.33,1.3h0a1.32,1.32,0,0,1-1.34-1.3V10.4a1.32,1.32,0,0,1,1.34-1.3h0A1.32,1.32,0,0,1,17,10.4Zm-5.33,0a1.32,1.32,0,0,1-1.34,1.3h0A1.32,1.32,0,0,1,9,19.5V10.4a1.32,1.32,0,0,1,1.33-1.3h0a1.32,1.32,0,0,1,1.34,1.3Z" />
  </Svg>
);

const MyTickets = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M18.38,19.92a1,1,0,1,0,1,1A1,1,0,0,0,18.38,19.92Z" />
    <circle cx="16.05" cy="7.85" r="1.02" />
    <path d="M25,15.86h1V9.77H23.41a2,2,0,0,1-.94-3.83l.87-.45L20.75,0,0,9.77H0v6.09H1a2,2,0,1,1,0,4.05H0V26H26V19.91H25a2,2,0,1,1,0-4.05ZM13.82,5.51a1,1,0,0,0,1.84-.87L19.78,2.7l.94,2a4.05,4.05,0,0,0-1.37,3,4.1,4.1,0,0,0,.54,2H4.78ZM24,21.81V24H19.4a1,1,0,0,0-2,0H2V21.81A4.05,4.05,0,0,0,2,14V11.8H17.37a1,1,0,0,0,2,0H24V14a4.05,4.05,0,0,0,0,7.85Z" />
    <circle cx="18.38" cy="14.85" r="1.02" />
    <circle cx="18.38" cy="17.89" r="1.02" />
  </Svg>
);
const Warning = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M13,20.7c1,0,1.8-0.8,1.9-1.8l1.3-15.5C16.3,2.6,16,1.7,15.4,1c-0.6-0.7-1.5-1-2.4-1c-0.9,0-1.8,0.4-2.4,1C10,1.7,9.7,2.6,9.8,3.5l1.3,15.5C11.2,19.9,12,20.7,13,20.7z" />
    <path d="M13,21.8c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1C15.1,22.8,14.2,21.8,13,21.8z" />
  </Svg>
);

const CrossedEye = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M9,14.65A4.09,4.09,0,0,1,8.67,13,4.29,4.29,0,0,1,13,8.78a4.51,4.51,0,0,1,1.69.34L17.49,6.4A13.62,13.62,0,0,0,13,5.62C6.33,5.62.38,12.38.13,12.67a.52.52,0,0,0,0,.68A27.18,27.18,0,0,0,5.62,18Z" />
    <path d="M25.9,12.7a19.5,19.5,0,0,0-6.3-5.38l3-2.92a.53.53,0,0,0,0-.75.57.57,0,0,0-.77,0L3.4,21.6a.51.51,0,0,0,0,.74.51.51,0,0,0,.38.16.54.54,0,0,0,.38-.15L7.56,19A12.85,12.85,0,0,0,13,20.39c6.68,0,12.63-6.76,12.88-7A.51.51,0,0,0,25.9,12.7ZM13,17.22a4.32,4.32,0,0,1-2.64-.9l6-5.9A4.15,4.15,0,0,1,17.33,13,4.29,4.29,0,0,1,13,17.22Z" />
  </Svg>
);

const VisibilityOn = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 16 16">
    <path
      d="M8,5.8c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2l0,0C10.2,6.8,9.2,5.8,8,5.8L8,5.8z M8,11.6C6,11.6,4.4,10,4.4,8
        S6,4.3,8,4.3S11.6,6,11.6,8l0,0C11.6,10,10,11.6,8,11.6L8,11.6z M8,2.5L8,2.5c-3.6,0-6.7,2.2-8,5.4L0,8c1.3,3.2,4.4,5.5,8,5.5
        s6.7-2.2,8-5.4L16,8C14.7,4.8,11.6,2.5,8,2.5C8,2.5,8,2.5,8,2.5z"
    />
  </Svg>
);

const ContactDetails = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M16.4,18.94a3.26,3.26,0,0,1-3.5,1.48,9.77,9.77,0,0,1-4.62-2.7,9.77,9.77,0,0,1-2.7-4.62A3.26,3.26,0,0,1,7.06,9.6a.68.68,0,0,1,.83.1l1.64,1.64a.67.67,0,0,1-.12,1.05l-.67.41a.68.68,0,0,0-.27.84,7.18,7.18,0,0,0,3.9,3.89.67.67,0,0,0,.83-.27l.41-.67a.67.67,0,0,1,1.05-.12l1.64,1.64a.68.68,0,0,1,.1.83Z" />
    <path d="M15.93,5.5A4.5,4.5,0,0,0,13,13.39a.2.2,0,0,1,0,.32l-.84.5a.16.16,0,0,0,.08.29h3.84a4.5,4.5,0,0,0-.16-9ZM16,13.2a.45.45,0,0,1-.45-.45.46.46,0,0,1,.45-.46.47.47,0,0,1,.46.46A.46.46,0,0,1,16,13.2Zm1.32-3.8a1.8,1.8,0,0,1-.87.51v1a.45.45,0,1,1-.9,0V9.52A.45.45,0,0,1,16,9.07a.88.88,0,0,0,.66-.29.85.85,0,0,0,.25-.68A.91.91,0,0,0,16,7.24h0a.92.92,0,0,0-.91.78v.13a.46.46,0,0,1-.46.45.45.45,0,0,1-.45-.45,2.11,2.11,0,0,1,0-.25,1.82,1.82,0,0,1,3.62.16A1.8,1.8,0,0,1,17.32,9.4Z" />
    <path d="M13,26A13,13,0,1,1,26,13,13,13,0,0,1,13,26ZM13,1A12,12,0,1,0,25,13,12,12,0,0,0,13,1Z" />
  </Svg>
);

const VisibilityOf = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 16 16">
    <path
      d="M7.9,5.5l2.3,2.3l0-0.1c0-1.2-1-2.2-2.2-2.2l0,0L7.9,5.5z M4.8,6l1.1,1.1c0,0.1-0.1,0.3-0.1,0.5c0,0,0,0,0,0v0
        c0,1.2,1,2.2,2.2,2.2l0,0c0,0,0,0,0,0c0.2,0,0.3,0,0.5-0.1l0,0l1.1,1.1c-0.5,0.2-1,0.4-1.6,0.4c-2,0-3.6-1.6-3.6-3.6
        C4.4,7.1,4.5,6.5,4.8,6L4.8,6L4.8,6z M0.7,2l2,2C1.5,4.9,0.6,6.2,0,7.6l0,0.1c1.3,3.2,4.4,5.5,8,5.5c1.1,0,2.2-0.2,3.2-0.6l-0.1,0
        l2.4,2.4l0.9-0.9L1.7,1.1L0.7,2z M8,4C8,4,8,4,8,4c2,0,3.6,1.6,3.6,3.6l0,0l0,0c0,0.5-0.1,0.9-0.3,1.3l0,0l2.1,2.1
        c1.1-0.9,1.9-2.1,2.5-3.4l0-0.1c-1.3-3.2-4.4-5.5-8-5.5h0c-1,0-2,0.2-3,0.5l0.1,0l1.6,1.6C7.1,4.1,7.5,4,8,4C8,4,8,4,8,4L8,4L8,4z"
    />
  </Svg>
);

const Ticket = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M9.1,10.43a1.3,1.3,0,0,0-1.3,1.28v2.58a1.3,1.3,0,0,0,2.6,0V11.71a1.3,1.3,0,0,0-1.3-1.28Zm15.6,1.28a1.31,1.31,0,0,0,.92-.37,1.3,1.3,0,0,0,.38-.91V5.29a1.28,1.28,0,0,0-.38-.91A1.32,1.32,0,0,0,24.7,4H1.3a1.32,1.32,0,0,0-.92.38A1.28,1.28,0,0,0,0,5.29v5.14a1.3,1.3,0,0,0,.38.91,1.31,1.31,0,0,0,.92.37,1.32,1.32,0,0,1,.92.38,1.28,1.28,0,0,1,0,1.82,1.32,1.32,0,0,1-.92.38,1.31,1.31,0,0,0-.92.37,1.3,1.3,0,0,0-.38.91v5.14a1.28,1.28,0,0,0,.38.91A1.32,1.32,0,0,0,1.3,22H24.7a1.32,1.32,0,0,0,.92-.38,1.28,1.28,0,0,0,.38-.91V15.57a1.3,1.3,0,0,0-.38-.91,1.31,1.31,0,0,0-.92-.37,1.32,1.32,0,0,1-.92-.38,1.28,1.28,0,0,1,0-1.82A1.32,1.32,0,0,1,24.7,11.71ZM23.4,9.37a3.91,3.91,0,0,0-1.86,1.42,3.79,3.79,0,0,0,0,4.42,3.91,3.91,0,0,0,1.86,1.42v2.8h-13a1.3,1.3,0,0,0-.38-.91,1.3,1.3,0,0,0-1.84,0,1.3,1.3,0,0,0-.38.91H2.6v-2.8a3.91,3.91,0,0,0,1.86-1.42,3.79,3.79,0,0,0,0-4.42A3.91,3.91,0,0,0,2.6,9.37V6.57H7.8a1.3,1.3,0,0,0,.38.91,1.3,1.3,0,0,0,1.84,0,1.3,1.3,0,0,0,.38-.91h13Z" />
  </Svg>
);
const Search = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M3.3,11.6c0-4.4,3.6-8,8-8h0.2l0,0c0.2,0,0.5,0,0.7,0c4.4,0,8,3.6,8,8s-3.6,8-8,8c-0.3,0-0.5,0-0.8,0l0,0h-0.2
	C6.8,19.4,3.3,16,3.3,11.6L3.3,11.6L3.3,11.6z M26,23.5l-5.4-5.2c1.5-1.8,2.3-4.1,2.3-6.7l0,0l0,0c0-6.2-5-11.1-11.2-11.1
	c-0.2,0-0.2,0-0.3,0l0,0h-0.2C5,0.4,0,5.4,0,11.5l0,0c0,6.2,5,11.1,11.2,11.1h0.2l0,0l0,0c2.6,0,4.9-0.8,6.8-2.3l0,0l5.4,5.2
	L26,23.5z"
    />
  </Svg>
);

const Horse = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 16 16">
    <path
      d="M3.1,12.6C0.9,9.4,2.5,6.9,2.5,6.9C2.1,6.8,1,7.6,1,7.6c0.6-2.3,4.2-4.3,4.2-4.3C4.8,3.1,3.7,3.4,3.7,3.4
        c1.2-1.2,2.9-1.9,4.8-1.9c0.6,0,1.1,0.1,1.6,0.2l0,0C10.1,1.1,11.9,0,11.9,0c-0.2,0.2-0.2,1.1-0.2,1.1c0.2-0.3,0.5-0.5,0.7-0.6l0,0
        c-0.3,0.7,0,2.4,0,2.4c0.9,0.9,2,4.1,2,4.1l0.4,0.6c0.5,0.7,0.3,1.8-0.2,2.1c-1.8,1-2-0.8-2-0.8C11,8.6,9.9,6.9,9.9,6.9
        C7.8,8,8.9,11,9.9,12.1c1.1,1.5,0.3,3.9,0.3,3.9S5.3,15.8,3.1,12.6L3.1,12.6z"
    />
  </Svg>
);
const ActiveEvents = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M23.93,14.11a10.27,10.27,0,0,0-3.4-6.84l.77-.76.88.87,1.53-1.52L20.42,2.6,18.88,4.12l.88.87-1,1a11.72,11.72,0,0,0-4.51-1.52V2.17h2.2V0H9.76V2.17H12V4.45a10.81,10.81,0,0,0-9.9,11.83,11,11,0,0,0,12.09,9.66A10.78,10.78,0,0,0,23.93,14.11ZM13.16,23.77a8.77,8.77,0,0,1-8.9-8.58,8.8,8.8,0,0,1,17.59,0A8.58,8.58,0,0,1,13.16,23.77Z" />
    <path d="M14.15,14.11V9.77a1,1,0,0,0-1.1-1.09,6.51,6.51,0,1,0,6.6,6.51,1,1,0,0,0-1.1-1.08Zm-1.1,5.42A4.35,4.35,0,0,1,12,11v4.23a1,1,0,0,0,1.09,1.09h4.29A4.45,4.45,0,0,1,13.05,19.53Z" />
  </Svg>
);

const Time = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M23.81,5.78A13,13,0,1,0,26,13,13,13,0,0,0,23.81,5.78ZM17.33,23.46a11.33,11.33,0,1,1,5.08-4.17A11.34,11.34,0,0,1,17.33,23.46Z" />
    <path d="M13.07,12.39V5.86a.85.85,0,0,0-.22-.57.76.76,0,0,0-.54-.23.72.72,0,0,0-.53.23.85.85,0,0,0-.22.57v7.2L16,17.82a.7.7,0,0,0,.25.17.59.59,0,0,0,.29.07.73.73,0,0,0,.42-.14.84.84,0,0,0,.28-.36.91.91,0,0,0,0-.46.77.77,0,0,0-.21-.41Z" />
  </Svg>
);

const PlaceWithoutFilling = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M23.38,7.37A11,11,0,1,0,8.1,20.89l3.52,4.44a2,2,0,0,0,.61.5A1.88,1.88,0,0,0,13,26a1.84,1.84,0,0,0,.77-.17,2,2,0,0,0,.61-.5l3.52-4.44A11,11,0,0,0,23.38,7.37Zm-.9,5.1a9.65,9.65,0,0,1-5.34,7.22.76.76,0,0,0-.25.19l-3.62,4.58a.27.27,0,0,1-.12.09.3.3,0,0,1-.3,0,.27.27,0,0,1-.12-.09L9.11,19.88a.67.67,0,0,0-.25-.19A9.61,9.61,0,0,1,12.31,1.44c.23,0,.46,0,.7,0A9.5,9.5,0,0,1,19.54,4a9.63,9.63,0,0,1,2.94,8.48Z" />
    <path d="M14.91,6.38A4.93,4.93,0,0,0,12,6.1,5,5,0,0,0,8.1,10a4.93,4.93,0,0,0,.28,2.89,5,5,0,1,0,6.53-6.53Zm1.18,6.69a3.68,3.68,0,0,1-1.67,1.37,3.76,3.76,0,0,1-2.15.21,3.74,3.74,0,0,1-2.92-2.92,3.76,3.76,0,0,1,.21-2.15,3.72,3.72,0,1,1,6.53,3.49Z" />
  </Svg>
);
const Users = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path d="M13,2.5A4.47,4.47,0,0,0,8.48,6.9a4.52,4.52,0,0,0,9,0A4.47,4.47,0,0,0,13,2.5Zm0,7.32A3,3,0,0,1,10,6.9a3,3,0,0,1,6,0A3,3,0,0,1,13,9.82ZM1.7,9.89a3.34,3.34,0,0,1,3.38-3.3,3.3,3.3,0,1,1,0,6.6A3.34,3.34,0,0,1,1.7,9.89Zm1.52,0A1.83,1.83,0,0,0,5.08,11.7a1.81,1.81,0,1,0,0-3.62A1.83,1.83,0,0,0,3.22,9.89ZM19.65,13.6h2.54A3.77,3.77,0,0,1,26,17.31v4a2.26,2.26,0,0,1-2.29,2.23H2.29A2.26,2.26,0,0,1,0,21.27v-4A3.77,3.77,0,0,1,3.81,13.6H6.35a4,4,0,0,1,1,.13,3.84,3.84,0,0,1,3.4-2h4.5a3.84,3.84,0,0,1,3.4,2A4,4,0,0,1,19.65,13.6ZM6.94,22V15.41c0-.08,0-.17,0-.25a2.35,2.35,0,0,0-.6-.07H3.81a2.26,2.26,0,0,0-2.29,2.22v4a.77.77,0,0,0,.77.75Zm1.53,0h9.06V15.41a2.26,2.26,0,0,0-2.28-2.23h-4.5a2.26,2.26,0,0,0-2.28,2.23Zm15.24,0a.77.77,0,0,0,.77-.75v-4a2.26,2.26,0,0,0-2.29-2.22H19.65a2.35,2.35,0,0,0-.6.07c0,.08,0,.17,0,.25V22ZM17.54,9.89a3.39,3.39,0,1,1,3.38,3.3A3.34,3.34,0,0,1,17.54,9.89Zm1.52,0a1.86,1.86,0,1,0,1.86-1.81A1.83,1.83,0,0,0,19.06,9.89Z" />
  </Svg>
);

const SearchOutline = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      fill="transparent"
      d="M19.35,9.53A9.13,9.13,0,0,1,10,18.42,9.13,9.13,0,0,1,.63,9.53,9.14,9.14,0,0,1,10,.63,9.14,9.14,0,0,1,19.35,9.53Z"
    />
    <path d="M10,19.05A9.77,9.77,0,0,1,0,9.53,9.77,9.77,0,0,1,10,0,9.77,9.77,0,0,1,20,9.53,9.77,9.77,0,0,1,10,19.05ZM10,1.27A8.51,8.51,0,0,0,1.26,9.53,8.52,8.52,0,0,0,10,17.79a8.51,8.51,0,0,0,8.72-8.26A8.5,8.5,0,0,0,10,1.27Z" />
    <path
      fill="transparent"
      d="M16.49,9.53A6.34,6.34,0,0,1,10,15.7a6.34,6.34,0,0,1-6.5-6.17A6.35,6.35,0,0,1,10,3.35,6.35,6.35,0,0,1,16.49,9.53Z"
    />
    <path d="M10,16.33a7,7,0,0,1-7.14-6.8A7,7,0,0,1,10,2.72a7,7,0,0,1,7.13,6.81A7,7,0,0,1,10,16.33ZM10,4A5.71,5.71,0,0,0,4.12,9.53,5.72,5.72,0,0,0,10,15.07a5.72,5.72,0,0,0,5.87-5.54A5.71,5.71,0,0,0,10,4Z" />
    <path
      fill="transparent"
      d="M17.27,17.65h0a2.12,2.12,0,0,1,2.89,0L24.77,22a1.89,1.89,0,0,1,0,2.76h0a2.12,2.12,0,0,1-2.89,0l-4.61-4.39A1.89,1.89,0,0,1,17.27,17.65Z"
    />
    <path d="M23.32,26a2.74,2.74,0,0,1-1.88-.74l-4.61-4.4a2.53,2.53,0,0,1,0-3.67,2.76,2.76,0,0,1,3.77,0l4.61,4.4a2.53,2.53,0,0,1,0,3.67A2.75,2.75,0,0,1,23.32,26Zm-4.6-8.29a1.44,1.44,0,0,0-1,.4,1.25,1.25,0,0,0-.41.92,1.28,1.28,0,0,0,.41.92l4.61,4.39a1.47,1.47,0,0,0,2,0,1.27,1.27,0,0,0,.41-.92,1.28,1.28,0,0,0-.41-.92l-4.61-4.39A1.41,1.41,0,0,0,18.72,17.71Z" />
    <polygon points="16.08 16.83 16.41 16.52 17.73 17.77 17.4 18.09 16.08 16.83" />
  </Svg>
);

const Calendar = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 16 16">
    <path d="M13.6,14.4H2.4V5.6h11.2V14.4z M11.2,0v1.6H4.8V0H3.2v1.6H2.4l0,0c-0.9,0-1.6,0.7-1.6,1.6c0,0,0,0,0,0v0v11.2l0,0 c0,0.9,0.7,1.6,1.6,1.6h11.2c0.9,0,1.6-0.7,1.6-1.6l0,0V3.2c0-0.9-0.7-1.6-1.6-1.6l0,0h-0.8V0L11.2,0z M12,8.8H8v4h4L12,8.8z" />
  </Svg>
);

const ArrowDoubleRight = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 28 26">
    <path d="M0,22.9L9.9,13L0,3.1L3,0l13,13L3,26L0,22.9z" />
    <path d="M12,23l9.9-9.9L12,3.1L15,0l13,13L15,26L12,23z" />
  </Svg>
);

const ArrowDoubleLeft = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 28 26">
    <path d="M25,26L12,13L25,0L28,3.1L18.1,13l9.9,9.9L25,26z" />
    <path d="M13,26L0,13L13,0L16,3.1L6.1,13L16,23L13,26z" />
  </Svg>
);
const Download = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 28 26">
    <path d="M21.62,13.1l-6.94,7.24V1.76a1.69,1.69,0,1,0-3.37,0V20.34L4.37,13.1A1.64,1.64,0,0,0,2,13.1a1.83,1.83,0,0,0,0,2.49l9.53,9.93A1.6,1.6,0,0,0,13,26a1.62,1.62,0,0,0,1.48-.45L24,15.59a1.83,1.83,0,0,0,0-2.49A1.66,1.66,0,0,0,21.62,13.1Z" />
  </Svg>
);
const CancelPaymentOutline = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M13,26A13,13,0,1,1,26,13,13,13,0,0,1,13,26ZM13,1.13A11.92,11.92,0,1,0,24.92,13,11.93,11.93,0,0,0,13,1.13Z"
      transform="translate(0 -0.04)"
    />
    <path
      d="M17.08,17.12a.87.87,0,0,1-1.23,0L13,14.27l-2.85,2.85a.87.87,0,1,1-1.23-1.23L11.77,13,8.92,10.19A.87.87,0,0,1,8.92,9a.89.89,0,0,1,1.23,0L13,11.81,15.85,9a.89.89,0,0,1,1.23,0,.87.87,0,0,1,0,1.23L14.23,13l2.85,2.85A.87.87,0,0,1,17.08,17.12Z"
      transform="translate(0 -0.04)"
    />
  </Svg>
);

const CircleChart = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M14.3,0V4a9.11,9.11,0,0,1,7.8,9,8.72,8.72,0,0,1-.65,3.31l3.41,2A13,13,0,0,0,14.3,0ZM13,22.09A9.12,9.12,0,0,1,11.7,4V0A13,13,0,1,0,23.46,20.67l-3.38-2A8.94,8.94,0,0,1,13,22.09Z"
      transform="translate(0 0)"
    />
  </Svg>
);

const HorseRider = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 16 16">
    <path
      d="M7.9,1.2c0,0.6-0.5,1.2-1.2,1.2S5.6,1.8,5.6,1.2S6.1,0,6.7,0S7.9,0.5,7.9,1.2z M5.4,4.2c0,0-0.1-1.7,1.8-1.4
  c0,0,0.9,0,1.3,2.6l1.3,0.9c0,0,1.4-1.2,2.9-1.8c0,0,0.9-0.9,1.6-0.9c0,0-0.6,0.8-0.2,1.2c0,0,1.4,2,1.5,2.2c0,0-0.1,0.9-0.8,0.6
  c-0.1,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-1.5-0.9-2.1-0.1c0,0-0.4,0.7-0.4,1.2c0,0-0.2,1-0.4,1.3l0.9,2.3
  c0,0,0.2,0.3,0.1,0.6c0,0-0.4,2.6-1.2,2.3c0,0-0.4-0.1-0.4-0.7l0.4-1.7c0,0-0.4-0.7-1.4-1.5l-0.4,4.1c0,0-0.1,0.6-0.4,0.7
  c0,0-0.4,0.1-0.6-0.7l-0.1-3.6c0,0-0.6-0.1-0.7,0.3c0,0-0.1,0.8-0.8,0.6c0,0-0.7-0.1-0.5-0.9l0.5-2.6l-2-2.8L5.4,4.2L5.4,4.2z
  M5.8,7.4l1.2,1.8l-0.2,2.5l-0.8-0.2c0,0,0.4,1,0.4,1.2c0,0-0.2,2.7-1.2,2.6c0,0-0.8-0.2-0.2-1.2L5.3,13l-1.1-1.3v1.4
  c0,0-0.4,3-1.1,2.9c0,0-0.6-0.1-0.5-0.6c0,0,0.2-2,0.4-2.4c0,0-1.5-3.3-0.9-4.4c0,0-0.7-0.3-0.4,1.5c0,0,0.6,2.3-0.4,3.2
  c0,0,0.2-1-0.6-2.6c0,0-1-2.6,1.8-2.9C2.4,7.8,3.3,6.9,5.8,7.4L5.8,7.4z"
    />
  </Svg>
);

const BillsSummary = ({ ...svgProps }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 26 26">
    <path
      d="M23.4,23.71H2.6A2.7,2.7,0,0,1,0,20.92V4.21A2.7,2.7,0,0,1,2.6,1.42H23.4A2.7,2.7,0,0,1,26,4.21V20.92A2.7,2.7,0,0,1,23.4,23.71ZM2.6,12.57v8.35H23.4V12.57Zm0-8.36V7H23.4V4.21ZM14.3,18.14H5.2V15.35h9.1Z"
      transform="translate(0 0)"
    />
  </Svg>
);

type TIconCircleContainer = LayoutProps & ColorProps & BorderProps & SpaceProps;
const IconCircleContainer = styled('span')<TIconCircleContainer>(layout, color, b, space, { display: 'inline-block' });
const iconSizes = {
  xs: {
    size: 20,
    padding: 3,
  },
  sm: {
    size: 24,
    padding: 4,
  },
  base: {
    size: 30,
    padding: 7,
  },
  md: {
    size: 50,
    padding: 10,
  },
  mdWithBorder: {
    size: 40,
    padding: 8,
  },
  '2xl': {
    size: 104,
    padding: 28,
  },
};

const iconCircleStyleProps = (
  size: 'sm' | 'xs' | '2xl' | 'base' | 'md' | 'mdWithBorder',
  backgroundColor: string,
  border: BorderProps
) => ({
  width: iconSizes[size].size,
  minWidth: iconSizes[size].size,
  height: iconSizes[size].size,
  minHeight: iconSizes[size].size,
  p: iconSizes[size].padding,
  borderRadius: 9999,
  backgroundColor,
  ...border,
});

const withIconCircleWrapper = (WrappedIcon: React.ElementType) => {
  const IconCircleWrapper = ({ size, bgColor, border, ...svgProps }: TIconCircleProps) => (
    <IconCircleContainer {...iconCircleStyleProps(size, bgColor, border)}>
      <WrappedIcon {...svgProps} />
    </IconCircleContainer>
  );

  IconCircleWrapper.defaultProps = {
    size: 'base',
    fill: 'currentColor',
    bgColor: 'rgba(0, 94, 101, 0.05)',
    border: { borderWidth: 0 },
  };
  return IconCircleWrapper;
};

const withIconWrapper = (WrappedIcon: React.ElementType) => {
  const IconWrapper = ({ size, ...svgProps }: TIconProps) => <WrappedIcon squareSize={size} {...svgProps} />;

  IconWrapper.defaultProps = {
    size: 16,
    fill: 'currentColor',
  };
  return IconWrapper;
};

const Icons = {
  Plus: withIconWrapper(Plus),
  Minus: withIconWrapper(Minus),
  CloseCircle: withIconCircleWrapper(Close),
  Close: withIconWrapper(Close),
  Cancel: withIconWrapper(Cancel),
  BellTwo: withIconWrapper(BellTwo),
  Send: withIconWrapper(Send),
  Edit: withIconWrapper(Edit),
  ArrowUp: withIconWrapper(ArrowUp),
  ArrowDown: withIconWrapper(ArrowDown),
  ArrowRight: withIconWrapper(ArrowRight),
  ArrowLeft: withIconWrapper(ArrowLeft),
  PaymentWallet: withIconWrapper(PaymentWallet),
  Return: withIconWrapper(Return),
  ReturnCircle: withIconCircleWrapper(Return),
  EventProposal: withIconWrapper(EventProposal),
  EventProposalCircle: withIconCircleWrapper(EventProposal),
  Place: withIconWrapper(Place),
  Events: withIconWrapper(Events),
  EventsCircle: withIconCircleWrapper(Events),
  Price: withIconWrapper(Price),
  LoadMore: withIconWrapper(LoadMore),
  Accept: withIconWrapper(Accept),
  AcceptCircle: withIconCircleWrapper(Accept),
  ExternalLink: withIconWrapper(ExternalLink),
  Dashboard: withIconWrapper(Dashboard),
  MoreVertical: withIconWrapper(MoreVertical),
  PendingCircle: withIconCircleWrapper(Pending),
  Trash: withIconWrapper(Trash),
  TrashCircle: withIconCircleWrapper(Trash),
  Filter: withIconWrapper(Filter),
  Info: withIconWrapper(Info),
  InfoCircle: withIconCircleWrapper(Info),
  PaymentVerification: withIconWrapper(PaymentVerification),
  Search: withIconWrapper(Search),
  Spinner: withIconWrapper(Spinner),
  Warning: withIconWrapper(Warning),
  Ticket: withIconWrapper(Ticket),
  WarningCircle: withIconCircleWrapper(Warning),
  TicketCircle: withIconCircleWrapper(Ticket),
  MyTickets: withIconWrapper(MyTickets),
  MyTicketsCircle: withIconCircleWrapper(MyTickets),
  CrossedEyeCircle: withIconCircleWrapper(CrossedEye),
  ActiveEventsCircle: withIconCircleWrapper(ActiveEvents),
  Time: withIconWrapper(Time),
  PlaceWithoutFilling: withIconWrapper(PlaceWithoutFilling),
  Users: withIconWrapper(Users),
  SearchOutline: withIconWrapper(SearchOutline),
  UsersCircle: withIconCircleWrapper(Users),
  Calendar: withIconWrapper(Calendar),
  CancelPaymentOutline: withIconWrapper(CancelPaymentOutline),
  CircleChart: withIconWrapper(CircleChart),
  CircleChartCircle: withIconCircleWrapper(CircleChart),
  HorseRider: withIconWrapper(HorseRider),
  HorseRiderCircle: withIconCircleWrapper(HorseRider),
  PlaceWithoutFillingCirlce: withIconCircleWrapper(PlaceWithoutFilling),
  Horse: withIconWrapper(Horse),
  HorseCircle: withIconCircleWrapper(Horse),
  BillsSummary: withIconWrapper(BillsSummary),
  BillsSummaryCircle: withIconCircleWrapper(BillsSummary),
  ArrowDoubleRight: withIconWrapper(ArrowDoubleRight),
  ArrowDoubleLeft: withIconWrapper(ArrowDoubleLeft),
  DownloadCircle: withIconCircleWrapper(Download),
  Download: withIconCircleWrapper(Download),
  VisibilityOn: withIconWrapper(VisibilityOn),
  VisibilityOf: withIconWrapper(VisibilityOf),
  ContactDetails: withIconWrapper(ContactDetails),
};

export default Icons;
