import { useState, useRef } from 'react';
import { connect } from 'react-redux';

import { refreshCustomData } from 'store/actions';
import Model from '@old/model';
import t from 'resources/translations';
import RenderDate from '@old/components/old/RenderDate';
import ImageCircle from '@old/components/guide/ImageCircle';
import FlexColumn from '@old/components/common/FlexColumn';
import { getErrorMessage, notify } from 'old/utils';
import Spinner from '@old/components/common/Spinner';
import Divider from '@old/components/common/Divider';
import RowAligner from '@old/components/common/RowAligner';
import Subtitle from '@old/components/common/Subtitle';
import Input from '@old/components/input';
import Button from '@old/components/guide/Button';
import { ContextTrigger } from '@old/components/common/ContextMenu';
import { useEditCommentButton, useDeleteCommentButton } from 'old/buttonHooks';
import { useOutsideClick, useKey } from '@old/hooks';
import { useOldLoggedMember } from 'utils/storeUtils';

const EventComment = ({ postId, eventId, comment, refreshPosts }) => {
  const loggedMember = useOldLoggedMember();
  const [content, setContent] = useState(comment ? comment.content : []);
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const commentRef = useRef();

  useOutsideClick(commentRef, () => setEditable(false));
  const onEscapeCancelEdit = useKey(() => setEditable(false), 'esc');

  const editModeOn = () => {
    setEditable(true);
  };

  const editModeOff = () => {
    setEditable(false);
  };

  const onChange = value => {
    setContent(value);
  };

  const deleteComment = async () => {
    setLoading(true);
    try {
      await Model.Events.deleteComment(eventId, postId, comment.id);
      refreshPosts('posts');
      notify(t('message.deleteComment'));
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    setLoading(false);
  };

  const updateComment = async () => {
    setLoading(true);
    try {
      await Model.Events.updateComment(eventId, postId, comment.id, { content });
      editModeOff();
      refreshPosts('posts');
      notify(t('message.editingCommentSuccess'));
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    setLoading(false);
  };

  const commentEdit = useEditCommentButton(editModeOn, comment.author);
  const commentDelete = useDeleteCommentButton(deleteComment, comment.author, loggedMember);

  const buttons = [commentEdit, commentDelete];

  if (!comment) return null;

  return (
    <ContextTrigger menuItems={buttons}>
      <div className="relative">
        {loading && <Spinner />}
        <FlexColumn separated="small">
          <RowAligner separated="small">
            <ImageCircle src={comment.author.getAvatar('thumb')} small />
            <div>
              <Subtitle>{comment.author.getName()}</Subtitle>,
            </div>
            <RenderDate startDate={comment.createdAt} />
          </RowAligner>
          <div ref={commentRef}>
            {editable ? (
              <FlexColumn>
                <Input.Textarea
                  value={content}
                  onChange={onChange}
                  placeholder={t('eventComment.writeComment')}
                  onKeyDown={onEscapeCancelEdit}
                />
                <div className="text-right">
                  <Button onClick={updateComment}>{t('general.save')}</Button>
                </div>
              </FlexColumn>
            ) : (
              comment.content
            )}
          </div>
          <Divider />
        </FlexColumn>
      </div>
    </ContextTrigger>
  );
};

export default connect(null, { refreshPosts: refreshCustomData })(EventComment);
