import Modal from 'components/combinations/modals/Modal';
import ModalHeader from 'components/combinations/modals/ModalHeader';
import ModalCloseButton from 'components/combinations/modals/ModalCloseButton';
import ModalContent from 'components/combinations/modals/ModalContent';
import { TWrappedModal, TStoreStateModals } from 'views/modals';
import TicketOffer from 'models/TicketOffer';
import { Box, Button, Flex, Icons, Label, Span, Stack, Text } from 'components/elements';
import FormLabel from 'components/combinations/forms/FormLabel';
import TextInput from 'components/combinations/forms/TextInput';
import t from 'resources/translations';
import SimpleBar from 'simplebar-react';
import DefaultOption from 'components/combinations/select/DefaultOption';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { BillService, MemberService, TicketOfferService } from 'services';
import _, { isNumber } from 'lodash';
import { getMaxHeight, useInfinityScroll, useSearchQuery, yup } from 'utils';
import React, { useEffect, useState } from 'react';
import { AvatarWithLabel, ModalButtons, Select } from 'components/combinations';
import FormField from 'components/combinations/forms/FormField';
import DateInput from 'components/combinations/forms/DateInput';
import { DATE_FORMAT, PAYMENT_STATUS_COMPACT } from 'config/constans';
import moment from 'moment';
import { useFormik } from 'formik';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { getErrorMessage, notify, parseToCurrencyString } from 'old/utils';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import { TOption } from 'resources/types/commonTypes';
import { useShallowSelector } from 'old/hooks';
import { CSSObject } from 'styled-components';

type TPaymentModalProps = {
  offer: TicketOffer;
};

type TFormTicketAssignState = {
  member?: TOption;
  activation_date: string;
};

const TicketAssign = ({ onSubmit, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const { offer } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['TICKET_ASSIGN'].props
  ) as TPaymentModalProps;
  const queryClient = useQueryClient();
  const search = useSearchQuery();
  const [step, setStep] = useState(1);
  const [billId, setBillId] = useState<number>(0);
  const [paymentType, setPaymentType] = useState(PAYMENT_STATUS_COMPACT[0]);

  const optionsState = useInfiniteQuery(
    ['OPTIONS_MEMBERS', { keyword: search.searchQuery }],
    ({ pageParam }) =>
      MemberService.fetchOptions(
        search.searchQuery,
        { page: pageParam },
        { with_status: ['active'], with_role: 'client' }
      ),
    {
      getNextPageParam: ({ additional }) => {
        return additional.page;
      },
      refetchOnWindowFocus: false,
    }
  );

  const { values, touched, errors, setFieldValue, setFieldTouched, ...formik } = useFormik<TFormTicketAssignState>({
    initialValues: {
      member: undefined,
      activation_date: moment().format(DATE_FORMAT),
    },
    onSubmit: async () => {
      if (values.member) {
        try {
          const { bill_id } = await TicketOfferService.setTicket(offer.id, values.member.value, values.activation_date);
          setStep(2);
          setBillId(bill_id || 0);
        } catch (e) {
          notify(getErrorMessage(e), { type: 'error' });
        }
      }
    },
    validationSchema: yup.object().shape({
      activation_date: yup
        .string()
        .required(t('error.fieldIsRequired'))
        .format(DATE_FORMAT, true)
        .test('', t('error.activationTicketNotInPast'), value =>
          moment(value, DATE_FORMAT).isAfter(moment().subtract(1, 'days'))
        ),
    }),
  });

  const onAddPayment = async () => {
    try {
      await BillService.addPaymentByBillId(billId, paymentType.value);
      queryClient.invalidateQueries('TICKETS');
      setStep(3);
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => optionsState.remove(), []);

  const refMenu = useInfinityScroll(optionsState.fetchNextPage, optionsState.isFetching, optionsState.hasNextPage);
  const options: TOption[] = _.flatten(_.concat((optionsState.data?.pages || []).map(page => page.options)));

  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{t('ticketOffers.assignAClient')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      {step === 1 && (
        <React.Fragment>
          <ModalContent isMakingRequest={isSubmitting}>
            <Box w="full">
              <FormLabel htmlFor="search" fontWeight="normal">
                {t('labels.searchClient')}
              </FormLabel>
              <TextInput
                name="search"
                value={search.query}
                placeholder={t('placeholders.searchByClientFullname')}
                onChange={e => search.setQuery(e.target.value)}
              >
                <Button variant="icon" ml="sm" onClick={() => search.setQuery('')} px={20}>
                  {search.query ? <Icons.Cancel squareSize={25} /> : <Icons.Search squareSize={24} />}
                </Button>
              </TextInput>
            </Box>
            <Box w="full">
              <SimpleBar
                style={{ height: 300, maxHeight: getMaxHeight(430), paddingRight: 30 }}
                scrollableNodeProps={{ ref: refMenu }}
              >
                {options.map(option => {
                  const isSelected = values.member?.value === option.value;
                  return (
                    <DefaultOption
                      onClick={() => {
                        setFieldValue('member', option);
                        setFieldTouched('member', true);
                      }}
                      isDisabled={false}
                      isSelected={isSelected}
                      data={option}
                    />
                  );
                })}
                {optionsState.isFetching && (
                  <Text py="md" color="grey" textAlign="center">
                    {t('message.loadingList')}
                  </Text>
                )}
              </SimpleBar>
            </Box>
            {values.member?.type === 'member' && (
              <Stack spacing="sm">
                <Label htmlFor="client" fontWeight="medium">
                  {`${t('labels.selectedClient')}:`}
                </Label>
                <Box>
                  <AvatarWithLabel
                    title={values.member.label}
                    alt={values.member.label}
                    src={values.member.data.image}
                    squareSize={30}
                  >
                    <Text>{values.member.label}</Text>
                  </AvatarWithLabel>
                </Box>
              </Stack>
            )}

            <FormField errors={touched.activation_date ? errors.activation_date : ''}>
              <FormLabel htmlFor="activation_date">{t('paymentTicketModal.dateActiveTicket')}:</FormLabel>
              <DateInput
                name="activation_date"
                placeholder={t('placeholders.date')}
                value={values.activation_date}
                onChange={(value: string) => {
                  setFieldValue('activation_date', value);
                  setFieldTouched('activation_date', true);
                }}
                maxW={280}
                pickerPosition="top"
                pickerOptions={{ minDate: moment().subtract(1, 'days') }}
                disabled={!offer.activable}
              />
            </FormField>
          </ModalContent>
          <ModalButtons>
            <Button
              disabled={!(formik.isValid && formik.dirty && values.member)}
              onClick={() => onSubmit(formik.submitForm)}
            >
              {isSubmitting ? <SpinnerDots label={t('general.loading2')} inline /> : t('buttons.assignClient')}
            </Button>
            <Button variant="outline" onClick={onClose}>
              {t('buttons.stopAndGoBackToOfferts')}
            </Button>
          </ModalButtons>
        </React.Fragment>
      )}
      {step === 2 && billId && (
        <React.Fragment>
          <ModalContent isMakingRequest={isSubmitting}>
            <ModalDescription>
              <Stack textAlign="center">
                <Text color="teal-dark" fontWeight="medium" textAlign="center">
                  {t('ticketOffers.successAssignedTicket')}
                </Text>
                <Text>{t('ticketOffers.billClientLater')}</Text>
              </Stack>
            </ModalDescription>
            {values.member?.type === 'member' && (
              <Stack spacing="sm">
                <Text fontWeight="medium">{`${t('ticketOffers.clientAssignedToTicket')}:`}</Text>
                <Box>
                  <AvatarWithLabel
                    title={values.member.label}
                    alt={values.member.label}
                    src={values.member.data.image}
                    squareSize={30}
                  >
                    <Text>{values.member.label}</Text>
                  </AvatarWithLabel>
                </Box>
              </Stack>
            )}
            <FormField hideErrorsBlock>
              <FormLabel htmlFor="paymentType">{t('bills.paymentMethod')}</FormLabel>
              <Select
                name="paymentType"
                value={paymentType}
                options={PAYMENT_STATUS_COMPACT.filter(payment => payment.value !== 'ticket')}
                onChange={setPaymentType}
                isSearchable={false}
                menuPortalTarget={document.body}
                customStyles={{
                  menuPortal: (base: CSSObject) => ({
                    ...base,
                    zIndex: 9999,
                    top: isNumber(base.top) ? base.top - 63 : 0,
                  }),
                }}
                maxMenuHeight={150}
              />
            </FormField>
            <Flex alignItems="center" py="md" justifyContent="space-between">
              <Text fontSize="md" fontWeight="bold">
                {`${t('ticketOffers.dueAmountForTicket')}:`}
              </Text>
              <Text fontSize="lg" fontWeight="bold" color="orange">
                {parseToCurrencyString(offer.price)}
              </Text>
            </Flex>
          </ModalContent>
          <ModalButtons>
            <Button disabled={!paymentType} onClick={() => onSubmit(onAddPayment)}>
              {isSubmitting ? <SpinnerDots label={t('general.loading2')} inline /> : t('buttons.addPaymentForTicket')}
            </Button>
            <Button variant="outline" onClick={onClose}>
              {t('buttons.continueWithoutBilling')}
            </Button>
          </ModalButtons>
        </React.Fragment>
      )}
      {step === 3 && (
        <React.Fragment>
          <ModalContent spacing="xl">
            <ModalDescription>
              <Stack textAlign="center">
                <Text color="teal-dark" fontWeight="medium" textAlign="center">
                  {t('ticketOffers.successClientTicketPaid')}
                </Text>
              </Stack>
            </ModalDescription>
            <Stack spacing="sm">
              <Text fontWeight="medium">{`${t('ticketOffers.summary')}: `}</Text>
              <Text>
                {`${t('ticketOffers.ticket')}: `}
                {offer.getName()}
              </Text>
              <Text>
                {`${t('ticketOffers.dueAmountForTicket')}: `}
                <Span color="green-light">
                  {parseToCurrencyString(offer.price)} ({t('bills.paid').toLowerCase()})
                </Span>
              </Text>
              <Text>
                {`${t('paymentTicketModal.paymentMethod')}: `} {paymentType.label}
              </Text>
            </Stack>
            {values.member?.type === 'member' && (
              <Stack spacing="sm">
                <Text fontWeight="medium">{`${t('ticketOffers.clientAssignedToTicket')}: `}</Text>
                <Box>
                  <AvatarWithLabel
                    title={values.member.label}
                    alt={values.member.label}
                    src={values.member.data.image}
                    squareSize={30}
                  >
                    <Text>{values.member.label}</Text>
                  </AvatarWithLabel>
                </Box>
              </Stack>
            )}
          </ModalContent>
          <ModalButtons>
            <Button variant="outline" onClick={onClose}>
              {t('buttons.close')}
            </Button>
          </ModalButtons>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default TicketAssign;
