import { DATE_TIME_FORMAT_WITH_COMMA } from 'config/constans';
import { IPaymentRes, TCurrency, TPaymentStatus, TPaymentType } from 'resources/types/billsTypes';
import Member from './Member';
import moment from 'moment';
import Transaction from './Transaction';

class Payment {
  id: number;
  currency: TCurrency;
  type: TPaymentType;
  status: TPaymentStatus;
  amount: number;
  payer: Member | undefined;
  refunder: Member | undefined;
  transactions: Transaction[];
  createdAt: Date;
  updatedAt: Date;
  paymentType: TPaymentType;

  constructor(data: IPaymentRes) {
    this.id = data.id;
    this.currency = data.currency;
    this.type = data.payment_type;
    this.status = data.status;
    this.amount = parseFloat(data.amount);
    this.payer = data.payer ? new Member(data.payer) : undefined;
    this.refunder = data.refunder ? new Member(data.refunder) : undefined;
    this.transactions = (data.transactions || []).map(t => new Transaction(t));
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
    this.paymentType = data.payment_type;
  }

  getStatus() {
    return this.status || 'none';
  }

  getPayer() {
    return this.payer;
  }

  getUpdatedDate(format = DATE_TIME_FORMAT_WITH_COMMA) {
    return moment(this.updatedAt).format(format);
  }

  getRefunder() {
    return this.refunder;
  }

  getPaymentType() {
    return this.paymentType || '';
  }

  getAmount() {
    return this.amount || 0;
  }

  getTransactions() {
    const transactions = this.transactions.sort(
      (t, tn) => new Date(tn.updatedAt).getTime() - new Date(t.updatedAt).getTime()
    );
    return transactions;
  }

  getLatestTransaction() {
    const latestTransaction = this.transactions.sort(
      (t, tn) => new Date(tn.createdAt).getTime() - new Date(t.createdAt).getTime()
    );
    return latestTransaction[0];
  }
}

export default Payment;
