import { Dot, Stack, Text } from '../../elements';
import t from 'resources/translations';
import { TStackProps } from 'resources/types/elementsTypes';
import { TParticipationStatus } from 'resources/types/participationsTypes';

const stylesProps: { container: TStackProps } = {
  container: {
    alignItems: 'center',
    radius: 'full',
    px: 'sm',
    py: 2,
  },
};
type TParticipationStatusLabel = {
  status?: TParticipationStatus;
};
const ParticipationStatusLabel = ({ status = 'none' }: TParticipationStatusLabel) => {
  const stausesColors = {
    invitation_requested: { bgColor: 'teal-dark', color: 'white' },
    joined: { bgColor: 'transparent', color: 'new-green' },
    invited: { bgColor: 'rgba(0, 160, 173, 0.2)', color: 'teal-dark' },
    rejected: { bgColor: 'transparent', color: 'orange' },
    none: { bgColor: 'transparent', color: 'grey-dark' },
  };

  return (
    <Stack spacing="xs" {...stylesProps.container} {...stausesColors[status]} isHorizontal>
      <Dot size={6} bgColor={stausesColors[status]?.color} />
      {/* @ts-ignore */}
      <Text color={stausesColors[status]?.color}>{t(`dashboard.statuses.${status}`)}</Text>
    </Stack>
  );
};

export default ParticipationStatusLabel;
