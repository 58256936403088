import React from 'react';
import Event from 'models/Event';
import Participation from 'models/Participation';
import t from 'resources/translations';
import { Button, Flex, Link, Stack, Ternary, Text } from 'components/elements';
import { AvatarWithLabel, Switch } from 'components/combinations';
import { useMutation, useQueryClient } from 'react-query';
import { usePermissions } from 'Permissions';
import { ParticipationService } from 'services';
import { IParticipationRes } from 'resources/types/participationsTypes';
import { mFetchedBillInEvents, mParticipationToEvents } from 'utils';
import { TCacheKey } from 'resources/types/cacheTypes';
import { IBillRes } from 'resources/types/billsTypes';
import { useModal } from 'old/hooks';

const ActiveEventParticipant = ({
  event,
  participation,
  isSingleMode,
}: {
  event: Event;
  participation: Participation;
  isSingleMode: boolean;
}) => {
  const { member, attendance, bill } = participation;
  const queryClient = useQueryClient();

  const queryKeys: TCacheKey[] = ['DASBOARD_EVENTS_TODAY', 'DASHBOARD_EVENTS'];
  const paymentModal = useModal('PAYMENT_EVENT');
  const returnModal = useModal('PAYMENT_RETURN');

  const checkSetPresence = usePermissions('PARTICIPATION_SET_PRESENCE');
  const canISetPayment = usePermissions('BILL_SET_PAYMENT')(bill, participation);
  const canIReturnPayment = usePermissions('BILL_RETURN_PAYMENT')(bill, participation);

  const setPresenceMutation = useMutation(() => ParticipationService.setPresence(event.id, participation), {
    onSuccess: (p: IParticipationRes) => mParticipationToEvents(queryClient, queryKeys, p),
  });

  const onOpenPaymenModal = () => {
    const mutationCallback = (billRes: IBillRes) =>
      mFetchedBillInEvents(queryClient, ['DASBOARD_EVENTS_TODAY', 'DASHBOARD_EVENTS'], billRes);
    return paymentModal.onOpen({
      bill: participation.bill,
      onSubmitCb: mutationCallback,
      onChangeDueCb: mutationCallback,
    });
  };

  const onOpenReturnPaymenModal = () => {
    if (bill) {
      const onSubmitCb = () => mFetchedBillInEvents(queryClient, ['DASBOARD_EVENTS_TODAY', 'DASHBOARD_EVENTS'], bill);
      return returnModal.onOpen({ bill, onSubmitCb });
    }
  };

  return (
    <Flex key={member.id} minH={42}>
      <Flex flex={4}>
        <AvatarWithLabel
          src={member.getAvatar('medium')}
          alt={member.getFullName()}
          title={member.getFullName()}
          squareSize={26}
        >
          <Link to={member.getProfileUrl()} fontSize={isSingleMode ? 'base' : 'sm'}>
            {member.getFullName()}
          </Link>
        </AvatarWithLabel>
      </Flex>
      <Stack flex={1} alignItems="center" justifyContent="flex-end" spacing="sm" isHorizontal>
        <Ternary cond={attendance === 'absent'}>
          <Text fontSize="sm">{t('eventParticipationsManage.absent')}</Text>
          <Text fontSize="sm" color="green-light">
            {t('eventParticipationsManage.attending')}
          </Text>
        </Ternary>
        {checkSetPresence(event, participation) && (
          <Switch
            id={`${member.id}-${event.id}`}
            name={`participation-${member.id}`}
            checked={participation.attendance === 'attending'}
            onChange={() => setPresenceMutation.mutateAsync()}
            disabled={setPresenceMutation.isLoading}
          />
        )}
      </Stack>
      {(canISetPayment || canIReturnPayment) && (
        <Flex minW={80} pr="md" justifyContent="flex-end">
          {canISetPayment && (
            <Button fontSize="base" variant="link" onClick={() => onOpenPaymenModal()}>
              {t('buttons.settle')}
            </Button>
          )}
          {canIReturnPayment && (
            <Button fontSize="base" variant="link" onClick={() => onOpenReturnPaymenModal()}>
              {t('buttons.return')}
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default React.memo(ActiveEventParticipant);
