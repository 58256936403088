/* eslint-disable max-len */
import React from 'react';

const RepeatEvent = () => {
  return (
    <svg width="100%" viewBox="0 0 23.4 26">
      <path d="M5.2,6.5h13v3.9l5.2-5.2L18.2,0V3.9H2.6v7.8H5.2Zm13,13H5.2V15.6L0,20.8,5.2,26V22.1H20.8V14.3H18.2Z" />
    </svg>
  );
};

export default RepeatEvent;
/* eslint-enable max-len */
