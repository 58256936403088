import {
  IBillEvent,
  IBillInstructor,
  IBillRes,
  TBillableType,
  TBillPaymentStatus,
  TCurrency,
  TPaymentType,
} from 'resources/types/billsTypes';
import Member from './Member';
import _ from 'lodash';
import Payment from './Payment';

class Bill {
  id: number;
  currency: TCurrency;
  billed: Member | null;
  billableType: TBillableType;
  billableId: number;
  paidAt: Date | null;
  payments: Payment[];
  dueAmount: number;
  paidAmount: number;
  event: IBillEvent;
  ticket: {
    id: number | null;
    name: string | null;
  };
  instructors: IBillInstructor[];
  status: TBillPaymentStatus;
  paymentType: TPaymentType;
  createdAt: Date;
  updatedAt: Date;
  eventStartAt: Date;

  constructor(data: IBillRes) {
    this.id = data.id;
    this.currency = data.currency;
    this.billed = data.billed ? new Member(data.billed) : null;
    this.billableType = data.billable_type;
    this.billableId = data.billable_id;
    this.paidAt = data.paid_at ? new Date(data.paid_at) : null;
    this.payments = (data.payments || [])
      .sort((t, tn) => new Date(tn.updated_at).getTime() - new Date(t.updated_at).getTime())
      .map(p => new Payment(p));
    this.dueAmount = parseFloat(data.due_amount);
    this.paidAmount = parseFloat(data.paid_amount);
    this.event = {
      id: data.event_id,
      name: data.event_name,
      startAt: new Date(data.event_start_at),
    };
    this.instructors = (data.instructors || []).map(i => ({ id: i.id, name: i.instructor_name }));
    this.status = data.payment_status;
    this.paymentType = data.payment_type;
    this.ticket = {
      id: data.billable_type === 'Ticket' ? data.billable_id : null,
      name: data.ticket_name,
    };
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
    this.eventStartAt = new Date(data.event_start_at);
  }

  getBookedPayments() {
    return this.payments.filter(p => p.status === 'booked') || [];
  }

  getDue() {
    return this.dueAmount;
  }

  getPaidAmount() {
    return this.paidAmount;
  }

  getAllTransactions() {
    return _.flatten(this.payments.map(p => p.transactions));
  }

  getPendingTransaction() {
    const transactions = this.getAllTransactions();
    return transactions.find(t => t.status === 'pending');
  }

  isPaid() {
    return this.status === 'paid';
  }

  isOverpaid() {
    return this.status === 'overpaid';
  }

  getName() {
    if (this.event.name) return this.event.name;
    return this.ticket.name;
  }

  getCurrentTransaction() {
    const transactions = this.getAllTransactions();
    const failureTransaction = transactions.find(t => t.status === 'failure' && !t.isExpired());
    if (failureTransaction) {
      return failureTransaction;
    }
    const pendingTransaction = transactions.find(t => t.status === 'pending' && !t.isExpired());
    if (pendingTransaction) {
      return pendingTransaction;
    }
    return null;
  }
}

export default Bill;
