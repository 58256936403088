/**
 * @module GoogleMap
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GoogleMaps from '@old/components/old/GoogleMap';

/**
 * Components that display google Maps and get coordinates from there
 *
 * @param  {Number[]} [props.value] gps coordinates example values: value[0]=19.9449799 value[1]=50.0646501
 * @param  {Function} props.onChange a function that sets the coordinates from the google map to the input field after clicking on the map
 * @param  {Number} [props.zoom] takes a number with the higher value representing a tighter focus on the map's center
 */
const GoogleMap = ({ value, onChange, zoom }) => {
  const [lat, setLat] = useState(value[0]);
  const [lng, setLng] = useState(value[1]);

  const handleClick = (e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
    onChange([e.latLng.lat(), e.latLng.lng()]);
  };

  return (
    <div className="h-64">
      <GoogleMaps
        onMapClick={handleClick}
        lat={lat}
        lng={lng}
        zoom={zoom}
        showMarker
      />
    </div>
  );
};

GoogleMap.defaultProps = {
  value: {
    lat: 0,
    lng: 0,
  },
  zoom: 12,
};

GoogleMap.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default GoogleMap;
