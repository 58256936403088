import styled from 'styled-components';
import { baseStyledProps, blockStyleProps, typographyStyleProps } from 'config/styledProps';
import { TTextareaProps } from 'resources/types/elementsTypes';

const Textarea = styled('textarea')<TTextareaProps>(baseStyledProps, typographyStyleProps, blockStyleProps);

Textarea.defaultProps = {
  py: 0,
  fontSize: 'base',
  lineHeight: 'normal',
};

export default Textarea;
