/* eslint-disable */
import React from 'react';

const Drag = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <g>
        <path d="M21.4,3.1c-0.6,0-1.2,0.2-1.7,0.5c-0.4-1.2-1.6-2-2.9-2c-0.6,0-1.2,0.2-1.7,0.5c-0.4-1.2-1.6-2-2.9-2
          s-2.5,0.9-2.9,2C8.9,1.7,8.3,1.6,7.7,1.6C6,1.6,4.6,2.9,4.6,4.6v3.5L3.1,9.6c-1,1-1.6,2.4-1.6,3.8v2.3c0,1.4,0.6,2.8,1.6,3.8
          l3.1,3.1c2.2,2.2,5.2,3.5,8.4,3.5c5.5,0,9.9-4.5,9.9-9.9V6.1C24.5,4.5,23.1,3.1,21.4,3.1z M23,16.1c0,4.6-3.8,8.4-8.4,8.4
          c-2.8,0-5.3-1.1-7.3-3l-3.1-3.1c-0.7-0.7-1.1-1.7-1.1-2.7v-2.3c0-1,0.4-2,1.1-2.7l0.4-0.4v1.2c0,0.4,0.3,0.8,0.8,0.8
          c0.4,0,0.8-0.3,0.8-0.8V8.4c0,0,0,0,0,0V4.6c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v2.3c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8
          V3.1c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v3.8c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8V4.6c0-0.8,0.7-1.5,1.5-1.5
          s1.5,0.7,1.5,1.5v2.3c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V6.1c0-0.8,0.7-1.5,1.5-1.5S23,5.3,23,6.1V16.1z"
        />
      </g>
    </svg>
  );
};

export default Drag;
/* eslint-enable */
