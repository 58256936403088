/* eslint-disable max-len */
import React from 'react';

const Spiner = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 50 50"
    >
      <path d="M5.4,24.9c0,5.4,2.2,10.3,5.7,13.9l-3.7,3.7C2.8,38,0,31.7,0,24.8c0-6.7,2.6-12.7,6.9-17.2l3.8,3.8C7.4,15,5.4,19.7,5.4,24.9z" />
      <path d="M50,24.8c0,6.6-2.6,12.6-6.8,17.1l-3.6-3.6c3.3-3.5,5.2-8.2,5.2-13.4c0-5-1.9-9.5-4.9-13l3.7-3.7C47.6,12.7,50,18.5,50,24.8z" />
    </svg>
  );
};

export default Spiner;
/* eslint-enable max-len */
