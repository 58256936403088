/**
 * @module SelectInput
 * */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import InputWrapper from '@old/components/common/InputWrapper';
import SelectMultiValueContainer from '@old/components/old/SelectMultiValueContainer';
import SelectMultiValueRemove from '@old/components/old/SelectMultiValueRemove';
import t from 'resources/translations';

/**
 * @typedef  {Object} SelectOption
 * @property {String|Number} value
 * @property {String} label
 *
 * @typedef  {Object} InputOptions
 * @property {String|Number} key
 * @property {String} label
 * @property {String|Number} value
 */

/**
 * Component displays select with multi options.
 * @param  {SelectOption[]} [props.value] value of input field
 * @param  {Function} props.onChange function detects when the value of an input changes and run function delegated in props
 * @param  {InputOptions[]} props.options options with custom field key, value, label
 * @param  {String} [props.placeholder]
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 * @param  {*} [props.customOptionComponents] Option with custom: label, slug, color
 * @param  {String} [props.name]
 * @param  {Boolean} [props.isSearchable]
 */

const MultiSelectInput = ({
  value,
  onChange,
  options,
  placeholder,
  label,
  required,
  customOptionComponents,
  name,
  isSearchable,
}) => {
  const OptionComponent = customOptionComponents ? { Option: customOptionComponents } : {};
  const MultiValueContainer = { MultiValueContainer: SelectMultiValueContainer };
  const MultiValueRemove = { MultiValueRemove: SelectMultiValueRemove };

  return (
    <InputWrapper label={label} required={required} name={name}>
      <Select
        components={{
          ...OptionComponent,
          ...MultiValueContainer,
          ...MultiValueRemove,
        }}
        className="select"
        classNamePrefix="select"
        options={options}
        onChange={val => onChange(val, name)}
        placeholder={placeholder}
        noOptionsMessage={() => <div>{t('message.noResults')}</div>}
        loadingMessage={() => <div>{t('general.loading')}</div>}
        closeMenuOnSelect={false}
        value={value}
        isSearchable={isSearchable}
        isMulti
      />
    </InputWrapper>
  );
};

MultiSelectInput.defaultProps = {
  value: [],
  placeholder: '',
  label: '',
  required: false,
  customOptionComponents: null,
  name: '',
  isSearchable: true,
};

MultiSelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ).isRequired,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  isSearchable: PropTypes.bool,
};

export default MultiSelectInput;
