/**
 * @module DndWrapper
 */
import React from 'react';
import PropTypes from 'prop-types';
import { isMobile as isMobileDevices } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

/**
 * Component that adds Drag and Drop functionality to child
 * @param  {Node} props.children
 */
const DndWrapper = ({ children }) => (
  <DndProvider backend={isMobileDevices ? TouchBackend : HTML5Backend}>
    {children}
  </DndProvider>
);

DndWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DndWrapper;
