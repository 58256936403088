import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@old/components/icon';
// Carousel has 3 modes, centerMode, normal, and centerModeWithoutArrows if perView is set to less than 3.
// PerView supports odd numbers.
// Autoplay is not yet implemented.

const Slider = ({ perView, centerMode, images, arrows, className, onClick, rounded, isMobile }) => {
  const containerRef = useRef();

  const centerModeWithoutArrows = centerMode && (perView < 3);

  const options = {
    // in centerMode with 1 or 2 images perView, current image is smaller
    offsetRatio: centerModeWithoutArrows ? 0.7 : 1,
    // number of items on one side of the carousel
    itemsOnOneSide: Math.round(perView / 2) - 1,
    // inital index of current item
    initCurrentImage: Math.round(perView / 2),
  };

  const [refreshSlider, setRefreshSlider] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(options.initCurrentImage);
  const [slideWidth, setSlideWidth] = useState(0); // spacing from left and right side added to transform
  const [slideOffset, setSlideOffset] = useState(0); // translateValue is used to move the carousel
  const [translateValue, setTranslateValue] = useState(0); // translateValue is used to move the carousel

  const [touchParams, setTouchParams] = useState({
    left: 0,
    originalOffset: 0,
    touchStartX: 0,
    beingTouched: false,
  });

  const arrowWidth = centerModeWithoutArrows ? slideOffset : slideWidth; // width of arrows

  // useEffect used to re-scale the carousel after resize window
  useEffect(() => {
    const updateSlideWidth = debounce(() => {
      if (containerRef.current) {
        setRefreshSlider(true);
      }
    }, 500);

    window.addEventListener('resize', updateSlideWidth);
    return () => {
      window.removeEventListener('resize', updateSlideWidth);
    };
  }, []);

  // useEffect used to re-scale the carousel after refreshSlider is true
  useEffect(() => {
    if (refreshSlider) {
      calculateSliderValues();
    }
    setRefreshSlider(false);
    // eslint-disable-next-line
  }, [refreshSlider]);

  // useEffect used to re-scale the carousel after render
  useEffect(() => {
    setTimeout(() => {
      calculateSliderValues();
    }, 500);
    // eslint-disable-next-line
  }, [containerRef, images.length, isMobile]);

  // calculate new slider values after when resize wrap container or change active index
  const calculateSliderValues = (index = null, onlyTranslate = false) => {
    if (containerRef.current) {
      const newIndex = (index || currentIndex);
      let width;

      if (!onlyTranslate) {
        const orginalSlideWidth = containerRef.current.clientWidth / perView;
        const offsetLeft = orginalSlideWidth * ((1 - options.offsetRatio) / 2);
        width = orginalSlideWidth * options.offsetRatio;

        setSlideWidth(width);
        setSlideOffset(offsetLeft);
      }

      const newWidth = width || slideWidth;
      if (options.itemsOnOneSide < newIndex && (images.length - options.itemsOnOneSide) >= newIndex) {
        setTranslateValue(-newWidth * (newIndex - options.initCurrentImage));
        setCurrentIndex(newIndex);
      }
    }
  };

  const goToPrevSlide = () => {
    const prevIndex = currentIndex - 1;
    calculateSliderValues(prevIndex, true);

    if (centerMode && prevIndex > 0) {
      setCurrentIndex(prevIndex);
    }
  };

  const goToNextSlide = () => {
    const nextIndex = currentIndex + 1;
    calculateSliderValues(nextIndex, true);
    if (centerMode && nextIndex <= (images.length)) {
      setCurrentIndex(nextIndex);
    }
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    handleStart(e.targetTouches[0].clientX);
  };

  const handleStart = (clientX) => {
    setTouchParams({
      ...touchParams,
      originalOffset: touchParams.left,
      touchStartX: clientX,
      beingTouched: true,
    });
  };

  const handleTouchMove = (e) => {
    handleMove(e.targetTouches[0].clientX);
  };

  const handleMove = (clientX) => {
    if (touchParams.beingTouched) {
      const deltaX = clientX - touchParams.touchStartX + touchParams.originalOffset;

      setTouchParams({
        ...touchParams,
        left: deltaX,
      });
    }
  };

  const handleEnd = () => {
    const checkValue = touchParams.left / slideWidth;
    if (checkValue > 0.3) {
      goToPrevSlide();
    } else if (checkValue < -0.3) {
      goToNextSlide();
    }

    setTouchParams({
      ...touchParams,
      touchStartX: 0,
      beingTouched: false,
      left: 0,
    });
  };

  if (!images.length) return null;

  const renderArrows = () => {
    const hideLeftArrow = centerMode
      ? currentIndex !== 1
      : currentIndex > options.initCurrentImage;
    const hideRightArrow = centerMode
      ? currentIndex !== images.length
      : (currentIndex + options.itemsOnOneSide) < images.length;

    if (perView === images.length) return null;

    return (
      <React.Fragment>
        {hideLeftArrow && (
          <SliderArrow
            onClick={goToPrevSlide}
            direction="left"
            width={arrowWidth}
            noIcon={centerModeWithoutArrows}
          />
        )}
        {hideRightArrow && (
          <SliderArrow
            onClick={goToNextSlide}
            direction="right"
            width={arrowWidth}
            noIcon={centerModeWithoutArrows}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div
        ref={containerRef}
        className={cls('overflow-hidden relative', className)}
        onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
        onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
        onTouchEnd={handleEnd}
      >
        {arrows && renderArrows()}
        <div
          style={{
            width: (images.length * slideWidth),
            display: 'flex',
            height: slideWidth - 24,
            transform: `translateX(${translateValue + slideOffset + touchParams.left}px)`,
            transition: `transform ease-out ${touchParams.beingTouched ? 0.05 : 0.45}s`,
            willChange: 'width, transform',
          }}
        >
          {images.map((image, i) => (
            <Slide
              key={i}
              image={image}
              width={slideWidth}
              index={i + 1}
              current={currentIndex === (i + 1)}
              centerMode={centerMode}
              onClick={onClick}
              rounded={rounded}
            />
          ))}
        </div>
      </div>
    </React.Fragment>

  );
};

Slider.defaultProps = {
  perView: 3,
  centerMode: false,
  images: [],
  arrows: true,
  className: '',
  onClick: () => { },
  rounded: false,
};

Slider.propTypes = {
  perView: PropTypes.number,
  centerMode: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.shape({
        thumb: PropTypes.string,
        small: PropTypes.string,
        medium: PropTypes.string,
        large: PropTypes.string,
      }),
      description: PropTypes.string,
    }),
  ),
  arrows: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app }) => ({ isMobile: app.isMobile });

export default connect(mapStateToProps)(Slider);

export const Slide = ({ index, image, width, current, centerMode, onClick, rounded }) => {
  const [onHover, setOnHover] = useState(false);

  const styles = {
    height: width - 24,
    backgroundImage: `url(${image.url.medium})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 60%',
    transform: (!current && centerMode) && 'scale(0.85)',
    transition: 'transform linear 0.25s',
  };

  const onMouseEnter = () => {
    setOnHover(true);
  };

  const onMouseLeave = () => {
    setOnHover(false);
  };

  return (
    <div
      style={{ width, padding: '0 12px' }}
      className={cls('relative overflow-hidden')}
    >
      <div
        onClick={() => onClick({ index, image })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cls('relative cursor-pointer rounded', { 'z-10': current, 'rounded-full': rounded })}
        style={styles}
      >
        <div
          className={cls('absolute left-0 top-0 w-full h-full', { 'rounded-full': rounded })}
          style={{ backgroundColor: onHover && 'rgba(0,0,0,0.2)' }}
        />
        {image.description && (current || !centerMode) && !rounded && ( // add contidion if description exists
          <div
            className="absolute left-0 bottom-0 p-4 text-white w-full truncate select-none"
            style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
          >
            <span>{image.description}</span>
          </div>
        )}
      </div>
    </div>
  );
};

Slide.defaultProps = {
  centerMode: false,
  onClick: () => { },
  rounded: false,
};

Slide.propTypes = {
  width: PropTypes.number.isRequired,
  current: PropTypes.bool.isRequired,
  centerMode: PropTypes.bool,
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
  image: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.shape({
      thumb: PropTypes.string,
      small: PropTypes.string,
      medium: PropTypes.string,
      large: PropTypes.string,
    }),
    description: PropTypes.string,
  }).isRequired,
  rounded: PropTypes.bool,
};

const SliderArrow = ({ onClick, direction, width, noIcon }) => {
  const icon = direction === 'left'
    ? <Icon.ArrowLeft className="fill-white" />
    : <Icon.ArrowRight className="fill-white" />;
  const customClassName = direction === 'left' ? 'left-0' : 'right-0 justify-end';
  const style = { width, paddingLeft: '3%', paddingRight: '3%' };
  return (
    <div
      className={cls('flex items-center absolute h-full z-20 cursor-pointer', customClassName)}
      onClick={onClick}
      style={style}
    >
      {!noIcon && icon}
    </div>
  );
};

SliderArrow.defaultProps = {
  noIcon: false,
};

SliderArrow.propTypes = {
  width: PropTypes.number.isRequired,
  noIcon: PropTypes.bool,
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
