import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalButtons,
  AvatarWithLabel,
} from 'components/combinations';
import { Box, Button, Flex, Label, MotionBox, Stack, Text, Icons, TextTruncate } from 'components/elements';
import { getErrorMessage, notify } from 'old/utils';
import Select from 'components/combinations/inputs/Select';
import AmountInput from 'components/combinations/inputs/Amount';
import { Divider } from 'components/combinations';
import { DATE_FORMAT_WITH_COMMA, PAYMENT_STATUS_COMPACT } from 'config/constans';
import t from 'resources/translations';
import { BillService, TicketService } from 'services';
import { useMutation } from 'react-query';
import { TWrappedModal, TStoreStateModals } from 'views/modals';
import Bill from 'models/Bill';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import { IBillRes } from 'resources/types/billsTypes';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { TImageProps } from 'resources/types/elementsTypes';
import FormLabel from 'components/combinations/forms/FormLabel';
import { useBillChangeDueAmount, yup } from 'utils';
import PaymentTypesOption from 'components/combinations/select/PaymentTypesOption';
import { RadioInputOptions } from 'resources/types/commonTypes';
import { useQuery } from 'react-query';
import _, { isNumber } from 'lodash';
import Ticket from 'models/Ticket';
import RadioInputGropup from 'components/combinations/forms/RadioGroupInput';
import { useShallowSelector } from 'old/hooks';
import { CSSObject } from '@styled-system/css';

const avatarStyles: TImageProps = {
  p: 2,
  width: 50,
  height: 50,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'teal-dark',
  borderRadius: 9999,
};

type TPaymentModalProps = {
  bill: Bill;
  onSubmitCb: (billRes: IBillRes) => void;
  onChangeDueCb?: (billRes: IBillRes) => void;
};

const BillSetPaymentEvent = ({ onUpdate, onSubmitAndClose, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const { bill, onSubmitCb, onChangeDueCb } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['PAYMENT_EVENT'].props
  ) as TPaymentModalProps;

  const [paymentType, setPaymentType] = useState(PAYMENT_STATUS_COMPACT[0]);
  const [ticketOption, setTicketOption] = useState<string | number>();
  const availableTicketsState = useQuery(['TICKETS_FOR_BILL', bill.id], () => TicketService.available(bill.id));
  const setPayment = (billId: number) => BillService.addPaymentByBillId(billId, paymentType.value);
  const mutAddPayment = useMutation(setPayment, { onSuccess: onSubmitCb });
  const setTicketPayment = ({ billId, ticketId }: { billId: number; ticketId: number | string }) =>
    BillService.addTicketPayment(billId, ticketId);
  const mutSetTicketPayment = useMutation(setTicketPayment, { onSuccess: onSubmitCb });
  const isTicketPayment = paymentType.value === 'ticket';
  useEffect(() => {
    return () => availableTicketsState.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentType.value === 'ticket' && _.isEmpty(ticketOption)) {
      setTicketOption(availableTicketsState.data?.tickets[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType.value]);

  const dueAmountState = useBillChangeDueAmount(
    bill,
    billRes => {
      !!onChangeDueCb && onChangeDueCb(billRes);
      onUpdate({ bill: new Bill(billRes) });
    },
    isTicketPayment
  );

  const onSubmit = async () => {
    try {
      if (isTicketPayment && ticketOption) {
        await mutSetTicketPayment.mutateAsync({ billId: bill.id, ticketId: ticketOption });
      } else {
        await mutAddPayment.mutateAsync(bill.id);
      }
      notify(t('message.succesSave'));
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };
  const getOptions = () => {
    if (isTicketPayment) {
      return availableTicketsState.data?.tickets.map(ticketRes => {
        const newTicket = new Ticket(ticketRes);
        return {
          value: newTicket.id,
          label: newTicket.getName(),
          node: (
            <Stack spacing="xs">
              <Text>{newTicket.getName()}</Text>
              <Text fontSize="sm">
                {moment(newTicket.activeAt).format(DATE_FORMAT_WITH_COMMA)} -{' '}
                {moment(newTicket.expireAt).format(DATE_FORMAT_WITH_COMMA)}
              </Text>
            </Stack>
          ),
        };
      }) as RadioInputOptions[];
    }
    return [] as RadioInputOptions[];
  };

  const isBlockedModal = dueAmountState.mutation.isLoading || isSubmitting;
  const paymentOptionsTypes = PAYMENT_STATUS_COMPACT.map(type => {
    if (type.value === 'ticket') {
      return {
        ...type,
        loadingMessage: 'Wyszukiwanie dostępnych karnetów.',
        loading: availableTicketsState.isLoading,
        isDisabled: availableTicketsState.isLoading || _.isEmpty(availableTicketsState.data?.tickets),
      };
    }
    return type;
  });
  const validationSchema = yup.object().shape({
    price: yup.string().maxNumberFromString(4000).required(t('error.fieldIsRequired')),
  });

  const isValid = validationSchema.isValidSync({ price: dueAmountState.inputProps.value });

  return (
    <Modal isHidden={isHidden} onClose={onClose} isBlocked={isBlockedModal} isOpen>
      <ModalHeader>{t('bills.customerPayment')}</ModalHeader>
      <ModalCloseButton onClose={onClose} disabled={isBlockedModal} />
      <ModalContent isMakingRequest={isSubmitting}>
        <Flex px={[0, 'base']} pt={[0, 'base']}>
          {!!bill.billed ? (
            <AvatarWithLabel
              src={bill.billed.getAvatar('small')}
              alt={bill.billed.getFullName()}
              title={bill.billed.getFullName()}
              spacing="md"
              {...avatarStyles}
            >
              <Text fontSize="md" fontWeight="medium">
                {bill.billed.getFullName()}
              </Text>
            </AvatarWithLabel>
          ) : (
            <Text fontSize="md" fontWeight="medium">
              {t('general.userDeleted')}
            </Text>
          )}
        </Flex>
        <ModalDescription>
          <Stack fontSize="base" spacing="sm">
            <Text fontWeight="bold">{t('bills.event')}:</Text>
            <Text>{moment(bill.event.startAt).format('DD.MM.YYYY, dddd')}</Text>
            <TextTruncate lines={2}>{bill.getName()}</TextTruncate>
          </Stack>
        </ModalDescription>

        <Stack spacing="xs">
          <Label htmlFor="dueAmount">{t('labels.due')}:</Label>
          <AmountInput
            name="dueAmount"
            intlConfig={{ locale: 'pl', currency: 'PLN' }}
            groupSeparator=" "
            maxLength={11}
            allowNegativeValue={false}
            decimalScale={2}
            disabled={isTicketPayment}
            color={isTicketPayment ? 'grey-light' : 'grey-dark'}
            {...dueAmountState.inputProps}
          />
          {!isValid && (
            <Flex mt="sm">
              <Text color="orange" fontSize="sm">
                {t('error.lowerThanOrEqualPrice', { count: 4000 })}
              </Text>
            </Flex>
          )}
          <Box>
            {!!bill && dueAmountState.mutation.isLoading && (
              <Stack spacing="xs" mt="xs" alignItems="center" isHorizontal>
                <MotionBox
                  width="auto"
                  transition={{ ease: 'linear', duration: 2, repeat: Infinity }}
                  animate={{ rotate: 360 }}
                >
                  <Icons.Spinner fill="teal-dark" />
                </MotionBox>
                <Text fontSize="sm">{t('message.saving')}...</Text>
              </Stack>
            )}
            {!!bill && dueAmountState.statuses.isReadyToSave && isValid && (
              <Button variant="link" mt="xs" w="auto" onClick={dueAmountState.onSubmitDueAmount}>
                <Stack spacing="xs" alignItems="center" isHorizontal>
                  <Icons.Accept borderColor="currentColor" borderWidth={1} borderRadius={9999} p={2} />
                  <Text fontSize="sm">{t('buttons.saveChanges')}</Text>
                </Stack>
              </Button>
            )}
            {!!bill && dueAmountState.statuses.isChanged && (
              <Stack spacing="xs" mt="xs" alignItems="center" isHorizontal>
                <Icons.Accept size={10} fill="teal-dark" />
                <Text color="teal-dark" fontSize="sm">
                  {t('message.dueAmountChanged')}
                </Text>
              </Stack>
            )}
          </Box>
        </Stack>
        <Divider variant="dashed" color="grey-light" />
        <Stack>
          <Box>
            <FormLabel htmlFor="paymentType">{t('bills.paymentMethod')}</FormLabel>
            <Box>
              <Select
                name="paymentType"
                value={paymentType}
                options={paymentOptionsTypes}
                onChange={setPaymentType}
                isSearchable={false}
                menuPortalTarget={document.body}
                maxMenuHeight={150}
                customStyles={{
                  menuPortal: (base: CSSObject) => ({
                    ...base,
                    zIndex: 9999,
                    top: isNumber(base.top) ? base.top - 63 : 0,
                  }),
                }}
                components={{ Option: PaymentTypesOption }}
                isOptionDisabled={(option: any) => option.isDisabled || option.loading}
              />
            </Box>
          </Box>
          {isTicketPayment && (
            <Box>
              <FormLabel htmlFor="ticket">{t('labels.availableTickets')}</FormLabel>
              <Stack pt="sm">
                <RadioInputGropup
                  value={ticketOption || ''}
                  name="ticket"
                  onChange={(_, value) => setTicketOption(value)}
                  options={getOptions() || []}
                />
              </Stack>
            </Box>
          )}
        </Stack>
      </ModalContent>
      <ModalButtons>
        <Button onClick={() => onSubmitAndClose(onSubmit)} disabled={dueAmountState.mutation.isLoading}>
          {isSubmitting ? <SpinnerDots label={t('bills.addingPayment')} inline /> : t('bills.addPayment')}
        </Button>
        <Button onClick={onClose} variant="outline">
          {t('general.cancel')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default BillSetPaymentEvent;
