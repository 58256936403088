/**
 * @module Tags
 */
import React from 'react';
import PropTypes from 'prop-types';

import Tag from '@old/components/common/Tag';
/**
 * Items displayed in Tag
 * @typedef   {Object} TagItem
 * @property  {String|Number} key tag key
 * @property  {String} label label of the tag
 */
/**
 * Component for displaying single/multiple tag components
 * @param  {TagItem} props.items
 * @param  {Function} props.onDelete function that specifies what happens after "X" is clicked
 * @param  {Function|Boolean=false} [props.customRenderTag] custom method tag label rendering
 */
const Tags = ({ items, customRenderTag, onDelete }) => (
  <div className="tags">
    {items.map(item => (
      <Tag key={item.key} item={item} customRenderTag={customRenderTag} onDelete={onDelete} />
    ))}
  </div>
);

Tags.defaultProps = {
  customRenderTag: false,
};

Tags.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  customRenderTag: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([false]),
  ]),
};

export default Tags;
