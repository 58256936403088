/* eslint-disable max-len */
import React from 'react';

const NumberUsers = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <g transform="translate(9.548 19.319)">
        <g>
          <path d="M-5.7-12.8h-2.2c-0.9,0-1.6,0.8-1.6,1.7s0.7,1.6,1.6,1.6h0.6v7.9C-7.3-0.7-6.6,0-5.7,0S-4.1-0.7-4-1.6 v-9.6C-4.1-12.1-4.8-12.8-5.7-12.8z" />
        </g>
        <g transform="translate(27.535 0.222)">
          <path d="M-12.1-6.6L-12.1-6.6c1-1.1,1.3-2.7,0.7-4c-0.6-1.4-2-2.2-3.4-2.2l0,0c-2,0-3.7,1.6-3.7,3.7 c0,0,0,0,0,0.1c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.5,0.4-0.9,0.9-0.9l0,0c0.6,0,0.7,0.3,0.9,0.5 c0.3,0.6,0.1,0.9-0.1,1.1l-0.9,0.9c-0.4,0.6-0.4,1.4,0,1.9l0.9,1c0.3,0.3,0.3,0.7,0.1,1c-0.1,0.4-0.5,0.6-0.9,0.6l0,0 c-0.5,0-0.9-0.4-0.9-0.9c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3c0,2,1.6,3.7,3.7,3.7c0,0,0,0,0.1,0l0,0c1.5,0,2.8-0.9,3.4-2.2 C-10.8-3.7-11.1-5.4-12.1-6.6L-12.1-6.6z" />
        </g>
      </g>
      <path d="M10.4,11h5.4c1.1,0,2,0.9,2,2l0,0c0,1.1-0.9,2-2,2h-5.4c-1.1,0-2-0.9-2-2l0,0C8.4,11.9,9.3,11,10.4,11z" />
    </svg>
  );
};

export default NumberUsers;
/* eslint-enable max-len */
