import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Grid } from '@elements';

import Icon from '../icon';
import Avatar from '../common/Avatar';

const AddAvatar = ({ onChange, value, isMobile, avatars, label }) => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const onClick = (image, isChecked) => {
    setSelectedAvatar(isChecked ? null : image);
    onChange(isChecked ? null : image);
  };

  useEffect(() => {
    if (value) {
      setSelectedAvatar(value);
    }
  }, [value]);

  const columns = isMobile ? 2 : 5;

  return (
    <>
      {label && (<div className="font-bold text-teal-light text-md mb-4">{label}</div>)}
      <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap="md">
        {avatars.map(({ image }) => {
          return (
            <div
              key={image}
              style={{ width: '122px', borderWidth: 1, borderRadius: 9999 }}
              onClick={() => { onClick(image, selectedAvatar === image); }}
              className={cls(
                'select-avatar relative', { 'border-teal-dark': selectedAvatar === image },
              )}
            >
              <div className={cls('hidden', { 'icon-selected': selectedAvatar === image })}>
                <Icon.SelectedAvatar className="fill-teal" />
              </div>
              <Avatar key={image} src={image} big />
            </div>
          );
        })}
      </Grid>
    </>
  );
};

AddAvatar.defaultProps = {
  value: '',
  label: '',
};

AddAvatar.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([
        PropTypes.string,
      ]).isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(AddAvatar);
