import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { useQueryClient } from 'react-query';
import { logout } from 'store/auth';
import { Redirect } from 'react-router';

const Logout = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  useEffect(() => {
    queryClient.clear();
    dispatch(logout());
  }, [queryClient, dispatch]);

  return <Redirect to="/" />;
};

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(null, { logout })(Logout);
