import IconButton from '@combinations/IconButton';

const ModalCloseButton = ({ onClose, disabled }) => {
  return (
    <IconButton
      icon="CloseCircle"
      position="absolute"
      top={20}
      right={25}
      onClick={onClose}
      disabled={disabled}
      size="sm"
    />
  );
};

ModalCloseButton.defaultProps = {
  disabled: false,
};

export default ModalCloseButton;
