/* eslint-disable max-len */
import React from 'react';

const Shift = () => {
  return (
    <svg
      width="100%"
      className="icon"
      viewBox="0 0 26 26"
    >
      <path d="M23,2H21.8V.4a.43.43,0,0,0-.4-.4H20.2a.43.43,0,0,0-.4.4V2H6.3V.4A.43.43,0,0,0,5.9,0H4.6a.43.43,0,0,0-.4.4V2H3A3,3,0,0,0,0,5H0V23a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V5.1A3,3,0,0,0,23,2ZM3,4.1H4.2V5.7a.43.43,0,0,0,.4.4H5.8a.43.43,0,0,0,.4-.4V4.1H19.6V5.7a.43.43,0,0,0,.4.4h1.2a.43.43,0,0,0,.4-.4V4.1H23a1,1,0,0,1,1,1V7.6H2V5.1A1,1,0,0,1,3,4.1ZM23,24H3a1,1,0,0,1-1-1V9.6H24V23A1.08,1.08,0,0,1,23,24Z" />
      <path d="M12,20.72a.6.6,0,0,1-.84,0h0L7.76,17.34a.9.9,0,0,1,0-1.27l.42-.43a.9.9,0,0,1,1.26,0h0l2.1,2.12,5.68-5.71a.89.89,0,0,1,1.26,0h0l.42.42a.9.9,0,0,1,0,1.27Z" />
    </svg>
  );
};

export default Shift;
/* eslint-enable max-len */
