import Link from '@old/components/common/Link';
import { usePlaceEditButton, usePlaceDeleteButton } from 'old/buttonHooks';
import ImageCircle from '@old/components/guide/ImageCircle';
import FlexColumn from '@old/components/common/FlexColumn';
import Card from '@old/components/common/Card';
import Center from '@old/components/common/Center';
import RowAligner from '@old/components/common/RowAligner';
import Subtitle from '@old/components/common/Subtitle';
import { ContextTrigger } from '@old/components/common/ContextMenu';
import ColorBar from '@old/components/common/ColorBar';

const PlacesGridItem = ({ place }) => {
  const placeEdit = usePlaceEditButton(place);
  const placeDelete = usePlaceDeleteButton(place);

  const placeButtons = [placeEdit, placeDelete];

  const colorBar = <ColorBar color={place.getColor()} />;

  return (
    <ContextTrigger menuItems={placeButtons}>
      <Card footerContent={colorBar} fullHeight>
        <Center>
          <FlexColumn>
            <Link to={`/places/${place.id}`}>
              <FlexColumn>
                <RowAligner className="justify-center">
                  <ImageCircle src={place.getImage()} big />
                </RowAligner>
                <Subtitle>{place.getName(40)}</Subtitle>
              </FlexColumn>
            </Link>
            <div className="leading-tight uppercase">{place.slug}</div>
          </FlexColumn>
        </Center>
      </Card>
    </ContextTrigger>
  );
};

export default PlacesGridItem;
