import { truncate, has } from 'lodash';
import moment from 'moment';

import BaseModel from '@old/model/BaseModel';
import { Member } from '@old/model/Member';
import {
  getObjectWithMinValueByKey,
  mapModelPictures,
  parseHTMLToMarkdown,
  parseMarkdownToHTML,
} from 'old/utils/Common';

class Farms extends BaseModel {
  constructor(options) {
    super({
      modelName: 'farms',
      updateKey: 'farm',
      basePath: '/api/v1/farms',
      ItemClass: Farm,
      ...options,
    });
  }

  // override BaseModel.fetch
  fetch = async (_, cancelToken) => {
    const response = await this.client.get(`${this.basePath}/${this.farmId}`, { cancelToken });
    return this.parse(response.data);
  };

  fetchRoles = async () => {
    const response = await this.client.get(`${this.basePath}/${this.farmId}/roles`);
    return response && response.data ? response.data.map(({ id, abilities }) => ({ id, name: abilities[0] })) : [];
  };

  fetchBySlugname = async name => {
    const response = await this.client.get(`${this.basePath}/${name}`);

    if (Array.isArray(response.data.farms)) {
      return [null, response.data.farms.map(farm => this.parse(farm))];
    } else {
      return [this.parse(response.data), null];
    }
  };

  // List of current user joined farms
  fetchMineFarms = async params => {
    const response = await this.client.get(`${this.basePath}/mine`, { params });

    return [response.data.farms.map(item => this.parse(item)), response.data.pagination];
  };

  fetchAccountInformation = async ({ cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/${this.farmId}/account`, { cancelToken, params });
    return [response.data.total_sms_count, response.data.history.items, response.data.history.pagination];
  };

  fetchAvailableSmsPacks = async ({ cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/${this.farmId}/sms_packs`, { cancelToken, params });
    return [response.data.sms_packs];
  };

  requestSmsPack = async packId => {
    const response = await this.client.post(`${this.basePath}/${this.farmId}/sms_packs/${packId}/buy`);
    return response.data;
  };

  // override BaseModel.update
  update = async (farmId, changes) => {
    const response = await this.client.patch(`${this.basePath}/${farmId}`, { farm: changes });
    return this.parse(response.data);
  };

  apiVersion = async () => {
    const response = await this.client.get('/api/v1/info/version');
    return response.data;
  };

  inviteByEmails = async emails => {
    const response = await this.client.post(`${this.basePath}/${this.farmId}/invite`, { emails });
    return response.data;
  };

  rate = async (farmId, params) => {
    const rate = {
      ...params,
      comment: params.comment?.trim(),
    };

    const response = await this.client.post(`${this.basePath}/${farmId}/rate`, { rate });
    return response.data;
  };

  toggleRating = async (farmId, rateId) => {
    const response = await this.client.post(`${this.basePath}/${farmId}/toggle_rate`, { rate_id: rateId });
    return response.data;
  };

  fetchRatings = async (farmId, { cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/${farmId}/rates`, { cancelToken, params });
    return [
      response.data.rates.map(rating => ({
        ...rating,
        rater: new Member(rating.rater),
      })),
      response.data.pagination,
    ];
  };

  deleteRating = async (farmId, rateId) => {
    const response = await this.client.delete(`${this.basePath}/${farmId}/rate/${rateId}`);
    return response.data;
  };

  updateFarmPolicy = async policy => {
    const response = await this.client.patch(`${this.basePath}/${this.farmId}/policy`, { ...policy });
    return response.data;
  };

  getFarmPolicy = async () => {
    const response = await this.client.get(`${this.basePath}/${this.farmId}/policy`);
    return response.data;
  };

  deleteFarmPolicy = async () => {
    const response = await this.client.delete(`${this.basePath}/${this.farmId}/policy`);
    return response.data;
  };

  getStatistics = async (_, params = {}) => {
    let { start, end } = params;
    if (!start || !end) {
      start = moment().subtract(30, 'days').startOf('day').toISOString();
      end = moment().endOf('day').toISOString();
    }

    try {
      const response = await this.client.get(`${this.basePath}/${this.farmId}/sms/statistics`, {
        params: {
          'stats[start]': start,
          'stats[end]': end,
        },
      });
      return response.data;
    } catch (e) {
      return null;
    }
  };

  getFarmSettings = async () => {
    const response = await this.client.get(`${this.basePath}/${this.farmId}/settings`);
    return response.data;
  };

  updateFarmSettings = async data => {
    const response = await this.client.patch(`${this.basePath}/${this.farmId}/settings`, {
      settings: { data: { ...data } },
    });
    return response.data;
  };

  updatePaymentPlan = async paymentPlan => {
    const response = await this.client.patch(`${this.basePath}/${this.farmId}`, {
      farm: {
        event_payment_plan: paymentPlan,
      },
    });
    return response.data;
  };
}

class Farm {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.description = data.description;
    this.www = data.www;
    this.latitude = data.latitude || 0;
    this.longitude = data.longitude || 0;
    this.image = data.image;
    this.phone = data.phone.replaceAll(' ', '');
    this.email = data.email;
    this.postcode = data.postcode;
    this.address = data.address;
    this.city = data.city;
    this.rating = data.rating;
    this.pictures = mapModelPictures(data.pictures, '/img/farm-placeholder.png');
    this.primaryPictureId = data.primary_picture_id;
    this.staff = data.staff;
    this.openingHours = data.opening_hours
      ? {
          monday: {
            open: data.opening_hours[0][0],
            close: data.opening_hours[0][1],
          },
          tuesday: {
            open: data.opening_hours[1][0],
            close: data.opening_hours[1][1],
          },
          wednesday: {
            open: data.opening_hours[2][0],
            close: data.opening_hours[2][1],
          },
          thursday: {
            open: data.opening_hours[3][0],
            close: data.opening_hours[3][1],
          },
          friday: {
            open: data.opening_hours[4][0],
            close: data.opening_hours[4][1],
          },
          saturday: {
            open: data.opening_hours[5][0],
            close: data.opening_hours[5][1],
          },
          sunday: {
            open: data.opening_hours[6][0],
            close: data.opening_hours[6][1],
          },
        }
      : null;
    this.privileges = data.meta ? data.meta.privileges : [];
    this.event_payment_plan = data.event_payment_plan;
  }

  static mapToSaveData = data => {
    const farm = {
      name: data.farmName,
      description: has(data, 'farmDescription') ? parseHTMLToMarkdown(data.farmDescription) : undefined,
      www: data.www,
      latitude: data.coordinates && data.coordinates[0] && parseFloat(data.coordinates[0]),
      longitude: data.coordinates && data.coordinates[1] && parseFloat(data.coordinates[1]),
      status: data.status,
      phone: data.phone,
      email: data.email,
      address: data.address,
      postcode: data.postcode,
      city: data.city,
      staff: has(data, 'staff') ? parseHTMLToMarkdown(data.staff) : undefined,
      open_0: data.monday && (data.monday.active ? data.monday.hours[0] : null),
      close_0: data.monday && (data.monday.active ? data.monday.hours[1] : null),
      open_1: data.tuesday && (data.tuesday.active ? data.tuesday.hours[0] : null),
      close_1: data.tuesday && (data.tuesday.active ? data.tuesday.hours[1] : null),
      open_2: data.wednesday && (data.wednesday.active ? data.wednesday.hours[0] : null),
      close_2: data.wednesday && (data.wednesday.active ? data.wednesday.hours[1] : null),
      open_3: data.thursday && (data.thursday.active ? data.thursday.hours[0] : null),
      close_3: data.thursday && (data.thursday.active ? data.thursday.hours[1] : null),
      open_4: data.friday && (data.friday.active ? data.friday.hours[0] : null),
      close_4: data.friday && (data.friday.active ? data.friday.hours[1] : null),
      open_5: data.saturday && (data.saturday.active ? data.saturday.hours[0] : null),
      close_5: data.saturday && (data.saturday.active ? data.saturday.hours[1] : null),
      open_6: data.sunday && (data.sunday.active ? data.sunday.hours[0] : null),
      close_6: data.sunday && (data.sunday.active ? data.sunday.hours[1] : null),
      primary_picture_id: data.primaryPictureId,
      pictures_attributes:
        data.pictures &&
        data.pictures.map(picture => ({
          id: picture.id,
          description: picture.description,
          file: picture.file,
          _destroy: picture.destroy,
        })),
    };

    Object.keys(farm).forEach(fieldName => {
      if (farm[fieldName] === undefined) delete farm[fieldName];
    });

    return farm;
  };

  static mapToFormData = data => {
    const farmData = {
      farmName: data.name,
      phone: data.phone,
      email: data.email,
      www: data.www,
      farmDescription: data.getDescription(),
      staff: data.getStaff(),
      address: data.address,
      postcode: data.postcode,
      city: data.city,
      monday: {
        active: !!data.openingHours.monday.open,
        hours: [data.openingHours.monday.open, data.openingHours.monday.close],
      },
      tuesday: {
        active: !!data.openingHours.tuesday.open,
        hours: [data.openingHours.tuesday.open, data.openingHours.tuesday.close],
      },
      wednesday: {
        active: !!data.openingHours.wednesday.open,
        hours: [data.openingHours.wednesday.open, data.openingHours.wednesday.close],
      },
      thursday: {
        active: !!data.openingHours.thursday.open,
        hours: [data.openingHours.thursday.open, data.openingHours.thursday.close],
      },
      friday: {
        active: !!data.openingHours.friday.open,
        hours: [data.openingHours.friday.open, data.openingHours.friday.close],
      },
      saturday: {
        active: !!data.openingHours.saturday.open,
        hours: [data.openingHours.saturday.open, data.openingHours.saturday.close],
      },
      sunday: {
        active: !!data.openingHours.sunday.open,
        hours: [data.openingHours.sunday.open, data.openingHours.sunday.close],
      },
      coordinates: [parseFloat(data.latitude), parseFloat(data.longitude)],
    };
    return farmData;
  };

  update = async changes => this.model.update(this.id, changes);

  getName(limit = false) {
    return limit ? truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getAvatar(size) {
    return this.getImage(size);
  }

  getImage(size) {
    if (!this.pictures || this.pictures.length === 0) {
      return '/img/farm-placeholder.png';
    }

    let mainPicture = null;

    if (!this.primaryPictureId) {
      mainPicture = getObjectWithMinValueByKey(this.pictures, 'id');
    } else {
      mainPicture = this.pictures.find(picture => picture.id === this.primaryPictureId);
    }

    return mainPicture && mainPicture.url
      ? mainPicture.url[size] || mainPicture.url.medium
      : '/img/farm-placeholder.png';
  }

  async updatePicture(picturesData) {
    return this.model.update(this.id, Farm.mapToSaveData(picturesData));
  }

  getStaff() {
    // remove all newlines, comments etc. -> For react-quill library
    return parseMarkdownToHTML(this.staff).replace(/(\r\n|\n|\r|<!--(.*?)-->)/gm, '') || '';
  }

  getDescription() {
    // remove all newlines, comments etc. -> For react-quill library
    return parseMarkdownToHTML(this.description).replace(/(\r\n|\n|\r|<!--(.*?)-->)/gm, '') || '';
  }

  getPermission(permissionKey) {
    return this.privileges.includes(permissionKey);
  }

  isAdvancedPaymentEnabled() {
    return this.event_payment_plan === 'advanced';
  }
}

export { Farm, Farms };
