/* eslint-disable max-len */
import React from 'react';

const News = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M15.9,1.6c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.5v10.8c0,0.7-0.4,1.4-1,1.7V0.7
        c0-0.3-0.2-0.6-0.6-0.6H0.6C0.2,0.2,0,0.4,0,0.7v12.1c0,1.7,1.4,3,3,3H13c0.3,0,0.6-0.1,0.9-0.1c0.5-0.1,0.9-0.4,1.3-0.8
        c0.6-0.6,0.9-1.3,0.9-2.2V2C16,1.8,16,1.7,15.9,1.6L15.9,1.6z M2.5,2.5h8.9c0.3,0,0.6,0.2,0.6,0.6s-0.2,0.6-0.6,0.6H2.5
        c-0.3,0-0.6-0.2-0.6-0.6S2.2,2.5,2.5,2.5z M1.7,4.9c0-0.3,0.2-0.6,0.6-0.6h4.4c0.3,0,0.6,0.2,0.6,0.6v4.4c0,0.3-0.2,0.6-0.6,0.6H2.3
        C2,9.9,1.8,9.7,1.8,9.4L1.7,4.9z M2.5,11.9c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h8.9c0.3,0,0.6,0.2,0.6,0.6s-0.2,0.6-0.6,0.6
        C11.4,11.9,2.5,11.9,2.5,11.9z M11.3,14H3.9c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h7.5c0.3,0,0.6,0.2,0.6,0.6S11.7,14,11.3,14z
        M11.5,9.7H8.6c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h3c0.3,0,0.6,0.2,0.6,0.6S11.8,9.7,11.5,9.7z M11.5,7.7H8.6
        c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6h3c0.3,0,0.6,0.2,0.6,0.6C12.2,7.4,11.8,7.7,11.5,7.7z M11.5,5.8H8.6
        c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6h3c0.3,0,0.6,0.2,0.6,0.6C12.2,5.6,11.8,5.8,11.5,5.8z"
      />
    </svg>
  );
};

export default News;
/* eslint-enable max-len */
