import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import theme from 'config/theme';

const defaultStyles = {
  input: provided => ({
    ...provided,
    '& input': {
      minHeight: 'auto',
      height: 'auto',
    },
  }),
  valueContainer: provided => ({
    ...provided,
    '& input': {
      minHeight: 'auto',
      height: 'auto',
    },
  }),
  container: provided => ({
    ...provided,
    fontSize: 16,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: theme.colors['teal-light'],
    cursor: 'pointer',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: theme.colors['grey-light'],
  }),
  control: (provided, { isFocused, selectProps }) => ({
    ...provided,
    boxShadow: 'none',
    minHeight: 45,
    borderColor: theme.colors[selectProps.menuIsOpen || isFocused ? 'teal-light' : 'grey-light'],
    '&:hover': {
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)',
    },
  }),
  menu: provided => ({
    ...provided,
    margin: '0 0 10px',
    zIndex: 999999,
  }),
  menuList: provided => ({
    ...provided,
    padding: '0 10px',
    zIndex: 999999,
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 999999,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    lineHeight: 1,
    cursor: 'pointer',
    padding: '15px 20px',
    borderBottom: `1px solid ${theme.colors['grey-light']}`,
    backgroundColor: 'white',
    color: state?.isSelected ? theme.colors['teal-dark'] : 'initial',
    '&:hover': {
      color: theme.colors['teal-light'],
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const Select = ({ options, customStyles = {}, ...selectProps }) => {
  return <ReactSelect styles={{ ...defaultStyles, ...customStyles }} options={options} {...selectProps} />;
};

Select.defaultProps = {
  options: [],
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
};

export default Select;
