import { useEffect, useRef } from 'react';
import cls from 'classnames';
import { useLocation } from 'react-router-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { A, Flex, Span, Text } from 'components/elements';

import Link from '@old/components/common/Link';
import t from 'resources/translations';
import Icon from '@old/components/icon';
import RowAligner from '@old/components/common/RowAligner';
import { useOutsideClick } from '@old/hooks';
import { useActions, useAppStore, useFarm } from 'utils/storeUtils';
import { toggleMenu } from 'store/app';

const MenuMobile = ({ items, member }) => {
  const farm = useFarm();
  const { menuIsOpen } = useAppStore();
  const setMenu = useActions(toggleMenu);
  const refMenu = useRef();
  const refMenuLinks = useRef();

  useOutsideClick(refMenu, () => menuIsOpen && setMenu(false));

  const location = useLocation();

  useEffect(() => {
    setMenu(false);
  }, [location.pathname, setMenu]);

  const onToggleMenu = () => {
    if (menuIsOpen) {
      setMenu(false);
    } else {
      setMenu(true);
      refMenuLinks.current.scrollTop = 0;
    }
  };

  return (
    <div>
      <div className={cls('menu-dimmer', menuIsOpen && 'open')} />
      <div ref={refMenu} className={cls('menu-mobile centered', menuIsOpen && 'open')}>
        <div className="hamb-icon" onClick={onToggleMenu}>
          <span />
          <span />
          <span />
          <span />
        </div>
        <div className="menu-content">
          <Link to={`/users/${member.id}`}>
            <img src={member.getAvatar()} alt={member.getFullName()} className="member-avatar" />
            <div className="mt-2 mb-5">
              <div>{member.getName()}</div>
              <div className="text-sm">({farm.getName()})</div>
            </div>
          </Link>
          <RemoveScroll className="hide-scollbar" enabled={menuIsOpen}>
            <div ref={refMenuLinks} className="menu-links-container">
              {items.map(([name, link, key, icon]) => {
                const isCurrent = link === location.pathname;
                return (
                  <Link key={key} to={link} className={cls('menu-link', isCurrent && 'current')}>
                    <RowAligner>
                      {icon}
                      <span>{name}</span>
                    </RowAligner>
                  </Link>
                );
              })}
              <a href="/" className="menu-link">
                <RowAligner>
                  <Icon.Farm />
                  <span>{t('menu.changeFarm')}</span>
                </RowAligner>
              </a>
              <Link to="/logout" className="menu-link">
                <RowAligner>
                  <Icon.Logout />
                  <span>{t('menu.logout')}</span>
                </RowAligner>
              </Link>
              <Flex justifyContent="center" pb="md">
                <Text>
                  <A href="https://horsemanago.com/pl/terms-of-service/" fontSize="sm" isExternal>
                    {t('register.terms')}
                  </A>
                  <Span>{` | `}</Span>
                  <A href="https://horsemanago.com/pl/privacy-policy/" fontSize="sm" isExternal>
                    {t('register.privacyPolicy')}
                  </A>
                </Text>
              </Flex>
            </div>
          </RemoveScroll>
        </div>
      </div>
    </div>
  );
};

export default MenuMobile;
