import { Button, Flex, Icons, Input, Label, Stack } from 'components/elements';

import { TOption } from 'resources/types/commonTypes';

const styleCheckbox = (isChecked: boolean, isDisabled: boolean) => ({
  borderRadius: 4,
  borderWidth: 2,
  borderColor: isChecked ? 'teal-dark' : 'teal',
  ...(isDisabled ? { borderColor: 'new-grey-light' } : {}),
  color: 'white',
  squareSize: 24,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: isChecked ? 'teal-dark' : 'transparent',
});

type TCheckboxMultiProps = {
  options: TOption[];
  value: TOption[];
  name: string;
  onChange: (value: TOption[]) => void;
  disabled?: (string | number)[];
};

const CheckboxMulti = ({ options = [], name, value = [], onChange, disabled }: TCheckboxMultiProps) => {
  const onClick = (isChecked: boolean, val: TOption) => {
    const newValue = !isChecked ? [...value, val] : [...value].filter(item => item.value !== val.value);
    onChange(newValue);
  };

  return (
    <Stack spacing={0}>
      {(options || []).map((option, index) => {
        const isChecked = !!value.find(item => item.value === option.value);
        const isDisabled = (disabled || []).includes(option.value);
        return (
          <Button
            key={`${option.value}-${index}`}
            variant="clean"
            cursor="pointer"
            width="fit-content"
            onClick={() => onClick(isChecked, option)}
            disabled={isDisabled}
          >
            <Stack isHorizontal my="xs" spacing="base" alignItems="center">
              <Flex {...styleCheckbox(isChecked, isDisabled)}>{isChecked && <Icons.Accept />}</Flex>
              <Input type="checkbox" checked={isChecked} name={`${name}-${index}`} hidden readOnly />
              <Label
                fontWeight="normal"
                textAlign="left"
                fontSize="base"
                htmlFor={option.label}
                {...(isDisabled
                  ? {
                      color: 'new-grey-light',
                      cursor: 'default',
                    }
                  : {
                      color: 'grey-dark',
                      cursor: 'pointer',
                    })}
              >
                {option.label}
              </Label>
            </Stack>
          </Button>
        );
      })}
    </Stack>
  );
};

export default CheckboxMulti;
