/* eslint-disable max-len */
import React from 'react';

const Italic = () => {
  return (
    <svg viewBox="0 0 26 26" width="100%">
      <path d="M13.4,26h-6l5.2-26h5.9L13.4,26z" />
    </svg>
  );
};

export default Italic;
/* eslint-enable max-len */
