/**
 * @module Allow
 */
import PropTypes from 'prop-types';

import { isAllowed } from 'old/utils';

/**
 * Component that checks user permissions to view/do something
 * @param  {*} props.children
 * @param  {String|String[]} props.permissionKey permission key to be checked
 * @param  {Node} props.fallback returns if you don't have permission
 * @param  {Object} props.permissionParams additional permission check params
 */
const Allow = ({ children, permissionKey, fallback, permissionParams, additionalCheck }) => {
  if (isAllowed(permissionKey, permissionParams) && additionalCheck) {
    return children;
  }

  return fallback;
};

Allow.defaultProps = {
  fallback: null,
  permissionParams: {},
  additionalCheck: true,
};

Allow.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  permissionKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  fallback: PropTypes.node,
  permissionParams: PropTypes.shape({}),
  additionalCheck: PropTypes.bool,
};

export default Allow;
