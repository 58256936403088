/**
 * @module AddHorseModal
 */
import { useState } from 'react';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import t from 'resources/translations';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';
import Option from '@old/components/view/listItem/SelectOption';
import SelectOptionList from '@old/components/view/list/SelectOption';
import { getErrorMessage, notify } from 'old/utils';

/**
 * A component that displays a modal window for adding horse to event
 * @param  {Model.Event} [props.event] Contains Event data
 * @param  {Function} props.onClose Modal closing function The parameter must contain the name of the modal
 */

const AddHorseModal = ({ onClose, event, cb }) => {
  const [selectedHorses, setSelectedHorses] = useState([]);

  const addHorse = async () => {
    const prevAddedHorses = event.getHorsesIds();
    const newHorsesList = [...selectedHorses.map(horse => horse.value), ...prevAddedHorses];
    try {
      await Model.Events.update(event.id, { horse_ids: newHorsesList });
      cb();
      setSelectedHorses([]);
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  if (!event) return null;

  return (
    <Modal header={t('model.add.horses')} onClose={onClose} isOpen>
      <SelectOptionList
        name="horsesSearch"
        onChange={setSelectedHorses}
        loadOptions={(keyword, page) => Model.Horses.fetchOptions(keyword, page, { with_deleted: event.isPast() })}
        optionComponent={Option.Horse}
        value={selectedHorses}
        isOptionDisabled={optionData =>
          event
            .getHorsesOptions()
            .map(horseOption => horseOption.value)
            .includes(optionData.value)
        }
        isMulti
      />
      <FlexRow separated="small" noShrink>
        <ButtonOutline onClick={onClose} fluid>
          {t('general.cancel')}
        </ButtonOutline>
        <Button onClick={addHorse} fluid async>
          {t('general.add')}
        </Button>
      </FlexRow>
    </Modal>
  );
};

AddHorseModal.defaultProps = {
  event: null,
};

export default AddHorseModal;
