/**
 * @module ManagePhoneModal
 */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import { getErrorMessage, notify } from 'old/utils';
import t from 'resources/translations';
import { closeModal, openModal } from 'store/actions';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_CHANGE_PHONE } from 'old/constants/formNames';
import config from '@old/config';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import localStorageService from 'services/storage/localStorageService';
import { useShallowSelector } from 'old/hooks';
import { MemberService } from 'services';

/**
 * A component that displays a modal window for managing phone number
 * @param  {Function} props.closeModal A function that closes the modal
 * @param  {Function} props.openModalByName A function that opens the modal
 * @param  {String} props.phoneNumber Phone number that will be edited
 * @param  {String} props.userId Logged user id
 */
const ManagePhoneModal = ({ name, onClose, openModalByName }) => {
  const { phoneNumber, userId } = useShallowSelector(({ modal }) => modal[name]?.props || {});
  const [isOpen, setIsOpen] = useState(false);
  const onSubmitPhone = async formState => {
    try {
      const user = await MemberService.updateUser(userId, { ...formState });
      localStorageService._setUser(user);
      notify(t('message.succesSave'));
      setIsOpen(false);
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const afterDeleteUser = () => {
    localStorage.clear();
    let prefix = '';
    if (!window.location.host.startsWith('http')) {
      prefix = `${window.location.protocol}//`;
    }
    window.location.replace(`${prefix}${window.location.host}/`);
    Model.refresh();
  };

  const onDeleteClick = () => {
    onClose();
    openModalByName('confirmModal', {
      header: t('confirmDeleteModal.confirmationBeforeDeleting'),
      content: t('message.confirmDeleteAccount'),
      approveLabel: t('memberManagement.deleteAccount'),
      onSubmitConfirm: () => Model.Members.deleteUser(),
      afterSubmit: () => afterDeleteUser(),
      onCancel: () => onCancelConfirmModal(),
    });
  };

  const onCancelConfirmModal = () => {
    openModalByName('managePhone');
  };

  const getInitValues = () => ({
    phone: phoneNumber ?? '',
  });

  return (
    <Modal header={t('model.edit.data')} onClose={onClose} beforeClose={() => setIsOpen(false)} isOpen>
      <ButtonSimple onClick={() => setIsOpen(!isOpen)}>
        <RowAligner>
          <Icon.Edit circle />
          <div className="text-highlight">{t('buttons.editPhone')}</div>
        </RowAligner>
      </ButtonSimple>
      <div className={cls('phone-form', isOpen && 'open')}>
        {isOpen && (
          <Form formName={FORM_CHANGE_PHONE} noPadding initValues={getInitValues()}>
            <Field.Phone
              name="phone"
              label={t('labels.phone')}
              placeholder={t('placeholders.phone')}
              defaultDirectionalValue={config.defaultDirectional.value}
              required
            />
            <FormButton formName={FORM_CHANGE_PHONE} onClick={onSubmitPhone} fluid async>
              {t('general.save')}
            </FormButton>
          </Form>
        )}
      </div>
      <ButtonSimple onClick={onDeleteClick}>
        <RowAligner>
          <Icon.Delete circle />
          <div className="text-highlight">{t('memberManagement.deleteAccount')}</div>
        </RowAligner>
      </ButtonSimple>
    </Modal>
  );
};

ManagePhoneModal.defaultProps = {
  phoneNumber: '',
  userId: '',
};

ManagePhoneModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  openModalByName: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  userId: PropTypes.string,
};

export default connect(null, { closeModal, openModalByName: openModal })(ManagePhoneModal);
