/**
 * @module TextEditorInput
 */

import React from 'react';
import PropTypes from 'prop-types';

import InputWrapper from '@old/components/common/InputWrapper';
import TextEditor from '@old/components/common/TextEditor';

/**
 * Component that generates input with rich text editor
 * @param  {String} [value]
 * @param  {String} [name]
 * @param  {Function} onChange Function called when the value of the input has changed
 * @param  {String} [placeholder]
 * @param  {Boolean} [required]
 * @param  {Node} [icon] default icon displayed with that input
 * @param  {String} [label]
 */

const TextEditorInput = ({ value, name, onChange, placeholder, required, icon, label }) => {
  const onInputChange = (htmlValue) => {
    onChange(htmlValue, name);
  };

  return (
    <InputWrapper label={label} icon={icon} required={required}>
      <TextEditor placeholder={placeholder} onChange={onInputChange} value={value} />
    </InputWrapper>
  );
};

TextEditorInput.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  label: '',
  icon: false,
  required: false,
};

TextEditorInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.node,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default TextEditorInput;
