import { Stack } from 'components/elements';
import { RadioInputOptions } from 'resources/types/commonTypes';
import { IStack } from 'resources/types/elementsTypes';
import RadioInput from './RadioInput';

type TRadioInput = {
  value: string | number;
  name: string;
  onChange: (name: string, value: string | number) => void;
  options: RadioInputOptions[];
  containerStyleProps?: Omit<IStack, 'children'>;
};

const RadioInputGropup = ({ name, onChange, options, value, containerStyleProps }: TRadioInput) => {
  return (
    <Stack spacing="sm" {...containerStyleProps}>
      {options.map((option, index) => (
        <RadioInput
          id={`${option.value}-${index}`}
          name={name}
          value={option.value}
          onChange={onChange}
          isChecked={value === option.value}
        >
          {option.node || option.label}
        </RadioInput>
      ))}
    </Stack>
  );
};

export default RadioInputGropup;
