import ReactGA from 'react-ga';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import t from 'resources/translations';
import { errorByKeyExists, notify } from 'old/utils';
import { IFarmRes } from 'resources/types/farmsTypes';
import { IProfileRes, IUserReq } from 'resources/types/membersTypes';
import {
  BillService,
  EventService,
  FarmService,
  HorseService,
  MemberService,
  ParticipationService,
  PlaceService,
  TicketService,
  TicketOfferService,
  EventTypeService,
  MemberGroupService,
} from 'services';
import localStorageService from 'services/storage/localStorageService';
import { GOOGLE_ANALYTICS_ENABLED } from 'config/constans';
import Model from 'old/model';
import { mapProfileResToUserRes } from 'utils/commonUtils';
import { setRedirect } from './app';
import { History, LocationState } from 'history';
import { RootState } from 'store';
import { isMobileOnly } from 'react-device-detect';

export interface IAuthenticationStore {
  profile: IProfileRes | null;
  farm: IFarmRes | null;
  login: {
    inProgress: boolean;
    error: unknown | null;
  };
  logout: {
    inProgress: boolean;
    error: unknown | null;
  };
}

const initialState: IAuthenticationStore = {
  profile: null,
  farm: null,
  login: {
    inProgress: false,
    error: null,
  },
  logout: {
    inProgress: false,
    error: null,
  },
};

type TLoginInArgs = {
  email: string;
  password: string;
};

type TService = 'google' | 'facebook';

type TSocialLoginInArgs = {
  service: TService;
  accessToken: string;
  farmSlug: string;
  history: History<LocationState>;
  userData?: Partial<IUserReq>;
};

export const refreshModels = (farmId: number) => {
  FarmService._refresh(farmId);
  TicketService._refresh(farmId);
  TicketOfferService._refresh(farmId);
  EventService._refresh(farmId);
  BillService._refresh(farmId);
  ParticipationService._refresh(farmId);
  MemberService._refresh(farmId);
  HorseService._refresh(farmId);
  PlaceService._refresh(farmId);
  EventTypeService._refresh(farmId);
  MemberGroupService._refresh(farmId);
};

export const setTokenAndRefreshModels = (token: string, farmId?: number) => {
  localStorageService._setToken(token);
  Model.refresh(token, farmId);
  if (farmId) {
    refreshModels(farmId);
  }
};

export const login = createAsyncThunk('auth/login', async ({ email, password }: TLoginInArgs, thunkAPI) => {
  const source = axios.CancelToken.source();
  thunkAPI.signal.addEventListener('abort', () => {
    source.cancel();
  });

  const loginMember = async () => {
    const [token, userRes] = await MemberService.login(email, password, source.token);
    localStorageService._setUser(userRes);
    setTokenAndRefreshModels(token);
    return { profile: null, farm: null };
  };

  const loginToFarm = async () => {
    const [token, { profile, farm }] = await MemberService.loginToFarm(email, password, source.token);
    const userRes = mapProfileResToUserRes(profile);
    localStorageService._setUser(userRes);
    setTokenAndRefreshModels(token, farm.id);
    return { profile, farm };
  };

  try {
    const slug = window.location.pathname.split('/')[1];
    if (slug) {
      return await loginToFarm();
    }
    return await loginMember();
  } catch (e) {
    if (e?.response?.status === 401) {
      notify(t('message.loginFailed'), { type: 'error' });
    }
    if (e?.response?.status === 404) {
      thunkAPI.dispatch(setRedirect('/'));
      thunkAPI.dispatch(login({ email, password }));
    }
    throw e;
  }
});

export const socialLogin = createAsyncThunk(
  'auth/socialLogin',
  async ({ service, accessToken, farmSlug, history, userData }: TSocialLoginInArgs, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener('abort', () => {
      source.cancel();
    });

    try {
      const [token, userRes] = await MemberService.socialLogin(service, accessToken, userData, source.token);
      Model.refresh(token);
      localStorageService._setUser(userRes);
      setTokenAndRefreshModels(token);
      const mainPathName = window.location.pathname.split('/')[1];
      if (farmSlug && farmSlug !== mainPathName && isMobileOnly) {
        window.location.replace(`${window.location.protocol}//${window.location.host}${farmSlug}`);
      }
      return { profile: null };
    } catch (e) {
      if (e?.response?.status === 401) {
        notify(t('message.loginFailed'), { type: 'error', toastId: 'notifyLoginErrorId' });
      }
      if (e?.response?.status === 422) {
        if (errorByKeyExists(e, 'email', 'taken')) {
          notify(t('error.emailTaken'), { type: 'error' });
        } else if (errorByKeyExists(e, 'phone', 'taken')) {
          notify(t('error.phoneTaken'), { type: 'error' });
        } else {
          localStorageService._clearToken();
          const requiredFields = Object.keys(e.response.data);
          history.push('/social/login', { requiredFields, service, accessToken });
        }
      }
      throw e;
    }
  }
);

export const refetchProfile = createAsyncThunk('auth/refetchProfile', async () => {
  try {
    const profile = await MemberService.fetchProfile();
    const userRes = mapProfileResToUserRes(profile);
    localStorageService._setUser(userRes);
    return profile;
  } catch (e) {
    throw e;
  }
});

export const refetchFarm = createAsyncThunk('auth/refetchFarm', async (_, thunkAPI) => {
  try {
    const storeState = thunkAPI.getState() as RootState;
    const slug = window.location.pathname.split('/')[1];
    const farm = await FarmService.fetchBySlugname(storeState.auth.farm?.slug || slug);
    refreshModels(farm.id);
    return farm;
  } catch (e) {
    throw e;
  }
});

export const logout = createAsyncThunk('auth/logout', async () => {
  localStorageService._clearAll();
  try {
    if (GOOGLE_ANALYTICS_ENABLED) {
      ReactGA.set({ dimension1: null });
      ReactGA.set({ userId: null });
    }
    Model.refresh();
    MemberService.logout();
  } catch (e) {
    throw e;
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setFarm: (state, action) => {
      state.farm = action.payload;
    },
    setProfileAndFarm: (state, action) => {
      state.profile = action.payload.profile;
      state.farm = action.payload.farm;
    },
  },
  extraReducers: builder => {
    /* auth/login */
    builder.addCase(login.fulfilled, (state, action) => {
      state.profile = action.payload.profile;
      state.farm = action.payload.farm;
      state.login = {
        inProgress: false,
        error: null,
      };
    });
    builder.addCase(login.pending, state => {
      state.profile = null;
      state.farm = null;
      state.login = {
        inProgress: true,
        error: null,
      };
    });
    builder.addCase(login.rejected, (state, action) => {
      state.login = {
        inProgress: false,
        error: action.error,
      };
    });
    /* auth/socialLogin */
    builder.addCase(socialLogin.fulfilled, (state, action) => {
      state.profile = action.payload.profile;
      state.farm = null;
      state.login = {
        inProgress: false,
        error: null,
      };
    });
    builder.addCase(socialLogin.pending, state => {
      state.profile = null;
      state.farm = null;
      state.login = {
        inProgress: true,
        error: null,
      };
    });
    builder.addCase(socialLogin.rejected, (state, action) => {
      state.login = {
        inProgress: false,
        error: action.error,
      };
    });
    /* auth/logout */
    builder.addCase(logout.fulfilled, state => {
      state.profile = null;
      state.farm = null;
      state.logout = {
        inProgress: false,
        error: null,
      };
    });
    builder.addCase(logout.pending, state => {
      state.logout = {
        inProgress: true,
        error: null,
      };
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.logout = {
        inProgress: false,
        error: action.error,
      };
    });
    /* auth/refetchProfile */
    builder.addCase(refetchProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
    /* auth/refetchFarm */
    builder.addCase(refetchFarm.fulfilled, (state, action) => {
      state.farm = action.payload;
    });
  },
});

export const { setProfile, setFarm, setProfileAndFarm } = authSlice.actions;

export default authSlice.reducer;
