import { Stack } from 'components/elements';
import { IStack } from 'resources/types/elementsTypes';

const ModalButtons = ({ children, ...stackProps }: IStack) => {
  return (
    <Stack px={['md', 'xl']} pb={['md', 'xl']} {...stackProps}>
      {children}
    </Stack>
  );
};

export default ModalButtons;
