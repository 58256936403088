import { connect } from 'react-redux';

import Modal from '@old/components/common/Modal';
import { refreshModalOptions } from 'store/actions';
import { getErrorMessage, notify } from 'old/utils';
import t from 'resources/translations';
import FlexColumn from '@old/components/common/FlexColumn';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import FlexRow from '@old/components/common/FlexRow';
import Button from '@old/components/guide/Button';

const BlockUserModal = ({ doc, onClose, afterSubmit, refreshModal }) => {
  const onSubmit = async () => {
    try {
      let updatedMember;
      if (doc.isBlocked()) {
        updatedMember = await doc.unblock(doc.id);
        notify(t('message.unblockUser'));
      } else {
        updatedMember = await doc.block(doc.id);
        notify(t('message.blockUser'));
      }
      refreshModal('manageMember', { member: updatedMember });
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      afterSubmit();
    }

    onClose();
  };

  if (!doc) return null;
  const content = doc.isBlocked()
    ? {
        header: t('blockUserModal.unblockUser'),
        notify: t('message.confirmUserUnblock', { title: doc.getName() }),
        confirmLabel: t('blockUserModal.unblock'),
      }
    : {
        header: t('blockUserModal.blockUser'),
        notify: t('message.confirmUserBlock', { title: doc.getName() }),
        confirmLabel: t('blockUserModal.block'),
      };

  return (
    <Modal header={content.header} onClose={onClose} isOpen>
      <FlexColumn>
        <div>{content.notify}</div>
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button onClick={onSubmit} fluid async>
            {content.confirmLabel}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

BlockUserModal.defaultProps = {
  doc: null,
  afterSubmit: () => { },
};

export default connect(null, { refreshModal: refreshModalOptions })(BlockUserModal);
