import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalButtons,
  AvatarWithLabel,
} from 'components/combinations';
import { Box, Button, Flex, Icons, Span, Stack, Text, TextTruncate } from 'components/elements';
import { parseToCurrencyString } from 'old/utils';
import t from 'resources/translations';
import { BillService, TicketService } from 'services';
import { TWrappedModal, TStoreStateModals } from 'views/modals';
import Bill from 'models/Bill';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useEffect } from 'react';
import Ticket from 'models/Ticket';
import axios from 'axios';
import { doubleTernary } from 'utils';
import { DATE_FORMAT } from 'config/constans';
import { TImageProps } from 'resources/types/elementsTypes';
import { useShallowSelector } from 'old/hooks';
import { useFarm } from 'utils/storeUtils';

const avatarStyles: TImageProps = {
  p: 2,
  width: 50,
  height: 50,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'teal-dark',
  borderRadius: 9999,
};

type TPaymentModalProps = {
  bill?: Bill;
  ticket?: Ticket;
};

const SetOnlinePayment = ({ onUpdate, onSubmit, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const { bill, ticket } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['PAYMENT_ONLINE'].props
  ) as TPaymentModalProps;
  const farm = useFarm();

  const isTicket = !bill || bill.billableType === 'Ticket';

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const fetchBill = async () => {
      if (ticket?.billId) {
        const billRes = await BillService.fetch(ticket.billId, source.token);
        const newBill = new Bill(billRes);
        onUpdate({ bill: newBill });
      }
    };
    const fetchTicket = async () => {
      if (bill?.billableId) {
        const ticket = await TicketService.fetch(bill.billableId, source.token);
        onUpdate({ ticket: new Ticket(ticket) });
      }
    };
    fetchBill();
    if (isTicket) {
      fetchTicket();
    }
    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRedirectToBM = async () => {
    if (bill) {
      const pendingTransaction = bill.getPendingTransaction();
      if (pendingTransaction) {
        window.location.href = pendingTransaction.getRedirect();
      } else {
        const { redirect_url } = await BillService.addOnlinePayment(bill.id);
        window.location.href = redirect_url;
      }
    }
  };

  const remainingAmount = bill ? bill.getDue() - bill.getPaidAmount() : 0;
  const totalAmount = remainingAmount > 0 ? remainingAmount : 0;
  const ticketName = doubleTernary(!!ticket, isTicket, [ticket?.getName(), bill?.ticket.name, '']);
  return (
    <Modal isHidden={isHidden} onClose={onClose} isBlocked={isSubmitting} isOpen>
      <ModalHeader>{isTicket ? t('bills.payForTicket') : t('bills.payForEvent')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting}>
        <Flex px={[0, 'base']} pt={[0, 'base']}>
          {isTicket ? (
            <Stack alignItems="center" isHorizontal>
              <Box squareSize={50} padding={3} borderWidth={2} borderColor="teal-dark" borderRadius={9999}>
                <Icons.TicketCircle bgColor="orange" fill="white" size="mdWithBorder" />
              </Box>
              <Text fontSize="md" fontWeight="medium">
                {ticketName}
              </Text>
            </Stack>
          ) : (
            <AvatarWithLabel
              src={farm.getAvatar('small')}
              alt={farm.getName()}
              title={farm.getName()}
              spacing="md"
              {...avatarStyles}
            >
              <Text fontSize="md" fontWeight="medium">
                {farm.getName()}
              </Text>
            </AvatarWithLabel>
          )}
        </Flex>
        <ModalDescription>
          {isTicket && (
            <>
              {ticket ? (
                <Stack fontSize="base" spacing="sm">
                  <Stack alignItems="center" pb="base" isHorizontal>
                    <Text fontWeight="medium" whiteSpace="nowrap">
                      {`${t('paymentTicketModal.assignedBy')}: `}
                    </Text>
                    <AvatarWithLabel
                      src={ticket.membership.getAvatar('small')}
                      alt={ticket.membership.getFullName()}
                      title={ticket.membership.getFullName()}
                      spacing="sm"
                      squareSize={26}
                    >
                      <Text>{ticket.membership.getFullName()}</Text>
                    </AvatarWithLabel>
                  </Stack>
                  <Text fontWeight="medium">
                    {`${t('paymentTicketModal.periodValidity')}: `}
                    <Span fontWeight="regular">
                      {ticket.count} {t('tickets.days', { count: ticket.count })}
                    </Span>
                  </Text>
                  <Text fontWeight="medium">
                    {`${t('paymentTicketModal.dateActiveTicket')}: `}
                    <Span fontWeight="regular">{moment(ticket.expireAt).format(DATE_FORMAT)}</Span>
                  </Text>
                  <Text fontWeight="medium">
                    {`${t('paymentTicketModal.eventTypes')}: `}
                    <Span fontWeight="regular">{ticket.eventTypes.map(type => type.getName()).join(', ')}</Span>
                  </Text>
                </Stack>
              ) : (
                <Box maxWidth={380}>
                  <SkeletonTheme color="#E5E5E5" highlightColor="#EBEBEB">
                    <Flex mb={15}>
                      <Skeleton height={20} style={{ marginBottom: 5 }} delay={1.2} width={280} />
                    </Flex>
                    <Skeleton height={20} style={{ marginBottom: 5 }} delay={1.2} width={210} />
                    <Skeleton height={20} style={{ marginBottom: 5 }} delay={1.4} />
                    <Skeleton height={20} style={{ marginBottom: 5 }} delay={1.6} width={260} />
                  </SkeletonTheme>
                </Box>
              )}
            </>
          )}
          {!isTicket && (
            <>
              {bill ? (
                <Stack fontSize="base" spacing="sm">
                  <Text fontWeight="bold">{t('bills.event')}:</Text>
                  <Text>{moment(bill.event.startAt).format('DD.MM.YYYY, dddd')}</Text>
                  <TextTruncate lines={2}>{bill.event.name}</TextTruncate>
                </Stack>
              ) : (
                <Box maxWidth={380}>
                  <SkeletonTheme color="#E5E5E5" highlightColor="#EBEBEB">
                    <Skeleton height={20} style={{ marginBottom: 5 }} delay={1.2} width={80} />
                    <Skeleton height={20} style={{ marginBottom: 5 }} delay={1.4} />
                    <Skeleton height={20} style={{ marginBottom: 5 }} delay={1.6} width={320} />
                  </SkeletonTheme>
                </Box>
              )}
            </>
          )}
        </ModalDescription>
        <Flex justifyContent="space-between" w="full">
          <Text fontWeight="medium">{t('bills.paymentMethod')}:</Text>
          <Text fontSize="sm">{t('bills.onlinePayment')}</Text>
        </Flex>
        <Flex alignItems="flex-end" pb="sm" justifyContent="space-between">
          <Text fontSize="md" fontWeight="bold">
            {t('bills.toPay')}:
          </Text>
          {bill ? (
            <Text fontSize="lg" color={totalAmount > 0 ? 'orange' : 'grey-dark'} fontWeight="bold">
              {parseToCurrencyString(totalAmount)}
            </Text>
          ) : (
            <Skeleton height={25} width={150} />
          )}
        </Flex>
      </ModalContent>
      <ModalButtons>
        <Button onClick={() => onSubmit(onRedirectToBM)}>
          {isSubmitting ? <SpinnerDots label={t('bills.redirectInProgress')} inline /> : t('bills.goToPayments')}
        </Button>
        <Button onClick={onClose} variant="outline">
          {t('general.cancel')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default SetOnlinePayment;
