import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import cls from 'classnames';
import axios from 'axios';

import FloatingButton from '@old/components/custom/FloatingButton';
import t from 'resources/translations';
import PageTitle from '@old/components/guide/PageTitle';
import NewsItemFooter from '@old/components/old/NewsItemFooter';
import FlexColumn from '@old/components/common/FlexColumn';
import Spinner from '@old/components/common/Spinner';
import Error404 from '@old/components/error/Error404';
import { useNewsEditButton, useNewsDeleteButton, useNewsPublishButton } from 'old/buttonHooks';
import { fetchSingleNews, setTab } from 'store/actions';
import { getTabsId } from 'old/utils';

const NewsDetails = ({ match, history, loading, error, isMobile, newsItem, ...actions }) => {
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchSingleNews(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  useEffect(() => {
    if (newsItem) {
      const tabsId = getTabsId('/news');
      actions.setActiveTab(newsItem.isDraft() ? 1 : 0, tabsId);
    }
  }, [newsItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const newsEdit = useNewsEditButton(newsItem);
  const newsDelete = useNewsDeleteButton(newsItem);
  const publishNews = useNewsPublishButton(newsItem, history, { fetchSingle: true });
  const newsButtons = [newsEdit, newsDelete, publishNews];

  if (error) return <Error404 />;
  if (loading && !newsItem) return <Spinner />;
  if (!newsItem) return null;

  const pageTitle = newsItem.isDraft() ? t('menu.draft') : t('model.news');
  return (
    <div>
      <PageTitle title={pageTitle} back="/news" loading={loading} />
      <FlexColumn>
        <div
          className="bg-no-repeat bg-cover bg-center lg:h-card-big h-card-small rounded shadow"
          style={{ backgroundImage: `url(${newsItem.getImage('large')})` }}
        />
        <div className={cls('content-block', isMobile ? 'px-4' : 'px-8')}>
          <FlexColumn marginBottom>
            <div className="text-headline break-words">{newsItem.title}</div>
            <div
              className="break-words text-content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: newsItem.getContent() }}
            />
          </FlexColumn>
          <NewsItemFooter newsItem={newsItem} isDraft={newsItem.isDraft()} />
          <div className="h-px bg-teal w-full" />
        </div>
      </FlexColumn>
      <FloatingButton buttons={newsButtons} />
    </div>
  );
};

NewsDetails.defaultProps = {
  newsItem: null,
};

const mapStateToProps = ({ fetchingData: { news, loading, error }, app: { isMobile } }, { match }) => {
  return {
    newsItem: news.find(newsItem => String(newsItem.id) === String(match.params.id)),
    loading: loading.news,
    error: error.news,
    isMobile,
  };
};

export default withRouter(connect(mapStateToProps, { fetchSingleNews, setActiveTab: setTab })(NewsDetails));
