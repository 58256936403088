/* eslint-disable max-len */
import React from 'react';

const Sort = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M0,8.9h10.7V7.1H0L0,8.9z M0,2.7v1.8h16V2.7H0z M0,13.3h5.3v-1.8H0L0,13.3z" />
    </svg>
  );
};

export default Sort;
/* eslint-enable max-len */
