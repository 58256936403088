/* eslint-disable max-len */
import React from 'react';

const Filter = () => {
  return (
    <svg width="100%" viewBox="0 0 16 16">
      <path d="M2.7,8.9h10.7V7.1H2.7L2.7,8.9z M0,2.6v1.8h16V2.6H0z M6.2,13.3h3.5v-1.8H6.2L6.2,13.3z" />
    </svg>
  );
};

export default Filter;
/* eslint-enable max-len */
