import Link from '@old/components/common/Link';
import { useOldLoggedMember } from 'utils/storeUtils';

const MemberLink = ({ member, children, forceRender, className }) => {
  const loggedMember = useOldLoggedMember();
  if (!member) return null;
  const isOwnProfile = loggedMember.id === member.id;
  const memberIsClientOrOwner = member.isClient() || member.isOwner();
  if (loggedMember.isClient() && memberIsClientOrOwner && !isOwnProfile) {
    return forceRender ? children : null;
  }

  return (
    <Link to={member.getProfileUrl()} forceRender={forceRender} className={className}>
      {children}
    </Link>
  );
};

MemberLink.defaultProps = {
  forceRender: false,
  className: '',
};

export default MemberLink;
