import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import { isPhone } from 'old/utils';
import Input from '@old/components/input';
import t from 'resources/translations';
import { FORM_FARM } from 'old/constants';

const PhoneField = ({ onChangeField, placeholder, defaultDirectionalValue, label, validation, ...props }) => {
  const { name, formName, value, required } = props;

  const onChange = phoneNumber => {
    onChangeField({ formName, fieldName: name, fieldState: phoneNumber });
  };

  const validationFuncs = [
    ...validation,
    {
      condition: phoneNumber => isPhone({ value: phoneNumber }),
      errorHint: t('error.invalidPhoneNumber'),
    },
  ];

  // trick the form that it was already edited to display error
  // this is a workaround for a bug in our API
  // people can add there phone number to a farm without directional number
  useEffect(() => {
    if (formName === FORM_FARM) {
      onChangeField({ formName, fieldName: name, fieldState: value });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormField validation={validationFuncs} {...props}>
      <Input.Phone
        value={value}
        name={name}
        formName={formName}
        defaultDirectionalValue={defaultDirectionalValue}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
        required={required}
      />
    </FormField>
  );
};

PhoneField.defaultProps = {
  value: '',
  placeholder: '',
  defaultDirectionalValue: '',
  validation: [],
  label: '',
  required: false,
};

PhoneField.propTypes = {
  placeholder: PropTypes.string,
  defaultDirectionalValue: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.func.isRequired,
      errorHint: PropTypes.string,
    })
  ),
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return { value: inputValue };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(PhoneField);
