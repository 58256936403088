/**
 * @module AppRoute
 */
import { Route } from 'react-router';

import AccessDenied from 'old/pages/AccessDenied';
import { isAllowed } from 'old/utils';
import SelectFarm from 'old/pages/SelectFarm';
import SimpleLayout from 'old/layouts/SimpleLayout';
import { useFarm, useLoggedMember } from 'utils/storeUtils';
import localStorageService from 'services/storage/localStorageService';

/**
 *  Component that its responsibility is to render some UI when its path matches the current URL.
 * @param {ElementType} props.Component specifies what component that will be displayed on this route
 * @param {ElementType} props.Layout specifies what layout that will be used for this route
 * @param  {Function} getPermissionKey return a permissionKey for specifi route
 */

const AppRoute = ({ Component, Layout, getPermissionKey, ...restProps }) => {
  const loggedMember = useLoggedMember();
  const farm = useFarm();
  const token = localStorageService._getToken();
  const user = localStorageService._getUser();

  const renderRoute = routeProps => {
    const isSimpleLayout = Layout.displayName === 'SimpleLayout';
    // handling case: token exists and we can't fetch member and farm because slug is an empty string
    if (!farm && !loggedMember && token && !isSimpleLayout && user?.phoneVerified) {
      return (
        <SimpleLayout>
          <SelectFarm />
        </SimpleLayout>
      );
    }

    if (isAllowed(getPermissionKey(routeProps.match.params), routeProps.match.params, true /* shallow */)) {
      return (
        <Layout loggedMember={loggedMember} farm={farm}>
          <Component {...routeProps} />
        </Layout>
      );
    } else if (token && !loggedMember) {
      // this can only (?) happen for a moment when loggedMember is not set in redux yet
      // and we don't want to show <AccessDenied /> for this moment
      return null;
    }
    return (
      <Layout loggedMember={loggedMember} farm={farm}>
        <AccessDenied />
      </Layout>
    );
  };

  return <Route {...restProps} render={renderRoute} />;
};

export default AppRoute;
