/* eslint-disable */
import React from 'react';

const PhoneSmsLarge = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 41.49 59.59"
    >
    <g transform="translate(-6730.833 -4468.761)">
        <path d="M6772.309 4500.292v-23.635a7.9 7.9 0 0 0-7.9-7.9h-19.894a7.854 7.854 0 0 0-3.033.607c-.084.034-.167.07-.25.107l-.03.013a7.936 7.936 0 0 0-2.189 1.481h-6.66a1.524 1.524 0 0 0-1.522 1.522v10.213a1.524 1.524 0 0 0 1.522 1.523h4.214v4.6h-3.855a1.524 1.524 0 0 0-1.522 1.523v10.208a1.524 1.524 0 0 0 1.522 1.523h3.841v4.959h-3.841a1.524 1.524 0 0 0-1.522 1.523v10.208a1.524 1.524 0 0 0 1.522 1.523h3.841v.161a7.9 7.9 0 0 0 7.9 7.9h19.96a7.932 7.932 0 0 0 7.911-7.924v-19.987a.811.811 0 0 0-.015-.148zm-30.095-29.449a6.229 6.229 0 0 1 2.252-.42l19.946-.022a6.263 6.263 0 0 1 6.234 5.75h-17.767v-3.658a1.524 1.524 0 0 0-1.523-1.522h-9.433c.097-.044.193-.088.291-.128zM6732 4482.7v-10.208a.352.352 0 0 1 .352-.351h19a.352.352 0 0 1 .352.351v10.092a.469.469 0 0 1-.469.469h-1.565a.584.584 0 0 0-.414.171l-2.677 2.676v-2.262a.585.585 0 0 0-.585-.585h-13.643a.352.352 0 0 1-.351-.353zm.359 17.855v-10.208a.353.353 0 0 1 .352-.352h19a.352.352 0 0 1 .351.352v10.092a.469.469 0 0 1-.468.469h-1.565a.587.587 0 0 0-.414.171l-2.676 2.676v-2.262a.585.585 0 0 0-.585-.585h-13.643a.353.353 0 0 1-.348-.351zm0 18.214v-10.208a.352.352 0 0 1 .352-.351h19a.351.351 0 0 1 .351.351v10.091a.468.468 0 0 1-.468.469h-1.565a.588.588 0 0 0-.414.172l-2.676 2.676v-2.262a.586.586 0 0 0-.585-.586h-13.643a.352.352 0 0 1-.348-.35zm38.306 1.684a6.211 6.211 0 0 1-6.243 6.223h-19.96a6.253 6.253 0 0 1-6.256-6.25v-.133h7.562v3.09a.585.585 0 0 0 1 .414l3.5-3.5h1.323a1.638 1.638 0 0 0 1.268-.6h17.8zm0-2.428h-17.431v-9.464a1.525 1.525 0 0 0-1.523-1.523h-13.506v-4.959h7.562v3.09a.585.585 0 0 0 1 .414l3.5-3.5h1.323a1.642 1.642 0 0 0 1.64-1.64v-10.092a1.525 1.525 0 0 0-1.523-1.523h-13.506v-4.6h7.2v3.09a.586.586 0 0 0 1 .414l3.5-3.5h1.323a1.642 1.642 0 0 0 1.64-1.64v-4.8l17.79.008z"/>
        <path d="M6944.274 4505.522h3.4a.937.937 0 0 0 .935-1 .958.958 0 0 0-.967-.874h-3.365a.937.937 0 0 0 0 1.874z" transform="translate(-187.612 -30.801)"/>
        <path d="M6889.77 4927a.937.937 0 1 0 0 1.873h8.075a.937.937 0 1 0 0-1.873z" transform="translate(-139.492 -404.564)"/>
        <path d="M6755.792 4517.942h15.074a.586.586 0 1 0 0-1.171h-15.074a.586.586 0 0 0 0 1.171z" transform="translate(-21.518 -42.386)"/>
        <path d="M6755.792 4538.878h15.074a.586.586 0 1 0 0-1.171h-15.074a.586.586 0 0 0 0 1.171z" transform="translate(-21.518 -60.87)"/>
        <path d="M6763.239 4560.686h-7.448a.585.585 0 0 0 0 1.171h7.448a.585.585 0 1 0 0-1.171z" transform="translate(-21.518 -81.156)"/>
        <path d="M6758.855 4670.367h15.075a.586.586 0 0 0 0-1.171h-15.075a.586.586 0 0 0 0 1.171z" transform="translate(-24.223 -176.956)"/>
        <path d="M6758.855 4691.3h15.075a.586.586 0 0 0 0-1.171h-15.075a.586.586 0 0 0 0 1.171z" transform="translate(-24.223 -195.441)"/>
        <path d="M6766.3 4713.11h-7.449a.586.586 0 0 0 0 1.171h7.449a.586.586 0 0 0 0-1.171z" transform="translate(-24.223 -215.727)"/>
        <path d="M6758.855 4825.857h15.075a.586.586 0 0 0 0-1.171h-15.075a.586.586 0 0 0 0 1.171z" transform="translate(-24.223 -314.233)"/>
        <path d="M6758.855 4846.793h15.075a.586.586 0 0 0 0-1.171h-15.075a.586.586 0 0 0 0 1.171z" transform="translate(-24.223 -332.716)"/>
        <path d="M6766.3 4868.6h-7.449a.586.586 0 0 0 0 1.172h7.449a.586.586 0 0 0 0-1.172z" transform="translate(-24.223 -353.003)"/>
    </g>
    </svg>
  );
};

export default PhoneSmsLarge;
/* eslint-enable */
