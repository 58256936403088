import { useQuery } from 'react-query';
import axios from 'axios';
import { useHistory } from 'react-router';
import moment from 'moment';
import { PageTitle } from 'components/combinations';
import { Box, Stack, Text } from 'components/elements';
import t from 'resources/translations';
import { mapUserFormToParams, useAppDispatch, useNewLayoutColor } from 'utils';
import { MemberService } from 'services';
import UsersForm from './UsersForm';
import Error404 from 'old/components/error/Error404';
import Spinner from 'old/components/common/Spinner';
import Member, { User } from 'models/Member';
import { TUsersFormState } from 'resources/types/membersTypes';
import { DATE_FORMAT } from 'config/constans';
import { getChanges, getErrorMessage, notify } from 'old/utils';
import { openModal } from 'store/actions';
import { refetchProfile } from 'store/auth';
import { useEffect } from 'react';

type TUsersFormEdit = {
  match: {
    params: {
      id: string;
    };
  };
};

const UsersFormEdit = ({ match }: TUsersFormEdit) => {
  useNewLayoutColor();
  const history = useHistory();
  const { id } = match.params;
  const dispatch = useAppDispatch();
  const memberState = useQuery(
    ['USER_FORM', { id }],
    async () => {
      return await MemberService.fetch(id);
    },
    {}
  );

  useEffect(() => {
    return () => {
      memberState.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (memberState.isLoading) return <Spinner />;
  if (memberState.isError) return <Error404 />;
  if (!memberState.data) return history.push('dashboard');

  const getBase64 = async (defaultAvatar: string) => {
    const b64 = await axios.get(defaultAvatar, {
      responseType: 'arraybuffer',
    });

    const image = Buffer.from(b64.data, 'binary').toString('base64');
    return `data:${b64.headers['content-type'].toLowerCase()};base64,${image}`;
  };

  const onSubmitForm = async (changes: TUsersFormState) => {
    const defaultAvatar = changes.defaultAvatar ? await getBase64(changes.defaultAvatar) : null;
    const createParams = mapUserFormToParams(changes, defaultAvatar);

    try {
      await MemberService.updateUser(member.identity.id, createParams);
      if (changes.phone) {
        history.push('/logout');
      } else {
        await dispatch(refetchProfile());
        history.push(member.isPending() ? '/' : `/users/${member.id}`);
      }
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };

  const onSubmit = async (changes: TUsersFormState) => {
    const values = getChanges(changes, initialValues) as TUsersFormState;
    if (values.phone) {
      dispatch(
        openModal('confirmModal', {
          header: t('message.phoneNumberChange'),
          content: (
            <Stack>
              <Text fontWeight="bold">{t('message.confirmPhoneNumberChange')}</Text>
              <Text>{t('message.confirmPhoneAreYouSure')}</Text>
            </Stack>
          ),
          onSubmitConfirm: () => onSubmitForm(values),
        })
      );
    } else {
      await onSubmitForm(values);
    }
  };

  const member = new Member(memberState.data);
  const user = member.identity as User;

  const initialValues: TUsersFormState = {
    firstname: user.getFirstName(),
    lastname: user.getLastName(),
    phone: user.phone,
    password: '',
    passwordConfirmation: '',
    avatar: user.getAvatar('medium'),
    defaultAvatar: null,
    showPassword: false,
    birthday: moment(user.birthday).format(DATE_FORMAT),
    privateProfile: user.privateProfile,
    email: user.email,
  };

  return (
    <Stack p={['md', 'lg']} borderRadius={4} backgroundColor="white">
      <PageTitle title={t('model.edit.members')} back px={0} />
      <Box pt="md">
        <UsersForm initialValues={initialValues} onSubmit={onSubmit} />
      </Box>
    </Stack>
  );
};

export default UsersFormEdit;
