import { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import FlexRow from '@old/components/common/FlexRow';
import Box from '@old/components/common/Box';
import Spinner from '@old/components/common/Spinner';
import PlaceStatisticsPieChart from '@old/components/view/charts/PlaceStatisticsPieChart';
import PlaceStatisticsBarChart from '@old/components/view/charts/PlaceStatisticsBarChart';
import SelectDateRangeModal from '@old/components/modals/SelectDateRange';
import HeatMap from '@old/components/view/charts/HeatMap';
import WeekSwitch from '@old/components/old/WeekSwitch';
import PageTitle from '@old/components/guide/PageTitle';
import { useBarChartData, useHeatMap, useEventsWithChart } from '@old/hooks';
import t from 'resources/translations';
import RowAligner from '@old/components/common/RowAligner';
import Error404 from '@old/components/error/Error404';
import { fetchPlace } from 'store/actions';
import EventsList from '@old/components/view/list/Event';

const PlaceStatistics = ({ match, loading, error, place, ...actions }) => {
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchPlace(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  const [selectedDay, setSelectedDay] = useState('');
  const [barChartDateRange, setBarChartDateRange] = useState({
    start: moment().startOf('week'),
    end: moment().endOf('week'),
  });

  const [pieChartDateRange, pieChartSetDateRange] = useState({
    start: moment().subtract(30, 'days').startOf('day'),
    end: moment().endOf('day'),
  });
  const eventsGroupedByDay = useEventsWithChart(barChartDateRange, {
    'with_place[]': place ? place.id : 0,
  });

  const [barChartData, loadingBarChart] = useBarChartData(match.params.id, 'places', barChartDateRange);

  const heatMapProps = useHeatMap(match.params.id, 'places', pieChartDateRange);

  if (error) return <Error404 />;

  if (loading && !place) {
    return <Spinner />;
  }

  if (!place) return null;

  const onBarClick = barProps => {
    const activeLabel = barProps ? barProps.activeLabel : '';
    const dayData = barChartData.find(item => item.name === activeLabel);
    setSelectedDay(dayData ? dayData.label : '');
  };

  return (
    <>
      <PageTitle title={place.getName()} back loading={loading} />
      <SelectDateRangeModal dateRange={pieChartDateRange} onSave={pieChartSetDateRange} />
      <FlexRow cols="10/6" marginBottom>
        <Box fullHeight>
          <HeatMap {...heatMapProps} />
        </Box>
        <Box fullHeight>
          <PlaceStatisticsPieChart placeId={match.params.id} dateRange={pieChartDateRange} />
        </Box>
      </FlexRow>
      <Box>
        <RowAligner className="justify-around text-center">
          {t('placeStatistics.hoursStatLabel')}
          <WeekSwitch dateRange={barChartDateRange} setDateRange={setBarChartDateRange} />
        </RowAligner>
        {loadingBarChart && (
          <div style={{ minHeight: 350 }}>
            <Spinner />
          </div>
        )}
        {!loadingBarChart && (
          <PlaceStatisticsBarChart activeDay={selectedDay} data={barChartData} onBarClick={onBarClick} />
        )}
      </Box>
      {selectedDay && !isEmpty(eventsGroupedByDay[selectedDay]) && (
        <div className="text-heading mt-4">{moment(selectedDay, 'YYYY-MM-DD').format('dddd, DD.MM.YYYY')}</div>
      )}
      <EventsList
        hideEmptyMessage
        fetchedEvents={(eventsGroupedByDay[selectedDay] || [])
          .sort((a, b) => a.startDate.diff(b.startDate))
          .filter(event => event.isFinished || event.isCancelled)}
      />
    </>
  );
};

PlaceStatistics.defaultProps = {
  place: null,
};

const mapStateToProps = ({ fetchingData: { places, loading, error } }, { match }) => {
  return {
    place: places.find(place => String(place.id) === String(match.params.id)),
    loading: loading.places,
    error: error.places,
  };
};

export default connect(mapStateToProps, { fetchPlace })(PlaceStatistics);
