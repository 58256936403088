import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import { useOutsideClick } from '@old/hooks';
import InputWrapper from '@old/components/common/InputWrapper';
import TimePicker from '@old/components/custom/TimePicker';
import TimePickerSpinner from '@old/components/custom/TimePickerSpinner';
import ModalSimple from '@old/components/common/ModalSimple';
import GroupButton from '@old/components/guide/GroupButton';
import Button from '@old/components/guide/Button';
import { useShallowSelector } from 'old/hooks';

const TimePickerInput = ({
  value,
  onChange,
  name,
  label,
  required,
  header,
  readOnly,
  hidePicker,
  hideButtons,
  hoursLabel,
  minutesLabel,
  onKeyDown,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [isHoursActive, setIsHoursActive] = useState(false);
  const [isMinutesActive, setIsMinutesActive] = useState(false);
  const [hours, minutes] = (value || ':').split(':');
  const refHours = useRef();
  const refMinutes = useRef();
  const inputRef = useRef();
  const isSmallScreen = useShallowSelector(({ app }) => app.isMobile || app.isTablet);

  const mode = isHoursActive ? 'hours' : 'minutes';
  const invalidChars = ['-', '+', 'e', ',', '.'];
  const limits = {
    hours: { min: 0, max: 23 },
    minutes: { min: 0, max: 59 },
  };

  useOutsideClick(inputRef, () => {
    if (!isSmallScreen) {
      setShowPicker(false);
      setIsHoursActive(false);
      setIsMinutesActive(false);
    }
  });

  const toggleInput = () => {
    if (!readOnly) {
      setShowPicker(!showPicker);
    }
  };

  const onChangeHours = ({ target }) => {
    const newChar = target.value[target.value?.length - 1];
    let newValue = target.value;
    if (!/[0-9]/.test(newValue) && newValue !== '') {
      return false;
    }
    if (newValue.length === 1) {
      newValue = `0${newChar}`;
    }
    if (parseInt(newValue, 10) > limits.hours.max) {
      newValue = limits.hours.max;
    }
    if (newValue.length === 3) {
      newValue = newValue.substring(1);
    }
    if (parseInt(newChar, 10) >= 3 || (newValue.length >= 2 && newValue[0] !== '0')) {
      refMinutes.current.focus();
    }

    onChange(`${newValue}:${minutes}`);
    return true;
  };

  const onChangeMinutes = ({ target }) => {
    const newChar = target.value[target.value?.length - 1];
    let newValue = target.value;
    if (!/[0-9]/.test(newValue) && newValue !== '') {
      return false;
    }

    if (newValue.length === 1) {
      newValue = `0${newChar}`;
    }

    if (newValue.length === 3) {
      newValue = newValue.substring(1);
    }
    onChange(`${hours}:${newValue}`);
    return true;
  };

  const setFocus = () => {
    if (mode === 'hours') {
      refHours.current.focus();
    } else {
      refMinutes.current.focus();
    }
  };

  const increment = () => {
    const prevValue = isHoursActive ? hours : minutes;
    let newValue = parseInt(prevValue, 10) + 1;
    if (newValue > limits[mode].max) {
      newValue = limits[mode].max;
    }
    newValue = String(newValue).length >= 2 ? newValue : `0${newValue}`;
    onChange(isHoursActive ? `${newValue}:${minutes}` : `${hours}:${newValue}`);
    setFocus();
  };

  const decrement = () => {
    const prevValue = isHoursActive ? hours : minutes;
    let newValue = parseInt(prevValue, 10) - 1;
    if (newValue <= -1) {
      newValue = 0;
    }
    newValue = String(newValue).length >= 2 ? newValue : `0${newValue}`;
    onChange(isHoursActive ? `${newValue}:${minutes}` : `${hours}:${newValue}`);
    setFocus();
  };

  const onKeyPressDown = e => {
    if (e.key === 'Tab') {
      setIsHoursActive(false);
      setIsMinutesActive(false);
    }
    if (e.key === 'ArrowUp') {
      increment();
      e.preventDefault();
    }
    if (e.key === 'ArrowDown') {
      decrement();
      e.preventDefault();
    }
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
    onKeyDown(e);
  };

  return (
    <InputWrapper ref={inputRef} relative label={label} required={required} disabled={readOnly} className="input-time">
      <div className="px-3 w-full items-center flex flex-row">
        <div>
          <input
            ref={refHours}
            type="number"
            inputMode="numeric"
            name={`${name}-hrs`}
            placeholder="HH"
            value={hours}
            className={cls('text-center', { 'text-teal': isHoursActive })}
            onChange={onChangeHours}
            onKeyDown={onKeyPressDown}
            onClick={e => e.target.select()}
            min={0}
            max={23}
            onFocus={() => {
              setIsHoursActive(true);
              setIsMinutesActive(false);
            }}
            style={{ width: 27, minWidth: 27, padding: 0 }}
            onBlur={() => {
              if (parseInt(hours, 10) >= 24) {
                onChange(`23:${minutes}`);
              }
            }}
          />
          <span className="opacity-50">{hoursLabel}</span>
        </div>
        <div className="px-1 self-center">
          <span className="opacity-50">:</span>
        </div>
        <div>
          <input
            ref={refMinutes}
            type="number"
            name={`${name}-min`}
            inputMode="numeric"
            placeholder="MM"
            value={minutes}
            min={0}
            max={59}
            className={cls('text-center', { 'text-teal': isMinutesActive })}
            onChange={onChangeMinutes}
            onKeyDown={onKeyPressDown}
            onClick={e => e.target.select()}
            onFocus={() => {
              setIsMinutesActive(true);
              setIsHoursActive(false);
            }}
            style={{ width: 27, minWidth: 27, padding: 0 }}
            onBlur={() => {
              if (parseInt(minutes, 10) >= 60) {
                onChange(`${hours}:59`);
              }
            }}
          />
          <span className="opacity-50">{minutesLabel}</span>
        </div>
        {!hidePicker && (
          <div className="input-icon ml-auto pl-3">
            <ButtonSimple tabIndex={-1} onClick={() => toggleInput()}>
              <Icon.ClockCircularOutline className={cls(readOnly && 'inactive')} />
            </ButtonSimple>
          </div>
        )}
      </div>
      {!hideButtons && (
        <div style={{ height: 45 }}>
          <GroupButton vertical small>
            <Button disabled={!(isMinutesActive || isHoursActive)} onClick={increment}>
              <Icon.ArrowUp small />
            </Button>
            <Button disabled={!(isMinutesActive || isHoursActive)} onClick={decrement}>
              <Icon.ArrowDown small />
            </Button>
          </GroupButton>
        </div>
      )}
      {isSmallScreen ? (
        <TimePickerMobile
          value={value}
          onChange={onChange}
          showPicker={showPicker}
          setShowPicker={setShowPicker}
          header={header}
          readOnly={readOnly}
        />
      ) : (
        <TimePickerDesktop
          value={value}
          onChange={onChange}
          isOpen={showPicker}
          onClose={() => setShowPicker(false)}
          header={header}
          readOnly={readOnly}
        />
      )}
    </InputWrapper>
  );
};

TimePickerInput.defaultProps = {
  value: '',
  required: false,
  label: '',
  name: '',
  header: '',
  readOnly: false,
  hidePicker: false,
  hideButtons: false,
  hoursLabel: '',
  minutesLabel: '',
  onKeyDown: () => {},
};

TimePickerInput.propTypes = {
  value: PropTypes.string,
  header: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  hidePicker: PropTypes.bool,
  hoursLabel: PropTypes.string,
  minutesLabel: PropTypes.string,
  hideButtons: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

export default TimePickerInput;

const TimePickerMobile = ({ value, onChange, showPicker, setShowPicker, header, defaultTime, readOnly }) => {
  const [selectedTime, setSelectedTime] = useState(value);

  useEffect(() => {
    setSelectedTime(value);
  }, [value]);

  const onChangeTime = test => {
    setSelectedTime(test);
  };

  const onSubmit = () => {
    onChange(selectedTime);
    setShowPicker(false);
  };

  return (
    !readOnly && (
      <ModalSimple close={() => setShowPicker(false)} open={showPicker} small basic>
        <ModalSimple.Body>
          <TimePickerSpinner value={selectedTime} onChange={onChangeTime} header={header} defaultTime={defaultTime} />
        </ModalSimple.Body>
        <ModalSimple.Action close={() => setShowPicker(false)} onSubmit={onSubmit} />
      </ModalSimple>
    )
  );
};

TimePickerMobile.defaultProps = {
  value: '',
  showPicker: false,
  header: '',
  defaultTime: '12:30',
  readOnly: true,
};

TimePickerMobile.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showPicker: PropTypes.bool,
  setShowPicker: PropTypes.func.isRequired,
  header: PropTypes.string,
  defaultTime: PropTypes.string,
  readOnly: PropTypes.bool,
};

const TimePickerDesktop = ({ value, onChange, isOpen, onClose, header, defaultTime, readOnly }) => {
  return (
    !readOnly &&
    isOpen && (
      <div className="input-picker-wrapper">
        <TimePicker value={value} onChange={onChange} onClose={onClose} header={header} defaultTime={defaultTime} />
      </div>
    )
  );
};

TimePickerDesktop.defaultProps = {
  value: '',
  header: '',
  defaultTime: '12:30',
  isOpen: false,
  readOnly: true,
};

TimePickerDesktop.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  defaultTime: PropTypes.string,
  readOnly: PropTypes.bool,
};
