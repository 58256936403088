/**
 * @module InputWithAction
 */
import React from 'react';
import PropTypes from 'prop-types';
import RowAligner from '@old/components/common/RowAligner';
/**
 * Wrapper for input and button that displays them in sigle line
 * @param  {Node} props.children
 */
const InputWithAction = ({ children }) => {
  return (
    <RowAligner className="input-with-action" separated="small">
      {children}
    </RowAligner>
  );
};

InputWithAction.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputWithAction;
