/* eslint-disable max-len */
import React from 'react';

const User = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M16,16H0v-2c0-2.6,5.4-4,8-4s8,1.4,8,4V16z M8,0C8,0,8,0,8,0c2.2,0,4,1.8,4,4s-1.8,4-4,4C5.8,8,4,6.2,4,4l0,0l0,0C4,1.8,5.8,0,8,0L8,0L8,0z" />
    </svg>
  );
};

export default User;
/* eslint-enable max-len */
