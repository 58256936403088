import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { withRouter } from 'react-router';
import Spinner from '@old/components/common/Spinner';
import ChangeHistoryList from '@old/components/view/list/EventHistory';
import Error404 from '@old/components/error/Error404';
import PagePagination from '@old/components/old/PagePagination';
import { notify, getErrorMessage, getPaginationId } from 'old/utils';
import config from '@old/config';
import Model from '@old/model';
import { setPagination } from 'store/actions';

const EventChangeHistory = ({ match, pagination, history, ...action }) => {
  const { minItemsPerPage } = config;
  const [versions, setVersions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    setLoading(true);
    const fetchEventHistory = async () => {
      try {
        const [data, versionPagination] = await Model.Events.getChangeHistory(match.params.id, {
          page: getActivePage() || 1,
          per_page: minItemsPerPage,
          cancelToken: source.token,
        });
        // if changes object is empty do not display it
        setVersions(data.filter(version => !isEmpty(version.changes)));
        // When you delete last element on page, go back to previous one
        const pag = { ...versionPagination };
        const lastPage = Math.ceil(pag.count / pag.per_page);
        if (pag.per_page * pag.current > pag.count) {
          if (lastPage && pag.current > lastPage) {
            pag.current = lastPage;
            history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
          }
          if (pag.current === 0) {
            pag.current = 1;
          }
        }
        action.setPagination(pag, getPaginationKey());
        setLoading(false);
      } catch (e) {
        if (!axios.isCancel(e)) {
          notify(getErrorMessage(e), { type: 'error' });
          setLoading(false);
        }
      }
    };
    fetchEventHistory();
    return () => source.cancel();
  }, [pagination.current, history.location.state]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Spinner />;

  if (!versions) return <Error404 />;

  return (
    <>
      <ChangeHistoryList versions={versions} />
      <PagePagination paginationKey={getPaginationKey()} />
    </>
  );
};

EventChangeHistory.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
      model: PropTypes.node,
    }),
  }).isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number,
  }).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({}),
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ pagination }) => {
  const paginationKey = getPaginationId(`${window.location.pathname}`);
  return {
    pagination: pagination[paginationKey] || {},
  };
};

export default withRouter(connect(mapStateToProps, { setPagination })(EventChangeHistory));
