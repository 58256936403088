import { Card, CardContent, CardHeader } from 'components/combinations';
import { Flex, Grid, Icons, Text } from 'components/elements';
import PagePagination from 'components/modules/PagePagination';
import Bill from 'models/Bill';
import t from 'resources/translations';
import { memo } from 'react';
import { APIPagination } from 'resources/types/commonTypes';
import { IStack } from 'resources/types/elementsTypes';
import BillClientStatementItem from './BillClientStatementItem';

const BillClientStatement = ({
  bills,
  pagination,
  ...stackProps
}: {
  bills: Bill[];
  pagination?: APIPagination;
  stackProps?: IStack;
}) => {
  return (
    <Card {...stackProps}>
      <CardHeader icon={<Icons.CircleChartCircle fill="grey-dark-80" bgColor="grey-lighter-50" />}>
        <Text>{t('bills.statement')}</Text>
      </CardHeader>
      <CardContent bgColor="grey-lighter-50">
        <Grid gridTemplateColumns="90px 30px 1fr 100px 100px 120px 40px" gridColumnGap={30}>
          <Text fontWeight="medium">{t('general.date')}</Text>
          <Text fontWeight="medium">{t('general.type')}</Text>
          <Text fontWeight="medium">{t('general.name')}</Text>
          <Text fontWeight="medium" textAlign="right">
            {t('bills.due')}
          </Text>
          <Text fontWeight="medium" textAlign="right">
            {t('bills.deposit')}
          </Text>
          <Text fontWeight="medium">{t('labels.paymentStatus')}</Text>
          <Flex />
        </Grid>
      </CardContent>
      <CardContent px={0} pt={0}>
        {(bills || []).map(bill => (
          <BillClientStatementItem key={bill.id} bill={bill} />
        ))}
        {pagination && <PagePagination pagination={pagination} />}
      </CardContent>
    </Card>
  );
};

export default memo(BillClientStatement);
