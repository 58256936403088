import { AxiosResponse as ARes } from 'axios';

import HTTP from 'config/axios';
import { IResWithPagination } from 'resources/types/commonTypes';
import {
  IAddPaymentParams,
  ITicketOfferRes,
  ITicketOffersCreateReq,
  ITIcketOffersFetchParams,
} from 'resources/types/TicketOfferTypes';

class TicketOfferService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async fetchAll(params?: ITIcketOffersFetchParams) {
    const response: ARes<IResWithPagination<ITicketOfferRes[]>> = await HTTP.get(`${this.apiBaseURL}/ticket_offers`, {
      params,
    });
    return response.data;
  }

  async create(params: ITicketOffersCreateReq) {
    const response: ARes<ITicketOfferRes> = await HTTP.post(`${this.apiBaseURL}/ticket_offers`, {
      ticket_offer: { ...params },
    });
    return response.data;
  }

  async update(id: number | string, params: ITicketOffersCreateReq) {
    const response: ARes<ITicketOfferRes> = await HTTP.patch(`${this.apiBaseURL}/ticket_offers/${id}`, {
      ticket_offer: { ...params },
    });
    return response.data;
  }

  async fetch(id: number) {
    const response: ARes<ITicketOfferRes> = await HTTP.get(`${this.apiBaseURL}/ticket_offers/${id}`);
    return response.data;
  }

  async delete(id: number) {
    const response: ARes<ITicketOfferRes> = await HTTP.delete(`${this.apiBaseURL}/ticket_offers/${id}`);
    return response.data;
  }

  async setTicket(id: number, membershipId: number | string, activationDate: string) {
    const response: ARes<ITicketOfferRes> = await HTTP.post(`${this.apiBaseURL}/ticket_offers/${id}/set`, {
      membership_id: membershipId,
      activation_date: activationDate,
    });
    return response.data;
  }

  async buyTicket(id: number | string, params: IAddPaymentParams) {
    const response: ARes<ITicketOfferRes> = await HTTP.post(`${this.apiBaseURL}/ticket_offers/${id}/buy`, {
      ...params,
    });
    return response.data;
  }
}
export default new TicketOfferService();
