/* eslint-disable max-len */
import React from 'react';

const Invite = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M10.5,10.8l5.5,4.4V6L10.5,10.8z M9.7,11.5L8,13l-1.7-1.5L0.7,16h14.5L9.7,11.5z M0,6v9.3l5.5-4.4L0,6z M2,0.8v5.5L3.9,8
        L8,11.7L12.1,8L14,6.3V0.8C14,0.4,13.7,0,13.2,0H2.8C2.3,0,2,0.4,2,0.8L2,0.8z M10.9,5.2H8.6v2.3c0,0.1-0.1,0.2-0.2,0.2H7.6
        c-0.1,0-0.2-0.1-0.2-0.2V5.2H5.1C5,5.2,4.9,5,4.9,5V4.1C4.9,4,5,3.9,5.1,3.9h2.3V1.7c0-0.1,0.1-0.2,0.2-0.2h0.9
        c0.1-0.1,0.2,0.1,0.2,0.2v2.3h2.3c0.1,0,0.2,0.1,0.2,0.2V5C11.1,5.1,11,5.2,10.9,5.2z"
      />
    </svg>
  );
};

export default Invite;
/* eslint-enable max-len */
