/**
 * @module ConfirmModal
 */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@old/components/common/Modal';
import t from 'resources/translations';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';
import FlexColumn from '@old/components/common/FlexColumn';
import { notify, getErrorMessage } from 'old/utils';

/**
 * A component that displays a modal window.
 * @param  {Function} props.onClose  A function that closes the modal
 * @param  {String|ElementType} [props.header] Title modal
 * @param  {String} [props.content] Content modal
 * @param  {Function} [props.onSubmitConfirm] Function that specifies what happens on submit button click
 * @param  {String} [props.approveLabel] Text in button of approval
 * @param  {Function} [props.afterSubmit] Function that specifies what happens after submit button click
 * @param  {Function} [props.onCancel] Function that specifies what happens after cancel button click
 */

const ConfirmModal = ({ onClose, header, content, onSubmitConfirm, approveLabel, afterSubmit, onCancel }) => {
  const onSubmit = async () => {
    try {
      await onSubmitConfirm();
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      afterSubmit();
    }
  };

  const onCancelClick = () => {
    onClose();
    onCancel();
  };

  return (
    <Modal header={header} onClose={onClose} isOpen>
      <FlexColumn>
        <div>{content}</div>
        <FlexRow>
          <ButtonOutline onClick={onCancelClick} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button onClick={onSubmit} fluid async>{approveLabel}</Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  approveLabel: t('general.save'),
  onSubmitConfirm: () => { },
  afterSubmit: () => { },
  onCancel: () => { },
  header: '',
  content: '',
};

ConfirmModal.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onClose: PropTypes.func.isRequired,
  approveLabel: PropTypes.string,
  onSubmitConfirm: PropTypes.func,
  afterSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ConfirmModal;
