import React from 'react';
import { isEqual, isEmpty, lowerFirst, uniqueId } from 'lodash';
import moment from 'moment';

import config from '@old/config';
import t from 'resources/translations';
import { redirectTo } from './Common';

/**
 * Function that compare two objects and returns only modified values
 * @memberof Utils
 * @function
 * @param  {Object} values
 * @param  {Object} initValues
 * @return {Object}
 */
export const getChanges = (values, initValues) => {
  const changes = {};
  Object.keys(values).forEach(fieldName => {
    if (!isEqual(values[fieldName], initValues[fieldName])) {
      changes[fieldName] = values[fieldName];
    }
  });

  return changes;
};

/**
 * Function that takes custom initial form values and returns them to form with rest of the default values
 * @memberof Utils
 * @function
 * @param  {String} formName
 * @param  {Object} customInitValues custom values that replace default values
 * @return {Object}
 */
export const getInitValues = ({ formName, customInitValues = {} }) => {
  if (!formName) return {};
  const data = {
    events: {
      eventName: '',
      eventDescription: '',
      startDate: moment()
        .hour(moment().hour() + 1)
        .startOf('hour')
        .format(config.dateTimeFormat),
      endDate: '',
      type: null,
      places: null,
      horses: [],
      participants: [],
      inviteParticipants: [],
      difficulty: config.difficulty[0],
      instructors: [],
      visibility: 'true',
      special: 'false',
      attendees: [1, 5],
    },
    events_edit: {
      eventName: '',
      eventDescription: '',
      startDate: moment()
        .hour(moment().hour() + 1)
        .startOf('hour')
        .format(config.dateTimeFormat),
      endDate: '',
      type: null,
      places: null,
      horses: [],
      difficulty: config.difficulty[0],
      instructors: [],
      visibility: 'true',
      special: 'false',
      attendees: [1, 5],
    },
    proposal: {
      proposalName: '',
      proposalDescription: '',
      startDate: moment()
        .hour(moment().hour() + 1)
        .startOf('hour')
        .format(config.dateTimeFormat),
      endDate: '',
      type: null,
      places: null,
      horses: [],
      participations: [],
      difficulty: config.difficulty[0],
      instructors: [],
      visibility: true,
      special: false,
      attendees: [1, 5],
    },
    news: {
      newsTitle: '',
      content: '',
      image: '',
    },
    place: {
      placeName: '',
      placeSlug: '',
    },
    eventType: {
      eventTypeName: '',
      eventTypeSlug: '',
      timeIntervals: [],
      defaultEventCost: '',
      defaultEventPlace: null,
      picture: null,
      avatar: null,
    },
    horse: {
      horseName: '',
      range: '',
      eventTypes: [],
      difficulty: config.difficulty[0],
      breed: '',
      birthday: '',
      horseDescription: '',
      healthStatus: '',
    },
    user: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      'confirm-password': '',
      phone: '',
      birthday: '',
      avatar: '',
      defaultAvatar: null,
    },
    farm: {
      farmName: '',
      phone: '',
      email: '',
      www: '',
      farmDescription: '',
      staff: '',
      address: '',
      postcode: '',
      city: '',
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      coordinates: [0, 0],
    },
    billsFilters: {
      start: '',
      end: '',
      paymentType: [],
      participants: [],
      horses: [],
      instructors: [],
      places: [],
      statuses: [],
    },
    farm_payments: {
      bluemedia_service_id: '',
      bluemedia_service_key: '',
      payments_enabled: false,
    },
    login: {
      email: '',
      password: '',
    },
    groups: {
      groupName: '',
      groupDescription: '',
      membersIds: [],
    },
  }[formName];
  if (!isEmpty(customInitValues)) {
    Object.keys(data).forEach(key => {
      if (customInitValues[key]) {
        data[key] = customInitValues[key];
      }
    });
  }
  return data;
};

/**
 * [todo description]
 * @memberof Utils
 * @function
 * @param  {Object}  formState
 * @param  {Boolean} [initValue=false]
 * @return {Array} [todo description]
 */
export const mapFormStateToData = ({ formState, initValue = false }) => {
  const arrayOfData = [];
  Object.entries(formState).forEach(fieldState => {
    const key = fieldState[0];
    const fieldData = initValue ? fieldState[1] : fieldState[1].value;

    if (!['validate', 'key'].includes(key)) {
      if (fieldData && fieldData.value) {
        arrayOfData[key] = fieldData.value;
      } else if (fieldData && fieldData[0] && fieldData[0].value) {
        arrayOfData[key] = fieldData.map(fieldValue => fieldValue.value);
      } else {
        arrayOfData[key] = fieldData;
      }
    }
  });

  return arrayOfData;
};

/**
 * [todo description]
 * @memberof Utils
 * @function
 * @param  {*} value [todo description]
 * @return {Boolean} [todo description]
 */
export const isEmptyExtended = value => {
  switch (typeof value) {
    case 'number':
      return isEmpty(String(value));
    case 'boolean':
      return value !== true;
    default:
      return isEmpty(value);
  }
};

/**
 * Function to check if email value is correct
 * @memberof Utils
 * @function
 * @param {String} value
 * @return {Boolean}
 */
export const isEmail = ({ value }) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return regex.test(value);
};

/**
 * Function to check if phone value is correct
 * @memberof Utils
 * @function
 * @param {String} value
 * @return {Boolean}
 */
export const isPhone = ({ value }) => {
  const regex = /^\+[0-9]{2}[1-9]{1}[0-9]{8,9}$/; // eslint-disable-line
  return regex.test(value);
};

/**
 * Function checking if the number is within the range of min max
 * @memberof Utils
 * @function
 * @param {Number} value
 * @param {Number} [min]
 * @param {Number} max
 * @return {Boolean}
 */
export const isNumberBetween = ({ value, min = 1, max }) => {
  const intValue = parseInt(value, 10);
  return max ? min <= intValue && intValue <= max : min <= intValue;
};

/**
 * Function checks whether a string length is in the range of min max
 * @memberof Utils
 * @function
 * @param {Number} value
 * @param {Number} [min]
 * @param {Number} [max]
 * @return {Boolean}
 */
export const isLengthBetween = ({ value, min = 1, max }) => {
  const { length } = String(value);
  return max ? min <= length && length <= max : min <= length;
};

/**
 *  Function that handles specific erorors provided with API
 * @function
 * @param {String} errorKey fieldName provided by API, which we translate to the user
 * @param {string[]} errors information returned from Api what the error is about
 * @param {string[]} errorMessages an array that combines errokey and erros ["fieldName: errors"]
 * @return {Array}
 */
const handleSpecificErrors = (errorKey, errors, errorMessages) => {
  switch (errorKey) {
    case 'horses.event_types':
      return errorMessages.push(t('keys.horseHaveEventType'));
    case 'avatar.file':
      return errorMessages.push(t('keys.avatar'));
    case 'postable.creator':
    case 'creator':
      if (errors[0] === 'blank') {
        return errorMessages.push(t('error.creatorCantBeBlank'));
      }
      return false;
    case 'memberships':
      if (errors[0] === 'exist') {
        return errorMessages.push(t('error.numberAlreadyInUse'));
      }
      return false;
    case 'beginning':
      return errorMessages.push(t('error.beforeStart'));
    case 'ending':
      return errorMessages.push(t('error.afterStart'));
    case 'error':
      return errorMessages.push(t('error.batch_payment_failed'));
    default:
      return false;
  }
};

/**
 * Function that converts errors from API into notifications understood by the user
 * @memberof Utils
 * @function
 * @param  {Error} e
 * @param  {Boolean} [onlyKey=false]             [todo description]
 * @param  {String}  [fieldName='general.field'] [todo description]
 * @return {Node} React element to display in toaster
 */
export const getErrorMessage = (e, onlyKey = false, fieldName = 'general.field') => {
  const errorMessages = [];
  if (e.response?.data && e.response.status === 403) {
    const errorsData = e.response.data;
    Object.keys(errorsData).forEach(errorKey => {
      const errorInfo = errorsData[errorKey];
      (Array.isArray(errorInfo) ? errorInfo : []).forEach(({ errors }) => {
        if (errors[0] === 'phone_number_not_verified') {
          const errorsTranslation = (errors || []).map(error => {
            return t(`error.${error}`);
          });
          errorMessages.push(`${errorsTranslation}`);
        }
      });
    });
  }
  if (e.response && e.response.data && e.response.status === 422) {
    const errorsData = e.response.data;
    Object.keys(errorsData).forEach(errorKey => {
      if (!handleSpecificErrors(errorKey, errorsData[errorKey][0].errors, errorMessages)) {
        const field = errorKey === 'base' ? t(fieldName) : t(`keys.${errorKey}`);
        const errorInfo = errorsData[errorKey];
        (errorInfo || []).forEach(({ id, name, errors }) => {
          const errorsTranslation = (errors || []).map(error => {
            const [key, count] = error.split('|');
            return t(`error.${key}`, { count });
          });
          if (field !== null && errorsTranslation.length > 0) {
            const key = id && name !== 'No Name' && !onlyKey ? name : field;
            errorMessages.push(`${key}: ${lowerFirst(errorsTranslation)}`);
          } else {
            errorMessages.push(t('general.error'));
          }
        });
      }
    });
  }
  if (e.response?.data && e.respons?.status === 401) {
    redirectTo('/');
    return errorMessages.push(t('message.youAreLogout'));
  }

  if (errorMessages.length === 0) {
    return t('general.error');
  }

  return (
    <React.Fragment>
      {errorMessages.map(message => (
        <div key={uniqueId()}>{message}</div>
      ))}
    </React.Fragment>
  );
};
