import FlexColumn from '@old/components/common/FlexColumn';
import RowAligner from '@old/components/common/RowAligner';
import ImageCircle from '@old/components/guide/ImageCircle';
import MemberLink from '@old/components/custom/MemberLink';
import RatingStars from '@old/components/old/RatingStars';
import t from 'resources/translations';
import Box from '@old/components/common/Box';
import Icon from '@old/components/icon';
import Tooltip from '@old/components/common/Tooltip';
import { ContextTrigger } from 'old/components/common/ContextMenu';

const RatingListItem = ({ rating, buttons }) => {
  return (
    <ContextTrigger menuItems={buttons}>
      <Box>
        {rating.hidden && (
          <div className="pointer-events-none bg-white-transparent left-0 top-0 z-10 absolute w-full h-full" />
        )}
        <FlexColumn>
          <RowAligner>
            <ImageCircle src={rating.rater.getAvatar('thumb')} />
            <div>
              <MemberLink forceRender member={rating.rater}>
                <div className="text-title-md mr-6 ">{rating.rater.getName()}</div>
              </MemberLink>
              <RatingStars rating={rating.stars || 0} />
            </div>
            {rating.hidden && (
              <div className="absolute top-4 right-4">
                <Tooltip content={t('message.hideRating')}>
                  <Icon.VisibilityOff className="fill-teal" />
                </Tooltip>
              </div>
            )}
          </RowAligner>
          {rating.comment && (
            <div>
              {t('ratingList.comment')}: <span className="italic">{rating.comment}</span>
            </div>
          )}
        </FlexColumn>
      </Box>
    </ContextTrigger>
  );
};

RatingListItem.defaultProps = {
  buttons: [],
};

export default RatingListItem;
