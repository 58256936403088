/**
 * @module TextareaField
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';
import { changeFieldState } from 'store/actions';
/**
 * @typedef {Object} TextArea
 * @property {String} name field name in the form
 * @property {Number} value
 * @property {String} formName
 * @property {Function} onChange
 */

/**
* @typedef {Object} Validation
* @property {String} errorHint
* @property {Function} condition condition to be fulfilled for validation to be carried out
*/

/**
 *  Component that generate input field that gets a possibility to input value in multiline
 * @param  {Function} props.onChangeField Function called when field value has changed
 * @param  {String} props.label
 * @param  {String} props.placeholder
 * @param  {Validation} [props.validation]
 * @param  {TextArea} props.fieldProps
 */
const TextareaField = ({ onChangeField, label, placeholder, validation, ...fieldProps }) => {
  const { name, formName } = fieldProps;

  const onChange = (value) => {
    onChangeField({ formName, fieldName: name, fieldState: value });
  };
  const validationFuncs = [
    ...validation,
  ];

  return (
    <FormField validation={validationFuncs} {...fieldProps}>
      <Input.Textarea
        onChange={onChange}
        value={fieldProps.value}
        label={label}
        placeholder={placeholder}
      />
    </FormField>
  );
};

TextareaField.defaultProps = {
  value: '',
  validation: [],
};

TextareaField.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  validation: PropTypes.arrayOf(PropTypes.shape({
    condition: PropTypes.func,
    errorHint: PropTypes.string,
  })),
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(TextareaField);
