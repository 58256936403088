import PropTypes from 'prop-types';
import Payments from '@old/components/modals/PaymentsModal';
import ReturnPayment from '@old/components/modals/ReturnPaymentModal';
import CancelPayment from '@old/components/modals/CancelPaymentModal';
import ManagePhone from '@old/components/modals/ManagePhone';
import {
  MODAL_PAYMENT,
  MODAL_RETURN_PAYMENT,
  MODAL_CANCEL_PAYMENT,
  MODAL_MANAGE_PHONE,
} from '@old/constants/modalNames';
import { useModal } from 'old/hooks';

export const withModalController = (WrappedModal, { name }) => {
  const ModalController = () => {
    const { isOpen, ...restModalProps } = useModal(name);

    if (!isOpen) {
      return null;
    }
    return <WrappedModal name={name} {...restModalProps} isOpen />;
  };

  return ModalController;
};

export const wrappedModalPropTypes = {
  isOpen: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitAndClose: PropTypes.func.isRequired,
};

export const PaymentsModal = withModalController(Payments, { name: MODAL_PAYMENT });
export const ReturnPaymentModal = withModalController(ReturnPayment, { name: MODAL_RETURN_PAYMENT });
export const CancelPaymentModal = withModalController(CancelPayment, { name: MODAL_CANCEL_PAYMENT });
export const ManagePhoneModal = withModalController(ManagePhone, { name: MODAL_MANAGE_PHONE });
