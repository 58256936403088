/* eslint-disable max-len */
import React from 'react';

const Check = () => {
  return (
    <svg width="100%" viewBox="0 0 16 16">
      <path d="M6.9,13.7L0,6.8l2.2-2.2l4.7,4.6l6.9-6.9L16,4.5L6.9,13.7z" />
    </svg>
  );
};

export default Check;
/* eslint-enable max-len */
