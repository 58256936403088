/**
 * @module PhoneInput
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import config from '@old/config';
import t from 'resources/translations';
import Input from '.';
import { FORM_CHANGE_PHONE, FORM_REGISTER, FORM_SOCIAL_REGISTER } from 'old/constants';

/**
 * Component that generate two input, one of them is adapted for directional number and can be filled with default directional number.
 * Second one generate input for phone number, input that have possibility to enter only numeric value
 * @param  {String} props.name name of input
 * @param  {String} props.formName name of the form
 * @param  {String} [props.value] value of input field
 * @param  {String} [props.placeholder] text put in placeholder of input field
 * @param  {String} [props.defaultDirectionalValue] default directional number for example: "+48"
 * @param  {Function} props.onChange The onChange detects when the value of an input element changes and run function delegated in props
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 */
const PhoneInput = ({ name, formName, value, placeholder, defaultDirectionalValue, onChange, label, required }) => {
  const isUserPhoneForm = [FORM_REGISTER, FORM_SOCIAL_REGISTER, FORM_CHANGE_PHONE].includes(formName);

  const directionalNumbers = isUserPhoneForm ? [{ id: '1', value: '+48' }] : config.directionalStates;

  const initialDirectional = () => {
    return value ? value.substring(0, 3) : defaultDirectionalValue;
  };

  const initialNumberPhone = () => {
    return value ? value.substring(3) : '';
  };

  const [directional, setDirectional] = useState(initialDirectional);
  const [numberPhone, setNumberPhone] = useState(initialNumberPhone);

  const onChangeDirectional = directionalNumber => {
    setDirectional(directionalNumber);
    if (!isEmpty(numberPhone)) {
      setFullNumber(directionalNumber, numberPhone);
    }
  };

  const onChangePhoneNumber = number => {
    setNumberPhone(number);
    setFullNumber(directional, number);
  };

  const setFullNumber = (newDirectional, newNumberPhone) => {
    const fullNumberPhone = `${newDirectional}${newNumberPhone}`;
    onChange(fullNumberPhone, name);
  };

  return (
    <div className="flex">
      <div className="mr-4" style={{ minWidth: 120 }}>
        <Input.Select
          options={directionalNumbers.map(item => ({
            key: item.value,
            value: item.value,
            label: item.value,
          }))}
          label="Prefix"
          placeholder={t('placeholders.phonePrefix')}
          required={required}
          onChange={selected => onChangeDirectional(selected.value)}
          value={{ label: directional, value: directional }}
          isDisabled={isUserPhoneForm}
        />
      </div>
      <Input.Tel
        name={name}
        value={numberPhone}
        label={label}
        required={required}
        onChange={number => onChangePhoneNumber(number)}
        placeholder={placeholder}
      />
    </div>
  );
};

PhoneInput.defaultProps = {
  value: '',
  placeholder: '',
  defaultDirectionalValue: '',
  label: '',
  required: false,
};

PhoneInput.propTypes = {
  placeholder: PropTypes.string,
  defaultDirectionalValue: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default PhoneInput;
