import React, { useCallback } from 'react';
import { AvatarWithLabel, Card, CardContent, ContextMenu, ContextMenuItem, LinkMember } from 'components/combinations';
import { Box, Button, Flex, Icons, Link, Span, Stack, Ternary, Text, Tooltip } from 'components/elements';
import { parseToCurrencyString } from 'old/utils';
import t from 'resources/translations';
import { useModal } from 'old/hooks';
import { IBillInstructor, IBillRes } from 'resources/types/billsTypes';
import Bill from 'models/Bill';
import _ from 'lodash';
import { useHistory } from 'react-router';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { useQueryClient } from 'react-query';
import { TicketService } from 'services';
import { useMutation } from 'react-query';
import { usePermissions } from 'Permissions';
import { mBillInBills, mFetchBillInBills, doubleTernary, useDisclosure, useLoggedMember } from 'utils';

type TBillsListItemProps = {
  bill: Bill;
};

const BillsListItemMobile = ({ bill }: TBillsListItemProps) => {
  const loggedMember = useLoggedMember();
  const expadedProps = useDisclosure();
  const history = useHistory();
  const queryClient = useQueryClient();
  const isTicket = bill.billableType === 'Ticket';

  const modalHistoryPayments = useModal('BILL_HISTORY_PAYMENTS');
  const modalConfirm = useModal('CONFIRM');
  const modalPayment = useModal(isTicket ? 'PAYMENT_TICKET' : 'PAYMENT_EVENT');
  const modalReturnPayment = useModal('PAYMENT_RETURN');

  const canISetPayment = usePermissions('BILL_SET_PAYMENT')(bill);
  const camIReturnPayment = usePermissions('BILL_RETURN_PAYMENT')(bill);
  const canIRemoveTicket = usePermissions('TICKET_REMOVE')(bill.status === 'unpaid') && isTicket;

  const refreshData = () => queryClient.invalidateQueries('BILLS', { refetchActive: true });
  const refreshSummaryBillsData = () => queryClient.invalidateQueries('SUMMARY_BILLS', { refetchActive: true });
  const actionRemoveTicket = useMutation(async () => TicketService.delete(bill.billableId), {
    onSuccess: () => {
      refreshData();
      refreshSummaryBillsData();
    },
  });

  const onOpenPaymenModal = () => {
    const onSubmitCb = (billRes: IBillRes) => {
      mBillInBills(queryClient, ['BILLS'], billRes);
      refreshSummaryBillsData();
    };
    const onChangeDueCb = (billRes: IBillRes) => {
      mBillInBills(queryClient, ['BILLS'], billRes);
      refreshSummaryBillsData();
    };
    return modalPayment.onOpen({ bill, onSubmitCb, onChangeDueCb });
  };

  const onOpenReturnPaymenModal = () => {
    const onSubmitCb = () => {
      mFetchBillInBills(queryClient, 'BILLS', bill.id);
      refreshSummaryBillsData();
    };
    modalReturnPayment.onOpen({ bill, onSubmitCb });
  };

  const onOpenDeleteModal = () => {
    modalConfirm.onOpen({
      title: t('tickets.deleteTicket'),
      content: (
        <ModalDescription spacing="xl">
          <Stack spacing="sm">
            <Text>{t('tickets.deleteUnpaidTicket')}:</Text>
            <Text>
              <Span fontWeight="medium">{bill.getName()}</Span>?
            </Text>
          </Stack>
          <Text fontWeight="medium">{t('tickets.rememberTicketDeleted')}</Text>
        </ModalDescription>
      ),
      action: {
        mutation: actionRemoveTicket,
        label: t('buttons.yesIWantDeleteTicket'),
        cancelLabel: t('buttons.noGetMyOutOfHere'),
        loadingLabel: t('message.removed'),
      },
    });
  };
  const onOpenHistoryPaymentsModal = () => {
    return modalHistoryPayments.onOpen({ bill });
  };

  const getOtherInstructors = useCallback(
    (instructors: IBillInstructor[]) => instructors.filter(instructor => instructor.id !== loggedMember.id),
    [loggedMember.id]
  );

  const isPaid = bill.getDue() === bill.getPaidAmount();
  const getPaymentStatus = useCallback(() => {
    const isUnpaid = bill.getDue() > bill.getPaidAmount();
    const paymentStatus: string = doubleTernary(isPaid, isUnpaid, [
      t('bills.statementList.paid'),
      t('bills.statementList.unpaid'),
      t('bills.statementList.overpaid'),
    ]);
    return paymentStatus;
  }, [bill, isPaid]);

  const url = isTicket ? `/tickets/${bill.ticket.id}` : `/events/${bill.event.id}`;
  const instructors = loggedMember.isInstructor() ? getOtherInstructors(bill.instructors) : bill.instructors;

  return (
    <>
      <Card my="xs">
        <CardContent>
          <Flex mb="sm">
            {!!bill.billed ? (
              <AvatarWithLabel
                src={bill.billed.getAvatar('thumb')}
                title={bill.billed.getFullName()}
                alt={bill.billed.getFullName()}
                squareSize={26}
              >
                <LinkMember member={bill.billed}>{bill.billed.getFullName()}</LinkMember>
              </AvatarWithLabel>
            ) : (
              <Flex>
                <Text>{t('general.userDeleted')}</Text>
              </Flex>
            )}
            <ContextMenu>
              {canISetPayment && (
                <ContextMenuItem onClick={() => onOpenPaymenModal()}>
                  <Text>{t('buttons.settle')}</Text>
                </ContextMenuItem>
              )}
              {camIReturnPayment && (
                <ContextMenuItem onClick={() => onOpenReturnPaymenModal()}>
                  <Text>{t('bills.return')}</Text>
                </ContextMenuItem>
              )}
              {bill.payments.length > 0 && (
                <ContextMenuItem onClick={() => onOpenHistoryPaymentsModal()}>
                  <Text>{t('bills.paymentsHistory')}</Text>
                </ContextMenuItem>
              )}
              {!loggedMember.isClient() && bill.billableType === 'Participation' && bill.event.id && (
                <ContextMenuItem onClick={() => history.push(`/bills/${bill.event.id}`)}>
                  <Text>{t('bills.viewBillingDetails')}</Text>
                </ContextMenuItem>
              )}
              {canIRemoveTicket && (
                <ContextMenuItem onClick={() => onOpenDeleteModal()}>
                  <Text>{t('tickets.deleteTicket')}</Text>
                </ContextMenuItem>
              )}
            </ContextMenu>
          </Flex>
          <Box
            bgColor="rgba(245, 245, 245, 0.3)"
            p="md"
            mx={-22}
            borderY={2}
            borderStyle="solid"
            borderColor="rgba(209, 209, 208, 0.3)"
          >
            <Stack isHorizontal alignItems="center">
              {isTicket ? (
                <Tooltip content={t('labels.ticket')} placement="top-start">
                  <Icons.TicketCircle bgColor="body-color" fill="teal-dark" />
                </Tooltip>
              ) : (
                <Tooltip content={t('labels.event')} placement="top-start">
                  <Icons.HorseRiderCircle bgColor="body-color" fill="teal-dark" />
                </Tooltip>
              )}
              <Ternary cond={!!bill.ticket.name || !!bill.event.name}>
                <Link to={url} wordBreak="break-all" py="sm">
                  {isTicket ? bill.ticket.name : bill.event.name}
                </Link>
                <Text>{t('model.delete.events')}</Text>
              </Ternary>
            </Stack>
          </Box>
          <Stack spacing="sm">
            <Text>
              {`${t('bills.due')}: `}
              {parseToCurrencyString(bill.dueAmount)}
            </Text>
            <Flex justifyContent="space-between">
              <Text>
                {`${t('bills.deposit')}: `}
                {parseToCurrencyString(bill.paidAmount)}
              </Text>
              <Text color={isPaid ? 'grey-dark' : 'orange'}>{getPaymentStatus().toLowerCase()}</Text>
            </Flex>
          </Stack>
          <Stack>
            {!_.isEmpty(instructors) && (
              <Stack spacing="xs">
                <Text fontWeight="medium">{`${
                  loggedMember.isInstructor() ? t('bills.otherEmployees') : t('general.instructors')
                }: `}</Text>
                {instructors.map((instructor, i) => (
                  <Text>
                    {i === 0 && (
                      <Flex justifyContent="space-between">
                        <Link to={`/instructors/${instructor.id}`} forceRender>
                          {instructor.name}
                        </Link>
                        {instructors.length > 1 && !expadedProps.isOpen && (
                          <Button onClick={() => expadedProps.onOpen()} fontSize="base" variant="link">
                            {t('buttons.showMore')}
                          </Button>
                        )}
                      </Flex>
                    )}
                    {i > 0 && expadedProps.isOpen && (
                      <Link to={`/instructors/${instructor.id}`} forceRender>
                        {instructor.name}
                      </Link>
                    )}
                  </Text>
                ))}
                {expadedProps.isOpen && (
                  <Flex w="100%" justifyContent="flex-end">
                    <Button onClick={() => expadedProps.onClose()} fontSize="base" variant="link">
                      {t('buttons.showLess')}
                    </Button>
                  </Flex>
                )}
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default React.memo(BillsListItemMobile);
