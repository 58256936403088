import moment from 'moment';

import BaseModel from '@old/model/BaseModel';
import { Member } from '@old/model/Member';

class Conversations extends BaseModel {
  constructor(options) {
    super({
      modelName: 'conversations',
      updateKey: 'conversation',
      basePath: '/api/v1/users/conversations',
      ItemClass: Conversation,
      ...options,
    });
  }

  openConversation = async (params) => {
    const response = await this.client.post(this.basePath, params);

    return this.parse(response.data);
  };
}

class Conversation {
  constructor(data) {
    this.id = data.id;
    this.createdAt = moment(data.created_at);
    this.recipient = data.recipient ? new Member(data.recipient, { fromUserData: true }) : new Member();
    this.messages = data.messages ? data.messages.map(message => ({
      id: message.id,
      createdAt: moment(message.created_at),
      user: message.user ? new Member(message.user, { fromUserData: true }) : new Member(),
      body: message.body,
    })) : [];
  }

  update = async changes => this.model.update(this.id, changes);
}

export { Conversation, Conversations };
