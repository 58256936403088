import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Pagination from 'react-js-pagination';

import Center from '@old/components/common/Center';
import { changePage } from 'store/actions';
import Icon from '@old/components/icon';

const PagePagination = ({ history, paginationKey, pagination, isMobile, ...props }) => {
  const onChange = debounce(
    activePage => {
      if (pagination.current !== activePage) {
        props.changePage(activePage, paginationKey);
        history.push(history.location.pathname, { [paginationKey]: { activePage } });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    250,
    { leading: true, trailing: false }
  );

  const { current, pages, count, per_page: perPage } = pagination;
  if (!count || !pages || pages < 2) return null;

  return (
    <Center>
      <Pagination
        activePage={current}
        itemsCountPerPage={perPage}
        totalItemsCount={count}
        onChange={onChange}
        pageRangeDisplayed={isMobile ? 3 : 5}
        innerClass="pagination"
        itemClass="item-wrapper"
        linkClass="item"
        prevPageText={<Icon.ArrowLeft className="fill-teal" />}
        nextPageText={<Icon.ArrowRight className="fill-teal" />}
        lastPageText={<Icon.ArrowPaginationDoubleRight className="fill-teal" />}
        firstPageText={<Icon.ArrowPaginationDoubleLeft className="fill-teal" />}
        activeLinkClass="active"
        hideDisabled
        hideFirstLastPages={pages < 3}
      />
    </Center>
  );
};

PagePagination.defaultProps = {
  isMobile: false,
};

PagePagination.propTypes = {
  pagination: PropTypes.shape({
    count: PropTypes.number,
    current: PropTypes.number,
    next: PropTypes.number,
    pages: PropTypes.number,
    per_page: PropTypes.number,
    previous: PropTypes.number,
  }).isRequired,
  isMobile: PropTypes.bool,
  changePage: PropTypes.func.isRequired,
  paginationKey: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = ({ pagination, app }, { paginationKey }) => ({
  pagination: pagination[paginationKey] || {},
  isMobile: app.isMobile,
});

export default withRouter(
  connect(mapStateToProps, {
    changePage,
  })(PagePagination)
);
