/**
 * @module Modal
 */
import React, { useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Measure from 'react-measure';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { RemoveScroll } from 'react-remove-scroll';
import { isMobile as isMobileDevices } from 'react-device-detect';
import { motion } from 'framer-motion';

import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import FlexColumn from '@old/components/common/FlexColumn';
import config from '@old/config';
import { useListener } from '@old/hooks';
import { connect } from 'react-redux';
import { Text } from 'components/elements';
/**
 * array of currently opened modals from redux
 * @typedef   {Object[]} ModalsList
 * @property  {String} name name of the modal
 */
/**
 * Component that generates modals
 * @param  {String|Node} [props.header] content for the modal header
 * @param  {String} props.name name of the modal
 * @param  {Boolean} [props.isOpen] specifies if modal is opened
 * @param  {Node} [props.children]
 * @param  {Function} props.close function that closes the modal
 * @param  {Boolean} [props.basic] change style for body no-padding no-margin auto-width
 * @param  {Boolean} [props.large] specifies if modal should be bigger than standard one
 * @param  {ModalsList} props.modalsList
 * @param  {Boolean} props.isMobile
 */

const Modal = ({ isOpen, onClose, header, children, basic, large, isMobile }) => {
  const innerRef = useRef();
  const handler = useCallback((e) => {
    if (isOpen && e.keyCode === config.keyCodes.esc) {
      onClose();
    }
  }, [isOpen, onClose]);

  useListener('keydown', handler, isMobileDevices ? document : window);

  const renderModalHeader = () => (
    <div className="modal-header">
      <RowAligner>
        <Text
          className="text-title-white  min-w-0 max-w-full truncate">
          {header}
        </Text>
        <div className="modal-close">
          <ButtonSimple onClick={onClose}>
            <Icon.Cancel circle />
          </ButtonSimple>
        </div>
      </RowAligner>
    </div>
  );

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <RemoveScroll enabled={isOpen}>
      <Measure bounds>
        {({ measureRef: modalRef, _, contentRect }) => {
          const notCentered = contentRect.bounds.height >= window.innerHeight;

          return (
            <motion.div
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className={cls('modal-dimmer opacity-0', { open: isOpen, basic, centered: !notCentered })}
            >
              <div className={cls('fixed left-0 top-0 h-full w-full', { hidden: !isOpen })} onMouseUp={onClose} />
              <motion.div
                ref={modalRef}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.1 }}
                variants={{
                  visible: { y: 0 },
                  hidden: { y: 300 },
                }}
                className={cls('modal', { large })}
              >
                <div ref={innerRef}>
                  {!!header && renderModalHeader()}
                  <FlexColumn>
                    <div className="modal-body">
                      <FlexColumn separated={isMobile ? 'small' : true}>
                        {children}
                      </FlexColumn>
                    </div>
                  </FlexColumn>
                </div>
              </motion.div>
            </motion.div>
          );
        }}
      </Measure>
    </RemoveScroll>,
    document.getElementById('root'),
  );
};

Modal.defaultProps = {
  header: null,
  large: false,
  children: null,
  basic: false,
  isOpen: false,
};

Modal.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  isMobile: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  large: PropTypes.bool,
  basic: PropTypes.bool, // change style for body no-padding no-margin auto-width
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(Modal);
