/* eslint-disable max-len */
import React from 'react';

const VisibilityOff = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M7.9,5.5l2.3,2.3l0-0.1c0-1.2-1-2.2-2.2-2.2l0,0L7.9,5.5z M4.8,6l1.1,1.1c0,0.1-0.1,0.3-0.1,0.5c0,0,0,0,0,0v0
        c0,1.2,1,2.2,2.2,2.2l0,0c0,0,0,0,0,0c0.2,0,0.3,0,0.5-0.1l0,0l1.1,1.1c-0.5,0.2-1,0.4-1.6,0.4c-2,0-3.6-1.6-3.6-3.6
        C4.4,7.1,4.5,6.5,4.8,6L4.8,6L4.8,6z M0.7,2l2,2C1.5,4.9,0.6,6.2,0,7.6l0,0.1c1.3,3.2,4.4,5.5,8,5.5c1.1,0,2.2-0.2,3.2-0.6l-0.1,0
        l2.4,2.4l0.9-0.9L1.7,1.1L0.7,2z M8,4C8,4,8,4,8,4c2,0,3.6,1.6,3.6,3.6l0,0l0,0c0,0.5-0.1,0.9-0.3,1.3l0,0l2.1,2.1
        c1.1-0.9,1.9-2.1,2.5-3.4l0-0.1c-1.3-3.2-4.4-5.5-8-5.5h0c-1,0-2,0.2-3,0.5l0.1,0l1.6,1.6C7.1,4.1,7.5,4,8,4C8,4,8,4,8,4L8,4L8,4z"
      />
    </svg>
  );
};

export default VisibilityOff;
/* eslint-enable max-len */
