import styled from 'styled-components';
import { flexbox, variant } from 'styled-system';
import { baseStyledProps, blockStyleProps, typographyStyleProps } from 'config/styledProps';
import theme from 'config/theme';
import { IButtonStyledProps } from 'resources/types/styledTypes';

const Button = styled('button')<IButtonStyledProps>(
  baseStyledProps,
  blockStyleProps,
  typographyStyleProps,
  flexbox,
  {
    appearance: 'none',
    fontFamily: 'inherit',
  },
  variant({
    variants: {
      icon: {
        color: 'teal-light',
        lineHeight: 0,
        '&:focus, &:hover': {
          borderColor: 'teal-dark',
          color: 'teal-dark',
        },
        span: {
          borderColor: 'teal',
          color: 'teal',
        },
        'span:hover': {
          borderColor: 'teal-dark',
          color: 'teal-dark',
        },
        '&:disabled': {
          cursor: 'default',
          color: 'grey',
          '&:hover': {
            color: 'grey',
          },
          'span:hover': {
            borderColor: 'teal-dark',
            color: 'teal-dark',
          },
        },
      },
      clean: {
        '&:focus, &:hover': {
          borderColor: 'teal-dark',
          color: 'teal-dark',
        },
        '&:disabled': {
          cursor: 'default',
        },
      },
      primary: {
        color: 'white',
        borderRadius: '8px',
        backgroundColor: 'teal-light',
        minHeight: '47px',
        '&:disabled': {
          backgroundColor: 'grey',
          cursor: 'default',
          '&:hover': {
            backgroundColor: 'grey',
          },
        },
        '&:not(:disabled):focus, &:not(:disabled):hover': {
          boxShadow: `0 1px 4px ${theme.colors.shadow}`,
          backgroundColor: 'teal-dark',
        },
      },
      outline: {
        color: 'teal',
        borderWidth: 1,
        borderColor: 'teal',
        backgroundColor: 'transparent',
        borderRadius: '8px',
        minHeight: '47px',
        '&:focus, &:hover': {
          boxShadow: `0 1px 4px ${theme.colors.shadow}`,
          borderColor: 'teal-dark',
          color: 'teal-dark',
        },
      },
      link: {
        '&:focus': {
          color: 'teal-dark',
        },
        '&:disabled': {
          color: 'grey',
          cursor: 'default',
          '&:hover': {
            color: 'grey',
          },
          '&:focus': {
            color: 'grey',
          },
        },
        color: 'teal-light',
        '&:hover': {
          color: 'teal-dark',
        },
      },
    },
  })
);

Button.defaultProps = {
  variant: 'primary',
  lineHeight: 'normal',
  fontSize: 'md',
  width: 'initial',
  type: 'button',
  cursor: 'pointer',
};

export default Button;
