/* eslint-disable max-len */
import React from 'react';

const Sms = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 24.213"
    >
      <path d="M9 1.52a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16.82l4 4.16a.13.13 0 0 0 .13.05.17.17 0 0 0 .17-.17v-4H31a2 2 0 0 0 2-2v-16a2 2 0 0 0-2-2H9zm10.68 15.19h-7.85a.75.75 0 0 1-.58-.85.74.74 0 0 1 .58-.66h7.85a.77.77 0 0 1 .58.85.73.73 0 0 1-.58.66zm8.84-4.45h-17a.75.75 0 0 1-.58-.85.73.73 0 0 1 .58-.66h17a.78.78 0 0 1 0 1.51zm0-4.45h-17A.75.75 0 0 1 11 7a.72.72 0 0 1 .58-.66h17a.78.78 0 0 1 0 1.51z" transform="translate(-7 -1.52)" />
    </svg>
  );
};

export default Sms;
/* eslint-enable max-len */
