/* eslint-disable max-len */
import React from 'react';

const List = () => {
  return (
    <svg width="100%" viewBox="0 0 26 26">
      <g transform="translate(0 -32.5)">
        <rect id="Rectangle_3844" x="7.8" y="51.4" width="18.2" height="3.3" />
        <rect id="Rectangle_3845" x="7.8" y="43.9" width="18.2" height="3.3" />
        <rect id="Rectangle_3846" x="7.8" y="36.2" width="18.2" height="3.3" />
        <rect id="Rectangle_3847" y="51.5" width="3.3" height="3.3" />
        <rect id="Rectangle_3848" y="43.9" width="3.3" height="3.3" />
        <rect id="Rectangle_3849" y="36.2" width="3.3" height="3.3" />
      </g>
    </svg>
  );
};

export default List;
/* eslint-enable max-len */
