import t from 'resources/translations';
import GroupMembersGrid from '@old/components/view/grid/GroupMember';
import Card from '@old/components/common/Card';
import { useGroupEditButton, useGroupDeleteButton } from 'old/buttonHooks';
import { Box, Flex, Link, Span, Stack, Text, Tooltip } from 'components/elements';
import { ContextMenu, ContextMenuItem } from 'components/combinations';
import { truncate } from 'lodash';

const styles = {
  groupName: {
    fontWeight: 'medium',
    whiteSpace: 'nowrap',
    minW: 0,
    maxW: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  eventNameBox: {
    alignItems: 'center',
  },
};
const GroupsGridItem = ({ group }) => {
  const editGroup = useGroupEditButton(group);
  const deleteGroup = useGroupDeleteButton(group);

  return (
    <Card fullHeight>
      <Stack>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flex={1} minW={0}>
            <Tooltip content={group.name}>
              <Text {...styles.groupName} color="teal-light">
                <Link to={group.getGroupUrl()}>{group.name} </Link>
              </Text>
            </Tooltip>
          </Flex>
          <ContextMenu ml="sm">
            {editGroup && (
              <ContextMenuItem onClick={() => editGroup.onClick()}>
                <Text>{editGroup.label}</Text>
              </ContextMenuItem>
            )}
            {deleteGroup && (
              <ContextMenuItem onClick={() => deleteGroup.onClick()}>
                <Text>{deleteGroup.label}</Text>
              </ContextMenuItem>
            )}
          </ContextMenu>
        </Flex>
        <Text fontSize="sm" fontStyle={!group.description ? 'italic' : 'normal'} h="4rem">
          {truncate(group.getDescription(), { length: 100, omission: '...' })}
        </Text>
        <Stack>
          <Text fontSize="sm" fontWeight="medium" color="teal-light">
            {`${t('groupsGridItem.numberOfPeople')}: `}
            <Span color="grey-dark" fontWeight="normal">
              {group.getSize()}
            </Span>
          </Text>
          <Box>
            <GroupMembersGrid members={group.members} />
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

export default GroupsGridItem;
