import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const ImageCircleSelection = ({ src, small, large, huge, centered, selected }) => (
  <div className={cls('image-circle-selection rounded-full', { selected })}>
    <div
      className={cls('image-circle', { small, large, huge })}
      style={{
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        margin: centered ? '0 auto' : 0,
        borderRadius: 9999,
      }}
    />
  </div>
);

ImageCircleSelection.defaultProps = {
  large: false,
  small: false,
  huge: false,
  centered: false,
  selected: false,
};

ImageCircleSelection.propTypes = {
  src: PropTypes.string.isRequired,
  huge: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  centered: PropTypes.bool,
  selected: PropTypes.bool,
};

export default ImageCircleSelection;
