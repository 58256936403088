/* eslint-disable max-len */
import React from 'react';

const SelectFarm = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M7.9,0C3.9,0,0.5,3.8,0.5,8.6c0,1.4,0.3,2.8,0.9,4.1L2.1,14c0,0.1,0.9,1.5,0.9,1.5c0.2,0.4,0.8,0.6,1.2,0.3l1.9-1.2
        c0.4-0.3,0.4-0.8,0.2-1.2l-0.8-1.3c-0.2-0.2-0.4-0.4-0.7-0.4c-0.2,0-0.3,0.1-0.5,0.1L4,11.4c-0.4-0.9-0.6-1.9-0.6-2.9
        c0-3.1,2-5.6,4.5-5.6s4.5,2.5,4.5,5.6c0,1-0.2,2-0.7,2.9l-0.2,0.5c-0.4-0.2-1-0.1-1.2,0.3l-0.7,1.2c-0.2,0.4-0.1,1,0.3,1.2l1.9,1.2
        c0.4,0.2,1,0.1,1.2-0.3c0,0,0.9-1.5,0.9-1.6l0.6-1.3c0.6-1.3,0.9-2.7,0.9-4.1C15.4,3.8,12.1,0,7.9,0L7.9,0z M1.6,8.9V7.6l0,0
        c0-0.2,0.2-0.4,0.4-0.4h0.1c0.2,0,0.4,0.2,0.4,0.4L2.4,9c0,0.2-0.2,0.4-0.4,0.4C1.8,9.4,1.6,9.2,1.6,8.9z M3.1,12.6
        c-0.2,0.1-0.4,0-0.6-0.2l-0.6-1.2v-0.1c-0.1-0.2,0-0.4,0.2-0.6c0.2-0.1,0.5,0,0.6,0.2L3.3,12l0,0c0,0.1,0,0.2,0,0.2
        C3.3,12.4,3.2,12.5,3.1,12.6z M3.5,4.5L2.9,5.7l0,0C2.7,5.8,2.5,5.9,2.3,5.8l0,0l0,0C2.1,5.7,2.1,5.4,2.1,5.2l0.6-1.2
        c0.1-0.2,0.4-0.2,0.6-0.1C3.6,4,3.6,4.2,3.5,4.5z M5.7,2.4l-1,0.7l0,0C4.5,3.3,4.2,3.2,4,3l0,0C3.9,2.8,4,2.5,4.2,2.4l1-0.7l0,0
        c0.2-0.1,0.4-0.1,0.6,0.1C6,2,5.9,2.3,5.7,2.4z M8.6,1.9H7.3c-0.2,0-0.4-0.2-0.4-0.4S7.1,1,7.4,1h1.3C8.9,1.1,9,1.2,9,1.4
        C9,1.6,8.9,1.8,8.6,1.9L8.6,1.9z M11.8,2.9C11.8,2.9,11.8,2.9,11.8,2.9c-0.1,0.2-0.4,0.3-0.6,0.2l-1.1-0.7V2.3C10,2.2,9.9,2,10,1.8
        s0.4-0.2,0.6-0.1l1.1,0.7C11.9,2.4,12,2.7,11.8,2.9z M12.4,4.5c-0.1-0.2,0-0.4,0.2-0.6c0,0,0,0,0.1,0c0.2-0.1,0.4-0.1,0.6,0.2
        l0.7,1.1c0.1,0.2,0.1,0.4-0.2,0.6c-0.2,0.1-0.4,0.1-0.6-0.2L12.4,4.5L12.4,4.5z M14.1,11.2l-0.5,1.1c-0.1,0.2-0.3,0.3-0.6,0.2l0,0
        c-0.2-0.1-0.3-0.3-0.2-0.5l0.5-1.2l0,0c0.1-0.2,0.3-0.3,0.6-0.2c0.1,0,0.1,0,0.1,0.1C14.1,10.8,14.2,11,14.1,11.2z M14,9.4L14,9.4
        c-0.2,0-0.4-0.2-0.4-0.4l-0.1-1.3V7.6c0-0.2,0.2-0.4,0.4-0.4h0.1c0.2,0,0.4,0.2,0.4,0.4l0.1,1.3v0.1C14.4,9.2,14.2,9.4,14,9.4L14,9.4z"
      />
    </svg>
  );
};

export default SelectFarm;
/* eslint-enable max-len */
