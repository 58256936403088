import { PageTitle } from 'components/combinations';
import { Box, Stack } from 'components/elements';
import t from 'resources/translations';
import { TicketOfferService } from 'services';
import { useNewLayoutColor, mapOffersFormToParams } from 'utils';
import { getErrorMessage, notify } from 'old/utils';
import { TTicketOffersFormState } from 'resources/types/TicketOfferTypes';
import { useHistory } from 'react-router';
import TicketOffersForm from 'views/Tickets/TicketOffersForm';
import { useQuery } from 'react-query';
import Spinner from 'old/components/common/Spinner';
import Error404 from 'old/components/error/Error404';
import TicketOffer from 'models/TicketOffer';
import EventType from 'models/EventType';

type TTicketOffersEdit = {
  match: {
    params: {
      id: string;
    };
  };
};

const TicketOffersEdit = ({ match }: TTicketOffersEdit) => {
  useNewLayoutColor();
  const history = useHistory();
  const ticketState = useQuery('TICKET_OFFERS_EDIT', async () => {
    return await TicketOfferService.fetch(parseInt(match.params.id));
  });

  const onSubmit = async (values: TTicketOffersFormState) => {
    try {
      const createParams = mapOffersFormToParams(values);
      await TicketOfferService.update(match.params.id, createParams);
      notify(t('message.succesSave'));
      history.push('/tickets');
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  if (ticketState.isLoading || ticketState.isFetching) return <Spinner />;
  if (ticketState.isError) return <Error404 />;
  if (!ticketState.data) return history.push('tickets');
  const ticketOffer = new TicketOffer(ticketState.data);

  const initialValues = {
    name: ticketOffer.getName(),
    description: ticketOffer.description,
    price: ticketOffer.price,
    count: ticketOffer.count,
    validity: ticketOffer.validity,
    visibility: ticketOffer.visibility ? 'visible' : 'hidden',
    activable: ticketOffer.activable ? 'true' : 'false',
    eventTypes: ticketOffer.eventTypes.map(EventType.mapToOption),
  } as TTicketOffersFormState;

  const submitLabels = {
    loading: `${t('message.saving')}...`,
    default: t('general.saveChanges'),
  };

  return (
    <Stack p={['md', 'lg']} borderRadius={4} backgroundColor="white">
      <PageTitle title={t('ticketOffers.editTicketOffer')} back="/tickets" px={0} />
      <Box pt="md">
        <TicketOffersForm initialValues={initialValues} onSubmit={onSubmit} submitLabels={submitLabels} />
      </Box>
    </Stack>
  );
};

export default TicketOffersEdit;
