import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import axios from 'axios';
import { connect } from 'react-redux';

import Spinner from '@old/components/common/Spinner';
import Allow from '@old/components/common/Allow';
import WeekSwitch from '@old/components/old/WeekSwitch';
import PageTitle from '@old/components/guide/PageTitle';
import t from 'resources/translations';
import InstructorStatisticsBarChart from '@old/components/view/charts/InstructorStatisticsBarChart';
import InstructorStatisticsPieChart from '@old/components/view/charts/InstructorStatisticsPieChart';
import HeatMap from '@old/components/view/charts/HeatMap';
import EventsList from '@old/components/view/list/Event';
import SelectDateRangeModal from '@old/components/modals/SelectDateRange';
import EventsSummaryModal from '@old/components/modals/EventsSummary';
import Box from '@old/components/common/Box';
import FlexRow from '@old/components/common/FlexRow';
import FlexColumn from '@old/components/common/FlexColumn';
import PieChartLegend from '@old/components/view/charts/PieChartLegend';
import RowAligner from '@old/components/common/RowAligner';
import { usePieChartData, useModelItems, useBarChartData, useHeatMap, useEventsWithChart } from '@old/hooks';
import Error404 from '@old/components/error/Error404';
import { fetchUser } from 'store/actions';

const moment = extendMoment(Moment);

const InstructorStatistics = ({ match, loading, error, member, ...actions }) => {
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchUser(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  const [pieChartDateRange, pieChartSetDateRange] = useState({
    start: moment().subtract(30, 'days').startOf('day'),
    end: moment().endOf('day'),
  });

  const [pieChartData, , duration] = usePieChartData(match.params.id, 'members', pieChartDateRange);
  const [pieChartEvents, pieChartEventsStatus] = useModelItems('events', {
    per_page: 9999,
    'in_interval[start]': pieChartDateRange.start.toDate(),
    'in_interval[end]': pieChartDateRange.end.toDate(),
    'instructed_by[]': member ? member.id : 0,
  });

  const [barChartDateRange, setBarChartDateRange] = useState({
    start: moment().startOf('week'),
    end: moment().endOf('week'),
  });
  const [barChartData, loadingBarChart] = useBarChartData(match.params.id, 'members', barChartDateRange);
  const eventsGroupedByDay = useEventsWithChart(barChartDateRange, {
    'instructed_by[]': member ? member.id : 0,
  });

  const heatMapProps = useHeatMap(match.params.id, 'members', pieChartDateRange);

  const [selectedDay, setSelectedDay] = useState('');

  if (error) return <Error404 />;

  if (loading && !member) {
    return <Spinner />;
  }

  if (!member) return null;

  const onBarClick = barProps => {
    const activeLabel = barProps ? barProps.activeLabel : '';
    const dayData = barChartData.find(item => item.name === activeLabel);
    setSelectedDay(dayData ? dayData.label : '');
  };

  const onWeekChange = value => {
    setBarChartDateRange(value);
    setSelectedDay('');
  };

  return (
    <React.Fragment>
      <PageTitle title={member.getName()} back loading={loading} />
      <SelectDateRangeModal dateRange={pieChartDateRange} onSave={pieChartSetDateRange} />
      <FlexRow cols="10/6" stretched marginBottom>
        <Box>
          <div className="mb-4">{t('instructorStatistics.frequentHours')}</div>
          <HeatMap {...heatMapProps} />
        </Box>
        <FlexColumn>
          <Box centerX centerY fullHeight>
            <InstructorStatisticsPieChart data={pieChartData} />
            <PieChartLegend chartData={pieChartData} duration={duration} />
          </Box>
          <Allow permissionKey="membership_statistics.index" permissionParams={member}>
            <Box centerX centerY fullHeight>
              <div className="text-lg text-center">
                <div className="mb-4">{t('instructorStatistics.viewSummary')}</div>
                <EventsSummaryModal
                  events={pieChartEvents}
                  dateRange={pieChartDateRange}
                  instructor={member}
                  loading={pieChartEventsStatus.isPending}
                />
              </div>
            </Box>
          </Allow>
        </FlexColumn>
      </FlexRow>
      <Box fullHeight>
        <RowAligner className="justify-around text-center">
          {t('instructorStatistics.hoursStatLabel')}
          <WeekSwitch dateRange={barChartDateRange} setDateRange={onWeekChange} />
        </RowAligner>
        {loadingBarChart && (
          <div style={{ minHeight: 350 }}>
            <Spinner />
          </div>
        )}
        {!loadingBarChart && (
          <InstructorStatisticsBarChart activeDay={selectedDay} data={barChartData} onBarClick={onBarClick} />
        )}
      </Box>
      {selectedDay && (
        <div className="text-heading mt-4">{moment(selectedDay, 'YYYY-MM-DD').format('dddd, DD.MM.YYYY')}</div>
      )}
      <EventsList
        hideEmptyMessage
        fetchedEvents={(eventsGroupedByDay[selectedDay] || [])
          .sort((a, b) => a.startDate.diff(b.startDate))
          .filter(event => event.isFinished || event.isCancelled)}
      />
    </React.Fragment>
  );
};

InstructorStatistics.defaultProps = {
  member: null,
};

const mapStateToProps = ({ fetchingData: { users, loading, error } }, { match }) => {
  return {
    member: users.find(user => String(user.id) === String(match.params.id)),
    loading: loading.users,
    error: error.users,
  };
};

export default connect(mapStateToProps, { fetchUser })(InstructorStatistics);
