import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';

import PageTitle from '@old/components/guide/PageTitle';
import NotificationsList from '@old/components/view/list/Notification';
import t from 'resources/translations';
import config from '@old/config';
import Error404 from '@old/components/error/Error404';
import Spinner from '@old/components/common/Spinner';
import {
  setPagination,
  fetchNotifications,
} from 'store/actions';
import { getPaginationId } from 'old/utils';
import PagePagination from '@old/components/old/PagePagination';

const Notifications = ({
  loading,
  error,
  notifications,
  notificationsPagination,
  pagination,
  history,
  ...actions
}) => {
  const { minItemsPerPage } = config;

  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return history.location.state
        && history.location.state[getPaginationKey()]
        && history.location.state[getPaginationKey()].activePage;
    }

    return pagination.current;
  };

  useEffect(() => { // eslint-disable-line
    if (isEmpty(pagination) || pagination.current !== undefined) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const fetch = async () => {
        await actions.fetchNotifications(
          {
            page: getActivePage() || 1,
            per_page: minItemsPerPage,
          },
          source.token,
        );
      };
      fetch();
      return (() => source.cancel());
    }
  }, [history.location.state, pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (notificationsPagination) {
      const pag = { ...notificationsPagination };
      // When you delete last element on page, go back to previous one
      const lastPage = Math.ceil(pag.count / pag.per_page);
      if (pag.per_page * pag.current > pag.count) {
        if (lastPage && pag.current > lastPage) {
          pag.current = lastPage;
          history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
        }
        if (pag.current === 0) {
          pag.current = 1;
        }
      }
      actions.setPagination(pag, getPaginationKey());
    }
  }, [notificationsPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Error404 />;
  if (loading && !notifications.length) {
    return <Spinner noDelay />;
  }

  return (
    <React.Fragment>
      <PageTitle title={t('notifications.notifications')} loading={loading} />
      <NotificationsList data={notifications} />
      <PagePagination paginationKey={getPaginationKey()} />
    </React.Fragment>
  );
};

Notifications.defaultProps = {
  notifications: [],
  notificationsPagination: {},
};

Notifications.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  notificationsPagination: PropTypes.shape({
    count: PropTypes.number,
    current: PropTypes.number,
    next: PropTypes.number,
    pages: PropTypes.number,
    per_page: PropTypes.number,
    previous: PropTypes.number,
  }),
  pagination: PropTypes.shape({
    current: PropTypes.number,
  }).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({}),
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  pagination,
  fetchingData: {
    notifications,
    notificationsPagination,
    loading,
    error,
  },
}) => {
  const paginationKey = getPaginationId(`${window.location.pathname}`);

  return {
    notifications,
    notificationsPagination,
    loading: loading.notifications,
    error: error.notifications,
    pagination: pagination[paginationKey] || {},
  };
};

export default withRouter(connect(mapStateToProps, { fetchNotifications, setPagination })(Notifications));
