import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import { compact } from 'lodash';

import Model from '@old/model';
import Modal from '@old/components/common/Modal';
import t from 'resources/translations';
import Icon from '@old/components/icon';
import FlexColumn from '@old/components/common/FlexColumn';
import EditableTextarea from '@old/components/common/editable/EditableTextarea';
import EditableInput from '@old/components/common/editable/EditableInput';
import RowAligner from '@old/components/common/RowAligner';
import config from '@old/config';
import store from 'store';
import { getErrorMessage, notify } from 'old/utils';
import { refreshModalOptions, fetchUsers, fetchUser } from 'store/actions';
import { useRoles } from '@old/hooks';
import EditableSelect from '@old/components/common/editable/EditableSelect';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import {
  useMemberDeleteMembershipButton,
  useMemberDeleteAccountButton,
  useMemberBlockButton,
  useMemberUnblockButton,
  useMemberDeleteGuestButton,
} from 'old/buttonHooks';
import { useOldLoggedMember } from 'utils/storeUtils';
import { refetchProfile } from 'store/auth';
import { MemberService } from 'services';

const ManageMemberModal = ({ member, fetchSingle, goBack, callback, onClose, ...actions }) => {
  const loggedMember = useOldLoggedMember();
  const dispatch = useDispatch();

  const onSave = async (value, field) => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    try {
      const updatedMember = await MemberService.update(member.id, Model.Member.mapToSaveData({ [field]: value }));
      actions.refreshModalOptions('manageMember', { member: new Model.Member(updatedMember) });
      if (loggedMember.id === member.id) {
        dispatch(refetchProfile());
      }
      if (fetchSingle) {
        actions.fetchUser(member.id, source.token);
      } else {
        const {
          fetchingData: { usersFetchParams },
        } = store.getState();
        actions.fetchUsers(usersFetchParams, source.token);
      }
      notify(t('message.succesSave'));
      return () => source.cancel();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      callback(value, field);
    }
    return false;
  };

  const roles = useRoles(loggedMember);
  const edtingOwnProfile = loggedMember && member && loggedMember.id === member.id;

  const memberDeleteMembership = useMemberDeleteMembershipButton(
    member,
    edtingOwnProfile,
    goBack,
    fetchSingle,
    onClose
  );
  const memberDelete = useMemberDeleteAccountButton(member, edtingOwnProfile);
  const memberBlock = useMemberBlockButton(member, edtingOwnProfile, fetchSingle, onClose);
  const memberUnblock = useMemberUnblockButton(member, edtingOwnProfile, fetchSingle, onClose);
  const deleteGuest = useMemberDeleteGuestButton(member, onClose);
  const manageButtons = [memberDeleteMembership, memberDelete, memberBlock, memberUnblock, deleteGuest];

  if (!member) return null;

  const instructor = loggedMember.isInstructor();
  const owner = loggedMember.isOwner();

  const clientEdited = member.isClient();
  const instructorEdited = member.isInstructor();
  const ownerEdited = member.isOwner();
  const guestEdited = member.isGuest();

  const showSkill = (instructor && !ownerEdited) || owner;
  const showAdditionalInfo =
    (clientEdited || instructorEdited) && (owner || (instructor && (edtingOwnProfile || clientEdited)));
  const showNickname = guestEdited && (instructor || owner);
  const showRole = owner && !guestEdited;
  const labelInput = instructorEdited ? t('labels.aboutMe') : t('labels.note');

  return (
    <Modal name="manageMember" header={t('general.manage')} onClose={onClose} isOpen small>
      <FlexColumn separated="small">
        {showRole && (
          <EditableSelect
            value={config.userRoles.find(role => role.name === member.role) || {}}
            options={roles.map(({ name, id }) => ({ label: t(`general.${name}`), value: id, key: id }))}
            onSubmit={value => onSave(value, 'role')}
            label={t('labels.role')}
          />
        )}
        {showSkill && (
          <EditableSelect
            value={config.difficulty.find(skill => skill.value === member.skill) || {}}
            options={config.difficulty.map(({ label, value }) => ({ label, value, key: value }))}
            onSubmit={value => onSave(value, 'skill')}
            label={t('general.level')}
          />
        )}
        {showAdditionalInfo && (
          <FlexColumn>
            <EditableTextarea
              onSubmit={value => onSave(value, instructorEdited ? 'aboutMe' : 'userNote')}
              defaultValue={instructorEdited ? member.aboutMe : member.userNote}
              placeholder={instructorEdited ? t('editableTextarea.addNoteAboutMe') : t('editableTextarea.addUserNote')}
              label={labelInput}
            />
          </FlexColumn>
        )}
        {showNickname && (
          <EditableInput onSubmit={value => onSave(value, 'nickname')} defaultValue={member.firstname} />
        )}
        {compact(manageButtons).map(({ key, onClick, label, iconName }) => {
          const IconComponent = Icon[iconName];
          return (
            <ButtonSimple key={key} onClick={onClick} className="text-left">
              <RowAligner>
                <IconComponent className="big fill-teal" />
                <div className="text-highlight">{label}</div>
              </RowAligner>
            </ButtonSimple>
          );
        })}
      </FlexColumn>
    </Modal>
  );
};

ManageMemberModal.defaultProps = {
  member: null,
  loggedMember: null,
  fetchSingle: false,
  goBack: false,
  callback: () => true,
};

export default connect(null, {
  refreshModalOptions,
  fetchUsers,
  fetchUser,
})(ManageMemberModal);
