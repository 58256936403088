import Allow from '@old/components/common/Allow';
import EventParticipationItem from '@old/components/view/listItem/EventParticipation';
import FlexRow from '@old/components/common/FlexRow';
import FlexColumn from '@old/components/common/FlexColumn';
import PairHorse from '@old/components/view/pairs/PairHorse';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import Center from '@old/components/common/Center';
import PairHeader from '@old/components/view/pairs/PairHeader';
import PlaceholderListItem from '@old/components/view/listItem/Placeholder';
import PairLinked from '@old/components/view/pairs/PairLinked';
import Tooltip from '@old/components/common/Tooltip';
import TitleList from '@old/components/common/TitleList';
import t from 'resources/translations';
import { useShallowSelector } from 'old/hooks';

const PairParticipations = ({
  participations,
  setPair,
  setUnpair,
  addParticipantButton,
  setParticipationId,
  availabilityCounter,
  horses,
  event,
}) => {
  const props = {
    participations,
    setPair,
    setUnpair,
    addParticipantButton,
    availabilityCounter,
    event,
  };
  const isMobile = useShallowSelector(({ app }) => app.isMobile);

  return !isMobile ? (
    <PairParticipationsDesktop {...props} />
  ) : (
    <PairParticipationsMobile horses={horses} setParticipationId={setParticipationId} {...props} />
  );
};

PairParticipations.defaultProps = {
  addParticipantButton: null,
  horses: [],
  setParticipationId: null,
  setPair: () => {},
  setUnpair: () => {},
};

export default PairParticipations;

const PairParticipationsDesktop = ({
  event,
  participations,
  setPair,
  setUnpair,
  addParticipantButton,
  availabilityCounter,
}) => {
  const renderEmptyList = () => {
    if (participations.length === 0) {
      return (
        <FlexRow cols="1/none/1" separated="small">
          <PlaceholderListItem>
            <Icon.HorseAlone />
            <div>{t('placeholders.noAssignedHorse')}</div>
          </PlaceholderListItem>
          <div className="w-10" />
          <FlexColumn separated="small">
            <PlaceholderListItem>
              <Icon.Human />
              <span>{t('placeholders.noParticipant')}</span>
            </PlaceholderListItem>
          </FlexColumn>
        </FlexRow>
      );
    }
    return null;
  };

  return (
    <FlexColumn separated="small">
      <PairHeader availabilityCounter={availabilityCounter} />
      {renderEmptyList()}
      {participations.map(participation => (
        <FlexRow key={participation.id} cols="1/none/1" separated="small">
          <PairHorse horse={participation.horse} participationId={participation.id} setPair={setPair} event={event} />
          <UnpairButton isHorse={!!participation.horse} setUnpair={() => setUnpair(participation.id)} event={event} />
          <EventParticipationItem modelItem={participation.member} />
        </FlexRow>
      ))}
      <FlexRow cols="1/none/1" separated="small">
        <div />
        <div className="w-10" />
        {addParticipantButton}
      </FlexRow>
    </FlexColumn>
  );
};

PairParticipationsDesktop.defaultProps = {
  addParticipantButton: null,
};

const PairParticipationsMobile = ({
  participations,
  setParticipationId,
  addParticipantButton,
  setUnpair,
  availabilityCounter,
  horses,
  event,
}) => {
  return (
    <FlexColumn separated="small">
      <TitleList>{t('eventPairs.participants', { ...availabilityCounter })}</TitleList>
      {participations.length === 0 && (
        <PlaceholderListItem>
          <Icon.Human />
          <span>{t('placeholders.noParticipant')}</span>
        </PlaceholderListItem>
      )}
      {participations.map(participation => {
        const { horse, member } = participation;
        const content = (
          <PairLinked modelItem={horse} event={event} onClickUnlink={() => setUnpair(participation.id)} />
        );

        return (
          <EventParticipationItem key={participation.id} modelItem={member} content={content}>
            <Allow
              permissionKey="participations.attach_horse"
              additionalCheck={!event?.isDeleted()}
              permissionParams={{ modelItem: event }}
            >
              {!horse && horses.length > 0 && (
                <ButtonSimple onClick={() => setParticipationId(participation.id)}>
                  <Icon.HorseAlone className="teal-hover" />
                </ButtonSimple>
              )}
            </Allow>
          </EventParticipationItem>
        );
      })}
      {addParticipantButton}
    </FlexColumn>
  );
};

PairParticipationsMobile.defaultProps = {
  addParticipantButton: null,
  setUnpair: () => {},
};

const UnpairButton = ({ isHorse, setUnpair, event }) => (
  <Center className="h-full text-teal w-10">
    {isHorse && (
      <Allow
        permissionKey={['participations.detach_horse']}
        permissionParams={{ modelItem: event }}
        additionalCheck={!event?.isDeleted()}
        fallback={<Icon.Events />}
      >
        <div className="teal-hover self-center">
          <Tooltip content={t('buttons.unlink')}>
            <ButtonSimple onClick={setUnpair}>
              <Icon.Events />
            </ButtonSimple>
          </Tooltip>
        </div>
      </Allow>
    )}
  </Center>
);

UnpairButton.defaultProps = {
  isHorse: false,
  setUnpair: () => {},
};
