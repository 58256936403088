import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import Model from 'old/model';
import { FarmService, MemberService } from 'services';
import localStorageService from 'services/storage/localStorageService';
import { RootState } from 'store';
import { setProfileAndFarm, setTokenAndRefreshModels } from './auth';
import queryString from 'query-string';
import { getHostLocation, mapProfileResToUserRes } from 'utils';

export interface IAppStore {
  isInitialized: boolean;
  sidebarIsOpen: boolean;
  menuIsOpen: boolean;
  apiVersion: string | null;
  isMaintenance: boolean;
  initError: SerializedError | null;
  isMobile: boolean;
  isTablet: boolean;
  redirectTo: string | null;
}

const initialState: IAppStore = {
  isInitialized: false,
  sidebarIsOpen: true,
  menuIsOpen: false,
  apiVersion: null,
  isMaintenance: false,
  initError: null,
  isMobile: false,
  isTablet: false,
  redirectTo: null,
};

const loginSuperUser = async (token: string | null) => {
  const { action, supertoken } = queryString.parse(window.location.search);

  if (action === 'logout' && supertoken !== undefined && token !== `Bearer ${supertoken}`) {
    const superToken = `Bearer ${supertoken}`;
    localStorageService._setToken(`Bearer ${supertoken}`);
    const { farms } = await FarmService.fetchMine();
    const farmId = farms[0]?.id;
    setTokenAndRefreshModels(superToken, farmId);
    const profile = await MemberService.fetchProfile();
    const userRes = mapProfileResToUserRes(profile);
    localStorageService._setUser(userRes);
  }
};

export const checkPhoneVerification = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  const token = localStorageService._getToken();
  const loggedUser = localStorageService._getUser();
  const mainPathName = window.location.pathname.split('/')[1];
  const allRequiredDataIsStored = loggedUser?.hasOwnProperty('phoneVerified') && loggedUser.id && loggedUser.phone;
  if (token) {
    if (!allRequiredDataIsStored) {
      dispatch(appSlice.actions.setRedirect('/logout'));
      return false;
    }
    if (allRequiredDataIsStored && !loggedUser?.phoneVerified) {
      if (mainPathName !== 'confirm-phone') {
        window.location.href = `${getHostLocation()}/confirm-phone`;
      }
      return false;
    }
  }
  return true;
};

export const initApp = createAsyncThunk('app/init', async (_, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const token = localStorageService._getToken();
    Model.refresh(token);
    await loginSuperUser(token);

    if (!checkPhoneVerification(thunkAPI.dispatch)) {
      return true;
    }

    if (!token) {
      thunkAPI.dispatch(appSlice.actions.setInitApp(true));
      return true;
    }

    const slug = window.location.pathname.split('/')[1];
    let profile = null;
    let farm = null;

    if (!store.auth.farm && slug) {
      farm = await FarmService.fetchBySlugname(slug);
      setTokenAndRefreshModels(token, farm?.id);
    }

    if (!store.auth.profile && farm) {
      profile = await MemberService.fetchProfile();
    }

    if (profile && farm) {
      thunkAPI.dispatch(setProfileAndFarm({ profile, farm }));
    }

    return true;
  } catch (e) {
    throw e;
  }
});

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setInitApp: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    },
    toggleSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebarIsOpen = action.payload;
    },
    toggleMenu: (state, action: PayloadAction<boolean>) => {
      state.menuIsOpen = action.payload;
    },
    setRedirect: (state, action: PayloadAction<string | null>) => {
      state.redirectTo = action.payload;
    },
    setMaintenance: (state, action: PayloadAction<boolean>) => {
      state.isMaintenance = action.payload;
    },
    setBreakpoints: (state, action: PayloadAction<{ isMobile: boolean; isTablet: boolean }>) => {
      state.isMobile = action.payload.isMobile;
      state.isTablet = action.payload.isTablet;
    },
  },
  extraReducers: builder => {
    /* app/init */
    builder.addCase(initApp.fulfilled, state => {
      state.isInitialized = true;
    });
    builder.addCase(initApp.pending, state => {
      state.isInitialized = false;
    });
    builder.addCase(initApp.rejected, (state, action) => {
      state.isInitialized = true;
      state.initError = action.error;
    });
  },
});

export const { setInitApp, toggleSidebar, toggleMenu, setMaintenance, setRedirect, setBreakpoints } = appSlice.actions;

export const appSelector = (state: RootState) => state.app;

export default appSlice.reducer;
