import { Flex, Stack, Text, Icons, Box, Dot, Tooltip, Span, Button } from 'components/elements';
import { TIconCircleProps, TBoxProps, TTextProps } from 'resources/types/elementsTypes';
import t from 'resources/translations';
import TicketOffer from 'models/TicketOffer';
import { isAllowed, parseToCurrencyString } from 'old/utils';
import { useModal } from 'old/hooks';
import { ContextMenu, ContextMenuItem } from 'components/combinations';
import { TicketOfferService } from 'services';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useAppStore, useFarm, useLoggedMember } from 'utils/storeUtils';

type TStyleProps = {
  textTruncate: TTextProps;
  iconRectangle: TIconCircleProps;
  redBox: TBoxProps;
  blueBox: TBoxProps;
  box: TBoxProps;
};

const styles = {
  textTruncate: {
    fontWeight: 'medium',
    whiteSpace: 'nowrap',
    minW: 0,
    maxW: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconRectangle: {
    bgColor: 'teal-dark',
    color: 'white',
    size: 'sm',
    border: { borderRadius: '3px' },
  },
  box: {
    p: 'sm',
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: 'grey',
    borderStyle: 'solid',
    minW: 0,
    minH: 0,
  },
  redBox: {
    backgroundColor: 'new-red-lighter',
    p: 'xs',
    borderRadius: '1px',
    borderWidth: '1px',
    borderColor: 'new-red-light',
    borderStyle: 'solid',
  },
  blueBox: {
    backgroundColor: 'new-blue-lighter',
    p: 'xs',
    borderRadius: '1px',
    borderWidth: '1px',
    borderColor: 'new-blue-light',
    borderStyle: 'solid',
  },
} as TStyleProps;

type TTicketOffersItemsProps = {
  offer: TicketOffer;
};

const TicketOffersItem = ({ offer }: TTicketOffersItemsProps) => {
  const { isMobile } = useAppStore();
  const farm = useFarm();
  const loggedMember = useLoggedMember();
  const history = useHistory();
  const queryClient = useQueryClient();
  const confirmModal = useModal('CONFIRM');
  const paymentTicketModal = useModal('PAYMENT_BUY_TICKET');
  const offerDetailsModal = useModal('TICKET_OFFER_DETAILS');
  const ticketAssignModal = useModal('TICKET_ASSIGN');
  const isAddOnlinePayment = farm?.isAdvancedPaymentEnabled();
  const deleteOffer = async () => await TicketOfferService.delete(offer.id);
  const refreshOffers = () => queryClient.invalidateQueries('TICKET_OFFERS', { exact: true });
  const rejectMutation = useMutation(deleteOffer, { onSuccess: refreshOffers });

  const onOpenPaymenModal = () => {
    return paymentTicketModal.onOpen({ ticketOffer: offer });
  };

  const onOpenTicketOfferDetailsModal = () => {
    return offerDetailsModal.onOpen({ offer });
  };

  const onOpenTicketAssignModal = () => {
    return ticketAssignModal.onOpen({ offer });
  };

  const onOpenConfirmDelete = () =>
    confirmModal.onOpen({
      title: t('ticketOffers.deleteTitleModal'),
      content: (
        <Box mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" p="xl">
          <Stack spacing="lg">
            <Text>
              {t('ticketOffers.deleteOfferModal')}
              <Span fontWeight="medium">{offer.getName()}</Span>
              <Span>?</Span>
            </Text>
            <Text>{t('ticketOffers.savedChangesLostIrretrievably')}</Text>
          </Stack>
        </Box>
      ),
      action: {
        mutation: rejectMutation,
        label: t('buttons.deleteOfferTicket'),
        loadingLabel: t('message.removed'),
        cancelLabel: t('buttons.noGoBackOfferList'),
      },
    });

  const eventTypeName = offer.eventTypes.map(type => type.getName()).join(', ');

  const tooltipProps = isMobile
    ? {
        visible: false,
      }
    : {};

  return (
    <Flex {...styles.box}>
      <Flex flex={loggedMember.isClient() ? 1 : 9} w="100%" overflow="hidden">
        <Stack spacing="sm" w="100%">
          <Stack isHorizontal alignItems="center" spacing="sm">
            {!isMobile && <Icons.TicketCircle {...styles.iconRectangle} />}
            <Flex flex={1} minW={0}>
              <Tooltip content={offer.getName()} placement="top-start" {...tooltipProps}>
                <Button minW={0} textAlign="left" variant="link" onClick={() => onOpenTicketOfferDetailsModal()}>
                  <Text {...styles.textTruncate}>{offer.getName()}</Text>
                </Button>
              </Tooltip>
            </Flex>
          </Stack>
          <Stack isHorizontal spacing="sm">
            <Box {...styles.redBox}>
              <Text color="new-red" fontSize="sm">
                {`${t('ticketOffers.important')}: ${offer.validity} ${t('tickets.days', {
                  count: offer.validity,
                })}`}
              </Text>
            </Box>
            <Box {...styles.blueBox}>
              <Text color="new-blue" fontSize="sm">
                {`${t('ticketOffers.price')}: ${parseToCurrencyString(offer.price)}`}
              </Text>
            </Box>
            {!offer.visibility && (
              <Flex alignItems="center">
                <Icons.CrossedEyeCircle bgColor="teal-dark" color="white" size="sm" />
              </Flex>
            )}
          </Stack>
          <Flex flexDirection={['column', 'row']} justifyContent="space-between">
            <Flex flex={3} minW={0} alignItems="center" mb={['sm', 0]}>
              <Dot size={6} bgColor="grey-dark" mr="xs" />
              <Tooltip content={eventTypeName} placement="top-start" {...tooltipProps}>
                <Text whiteSpace="nowrap" minW={0} maxW="100%" textOverflow="ellipsis" overflow="hidden">
                  {eventTypeName}
                </Text>
              </Tooltip>
            </Flex>
            <Stack isHorizontal={!isMobile} flex={1} spacing="xs">
              <Stack flex={1} spacing="xs" alignItems="center" isHorizontal>
                <Dot size={6} bgColor="grey-dark" />
                <Text whiteSpace="nowrap">{`${t('ticketOffers.numbersOfRides')}: ${offer.count}`}</Text>
              </Stack>
              {isAddOnlinePayment && loggedMember.isClient() && (
                <Flex whiteSpace="nowrap" flex={2} justifyContent={isMobile ? 'center' : 'flex-end'}>
                  <Button variant="link" onClick={() => onOpenPaymenModal()} fontSize="base">
                    {t('ticketOffers.buyTicket')}
                  </Button>
                </Flex>
              )}
            </Stack>
          </Flex>
        </Stack>
      </Flex>
      {!loggedMember.isClient() && (
        <Flex alignSelf="center" justifyContent="center" color="teal-light">
          <ContextMenu trigger={null}>
            <ContextMenuItem onClick={onOpenTicketOfferDetailsModal}>
              <Text>{t('ticketOffers.seeDetails')}</Text>
            </ContextMenuItem>
            {isAllowed('ticket_offers.set') && (
              <ContextMenuItem onClick={onOpenTicketAssignModal}>
                <Text>{t('ticketOffers.assignClient')}</Text>
              </ContextMenuItem>
            )}
            {isAllowed('ticket_offers.update') && (
              <ContextMenuItem onClick={() => history.push(`/tickets/${offer.id}/edit`)}>
                <Text>{t('general.edit')}</Text>
              </ContextMenuItem>
            )}
            <ContextMenuItem onClick={onOpenConfirmDelete}>
              <Text>{t('general.delete')}</Text>
            </ContextMenuItem>
          </ContextMenu>
        </Flex>
      )}
    </Flex>
  );
};
export default TicketOffersItem;
