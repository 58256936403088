/**
 * @module GroupsProvider
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import { isEmpty } from 'lodash';

import Error404 from '@old/components/error/Error404';
import Spinner from '@old/components/common/Spinner';
import { setPagination, fetchGroups } from 'store/actions';
import { getPaginationId } from 'old/utils';
import PagePagination from '@old/components/old/PagePagination';
import FlexColumn from '@old/components/common/FlexColumn';
import GroupsList from '@old/components/view/list/Group';
import GroupsGrid from '@old/components/view/grid/Group';
import config from '@old/config';
import { useAppStore } from 'utils';

/**
 * @typedef   {Object} PaginationStore
 * @property  {Number} current current page number
 * @property  {String} pathname current url without host
 */
/**
 * @typedef   {Object} Pagination
 * @property  {Number} count number of items
 * @property  {Number} current current page number
 * @property  {Number} next next page number
 * @property  {Number} pages number of pages
 * @property  {Number} per_page number of items per single page
 * @property  {Number} previous previous page number
 * @property  {Function} push
 */
/**
 * Component that provides groups and passes them view component
 * @param  {Boolean} props.loading specifies wether new groups are being fetch
 * @param  {Boolean} props.error specifies wether an error occured when the groups were being fetch
 * @param  {Group[]} [props.groups] fetched groups
 * @param  {Pagination} [props.groupsPagination] pagination fetched with groups
 * @param  {PaginationStore} props.pagination pagination from the store(set after fetched groups pagination)
 * @param  {String} [props.pagKey] string passed to create uniq pagination id - passed when tabs are being used
 * @param  {History} props.history
 * @param  {Function} props.fetchGroups function that fetches groups and saves them to strone
 * @param  {Function} props.setPagination function that sets pagination and saves it to store
 * @param  {Boolean} props.isMobile specifies wether mobile device is being used
 * @param  {Boolean} props.fetchMine specifies wether to fetch logged user groups
 */

const GroupsProvider = ({
  loading,
  error,
  groups,
  groupsPagination,
  pagination,
  pagKey,
  history,
  fetchMine,
  ...actions
}) => {
  const { minItemsPerPage } = config;
  const { isMobile } = useAppStore();
  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname} ${JSON.stringify(pagKey)}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    // eslint-disable-line
    if (isEmpty(pagination) || pagination.current !== undefined) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const fetch = async () => {
        await actions.fetchGroups(
          {
            per_page: minItemsPerPage,
            page: getActivePage() || 1,
          },
          source.token,
          fetchMine
        );
      };
      fetch();
      return () => source.cancel();
    }
  }, [history.location.state, pagKey, pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (groupsPagination) {
      const pag = { ...groupsPagination };
      // When you delete last element on page, go back to previous one
      const lastPage = Math.ceil(pag.count / pag.per_page);
      if (pag.per_page * pag.current > pag.count) {
        if (lastPage && pag.current > lastPage) {
          pag.current = lastPage;
          history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
        }
        if (pag.current === 0) {
          pag.current = 1;
        }
      }
      actions.setPagination(pag, getPaginationKey());
    }
  }, [groupsPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Error404 />;
  if (loading && !groups.length) {
    return <Spinner noDelay />;
  }

  return (
    <div>
      <FlexColumn>
        <React.Fragment>
          {isMobile ? <GroupsList data={groups} /> : <GroupsGrid data={groups} />}
          <PagePagination paginationKey={getPaginationKey()} />
        </React.Fragment>
      </FlexColumn>
    </div>
  );
};

GroupsProvider.defaultProps = {
  groups: [],
  groupsPagination: {},
  pagKey: '',
  isMobile: false,
  fetchMine: false,
};

GroupsProvider.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  groupsPagination: PropTypes.shape({
    count: PropTypes.number,
    current: PropTypes.number,
    next: PropTypes.number,
    pages: PropTypes.number,
    per_page: PropTypes.number,
    previous: PropTypes.number,
  }),
  pagination: PropTypes.shape({
    current: PropTypes.number,
  }).isRequired,
  pagKey: PropTypes.string,
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({}),
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  fetchMine: PropTypes.bool,
};

const mapStateToProps = ({ pagination, fetchingData: { groups, groupsPagination, loading, error } }, { pagKey }) => {
  const paginationKey = getPaginationId(`${window.location.pathname} ${JSON.stringify(pagKey || '')}`);

  return {
    groups,
    groupsPagination,
    loading: loading.groups,
    error: error.groups,
    pagination: pagination[paginationKey] || {},
  };
};

export default withRouter(connect(mapStateToProps, { fetchGroups, setPagination })(GroupsProvider));
