/**
 * @module Widget
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// A customizable rating component for selecting x widgets or visualizing x widgets. SVG stars that show aggregate star ratings to the hundreths decimal point.
/** The documentation is here
 *  @see {@link https://github.com/ekeric13/react-ratings-declarative}
 */
const Widget = ({ ...props }) => {
  const widgetContainerStyle = () => {
    const {
      changeRating,
      widgetSpacing,
      isFirstWidget,
    } = props;

    const widgetContainerStyles = {
      position: 'relative',
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingLeft: isFirstWidget ? undefined : widgetSpacing,
      cursor: changeRating ? 'pointer' : undefined,
    };
    return widgetContainerStyles;
  };

  const widgetSvgStyle = () => {
    const {
      isCurrentHoveredWidget,
      widgetDimension,
    } = props;
    const widgetSvgStyles = {
      width: widgetDimension,
      height: widgetDimension,
      transition: 'transform .2s ease-in-out',
      transform: isCurrentHoveredWidget ? 'scale(1.1)' : undefined,
    };

    return widgetSvgStyles;
  };

  const pathStyle = () => {
    const {
      isSelected,
      isPartiallyFullWidget,
      isHovered,
      hoverMode,
      widgetEmptyColor,
      widgetRatedColor,
      widgetHoverColor,
      gradientPathName,
      inheritFillId,
    } = props;

    let fill;
    if (hoverMode) {
      if (isHovered) fill = widgetHoverColor;
      else fill = widgetEmptyColor;
    } else if (isPartiallyFullWidget) fill = `url('${gradientPathName}#${inheritFillId}')`;
    else if (isSelected) fill = widgetRatedColor;
    else fill = widgetEmptyColor;

    const pathStyles = {
      fill,
      transition: 'fill .2s ease-in-out',
    };
    return pathStyles;
  };

  const widgetClasses = () => {
    const {
      isSelected,
      isPartiallyFullWidget,
      isHovered,
      isCurrentHoveredWidget,
    } = props;

    const widgetClass = classNames({
      'widget-svg': true,
      'widget-selected': isSelected,
      'multi-widget-selected': isPartiallyFullWidget,
      hovered: isHovered,
      'current-hovered': isCurrentHoveredWidget,
    });

    return widgetClass;
  };

  const {
    changeRating,
    inheritFillId,
    svgIconViewBox,
    hoverOverWidget,
    unHoverOverWidget,
    svgIconPath,
    svg,
  } = props;
  let customSvg = svg;
  if (React.isValidElement(customSvg)) {
    customSvg = React.cloneElement(customSvg, {
      ...props,
      fillId: inheritFillId,
    });
  }
  return (
    <div
      className="widget-container"
      style={widgetContainerStyle()}
      onMouseEnter={hoverOverWidget}
      onMouseLeave={unHoverOverWidget}
      onClick={changeRating}
    >
      {customSvg || (
        <svg
          viewBox={svgIconViewBox}
          className={widgetClasses()}
          style={widgetSvgStyle()}
        >
          <path
            className="widget"
            style={pathStyle()}
            d={svgIconPath}
          />
        </svg>
      )}
    </div>
  );
};

Widget.propTypes = {
  gradientPathName: PropTypes.string,
  changeRating: PropTypes.func,
  inheritFillId: PropTypes.string,
  isSelected: PropTypes.bool,
  isHovered: PropTypes.bool,
  isCurrentHoveredWidget: PropTypes.bool,
  isPartiallyFullWidget: PropTypes.bool,
  isFirstWidget: PropTypes.bool,
  hoverMode: PropTypes.bool,
  // customizable
  svgIconPath: PropTypes.string,
  svgIconViewBox: PropTypes.string,
  svg: PropTypes.node,
  widgetRatedColor: PropTypes.string,
  widgetEmptyColor: PropTypes.string,
  widgetHoverColor: PropTypes.string,
  widgetDimension: PropTypes.string,
  widgetSpacing: PropTypes.string,
  hoverOverWidget: PropTypes.func,
  unHoverOverWidget: PropTypes.func,
};

Widget.defaultProps = {
  gradientPathName: '',
  changeRating: () => true,
  inheritFillId: '',
  isSelected: false,
  isHovered: false,
  isCurrentHoveredWidget: false,
  isPartiallyFullWidget: false,
  isFirstWidget: false,
  hoverMode: false,
  svgIconPath: '',
  svgIconViewBox: '',
  svg: '',
  widgetRatedColor: '',
  widgetEmptyColor: '',
  widgetHoverColor: '',
  widgetDimension: '',
  widgetSpacing: '',
  hoverOverWidget: () => true,
  unHoverOverWidget: () => true,
};

export default Widget;
