import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PieChart, Cell, Pie, ResponsiveContainer, Label, Sector } from 'recharts';
import moment from 'moment';
import sumBy from 'lodash/sumBy';

import WorkedHoursChart from '@old/components/view/charts/WorkedHoursChart';
import PieChartLegend from '@old/components/view/charts/PieChartLegend';
import FlexColumn from '@old/components/common/FlexColumn';
import PieChartLabel from '@old/components/view/charts/PieChartLabel';
import { usePieChartData } from '@old/hooks';
import { formatDuration } from 'old/utils';
import config from '@old/config';

const PlaceStatisticsPieChart = ({ dateRange, placeId, basic, windowWidth }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const onPieLeave = () => {
    setActiveIndex(null);
  };

  const [pieChartData, total, duration] = usePieChartData(placeId, 'places', dateRange);
  const totalEvents = total ? total.finished_count : 0;
  const finishedDuration = (total && total.finished_duration) || null;
  const totalHours = finishedDuration ? formatDuration(moment.duration(finishedDuration, 'minutes')) : '0m';
  const smallWindow = windowWidth < 1000;

  /* eslint-disable react/prop-types */
  const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value }) => (
    <g>
      {payload.name !== 'empty' && (
        <PieChartLabel
          position="center"
          labelKey={payload.name}
          viewBox={{ cx, cy }}
          value={value}
          small={smallWindow}
        />
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
  /* eslint-enable react/prop-types */
  const dataIsEmpty = pieChartData.length === 0 || sumBy(pieChartData, item => item.value) === 0;

  return (
    <React.Fragment>
      <ResponsiveContainer height={smallWindow ? 150 : 200}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={!dataIsEmpty ? pieChartData : [{ name: 'empty', value: 1, color: config.color.tealLight }]}
            innerRadius={smallWindow ? 46 : 60}
            outerRadius={smallWindow ? 56 : 80}
            fill={config.color.tealLight}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            dataKey="value"
            paddingAngle={2}
          >
            {pieChartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
            {activeIndex === null && (
              <Label
                position="center"
                content={<PieChartLabel value={totalEvents} labelKey="planned" small={smallWindow} />}
              />
            )}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <FlexColumn>
        {!basic && <PieChartLegend chartData={pieChartData} duration={duration} />}
        {basic && <WorkedHoursChart value={totalHours} />}
      </FlexColumn>
    </React.Fragment>
  );
};

const mapStateToProps = ({ app: { windowWidth } }) => ({ windowWidth });

export default connect(mapStateToProps)(PlaceStatisticsPieChart);

PlaceStatisticsPieChart.defaultProps = {
  basic: false,
  dateRange: null,
};
