/* eslint-disable max-len */
import React from 'react';

const CodeQR = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M0,7.3h7.3V0H0L0,7.3z M1,1h5.2v5.2H1L1,1z M2.1,2.1h3.1v3.1H2.1V2.1z M8.7,0v7.3H16V0L8.7,0z M15,6.2H9.7V1H15L15,6.2z
        M10.8,2.1h3.1v3.1h-3.1V2.1z M0,16h7.3V8.7H0L0,16z M1,9.7h5.2v5.2H1L1,9.7z M2.1,10.8h3.1v3.1H2.1V10.8z M8.7,16h2.1v-1h-1v-2.1h1
        v-1h-1V9.7h1v-1H8.7V16L8.7,16z M15,8.7h1v1h-1V8.7z M15,14.9h-1v1H16v-4.9h-1L15,14.9L15,14.9z M11.8,8.7h2.1v1h-2.1V8.7z
        M11.8,13.9h2.1v-1h-1v-1h1v-1h-2.1V13.9L11.8,13.9z M11.8,14.9h1v1h-1V14.9z"
      />
    </svg>
  );
};

export default CodeQR;
/* eslint-enable max-len */
