import React from 'react';
import PropTypes from 'prop-types';

import t from 'resources/translations';

const WorkedHoursChart = ({ value }) => {
  return (
    <React.Fragment>
      <div className="font-bold text-center">{value}</div>
      <div className="bg-teal-dark" style={{ height: 20, width: '60%', margin: '0 auto' }} />
      <div className="text-center">{t('workedHoursChart.eventsDuration')}</div>
    </React.Fragment>
  );
};

WorkedHoursChart.propTypes = {
  value: PropTypes.string.isRequired,
};

export default WorkedHoursChart;
