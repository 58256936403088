/* eslint-disable max-len */
import React from 'react';

const MailOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,0c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S3.6,0,8,0z M9.4,8.7L9.3,8.8C9.1,9,9,9,8.9,9.1C8.8,9.2,8.6,9.3,8.5,9.3
        C8.3,9.4,8.2,9.4,8,9.4c-0.2,0-0.3,0-0.5-0.1C7.4,9.3,7.3,9.2,7.1,9.1C7,9,6.9,9,6.7,8.8L6.6,8.7l-2.2,2.2c0.1,0.1,0.3,0.2,0.5,0.2
        h6.4c0.1,0,0.3-0.1,0.4-0.2L9.4,8.7L9.4,8.7z M11.9,7c-0.1,0.1-0.3,0.3-0.4,0.4c-0.6,0.4-1.1,0.7-1.5,1l1.9,2
        C11.9,10.3,11.9,7,11.9,7z M4.2,6.9v3.3l1.9-1.9c-0.4-0.3-0.9-0.6-1.5-1C4.4,7.2,4.3,7.1,4.2,6.9z M11.2,5H4.9
        C4.6,5,4.4,5.1,4.3,5.2C4.2,5.4,4.2,5.6,4.2,5.8c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.4,0.5,0.5l0.9,0.6L6.5,8l0.4,0.3L7,8.3
        l0.1,0.1l0.2,0.2l0.2,0.1c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2-0.1,0.2-0.1l0.2-0.1l0.2-0.2
        l0.1-0.1l0.1-0.1L9.6,8l1.6-1.1c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.5C11.6,5.1,11.4,5,11.2,5L11.2,5z"
      />
    </svg>
  );
};

export default MailOutline;
/* eslint-enable max-len */
