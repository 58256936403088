import Farm from 'models/Farm';
import Member from 'models/Member';
import React, { useMemo } from 'react';
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { bindActionCreators } from 'redux';
import type { RootState, AppDispatch } from '../store';
import { Member as OldMember } from 'old/model/Member';

export const useAppDispatch = () => useDispatch<AppDispatch>();
/**
 * Use throughout your app instead of plain `useDispatch` and `useSelector`
 * @return extracted data from the Redux store state,
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = mapStateToData =>
  useSelector(mapStateToData, shallowEqual);

export const useActions = (actions: AnyAction[], deps?: any) => {
  const dispatch = useAppDispatch();

  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [dispatch, ...deps] : [dispatch]
  );
};

export const useAppStore = () => {
  return useAppSelector(({ app }) => app);
};

export const useAuthStore = () => {
  return useAppSelector(({ auth }) => auth);
};

export const useLoggedMember = () => {
  const authStore = useAuthStore();
  return React.useMemo(() => (authStore.profile ? new Member(authStore.profile) : null), [authStore.profile]) as Member;
};

export const useOldLoggedMember = () => {
  const authStore = useAuthStore();
  return React.useMemo(
    () => (authStore.profile ? new OldMember(authStore.profile, { fromUserData: true }) : null),
    [authStore.profile]
  ) as OldMember;
};

export const useFarm = () => {
  const authStore = useAuthStore();
  return React.useMemo(() => (authStore.farm ? new Farm(authStore.farm) : null) as Farm, [authStore.farm]);
};

export const useContextMenuStore = () => {
  return useAppSelector(({ contextMenu }) => contextMenu);
};
