import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

import FlexColumn from '@old/components/common/FlexColumn';
import BoxWhite from '@old/components/common/BoxWhite';
import Form from '@old/components/common/Form';
import Field from '@old/components/field';
import FormButton from '@old/components/common/FormButton';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import { fortmatDateWithSeconds } from 'old/utils';
import t from 'resources/translations';
import { Ternary } from 'components/elements';

const ActivationCodeForm = ({
  dateFromApi,
  sendSmsWithActivationCode,
  setPhoneNumer,
  confirmPhoneByActivationCode,
}) => {
  const timerRef = useRef();

  useEffect(() => {
    if (dateFromApi) timerRef.current.start();
  }, [dateFromApi]);

  return (
    <BoxWhite padding>
      <FlexColumn>
        <RowAligner>
          <ButtonSimple onClick={() => setPhoneNumer('')} basic>
            <Icon.ArrowLeft className="fill-teal" />
          </ButtonSimple>
          <h1 className="text-headline">{t('confirmPhonePage.verifyNumber')}</h1>
        </RowAligner>
        <FlexColumn>
          <div>
            <div>{t('confirmPhonePage.youHaveNotReceivedMessage')}</div>
            <Ternary cond={dateFromApi === null}>
              <div>{t('confirmPhonePage.exceededTheLimit')}</div>
              <Countdown
                date={dateFromApi}
                intervalDelay={0}
                precision={1}
                ref={timerRef}
                renderer={props => (
                  <>
                    {props.total !== 0 && (
                      <div className="text-fade">
                        {t('confirmPhonePage.SendSMSAgainIn')} {fortmatDateWithSeconds(props.total)}
                      </div>
                    )}
                    {props.total === 0 && (
                      <ButtonSimple onClick={sendSmsWithActivationCode} async="simple">
                        <span className="text-link font-bold underline">{t('confirmPhonePage.sendSMSAgain')}</span>
                      </ButtonSimple>
                    )}
                  </>
                )}
              />
            </Ternary>
          </div>
          <Form formName="ACTIVATION_CODE" initValues={{ code: [] }} noPadding>
            <Field.Code name="code" label={t('labels.rewriteCodeFromSMS')} />
            <FormButton formName="ACTIVATION_CODE" onClick={confirmPhoneByActivationCode} async fluid>
              {t('buttons.verify')}
            </FormButton>
          </Form>
        </FlexColumn>
      </FlexColumn>
    </BoxWhite>
  );
};
ActivationCodeForm.defaultProps = {
  dateFromApi: null,
  total: '',
};

ActivationCodeForm.propTypes = {
  setPhoneNumer: PropTypes.func.isRequired,
  confirmPhoneByActivationCode: PropTypes.func.isRequired,
  sendSmsWithActivationCode: PropTypes.func.isRequired,
  dateFromApi: PropTypes.instanceOf(Date),
  total: PropTypes.string,
};

export default ActivationCodeForm;
