/* eslint-disable max-len */
import React from 'react';

const WarningTriangleOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M15.9,13.1l-7-11.4C8.7,1.5,8.3,1.3,8,1.3S7.3,1.5,7.2,1.8l0,0l-7,11.4C0.1,13.3,0,13.5,0,13.7c0,0.5,0.4,1,1,1c0,0,0,0,0,0
        h0h14c0,0,0,0,0,0c0.5,0,1-0.4,1-1C16,13.5,15.9,13.3,15.9,13.1C15.9,13.1,15.9,13.1,15.9,13.1z M8.1,13.4
        C8.1,13.4,8.1,13.4,8.1,13.4c-0.4,0-0.8-0.3-0.8-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7l0,0l0,0
        C8.8,13.1,8.5,13.4,8.1,13.4L8.1,13.4z M8.8,9.7c0,0.4-0.3,0.7-0.7,0.7h0H8c0,0,0,0,0,0c-0.4,0-0.7-0.3-0.7-0.7v0L7,4.8c0,0,0,0,0,0
        c0-0.6,0.5-1,1-1h0l0,0c0.6,0,1,0.5,1,1c0,0,0,0,0,0v0L8.8,9.7z"
      />
    </svg>
  );
};

export default WarningTriangleOutline;
/* eslint-enable max-len */
