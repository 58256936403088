import InputWrapper from 'components/combinations/forms/InputWrapper';
import { Button, Icons, Stack } from 'components/elements';
import InputNumber from 'components/elements/InputNumber';
import theme from 'config/theme';
import { NumericInputProps } from 'react-numeric-input';
import { TFlexProps, TInputProps } from 'resources/types/elementsTypes';
import { css } from 'styled-components';

type TNumerInput = TInputProps &
  Omit<NumericInputProps, 'value' | 'onChange' | 'min' | 'max'> & {
    value: number | string;
    inputWrapperProps?: TFlexProps;
    showSpinButtons?: boolean;
    onChange: (val: number | null) => void;
    min?: number;
    max?: number;
  };

const buttonStyles = {
  flex: 1,
  px: 'sm',
  bgColor: 'teal',
  borderRadius: 4,
};

const NumberInput = ({
  value,
  name,
  placeholder,
  onChange,
  children,
  marginInlineStart,
  mt,
  inputWrapperProps = {},
  showSpinButtons = false,
  ...numberInputProps
}: TNumerInput) => {
  const parseValue = (val: string | number) => {
    if (typeof val === 'number') {
      return val;
    }
    return parseFloat(val || '0');
  };

  return (
    <InputWrapper overflow="hidden" marginInlineStart={marginInlineStart} mt={mt} {...inputWrapperProps}>
      <InputNumber
        id={name}
        type="text"
        name={name}
        value={value}
        onChange={val => onChange(val)}
        placeholder={placeholder}
        inputMode="numeric"
        px={20}
        {...numberInputProps}
        width="100%"
      />
      {showSpinButtons && (
        <Stack spacing={2} flex="none" mr={-1}>
          <Button
            variant="icon"
            disabled={(numberInputProps.max || Infinity) <= parseValue(value) || !value}
            onClick={() => onChange(parseValue(value) + 1)}
            {...buttonStyles}
            css={css({
              '&:disabled': {
                cursor: 'default',
                backgroundColor: theme.colors.grey,
              },
              '&:hover': {
                backgroundColor: 'teal-dark',
              },
            })}
          >
            <Icons.ArrowUp fill="white" />
          </Button>
          <Button
            variant="icon"
            disabled={(numberInputProps.min || -Infinity) >= parseValue(value) || !value}
            onClick={() => onChange(parseValue(value) - 1)}
            {...buttonStyles}
            css={css({
              '&:disabled': {
                cursor: 'default',
                backgroundColor: theme.colors.grey,
              },
              '&:hover': {
                backgroundColor: 'teal-dark',
              },
            })}
          >
            <Icons.ArrowDown fill="white" />
          </Button>
        </Stack>
      )}
    </InputWrapper>
  );
};

export default NumberInput;
