/* eslint-disable max-len */
import React from 'react';

const Print = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <path d="M16.8,19.4H9.2c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h7.5c0.5,0,0.9-0.4,0.9-0.9C17.6,19.8,17.2,19.4,16.8,19.4z" />
      <path d="M16.8,16.7H9.2c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h7.5c0.5,0,0.9-0.4,0.9-0.9C17.6,17,17.2,16.7,16.8,16.7z" />
      <path d="M24,6.8h-2.8v-5c0-0.5-0.4-0.9-0.9-0.9H5.6c-0.5,0-0.9,0.4-0.9,0.9v5H2c-1.1,0-2,0.9-2,2v8.7c0,1.1,0.9,2,2,2h2.8v4.7c0,0.5,0.4,0.9,0.9,0.9h14.7c0.5,0,0.9-0.4,0.9-0.9v-4.7H24c1.1,0,2-0.9,2-2V8.8C26,7.7,25.1,6.8,24,6.8z M6.5,2.7h13v4.1h-13V2.7z M19.5,23.3h-13c0-0.2,0-6.9,0-7.1h13C19.5,16.5,19.5,23.2,19.5,23.3z M20.4,11.9h-2.2c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h2.2c0.5,0,0.9,0.4,0.9,0.9S20.8,11.9,20.4,11.9z" />
    </svg>
  );
};

export default Print;
/* eslint-enable max-len */
