import { useState } from 'react';
import { connect } from 'react-redux';

import EventParticipationItem from '@old/components/view/listItem/EventParticipation';
import DragItem from '@old/components/common/DragItem';
import FlexColumn from '@old/components/common/FlexColumn';
import Subtitle from '@old/components/common/Subtitle';
import Allow from '@old/components/common/Allow';
import { Grid } from '@elements';
import TitleList from '@old/components/common/TitleList';
import RowAligner from '@old/components/common/RowAligner';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Tooltip from '@old/components/common/Tooltip';
import { useEventPairParticipantsWithHorsesButton } from '@old/hooks';
import BoxWhite from '@old/components/common/BoxWhite';
import Divider from '@old/components/common/Divider';
import t from 'resources/translations';

const UnpairedHorsesList = ({
  horses,
  event,
  addHorseButton,
  eventDeleteHorseButton,
  isMobile,
  isTablet,
  participants,
  cb,
}) => {
  const eventPairParticipantsWithHorses = useEventPairParticipantsWithHorsesButton(event, participants, horses, cb);

  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const columns = isTablet ? 2 : 3;
  const draggedItems = horses.map(horse => (
    <DragItem key={horse.id} dragState={{ horseId: horse.id }}>
      {isDeleteMode && !isMobile ? (
        <EventParticipationItem modelItem={horse}>
          <ButtonSimple name="removeHorse" onClick={() => eventDeleteHorseButton.onClick(horse.id)} async="simple">
            <Tooltip content={t('general.delete')}>{eventDeleteHorseButton.icon}</Tooltip>
          </ButtonSimple>
        </EventParticipationItem>
      ) : (
        <EventParticipationItem modelItem={horse} />
      )}
    </DragItem>
  ));
  const items = horses.map(horse => (
    <div key={horse.id}>
      {isDeleteMode ? (
        <EventParticipationItem modelItem={horse}>
          <ButtonSimple onClick={() => eventDeleteHorseButton.onClick(horse.id)} async="simple">
            <Tooltip content={t('general.delete')}>{eventDeleteHorseButton.icon}</Tooltip>
          </ButtonSimple>
        </EventParticipationItem>
      ) : (
        <EventParticipationItem modelItem={horse} />
      )}
    </div>
  ));

  const renderDeleteModeSwitcher = () => {
    return (
      <ButtonSimple onClick={() => setIsDeleteMode(prev => !prev)}>
        <div className="text-link font-bold">{t(`general.${isDeleteMode ? 'cancel' : 'delete'}`)}</div>
      </ButtonSimple>
    );
  };

  if (isMobile) {
    return (
      <FlexColumn separated="small">
        {eventPairParticipantsWithHorses && (
          <FlexColumn marginBottom>
            <Divider />
            <BoxWhite>
              <div className="text-teal">
                <ButtonSimple onClick={eventPairParticipantsWithHorses.onClick} async="simple">
                  <RowAligner className="text-highlight teal-hover">
                    {eventPairParticipantsWithHorses.icon}
                    <div>{eventPairParticipantsWithHorses.label}</div>
                  </RowAligner>
                </ButtonSimple>
              </div>
            </BoxWhite>
          </FlexColumn>
        )}
        <RowAligner className="justify-between">
          <TitleList>{t('eventPairs.unpairedHorses')}</TitleList>
          {eventDeleteHorseButton && <div className="button-container px-4 md:px-6">{renderDeleteModeSwitcher()}</div>}
        </RowAligner>
        {items}
        {addHorseButton}
      </FlexColumn>
    );
  }

  return (
    <FlexColumn>
      {eventPairParticipantsWithHorses ? (
        <FlexColumn>
          <Subtitle>{t('eventPairs.unpairedHorses')}</Subtitle>
          <div className="text-teal fill-teal">
            <ButtonSimple onClick={eventPairParticipantsWithHorses.onClick} async="simple">
              <RowAligner className="text-highlight teal-hover">
                {eventPairParticipantsWithHorses.icon}
                <div>{eventPairParticipantsWithHorses.label}</div>
              </RowAligner>
            </ButtonSimple>
          </div>
          {eventDeleteHorseButton && <div className="button-container float-right">{renderDeleteModeSwitcher()}</div>}
        </FlexColumn>
      ) : (
        <RowAligner className="justify-between">
          <Subtitle>{t('eventPairs.unpairedHorses')}</Subtitle>
          {eventDeleteHorseButton && <div className="button-container">{renderDeleteModeSwitcher()}</div>}
        </RowAligner>
      )}
      <Allow
        permissionKey={['participations.attach_horse']}
        permissionParams={{ modelItem: event }}
        additionalCheck={!event?.isDeleted()}
        fallback={
          <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap="md">
            {items}
            {addHorseButton}
          </Grid>
        }
      >
        <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap="md">
          {draggedItems}
          {addHorseButton}
        </Grid>
      </Allow>
    </FlexColumn>
  );
};

UnpairedHorsesList.defaultProps = {
  addHorseButton: null,
  eventDeleteHorseButton: null,
};

const mapStateToProps = ({ app: { isMobile, isTablet } }) => ({ isMobile, isTablet });
export default connect(mapStateToProps)(UnpairedHorsesList);
