import { useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from 'react-query';
import { TCacheKey } from 'resources/types/cacheTypes';
import { APIPagination } from 'resources/types/commonTypes';
import { IEventFetchParams, IEventRes } from 'resources/types/eventsTypes';
import { BillService, EventService } from 'services';
import Event from 'models/Event';
import { combineQueryData } from 'utils';
import { IProposalFetchParams, IProposalRes } from 'resources/types/proposalsTypes';
import { IBillFetchParams, IBillRes } from 'resources/types/billsTypes';
import Bill from 'models/Bill';

export interface IQueryData<TData> {
  data: TData[];
  pagination: APIPagination;
}

export const useFetchEvents = (key: TCacheKey, params: IEventFetchParams = {}, options = {}) => {
  const queryFn = async () => {
    const data = await EventService.fetchAll(params);
    return { data: data.events, pagination: data.pagination } as IQueryData<IEventRes>;
  };

  const stateQuery = useQuery(key, queryFn, options);

  return [stateQuery, stateQuery.data?.data.map(event => new Event(event))] as [UseQueryResult<IEventRes>, Event[]];
};

export const useFetchEventsInfinity = (queryKey: TCacheKey, params: IEventFetchParams = {}, options = {}) => {
  const queryFn = async ({ pageParam = 1 }: { pageParam?: number }) => {
    const data = await EventService.fetchAll({ ...params, page: pageParam });
    return { data: data.events, pageParam: data.pagination.next };
  };

  const stateQuery = useInfiniteQuery(queryKey, queryFn, {
    getNextPageParam: lastPage => {
      return lastPage.pageParam;
    },
    ...options,
  });

  const events = combineQueryData<IEventRes>(stateQuery.data?.pages);
  return [stateQuery, events.map(event => new Event(event))] as [UseInfiniteQueryResult<IEventRes>, Event[]];
};

export const useFetchProposalsInfinity = (queryKey: TCacheKey, params: IProposalFetchParams = {}, options = {}) => {
  const queryFn = async ({ pageParam = 1 }: { pageParam?: number }) => {
    const data = await EventService.fetchAllProposals({ ...params, page: pageParam });
    return { data: data.events, pageParam: data.pagination.next };
  };

  const stateQuery = useInfiniteQuery(queryKey, queryFn, {
    getNextPageParam: lastPage => {
      return lastPage.pageParam;
    },
    ...options,
  });

  const events = combineQueryData<IProposalRes>(stateQuery.data?.pages);
  return [stateQuery, events.map(event => new Event(event))] as [UseInfiniteQueryResult<IProposalRes>, Event[]];
};

export const useFetchBills = (
  key: TCacheKey | [TCacheKey, IBillFetchParams],
  params: IBillFetchParams = {},
  options = {}
) => {
  const queryFn = async () => {
    const data = await BillService.fetchAll(params);
    return { data: data.bills, pagination: data.pagination } as IQueryData<IBillRes>;
  };

  const stateQuery = useQuery(key, queryFn, options);

  return [stateQuery, stateQuery.data?.data.map(bill => new Bill(bill)) || []] as [
    UseQueryResult<IQueryData<IBillRes>>,
    Bill[]
  ];
};
