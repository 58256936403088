/* eslint-disable max-len */
import React from 'react';

const Logout = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M12.6,7.1l-2.3-2.3l1.2-1.2L16,8l-4.4,4.4l-1.2-1.2l2.3-2.3H4V7.1L12.6,7.1z M8,14.2H1.8V1.8H8V0H1.8C0.8,0,0,0.8,0,1.8l0,0v12.4c0,1,0.8,1.8,1.8,1.8l0,0H8V14.2z" />
    </svg>
  );
};

export default Logout;
/* eslint-enable max-len */
