import React, { useState } from 'react';
import Button from '@old/components/guide/Button';
import PageTitle from '@old/components/guide/PageTitle';
import FlexColumn from '@old/components/common/FlexColumn';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import ImageCircle from '@old/components/guide/ImageCircle';
import GroupButton from '@old/components/guide/GroupButton';
import FlexRow from '@old/components/common/FlexRow';
import RowAligner from '@old/components/common/RowAligner';
import Box from '@old/components/common/Box';
import Title from '@old/components/common/Title';
import TitleWhite from '@old/components/common/TitleWhite';
import Subtitle from '@old/components/common/Subtitle';
import SubtitleWhite from '@old/components/common/SubtitleWhite';
import FadeText from '@old/components/common/FadeText';
import Icon from '@old/components/icon';
import { Grid } from '@elements';
import ViewPager from '@old/components/guide/ViewPager';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import DragItem from '@old/components/common/DragItem';
import DndWrapper from '@old/components/common/DndWrapper';
import DropItem from '@old/components/common/DropItem';
import OverflowItem from '@old/components/common/OverflowItem';
import Tags from '@old/components/common/Tags';
import Indicator from '@old/components/common/Indicator';

const Guide = () => {
  const [dragAndDrop, setDragEndDrops] = useState([
    [
      { type: 'bio', color: '#653332' },
      { type: 'other', color: '#000000' },
      { type: 'metal', color: '#ef4921' },
      { type: 'paper', color: '#063896' },
      { type: 'plastics', color: '#faf032' },
      { type: 'glass', color: '#326c3b' },
    ],
    [
      { type: 'paper', label: 'Papier' },
      { type: 'bio', label: 'Żywność' },
      { type: 'paper', label: 'Karton' },
      { type: 'plastics', label: 'Plastik' },
      { type: 'glass', label: 'Szkło' },
      { type: 'metal', label: 'Metal' },
      { type: 'other', label: 'Zmieszane' },
    ],
  ]);

  const [tags, setTags] = useState(
    [
      { key: 1, label: 'Jakub Dziadek' },
      { key: 2, label: 'Michał Gawlik' },
      { key: 3, label: 'Filip Gędłek' },
      { key: 4, label: 'Filip Janusz' },
    ],
  );

  const deleteTag = (item) => {
    setTags(prevTags => prevTags.filter(tag => tag.key !== item.key));
  };

  const onDrop = (item) => {
    const rubbish = [...dragAndDrop[1]];
    const filteredRubbish = rubbish.filter(rubbishItem => rubbishItem.label !== item.key);
    setDragEndDrops(prevState => [prevState[0], filteredRubbish]);
  };

  const asyncOnClick = () => new Promise((resolve) => {
    setTimeout(() => resolve(true), 52000);
  });

  const onClick = () => {
    console.log('click'); // eslint-disable-line no-console
  };

  const Tab1 = () => <div>Tabs numer 1</div>;
  const Tab2 = () => <div>Tabs numer 1</div>;
  const Tab3 = () => <div>Tabs numer 1</div>;

  const tabs = [{
    name: 'Pierwszy Tabs',
    view: Tab1,
  }, {
    name: 'Drugi Tabs',
    view: Tab2,
  }, {
    name: 'Trzeci Tabs',
    view: Tab3,
  }];

  return (
    <div className="fixed bg-white left-0 top-0 w-full h-full overflow-hidden">
      <div className="h-full p-6 overflow-y-auto px-32">
        <FlexColumn>
          <PageTitle title="Guide" back />
          <Box>
            <FlexColumn>
              <Title>Tytuł</Title>
              <TitleWhite>Tytuł biały</TitleWhite>
              <Subtitle>Podtytuł</Subtitle>
              <SubtitleWhite>Podtytuł biały</SubtitleWhite>
              <FadeText>Szary tekst</FadeText>
              <span>Tekst domyślny</span>
            </FlexColumn>
          </Box>
          <Title>Przyciski</Title>
          <Box>
            <FlexColumn>
              <FlexRow cols="none/none/none/none">
                <Button onClick={onClick}>Przycisk</Button>
                <Button onClick={onClick} disabled>Przycisk Disabled</Button>
                <Button onClick={asyncOnClick} async>Przycisk asynchroniczny</Button>
                <Button onClick={asyncOnClick} async="simple">Prosty Przycisk asynchroniczny</Button>
              </FlexRow>

              <FlexRow cols="none/none/none/none">
                <ButtonOutline onClick={onClick}>Outline Przycisk</ButtonOutline>
                <ButtonOutline onClick={onClick} disabled>Outline Disabled Przycisk</ButtonOutline>
                <ButtonOutline onClick={asyncOnClick} async>Outline Przycisk asynchroniczny</ButtonOutline>
                <ButtonOutline onClick={asyncOnClick} async="simple">
                  Outline Prosty Przycisk asynchroniczny
                </ButtonOutline>
              </FlexRow>

              <FlexRow cols="none/none/none">
                <ButtonSimple onClick={onClick}>Simple Przycisk</ButtonSimple>
                <ButtonSimple onClick={onClick} disabled>Simple Disabled Przycisk</ButtonSimple>
                <ButtonSimple onClick={asyncOnClick} async>Simple Przycisk asynchroniczny</ButtonSimple>
              </FlexRow>

              <Title>Grupa przycisków</Title>
              <FlexRow cols="none/none/none">
                <GroupButton vertical>
                  <ButtonOutline onClick={onClick}>A</ButtonOutline>
                  <Button onClick={asyncOnClick}>B</Button>
                  <ButtonOutline onClick={onClick}>C</ButtonOutline>
                </GroupButton>

                <span>Wertykalne grupowanie przycisków - przyjmuje wysokość kontenera</span>

                <GroupButton>
                  <ButtonOutline onClick={onClick}>A</ButtonOutline>
                  <ButtonOutline onClick={asyncOnClick}>B</ButtonOutline>
                  <Button onClick={onClick}>C</Button>
                </GroupButton>
              </FlexRow>
            </FlexColumn>
          </Box>
          <Title>Awatary</Title>
          <Box>
            <FlexColumn>
              <FlexRow>
                <ImageCircle src="img/user-avatar-placeholder.png" large />
                <p>np. w szczegółach</p>

                <ImageCircle src="img/user-avatar-placeholder.png" big />
                <p>np. lista uzytkownikow i koni</p>

                <ImageCircle src="img/user-avatar-placeholder.png" />
                <p>np. na liscie uczestników</p>

                <ImageCircle src="img/user-avatar-placeholder.png" small />
                <p>np. do selectów i list</p>
              </FlexRow>
            </FlexColumn>
          </Box>
          <Title>Ikony</Title>
          <Box>

            <Grid gridTemplateColumns="repeat(4, 1fr)" gridGap="md">
              <RowAligner>
                <Icon.Star /> <span>Star</span>
              </RowAligner>
              <RowAligner>
                <Icon.ArrowPaginationDoubleLeft /> <span>ArrowPaginationDoubleRight</span>
              </RowAligner>
              <RowAligner>
                <Icon.ArrowPaginationDoubleRight /> <span>ArrowPaginationDoubleRight</span>
              </RowAligner>
              <RowAligner>
                <Icon.ArrowLeft /> <span>ArrowLeft</span>
              </RowAligner>
              <RowAligner>
                <Icon.ArrowRight /> <span>ArrowRight</span>
              </RowAligner>
              <RowAligner>
                <Icon.Shift /> <span>Shift</span>
              </RowAligner>
              <RowAligner>
                <Icon.ArrowUp /> <span>ArrowUp</span>
              </RowAligner>
              <RowAligner>
                <Icon.Shift /> <span>Shift</span>
              </RowAligner>
              <RowAligner>
                <Icon.ArrowDown /> <span>ArrowDown</span>
              </RowAligner>
              <RowAligner>
                <Icon.Availability /> <span>Availability</span>
              </RowAligner>
              <RowAligner>
                <Icon.Bill /> <span>Bill</span>
              </RowAligner>
              <RowAligner>
                <Icon.Block /><span>Block</span>
              </RowAligner>
              <RowAligner>
                <Icon.Calendar /> <span>Calendar</span>
              </RowAligner>
              <RowAligner>
                <Icon.CalendarShadow /> <span>CalendarShadow</span>
              </RowAligner>
              <RowAligner>
                <Icon.CalendarChecked /> <span>CalendarChecked</span>
              </RowAligner>
              <RowAligner>
                <Icon.CalendarList /> <span>CalendarList</span>
              </RowAligner>
              <RowAligner>
                <Icon.CalendarPlus /> <span>CalendarPlus</span>
              </RowAligner>
              <RowAligner>
                <Icon.Camera /> <span>Camera</span>
              </RowAligner>
              <RowAligner>
                <Icon.Cancel /> <span>Cancel</span>
              </RowAligner>
              <RowAligner>
                <Icon.CancelOutline /> <span>CancelOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.Check /><span>Check</span>
              </RowAligner>
              <RowAligner>
                <Icon.Checked /> <span>Checked</span>
              </RowAligner>
              <RowAligner>
                <Icon.CheckList /> <span>CheckList</span>
              </RowAligner>
              <RowAligner>
                <Icon.ClockCircularOutline /> <span>ClockCircularOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.Coin /><span>Coin</span>
              </RowAligner>
              <RowAligner>
                <Icon.CoinCircle /><span>CoinCircle</span>
              </RowAligner>
              <RowAligner>
                <Icon.Delete /> <span>Delete</span>
              </RowAligner>
              <RowAligner>
                <Icon.DeleteOutline /> <span>DeleteOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.InfoOutline /> <span>InfoOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.WrongSms /> <span>WrongSms</span>
              </RowAligner>
              <RowAligner>
                <Icon.OkSms /> <span>OkSms</span>
              </RowAligner>
              <RowAligner>
                <Icon.Doc /> <span>Doc</span>
              </RowAligner>
              <RowAligner>
                <Icon.Edit /> <span>Edit</span>
              </RowAligner>
              <RowAligner>
                <Icon.EditOutline /> <span>EditOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.EmptyCalendar /> <span>EmptyCalendar</span>
              </RowAligner>
              <RowAligner>
                <Icon.Events /><span>Events</span>
              </RowAligner>
              <RowAligner>
                <Icon.EventType /> <span>EventType</span>
              </RowAligner>
              <RowAligner>
                <Icon.Farm /><span>Farm</span>
              </RowAligner>
              <RowAligner>
                <Icon.Filter /><span>Filter</span>
              </RowAligner>
              <RowAligner>
                <Icon.History /> <span>History</span>
              </RowAligner>
              <RowAligner>
                <Icon.HistoryOutline /><span>HistoryOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.Home /><span>Home</span>
              </RowAligner>
              <RowAligner>
                <Icon.Horse /><span>Horse</span>
              </RowAligner>
              <RowAligner>
                <Icon.HorseAlone /><span>HorseAlone</span>
              </RowAligner>
              <RowAligner>
                <Icon.Info /> <span>Info</span>
              </RowAligner>
              <RowAligner>
                <Icon.Invite /> <span>Invite</span>
              </RowAligner>
              <RowAligner>
                <Icon.InviteUser /> <span>InviteUser</span>
              </RowAligner>
              <RowAligner>
                <Icon.InviteHeart /> <span>InviteHeart</span>
              </RowAligner>
              <RowAligner>
                <Icon.InviteHorseShoe /> <span>InviteHorseShoe</span>
              </RowAligner>
              <RowAligner>
                <Icon.Logout /> <span>Logout</span>
              </RowAligner>
              <RowAligner>
                <Icon.Mail /> <span>Mail</span>
              </RowAligner>
              <RowAligner>
                <Icon.MailOutline /> <span>MailOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.MoreHorizontal /> <span>MoreHorizontal</span>
              </RowAligner>
              <RowAligner>
                <Icon.MoreHorizontalOutline /> <span>MoreHorizontalOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.MoreVertical /><span>MoreVertical</span>
              </RowAligner>
              <RowAligner>
                <Icon.News /> <span>News</span>
              </RowAligner>
              <RowAligner>
                <Icon.NewsOutline /><span>NewsOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.Place /> <span>Place</span>
              </RowAligner>
              <RowAligner>
                <Icon.Add /> <span>Add</span>
              </RowAligner>
              <RowAligner>
                <Icon.AddOutline /> <span>AddOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.Proposals /> <span>Proposals</span>
              </RowAligner>
              <RowAligner>
                <Icon.ProposalsOutline /><span>ProposalsOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.CodeQR /> <span>CodeQR</span>
              </RowAligner>
              <RowAligner>
                <Icon.Save /> <span>Save</span>
              </RowAligner>
              <RowAligner>
                <Icon.Schedule /> <span>Schedule</span>
              </RowAligner>
              <RowAligner>
                <Icon.Search /> <span>Search</span>
              </RowAligner>
              <RowAligner>
                <Icon.SelectFarm /> <span>SelectFarm</span>
              </RowAligner>
              <RowAligner>
                <Icon.Settings /> <span>Settings</span>
              </RowAligner>
              <RowAligner>
                <Icon.Sort /><span>Sort</span>
              </RowAligner>
              <RowAligner>
                <Icon.Unchecked /> <span>Unchecked</span>
              </RowAligner>
              <RowAligner>
                <Icon.UncheckedOutline /> <span>UncheckedOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.User /> <span>User</span>
              </RowAligner>
              <RowAligner>
                <Icon.AddUser /> <span>AddUser</span>
              </RowAligner>
              <RowAligner>
                <Icon.Users /> <span>Users</span>
              </RowAligner>
              <RowAligner>
                <Icon.VisibilityOn /> <span>VisibilityOn</span>
              </RowAligner>
              <RowAligner>
                <Icon.VisibilityOff /> <span>VisibilityOff</span>
              </RowAligner>
              <RowAligner>
                <Icon.Warning /> <span>Warning</span>
              </RowAligner>
              <RowAligner>
                <Icon.WarningTriangle /><span>WarningTriangle</span>
              </RowAligner>
              <RowAligner>
                <Icon.WarningDiamond /> <span>WarningDiamond</span>
              </RowAligner>
              <RowAligner>
                <Icon.WarningOutline /> <span>WarningOutline</span>
              </RowAligner>
              <RowAligner>
                <Icon.ZoomIn /><span>ZoomIn</span>
              </RowAligner>
              <RowAligner>
                <Icon.ZoomOut /><span>ZoomOut</span>
              </RowAligner>
              <RowAligner>
                <Icon.Spinner /><span>Spinner</span>
              </RowAligner>
              <RowAligner>
                <Icon.Send /><span>Send</span>
              </RowAligner>
              <RowAligner>
                <Icon.Drag /><span>Drag</span>
              </RowAligner>
              <RowAligner>
                <Icon.UserCircular /><span>UserCircular</span>
              </RowAligner>
              <RowAligner>
                <Icon.HorseAloneCircular /><span>HorseAloneCircular</span>
              </RowAligner>
              <RowAligner>
                <Icon.AddHorse /><span>AddHorse</span>
              </RowAligner>
              <RowAligner>
                <Icon.Www /><span>Www</span>
              </RowAligner>
              <RowAligner>
                <Icon.Phone /><span>Phone</span>
              </RowAligner>
              <RowAligner>
                <Icon.Policy /><span>Policy</span>
              </RowAligner>
              <RowAligner>
                <Icon.NumberUsers /><span>NumberUsers</span>
              </RowAligner>
              <RowAligner>
                <Icon.EventLevel /><span>EventLevel</span>
              </RowAligner>
              <RowAligner>
                <Icon.LinkAnchor /><span>LinkAnchor</span>
              </RowAligner>
              <RowAligner>
                <Icon.LinkAnchorCircular /><span>LinkAnchorCircular</span>
              </RowAligner>
              <RowAligner>
                <Icon.List /><span>List</span>
              </RowAligner>
              <RowAligner>
                <Icon.Underline /><span>Underline</span>
              </RowAligner>
              <RowAligner>
                <Icon.Italic /><span>Italic</span>
              </RowAligner>
              <RowAligner>
                <Icon.ListOrdered /><span>ListOrdered</span>
              </RowAligner>
              <RowAligner>
                <Icon.Bold /><span>Bold</span>
              </RowAligner>
              <RowAligner>
                <Icon.Quote /><span>Quote</span>
              </RowAligner>
              <RowAligner>
                <Icon.Sms /><span>Sms</span>
              </RowAligner>
              <RowAligner>
                <Icon.PhoneSmsSmall /><span>PhoneSmsSmall</span>
              </RowAligner>
              <RowAligner>
                <Icon.PhoneSms /><span>PhoneSms</span>
              </RowAligner>
              <RowAligner>
                <Icon.PhoneSmsLarge /><span>PhoneSmsLarge</span>
              </RowAligner>
              <RowAligner>
                <Icon.Dash /><span>Dash</span>
              </RowAligner>
              <RowAligner>
                <Icon.PaymentSettings /> <span>PaymentSettings</span>
              </RowAligner>
            </Grid>
          </Box>

          <Title>Ikony - rozmiary</Title>
          <Box>
            <RowAligner>
              <Icon.Cancel big />
              <Icon.Cancel />
              <Icon.Cancel small />
            </RowAligner>
          </Box>

          <Title>Ikony - kolory</Title>
          <Box>
            <RowAligner>
              <a href="#url" className="text-link"> <Icon.Cancel big /> </a>
              <Icon.Cancel className="fill-grey" big />
              <Icon.Cancel className="fill-teal-dark" big />
              <Icon.Cancel big />
            </RowAligner>
          </Box>

          <Title>Okrągłe ikony</Title>
          <Box>
            <RowAligner>
              <Icon.User circle small />
              <Icon.VisibilityOn circle />
              <Icon.VisibilityOff circle big />
            </RowAligner>
          </Box>

          <Title>Ikony w przyciskach</Title>
          <Box>
            <RowAligner>
              <Button onClick={onClick}><Icon.Cancel /></Button>
              <ButtonOutline onClick={onClick}><Icon.Cancel /></ButtonOutline>
              <ButtonSimple onClick={onClick}><Icon.Cancel /></ButtonSimple>
              <OverflowItem><Icon.Cancel /></OverflowItem>
            </RowAligner>
          </Box>

          <Title>Tagi</Title>
          <Tags items={tags} onDelete={deleteTag} />

          <Title>Indicator</Title>
          <RowAligner>
            <Indicator />
            <Indicator active />
          </RowAligner>

          <Title>RowAligner</Title>
          <Box>
            <RowAligner>
              <div>
                <ImageCircle src="img/user-avatar-placeholder.png" big />
              </div>
              <div>wszystko w jednej linii</div>
              <ButtonOutline onClick={onClick}>Przycisk</ButtonOutline>
            </RowAligner>
          </Box>
          <Title>Wrapery</Title>

          <Subtitle>FlexRow</Subtitle>

          <FlexRow marginBottom>
            <Box><div style={{ height: 80 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
            <Box><div style={{ height: 80 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
          </FlexRow>

          <Subtitle>FlexRow marginBottom small separated small</Subtitle>
          <FlexRow marginBottom="small" separated="small">
            <Box><div style={{ height: 80 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
            <Box><div style={{ height: 80 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
          </FlexRow>

          <Subtitle>FlexRow streched</Subtitle>
          <FlexRow marginBottom stretched>
            <Box><div style={{ height: 80 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
            <Box><div style={{ height: 80 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
          </FlexRow>

          <Subtitle>FlexRow cols 2/3</Subtitle>
          <FlexRow marginBottom cols="2/3">
            <Box><div style={{ height: 80 }}>2/5</div></Box>
            <Box><div style={{ height: 30 }}>3/5</div></Box>
          </FlexRow>

          <Subtitle>FlexRow alignCenter</Subtitle>
          <FlexRow marginBottom alignCenter>
            <Box><div style={{ height: 80 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
            <Box><div style={{ height: 80 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
            <Box><div style={{ height: 30 }}>1/5</div></Box>
          </FlexRow>

          <Subtitle>FlexRow cols 1/2 alignCenter</Subtitle>
          <FlexRow marginBottom cols="1/2" alignCenter>
            <Box><div style={{ height: 80 }}>1/3</div></Box>
            <Box><div style={{ height: 30 }}>2/3</div></Box>
          </FlexRow>

          <Subtitle>FlexRow stała szerokość</Subtitle>
          <FlexRow marginBottom cols="1/none" alignCenter>
            <Box><div style={{ height: 80 }}>fill</div></Box>
            <Box><div style={{ height: 30, width: 500 }}>540px</div></Box>
          </FlexRow>

          <Subtitle>FlexRow without margin</Subtitle>
          <FlexRow>
            <Box><div style={{ height: 80 }}>1/2</div></Box>
            <Box><div style={{ height: 30 }}>1/2</div></Box>
          </FlexRow>

          <Subtitle>FlexRow !separated</Subtitle>
          <FlexRow marginBottom separated={false}>
            <Box><div style={{ height: 80 }}>1/2</div></Box>
            <Box><div style={{ height: 30 }}>1/2</div></Box>
          </FlexRow>

          <Subtitle>FlexColumn</Subtitle>
          <FlexColumn>
            <Box><div style={{ height: 80 }}>FlexColumn</div></Box>
            <Box><div style={{ height: 80 }}>FlexColumn</div></Box>
          </FlexColumn>

          <Subtitle>FlexColumn separated small</Subtitle>
          <FlexColumn separated="small">
            <Box><div style={{ height: 80 }}>FlexColumn</div></Box>
            <Box><div style={{ height: 80 }}>FlexColumn</div></Box>
          </FlexColumn>

          <Subtitle>FlexColumn !separated</Subtitle>
          <FlexColumn separated={false}>
            <Box><div style={{ height: 80 }}>FlexColumn</div></Box>
            <Box><div style={{ height: 80 }}>FlexColumn</div></Box>
          </FlexColumn>

          <PageTitle title="Przykładowe komponenty" />
          <Subtitle>Nagłówek kalendarza</Subtitle>

          <FlexRow marginBottom cols="none/1/none" noMargin alignCenter stretched>
            <ButtonOutline onClick={onClick}>Dzisiaj</ButtonOutline>
            <RowAligner className="justify-start">
              <ButtonSimple onClick={onClick}><Icon.ArrowLeft className="fill-teal" /></ButtonSimple>
              <span>28 maja 2019, czwartek</span>
              <ButtonSimple onClick={onClick}><Icon.ArrowRight className="fill-teal" /></ButtonSimple>
            </RowAligner>
            <GroupButton>
              <ButtonOutline onClick={onClick}>D</ButtonOutline>
              <ButtonOutline onClick={onClick}>T</ButtonOutline>
              <Button onClick={asyncOnClick}>MIESIĄC</Button>
            </GroupButton>
          </FlexRow>
          <Subtitle>Tabsy</Subtitle>
          <ViewPager tabs={tabs} />

          <Title>Drag&Drop</Title>
          <DndWrapper>
            <FlexColumn>
              <FlexRow>
                {dragAndDrop[0].map(trash => (
                  <DropItem key={trash.type} acceptType={trash.type} onDrop={onDrop}>
                    <div className="h-24 w-full" style={{ backgroundColor: trash.color }} />
                  </DropItem>
                ))}
              </FlexRow>
              <FlexRow>
                {dragAndDrop[1].map(rubbish => (
                  <DragItem key={rubbish.label} dragState={{ key: rubbish.label, type: rubbish.type }}>
                    <div className="bg-grey-light px-4 py-2">{rubbish.label}</div>
                  </DragItem>
                ))}
              </FlexRow>
            </FlexColumn>
          </DndWrapper>
        </FlexColumn>
      </div>
    </div>
  );
};

export default Guide;
