/**
 * @module CheckboxInput
 */

import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import Icon from '../icon';
import RowAligner from '../common/RowAligner';

/**
 * Component that return checkbox input
 * @param  {Function} props.onChange
 * @param  {String|Boolean|Numer} [props.value]
 * @param  {String} props.label
 * @param  {String} [props.name]
 * @param  {Boolean} [props.required]
 */
const CheckboxInput = ({ onChange, value, label, name, required, clickableWrapper }) => {
  const onClick = () => {
    onChange(!value, name, label);
  };

  const isChecked = value || false;

  return (
    <div
      className="flex items-start cursor-pointer mb-3"
      style={{ maxW: 'max-content' }}
      onClick={clickableWrapper ? onClick : () => {}}
    >
      <RowAligner separated={false}>
        <div
          className={cls('checkbox-wrapper', { isChecked })}
          style={{ minWidth: 22, minHeight: 22 }}
          onClick={onClick}
        >
          <button type="button" onClick={onClick} className="opacity-0 absolute" />
          <input
            type="checkbox"
            checked={isChecked}
            name={name}
            onChange={onClick}
            hidden
          />
          {isChecked && <Icon.Check small className="fill-white absolute" />}
        </div>
        <div className="ml-2">
          <div className={cls('font-light hover:text-teal-light', { 'text-teal-dark': isChecked })}>
            {label}
            {required && <span className="asterisk mr-1" />}
          </div>
        </div>
      </RowAligner>
    </div>
  );
};

CheckboxInput.defaultProps = {
  value: null,
  name: null,
  required: false,
  clickableWrapper: true,
};

CheckboxInput.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  required: PropTypes.bool,
  clickableWrapper: PropTypes.bool,
};

export default CheckboxInput;
