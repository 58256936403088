import moment from 'moment';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import Box from '@old/components/common/Box';
import ImageCircle from '@old/components/guide/ImageCircle';
import t from 'resources/translations';
import RenderDate from '@old/components/old/RenderDate';
import FlexColumn from '@old/components/common/FlexColumn';
import Icon from '@old/components/icon';
import RowAligner from '@old/components/common/RowAligner';
import FlexRow from '@old/components/common/FlexRow';
import BoxWhite from '@old/components/common/BoxWhite';
import DateLabel from '@old/components/custom/DateLabel';
import config from '@old/config';
import { Member } from '@old/model/Member';

const ChangeHistoryListItem = ({ version, isMobile }) => {
  const renderHistoryBlock = (versionEvent, type) => {
    switch (type) {
      case 'createEvent':
      case 'createHorse':
      case 'createPlace':
      case 'createInstructor':
        return <RenderBlock context={renderBlock(versionEvent)} iconName="Add" />;
      case 'destroyPlace':
      case 'destroyHorse':
      case 'destroyInstructor':
        return <RenderBlock context={renderBlock(versionEvent)} iconName="Delete" />;
      case 'updateEvent':
        return <RenderBlock context={renderUpdateBlock(versionEvent)} iconName="Edit" />;
      case 'updateParticipation':
        let icon = '';
        if (versionEvent.changes.horse) icon = 'Events';
        else if (versionEvent.changes.payment_status) icon = 'Coin';
        else if (versionEvent.changes.paid_at) icon = 'Coin';
        else if (versionEvent.changes.attendance) icon = 'Check';
        else icon = 'User';
        return (
          <RenderBlock
            context={renderUpdateBlock(versionEvent)}
            iconName={icon}
            memberName={versionEvent.member.getFullName()}
          />
        );
      default:
        return null;
    }
  };

  const renderUpdateBlock = versionEvent =>
    Object.entries(versionEvent.changes).map(change => {
      const key = change[0];
      const changeFrom = change[1][0];
      const changeTo = change[1][1];
      const nodeKey = `${key}-${changeFrom}-${versionEvent.id}`;

      let label = null;
      let from = null;
      let to = null;
      let prevHorse = '';
      let nextHorse = '';
      let customContent = null;

      switch (key) {
        case 'special':
          label = t(`keys.${key}`);
          from = changeFrom ? t('general.yes') : t('general.no');
          to = changeTo ? t('general.yes') : t('general.no');
          break;
        case 'status':
          label = t(`keys.${key}`);
          from = t(`changeHistoryListItem.status.${changeFrom}`);
          to = t(`changeHistoryListItem.status.${changeTo}`);
          break;
        case 'visibility':
          label = t('labels.visibility');
          from = t(`changeHistoryListItem.visibility.${changeFrom}`);
          to = t(`changeHistoryListItem.visibility.${changeTo}`);
          break;
        case 'start_at':
          label = t(`keys.${key}`);
          from = <RenderDate startDate={moment(changeFrom)} />;
          to = <RenderDate startDate={moment(changeTo)} />;
          break;
        case 'end_at':
          label = t(`keys.${key}`);
          from = <RenderDate startDate={moment(changeFrom)} />;
          to = <RenderDate startDate={moment(changeTo)} />;
          break;
        case 'event_type':
          label = t(`keys.${key}`);
          to = change[1] ? change[1].name : t('model.delete.eventTypes');
          break;
        case 'attendance':
          label = t(`keys.${key}`);
          from = t(`changeHistoryListItem.attendance.${changeFrom}`);
          to = t(`changeHistoryListItem.attendance.${changeTo}`);
          break;
        case 'paid_amount':
          label = t(`keys.${key}`);
          from = changeFrom;
          to = changeTo;
          break;
        case 'paid_at':
          label = t(`keys.${key}`);
          from = changeFrom ? <RenderDate startDate={moment(changeFrom)} /> : '';
          to = changeTo ? <RenderDate startDate={moment(changeTo)} /> : '';
          break;
        case 'payment_status':
          label = t(`keys.${key}`);
          from = t(`paymentStatus.${changeFrom || 'missing'}`);
          to = t(`paymentStatus.${changeTo || 'missing'}`);
          break;
        case 'difficulty':
          label = t(`keys.${key}`);
          from = t(`skills.${changeFrom || 'missing'}`);
          to = t(`skills.${changeTo}`);
          break;
        case 'instructor':
          label = t(`keys.${key}`);
          to = `${change[1] ? `${change[1].firstname} ${change[1].lastname}` : t('changeHistoryListItem.userDeleted')}`;
          break;
        case 'horse':
          label = t('eventPairs.paired');
          if (change[1][0]) {
            prevHorse = change[1][0];
          }
          if (change[1][1]) {
            nextHorse = change[1][1];
          }

          if (prevHorse && !nextHorse) {
            label = t('eventPairs.unpaired');
            customContent = <span>{prevHorse.name}</span>;
          }

          if (!prevHorse && nextHorse) {
            customContent = <span>{nextHorse.name}</span>;
          }

          if (prevHorse && nextHorse) {
            from = prevHorse.name;
            to = nextHorse.name;
          }
          break;
        case 'reminder_sent':
          label = t('changeHistoryListItem.notifications');
          customContent = <span>{t('message.reminderEventSent')}</span>;
          break;
        default:
          label = t(`keys.${key}`);
          from = changeFrom;
          to = changeTo;
          break;
      }

      return !isMobile ? (
        <div key={nodeKey}>
          <span className="text-highlight mr-2">{label}:</span>
          <span className="text self-start">
            <>
              <span>{from}</span>
              {!customContent ? (
                <>
                  {from && to && <span className="mx-2">{'>>'}</span>}
                  <span>{to}</span>
                </>
              ) : (
                customContent
              )}
            </>
          </span>
        </div>
      ) : (
        <div key={nodeKey}>
          <div className="text-highlight" style={{ minWidth: 70 }}>
            {label}:
          </div>
          <div className="text self-start">
            <span>{from}</span>
            {!customContent ? (
              <>
                {from && to ? (
                  <span>
                    {' >> '}
                    {to}
                  </span>
                ) : (
                  <span>{to}</span>
                )}
              </>
            ) : (
              customContent
            )}
          </div>
        </div>
      );
    });

  const renderBlock = versionEvent =>
    Object.entries(versionEvent.changes).map(change => {
      if (['end_at', 'attendees_max'].includes(change[0])) return null;

      const key = change[0];
      const changeTo = change[1];
      const endAt = versionEvent.changes.end_at || null;
      const attendeesMax = versionEvent.changes.attendees_max || null;
      const nodeKey = `${key}-${changeTo}-${versionEvent.id}`;
      let label = null;
      let value = null;

      switch (key) {
        case 'special':
          label = t(`keys.${key}`);
          value = changeTo ? t('general.yes') : t('general.no');
          break;
        case 'status':
          label = t(`keys.${key}`);
          value = t(`changeHistoryListItem.status.${changeTo}`);
          break;
        case 'visibility':
          label = t(`keys.${key}`);
          value = t(`changeHistoryListItem.visibility.${changeTo}`);
          break;
        case 'difficulty':
          label = t(`keys.${key}`);
          value = t(`skills.${changeTo || 'missing'}`);
          break;
        case 'start_at':
          label = t('changeHistoryListItem.date');
          value = <RenderDate startDate={moment(changeTo)} endDate={moment(endAt)} />;
          break;
        case 'attendees_min':
          label = t('changeHistoryListItem.numberParticipants');
          value = `od ${changeTo} do ${attendeesMax}`;
          break;
        case 'event_type':
          label = t(`keys.${key}`);
          value = changeTo ? changeTo.name : t('model.delete.eventTypes');
          break;
        case 'instructor':
          const user =
            changeTo &&
            changeTo.identity &&
            new Member(
              {
                ...changeTo.identity,
                membership: omit(changeTo, 'identity'),
              },
              { fromUserData: true }
            ); // fixme after api will send new object(membership in identity, now its identity in membership)
          label = t(`keys.${key}`);
          value = user.getFullName();
          break;
        case 'place':
          label = t(`keys.${key}`);
          value = changeTo ? changeTo.name : t('model.delete.places');
          break;
        case 'horse':
          label = t(`keys.${key}`);
          value = changeTo ? changeTo.name : t('model.delete.horses');
          break;
        default:
          label = t(`keys.${key}`);
          value = changeTo;
          break;
      }
      if (value) {
        return !isMobile ? (
          <div key={nodeKey}>
            <span className="text-highlight mr-2">{label}:</span>
            <span>{value}</span>
          </div>
        ) : (
          <div key={nodeKey}>
            <div className="text-highlight">{label}:</div>
            <div>{value}</div>
          </div>
        );
      }

      return null;
    });

  let itemType = null;

  switch (version.model) {
    case 'Event::EventsHorse':
      itemType = 'Horse';
      break;
    case 'Event::EventsPlace':
      itemType = 'Place';
      break;
    case 'Event::EventsInstructor':
      itemType = 'Instructor';
      break;
    default:
      itemType = version.model;
      break;
  }

  const type = version.action + itemType;
  const moderatorNotExists = version.moderator && version.moderator.firstname;
  const { dateTimeFormat } = config;
  return (
    <FlexRow cols="none/1">
      {isMobile ? (
        <DateLabel date={moment(version.createdAt).format(dateTimeFormat)} />
      ) : (
        <div style={{ width: 200, minWidth: 200 }}>
          <FlexColumn>
            <BoxWhite padding>
              <div className="square-small absolute" />
              <RenderDate
                className="text-title-md text-right float-right"
                withWeekDay
                startDate={moment(version.createdAt)}
              />
            </BoxWhite>
          </FlexColumn>
        </div>
      )}
      <Box>
        <FlexColumn>
          {moderatorNotExists ? (
            <div separated="small">
              {!isMobile ? (
                <RowAligner>
                  <ImageCircle src={version.moderator.getAvatar('thumb')} small />
                  <span className="text-title-md">
                    {t('eventDetails.editedBy')}: {version.moderator.getName()}
                  </span>
                </RowAligner>
              ) : (
                <>
                  <div className="text-title-md">
                    {t('eventDetails.editedBy')}: {version.moderator.getName()}
                  </div>
                  <RenderDate className="text" startDate={version.createdAt} />
                </>
              )}
            </div>
          ) : (
            <span className="text-title-md">System</span>
          )}
          <FlexRow>{renderHistoryBlock(version, type)}</FlexRow>
        </FlexColumn>
      </Box>
    </FlexRow>
  );
};

const mapStateToProps = ({ app: { isMobile } }) => ({
  isMobile,
});

export default connect(mapStateToProps)(ChangeHistoryListItem);

const RenderBlock = ({ iconName, context, memberName }) => {
  const IconComponent = Icon[iconName];
  return (
    <FlexRow cols="none/1" noShrink>
      <div className="w-6 pt-1 center text-center border-r border-grey h-full">
        <IconComponent small className="fill-teal text-teal" />
      </div>
      <FlexColumn separated="small">
        {memberName && <span className="font-bold">{memberName} </span>}
        {context}
      </FlexColumn>
    </FlexRow>
  );
};
RenderBlock.defaultProps = {
  memberName: '',
};
