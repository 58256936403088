import { Flex } from '../../elements';
import { TFlexProps } from 'resources/types/elementsTypes';
import { TReactNode } from 'resources/types/commonTypes';

export type TCard = TFlexProps & {
  children: TReactNode;
};

const Card = ({ children, ...flexProps }: TCard) => {
  return (
    <Flex radius={['sm', 'base']} bg="white" flexDirection="column" boxShadow="base" {...flexProps}>
      {children}
    </Flex>
  );
};

export default Card;
