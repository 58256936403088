import React from 'react';
import PropTypes from 'prop-types';

import t from 'resources/translations';
import { FacebookIcon, InstagramIcon, LogoHmIcon } from 'old/assets/svg';
import BoxWhite from '@old/components/common/BoxWhite';
import InputWrapper from '@old/components/common/InputWrapper';
import FlexColumn from '@old/components/common/FlexColumn';
import Center from '@old/components/common/Center';

const EmailPreview = ({ farmName }) => {
  return (
    <div>
      <InputWrapper className="max-w-sm" label={t('formInvitationEmails.previewMessage')}>
        <div className='p-4'>
          <BoxWhite padding>
            <div
              className='bg-no-repeat'
              style={{
                backgroundImage: 'url(/img/horseshoe-left.png), url(/img/horseshoe-right.png)',
                backgroundPosition: '-6% 34%, 106% 72%',
                backgroundSize: '30%, 32%',
              }}
            >
              <FlexColumn>
                <div className="float-right">
                  <LogoHmIcon width={50} height={50} color="teal" />
                </div>
                <div className="text-xl font-bold text-teal">
                  {t('emailPreview.invitationMailTitle')}
                </div>
                <div className="text-sm">
                  <p>
                    {t('emailPreview.invitationMailContentInfoFirst')}
                    <span className="text-teal font-bold uppercase"> {farmName}</span>
                    {t('emailPreview.invitationMailContentInfoSecond')}
                  </p>
                  <div
                    className="uppercase rounded bg-teal text-white px-4 py-2"
                    style={{ marginTop: 30, marginBottom: 30, maxWidth: 'max-content', fontSize: 12 }}
                  >
                    {t('emailPreview.invitationMailAccept')}
                  </div>
                  <p>
                    {t('emailPreview.invitationMailContent')}
                  </p>
                  <div>
                    {t('emailPreview.invitationMailContentFoot')}
                  </div>
                  <div>
                    {t('emailPreview.invitationMailContentFoot2')}
                  </div>
                  <div className="inline-flex">
                    <FacebookIcon width={30} height={30} />
                    <InstagramIcon width={30} height={30} />
                  </div>
                </div>
              </FlexColumn>
            </div>
          </BoxWhite>
          <Center className="mt-2">
            <FlexColumn separated="small">
              <div>
                {t('emailPreview.invitationMailFooterFirst')}
              </div>
              <div>
                {t('emailPreview.invitationMailFooterLast')}
              </div>
            </FlexColumn>
          </Center>
        </div>
      </InputWrapper>
    </div>
  );
};

EmailPreview.defaultProps = {
  farmName: '',
};

EmailPreview.propTypes = {
  farmName: PropTypes.string,
};

export default EmailPreview;
