/**
 * @module ConfirmModal
 */
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import Modal from '@old/components/common/Modal';
import t from 'resources/translations';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import FlexColumn from '@old/components/common/FlexColumn';
import { isAllowed, parseMarkdownToHTML } from 'old/utils';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import { useDeleteFarmPolicyButton } from 'old/buttonHooks';
import { Image, Text, Stack, Box } from '@elements';
import { Divider, Wrap } from '@combinations';
import config from '@old/config';

/**
 * A component that displays farm policy in modal.
 * @param  {Function} props.onClose  A function that closes the modal
 * @param  {String} [props.farmPolicy] Content of modal, farm policy in markdown format
 */

const FarmPolicyModal = ({ onClose, farmPolicy, farm }) => {
  const deletePolicy = useDeleteFarmPolicyButton();

  return (
    <Modal header={t('showPolicyModal.farmPolicy')} onClose={onClose} isOpen>
      <SimpleBar style={{ maxHeight: 'calc(100vh - 240px)', paddingRight: 20 }}>
        <Box overflow="hidden">
          {farmPolicy && (
            <FlexColumn>
              {deletePolicy && (
                <ButtonSimple onClick={deletePolicy.onClick}>
                  <div className="text-link font-bold">{deletePolicy.label}</div>
                </ButtonSimple>
              )}
              <div
                className="text-content"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: parseMarkdownToHTML(farmPolicy) }}
              />
            </FlexColumn>
          )}
          {farm.isAdvancedPaymentEnabled() && (
            <>
              <Divider color="new-grey" my="sm" />
              <Stack>
                <Text fontSize="sm" fontWeight="medium" color="grey-dark">
                  {t('farmDetails.bmPayments')}
                </Text>
                <Wrap spacing="xs">
                  {config.bmPaymentIcons.map((bmPayment, index) => (
                    <Image key={index} src={bmPayment.image} width="50px" />
                  ))}
                </Wrap>
              </Stack>
            </>
          )}
        </Box>
      </SimpleBar>
      {isAllowed('farm_policy.update') ? (
        <FlexRow separated="small" noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Link to="/policy/edit" className="btn">
            {t('general.edit')}
          </Link>
        </FlexRow>
      ) : (
        <ButtonOutline onClick={onClose} fluid>
          {t('general.cancel')}
        </ButtonOutline>
      )}
    </Modal>
  );
};

export default FarmPolicyModal;
