import React from 'react';
import moment from 'moment';
import { Card, CardContent, CardHeader } from 'components/combinations';
import { Content, Flex, Grid, Stack, Text } from 'components/elements';
import Message from 'old/components/common/Message';
import BillsListItem from 'views/Bills/BillsListItem';
import Bill from 'models/Bill';
import { APIPagination } from 'resources/types/commonTypes';
import PagePagination from 'components/modules/PagePagination';
import t from 'resources/translations';
import { DATE_FORMAT, DATE_FORMAT_WITH_COMMA } from 'config/constans';
import _ from 'lodash';

type TBillsListProps = {
  bills: Bill[];
  pagination?: APIPagination;
  isLoading: boolean;
};

const BillsList = ({ bills = [], pagination, isLoading }: TBillsListProps) => {
  const isBillsEmpty = bills.length === 0;
  const noResult = !isLoading && isBillsEmpty;

  if (noResult) {
    return (
      <Content mt="lg">
        <Message info>{t('message.noResults')}</Message>
      </Content>
    );
  }

  const billsGroupedByDays = _.groupBy(bills, (bill: Bill) => {
    if (bill.billableType === 'Ticket') {
      return moment(bill.createdAt).format(DATE_FORMAT);
    }
    return moment(bill.event.startAt).format(DATE_FORMAT);
  });

  return (
    <>
      <Stack spacing="xl">
        {Object.keys(billsGroupedByDays).map((dayKey: string) => {
          return (
            <Card key={dayKey}>
              <CardHeader>{moment(dayKey, DATE_FORMAT).format(DATE_FORMAT_WITH_COMMA)}</CardHeader>
              <CardContent bgColor="grey-lighter-50">
                <Grid gridTemplateColumns="1fr 30px 3.5fr 100px 100px 120px 40px" gridColumnGap={30}>
                  <Text fontWeight="medium">{t('general.client')}</Text>
                  <Text fontWeight="medium">{t('general.type')}</Text>
                  <Text fontWeight="medium">{t('general.name')}</Text>
                  <Text fontWeight="medium" textAlign="right">
                    {t('bills.due')}
                  </Text>
                  <Text fontWeight="medium" textAlign="right">
                    {t('bills.deposit')}
                  </Text>
                  <Text fontWeight="medium">{t('labels.paymentStatus')}</Text>
                  <Flex />
                </Grid>
              </CardContent>
              {billsGroupedByDays[dayKey].map(billPerDay => (
                <BillsListItem key={billPerDay.id} bill={billPerDay} />
              ))}
            </Card>
          );
        })}
      </Stack>
      {!isBillsEmpty && pagination && <PagePagination pagination={pagination} />}
    </>
  );
};

BillsList.defaultProps = {
  children: null,
  isLoading: false,
};

export default React.memo(BillsList);
