import PageHeader from '@old/components/old/PageHeader';
import Spinner from '@old/components/common/Spinner';
import { ContextContainer } from '@old/components/common/ContextMenu';
import localStorageService from 'services/storage/localStorageService';

const FarmViewLayout = ({ children, loggedMember, farm }) => {
  const user = localStorageService._getUser();

  if (!loggedMember || !farm || !user.phoneVerified) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader />
      <div className="content">
        <div className="page-container">{children}</div>
      </div>
      <ContextContainer />
    </>
  );
};

FarmViewLayout.defaultProps = {
  loggedMember: null,
  farm: null,
};

FarmViewLayout.displayName = 'FarmViewLayout';

export default FarmViewLayout;
