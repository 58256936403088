/**
 * @module PasswordInput
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '@old/components/icon';
import InputWrapper from '@old/components/common/InputWrapper';
import ButtonSimple from '@old/components/guide/ButtonSimple';

/**
 * Component that display input with posibility to covering text with *
 * @param  {String} [props.value] value of input field
 * @param  {String} [props.name] name of input
 * @param  {Function} props.onChange The onChange detects when the value of an input element changes and run function delegated in props
 * @param  {String} [props.placeholder] text put in placeholder of input field
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 */
const PasswordInput = ({ value, name, onChange, placeholder, label, required }) => {
  const [showPassword, setShowPassword] = useState(false);

  const onInputChange = (e) => {
    onChange(e.target.value, name);
  };

  const icon = (
    <ButtonSimple tabIndex={-1} onClick={() => setShowPassword(prevShowPassword => !prevShowPassword)}>
      {showPassword ? <Icon.VisibilityOn /> : <Icon.VisibilityOff />}
    </ButtonSimple>
  );

  return (
    <InputWrapper label={label} required={required} icon={icon}>
      <input
        type={showPassword ? 'text' : 'password'}
        name={name}
        value={value}
        onChange={onInputChange}
        placeholder={placeholder}
        autoComplete="off"
      />
    </InputWrapper>
  );
};

PasswordInput.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  label: '',
  required: false,
};

PasswordInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default PasswordInput;
