import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import Option from '@old/components/view/listItem/SelectOption';
import { FORM_EVENT_ADD } from 'old/constants/formNames';
import FloatingButton from '@old/components/custom/FloatingButton';
import config from '@old/config';
import { getDuration, isCorrectFormat, isLengthBetween, getInitValues, parseToCurrencyString } from 'old/utils';
import { openModal, changeFieldState } from 'store/actions';
import FieldGroupIcons from '@old/components/common/FieldGroupIcons';
import { useActions, useShallowSelector } from 'old/hooks';

const EventForm = ({ initValues, onSubmit }) => {
  const [eventCost, setEventCost] = useState(initValues?.type?.eventCost || null);
  const isMobile = useShallowSelector(({ app }) => app.isMobile);
  const [onChangeField, openModalByName] = useActions([changeFieldState, openModal]);
  const { dateTimeFormat } = config;
  const formName = FORM_EVENT_ADD;

  const addParticipantsRef = useRef();

  const setNameAfterFieldChange = ({ formState }) => {
    const typeName = formState.type.value && formState.type.value.label;
    const date =
      formState.startDate.value && formState.endDate.value
        ? getDuration(
            moment(formState.startDate.value, dateTimeFormat),
            moment(formState.endDate.value, dateTimeFormat)
          )
        : '';
    const placesName = formState.places.value && formState.places.value.label;

    return onChangeField({
      formName,
      fieldName: 'eventName',
      fieldState: [typeName, date, placesName].filter(Boolean).join(' / '),
    });
  };
  const setFieldsAfterEventTypeChange = ({ formState }) => {
    setNameAfterFieldChange({ formState });
    setEndDateAfterFieldChange({ formState });
    setPlacesAfterEventTypeChange({ formState });
    setCostAfterEventTypeChange({ formState });
  };

  const setCostAfterEventTypeChange = ({ formState }) => {
    const eventType = formState.type.value;
    setEventCost(eventType?.eventCost);
  };

  const setPlacesAfterEventTypeChange = ({ formState }) => {
    const eventType = formState.type.value;
    if (eventType && eventType.defaultPlace) {
      onChangeField({ formName, fieldName: 'places', fieldState: eventType.defaultPlace });
    }
  };

  const setEndDateAfterFieldChange = ({ formState }) => {
    if (isCorrectFormat(formState.startDate.value, dateTimeFormat)) {
      const timeIntervals = formState.type.value ? formState.type.value.timeIntervals : [];
      let datetime = '';
      if (timeIntervals && timeIntervals.length > 0) {
        const timeIncrement = moment(timeIntervals[0], 'HH:mm');
        const minutes = timeIncrement.hour() * 60 + timeIncrement.minute();
        datetime = moment(formState.startDate.value, dateTimeFormat).add(minutes, 'minutes').format(dateTimeFormat);
      } else {
        datetime = moment(formState.startDate.value, dateTimeFormat).add(60, 'minutes').format(dateTimeFormat);
      }
      onChangeField({ formName, fieldName: 'endDate', fieldState: datetime });
    }
  };

  const setAttendeesAfterFieldChange = ({ value, formState }) => {
    const participantsLength = value.length;
    const maxLimmitOFParticipants = formState.attendees.value[1];

    if (participantsLength > maxLimmitOFParticipants) {
      onChangeField({
        formName,
        fieldName: 'attendees',
        fieldState: [formState.attendees.value[0], value.length],
      });
    }
  };

  const approveSubmit = formState => {
    const startDateValue = moment(formState.startDate, dateTimeFormat);
    if (moment().isAfter(startDateValue, 'seconds')) {
      return openModalByName('confirmModal', {
        header: t('eventForm.confirmationChanges'),
        content: `${t('eventForm.changesInPast')} ${t('eventForm.areYouSureWantSave')}`,
        onSubmitConfirm: () => onSubmit(Model.Event.mapToSaveData(formState)),
      });
    }
    return onSubmit(Model.Event.mapToSaveData(formState));
  };

  const validations = {
    eventName: [
      {
        condition: value => isLengthBetween({ value, min: 5, max: 120 }),
        errorHint: t('error.fieldMustHaveLengthBetween', { min: 5, max: 120 }),
      },
    ],
    endDate: [
      {
        condition: (value, formState) => {
          const startDate = formState.startDate.value;
          const endDate = value;
          if (isCorrectFormat(startDate, dateTimeFormat) && isCorrectFormat(endDate, dateTimeFormat)) {
            const momentStartDate = moment(startDate, dateTimeFormat);
            return !moment(endDate, dateTimeFormat).isSameOrBefore(momentStartDate);
          }
          return true;
        },
        errorHint: t('error.after_start'),
      },
    ],
    startDate: [
      {
        condition: (value, formState) => {
          const startDate = value;
          const endDate = formState.endDate.value;
          if (isCorrectFormat(startDate, dateTimeFormat) && isCorrectFormat(endDate, dateTimeFormat)) {
            const momentStartDate = moment(startDate, dateTimeFormat);
            return !moment(endDate, dateTimeFormat).isSameOrBefore(momentStartDate);
          }
          return true;
        },
        errorHint: t('error.after_start'),
      },
    ],
    participants: [
      {
        condition: (value, formState) => {
          const isToManyParticipants = value.length <= formState.attendees.value[1];
          return isToManyParticipants;
        },
        errorHint: t('error.toManyParticipants'),
      },
    ],
  };

  const loadOptionsForInstructors = (keyword, page) => {
    return Model.Members.fetchInstructorOptions(keyword, page, { with_status: ['pending', 'active'] });
  };

  const initialValues = getInitValues({ formName: 'events', customInitValues: initValues });

  const dependentFields = {
    startDate: ['endDate'],
    endDate: ['startDate'],
    participants: ['attendees'],
    attendees: ['participants'],
  };

  return (
    <React.Fragment>
      <Form formName={formName} initValues={initialValues} dependentFields={dependentFields}>
        <Field.AsyncSelect
          name="type"
          label={t('labels.eventType')}
          placeholder={t('placeholders.selectEventType')}
          loadOptions={(keyword, page) => Model.EventTypes.fetchOptions(keyword, page, { with_deleted: false })}
          afterChanges={setFieldsAfterEventTypeChange}
          customOptionComponents={Option.EventType}
          required
        />
        {eventCost && (
          <div className="flex ml-1 -mt-3">
            <div className="font-bold">{t('eventListItem.pricePerShare')}:</div>
            <div className="ml-1">{parseToCurrencyString(eventCost)}</div>
          </div>
        )}
        <Field.DateTime
          name="startDate"
          dataTest="starttime"
          label={t('labels.start')}
          validation={validations.startDate}
          afterChanges={setEndDateAfterFieldChange}
          // eslint-disable-next-line react/prop-types
          pickerOptions={{ defaultIsOpen: !!initValues?.eventName }} // it only is opened by default when some event is copied - when event name is send as default value
          placeholder={t('placeholders.date')}
          required
        />

        <Field.DateTimeWithDuration
          name="endDate"
          dataTest="endtime"
          label={t('labels.end')}
          validation={validations.endDate}
          afterChanges={setNameAfterFieldChange}
          durations={{
            masterField: 'startDate',
            dependentField: 'type',
          }}
          placeholder={t('placeholders.date')}
          required
        />

        <Field.AsyncSelect
          name="places"
          label={t('labels.place')}
          placeholder={t('placeholders.selectPlace')}
          loadOptions={(keyword, page) => Model.Places.fetchOptions(keyword, page, { with_deleted: false })}
          afterChanges={setNameAfterFieldChange}
          customOptionComponents={Option.Place}
          isSearchable={false}
          required
        />

        <Field.AsyncMultiSelect
          name="instructors"
          label={t('labels.instructor')}
          placeholder={t('placeholders.selectInstructor')}
          loadOptions={loadOptionsForInstructors}
          customOptionComponents={Option.Member}
          isSearchable={false}
          required
        />

        <Field.AddParticipants
          ref={addParticipantsRef}
          name="participants"
          afterChanges={setAttendeesAfterFieldChange}
          validation={validations.participants}
        />

        <Field.Radio
          name="visibility"
          label={t('labels.visibility')}
          options={[
            { value: 'true', label: t('visibility.public').toLowerCase(), dataTest: 'public' },
            { value: 'false', label: t('visibility.private').toLowerCase(), dataTest: 'private' },
          ]}
        />

        <Field.Text name="eventName" label={t('labels.name')} validation={validations.eventName} required />

        <FieldGroupIcons label={t('general.other')}>
          <Field.NumberRange
            iconName="NumberUsers"
            name="attendees"
            label={t('labels.numberParticipants')}
            options={{
              min: 1,
              max: 50,
              minLabel: isMobile ? t('labels.min') : t('labels.minNumberOfParticipants'),
              maxLabel: isMobile ? t('labels.max') : t('labels.maxNumberOfParticipants'),
            }}
          />

          <Field.AsyncMultiSelect
            iconName="InviteUser"
            name="inviteParticipants"
            label={t('labels.inviteParticipants')}
            placeholder={isMobile ? t('placeholders.inviteParticipants') : t('placeholders.selectParticipants')}
            loadOptions={Model.Members.fetchClientOptions}
            customOptionComponents={Option.Member}
            options={{
              customFilter: option => !option.isGuest, // FIXME: fix in 1.4.n version
              isOptionDisabled: (optionData, formState) => {
                return formState.participants.value.map(option => option.value).includes(optionData.value);
              },
            }}
          />

          <Field.AsyncMultiSelect
            iconName="AddHorse"
            name="horses"
            label={t('labels.horses')}
            placeholder={t('placeholders.selectHorses')}
            loadOptions={(keyword, page) => Model.Horses.fetchOptions(keyword, page, { with_deleted: false })}
            customOptionComponents={Option.Horse}
          />

          <Field.Text iconName="Info" name="eventDescription" label={t('labels.additionalNotes')} />

          <Field.Radio
            iconName="Star"
            name="special"
            label={t('labels.typeOfEvent')}
            options={[
              { value: 'false', label: t('labels.normal').toLowerCase() },
              { value: 'true', label: t('labels.special').toLowerCase() },
            ]}
          />

          <Field.Select
            iconName="EventLevel"
            name="difficulty"
            label={t('labels.difficulty')}
            placeholder={t('placeholders.selectDifficulty')}
            options={config.difficulty.map(skill => ({
              key: skill.value,
              value: skill.value,
              label: skill.label,
              slug: skill.slug,
              color: skill.color,
            }))}
            customOptionComponents={Option.Difficulty}
          />
        </FieldGroupIcons>
        <FormButton formName={formName} onClick={approveSubmit} async>
          {t('general.save')}
        </FormButton>
      </Form>
      <FloatingButton
        buttons={[
          {
            key: 'addGuest',
            label: t('add.addGuest'),
            onClick: () =>
              openModalByName('addGuest', {
                afterAddGuest: addParticipantsRef.current.afterAddGuest,
              }),
            iconName: 'AddUser',
          },
        ]}
      />
    </React.Fragment>
  );
};

EventForm.defaultProps = {
  onSubmit: null,
  initValues: {},
};

EventForm.propTypes = {
  initValues: PropTypes.shape({
    type: PropTypes.shape({
      eventCost: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func,
};

export default EventForm;
