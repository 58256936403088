import { MODAL_OPEN, MODAL_HIDE, MODAL_UPDATE, MODAL_CLOSE } from 'store/actions/modalActions';

const baseInitValue = {
  isOpen: false,
  isHidden: false,
  props: {},
};

const initialState = {
  MODAL_PAYMENT: baseInitValue,
  MODAL_CONFIRM: baseInitValue,
  MODAL_RETURN_PAYMENT: baseInitValue,
  MODAL_CANCEL_PAYMENT: baseInitValue,
};

const modalReducer = (state = initialState, action) => {
  const { name, payload } = action;

  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        [name]: {
          isOpen: true,
          isHidden: false,
          props: payload || state[name]?.props || {},
        },
      };
    case MODAL_HIDE:
      return {
        ...state,
        [name]: {
          ...state[name],
          isHidden: true,
        },
      };
    case MODAL_UPDATE:
      return {
        ...state,
        [name]: {
          ...state[name],
          props: {
            ...state[name].props,
            ...payload,
          },
        },
      };
    case MODAL_CLOSE:
      return {
        ...state,
        [name]: initialState[name],
      };
    default:
      return state;
  }
};

export default modalReducer;
