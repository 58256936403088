import moment from 'moment';
import truncate from 'lodash/truncate';

import BaseModel from 'old/model/BaseModel';
import config from 'old/config';
import { getObjectWithMinValueByKey, mapModelPictures } from 'old/utils/Common';

class Places extends BaseModel {
  constructor(options) {
    super({
      modelName: 'places',
      updateKey: 'place',
      basePath: '/api/v1/farms/{farmId}/places',
      ItemClass: Place,
      ...options,
    });
  }

  getStatistics = async (placeId, params = {}) => {
    let { start, end } = params;
    if (!start || !end) {
      start = moment().subtract(7, 'days').startOf('day').toISOString();
      end = moment().subtract(1, 'days').endOf('day').toISOString();
    }

    try {
      const response = await this.client.get(`${this.basePath}/${placeId}/statistics`, {
        params: {
          'stats[start]': start,
          'stats[end]': end,
        },
      });
      return response.data.statistics;
    } catch (e) {
      return null;
    }
  };

  getWeeklyStatistics = async (placeId, params = {}) => {
    let { start, end } = params;
    if (!start || !end) {
      start = moment().subtract(7, 'days').startOf('day').toISOString();
      end = moment().subtract(1, 'days').endOf('day').toISOString();
    }

    try {
      const response = await this.client.get(`${this.basePath}/${placeId}/statistics/weekly`, {
        params: {
          'stats[start]': start,
          'stats[end]': end,
        },
      });
      return response.data.statistics;
    } catch (e) {
      return null;
    }
  };

  fetchOptions = async (keyword, page, fetchParams) => {
    const [places, pagination] = await this.fetchAll({ per_page: 20, keyword, page, ...fetchParams });
    const mappedOptions = places.map(place => ({
      key: place.id,
      value: place.id,
      label: place.getName(),
      image: place.getAvatar('thumb'),
      color: place.getColor(),
      slug: place.slug,
      isDeleted: place.isDeleted(),
    }));

    return {
      options: mappedOptions,
      hasMore: pagination.pages > page,
      additional: { page: page + 1 },
    };
  };
}

class Place {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.deleted = data.deleted;
    this.primaryPictureId = data.primary_picture_id || null;
    this.pictures = mapModelPictures(data.pictures, '/img/place-avatar-placeholder.png');
    this.privileges = data.meta ? data.meta.privileges : [];
  }

  static mapToSaveData = (data) => {
    const place = {
      name: data.placeName,
      slug: data.placeSlug,
      primary_picture_id: data.primaryPictureId,
      pictures_attributes: data.pictures && data.pictures.map(picture => ({
        id: picture.id,
        description: picture.description,
        file: picture.file,
        _destroy: picture.destroy,
      })),
    };

    Object.keys(place).forEach((fieldName) => {
      if (place[fieldName] === undefined) delete place[fieldName];
    });

    return place;
  };

  static mapToFormData = (data) => {
    const placeData = {
      placeName: data.getName(),
      placeSlug: data.getSlug(),
    };
    return placeData;
  };

  update = async changes => this.model.update(this.id, changes);

  getName(limit = false) {
    return limit ? truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getSlug() {
    return this.slug;
  }

  getColor() {
    return config.accentColorsPlaces[this.id % config.accentColorsPlaces.length];
  }

  getImage(size) {
    return this.getAvatar(size);
  }

  isDeleted() {
    return this.deleted || false;
  }

  getPermission(permissionKey) {
    return this.privileges.includes(permissionKey);
  }

  getAvatar(size) {
    if (!this.pictures || this.pictures.length === 0) {
      return '/img/place-avatar-placeholder.png';
    }

    let mainPicture = null;

    if (!this.primaryPictureId) {
      mainPicture = getObjectWithMinValueByKey(this.pictures, 'id');
    } else {
      mainPicture = this.pictures.find(picture => picture.id === this.primaryPictureId);
    }

    return (mainPicture && mainPicture.url)
      ? (mainPicture.url[size] || mainPicture.url.medium)
      : '/img/place-avatar-placeholder.png';
  }

  async updatePicture(picturesData) {
    return this.model.update(this.id, Place.mapToSaveData(picturesData));
  }

  getSlugInfo() {
    const place = {
      name: this.getName(),
      color: this.getColor(),
      slug: this.slug,
      id: this.id,
    };

    return { place };
  }
}

export { Place, Places };
