import Modal from 'components/combinations/modals/Modal';
import ModalHeader from 'components/combinations/modals/ModalHeader';
import ModalCloseButton from 'components/combinations/modals/ModalCloseButton';
import ModalContent from 'components/combinations/modals/ModalContent';
import { Box, Button, Flex, Icons, Span, Stack, Text } from 'components/elements';
import t from 'resources/translations';
import { TWrappedModal, TStoreStateModals } from 'views/modals';
import TicketOffer from 'models/TicketOffer';
import { TIconCircleProps } from 'resources/types/elementsTypes';
import { useAppSelector } from 'utils';
import { parseToCurrencyString } from 'old/utils';
import { ModalButtons } from 'components/combinations';
import { useShallowSelector } from 'old/hooks';

const styleProps = {
  size: 'md',
  bgColor: 'teal-light',
  color: 'white',
} as TIconCircleProps;

type TPaymentModalProps = {
  offer: TicketOffer;
};
const TicketOfferDetailsModal = ({ onClose, isHidden }: TWrappedModal) => {
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const { offer } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['TICKET_OFFER_DETAILS'].props
  ) as TPaymentModalProps;
  const eventTypeName = offer.eventTypes.map(type => type.getName()).join(', ');

  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{t('ticketOffers.offerDetails')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent>
        <Stack isHorizontal={!isMobile}>
          <Stack isHorizontal alignItems="center" flex={7}>
            <Icons.TicketCircle {...styleProps} />
            <Text fontWeight="medium" fontSize="base">
              {offer.getName()}
            </Text>
          </Stack>
          <Stack spacing="xs" justifyContent="center" flex={3} textAlign={isMobile ? 'left' : 'right'}>
            <Text fontSize="sm" color="teal-dark">
              {t('ticketOffers.numberOfRidesOnOffer')}:
            </Text>
            <Text fontWeight="bold" fontSize="md">
              {offer.count}
            </Text>
          </Stack>
        </Stack>
        {!offer.visibility && (
          <Stack isHorizontal alignItems="center" spacing="sm">
            <Icons.CrossedEyeCircle bgColor="teal-dark" color="white" size="sm" />
            <Text color="teal-dark">{t('ticketOffers.invisibleTicketOffer')}</Text>
          </Stack>
        )}
        <Box mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" px="xl" py="md">
          <Stack spacing="md">
            <Text fontWeight="medium">
              {t('paymentTicketModal.periodValidity')}
              <Span fontWeight="normal">{`: ${offer.validity} ${t('tickets.days', { count: offer.validity })}`}</Span>
            </Text>
            <Stack spacing="xs">
              <Text fontWeight="medium">{t('ticketOffers.eventTypesAvailableOffer')}:</Text>
              <Text>{eventTypeName}</Text>
            </Stack>
            {offer.description && (
              <Text fontWeight="medium">
                {t('ticketOffers.additionalRemarks')}
                <Span fontWeight="normal">{`: ${offer.description}`}</Span>
              </Text>
            )}
          </Stack>
        </Box>
        <Stack spacing="xs">
          <Text fontWeight="medium">{t('ticketOffers.offerActivationDate')}:</Text>
          <Text>
            {offer.activable
              ? t('ticketOffers.dateActivationAtTheTimeOfPurchase')
              : t('ticketOffers.OfferActivatedAutomatically')}
          </Text>
        </Stack>
        <Flex alignItems="flex-end" pt="base" pb="sm" justifyContent="space-between">
          <Text fontSize="md" fontWeight="bold" textTransform="capitalize">
            {t('ticketOffers.price')}:
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {parseToCurrencyString(offer.price)}
          </Text>
        </Flex>
      </ModalContent>
      <ModalButtons>
        <Button onClick={onClose} variant="outline">
          {t('general.close')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default TicketOfferDetailsModal;
