import t from 'resources/translations';
import { getErrorMessage, notify } from 'old/utils';
import { Button, Span, Text } from 'components/elements';
import { Modal, ModalHeader, ModalCloseButton, ModalContent, ModalButtons } from 'components/combinations';
import { TStoreStateModals, TWrappedModal } from '.';
import Bill from 'models/Bill';
import { useMutation } from 'react-query';
import { BillService } from 'services';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { IPaymentRes } from 'resources/types/billsTypes';
import { useShallowSelector } from 'old/hooks';

type TReturnPaymentModalProps = {
  bill: Bill;
  onSubmitCb: (paymentRes: IPaymentRes | null) => void;
};

const ReturnPaymentsModal = ({ onSubmitAndClose, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const { bill, onSubmitCb } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['PAYMENT_RETURN'].props
  ) as TReturnPaymentModalProps;

  const returnPaymentMutation = useMutation((paymentId: number) => BillService.returnPayment(bill.id, paymentId), {
    onSuccess: onSubmitCb,
  });

  const onSubmit = async () => {
    try {
      const bookedPayment = bill.payments.find(payment => payment.status === 'booked');
      if (bookedPayment) {
        await returnPaymentMutation.mutateAsync(bookedPayment.id);
        notify(t('message.succesSave'));
      }
    } catch (e) {
      if (e.response?.status === 422) {
        notify(t('error.refund_transaction_unavailable'), { type: 'error' });
      } else {
        notify(getErrorMessage(e), { type: 'error' });
      }
    }
  };

  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{t('bills.returnPayment')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting}>
        <ModalDescription>
          <Text>
            {t('bills.areYouSureReturn')}
            {!!bill.billed ? (
              <Span fontWeight="bold">{bill.billed.getFullName()}</Span>
            ) : (
              <Span fontWeight="bold">{t('general.userDeleted')}</Span>
            )}
            <Span>?</Span>
          </Text>
        </ModalDescription>
      </ModalContent>
      <ModalButtons>
        {!returnPaymentMutation.isError && (
          <Button onClick={() => onSubmitAndClose(onSubmit)}>
            {isSubmitting ? <SpinnerDots label={t('bills.makingReturn')} inline /> : t('bills.makeAReturn')}
          </Button>
        )}
        <Button onClick={onClose} variant="outline">
          {!returnPaymentMutation.isError ? t('general.cancel') : t('general.close')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default ReturnPaymentsModal;
