import React from 'react';

import MaintenanceMode from '@old/components/error/MaintenanceMode';

const MaintenanceModePage = () => {
  return (
    <MaintenanceMode />
  );
};

export default MaintenanceModePage;
