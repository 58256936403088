import React from 'react';
import PropTypes from 'prop-types';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment';

import Center from '@old/components/common/Center';
import BoxShadow from '@old/components/common/BoxShadow';
import { useScrollBottomOfBox } from '@old/hooks';
import t from 'resources/translations';

const TimePicker = ({ value, onChange, onClose, header, defaultTime }) => {
  const innerRef = useScrollBottomOfBox(30);

  const onChangeTime = ({ formatted24 }) => {
    onChange(moment(formatted24, 'H:mm').format('HH:mm'));
  };

  const onClosePicker = () => {
    onClose();
  };

  return (
    <div ref={innerRef}>
      <BoxShadow>
        <Center className="bg-teal relative rounded text-md-white py-1 px-2">
          {header || t('timePickerInput.centralEuropeanTime')}
        </Center>
        <TimeKeeper
          time={value.length === 5 ? value : defaultTime}
          doneButton={() => null}
          onChange={onChangeTime}
          switchToMinuteOnHourSelect
          switchToMinuteOnHourDropdownSelect
          onDoneClick={onClosePicker}
          closeOnMinuteSelect
          hour24Mode
        />
      </BoxShadow>
    </div>
  );
};

TimePicker.defaultProps = {
  value: '00:00',
  defaultTime: '12:30',
  header: '',
};

TimePicker.propTypes = {
  header: PropTypes.string,
  defaultTime: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default TimePicker;
