import { AxiosResponse as ARes } from 'axios';
import HTTP from 'config/axios';
import Participation from 'models/Participation';
import { IEventRes } from 'resources/types/eventsTypes';
import { IParticipationRes, IParticipationsBatchPaymentsParams } from 'resources/types/participationsTypes';

class ParticipationService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async setPresence(eventId: number, participation: Participation) {
    const requestPath = `${this.apiBaseURL}/events/${eventId}/participations/${participation.id}/`;
    const changeTo = participation.attendance === 'attending' ? 'absent' : 'present';
    const response: ARes<IParticipationRes> = await HTTP.patch(`${requestPath}${changeTo}`);
    return response.data;
  }

  async setReject(eventId: number, participationId: number) {
    const requestPath = `${this.apiBaseURL}/events/${eventId}/participations/${participationId}/reject`;
    const response: ARes<IParticipationRes> = await HTTP.put(requestPath);
    return response.data;
  }

  async setAccept(eventId: number, participationId: number) {
    const requestPath = `${this.apiBaseURL}/events/${eventId}/participations/${participationId}/accept`;
    const response: ARes<IParticipationRes> = await HTTP.put(requestPath);
    return response.data;
  }

  async batchPayments(eventId: number, params: IParticipationsBatchPaymentsParams) {
    const requestPath = `${this.apiBaseURL}/events/${eventId}/participations/batch_payment`;
    const response: ARes<IEventRes> = await HTTP.patch(requestPath, params);
    return response.data;
  }
}

export default new ParticipationService();
