import Link from '@old/components/common/Link';
import RatingStars from '@old/components/old/RatingStars';
import t from 'resources/translations';
import Card from '@old/components/common/Card';
import FlexColumn from '@old/components/common/FlexColumn';
import ImageCircle from '@old/components/guide/ImageCircle';
import Center from '@old/components/common/Center';
import TagCircle from '@old/components/common/TagCircle';
import Subtitle from '@old/components/common/Subtitle';
import { useHorseEditButton, useHorseDeleteButton, useHorseHistoryRidingButton } from 'old/buttonHooks';
import { ContextTrigger } from 'old/components/common/ContextMenu';

const HorseGridItem = ({ horse }) => {
  const age = horse.getAge();
  const slugInfos = horse.getSlugInfo();
  const horseEdit = useHorseEditButton(horse);
  const horseDelete = useHorseDeleteButton(horse);
  const historyHorseRiding = useHorseHistoryRidingButton(horse);

  const horseButtons = [horseEdit, horseDelete, historyHorseRiding];

  const Stars = (
    <Link to={`/horses/${horse.id}/rating`}>
      <Center>
        <RatingStars rating={horse.rating || 0} />
      </Center>
    </Link>
  );

  return (
    <ContextTrigger menuItems={horseButtons}>
      <Card footerContent={Stars} fullHeight>
        <div className="text-center">
          <Link to={`/horses/${horse.id}`}>
            <div className="absolute slug-left-corner z-10">
              <TagCircle color={slugInfos.color} small>
                {slugInfos.text}
              </TagCircle>
            </div>
            <FlexColumn>
              <Center>
                <ImageCircle src={horse.getImage()} big />
              </Center>
              <Subtitle>{horse.getName(40)}</Subtitle>
            </FlexColumn>
          </Link>
          <div className="leading-tight">
            {horse.breed}, {t('age', { age, count: age })}
          </div>
        </div>
      </Card>
    </ContextTrigger>
  );
};

export default HorseGridItem;
