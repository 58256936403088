/**
 * @module FlexSection
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

/**
 * wrapper for vertically arranged elements that adds border around
 * @param  {*} props.children
 * @param  {Boolean} props.marginBottom specifies if there should be bottom margin
 */
const FlexSection = ({ children, marginBottom }) => {
  const className = cls('flex flex-1 flex-col hm-flex-col', {
    'mb-10': marginBottom,
  });

  return (
    <div className={className}>
      {React.Children.map(children, (child) => {
        if (child) { // we don't want to wrap 'falsy' values
          return <div className="section">{child}</div>;
        }
        return null;
      })}
    </div>
  );
};

FlexSection.defaultProps = {
  marginBottom: false,
};

FlexSection.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  marginBottom: PropTypes.bool,
};

export default FlexSection;
