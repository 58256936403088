import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import theme from 'config/theme';
import Spinner from 'old/components/common/Spinner';
import ErrorBoundary from 'old/components/old/ErrorBoundary';
import MaintenanceModePage from 'old/pages/MaintenanceMode';
import FarmViewLayout from 'old/layouts/FarmViewLayout';
import AppRoute from 'old/layouts/AppRoute';
import ModalsController from 'old/components/custom/ModalsController';
import Sidebar from 'old/components/custom/Sidebar';
import { IRoute } from 'resources/types/commonTypes';
import { useAppStore, useFarm } from 'utils/storeUtils';
import AppEffects from './AppEffects';

import { useDispatch } from 'react-redux';
import { ConfirmModal } from 'views/modals';
import { initApp } from 'store/app';
import localStorageService from 'services/storage/localStorageService';
import { getPermissionCategoryByName } from 'old/utils';
import Login from 'old/pages/Login';
import Edit from 'old/components/custom/Edit';
import Add from 'old/components/custom/Add';
import Register from 'old/pages/Register';
import InvitationEmails from 'old/pages/InvitationEmails';
import Timetable from 'old/pages/Timetable';
import NewsPage from 'old/pages/News';
import NewsDetails from 'old/pages/NewsDetails';
import HorsesPage from 'old/pages/Horses';
import HorseDetails from 'old/pages/HorseDetails';
import UsersPage from 'old/pages/Users';
import UserDetails from 'old/pages/UserDetails';
import EventTypesDetails from 'old/pages/EventTypeDetails';
import EventsPage from 'old/pages/Events';
import EventDetails from 'old/pages/EventDetails';
import PlaceDetails from 'old/pages/PlaceDetails';
import SmsPage from 'old/pages/Sms';
import SmsPageConfirm from 'old/pages/SmsConfirm';
import ProposalEventsPage from 'old/pages/ProposalEvents';
import RatingPage from 'old/pages/RatingPage';
import HorseEventHistory from 'old/pages/HorseEventHistory';
import Absences from 'old/pages/Absences';
import Shifts from 'old/pages/Shifts';
import FarmDetails from 'old/pages/FarmDetails';
import ForgotPassword from 'old/pages/ForgotPassword';
import NewPassword from 'old/pages/NewPassword';
import Logout from 'old/pages/Logout';
import NotificationsPage from 'old/pages/Notifications';
import SocialLogin from 'old/pages/SocialLogin';
import InstructorStatistics from 'old/pages/InstructorStatistics';
import HorseStatistics from 'old/pages/HorseStatistics';
import PlaceStatistics from 'old/pages/PlaceStatistics';
import UserGroups from 'old/pages/UserGroups';
import Guide from 'old/pages/Guide';
import UpdateFarmPolicy from 'old/pages/UpdateFarmPolicy';
import FarmEdit from 'old/pages/FarmEdit';
import SimpleLayout from 'old/layouts/SimpleLayout';
import ConfirmPhone from 'old/pages/ConfirmPhone';
import Bills from 'views/Bills/Bills';
import BillDetails from 'views/Bills/BillDetails';
import Dashboard from 'views/Dashboard/Dashboard';
import PaymentSuccess from 'old/atomic/pages/PaymentSuccess';
import Tickets from 'views/Tickets/Tickets';
import TicketOffersAdd from 'views/Tickets/TicketOffersAdd';
import TicketOffersEdit from 'views/Tickets/TicketOffersEdit';
import TicketDetails from 'views/Tickets/TicketDetails';
import Error404 from 'old/components/error/Error404';
import UsersFormEdit from 'views/Users/UsersFormEdit';

type TGetPermissionsArgs = 'event-types' | 'users' | 'members';

export const routes: IRoute[] = [
  {
    path: '/guide',
    Component: Guide,
    Layout: SimpleLayout,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
    noLogged: true,
  },
  {
    path: '/register',
    Component: Register,
    Layout: SimpleLayout,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
    noLogged: true,
  },
  {
    path: '/forgot-password',
    Component: ForgotPassword,
    Layout: SimpleLayout,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
    noLogged: true,
  },
  {
    path: '/new-password',
    Component: NewPassword,
    Layout: SimpleLayout,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
    noLogged: true,
  },
  {
    path: '/invitation/accept',
    Component: Register,
    Layout: SimpleLayout,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
    noLogged: true,
  },
  {
    path: '/social/login',
    Component: SocialLogin,
    Layout: SimpleLayout,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
    noLogged: true,
  },
  {
    Component: Login,
    getPermissionKey: () => 'alwaysAllowed',
    Layout: SimpleLayout,
    exact: true,
    noLogged: true,
  },
  {
    path: '/confirm-phone',
    Layout: SimpleLayout,
    Component: ConfirmPhone,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
  },
  {
    path: '/logout',
    Component: Logout,
    Layout: SimpleLayout,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
  },
  {
    path: '/payment_success',
    Component: PaymentSuccess,
    Layout: SimpleLayout,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
  },
  {
    path: '/policy/edit',
    Component: UpdateFarmPolicy,
    exact: true,
    getPermissionKey: () => 'farm_policy.update',
  },
  {
    path: '/tickets/:id/edit',
    Component: TicketOffersEdit,
    exact: true,
    getPermissionKey: () => 'ticket_offers.update',
  },
  {
    path: '/users/:id/edit',
    Component: UsersFormEdit,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
  },
  {
    path: '/tickets/:id(\\d+)',
    Component: TicketDetails,
    exact: true,
    getPermissionKey: () => 'ticket_offers.index',
  },
  {
    path: '/tickets/add',
    Component: TicketOffersAdd,
    exact: true,
    getPermissionKey: () => 'ticket_offers.create',
  },
  {
    path: '/:model/add',
    Component: Add,
    exact: true,
    getPermissionKey: ({ model }: { model: TGetPermissionsArgs }) => `${getPermissionCategoryByName(model)}.create`,
  },
  {
    path: '/:model/:id/edit',
    Component: Edit,
    exact: true,
    getPermissionKey: ({ model }: { model: TGetPermissionsArgs }) => `${getPermissionCategoryByName(model)}.update`,
  },
  {
    path: '/farm/edit',
    Component: FarmEdit,
    getPermissionKey: () => 'farms.update',
  },
  {
    path: '/news',
    exact: true,
    Component: NewsPage,
    model: 'News',
    getPermissionKey: () => 'news.index',
  },
  {
    path: '/news/:id(\\d+)', // (\\d+)allow only numbers to be id
    Component: NewsDetails,
    exact: true,
    getPermissionKey: () => 'news.show',
  },
  {
    path: '/news/drafts/:id',
    exact: true,
    Component: NewsDetails,
    model: 'News',
    fetchParams: {
      drafts: true,
    },
    getPermissionKey: () => 'news.drafts',
  },
  {
    path: '/horses',
    Component: HorsesPage,
    exact: true,
    model: 'Horses',
    getPermissionKey: () => 'horses.index',
  },
  {
    path: '/bills',
    Component: Bills,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
  },
  {
    path: '/bills/:id',
    Component: BillDetails,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
  },
  {
    path: '/proposals',
    Component: ProposalEventsPage,
    exact: true,
    model: 'Proposals',
    getPermissionKey: () => 'proposals.index',
  },
  {
    path: '/events',
    Component: EventsPage,
    exact: true,
    getPermissionKey: () => 'events.index',
  },
  {
    path: '/users',
    Component: UsersPage,
    exact: true,
    getPermissionKey: () => ['memberships.index', 'riding_groups.index'], // ??
  },
  {
    path: '/sms',
    Component: SmsPage,
    exact: true,
    getPermissionKey: () => 'farm_account.index',
  },
  {
    path: '/sms/confirm',
    Component: SmsPageConfirm,
    exact: true,
    getPermissionKey: () => 'farm_account.index',
  },
  {
    path: '/horses/:id',
    Component: HorseDetails,
    exact: true,
    getPermissionKey: () => 'horses.show',
  },
  {
    path: '/places/:id',
    Component: PlaceDetails,
    exact: true,
    model: 'Places',
    getPermissionKey: () => 'places.show',
  },
  {
    path: '/event-types/:id',
    Component: EventTypesDetails,
    exact: true,
    model: 'EventTypes',
    getPermissionKey: () => 'event_types.show',
  },
  {
    path: '/users/:id',
    Component: UserDetails,
    exact: true,
    getPermissionKey: () => ['memberships.show_client', 'memberships.show_owner', 'memberships.show'],
  },
  {
    path: '/instructors/:id',
    Component: UserDetails,
    exact: true,
    getPermissionKey: () => ['memberships.show_instructor', 'memberships.show'],
  },
  {
    path: '/events/:id(\\d+)',
    Component: EventDetails,
    exact: true,
    getPermissionKey: () => 'events.show',
  },
  {
    path: '/:model/:id/rating',
    Component: RatingPage,
    getPermissionKey: ({ model }: { model: TGetPermissionsArgs }) => `${getPermissionCategoryByName(model)}.rates`,
  },
  {
    path: '/farm',
    Component: FarmDetails,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed', // ??
  },
  {
    path: '/farm/invitations',
    Component: InvitationEmails,
    exact: true,
    getPermissionKey: () => 'farms.invite',
  },
  {
    path: '/horses/:id/history',
    Component: HorseEventHistory,
    exact: true,
    getPermissionKey: () => 'horses.show', // ??
  },
  {
    path: '/notifications',
    Component: NotificationsPage,
    model: 'Notifications',
    exact: true,
    getPermissionKey: () => 'alwaysAllowed', // ??
  },
  {
    path: '/horses/:id/absences',
    Component: Absences,
    exact: true,
    getPermissionKey: () => 'horse_absence.show',
  },
  {
    path: '/instructors/:id/shifts',
    Component: Shifts,
    exact: true,
    getPermissionKey: () => 'work_shifts.show',
  },
  {
    path: '/instructors/:id/statistics',
    Component: InstructorStatistics,
    exact: true,
    getPermissionKey: () => 'membership_statistics.index', // ??
  },
  {
    path: '/horses/:id/statistics',
    Component: HorseStatistics,
    exact: true,
    getPermissionKey: () => 'horse_statistics.index', // ??
  },
  {
    path: '/places/:id/statistics',
    Component: PlaceStatistics,
    exact: true,
    getPermissionKey: () => 'place_statistics.index', // ??
  },
  {
    path: '/riding-groups/:id',
    Component: UserGroups,
    model: 'MemberGroups',
    exact: true,
    getPermissionKey: () => 'riding_groups.show',
  },
  {
    path: '/timetable',
    Component: Timetable,
    exact: true,
    getPermissionKey: () => 'events.index',
  },
  {
    path: '/tickets',
    Component: Tickets,
    exact: true,
    getPermissionKey: () => 'tickets.index',
  },
  {
    path: '/',
    Component: Dashboard,
    exact: true,
    getPermissionKey: () => 'alwaysAllowed',
  },
  {
    Component: Error404,
    exact: false,
    getPermissionKey: () => 'alwaysAllowed',
  },
];

const queryClient = new QueryClient();

const App = () => {
  const farm = useFarm();
  const appState = useAppStore();
  const dispatch = useDispatch();
  const token = localStorageService._getToken() ?? null;
  const loggedUser = localStorageService._getUser();
  useEffect(() => {
    dispatch(initApp());
  }, [dispatch, token]);

  if (!appState.isInitialized) {
    return <Spinner />;
  }

  if (appState.isMaintenance) {
    return <MaintenanceModePage />;
  }
  const properRoutes = routes.filter(({ noLogged }) => (token ? !noLogged : noLogged));

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={farm?.slug || ''}>
          <AppEffects>
            <>
              {token && farm?.slug && loggedUser?.phoneVerified && <Sidebar />}
              <ErrorBoundary>
                <Switch>
                  {properRoutes.map((route, index) => (
                    <AppRoute key={`${route.path}-${index}`} Layout={route.Layout || FarmViewLayout} {...route} />
                  ))}
                </Switch>
                <ConfirmModal />
                <ModalsController />
              </ErrorBoundary>
              <ToastContainer />
            </>
          </AppEffects>
        </BrowserRouter>
      </ThemeProvider>
      {process.env.REACT_APP_SERVER === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

export default App;
