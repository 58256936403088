import t from 'resources/translations';
import Event from 'models/Event';
import { isPaymentAvailable, parseToCurrencyString } from 'old/utils';
import { Box, Button, Flex, Icons, Span, Stack, Text } from 'components/elements';
import { EventService } from 'services';
import { useAppSelector, useLoggedMember, mParticipationToEvents } from 'utils';
import { useMutation, useQueryClient } from 'react-query';
import { IParticipationRes } from 'resources/types/participationsTypes';
import { TCacheKey } from 'resources/types/cacheTypes';
import { usePermissions } from 'Permissions';
import { useModal } from 'old/hooks';

type TMyEventClientManage = {
  event: Event;
};

const MyEventClientManage = ({ event }: TMyEventClientManage) => {
  const loggedMember = useLoggedMember();
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const queryClient = useQueryClient();
  const mutationKeys: TCacheKey[] = ['DASBOARD_EVENTS_TODAY', 'DASHBOARD_EVENTS'];
  const modalPayment = useModal('PAYMENT_ONLINE');
  const canIRejectInvitation = usePermissions('EVENT_REJECT_INVITATION');
  const canIAddPayment = usePermissions('BILL_ADD_PAYMENT');
  const canIUnjoin = usePermissions('EVENT_UNJOIN');
  const canIJoin = usePermissions('EVENT_JOIN');

  const loggedClientParticipation = event.getParticipations().find(p => p.member.id === loggedMember?.id);
  const mutateOnSuccess = (participation: IParticipationRes) =>
    mParticipationToEvents(queryClient, mutationKeys, participation);

  const setJoinMutation = useMutation(async () => await EventService.join(event.id), {
    onSuccess: mutateOnSuccess,
  });
  const setUnjoinMutation = useMutation(async () => await EventService.unjoin(event.id), {
    onSuccess: mutateOnSuccess,
  });
  const setRejectMutation = useMutation(async () => await EventService.rejectInvitation(event.id), {
    onSuccess: mutateOnSuccess,
  });

  const onOpenPaymenModal = () => {
    if (loggedClientParticipation) {
      modalPayment.onOpen({ bill: loggedClientParticipation.bill });
    }
  };

  const bill = loggedClientParticipation?.bill;
  const participationStatus = loggedClientParticipation?.getStatus();
  const { isAwaiting, isActive } = event.getStatuses(loggedMember?.id);

  return (
    <Box mb={['sm', 0]}>
      {participationStatus === 'invitation_requested' && (
        <Box w="full" bgColor="new-grey-light-15" py="md" pl="2xl" pr="md">
          <Flex justifyContent="space-between" flexDirection={['column', 'row']} alignItems="center">
            <Text>{t('eventRiderAction.eventRequestLabel')}</Text>
            <Stack justifyContent="flex-end" mt={['md', 0]} isHorizontal>
              {canIUnjoin(event) && (
                <Button
                  variant="link"
                  onClick={() => setUnjoinMutation.mutateAsync()}
                  disabled={setUnjoinMutation.isLoading}
                >
                  <Stack spacing="sm" alignItems="center" isHorizontal>
                    <Icons.Cancel size="sm" />
                    <Text>{t('buttons.signOff')}</Text>
                  </Stack>
                </Button>
              )}
            </Stack>
          </Flex>
        </Box>
      )}
      {participationStatus === 'joined' && !!bill && (
        <Box w="full" bgColor="new-grey-light-15" py="md" pl="2xl" pr="md">
          <Flex justifyContent="space-between" flexDirection={['column', 'row']} alignItems="center">
            <Text>
              {`${t('dashboard.due')}: ${parseToCurrencyString(bill.getDue())}`}
              {!isPaymentAvailable({ bill }) && (
                <>
                  {`, `}
                  <Span color="new-green">{`${t('message.paid')}`}</Span>
                </>
              )}
            </Text>

            <Stack justifyContent="flex-end" mt={['md', 0]} isHorizontal>
              {canIUnjoin(event) && (
                <Button
                  variant="link"
                  onClick={() => setUnjoinMutation.mutateAsync()}
                  disabled={setUnjoinMutation.isLoading}
                >
                  <Stack spacing="sm" alignItems="center" isHorizontal>
                    <Icons.Cancel size="sm" />
                    <Text>{t('buttons.signOff')}</Text>
                  </Stack>
                </Button>
              )}
              {canIAddPayment(bill) && (
                <Button variant="link" onClick={onOpenPaymenModal}>
                  <Stack spacing="sm" alignItems="center" isHorizontal>
                    <Icons.PaymentWallet size="sm" />
                    <Text>{t('buttons.pay')}</Text>
                  </Stack>
                </Button>
              )}
            </Stack>
          </Flex>
        </Box>
      )}
      {participationStatus === 'invited' && (isActive || isAwaiting) && (
        <Box w="full" bgColor="new-grey-light-15" py="md" pl="2xl" pr="md">
          <Flex justifyContent="space-between" flexDirection={['column', 'row']} alignItems="center">
            <Text>{t('eventRiderAction.eventInvitationLabel')}</Text>
            <Stack justifyContent="flex-end" mt={['md', 0]} isHorizontal={!isMobile}>
              {canIRejectInvitation(event) && (
                <Button
                  variant="link"
                  onClick={() => setRejectMutation.mutateAsync()}
                  disabled={setRejectMutation.isLoading}
                >
                  <Stack spacing="sm" alignItems="center" isHorizontal>
                    <Icons.Cancel size={12} />
                    <Text>{t('buttons.reject')}</Text>
                  </Stack>
                </Button>
              )}
              {canIJoin(event) && (
                <Button
                  variant="link"
                  onClick={() => setJoinMutation.mutateAsync()}
                  disabled={setJoinMutation.isLoading}
                >
                  <Stack spacing="sm" alignItems="center" isHorizontal>
                    <Icons.Accept size={12} />
                    <Text>{t('buttons.accept')}</Text>
                  </Stack>
                </Button>
              )}
            </Stack>
          </Flex>
        </Box>
      )}
      {participationStatus === 'rejected' && (
        <Box w="full" border="orange" borderWidth={1} borderStyle="solid" py="md" pl="2xl" pr="md">
          <Text>{t('eventRiderAction.rejectInvitation')}</Text>
        </Box>
      )}
    </Box>
  );
};

export default MyEventClientManage;
