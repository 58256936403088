import { AxiosResponse as ARes, CancelToken } from 'axios';
import { ITicketFetchParams, TTicketAuditRes, ITicketRes, ITicketUpdateReq } from 'resources/types/TicketTypes';
import HTTP from 'config/axios';
import { IResWithPagination } from 'resources/types/commonTypes';

class TicketService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async fetchAll(params: ITicketFetchParams) {
    const response: ARes<IResWithPagination<ITicketRes[]>> = await HTTP.get(`${this.apiBaseURL}/tickets`, { params });
    return response.data;
  }

  async fetch(id: number | string, cancelToken?: CancelToken) {
    const response: ARes<ITicketRes> = await HTTP.get(`${this.apiBaseURL}/tickets/${id}`, { cancelToken });
    return response.data;
  }

  async update(id: number, params: ITicketUpdateReq) {
    const response: ARes<ITicketRes> = await HTTP.patch(`${this.apiBaseURL}/tickets/${id}`, { ticket: { ...params } });
    return response.data;
  }

  async delete(id: number) {
    const response: ARes<ITicketRes> = await HTTP.delete(`${this.apiBaseURL}/tickets/${id}`);
    return response.data;
  }

  async available(billId: number) {
    const response: ARes<IResWithPagination<ITicketRes[]>> = await HTTP.get(`${this.apiBaseURL}/tickets/available`, {
      params: { bill_id: billId },
    });
    return response.data;
  }

  async fetchHistory(id: string, params: ITicketFetchParams) {
    const response: ARes<IResWithPagination<TTicketAuditRes[]>> = await HTTP.get(
      `${this.apiBaseURL}/tickets/${id}/changes`,
      { params }
    );

    return response.data;
  }
}
export default new TicketService();
