import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { MotionBox } from '@elements';
import { useAppStore } from 'utils/storeUtils';

const styleProps = {
  overlay: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: 'calc(var(--vh, 1vh) * 100)',
    backgroundColor: 'rgba(0, 0, 0, 0.48)',
    willChange: 'background-color',
  },
  modalContainer: (isMobile, isOpen) => ({
    display: isOpen ? 'flex' : 'none',
    width: '100vw',
    height: 'calc(var(--vh, 1vh) * 100)',
    position: 'fixed',
    left: 0,
    zIndex: 1010,
    top: 0,
    justifyContent: 'center',
    alignItems: isMobile ? 'flex-end' : 'flex-start',
    overlay: 'hidden',
  }),
  modal: {
    display: 'flex',
    opacity: 1,
    transform: 'none',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    outline: 0,
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'inherit',
    boxShadow: 'rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px',
    mt: ['1rem', '3.75rem'],
    mb: [0, '3.75rem'],
    borderTopRadius: 16,
    borderBottomLeftRadius: [0, 16],
    borderBottomRightRadius: [0, 16],
    maxW: ['100%', '576px'],
    willChange: 'top',
  },
};

const animation = {
  overlay: isHidden => ({
    initial: { opacity: 0 },
    animate: isHidden ? { opacity: 0 } : { opacity: 1 },
  }),
  modal: (isHidden, isMobile) => ({
    animate: isHidden ? 'isHidden' : 'isVisible',
    position: 'relative',
    transition: { duration: isMobile ? 0.35 : 0.15 },
    ...(isMobile
      ? {
        initial: { top: '100%' },
        variants: {
          isHidden: {
            top: '100%',
          },
          isVisible: {
            top: '0',
            opacity: 1,
          },
        },
      }
      : {
        initial: { opacity: 0, top: '25%' },
        variants: {
          isHidden: {
            top: '15%',
            opacity: 0,
          },
          isVisible: {
            top: '0',
            opacity: 1,
          },
        },
      }),
  }),
};

const Modal = ({ isOpen, isHidden, onClose, children, isBlocked, innerModalProps }) => {
  const { isMobile } = useAppStore();
  return ReactDOM.createPortal(
    <RemoveScroll enabled>
      <MotionBox className="modal-container" {...styleProps.modalContainer(isMobile, isOpen)}>
        <MotionBox
          onMouseDown={isBlocked ? () => { } : onClose}
          {...animation.overlay(isHidden)}
          {...styleProps.overlay}
        />
        <MotionBox {...animation.modal(isHidden, isMobile)} {...styleProps.modal} {...innerModalProps}>
          {children}
        </MotionBox>
      </MotionBox>
    </RemoveScroll>,
    document.getElementById('hm-portal')
  );
};

Modal.defaultProps = {
  isBlocked: false,
  innerModalProps: {},
  innerRef: null,
};

export default Modal;
