/* eslint-disable max-len */
import React from 'react';

const History = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M3,10.7l-3-3h2.3c0-3.8,3.1-6.9,6.9-6.9S16,4,16,7.8c0,3.8-3.1,6.9-6.8,6.9c-1.8,0-3.6-0.7-4.8-2l1.1-1.1c1.8,1.8,4.6,2.1,6.7,0.6c2.1-1.4,2.9-4.1,1.9-6.5c-1-2.3-3.5-3.7-6-3.2C5.6,3.1,3.8,5.2,3.8,7.8h2.3L3,10.9L3,10.7z" />
    </svg>
  );
};

export default History;
/* eslint-enable max-len */
