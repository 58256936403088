/* eslint-disable max-len */
import React from 'react';

const Bill = () => {
  return (
    <svg
      viewBox="0 0 26 26"
      width="100%"
    >
      <path
        d="M15.15,5.39h2.89l-4.42-4.65v3.11C13.62,4.69,14.3,5.39,15.15,5.39z"
      />
      <path
        d="M10.36,17.11H5.25c-0.26,0-0.47-0.21-0.47-0.47c0-0.26,0.21-0.47,0.47-0.47h5.43c0.3-0.77,0.7-1.41,1.2-2.06H5.25
        c-0.26,0-0.47-0.21-0.47-0.47c0-0.26,0.21-0.47,0.47-0.47h7.52c1.41-1.24,3.29-2.03,5.33-2.03c0.19,0,0.35,0.02,0.59,0.04V6.33
        h-3.53c-1.37,0-2.47-1.12-2.47-2.48V0.03H3.64c-1.17,0-2.1,0.95-2.1,2.13v17.98c0,1.17,0.93,2.1,2.1,2.1h7.1
        c-0.41-0.94-0.63-2-0.63-3.11C10.1,18.43,10.2,17.76,10.36,17.11z M5.25,10.22h5.97c0.26,0,0.47,0.21,0.47,0.47
        c0,0.26-0.21,0.47-0.47,0.47H5.25c-0.26,0-0.47-0.21-0.47-0.47C4.77,10.43,4.99,10.22,5.25,10.22z"
      />
      <path
        d="M18,12.01c-3.86,0-7,3.13-7,7c0,3.86,3.13,7,7,7c3.86,0,7-3.13,7-7C25,15.14,21.87,12.01,18,12.01z M20.29,22.35
        c-0.43,0.47-0.94,0.73-1.63,0.86v1.12c0,0.04,0,0.09-0.04,0.13c0,0.04-0.09,0.04-0.13,0.04h-0.86c-0.04,0-0.09,0-0.13,0.17
        c-0.04-0.04-0.04-0.09-0.04-0.13v-1.12c-0.3-0.04-0.56-0.13-0.82-0.21c-0.26-0.13-0.47-0.21-0.64-0.3
        c-0.17-0.09-0.34-0.17-0.47-0.3s-0.26-0.21-0.3-0.26c-0.09-0.09-0.13-0.09-0.13-0.13c-0.04-0.09-0.04-0.17,0-0.26l0.64-0.86
        c0-0.04,0.04-0.09,0.13-0.09c0.09-0.04,0.13,0,0.17,0.04c0.47,0.39,0.99,0.64,1.55,0.77c0.17,0.04,0.3,0.04,0.47,0.04
        c0.34,0,0.64-0.09,0.9-0.26c0.26-0.17,0.39-0.43,0.39-0.77c0-0.13-0.04-0.26-0.09-0.34c-0.09-0.09-0.13-0.17-0.21-0.26
        c-0.09-0.09-0.21-0.17-0.39-0.26c-0.17-0.09-0.3-0.17-0.43-0.21c-0.13-0.04-0.3-0.13-0.52-0.21c-0.17-0.09-0.3-0.13-0.39-0.17
        c-0.09-0.04-0.21-0.09-0.39-0.17c-0.17-0.09-0.3-0.17-0.39-0.21c-0.09-0.04-0.21-0.13-0.34-0.21c-0.13-0.09-0.26-0.17-0.34-0.26
        c-0.09-0.09-0.17-0.17-0.26-0.3c-0.09-0.13-0.17-0.26-0.21-0.39c-0.04-0.13-0.09-0.26-0.13-0.43c-0.04-0.17-0.04-0.34-0.04-0.52
        c0-0.6,0.17-1.12,0.6-1.55s0.99-0.73,1.63-0.86v-1.16h1.29v1.25c0.21,0,0.47,0.04,0.69,0.13c0.21,0.09,0.43,0.13,0.56,0.21
        c0.13,0.09,0.26,0.17,0.39,0.26c0.13,0.09,0.21,0.13,0.26,0.17c0.04,0.04,0.04,0.04,0.09,0.09c0.09,0.09,0.09,0.17,0.04,0.26
        l-0.52,0.94c0,0.04-0.09,0.09-0.13,0.09s-0.13,0-0.17-0.04c0-0.04-0.04-0.04-0.09-0.09c-0.04-0.04-0.13-0.09-0.26-0.17
        c-0.13-0.09-0.26-0.17-0.39-0.21c-0.13-0.09-0.26-0.13-0.47-0.17c-0.21-0.04-0.39-0.09-0.56-0.09c-0.39,0-0.73,0.09-0.99,0.26
        c-0.26,0.17-0.39,0.39-0.39,0.69c0,0.09,0,0.21,0.04,0.3c0,0.09,0.09,0.17,0.17,0.26c0.09,0.09,0.17,0.17,0.26,0.21
        c0.09,0.09,0.17,0.13,0.34,0.21c0.17,0.09,0.3,0.13,0.39,0.17c0.09,0.04,0.21,0.09,0.43,0.17c0.21,0.09,0.39,0.17,0.52,0.21
        c0.13,0.04,0.26,0.13,0.47,0.21c0.21,0.09,0.34,0.17,0.47,0.26c0.13,0.04,0.26,0.17,0.39,0.3c0.17,0.13,0.26,0.26,0.34,0.39
        c0.09,0.13,0.17,0.3,0.21,0.47c0.04,0.17,0.09,0.39,0.09,0.6C20.94,21.32,20.72,21.88,20.29,22.35z"
      />
    </svg>
  );
};

export default Bill;
/* eslint-enable max-len */
