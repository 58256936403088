/* eslint-disable max-len */
import React from 'react';

const InviteHorseShoe = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M15.2,5.2l-6-4.8C8.8,0.2,8.4,0,8,0S7.1,0.2,6.8,0.4l0,0L1,5.1C0.4,5.6,0,6.3,0,7.1c0,0,0,0,0,0v0v6.7C0,15,1,16,2.2,16l0,0
        h11.7c1.2,0,2.2-1,2.2-2.2l0,0V6.9C16,6.2,15.7,5.6,15.2,5.2L15.2,5.2L15.2,5.2z M8,2.7c1.5,0,2.6,1.4,2.6,3c0,0.5-0.1,1-0.3,1.5
        l0,0l-0.2,0.4c0,0-0.3,0.6-0.3,0.6C9.7,8.3,9.6,8.3,9.5,8.3c-0.1,0-0.1,0-0.2-0.1l0,0L8.6,7.9C8.6,7.8,8.5,7.7,8.5,7.6
        c0-0.1,0-0.1,0.1-0.2l0,0L8.8,7C8.9,6.9,9,6.9,9.1,6.9c0.1,0,0.1,0,0.2,0l0,0l0.1-0.2c0.2-0.3,0.2-0.7,0.2-1l0,0c0-1.1-0.7-2-1.6-2
        s-1.6,0.9-1.6,2c0,0.4,0.1,0.7,0.2,1.1l0,0l0.1,0.2c0,0,0.1,0,0.2,0C7,6.9,7.1,6.9,7.1,7l0,0l0.3,0.5c0,0,0,0.1,0,0.2
        c0,0.1,0,0.2-0.1,0.2l0,0L6.7,8.3c0,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.1l0,0c0,0-0.3-0.5-0.3-0.6L5.7,7.2C5.5,6.8,5.4,6.3,5.4,5.7
        v0C5.4,4,6.5,2.7,8,2.7L8,2.7z M1.8,14.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4,0-0.7-0.3-0.7-0.7v0V7.6c0-0.4,0.3-0.7,0.7-0.7
        c0.1,0,0.3,0,0.4,0.1l0,0l4,2.8C5.9,9.9,6,10.2,6,10.4c0,0.2-0.1,0.4-0.3,0.6l0,0L1.8,14.2z M13,15.5H3.1c0,0,0,0,0,0
        c-0.4,0-0.7-0.3-0.7-0.7c0-0.2,0.1-0.4,0.2-0.5l0,0l5-4C7.7,10.1,7.8,10,8,10c0.2,0,0.3,0.1,0.5,0.2l0,0l5,4
        c0.1,0.1,0.2,0.3,0.2,0.5C13.7,15.1,13.4,15.5,13,15.5L13,15.5L13,15.5z M15.4,13.6c0,0.4-0.3,0.7-0.7,0.7c-0.2,0-0.3-0.1-0.4-0.1
        l0,0l-4-3.3c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.2,0.1-0.4,0.3-0.6l0,0l4-2.8c0.1-0.1,0.3-0.1,0.4-0.1c0.4,0,0.7,0.3,0.7,0.7v0L15.4,13.6z
        M10,5.4l0,0.5C10,5.9,10.1,6,10.1,6c0,0,0,0,0,0h0c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0v0l0-0.5c0-0.1-0.1-0.1-0.1-0.1
        c0,0,0,0,0,0h0c0,0,0,0,0,0C10,5.2,10,5.3,10,5.4C10,5.4,10,5.4,10,5.4L10,5.4z M10.1,6.4C10.1,6.4,10,6.4,10.1,6.4
        c-0.1,0-0.2,0-0.2,0.1l0,0L9.7,7c0,0,0,0,0,0.1c0,0.1,0,0.1,0.1,0.1l0,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.1-0.1l0,0l0.2-0.4
        c0,0,0,0,0-0.1C10.2,6.5,10.1,6.5,10.1,6.4L10.1,6.4L10.1,6.4z M9.8,4.7c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1
        l0,0L9.8,4.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0l0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0.1l0,0L9.8,4.7z M8.8,3.5l0.4,0.2
        c0,0,0,0,0.1,0c0.1,0,0.1,0,0.1-0.1l0,0c0,0,0,0,0-0.1c0-0.1,0-0.1-0.1-0.1l0,0L8.9,3.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2
        C8.7,3.4,8.7,3.5,8.8,3.5L8.8,3.5L8.8,3.5z M7.8,3.3h0.5c0.1,0,0.1-0.1,0.1-0.1S8.3,3,8.2,3l0,0H7.8C7.7,3,7.6,3.1,7.6,3.2l0,0
        C7.6,3.3,7.7,3.3,7.8,3.3C7.8,3.3,7.8,3.3,7.8,3.3z M6.8,3.8l0.4-0.3c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1l0,0c0,0-0.1-0.1-0.1-0.1
        c0,0-0.1,0-0.1,0h0L6.7,3.5c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1l0,0C6.6,3.8,6.7,3.8,6.8,3.8C6.8,3.8,6.8,3.8,6.8,3.8L6.8,3.8z
        M6,4.7C6,4.7,6,4.7,6,4.7c0.1,0,0.2,0,0.2,0l0,0l0.2-0.4c0,0,0,0,0-0.1c0-0.1,0-0.1-0.1-0.1l0,0c0,0,0,0-0.1,0
        c-0.1,0-0.1,0-0.1,0.1l0,0L5.9,4.5c0,0,0,0,0,0.1C5.9,4.7,5.9,4.7,6,4.7L6,4.7L6,4.7z M6.2,6.5c0-0.1-0.1-0.1-0.1-0.1
        c0,0-0.1,0-0.1,0l0,0c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1l0,0l0.2,0.4c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0l0,0
        c0.1,0,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1l0,0L6.2,6.5z M5.9,6C6,6,6,5.9,6,5.9l0,0l0-0.5c0,0,0,0,0,0c0-0.1-0.1-0.2-0.2-0.2
        c0,0,0,0,0,0h0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0l0,0v0.5C5.7,5.9,5.8,6,5.9,6C5.9,6,5.9,6,5.9,6z"
      />
    </svg>
  );
};

export default InviteHorseShoe;
/* eslint-enable max-len */
