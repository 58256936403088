/* eslint-disable max-len */
import React from 'react';

const EditOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,0c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S3.6,0,8,0z M8.8,5.9l-4,4v1.4h1.4l4-4L8.8,5.9z M10.2,4.8c-0.3,0-0.2-0.1-0.4,0.1L9.1,5.5l1.4,1.4l0.7-0.7h0c0.1-0.1,0.1-0.4,0-0.5l-0.8-0.9L10.2,4.8L10.2,4.8z" />
    </svg>
  );
};

export default EditOutline;
/* eslint-enable max-len */
