/* eslint-disable max-len */
import React from 'react';

const MoreHorizontal = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,6C6.9,6,6,6.9,6,8s0.9,2,2,2c1.1,0,2-0.9,2-2l0,0C10,6.9,9.1,6,8,6L8,6z M14,6C14,6,14,6,14,6c-1.1,0-2,0.9-2,2
        s0.9,2,2,2c1.1,0,2-0.9,2-2l0,0l0,0C16,6.9,15.1,6,14,6L14,6z M2,6C0.9,6,0,6.9,0,8s0.9,2,2,2c1.1,0,2-0.9,2-2l0,0C4,6.9,3.1,6,2,6L2,6z"
      />
    </svg>
  );
};

export default MoreHorizontal;
/* eslint-enable max-len */
