/* eslint-disable max-len */
import React from 'react';

const Checked = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,0L8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7.4,11L3.9,7.4L5,6.3l2.4,2.3L11,5.1l1.2,1.2C12.1,6.3,7.4,11,7.4,11z" />
    </svg>
  );
};

export default Checked;
/* eslint-enable max-len */
