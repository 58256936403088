import { connect } from 'react-redux';
import t from 'resources/translations';

const HorseFatigue = ({ fatigue, isMobile }) => {
  if (!fatigue) return null;
  let fatigueColor = '';
  if (fatigue.fatigue_total < 12) fatigueColor = '#00bc1f';
  else if (fatigue.fatigue_total < 22) fatigueColor = '#e2c707';
  else if (fatigue.fatigue_total < 32) fatigueColor = '#ff7900';
  else fatigueColor = '#b22302';
  const { hours_weekly, hours_tridaily, hours_daily } = fatigue;
  return (
    <span style={{ backgroundColor: fatigueColor }} className="text-base font-semibold text-white rounded px-1 py-px">
      {!isMobile ? t('general.fatigue', fatigue) : `${hours_weekly}h / ${hours_tridaily}h / ${hours_daily}h`}
    </span>
  );
};

HorseFatigue.defaultProps = {
  fatigue: null,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(HorseFatigue);
