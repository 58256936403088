import { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { cloneDeep, unset } from 'lodash';

import { LogoHmNotextIcon } from 'old/assets/svg';
import { getErrorMessage, notify } from 'old/utils';
import Model from '@old/model';
import t from 'resources/translations';
import BoxWhite from '@old/components/common/BoxWhite';
import BoxShadow from '@old/components/common/BoxShadow';
import FlexColumn from '@old/components/common/FlexColumn';
import Button from '@old/components/guide/Button';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_REGISTER } from 'old/constants/formNames';
import config from '@old/config';
import { A, Box } from 'components/elements';

const Register = ({ location, history }) => {
  const [registrationDone, setRegistrationDone] = useState(false);
  const [invitationToken, farmId, errorFromApi] = useMemo(
    () => [
      queryString.parse(location.search).invitation_token || null,
      queryString.parse(location.search).farm_id || null,
      queryString.parse(location.search).error || null,
    ],
    [location.search]
  );

  useEffect(() => {
    if (errorFromApi !== null) {
      history.push('/');
    }
  }, [errorFromApi]); // eslint-disable-line react-hooks/exhaustive-deps

  const minBirthdayDate = moment().subtract(120, 'years');
  const maxBirthdayDate = moment().subtract(6, 'years');

  const onSubmit = async formState => {
    const { firstname, lastname, email, phone, password, birthday, term, policy } = formState;
    const additionalUserData = invitationToken ? { invitation_token: invitationToken } : { email };

    const userData = {
      firstname,
      lastname,
      phone,
      password,
      birthday,
      terms_accepted: term && policy,
    };

    const value = {
      user: {
        ...userData,
        birthday: moment(userData.birthday, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        ...additionalUserData,
      },
      farm_id: farmId,
    };

    try {
      if (invitationToken && farmId) {
        await Model.Members.invitation(value);
      } else {
        await Model.Members.signUp(value.user);
      }
      notify(t('message.succesfullyRegistered'));
      setRegistrationDone(true);
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };

  const redirectToLoginPage = async () => {
    history.replace('/');
  };

  const validations = {
    password: [
      {
        condition: (value, formState) => {
          if (!value) return false;
          return value === formState.password.value;
        },
        errorHint: t('error.passwordsMustBeSame'),
      },
    ],
  };

  const filterInitValues = initialVals => {
    const filteredInitVals = cloneDeep(initialVals);
    if (invitationToken) unset(filteredInitVals, 'email');
    return filteredInitVals;
  };

  const initValues = filterInitValues({
    firstname: '',
    lastname: '',
    birthday: '',
    phone: '',
    email: '',
    password: '',
    'confirm-password': '',
    term: false,
    policy: false,
  });

  return (
    <BoxShadow className="page-register">
      <div className="login-banner">
        <LogoHmNotextIcon width={120} height={120} />
      </div>
      <BoxWhite padding>
        <FlexColumn>
          <h1 className="text-headline">{t('register.registration')}</h1>
          {!registrationDone && (
            <Form formName={FORM_REGISTER} initValues={initValues} noPadding>
              <Field.Text
                name="firstname"
                label={t('labels.firstname')}
                placeholder={t('placeholders.firstname')}
                required
              />

              <Field.Text
                name="lastname"
                label={t('labels.lastname')}
                placeholder={t('placeholders.lastname')}
                required
              />
              <Field.DateValue
                name="birthday"
                label={t('labels.birthDate')}
                placeholder={t('placeholders.date')}
                minDate={minBirthdayDate}
                maxDate={maxBirthdayDate}
                defaultDate={moment().subtract(18, 'years')}
                required
              />

              <Field.Phone
                name="phone"
                label={t('labels.phone')}
                placeholder={t('placeholders.phone')}
                defaultDirectionalValue={config.defaultDirectional.value}
                required
              />

              {!invitationToken && (
                <Field.Email name="email" label={t('labels.email')} placeholder={t('placeholders.email')} required />
              )}

              <Field.Password
                name="password"
                label={t('labels.password')}
                placeholder={t('placeholders.password')}
                required
              />

              <Field.Password
                name="confirm-password"
                label={t('labels.confirmPassword')}
                placeholder={t('placeholders.repeatPassword')}
                validation={validations.password}
                required
              />

              <Field.Checkbox name="term" label={t('labels.termsAndConditionsDeclare')} required />

              <Field.Checkbox name="policy" label={t('labels.policyAndConditionsDeclare')} required />

              <Box>
                <A href="https://horsemanago.com/pl/terms-of-service/" fontSize="sm" isExternal>
                  {t('register.terms')}
                </A>
                <span>{` ${t('general.and')} `}</span>
                <A href="https://horsemanago.com/pl/privacy-policy/" fontSize="sm" isExternal>
                  {t('register.privacyPolicy')}
                </A>
              </Box>
              <FormButton formName={FORM_REGISTER} onClick={onSubmit} fluid>
                {t('register.createAccount')}
              </FormButton>
            </Form>
          )}

          {registrationDone && (
            <FlexColumn>
              <p>{t('register.succesfullyRegistered')}</p>
              <Button onClick={redirectToLoginPage} fluid>
                {t('register.login')}
              </Button>
            </FlexColumn>
          )}
        </FlexColumn>
      </BoxWhite>
    </BoxShadow>
  );
};

Register.defaultProps = {
  location: {},
};

Register.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func,
  }).isRequired,
};

export default Register;
