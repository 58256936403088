import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import cls from 'classnames';

import SearchInput from '@old/components/custom/SearchInput';
import { useFetchOptions } from '@old/hooks';
import OptionLoader from '@old/components/custom/OptionLoader';
import t from 'resources/translations';
import FlexColumn from '@old/components/common/FlexColumn';
import Option from '@old/components/view/listItem/SelectOption';
import { useShallowSelector } from 'old/hooks';

const SelectOptionList = ({
  value,
  onChange,
  loadOptions,
  customFilter,
  optionComponent,
  isMulti,
  isOptionDisabled,
  isSearchable,
  isOptionSelected,
  name,
}) => {
  const isMobile = useShallowSelector(({ app }) => app.isMobile);
  const OptionComponent = optionComponent || Option.Default;
  const offset = 100;
  const [query, setQuery] = useState();
  const [options, statuses, fetchNext, searchByName] = useFetchOptions(loadOptions, customFilter);

  const checkIsSelected = option => {
    if (isOptionSelected) {
      return isOptionSelected(option);
    } else {
      if (isMulti) {
        return !!(value || []).find(selectedOption => selectedOption.value === option.value);
      }
      return value && value.value === option.value;
    }
  };

  const toggleSelected = (option, isSelected) => {
    if (option.isGroup) {
      // we also assume that isMulti === true
      // In some cases group option is not selected but we also have no way to select it eg.:
      // in add participant modal we have group: [a, b, c]
      // a - is selected and saved in event (and therefore is disabled too)
      // b - is disabled and can't be selected (becasue has 'invited' status),
      // c - is selected (to add)
      // in above situation after clicking on group option we should unselect c even if group is not selected
      const nothingToSelect = option.value.every(item => isOptionSelected(item) || isOptionDisabled(item));
      if (!isSelected && !nothingToSelect) {
        onChange([...value, ...option.value]);
      } else {
        onChange(value.filter(selectedOption => !option.value.map(item => item.value).includes(selectedOption.value)));
      }
    } else if (!isSelected) {
      onChange(isMulti ? [...value, option] : option);
    } else {
      onChange(isMulti ? value.filter(selectedOption => selectedOption.value !== option.value) : null);
    }
  };

  const changeQuery = val => {
    setQuery(val);
    searchByName(val);
  };

  const throttleEventHandler = (...args) => {
    const throttled = throttle(...args);
    return e => {
      e.persist();
      return throttled(e);
    };
  };

  const scrolledToBottom = e => {
    const isBottom = e.target.scrollHeight - offset - e.target.scrollTop < e.target.clientHeight;
    if (!statuses.isPending && !statuses.isNoMorePage && isBottom) {
      fetchNext(options);
    }
  };

  if (!options) {
    return null;
  }
  return (
    <FlexColumn separated="small">
      {isSearchable && (
        <SearchInput
          name={name}
          label={t('general.search')}
          value={query}
          onChange={changeQuery}
          clear={() => changeQuery('')}
          placeholder={t('placeholders.searchByName')}
        />
      )}
      <div
        className={cls('select-list-container', { mobile: isMobile })}
        onScroll={throttleEventHandler(scrolledToBottom, 50)}
      >
        {options.map(option => {
          const isSelected = checkIsSelected(option);
          const isDisabled = isOptionDisabled ? isOptionDisabled(option) : false;
          return (
            <OptionComponent
              key={option.key}
              data={option}
              innerProps={{ onClick: !isDisabled ? () => toggleSelected(option, isSelected) : () => {} }}
              isSelected={isSelected}
              isDisabled={isDisabled}
            />
          );
        })}
        {statuses.isPending && <OptionLoader />}
      </div>
    </FlexColumn>
  );
};

SelectOptionList.defaultProps = {
  value: '',
  isMulti: false,
  customFilter: () => true,
  optionComponent: null,
  isOptionDisabled: null,
  isSearchable: true,
  isOptionSelected: null,
  name: 'search',
};

SelectOptionList.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  isOptionDisabled: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  customFilter: PropTypes.func,
  optionComponent: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isOptionSelected: PropTypes.func,
  name: PropTypes.string,
};

export default SelectOptionList;
