import { Stack, Box } from 'components/elements';
import SimpleBar from 'simplebar-react';
import { useAppSelector, getMaxHeight } from 'utils';
import { IStack } from 'resources/types/elementsTypes';
import { TBoxProps, TStackProps } from 'resources/types/elementsTypes';

const contentStyleProps = {
  px: ['md', 'xl'],
  overflowY: 'auto',
  overflowX: 'hidden',
} as TStackProps;

const requestBoxStyleProps = {
  opacity: '0.5',
  position: 'absolute',
  width: '100%',
  height: 'calc(100% - 71px)',
  top: '71px',
  left: '0',
  backgroundColor: 'white',
  zIndex: 10,
  borderRadius: '16px',
} as TBoxProps;

type TModalContent = IStack & {
  isMakingRequest?: boolean;
};

const ModalContent = ({ children, isMakingRequest, ...stackProps }: TModalContent) => {
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  return (
    <Box mb={['md', 'xl']}>
      <SimpleBar style={{ maxHeight: getMaxHeight(isMobile ? 210 : 315) }}>
        <Stack {...contentStyleProps} {...stackProps}>
          {children}
        </Stack>
      </SimpleBar>
      {isMakingRequest && <Box {...requestBoxStyleProps} />}
    </Box>
  );
};

export default ModalContent;
