import React from 'react';
import { Button, Flex, Icons, Span, Stack, Text } from 'components/elements';
import _ from 'lodash';
import { TagLabel, TagRemoveButton, Wrap } from '..';
import Tag from '../tags/Tag';
import InputWrapper from './InputWrapper';
import { TFetchedOptions, TOption } from 'resources/types/commonTypes';
import { useEffect, useState } from 'react';
import SelectModal from '../select/SelectModal';
import { useInfiniteQuery } from 'react-query';
import { useSearchQuery } from 'utils';
import { useModal } from 'old/hooks';

type TSelectWithModal = {
  name: string;
  placeholder: string;
  value?: any[];
  onChange: (value: TOption[]) => void;
  loadOptions: ({ pageParam, keyword }: { pageParam: number; keyword: string }) => Promise<TFetchedOptions>;
  translations?: {
    title?: string;
    searchLabel?: string;
    searchPlaceholder?: string;
  };
  disabled?: boolean;
  customModalComponent?: any;
};
const SelectWithModal = ({
  name,
  placeholder,
  value = [],
  onChange,
  loadOptions,
  translations,
  disabled,
  customModalComponent,
}: TSelectWithModal) => {
  const { isOpen, onOpen, ...modalProps } = useModal(`${name.toUpperCase()}_SELECT_WITH_MODAL`);
  const [selectedOptions, setSelectedOptions] = useState<TOption[]>(value);
  const search = useSearchQuery();

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onChange([]);
    setSelectedOptions([]);
  };

  const onRemove = (option: TOption) => {
    const copyValue = _.cloneDeep(value);
    const newValue = copyValue.filter(valueItem => valueItem.value !== option.value);
    onChange(newValue);
    setSelectedOptions(newValue);
  };

  const optionsState = useInfiniteQuery(
    [`OPTIONS_${name.toUpperCase()}`, { keyword: search.searchQuery }],
    ({ pageParam }) => loadOptions({ pageParam: pageParam || 1, keyword: search.searchQuery || '' }),
    {
      getNextPageParam: ({ additional }) => {
        return additional.page;
      },
      refetchOnWindowFocus: false,
    }
  );

  const onOpenModal = () => {
    if (!disabled) {
      /* @ts-ignore m */
      onOpen();
    }
  };

  const isEmpty = _.isEmpty(value);

  const CustomModalComponent = customModalComponent;

  return (
    <>
      <Button variant="clean" width="100%" onClick={onOpenModal} disabled={disabled}>
        <InputWrapper
          pl="sm"
          pr={0}
          alignItems="center"
          justifyContent="space-between"
          borderColor={isOpen ? 'teal' : 'new-grey-light'}
          disabled={disabled}
        >
          <Flex w="full" minW={0}>
            {isEmpty && (
              <Text
                color="new-grey-light"
                py="sm"
                textAlign="left"
                pl="sm"
                {...(disabled ? { userSelect: 'none' } : {})}
              >
                {placeholder}
              </Text>
            )}
            <Wrap spacing="xs" py={7} maxW="100%">
              {(value || []).map((option, index) => (
                <Tag key={`${name}-${index}`}>
                  <TagLabel>{option.label}</TagLabel>
                  <TagRemoveButton onClick={() => onRemove(option)} />
                </Tag>
              ))}
            </Wrap>
          </Flex>
          <Flex flex="none" alignItems="center" justifyContent="center" alignSelf="stretch">
            <Stack spacing={0} isHorizontal>
              {!isEmpty && (
                <Button onClick={onClear} variant="icon" px="base">
                  <Icons.Cancel squareSize={14} mx="auto" />
                </Button>
              )}
              <Span backgroundColor="new-grey-light" w={1} my={-5} />
              <Button onClick={onOpenModal} variant="icon" px="base" disabled={disabled}>
                <Icons.Plus squareSize={16} mx="auto" />
              </Button>
            </Stack>
          </Flex>
        </InputWrapper>
      </Button>
      {isOpen && !disabled && (
        <React.Fragment>
          {!!customModalComponent ? (
            <CustomModalComponent
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              optionsState={optionsState}
              onChange={onChange}
              search={search}
              translations={translations}
              placeholder={placeholder}
              isOpen={isOpen}
              {...modalProps}
            />
          ) : (
            <SelectModal
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              optionsState={optionsState}
              onChange={onChange}
              search={search}
              translations={translations}
              placeholder={placeholder}
              isOpen={isOpen}
              {...modalProps}
            />
          )}
        </React.Fragment>
      )}
    </>
  );
};

export default SelectWithModal;
