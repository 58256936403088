/* eslint-disable max-len */
import React from 'react';

const SelectedAvatar = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 40 40"
    >
      <path d="M20,0C9,0,0,9,0,20c0,11,9,20,20,20s20-9,20-20C40,9,31,0,20,0z M28,17.4l-9,8.9c-0.8,0.8-2.1,0.8-2.9,0
      L12.6,23c0,0,0,0,0,0c-0.8-0.8-0.8-2.1,0-2.8c0.8-0.8,2.1-0.8,2.9,0l2,2l7.6-7.5c0.4-0.4,0.9-0.6,1.5-0.6c0.5,0,1.1,0.2,1.4,0.6
      c0,0,0,0,0,0C28.8,15.4,28.8,16.7,28,17.4z"
      />
    </svg>
  );
};

export default SelectedAvatar;
/* eslint-enable max-len */
