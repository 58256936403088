import { combineReducers } from 'redux';

import calendarReducer from './calendarReducer';
import dataReducer from './dataReducer';
import filtersReducer from './filtersReducer';
import modalsReducer from './modalsReducer';
import modalReducer from './modalReducer';
import paginationReducer from './paginationReducer';
import searchReducer from './searchReducer';
import ratingsReducer from './ratingsReducer';
import notificationsReducer from './notificationsReducer';
import formReducer from './formReducer';
import tabsReducer from './tabsReducer';
import app from 'store/app';
import auth from 'store/auth';
import contextMenu from 'store/contextMenu';

export default combineReducers({
  app: app,
  auth: auth,
  contextMenu: contextMenu,
  calendar: calendarReducer,
  fetchingData: dataReducer,
  filters: filtersReducer,
  modals: modalsReducer,
  pagination: paginationReducer,
  search: searchReducer,
  ratings: ratingsReducer,
  notifications: notificationsReducer,
  form: formReducer,
  tabs: tabsReducer,
  modal: modalReducer,
});
