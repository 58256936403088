import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import PageTitle from '@old/components/guide/PageTitle';
import BuyConfirmationSvg from '@old/assets/svg/BuyConfirmation.svg';
import t from 'resources/translations';
import FlexColumn from '@old/components/common/FlexColumn';

const SmsConfirm = ({ history }) => {
  // redirects users to /sms page if they write the link directly to the browser
  if (!(history.location.state && history.location.state.selectedPackage)) {
    history.replace('/sms');
  }

  return (
    <>
      <PageTitle title={t('smsManagementPage.buyConfirmation')} back="/sms" />
      <div className="flex flex-col text-center mx-4">
        <FlexColumn>
          <img src={BuyConfirmationSvg} alt="maintenance-mode" className="my-0 mx-auto" />
          <FlexColumn separated={false}>
            <div className="text-headline">{t('smsManagementPage.success')}</div>
            <div className="font-bold">{t('smsManagementPage.orderProcessed')}</div>
            <div className="font-bold">{t('smsManagementPage.smsPackageTransferred')}</div>
          </FlexColumn>
          <div>
            {t('smsManagementPage.ifQuestions')}
            <a className="text-teal" href="mailto:kontakt@horsemanago.com">kontakt@horsemanago.com</a>
          </div>
        </FlexColumn>
      </div>
    </>
  );
};

SmsConfirm.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        selectedPackage: PropTypes.shape({}),
      }),
    }).isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(SmsConfirm);
