import React from 'react';
import PropTypes from 'prop-types';

import InputWrapper from '@old/components/common/InputWrapper';

const TelInput = ({ value, name, onChange, placeholder, label, required, icon, ...props }) => {
  const onInputChange = (e) => {
    const reg = new RegExp('^$|^\\d+$');
    if (reg.test(e.target.value)) {
      onChange(e.target.value, name);
    }
  };

  return (
    <InputWrapper label={label} icon={icon} required={required}>
      <input
        type="tel"
        name={name}
        value={value}
        onChange={onInputChange}
        placeholder={placeholder}
        {...props}
      />
    </InputWrapper>
  );
};

TelInput.defaultProps = {
  label: '',
  value: '',
  name: '',
  placeholder: '',
  required: false,
  icon: null,
};

TelInput.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.node,
};

export default TelInput;
