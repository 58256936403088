import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';
import {
  removeHTMLTags,
} from 'old/utils';
import t from 'resources/translations';

const RichTextEditorField = ({ onChangeField, placeholder, label, validation, ...props }) => {
  const { formName, name, required } = props;

  const onChange = (value) => {
    onChangeField({ formName, fieldName: name, fieldState: value });
  };

  let validationFuncs = [
    ...validation,
  ];

  if (required) {
    validationFuncs = [
      ...validationFuncs,
      {
        condition: value => !!removeHTMLTags(value),
        errorHint: t('error.fieldIsRequired'),
      },
    ];
  }

  return (
    <FormField validation={validationFuncs} {...props}>
      <Input.TextEditor
        defaultValue={props.value}
        label={label}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        value={props.value}
      />
    </FormField>
  );
};

RichTextEditorField.defaultProps = {
  value: null,
  placeholder: '',
  label: '',
  required: false,
  validation: [],
};

RichTextEditorField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  formName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.func.isRequired,
      errorHint: PropTypes.string,
    }),
  ),
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(RichTextEditorField);
