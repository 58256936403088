import { Box, Button, Icons, Label, Span, Stack, Text, Tooltip } from 'components/elements';
import { TLabelProps } from 'resources/types/elementsTypes';
import { useAppStore } from 'utils/storeUtils';

type TFormField = {
  children: JSX.Element | JSX.Element[] | string | string[];
  htmlFor: string;
  required?: boolean;
  info?: string;
  disabled?: boolean;
} & TLabelProps;

const FormLabel = ({ children, htmlFor, required = false, info, disabled, ...labelStyleProps }: TFormField) => {
  const { isMobile } = useAppStore();

  return (
    <Box mb="xs" w="full" userSelect="none">
      <Label
        htmlFor={htmlFor}
        display="flex"
        alignItems="center"
        fontWeight="medium"
        fontSize="base"
        color={!disabled ? 'grey-dark' : 'grey'}
        {...labelStyleProps}
      >
        {children}
        {required && <Span color={disabled ? 'grey' : 'orange'}>*</Span>}
        {info && !disabled && !isMobile && (
          <Tooltip content={info}>
            <Button variant="clean" ml="sm">
              <Icons.InfoCircle
                bgColor="transparent"
                border={{ borderWidth: 2, borderColor: 'teal-dark' }}
                fill="teal-dark"
                size="xs"
              />
            </Button>
          </Tooltip>
        )}
      </Label>
      {info && !disabled && isMobile && (
        <Stack spacing="xs" my="sm" alignItems="center" isHorizontal>
          <Icons.InfoCircle
            bgColor="transparent"
            border={{ borderWidth: 2, borderColor: 'teal-dark' }}
            color="teal-dark"
            size="xs"
          />
          <Text fontSize="xs" color="teal-dark">
            {info}
          </Text>
        </Stack>
      )}
    </Box>
  );
};

export default FormLabel;
