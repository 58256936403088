import { connect } from 'react-redux';
import cls from 'classnames';
import { truncate } from 'lodash';
import { useQueryClient } from 'react-query';

import Link from '@old/components/common/Link';
import EventParticipationItemsGrid from '@old/components/view/grid/EventParticipation';
import Model from '@old/model';
import TextValue from '@old/components/common/TextValue';
import Box from '@old/components/common/Box';
import FlexColumn from '@old/components/common/FlexColumn';
import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import FlexRow from '@old/components/common/FlexRow';
import Subtitle from '@old/components/common/Subtitle';
import TitleWhite from '@old/components/common/TitleWhite';
import TagCircle from '@old/components/common/TagCircle';
import Tooltip from '@old/components/common/Tooltip';
import t from 'resources/translations';
import { datesIsSame } from 'old/utils';
import { useEventStatus } from '@old/hooks';
import {
  useEventJoinButton,
  useEventUnjoinButton,
  useEventRejectInvitationButton,
  useEventAcceptInvitationButton,
  useEventActivateButton,
  useEventEditButton,
  useEventDeleteButton,
  useEventCancelButton,
  useEventCopyButton,
  useEventRepeatButton,
} from 'old/buttonHooks';
import config from '@old/config';
import Spinner from '@old/components/common/Spinner';
import { ContextTrigger } from 'old/components/common/ContextMenu';
import { useOldLoggedMember } from 'utils/storeUtils';

const EventListItem = ({ event, isMobile, loading, deleteFromList, newCache, fetchParams }) => {
  const loggedMember = useOldLoggedMember();
  const queryClient = useQueryClient();
  const cbFetch = async () => {
    const data = await Model.Events.fetch(event.id);
    const prevEvents = queryClient.getQueryData([
      'events',
      {
        ...fetchParams,
      },
    ]);
    const updatedEvents = [...prevEvents[0]];
    const index = updatedEvents.findIndex(e => e.id === event.id);
    if (index !== -1) {
      updatedEvents[index] = data;
      queryClient.setQueryData(
        [
          'events',
          {
            ...fetchParams,
          },
        ],
        [updatedEvents, prevEvents[1]]
      );
    }
  };

  const cbDelete = () => {
    const prevEvents = queryClient.getQueryData([
      'events',
      {
        ...fetchParams,
      },
    ]);
    const updatedEvents = [...prevEvents[0]];
    const index = updatedEvents.findIndex(e => e.id === event.id);
    if (index !== -1) {
      updatedEvents.splice(index, 1);
      queryClient.setQueryData(
        [
          'events',
          {
            ...fetchParams,
          },
        ],
        [updatedEvents, prevEvents[1]]
      );
    }
  };
  const eventActivate = useEventActivateButton(event, { newCache }, newCache && cbFetch);
  const eventEdit = useEventEditButton(event);
  const eventDelete = useEventDeleteButton(event, { newCache }, newCache && cbDelete);
  const eventCancel = useEventCancelButton(event, { newCache }, newCache && cbFetch);
  const eventCopy = useEventCopyButton(event);
  const eventRepeat = useEventRepeatButton(event);
  const eventJoin = useEventJoinButton(event, { loggedMember, newCache }, newCache && cbFetch);
  const eventUnjoin = useEventUnjoinButton(event, { loggedMember, deleteFromList, newCache }, newCache && cbFetch);
  const eventRejectInvitation = useEventRejectInvitationButton(
    event,
    { loggedMember, deleteFromList, newCache },
    newCache && cbFetch
  );
  const eventAcceptInvitation = useEventAcceptInvitationButton(
    event,
    { loggedMember, deleteFromList, newCache },
    newCache && cbFetch
  );

  const buttons = [
    eventAcceptInvitation,
    eventRejectInvitation,
    eventJoin,
    eventUnjoin,
    eventActivate,
    eventEdit,
    eventDelete,
    eventCancel,
    eventCopy,
    eventRepeat,
  ];

  const status = useEventStatus(event, loggedMember);
  if (!event || !status) {
    return null;
  }

  const statusBarProps = event.getStatusBarProps();
  const isLoading = Array.isArray(loading) && loading.includes(event.id);

  return (
    <div className="relative">
      <ContextTrigger menuItems={buttons} disabled={isLoading}>
        {isMobile ? (
          <EventListItemMobile event={event} statusBarProps={statusBarProps} isLoading={isLoading} />
        ) : (
          <EventListItemDesktop event={event} statusBarProps={statusBarProps} isLoading={isLoading} />
        )}
      </ContextTrigger>
    </div>
  );
};

EventListItem.defaultProps = {
  deleteFromList: false,
  newCache: false,
  fetchParams: {},
};

const mapStateToProps = ({ app: { isMobile }, fetchingData: { loading } }) => ({
  loading: loading.events,
  isMobile,
});

export default connect(mapStateToProps)(EventListItem);

const EventListItemDesktop = ({ event, statusBarProps, isLoading }) => {
  const places = (event.places || []).map(place => place.name.toLowerCase()).join(', ');
  const instructorsName = event
    .getInstructors(true)
    .map(instructor => instructor.getFullName())
    .join(', ');
  const tooltipContent = event
    .getJoinedParticipants()
    .map(participant => truncate(participant.getFullName()), { lenght: 25, omission: '...' })
    .join('\n');
  const renderDate = () => (
    <div>
      {event.startDate.format(config.dateFormat)}
      {!datesIsSame(event.startDate, event.endDate) && (
        <span>
          {' '}
          /<div>{event.endDate.format(config.dateFormat)}</div>
        </span>
      )}
    </div>
  );
  return (
    <Box>
      {event.status === 'cancelled' && (
        <div className="pointer-events-none bg-white-transparent left-0 top-0 absolute w-full h-full" />
      )}
      <FlexRow cols="none/1" noShrink>
        <Link to={`/events/${event.id}`} forceRender>
          <div className={cls('centered text-white p-2', statusBarProps.color)} style={{ width: 144, height: 144 }}>
            <FlexColumn>
              <div className="flex-1">
                <TitleWhite>
                  {event.startDate.format('HH:mm')} - {event.endDate.format('HH:mm')}
                </TitleWhite>
                {renderDate()}
              </div>
              <div>{statusBarProps.status.toUpperCase()}</div>
            </FlexColumn>
          </div>
        </Link>
        <div>
          <FlexColumn separated="small">
            <div className={cls(!event.special && 'mb-4')}>
              <RowAligner className="justify-between">
                <Link to={`/events/${event.id}`} forceRender>
                  <Subtitle>{event.name}</Subtitle>
                </Link>
                <div>
                  <Tooltip content={tooltipContent}>
                    <div style={{ minWidth: 80 }}>
                      <RowAligner separated="small">
                        <Icon.Users className="fill-teal" />
                        <span>
                          {event.getJoinedParticipants().length}/{event.attendees.max}
                        </span>
                      </RowAligner>
                    </div>
                  </Tooltip>
                </div>
              </RowAligner>
              <div>{instructorsName}</div>
            </div>
            <div>
              <FlexRow cols="4/6" noShrink>
                <div>
                  {event.special && <Icon.Star small className="fill-teal" />}
                  <TextValue text={t('eventListItem.type')} value={event.type && event.type.getName().toLowerCase()} />
                  <TextValue text={t('model.place', { count: event.places.length })} value={places} />
                  <TextValue text={t('general.level')} value={t(`skills.${event.getDifficulty()}`).toLowerCase()} />
                </div>
                {event.horses.length > 0 && (
                  <FlexColumn separated="small">
                    <div className="text-highlight">{t('model.horses')}: </div>
                    <EventParticipationItemsGrid event={event} model="horses" itemUrl="/horses" />
                  </FlexColumn>
                )}
              </FlexRow>
            </div>
          </FlexColumn>
        </div>
      </FlexRow>
      {event.edited && (
        <div className="absolute text-teal bottom-2 right-4">
          <Tooltip content={t('eventListItem.editedEvent')}>
            <Icon.Warning />
          </Tooltip>
        </div>
      )}
      {isLoading && <Spinner noDelay />}
    </Box>
  );
};

EventListItemDesktop.defaultProps = {
  isLoading: false,
};

const EventListItemMobile = ({ event, statusBarProps, isLoading }) => {
  const instructorsName = event
    .getInstructors(true)
    .map(instructor => instructor.getFullName())
    .join(', ');
  const slugInfos = event.getSlugInfo();
  const oneDayEvent = datesIsSame(event.startDate, event.endDate);

  return (
    <Box>
      {event.status === 'cancelled' && (
        <div className="pointer-events-none bg-white-transparent left-0 top-0 z-10 absolute w-full h-full" />
      )}
      <FlexColumn>
        <div className="absolute left-0">
          <Link to={`/events/${event.id}`}>
            <RowAligner>
              <div className={cls('pl-4 pr-3 py-1', statusBarProps.color)}>
                <TitleWhite>
                  {event.startDate.format('HH:mm')} - {event.endDate.format('HH:mm')}
                </TitleWhite>
              </div>
              {!oneDayEvent && (
                <div>
                  {event.startDate.format(config.dateFormat)} / {event.endDate.format(config.dateFormat)}
                </div>
              )}
            </RowAligner>
          </Link>
        </div>
        <div className="mt-6">
          <RowAligner className="justify-between">
            <Subtitle>{event.name}</Subtitle>
          </RowAligner>
          <div>{instructorsName}</div>
        </div>
        <RowAligner>
          <div>
            <RowAligner separated="small">
              {Object.entries(slugInfos).map(([name, value]) => (
                <TagCircle key={name} color={value.color}>
                  {value.slug}
                </TagCircle>
              ))}
              {event.special && <Icon.Star small className="fill-teal" />}
            </RowAligner>
          </div>
          <div>
            <RowAligner separated="small">
              <Icon.Users className="fill-teal" />
              <span>
                {event.getJoinedParticipants().length}/{event.attendees.max}
              </span>
            </RowAligner>
          </div>
        </RowAligner>
        {event.horses.length > 0 && (
          <FlexColumn separated="small">
            <div className="text-highlight">Konie: </div>
            <EventParticipationItemsGrid event={event} model="horses" itemUrl="/horses" />
          </FlexColumn>
        )}
      </FlexColumn>
      {event.edited && (
        <div className="absolute text-teal bottom-2 right-4">
          <Icon.Warning />
        </div>
      )}
      {isLoading && <Spinner noDelay />}
    </Box>
  );
};

EventListItemMobile.defaultProps = {
  isLoading: false,
};
