/* eslint-disable */
import React from 'react';

const EditFarmPolicy = () => {
  return (
    <svg
      viewBox="0 0 25 26"
      width="100%"
    >
     <path d="M17.45,6.07h3.27L15.72.8V4.32A1.75,1.75,0,0,0,17.45,6.07Z" />
     <path d="M11.72,19.34H6.24a.54.54,0,0,1,0-1.07h5.4V15.94H6.24a.53.53,0,0,1,0-1.06h5.4V12.81a.76.76,0,0,1,0-.21H6.24a.53.53,0,0,1-.53-.53.52.52,0,0,1,.52-.53h6.36l.15-.06,5.64-1.06a1.4,1.4,0,0,1,.5,0l2.58.49V7.13h-4a2.8,2.8,0,0,1-2.8-2.8V0H4.42A2.39,2.39,0,0,0,2,2.39V22.77a2.36,2.36,0,0,0,2.35,2.37h10.7A8.53,8.53,0,0,1,11.72,19.34Z" transform="translate(-2.04)"/><path d="M24.36,12.31l-5.64-1.06h-.19l-5.64,1.06a.51.51,0,0,0-.41.49v5.54a2.53,2.53,0,0,0,0,.28,7.77,7.77,0,0,0,4.74,6.81l1.24.54a.42.42,0,0,0,.31,0L20,25.43a7.75,7.75,0,0,0,4.74-6.81V12.8A.5.5,0,0,0,24.36,12.31Zm-5.88,8.91-3.15-3.14,1-1,2.14,2.09L21.62,16l1,1Z" />
    </svg>
  );
};

export default EditFarmPolicy;
/* eslint-enable */
