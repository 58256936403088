/* eslint-disable max-len */
import React from 'react';

const MoreVertical = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M10,8c0-1.1-0.9-2-2-2S6,6.9,6,8c0,1.1,0.9,2,2,2l0,0C9.1,10,10,9.1,10,8L10,8z M10,14C10,14,10,14,10,14c0-1.1-0.9-2-2-2
        s-2,0.9-2,2c0,1.1,0.9,2,2,2l0,0l0,0C9.1,16,10,15.1,10,14L10,14z M10,2c0-1.1-0.9-2-2-2S6,0.9,6,2c0,1.1,0.9,2,2,2l0,0C9.1,4,10,3.1,10,2L10,2z"
      />
    </svg>
  );
};

export default MoreVertical;
/* eslint-enable max-len */
