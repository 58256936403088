export const FORM_EVENT_ADD = 'FORM_EVENT_ADD';
export const FORM_EVENT_EDIT = id => `FORM_EVENT_EDIT${id}`;
export const FORM_PROPOSAL = id => `FORM_PROPOSAL${id}`;
export const FORM_EVENT_TYPE = id => `FORM_EVENT_TYPE${id}`;
export const FORM_HORSE = id => `FORM_HORSE${id}`;
export const FORM_USER = 'FORM_USER';
export const FORM_FARM = 'FORM_FARM';
export const FORM_INVITATIONS = 'FORM_INVITATIONS';
export const FORM_ADD_GUEST = 'FORM_ADD_GUEST';
export const FORM_EDIT_GUEST = 'FORM_EDIT_GUEST';
export const FORM_MANAGE_GROUP = 'FORM_MANAGE_GROUP';
export const FORM_PLACE = id => `FORM_PLACE${id}`;
export const FORM_LOGIN = 'FORM_LOGIN';
export const FORM_REGISTER = 'FORM_REGISTER';
export const FORM_SIGN_UP_CHILD = 'FORM_SIGN_UP_CHILD';
export const FORM_NEWS = id => `FORM_NEWS${id}`;
export const FORM_MANAGE_ABSENCE = 'FORM_MANAGE_ABSENCE';
export const FORM_MANAGE_SHIFTS = 'FORM_MANAGE_SHIFTS';
export const FORM_FORGOT_PASSWORD = 'FORM_FORGOT_PASSWORD';
export const FORM_MANAGE_MEMBER = id => `FORM_MANAGE_MEMBER${id}`;
export const FORM_NEW_PASSWORD = 'FORM_NEW_PASSWORD';
export const FORM_PAYMENTS = id => `FORM_PAYMENTS${id}`;
export const FORM_ADD_RATING = 'FORM_ADD_RATING';
export const FORM_FARM_POLICY = 'FORM_FARM_POLICY';
export const FORM_CHANGE_PHONE = 'FORM_CHANGE_PHONE';
export const FORM_BILLS_FILTERS = 'FORM_BILLS_FILTERS';
export const FORM_SOCIAL_REGISTER = 'FORM_SOCIAL_REGISTER';
