/**
 * @module Message
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
/**
 * Component for generating messages
 * @param  {*} props.children
 * @param  {String} [props.className]
 * @param  {Boolean} [props.info] specifies the type of the message
 * @param  {Boolean} [props.warning] specifies the type of the message
 */
const Message = ({ children, className, info, warning, deleted }) => {
  const classNames = cls('flex items-center border rounded p-4', className, {
    'border-teal text-teal-dark bg-white': info,
    'border-orange bg-orange text-black': warning,
    'shadow-box border-teal text-teal-dark bg-white mb-4': deleted,
  });

  return (
    <div className={classNames}>
      <span>{children}</span>
    </div>
  );
};

Message.defaultProps = {
  info: false,
  warning: false,
  deleted: false,
  className: '',
};

Message.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line
  info: PropTypes.bool,
  warning: PropTypes.bool,
  className: PropTypes.string,
  deleted: PropTypes.bool,
};

export default Message;
