/**
 * @module Ratings
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import Widget from '@old/components/common/Widget';
import t from 'resources/translations';

// Customizable react star ratings. SVG stars that show aggregate star ratings to the hundreths decimal point.
/** The documentation is here
 *  @see {@link https://github.com/ekeric13/react-ratings-declarative}
 */
const Ratings = ({ ...props }) => {
  const fillId = `${uniqueId('widgetGrad')}`;
  const [highestWidgetHovered, setHighestWidgetHovered] = useState(-Infinity);

  const stopColorStyle = (color) => {
    const stopColorStyles = {
      stopColor: color,
      stopOpacity: '1',
    };
    return stopColorStyles;
  };

  const titleText = () => {
    const {
      rating: selectedRating,
    } = props;
    const hoveredRating = highestWidgetHovered;
    const currentRating = hoveredRating > 0 ? hoveredRating : selectedRating;
    // fix it at 2 decimal places and remove trailing 0s
    let formattedRating = parseFloat(currentRating.toFixed(2)).toString();
    if (Number.isInteger(currentRating)) {
      formattedRating = String(currentRating);
    }
    return `${t('ratingList.rating')}: ${formattedRating}`;
  };

  const offsetValue = () => {
    const { rating } = props;
    const ratingIsInteger = Number.isInteger(rating);
    let offsetValues = '0%';
    if (!ratingIsInteger) {
      const firstTwoDecimals = rating.toFixed(2).split('.')[1].slice(0, 2);
      offsetValues = `${firstTwoDecimals}%`;
    }
    return offsetValues;
  };

  const unHoverOverWidget = () => {
    setHighestWidgetHovered(-Infinity);
  };

  const hoverOverWidget = (rating) => {
    setHighestWidgetHovered(rating);
  };

  const childrenWithRatingState = () => {
    const {
      changeRating,
      rating: selectedRating,
      children,
      gradientPathName,
      widgetEmptyColors,
      widgetHoverColors,
      widgetRatedColors,
      widgetDimensions,
      widgetSpacings,
      svgIconPaths,
    } = props;
    const numberOfWidgets = children.length;
    return React.Children.map(children, (child, index) => {
      const {
        svgIconPath,
        widgetHoverColor,
        widgetEmptyColor,
        widgetRatedColor,
        widgetDimension,
        widgetSpacing,
      } = child.props;

      const widgetRating = index + 1;
      const isSelected = widgetRating <= selectedRating;

      // hovered only matters when changeRating is true
      const hoverMode = highestWidgetHovered > 0;
      const isHovered = widgetRating <= highestWidgetHovered;
      const isCurrentHoveredWidget = widgetRating === highestWidgetHovered;

      // only matters when changeRating is false
      // given widget 5 and rating 4.2:  5 > 4.2 && 4 < 4.2;
      const isPartiallyFullWidget = widgetRating > selectedRating && widgetRating - 1 < selectedRating;

      const isFirstWidget = widgetRating === 1;
      const isLastWidget = widgetRating === numberOfWidgets;

      return React.cloneElement(child, {
        selectedRating,
        gradientPathName,
        changeRating: changeRating ? () => changeRating(widgetRating) : null,
        hoverOverWidget: changeRating ? () => hoverOverWidget(widgetRating) : null,
        unHoverOverWidget: changeRating ? unHoverOverWidget : null,
        inheritFillId: fillId,
        isSelected,
        isHovered,
        isCurrentHoveredWidget,
        isPartiallyFullWidget,
        isFirstWidget,
        isLastWidget,
        hoverMode,
        hasCustomGradientColor: (widgetRatedColor || widgetEmptyColor) && isPartiallyFullWidget,
        svgIconPath: svgIconPath || svgIconPaths,
        svgIconViewBox: '0 0 51 48',
        widgetHoverColor: widgetHoverColor || widgetHoverColors,
        widgetEmptyColor: widgetEmptyColor || widgetEmptyColors,
        widgetRatedColor: widgetRatedColor || widgetRatedColors,
        widgetDimension: widgetDimension || widgetDimensions,
        widgetSpacing: widgetSpacing || widgetSpacings,
      });
    });
  };

  const {
    widgetEmptyColors,
    widgetRatedColors,
  } = props;

  return (
    <div className="widget-ratings" title={titleText()}>
      <svg className="widget-grad">
        <linearGradient id={fillId}>
          <stop offset="0%" style={stopColorStyle(widgetRatedColors)} />
          <stop offset={offsetValue()} style={stopColorStyle(widgetRatedColors)} />
          <stop offset={offsetValue()} style={stopColorStyle(widgetEmptyColors)} />
          <stop offset="100%" style={stopColorStyle(widgetEmptyColors)} />
        </linearGradient>
      </svg>
      {childrenWithRatingState()}
    </div>
  );
};

Ratings.propTypes = {
  rating: PropTypes.number.isRequired,
  changeRating: PropTypes.func,
  gradientPathName: PropTypes.string,
  svgIconPaths: PropTypes.string,
  widgetRatedColors: PropTypes.string,
  widgetEmptyColors: PropTypes.string,
  widgetHoverColors: PropTypes.string,
  widgetDimensions: PropTypes.string,
  widgetSpacings: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Ratings.defaultProps = {
  changeRating: null,
  gradientPathName: '',
  svgIconPaths: 'm25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z',
  widgetRatedColors: 'rgb(109, 122, 130)',
  widgetEmptyColors: 'rgb(203, 211, 227)',
  widgetHoverColors: 'rgb(230, 67, 47)',
  widgetDimensions: '20px',
  widgetSpacings: '2px',
};

Ratings.Widget = Widget;
export default Ratings;
