/**
 * @module BoxShadow
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
/**
 * Wrapper that adds shadow to his children
 * @param  {*} props.children
 * @param  {String} [props.className]
 */
const BoxShadow = ({ children, className }) => {
  return (
    <div className={cls('shadow-box bg-white rounded', className)}>
      {children}
    </div>
  );
};

BoxShadow.defaultProps = {
  className: '',
};

BoxShadow.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  className: PropTypes.string,
};

export default BoxShadow;
