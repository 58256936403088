import React, { useState, useEffect } from 'react';
import moment from 'moment';
import cls from 'classnames';
import axios from 'axios';
import { isEmpty } from 'lodash';

import RatingStars from '@old/components/old/RatingStars';
import GoogleMap from '@old/components/old/GoogleMap';
import FloatingButton from '@old/components/custom/FloatingButton';
import Spinner from '@old/components/common/Spinner';
import GalleryViewer from '@old/components/modals/GalleryViewer';
import FlexRow from '@old/components/common/FlexRow';
import Box from '@old/components/common/Box';
import FlexColumn from '@old/components/common/FlexColumn';
import Subtitle from '@old/components/common/Subtitle';
import Error404 from '@old/components/error/Error404';
import Icon from '@old/components/icon';
import RowAligner from '@old/components/common/RowAligner';
import Center from '@old/components/common/Center';
import BoxWhite from '@old/components/common/BoxWhite';
import Divider from '@old/components/common/Divider';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Button from '@old/components/guide/Button';
import EditableAvatar from '@old/components/common/editable/EditableAvatar';
import { openModal, fetchFarm } from 'store/actions';
import {
  useAddPictureButton,
  useFarmEditButton,
  useSettingsButton,
  useAddFarmPolicyButton,
  useEditFarmPolicyButton,
} from 'old/buttonHooks';
import t from 'resources/translations';
import Model from '@old/model';
import { getErrorMessage, notify } from 'old/utils';
import { A, Link, Icons } from '@elements';
import { useActions, useShallowSelector } from '@old/hooks';
import { useOldLoggedMember } from 'utils/storeUtils';

const FarmView = () => {
  const [openModalByName, fetchFarmAction] = useActions([openModal, fetchFarm]);
  const loggedMember = useOldLoggedMember();
  const [isMobile, farm, loading, error] = useShallowSelector(({ app, fetchingData }) => [
    app.isMobile,
    fetchingData.farm || null,
    fetchingData.loading.farm,
    fetchingData.error.farm,
  ]);

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    fetchFarmAction(source.token);
    return () => source.cancel();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!loading) {
      const fetchData = async () => {
        try {
          if (loggedMember.isActive()) {
            const result = await Model.Farms.getFarmPolicy();
            setFarmPolicy(result.content);
          }
        } catch (e) {
          notify(getErrorMessage(e), { type: 'error' });
        }
      };
      fetchData();
    }
  }, [loading, loggedMember]);

  const [hoursBlockOpen, setHoursBlockOpen] = useState(false);
  const [farmPolicy, setFarmPolicy] = useState(false);
  const editFarm = useFarmEditButton(farm);
  const addPicture = useAddPictureButton(farm);
  const settings = useSettingsButton(farmPolicy, farm);
  const addFarmPolicy = useAddFarmPolicyButton();
  const editFarmPolicy = useEditFarmPolicyButton();
  const policyButton = !farmPolicy ? addFarmPolicy : editFarmPolicy;
  const isCorrectWebsite = farm?.www?.includes('https://');
  const showPolicyButton = !!farmPolicy || farm?.isAdvancedPaymentEnabled();

  const farmButtons = [editFarm, policyButton, settings, addPicture];
  const onClick = () => {
    setHoursBlockOpen(!hoursBlockOpen);
  };

  const goToGoogleMaps = () => {
    if (
      navigator.platform.indexOf('iPhone') !== -1 ||
      navigator.platform.indexOf('iPad') !== -1 ||
      navigator.platform.indexOf('iPod') !== -1
    ) {
      window.open(`maps://maps.google.com/maps?daddr=${farm.latitude},${farm.longitude}&amp;ll=`);
    } else {
      window.open(`https://maps.google.com/maps?daddr=${farm.latitude},${farm.longitude}&amp;ll=`);
    }
  };

  const getCurrentOpenHours = () => {
    let index = moment().day() - 1;
    if (index === -1) {
      index = 6;
    }
    const key = Object.keys(farm.openingHours)[index];
    let openHour = t('farmDetails.closed').toLowerCase();
    if (farm.openingHours[key].open && farm.openingHours[key].close) {
      openHour = `${farm.openingHours[key].open} - ${farm.openingHours[key].close}`;
    }
    return (
      <span className="text-highlight">
        {t(`week.${key.substring(0, 2)}`)}: {openHour}
      </span>
    );
  };

  if (error) return <Error404 />;
  if (loading && isEmpty(farm)) return <Spinner />;
  if (isEmpty(farm)) return null;

  if (isMobile) {
    return (
      <React.Fragment>
        <FlexColumn>
          <BoxWhite>
            <FlexColumn>
              <Center>
                <EditableAvatar modelItem={farm} refreshAction={fetchFarmAction} />
              </Center>
              <FlexColumn separated="small">
                <Center>
                  <Subtitle>{farm.getName()}</Subtitle>
                </Center>
                <Center>
                  <Link to={`/farms/${farm.id}/rating`} forceRender>
                    <RatingStars rating={farm.rating} />
                  </Link>
                </Center>
                <div className="center-child">
                  <ButtonSimple onClick={onClick}>
                    <div className={cls('opening-hours', hoursBlockOpen && 'open')}>
                      <FlexColumn separated="small">
                        <Center>
                          <div className="mr-2">
                            <Subtitle>{t('farmDetails.openingHours')}</Subtitle>
                          </div>
                          {hoursBlockOpen ? (
                            <Icon.ArrowUp small className="fill-teal" />
                          ) : (
                            <Icon.ArrowDown small className="fill-teal" />
                          )}
                        </Center>
                        {!hoursBlockOpen ? (
                          getCurrentOpenHours()
                        ) : (
                          <FlexColumn separated="small">
                            {Object.keys(farm.openingHours).map((key, index) => {
                              let openingHours = t('farmDetails.closed').toLowerCase();
                              let fixIndex = moment().day() - 1;
                              if (fixIndex === -1) fixIndex = 6;
                              if (farm.openingHours[key].open && farm.openingHours[key].close) {
                                openingHours = `${farm.openingHours[key].open} - ${farm.openingHours[key].close}`;
                              }
                              return (
                                <Center key={key}>
                                  <div className={cls(fixIndex === index && 'text-highlight')}>
                                    {t(`week.${key.substring(0, 2)}`)}: {openingHours}
                                  </div>
                                </Center>
                              );
                            })}
                          </FlexColumn>
                        )}
                      </FlexColumn>
                    </div>
                  </ButtonSimple>
                </div>
                <Divider />
                <RowAligner separated="small" className="centered">
                  <Icon.Phone small className="fill-teal" />
                  <a href={`tel:${farm.phone}`}>{farm.phone}</a>
                </RowAligner>
                <RowAligner separated="small" className="centered">
                  <Icon.Mail small className="fill-teal" />
                  <a href={`mailto:${farm.email}`}>{` ${farm.email}`}</a>
                </RowAligner>
                {farm.www && (
                  <RowAligner separated="small" className="centered">
                    <Icon.Www small className="fill-teal" />
                    <div>{farm.www}</div>
                    {isCorrectWebsite && (
                      <A href={farm.www} isExternal>
                        <Icons.ExternalLink size={12} />
                      </A>
                    )}
                  </RowAligner>
                )}
              </FlexColumn>
              {showPolicyButton && (
                <Button fluid onClick={() => openModalByName('showPolicy', { farmPolicy, farm })}>
                  {t('farmDetails.readPolicy').toUpperCase()}
                </Button>
              )}
            </FlexColumn>
          </BoxWhite>
          <Box>
            <FlexColumn separated="small">
              {farm.description && (
                <FlexColumn separated="small">
                  <div className="text-highlight">{t('general.description')}:</div>
                  <div
                    className="text-content"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: farm.getDescription() }}
                  />
                </FlexColumn>
              )}
              {farm.staff && (
                <FlexColumn separated="small">
                  <div className="text-highlight">{t('farmDetails.staff')}:</div>
                  <div
                    className="text-content"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: farm.getStaff() }}
                  />
                </FlexColumn>
              )}
            </FlexColumn>
          </Box>
          <BoxWhite>
            <FlexColumn marginBottom>
              <Subtitle>{t('farmDetails.localization')}</Subtitle>
              <div role="presentation" className="cursor-pointer" onClick={goToGoogleMaps} onKeyPress={goToGoogleMaps}>
                <div>{farm.address}</div>
                <div>
                  {farm.postcode} {farm.city}
                </div>
              </div>
            </FlexColumn>
            <div style={{ height: 250 }}>
              <GoogleMap lat={parseFloat(farm.latitude)} lng={parseFloat(farm.longitude)} zoom={13} showMarker />
            </div>
          </BoxWhite>
          {farm.pictures.length > 0 ? (
            <Box>
              <GalleryViewer
                modelItem={farm}
                perView={1}
                permissionKey="farms.update"
                refreshAction={fetchFarmAction}
              />
            </Box>
          ) : (
            <GalleryViewer modelItem={farm} permissionKey="farms.update" />
          )}
          <FloatingButton buttons={farmButtons} />
        </FlexColumn>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FlexRow cols="10/6" marginBottom stretched>
        <Box>
          <FlexRow cols="none/11" marginBottom>
            <EditableAvatar modelItem={farm} refreshAction={fetchFarmAction} />
            <FlexColumn separated="small">
              <Subtitle>{farm.getName()}</Subtitle>
              <RowAligner separated="small">
                <Icon.Phone small className="fill-teal" />
                <a href={`tel:${farm.phone}`}>{farm.phone}</a>
              </RowAligner>
              <RowAligner separated="small">
                <Icon.Mail small className="fill-teal" />
                <a href={`mailto:${farm.email}`}>{` ${farm.email}`}</a>
              </RowAligner>
              {farm.www && (
                <RowAligner separated="small">
                  <Icon.Www small className="fill-teal" />
                  <div>{farm.www}</div>
                  {isCorrectWebsite && (
                    <A href={farm.www} isExternal>
                      <Icons.ExternalLink size={12} />
                    </A>
                  )}
                </RowAligner>
              )}
            </FlexColumn>
          </FlexRow>
          <FlexColumn>
            {farm.description && (
              <FlexColumn separated="small">
                <div className="text-highlight">{t('general.description')}:</div>
                <div
                  className="text-content"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: farm.getDescription() }}
                />
              </FlexColumn>
            )}
            {farm.staff && (
              <FlexColumn separated="small">
                <div className="text-highlight">{t('farmDetails.staff')}:</div>
                <div
                  className="text-content"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: farm.getStaff() }}
                />
              </FlexColumn>
            )}
          </FlexColumn>
        </Box>
        <div>
          <FlexColumn>
            {showPolicyButton && (
              <Button fluid onClick={() => openModalByName('showPolicy', { farmPolicy, farm })}>
                {t('farmDetails.readPolicy').toUpperCase()}
              </Button>
            )}
            <Box fullHeight>
              <Link to={`/farms/${farm.id}/rating`} forceRender>
                <FlexColumn separated="small">
                  <Subtitle>{t('farmDetails.rating')}</Subtitle>
                  <RatingStars rating={farm.rating} />
                </FlexColumn>
              </Link>
            </Box>
            <Box fullHeight>
              <FlexColumn separated="small">
                <Subtitle>{t('farmDetails.openingHours')}</Subtitle>
                {Object.keys(farm.openingHours).map((key, index) => {
                  let openingHours = t('farmDetails.closed').toLowerCase();
                  if (farm.openingHours[key].open && farm.openingHours[key].close) {
                    openingHours = `${farm.openingHours[key].open} - ${farm.openingHours[key].close}`;
                  }
                  return (
                    <div key={key} className={cls(moment().day() - 1 === index && 'text-highlight')}>
                      {t(`week.${key.substring(0, 2)}`)}: {openingHours}
                    </div>
                  );
                })}
              </FlexColumn>
            </Box>
          </FlexColumn>
        </div>
      </FlexRow>
      <FlexRow marginBottom cols="6/10">
        <Box fullHeight>
          <FlexRow cols="1/2">
            <FlexColumn separated="small">
              <Subtitle>{t('farmDetails.localization')}</Subtitle>
              <div role="presentation" className="cursor-pointer" onClick={goToGoogleMaps} onKeyPress={goToGoogleMaps}>
                <div>{farm.address}</div>
                <div>
                  {farm.postcode} {farm.city}
                </div>
              </div>
            </FlexColumn>
            <div style={{ height: 250 }}>
              <GoogleMap lat={parseFloat(farm.latitude)} lng={parseFloat(farm.longitude)} zoom={13} showMarker />
            </div>
          </FlexRow>
        </Box>
      </FlexRow>
      {farm.pictures.length > 0 ? (
        <Box>
          <GalleryViewer modelItem={farm} perView={5} permissionKey="farms.update" refreshAction={fetchFarmAction} />
        </Box>
      ) : (
        <GalleryViewer modelItem={farm} permissionKey="farms.update" refreshAction={fetchFarmAction} />
      )}
      <FloatingButton buttons={farmButtons} />
    </React.Fragment>
  );
};

export default FarmView;
