import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import cls from 'classnames';
import truncate from 'lodash/truncate';

import ButtonSimple from '@old/components/guide/ButtonSimple';
import { getErrorMessage, notify } from 'old/utils';
import Model from '@old/model';
import RenderDate from '@old/components/old/RenderDate';
import {
  openNotification,
  toggleNotificationsWidget,
  openModal,
  fetchNotifications,
  setNotifications,
} from 'store/actions';
import store from 'store';
import t from 'resources/translations';
import ImageCircle from '@old/components/guide/ImageCircle';
import FlexColumn from '@old/components/common/FlexColumn';
import RowAligner from '@old/components/common/RowAligner';
import FlexRow from '@old/components/common/FlexRow';
import Icon from '@old/components/icon';
import Allow from '@old/components/common/Allow';
import Link from '@old/components/common/Link';
import { useFarm } from 'utils/storeUtils';

const NotificationListItem = ({ notification, basic, history, ...actions }) => {
  const farm = useFarm();
  const onClick = async notificationItem => {
    try {
      let url = '';
      actions.toggleNotificationsWidget(false);
      if (notificationItem.farm.slug) {
        const isSameFarm = notificationItem.farm.id === farm.id;
        url = notificationItem.notifiable.urlToRedirect;

        if (!notificationItem.openedAt) await actions.openNotification(notification.id);
        if (!isSameFarm) {
          const prefix = window.location.host.startsWith('http') ? '' : 'http://';
          window.location.replace(`${prefix}${window.location.host}/${notificationItem.farm.slug}${url}`);
        } else {
          history.push(url);
        }
      }
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };
  const onRejectProposal = () => {
    actions.openModalByName('confirmModal', {
      header: notification.notifiable.title,
      content: t('rejectProposalModal.confirmRejectEvent'),
      approveLabel: t('buttons.reject'),
      onSubmitConfirm: async () => {
        await Model.Proposals.reject(notification.notifiable.id);
        notify(t('message.proposalRejected'));
      },
      afterSubmit: () => {
        const {
          fetchingData: { notificationsFetchParams },
        } = store.getState();
        store.dispatch(fetchNotifications(notificationsFetchParams));
        store.dispatch(setNotifications({ per_page: 110 }));
      },
    });
  };

  const getNotificationContentWithoutNotifable = notifKey => {
    return (
      <div>
        <span>{t(`notificationListItem.notify.${notifKey}`)}: </span>
        <span>{t('notificationListItem.deletedItem')}</span>
      </div>
    );
  };

  const getNotificationContent = notifKey => {
    const participant = notification.notifiable.member;
    const title = truncate(notification.notifiable.title, { length: 40 });
    const content = notification.notifiable.content ? truncate(notification.notifiable.content, { length: 40 }) : '';
    const systemMessage = !notification.notifier;
    const label = t(`notificationListItem.notify.${notifKey}`);
    switch (notification.key) {
      case 'participation.status.added.without_email':
        return (
          <div>
            <span>{t('notificationListItem.notify.participation.status.addedMember', { member: participant })}</span>
            <span>{title}</span>
            <span className="italic">{content}</span>
          </div>
        );
      case 'event.post.comment.created':
      case 'event.post.post.created':
        return (
          <div>
            <span>{t(`notificationListItem.notify.${notifKey}`)}: </span>
            <span>{title}</span>
            <span className="italic">{content}</span>
          </div>
        );
      default:
        return (
          <div>
            {`${systemMessage ? t('notificationListItem.system') : ''} ${label}: `}
            <span>{title}</span>
          </div>
        );
    }
  };

  const renderNotificationContent = () => {
    // Fix in API after release 1.4
    let notifKey = notification.key;
    if (notifKey.split('.').slice(-1).toString() === 'without_email') {
      notifKey = notifKey.split('.').slice(0, -1).join('.');
    }

    if (
      notification.key === 'event.proposed' &&
      notification.notifiable &&
      notification.notifiable.proposalStatus === 'proposed'
    ) {
      const acceptProposalLink = {
        pathname: `/proposals/${notification.notifiable.id}/edit`,
        state: { confirm: true },
      };
      return (
        <FlexColumn separated="small">
          <RowAligner>
            <ButtonSimple disabled={!notification.notifiable} onClick={() => onClick(notification)}>
              {notification.notifier && <ImageCircle src={notification.notifier.getAvatar('thumb')} small />}
              <FlexColumn>
                <div className="text-sm text-left">
                  <div className="font-bold">{notification.notifier && notification.notifier.getFullName()}</div>
                  <div>
                    {notification.notifiable
                      ? getNotificationContent(notifKey)
                      : getNotificationContentWithoutNotifable(notifKey)}
                  </div>
                  <div className="italic">
                    <RenderDate startDate={notification.createdAt} />
                  </div>
                  <div className="font-bold">{notification.farm && notification.farm.name}</div>
                </div>
              </FlexColumn>
            </ButtonSimple>
            <div>
              <FlexRow noShrink>
                <Allow permissionKey="proposals.reject" permissionParams={{ id: notification.notifiable.id }}>
                  <ButtonSimple onClick={onRejectProposal}>
                    <Icon.UncheckedOutline className="fill-teal" />
                  </ButtonSimple>
                </Allow>
                <Allow permissionKey="proposals.approve" permissionParams={{ id: notification.notifiable.id }}>
                  <Link className="btn simple w-full" to={acceptProposalLink}>
                    <Icon.Checked className="fill-teal" />
                  </Link>
                </Allow>
              </FlexRow>
            </div>
          </RowAligner>
        </FlexColumn>
      );
    }

    return (
      <ButtonSimple disabled={!notification.notifiable} onClick={() => onClick(notification)}>
        <FlexColumn separated="small">
          <RowAligner className="text-left">
            {notification.notifier && <ImageCircle src={notification.notifier.getAvatar('thumb')} small />}
            <FlexColumn>
              <div className="text-sm">
                <div className="font-bold">{notification.notifier && notification.notifier.getFullName()}</div>
                <div>
                  {notification.notifiable
                    ? getNotificationContent(notifKey)
                    : getNotificationContentWithoutNotifable(notifKey)}
                </div>
                <div className="italic">
                  <RenderDate startDate={notification.createdAt} />
                </div>
                <div className="font-bold">{notification.farm && notification.farm.name}</div>
              </div>
            </FlexColumn>
          </RowAligner>
        </FlexColumn>
      </ButtonSimple>
    );
  };

  const isOpened = notification.openedAt !== null;
  const className = cls('hm-notification rounded hover:bg-grey-light border-b border-grey', {
    'bg-white': isOpened,
    'bg-grey-light': !isOpened,
    'p-6': !basic,
    'p-3': basic,
  });

  return <div className={className}>{renderNotificationContent()}</div>;
};

NotificationListItem.defaultProps = {
  basic: false,
};

export default withRouter(
  connect(null, {
    openNotification,
    toggleNotificationsWidget,
    openModalByName: openModal,
  })(NotificationListItem)
);
