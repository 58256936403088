/**
 * @module Link
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { pathIsAllowed } from 'old/utils';
/**
 * Path to be checked if you have permission to view it
 * @typedef   {String|Object} ToProps
 * @property  {String} pathname
 */
/**
 * Component for rendering links and checking permissions to them.
 * @param  {*} props.children
 * @param  {ToProps} props.to
 * @param  {Boolean} [props.forceRender] specifies wether the child element should be visible
 * @param  {Boolean} [props.allowed] if you use it with this props, no permissions will be checked
 * @param  {String}  [props.className]
 * @param  {Function} [props.onMouseEnter] - Function that specifies what happens when mouse enters the component
 * @param  {Function} [props.onMouseLeave] - Function that specifies what happens when mouse leaves the component
 */
const Link = ({ children, to, forceRender, allowed, className, onMouseEnter, onMouseLeave }) => {
  if (allowed || pathIsAllowed(to)) {
    return (
      <RouterLink
        to={to}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </RouterLink>
    );
  }

  return forceRender ? children : null;
};

Link.defaultProps = {
  forceRender: false,
  className: '',
  onMouseEnter: () => { },
  onMouseLeave: () => { },
  allowed: false,
};

Link.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
    }),
  ]).isRequired,
  forceRender: PropTypes.bool,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  allowed: PropTypes.bool,
};

export default Link;
