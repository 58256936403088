import ReactSelect, { OptionTypeBase, Styles as SelectStyles, Props as SelectProps } from 'react-select';
import { isMobile as isMobileDevices } from 'react-device-detect';
import theme from 'config/theme';
import MultiValueContainer from '../select/MultiValueContainer';
import MultiValueLabel from '../select/MultiValueLabel';
import MultiValueRemove from '../select/MultiValueRemove';
import DropdownIndicator from '../select/DropdownIndicator';
import ClearIndicator from '../select/ClearIndicator';
import t from 'resources/translations';

const customStyles = {
  input: provided => ({
    ...provided,
    paddingLeft: '10px',
    '& input': {
      minHeight: 'auto',
      height: 'auto',
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 5px 0 10px',
    '& input': {
      minHeight: 'auto',
      height: 'auto',
    },
  }),
  container: provided => ({
    ...provided,
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 0,
    color: theme.colors['teal-light'],
    cursor: 'pointer',
    margin: '0 15px',
    '&:hover': {
      color: theme.colors['teal-dark'],
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: theme.colors['teal-light'],
    cursor: 'pointer',
    margin: '0 15px',
    padding: 0,
    '&:hover': {
      color: theme.colors['teal-dark'],
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: theme.colors['new-grey-light'],
  }),
  control: (provided, { isFocused, selectProps }) => ({
    ...provided,
    boxShadow: 'none',
    borderRadius: 8,
    minHeight: 45,
    borderColor: theme.colors[selectProps.menuIsOpen || isFocused ? 'teal' : 'new-grey-light'],
    '&:hover': {
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)',
    },
  }),
  menu: provided => ({
    ...provided,
    margin: '0 0 10px',
  }),
  menuList: provided => ({
    ...provided,
    padding: '0 10px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    lineHeight: 1,
    cursor: 'pointer',
    padding: '15px 20px',
    borderBottom: `1px solid ${theme.colors['grey-light']}`,
    backgroundColor: 'white',
    color: state?.isSelected ? theme.colors['teal-dark'] : 'initial',
    '&:hover': {
      color: theme.colors['teal-light'],
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  multiValue: provided => ({
    ...provided,
    margin: '5px 5px 5px 0',
    backgroundColor: 'white',
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '16px',
    paddingLeft: '10px',
    color: theme.colors['new-grey-light'],
  }),
  multiValueLabel: () => ({}),
} as Partial<SelectStyles<OptionTypeBase, true>>;

const SelectMulti = ({
  value,
  name,
  placeholder,
  onChange,
  options,
  ...selectOptions
}: SelectProps<OptionTypeBase, true>) => {
  return (
    <ReactSelect
      id={name}
      inputId={name}
      name={name}
      placeholder={placeholder}
      isSearchable={!isMobileDevices}
      components={{
        MultiValueContainer: MultiValueContainer,
        MultiValueLabel: MultiValueLabel,
        MultiValueRemove: MultiValueRemove,
        DropdownIndicator: DropdownIndicator,
        ClearIndicator: ClearIndicator,
      }}
      onChange={onChange}
      options={options}
      noOptionsMessage={() => t('message.noOptions')}
      loadingMessage={() => t('general.loading')}
      value={value}
      closeMenuOnSelect={false}
      {...selectOptions}
      styles={{ ...customStyles, ...selectOptions.styles }}
      isMulti
    />
  );
};

SelectMulti.defaultProps = {
  options: [],
};

export default SelectMulti;
