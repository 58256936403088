import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { SET_ACTIVE_DAY, SET_ACTIVE_WEEK, SET_ACTIVE_MONTH, RESET_CALENDAR_STATE } from 'store/actions/calendarActions';

const moment = extendMoment(Moment);

const currentWeekRange = moment.range([moment().startOf('week'), moment().endOf('week')]);
const currentMonthRange = moment.range([moment().startOf('month'), moment().endOf('month')]);
const initialState = {
  default: {
    dayStart: moment().startOf('day'),
    dayRange: moment.range([moment().startOf('day'), moment().endOf('day')]),
    weekStart: moment().startOf('week'),
    weekRange: currentWeekRange,
    monthStart: moment().startOf('month'),
    monthRange: currentMonthRange,
    calendarRange: moment.range([moment().startOf('month').startOf('week'), moment().endOf('month').endOf('week')]),
  },
};

/* eslint-disable no-case-declarations */
const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_DAY:
      const currentCalendarState = state[action.key] || state.default;
      const dayRange = moment.range([action.date, moment(action.date).endOf('day')]);
      const sameWeek = currentCalendarState.weekRange.contains(dayRange);
      const sameMonth = currentCalendarState.monthRange.contains(dayRange);
      const weekRange = sameWeek
        ? currentCalendarState.weekRange
        : moment.range([moment(action.date).startOf('week'), moment(action.date).endOf('week')]);
      const monthRange = sameMonth
        ? currentCalendarState.monthRange
        : moment.range([moment(action.date).startOf('month'), moment(action.date).endOf('month')]);

      return {
        ...state,
        [action.key]: {
          dayStart: moment(action.date).startOf('day'),
          dayRange,
          weekStart: sameWeek ? currentCalendarState.weekStart : moment(action.date).startOf('week'),
          weekRange,
          monthStart: sameMonth ? currentCalendarState.monthStart : moment(action.date).startOf('month'),
          monthRange,
          calendarRange: sameMonth
            ? currentCalendarState.calendarRange
            : moment.range([
                moment(action.date).startOf('month').startOf('week'),
                moment(action.date).endOf('month').endOf('week'),
              ]),
        },
      };
    case SET_ACTIVE_WEEK:
      const currentCalendarStateW = state[action.key] || state.default;
      const weekRangeW = moment.range([action.date, moment(action.date).endOf('week')]);
      const sameMonthW = currentCalendarStateW.monthRange.overlaps(weekRangeW);
      const monthRangeW = sameMonthW
        ? currentCalendarStateW.monthRange
        : moment.range([moment(action.date).startOf('month'), moment(action.date).endOf('month')]);

      return {
        ...state,
        [action.key]: {
          dayStart: moment(action.date).startOf('day'),
          dayRange: moment.range([moment(action.date).startOf('day'), moment(action.date).endOf('day')]),
          weekStart: action.date,
          weekRange: weekRangeW,
          monthStart: sameMonthW ? currentCalendarStateW.monthStart : moment(action.date).startOf('month'),
          monthRange: monthRangeW,
          calendarRange: sameMonthW
            ? currentCalendarStateW.calendarRange
            : moment.range([
                moment(action.date).startOf('month').startOf('week'),
                moment(action.date).endOf('month').endOf('week'),
              ]),
        },
      };
    case SET_ACTIVE_MONTH:
      return {
        ...state,
        [action.key]: {
          dayStart: moment(action.date).startOf('day'),
          dayRange: moment.range([moment(action.date).startOf('day'), moment(action.date).endOf('day')]),
          weekStart: moment(action.date).startOf('week'),
          weekRange: moment.range([moment(action.date).startOf('week'), moment(action.date).endOf('week')]),
          monthStart: action.date,
          monthRange: moment.range([action.date, moment(action.date).endOf('month')]),
          calendarRange: moment.range([
            moment(action.date).startOf('month').startOf('week'),
            moment(action.date).endOf('month').endOf('week'),
          ]),
        },
      };
    case RESET_CALENDAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
/* eslint-enable no-case-declarations */

export default calendarReducer;
