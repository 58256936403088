import styled from 'styled-components';
import { border, layout, space, position } from 'styled-system';
import { THrProps } from 'resources/types/elementsTypes';

const Hr = styled('hr')<THrProps>(border, layout, space, position);

Hr.defaultProps = {
  'aria-orientation': 'horizontal',
  role: 'separator',
  borderBottomWidth: 1,
  borderStyle: 'solid',
  width: '100%',
};

export default Hr;
