/**
 * @module DropItem
 */
import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
/**
 * Wrapper that allows drag items to be dropped on its children
 * @param  {Node} [props.children]
 * @param  {String} [props.className]
 * @param  {String|Node} [props.canDropLabel]
 * @param  {String} [props.acceptType] specifies what type of the dragged item you can drop
 * @param  {Function} props.onDrop specifies what happens after you drop the item
 * @param  {Function} [props.isActive] specifies whether you can drop the item
 */
const DropItem = ({ children, className, canDropLabel, acceptType, onDrop, isActive }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: acceptType,
    drop: onDrop,
    canDrop: isActive,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div className={cls('drop-item', { 'can-drop': canDrop, 'is-over': isOver }, className)} ref={drop}>
      {children}
      {canDrop && canDropLabel}
    </div>
  );
};

DropItem.defaultProps = {
  acceptType: 'dragged-item',
  isActive: () => true,
  className: '',
  canDropLabel: null,
  children: '',

};

DropItem.propTypes = {
  children: PropTypes.node,
  onDrop: PropTypes.func.isRequired,
  acceptType: PropTypes.string,
  isActive: PropTypes.func,
  className: PropTypes.string,
  canDropLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default DropItem;
