import { Flex, Text } from '@elements';

const ModalHeader = ({ children }) => {
  return (
    <Flex width="100%" px={['md', 'xl']} py={['md', 'lg']}>
      <Text fontSize="md" color="teal-dark" fontWeight="bold">
        {children}
      </Text>
    </Flex>
  );
};

export default ModalHeader;
