/* eslint-disable max-len */
import React from 'react';

const EventCost = () => {
  return (
    <svg
      viewBox="0 0 26 26"
      width="100%"
      className="icon"
    >
      <path d="M24.15,3.18h0v9.08a1.72,1.72,0,0,1-.48,1.2l-10.3,10.8.16.17a1.8,1.8,0,0,0,2.54.09l.09-.09,9.3-9.75A2,2,0,0,0,26,13.3V5.13A1.9,1.9,0,0,0,24.15,3.18Z" transform="translate(0)" />
      <path d="M22.49,11.37V3a2,2,0,0,0-1.94-2H12.47a1.84,1.84,0,0,0-1.36.59L.59,12.48a2,2,0,0,0,0,2.83h0l8.08,8.38a1.88,1.88,0,0,0,2.66.07l.07-.07,10.52-10.9A2,2,0,0,0,22.49,11.37Zm-7.61.18a.55.55,0,0,1-.77,0l0,0L12.38,9.84a1.2,1.2,0,0,0-1.7,0l0,0a1.3,1.3,0,0,0,0,1.8l.86.86a2.51,2.51,0,0,1,0,3.44A2.29,2.29,0,0,1,8.26,16l-.08-.08-.86.92a.55.55,0,0,1-.76,0l0,0a.59.59,0,0,1,0-.82l.86-.91-.89-.89a.61.61,0,0,1,0-.82.56.56,0,0,1,.79,0L9,15.19a1.2,1.2,0,0,0,1.7,0l0,0a1.3,1.3,0,0,0,0-1.8l-.85-.86a2.5,2.5,0,0,1-.05-3.44A2.29,2.29,0,0,1,13.09,9l.08.08.84-1a.54.54,0,0,1,.77,0h0a.6.6,0,0,1,0,.81L14,9.85l.88.89A.6.6,0,0,1,14.88,11.55ZM17.18,8a1.5,1.5,0,1,1,1.44-1.55v0A1.47,1.47,0,0,1,17.18,8Z" transform="translate(0)" />
    </svg>
  );
};

export default EventCost;
/* eslint-enable max-len */
