import { Flex, Stack, Text } from '../../elements';
import { TReactNode } from 'resources/types/commonTypes';
import { IStack } from 'resources/types/elementsTypes';

type TCardHeader = IStack & {
  children: TReactNode;
  icon?: TReactNode;
};

const CardHeader = ({ children, icon, ...stackProps }: TCardHeader) => {
  return (
    <Stack alignItems="center" px="lg" py="md" isHorizontal {...stackProps}>
      {icon && <Flex flex="none">{icon}</Flex>}
      <Text fontWeight="medium">{children}</Text>
    </Stack>
  );
};

export default CardHeader;
