import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PieChart, Cell, Pie, ResponsiveContainer, Label, Sector } from 'recharts';
import sumBy from 'lodash/sumBy';

import PieChartLabel from '@old/components/view/charts/PieChartLabel';
import config from '@old/config';

const HorseStatisticsPieChart = ({ data, windowWidth }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const onPieLeave = () => {
    setActiveIndex(null);
  };

  const total = sumBy(data, item => item.value);
  const smallWindow = windowWidth < 1000;

  /* eslint-disable react/prop-types */
  const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value }) => (
    <g>
      {payload.name !== 'empty' && (
        <PieChartLabel
          position="center"
          labelKey={payload.name}
          viewBox={{ cx, cy }}
          value={value}
          small={smallWindow}
        />
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
  /* eslint-enable react/prop-types */

  const dataIsEmpty = data.length === 0 || sumBy(data, item => item.value) === 0;

  return (
    <ResponsiveContainer height={smallWindow ? 150 : 200}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={!dataIsEmpty ? data : [{ name: 'empty', value: 1, color: config.color.tealLight }]}
          innerRadius={smallWindow ? 46 : 60}
          outerRadius={smallWindow ? 56 : 80}
          fill={config.color.tealLight}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          dataKey="value"
          paddingAngle={2}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          {activeIndex === null && (
            <Label position="center" content={<PieChartLabel value={total} labelKey="total" small={smallWindow} />} />
          )}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

HorseStatisticsPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  windowWidth: PropTypes.number.isRequired,
};

const mapStateToProps = ({ app: { windowWidth } }) => ({ windowWidth });

export default connect(mapStateToProps)(HorseStatisticsPieChart);
