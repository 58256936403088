/**
 * @module UsersProvider
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import { throttle, isEmpty } from 'lodash';

import Error404 from '@old/components/error/Error404';
import Spinner from '@old/components/common/Spinner';
import { getPaginationId } from 'old/utils';
import PagePagination from '@old/components/old/PagePagination';
import FlexColumn from '@old/components/common/FlexColumn';
import UsersList from '@old/components/view/list/User';
import UsersGrid from '@old/components/view/grid/User';
import SearchInput from '@old/components/custom/SearchInput';
import Message from '@old/components/common/Message';
import { setPagination, fetchUsers, resetPage } from 'store/actions';
import config from '@old/config';
import t from 'resources/translations';

/**
 * @typedef   {Object} PaginationStore
 * @property  {Number} current current page number
 * @property  {String} pathname current url without host
 */
/**
 * @typedef   {Object} Pagination
 * @property  {Number} count number of items
 * @property  {Number} current current page number
 * @property  {Number} next next page number
 * @property  {Number} pages number of pages
 * @property  {Number} per_page number of items per single page
 * @property  {Number} previous previous page number
 * @property  {Function} push
 */
/**
 * Component that provides users and passes them view component
 * @param  {Object} [props.fetchParams] params for users that will be fetched
 * @param  {Boolean} props.loading specifies wether new users are being fetch
 * @param  {Boolean} props.error specifies wether an error occured when the users were being fetch
 * @param  {User[]} [props.users] fetched users
 * @param  {Pagination} [props.usersPagination] pagination fetched with users
 * @param  {PaginationStore} props.pagination pagination from the store(set after fetched users pagination)
 * @param  {String} [props.pagKey] string passed to create uniq pagination id - passed when tabs are being used
 * @param  {History} props.history
 * @param  {Function} props.fetchUsers function that fetches users and saves them to strone
 * @param  {Function} props.setPagination function that sets pagination and saves it to store
 * @param  {Boolean} props.isMobile specifies wether mobile device is being used
 * @param  {Boolean} props.isSearchable specifies wether search input should be displayed
 */

const UsersProvider = ({
  fetchParams,
  loading,
  error,
  users,
  usersPagination,
  pagination,
  pagKey,
  history,
  isMobile,
  isSearchable,
  ...actions
}) => {
  const [searchQuery, setSearchQuery] = useState();
  const [keyword, setKeyword] = useState('');
  const [wasSearched, setWasSearched] = useState(false);
  const { minItemsPerPage } = config;
  const onChangeQuery = val => {
    actions.resetPage(getPaginationKey());
    setSearchQuery(val);
    searchByKeyword(val);
    setWasSearched(true);
  };

  const searchByKeyword = throttle(value => {
    setKeyword(value);
  }, 500);

  useEffect(() => {
    setSearchQuery('');
    setKeyword('');
  }, [pagKey]);

  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname} ${JSON.stringify(pagKey)}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    // eslint-disable-line
    if (isEmpty(pagination) || pagination.current !== undefined) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const fetch = async () => {
        actions.fetchUsers(
          {
            ...fetchParams,
            per_page: minItemsPerPage,
            page: getActivePage() || 1,
            keyword,
          },
          source.token
        );
      };
      fetch();
      return () => source.cancel();
    }
  }, [history.location.state, pagKey, keyword, pagination.current]); // eslint-disable-line

  useEffect(() => {
    if (usersPagination) {
      const pag = { ...usersPagination };
      // When you delete last element on page, go back to previous one
      const lastPage = Math.ceil(pag.count / pag.per_page);
      if (pag.per_page * pag.current > pag.count) {
        if (lastPage && pag.current > lastPage) {
          pag.current = lastPage;
          history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
        }
        if (pag.current === 0) {
          pag.current = 1;
        }
      }
      actions.setPagination(pag, getPaginationKey());
    }
  }, [usersPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Error404 />;
  if (loading && !users.length && !wasSearched) {
    return <Spinner noDelay />;
  }
  if (!loading && !wasSearched && !users.length) {
    return <Message info>{t('usersProvider.noUsers')}</Message>;
  }

  return (
    <div>
      <FlexColumn>
        {isSearchable && (
          <SearchInput
            name="UsersSearch"
            value={searchQuery}
            onChange={onChangeQuery}
            label={t('labels.search')}
            placeholder={t('placeholders.search')}
            clear={() => onChangeQuery('')}
          />
        )}
        {wasSearched && !loading && users.length === 0 ? (
          <Message info>{t('message.noResults')}</Message>
        ) : (
          <React.Fragment>
            {isMobile ? (
              <UsersList data={users} pending={pagKey === 'pendings'} />
            ) : (
              <UsersGrid data={users} pending={pagKey === 'pendings'} />
            )}
            <PagePagination paginationKey={getPaginationKey()} />
          </React.Fragment>
        )}
      </FlexColumn>
    </div>
  );
};

UsersProvider.defaultProps = {
  users: [],
  usersPagination: {},
  fetchParams: {},
  pagKey: '',
  isMobile: false,
  isSearchable: false,
};

const mapStateToProps = (
  { app, pagination, fetchingData: { users, usersPagination, loading, error }, search },
  { pagKey }
) => {
  const paginationKey = getPaginationId(`${window.location.pathname} ${JSON.stringify(pagKey || '')}`);

  return {
    users,
    usersPagination,
    loading: loading.users,
    error: error.users,
    pagination: pagination[paginationKey] || {},
    query: search.query,
    isMobile: app.isMobile,
  };
};

export default withRouter(connect(mapStateToProps, { fetchUsers, setPagination, resetPage })(UsersProvider));
