import styled from 'styled-components';
import { system } from 'styled-system';
import { baseStyledProps, blockStyleProps } from 'config/styledProps';
import { TImageProps } from 'resources/types/elementsTypes';

const Image = styled('img')<TImageProps>(
  baseStyledProps,
  blockStyleProps,
  system({
    opacity: true,
  })
);

export default Image;
