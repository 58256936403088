import React from 'react';
import { baseStyledProps, blockStyleProps, typographyStyleProps } from 'config/styledProps';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';
import theme from 'config/theme';
import { baseComposePropTypes, blockComposePropTypes, typographyComposePropTypes } from 'old/utils/PropTypes';
const AmountInputWrapper = styled(CurrencyInput).withConfig({
  shouldForwardProp: (prop) => !['borderColor', 'borderWidth', 'borderStyle', 'minHeight', '&:focus'].includes(prop),
})(
  {
    color: theme.colors['grey-dark'],
    borderColor: theme.colors['grey-light'],
    borderWidth: 1,
    borderStyle: 'solid',
    minHeight: 45,
    '&:focus': {
      borderColor: theme.colors['teal-light'],
    },
    '&:hover': {
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)',
    },
  },
  baseStyledProps,
  blockStyleProps,
  typographyStyleProps
);

const amountInputPropTypes = {
  ...baseComposePropTypes,
  ...typographyComposePropTypes,
  ...blockComposePropTypes,
};

const styleProps = {
  color: 'grey-dark',
  borderColor: 'grey-light',
  borderWidth: 1,
  borderStyle: 'solid',
  minHeight: 45,
  px: 'sm',
  '&:focus': {
    borderColor: 'teal-light',
  },
};

const AmountInput = amountInputProps => {
  return <AmountInputWrapper {...styleProps} {...amountInputProps} />;
};

AmountInput.propTypes = {
  ...amountInputPropTypes,
};

export default AmountInput;
