import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changeFieldState } from 'store/actions';
import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';

const MultiSelectField = ({
  onChangeField,
  options,
  customOptionComponents,
  placeholder,
  isSearchable,
  ...props
}) => {
  const { name, formName } = props;

  const onChange = async (selectedOptions) => {
    const fieldState = (selectedOptions || []).map(option => ({ value: option.value, label: option.label }));
    onChangeField({ formName, fieldName: name, fieldState });
  };

  return (
    <FormField {...props}>
      <Input.MultiSelect
        name={name}
        value={props.value}
        options={options}
        label={props.label}
        required={props.required}
        onChange={onChange}
        placeholder={placeholder}
        customOptionComponents={customOptionComponents}
        isSearchable={isSearchable}
      />
    </FormField>
  );
};

MultiSelectField.defaultProps = {
  value: '',
  placeholder: '',
  customOptionComponents: null,
  isSearchable: true,
  required: false,
  label: '',
};

MultiSelectField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})),
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ).isRequired,
  isSearchable: PropTypes.bool,
  customOptionComponents: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(MultiSelectField);
