import React from 'react';
import t from 'resources/translations';
import moment from 'moment';
import Event from 'models/Event';
import { parseToCurrencyString } from 'old/utils';
import { Icons, Link, Span, Stack, Box, Text, Flex, Button } from 'components/elements';
import { Divider, LinkMember } from 'components/combinations';
import RenderDate from 'old/components/old/RenderDate';
import { useAppStore } from 'utils/storeUtils';
import { usePermissions } from 'Permissions';
import { useModal } from 'old/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { EventService } from 'services';
import { mRemoveEventFromEvents } from 'utils';
import { TCacheKey } from 'resources/types/cacheTypes';

const MyProposalInfo = ({ proposal }: { proposal: Event }) => {
  const { isMobile } = useAppStore();
  const queryClient = useQueryClient();
  const cacheKeys: TCacheKey[] = ['PROPOSALS'];
  const instructors = proposal.getInstructors();
  const participations = proposal.getParticipations();
  const horses = proposal.getHorses();
  const canIUpdate = usePermissions('PROPOSAL_UPDATE');
  const canIReject = usePermissions('PROPOSAL_REJECT');
  const canIApprove = usePermissions('PROPOSAL_APPROVE');
  const confirmModal = useModal('CONFIRM');

  const rejectProposal = async () => await EventService.rejectProposal(proposal.id);
  const approveProposal = async () =>
    await EventService.approveProposal(proposal.id, { event: Event.mapToSave(proposal) });

  const mutateRejectOnSuccess = () => mRemoveEventFromEvents(queryClient, cacheKeys, proposal.id);

  const rejectMutation = useMutation(rejectProposal, { onSuccess: mutateRejectOnSuccess });
  const approveMutation = useMutation(approveProposal, { onSuccess: mutateRejectOnSuccess });

  const onOpenConfirmReject = () =>
    confirmModal.onOpen({
      title: proposal.getName(),
      content: (
        <Box mx={-30} backgroundColor="grey-light" width="calc(100% + 60px)" p="xl">
          <Text>{t('rejectProposalModal.confirmRejectEvent', { title: proposal.getName() })}</Text>
        </Box>
      ),
      action: {
        mutation: rejectMutation,
        label: t('buttons.reject'),
        loadingLabel: t('message.rejection'),
      },
    });

  const actions = (
    <Flex alignSelf="center">
      <Stack mb="md">
        {canIUpdate(proposal) && (
          <Link to={`${proposal.getUrl()}/edit`} allowed>
            <Stack spacing="sm" alignItems="center" isHorizontal>
              <Icons.Edit size={12} />
              <Text>{t('general.edit')}</Text>
            </Stack>
          </Link>
        )}
        {canIReject() && (
          <Button onClick={onOpenConfirmReject} variant="link">
            <Stack spacing="sm" alignItems="center" isHorizontal>
              <Icons.Cancel size={12} />
              <Text>{t('proposalEventsList.reject')}</Text>
            </Stack>
          </Button>
        )}
        {canIApprove() && proposal.isReadyToApprove() && (
          <Button variant="link" onClick={() => approveMutation.mutateAsync()} disabled={approveMutation.isLoading}>
            <Stack spacing="sm" alignItems="center" isHorizontal>
              <Icons.Accept size={12} />
              <Text>{t('buttons.accept')}</Text>
            </Stack>
          </Button>
        )}
      </Stack>
    </Flex>
  );

  return (
    <Box my="base">
      <Stack px="xl" mb="md">
        <Stack>
          <Stack spacing="sm" alignItems="center" isHorizontal>
            <Icons.Price fill="teal-dark" size={20} />
            <Text fontWeight="medium">
              {`${t('dashboard.priceForParticipation')}: `}
              <Span fontWeight="regular">{parseToCurrencyString(proposal.type.defaultEventCost)}</Span>
            </Text>
          </Stack>
          <Stack flex={1} spacing="xs">
            <Text fontWeight="medium">
              {`${t('general.date')}: `}
              <Span fontWeight="regular">
                <RenderDate startDate={moment(proposal.startDate)} endDate={moment(proposal.endDate)} sameDateHours />
              </Span>
            </Text>
            <Text fontWeight="medium">
              {`${t('dashboard.eventType')}: `}
              <Span fontWeight="regular">{proposal.type.getName()}</Span>
            </Text>
            <Text fontWeight="medium">
              {`${t('general.level')}: `}
              {/* @ts-ignore */}
              <Span fontWeight="regular">{t(`skills.${proposal.getDifficulty()}`)}</Span>
            </Text>
            {proposal.getPlace() && (
              <Text fontWeight="medium">
                {`${t('general.place')}: `}
                <Span fontWeight="regular">{proposal.getPlace().getName()}</Span>
              </Text>
            )}
          </Stack>
          <Divider color="new-grey-light-30" />
          <Stack flex={1} spacing="xs">
            <Text fontWeight="medium">{t('dashboard.proposedInstructors')}:</Text>
            <Box>
              {instructors.map((instructor, i) => (
                <React.Fragment key={instructor.id}>
                  {i !== 0 && <Span mr={4}>,</Span>}
                  <Link to={instructor.getProfileUrl()} fontSize="sm" fontWeight="medium">
                    {instructor.getFullName()}
                  </Link>
                </React.Fragment>
              ))}
            </Box>
          </Stack>
          {participations.length && (
            <>
              <Divider color="new-grey-light-30" my="sm" />
              <Stack flex={1} spacing="xs">
                <Text fontWeight="medium">{t('dashboard.proposedParticipants')}:</Text>
                <Box>
                  {participations.map((participation, i) => (
                    <React.Fragment key={participation.id}>
                      {i !== 0 && <Span mr={4}>,</Span>}
                      <LinkMember
                        key={participation.id}
                        member={participation.member}
                        fontSize="sm"
                        fontWeight="medium"
                        forceRender
                      >
                        {participation.member.getFullName()}
                      </LinkMember>
                    </React.Fragment>
                  ))}
                </Box>
              </Stack>
            </>
          )}
          {horses.length > 0 && (
            <>
              <Divider color="new-grey-light-30" my="sm" />
              <Stack flex={1} spacing="xs">
                <Text fontWeight="medium">{t('dashboard.proposedHorses')}:</Text>
                <Box>
                  {horses.map((horse, i) => (
                    <React.Fragment key={horse.id}>
                      {i !== 0 && <Span mr={4}>,</Span>}
                      <Link key={horse.id} to={horse.getUrl()} fontSize="sm" fontWeight="medium">
                        {horse.getName()}
                      </Link>
                    </React.Fragment>
                  ))}
                </Box>
              </Stack>
            </>
          )}
          {proposal.description && (
            <>
              <Divider color="new-grey" my="sm" />
              <Stack flex={1} spacing="sm">
                <Text fontWeight="medium">
                  {`${t('dashboard.additionalRemarks')}: `}
                  <Span fontWeight="regular">{proposal.description}</Span>
                </Text>
              </Stack>
            </>
          )}
          {isMobile && actions}
        </Stack>
      </Stack>
    </Box>
  );
};

export default MyProposalInfo;
