import { withRouter } from 'react-router';
import { truncate } from 'lodash';
import cls from 'classnames';

import Link from '@old/components/common/Link';
import t from 'resources/translations';
import NewsItemFooter from '@old/components/old/NewsItemFooter';
import FlexColumn from '@old/components/common/FlexColumn';
import Box from '@old/components/common/Box';
import { useNewsEditButton, useNewsDeleteButton, useNewsPublishButton } from 'old/buttonHooks';
import { ContextTrigger } from 'old/components/common/ContextMenu';

const NewsListItem = ({ newsItem }) => {
  const newsEdit = useNewsEditButton(newsItem);
  const publishNews = useNewsPublishButton(newsItem);
  const newsDelete = useNewsDeleteButton(newsItem);
  const isDraft = newsItem.isDraft();
  const url = isDraft ? `/news/drafts/${newsItem.id}` : `/news/${newsItem.id}`;

  const newsButtons = [publishNews, newsEdit, newsDelete];
  return (
    <ContextTrigger menuItems={newsButtons}>
      <div className="md:rounded bg-grey-light h-full relative">
        <div
          className={cls('bg-no-repeat bg-cover bg-center h-card-small md:rounded-t', { 'opacity-50': isDraft })}
          style={{ backgroundImage: `url(${newsItem.getImage()})` }}
        />
        <Box>
          <FlexColumn padded marginBottom>
            <Link className={cls('text-highlight break-words', { 'opacity-50': isDraft })} to={url}>
              {truncate(newsItem.title, { length: 100, omission: '...' })}
            </Link>
            <div className={cls('break-words', { 'opacity-50': isDraft })}>
              <div
                className="break-words text-content"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: truncate(newsItem.getContent(), { length: 250 }) }}
              />
              {newsItem.content.length > 250 && (
                <Link className="text-highlight" to={url}>
                  {`${t('newsListItem.readMore')}`}
                </Link>
              )}
            </div>
          </FlexColumn>
        </Box>
        <NewsItemFooter
          newsItem={newsItem}
          isDraft={isDraft}
          className={cls('absolute md:bottom-4 sm:bottom-1 md:px-10 sm:px-6', { 'opacity-50': isDraft })}
        />
      </div>
    </ContextTrigger>
  );
};
export default withRouter(NewsListItem);
