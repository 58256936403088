import _ from 'lodash';
import { TPrivilege } from 'resources/types/commonTypes';
import { IMemberGroupRes } from 'resources/types/memberGroupsTypes';
import Member from './Member';
import t from 'resources/translations';

class MemberGroup {
  id: number;
  name: string;
  description: string;
  memberships: Member[];
  privileges: TPrivilege[];
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IMemberGroupRes) {
    this.id = data.id;
    this.name = data.name;
    this.memberships = (data.memberships || []).map(membership => new Member(membership));
    this.description = data.description;
    this.privileges = data.meta.privileges || [];
    this.deleted = data.deleted;
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
  }

  getUrl() {
    return `/riding-groups/${this.id}`;
  }

  getName(limit: number = 0) {
    return limit ? _.truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getSize() {
    return this.memberships.length;
  }

  getMembersIds() {
    return this.memberships.map(member => member.id);
  }

  getDescription() {
    return this.description || t('general.noDescription');
  }

  getMembers() {
    return this.memberships || [];
  }
}

export default MemberGroup;
