import React, { useState } from 'react';

import t from 'resources/translations';
import SearchInput from '@old/components/custom/SearchInput';
import Subtitle from '@old/components/common/Subtitle';
import FlexColumn from '@old/components/common/FlexColumn';

const SearchUsersInvited = ({ members }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const onSearch = value => {
    setSearchQuery(value);
  };

  const clearQuery = () => {
    setSearchQuery('');
  };

  const filteredMembers = members.filter(
    member => member.email.toLowerCase().includes(searchQuery) || searchQuery === null
  );
  return (
    <React.Fragment>
      <FlexColumn marginBottom>
        <Subtitle>{t('searchUsersInvited.invitationSent')}</Subtitle>
        <SearchInput
          name="UsersInvitedSearch"
          value={searchQuery}
          onChange={onSearch}
          label={t('labels.listUsers')}
          placeholder={t('placeholders.searchByName')}
          clear={clearQuery}
        />
        <div className="relative">
          <div className="select-users border border-grey-light rounded">
            {filteredMembers.map(member => (
              <div key={member.id} className="mx-2 truncate py-3 border-b border-grey-light">
                {member.email}
              </div>
            ))}
          </div>
        </div>
      </FlexColumn>
    </React.Fragment>
  );
};

export default SearchUsersInvited;
