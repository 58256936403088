import { useState } from 'react';

import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';

import { getErrorMessage, notify, getChanges, mapFormStateToData, getInitValues, isAllowed } from 'old/utils';
import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_NEWS } from 'old/constants/formNames';
import FlexRow from '@old/components/common/FlexRow';

// FIXME news api is ****, when we will have more time we need to talk about redoing and implementing it in correct way
const NewsForm = ({ doc, history, initValues, ...props }) => {
  const [loading, setLoading] = useState(false);
  const formName = FORM_NEWS(doc ? doc.id : '');

  const cropperOptions = {
    aspectRatio: 21 / 9,
  };

  const initialValues = getInitValues({
    formName: 'news',
    customInitValues: doc ? Model.NewsItem.mapToFormData(doc) : initValues,
  });

  const getModifier = formState => {
    return doc ? getChanges(formState, mapFormStateToData({ formState: initialValues, initValue: true })) : formState;
  };

  const publishNews = async formState => {
    try {
      setLoading(true);
      const values = getModifier(formState);
      await props.onSubmit(Model.NewsItem.mapToSaveData(values), { params: { commit: 'publish' } });
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const publishDraft = async formState => {
    try {
      const values = getModifier(formState);
      await doc.publish();
      if (!isEmpty(values)) {
        await props.onSubmit(Model.NewsItem.mapToSaveData(values), { params: { commit: 'publish' } });
      }
      history.replace(`/news/${doc.id}`);
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const saveNewsItemToDraft = async formState => {
    try {
      setLoading(true);
      const values = getModifier(formState);
      await props.onSubmit(Model.NewsItem.mapToSaveData(values), {
        params: { commit: 'draft' },
        redirectTo: '/news/drafts/',
        redirectWithId: true,
      });
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const saveDraft = async formState => {
    try {
      const values = getModifier(formState);
      await props.onSubmit(Model.NewsItem.mapToSaveData(values), {
        params: { commit: 'draft' },
        redirectTo: `/news/drafts/${doc.id}`,
      });
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const docIsDraft = doc && doc.isDraft();
  const docIsNews = doc && !doc.isDraft();
  const docIsNotDraft = doc && !doc.isDraft();

  return (
    <Form formName={formName} initValues={initialValues}>
      <Field.Text name="newsTitle" label={t('labels.title')} placeholder={t('placeholders.title')} required />
      <Field.RichTextEditor
        name="content"
        label={t('labels.content')}
        placeholder={t('placeholders.content')}
        required
      />
      <Field.ImageUploader
        label={t('labels.photo')}
        placeholder={t('placeholders.dropFile')}
        name="image"
        options={{
          cropperOptions,
        }}
        required
      />
      {docIsNews && doc.getPermission('update') && (
        <FormButton formName={formName} onClick={publishNews} disabled={loading}>
          {t('general.save')}
        </FormButton>
      )}
      {docIsDraft && (
        <FlexRow cols="none/none">
          {doc.getPermission('update') && (
            <FormButton formName={formName} onClick={saveDraft} outline disabled={loading}>
              {t('general.save')}
            </FormButton>
          )}
          {doc.getPermission('publish') && (
            <FormButton formName={formName} onClick={publishDraft} availableBeforeFormIsChanged disabled={loading}>
              {t('newsForm.publish')}
            </FormButton>
          )}
        </FlexRow>
      )}
      {!doc && isAllowed('news.create') && (
        <FlexRow cols="none/none">
          <FormButton formName={formName} onClick={saveNewsItemToDraft} outline disabled={loading}>
            {t('newsForm.addToDraft')}
          </FormButton>
          <FormButton formName={formName} onClick={publishNews} disabled={loading}>
            {docIsNotDraft ? t('general.save') : t('newsForm.publish')}
          </FormButton>
        </FlexRow>
      )}
    </Form>
  );
};

NewsForm.defaultProps = {
  doc: null,
  initValues: {},
};

export default withRouter(NewsForm);
