/**
 * @module ButtonSimple
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import Button from '@old/components/guide/Button';
/**
 * Properties of the button
 * @typedef   {Object} ButtonProps
 * @property  {Function} [onClick] specifies what happens after button click
 * @property  {Boolean} [disabled] specifies wether the button should be disabled
 * @property  {Boolean|'simple'} [async] specifies wether the onClick function should be called asynchronously
 * @property  {String} [tabIndex] {@link https://developer.mozilla.org/pl/docs/Web/HTML/Global_attributes/tabindex}
 * @property  {'submit'|'button'|'reset'} [type] specifies type of the button
 */
/**
 * Component that generates simple button styled as normal text
 * @param  {String|Node} props.children
 * @param  {Boolean} [props.fluid] specifies wether the button should have full width of it's parent
 * @param  {String} [props.className]
 * @param  {ButtonProps} props.buttonProps
 */
const ButtonSimple = ({ children, fluid, className, ...buttonProps }) => {
  const { name } = buttonProps;
  return (
    <Button {...buttonProps} name={name} className={cls('simple', className, { fluid })}>{children}</Button>
  );
};

ButtonSimple.defaultProps = {
  fluid: false,
  className: '',
};

ButtonSimple.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  fluid: PropTypes.bool,
  className: PropTypes.string,
};

export default ButtonSimple;
