import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import InputWrapper from '@old/components/common/InputWrapper';

const TextareaInput = ({ value, name, onChange, placeholder, required, icon, label, ...props }) => {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  });

  const onInputChange = e => {
    onChange(e.target.value, name);
  };

  return (
    <InputWrapper label={label} icon={icon} required={required}>
      <textarea
        ref={inputRef}
        name={name}
        value={value || ''}
        onChange={onInputChange}
        placeholder={placeholder}
        {...props}
      />
    </InputWrapper>
  );
};

TextareaInput.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  label: '',
  icon: false,
  required: false,
};

TextareaInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.node,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default TextareaInput;
