/* eslint-disable max-len */
import React from 'react';

const WarningOutlie = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,10.1c0.6,0,1.1-0.5,1.1-1.1V4.3c0-0.6-0.5-1.1-1.1-1.1S6.9,3.7,6.9,4.3V9C6.9,9.6,7.4,10.1,8,10.1L8,10.1z M8,10.6
        c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.5-0.3,0.7s0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3s0.3-0.5,0.3-0.7S9,11.2,8.7,11
        S8.3,10.6,8,10.6z M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14.8c-3.7,0-6.8-3-6.8-6.8s3-6.8,6.8-6.8s6.8,3,6.8,6.8
        S11.7,14.8,8,14.8z"
      />
    </svg>
  );
};

export default WarningOutlie;
/* eslint-enable max-len */
