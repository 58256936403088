import store from 'store';
import { EventTypes, EventType } from '@old/model/EventType';
import { Farms, Farm } from '@old/model/Farm';
import { Horses, Horse } from '@old/model/Horse';
import { Events, Event } from '@old/model/Event';
import { Members, Member } from '@old/model/Member';
import { MemberGroups, MemberGroup } from '@old/model/MemberGroup';
import { News, NewsItem } from '@old/model/NewsItem';
import { Places, Place } from '@old/model/Place';
import { Proposals, Proposal } from '@old/model/Proposal';
import { Notifications, Notification } from '@old/model/Notification';
import { Bills, Bill } from '@old/model/Bill';
import { Conversations, Conversation } from '@old/model/Conversations';
import Participation from '@old/model/Participation';
import getClient from '@old/model/client';

class Model {
  constructor() {
    this.client = getClient();
    this.createModelInstances();
  }

  refreshClientToken(newToken) {
    const token = newToken || localStorage.getItem('auth_token');

    this.client.updateHeader('Authorization', token);
  }

  getToken() {
    return this.client.getToken();
  }

  getModelOptions(currentFarmId = null) {
    const state = store ? store.getState() : null;
    const farmId = currentFarmId || state.auth?.farm?.id;
    this.refreshClientToken();

    return { client: this.client, farmId: farmId || null };
  }

  refresh(token = null, farmId = null) {
    this.refreshClientToken(token);
    const options = this.getModelOptions(farmId);

    this.EventTypes.refreshTokenAndFarmId(options);
    this.Horses.refreshTokenAndFarmId(options);
    this.Events.refreshTokenAndFarmId(options);
    this.Farms.refreshTokenAndFarmId(options);
    this.Members.refreshTokenAndFarmId(options);
    this.MemberGroups.refreshTokenAndFarmId(options);
    this.News.refreshTokenAndFarmId(options);
    this.Places.refreshTokenAndFarmId(options);
    this.Proposals.refreshTokenAndFarmId(options);
    this.Notifications.refreshTokenAndFarmId(options);
    this.Conversations.refreshTokenAndFarmId(options);
    this.Bills.refreshTokenAndFarmId(options);
  }

  createModelInstances() {
    const options = this.getModelOptions();

    this.EventTypes = new EventTypes(options);
    this.Horses = new Horses(options);
    this.Events = new Events(options);
    this.Farms = new Farms(options);
    this.Members = new Members(options);
    this.MemberGroups = new MemberGroups(options);
    this.News = new News(options);
    this.Places = new Places(options);
    this.Proposals = new Proposals(options);
    this.Notifications = new Notifications(options);
    this.Conversations = new Conversations(options);
    this.Bills = new Bills(options);
    this.EventType = EventType;
    this.Farm = Farm;
    this.Horse = Horse;
    this.Event = Event;
    this.Member = Member;
    this.MemberGroup = MemberGroup;
    this.NewsItem = NewsItem;
    this.Place = Place;
    this.Proposal = Proposal;
    this.Notification = Notification;
    this.Conversation = Conversation;
    this.Bill = Bill;
    this.Participation = Participation;
  }
}

export default new Model();
