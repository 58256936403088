import { Button, Content, Flex, Grid, Stack, Text } from 'components/elements';
import Icon from 'old/components/icon';
import Event from 'models/Event';
import t from 'resources/translations';
import ParticipantItem from './ParticipantItem';
import { PaymentEventModal, PaymentReturnModal } from 'views/modals';
import { usePermissions } from 'Permissions';
import { openModal } from 'store/actions';
import store from 'store';
import { useQueryClient } from 'react-query';
import { Event as OldEvent } from 'old/model/Event';
import { Divider } from 'components/combinations';
import _ from 'lodash';
import Spinner from 'old/components/common/Spinner';

type TParticipationsProps = {
  event: Event;
  oldEventModel: OldEvent;
  cb: () => void;
  isFetching: boolean;
};

const styleProps = {
  placholderBlock: {
    p: 'md',
    alignItems: 'center',
    borderStyle: 'dashed',
    borderColor: 'grey',
    borderWidth: 1,
    borderRadius: 4,
    h: 92,
  },
  dashedButton: {
    p: 'md',
    alignItems: 'center',
    borderStyle: 'dashed',
    borderColor: 'teal',
    borderWidth: 1,
    borderRadius: 4,
  },
};

const Participations = ({ event, oldEventModel }: TParticipationsProps) => {
  const queryClient = useQueryClient();

  const canIInvite = usePermissions('EVENT_INVITE')(event);
  const canIAdd = usePermissions('EVENT_ADD')(event);

  const refreshData = () => queryClient.invalidateQueries(['event'], { refetchActive: true });

  const openInviteModal = () =>
    store.dispatch(openModal('inviteParticipants', { event: oldEventModel, cb: refreshData }));
  const openAddModal = () => store.dispatch(openModal('addParticipant', { event: oldEventModel, cb: refreshData }));

  if (!event) {
    return <Spinner />;
  }

  const joinedParticipations = event.getParticipationsByStatus('joined');
  const requestedParticipations = event.getParticipationsByStatus('invitation_requested');
  const inviteParticipations = event.getParticipationsByStatus('invited');
  const rejectedParticipations = event.getParticipationsByStatus('rejected');

  return (
    <Content>
      <Stack spacing="2xl">
        <Stack spacing="sm">
          <Flex>
            <Text fontWeight="medium">{t('eventParticipationsManage.savedRiders')}:</Text>
          </Flex>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap="md">
            {joinedParticipations.map(participation => (
              <ParticipantItem key={participation.id} event={event} participation={participation} />
            ))}
            {_.isEmpty(joinedParticipations) && !canIAdd && (
              <Stack {...styleProps.placholderBlock} isHorizontal>
                <Flex flex="none" color="grey">
                  <Icon.Human />
                </Flex>
                <Text color="grey">{t('placeholders.noJoinedParticipants')}</Text>
              </Stack>
            )}
            {canIAdd && (
              <Button variant="icon" alignSelf="center" onClick={openAddModal}>
                <Stack {...styleProps.dashedButton} isHorizontal>
                  <Flex flex="none">
                    <Icon.UserCircular big />
                  </Flex>
                  <Text>{t('model.add.participants')}</Text>
                </Stack>
              </Button>
            )}
          </Grid>
        </Stack>

        <Divider color="grey" />

        <Stack spacing="sm">
          <Flex>
            <Text fontWeight="medium">{t('eventParticipationsManage.requestedToJoin')}:</Text>
          </Flex>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap="md">
            {requestedParticipations.map(participation => (
              <ParticipantItem key={participation.id} event={event} participation={participation} />
            ))}
            {_.isEmpty(requestedParticipations) && (
              <Stack {...styleProps.placholderBlock} isHorizontal>
                <Flex flex="none" color="grey">
                  <Icon.Human />
                </Flex>
                <Text color="grey">{t('placeholders.noRequestedParticipants')}</Text>
              </Stack>
            )}
          </Grid>
        </Stack>

        <Divider color="grey" />

        <Stack spacing="sm">
          <Flex>
            <Text fontWeight="medium">{t('eventParticipationsManage.invitationsSend')}:</Text>
          </Flex>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap="md">
            {inviteParticipations.map(participation => (
              <ParticipantItem key={participation.id} event={event} participation={participation} />
            ))}
            {_.isEmpty(inviteParticipations) && !canIInvite && (
              <Stack {...styleProps.placholderBlock} isHorizontal>
                <Flex flex="none" color="grey">
                  <Icon.Human />
                </Flex>
                <Text color="grey">{t('placeholders.noParticipants')}</Text>
              </Stack>
            )}
            {canIInvite && (
              <Button variant="icon" alignSelf="center" onClick={openInviteModal}>
                <Stack {...styleProps.dashedButton} isHorizontal>
                  <Flex flex="none">
                    <Icon.InviteUser circle />
                  </Flex>
                  <Text>{t('general.inviteParticipants')}</Text>
                </Stack>
              </Button>
            )}
          </Grid>
        </Stack>
        <Divider color="grey" />
        <Stack spacing="sm">
          <Flex>
            <Text fontWeight="medium">{t('eventParticipationsManage.rejectedInvitation')}:</Text>
          </Flex>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap="md">
            {rejectedParticipations.map(participation => (
              <ParticipantItem key={participation.id} event={event} participation={participation} />
            ))}
            {_.isEmpty(rejectedParticipations) && (
              <Stack {...styleProps.placholderBlock} isHorizontal>
                <Flex flex="none" color="grey">
                  <Icon.Human />
                </Flex>
                <Text color="grey">{t('placeholders.noParticipants')}</Text>
              </Stack>
            )}
          </Grid>
        </Stack>
      </Stack>
      <PaymentReturnModal />
      <PaymentEventModal />
    </Content>
  );
};

export default Participations;
