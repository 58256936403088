import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IContextMenuStore {
  cords: { x: number; y: number };
  content: [];
}

const initialState: IContextMenuStore = {
  cords: { x: 0, y: 0 },
  content: [],
};

export const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState,
  reducers: {
    setCords: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.cords = action.payload;
    },
    setContent: (state, action: PayloadAction<[]>) => {
      state.content = action.payload;
    },
  },
});

export const { setCords, setContent } = contextMenuSlice.actions;

export default contextMenuSlice.reducer;
