import { connect } from 'react-redux';
import NotificationListItem from '@old/components/view/listItem/Notification';
import FlexColumn from '@old/components/common/FlexColumn';
import { openAllNotifications, fetchNotifications } from 'store/actions';
import t from 'resources/translations';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import store from 'store';

const NotificationsList = ({ data, ...actions }) => {
  const onClick = async () => {
    await actions.openAllNotifications();
    const {
      fetchingData: { notificationsFetchParams },
    } = store.getState();
    actions.fetchNotifications(notificationsFetchParams);
  };

  return (
    <FlexColumn>
      <div className="float-right">
        <ButtonSimple onClick={onClick}>
          <div className="text-teal">{t('notificationsList.markAsRead')}</div>
        </ButtonSimple>
      </div>
      {data.map(notification => (
        <NotificationListItem key={notification.id} notification={notification} />
      ))}
    </FlexColumn>
  );
};

export default connect(null, { openAllNotifications, fetchNotifications })(NotificationsList);
