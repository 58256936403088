import { useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compact } from 'lodash';

import FloatingButton from '@old/components/custom/FloatingButton';
import t from 'resources/translations';
import {
  useMemberStatus,
  useStatistics,
  usePendingAcceptButton,
  usePendingRejectButton,
  useUserEditShiftButton,
  useMemberManagementButton,
  useUserEditButton,
  useGuestEditButton,
} from '@old/hooks';
import Box from '@old/components/common/Box';
import ImageCircle from '@old/components/guide/ImageCircle';
import FlexRow from '@old/components/common/FlexRow';
import PageTitle from '@old/components/guide/PageTitle';
import Icon from '@old/components/icon';
import FlexColumn from '@old/components/common/FlexColumn';
import TextValue from '@old/components/common/TextValue';
import RowAligner from '@old/components/common/RowAligner';
import SelectDateRange from '@old/components/modals/SelectDateRange';
import Center from '@old/components/common/Center';
import BoxWhite from '@old/components/common/BoxWhite';
import ColorBar from '@old/components/common/ColorBar';
import Error404 from '@old/components/error/Error404';
import Subtitle from '@old/components/common/Subtitle';
import { useOldLoggedMember } from 'utils/storeUtils';

const ClientDetails = ({ isMobile, member, loading, history }) => {
  const loggedMember = useOldLoggedMember();
  const status = useMemberStatus(member, loggedMember);
  const isMyProfile = loggedMember.id === member.id;
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(7, 'days').startOf('day'),
    end: moment().subtract(1, 'days').endOf('day'),
  });
  const [stats, statsReady] = useStatistics(member.id, 'members', dateRange, isMyProfile || !loggedMember.isClient());
  const numberOfHours = stats ? Math.round(stats.total.finished_duration / 60) : 0;
  const skillColor = member.getSlugInfo().color;
  const redirect = () => {
    history.replace('/users');
  };

  const pendingAccept = usePendingAcceptButton(member, true);
  const pendingDelete = usePendingRejectButton(member, true, redirect);
  const editShift = useUserEditShiftButton(member);
  const memberManagement = useMemberManagementButton(member, status, true);
  const editGuest = useGuestEditButton(member);
  const userEdit = useUserEditButton(member, status.isCurrentUser);

  const memberButtons = [pendingAccept, pendingDelete, editShift, memberManagement, editGuest, userEdit];

  if (member.isDeleted()) return <Error404 />; // ??

  if (isMobile) {
    return (
      <>
        <PageTitle title={member.getName()} back="/users" loading={loading} />
        <FlexRow cols="10/6" marginBottom stretched>
          <BoxWhite padding>
            <FlexRow>
              <FlexColumn center>
                <ImageCircle src={member.getAvatar()} large />
                <Subtitle>{t('general.rider')}</Subtitle>
              </FlexColumn>
              <FlexColumn center separated="small">
                <div>
                  <Icon.Phone className="fill-teal" small />
                  <a className="text ml-2" href={`tel:${member.phone}`}>
                    {member.phone}
                  </a>
                </div>
                {!member.isGuest() && (
                  <div>
                    <Icon.Mail className="fill-teal" small />
                    <a className="text ml-2" href={`mailto:${member.email}`}>
                      {member.email}
                    </a>
                  </div>
                )}
                <Center>
                  <IndocatorColorWithLine color={skillColor} />
                </Center>
                {member.skill && <TextValue text={t('general.level')} value={t(`skills.${member.getSkill()}`)} />}
              </FlexColumn>
            </FlexRow>
          </BoxWhite>
          {member.isClient() && member.getUserNote() && (
            <BoxWhite>
              <FlexColumn>
                <Subtitle>{t('labels.note')}</Subtitle>
                <div style={{ wordBreak: 'break-word' }}>{member.getUserNote()}</div>
              </FlexColumn>
            </BoxWhite>
          )}
          {isMyProfile && (
            <FlexRow noShrink>
              <Box fullHeight>
                <FlexColumn center separated="small">
                  <Subtitle>{t('labels.birthDate')}</Subtitle>
                  <Icon.Cake className="fill-teal" big />
                  <div>{member.getBirthday()}</div>
                </FlexColumn>
              </Box>
              <Box fullHeight>
                <FlexColumn center separated="small">
                  <Subtitle>{t('labels.ridings')}</Subtitle>
                  <div className="py-4 px-4 border border-teal rounded">
                    {statsReady ? (
                      t('labels.hours', { hours: numberOfHours })
                    ) : (
                      <Icon.Spinner className="fill-teal rotate small" />
                    )}
                  </div>
                  <SelectDateRange dateRange={dateRange} onSave={setDateRange} />
                </FlexColumn>
              </Box>
            </FlexRow>
          )}
          {!isMyProfile && (
            <Box fullHeight>
              <FlexColumn center separated="small">
                <Subtitle>{t('labels.ridings')}</Subtitle>
                <div className="py-4 px-4 border border-teal rounded">
                  {statsReady ? (
                    t('labels.hours', { hours: numberOfHours })
                  ) : (
                    <Icon.Spinner className="fill-teal rotate small" />
                  )}
                </div>
                <SelectDateRange dateRange={dateRange} onSave={setDateRange} />
              </FlexColumn>
            </Box>
          )}
        </FlexRow>
        <FloatingButton buttons={compact(memberButtons)} />
      </>
    );
  }
  return (
    <>
      <PageTitle title={member.getName()} back="/users" loading={loading} />
      <FlexRow cols="none/1" stretched separated={false} marginBottom>
        <div className="bg-grey py-6 px-2 items-center">
          <ColorBar color={skillColor} vertical />
        </div>
        <FlexRow cols="10/6" stretched>
          <Box>
            <FlexRow>
              <FlexColumn center>
                <ImageCircle src={member.getAvatar()} large />
              </FlexColumn>
              <FlexColumn>
                <Subtitle>{t('general.rider')}</Subtitle>
                <FlexColumn separated="small">
                  <div>
                    <Icon.Phone className="fill-teal" small />
                    <a className="text ml-2" href={`tel:${member.phone}`}>
                      {member.phone}
                    </a>
                  </div>
                  {!member.isGuest() && (
                    <div>
                      <Icon.Mail className="fill-teal" small />
                      <a className="text ml-2" href={`mailto:${member.email}`}>
                        {member.email}
                      </a>
                    </div>
                  )}
                  {member.skill && (
                    <div>
                      <TextValue text={t('general.level')} value={t(`skills.${member.getSkill()}`)} />
                    </div>
                  )}
                </FlexColumn>
              </FlexColumn>
            </FlexRow>
          </Box>
          <FlexColumn>
            {isMyProfile && (
              <Box fullHeight>
                <RowAligner>
                  <FlexColumn>
                    <Subtitle>{t('labels.birthDate')}</Subtitle>
                    <div>{member.getBirthday()}</div>
                  </FlexColumn>
                  <Icon.Cake className="fill-teal" big />
                </RowAligner>
              </Box>
            )}
            <Box fullHeight>
              <RowAligner>
                <FlexColumn>
                  <Subtitle>{t('labels.ridings')}</Subtitle>
                  <SelectDateRange dateRange={dateRange} onSave={setDateRange} />
                </FlexColumn>
                <div className="py-4 px-4 border border-teal rounded">
                  {statsReady ? (
                    t('labels.hours', { hours: numberOfHours })
                  ) : (
                    <Icon.Spinner className="fill-teal rotate small" />
                  )}
                </div>
              </RowAligner>
            </Box>
          </FlexColumn>
        </FlexRow>
      </FlexRow>
      {member.isClient() && member.getUserNote() && (
        <Box>
          <FlexColumn>
            <Subtitle>{t('labels.note')}</Subtitle>
            <div style={{ wordBreak: 'break-word' }}>{member.getUserNote()}</div>
          </FlexColumn>
        </Box>
      )}
      <FloatingButton buttons={compact(memberButtons)} />
    </>
  );
};

const mapStateToProps = ({ fetchingData: { loading }, app: { isMobile } }) => ({
  isMobile,
  loading: loading.users,
});

export default withRouter(connect(mapStateToProps)(ClientDetails));

/**
 * Indicator component with custom color and line under them
 * @param  {String} color Custom color of the indicator
 */
const IndocatorColorWithLine = ({ color }) => {
  return (
    <>
      <div className="circle-small" style={{ backgroundColor: color }} />
      <div className="line absolute w-1/2" />
    </>
  );
};

IndocatorColorWithLine.propTypes = {
  color: PropTypes.string.isRequired,
};
