import ImageCircle from '@old/components/guide/ImageCircle';
import FlexColumn from '@old/components/common/FlexColumn';
import Card from '@old/components/common/Card';
import RowAligner from '@old/components/common/RowAligner';
import Center from '@old/components/common/Center';
import Subtitle from '@old/components/common/Subtitle';
import { ContextTrigger } from '@old/components/common/ContextMenu';
import Link from '@old/components/common/Link';
import ColorBar from '@old/components/common/ColorBar';
import { useEventTypeEditButton, useEventTypeDeleteButton } from 'old/buttonHooks';

const EventTypesGridItem = ({ eventType }) => {
  const editEventType = useEventTypeEditButton(eventType);
  const deleteEventType = useEventTypeDeleteButton(eventType);
  const eventTypeButtons = [editEventType, deleteEventType];
  const colorBar = <ColorBar color={eventType.getColor()} />;

  return (
    <ContextTrigger menuItems={eventTypeButtons}>
      <Card footerContent={colorBar}>
        <Center>
          <FlexColumn>
            <FlexColumn>
              <RowAligner className="justify-center">
                <Link to={`/event-types/${eventType.id}`}>
                  <ImageCircle src={eventType.getImage()} big />
                </Link>
              </RowAligner>
              <Link to={`/event-types/${eventType.id}`}>
                <Subtitle>{eventType.getName(40)}</Subtitle>
              </Link>
            </FlexColumn>
            <div className="leading-tight uppercase">{eventType.getSlug()}</div>
          </FlexColumn>
        </Center>
      </Card>
    </ContextTrigger>
  );
};

export default EventTypesGridItem;
