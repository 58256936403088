/* eslint-disable max-len */
import React from 'react';

const Events = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M7.9,1.2c0,0.6-0.5,1.2-1.2,1.2S5.6,1.8,5.6,1.2S6.1,0,6.7,0S7.9,0.5,7.9,1.2z M5.4,4.2c0,0-0.1-1.7,1.8-1.4
        c0,0,0.9,0,1.3,2.6l1.3,0.9c0,0,1.4-1.2,2.9-1.8c0,0,0.9-0.9,1.6-0.9c0,0-0.6,0.8-0.2,1.2c0,0,1.4,2,1.5,2.2c0,0-0.1,0.9-0.8,0.6
        c-0.1,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-1.5-0.9-2.1-0.1c0,0-0.4,0.7-0.4,1.2c0,0-0.2,1-0.4,1.3l0.9,2.3
        c0,0,0.2,0.3,0.1,0.6c0,0-0.4,2.6-1.2,2.3c0,0-0.4-0.1-0.4-0.7l0.4-1.7c0,0-0.4-0.7-1.4-1.5l-0.4,4.1c0,0-0.1,0.6-0.4,0.7
        c0,0-0.4,0.1-0.6-0.7l-0.1-3.6c0,0-0.6-0.1-0.7,0.3c0,0-0.1,0.8-0.8,0.6c0,0-0.7-0.1-0.5-0.9l0.5-2.6l-2-2.8L5.4,4.2L5.4,4.2z
        M5.8,7.4l1.2,1.8l-0.2,2.5l-0.8-0.2c0,0,0.4,1,0.4,1.2c0,0-0.2,2.7-1.2,2.6c0,0-0.8-0.2-0.2-1.2L5.3,13l-1.1-1.3v1.4
        c0,0-0.4,3-1.1,2.9c0,0-0.6-0.1-0.5-0.6c0,0,0.2-2,0.4-2.4c0,0-1.5-3.3-0.9-4.4c0,0-0.7-0.3-0.4,1.5c0,0,0.6,2.3-0.4,3.2
        c0,0,0.2-1-0.6-2.6c0,0-1-2.6,1.8-2.9C2.4,7.8,3.3,6.9,5.8,7.4L5.8,7.4z"
      />
    </svg>
  );
};

export default Events;
/* eslint-enable max-len */
