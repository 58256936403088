import React from 'react';
import { components } from 'react-select';

import Icon from '@old/components/icon';

// MultiValue Remove used in select inputs
const SelectMultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon.Cancel circle small />
    </components.MultiValueRemove>
  );
};

export default SelectMultiValueRemove;
