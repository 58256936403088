import React from 'react';
import cls from 'classnames';

// forwardRef doesn't support prop types
// eslint-disable-next-line react/prop-types
const InputWrapper = ({ children, className, relative, icon, label, required, name, disabled }, ref) => (
  <div
    name={name}
    className={cls('input-wrapper', className, { relative, labeled: label }, disabled && 'inactive')}
    ref={ref}
  >
    {children}
    <span className="input-icon">{icon}</span>
    {label && <label>{label}{required && <span className="asterisk" />}</label>}
  </div>
);

export default React.forwardRef(InputWrapper);
