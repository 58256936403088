import EventListItem from '@old/components/view/listItem/Event';
import t from 'resources/translations';
import Message from '@old/components/common/Message';
import FlexColumn from '@old/components/common/FlexColumn';
import { datesIsSame } from 'old/utils';
import DateLabel from '@old/components/custom/DateLabel';

const EventsList = ({ fetchedEvents, showDateLabels, hideEmptyMessage, ...props }) => {
  if (!fetchedEvents.length && hideEmptyMessage) return null;

  if (!fetchedEvents.length) {
    return <Message info>{t('eventsList.noEvents')}</Message>;
  }

  const renderDate = ({ prevDate, date, check }) => {
    // eslint-disable-line react/prop-types
    let newDay = true;
    if (check) {
      newDay = !datesIsSame(prevDate, date);
    }
    return newDay && <DateLabel date={date.format('DD.MM.YYYY, dddd')} />; // eslint-disable-line react/prop-types
  };

  return (
    <FlexColumn>
      {fetchedEvents.map((event, index) => (
        <FlexColumn key={event.id}>
          {showDateLabels &&
            renderDate({
              prevDate: fetchedEvents[index ? index - 1 : 0].startDate,
              date: event.startDate,
              check: index > 0,
            })}
          <EventListItem event={event} {...props} />
        </FlexColumn>
      ))}
    </FlexColumn>
  );
};

EventsList.defaultProps = {
  fetchedEvents: [],
  showDateLabels: false,
  hideEmptyMessage: false,
};

export default EventsList;
