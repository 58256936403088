/**
 * @module Tag
 */
import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { connect } from 'react-redux';

import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
/**
 * Content of the tag
 * @typedef   {Object} TagItem
 * @property  {String|Number} key tag key
 * @property  {String} label label of the tag
 */
/**
 * Component that shows tag(element that is selected mainly in select fields)
 * @param  {TagItem} props.item
 * @param  {Function} props.onDelete function that specifies what happens after "X" is clicked
 * @param  {Boolean} props.isMobile specifies whether component is running on mobile device
 * @param  {Boolean} props.isTablet specifies whether component is running on tablet device
 * @param {Function|Boolean=false} [props.customRenderTag] custom method tag label rendering
 */
const Tag = ({ item, onDelete, isMobile, customRenderTag, isTablet }) => {
  const label = (isMobile && truncate(item.label, { length: 35 }))
    || (isTablet && truncate(item.label, { length: 75 }))
    || item.label;

  return (
    <div key={item.key} className="tag">
      <span className="tag-label">{customRenderTag ? customRenderTag(item) : label}</span>
      <ButtonSimple onClick={e => onDelete(item, e)} tabIndex={-1}>
        <Icon.Cancel circle small />
      </ButtonSimple>
    </div>
  );
};
Tag.defaultProps = {
  customRenderTag: false,
};

Tag.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  customRenderTag: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([false]),
  ]),
};

const mapStateToProps = ({ app: { isMobile, isTablet } }) => ({
  isMobile,
  isTablet,
});

export default connect(mapStateToProps)(Tag);
