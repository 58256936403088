import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import t from 'resources/translations';
import { getErrorMessage, notify } from 'old/utils';
import ImageCircle from '@old/components/guide/ImageCircle';
import Center from '@old/components/common/Center';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';
import FlexColumn from '@old/components/common/FlexColumn';
import Input from '@old/components/input';

const EditPicture = ({ onClose, modelItem, picture, refreshAction }) => {
  const [description, setDescription] = useState(picture?.description || '');

  const editPicture = async () => {
    try {
      await modelItem.updatePicture({ pictures: [{ ...picture, description }] });
      notify(t('message.photoSuccesSave'));
      refreshAction(modelItem.modelName !== 'farms' && modelItem.id);
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    onClose();
  };

  if (!picture) return false;

  return (
    <Modal header={t('editPicture.editPicture')} onClose={onClose} isOpen small>
      <FlexColumn>
        {picture?.url && (
          <Center>
            <ImageCircle src={picture.url.large} large />
          </Center>
        )}
        <Input.Textarea
          label={t('labels.description')}
          onChange={value => setDescription(value)}
          value={description}
          name="description"
        />
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button onClick={editPicture} async fluid>
            {t('general.edit')}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

EditPicture.defaultProps = {
  modelItem: null,
  picture: null,
  refreshAction: () => { },
};

EditPicture.propTypes = {
  modelItem: PropTypes.oneOfType([
    Model.Horse,
    Model.Farm,
    Model.Place,
  ]),
  picture: PropTypes.shape({
    url: PropTypes.shape({
      large: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  refreshAction: PropTypes.func,
};

export default EditPicture;
