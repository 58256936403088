import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import t from 'resources/translations';
import {
  changeFieldState,
  fetchAbsences,
} from 'store/actions';
import { getErrorMessage, notify, isCorrectFormat } from 'old/utils';
import Form from '@old/components/common/Form';
import Field from '@old/components/field';
import FormButton from '@old/components/common/FormButton';
import { FORM_MANAGE_ABSENCE } from 'old/constants/formNames';
import store from 'store';
import config from '@old/config';

const ManageAbsence = ({ onClose, absence, horseId, ...actions }) => {
  const getInitValues = () => ({
    beginning: absence
      ? absence.beginning.format(config.dateTimeFormat)
      : moment().format(config.dateTimeFormat),
    ending: absence
      ? absence.ending.format(config.dateTimeFormat)
      : moment().add(1, 'hours').format(config.dateTimeFormat),
    reason: absence ? absence.reason : '',
  });

  const addAbsence = async (formState) => {
    await Model.Horses.addAbsence(
      horseId,
      moment(formState.beginning, config.dateTimeFormat).toDate(),
      moment(formState.ending, config.dateTimeFormat).toDate(),
      formState.reason,
    );
    notify(t('message.addingAbsenceSuccess'));
  };

  const updateAbsence = async (formState) => {
    await Model.Horses.updateAbsence(
      horseId,
      absence.id,
      moment(formState.beginning, config.dateTimeFormat).toDate(),
      moment(formState.ending, config.dateTimeFormat).toDate(),
      formState.reason,
    );
    notify(t('message.editingAbsenceSuccess'));
  };

  const setEndDateAfterChanges = (formState) => {
    let datetime = '';
    if (isCorrectFormat(formState.value, config.dateTimeFormat)) {
      datetime = moment(formState.value, config.dateTimeFormat)
        .add(60, 'minutes').format(config.dateTimeFormat);
      actions.changeFieldState({ formName: FORM_MANAGE_ABSENCE, fieldName: 'ending', fieldState: datetime });
    }
  };

  const onSubmit = async (formState) => {
    try {
      if (absence) {
        await updateAbsence(formState);
      } else {
        await addAbsence(formState);
      }
      const { fetchingData: { absencesFetchParams: { page, per_page } } } = store.getState();
      store.dispatch(fetchAbsences(parseInt(horseId, 10), { page, per_page }));
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const validationEndingDate = [{
    condition: (value, formState) => {
      const startDate = formState.beginning.value;
      const endDate = value;
      if (isCorrectFormat(startDate, config.dateTimeFormat)
        && isCorrectFormat(endDate, config.dateTimeFormat)) {
        const momentStartDate = moment(startDate, config.dateTimeFormat);
        return !moment(endDate, config.dateTimeFormat).isSameOrBefore(momentStartDate);
      }
      return true;
    },
    errorHint: t('error.dateStartAfterDateEnd'),
  }];

  const validationTextarea = [{
    condition: value => !(value.length > 250),
    errorHint: t('error.descriptionAbsenceMaxLenght'),
  }];

  const header = absence
    ? t('manageAbsence.editHorseAbsenceNotification')
    : t('manageAbsence.addHorseAbsenceNotification');

  const dependentFields = {
    beginning: ['ending'],
    ending: ['beginning'],
  };

  return (
    <Modal header={header} onClose={onClose} isOpen>
      <Form
        formName={FORM_MANAGE_ABSENCE}
        initValues={getInitValues()}
        dependentFields={dependentFields}
        refreshAlways
        noPadding
      >
        <Field.DateTime
          name="beginning"
          label={t('labels.start')}
          placeholder={t('placeholders.date')}
          afterChanges={setEndDateAfterChanges}
          required

        />
        <Field.DateTime
          name="ending"
          label={t('labels.end')}
          placeholder={t('placeholders.date')}
          validation={validationEndingDate}
          required
        />
        <Field.TextArea
          name="reason"
          label={t('labels.reasonAbsence')}
          placeholder={t('placeholders.reasonAbsenceHorse')}
          validation={validationTextarea}
        />
        <FormButton formName={FORM_MANAGE_ABSENCE} onClick={onSubmit} availableBeforeFormIsChanged fluid async>
          {t('general.add')}
        </FormButton>
      </Form>
    </Modal>
  );
};

ManageAbsence.defaultProps = {
  horseId: '',
  absence: null,
};

ManageAbsence.propTypes = {
  horseId: PropTypes.string,
  absence: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  changeFieldState: PropTypes.func.isRequired,
  fetchAbsences: PropTypes.func.isRequired,
};

export default connect(null, { changeFieldState, fetchAbsences })(ManageAbsence);
