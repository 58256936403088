import { useMemo } from 'react';
import { connect } from 'react-redux';

import PageTitle from '@old/components/guide/PageTitle';
import FloatingButton from '@old/components/custom/FloatingButton';
import Model from '@old/model';
import t from 'resources/translations';
import ViewPager from '@old/components/guide/ViewPager';
import { useNumberOfAwaitingsMembers, useTabs } from '@old/hooks';
import { useAddGuestButton, useAddGroupButton } from 'old/buttonHooks';
import { getTabsId } from 'old/utils';
import Allow from '@old/components/common/Allow';
import UsersProvider from '@old/components/custom/UsersProvider';
import GroupsProvider from '@old/components/custom/GroupsProvider';
import { useOldLoggedMember } from 'utils/storeUtils';

const UsersPage = ({ isMobile, activeTab, loading, location }) => {
  const loggedMember = useOldLoggedMember();
  const [numberOfAwaitingsMembers] = useNumberOfAwaitingsMembers(Model.Members.getNumbersOfAwaitings, 0, !loading);
  const tabs = useMemo(
    () => [
      {
        name: t('general.clients'),
        view: UsersProvider,
        props: {
          pagKey: 'clients',
          isSearchable: true,
          isMobile,
          fetchParams: {
            with_role: 'client',
            with_status: ['active'],
          },
        },
        permissionKey: 'memberships.index',
      },
      {
        name: t('general.instructors'),
        view: UsersProvider,
        props: {
          pagKey: 'instructors',
          isSearchable: true,
          isMobile,
          fetchParams: {
            with_role: 'instructor',
            with_status: ['active'],
          },
        },
        permissionKey: 'memberships.index', // ?
      },
      {
        name: t('general.groups'),
        view: GroupsProvider,
        props: {
          pagKey: 'groups',
        },
        permissionKey: 'riding_groups.index',
      },
      {
        name: t('general.mineGroups'),
        view: GroupsProvider,
        props: {
          fetchMine: true,
          isMobile,
          pagKey: 'minegroups',
        },
        permissionKey: 'riding_groups.mine',
      },
      {
        name: `${t('general.pending')} (${numberOfAwaitingsMembers})`,
        view: UsersProvider,
        props: {
          pagKey: 'pendings',
          isSearchable: true,
          isMobile,
          fetchParams: {
            with_role: 'client',
            with_status: ['pending'],
          },
        },
        permissionKey: 'memberships.index',
        active: loggedMember.isOwner() && numberOfAwaitingsMembers > 0,
      },
    ],
    [location, loggedMember, numberOfAwaitingsMembers] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const userTabs = useTabs(tabs);

  const addGuest = useAddGuestButton(loggedMember);
  const addGroup = useAddGroupButton(loggedMember);

  return (
    <>
      <PageTitle title={t('model.users')} loading={loading} />
      <ViewPager tabs={userTabs} />
      {activeTab === 0 && <FloatingButton buttons={[addGuest]} />}
      {activeTab === 2 && (
        <Allow permissionKey="riding_groups.create">
          <FloatingButton buttons={[addGroup]} />
        </Allow>
      )}
    </>
  );
};

const mapStateToProps = ({ app: { isMobile }, tabs, fetchingData: { loading } }, { match }) => ({
  activeTab: tabs[getTabsId(match.path)] || 0,
  isMobile,
  loading: loading.users || loading.groups,
});

export default connect(mapStateToProps)(UsersPage);
