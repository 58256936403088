export const CHANGE_FIELD_STATE = 'CHANGE_FIELD_STATE';
export const SET_INIT_VALUES = 'SET_INIT_VALUES';
export const VALIDATE_FIELD = 'VALIDATE_FIELD';
export const CLEAR_FORM = 'CLEAR_FORM';
export const REFRESH_FORM = 'REFRESH_FORM';

export const changeFieldState = ({ formName, fieldName, fieldState }) => ({
  type: CHANGE_FIELD_STATE,
  formName,
  fieldName,
  fieldState,
});

export const setInitValues = ({ formName, initValues, dependentFields, force = false }) => ({
  type: SET_INIT_VALUES,
  formName,
  initValues,
  dependentFields,
  force,
});

export const validateField = ({ formName, fieldName, isValidate }) => ({
  type: VALIDATE_FIELD,
  formName,
  fieldName,
  isValidate,
});

export const clearForm = formName => ({ type: CLEAR_FORM, formName });
