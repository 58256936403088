import Link from '@old/components/common/Link';
import Box from '@old/components/common/Box';
import { usePlaceEditButton, usePlaceDeleteButton } from 'old/buttonHooks';
import ImageCircle from '@old/components/guide/ImageCircle';
import RowAligner from '@old/components/common/RowAligner';
import Subtitle from '@old/components/common/Subtitle';
import { ContextTrigger } from '@old/components/common/ContextMenu';
import FlexRow from '@old/components/common/FlexRow';
import ColorBar from '@old/components/common/ColorBar';

const PlacesListItem = ({ place }) => {
  const placeEdit = usePlaceEditButton(place);
  const placeDelete = usePlaceDeleteButton(place);

  const placeButtons = [placeEdit, placeDelete];

  return (
    <ContextTrigger menuItems={placeButtons}>
      <FlexRow cols="none/1" stretched separated={false} noShrink>
        <ColorBar className="w-2 rounded-none" color={place.getColor()} vertical />
        <Box>
          <RowAligner>
            <Link to={`/places/${place.id}`}>
              <ImageCircle src={place.getImage('thumb')} />
            </Link>
            <div>
              <Link to={`/places/${place.id}`}>
                <Subtitle>{place.getName(40)}</Subtitle>
              </Link>
              <div className="leading-tight uppercase">{place.slug}</div>
            </div>
          </RowAligner>
        </Box>
      </FlexRow>
    </ContextTrigger>
  );
};

PlacesListItem.defaultProps = {
  activeItem: {
    id: 0,
    name: null,
  },
};

export default PlacesListItem;
