import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import config from '@old/config';
import FlexColumn from '../common/FlexColumn';
import t from '../../../resources/translations';
import ToggleBlock from '../common/ToggleBlock';
import Input from '.';
import Accordion from '../common/Accordion/Accordion';
import Icon from '../icon';

const AddAvatarUser = ({ onChange, value }) => {
  const [isOpenBlock, setIsOpenBlock] = useState(false);
  const [activeEventKey, setActiveEventKey] = useState(-1);
  return (
    <>
      <div className="text-input-content inline-flex mb-3">
        {t('usersForm.or')}
        <div
          onClick={() => setIsOpenBlock(prevIsOpen => !prevIsOpen)}
          className={cls(isOpenBlock ? 'text-teal-dark' : 'text-teal-light', 'pl-1 font-bold cursor-pointer')}
        >
          {t('usersForm.chooseFromDefault')}
        </div>
      </div>
      <ToggleBlock isOpen={isOpenBlock}>
        <FlexColumn separated="small">
          <Accordion activeEventKey={activeEventKey} onToggle={setActiveEventKey}>
            {config.userAvatars.map(({ name, avatars }, index) => {
              const iconColor = activeEventKey === index ? 'fill-teal-dark' : 'fill-teal-light';
              return (
                <div key={index}>
                  <Accordion.Toggle eventKey={index}>
                    <div className="inline-flex items-center">
                      {activeEventKey !== index && <Icon.ArrowRight className={cls(iconColor)} />}
                      {activeEventKey === index && <Icon.ArrowDown className={cls(iconColor)} />}
                      <div className={
                        cls(activeEventKey === index
                          ? 'text-teal-dark' : 'text-teal-light', 'ml-2 font-bold cursor-pointer')}
                      >
                        {name}
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index}>
                    <Input.AddAvatar
                      key={name}
                      name="avatar"
                      avatars={avatars}
                      value={value}
                      onChange={onChange}
                    />
                  </Accordion.Collapse>
                </div>
              );
            })}
          </Accordion>
        </FlexColumn>
      </ToggleBlock>
    </>
  );
};

AddAvatarUser.defaultProps = {
  value: '',
};

AddAvatarUser.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default AddAvatarUser;
