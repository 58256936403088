import React from 'react';
import PropTypes from 'prop-types';
import { variant } from 'styled-system';
import { Box, Label } from '@elements';
import styled from 'styled-components';
import theme from 'config/theme';

const SwitchInner = styled('span')(
  {
    display: 'block',
    width: '200%',
    transition: 'margin-right 200ms',
    '&::before, &::after': {
      display: 'block',
      float: 'left',
      width: '50%',
      height: 24,
      padding: 0,
      boxSizing: 'border-box',
      content: JSON.stringify(''),
    },
    '&::before': {
      backgroundColor: theme.colors.grey,
    },
    '&::after': {
      backgroundColor: theme.colors['green-light'],
    },
  },
  variant({
    variants: {
      on: {
        ml: '-100%',
      },
      off: {
        ml: 0,
      },
    },
  }),
);

const SwitchToggleButton = styled('span')(
  {
    transition: 'transform 200ms',
    backgroundColor: 'white',
    position: 'absolute',
    display: 'block',
    width: 18,
    height: 18,
    margin: 3,
    borderRadius: 9999,
    top: 0,
    bottom: 0,
    left: 0,
  },
  variant({
    variants: {
      on: {
        transform: 'translate(24px)',
      },
      off: {
        transform: 'translate(0px)',
      },
    },
  }),
);

const styleProps = {
  switchContainer: disabled => ({
    position: 'relative',
    width: 48,
    height: 24,
    display: 'inline-block',
    verticalAlign: 'middle',
    textAlign: 'left',
    userSelect: 'none',
    opacity: disabled ? 0.7 : 1,
  }),
  switchLabel: {
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'block',
    m: 0,
    borderRadius: 20,
    height: 24,
  },
};

const Switch = ({ id, name, checked, onChange, disabled, ...boxProps }) => {
  return (
    <Box {...styleProps.switchContainer(disabled)} {...boxProps}>
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
        style={{ display: 'none' }}
      />
      <Label htmlFor={id} {...styleProps.switchLabel}>
        <SwitchInner variant={checked ? 'on' : 'off'} />
        <SwitchToggleButton variant={checked ? 'on' : 'off'} />
      </Label>
    </Box>
  );
};

Switch.defaultProps = {
  disabled: false,
};

Switch.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
  ]),
};

export default Switch;
