import { ContextMenu, ContextMenuItem } from 'components/combinations';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { Box, Button, Flex, Icons, Link, Span, Stack, Ternary, Text, TextTruncate } from 'components/elements';
import { DATE_FORMAT } from 'config/constans';
import Ticket from 'models/Ticket';
import moment from 'moment';
import t from 'resources/translations';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TFlexProps, TIconCircleProps } from 'resources/types/elementsTypes';
import { TColorsProps } from 'resources/types/styledTypes';
import { TicketService } from 'services';
import { useModal } from 'old/hooks';
import { useAppSelector, useFarm } from 'utils';

const iconRectangleStyles = (iconColor: TColorsProps) =>
  ({
    bgColor: iconColor,
    color: 'white',
    size: 'sm',
    border: { borderRadius: 3 },
  } as TIconCircleProps);

type TTicketItem = {
  ticket: Ticket;
  iconColor?: TColorsProps;
  flexProps?: TFlexProps;
};

const TicketItemClient = ({ ticket, iconColor = 'teal', ...flexProps }: TTicketItem) => {
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const farm = useFarm();
  const queryClient = useQueryClient();
  const confirmDeleteModal = useModal('CONFIRM');
  const paymentModal = useModal('PAYMENT_ONLINE');
  const deleteTicket = async () => await TicketService.delete(ticket.id);
  const refreshTickets = () => queryClient.invalidateQueries('TICKETS');
  const deleteMutation = useMutation(deleteTicket, { onSuccess: refreshTickets });

  const onOpenDeleteModal = () => {
    confirmDeleteModal.onOpen({
      title: t('tickets.deleteTicket'),
      content: (
        <ModalDescription spacing="xl">
          <Stack spacing="sm">
            <Text>{t('tickets.deleteUnpaidTicket')}:</Text>
            <Text>
              <Span fontWeight="medium">{ticket.getName()}</Span>?
            </Text>
          </Stack>
          <Text fontWeight="medium">{t('tickets.rememberTicketDeleted')}</Text>
        </ModalDescription>
      ),
      action: {
        mutation: deleteMutation,
        label: t('buttons.yesIWantDeleteTicket'),
        cancelLabel: t('buttons.noGetMyOutOfHere'),
        loadingLabel: t('message.removed'),
      },
    });
  };

  const onOpenPaymenModal = () => {
    return paymentModal.onOpen({ ticket });
  };

  const isAddOnlinePayment = farm?.isAdvancedPaymentEnabled();

  return (
    <Flex borderColor="new-grey-light" borderWidth={1} borderRadius={8} p="md" {...flexProps}>
      <Flex flexDirection={['column', 'row']}>
        <Stack flex={2}>
          <Stack w="full" spacing="sm" alignItems="center" isHorizontal>
            {!isMobile && <Icons.TicketCircle {...iconRectangleStyles(iconColor)} />}
            <Link to={`/tickets/${ticket.id}`} w="full" fontSize="md" fontWeight="medium" allowed>
              <TextTruncate>{ticket.name}</TextTruncate>
            </Link>
          </Stack>
          <Box>
            <Text>
              <Span fontWeight="medium">{t('tickets.assignmentDate')}: </Span>
              {moment(ticket.createdAt).format(DATE_FORMAT)}
            </Text>
          </Box>
        </Stack>
        <Flex flex="none" mt={['md', 0]} minW={['auto', 300]}>
          <Stack spacing="sm">
            <Stack spacing="sm" w="full">
              <Text fontWeight="medium">{t('tickets.periodValidityOfTickets')}:</Text>
              <Text color="#202020">
                {moment(ticket.activeAt).format(DATE_FORMAT)} - {moment(ticket.expireAt).format(DATE_FORMAT)}
              </Text>
            </Stack>
          </Stack>
        </Flex>
        <Stack flex="none" mt={['md', 0]} spacing="xs" isHorizontal={isMobile} alignItems="center">
          <Text color="teal-dark">{t('tickets.restRides')}:</Text>
          <Text fontSize="md" fontWeight="bold" textAlign="right">
            {ticket.count}
          </Text>
        </Stack>
      </Flex>

      <Flex flex="none" alignSelf="center" justifyContent="center">
        <Ternary cond={!!isAddOnlinePayment}>
          <>
            {ticket.status === 'unpaid' && (
              <ContextMenu pl="md">
                <ContextMenuItem onClick={() => onOpenPaymenModal()}>
                  <Text>{t('buttons.pay')}</Text>
                </ContextMenuItem>
                <ContextMenuItem onClick={() => onOpenDeleteModal()}>
                  <Text>{t('general.delete')}</Text>
                </ContextMenuItem>
              </ContextMenu>
            )}
          </>
          <>
            {ticket.status === 'unpaid' && (
              <Button onClick={() => onOpenDeleteModal()} variant="link" pl="md">
                <Icons.TrashCircle bgColor="teal-light-15" />
              </Button>
            )}
          </>
        </Ternary>
      </Flex>
    </Flex>
  );
};

export default React.memo(TicketItemClient);
