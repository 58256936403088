import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { compact } from 'lodash';

import Link from '@old/components/common/Link';
import WorkedHoursChart from '@old/components/view/charts/WorkedHoursChart';
import PageTitle from '@old/components/guide/PageTitle';
import Calendar from '@old/components/view/calendar/Calendar';
import Model from '@old/model';
import EditableTextarea from '@old/components/common/editable/EditableTextarea';
import SelectDateRangeModal from '@old/components/modals/SelectDateRange';
import RatingStars from '@old/components/old/RatingStars';
import FloatingButton from '@old/components/custom/FloatingButton';
import InstructorStatisticsPieChart from '@old/components/view/charts/InstructorStatisticsPieChart';
import BasicStatistics from '@old/components/old/BasicStatistics';
import Box from '@old/components/common/Box';
import FlexRow from '@old/components/common/FlexRow';
import FlexColumn from '@old/components/common/FlexColumn';
import t from 'resources/translations';
import { formatDuration, notify, getErrorMessage } from 'old/utils';
import {
  useMemberStatus,
  usePieChartData,
  useUserEditShiftButton,
  useMemberManagementButton,
  useUserEditButton,
  useGuestEditButton,
  useAddEventButton,
  useAddProposalButton,
} from '@old/hooks';
import ImageCircle from '@old/components/guide/ImageCircle';
import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import Center from '@old/components/common/Center';
import OverflowItem from '@old/components/common/OverflowItem';
import Subtitle from '@old/components/common/Subtitle';
import { fetchUser } from 'store/actions';
import EditableSelect from '@old/components/common/editable/EditableSelect';
import config from '@old/config';
import Message from '@old/components/common/Message';
import { useOldLoggedMember } from 'utils/storeUtils';
import { MemberService } from 'services';
import { refetchProfile } from 'store/auth';
import { Icons, Stack, Text } from 'components/elements';

const InstructorDetails = ({ member, loading, isMobile, match, ...actions }) => {
  const loggedMember = useOldLoggedMember();
  const isMyProfile = loggedMember.id === member.id;
  const dispatch = useDispatch();

  const status = useMemberStatus(member, loggedMember);
  const editShift = useUserEditShiftButton(member);
  const memberManagement = useMemberManagementButton(member, status, true);
  const editGuest = useGuestEditButton(member);
  const userEdit = useUserEditButton(member, status.isCurrentUser);
  const addEvent = useAddEventButton({
    ...(member
      ? {
          initState: {
            initValues: {
              instructors: [{ value: member.id, label: member.getName() }],
            },
          },
        }
      : {}),
    label: t('buttons.addEventWithInstructor'),
  });

  const addProposal = useAddProposalButton({
    ...(member
      ? {
          initState: {
            initValues: {
              instructors: [{ value: member.id, label: member.getName() }],
              participations: loggedMember.isClient()
                ? [{ value: loggedMember.id, label: loggedMember.getFullName() }]
                : [],
            },
          },
        }
      : {}),
    label: t('buttons.addProposalWithInstructor'),
  });
  const memberButtons = [editShift, memberManagement, editGuest, addEvent, addProposal, userEdit];

  const [pieChartDateRange, pieChartSetDateRange] = useState({
    start: moment().subtract(30, 'days').startOf('day'),
    end: moment().endOf('day'),
  });
  const [pieChartData, total] = usePieChartData(
    match.params.id,
    'members',
    pieChartDateRange,
    !loggedMember.isClient()
  );
  const finishedDuration = (total && total.finished_duration) || null;
  const hoursWorked = finishedDuration ? formatDuration(moment.duration(finishedDuration, 'minutes')) : '0m';
  const isContactDetailsHidden = !loggedMember.isClient() && member.privateProfile;
  const showEditableAboutMe = (loggedMember.isOwner() || isMyProfile) && !member.isDeleted();
  const showEditableSkill =
    ((loggedMember.isInstructor() && !member.isOwner()) || loggedMember.isOwner()) && !member.isDeleted();

  const onSubmit = async (value, field) => {
    try {
      await MemberService.update(member.id, Model.Member.mapToSaveData({ [field]: value }));
      notify(t('message.succesSave'));
      if (loggedMember.id === member.id) {
        dispatch(refetchProfile());
      }
      actions.fetchUser(member.id);
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  return (
    <React.Fragment>
      {!isMobile && (
        <React.Fragment>
          <PageTitle title={member.getName()} back="/users" loading={loading} />
          {member.isDeleted() && (
            <Message deleted>
              <RowAligner>
                <Icon.WarningOutline className="fill-teal" />
                <span>{t('model.deleteNotify.member')}</span>
              </RowAligner>
            </Message>
          )}
          <FlexRow cols="10/6" stretched>
            <Box>
              <FlexColumn>
                <RowAligner>
                  <ImageCircle src={member.getAvatar()} large />
                  <Stack>
                    {isContactDetailsHidden && (
                      <Stack isHorizontal alignItems="center" spacing="sm" mb="xl">
                        <Icons.CrossedEyeCircle size="xs" bgColor="teal-dark" color="white" />
                        {isMyProfile ? (
                          <Text color="teal-dark">{t('instructorDetails.myContactDetailsHidden')}</Text>
                        ) : (
                          <Text color="teal-dark">{t('instructorDetails.instructorContactDetailsHidden')}</Text>
                        )}
                      </Stack>
                    )}
                    <Stack spacing="xs">
                      <Text color="teal-dark" fontWeight="medium">
                        {t('general.phone')}
                      </Text>
                      {!!member.phone ? (
                        <a className="text-md" href={`tel:${member.phone}`}>
                          {member.phone}
                        </a>
                      ) : (
                        <Text>{t('instructorDetails.noContactDetails')}</Text>
                      )}
                    </Stack>
                    <Stack spacing="xs">
                      <Text color="teal-dark" fontWeight="medium">
                        {t('general.email')}
                      </Text>
                      {!!member.email ? (
                        <a className="text-md break-all" href={`mailto:${member.email}`}>
                          {member.email}
                        </a>
                      ) : (
                        <Text>{t('instructorDetails.noContactDetails')}</Text>
                      )}
                    </Stack>
                    {!showEditableSkill && (
                      <Stack spacing="xs">
                        <Text color="teal-dark" fontWeight="medium">
                          {t('general.level')}
                        </Text>
                        <Text>{t(`skills.${member.skill}`)}</Text>
                      </Stack>
                    )}
                  </Stack>
                </RowAligner>
                {showEditableSkill && (
                  <EditableSelect
                    value={config.difficulty.find(skill => skill.value === member.skill) || {}}
                    options={config.difficulty.map(({ label, value }) => ({ label, value, key: value }))}
                    onSubmit={value => onSubmit(value, 'skill')}
                    label={t('general.level')}
                  />
                )}

                {showEditableAboutMe && (
                  <EditableTextarea
                    onSubmit={value => onSubmit(value, 'aboutMe')}
                    defaultValue={member.aboutMe}
                    placeholder={t('editableTextarea.addNoteAboutMe')}
                    label={t('instructorDetails.aboutMe')}
                  />
                )}
                {!showEditableAboutMe && member.aboutMe && (
                  <Stack spacing="xs">
                    <Text color="teal-dark" fontWeight="medium">
                      {t('instructorDetails.aboutMe')}
                    </Text>
                    <Text>{member.aboutMe}</Text>
                  </Stack>
                )}
              </FlexColumn>
            </Box>
            <FlexColumn>
              {(loggedMember.isOwner() || isMyProfile) && (
                <Box>
                  <FlexColumn>
                    <OverflowItem>
                      <Link className="text-link" to={`${match.url}/statistics`}>
                        <Icon.VisibilityOn />
                      </Link>
                    </OverflowItem>
                    <InstructorStatisticsPieChart data={pieChartData} />
                    <WorkedHoursChart value={hoursWorked} />
                    <Center>
                      <SelectDateRangeModal dateRange={pieChartDateRange} onSave={pieChartSetDateRange} />
                    </Center>
                  </FlexColumn>
                </Box>
              )}
              <Box centerX centerY fullHeight>
                <FlexColumn separated="small">
                  <Center>
                    <Subtitle>{t('instructorDetails.rating')}</Subtitle>
                  </Center>
                  <Center>
                    <Link to={`/members/${member.id}/rating`}>
                      <RatingStars rating={member.rating || 0} />
                    </Link>
                  </Center>
                </FlexColumn>
              </Box>
            </FlexColumn>
          </FlexRow>
        </React.Fragment>
      )}

      {isMobile && (
        <React.Fragment>
          <PageTitle title={member.getName()} back="/users" />
          {member.isDeleted() && (
            <Message deleted>
              <RowAligner>
                <Icon.WarningOutline className="fill-teal" />
                <span>{t('model.deleteNotify.member')}</span>
              </RowAligner>
            </Message>
          )}
          <Box>
            <FlexRow>
              <Center>
                <ImageCircle big src={member.getAvatar()} />
              </Center>
              <Center>
                <div className="text-headline">{member.getName()}</div>
              </Center>
              <Center>
                <Link to={`/members/${member.id}/rating`}>
                  <RatingStars rating={member.rating || 0} />
                </Link>
              </Center>
              {(loggedMember.isOwner() || isMyProfile) && (
                <Center>
                  <BasicStatistics
                    id={member.id}
                    type="members"
                    link={`/instructors/${member.id}/statistics`}
                    suffixKey="instructor"
                  />
                </Center>
              )}
            </FlexRow>
          </Box>
          <Box>
            <FlexColumn>
              {isContactDetailsHidden && (
                <Stack isHorizontal alignItems="center" spacing="sm">
                  <Icons.CrossedEyeCircle size="xs" bgColor="teal-dark" color="white" />
                  {isMyProfile ? (
                    <Text color="teal-dark">{t('instructorDetails.myContactDetailsHidden')}</Text>
                  ) : (
                    <Text color="teal-dark">{t('instructorDetails.instructorContactDetailsHidden')}</Text>
                  )}
                </Stack>
              )}
              <div>
                <div className="text-highlight">{t('general.phone')}</div>
                {!!member.phone ? (
                  <a className="text-md" href={`tel:${member.phone}`}>
                    {member.phone}
                  </a>
                ) : (
                  <Text fontSize="sm">{t('instructorDetails.noContactDetails')}</Text>
                )}
              </div>
              <div>
                <div className="text-highlight">{t('general.email')}</div>
                {!!member.email ? (
                  <a className="text" href={`mailto:${member.email}`}>
                    {member.email}
                  </a>
                ) : (
                  <Text fontSize="sm">{t('instructorDetails.noContactDetails')}</Text>
                )}
              </div>
              {showEditableSkill && (
                <EditableSelect
                  value={config.difficulty.find(skill => skill.value === member.skill) || {}}
                  options={config.difficulty.map(({ label, value }) => ({ label, value, key: value }))}
                  onSubmit={value => onSubmit(value, 'skill')}
                  label={t('general.level')}
                />
              )}
              {!showEditableSkill && (
                <div>
                  <div className="text-highlight">{t('general.level')}</div>
                  <span className="text">{t(`skills.${member.skill}`)}</span>
                </div>
              )}
              {showEditableAboutMe && (
                <EditableTextarea
                  onSubmit={value => onSubmit(value, 'aboutMe')}
                  defaultValue={member.aboutMe}
                  placeholder={t('editableTextarea.addNoteAboutMe')}
                  label={t('instructorDetails.aboutMe')}
                />
              )}
              {!showEditableAboutMe && member.aboutMe && (
                <>
                  <div className="text-highlight">{t('instructorDetails.aboutMe')}</div>
                  <div className="break-words">{member.aboutMe}</div>
                </>
              )}
            </FlexColumn>
          </Box>
        </React.Fragment>
      )}
      <div className="mt-4">
        <Calendar
          fetchParams={{ 'instructed_by[]': member.id }}
          defaultMode="week"
          addEventButton={addEvent}
          addProposalButton={addProposal}
          onlyDefaultMode
          extended
          displayedRange="week"
        />
      </div>
      <FloatingButton buttons={compact(memberButtons)} />
    </React.Fragment>
  );
};

const mapStateToProps = ({ fetchingData: { loading }, app: { isMobile } }) => ({
  isMobile,
  loading: loading.users,
});

export default withRouter(connect(mapStateToProps, { fetchUser })(InstructorDetails));
