import t from 'resources/translations';
import { getErrorMessage, notify } from 'old/utils';
import { Box, Button, Flex, Icons, Span, Stack, Text } from 'components/elements';
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  AvatarWithLabel,
  ModalButtons,
} from 'components/combinations';

import { TStoreStateModals, TWrappedModal } from '.';
import { useQueryClient } from 'react-query';
import { TicketService } from 'services';
import Ticket from 'models/Ticket';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import NumberInput from 'components/combinations/forms/NumberInput';
import TextareaInput from 'components/combinations/forms/TextareaInput';
import FormLabel from 'components/combinations/forms/FormLabel';
import FormField from 'components/combinations/forms/FormField';
import { useFormik } from 'formik';
import { yup } from 'utils';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import { useShallowSelector } from 'old/hooks';
import { useAppStore } from 'utils/storeUtils';

type TTicketRidesEditModalProps = {
  ticket: Ticket;
  callback?: Function;
};

const TicketRidesEditModal = ({ onSubmitAndClose, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const queryClient = useQueryClient();
  const { isMobile } = useAppStore();
  const { ticket, callback } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['TICKET_RIDES_EDIT']?.props || {}
  ) as TTicketRidesEditModalProps;

  const validationSchema = yup.object().shape({
    reason: yup
      .string()
      .trim()
      .required(t('error.fieldIsRequired'))
      .min(1)
      .max(240, t('error.infoMaxLength', { count: 240 })),
  });

  const onSubmit = async () => {
    try {
      await TicketService.update(ticket.id, {
        count: formik.values.count,
        count_limit:
          formik.values.count > ticket.count
            ? ticket.countLimit + (formik.values.count + ticket.count)
            : ticket.countLimit,
        audit_comment: formik.values.reason,
      });
      queryClient.invalidateQueries('TICKETS');
      callback && callback();
      notify(t('message.succesSave'));
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      count: ticket.count || 0,
      reason: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{t('tickets.changeNumberOfRides')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting}>
        <ModalDescription py="md">
          <Flex flexDirection={['column', 'row']}>
            <Stack flex={1}>
              <Text fontWeight="medium">{ticket.getName()}</Text>
              <Flex>
                <AvatarWithLabel
                  title={ticket.membership.getFullName()}
                  alt={ticket.membership.getFullName()}
                  src={ticket.membership.getAvatar('thumb')}
                  squareSize={26}
                >
                  <Text>{ticket.membership.getFullName()}</Text>
                </AvatarWithLabel>
              </Flex>
            </Stack>
            <Stack
              mt={['base', 0]}
              flex={[1, 'none']}
              spacing="xs"
              justifyContent={['space-between', 'flex-start']}
              alignItems={['center', 'normal']}
              isHorizontal={!!isMobile}
            >
              <Text fontSize={['base', 'sm']}>{t('tickets.currentlyInTicket')}:</Text>
              <Text fontWeight="bold" color="teal-dark" fontSize="md" textAlign="right">
                {ticket.count} {t('tickets.rides', { count: ticket.count })}
              </Text>
            </Stack>
          </Flex>
        </ModalDescription>
        <Stack>
          <FormField hideErrorsBlock>
            <FormLabel htmlFor="count" required>
              {t('tickets.changeNumberOfRides')}
            </FormLabel>
            <Stack spacing="sm" mt="sm" mx={['auto', 0]} isHorizontal>
              <Button
                display="flex"
                squareSize={47}
                onClick={() => {
                  formik.setFieldTouched('count');
                  if (formik.values.count > 0) {
                    formik.setFieldValue('count', formik.values.count - 1);
                  }
                }}
                disabled={formik.values.count <= 0}
                alignItems="center"
                justifyContent="center"
              >
                <Icons.Minus squareSize={26} />
              </Button>
              <NumberInput
                {...formik.getFieldProps('count')}
                onChange={value => {
                  formik.setFieldTouched('count');
                  formik.setFieldValue('count', value);
                }}
                min={0}
                maxW={75}
                max={9999}
                // @ts-ignore
                onClick={e => e.target.select()}
                strict
                precision={0}
                inputWrapperProps={{ borderRadius: 0 }}
              />
              <Button
                display="flex"
                squareSize={47}
                onClick={() => {
                  formik.setFieldTouched('count');
                  formik.setFieldValue('count', formik.values.count + 1);
                }}
                disabled={formik.values.count >= 9999}
                alignItems="center"
                justifyContent="center"
              >
                <Icons.Plus squareSize={26} />
              </Button>
            </Stack>
            <Box mt="sm">
              <Box display="inline-block" pt="sm" pb="xs" borderBottomWidth={1} borderColor="grey-light">
                <Text>
                  {t('tickets.numberOfRidesAfterChanges')}: <Span fontWeight="bold">{formik.values.count}</Span>
                </Text>
              </Box>
            </Box>
          </FormField>
          <FormField errors={formik.touched.reason ? formik.errors.reason : ''}>
            <Flex justifyContent="space-between">
              <FormLabel htmlFor="reason" required>
                {t('labels.giveReason')}
              </FormLabel>
              <Text textAlign="right" {...(formik.values.reason.length > 240 ? { color: 'orange' } : {})}>
                {formik.values.reason.length}/{240}
              </Text>
            </Flex>
            <TextareaInput
              {...formik.getFieldProps('reason')}
              onChange={e => {
                formik.setFieldValue('reason', e.target.value);
                if (formik.values.reason.length > 2) {
                  formik.setFieldTouched('reason');
                }
              }}
              placeholder={t('placeholders.reason')}
            />
          </FormField>
        </Stack>
      </ModalContent>
      <ModalButtons>
        <Button
          onClick={() => onSubmitAndClose(formik.submitForm)}
          disabled={!(formik.isValid && formik.dirty) || ticket.count === formik.values.count}
        >
          {isSubmitting ? <SpinnerDots label={t('message.saving')} inline /> : t('general.saveChanges')}
        </Button>
        <Button onClick={onClose} variant="outline">
          {t('buttons.dontSaveChanges')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default TicketRidesEditModal;
