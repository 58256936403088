/* eslint-disable max-len */
import React from 'react';

const Mail = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M10.6,9.6C10.3,9.9,10,10,9.8,10.2c-0.2,0.2-0.5,0.3-0.8,0.4l0,0c-0.3,0.1-0.6,0.2-1,0.2h0h0c-0.4,0-0.7-0.1-1-0.2l0,0
        c-0.3-0.1-0.6-0.3-0.9-0.4l0,0C6,10,5.7,9.9,5.3,9.6L5,9.4l-4.5,4.5c0.2,0.2,0.6,0.4,0.9,0.4h13.1c0,0,0,0,0,0
        c0.4,0,0.7-0.1,0.9-0.4l0,0L11,9.4L10.6,9.6z M0.9,6.5C0.6,6.3,0.3,6.1,0,5.8l0,0v6.9l4-4C3.2,8.1,2.2,7.4,0.9,6.5z M15.1,6.5
        c-1.2,0.8-2.2,1.5-3.1,2.1l4,4V5.8C15.7,6,15.4,6.3,15.1,6.5L15.1,6.5L15.1,6.5z M1.5,5.6l1.8,1.3l1.4,1l0.9,0.6l0.1,0.1l0.2,0.2
        C6.3,8.9,6.4,9,6.6,9.1C6.7,9.2,6.8,9.3,7,9.4c0.2,0.1,0.3,0.2,0.5,0.2l0,0c0.1,0,0.3,0.1,0.4,0.1h0h0c0.2,0,0.3,0,0.5-0.1l0,0
        C8.7,9.5,8.8,9.5,9,9.4l0,0c0.2-0.1,0.3-0.2,0.5-0.3C9.6,9,9.7,8.9,9.9,8.7l0.2-0.2l0.1-0.1l0.9-0.6l3.3-2.3
        c0.4-0.3,0.8-0.7,1.1-1.1l0,0C15.8,4.1,16,3.7,16,3.1v0l0,0c0-0.8-0.6-1.4-1.4-1.4c0,0,0,0,0,0h0H1.4c0,0,0,0-0.1,0
        c-0.4,0-0.7,0.2-1,0.5l0,0C0.2,2.5,0,2.9,0,3.3c0,0,0,0.1,0,0.1v0c0,0.5,0.2,0.9,0.5,1.2l0,0C0.8,5,1.1,5.3,1.5,5.6L1.5,5.6L1.5,5.6z"
      />
    </svg>
  );
};

export default Mail;
/* eslint-enable max-len */
