import t from 'resources/translations';
import Message from '@old/components/common/Message';
import GroupListItem from '@old/components/view/listItem/Group';
import FlexColumn from '@old/components/common/FlexColumn';

const GroupList = ({ data }) => {
  return (
    <FlexColumn>
      {!data.length && <Message info>{t('groupsGrid.noGroups')}</Message>}
      {data.map(group => (
        <GroupListItem key={group.id} group={group} />
      ))}
    </FlexColumn>
  );
};

GroupList.defaultProps = {
  data: [],
};

export default GroupList;
