import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import Field from '@old/components/field';
import FormButton from '@old/components/common/FormButton';
import {
  changeFieldState,
  fetchShifts,
} from 'store/actions';
import { FORM_MANAGE_SHIFTS } from 'old/constants/formNames';
import { isCorrectFormat, notify } from 'old/utils';
import config from '@old/config';

const ManageShift = ({ onClose, shift, memberId, shiftsFetchParams, ...actions }) => {
  const getInitValues = () => ({
    beginning: shift
      ? shift.beginning.format(config.dateTimeFormat) : moment().format(config.dateTimeFormat),
    ending: shift
      ? shift.ending.format(config.dateTimeFormat)
      : moment().add(1, 'hours').format(config.dateTimeFormat),
  });

  const updateShift = async (formState) => {
    await Model.Members.updateShift(
      memberId,
      shift.id,
      moment(formState.beginning, config.dateTimeFormat).toDate(),
      moment(formState.ending, config.dateTimeFormat).toDate(),
    );
    notify(t('message.editingShiftSuccess'));
  };

  const addShift = async (formState) => {
    await Model.Members.addShift(
      memberId,
      moment(formState.beginning, config.dateTimeFormat).toDate(),
      moment(formState.ending, config.dateTimeFormat).toDate(),
    );
    notify(t('message.addingShiftSuccess'));
  };

  const setEndDateAfterChanges = (formState) => {
    let datetime = '';
    if (isCorrectFormat(formState.value, config.dateTimeFormat)) {
      datetime = moment(formState.value, config.dateTimeFormat)
        .add(60, 'minutes').format(config.dateTimeFormat);
      actions.changeFieldState({ formName: FORM_MANAGE_SHIFTS, fieldName: 'ending', fieldState: datetime });
    }
  };

  const onSubmit = async (formState) => {
    try {
      if (shift) {
        await updateShift(formState);
      } else {
        await addShift(formState);
      }
      actions.fetchShifts(memberId, shiftsFetchParams);
      onClose();
    } catch (e) {
      notify(t('error.addingShiftError'), { type: 'error' });
    }
  };

  const validationEndingDate = [{
    condition: (value, formState) => {
      const startDate = formState.beginning.value;
      const endDate = value;
      if (isCorrectFormat(startDate, config.dateTimeFormat)
        && isCorrectFormat(endDate, config.dateTimeFormat)) {
        const momentStartDate = moment(startDate, config.dateTimeFormat);
        return !moment(endDate, config.dateTimeFormat).isSameOrBefore(momentStartDate);
      }
      return true;
    },
    errorHint: t('error.dateStartAfterDateEnd'),
  }];

  const header = shift ? t('manageShift.editShiftTitle') : t('manageShift.addShiftTitle');

  const dependentFields = { beginning: ['ending'] };

  return (
    <Modal header={header} onClose={onClose} isOpen>
      <Form
        formName={FORM_MANAGE_SHIFTS}
        initValues={getInitValues()}
        dependentFields={dependentFields}
        refreshAlways
        noPadding
      >
        <Field.DateTime
          name="beginning"
          label={t('labels.start')}
          placeholder={t('placeholders.date')}
          afterChanges={setEndDateAfterChanges}
          required
        />
        <Field.DateTime
          name="ending"
          label={t('labels.end')}
          placeholder={t('placeholders.date')}
          validation={validationEndingDate}
          required
        />
        <FormButton formName={FORM_MANAGE_SHIFTS} onClick={onSubmit} availableBeforeFormIsChanged fluid async>
          {t('general.add')}
        </FormButton>
      </Form>
    </Modal>
  );
};

ManageShift.defaultProps = {
  memberId: null,
  shift: null,
};

ManageShift.propTypes = {
  memberId: PropTypes.number,
  shift: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  shiftsFetchParams: PropTypes.shape({
    per_page: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = ({ fetchingData: { shiftsFetchParams } }) => ({ shiftsFetchParams });

export default connect(mapStateToProps, { changeFieldState, fetchShifts })(ManageShift);
