import axios from 'axios';

import store from 'store';
import { setMaintenance } from 'store/app';
import { version } from '../../../package.json';

const getClient = () => {
  const client = new HttpClient();

  return client;
};

class HttpClient {
  constructor() {
    this.axios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
    this.updateHeader('version', version);
  }

  async callAxiosMethod(params, method) {
    try {
      const response = await this.axios[method](...params);
      return response;
    } catch (e) {
      if (e.response && e.response.status === 503 && e.response.data.error === 'Service Unavailable') {
        return store.dispatch(setMaintenance());
      } else {
        throw e;
      }
    }
  }

  get(...params) {
    return this.callAxiosMethod(params, 'get');
  }

  post(...params) {
    return this.callAxiosMethod(params, 'post');
  }

  patch(...params) {
    return this.callAxiosMethod(params, 'patch');
  }

  delete(...params) {
    return this.callAxiosMethod(params, 'delete');
  }

  put(...params) {
    return this.callAxiosMethod(params, 'put');
  }

  getToken() {
    return this.axios.defaults.headers.common.Authorization;
  }

  updateHeader(key, value) {
    this.axios.defaults.headers.common[key] = value;
  }
}

export default getClient;
