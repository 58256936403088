/**
 * @module TextValue
 */
import React from 'react';
import PropTypes from 'prop-types';
/**
 * Component that displays text in "text: value" format
 * @param  {String} props.text label
 * @param  {String|Node|String[]} props.value value
 * @param  {String} [props.className]
 */
const TextValue = ({ text, value, className }) => (
  <div className={className}>
    <span className="text-highlight">{text}:</span> {value}
  </div>
);

TextValue.defaultProps = {
  className: null,
};

TextValue.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  className: PropTypes.string,
};

export default TextValue;
