/* eslint-disable max-len */
import React from 'react';

const Camera = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M4.8,9c0,1.8,1.5,3.2,3.2,3.2s3.2-1.5,3.2-3.2S9.8,5.7,8,5.7S4.8,7.2,4.8,9z M15,3.5h-3.5c-0.3-1-0.5-2-1.5-2H6
        c-1,0-1.2,1-1.5,2H1c-0.5,0-1,0.5-1,1v9c0,0.5,0.5,1,1,1h14c0.5,0,1-0.5,1-1v-9C16,4,15.5,3.5,15,3.5L15,3.5z M8,13.4
        c-2.5,0-4.4-2-4.4-4.4s2-4.4,4.4-4.4s4.4,2,4.4,4.4S10.5,13.4,8,13.4z M15,6.5h-2v-1h2C15,5.5,15,6.5,15,6.5z"
      />
    </svg>
  );
};

export default Camera;
/* eslint-enable max-len */
