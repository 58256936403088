import _ from 'lodash';
import queryString from 'query-string';

import { MouseEvent, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useActions, useShallowSelector } from 'old/hooks';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { IProfileRes, IUserRes } from 'resources/types/membersTypes';
import { setFilters, resetFilters, TFilterType } from 'store/actions';
import { IFiltersStore } from 'store/reducers/filtersReducer';
import localStorageService from 'services/storage/localStorageService';
import store, { RootState } from 'store';

interface ValidRefTarget {
  contains(target: EventTarget | null): any;
}

/**
 * useOutsideClick is a custom hook that handles click events outside a specific DOM element, like a div
 * @param {ref} React.RefObject<ValidRefTarget>
 * @param {Function} callback
 */
export const useOutsideClick = (ref: React.RefObject<ValidRefTarget>, callback: Function) => {
  const savedHandler = useRef<Function>(() => {});

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    savedHandler.current = handleClick;
  }, [callback, savedHandler, ref]);

  useEffect(() => {
    const options = { capture: true };
    const eventListener = (e: Event) => savedHandler.current(e);

    document.addEventListener('mousedown', eventListener, options);
    document.addEventListener('touchstart', eventListener, options);

    return () => {
      document.removeEventListener('mousedown', eventListener, options);
      document.removeEventListener('touchstart', eventListener, options);
    };
  }, []);
};

export default useOutsideClick;

/**
 * This is a React hook that detects all or some keys from keyboard.
 * @param {Function} callback
 * @param {string} keyCode
 * @return {void}
 */
export const useKey = (callback: Function, keyCode: string) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === keyCode) {
      e.preventDefault();
      callback();
    }
  };
  return handleKeyDown;
};
/**
 * useDisclosure is a custom hook used to help handle common open, close, or toggle.
 * @param {boolean} isOpenDefault
 * @return {Object} {isOpen, onOpen, onClose, onToggle}
 */
export const useDisclosure = (isOpenDefault: boolean = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault);

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen(state => !state), []);

  return { isOpen, onOpen, onClose, onToggle };
};

/**
 * useDisclosureModal is a custom hook used to help handle common open, close, or onHideAndCloseModal.
 * @param {boolean} isOpenDefault
 * @return {Object} {sOpen, isHidden, onOpen, onClose: onHideAndCloseModal}
 */
export const useModalDisclosure = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const onClose = useCallback(() => setIsOpen(false), []);

  const onHideAndCloseModal = useCallback(() => {
    setIsHidden(true);
    setTimeout(onClose, 350);
  }, [onClose]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
    setIsHidden(false);
  }, []);

  return { isOpen, isHidden, onOpen, onClose: onHideAndCloseModal };
};
/**
 * @param {string} defaultQuery
 * @param {number} time
 * @return {Object}
 */
export const useSearchQuery = (defaultQuery: string = '', time: number = 750) => {
  const [query, setQuery] = useState<string>(defaultQuery);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const searchQuery = useDebouncedValue(query, time);
  useEffect(() => {
    if (searchQuery && !isTouched) {
      setIsTouched(true);
    }
  }, [searchQuery, isTouched]);
  return { query, isTouched, searchQuery, setQuery };
};

/**
 * This hook allows you to debounce any fast changing value
 * @param {string} input
 * @param {number} time
 * @return {string} debouncedValue
 */
export const useDebouncedValue = (input: string, time: number = 500) => {
  const [debouncedValue, setDebouncedValue] = useState<string>(input);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(input);
    }, time);

    return () => {
      clearTimeout(timeout);
    };
  }, [input, time]);

  return debouncedValue;
};

/**
 * A simple hook to receive current window width using
 * @param {number} delay
 * @return {number} width;
 */
export const useWindowWidth = (delay: number = 700) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const debouncedHandleResize = _.debounce(handleResize, delay);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [delay]);

  return width;
};

/**
 * @param {Array[string]} paginationsKeys
 * @param {string} nameOfTabs
 * @return {Object} page, activeTab, setPage, setTab
 */
export const useTabPagination = (paginationsKeys: string[], nameOfTabs: string = '_tab') => {
  const location = useLocation();
  const history = useHistory();
  const getInitPaginations = () => {
    let tmpPaginations: number[] = [];
    paginationsKeys.forEach(paginationKey => {
      // @ts-ignore
      tmpPaginations[paginationKey] = 1;
    });

    return tmpPaginations;
  };

  const [paginations, setPaginations] = useState(getInitPaginations());
  const locationQuery = queryString.parse(location.search);
  let page = 1;
  if (typeof locationQuery?.page === 'string') {
    page = parseInt(locationQuery?.page, 10);
  }

  const activeTab = (locationQuery[nameOfTabs] || paginationsKeys[0]) as string;

  useEffect(() => {
    onChangePaginations(activeTab, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onChangePaginations = (tab: string, newPage: number) => {
    setPaginations(prevPaginations => ({ ...prevPaginations, [tab]: newPage }));
  };

  const setPage = (newPage: number) => {
    onChangePaginations(activeTab, newPage);

    if (newPage < 2) {
      return history.push({ pathname: location.pathname });
    }
    // @ts-ignore
    locationQuery.page = newPage;
    return history.push({ pathname: location.pathname, search: queryString.stringify(locationQuery) });
  };

  const setTab = (newTabKey: string) => {
    locationQuery[nameOfTabs] = newTabKey;
    // @ts-ignore
    locationQuery.page = paginations[newTabKey];

    return history.push({ pathname: location.pathname, search: queryString.stringify(locationQuery) });
  };

  return { page, activeTab, setPage, setTab };
};

export const usePagination = () => {
  const location = useLocation();
  const history = useHistory();
  const locationQuery = queryString.parse(location.search);
  let page = 1;
  if (typeof locationQuery?.page === 'string') {
    page = parseInt(locationQuery?.page, 10) || 1;
  }
  const setPage = useCallback(
    newPage => {
      if (newPage < 2) {
        return history.push({ pathname: location.pathname });
      }
      locationQuery.page = newPage;
      return history.push({ pathname: location.pathname, search: queryString.stringify(locationQuery) });
    },
    [location.pathname, history, locationQuery]
  );

  return { page, setPage };
};

/**
 * changes apperance of the page to new one
 */
export const useNewLayoutColor = () => {
  useLayoutEffect(() => {
    const pageContainerEl = document.querySelector<HTMLElement>('.page-container');
    if (pageContainerEl) {
      pageContainerEl.style.backgroundColor = '#f5f5f5';
    }
    const contentEl = document.querySelector<HTMLElement>('.content');
    if (contentEl) {
      contentEl.style.backgroundColor = '#f5f5f5';
    }
    return () => {
      if (contentEl && pageContainerEl) {
        pageContainerEl.style.backgroundColor = '';
        contentEl.style.backgroundColor = '';
      }
    };
  }, []);
};

/**
 * @param {TFilterType} type type of filters
 * @return filters
 */
export const useFilters = <TFilters>(type: TFilterType) => {
  const allFilters = useShallowSelector(({ filters }: { filters: IFiltersStore }) => filters) as IFiltersStore;
  const actions = useActions({ setFilters, resetFilters }, []);
  const filters = allFilters[type] as TFilters;

  const onChangeFilters = (newFilters: TFilters) => {
    actions.setFilters(type, newFilters);
  };

  const onClearFilters = () => {
    actions.resetFilters(type);
  };
  return { filters, onChangeFilters, onClearFilters };
};

/**
 * @param {function} fetchNextPage
 * @param {boolean} isFetching
 * @param {boolean | undefined} hasNextPage
 */
export const useInfinityScroll = (fetchNextPage: () => void, isFetching: boolean, hasNextPage: boolean | undefined) => {
  const refMenu = useRef<HTMLDivElement>(null);
  const refFetching = useRef<{ isFetching: boolean; hasNextPage: boolean | undefined }>({
    isFetching: true,
    hasNextPage: true,
  });

  refFetching.current = { isFetching, hasNextPage };
  useEffect(() => {
    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number) => {
      var bottomBorder = scrollHeight - clientHeight - 250;
      return bottomBorder < scrollTop;
    };

    const loadMoreOptions = _.throttle(async ({ target }) => {
      const { hasNextPage, isFetching } = refFetching.current;
      if (hasNextPage && !isFetching) {
        const shouldLoad = shouldLoadMore(target.scrollHeight, target.clientHeight, target.scrollTop);
        if (shouldLoad) {
          fetchNextPage();
        }
      }
    }, 25);

    if (refMenu.current) {
      refMenu.current.addEventListener('scroll', loadMoreOptions);
    }
    return () => {
      if (refMenu.current) {
        loadMoreOptions.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        refMenu.current.removeEventListener('scroll', loadMoreOptions);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refMenu.current]);

  return refMenu;
};

/**
 * Returns max height-given offset
 * @param {number} offset
 * @return {string}
 */
export const getMaxHeight = (offset: number): string => {
  return `calc(var(--vh, 1vh) * 100 - ${offset}px)`;
};

/**
 * @param {boolean} cond
 * @param {boolean} cond2
 * @param {any[]} results
 * @return {any}
 */
export const doubleTernary = (cond: boolean, cond2: boolean, results: any[]): any => {
  return cond ? results[0] : cond2 ? results[1] : results[2];
};

export const mapProfileResToUserRes = (profile: IProfileRes): IUserRes => ({
  id: profile.id,
  firstname: profile.firstname,
  lastname: profile.lastname,
  created_at: profile.created_at,
  updated_at: profile.updated_at,
  email: profile.email,
  phone: profile.phone,
  parent_id: profile.parent_id,
  deleted: false,
  birthday: profile.birthday,
  avatar: profile.avatar,
  phone_verified: profile.phone_verified,
  private_data_hidden: profile.private_data_hidden,
});

export const getHostLocation = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

/**
 * sending and receiving file from API, starts the download
 * @param {string} url ex. /bills/xlsx_report
 * @param {params} TParams
 * @return {boolean}
 */
export const downloadFileFromApi = <TParams>(url: string, params: TParams) => {
  return new Promise<Boolean>((res, rej) => {
    const { auth } = store.getState() as RootState;
    const APIUrl = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/farms/${auth?.farm?.id}`;
    const xmlHttp = new XMLHttpRequest();
    const token = localStorageService._getToken() as string;
    xmlHttp.onerror = e => {
      rej(e);
    };
    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        const blobUrl = window.URL.createObjectURL(xmlHttp.response);
        const e = document.createElement('a');
        e.href = blobUrl;
        const filenameFromHeader = xmlHttp.getResponseHeader('Content-Disposition');
        (filenameFromHeader || '').split("'").pop();
        e.download = filenameFromHeader || 'billing-report.xlsx';
        document.body.appendChild(e);
        e.click();
        document.body.removeChild(e);
        res(true);
      }
    };
    var param = JSON.stringify(params);
    xmlHttp.responseType = 'blob';
    xmlHttp.open('POST', `${APIUrl}${url}`, true);
    xmlHttp.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    xmlHttp.setRequestHeader('Authorization', token);
    xmlHttp.send(param);
  });
};
