import { ParticipationTogglePresenceAction } from 'actions/participant';
import { AvatarWithLabel, LinkMember, Switch } from 'components/combinations';
import SkillIndicator from 'components/combinations/participations/SkillIndicator';
import { Box, Flex, Stack, Ternary, Text } from 'components/elements';
import Event from 'models/Event';
import Participation from 'models/Participation';
import t from 'resources/translations';
import { parseToCurrencyString } from 'old/utils';
import { usePermissions } from 'Permissions';
import { useQueryClient } from 'react-query';
import ParticipantActions from './ParticipantActions';

const ParticipantItem = ({ event, participation }: { event: Event; participation: Participation }) => {
  const { member, bill } = participation;
  const queryClient = useQueryClient();
  const checkSetPresence = usePermissions('PARTICIPATION_SET_PRESENCE');

  return (
    <Box bgColor="grey-light" borderRadius={4} borderColor="grey" borderWidth={1}>
      <Flex>
        {participation.status !== 'joined' && <SkillIndicator member={participation.member} />}
        <Flex p={['sm', 'base']} alignItems="center">
          <AvatarWithLabel
            spacing={['sm', 'base']}
            src={member.getAvatar('thumb')}
            title={member.getFullName()}
            alt={member.getFullName()}
          >
            <LinkMember member={member} fontWeight="medium">
              {member.getFullName()}
            </LinkMember>
          </AvatarWithLabel>
          {participation.status === 'joined' && (
            <>
              <Stack mx={['sm', 'md']} alignItems="center" justifyContent="flex-end" spacing="xs">
                {checkSetPresence(event, participation) && (
                  <ParticipationTogglePresenceAction
                    eventId={event.id}
                    participation={participation}
                    onSuccess={() => queryClient.invalidateQueries(['event'], { refetchActive: true })}
                  >
                    {action => (
                      <Switch
                        id={`${member.id}-${event.id}`}
                        name={`participation-${member.id}`}
                        checked={participation.attendance === 'attending'}
                        onChange={() => action.mutateAsync()}
                        disabled={action.isLoading}
                      />
                    )}
                  </ParticipationTogglePresenceAction>
                )}
                <Box>
                  <Ternary cond={participation.attendance === 'absent'}>
                    <Text fontSize="sm">{t('eventParticipationsManage.absent')}</Text>
                    <Text fontSize="sm" color="green-light">
                      {t('eventParticipationsManage.attending')}
                    </Text>
                  </Ternary>
                </Box>
              </Stack>
              <Text fontWeight="medium" fontSize="md" whiteSpace="nowrap">
                {parseToCurrencyString(bill?.getPaidAmount())}
              </Text>
            </>
          )}
        </Flex>
      </Flex>
      <ParticipantActions event={event} participation={participation} />
    </Box>
  );
};

export default ParticipantItem;
