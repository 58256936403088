/* eslint-disable max-len */
import React from 'react';

const HistoryOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,0c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S3.6,0,8,0z M8.2,3.7C6,3.7,4.2,5.5,4.2,7.8H2.8l1.8,1.8l0,0.1l1.8-1.8H5.1
        c0-1.5,1.1-2.8,2.5-3.1c1.5-0.3,3,0.5,3.5,1.9c0.6,1.4,0.1,3-1.2,3.8C8.7,11.2,7,11.1,6,10l-0.6,0.6c0.8,0.8,1.8,1.2,2.9,1.2
        c2.3,0,4.1-1.8,4.1-4.1C12.3,5.5,10.5,3.7,8.2,3.7L8.2,3.7z M8.5,6H7.8v2.3l1.9,1.2L10,8.8L8.5,7.9C8.5,7.9,8.5,6,8.5,6z"
      />
    </svg>
  );
};

export default HistoryOutline;
/* eslint-enable max-len */
