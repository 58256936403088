import React from 'react';
import styled from 'styled-components';
import { blockStyleProps, baseStyledProps, typographyStyleProps } from 'config/styledProps';
import { flexbox } from 'styled-system';
import { compact, flatten } from 'lodash';
import { IStack, TStackProps } from 'resources/types/elementsTypes';

const StackAtom = styled('div')<TStackProps>(baseStyledProps, blockStyleProps, typographyStyleProps, flexbox, {
  display: 'flex',
});

const Stack = ({ children, spacing, divider, isHorizontal, ...stackAtomStyleProps }: IStack) => {
  const marginDir = isHorizontal ? 'marginInlineStart' : 'mt';
  const flexDirection = isHorizontal ? 'row' : 'column';
  const childProps = divider ? {} : { [marginDir]: spacing };
  const compactedChildrens = flatten(compact(Array.isArray(children) ? children : [children]));

  return (
    <StackAtom flexDirection={flexDirection} {...stackAtomStyleProps}>
      {React.Children.map(
        compactedChildrens,
        (child, index) =>
          child && [
            React.cloneElement(child as React.ReactElement, index > 0 ? childProps : {}),
            index + 1 !== compactedChildrens.length && divider,
          ]
      )}
    </StackAtom>
  );
};

Stack.defaultProps = {
  spacing: 'md',
  isHorizontal: false,
  divider: null,
};

export default Stack;
