import React from 'react';

import MaintenanceModeSvg from '@old/assets/svg/MaintenanceMode.svg';
import t from 'resources/translations';

const MaintenanceMode = () => {
  return (
    <div className="page-error h-screen">
      <img src={MaintenanceModeSvg} alt="maintenance-mode" />
      <div className="text-error-page mb-4">{t('maintanceMode.workingOnApp')}</div>
      <div className="text-lg leading-normal">
        {t('maintanceMode.appUnavailable')}
        <a className="text-teal" href="https://www.facebook.com/HORSEmanago/">{t('maintanceMode.ourFacebook')}</a>
      </div>
    </div>
  );
};

export default MaintenanceMode;
