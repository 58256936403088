import React from 'react';

const ArrowDown = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      width="100%"
      className="icon"
    >
      <path d="M1.9,3.1L8,9.2l6.1-6.1L16,4.9l-8,8l-8-8L1.9,3.1z" />
    </svg>
  );
};

export default ArrowDown;
