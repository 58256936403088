import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import axios from 'axios';

import t from 'resources/translations';
import InstructorDetails from 'old/pages/InstructorDetails';
import ClientDetails from 'old/pages/ClientDetails';
import Spinner from '@old/components/common/Spinner';
import Message from '@old/components/common/Message';
import Error404 from '@old/components/error/Error404';
import { fetchUser } from 'store/actions';
import { useOldLoggedMember } from 'utils/storeUtils';

const UserDetails = ({ match, loading, error, location, member, ...actions }) => {
  const loggedMember = useOldLoggedMember();

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchUser(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  if (error) return <Error404 />;

  if (loading && !member) {
    return <Spinner />;
  }

  if (String(loggedMember.id) === match.params.id && loggedMember.isPending()) {
    return (
      <Message className="mt-4" warning>
        {t('message.pendingApproval')}
      </Message>
    );
  }

  if (String(loggedMember.id) === match.params.id && loggedMember.isBlocked()) {
    return (
      <Message className="mt-4" warning>
        {t('message.blockedMember')}
      </Message>
    );
  }

  if (!member) return null;

  if (member.isInstructor() && location.pathname.indexOf('instructors') < 0) {
    // fixme
    return <Redirect to={member.getProfileUrl()} />;
  }

  return (
    <React.Fragment>
      {member.isInstructor() ? ( // fixme
        <InstructorDetails member={member} />
      ) : (
        <ClientDetails member={member} />
      )}
    </React.Fragment>
  );
};

UserDetails.defaultProps = {
  member: null,
};

const mapStateToProps = ({ fetchingData: { users, loading, error } }, { match }) => {
  return {
    member: users.find(user => String(user.id) === String(match.params.id)),
    loading: loading.users,
    error: error.users,
  };
};

export default connect(mapStateToProps, { fetchUser })(UserDetails);
