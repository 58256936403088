import { useRef, useState } from 'react';

import DatePicker from 'old/components/custom/DatePicker';
import ModalSimple from 'old/components/common/ModalSimple';
import InputWrapper from 'components/combinations/forms/InputWrapper';
import { Box, Button, Icons, Input } from 'components/elements';
import moment, { Moment } from 'moment';
import { TBoxProps, TFlexProps } from 'resources/types/elementsTypes';
import { DATE_FORMAT } from 'config/constans';
import { useOutsideClick } from 'utils';
import { useAppSelector } from 'utils/storeUtils';

type TDateInput = TFlexProps & {
  value: string;
  name: string;
  placeholder: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  /** custom date picker options */
  pickerOptions: {
    minDate?: Moment;
    maxDate?: Moment;
    defaultIsOpen?: boolean;
  };
  pickerPosition?: 'bottom' | 'top';
};

const desktopPickerStyles = (pickerPosition: 'bottom' | 'top') =>
  ({
    maxW: 300,
    right: 0,
    zIndex: 20,
    position: 'absolute',
    ...(pickerPosition === 'top' ? { bottom: '100%' } : { top: '100%' }),
  } as TBoxProps);

const DateInput = ({
  value,
  name,
  placeholder,
  onChange,
  disabled,
  pickerOptions = {},
  pickerPosition = 'bottom',
  ...styleProps
}: TDateInput) => {
  const isSmallScreen = useAppSelector(({ app }) => app.isMobile || app.isTablet);
  const [showPicker, setShowPicker] = useState<Boolean>(!!pickerOptions.defaultIsOpen);
  const [pickerValue, setPickerValue] = useState<string>(value || moment().format(DATE_FORMAT));
  const inputRef = useRef<HTMLDivElement>(null);
  useOutsideClick(inputRef, () => !isSmallScreen && setShowPicker(false));

  const onSubmitDateModalPicker = () => {
    onChange(pickerValue);
    setShowPicker(false);
  };

  return (
    <InputWrapper position="relative" innerRef={inputRef} {...styleProps}>
      <Input
        id={name}
        type="text"
        name={name}
        value={value}
        color={disabled ? 'grey' : 'grey-dark'}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        pl={20}
      />
      <Button
        pr={20}
        variant="icon"
        tabIndex={-1}
        ml="sm"
        onClick={() => (disabled ? {} : setShowPicker(prevShowPicker => !prevShowPicker))}
      >
        <Icons.Calendar squareSize="lg" fill={disabled ? 'grey' : 'teal-light'} />
      </Button>
      {!isSmallScreen && showPicker && (
        <Box {...desktopPickerStyles(pickerPosition)}>
          {/** @ts-ignore */}
          <DatePicker
            value={value}
            onChange={val => onChange(val)}
            close={() => setShowPicker(false)}
            {...pickerOptions}
          />
        </Box>
      )}
      {isSmallScreen && showPicker && (
        //  @ts-ignore
        <ModalSimple close={() => setShowPicker(false)} open={true} small basic>
          {/** @ts-ignore */}
          <ModalSimple.Body>
            {/** @ts-ignore */}
            <DatePicker value={pickerValue} onChange={setPickerValue} close={() => {}} {...pickerOptions} />
          </ModalSimple.Body>
          {/** @ts-ignore */}
          <ModalSimple.Action close={() => setShowPicker(false)} onSubmit={onSubmitDateModalPicker} />
        </ModalSimple>
      )}
    </InputWrapper>
  );
};

DateInput.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  pickerOptions: {},
};

export default DateInput;
