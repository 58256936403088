import React from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';

// FIXME: in 1.4.1v.
const SelectMenuList = (props) => {
  return (
    <div className="overflow-hidden h-full shadow-box" style={{ maxHeight: props.selectProps.maxMenuHeight }}>
      <SimpleBar scrollableNodeProps={{ ref: props.innerRef }}>
        {props.children}
      </SimpleBar>
    </div>
  );
};

SelectMenuList.propTypes = {
  selectProps: PropTypes.shape({
    maxMenuHeight: PropTypes.number,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  children: PropTypes.node.isRequired,
};

export default SelectMenuList;
