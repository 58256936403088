import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Link from '@old/components/common/Link';
import PageTitle from '@old/components/guide/PageTitle';
import { getFormByModelName, getModelByName, notify } from 'old/utils';
import t from 'resources/translations';
import Spinner from '@old/components/common/Spinner';

const Add = ({ match, history, location }) => {
  const { model: modelName } = match.params;
  const Form = getFormByModelName(modelName);
  const Model = getModelByName(modelName);
  const [docToCopy, setDocToCopy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCopyDoc = async () => {
      setIsLoading(true);
      try {
        const result = await Model.fetch(location.state.docId);
        setDocToCopy(Model.ItemClass.mapToFormData(result));
      } finally {
        setIsLoading(false);
      }
    };
    if (location.state && location.state.docId) {
      fetchCopyDoc();
    }
  }, [location, Model]);

  const redirectTo = (customRedirect, docId) => {
    history.replace(customRedirect || `/${match.params.model}/${docId}`);
  };
  const addDocument = async (data, options = {}) => {
    const doc = await Model.create(data, options.params);
    notify(renderSuccessMessage(doc));

    if (!options.noRedirect) {
      redirectTo(options.redirectWithId ? `${options.redirectTo}${doc.id}` : options.redirectTo, doc.id);
    }
  };
  const renderSuccessMessage = doc => (
    <>
      <div>{t(`add.${Model.name}`)}:</div>
      {modelName !== 'proposals'
        ? (<Link to={`/${modelName}/${doc.id}`}>{doc.getName()}</Link>)
        : (<>{doc.getName()} </>)}
    </>
  );

  if (!Form || modelName === 'users') {
    return t('add.notImplemented');
  }

  if (isLoading) return <Spinner />;

  return (
    <>
      <PageTitle title={t(`model.add.${Model.name}`)} back />
      <Form
        onSubmit={addDocument}
        initValues={location?.state?.initValues || docToCopy}
      />
    </>
  );
};

Add.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      model: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      docId: PropTypes.number,
      initValues: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default Add;
