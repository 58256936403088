import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { times, find } from 'lodash';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import Tooltip from '@old/components/common/Tooltip';
import Title from '@old/components/common/Title';
import t from 'resources/translations';
import Box from '@old/components/common/Box';
import Subtitle from '@old/components/common/Subtitle';
import { useModelItems, useHorsesWeekStatistics } from '@old/hooks';
import RowAligner from '@old/components/common/RowAligner';
import IndicatorColor from '@old/components/common/IndicatorColor';
import Icon from '@old/components/icon';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import FlexColumn from '@old/components/common/FlexColumn';
import Center from '@old/components/common/Center';
import Spinner from '@old/components/common/Spinner';
import { formatDurationWithoutDays } from 'old/utils';
import ToggleBlock from '@old/components/common/ToggleBlock';
import Message from '@old/components/common/Message';
import WeekSelector from '@old/components/custom/WeekSelector';
import { openModal } from 'store/actions';
import FlexRow from '@old/components/common/FlexRow';
import Error404 from '@old/components/error/Error404';
import SpinnerDots from '@old/components/guide/SpinnerDots';
import config from '@old/config';
import BoxWhite from '@old/components/common/BoxWhite';
import HorseStatisticMobile from '@old/components/custom/HorseStatisticsMobile';
import { Grid } from '@elements';
import { useFarm } from 'utils/storeUtils';

const HorsesStatistics = ({ isMobile, openModalByName }) => {
  const farm = useFarm();
  const [legendIsOpen, setLegendIsOpen] = useState(false);
  const [statisticsDateRange, setStatisticsDateRange] = useState({
    start: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    end: moment().endOf('isoWeek').format('YYYY-MM-DD'),
  });

  const documentDate = {
    start: moment(statisticsDateRange.start).format(config.dateFormat),
    end: moment(statisticsDateRange.end).format(config.dateFormat),
  };
  const addWeek = () => {
    setStatisticsDateRange(prevDate => ({
      start: moment(prevDate.start, 'YYYY-MM-DD').add(1, 'w').format('YYYY-MM-DD'),
      end: moment(prevDate.end, 'YYYY-MM-DD').add(1, 'w').format('YYYY-MM-DD'),
    }));
  };
  const subtractWeek = () => {
    setStatisticsDateRange(prevDate => ({
      start: moment(prevDate.start, 'YYYY-MM-DD').subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment(prevDate.end, 'YYYY-MM-DD').subtract(1, 'w').format('YYYY-MM-DD'),
    }));
  };

  const componentRef = useRef();
  const [eventTypes, eventTypesFetchStatuses] = useModelItems('event-types', { per_page: 9999 });
  const [statistics, statisticsFetchReady, statisticsFetchError] = useHorsesWeekStatistics(statisticsDateRange);

  if (eventTypesFetchStatuses.isPending) return <Spinner />;
  if (eventTypesFetchStatuses.isError || statisticsFetchError) return <Error404 />;

  const StatisticsComponent = isMobile ? HorseStatisticMobile : HorseStatisticWeb;
  return (
    <div>
      <FlexColumn marginBottom separated="small">
        {!isMobile ? (
          <>
            <div className="flex mb-4">
              <Title>{t('horseStatistics.horseRidingStatement', { farm: farm.getName() })}</Title>
              <div className="ml-auto">
                <Tooltip content={t('horseStatistics.print')}>
                  <ReactToPrint
                    content={() => componentRef.current}
                    trigger={() => (
                      <ButtonSimple fluid>
                        <Icon.Print className="fill-teal" fill="teal" />
                      </ButtonSimple>
                    )}
                  />
                </Tooltip>
              </div>
            </div>
            <WeekSelector
              weekDate={statisticsDateRange}
              setWeekDate={setStatisticsDateRange}
              addWeek={addWeek}
              subtractWeek={subtractWeek}
            />
          </>
        ) : (
          <BoxWhite marginBottom>
            <div className="mb-4">
              <div className="w-2/3 inline-flex">
                <Title>
                  {t('horseStatistics.horseStatementRiding')}
                  <div>{farm.getName()}</div>
                </Title>
              </div>
              <div className="float-right">
                <Tooltip content={t('horseStatistics.print')}>
                  <ReactToPrint
                    content={() => componentRef.current}
                    trigger={() => (
                      <ButtonSimple fluid>
                        <Icon.Print className="fill-teal" fill="teal" />
                      </ButtonSimple>
                    )}
                  />
                </Tooltip>
              </div>
            </div>
            <Center>
              <WeekSelector
                weekDate={statisticsDateRange}
                setWeekDate={setStatisticsDateRange}
                addWeek={addWeek}
                subtractWeek={subtractWeek}
              />
            </Center>
          </BoxWhite>
        )}
      </FlexColumn>
      <FlexColumn>
        {eventTypes.length > 0 && (
          <ButtonSimple onClick={() => setLegendIsOpen(prevIsOpen => !prevIsOpen)} fluid>
            <Box fullWidth>
              <RowAligner className="justify-between">
                <Subtitle>{t('horseStatistics.legend')}</Subtitle>
                {!legendIsOpen ? <Icon.ArrowDown className="fill-teal" /> : <Icon.ArrowUp className="fill-teal" />}
              </RowAligner>
              <ToggleBlock isOpen={legendIsOpen}>
                {!isMobile ? (
                  <HorsesStatisticsLegendWeb eventTypes={eventTypes} />
                ) : (
                  <div className="mb-8">
                    <HorsesStatisticsLegendMobile eventTypes={eventTypes} />
                  </div>
                )}
              </ToggleBlock>
            </Box>
          </ButtonSimple>
        )}
        <StatisticsComponent
          statistics={statistics}
          statisticsFetchReady={statisticsFetchReady}
          eventTypes={eventTypes}
          openModalByName={openModalByName}
        />
      </FlexColumn>
      {/* Print page */}
      <div className="hidden">
        <div ref={componentRef} className="print-horses-statistic">
          {eventTypes.length > 0 && <HorsesStatisticsLegendWeb eventTypes={eventTypes} withTitle />}
          <Center className="my-4 text-title-md">{`${documentDate.start} - ${documentDate.end}`}</Center>
          <HorseStatisticWeb
            statistics={statistics}
            statisticsFetchReady={statisticsFetchReady}
            eventTypes={eventTypes}
          />
        </div>
      </div>
    </div>
  );
};

HorsesStatistics.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  openModalByName: PropTypes.func.isRequired,
};

HorsesStatistics.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  openModalByName: PropTypes.func.isRequired,
};

const HorsesStatisticsLegendWeb = ({ eventTypes, withTitle }) => (
  <Box fullWidth>
    {withTitle && (
      <FlexRow marginBottom>
        <Subtitle>{t('horseStatistics.legend')}</Subtitle>
      </FlexRow>
    )}
    <FlexRow separated={false} marginBottom noShrink>
      <FlexRow cols="none/1" noShrink>
        <IndicatorColor border large />
        <div className="text-green-light flex">{t('horseStatistics.horseShift')}</div>
      </FlexRow>
      <FlexRow cols="none/1" noShrink>
        <IndicatorColor color="bg-orange" large />
        <div className="text-orange flex">{t('horseStatistics.horseAbsence')}</div>
      </FlexRow>
    </FlexRow>
    <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap={0}>
      {eventTypes.map(eventType => (
        <FlexRow cols="none/1" key={eventType.id} noShrink>
          <span className="font-bold uppercase" style={{ color: eventType.getColor() }}>
            {eventType.getSlug() || 'null'}
          </span>
          <div className="flex text-left">{eventType.name}</div>
        </FlexRow>
      ))}
    </Grid>
  </Box>
);

HorsesStatisticsLegendWeb.defaultProps = {
  withTitle: false,
};

HorsesStatisticsLegendWeb.propTypes = {
  withTitle: PropTypes.bool,
};

const HorsesStatisticsLegendMobile = ({ eventTypes, withTitle }) => (
  <Box fullWidth>
    {withTitle && (
      <FlexRow marginBottom>
        <Subtitle>{t('horseStatistics.legend')}</Subtitle>
      </FlexRow>
    )}
    <FlexColumn separated="small">
      <RowAligner>
        <IndicatorColor border large />
        <div className="text-green-light flex">{t('horseStatistics.horseShift')}</div>
      </RowAligner>
      <RowAligner>
        <IndicatorColor color="bg-orange" large />
        <div className="text-orange flex">{t('horseStatistics.horseAbsence')}</div>
      </RowAligner>
      <>
        {eventTypes.map(eventType => (
          <RowAligner className="mb-2" key={eventType.id}>
            <div className="font-bold uppercase whitespace-no-wrap" style={{ color: eventType.getColor() }}>
              {eventType.getSlug() || 'null'}
            </div>
            <div className="text-left">{eventType.name}</div>
          </RowAligner>
        ))}
      </>
    </FlexColumn>
  </Box>
);

HorsesStatisticsLegendMobile.defaultProps = {
  withTitle: false,
};

HorsesStatisticsLegendMobile.propTypes = {
  withTitle: PropTypes.bool,
};

const HorseStatisticWeb = ({ statistics, statisticsFetchReady, eventTypes, openModalByName }) => {
  const renderEventTypes = (slugName, isLast) => {
    const evenType = find(eventTypes, { slug: slugName.substring(slugName.length - 2, slugName.length) });
    return (
      <span key={slugName}>
        <span className="uppercase" style={{ color: evenType ? evenType.getColor() : 'grey' }}>
          {slugName}
        </span>
        {!isLast && <span>,</span>}
      </span>
    );
  };

  if (statisticsFetchReady && statistics.length === 0) {
    return <Message info>{t('horseStatistics.noStatistics')}</Message>;
  }

  return (
    <div className="relative">
      <FlexRow marginBottom>
        <div className="horse-statistics">
          <div className="flex items-center bg-grey-light pl-2">{t('horseStatistics.horse')}</div>
          {times(7, i => (
            <Center key={i} className="bg-grey-light text-title-md">
              {moment()
                .isoWeekday(i + 1)
                .format('dd')}
            </Center>
          ))}
          <Center className="bg-grey-light text-title-md">{t('horseStatistics.inTotal')}</Center>
        </div>
      </FlexRow>

      {!statisticsFetchReady && (
        <Center className="py-2">
          <SpinnerDots />
        </Center>
      )}

      {statisticsFetchReady &&
        statistics.map((statistic, key) => (
          <div key={key} className="horse-statistics horse-statistics-print" style={{ pageBreakInside: 'avoid' }}>
            <div className="flex items-center pl-2">{statistic.horse.name}</div>
            {statistic.dates.map((dayWeek, keyDate) => {
              const slugs = dayWeek.eventTypesSlugs.map((eventTypeSlug, index) =>
                renderEventTypes(eventTypeSlug, index === dayWeek.eventTypesSlugs.length - 1)
              );
              const modalProps = {
                date: moment(dayWeek.date),
                slugColor: slugs,
                slugName: dayWeek.eventTypesSlugs,
                horseName: statistic.horse.name,
                horseId: statistic.horse.id,
              };

              return (
                <Center key={keyDate}>
                  <ButtonSimple onClick={() => (!dayWeek.absent ? openModalByName('horseStatistics', modalProps) : {})}>
                    {dayWeek.absent && (
                      <div className="cursor-default">
                        <IndicatorColor color="bg-orange" large />
                      </div>
                    )}
                    {!dayWeek.absent && dayWeek.eventTypesSlugs.length === 0 && <IndicatorColor border large />}
                    {!dayWeek.absent && (
                      <div style={{ wordBreak: 'break-word' }}>
                        {slugs}
                        {dayWeek.duration !== 0 && `/${formatDurationWithoutDays(dayWeek.duration)}`}
                      </div>
                    )}
                  </ButtonSimple>
                </Center>
              );
            })}

            <Center>{formatDurationWithoutDays(statistic.totalDuration)}</Center>
          </div>
        ))}
    </div>
  );
};

HorseStatisticWeb.defaultProps = {
  openModalByName: () => {},
};

HorseStatisticWeb.propTypes = {
  openModalByName: PropTypes.func,
  statisticsFetchReady: PropTypes.bool.isRequired,
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      horse: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      dates: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          duration: PropTypes.number,
          eventTypesSlugs: PropTypes.arrayOf(PropTypes.string),
          absent: PropTypes.boolean,
        })
      ),
      totalDuration: PropTypes.number,
    })
  ).isRequired,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps, { openModalByName: openModal })(HorsesStatistics);
