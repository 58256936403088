/* eslint-disable max-len */
import React from 'react';

const Horse = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M3.1,12.6C0.9,9.4,2.5,6.9,2.5,6.9C2.1,6.8,1,7.6,1,7.6c0.6-2.3,4.2-4.3,4.2-4.3C4.8,3.1,3.7,3.4,3.7,3.4
        c1.2-1.2,2.9-1.9,4.8-1.9c0.6,0,1.1,0.1,1.6,0.2l0,0C10.1,1.1,11.9,0,11.9,0c-0.2,0.2-0.2,1.1-0.2,1.1c0.2-0.3,0.5-0.5,0.7-0.6l0,0
        c-0.3,0.7,0,2.4,0,2.4c0.9,0.9,2,4.1,2,4.1l0.4,0.6c0.5,0.7,0.3,1.8-0.2,2.1c-1.8,1-2-0.8-2-0.8C11,8.6,9.9,6.9,9.9,6.9
        C7.8,8,8.9,11,9.9,12.1c1.1,1.5,0.3,3.9,0.3,3.9S5.3,15.8,3.1,12.6L3.1,12.6z"
      />
    </svg>
  );
};

export default Horse;
/* eslint-enable max-len */
