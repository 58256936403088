import React from 'react';
import RowAligner from '@old/components/common/RowAligner';
import SpinnerDots from '@old/components/guide/SpinnerDots';

const OptionLoader = () => (
  <div className="py-4">
    <RowAligner className="justify-center">
      <SpinnerDots withLabel={false} />
    </RowAligner>
  </div>
);

export default OptionLoader;
