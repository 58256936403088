import styled from 'styled-components';
import NumericInput from 'react-numeric-input';
import { baseStyledProps, blockStyleProps, typographyStyleProps } from 'config/styledProps';
import { TInputProps } from 'resources/types/elementsTypes';

const InputNumber = styled(NumericInput).withConfig({
  shouldForwardProp: prop => !['marginInlineStart'].includes(prop),
})<TInputProps>(baseStyledProps, typographyStyleProps, blockStyleProps);

InputNumber.defaultProps = {
  style: false,
  px: 0,
  marginInlineStart: 0,
  width: '100%',
};

export default InputNumber;
