import styled from 'styled-components';
import { baseStyledProps, typographyStyleProps } from 'config/styledProps';
import { TTextProps } from 'resources/types/elementsTypes';

const Text = styled('p')<TTextProps>(baseStyledProps, typographyStyleProps);

Text.defaultProps = {
  py: 0,
  fontSize: 'base',
  lineHeight: 'normal',
};

export default Text;
