import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import times from 'lodash/times';
import cls from 'classnames';

import Center from '@old/components/common/Center';
import BoxShadow from '@old/components/common/BoxShadow';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import FlexRow from '@old/components/common/FlexRow';
import { getRowsInMonth } from 'old/utils';
import { useScrollBottomOfBox } from '@old/hooks';
import config from '@old/config';

const DatePicker = ({ value, onChange, close, maxDate, minDate, defaultDate }) => {
  const selectedDate = moment(value, config.dateFormat, true).isValid()
    ? moment(value, config.dateFormat)
    : defaultDate;
  const [activeDate, setActiveDate] = useState(selectedDate);
  const innerRef = useScrollBottomOfBox(30);
  useEffect(() => {
    setActiveDate(selectedDate);
  }, [selectedDate.format(config.dateFormat)]); // eslint-disable-line react-hooks/exhaustive-deps
  const onDayClick = dayDate => {
    onChange(dayDate.format(config.dateFormat));
    close();
  };
  const daysInMonth = activeDate.daysInMonth();
  const startOfMonth = moment(activeDate).startOf('month').isoWeekday(); // 1 - monday, 2 - tuesday, ..., 7 - sunday
  const endOfMonth = moment(activeDate).endOf('month').isoWeekday();
  const renderDays = () =>
    times(daysInMonth, i => {
      const dayDate = moment(activeDate).date(i + 1);
      const dayIsSelected = selectedDate.isValid() && selectedDate.isSame(dayDate, 'day');
      const isRestrictedByMaxMinDate = (minDate && dayDate < minDate) || (maxDate && dayDate > maxDate);
      return (
        <Day
          key={i}
          date={dayDate}
          disabled={isRestrictedByMaxMinDate}
          selected={dayIsSelected}
          onClick={() => onDayClick(dayDate)}
        />
      );
    });

  const renderPastMonthDays = () =>
    times(startOfMonth - 1, i => {
      const dayDate = moment(activeDate).date(-arr.length + 1 + i);
      return <Day key={i} date={dayDate} disabled />;
    });
  const arr = times(startOfMonth - 1, () => null);
  const renderFutureMonthDays = () =>
    times(7 - endOfMonth, i => {
      const dayDate = moment(activeDate)
        .endOf('month')
        .add(1 + i, 'd');
      return <Day key={i} date={dayDate} disabled />;
    });
  const rowsNumber = getRowsInMonth({ daysInMonth, dayOfWeekInFirstMonthDay: startOfMonth });
  return (
    <div ref={innerRef}>
      <BoxShadow>
        <DateSwitcher date={activeDate} onChange={setActiveDate} />
        <div className="date-picker" style={{ gridTemplateRows: `50px repeat(${rowsNumber}, 40px)` }}>
          {times(7, i => (
            <Center key={i} className="uppercase text-bold-teal h-full">
              {moment()
                .isoWeekday(i + 1)
                .format('dd')}
            </Center>
          ))}
          {renderPastMonthDays()}
          {renderDays()}
          {renderFutureMonthDays()}
        </div>
      </BoxShadow>
    </div>
  );
};

DatePicker.defaultProps = {
  minDate: false,
  maxDate: false,
  defaultDate: moment(),
};

DatePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

const Day = ({ selected, date, onClick, disabled }) => {
  if (!date) return <div />;
  const isCurrentDay = date && date.isSame(moment(), 'day');
  const className = cls('day h-full', {
    'text-highlight dot': isCurrentDay,
    'bg-teal-dark text-white rounded-full font-bold': selected,
    disabled,
  });

  return (
    <Center className={className}>
      <ButtonSimple onClick={!disabled ? onClick : () => {}} fluid>
        {date.format('D')}
      </ButtonSimple>
    </Center>
  );
};

Day.defaultProps = {
  selected: false,
  date: null,
  onClick: () => {},
  disabled: false,
};

Day.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const DateSwitcher = ({ date, onChange }) => {
  const [mode, setMode] = useState('month');
  const [monthSelectOpen, setMonthSelectOpen] = useState(false);
  const onMonthClick = monthIndex => {
    setMonthSelectOpen(false);
    setMode('month');
    onChange(moment(date).month(monthIndex));
  };
  const prevMonth = () => onChange(moment(date).subtract(1, mode));
  const nextMonth = () => onChange(moment(date).add(1, mode));
  const changeMode = () => {
    setMode(mode === 'month' ? 'year' : 'month');
    setMonthSelectOpen(prevMonthSelectOpen => !prevMonthSelectOpen);
  };

  return (
    <div className="relative">
      <Center className="bg-teal rounded text-md-white py-1 px-2">
        <FlexRow cols="none/1/none" alignCenter stretched separated={false} noShrink>
          <ButtonSimple onClick={prevMonth}>
            <Icon.ArrowLeft small />
          </ButtonSimple>
          <ButtonSimple onClick={changeMode}>
            {mode === 'month' ? date.format('MMMM, YYYY') : date.format('YYYY')}
          </ButtonSimple>
          <ButtonSimple onClick={nextMonth}>
            <Icon.ArrowRight small />
          </ButtonSimple>
        </FlexRow>
      </Center>
      {monthSelectOpen && (
        <div className="month-picker">
          {times(12, i => {
            return (
              <ButtonSimple key={i} onClick={() => onMonthClick(i)}>
                {moment(date).month(i).format('MMM')}
              </ButtonSimple>
            );
          })}
        </div>
      )}
    </div>
  );
};

DateSwitcher.defaultProps = {
  date: null,
  onChange: () => {},
};

DateSwitcher.propTypes = {
  onChange: PropTypes.func,
};

export default DatePicker;
