import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import axios from 'axios';

import Error404 from '@old/components/error/Error404';
import Spinner from '@old/components/common/Spinner';
import { setPagination, fetchRatings } from 'store/actions';
import { getPaginationId, getModelByName } from 'old/utils';
import FlexColumn from '@old/components/common/FlexColumn';
import RatingList from '@old/components/view/list/Rating';
import config from '@old/config';

const RatingsPage = ({
  loading,
  error,
  ratings,
  ratingsPagination,
  ratingsModel,
  pagination,
  history,
  match,
  ...actions
}) => {
  const { minItemsPerPage } = config;

  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    if (isEmpty(pagination) || pagination.current !== undefined) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const fetch = async () => {
        await actions.fetchRatings(
          getModelByName(match.params.model),
          parseInt(match.params.id, 10),
          {
            page: getActivePage() || 1,
            per_page: minItemsPerPage,
          },
          source.token
        );
      };
      fetch();
      return () => source.cancel();
    }
    return false;
  }, [history.location.state, match.params.id, match.params.model, pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ratingsPagination) {
      const pag = { ...ratingsPagination };
      // When you delete last element on page, go back to previous one
      const lastPage = Math.ceil(pag.count / pag.per_page);
      if (pag.per_page * pag.current > pag.count) {
        if (lastPage && pag.current > lastPage) {
          pag.current = lastPage;
          history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
        }
        if (pag.current === 0) {
          pag.current = 1;
        }
      }
      actions.setPagination(pag, getPaginationKey());
    }
  }, [ratingsPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Error404 />;
  if (loading && !ratings.length) {
    return <Spinner noDelay />;
  }

  return (
    <FlexColumn>
      <RatingList ratings={ratings} modelItem={ratingsModel} pagKey={getPaginationKey()} />
    </FlexColumn>
  );
};

RatingsPage.defaultProps = {
  ratings: [],
  ratingsPagination: {},
  ratingsModel: {},
  match: {
    params: {
      model: null,
    },
  },
};

const mapStateToProps = ({
  pagination,
  fetchingData: { ratings, ratingsPagination, ratingsModel, loading, error },
}) => {
  const paginationKey = getPaginationId(`${window.location.pathname}`);

  return {
    ratings,
    ratingsPagination,
    ratingsModel,
    loading: loading.ratings,
    error: error.ratings,
    pagination: pagination[paginationKey] || {},
  };
};

export default withRouter(connect(mapStateToProps, { fetchRatings, setPagination })(RatingsPage));
