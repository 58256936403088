/**
 * @module EditableInput
 */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Icon from '@old/components/icon';
import Input from '@old/components/input';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import t from 'resources/translations';
import { useOutsideClick, useKey } from '@old/hooks';
import RowAligner from '@old/components/common/RowAligner';

/**
 * Component displaying value and giving the option of inline editing
 * @param {String} props.defaultValue
 * @param {Function} props.onSubmit submit handler
 */
const EditableInput = ({ defaultValue, onSubmit }) => {
  const [value, setValue] = useState(defaultValue || '');
  const [editing, setIsEditing] = useState(false);
  const innerRef = useRef();

  /**
   * @name useOutsideClick
   * @function
   * @description hook used to end edit on click outside field area
   */
  useOutsideClick(innerRef, () => {
    cancelEdit();
  });

  const onEscapeCancelEdit = useKey(() => cancelEdit(), 'esc');

  const cancelEdit = () => {
    setValue(defaultValue || '');
    setIsEditing(false);
  };

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const onSave = () => {
    setIsEditing(false);
    onSubmit(value.trim());
  };

  /**
   * @name setFocus
   * @function
   * @description Function to set cursor at the end of input value after entering in edit mode
   * @see {@link https://stackoverflow.com/questions/35951771/react-autofocus-sets-curser-to-beginning-of-input-value|focus on end}
   * @param {*} e
   */
  const setFocus = (e) => {
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  };

  const onClick = () => {
    if (editing) return;

    setIsEditing(true);
  };

  const icon = (
    <div className="absolute right-0 top-0 p-2">
      <ButtonSimple onClick={onSave}>
        <Icon.Save className="fill-teal" />
      </ButtonSimple>
    </div>
  );

  return (
    <div className="editable">
      {!editing && (
        <div>
          <RowAligner separated="small" className="text-input-content">
            <div className="text-highlight">{t('memberManagement.profileName')}:</div>
            <div className="my-1 text">
              {value || <span className="text-fade">{t('editableTextarea.clickToEdit')}</span>}
            </div>
            <ButtonSimple onClick={onClick}>
              {/* modification that fixes a cut icon on Iphone */}
              <div className="ml-2">
                <Icon.Edit className="fill-teal" small />
              </div>
            </ButtonSimple>
          </RowAligner>

        </div>
      )}
      {editing && (
        <div ref={innerRef}>
          <Input.Text
            value={value}
            onChange={onChange}
            icon={icon}
            onKeyDown={onEscapeCancelEdit}
            autoFocus
            onFocus={setFocus}
          />
        </div>
      )}
    </div>
  );
};

EditableInput.defaultProps = {
  defaultValue: '',
};

EditableInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

export default EditableInput;
