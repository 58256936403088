import { useState } from 'react';
import { connect } from 'react-redux';
import Allow from '@old/components/common/Allow';
import DndWrapper from '@old/components/common/DndWrapper';
import FlexColumn from '@old/components/common/FlexColumn';
import UnpairedHorsesList from '@old/components/view/pairs/UnpairHorses';
import ParticipationsList from '@old/components/view/pairs/PairParticipations';
import ModalSimple from '@old/components/common/ModalSimple';
import Option from '@old/components/view/listItem/SelectOption';
import t from 'resources/translations';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import PlaceholderListItem from '@old/components/view/listItem/Placeholder';
import FlexSection from '@old/components/common/FlexSection';
import FlexRow from '@old/components/common/FlexRow';
import { useEventPairs } from '@old/hooks';
import { fetchEvent } from 'store/actions';

const Pairs = ({ event, isMobile, isTablet, buttons, cb }) => {
  const [eventDeleteHorseButton, eventAddParticipant, eventAddHorse] = buttons;
  const [participations, unpairedHorses, setPair, setUnpair] = useEventPairs(event, cb);

  const availabilityCounter = { participating: participations.length, max: event.getAttendeesMax() };
  const props = { participations, availabilityCounter, event };
  const renderButton = button =>
    button && (
      <ButtonSimple onClick={button.onClick} fluid>
        <PlaceholderListItem className="teal-hover">
          {button.icon}
          <div className="font-bold">{button.label}</div>
        </PlaceholderListItem>
      </ButtonSimple>
    );

  const pairsWidget = (
    <FlexSection>
      <FlexRow cols={isTablet ? '1' : '10/4'}>
        <ParticipationsList
          setPair={setPair}
          setUnpair={setUnpair}
          addParticipantButton={renderButton(eventAddParticipant)}
          {...props}
        />
        <div />
      </FlexRow>
      <UnpairedHorsesList
        horses={unpairedHorses}
        participants={participations}
        addHorseButton={renderButton(eventAddHorse)}
        eventDeleteHorseButton={eventDeleteHorseButton}
        event={event}
        cb={cb}
      />
    </FlexSection>
  );

  if (isMobile) {
    return (
      <PairsMobile
        horses={unpairedHorses}
        setPair={setPair}
        setUnpair={setUnpair}
        addParticipantButton={renderButton(eventAddParticipant)}
        addHorseButton={renderButton(eventAddHorse)}
        eventDeleteHorseButton={eventDeleteHorseButton}
        cb={cb}
        {...props}
      />
    );
  }

  return (
    <Allow
      fallback={pairsWidget}
      permissionKey="participations.attach_horse"
      permissionParams={{ modelItem: event }}
      additionalCheck={!event?.isDeleted()}
    >
      <DndWrapper>{pairsWidget}</DndWrapper>
    </Allow>
  );
};

Pairs.defaultProps = {
  buttons: [],
};

const mapStateToProps = ({ app: { isMobile, isTablet } }) => ({ isMobile, isTablet });
export default connect(mapStateToProps, { fetchEventAction: fetchEvent })(Pairs);

const PairsMobile = ({
  event,
  horses,
  participations,
  setPair,
  setUnpair,
  availabilityCounter,
  addParticipantButton,
  addHorseButton,
  eventDeleteHorseButton,
  cb,
}) => {
  const [participationId, setParticipationId] = useState(null);
  const [horseId, setHorseId] = useState(null);

  const pair = () => {
    if (!horseId) {
      setParticipationId(null);
    } else {
      setPair(participationId, horseId);
      setParticipationId(null);
      setHorseId(null);
    }
  };

  return (
    <>
      <FlexColumn>
        <ParticipationsList
          participations={participations}
          setUnpair={setUnpair}
          addParticipantButton={addParticipantButton}
          availabilityCounter={availabilityCounter}
          setParticipationId={setParticipationId}
          horses={horses}
          event={event}
        />
        <UnpairedHorsesList
          event={event}
          horses={horses}
          participants={participations}
          addHorseButton={addHorseButton}
          eventDeleteHorseButton={eventDeleteHorseButton}
          cb={cb}
        />
      </FlexColumn>
      <Allow
        permissionKey="participations.attach_horse"
        permissionParams={{ modelItem: event }}
        additionalCheck={!event?.isDeleted()}
      >
        <ModalSimple open={!!participationId} close={() => setParticipationId(null)}>
          <ModalSimple.Header>{t('eventPairs.instantPairing')}</ModalSimple.Header>
          <ModalSimple.Body>
            <div className="select-list-container">
              {horses.map(horse => {
                const data = { image: horse.getAvatar(), label: horse.getName() };
                return (
                  <Option.Horse
                    key={horse.id}
                    data={data}
                    innerProps={{ onClick: () => setHorseId(horse.id) }}
                    isSelected={horse.id === horseId}
                  />
                );
              })}
            </div>
          </ModalSimple.Body>
          <ModalSimple.Action close={() => setParticipationId(null)} onSubmit={pair} />
        </ModalSimple>
      </Allow>
    </>
  );
};

PairsMobile.defaultProps = {
  addParticipantButton: null,
  addHorseButton: null,
  eventDeleteHorseButton: null,
};
