/**
 * @module RowAligner
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

/**
 * Wrapper that centers items inside
 * @param  {*} props.children
 * @param  {String} [props.className]
 * @param  {Boolean|"small"} [props.separated] specifies if items inside should be separated
 * @param  {Boolean|"small"} [props.marginBottom] specifies if there should be bottom margin
 */
const RowAligner = ({ children, className, separated, marginBottom }) => (
  <div
    className={cls(
      'row-alignator',
      className,
      {
        separated: separated === true,
        'separated-small': separated === 'small',
        'mb-4': marginBottom === true,
        'mb-2': marginBottom === 'small',
      },
    )}
  >
    {children}
  </div>
);

RowAligner.defaultProps = {
  separated: true,
  marginBottom: false,
  className: '',
};

RowAligner.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  separated: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['small']),
  ]),
  marginBottom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['small']),
  ]),
  className: PropTypes.string,
};

export default RowAligner;
