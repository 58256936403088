import useCopyClipboard from 'react-use-clipboard';

import { Button } from '../elements';
import { IButtonStyledProps } from 'resources/types/styledTypes';

type TCopyButton = IButtonStyledProps & {
  content: string;
  isAfterDisabled?: boolean;
  children: (isCopied: boolean) => React.ReactNode;
};
const CopyButton = ({ content, isAfterDisabled, children, ...buttonStyleProps }: TCopyButton) => {
  const [isCopied, setCopied] = useCopyClipboard(content, { successDuration: 2000 });

  return (
    <Button onClick={() => setCopied()} disabled={isCopied && isAfterDisabled} {...buttonStyleProps}>
      {children(isCopied)}
    </Button>
  );
};

export default CopyButton;
