export const SET_ACTIVE_DAY = 'SET_ACTIVE_DAY';
export const SET_ACTIVE_WEEK = 'SET_ACTIVE_WEEK';
export const SET_ACTIVE_MONTH = 'SET_ACTIVE_MONTH';
export const RESET_CALENDAR_STATE = 'RESET_CALENDAR_STATE';

export const setDay = (date, key) => ({ type: SET_ACTIVE_DAY, date, key });

export const setWeek = (date, key) => ({ type: SET_ACTIVE_WEEK, date, key });

export const setMonth = (date, key) => ({ type: SET_ACTIVE_MONTH, date, key });

export const resetCalendarState = () => ({ type: RESET_CALENDAR_STATE });
