import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { times, find } from 'lodash';
import moment from 'moment';
import Carousel from 'nuka-carousel';

import t from 'resources/translations';
import { openModal } from 'store/actions';
import Center from '@old/components/common/Center';
import Message from '@old/components/common/Message';
import FlexRow from '@old/components/common/FlexRow';
import SpinnerDots from '@old/components/guide/SpinnerDots';
import { formatDurationWithoutDays } from 'old/utils';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import IndicatorColor from '@old/components/common/IndicatorColor';
import RowAligner from '@old/components/common/RowAligner';
import Indicator from '@old/components/common/Indicator';
import { Flex, TextTruncate } from 'components/elements';

const StatisticHorsesMobile = ({ statistics, statisticsFetchReady, eventTypes, openModalByName }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const renderEventTypes = (slugName, isLast, maxLenght) => {
    const evenType = find(eventTypes, { slug: slugName.substring(slugName.length - 2, slugName.length) });
    return (
      <span key={slugName}>
        <span className="uppercase" style={{ color: evenType ? evenType.getColor() : 'grey' }}>
          {slugName}
        </span>
        {!(isLast || maxLenght) && <span>,</span>}
      </span>
    );
  };

  if (statisticsFetchReady && statistics.length === 0) {
    return <Message info>{t('horseStatistics.noStatistics')}</Message>;
  }
  const renderItemDay = (i, statistic) => {
    const day = statistic.dates[i];
    const slugs = day.eventTypesSlugs.map((eventTypeSlug, index) =>
      renderEventTypes(eventTypeSlug, index === day.eventTypesSlugs.length - 1, index === 3)
    );
    const modalProps = {
      date: moment(day.date),
      slugColor: slugs,
      slugName: day.eventTypesSlugs,
      horseName: statistic.horse.name,
      horseId: statistic.horse.id,
    };

    return (
      <ButtonSimple onClick={() => (!day.absent ? openModalByName('horseStatistics', modalProps) : {})}>
        {day.absent && (
          <div className="cursor-default">
            <IndicatorColor color="bg-orange" large />
          </div>
        )}
        {!day.absent && day.eventTypesSlugs.length === 0 && <IndicatorColor border large />}
        {!day.absent && (
          <div style={{ wordBreak: 'break-word' }}>
            {slugs.slice(0, 4)}
            {slugs.length >= 4 ? '...' : ''}
            {day.duration !== 0 && `/${formatDurationWithoutDays(day.duration)}`}
          </div>
        )}
      </ButtonSimple>
    );
  };

  return (
    <div className="relative">
      <FlexRow marginBottom>
        {!statisticsFetchReady && (
          <Center className="py-2">
            <SpinnerDots />
          </Center>
        )}
        <Center>
          <div>
            <RowAligner separated="small">
              {[...Array(4)].map((_, index) => (
                <ButtonSimple key={index} onClick={() => setSelectedIndex(index)}>
                  <Indicator className="dots" active={selectedIndex === index} />
                </ButtonSimple>
              ))}
            </RowAligner>
          </div>
        </Center>
        <div className="horse-statistics-mobile">
          {/* first column- title and horses */}
          <div>
            <div className="item mb-4 bg-grey-light">{t('horseStatistics.horse')}</div>
            {statisticsFetchReady &&
              statistics.map((statistic, key) => (
                <div key={key} className="item">
                  <Flex>
                    <TextTruncate lines={2}>{statistic.horse.name}</TextTruncate>
                  </Flex>
                </div>
              ))}
          </div>
          <Carousel
            withoutControls
            slideIndex={selectedIndex}
            afterSlide={i => setSelectedIndex(i)}
            enableKeyboardControls={false}
          >
            {/* Fixme- create better logic to display the statistics */}
            {/* second column-  days of the week and statistic horse */}
            {times(4, i => (
              // displaying the first line, i.e. days of the week and total Duration
              <div key={i}>
                <div className="horse-statistics-mobile-week mb-2">
                  <div>
                    <Center className="item mb-2 bg-grey-light">
                      {/* displayed Mon, Wed, Fri and Sun */}
                      {moment()
                        .isoWeekday(i * 2 + 1)
                        .format('dd')}
                    </Center>
                  </div>
                  {/* displaying Tue, Thu, Sat */}
                  {i !== 3 ? (
                    <div>
                      <Center className="item mb-2 bg-grey-light">
                        {moment()
                          .isoWeekday(i * 2 + 2)
                          .format('dd')}
                      </Center>
                    </div>
                  ) : (
                    // displaying total Duration
                    <div>
                      <Center className="item bg-grey-light">{t('horseStatistics.inTotal')}</Center>
                    </div>
                  )}
                </div>
                {/* display the horse statistics on one day of the week */}
                {statisticsFetchReady &&
                  statistics.map((statistic, key) => (
                    <div key={key} className="horse-statistics-mobile-week">
                      <div>
                        {/* odd days */}
                        <Center className="item">{renderItemDay(i * 2, statistic)}</Center>
                      </div>
                      {/* even days */}
                      {i !== 3 ? (
                        <div>
                          <Center className="item">{renderItemDay(i * 2 + 1, statistic)}</Center>
                        </div>
                      ) : (
                        <div>
                          <Center className="item">{formatDurationWithoutDays(statistic.totalDuration)}</Center>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </Carousel>
        </div>
      </FlexRow>
    </div>
  );
};

StatisticHorsesMobile.defaultProps = {
  openModalByName: () => {},
};

StatisticHorsesMobile.propTypes = {
  openModalByName: PropTypes.func,
  statisticsFetchReady: PropTypes.bool.isRequired,
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      horse: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      dates: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          duration: PropTypes.number,
          eventTypesSlugs: PropTypes.arrayOf(PropTypes.string),
          absent: PropTypes.boolean,
        })
      ),
      totalDuration: PropTypes.number,
    })
  ).isRequired,
};

export default connect(null, { openModalByName: openModal })(StatisticHorsesMobile);
