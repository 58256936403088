import { compact } from 'lodash';

import Link from '@old/components/common/Link';
import RatingStars from '@old/components/old/RatingStars';
import t from 'resources/translations';
import {
  useMemberStatus,
  usePendingAcceptButton,
  usePendingRejectButton,
  useUserEditShiftButton,
  useMemberManagementButton,
  useUserEditButton,
  useGuestEditButton,
} from '@old/hooks';
import Box from '@old/components/common/Box';
import ImageCircle from '@old/components/guide/ImageCircle';
import TextValue from '@old/components/common/TextValue';
import Subtitle from '@old/components/common/Subtitle';
import RowAligner from '@old/components/common/RowAligner';
import MemberLink from '@old/components/custom/MemberLink';
import { ContextTrigger } from '@old/components/common/ContextMenu';
import store from 'store';
import { closeModal } from 'store/actions';
import { useOldLoggedMember } from 'utils/storeUtils';
import { Flex, Icons, Stack, Text } from 'components/elements';

const UsersListItem = ({ member }) => {
  const loggedMember = useOldLoggedMember();
  const status = useMemberStatus(member, loggedMember);
  const closeAfterChangeRole = (_, field) => {
    if (field === 'role') {
      store.dispatch(closeModal('manageMember'));
    }
  };
  const pendingAccept = usePendingAcceptButton(member);
  const pendingDelete = usePendingRejectButton(member);
  const editShift = useUserEditShiftButton(member);
  const memberManagement = useMemberManagementButton(member, status, false, closeAfterChangeRole);
  const editGuest = useGuestEditButton(member);
  const userEdit = useUserEditButton(member, status.isCurrentUser);
  const isContactDetailsHidden = !loggedMember.isClient() && !member.isClient() && member.privateProfile;
  const memberButtons = [pendingAccept, pendingDelete, editShift, memberManagement, editGuest, userEdit];

  const renderExtraContent = () => {
    if (member.isInstructor()) {
      return (
        <div className="mt-4">
          <Link to={`/members/${member.id}/rating`}>
            <RatingStars rating={member.rating || 0} />
          </Link>
        </div>
      );
    }

    if (member.isPending()) {
      return t('general.pending');
    }

    return <TextValue text={t('general.level')} value={t(`skills.${member.getSkill()}`)} />;
  };

  return (
    <ContextTrigger menuItems={compact(memberButtons)}>
      <Box>
        {isContactDetailsHidden && (
          <Flex position="absolute" right={10} top={10}>
            <Icons.CrossedEyeCircle size="xs" bgColor="teal-dark" color="white" />
          </Flex>
        )}
        <RowAligner>
          <MemberLink forceRender member={member}>
            <ImageCircle src={member.getAvatar()} big />
          </MemberLink>
          <div className="ml-4">
            <MemberLink forceRender member={member}>
              <div>
                <Subtitle>{member.getName(40)}</Subtitle>
              </div>
            </MemberLink>

            {member.phone && (
              <TextValue text={t('general.phone')} value={<a href={`tel:${member.phone}`}>{member.phone}</a>} />
            )}
            {member.email && (
              <TextValue text={t('general.email')} value={<a href={`mailto:${member.email}`}>{member.email}</a>} />
            )}
            {member.privateProfile && !member.isClient() && loggedMember.isClient() && (
              <Stack isHorizontal spacing="sm" alignItems="center" mt="md">
                <Icons.ContactDetails squareSize="md" color="teal-dark" />
                <Text fontSize="sm" color="teal-dark">
                  {t('instructorDetails.noContactDetails')}
                </Text>
              </Stack>
            )}
            {renderExtraContent()}
          </div>
        </RowAligner>
      </Box>
    </ContextTrigger>
  );
};

export default UsersListItem;
