/**
 * @module DateValue
 */

import { useRef, useState } from 'react';
import moment from 'moment';

import InputWrapper from '@old/components/common/InputWrapper';
import DatePicker from '@old/components/custom/DatePicker';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import { useOutsideClick } from '@old/hooks';
import ModalSimple from '@old/components/common/ModalSimple';
import config from '@old/config';
import { useShallowSelector } from 'old/hooks';

/**
 * @typedef {Object} DatePickerOptions
 * @property {Moment} minDate
 * @property {Moment} maxDate
 * @property {Boolean} defaultIsOpen
 */

/**
 * Component that display date picker dependent on window width
 *
 * @param  {String} [props.value]
 * @param  {String} [props.name]
 * @param  {String} [props.placeholder]
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 * @param  {DatePickerOptions} [props.pickerOptions] custom date picker options
 * @param {Function} props.onChange
 */
const DateValue = ({ value, name, placeholder, label, required, pickerOptions, onChange }) => {
  const [showPicker, setShowPicker] = useState(pickerOptions.defaultIsOpen);
  const inputRef = useRef();
  const isSmallScreen = useShallowSelector(({ app }) => app.isMobile || app.isTablet);

  useOutsideClick(inputRef, () => !isSmallScreen && setShowPicker(false));

  const onChangeDate = val => {
    onChange(val, name);
  };
  const icon = (
    <ButtonSimple tabIndex={-1} onClick={() => setShowPicker(prevShowPicker => !prevShowPicker)}>
      <Icon.CalendarShadow />
    </ButtonSimple>
  );
  const mobilePicker = (
    <DatePickerMobile
      key={value}
      value={value}
      onChange={onChangeDate}
      name={name}
      showPicker={showPicker}
      setShowPicker={setShowPicker}
      pickerOptions={pickerOptions}
    />
  );
  const desktopPicker = (
    <DatePickerDesktop
      value={value}
      onChange={onChangeDate}
      name={name}
      showPicker={showPicker}
      setShowPicker={setShowPicker}
      pickerOptions={pickerOptions}
    />
  );
  return (
    <InputWrapper relative icon={icon} label={label} required={required} ref={inputRef}>
      <input
        type="text"
        name={name}
        value={value}
        onChange={event => onChange(event.target.value)}
        placeholder={placeholder}
      />
      {isSmallScreen ? mobilePicker : desktopPicker}
    </InputWrapper>
  );
};

DateValue.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  label: '',
  required: false,
  pickerOptions: {},
};

export default DateValue;
/**
 * @module DatePickerMobile
 */

/**
 * @typedef  {Object} DatePickerOptions
 * @property {Moment} minDate
 * @property {Moment} maxDate
 * @property {Boolean} defaultIsOpen
 */

/**
 * Component that display date picker input adapted to mobile devices dependents on window width (less than 1200 px)
 *
 * @param  {String} [props.value]
 * @param  {Function} props.onChange
 * @param  {String} [props.name]
 * @param  {String} [props.placeholder]
 * @param  {Boolean} [props.showPicker]
 * @param  {Function} props.setShowPicker
 * @param  {DatePickerOptions} [props.pickerOptions] custom date picker options
 */
const DatePickerMobile = ({ value, onChange, name, showPicker, setShowPicker, pickerOptions }) => {
  const [selectedDay, setSelectedDay] = useState(
    value || pickerOptions?.defaultDate?.format(config.dateFormat) || moment().format(config.dateFormat)
  );
  const onSubmit = () => {
    onChange(selectedDay);
    setShowPicker(false);
  };

  return (
    <ModalSimple close={() => setShowPicker(false)} open={showPicker} small basic>
      <ModalSimple.Body>
        <DatePicker
          value={selectedDay}
          onChange={val => setSelectedDay(val, name)}
          close={() => {}}
          {...pickerOptions}
        />
      </ModalSimple.Body>
      <ModalSimple.Action close={() => setShowPicker(false)} onSubmit={onSubmit} />
    </ModalSimple>
  );
};

DatePickerMobile.defaultProps = {
  value: '',
  name: '',
  showPicker: false,
  pickerOptions: {},
};

/**
 * @module DatePickerDesktop
 */

/**
 * @typedef  {Object} DatePickerOptions
 * @property {Moment} minDate
 * @property {Moment} maxDate
 * @property {Boolean} defaultIsOpen
 */

/**
 * Component that display date picker input adapted to desktop dependents on window width (more than 1199 px)
 *
 * @param  {String} [props.value]
 * @param  {Function} props.onChange
 * @param  {String} [props.name]
 * @param  {String} [props.placeholder]
 * @param  {Boolean} [props.showPicker]
 * @param  {Function} props.setShowPicker
 * @param  {DatePickerOptions} [props.pickerOptions] custom date picker options
 */
const DatePickerDesktop = ({ value, onChange, name, pickerOptions, showPicker, setShowPicker }) => {
  return (
    showPicker && (
      <div className="input-picker-wrapper">
        <DatePicker
          value={value}
          onChange={val => onChange(val, name)}
          close={() => setShowPicker(false)}
          {...pickerOptions}
        />
      </div>
    )
  );
};

DatePickerDesktop.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  showPicker: false,
  pickerOptions: {},
};
