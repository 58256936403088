import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import PageTitle from '@old/components/guide/PageTitle';
import {
  notify,
  getChanges,
  getErrorMessage,
  mapFormStateToData,
  parseHTMLToMarkdown,
  parseMarkdownToHTML,
} from 'old/utils';
import t from 'resources/translations';
import { FORM_FARM_POLICY } from 'old/constants/formNames';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import Model from '@old/model';
import Spinner from '@old/components/common/Spinner';
import { Image, Text, Stack } from '@elements';
import { useShallowSelector } from '@old/hooks';
import config from '@old/config';
import { Wrap } from '@combinations';

const UpdateFarmPolicy = ({ history }) => {
  const [farmPolicy, setFarmPolicy] = useState(false);
  const [isPending, setPending] = useState(true);
  const { farm } = useShallowSelector(({ app }) => app);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPending(true);
        const result = await Model.Farms.getFarmPolicy();
        setFarmPolicy(result.content);
      } catch (e) {
        notify(getErrorMessage(e), { type: 'error' });
      } finally {
        setPending(false);
      }
    };
    fetchData();
  }, []);

  const formName = FORM_FARM_POLICY;

  const getInitValues = () => ({
    content: parseMarkdownToHTML(farmPolicy || ''),
  });

  const onSubmit = async formState => {
    const tmpValues = farmPolicy
      ? getChanges(formState, mapFormStateToData({ formState: getInitValues(), initValue: true }))
      : formState;
    const values = {
      ...tmpValues,
      content: parseHTMLToMarkdown(tmpValues.content || ''),
    };

    if (Object.keys(values).length === 0) {
      notify(t('message.noChange'), { type: 'warning', autoClose: 1500 });
      return;
    }

    try {
      await Model.Farms.updateFarmPolicy(values);
      notify(t('message.addingFarmPolicySuccess'));
      history.goBack();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  if (isPending) return <Spinner />;

  return (
    <>
      <PageTitle
        title={farmPolicy ? t('farmPolicyForm.updateFarmPolicy') : t('buttons.addFarmPolicy')}
        back
      />
      <Form formName={formName} initValues={getInitValues()}>
        <Field.RichTextEditor
          name="content"
          label={t('labels.policy')}
          placeholder={t('placeholders.content')}
          required
        />
        {farm?.isAdvancedPaymentEnabled() && (
          <Stack maxW="900px">
            <Text fontSize="sm" fontWeight="medium" color="grey-dark">
              {t('farmDetails.bmPayments')}
            </Text>
            <Wrap spacing="xs">
              {config.bmPaymentIcons.map((bmPayment, index) => (
                <Image key={index} src={bmPayment.image} width="50px" />
              ))}
            </Wrap>
          </Stack>
        )}
        <FormButton formName={formName} onClick={onSubmit}>
          {t('general.add')}
        </FormButton>
      </Form>
    </>
  );
};

UpdateFarmPolicy.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

export default withRouter(UpdateFarmPolicy);
