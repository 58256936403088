/* eslint-disable max-len */
import React from 'react';

const Link = () => {
  return (
    <svg width="26" viewBox="0 0 19.35 8.375">
      <g transform="translate(-262.566 -627.123)">
        <path d="M354.063,635.39a3.409,3.409,0,0,1-1.879.337c-.665-.016-1.332,0-2,0-.538,0-.835-.234-.837-.651s.3-.667.822-.668c.817,0,1.634-.021,2.449.009a1.111,1.111,0,0,0,1.061-.559,4.829,4.829,0,0,0-.1-4.725.986.986,0,0,0-.858-.435c-2.512.011-5.025.02-7.537-.006a1.136,1.136,0,0,0-1.111.666,4.815,4.815,0,0,0-.327,3.615.723.723,0,0,1-.22.876.635.635,0,0,1-.983-.33,5.733,5.733,0,0,1,.776-5.355,1.935,1.935,0,0,1,1.6-.78c2.651,0,5.3.006,7.952-.007a4.339,4.339,0,0,1,1.137.282l.64.64c-.029.177.143.243.212.366a6.173,6.173,0,0,1,.65,3.8,6.43,6.43,0,0,1-.809,2.283Z" transform="translate(-73.65 -0.233)" />
        <path d="M264.145,627.423c1.32-.519,2.7-.193,4.047-.262a.625.625,0,0,1,.6.644.641.641,0,0,1-.677.656c-.779.014-1.559.006-2.338.007-.993,0-1.257.153-1.578,1.1a4.68,4.68,0,0,0,.364,4.19.854.854,0,0,0,.785.422q3.884-.008,7.769,0a1.025,1.025,0,0,0,.936-.639,4.786,4.786,0,0,0,.371-3.54c-.023-.1-.059-.192-.076-.291a.639.639,0,0,1,.447-.766.62.62,0,0,1,.792.392,5.69,5.69,0,0,1-.826,5.446,1.878,1.878,0,0,1-1.518.711c-2.677,0-5.355,0-8.033,0a2.608,2.608,0,0,1-1.175-.387l-.693-.693c.06-.1-.032-.166-.068-.234a6.128,6.128,0,0,1-.291-5.109c.109-.309.273-.6.412-.9Z" />
      </g>
    </svg>
  );
};

export default Link;
/* eslint-enable max-len */
