import { Text } from '../../elements';
import { TTextProps } from 'resources/types/elementsTypes';
import { TReactNode } from 'resources/types/commonTypes';

const labelStyles: TTextProps = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

type TTagLabel = {
  children: TReactNode;
};

const TagLabel = ({ children }: TTagLabel) => {
  return <Text {...labelStyles}>{children}</Text>;
};

export default TagLabel;
