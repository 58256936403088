import moment from 'moment';
import sum from 'lodash/sum';
import groupBy from 'lodash/groupBy';
import truncate from 'lodash/truncate';

import { EventType } from '@old/model/EventType';
import { Horse } from '@old/model/Horse';
import { Member } from '@old/model/Member';
import { Place } from '@old/model/Place';
import config from '@old/config';
import { DATETIME_FORMAT, DATE_FORMAT } from 'old/constants/dateFormats';

import BaseModel from '@old/model/BaseModel';
import Participation from '@old/model/Participation';

class Events extends BaseModel {
  constructor(options) {
    super({
      modelName: 'events',
      updateKey: 'event',
      basePath: '/api/v1/farms/{farmId}/events',
      ItemClass: Event,
      ...options,
    });
  }

  // override BaseModel.fetchAll()
  fetchAll = async ({ mine, cancelToken, ...params }) => {
    if (mine) {
      return this.fetchUserEvents({ cancelToken, ...params });
    }

    const response = await this.client.get(this.basePath, { cancelToken, params });
    return [response.data.events.map(item => this.parse(item)), response.data.pagination];
  };

  fetchUserEvents = async ({ cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/mine`, { cancelToken, params });
    return [response.data.events.map(item => this.parse(item)), response.data.pagination];
  };

  fetchCountEvents = async ({ cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/daily`, { cancelToken, params });
    return response.data;
  };

  cancel = async (eventId) => {
    const response = await this.client.patch(`${this.basePath}/${eventId}/cancel`);
    return response.data;
  };

  activate = async (eventId) => {
    const response = await this.client.patch(`${this.basePath}/${eventId}/activate`);
    return response.data;
  };

  reject = async (eventId) => {
    const response = await this.client.put(`${this.basePath}/${eventId}/reject`);
    return response.data;
  };

  join = async (eventId) => {
    const response = await this.client.put(`${this.basePath}/${eventId}/join`);
    return response.data;
  };

  unjoin = async (eventId) => {
    const response = await this.client.put(`${this.basePath}/${eventId}/unjoin`);
    return response.data;
  };

  invite = async (eventId, participantsIds) => {
    const response = await this.client.post(`${this.basePath}/${eventId}/invite`, { membership_ids: participantsIds });
    return response.data;
  };

  addParticipants = async (eventId, participantsIds) => {
    const response = await this.client.post(`${this.basePath}/${eventId}/add`, { membership_ids: participantsIds });
    return response.data;
  };

  getParticipations = async ({ eventId, ...params }) => {
    const response = await this.client.get(`${this.basePath}${eventId}/participations`, { params });
    const participations = response.data.participations ? response.data.participations.map(
      participation => new Participation(participation),
    ) : [];

    return [participations, response.data.pagination];
  };

  acceptRequestedInvitation = async (eventId, participationId) => {
    const response = await this.client.put(`${this.basePath}/${eventId}/participations/${participationId}/accept`);
    return response.data;
  };

  rejectRequestedInvitation = async (eventId, participationId) => {
    const response = await this.client.put(`${this.basePath}/${eventId}/participations/${participationId}/reject`);
    return response.data;
  };

  absentParticipation = async (eventId, participationId) => {
    const response = await this.client.patch(`${this.basePath}/${eventId}/participations/${participationId}/absent`);
    return response.data;
  };

  presentParticipation = async (eventId, participationId) => {
    const response = await this.client.patch(`${this.basePath}/${eventId}/participations/${participationId}/present`);
    return response.data;
  };

  setPaymentParticipation = async (eventId, participationId, params) => {
    const setPaymentPath = `${this.basePath}/${eventId}/participations/${participationId}/set_payment`;
    const response = await this.client.patch(setPaymentPath, { participation: params });
    return response.data;
  };

  setPaymentParticipations = async (eventId, participation) => {
    const setPaymentPath = `${this.basePath}/${eventId}/participations/batch_payment`;
    const response = await this.client.patch(setPaymentPath, { participation });
    return response.data;
  };

  attachHorse = async (eventId, participationId, horseId) => {
    const attachHorsePath = `${this.basePath}/${eventId}/participations/${participationId}/attach_horse`;
    const response = await this.client.patch(attachHorsePath, { horse_id: horseId });
    return response.data;
  };

  detachHorse = async (eventId, participationId) => {
    const detachHorsePath = `${this.basePath}/${eventId}/participations/${participationId}/detach_horse`;
    const response = await this.client.patch(detachHorsePath);
    return response.data;
  };

  getChangeHistory = async (eventId, { cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/${eventId}/versions`, { params, cancelToken });
    const versions = response.data.versions.map(version => ({
      id: version.id,
      model: version.model,
      action: version.action,
      moderator: version.moderator ? new Member(version.moderator, { fromUserData: true }) : new Member(),
      member: version.membership ? new Member(version.membership) : null,
      createdAt: moment(version.created_at),
      changes: version.changes,
    }));

    return [versions, response.data.pagination];
  };

  fetchPosts = async (eventId, { cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/${eventId}/posts`, { params, cancelToken });
    const posts = (response.data.posts || []).map(post => ({
      id: post.id,
      content: post.content,
      author: post.author && new Member(post.author),
      createdAt: moment(post.created_at),
      comments: (post.comments || []).map(comment => ({
        id: comment.id,
        content: comment.content,
        createdAt: moment(comment.created_at),
        author: comment.author && new Member(comment.author),
      })),
    }));
    return [posts, response.data.pagination];
  };

  createPost = async (eventId, params) => {
    const response = await this.client.post(`${this.basePath}/${eventId}/posts`, { post: params });
    return response.data;
  };

  updatePost = async (eventId, postId, params) => {
    const response = await this.client.patch(`${this.basePath}/${eventId}/posts/${postId}`, { post: params });
    return response.data;
  };

  deletePost = async (eventId, postId) => {
    const response = await this.client.delete(`${this.basePath}/${eventId}/posts/${postId}`);
    return response.data;
  };

  createComment = async (eventId, postId, params) => {
    const createCommentPath = `${this.basePath}/${eventId}/posts/${postId}/comments`;
    const response = await this.client.post(createCommentPath, { comment: params });
    return response.data;
  };

  updateComment = async (eventId, postId, commentId, params) => {
    const updateCommentPath = `${this.basePath}/${eventId}/posts/${postId}/comments/${commentId}`;
    const response = await this.client.patch(updateCommentPath, { comment: params });
    return response.data;
  };

  deleteComment = async (eventId, postId, commentId) => {
    const response = await this.client.delete(`${this.basePath}/${eventId}/posts/${postId}/comments/${commentId}`);
    return response.data;
  };

  mapFiltersData = (dataFilters) => {
    const activeFilters = {
      with_event_type: dataFilters.eventType,
      with_difficulty: dataFilters.difficulty,
      participated_by: dataFilters.participants,
      with_horse: dataFilters.horses,
      with_special: dataFilters.special && [dataFilters.special === 'special'],
      start_after: dataFilters.startAfter,
      end_before: dataFilters.endBefore,
      instructed_by: dataFilters.instructor,
    };

    Object.keys(activeFilters).forEach((key) => {
      if (activeFilters[key] === undefined) delete activeFilters[key];
    });

    return activeFilters;
  };
}

class Event {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.visibility = data.visibility === 'public';
    this.startDate = moment(data.start_at);
    this.endDate = moment(data.end_at);
    this.difficulty = data.difficulty;
    this.special = data.special;
    this.attendees = { min: data.attendees_min, max: data.attendees_max };
    this.instructors = data.instructors
      ? data.instructors.map(instructor => new Member(instructor)) : [];
    this.creator = new Member(data.creator || {});
    this.places = data.places ? data.places.map(place => new Place(place)) : [];
    this.horses = data.horses ? data.horses.map(horse => new Horse(horse)) : [];
    this.type = data.type ? new EventType(data.type) : null;
    this.participations = data.participations
      ? data.participations.map(participation => new Participation(participation))
      : [];
    this.isAwaiting = data.status === 'awaiting';
    this.isActive = data.status === 'active';
    this.isOngoing = data.status === 'ongoing';
    this.isFinished = data.status === 'finished';
    this.isCancelled = data.status === 'cancelled';
    this.status = data.status || null;
    this.edited = data.edited;
    this.privileges = data.meta ? data.meta.privileges : [];
    this.unjoinTimeout = data.unjoin_timeout || 0;
    this.dueAmount = data.due_amount;
    this.paidAmount = data.paid_amount;
    this.deleted = data.deleted;
    this.createdAt = moment(data.created_at);
  }

  static mapToSaveData = (data) => {
    const event = {
      name: data.eventName,
      description: data.eventDescription,
      start_at: data.startDate && moment(data.startDate, DATETIME_FORMAT).toISOString(),
      end_at: data.endDate && moment(data.endDate, DATETIME_FORMAT).toISOString(),
      difficulty: data.difficulty,
      special: data.special,
      attendees_min: data.attendees && data.attendees[0],
      attendees_max: data.attendees && data.attendees[1],
      event_type_id: data.type,
      instructor_ids: data.instructors,
      place_ids: data.places && [data.places], // fixme
      horse_ids: data.horses,
      added_participant_ids: data.participants,
      participant_ids: data.inviteParticipants ? data.inviteParticipants.filter(
        participantId => !data.participants.includes(participantId),
      ) : data.inviteParticipants,
      edited: data.edited,
    };

    if (data.visibility !== undefined) {
      event.visibility = data.visibility === 'true' ? 'public' : 'private';
    }

    Object.keys(event).forEach((fieldName) => {
      if (event[fieldName] === undefined) delete event[fieldName];
    });

    return event;
  };

  static mapToFormData = (data) => {
    const eventData = {
      eventName: data.name,
      eventDescription: data.description,
      startDate: data.getStartDate(),
      endDate: data.getEndDate(),
      type: {
        value: data.type.id,
        label: data.type.getName(),
        timeIntervals: data.type.timeIntervals,
        eventCost: data.type.defaultEventCost,
      },
      places: data.getPlacesOption(),
      horses: data.getHorsesOptions(),
      participants: data.getJoinedParticipantsOptions(false),
      inviteParticipants: data.getInvitedParticipantsOptions(false),
      difficulty: config.difficulty.find(d => d.value === String(data.difficulty)),
      instructors: data.getInstructorsOptions(false),
      visibility: String(data.visibility),
      special: String(data.special),
      attendees: Object.values(data.attendees),
    };
    return eventData;
  };

  delete = async () => this.model.delete(this.id);

  update = async changes => this.model.update(this.id, changes);

  cancel = async () => this.model.cancel(this.id);

  addParticipants = async participantsIds => this.model.addParticipants(this.id, participantsIds);

  invite = async participantsIds => this.model.invite(this.id, participantsIds);

  absentParticipation = async participationId => this.model.absentParticipation(this.id, participationId);

  presentParticipation = async participationId => this.model.presentParticipation(this.id, participationId);

  isInstructedByMember(memberId) {
    return (this.instructors || []).map(instructor => instructor.id).includes(memberId);
  }

  isEdited() {
    return this.edited;
  }

  getStatus() {
    return this.status;
  }

  getFreeHorses() {
    const participations = this.getParticipations(true);
    return this.getHorses().filter(
      horse => !participations.map(
        participant => participant.horse && participant.horse.id,
      ).includes(horse.id),
    );
  }

  getInstructors(withDeleted) {
    return (this.instructors || []).filter(instructor => (
      (withDeleted || !instructor.isDeleted())
    ));
  }

  getHorses() {
    return this.horses || [];
  }

  getHorsesIds() {
    return this.getHorses().map(horse => horse.id);
  }

  getPlaces() {
    return this.places || [];
  }

  getCreatorId() {
    return this.creator.id;
  }

  getInstructorsIds() {
    return this.getInstructors().map(instructor => instructor.id);
  }

  getParticipations(withDeleted) {
    return (this.participations || []).filter(participation => (
      (withDeleted || !participation.member.isDeleted())
    ));
  }

  getParticipants(withDeleted) {
    return this.getParticipations(withDeleted).map(participation => participation.member);
  }

  getParticipantsIds(withDeleted) {
    return this.getParticipations(withDeleted)
      .filter(participation => participation.status !== 'rejected')
      .map(participation => participation.member.id);
  }

  getParticipationsByStatus() {
    return groupBy(this.getParticipations(), participation => participation.status);
  }

  getInvitationRequestedParticipations(withDeleted = true) {
    return this.getParticipations(withDeleted).filter(participation => (
      participation.status === 'invitation_requested'
    ));
  }

  getInvitationRequestedParticipants(withDeleted = true) {
    return this.getInvitationRequestedParticipations(withDeleted).map(participation => participation.member);
  }

  getInvitationRequestedParticipantsOptions(withDeleted = true) {
    return this.getInvitationRequestedParticipants(withDeleted).map(this.mapToOption);
  }

  getInvitationRequestedParticipantsIds(withDeleted = true) {
    return this.getInvitationRequestedParticipations(withDeleted).map(participation => participation.member.id);
  }

  getInvitedParticipations(withDeleted = true) {
    return this.getParticipations(withDeleted).filter(participation => (
      participation.status === 'invited'
    ));
  }

  getInvitedParticipants(withDeleted = true) {
    return this.getInvitedParticipations(withDeleted).map(participation => participation.member);
  }

  getRejectedParticipants(withDeleted = true) {
    return this.getRejectedParticipations(withDeleted).map(participation => participation.member);
  }

  getRejectedParticipations(withDeleted = true) {
    return this.getParticipations(withDeleted).filter(participation => (
      participation.status === 'rejected'
    ));
  }

  getInvitedParticipantsOptions(withDeleted = true) {
    return this.getInvitedParticipants(withDeleted).map(this.mapToOption);
  }

  getInvitedParticipantsIds(withDeleted = true) {
    return this.getInvitedParticipations(withDeleted).map(participation => participation.member.id);
  }

  getJoinedParticipations(withDeleted = true) {
    return this.getParticipations(withDeleted).filter(participation => (
      participation.status === 'joined'
    ));
  }

  getJoinedParticipants(withDeleted = true) {
    return this.getJoinedParticipations(withDeleted).map(participation => participation.member);
  }

  getJoinedParticipantsOptions(withDeleted = true) {
    return this.getJoinedParticipants(withDeleted).map(this.mapToOption);
  }

  getJoinedParticipantsIds(withDeleted = true) {
    return this.getJoinedParticipations(withDeleted).map(participation => participation.member.id);
  }

  isJoinedParticipant(memberId) {
    return this.getJoinedParticipantsIds().includes(memberId);
  }

  mapToOption = item => ({
    value: item.id,
    label: item.getName(),
  });

  getParticipantsOptions(withDeleted = true) {
    const joinedParticipants = this.getJoinedParticipants(withDeleted).map(this.mapToOption);
    const invitedParticipants = this.getInvitedParticipants(withDeleted).map(this.mapToOption);
    const rejectedParticipants = this.getRejectedParticipants(withDeleted).map(this.mapToOption);
    const invitationRequestedParticipants = this.getInvitationRequestedParticipants(
      withDeleted,
    ).map(this.mapToOption);
    return [...joinedParticipants, ...invitedParticipants, ...invitationRequestedParticipants, ...rejectedParticipants];
  }

  getHorsesOptions() {
    return this.getHorses().map(item => ({ ...this.mapToOption(item), fatigue: this.fatigue }));
  }

  getPlacesOption() {
    return this.getPlaces().map(this.mapToOption)[0];
  }

  getInstructorsOptions(withDeleted = true) {
    return this.getInstructors(withDeleted).map(this.mapToOption);
  }

  getDifficulty() {
    return this.difficulty || 'missing';
  }

  getName(limit = false) {
    return limit ? truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getStartDate(format = config.dateTimeFormat) {
    return this.startDate.format(format);
  }

  getCreatedAtDate(format = config.dateTimeFormat) {
    return this.createdAt.format(format);
  }

  getEndDate(format = config.dateTimeFormat) {
    return this.endDate.format(format);
  }

  getStartDateTime() {
    return this.getStartDate(config.dateTimeFormat);
  }

  getCreatedAtDateTime() {
    return this.getCreatedAtDate(config.dateTimeFormat);
  }

  getEndDateTime() {
    return this.getEndDate(config.dateTimeFormat);
  }

  isCreatedBeforeBillingRelease() {
    return moment(this.getCreatedAtDateTime(), config.dateTimeFormat).isBefore(config.releaseBillingDate);
  }

  getFullDate() {
    return this.startDate.isSame(this.endDate, 'day')
      ? `${this.startDate.format(DATE_FORMAT)} ${this.startDate.format('HH:mm')} - ${this.endDate.format('HH:mm')}`
      : `${this.startDate.format(DATETIME_FORMAT)} - ${this.endDate.format(DATETIME_FORMAT)}`;
  }

  getStatusesParticipant(id) {
    const userParticipation = this.participations.find(participation => participation.member.id === id);
    if (!userParticipation) {
      return {
        currentStatus: null,
        isInvited: false,
        isJoined: false,
        isRequested: false,
      };
    }

    const isInvited = userParticipation.status === 'invited';
    const isJoined = userParticipation.status === 'joined';
    const isRequested = userParticipation.status === 'invitation_requested';

    return { currentStatus: userParticipation.status, isInvited, isJoined, isRequested };
  }

  getStatuses(memberId) {
    const userParticipation = this.participations.find(participation => participation.member.id === memberId);
    const instructorIds = this.getInstructorsIds();
    return {
      memberIsJoined: userParticipation ? userParticipation.status === 'joined' : false,
      memberIsInvited: userParticipation ? userParticipation.status === 'invited' : false,
      memberRequestInvitation: userParticipation ? userParticipation.status === 'invitation_requested' : false,
      memberIsEventInstructor: instructorIds.includes(memberId),
      isFinished: this.isFinished,
      isOngoing: this.isOngoing,
      isAwaiting: this.isAwaiting,
      isFull: this.isFull,
      isActive: this.isActive,
      isCancelled: this.isCancelled,
      isProposed: this.status === 'proposed',
    };
  }

  memberPartcipates(memberId) {
    return this.getJoinedParticipations().map(participation => participation.member.id).includes(memberId);
  }

  getParticipantsByStatus(status) {
    const participations = this.participations.filter(participation => participation.status === status);
    return participations;
  }

  getStatusBarProps() {
    return config.eventStatusProps[this.status || 'none'];
  }

  getPresentParticipants() {
    if (this.getProgress() < 1) {
      const participants = this.participations.filter(participation => participation.status === 'joined');
      const presentParticipation = participants.filter(participation => participation.attendance === 'attending');

      return `${presentParticipation.length}/${participants.length}`;
    }
    return null;
  }

  getParticipantsByAttendanceAndPaid(attendance) {
    return this.getJoinedParticipations()
      .filter(participation => participation.attendance === attendance || participation.paymentStatus !== 'unpaid');
  }

  getPlace() {
    return this.places[0] || null;
  }

  getDuration() {
    return this.endDate.diff(this.startDate, 'minutes');
  }

  getTypeName() {
    return this.type ? this.type.getName() : '';
  }

  getTotalPaidAmount() {
    return sum(this.getJoinedParticipations().map(participation => (participation.paidAmount || 0)));
  }

  hasUnpaidParticipation() {
    return this.getParticipantsByAttendanceAndPaid('attending')
      .some(participation => participation.paymentStatus === 'unpaid');
  }

  getNumberOfParticipations(filter = null) {
    return filter ? this.getJoinedParticipations().filter(filter).length : this.participations.length;
  }

  getTotalParticipantsTime() {
    return this.getNumberOfParticipations(
      participation => participation.attendance === 'attending',
    ) * this.getDuration();
  }

  getSlugInfo() {
    const slugs = {};
    const place = this.getPlace();
    const difficulty = config.difficulty.find(d => this.difficulty === d.value);

    if (place) {
      slugs.place = {
        name: place.getName(),
        color: place.getColor(),
        slug: place.slug,
      };
    }

    if (this.type) {
      slugs.type = {
        name: this.type.getName(),
        color: this.type.getColor(),
        slug: this.type.slug,
      };
    }

    if (difficulty) {
      slugs.difficulty = {
        name: difficulty.label,
        color: difficulty.color,
        slug: difficulty.slug,
      };
    }

    return slugs;
  }

  getAttendeesMax() {
    return this.attendees.max || 0;
  }

  getAttendeesMin() {
    return this.attendees.min || 0;
  }

  isPast() {
    return moment(this.getEndDateTime(), DATETIME_FORMAT).isBefore(moment());
  }

  getPermission(permissionKey) {
    return this.privileges.includes(permissionKey);
  }

  canUnjoin() {
    if (this.isPast()) return false;
    const timeToEvent = this.startDate.diff(moment(), 'seconds');
    if (timeToEvent > this.unjoinTimeout) {
      return false;
    }
    return true;
  }

  isDeleted() {
    return this.deleted || false;
  }

  getUrl() {
    return `/events/${this.id}`;
  }

  setParticipation(participationId, newParticipations) {
    const index = this.participations.findIndex(p => p.id === participationId);

    if (index === -1) return this;
    this.participations[index] = newParticipations;
    return this;
  }

}

export { Event, Events };
