import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import cls from 'classnames';
import Measure from 'react-measure';
import { extendMoment } from 'moment-range';
import groupBy from 'lodash/groupBy';

import t from 'resources/translations';
import config from '@old/config';
import ImageCircle from '@old/components/guide/ImageCircle';
import TagCircle from '@old/components/common/TagCircle';
import FlexColumn from '@old/components/common/FlexColumn';
import Center from '@old/components/common/Center';
import Icon from '@old/components/icon';
import Popover from '@old/components/common/Popover';
import Divider from '@old/components/common/Divider';
import ButtonSimple from '@old/components/guide/ButtonSimple';

const moment = extendMoment(Moment);

const getContextPosition = position => {
  let xHeight = position.top < 0 ? position.height + position.top : position.height;
  const xTop = position.top < 0 ? 0 : position.top;
  if (xTop + xHeight > position.maxElementHeight) {
    xHeight = position.maxElementHeight - xTop;
  }

  return [xHeight, xTop];
};

export const getRangeLabel = (start, end) => {
  if (start.isSame(end, 'day')) {
    return `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
  }

  return `${start.format('HH:mm DD.MM')} - ${end.format('HH:mm DD.MM')}`;
};

export const mapEventToCalendarItem = (event, dayStart) => {
  const { startDate, endDate } = event;
  const difficulty = config.difficulty.find(d => d.value === String(event.difficulty)) || config.difficulty[0];
  const placeColor = event.places[0] ? event.places[0].getColor() : '';
  const typeColor = event.type ? event.type.getColor() : '';
  const skillColor = difficulty.color;
  const confirmedParticipants = event.participations.filter(participation => participation.status === 'joined');
  const { true: pairedParticipants = [] } = groupBy(confirmedParticipants, item => !!item.horse);
  const pairedParticipantsUserIds = pairedParticipants.map(item => item.member.id);
  const pairedParticipantsHorseIds = pairedParticipants.map(item => item.horse.id);
  const instructor = event.instructors ? event.instructors[0] : null;

  return {
    range: dayStart.isBefore(startDate) ? moment.range(startDate, endDate) : moment.range(dayStart, endDate),
    rangeLabel: getRangeLabel(startDate, endDate),
    name: event.name,
    edited: event.isEdited(),
    type: event.type ? { color: typeColor, text: event.type.slug } : { color: 'transparent' },
    place: event.places[0] ? { color: placeColor, text: event.places[0].slug } : { color: 'transparent' },
    numberOfConfirmations: `${confirmedParticipants.length}/${event.attendees.max}`,
    id: event.id,
    difficulty: { color: skillColor, text: difficulty.slug },
    avatar: instructor ? instructor.getAvatar('thumb') : '/img/user-avatar-placeholder.png',
    horses: event.horses.filter(h => !pairedParticipantsHorseIds.includes(h.id)).map(horse => horse.getName()),
    participants: confirmedParticipants
      .filter(p => !pairedParticipantsUserIds.includes(p.member.id))
      .map(({ member }) => member.getName()),
    pairedParticipants: pairedParticipants.map(item => [item.horse.getName(), item.member.getName()]),
    status: event.status,
  };
};

export const CalendarItem = ({ item, history, index, ...position }) => {
  const { rangeLabel, id } = item;
  const eventPath = `/events/${id}`;
  const [xHeight, xTop] = getContextPosition(position);

  return (
    <div className="calendar-element" style={{ height: xHeight, top: `${xTop + 31}px`, zIndex: index + 2 }}>
      <Popover
        content={
          <div className="text-center calendar-item-popup w-64">
            <CalendarItemContent
              height={xHeight}
              item={item}
              rangeLabel={rangeLabel}
              eventPath={eventPath}
              history={history}
              popup
            />
          </div>
        }
      >
        <CalendarItemContent height={xHeight} item={item} rangeLabel={rangeLabel} eventPath={eventPath} />
      </Popover>
    </div>
  );
};

CalendarItem.propTypes = {
  height: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    rangeLabel: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export const CalendarItemContent = ({ rangeLabel, eventPath, history, height, onBlur, popup, item }) => {
  const { participants, horses, type, place, edited, difficulty, numberOfConfirmations, pairedParticipants, avatar } =
    item;

  const [contentDimensions, setContentDimensions] = useState({
    width: -1,
    height: -1,
  });
  const [wrapperDimensions, setWrapperDimensions] = useState({
    width: -1,
    height: -1,
  });
  const eventEdited = edited === true;
  const small = wrapperDimensions.height === 0 || wrapperDimensions.height === 1;
  const smallWidth = contentDimensions.width < 138;
  const headerColor = config.eventStatusProps[item.status || 'none'].color;

  return (
    <React.Fragment>
      <div className={cls('calendar-item-header', headerColor)}>{rangeLabel}</div>
      <Measure bounds onResize={contentRect => setWrapperDimensions(contentRect.bounds)}>
        {({ measureRef: wrapperRef }) => {
          return (
            <div
              ref={wrapperRef}
              onBlur={onBlur}
              className="calendar-item-content"
              style={{
                backgroundColor: 'white',
                borderLeft: '1px solid #d1d0d0',
                borderRight: '1px solid #d1d0d0',
                borderBottom: small ? 'none' : '1px solid #d1d0d0',
                borderTop: !popup ? 'none' : '1px solid #d1d0d0',
                height: 'calc(100% - 15px)',
              }}
            >
              {(height >= 75 || popup) && (
                <Measure bounds onResize={contentRect => setContentDimensions(contentRect.bounds)}>
                  {({ measureRef }) => {
                    return (
                      <div ref={measureRef} style={{ backgroundColor: 'white', overflow: 'hidden' }}>
                        <FlexColumn separated="small">
                          {smallWidth && (
                            <Center>
                              <ImageCircle src={avatar} className="calendar-item-image" alt="calendar" small />
                            </Center>
                          )}
                          <div
                            className={cls('flex justify-around items-center flex-wrap', !smallWidth ? 'mt-2' : 'mt-0')}
                          >
                            <TagCircle small color={type.color}>
                              {type.text}
                            </TagCircle>
                            <TagCircle small color={place.color}>
                              {place.text}
                            </TagCircle>
                            {!smallWidth && (
                              <ImageCircle src={avatar} className="calendar-item-image" alt="calendar" small />
                            )}
                            <TagCircle small color={difficulty.color}>
                              {difficulty.text}
                            </TagCircle>
                            {!smallWidth && (
                              <TagCircle color="white" small>
                                {numberOfConfirmations}
                              </TagCircle>
                            )}
                          </div>
                          {contentDimensions.width > 230 && (
                            <ParticipantsColumnsList lists={{ pairedParticipants, horses, participants }} />
                          )}
                          {contentDimensions.width < 231 && (
                            <React.Fragment>
                              <ParticipantsVerticalList
                                items={[...participants, ...pairedParticipants.map(([_, member]) => member)]}
                              />
                              {((participants.length > 0 && horses.length > 0) || pairedParticipants.length > 0) && (
                                <Divider />
                              )}
                              <ParticipantsVerticalList
                                items={[...horses, ...pairedParticipants.map(([horse]) => horse)]}
                              />
                            </React.Fragment>
                          )}
                          {popup && (
                            <div>
                              <div className="absolute text-teal" style={{ bottom: '5px', right: '10px' }}>
                                {eventEdited && <Icon.Warning />}
                              </div>
                              <ButtonSimple onClick={() => history.push(eventPath)} fluid>
                                <div className="text-link uppercase mb-2 mt-2 block">{t('general.details')}</div>
                              </ButtonSimple>
                            </div>
                          )}
                        </FlexColumn>
                      </div>
                    );
                  }}
                </Measure>
              )}
              {contentDimensions.width > 231 && eventEdited && (
                <div className="absolute text-teal bottom-1 right-2" title={t('eventListItem.editedEvent')}>
                  <Icon.Warning />
                </div>
              )}
              {height < 75 && !popup && <div style={{ overflow: 'hidden', maxHeight: '100%' }}>...</div>}
            </div>
          );
        }}
      </Measure>
    </React.Fragment>
  );
};

CalendarItemContent.defaultProps = {
  onBlur: () => { },
  popup: false,
  history: {},
};

CalendarItemContent.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.shape({
      color: PropTypes.string.isRequired,
      text: PropTypes.string,
    }).isRequired,
    place: PropTypes.shape({
      color: PropTypes.string.isRequired,
      text: PropTypes.string,
    }).isRequired,
    difficulty: PropTypes.shape({
      color: PropTypes.string.isRequired,
      text: PropTypes.string,
    }).isRequired,
    numberOfConfirmations: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    participants: PropTypes.arrayOf(PropTypes.string),
    horses: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.oneOf(['none', 'awaiting', 'active', 'ongoing', 'finished', 'cancelled', 'proposed']).isRequired,
    edited: PropTypes.bool.isRequired,
    pairedParticipants: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }).isRequired,
  rangeLabel: PropTypes.string.isRequired,
  eventPath: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  onBlur: PropTypes.func,
  popup: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const ParticipantsVerticalList = ({ items }) => {
  if (items.length === 0) return null;

  return (
    <div style={{ fontSize: 10 }} className="leading-none">
      {items.map((item, i) => (
        <div key={i}>{item}</div>
      ))}
    </div>
  );
};

ParticipantsVerticalList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const ParticipantsColumnsList = ({ lists: { pairedParticipants, horses, participants } }) => {
  if (horses.length === 0 && participants.length === 0 && pairedParticipants.length === 0) return null;

  return (
    <div
      style={{
        display: 'grid',
        gridTemplate: '1fr / calc(50% - 15px) 30px calc(50% - 15px)',
        alignItems: 'center',
        fontSize: 10,
      }}
    >
      <React.Fragment>
        <span className="whitespace-no-wrap text-teal-dark font-bold" style={{ justifySelf: 'right' }}>
          {t('general.participants')}
        </span>
        <div />
        <span className="whitespace-no-wrap text-teal-dark font-bold" style={{ justifySelf: 'left' }}>
          {t('model.horses')}
        </span>
      </React.Fragment>
      {pairedParticipants.map(([horseName, userName], index) => (
        <ParticipantItem key={index} userName={userName} horseName={horseName} linked />
      ))}
      {horses.length > participants.length &&
        horses.map((horseName, index) => (
          <ParticipantItem key={index} userName={participants[index]} horseName={horseName} />
        ))}
      {horses.length <= participants.length &&
        participants.map((userName, index) => (
          <ParticipantItem key={index} userName={userName} horseName={horses[index]} />
        ))}
    </div>
  );
};

ParticipantsColumnsList.propTypes = {
  lists: PropTypes.shape({
    pairedParticipants: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    horses: PropTypes.arrayOf(PropTypes.string).isRequired,
    participants: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

const ParticipantItem = ({ userName = '', horseName = '', linked }) => {
  return (
    <React.Fragment>
      <span className="whitespace-no-wrap" style={{ justifySelf: 'right' }}>
        {userName}
      </span>
      {linked ? (
        <div style={{ justifySelf: 'center' }}>
          <Icon.Link small />
        </div>
      ) : (
        <div />
      )}
      <span className="whitespace-no-wrap" style={{ justifySelf: 'left' }}>
        {horseName}
      </span>
    </React.Fragment>
  );
};

ParticipantItem.defaultProps = {
  userName: '',
  horseName: '',
  linked: false,
};

ParticipantItem.propTypes = {
  userName: PropTypes.string,
  horseName: PropTypes.string,
  linked: PropTypes.bool,
};

export const SpecialItem = ({ item: { rangeLabel }, type, ...position }) => {
  const [xHeight, xTop] = getContextPosition(position);
  const backgroundColor = type === 'absence' ? '#FAD1D1' : '#CDEED9';

  return (
    <div className="calendar-element" style={{ height: `${xHeight + 1}px`, top: `${xTop + 30}px` }}>
      <div className="relative p-1" style={{ backgroundColor, height: '100%' }}>
        {rangeLabel}
      </div>
    </div>
  );
};
