/**
 * @module OverflowItem
 */
import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
/**
 * Generates absolute block on top right side of the parent block with children that in wrappes with option to show it only on hover
 * @param  {Node} props.children
 * @param  {Boolean} [props.onHover] specifies wether it should be shown on hover only
 * @param  {Boolean} [props.noPadding] specifies wether there should be padding
 */
const OverflowItem = ({ children, onHover, noPadding }) => {
  return (
    <div
      style={{ zIndex: 10 }}
      className={cls('absolute top-0 right-0 item-overflow', { hide: onHover, 'p-4': !noPadding })}
    >
      {children}
    </div>
  );
};

OverflowItem.defaultProps = {
  onHover: false,
  noPadding: false,
};

OverflowItem.propTypes = {
  children: PropTypes.node.isRequired,
  onHover: PropTypes.bool,
  noPadding: PropTypes.bool,
};

export default OverflowItem;
