import { RouterLink, Span } from '../elements';
import { ILinkStyledProps } from 'resources/types/styledTypes';
import { pathIsAllowed } from 'old/utils';
import { LinkProps } from 'react-router-dom';

type TLink = ILinkStyledProps & {
  children: React.ReactNode;
  isExternal?: boolean;
  allowed?: boolean;
  forceRender?: boolean;
  to:
    | Extract<LinkProps, 'to'>
    | string
    | {
        pathname: string;
        state: object;
      };
};

const Link = ({ children, to, isExternal, allowed, forceRender, ...aStyleProps }: TLink) => {
  if (isExternal || allowed || pathIsAllowed(typeof to === 'object' ? to.pathname : to)) {
    return (
      <RouterLink to={to} {...aStyleProps}>
        {children}
      </RouterLink>
    );
  }

  return forceRender ? <Span>{children}</Span> : null;
};

export default Link;
