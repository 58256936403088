
import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import Center from '@old/components/common/Center';
import BoxShadow from '@old/components/common/BoxShadow';
import FlexRow from '@old/components/common/FlexRow';
import RowAligner from '@old/components/common/RowAligner';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import t from 'resources/translations';

const TimePickerSpinner = ({ value, defaultTime, onChange, header }) => {
  const timeArray = (value || defaultTime).split(':');
  const [hours, setHours] = useState(timeArray[0]);
  const [minutes, setMinutes] = useState(timeArray[1]);
  useEffect(() => {
    onChange(`${hours}:${minutes}`);
  }, [hours, minutes]); // eslint-disable-line
  const onChangeHours = (val) => {
    setHours(val);
  };
  const onChangeMinutes = (val) => {
    setMinutes(val);
  };

  return (
    <BoxShadow className="timepicker-spin">
      <Center className="bg-teal relative rounded text-md-white py-1 px-2">
        {header || t('timePickerInput.centralEuropeanTime')}
      </Center>
      <FlexRow noShrink stretched separated="small">
        <div className="timepicker-spin-container">
          <RowAligner className="h-full justify-center text-grey">
            <Spinner initValue={timeArray[0]} max={23} onChange={onChangeHours} />
            <div className="font-bold text-lg text-grey-dark">:</div>
            <Spinner initValue={timeArray[1]} max={59} onChange={onChangeMinutes} />
          </RowAligner>
        </div>
      </FlexRow>
    </BoxShadow>
  );
};

TimePickerSpinner.defaultProps = {
  value: null,
  defaultTime: '12:30',
  header: '',
};

TimePickerSpinner.propTypes = {
  defaultTime: PropTypes.string,
  value: PropTypes.string,
  header: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TimePickerSpinner;

const Spinner = ({ initValue, onChange, max }) => {
  const [activeIndex, setActiveIndex] = useState();
  const pickerRef = useRef();
  const counts = max + 5;
  const size = 40;
  const offset = 2;
  const arrOfElements = useMemo(() => ([...Array(counts)]), [counts]);
  useEffect(() => {
    const { current } = pickerRef;
    const setInitValues = () => {
      const initNumber = parseInt(initValue, 10);
      const newScrollPos = initNumber * size;
      if (newScrollPos === 0) {
        setActiveIndex(0);
      }
      current.scrollTop = newScrollPos;
    };
    setInitValues();
  }, [pickerRef]); // eslint-disable-line
  const changeScrollTop = (e) => {
    const index = countActiveIndex(e.target.scrollTop);
    if (index !== activeIndex) {
      setActiveIndex(index);
      onChange(index < 10 ? `0${index}` : index);
    }
  };
  const countActiveIndex = (sTop) => {
    return parseInt((sTop + (size / 2)) / size, 10);
  };

  const changeActiveIndex = (number) => {
    pickerRef.current.scrollTo({
      top: number * size,
      behavior: 'smooth',
    });
  };

  return (
    <div className="spin hide-scollbar">
      <div className="spinner" ref={pickerRef} onScroll={changeScrollTop}>
        {arrOfElements.map((_, index) => {
          const prevActiveHour = activeIndex === index - offset + 1;
          const showLabel = index > 1 && index <= (max + offset);
          const number = index - offset;
          const label = number < 10 ? `0${number}` : number;
          return (
            <div key={index} className={cls('text-lg centered', { 'prev-active': prevActiveHour })}>
              <ButtonSimple onClick={() => changeActiveIndex(number)}>
                <div className="spin-number" style={{ minWidth: size, minHeight: size }}>
                  <span>{showLabel && label}</span>
                </div>
              </ButtonSimple>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Spinner.defaultProps = {
  initValue: 0,
  max: 24,
};

Spinner.propTypes = {
  initValue: PropTypes.string,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
