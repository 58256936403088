import Event from 'models/Event';
import Bill from 'models/Bill';
import Farm from 'models/Farm';
import Participation from 'models/Participation';
import Member from 'models/Member';
import { useFarm, useLoggedMember } from 'utils/storeUtils';
import _ from 'lodash';

type TActionKey =
  | 'BILL_ADD_PAYMENT'
  | 'BILL_SET_PAYMENT'
  | 'BILL_RETURN_PAYMENT'
  | 'BILL_CANCEL_PAYMENT'
  | 'BILL_DOWNLOAD_XMLX'
  | 'PARTICIPATION_SET_PRESENCE'
  | 'PARTICIPATION_REJECT'
  | 'PARTICIPATION_ACCEPT'
  | 'EVENT_JOIN'
  | 'EVENT_UNJOIN'
  | 'EVENT_REJECT_INVITATION'
  | 'EVENT_INVITE'
  | 'EVENT_ADD'
  | 'PROPOSAL_APPROVE'
  | 'PROPOSAL_UPDATE'
  | 'PROPOSAL_REJECT'
  | 'TICKET_REMOVE';

const participationPresnce = () => {
  const check = (event: Event | null, participation: Participation) => {
    if (!event || !participation || event.deleted || event.status === 'cancelled') {
      return false;
    }
    if (participation) {
      let isHasPrivilege = false;
      if (participation.attendance === 'attending') {
        isHasPrivilege = participation.canI('absent');
      }
      if (participation.attendance === 'absent') {
        isHasPrivilege = participation.canI('present');
      }
      if (!isHasPrivilege) return false;
    }
    return true;
  };
  return check;
};

const participationReject = () => {
  const check = (event: Event | null, participation: Participation) => {
    if (!event || !participation || event.deleted || event.isPast()) {
      return false;
    }
    const isHasPrivilege = participation.canI('reject');
    if (!isHasPrivilege) return false;
    return true;
  };

  return check;
};

const participationAccept = () => {
  const check = (event: Event | null, participation: Participation) => {
    if (!event || !participation || event.deleted || event.isPast()) {
      return false;
    }
    const isHasPrivilege = participation.canI('accept');
    if (!isHasPrivilege) return false;
    return true;
  };

  return check;
};

const billSetPayment = () => {
  const check = (bill?: Bill | null, participation?: Participation) => {
    if (!bill) return false;
    if (participation) {
      const isHasPrivilege = participation.canI('set_payment');
      if (!isHasPrivilege) return false;
    }
    const isNoBookedPayment = !bill.getBookedPayments().length;
    const isNoPaidOrIsSettled = bill.status === 'unpaid' || bill.status === 'settled';
    const isPending = bill?.getCurrentTransaction()?.status === 'pending';
    const isActive = isNoBookedPayment && isNoPaidOrIsSettled && !isPending;
    if (!isActive) return false;
    return true;
  };

  return check;
};

const billAddPayment = (loggedMember: Member | null, farm: Farm | null) => {
  const check = (bill?: Bill | null) => {
    if (!bill) return false;
    const isClientAndAdvancePaymentEnabled = farm?.isAdvancedPaymentEnabled() && loggedMember?.isClient();
    const isNoBookedPayment = !bill.getBookedPayments().length;
    const isNoSettledStatus = bill.status !== 'settled';
    const isPending = bill?.getCurrentTransaction()?.status === 'pending';
    if (isNoBookedPayment && isClientAndAdvancePaymentEnabled && isNoSettledStatus && !isPending) {
      return true;
    }
    return false;
  };

  return check;
};

const billReturnPayment = (loggedMember: Member | null) => {
  const check = (bill?: Bill | null, participation?: Participation) => {
    if (participation) {
      const isHasPrivilege = participation.canI('set_payment');
      if (!isHasPrivilege) return false;
    }
    if (loggedMember?.isClient() || !bill) {
      return false;
    }
    if (!_.isEmpty(bill.getBookedPayments())) {
      return true;
    }
    return false;
  };

  return check;
};

const billCancelPayment = (loggedMember: Member | null) => {
  const check = (bill: Bill | null) => {
    if (bill?.getCurrentTransaction()?.status === 'pending' && loggedMember?.isClient()) {
      return true;
    }
    return false;
  };

  return check;
};

const billDownloadXmlx = (loggedMember: Member | null) => {
  const check = () => {
    if (!loggedMember?.isClient()) {
      return true;
    }
    return false;
  };

  return check;
};

const eventJoin = (loggedMember: Member | null) => {
  const check = (event: Event) => {
    if (!loggedMember) {
      return false;
    }

    const { memberIsJoined, memberRequestInvitation, isAwaiting, isFull, isActive } = event.getStatuses(
      loggedMember.id
    );

    const isHasPrivilege = event.canI('join');
    if (!isHasPrivilege) return false;
    return !memberRequestInvitation && !memberIsJoined && !isFull && (isAwaiting || isActive);
  };
  return check;
};

const eventUnjoin = (loggedMember: Member | null) => {
  const check = (event: Event) => {
    if (!loggedMember) {
      return false;
    }
    const { memberIsJoined, isAwaiting, isActive } = event.getStatuses(loggedMember.id);
    const isHasPrivilege = event.canI('unjoin');
    if (!isHasPrivilege) return false;
    return memberIsJoined && (isAwaiting || isActive) && !event.canUnjoin();
  };
  return check;
};

const eventRejectInvitation = (loggedMember: Member | null) => {
  const check = (event: Event) => {
    if (!loggedMember) {
      return false;
    }
    const { memberIsInvited, isAwaiting, isActive } = event.getStatuses(loggedMember.id);
    const isHasPrivilege = event.canI('reject');
    if (!isHasPrivilege) return false;
    return memberIsInvited && (isAwaiting || isActive);
  };
  return check;
};

const eventInvite = (loggedMember: Member | null) => {
  const check = (event: Event) => {
    if (!loggedMember || !event || event.deleted) {
      return false;
    }

    if (!event.isFull() && !event.isPast() && event.canI('invite')) return true;
    return false;
  };
  return check;
};

const eventAdd = (loggedMember: Member | null) => {
  const check = (event: Event) => {
    if (!loggedMember || !event || event.deleted) {
      return false;
    }

    if (!event.isFull() && event.canI('add')) return true;
    return false;
  };
  return check;
};

const proposalApprove = (loggedMember: Member | null) => {
  const check = () => {
    if (!loggedMember) return false;
    const isHasPrivilege = loggedMember.getAccesByKey('proposals', 'approve');
    return isHasPrivilege;
  };
  return check;
};

const proposalUpdate = (loggedMember: Member | null) => {
  const isEventManager = (event: Event) => {
    return event.creator.id === loggedMember?.id || event.getInstructorsIds().some(id => id === loggedMember?.id);
  };
  const check = (proposal: Event) => {
    if (!loggedMember) return false;
    const isHasPrivilege =
      loggedMember.getAccesByKey('proposals', 'update') ||
      (loggedMember.getAccesByKey('proposals', 'update_if_is_event_manager') && isEventManager(proposal));
    return isHasPrivilege;
  };
  return check;
};

const proposalReject = (loggedMember: Member | null) => {
  const check = () => {
    if (!loggedMember) return false;
    const isHasPrivilege = loggedMember.getAccesByKey('proposals', 'reject');
    return isHasPrivilege;
  };
  return check;
};

const removeTicket = (loggedMember: Member | null) => {
  const check = (ticketIsPaid?: boolean) => {
    if (!loggedMember) return false;
    const isHasPrivilege = loggedMember.getAccesByKey('tickets', 'destroy');

    return isHasPrivilege && ticketIsPaid;
  };
  return check;
};

export const usePermissions = <T extends TActionKey>(actionKey: T) => {
  const loggedMember = useLoggedMember();
  const farm = useFarm();

  return {
    BILL_SET_PAYMENT: billSetPayment(),
    BILL_ADD_PAYMENT: billAddPayment(loggedMember, farm),
    BILL_RETURN_PAYMENT: billReturnPayment(loggedMember),
    BILL_CANCEL_PAYMENT: billCancelPayment(loggedMember),
    BILL_DOWNLOAD_XMLX: billDownloadXmlx(loggedMember),
    PARTICIPATION_SET_PRESENCE: participationPresnce(),
    PARTICIPATION_REJECT: participationReject(),
    PARTICIPATION_ACCEPT: participationAccept(),
    EVENT_JOIN: eventJoin(loggedMember),
    EVENT_UNJOIN: eventUnjoin(loggedMember),
    EVENT_REJECT_INVITATION: eventRejectInvitation(loggedMember),
    EVENT_INVITE: eventInvite(loggedMember),
    EVENT_ADD: eventAdd(loggedMember),
    PROPOSAL_APPROVE: proposalApprove(loggedMember),
    PROPOSAL_UPDATE: proposalUpdate(loggedMember),
    PROPOSAL_REJECT: proposalReject(loggedMember),
    TICKET_REMOVE: removeTicket(loggedMember),
  }[actionKey];
};
