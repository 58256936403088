import moment from 'moment';

import RowAligner from '@old/components/common/RowAligner';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import config from '@old/config';

const WeekSwitch = ({ dateRange, setDateRange }) => {
  const onNext = () =>
    setDateRange({
      start: moment(dateRange.start).add(1, 'week'),
      end: moment(dateRange.end).add(1, 'week'),
    });

  const onPrevious = () =>
    setDateRange({
      start: moment(dateRange.start).subtract(1, 'week'),
      end: moment(dateRange.end).subtract(1, 'week'),
    });

  return (
    <RowAligner className="justify-between">
      <ButtonSimple onClick={onPrevious}>
        <Icon.ArrowLeft className="fill-teal" />
      </ButtonSimple>
      <div>
        {dateRange.start.format(config.dateFormat)} - {dateRange.end.format(config.dateFormat)}
      </div>
      <ButtonSimple onClick={onNext}>
        <Icon.ArrowRight className="fill-teal" />
      </ButtonSimple>
    </RowAligner>
  );
};

export default WeekSwitch;
