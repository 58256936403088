import { Box } from '../../elements';
import { TReactNode } from 'resources/types/commonTypes';

type TTag = {
  color: string;
  children: TReactNode;
};
const Tag = ({ children, color }: TTag) => {
  return (
    <Box
      pl={12}
      pr={34}
      py={3}
      borderWidth={1}
      borderStyle="solid"
      borderColor={color}
      borderRadius={9999}
      position="relative"
      minHeight={26}
      minWidth={0}
    >
      {children}
    </Box>
  );
};

Tag.defaultProps = {
  color: 'teal-light',
};

export default Tag;
