import styled from 'styled-components';
import { blockStyleProps, typographyStyleProps, baseStyledProps } from 'config/styledProps';
import { system } from 'styled-system';
import { TSvgProps } from 'resources/types/elementsTypes';

const Svg = styled('svg')<TSvgProps>(
  baseStyledProps,
  typographyStyleProps,
  blockStyleProps,
  system({
    fill: {
      property: 'fill',
      scale: 'colors',
    },
  })
);

export default Svg;
