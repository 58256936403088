/**
 * @module DoubleTimeInput
 */

import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import Input from '@old/components/input';
import Icon from '@old/components/icon';

/**
 * Component that display two input with posibility to select time in format (HH:mm), inputs are divided by :
 *
 * @param  {Function} props.onChange
 * @param  {String[]} [props.value]
 * @param  {String} [props.label]
 */
const DoubleTimeInput = ({ withCheckbox, value, ...props }) => {
  const onClick = (checkboxActive) => {
    props.onChange({
      active: checkboxActive,
      hours: value.hours,
    });
  };

  if (withCheckbox) {
    return (
      <div className="double-time-checkbox">
        <Input.Checkbox
          value={value.active}
          label={<DoubleTime {...props} value={value} />}
          onChange={onClick}
          clickableWrapper={false}
        />
      </div>
    );
  }

  return <DoubleTime {...props} value={value} />;
};

DoubleTimeInput.defaultProps = {
  value: {},
  label: '',
  withCheckbox: false,
};

DoubleTimeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    active: PropTypes.bool,
    hours: PropTypes.arrayOf(PropTypes.string),
  }),
  label: PropTypes.string,
  withCheckbox: PropTypes.bool,
};

export default DoubleTimeInput;

const DoubleTime = ({ onChange, value, label }) => {
  const onChangeStart = (openTime) => {
    onChange({
      active: true,
      hours: [openTime, value.hours[1]],
    });
  };
  const onChangeEnd = (closeTime) => {
    onChange({
      active: true,
      hours: [value.hours[0], closeTime],
    });
  };

  return (
    <div className="double-time-wrapper relative">
      {label && <label>{label}</label>}
      <div className="flex">
        <Input.Time
          value={value.hours[0] || ''}
          onChange={onChangeStart}
          readOnly={!value.active}
          hideButtons
          required
        />
        <div className={cls('input-wrapper px-1', !value.active && 'inactive')}>
          <span><Icon.Dash small className="fill-grey cursor-default" /></span>
        </div>
        <Input.Time
          value={value.hours[1] || ''}
          onChange={onChangeEnd}
          readOnly={!value.active}
          hideButtons
          required
        />
      </div>
    </div>
  );
};

DoubleTime.defaultProps = {
  value: [],
  label: '',
};

DoubleTime.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    active: PropTypes.bool,
    hours: PropTypes.arrayOf(PropTypes.string),
  }),
  label: PropTypes.string,
};
