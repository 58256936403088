/* eslint-disable max-len */
import React from 'react';

const Users = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 21.88"
    >
      <path d="M15.69 10.1a4.724 4.724 0 0 1 2.2 3.51 3.812 3.812 0 1 0-2.2-3.51zm-2.5 7.807A3.813 3.813 0 1 0 9.378 14.1a3.813 3.813 0 0 0 3.814 3.811zm1.617.26h-3.233a4.888 4.888 0 0 0-4.882 4.883v3.96l.01.062.273.085a22.224 22.224 0 0 0 6.638 1.07 13.628 13.628 0 0 0 5.8-1.088l.255-.129h.027v-3.957a4.887 4.887 0 0 0-4.886-4.882zm6.309-3.937h-3.209a4.7 4.7 0 0 1-1.45 3.273 5.8 5.8 0 0 1 4.143 5.556v1.219a13.139 13.139 0 0 0 5.118-1.078l.255-.129H26v-3.955a4.888 4.888 0 0 0-4.882-4.882zM6.5 13.975a3.788 3.788 0 0 0 2.028-.589 4.719 4.719 0 0 1 1.774-3.01c0-.071.011-.142.011-.214A3.813 3.813 0 1 0 6.5 13.975zm3.424 3.533a4.7 4.7 0 0 1-1.449-3.255c-.119-.009-.237-.018-.358-.018H4.882A4.888 4.888 0 0 0 0 19.116v3.958l.01.061.273.086a22.891 22.891 0 0 0 5.5 1.033v-1.2a5.8 5.8 0 0 1 4.142-5.546z" transform="translate(0 -6.348)" />
    </svg>
  );
};

export default Users;
/* eslint-enable max-len */
