import { useState } from 'react';
import cls from 'classnames';
import { sortBy } from 'lodash';

import t from 'resources/translations';
import { fetchByModelName, getErrorMessage, notify } from 'old/utils';
import Input from '@old/components/input';
import ImageCircle from '@old/components/guide/ImageCircle';
import Spinner from '@old/components/common/Spinner';
import Center from '@old/components/common/Center';
import { Grid } from '@elements';
import Modal from '@old/components/common/Modal';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import FlexColumn from '@old/components/common/FlexColumn';
import BoxShadow from '@old/components/common/BoxShadow';
import { refetchFarm } from 'store/auth';
import { useDispatch } from 'react-redux';

const EditAvatarModal = ({ modelItem, onClose, refreshAction }) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const addPictures = async (file, description) => {
    setLoading(true);
    try {
      await modelItem.updatePicture({ pictures: [{ file, description }] });
      notify(t('message.photoSuccesSave'));
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      refreshAction(modelItem.modelName !== 'farms' && modelItem.id);
    }
    setLoading(false);
  };

  const setPrimary = async id => {
    setLoading(true);
    try {
      await modelItem.updatePicture({ primaryPictureId: id });
      await fetchByModelName(modelItem.id, modelItem.model.name);
      if (modelItem.model.name === 'farms') {
        dispatch(refetchFarm());
      }
      notify(t('message.photoSuccesUpdate'));
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    setLoading(false);
  };

  const pictures = sortBy(modelItem.pictures || [], ['id']);

  return (
    <Modal header={t('general.changeAvatar')} onClose={onClose} isOpen>
      <FlexColumn>
        <Center>
          {isLoading && <Spinner />}
          <ImageCircle src={modelItem.getAvatar()} large centered />
        </Center>
        {pictures.length === 0 && (
          <Input.ImageUploader
            onChange={addPictures}
            label={t('labels.photo')}
            dropzoneOptions={{
              promptMessage: (
                <FlexColumn>
                  <div>{t('editableAvatarModal.galeryEmpty')}</div>
                  <div>{t('general.dropFiles')}</div>
                </FlexColumn>
              ),
            }}
          />
        )}
        {pictures.length !== 0 && (
          <FlexColumn>
            <div>{t('editableAvatarModal.availablePhotosInGallery')}:</div>
            <Grid gridTemplateColumns="repeat(3, 1fr)" gridGap="md">
              {pictures.map(picture => (
                <ButtonSimple key={picture.id} onClick={() => setPrimary(picture.id)}>
                  <BoxShadow
                    className={cls('overflow-hidden w-full', {
                      'border-2 border-teal': modelItem.primaryPictureId === picture.id,
                    })}
                  >
                    <img src={picture.url.medium} className="w-full" alt={`${picture.id}`} />
                  </BoxShadow>
                </ButtonSimple>
              ))}
            </Grid>
          </FlexColumn>
        )}
      </FlexColumn>
    </Modal>
  );
};
export default EditAvatarModal;
