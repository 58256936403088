import InputWrapper from 'components/combinations/forms/InputWrapper';
import Textarea from 'components/elements/Textarea';
import { TReactNode } from 'resources/types/commonTypes';

type TTextareaInput = {
  value: string;
  name: string;
  placeholder: string;
  children?: TReactNode;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const TextInput = ({ value, name, placeholder, onChange, children, ...inputProps }: TTextareaInput) => {
  return (
    <InputWrapper px={0}>
      <Textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        py={20}
        px={20}
        {...inputProps}
      />
      {!!children && <>{children}</>}
    </InputWrapper>
  );
};

export default TextInput;
