/* eslint-disable max-len */
import React from 'react';

const NewsOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M16,2v10.8c0,1.7-1.4,3-3,3H3c-1.7,0-3-1.4-3-3V0.7c0-0.3,0.2-0.6,0.6-0.6h12.7c0.3,0,0.6,0.2,0.6,0.6v10.9
        c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6V1.3H1.1v11.5c0,1,0.9,1.9,1.9,1.9h10c1,0,1.9-0.9,1.9-1.9V2c0-0.3,0.2-0.6,0.6-0.6
        c0.1,0,0.3,0.1,0.4,0.2C15.9,1.7,16,1.8,16,2z M11.9,3.1c0,0.3-0.2,0.6-0.6,0.6H2.5c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h8.9
        C11.6,2.5,11.9,2.7,11.9,3.1z M11.9,11.4c0,0.3-0.2,0.6-0.6,0.6H2.5c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6h8.9
        C11.6,10.8,11.9,11.1,11.9,11.4z M11.9,13.5c0,0.3-0.2,0.6-0.6,0.6H3.9c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h7.4
        C11.6,12.9,11.9,13.1,11.9,13.5z M6.7,4.3H2.3C2,4.3,1.7,4.6,1.7,4.9v4.4c0,0.3,0.2,0.6,0.6,0.6h4.4c0.3,0,0.6-0.2,0.6-0.6V4.9
        C7.3,4.6,7,4.3,6.7,4.3z M2.9,8.7V5.5h3.3v3.3H2.9z M12.1,5.3c0,0.3-0.2,0.6-0.6,0.6H8.6c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h3
        C11.8,4.8,12.1,5,12.1,5.3z M12.1,7.1c0,0.3-0.2,0.6-0.6,0.6H8.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6h3
        C11.8,6.6,12.1,6.8,12.1,7.1z M12.1,9.1c0,0.3-0.2,0.6-0.6,0.6H8.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6h3
        C11.8,8.5,12.1,8.8,12.1,9.1z"
      />
    </svg>
  );
};

export default NewsOutline;
/* eslint-enable max-len */
