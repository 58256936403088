import moment from 'moment';

import {
  SET_NOTIFICATIONS,
  OPEN_NOTIFICATIONS_WIDGET,
  OPEN_NOTIFICATION,
  OPEN_ALL_NOTIFICATION,
  RECEIVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from 'store/actions/notificationsActions';
import Model from '@old/model';

const initialState = {
  data: [],
  widgetIsOpen: false,
};

const notificationsReducer = (state = initialState, action) => {
  const { message } = action;
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        data: action.notifications,
      };
    case OPEN_NOTIFICATIONS_WIDGET:
      return {
        ...state,
        widgetIsOpen: action.isOpen === null ? !state.widgetIsOpen : action.isOpen,
      };
    case OPEN_NOTIFICATION:
      return {
        ...state,
        data: state.data.map(notification => {
          if (notification.id === action.id) {
            const notificationItem = notification;
            notificationItem.openedAt = moment();
            return notificationItem;
          }
          return notification;
        }),
        widgetIsOpen: false,
      };
    case OPEN_ALL_NOTIFICATION:
      return {
        ...state,
        data: state.data.map(notification => {
          if (!notification.openedAt) {
            const notificationItem = notification;
            notificationItem.openedAt = moment();
            return notificationItem;
          }
          return notification;
        }),
      };
    case RECEIVE_NOTIFICATION:
      if (!message.notification) {
        return state;
      }

      return {
        ...state,
        data: message.notification ? [Model.Notifications.parse(message.notification), ...state.data] : state.data,
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default notificationsReducer;
