/* eslint-disable max-len */
import React from 'react';

const Doc = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M14.9,14.8h-8V4.6h3.4v3.7c0,0.5,0.4,0.9,0.9,0.9l0,0h3.7L14.9,14.8z M2.6,2.3L2.6,2.3C2.4,2.3,2.3,2.1,2.3,2v0V1.4
        c0-0.2,0.1-0.3,0.3-0.3h0h6.3c0.2,0,0.3,0.1,0.3,0.3v0V2c0,0.2-0.1,0.3-0.3,0.3h0L2.6,2.3z M11.4,5.3L14.1,8h-2.7V5.3z M15.8,8.3
        c-0.1-0.3-0.3-0.5-0.4-0.7l0,0L11.8,4c-0.1-0.1-0.2-0.2-0.3-0.2l0,0V0.9c0,0,0,0,0,0C11.4,0.4,11,0,10.6,0h0H0.9
        C0.6,0,0.4,0.1,0.3,0.2l0,0C0.1,0.4,0,0.6,0,0.8c0,0,0,0,0,0v0v12c0,0,0,0,0,0c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0h0h4.9v1.4
        c0,0.5,0.4,0.9,0.9,0.9l0,0h8.6c0.5,0,0.9-0.4,0.9-0.9l0,0v-6C16,8.8,15.9,8.6,15.8,8.3L15.8,8.3L15.8,8.3z"
      />
    </svg>
  );
};

export default Doc;
/* eslint-enable max-len */
