import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import FlexColumn from '@old/components/common/FlexColumn';
import BoxWhite from '@old/components/common/BoxWhite';
import BoxShadow from '@old/components/common/BoxShadow';
import { LogoHmNotextIcon } from 'old/assets/svg/index';
import ActivationCodeForm from '@old/components/form/ActivationCode';
import t from 'resources/translations';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import RowAligner from '@old/components/common/RowAligner';
import { notify, getErrorMessage } from 'old/utils';
import Button from '@old/components/guide/Button';
import Center from '@old/components/common/Center';
import Icon from '@old/components/icon';
import localStorageService from 'services/storage/localStorageService';
import Error404 from 'old/components/error/Error404';
import { MemberService } from 'services';
import { initApp } from 'store/app';
import { useHistory } from 'react-router';
import { useModal } from 'old/hooks';
import { MODAL_MANAGE_PHONE } from 'old/constants';
import { ManagePhoneModal } from 'old/components/modals';

const ConfirmPhonePage = () => {
  const managePhoneModal = useModal(MODAL_MANAGE_PHONE);
  const [loggedUser, setLoggedUser] = useState();
  const history = useHistory();
  const [phoneNubmer, setPhoneNumer] = useState('');
  const [failSendSMS, setFailSendSMS] = useState(false);
  const [successSendSMS, setSuccessSendSMS] = useState(false);
  const [dateFromApi, setDateFromApi] = useState(new Date(0));
  const dispatch = useDispatch();
  useEffect(() => {
    setLoggedUser(localStorageService._getUser());
  }, [managePhoneModal.isOpen]);
  useEffect(() => {
    setFailSendSMS(false);
    setSuccessSendSMS(false);
  }, [phoneNubmer]);

  const sendSmsWithActivationCode = async () => {
    try {
      const { sending_sms_window_at } = await MemberService.sendSmsActivation();
      const date = new Date(sending_sms_window_at * 1000 + 1); // +1 - handle 0 sec

      setDateFromApi(date);
      setSuccessSendSMS(true);
      await setTimeout(() => {
        setPhoneNumer(loggedUser.phone);
      }, 2000);
    } catch (e) {
      const timeToSendSMS = e.response.data.sms_code[0].remaining_wait_seconds;
      const formatted = moment.utc(timeToSendSMS * 1000).format('mm:ss');
      if (timeToSendSMS !== undefined) {
        let convertedDate = new Date();
        convertedDate = new Date(convertedDate.getTime() + timeToSendSMS * 1000 + 1);
        setDateFromApi(convertedDate);
        notify(t('error.not_in_sending_window', { value: formatted }), { type: 'error' });
      } else {
        setDateFromApi(null);
        notify(getErrorMessage(e), { type: 'error' });
      }
      setFailSendSMS(true);
    }
  };

  const confirmPhoneByActivationCode = async ({ code }) => {
    try {
      const user = await MemberService.confirmPhone(code.join(''));
      localStorageService._setUser(user);
      history.replace('/');
      dispatch(initApp());
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  if (loggedUser?.phoneVerified) {
    return <Error404 />;
  }

  if (!loggedUser?.phoneVerified)
    return (
      <BoxShadow className="page-login">
        <div className="login-banner text-white relative">
          <LogoHmNotextIcon width={120} height={120} />
          <div className="absolute bottom-2 right-2">
            <ButtonSimple onClick={() => history.push('/logout')}>
              <RowAligner separated="small">
                <span>{t('menu.logout')}</span>
                <Icon.Logout />
              </RowAligner>
            </ButtonSimple>
          </div>
        </div>

        {!phoneNubmer ? (
          <SendActivationSMS
            setPhoneNumer={setPhoneNumer}
            sendSmsWithActivationCode={sendSmsWithActivationCode}
            failSendSMS={failSendSMS}
            successSendSMS={successSendSMS}
            managePhoneModal={managePhoneModal}
          />
        ) : (
          <ActivationCodeForm
            confirmPhoneByActivationCode={confirmPhoneByActivationCode}
            setPhoneNumer={setPhoneNumer}
            phoneNumber={phoneNubmer}
            sendSmsWithActivationCode={sendSmsWithActivationCode}
            dateFromApi={dateFromApi}
          />
        )}
        <ManagePhoneModal />
      </BoxShadow>
    );
};

export default ConfirmPhonePage;

const SendActivationSMS = ({
  sendSmsWithActivationCode,
  failSendSMS,
  successSendSMS,
  managePhoneModal,
  setPhoneNumer,
}) => {
  const loggedUser = localStorageService._getUser();
  const phone = loggedUser?.phone || '';
  const numberSpace = phone.replace(/^(.{3})(.{3})(.{3})(.*)$/, '$1 $2 $3 $4');

  return (
    <BoxWhite padding>
      <FlexColumn separated>
        <h1 className="text-headline">{t('confirmPhonePage.confirmPhone')}</h1>
        <div className="text">{t('confirmPhonePage.weSendVerificationCodeToThisNumber')}</div>
        <RowAligner separated="small">
          <Icon.Phone small className="fill-teal" />
          <div>{numberSpace}</div>
        </RowAligner>
        <div>
          {t('confirmPhonePage.wrongNumber')}&nbsp;
          <button type="button" onClick={() => managePhoneModal.onOpen({ userId: loggedUser.id, phoneNumber: phone })}>
            <span className="text-link">{t('confirmPhonePage.goToAccountManagement')}</span>
          </button>
          {t('confirmPhonePage.toUpdateData')}
        </div>
        <ButtonSimple onClick={() => setPhoneNumer(phone)}>
          <span className="text-link">{t('confirmPhonePage.iAlreadyHaveCode')}</span>
        </ButtonSimple>
        {failSendSMS && (
          <Center>
            <div>
              <Icon.WrongSms big className="fill-teal" />
              <div>{t('confirmPhonePage.sendSMS')}</div>
              <div>{t('confirmPhonePage.checkNumber')}</div>
            </div>
          </Center>
        )}
        {successSendSMS && (
          <Center>
            <div>
              <Icon.OkSms big className="fill-teal" />
              <div>{t('confirmPhonePage.successSendSMS')}</div>
            </div>
          </Center>
        )}
        <Button fluid onClick={sendSmsWithActivationCode} disabled={failSendSMS || successSendSMS}>
          <div className="uppercase">{t('confirmPhonePage.sendCode')}</div>
        </Button>
      </FlexColumn>
    </BoxWhite>
  );
};
