/**
 * @module FormButton
 */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@old/components/guide/Button';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import {
  notify,
  getErrorMessage,
  mapFormStateToData,
} from 'old/utils';
/**
 * Component that adds buttons to form
 * @param  {Node} props.children
 * @param  {Function} props.onClick
 * @param  {Object} props.formState object that contains form data
 * @param  {Boolean} [props.async] specifies if the form should be submitted asynchronously
 * @param  {Boolean} [props.fluid] specifies whether the button should have full width
 * @param  {Boolean} [props.buttonIsActive] computed in mapStateToProps; should disable button
 * if any validation function not pass; if you want to disable button use disabled props instead
 * @param  {Boolean} [props.disabled] specifies whether the button should be disabled
 * @param  {Boolean} [props.outline] specifies type of the button
 */
const FormButton = ({ children, onClick, formState, async, fluid, buttonIsActive, disabled, outline }) => {
  const submitForm = async () => {
    const dataToSend = mapFormStateToData({ formState });
    try {
      await onClick(dataToSend, formState);
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const ButtonSubmit = outline ? ButtonOutline : Button;

  return (
    <div className="form-btn">
      <ButtonSubmit
        type='submit'
        onClick={submitForm}
        disabled={disabled || !buttonIsActive}
        async={async}
        fluid={fluid}
      >
        {children}
      </ButtonSubmit>
    </div>
  );
};

FormButton.defaultProps = {
  async: true,
  fluid: false,
  availableBeforeFormIsChanged: false,
  buttonIsActive: false,
  outline: false,
  disabled: false,
};

FormButton.propTypes = {
  buttonIsActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  formState: PropTypes.shape({}).isRequired,
  availableBeforeFormIsChanged: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  async: PropTypes.bool,
  fluid: PropTypes.bool,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
};

const mapStateToProps = ({ form }, props) => {
  const formState = form[props.formName];
  const anyFieldChanged = Object.values(formState).some(inputState => inputState.isChanged);
  const allFieldsPassValidation = Object.values(formState.validate).every(value => value);
  return {
    formState,
    buttonIsActive: ((anyFieldChanged || props.availableBeforeFormIsChanged) && allFieldsPassValidation),
  };
};

export default connect(mapStateToProps)(FormButton);
