/* eslint-disable */
import React from 'react';

const HorseAlone = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <g>
        <path d="M26,8.2c0,0-0.2,1.5-1.4,1.1c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.4-0.3-2.7-1.6-3.6-0.1
          c-0.4,0.6-0.6,1.3-0.7,2c-0.1,0.8-0.3,1.5-0.6,2.2l1.6,4c0.2,0.3,0.3,0.7,0.1,1.1c0,0-0.7,4.6-2,4c0,0-0.7-0.2-0.6-1.3l0.7-3
          c-0.6-1-1.4-1.9-2.3-2.7l-0.6,7c0,0-0.1,1.1-0.7,1.2c0,0-0.7,0.1-1-1.2l-0.2-6.2c-0.3,0.1-0.6,0.1-0.9,0.1c-0.8,0.1-1.6,0.1-2.4-0.1
          l-0.2,0c0,0-0.1,0-0.1,0l-1-0.2c0.3,0.7,0.6,1.4,0.7,2.1c0,0-0.4,4.7-2.1,4.6c0,0-1.4-0.3-0.4-2.1l0.6-2.1l-1.9-2.2v2.4
          c0,0-0.6,5.1-1.9,5c0,0-1.1-0.1-0.8-1c0,0,0.4-3.5,0.6-4.1c0,0-2.5-5.7-1.5-7.6c0,0-1.2-0.5-0.7,2.7c0,0,1,3.9-0.7,5.5
          c0.1-1.6-0.2-3.2-1-4.6c0,0-1.8-4.6,3.2-5C3.4,9.6,4.9,8,9.1,9c0,0,0.1,0,0.3,0C10.5,9.1,14,9.3,16,7c1.5-1.3,3.2-2.3,5-3.1
          c0,0,1.5-1.6,2.8-1.6c0,0-1.1,1.4-0.3,2.1C23.5,4.4,25.9,7.9,26,8.2z"
        />
      </g>
    </svg>
  );
};

export default HorseAlone;
/* eslint-enable */
