/**
 * @module RadioInput
 */
import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

/**
 * Additional  with opcional label of value radio buttons
 * @typedef   {Object} Options
 * @property  {String} value
 * @property  {String} label opcional label
 */
/**
 * Component that generates radio buttons
 * @param  {String|Boolean|Number} [props.value] value of input field
 * @param  {Function} props.onChange The onChange detects when the value of an input element changes and run function delegated in props
 * @param  {Options|Options[]} props.options additional label of radio buttons for example: value: 'false', label: 'normal' value: 'true', label: 'special'
 * @param  {String} [props.name]
 * @param  {String} [props.label]
 * @param  {'col'|'row'} [props.direction] Directions of radio input elements defult value: 'row'
 */
const RadioInput = ({ value, onChange, options, name, label, ...props }) => {
  const onClick = chosenValue => {
    onChange(chosenValue, name);
  };

  return (
    <React.Fragment>
      <div className="text-input-label text-grey-dark pl-2 mb-2">{label}</div>
      <div className={cls('flex pl-2', props.direction === 'col' ? 'flex-col' : 'flex-row')}>
        {(options || []).map(option => {
          const isChecked = value === option.value;
          return (
            <div
              key={option.value}
              className="radio-wrapper"
              style={{ minWidth: 140 }}
              value={option.value}
              onClick={() => onClick(option.value)}
            >
              <input
                type="radio"
                test-data={option.dataTest}
                name={name}
                onClick={() => onClick(option.value)}
                hidden
              />
              <div className="radio">{isChecked && <div className="radio-dot" />}</div>
              <div className="font-light ml-2 text-md">{option.label}</div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

RadioInput.defaultProps = {
  value: null,
  name: null,
  direction: 'row',
  label: '',
};

RadioInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  options: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
  label: PropTypes.string,
  direction: PropTypes.oneOf(['col', 'row']),
  onChange: PropTypes.func.isRequired,
};

export default RadioInput;
