/* eslint-disable max-len */
import React from 'react';

const ArrowPaginationDoubleRight = () => {
  return (
    <svg
      viewBox="0 0 28 26"
      width="100%"
      className="icon"
    >
      <path d="M0,22.9L9.9,13L0,3.1L3,0l13,13L3,26L0,22.9z" />
      <path d="M12,23l9.9-9.9L12,3.1L15,0l13,13L15,26L12,23z" />
    </svg>
  );
};

export default ArrowPaginationDoubleRight;
/* eslint-enable max-len */
