/**
 * @module DeleteGuestModal
 */
import { useHistory } from 'react-router';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import { notify } from 'old/utils';
import t from 'resources/translations';
import FlexRow from '@old/components/common/FlexRow';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import Button from '@old/components/guide/Button';
import FlexColumn from '@old/components/common/FlexColumn';

/**
 * A component that displays a modal in which we can confirm that we want to delete a user
 * @param {string} props.member           [description]
 * @param {string} props.onClose redux action to close modal
 * @param {string} props.afterSubmit      function called after deleting user
 */
const DeleteGuestModal = ({ member, onClose, afterSubmit }) => {
  const history = useHistory();
  const onSubmit = async () => {
    try {
      await Model.Members.deleteGuest(member.identityId);
      if (history.location.pathname !== '/users') {
        history.push('/users');
      }
      notify(t('message.accountSuccesDeleted', { name: member.getName() }));
      onClose();
    } catch (e) {
      notify(t('error.userDelete'), { type: 'error' });
    } finally {
      afterSubmit();
    }
  };

  if (!member) return null;

  return (
    <Modal header={t('deleteGuestModal.deleteGuestTitle')} onClose={onClose} small isOpen>
      <FlexColumn>
        <div>{t('message.deletingGuestConfirmation', { name: member.firstname, phone: member.phone })}</div>
        <FlexRow noShrink>
          <ButtonOutline onClick={onClose} fluid>
            {t('general.cancel')}
          </ButtonOutline>
          <Button onClick={onSubmit} fluid>
            {t('general.delete')}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

DeleteGuestModal.defaultProps = {
  member: null,
  afterSubmit: () => {},
};

export default DeleteGuestModal;
