import { AxiosResponse as ARes, CancelToken } from 'axios';
import HTTP from 'config/axios';
import {
  IBillFetchParams,
  IBillRes,
  IBillUpdateReq,
  IPaymentRes,
  ITransactionRes,
  TBillSummary,
  TBillSummaryRes,
  TPaymentType,
} from 'resources/types/billsTypes';
import { IResWithPagination } from 'resources/types/commonTypes';
import { IParticipationRes } from 'resources/types/participationsTypes';
import { downloadFileFromApi } from 'utils';

export interface IAddPaymentParams {
  participation: {
    payment_status: Exclude<TPaymentType, 'transfer' | 'unpaid'>;
    paid_at: string;
  };
}

class BillService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async fetch(billId: number, cancelToken?: CancelToken) {
    const requestPath = `${this.apiBaseURL}/bills/${billId}`;
    const response: ARes<IBillRes> = await HTTP.get(requestPath, { cancelToken });
    return response.data;
  }

  async fetchAll(params: IBillFetchParams, cancelToken?: CancelToken) {
    const response: ARes<IResWithPagination<IBillRes[]>> = await HTTP.get(`${this.apiBaseURL}/bills`, {
      params,
      cancelToken,
    });
    return response.data;
  }

  async fetchSummary(params: IBillFetchParams, cancelToken?: CancelToken) {
    const requestPath = `${this.apiBaseURL}/bills/summary`;
    const response: ARes<TBillSummaryRes> = await HTTP.get(requestPath, {
      params,
      cancelToken,
    });

    const summary: TBillSummary = {
      dueAmount: response.data.due_amount,
      paidAmount: response.data.paid_amount,
      outstandingAmount: response.data.outstanding_amount,
      overpaidAmount: response.data.overpaid_amount,
    };

    return summary;
  }

  async update(billId: number, params: IBillUpdateReq) {
    const requestPath = `${this.apiBaseURL}/bills/${billId}`;
    const response: ARes<IBillRes> = await HTTP.patch(requestPath, params);
    return response.data;
  }

  async addPayment(eventId: number | string, participationId: number | string, params: IAddPaymentParams) {
    const requestPath = `${this.apiBaseURL}/events/${eventId}/participations/${participationId}/set_payment`;
    const response: ARes<IParticipationRes> = await HTTP.patch(requestPath, params);
    return response.data;
  }

  async addTicketPayment(billId: number | string, ticketId: number | string) {
    const requestPath = `${this.apiBaseURL}/bills/${billId}/ticket_payment`;
    const response: ARes<IBillRes> = await HTTP.post(requestPath, {
      ticket_id: ticketId,
    });
    return response.data;
  }

  async addPaymentByBillId(billId: number | string, paymentType: TPaymentType) {
    const requestPath = `${this.apiBaseURL}/bills/${billId}/set_payment`;
    const response: ARes<IBillRes> = await HTTP.post(requestPath, {
      bill: {
        payment_type: paymentType,
        paid_at: new Date(),
      },
    });
    return response.data;
  }

  async addOnlinePayment(billId: number | string) {
    const requestPath = `${this.apiBaseURL}/bills/${billId}/payments`;
    const response: ARes<ITransactionRes> = await HTTP.post(requestPath);
    return response.data;
  }

  async returnPayment(billId: number | string, paymentId: number | string) {
    const requestPath = `${this.apiBaseURL}/bills/${billId}/payments/${paymentId}/refund`;
    const response: ARes<IPaymentRes> = await HTTP.post(requestPath);
    return response.data;
  }

  async cancelPayment(billId: number | string, transactionId: number | string) {
    const requestPath = `${this.apiBaseURL}/bills/${billId}/payments/${transactionId}`;
    const response: ARes<IPaymentRes> = await HTTP.delete(requestPath);
    return response.data;
  }

  async downloadReport(params: IBillFetchParams) {
    return downloadFileFromApi<IBillFetchParams>('/bills/xlsx_report', params);
  }
}

export default new BillService();
