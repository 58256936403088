import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/actions';
import AddGuestModal from '@old/components/modals/AddGuest';
import AddHorseModal from '@old/components/modals/AddHorse';
import AddParticipantModal from '@old/components/modals/AddParticipant';
import AddPictureModal from '@old/components/modals/AddPicture';
import AddRatingModal from '@old/components/modals/AddRating';
import AddRiderToGroupModal from '@old/components/modals/AddRiderToGroup';
import BlockUserModal from '@old/components/modals/BlockUser';
import CancelEventModal from '@old/components/modals/CancelEvent';
import ConfirmModal from '@old/components/modals/Confirm';
import ConfirmDeleteModal from '@old/components/modals/ConfirmDelete';
import ConfirmDeleteEventModal from '@old/components/modals/ConfirmDeleteEvent';
import DeleteGuestModal from '@old/components/modals/DeleteGuest';
import EditAvatarModal from '@old/components/modals/EditAvatar';
import FarmPolicyModal from '@old/components/modals/FarmPolicy';
import ManageMemberModal from '@old/components/modals/ManageMember';
import EditGuestModal from '@old/components/modals/EditGuest';
import EditPictureModal from '@old/components/modals/EditPicture';
import HorseStatisticsModal from '@old/components/modals/HorseStatistics';
import InviteParticipantsModal from '@old/components/modals/InviteParticipants';
import ManageAbsenceModal from '@old/components/modals/ManageAbsence';
import ManageGroupModal from '@old/components/modals/ManageGroup';
import ManagePhoneModal from '@old/components/modals/ManagePhone';
import ManageShiftModal from '@old/components/modals/ManageShift';
import RejectProposalModal from '@old/components/modals/RejectProposal';
import RepeatEventModal from '@old/components/modals/RepeatEvent';
import SettingsModal from '@old/components/modals/Settings';
import { useShallowSelector } from '@old/hooks';

const modalComponents = {
  addGuest: AddGuestModal,
  addHorse: AddHorseModal,
  addParticipant: AddParticipantModal,
  addPicture: AddPictureModal,
  addRating: AddRatingModal,
  addRiderToGroup: AddRiderToGroupModal,
  blockUser: BlockUserModal,
  cancelEvent: CancelEventModal,
  confirmModal: ConfirmModal,
  confirmDelete: ConfirmDeleteModal,
  confirmDeleteEvent: ConfirmDeleteEventModal,
  deleteGuest: DeleteGuestModal,
  editAvatar: EditAvatarModal,
  editGuest: EditGuestModal,
  editPicture: EditPictureModal,
  horseStatistics: HorseStatisticsModal,
  inviteParticipants: InviteParticipantsModal,
  manageAbsence: ManageAbsenceModal,
  manageGroup: ManageGroupModal,
  manageMember: ManageMemberModal,
  managePhone: ManagePhoneModal,
  manageShift: ManageShiftModal,
  rejectProposal: RejectProposalModal,
  repeatEvent: RepeatEventModal,
  showPolicy: FarmPolicyModal,
  settings: SettingsModal,
};

const ModalsController = () => {
  const modalsState = useShallowSelector(({ modals }) => modals);
  const dispatch = useDispatch();
  return (
    <>
      {modalsState.map(modal => {
        const Component = modalComponents[modal.name];
        return (
          <Component
            key={modal.name}
            name={modal.name}
            onClose={() => dispatch(closeModal(modal.name))}
            {...modal.payload}
          />
        );
      })}
    </>
  );
};

export default ModalsController;
