import { Stack } from 'components/elements';
import { IStack } from 'resources/types/elementsTypes';

const ModalDescription = ({ children, ...stackProps }: IStack) => {
  return (
    <Stack
      mx={[-20, -30]}
      backgroundColor="body-color-50"
      width={['calc(100% + 40px)', 'calc(100% + 60px)']}
      py="xl"
      px={['md', 'xl']}
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor="new-grey-light-30"
      {...stackProps}
    >
      {children}
    </Stack>
  );
};

export default ModalDescription;
