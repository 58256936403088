import PropTypes from 'prop-types';

import PageTitle from '@old/components/guide/PageTitle';
import Spinner from '@old/components/common/Spinner';
import Error404 from '@old/components/error/Error404';
import { getFormByModelName, getModelByName, notify } from 'old/utils';
import t from 'resources/translations';
import { useModelItem } from '@old/hooks';
import localStorageService from 'services/storage/localStorageService';

const Edit = ({ match, location, history }) => {
  if (match.url === `/farms/${match.params.id}/edit`) {
    history.replace('/farm/edit');
  }

  const { model: modelName } = match.params;
  const Model = getModelByName(modelName);
  const [doc, status] = useModelItem(modelName === 'users' ? 'members' : modelName, match.params.id);
  const Form = getFormByModelName(modelName, { editForm: true });

  const redirectTo = (customRedirect, docId) => {
    // When you visit this from details page and save, go back instead of replacing.
    const prevPathname = location.state && location.state.prevPathname.split('/').slice(2).join('/');
    if (prevPathname === `${match.params.model}/${docId}`) {
      history.goBack();
    } else {
      history.replace(customRedirect || `/${match.params.model}/${docId}`);
    }
  };

  const redirectGoBack = () => {
    history.goBack();
  };

  const changeDocument = async (data, options = {}) => {
    if (Object.keys(data).length === 0) {
      notify(t('message.noChange'), { type: 'warning', autoClose: 1500 });
      return;
    }

    if (match.params.model === 'users') {
      const user = await doc.updateUser(data);
      localStorageService._setUser(user);
    } else {
      await doc.update(data, options.params);
    }

    notify(t('message.succesSave'));

    if (!options.noRedirect) {
      if (options.goBack) {
        redirectGoBack();
      } else {
        redirectTo(options.redirectWithId ? `${options.redirectTo}${doc.id}` : options.redirectTo, doc.id);
      }
    }
  };
  if (!Form) return t('message.notImplemented');
  if (status.isPending) return <Spinner />;
  if (status.isError) return <Error404 />;
  if (!doc) return null;

  return (
    <>
      <PageTitle title={t(`model.edit.${Model.name}`)} back />
      <Form doc={doc} onSubmit={changeDocument} {...(location.state || {})} />
    </>
  );
};

Edit.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      model: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      prevPathname: PropTypes.string,
    }),
  }).isRequired,
};

export default Edit;
