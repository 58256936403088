import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { compact } from 'lodash';
import moment from 'moment';
import { useQueryClient, useQuery } from 'react-query';
import { CancelToken } from 'axios';

import { findDataInCacheById } from '@old/utils';
import EventInfo from '@old/components/custom/EventInfo';
import Participants from 'views/Events/Participants';
import EventChangeHistory from '@old/components/custom/EventChangeHistory';
import PageTitle from '@old/components/guide/PageTitle';
import Spinner from '@old/components/common/Spinner';
import t from 'resources/translations';
import Model from '@old/model';
import ProgressBar from '@old/components/old/ProgressBar';
import Error404 from '@old/components/error/Error404';
import ViewPager from '@old/components/guide/ViewPager';
import FloatingButton from '@old/components/custom/FloatingButton';
import {
  useTabs,
  useEventDeleteHorseButton,
  useEventAddHorseButton,
  useEventAddParticipantButton,
  useEventJoinButton,
  useEventUnjoinButton,
  useEventRejectInvitationButton,
  useEventAcceptInvitationButton,
  useEventActivateButton,
  useEventEditButton,
  useEventDeleteButton,
  useEventCancelButton,
  useEventCopyButton,
  useEventRepeatButton,
} from '@old/hooks';
import Message from '@old/components/common/Message';
import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import config from '@old/config';
import { EventService } from 'services';
import Event from 'models/Event';
import { useOldLoggedMember } from 'utils/storeUtils';

const EventDetails = () => {
  const [event, setEvent] = useState();
  const [newModelEvent, setNewModelEvent] = useState();
  const { id } = useParams();
  const loggedMember = useOldLoggedMember();

  const queryClient = useQueryClient();
  const queryFn = () => {
    const source = CancelToken.source();
    const eventRes = EventService.fetch(parseInt(id, 10), source.token);
    eventRes.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return eventRes;
  };
  const result = useQuery(['event', { id }], () => queryFn());
  useEffect(() => {
    const setEvents = async () => {
      if (result.isSuccess) {
        setEvent(new Model.Event(result.data));
        setNewModelEvent(new Event(result.data));
      } else {
        const cachedEvent = findDataInCacheById(queryClient, 'events', id);
        if (cachedEvent) {
          setEvent(cachedEvent);
          const eventRes = await EventService.fetch(cachedEvent.id);
          setNewModelEvent(new Event(eventRes));
        }
      }
    };
    setEvents();
  }, [id, result.isFetching]); // eslint-disable-line

  const cb = () => queryClient.invalidateQueries(['event', { id }], { refetchActive: true });

  const eventActivate = useEventActivateButton(event, { newCache: true }, cb);
  const eventEdit = useEventEditButton(event);
  const eventDelete = useEventDeleteButton(event, { newCache: true, redirect: '/events' }, () => {});
  const eventCancel = useEventCancelButton(event, { newCache: true }, cb);
  const eventCopy = useEventCopyButton(event);
  const eventDeleteHorseButton = useEventDeleteHorseButton(event, cb);
  const eventAddParticipant = useEventAddParticipantButton(event, cb);
  const eventAddHorse = useEventAddHorseButton(event, cb);
  const eventJoin = useEventJoinButton(event, { loggedMember, newCache: true }, cb);
  const eventUnjoin = useEventUnjoinButton(event, { loggedMember, newCache: true }, cb);
  const eventRejectInvitation = useEventRejectInvitationButton(event, { loggedMember, newCache: true }, cb);
  const eventAcceptInvitation = useEventAcceptInvitationButton(event, { loggedMember, newCache: true }, cb);
  const eventRepeat = useEventRepeatButton(event);

  const isEventDeleted = event?.isDeleted();

  const floatingButtons = isEventDeleted
    ? []
    : [
        eventAcceptInvitation,
        eventRejectInvitation,
        eventJoin,
        eventActivate,
        eventEdit,
        eventDelete,
        eventCancel,
        eventCopy,
        eventAddHorse,
        eventRepeat,
      ];

  const eventPairButtons = isEventDeleted ? [] : [eventDeleteHorseButton, eventAddParticipant, eventAddHorse];

  const eventRiderButtons = isEventDeleted
    ? []
    : [eventJoin, eventUnjoin, eventRejectInvitation, eventAcceptInvitation];

  const isCreatorEvent = event?.getCreatorId() === loggedMember.id;

  const tabs = [
    {
      name: t('eventDetails.info'),
      view: EventInfo,
      props: { event, loggedMember, buttons: [eventRiderButtons, eventPairButtons], isEventDeleted, cb },
    },
    {
      name: t('eventDetails.participants'),
      view: Participants,
      props: {
        event: newModelEvent,
        oldEventModel: event,
        cb,
        isFetching: result.isFetching,
      },
      active: isCreatorEvent || !loggedMember.isClient(),
    },
    {
      name: t('eventDetails.history'),
      view: EventChangeHistory,
      props: { event },
      permissionKey: 'events.destroy',
    },
  ];

  const eventDetailsTabs = useTabs(tabs);

  if (result.isLoading && !event) {
    return <Spinner />;
  }

  if (result.isError) return <Error404 />;

  if (!event) return null;

  return (
    <>
      <ProgressBar status={event.getStatus()} />
      <PageTitle title={event.getName()} back="/events" />
      {isEventDeleted && (
        <Message deleted>
          <RowAligner>
            <Icon.WarningOutline className="fill-teal" />
            <span>{t('model.deleteNotify.event')}</span>
          </RowAligner>
        </Message>
      )}
      {event.isCreatedBeforeBillingRelease() && !isEventDeleted && (
        <Message deleted>
          <RowAligner>
            <Icon.WarningOutline className="fill-teal" />
            <span>
              {t('eventDetails.paymentCantBeMade', {
                after: moment(config.releaseBillingDate).format(config.dateTimeFormatWithComma),
              })}
            </span>
          </RowAligner>
        </Message>
      )}
      <ViewPager tabs={eventDetailsTabs} />
      <FloatingButton buttons={compact(floatingButtons)} />
    </>
  );
};

export default EventDetails;
