/* eslint-disable max-len */
import React from 'react';

const CalendarShadow = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M13.6,14.4H2.4V5.6h11.2V14.4z M11.2,0v1.6H4.8V0H3.2v1.6H2.4l0,0c-0.9,0-1.6,0.7-1.6,1.6c0,0,0,0,0,0v0v11.2l0,0
        c0,0.9,0.7,1.6,1.6,1.6h11.2c0.9,0,1.6-0.7,1.6-1.6l0,0V3.2c0-0.9-0.7-1.6-1.6-1.6l0,0h-0.8V0L11.2,0z M12,8.8H8v4h4L12,8.8z"
      />
    </svg>
  );
};

export default CalendarShadow;
/* eslint-enable max-len */
