import { connect } from 'react-redux';
import ChangeHistoryListItem from '@old/components/view/listItem/EventHistory';
import FlexColumn from '@old/components/common/FlexColumn';

const ChangeHistoryList = ({ versions, isMobile }) => {
  return (
    <div className="relative">
      {!isMobile && <div className="line-time" />}
      <FlexColumn>
        {versions.map(version => (
          <ChangeHistoryListItem key={version.id} version={version} />
        ))}
      </FlexColumn>
    </div>
  );
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(ChangeHistoryList);
