import PropTypes from 'prop-types';
import propTypes from '@styled-system/prop-types';

const createStyledPropTypes = cssTypes => {
  return PropTypes.oneOfType([cssTypes, PropTypes.arrayOf(cssTypes)]);
};

const cssGlobalProps = ['inherit', 'initial', 'unset'];
const stringOrNumberType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
const cssPropTypes = {
  cursor: PropTypes.oneOf([
    'auto',
    'default',
    'none',
    'context-menu',
    'help',
    'pointer',
    'progress',
    'wait',
    'cell',
    'crosshair',
    'text',
    'vertical-text',
    'alias',
    'copy',
    'move',
    'no-drop',
    'not-allowed',
    'e-resize',
    'n-resize',
    'ne-resize',
    'nw-resize',
    's-resize',
    'se-resize',
    'sw-resize',
    'w-resize',
    'ew-resize',
    'ns-resize',
    'nesw-resize',
    'nwse-resize',
    'col-resize',
    'row-resize',
    'all-scroll',
    'zoom-in',
    'zoom-out',
    'grab',
    'grabbing',
  ]),
  willChange: stringOrNumberType,
  display: PropTypes.oneOf([
    'block',
    'inline',
    'inline-block',
    'flex',
    'inline-flex',
    'grid',
    'inline-grid',
    'flow-root',
    'none',
    'contents',
    'block flow',
    'inline flow',
    'inline flow-root',
    'block flex',
    'inline-flex',
    'block grid',
    'inline grid',
    'block flow-root',
    'table',
    'table-row',
    'list-item',
    'inherit',
    'initial',
    'unset',
  ]),
  lineClamp: PropTypes.oneOfType([PropTypes.oneOf(['none']), PropTypes.number]),
  textOverflow: PropTypes.oneOfType([PropTypes.oneOf(['clip', 'ellipsis']), PropTypes.string]),
  boxOrient: PropTypes.oneOf(['horizontal', 'vertical']),
  overflow: PropTypes.oneOf(['visible', 'hidden', 'clip', 'scroll', 'auto', 'hidden visible', ...cssGlobalProps]),
  wordBreak: PropTypes.oneOf(['normal', 'break-all', 'keep-all', 'break-word', ...cssGlobalProps]),
  visibility: PropTypes.oneOf(['visible', 'hidden', 'collapse', ...cssGlobalProps]),
  whiteSpace: PropTypes.oneOf(['normal', 'nowrap', 'pre', 'pre-wrap', 'pre-line', 'break-spaces', ...cssGlobalProps]),
  textTransform: PropTypes.oneOf([
    'none',
    'capitalize',
    'uppercase',
    'lowercase',
    'full-width',
    'full-size-kana',
    ...cssGlobalProps,
  ]),
};

export const baseComposePropTypes = {
  ...propTypes.space,
  marginInlineStart: createStyledPropTypes(stringOrNumberType),
  w: createStyledPropTypes(stringOrNumberType),
  h: createStyledPropTypes(stringOrNumberType),
  minW: createStyledPropTypes(stringOrNumberType),
  maxW: createStyledPropTypes(stringOrNumberType),
  minH: createStyledPropTypes(stringOrNumberType),
  maxH: createStyledPropTypes(stringOrNumberType),
  cursor: createStyledPropTypes(cssPropTypes.cursor),
  willChange: createStyledPropTypes(cssPropTypes.willChange),
  display: createStyledPropTypes(cssPropTypes.display),
};

export const typographyComposePropTypes = {
  ...propTypes.baseComposePropTypes,
  ...propTypes.typography,
  ...propTypes.color,
  lineClamp: createStyledPropTypes(cssPropTypes.lineClamp),
  boxOrient: createStyledPropTypes(cssPropTypes.boxOrient),
  textOverflow: createStyledPropTypes(cssPropTypes.textOverflow),
  overflow: createStyledPropTypes(cssPropTypes.overflow),
  wordBreak: createStyledPropTypes(cssPropTypes.wordBreak),
  visibility: createStyledPropTypes(cssPropTypes.visibility),
  whiteSpace: createStyledPropTypes(cssPropTypes.whiteSpace),
  textTransform: createStyledPropTypes(cssPropTypes.textTransform),
};

export const blockComposePropTypes = {
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.shadow,
  ...propTypes.position,
  radius: createStyledPropTypes(stringOrNumberType),
  squareSize: createStyledPropTypes(stringOrNumberType),
  bgColor: createStyledPropTypes(stringOrNumberType),
  borderTopRadius: createStyledPropTypes(stringOrNumberType),
  borderBottomRadius: createStyledPropTypes(stringOrNumberType),
  visibility: createStyledPropTypes(cssPropTypes.visibility),
};

export const linkComposePropTypes = {
  ...baseComposePropTypes,
  ...typographyComposePropTypes,
  variant: PropTypes.oneOf(['primary']),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.shape({}),
    }),
    PropTypes.func,
  ]),
  replace: PropTypes.bool,
  title: PropTypes.string,
  component: PropTypes.node,
};

export const elPropTypes = {
  motionBox: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    ...blockComposePropTypes,
    ...propTypes.flexbox,
  },
  box: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    ...blockComposePropTypes,
  },
  button: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    ...blockComposePropTypes,
    variant: PropTypes.oneOf(['link', 'outline', 'clean', 'icon', 'primary']),
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.node,
  },
  content: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    ...blockComposePropTypes,
  },
  dot: {
    children: PropTypes.node,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    ...blockComposePropTypes,
    ...typographyComposePropTypes,
    ...baseComposePropTypes,
    ...propTypes.flexbox,
  },
  flex: {
    ...blockComposePropTypes,
    ...typographyComposePropTypes,
    ...baseComposePropTypes,
    ...propTypes.flexbox,
  },
  grid: {
    ...blockComposePropTypes,
    ...typographyComposePropTypes,
    ...baseComposePropTypes,
    ...propTypes.grid,
  },
  hr: {
    ...propTypes.border,
    ...propTypes.layout,
    ...propTypes.space,
    ...propTypes.position,
    'aria-orientation': PropTypes.oneOf(['horizontal', 'vertical']),
  },
  image: {
    ...blockComposePropTypes,
    ...baseComposePropTypes,
    alt: PropTypes.string.isRequired,
    title: PropTypes.string,
  },
  input: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    ...blockComposePropTypes,
  },
  label: {
    ...blockComposePropTypes,
    ...typographyComposePropTypes,
    ...baseComposePropTypes,
  },
  anchor: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    href: PropTypes.string,
    title: PropTypes.string,
    taget: PropTypes.string,
    isExternal: PropTypes.bool,
    rel: PropTypes.string,
  },
  link: {
    ...linkComposePropTypes,
    forceRender: PropTypes.bool,
    allowed: PropTypes.bool,
  },
  list: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    ...blockComposePropTypes,
    ...propTypes.flexbox,
  },
  listItem: {
    ...typographyComposePropTypes,
    ...blockComposePropTypes,
    ...propTypes.flexbox,
  },
  span: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
  },
  stack: {
    ...blockComposePropTypes,
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    ...propTypes.flexbox,
  },
  svg: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    ...blockComposePropTypes,
    fill: PropTypes.string,
  },
  ternary: {
    cond: PropTypes.bool.isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
  },
  text: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
  },
  tooltip: {
    ...baseComposePropTypes,
    ...typographyComposePropTypes,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(['dark', 'light']),
  },
};
