import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compact } from 'lodash';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';
import t from 'resources/translations';

const DoubleTimeField = ({ onChangeField, placeholder, validation, label, ...props }) => {
  const { name, formName, required, withCheckbox } = props;

  const onChange = async (number) => {
    onChangeField({ formName, fieldName: name, fieldState: number });
  };

  const validateTimes = (value) => {
    if (value && value.active && value.hours[0] && value.hours[1]) {
      const startTime = value.hours[0].split(':');
      const endTime = value.hours[1].split(':');

      if (startTime[0] > endTime[0]) {
        return false;
      } else if (startTime[0] === endTime[0]) {
        return startTime[1] < endTime[1];
      }
      return true;
    }

    return true;
  };

  const validateTimesNotEmpty = (value) => {
    if (value && value.active) {
      if (!(compact(value.hours).length === 2)) {
        return false;
      }
    }
    return true;
  };

  const validateTimesCorrectFormat = (value) => {
    if (value && value.active && value.hours[0] && value.hours[1]) {
      const startTime = compact(value.hours[0].split(':')).length;
      const endTime = compact(value.hours[1].split(':')).length;
      if (startTime !== 2 || endTime !== 2) {
        return false;
      }
    }
    return true;
  };

  const validationFuncs = [
    ...validation,
    {
      condition: value => validateTimes(value),
      errorHint: t('error.afterStartTime'),
    },
    {
      condition: value => validateTimesNotEmpty(value),
      errorHint: t('error.openingHoursNotEmpty'),
    },
    {
      condition: value => validateTimesCorrectFormat(value),
      errorHint: t('error.openingHoursWrongFormat'),
    },
  ];

  validateTimes();
  validateTimesNotEmpty();

  return (
    <FormField validation={validationFuncs} {...props}>
      <Input.DoubleTime
        name={name}
        label={label}
        required={required}
        value={props.value}
        onChange={onChange}
        placeholder={placeholder}
        withCheckbox={withCheckbox}
      />
    </FormField>
  );
};

DoubleTimeField.defaultProps = {
  value: '',
  label: '',
  validation: [],
  placeholder: '',
  required: false,
  withCheckbox: false,
};

DoubleTimeField.propTypes = {
  value: PropTypes.shape({
    active: PropTypes.bool,
    hours: PropTypes.arrayOf(PropTypes.string),
  }),
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.func.isRequired,
      errorHint: PropTypes.string,
    }),
  ),
  withCheckbox: PropTypes.bool,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(DoubleTimeField);
