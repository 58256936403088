import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import Icon from '@old/components/icon';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import RowAligner from '@old/components/common/RowAligner';
import t from 'resources/translations';
import WeekPicker from '@old/components/custom/WeekPicker';
import { useOutsideClick } from '@old/hooks';
import config from '@old/config';
import Center from '@old/components/common/Center';

const WeekSelector = ({ weekDate, isMobile, isTablet, addWeek, subtractWeek, setWeekDate }) => {
  return (
    <>
      {(isMobile || isTablet) ? (
        <WeekSelectorMobile
          weekDate={weekDate}
          addWeek={addWeek}
          subtractWeek={subtractWeek}
          setWeekDate={setWeekDate}
        />
      ) : (
        <WeekSelectorDesktop
          weekDate={weekDate}
          addWeek={addWeek}
          subtractWeek={subtractWeek}
          setWeekDate={setWeekDate}
        />
      )}
    </>
  );
};
WeekSelector.defaultProps = {
  setWeekDate: () => { },
};

WeekSelector.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  weekDate: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  setWeekDate: PropTypes.func,
  addWeek: PropTypes.func.isRequired,
  subtractWeek: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app: { isMobile, isTablet } }) => ({ isMobile, isTablet });

export default connect(mapStateToProps)(WeekSelector);

const WeekSelectorDesktop = ({ weekDate, addWeek, subtractWeek, setWeekDate }) => {
  const refPicker = useRef();
  const [showPicker, setShowPicker] = useState(false);
  useOutsideClick(refPicker, () => setShowPicker(false));

  return (
    <RowAligner>
      <ButtonSimple onClick={subtractWeek}>
        <Icon.ArrowLeft className="fill-teal" />
      </ButtonSimple>
      <div ref={refPicker} className="input-wrapper border-teal-light shadow-md">
        <label>{t('weekSelector.chooseDate')}</label>
        <div className="px-2">
          {`${moment(weekDate.start).format(config.dateFormat)} - ${moment(weekDate.end).format(config.dateFormat)}`}
        </div>
        <ButtonSimple onClick={() => setShowPicker(prevShowPicker => !prevShowPicker)}>
          <div className="ml-4 px-2">
            <Icon.CalendarShadow className="fill-teal" />
          </div>
        </ButtonSimple>
        {showPicker && (
          <div className="input-picker-wrapper">
            <WeekPicker
              value={weekDate}
              onChange={val => setWeekDate(val)}
              close={() => setShowPicker(false)}
            />
          </div>
        )}
      </div>
      <ButtonSimple onClick={addWeek}>
        <Icon.ArrowRight className="fill-teal" />
      </ButtonSimple>
    </RowAligner>
  );
};

WeekSelectorDesktop.propTypes = {
  weekDate: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  setWeekDate: PropTypes.func.isRequired,
  addWeek: PropTypes.func.isRequired,
  subtractWeek: PropTypes.func.isRequired,
};

const WeekSelectorMobile = ({ weekDate, addWeek, subtractWeek, setWeekDate }) => {
  const refPicker = useRef();
  const [showPicker, setShowPicker] = useState(false);

  return (
    <div ref={refPicker}>
      <RowAligner separated="small">
        <ButtonSimple onClick={subtractWeek}>
          <Icon.ArrowLeft className="fill-teal" />
        </ButtonSimple>
        <div>
          {`${moment(weekDate.start).format(config.dateFormat)} - ${moment(weekDate.end).format(config.dateFormat)}`}
        </div>
        <ButtonSimple onClick={() => setShowPicker(prevShowPicker => !prevShowPicker)}>
          <Center>
            <Icon.CalendarRange className="fill-teal" />
          </Center>
        </ButtonSimple>
        {showPicker && (
          <div className="input-picker-wrapper mobile">
            <WeekPicker
              showPicker={showPicker}
              value={weekDate}
              onChange={val => setWeekDate(val)}
              close={() => setShowPicker(false)}
            />
          </div>
        )}
        <ButtonSimple onClick={addWeek}>
          <Icon.ArrowRight className="fill-teal" />
        </ButtonSimple>
      </RowAligner>
    </div>
  );
};

WeekSelectorMobile.propTypes = {
  weekDate: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  setWeekDate: PropTypes.func.isRequired,
  addWeek: PropTypes.func.isRequired,
  subtractWeek: PropTypes.func.isRequired,
};

