/* eslint-disable max-len */
import React from 'react';

const VisibilityOn = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,5.8c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2l0,0C10.2,6.8,9.2,5.8,8,5.8L8,5.8z M8,11.6C6,11.6,4.4,10,4.4,8
        S6,4.3,8,4.3S11.6,6,11.6,8l0,0C11.6,10,10,11.6,8,11.6L8,11.6z M8,2.5L8,2.5c-3.6,0-6.7,2.2-8,5.4L0,8c1.3,3.2,4.4,5.5,8,5.5
        s6.7-2.2,8-5.4L16,8C14.7,4.8,11.6,2.5,8,2.5C8,2.5,8,2.5,8,2.5z"
      />
    </svg>
  );
};

export default VisibilityOn;
/* eslint-enable max-len */
