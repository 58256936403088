import { PageTitle } from 'components/combinations';
import { Box, Stack } from 'components/elements';
import t from 'resources/translations';
import { TicketOfferService } from 'services';
import { useNewLayoutColor, mapOffersFormToParams } from 'utils';
import { getErrorMessage, notify } from 'old/utils';
import { TTicketOffersFormState } from 'resources/types/TicketOfferTypes';
import { useHistory } from 'react-router';
import TicketOffersForm from 'views/Tickets/TicketOffersForm';

const TicketOffersAdd = () => {
  useNewLayoutColor();
  const history = useHistory();

  const initialValues = {
    name: '',
    description: '',
    price: undefined,
    count: '',
    validity: 1,
    visibility: 'hidden',
    activable: 'false',
    eventTypes: [],
  } as TTicketOffersFormState;

  const onSubmit = async (values: TTicketOffersFormState) => {
    try {
      const createParams = mapOffersFormToParams(values);
      await TicketOfferService.create(createParams);
      notify(t('add.ticketOffers'));
      history.push('/tickets');
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const submitLabels = {
    loading: `${t('message.adding')}...`,
    default: t('model.add.ticketOffer'),
  };

  return (
    <Stack p={['md', 'lg']} borderRadius={4} backgroundColor="white">
      <PageTitle title={t('ticketOffers.addTicketOffert')} back="/tickets" px={0} />
      <Box pt="md">
        <TicketOffersForm initialValues={initialValues} onSubmit={onSubmit} submitLabels={submitLabels} />
      </Box>
    </Stack>
  );
};

export default TicketOffersAdd;
