/**
 * @module FlexColumn
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

/**
 * Wrapper for vertically arranged elements with default margin between them
 * @param  {*} props.children
 * @param  {Boolean|"small"} [props.separated] specifies if items inside should be separated
 * @param  {"grey-light"} [props.background] specifies background color
 * @param  {Boolean|"large"} [props.padded] specifies padding
 * @param  {Boolean} [props.marginBottom] specifies if there should be a bottom margin
 */
const FlexColumn = ({ children, separated, background, padded, marginBottom, center }) => {
  const className = cls('flex flex-1 flex-col hm-flex-col', {
    'bg-grey-light': background === 'grey-light',
    'p-2': padded === true,
    'md:p-6 p-4': padded === 'large',
    separated: separated === true,
    'separated-small': separated === 'small',
    'mb-4': marginBottom,
  });

  return (
    <div className={className}>
      {React.Children.map(children, (child) => {
        if (child) { // we don't want to wrap 'falsy' values
          return <div className={cls('flex-1', { centered: center })}>{child}</div>;
        }

        return null;
      })}
    </div>
  );
};

FlexColumn.defaultProps = {
  separated: true,
  background: null,
  padded: false,
  marginBottom: false,
  center: false,
};

FlexColumn.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  separated: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['small']),
  ]),
  background: PropTypes.oneOf([
    'grey-light', // feel free to add new one if needed
  ]),
  padded: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['large']),
  ]),
  marginBottom: PropTypes.bool,
  center: PropTypes.bool,
};

export default FlexColumn;
