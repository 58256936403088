import React from 'react';
import Measure from 'react-measure';

import t from 'resources/translations';
import { Box, Grid } from '@elements';
import Message from '@old/components/common/Message';
import GroupGridItem from '@old/components/view/gridItem/Group';
import { countGridColumns } from 'old/utils';

const GroupsGrid = ({ data }) => {
  return (
    <React.Fragment>
      {!data.length && <Message info>{t('groupsGrid.noGroups')}</Message>}
      <Measure bounds>
        {({ measureRef: itemRef, _, contentRect }) => {
          const columns = countGridColumns(contentRect.bounds.width, 380);
          return (
            <div ref={itemRef}>
              <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap="md">
                {data.map(group => (
                  <Box minWidth={0} key={group.id}>
                    <GroupGridItem group={group} />
                  </Box>
                ))}
              </Grid>
            </div>
          );
        }}
      </Measure>
    </React.Fragment>
  );
};

GroupsGrid.defaultProps = {
  data: [],
};

export default GroupsGrid;
