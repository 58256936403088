/**
 * @module TagInput
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FlexColumn from '@old/components/common/FlexColumn';
import Tags from '@old/components/common/Tags';
import Icon from '@old/components/icon';
import InputWithAction from '@old/components/common/InputWithAction';
import Button from '@old/components/guide/Button';
import { useKey } from '@old/hooks';
import Input from '.';

/**
 *  @typedef  {Object} InputValue
 *  @property {String|Number} key
 *  @property {String} label
 *
 *  @typedef  {Object} ValidationsInput
 *  @property {Function} condition
 *  @property {String} errorHint
 *
 *  @typedef  {Object} OptionsInput
 *  @property {'Email'|'Time'|'Text'} props.type
 *  @property {ValidationsInput} [props.validationsInput]  validation functions before adding the tag
 *  @property {String} [props.header]
 *
 *  @typedef  {Object} OptionsTags
 *  @property {Function|Boolean=false} [props.customRenderTag] custom method tag label rendering
 */

/**
 * Component that display input with possibility to insert multiple data type (Email, Time, Text)
 * Items already insert display as Tags below input field, with button that give user a possibility to delete selected data
 *
 * @param  {InputValue} [props.value]
 * @param  {Function} props.onChange
 * @param  {String} [props.placeholder]
 * @param  {String} [props.label]
 * @param  {Boolean} [props.required]
 * @param  {String} [props.name]
 * @param  {OptionsInput} [props.options]
 * @param  {OptionsTags} [props.tagsOptions]
 * @param  {String} [props.defaultValue]
*/

const TagInput = ({ value, placeholder, label, required, options, defaultValue, tagsOptions, ...props }) => {
  const [query, setQuery] = useState(defaultValue || '');
  const { validationsInput, type, ...restOptions } = options;

  const validation = () => {
    const currentTags = [...value];
    const errorList = [];

    const tagsValue = currentTags.map(tag => tag.value);

    if (tagsValue.includes(query)) return false;

    validationsInput.forEach((validationItem) => {
      if (!validationItem.condition(query, value)) {
        errorList.push(validationItem.errorHint);
      }
      return true;
    });
    if (errorList.length > 0) {
      return false;
    }
    return true;
  };

  const onChange = (textValue) => {
    setQuery(textValue);
  };

  const addTag = () => {
    const tag = { key: query, value: query, label: query };
    const currentTags = [...value, tag];
    if (validation()) {
      setQuery('');
      props.onChange(currentTags);
    }
  };
  const deleteTag = (tagValue) => {
    const currentTags = [...value].filter(tag => tag.key !== tagValue.key);
    props.onChange(currentTags);
  };

  const onEnter = useKey(() => addTag(), ('enter'));
  const TagComponent = Input[type];

  return (
    <FlexColumn>
      <InputWithAction>
        <TagComponent
          value={query}
          onChange={onChange}
          placeholder={placeholder}
          label={label}
          required={required}
          onKeyDown={onEnter}
          {...restOptions}
        />
        <Button onClick={addTag} disabled={!validation()} tabIndex={-1}>
          <Icon.Add />
        </Button>
      </InputWithAction>
      <Tags items={value} onDelete={deleteTag} {...tagsOptions} />
    </FlexColumn>
  );
};

TagInput.defaultProps = {
  value: [],
  name: '',
  placeholder: '',
  label: '',
  required: false,
  tagsOptions: {},
  defaultValue: '',
};

TagInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  })),
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  options: PropTypes.shape({
    validationsInput: PropTypes.arrayOf(
      PropTypes.shape({
        condition: PropTypes.func.isRequired,
        errorHint: PropTypes.string,
      }),
    ),
    type: PropTypes.oneOf([
      'Email',
      'Time',
      'Text',
    ]).isRequired,
    header: PropTypes.string,
  }).isRequired, // options are additional button props
  tagsOptions: PropTypes.shape({}), // options are additional tags components props
};

export default TagInput;
