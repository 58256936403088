import React from 'react';
import PropTypes from 'prop-types';

import t from 'resources/translations';
import Icon from '@old/components/icon';
import Input from '@old/components/input';
import ButtonSimple from '@old/components/guide/ButtonSimple';

const SearchInput = ({ label, value, onChange, clear, placeholder, name }) => {
  const icon = (
    <ButtonSimple onClick={value ? clear : null}>
      {value ? <Icon.Cancel /> : <Icon.Search />}
    </ButtonSimple>
  );
  return (
    <Input.Text
      label={label}
      value={value}
      onChange={onChange}
      placeholder={placeholder || t('general.search')}
      name={name}
      icon={icon}
    />
  );
};

SearchInput.defaultProps = {
  label: '',
  value: '',
  name: 'search',
};

SearchInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string,
};

export default SearchInput;
