import { Box } from 'components/elements';
import Bill from 'models/Bill';
import { useModal } from 'old/hooks';
import { TBoxProps } from 'resources/types/elementsTypes';

type TBillOpenSetPaymentAction = TBoxProps & {
  bill: Bill;
  children: ({ onClick }: { onClick: () => void }) => JSX.Element;
  onSubmitCb: () => void;
  onChangeDueCb: () => void;
};

export const BillOpenSetPaymentAction = ({
  bill,
  onSubmitCb,
  onChangeDueCb,
  children,
  ...boxProps
}: TBillOpenSetPaymentAction) => {
  const modalPayment = useModal(bill.billableType === 'Ticket' ? 'PAYMENT_TICKET' : 'PAYMENT_EVENT');

  const onOpenPaymenModal = () => {
    return modalPayment.onOpen({ bill, onSubmitCb, onChangeDueCb });
  };

  return (
    <Box lineHeight="normal" {...boxProps}>
      {children({ onClick: onOpenPaymenModal })}
    </Box>
  );
};

type TBillOpenReturnPaymentAction = TBoxProps & {
  bill: Bill;
  onSubmitCb: () => void;
  children: ({ onClick }: { onClick: () => void }) => JSX.Element;
};

export const BillOpenReturnPaymentAction = ({
  bill,
  onSubmitCb,
  children,
  ...boxProps
}: TBillOpenReturnPaymentAction) => {
  const modalReturnPayment = useModal('PAYMENT_RETURN');
  const onOpenReturnPaymenModal = () => {
    return modalReturnPayment.onOpen({ bill, onSubmitCb });
  };

  return (
    <Box lineHeight="normal" {...boxProps}>
      {children({ onClick: onOpenReturnPaymenModal })}
    </Box>
  );
};
