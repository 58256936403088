import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Lottie } from '@crello/react-lottie';

const LottieIcon = forwardRef(({ iconData, options, small, large }, ref) => { // eslint-disable-line react/prop-types
  const [isStopped, setIsStopped] = useState(true);
  const sizes = {
    small: 14,
    medium: 28,
    large: 42,
  };

  let size = 'medium';
  if (small) size = 'small';
  if (large) size = 'large';

  const defaultOptions = {
    loop: false,
    autoplay: false,
    renderer: 'canvas',
    animationData: iconData,
    ...options,
  };

  const eventListener = [{
    name: 'complete',
    callback: () => setIsStopped(prevState => !prevState),
  }];

  useImperativeHandle(ref, () => ({
    triggerAnimations() {
      setIsStopped(false);
    },
  }));

  return (
    <Lottie
      className="lottie-icon"
      width={sizes[size]}
      height={sizes[size]}
      config={defaultOptions}
      playingState={isStopped ? 'stopped' : 'playing'}
      lottieEventListeners={eventListener}
    />
  );
});

// LottieIcon.defaultProps = {
//   options: null,
//   small: false,
//   large: false,
// };

// LottieIcon.propTypes = {
//   iconData: PropTypes.shape({}).isRequired,
//   // https://github.com/chenqingspring/react-lottie
//   options: PropTypes.shape({}),
//   small: PropTypes.bool,
//   large: PropTypes.bool,
// };

export default LottieIcon;
