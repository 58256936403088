/**
 * @module Card
 */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';

/**
 * Wrapper with blue background and optionally centered content and optinal footer content
 * @param  {*} props.children
 * @param  {Boolean} props.isMobile specifies whether component is running on mobile device
 * @param  {*} props.footerContent adds content at the bottom of the component
 * @param  {Boolean} [props.fullHeight] specifies whether component should have full possible height
 */
const Card = ({ children, isMobile, footerContent, fullHeight }) => {
  const style = { boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5' };

  return (
    <div style={style} className={cls('flex flex-col', { rounded: !isMobile, 'h-full': fullHeight })}>
      <div className="card">{children}</div>
      {footerContent && (
        <div className="py-2 bg-grey text-center rounded-b">{footerContent}</div>
      )}
    </div>
  );
};

Card.defaultProps = {
  fullHeight: false,
};

Card.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line
  isMobile: PropTypes.bool.isRequired,
  footerContent: PropTypes.any, // eslint-disable-line
  fullHeight: PropTypes.bool,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(Card);
