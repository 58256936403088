import RowAligner from '@old/components/common/RowAligner';
import Icon from '@old/components/icon';
import IndicatorColor from '@old/components/common/IndicatorColor';
import Allow from '@old/components/common/Allow';

const PairLinked = ({ modelItem, event, onClickUnlink }) => {
  if (!modelItem) return null;

  const options = onClickUnlink
    ? {
        onClick: onClickUnlink,
        className: 'teal-hover',
        style: { cursor: 'pointer' },
      }
    : {};

  return (
    <RowAligner separated={false}>
      <Allow
        permissionKey={['participations.detach_horse']}
        permissionParams={{ modelItem: event }}
        additionalCheck={!event?.isDeleted()}
        fallback={
          <div className="text-teal">
            <Icon.Link small />
          </div>
        }
      >
        <div {...options}>
          <Icon.Link small />
        </div>
      </Allow>
      <span className="mx-1">{modelItem.getName()}</span>
      <IndicatorColor hexColor={modelItem.getSlugInfo().color} />
    </RowAligner>
  );
};

PairLinked.defaultProps = {
  onClickUnlink: null,
  modelItem: null,
  event: null,
};

export default PairLinked;
