import moment from 'moment';
import flatten from 'lodash/flatten';
import truncate from 'lodash/truncate';

import t from 'resources/translations';
import config from '@old/config';
import { Farm } from '@old/model/Farm';
import BaseModel from '@old/model/BaseModel';

class Members extends BaseModel {
  constructor(options) {
    super({
      modelName: 'members',
      dataKey: 'memberships',
      updateKey: 'membership',
      basePath: '/api/v1/farms/{farmId}/memberships',
      ItemClass: Member,
      ...options,
    });
  }

  static mapToSelectOption = item => {
    return {
      key: item.id,
      value: item.id,
      label: item.getFullName(),
      image: item.getAvatar('thumb'),
      skill: item.getSkill(),
      isGuest: item.isGuest(),
      isDeleted: item.isDeleted(),
    };
  };

  logout = async () => {
    await this.client.delete('/logout');
  };

  login = async (email, password) => {
    const response = await this.client.post('/login', { user: { email, password } });
    const token = response.headers.authorization;
    return [token, this.parse(response.data, { fromUserData: true })];
  };

  loginToFarm = async (email, password) => {
    const slug = window.location.pathname.split('/')[1];
    const response = await this.client.post(`/farms/${slug}/login`, { user: { email, password } });
    const token = response.headers.authorization;
    const { farm, identity } = response.data;
    return { token, farm: new Farm(farm), member: new Member(identity, { fromUserData: true }) };
  };

  googleLogin = async (idToken, user) => {
    const response = await this.client.post('/login/google', { id_token: idToken, user });
    const token = response.headers.authorization;
    return [token, this.parse(response.data, { fromUserData: true })];
  };

  facebookLogin = async (idToken, user) => {
    const response = await this.client.post('/login/facebook', { id_token: idToken, user });
    const token = response.headers.authorization;
    return [token, this.parse(response.data, { fromUserData: true })];
  };

  sendSmsActivation = async () => {
    const response = await this.client.post('/login/send_sms');
    return response.data;
  };

  confirmPhone = async code => {
    const response = await this.client.post('/login/verify_sms_code', { sms_code: code });
    return this.parse(response.data, { fromUserData: true });
  };

  signUp = async params => {
    const response = await this.client.post('/signup', { user: params });
    return response;
  };

  signUpChild = async user => {
    const response = await this.client.post('/signup/child', { user });
    return response;
  };

  invitation = async params => {
    const response = await this.client.patch('/invitation ', params);
    return response;
  };

  sendEmailWIthNewPassword = async email => {
    const response = await this.client.post('/password', { user: { email } });
    return response.data;
  };

  changePassword = async ({ user }) => {
    const response = await this.client.patch('/password', { user });
    return response;
  };

  fetchProfile = async () => {
    const response = await this.client.get(`/api/v1/farms/${this.farmId}/profile`);
    return this.parse(response.data, { fromUserData: true });
  };

  accept = async membershipId => {
    const membership = await this.client.patch(`${this.basePath}/${membershipId}/accept`);
    return this.parse(membership);
  };

  reject = async membershipId => {
    const membership = await this.client.patch(`${this.basePath}/${membershipId}/reject`);
    return this.parse(membership);
  };

  block = async membershipId => {
    const response = await this.client.patch(`${this.basePath}/${membershipId}/block`);
    return this.parse(response.data);
  };

  unblock = async membershipId => {
    const response = await this.client.patch(`${this.basePath}/${membershipId}/unblock`);
    return this.parse(response.data);
  };

  addFunds = async (membershipId, funds) => {
    const membership = await this.client.patch(`${this.basePath}/${membershipId}/add_funds`, { funds });
    return this.parse(membership);
  };

  subtractFunds = async (membershipId, funds) => {
    const membership = await this.client.patch(`${this.basePath}/${membershipId}/subtract_funds`, { funds });
    return this.parse(membership);
  };

  getShifts = async (membershipId, { cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/${membershipId}/shifts`, { cancelToken, params });
    const shifts = (response.data.shifts || []).map(shift => ({
      id: shift.id,
      beginning: moment(shift.beginning),
      ending: moment(shift.ending),
    }));

    return [shifts, response.data.pagination];
  };

  addShift = async (membershipId, startDate, endDate) => {
    const response = await this.client.post(`${this.basePath}/${membershipId}/shifts`, {
      shift: {
        beginning: startDate,
        ending: endDate,
      },
    });

    return response;
  };

  updateShift = async (membershipId, shiftId, startDate, endDate) => {
    const response = await this.client.patch(`${this.basePath}/${membershipId}/shifts/${shiftId}`, {
      shift: {
        beginning: startDate,
        ending: endDate,
      },
    });

    return response;
  };

  deleteShift = async (membershipId, shiftId) => {
    const response = await this.client.delete(`${this.basePath}/${membershipId}/shifts/${shiftId}`);

    return response;
  };

  getNumbersOfAwaitings = async () => {
    const result = await this.fetchAll({ with_role: 'client', with_status: ['pending'], page: 1, per_page: 1 });
    return result[1].count;
  };

  getStatistics = async (membershipId, params = {}) => {
    let { start, end } = params;
    if (!start || !end) {
      start = moment().subtract(7, 'days').startOf('day').toISOString();
      end = moment().subtract(1, 'days').endOf('day').toISOString();
    }

    try {
      const result = await this.client.get(`${this.basePath}/${membershipId}/statistics`, {
        params: {
          'stats[start]': start,
          'stats[end]': end,
        },
      });
      return result.data.statistics;
    } catch (e) {
      return null;
    }
  };

  getWeeklyStatistics = async (membershipId, params = {}) => {
    let { start, end } = params;
    if (!start || !end) {
      start = moment().subtract(7, 'days').startOf('day').toISOString();
      end = moment().subtract(1, 'days').endOf('day').toISOString();
    }

    try {
      const result = await this.client.get(`${this.basePath}/${membershipId}/statistics/weekly`, {
        params: {
          'stats[start]': start,
          'stats[end]': end,
        },
      });
      return result.data.statistics;
    } catch (e) {
      return null;
    }
  };

  rate = async (membershipId, params) => {
    const rate = {
      ...params,
      comment: params.comment?.trim(),
    };

    const response = await this.client.post(`/api/v1/farms/${this.farmId}/instructors/${membershipId}/rate`, { rate });
    return response.data;
  };

  toggleRating = async (membershipId, rateId) => {
    const toggleRatingPath = `/api/v1/farms/${this.farmId}/instructors/${membershipId}/toggle_rate`;
    const response = await this.client.post(toggleRatingPath, { rate_id: rateId });
    return response.data;
  };

  fetchRatings = async (membershipId, { cancelToken, ...params }) => {
    const fetchRatingsPath = `/api/v1/farms/${this.farmId}/instructors/${membershipId}/rates`;
    const response = await this.client.get(fetchRatingsPath, { cancelToken, params });
    return [
      response.data.rates.map(rating => ({
        ...rating,
        rater: new Member(rating.rater),
      })),
      response.data.pagination,
    ];
  };

  deleteRating = async (membershipId, rateId) => {
    const deleteRatingPath = `/api/v1/farms/${this.farmId}/instructors/${membershipId}/rate/${rateId}`;
    const response = await this.client.delete(deleteRatingPath);
    return response.data;
  };

  addGuest = guest => this.client.post(`/api/v1/farms/${this.farmId}/guests`, { guest });

  editGuest = (guestId, guest) => this.client.patch(`/api/v1/farms/${this.farmId}/guests/${guestId}`, { guest });

  deleteGuest = guestId => this.client.delete(`/api/v1/farms/${this.farmId}/guests/${guestId}`);

  updateUser = async (userId, changes) => {
    const response = await this.client.patch(`/api/v1/users/${userId}`, { user: changes });
    return this.parse(response.data, { fromUserData: true });
  };

  deleteUser = async () => {
    const response = await this.client.delete('/api/v1/users/');
    return response;
  };

  getParent = async member => {
    if (!member.isChild()) return null;

    const response = await this.client.get(`/api/v1/users/${member.identityId}/parent`);
    return this.parse(response.data, { fromUserData: true });
  };

  getChildren = async member => {
    if (member.isChild) return null;

    const response = await this.client.get(`/api/v1/users/${member.identtyId}/children`);
    return response.data.users.map(item => this.parse(item, { fromUserData: true }));
  };

  fetchInstructorOptions = async (keyword, page, fetchParams) => {
    const [instructors, pagination] = await this.fetchAll({
      with_role: 'instructor',
      with_status: ['pending', 'active'],
      per_page: 20,
      keyword,
      page,
      ...fetchParams,
    });

    const mappedOptions = instructors.map(Members.mapToSelectOption);

    return {
      options: mappedOptions,
      hasMore: pagination.pages > page,
      additional: { page: page + 1 },
    };
  };

  fetchClientOptions = async (keyword, page, fetchParams) => {
    const [clients, pagination] = await this.fetchAll({
      with_role: 'client',
      with_status: ['pending', 'active'],
      per_page: 20,
      keyword,
      page,
      ...fetchParams,
    });

    const mappedOptions = clients.map(Members.mapToSelectOption);

    return {
      options: mappedOptions,
      hasMore: pagination.pages > page,
      additional: { page: page + 1 },
    };
  };
}

class Member {
  constructor(data, options = {}) {
    if (data) {
      if (options.fromUserData) {
        this.mapRawUserToMembership(data);
      } else {
        this.mapRawMembershipToMembership(data);
      }
    } else {
      // deleted user
      this.mapRawMembershipToMembership({
        id: 0,
        identity: {
          id: 0,
        },
        firstname: '',
        lastname: '',
        birthday: moment(),
        identity_type: 'deleted',
      });
    }
  }

  mapRawMembershipToMembership = membershipData => {
    let identity = { ...membershipData.identity };
    const { role } = membershipData;

    if (membershipData.identity_type === 'Guest') {
      identity = {
        id: identity.id,
        firstname: membershipData.nickname || identity.name,
        lastname: '',
        phone: identity.phone,
        avatar: null,
        birthday: moment(),
        email: '',
      };
    }
    this.identityId = identity ? identity.id : 0;
    this.firstname = identity ? identity.firstname : '';
    this.lastname = identity ? identity.lastname : '';
    this.birthday = identity ? moment(identity.birthday, 'YYYY-MM-DD') : moment();
    this.email = identity ? identity.email : null;
    this.phone = identity ? identity.phone : null;
    this.rating = identity ? identity.rating : null;
    this.avatar = identity ? identity.avatar : null;
    this.phoneVerified = identity ? identity.phone_verified : null;
    this.privateProfile = identity ? identity.private_data_hidden : null;
    this.parentId = identity ? identity.parent_id : null;

    this.identityType = membershipData.identity_type;
    this.id = membershipData.id;
    this.role = role && role.abilities ? role.abilities[0] : role;

    this.permissionsList = role && role.permissions && role.permissions;
    this.permissions = role && role.permissions && this.mapPermissions(role.permissions);
    this.privileges = membershipData.meta ? membershipData.meta.privileges : [];
    // for clients who can't edit their own profiles
    if (this.permissions && ((role && role.abilities && role.abilities[0] === 'client') || role === 'client')) {
      this.permissions['memberships.update'].allowedIf = ['isOwn'];
    }

    this.status = membershipData.status;
    this.saldo = membershipData.saldo;
    this.skill = membershipData.skill;
    this.rating = membershipData.rating;

    if ((role && role.abilities && role.abilities[0] === 'instructor') || role === 'instructor') {
      this.aboutMe = membershipData.additional_info;
    }
    if ((role && role.abilities && role.abilities[0] === 'client') || role === 'client') {
      this.userNote = membershipData.additional_info;
    }
  };

  mapPermissions = permissions => {
    // permissions.events.activate_if_is_instructor = false;
    // categoryKey: "places", "events" etc.
    const permissionsObjects = flatten(
      Object.keys(permissions).map(categoryKey => {
        const permissionsCategory = permissions[categoryKey];
        // actionKey: "show", "create", "activate_if_is_instructor" etc.
        return Object.keys(permissionsCategory).map(actionKey => {
          if (actionKey.includes('if')) {
            const [action, condition] = actionKey.split('_if_'); // activate_if_is_instructor => [activate, is_instructor]
            if (permissionsCategory[actionKey] === true) {
              return {
                [`${categoryKey}.${action}`]: { allowedIf: [condition] },
              };
            } else {
              // conditional permission keys with false value are omitted
              return null;
            }
          } else if (actionKey.endsWith('_own')) {
            const action = actionKey.replace('_own', '');
            if (permissionsCategory[actionKey] === true) {
              return { [`${categoryKey}.${action}`]: { allowedIf: ['isOwn'] } };
            } else {
              // conditional permission keys with false value are omitted
              return null;
            }
          } else {
            return { [`${categoryKey}.${actionKey}`]: { allowed: permissionsCategory[actionKey] } };
          }
        });
      })
    ).filter(Boolean);

    const result = {};
    permissionsObjects.forEach(item => {
      // permissionKey: "events.create" etc.
      const permissionKey = Object.keys(item)[0];
      if (result[permissionKey]) {
        result[permissionKey] = {
          ...result[permissionKey],
          ...item[permissionKey],
          allowedIf: [...(result[permissionKey].allowedIf || []), ...item[permissionKey].allowedIf],
        };
      } else {
        // can be just: result[permissionKey] = item[permissionKey] but we wants to be consistents and we need ...
        // ... to add key 'allowed' for every object due to missing 'comments.update' permission in API
        result[permissionKey] = {
          allowed: item[permissionKey].allowed || false,
          ...item[permissionKey],
        };
      }
    });
    return result;
  };

  mapRawUserToMembership = userData => {
    const { membership, ...identity } = userData;
    this.mapRawMembershipToMembership({ ...membership, identity });
  };

  static mapToSaveData = data => {
    const membership = {
      role_id: data.role,
      skill: data.skill,
      additional_info: data.aboutMe || data.aboutMe === '' ? data.aboutMe : data.userNote,
      nickname: data.nickname,
    };

    Object.keys(membership).forEach(fieldName => {
      if (membership[fieldName] === undefined) delete membership[fieldName];
    });

    return membership;
  };

  static mapToSaveUserData = data => {
    const user = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      password: data.password || undefined,
      phone: data.phone,
      birthday: data.birthday && moment(data.birthday, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      avatar_attributes: {
        file: data.avatar,
      },
    };

    Object.keys(user).forEach(fieldName => {
      if (fieldName === 'avatar_attributes' && user.avatar_attributes.file === undefined) delete user[fieldName];
      if (user[fieldName] === undefined) delete user[fieldName];
    });

    return user;
  };

  static mapToFormData = data => {
    const userData = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      password: data.password,
      phone: data.phone,
      birthday: data.birthday.format(config.dateFormat),
      avatar: data.getAvatar(),
    };
    return userData;
  };

  updateUser = async changes => this.model.updateUser(this.identityId, changes);

  isChild() {
    return this.parentId != null;
  }

  isGuest() {
    return this.identityType === 'Guest';
  }

  getUserNote() {
    return this.userNote;
  }

  isPending() {
    return this.status === 'pending';
  }

  isBlocked() {
    return this.status === 'blocked';
  }

  isRejected() {
    return this.status === 'rejected';
  }

  isActive() {
    return this.status === 'active';
  }

  isInstructor() {
    return this.role === 'instructor';
  }

  isClient() {
    return this.role === 'client';
  }

  isOwner() {
    return this.role === 'owner';
  }

  isDeleted() {
    return this.status === 'deleted';
  }

  getName(limit = false) {
    return limit ? truncate(this.getFullName(), { length: limit, omission: '...' }) : this.getFullName();
  }

  getFullName() {
    if (this.isDeleted()) {
      return t('general.userDeleted');
    }

    if (this.isGuest()) {
      return this.firstname || t('general.guest');
    }

    return `${this.firstname} ${this.lastname}`;
  }

  getSkill() {
    return this.skill || 'missing';
  }

  getProfileUrl() {
    if (this.isInstructor()) {
      return `/instructors/${this.id}`;
    }

    return `/users/${this.id}`;
  }

  getAvatar(size) {
    if (!this.avatar || !this.avatar.url) {
      return this.isGuest() ? '/img/guest-placeholder.png' : '/img/user-avatar-placeholder.png';
    }
    return this.avatar.url[size] || this.avatar.url.medium;
  }

  getSlugInfo() {
    const difficulty = config.difficulty.find(d => this.skill === d.value) || config.difficulty[0];

    const slugInfo = {
      color: difficulty.color,
      text: difficulty.slug,
    };

    return slugInfo;
  }

  getAge() {
    return parseInt(moment().format('YYYY'), 10) - parseInt(this.birthday.format('YYYY'), 10) || 0;
  }

  getBirthday() {
    return this.birthday.format('LL');
  }

  block = async () => {
    return this.model.block(this.id);
  };

  unblock = async () => {
    return this.model.unblock(this.id);
  };

  update = async (changes, user) => {
    if (user) {
      return this.updateUser(changes);
    }
    return this.model.update(this.id, changes);
  };

  isPhoneConfirmed() {
    return !!this.phoneVerified;
  }

  getPermission(permissionKey) {
    return this.privileges.includes(permissionKey);
  }

  getAccesByKey(permModel, permAction) {
    return this.permissionsList[permModel][permAction];
  }
}

export { Members, Member };
