import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import cls from 'classnames';

import { getCalendarKey } from 'old/utils';
import Day from '@old/components/view/calendar/CalendarDay';
import FlexColumn from '@old/components/common/FlexColumn';
import { setDay } from 'store/actions';

const Week = ({ weekStart, calendarKey, setDayDate, dayStart, isMobile }) => {
  const clickDayOfTheWeek = dayOfTheWeek => {
    setDayDate(dayOfTheWeek, calendarKey);
  };
  const daysIndexes = [1, 2, 3, 4, 5, 6, 7];

  return (
    <>
      {!isMobile ? (
        <div className="bg-grey-light flex">
          <div style={{ flex: '1 0' }} />
          <div className="bg-grey-light flex" style={{ flex: '9 0 0' }}>
            {daysIndexes.map(dayIndex => {
              const dayOfTheWeek = moment(weekStart).isoWeekday(dayIndex);
              return (
                <div
                  key={dayIndex}
                  onClick={() => clickDayOfTheWeek(dayOfTheWeek)}
                  className={cls('centered cursor-pointer  flex-1', {
                    'bg-teal-light text-white': dayStart.isoWeekday() === dayIndex,
                  })}
                >
                  <div className="my-2 capitalize">
                    <FlexColumn separated="small">
                      <div>{dayOfTheWeek.format('dddd')}</div>
                      <div className="font-bold text-lg">{dayOfTheWeek.format('D.MM')}</div>
                    </FlexColumn>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="calendar-weekly-grid mb-4" style={{ gridTemplateColumns: 'repeat(7 , 1fr)' }}>
          {daysIndexes.map(dayIndex => {
            const dayOfTheWeek = moment(weekStart).isoWeekday(dayIndex);
            return (
              <div
                key={dayIndex}
                onClick={() => clickDayOfTheWeek(dayOfTheWeek)}
                className={cls('centered cursor-pointer', {
                  'bg-teal-light text-white font-bold': dayStart.isoWeekday() === dayIndex,
                })}
              >
                <div className="my-2 text-md capitalize">
                  <FlexColumn separated="small">
                    <div>{dayOfTheWeek.format('dd')}</div>
                    <div>{dayOfTheWeek.format('D')}</div>
                  </FlexColumn>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Day />
    </>
  );
};

const mapStateToProps = ({ calendar, app: { isMobile } }, { location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;

  return {
    weekStart: calendarState.weekStart,
    dayStart: calendarState.dayStart,
    calendarKey,
    isMobile,
  };
};

export default withRouter(connect(mapStateToProps, { setDayDate: setDay })(Week));
