import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import queryString from 'query-string';

import ButtonOutline from '@old/components/guide/ButtonOutline';
import { FacebookIconCircle } from 'old/assets/svg';
import t from 'resources/translations';
import { useDispatch } from 'react-redux';
import { socialLogin } from 'store/auth';
import { useHistory } from 'react-router';

const ButtonFacebook = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const callback = async ({ accessToken }) => {
    const { state } = queryString.parse(window.location.search);
    dispatch(socialLogin({ service: 'facebook', accessToken, farmSlug: state, history }));
  };
  const { origin, pathname } = window.location;

  return (
    <FacebookLogin
      appId="342373523342393"
      callback={callback}
      redirectUri={origin}
      state={pathname} // see state parameter https://www.npmjs.com/package/react-facebook-login#parameters
      render={renderProps => (
        <ButtonOutline fluid onClick={renderProps.onClick} disabled={renderProps.disabled}>
          <FacebookIconCircle />
          <span className="ml-1">{t('facebookLoginButton.facebookSignIn')}</span>
        </ButtonOutline>
      )}
    />
  );
};

export default ButtonFacebook;
