import React from 'react';
import moment from 'moment';
import { Content, Stack, Text } from 'components/elements';
import Message from 'old/components/common/Message';
import Bill from 'models/Bill';
import { APIPagination } from 'resources/types/commonTypes';
import PagePagination from 'components/modules/PagePagination';
import t from 'resources/translations';
import { DATE_FORMAT, DATE_FORMAT_WITH_COMMA } from 'config/constans';
import _ from 'lodash';
import BillsListItemMobile from 'views/Bills/BillsListItemMobile';
import { Card, CardContent } from 'components/combinations';

type TBillsListProps = {
  bills: Bill[];
  pagination?: APIPagination;
  isLoading: boolean;
};

const BillsListMobile = ({ bills = [], pagination, isLoading }: TBillsListProps) => {
  const isBillsEmpty = bills.length === 0;
  const noResult = !isLoading && isBillsEmpty;

  if (noResult) {
    return (
      <Content mt="lg">
        <Message info>{t('message.noResults')}</Message>
      </Content>
    );
  }

  const billsGroupedByDays = _.groupBy(bills, (bill: Bill) => {
    if (bill.billableType === 'Ticket') {
      return moment(bill.createdAt).format(DATE_FORMAT);
    }
    return moment(bill.event.startAt).format(DATE_FORMAT);
  });

  return (
    <>
      <Stack spacing="xl">
        {Object.keys(billsGroupedByDays).map((dayKey: string) => {
          return (
            <Stack spacing="sm">
              <Card bgColor="white" mb="xs">
                <CardContent>
                  <Text fontWeight="medium">{moment(dayKey, DATE_FORMAT).format(DATE_FORMAT_WITH_COMMA)}</Text>
                </CardContent>
              </Card>
              {billsGroupedByDays[dayKey].map(billPerDay => (
                <BillsListItemMobile key={billPerDay.id} bill={billPerDay} />
              ))}
            </Stack>
          );
        })}
      </Stack>
      {!isBillsEmpty && pagination && <PagePagination pagination={pagination} />}
    </>
  );
};

BillsListMobile.defaultProps = {
  children: null,
  isLoading: false,
};

export default React.memo(BillsListMobile);
