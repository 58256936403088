import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import { rangeRight, throttle, sortBy, unionBy, times, sumBy, uniqBy } from 'lodash';
import axios from 'axios';

import { getModelByName, sortByTimeString, notify, getErrorMessage, isAllowed } from 'old/utils';
import CordeoModel from 'old/model';
import t from 'resources/translations';
import config from '@old/config';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_CLOSE, MODAL_HIDE, MODAL_OPEN, MODAL_UPDATE } from 'store/actions/modalActions';
import { shallowEqual } from 'recompose';
import { bindActionCreators } from 'redux';

export * from 'old/buttonHooks';

export const CalendarDataContext = React.createContext([]);

export const useModelItems = (modelName, params, refreshCounter) => {
  const [isPending, setPending] = useState(true);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    setPending(true);
    const CurrentModel = getModelByName(modelName);
    CurrentModel.fetchAll({ cancelToken: source.token, ...params })
      .then(([fetchedItems]) => {
        setItems(fetchedItems);
        setError(null);
        setPending(false);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          setError(err);
          setItems(null);
          setPending(false);
        }
      });

    return () => source.cancel();
    // note: params is included, but not directly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelName, refreshCounter, ...Object.values(params).map(param => String(param))]);

  return [
    items,
    {
      isPending,
      isSuccess: !isPending && !!items,
      isError: !isPending && !!error,
    },
  ];
};

export const useModelItem = (modelName, itemId, refreshCounter) => {
  const [isPending, setPending] = useState(false);
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);

  useMemo(() => {
    setPending(true);
    const CurrentModel = getModelByName(modelName);
    CurrentModel.fetch(itemId)
      .then(fetchedItem => {
        setItem(fetchedItem);
        setError(null);
        setPending(false);
      })
      .catch(err => {
        setError(err);
        setItem(null);
        setPending(false);
      });
  }, [modelName, itemId, refreshCounter]); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    item,
    {
      isPending,
      isSuccess: !isPending && !!item,
      isError: !isPending && !!error,
    },
  ];
};

export const useTabs = tabs => {
  return useMemo(() => {
    if (!tabs) {
      return [];
    }
    const filterTabs = tabProps => {
      const { permissionKey, active } = tabProps;
      const isAccess = permissionKey ? isAllowed(permissionKey) : true;
      return (active || active === undefined) && isAccess;
    };
    return tabs.filter(filterTabs);
  }, [tabs]);
};

export const useFetchOptions = (loadOptions, customFilter = Boolean) => {
  const [isPending, setPending] = useState(false);
  const [params, setParams] = useState({ query: '', page: 1 });
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadedOptions, setLoadedOptions] = useState([]);
  const [error, setError] = useState(null);
  const [fetchCounter, setFetchCounter] = useState(0);

  const fetchNext = alreadyFetched => {
    setLoadedOptions([...alreadyFetched]);
    setItems([]);
    setFetchCounter(prevFetchCounter => prevFetchCounter + 1);
  };

  const clearItems = () => {
    setLoadedOptions([]);
  };

  const searchByName = throttle(value => {
    clearItems();
    setParams({ query: value, page: 1 });
  }, 500);

  useEffect(() => {
    setPending(true);
    loadOptions(params.query, params.page)
      .then(fetchedOptions => {
        setItems(fetchedOptions.options.filter(customFilter));
        setHasMore(fetchedOptions.hasMore);
        setParams(prevParams => ({ ...prevParams, page: fetchedOptions.additional.page }));
        setError(null);
        setPending(false);
      })
      .catch(err => {
        setError(err);
        setItems([]);
        setPending(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.query, fetchCounter]);

  return [
    unionBy([...loadedOptions, ...items], 'key'),
    {
      isPending,
      isSuccess: !isPending && !!items,
      isError: !isPending && !!error,
      isNoMorePage: !hasMore,
    },
    fetchNext,
    searchByName,
  ];
};

export const useEventStatus = (event, loggedMember) => {
  const { currentStatus, isJoined, isInvited, isRequested } = event
    ? event.getStatusesParticipant(loggedMember.id)
    : {};
  const statuses = useMemo(() => {
    if (!event) {
      return null;
    }

    const { isCancelled, isFinished, isAwaiting, isOngoing, isActive } = event;
    const instructorIds = event.getInstructorsIds();
    const isInstructedByCurrentUser = instructorIds.includes(loggedMember.id);
    const joinedParticipantsNumber = event.getJoinedParticipations().length;
    const isFull = joinedParticipantsNumber >= event.attendees.max;

    return {
      isJoined,
      isInvited,
      isRequested,
      isFinished,
      isOngoing,
      isAwaiting,
      isFull,
      isActive,
      isCancelled,
      isInstructedByCurrentUser,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus, event, loggedMember]);

  return statuses;
};

export const useMemberStatus = (member, loggedMember) => {
  const statuses = useMemo(() => {
    if (!member) {
      return null;
    }

    const isCurrentUser = loggedMember.id === member.id;
    const currentIsOwner = loggedMember.isOwner();
    const currentIsInstructor = loggedMember.isInstructor();
    const currentIsClient = loggedMember.isClient();
    const isOwner = member.isOwner();
    const isInstructor = member.isInstructor();
    const isClient = member.isClient();
    const isPending = member.isPending();

    return {
      isCurrentUser,
      isOwner,
      isInstructor,
      isClient,
      currentIsOwner,
      currentIsInstructor,
      currentIsClient,
      isPending,
    };
  }, [member, loggedMember]);

  return statuses;
};

export const useStatistics = (id, type, dateRange, active = true) => {
  const Model = getModelByName(type);
  const [apiCallResult, setApiCallResult] = useState(null);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setReady(false);
    const call = async () => {
      try {
        const result = await Model['getStatistics'](id, dateRange);
        setApiCallResult(result);
        setReady(true);
      } catch (e) {
        setError(e);
        setReady(true);
      }
    };
    if (active) {
      call();
    }
    // eslint-disable-next-line
  }, [Model, id, type, dateRange, active]);

  return [apiCallResult, ready, error];
};

export const useHorsesWeekStatistics = dateRange => {
  const [stats, statsReady, statusError] = useAPICall(CordeoModel.Horses, 'getEventTypeStatistics', dateRange);
  const days = [];
  /**
   * A function that returns a array with slugsName
   * @function
   * @memberof Hooks
   * @param  {Array} events contains id, slugs and event status from one day
   * @return {Array} slug name string
   */
  const mapEventsToSlugsList = events => {
    const eventList = uniqBy(events, 'id'); // fix me after api changes
    const eventSlug = eventList.map(event => event.slug);
    // That variable contain a object with the number of slugs from one day
    const evenSlugsCount = eventSlug.reduce((prev, cur) => {
      const tmp = prev;
      tmp[cur] = (prev[cur] || 0) + 1;
      return tmp;
    }, {});

    // That variable contain array of string with slugnames ex. `3PR, 2LO`
    const slugsList = Object.entries(evenSlugsCount).map(item => {
      if (item[1] === 1) {
        return item[0];
      }
      return [item[1], item[0]].join('');
    });
    return slugsList;
  };

  if (stats) {
    times(7, i => {
      days[i] = { date: moment(stats.from).add(i, 'd').format('YYYY-MM-DD') };
    });
  }

  const statistics = ((stats && stats.horses) || []).map(statistic => {
    const sortDates = sortBy(unionBy(statistic.dates, days, 'date'), 'date');
    const dates = sortDates.map(weekDay => ({
      date: weekDay.date,
      eventTypesSlugs: weekDay.events ? mapEventsToSlugsList(weekDay.events) : [],
      duration: weekDay.total_duration ? weekDay.total_duration : 0,
      absent: !!weekDay.absent,
    }));
    const totalDuration = sumBy(dates, 'duration');

    return { horse: statistic.horse, dates, totalDuration };
  });

  return [statistics, statsReady, statusError];
};

export const useWeeklyStatistics = (id, type, dataRange) => {
  const Model = getModelByName(type);

  const [stats, statsReady] = useAPICall(Model, 'getWeeklyStatistics', id, dataRange);

  if (!statsReady) {
    // [data, ranges, loading]
    return {
      heatMapData: {},
      heatMapRanges: [],
      loading: true,
    };
  }

  // { '08:00-09:00': {}, '09:00-10:00': {} ... }
  const ranges = Object.assign({}, ...config.heatMapHours.map(key => ({ [key]: {} })));

  const weekly = stats ? stats.weekly : null;

  const weekMap = {
    monday: { ...ranges },
    tuesday: { ...ranges },
    wednesday: { ...ranges },
    thursday: { ...ranges },
    friday: { ...ranges },
    saturday: { ...ranges },
    sunday: { ...ranges },
  };

  let max = 0;
  if (weekly) {
    Object.keys(weekly).forEach(dayName => {
      Object.keys(weekly[dayName]).forEach(hourRangeKey => {
        if (weekMap[dayName][hourRangeKey]) {
          weekMap[dayName][hourRangeKey] = weekly[dayName][hourRangeKey];
        }
        if (max < weekly[dayName][hourRangeKey].time) {
          max = weekly[dayName][hourRangeKey].time;
        }
      });
    });
  }

  // [data, ranges, loading]
  return {
    heatMapData: weekMap,
    heatMapRanges: rangeRight(0, max, max / 5),
    loading: false,
  };
};

export const useHeatMap = (docId, modelName, dateRange) => {
  return useWeeklyStatistics(docId, modelName, dateRange);
};

const useAPICall = (Model, method, ...args) => {
  const [apiCallResult, setApiCallResult] = useState(null);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setReady(false);
    const call = async () => {
      try {
        const result = await Model[method](...args);
        setApiCallResult(result);
        setReady(true);
      } catch (e) {
        setError(e);
        setReady(true);
      }
    };

    call();
    // eslint-disable-next-line
  }, [Model, method, ...args]);

  return [apiCallResult, ready, error];
};

export const useBarChartData = (docId, modelName, barChartDateRange) => {
  const [barChartStats, ready] = useStatistics(docId, modelName, barChartDateRange);
  const statsByDay = useMemo(() => {
    const result = [];
    for (let i = 0; i < 7; i += 1) {
      const day = moment(barChartDateRange.start).add(i, 'day');
      const key = day.format('YYYY-MM-DD');
      if (barChartStats && barChartStats.daily && barChartStats.daily[key]) {
        const { activity_ranges, exceeded_range, count } = barChartStats.daily[key];
        const activityDurations = activity_ranges
          .sort(([a], [b]) => sortByTimeString(a, b))
          .map(([start, end]) => {
            const rangeKey = `${start} - ${end}`;
            const duration = moment.range(moment(start, 'HH:mm'), moment(end, 'HH:mm')).diff('minutes');
            return {
              rangeKey: `${key}/${rangeKey}`,
              duration: duration < 0 ? 0 : duration,
            };
          });
        let lastItemEnd = 0;

        const dayStats = Object.assign(
          {},
          {
            name: key,
            exceeded: exceeded_range,
            count,
          },
          ...activityDurations.map(item => {
            const thisItemStart = lastItemEnd ? lastItemEnd + 20 : lastItemEnd;
            const thisItemEnd = thisItemStart + item.duration;
            lastItemEnd = thisItemEnd;
            return { [item.rangeKey]: item.duration };
          })
        );
        result.push(dayStats);
      } else {
        result.push(null);
      }
    }
    return result;
  }, [barChartDateRange, barChartStats]);

  const barChartData = statsByDay.map((item, index) => {
    if (!item) {
      return { name: t(`week.short.${config.weekDays[index]}`) };
    }
    const { name, ...rest } = item;
    return {
      name: `${t(`week.short.${config.weekDays[index]}`)} (${item.count})`,
      label: name,
      ...rest,
    };
  });

  return [barChartData, !ready];
};

export const useEventsWithChart = (barChartDateRange, additionalSelector) => {
  const [barChartEvents] = useModelItems('events', {
    per_page: 9999,
    'in_interval[start]': barChartDateRange.start.toDate(),
    'in_interval[end]': barChartDateRange.end.toDate(),
    ...additionalSelector,
  });
  const eventsByDay = useMemo(() => {
    const result = {};
    for (let i = 0; i < 7; i += 1) {
      const day = moment(barChartDateRange.start).add(i, 'day');
      const key = day.format('YYYY-MM-DD');
      result[key] = (barChartEvents || []).filter(event => {
        const eventRange = moment.range(event.startDate, event.endDate);
        const dayRange = moment.range(day, moment(day).endOf('day'));
        return eventRange.overlaps(dayRange);
      });
    }
    return result;
  }, [barChartEvents, barChartDateRange.start]);

  return eventsByDay;
};

export const usePieChartData = (docId, modelName, dateRange, active = true) => {
  const [pieChartStats] = useStatistics(docId, modelName, dateRange, active);
  const isHorseStatistics = modelName === 'horses';

  if (pieChartStats) {
    const data = isHorseStatistics
      ? [
          {
            name: 'exceeded',
            color: config.color.red,
            value: pieChartStats.total.exceeded_range_count,
          },
          {
            name: 'noExceeded',
            color: config.color.teal,
            value: pieChartStats.total.finished_count - (pieChartStats.total.exceeded_range_count || 0),
          },
        ]
      : [
          {
            name: 'cancelled',
            color: config.color.tealLight,
            value: pieChartStats.total.cancelled_count || 0,
          },
          {
            name: 'worked',
            color: config.color.teal,
            value: pieChartStats.total.finished_count || 0,
          },
        ];

    const duration = {
      cancelled: pieChartStats.total.cancelled_duration || 0,
      worked: pieChartStats.total.finished_duration || 0,
    };

    return [data, pieChartStats.total, duration];
  }

  return [[], null, {}];
};

export function useLongTouchPress(callback = () => {}, ms = 400) {
  const [startLongPress, setStartLongPress] = useState(false);
  const [cords, setCords] = useState();

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(() => callback(cords), ms);
    } else {
      clearTimeout(timerId);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [startLongPress, callback, ms]); // eslint-disable-line react-hooks/exhaustive-deps

  const start = e => {
    setCords({ clientX: e.touches[0].clientX, clientY: e.touches[0].clientY });
    setStartLongPress(true);
  };

  const stop = () => {
    setStartLongPress(false);
  };

  return {
    onTouchStart: start,
    onTouchEnd: stop,
  };
}

export const useOutsideClick = (ref, callback) => {
  const savedHandler = useRef();

  useEffect(() => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    savedHandler.current = handleClick;
  }, [callback, savedHandler, ref]);

  useEffect(() => {
    const options = { capture: true };
    const eventListener = e => savedHandler.current(e);

    document.addEventListener('mousedown', eventListener, options);
    document.addEventListener('touchstart', eventListener, options);

    return () => {
      document.removeEventListener('mousedown', savedHandler, options);
      document.removeEventListener('touchstart', savedHandler, options);
    };
  }, []);
};

export const useScrollBottomOfBox = marginBottom => {
  const ref = useRef();
  useEffect(() => {
    const { y, height } = ref.current.getBoundingClientRect();
    const arrayOfAnimationId = [];
    const boxBottomYPos = y + height;
    const isScrollNeeded = boxBottomYPos > window.innerHeight;
    const pxDiff = boxBottomYPos - window.innerHeight;
    const windowScrollYPos = window.scrollY;
    const scrollWindow = () => {
      window.scrollTo(0, window.scrollY + marginBottom);
      if (window.scrollY <= windowScrollYPos + pxDiff && ref.current) {
        arrayOfAnimationId.push(window.requestAnimationFrame(scrollWindow));
      }
    };
    if (isScrollNeeded) {
      arrayOfAnimationId.push(window.requestAnimationFrame(scrollWindow));
    }
    return () => {
      arrayOfAnimationId.map(aniamtionId => window.cancelAnimationFrame(aniamtionId));
    };
  }, [marginBottom]);
  return ref;
};

export const useFocus = () => {
  const innerRef = useRef(null);
  const setFocus = () => innerRef.current && innerRef.current.focus();
  return [innerRef, setFocus];
};

// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useKey = (callback, keyCode) => {
  const handleKeyDown = e => {
    if (e.keyCode === config.keyCodes[keyCode]) {
      e.preventDefault();
      callback();
    }
  };
  return handleKeyDown;
};

export const useListener = (eventName, handler, element = window) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return () => null;
    }
    const eventListener = event => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

export const useNumberOfAwaitingsMembers = (customFunction, defaultResult, refreshNow) => {
  const [result, setResult] = useState(null);
  const [isPending, setPending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (refreshNow) {
      setPending(true);
      customFunction()
        .then(fetchedResult => {
          setResult(fetchedResult);
          setError(null);
          setPending(false);
        })
        .catch(err => {
          setError(err);
          setResult(null);
          setPending(false);
        });
    }
  }, [refreshNow, customFunction]);

  return [
    result || defaultResult,
    {
      isPending,
      isSuccess: !isPending && !!result,
      isError: !isPending && !!error,
    },
  ];
};

export const useEventPairs = (event, cb) => {
  const [sortedParticipations, setSortedParticipations] = useState(sortBy(event.getJoinedParticipations(), ['horse']));
  const [unpairHorses, setUnpairHorses] = useState(event.getFreeHorses());
  const [isPairing, setIsPairing] = useState(false);

  useEffect(() => {
    setSortedParticipations(sortBy(event.getJoinedParticipations(), ['horse']));
    setUnpairHorses(event.getFreeHorses());
  }, [event]);

  const setPairParticipant = useCallback(async (participationId, horseId) => {
    try {
      setIsPairing(true);
      await CordeoModel.Events.attachHorse(event.id, participationId, horseId);
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      setIsPairing(false);
      cb();
    }
  }, []); // eslint-disable-line

  const setUnpairParticipant = useCallback(async participationId => {
    try {
      setIsPairing(true);
      await CordeoModel.Events.detachHorse(event.id, participationId);
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    } finally {
      setIsPairing(false);
      cb();
    }
  }, []); // eslint-disable-line

  return [
    sortedParticipations,
    unpairHorses,
    setPairParticipant,
    setUnpairParticipant,
    {
      isPairing,
    },
  ];
};

export const useRoles = member => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const result = await CordeoModel.Farms.fetchRoles();
        setRoles(result);
      } catch (e) {
        setRoles([]);
      }
    };
    if (member.isActive()) fetchRoles();
  }, [member]);

  return roles;
};

export const useAddParticipantsLogic = ({
  eventIsPast,
  joinedParticipantsIds,
  joinedParticipantsOptions,
  participantsIds,
  defaultSelected,
}) => {
  const [selectedParticipants, setSelectedParticipants] = useState([]); // there are actually options, not participants

  useEffect(() => {
    setSelectedParticipants(defaultSelected || []);
  }, [defaultSelected]);

  const loadOptions = useCallback(
    async (keyword, page) => {
      const getFetchParamsForMembers = () => {
        if (eventIsPast) {
          return { with_status: ['pending', 'active', 'deleted'] };
        }
        return { with_status: ['pending', 'active'] };
      };

      let membersOptionsData = {
        options: [],
        hasMore: false,
        additional: {
          page: 2,
        },
      };
      let groupsOptionsData = {
        options: [],
        hasMore: false,
        additional: {
          page: 2,
        },
      };

      if (isAllowed('memberships.index')) {
        [membersOptionsData] = await Promise.all([
          CordeoModel.Members.fetchClientOptions(keyword, page, getFetchParamsForMembers()),
        ]);
      }

      if (isAllowed('riding_groups.index')) {
        [groupsOptionsData] = await Promise.all([CordeoModel.MemberGroups.fetchOptions()]);
      }

      return {
        ...membersOptionsData,
        options: [...groupsOptionsData.options, ...membersOptionsData.options],
      };
    },
    [eventIsPast]
  );

  const selectedParticipantsIds = selectedParticipants.map(option => option.value);

  // participants saved in event and participants selected to be added - only unique ids
  const newParticipantsIds = useMemo(() => {
    const result = [...new Set([...joinedParticipantsIds, ...selectedParticipantsIds])];
    return result;
  }, [joinedParticipantsIds, selectedParticipantsIds]);

  const isOptionDisabled = option => {
    if (option.isGroup) {
      return option.value.every(groupMemberOption => isOptionDisabled(groupMemberOption));
    } else {
      return participantsIds.includes(option.value);
    }
  };

  const isOptionSelected = option => {
    if (option.isGroup) {
      return option.value
        .map(participantOption => participantOption.value)
        .every(memberId => newParticipantsIds.includes(memberId));
    } else {
      return [...joinedParticipantsOptions, ...selectedParticipants].map(item => item.value).includes(option.value);
    }
  };

  const selectParticipantToAdd = selectedOptions => {
    setSelectedParticipants(selectedOptions.filter(option => !isOptionDisabled(option)));
  };

  const toggleSelectedOption = (option, isSelected) => {
    if (option.isGroup) {
      // In some cases group option is not selected but we also have no way to select it eg.:
      // in add participant modal we have group: [a, b, c]
      // a - is selected and saved in event (and therefore is disabled too)
      // b - is disabled and can't be selected (becasue has 'invited' status),
      // c - is selected (to add)
      // in above situation after clicking on group option we should unselect c even if group is not selected
      const nothingToSelect = option.value.every(item => isOptionSelected(item) || isOptionDisabled(item));
      if (!isSelected && !nothingToSelect) {
        selectParticipantToAdd([...selectedParticipants, ...option.value]);
      } else {
        selectParticipantToAdd(
          selectedParticipants.filter(
            selectedOption => !option.value.map(item => item.value).includes(selectedOption.value)
          )
        );
      }
    } else if (!isSelected) {
      selectParticipantToAdd([...selectedParticipants, option]);
    } else {
      selectParticipantToAdd(selectedParticipants.filter(selectedOption => selectedOption.value !== option.value));
    }
  };

  const [options, fetchOptionStatus, fetchNext, searchByName] = useFetchOptions(loadOptions);
  const [query, setQuery] = useState();

  const changeQuery = val => {
    setQuery(val);
    searchByName(val);
  };

  const fetchMore = e => {
    const isBottom = e.target.scrollHeight - offset - e.target.scrollTop < e.target.clientHeight;
    if (!fetchOptionStatus.isPending && !fetchOptionStatus.isNoMorePage && isBottom) {
      fetchNext(options);
    }
  };

  const offset = 100;

  return [
    query,
    changeQuery,
    fetchMore,
    isOptionSelected,
    isOptionDisabled,
    selectedParticipants,
    setSelectedParticipants,
    newParticipantsIds,
    options,
    fetchOptionStatus,
    toggleSelectedOption,
  ];
};

export const useDisclosure = (isOpenDefault = false) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen(state => !state), []);

  return { isOpen, onOpen, onClose, onToggle };
};

export const useModal = name => {
  const dispatch = useDispatch();
  const { isOpen, isHidden } = useShallowSelector(({ modal }) => modal[name] || {});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onOpen = payload => dispatch({ type: MODAL_OPEN, name, payload }, [name, payload]);
  const onClose = useCallback(() => dispatch({ type: MODAL_CLOSE, name }, [name]), [dispatch, name]);
  const onHide = () => dispatch({ type: MODAL_HIDE, name }, [name]);
  const onUpdate = payload => dispatch({ type: MODAL_UPDATE, name, payload }, [name, payload]);

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        onHide();
        setTimeout(onClose, 350);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      return () => onClose();
    }
  }, [onClose, isOpen]);

  const onHideAndCloseModal = () => {
    onHide();
    setTimeout(onClose, 350);
  };

  const onSubmitAndClose = async cb => {
    try {
      setIsSubmitting(true);
      await cb();
      onHideAndCloseModal();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    setIsSubmitting(false);
  };

  const onSubmit = async cb => {
    try {
      setIsSubmitting(true);
      await cb();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    setIsSubmitting(false);
  };

  return { isOpen, isHidden, isSubmitting, onOpen, onUpdate, onClose: onHideAndCloseModal, onSubmitAndClose, onSubmit };
};

export const useShallowSelector = mapStateToData => {
  return useSelector(mapStateToData, shallowEqual);
};

export const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [dispatch, ...deps] : [dispatch]
  );
};
