import React from 'react';
import { compose, withProps } from 'recompose';
import { GoogleMap as GMap, Marker, withScriptjs, withGoogleMap } from 'react-google-maps';

const GoogleMaps = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&signed_in=true&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ lat, lng, zoom, showMarker, onMapClick }) => (
  <GMap defaultZoom={zoom} defaultCenter={{ lat, lng }} onClick={onMapClick} key={new Date().getTime()}>
    {showMarker && <Marker position={{ lat, lng }} />}
  </GMap>
));

export default GoogleMaps;
