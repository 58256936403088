import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { linkStyleProps } from 'config/styledProps';
import { ILinkStyledProps } from 'resources/types/styledTypes';

const RouterLink = styled(Link).withConfig({
  shouldForwardProp: prop =>
    !['maxW', 'lineHeight', 'lineClamp', 'marginInlineStart', 'boxOrient', 'textOverflow', 'wordBreak'].includes(prop),
})<ILinkStyledProps>(linkStyleProps);
RouterLink.defaultProps = { variant: 'primary', fontSize: 'base', lineHeight: 'normal' };

export default RouterLink;
