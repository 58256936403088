import ActionCableJWT from 'action-cable-react-jwt';

const cableMiddleware = () => {
  const cable = ActionCableJWT.createConsumer(process.env.REACT_APP_WEBSOCKET_URL, null);

  return ({ dispatch }) =>
    next =>
    action => {
      if (typeof action === 'function') return next(action);

      const { channel, leave } = action;
      const type = action.received;
      let received = null;

      if (!channel) return next(action);

      const JWT = localStorage.getItem('auth_token');
      const token = JWT ? JWT.replace('Bearer ', '') : null;
      cable.jwt = token;

      if (leave) {
        const subscription = cable.subscriptions.subscriptions.find(
          sub => sub.identifier === JSON.stringify({ channel })
        );
        cable.subscriptions.remove(subscription);
        return type ? dispatch({ type }) : null;
      }

      if (typeof type === 'string') received = message => dispatch({ type, message });
      return cable.subscriptions.create({ channel }, { received });
    };
};

export default cableMiddleware;
