import { useState } from 'react';
import { Button, Icons, Input } from 'components/elements';
import InputWrapper from './InputWrapper';
type TPassword = {
  value: string;
  name: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const PasswordInput = ({ value, name, onChange, placeholder, ...styleProps }: TPassword) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputWrapper position="relative" {...styleProps}>
      <Input
        id={name}
        type={showPassword ? 'text' : 'password'}
        name={name}
        value={value}
        //@ts-ignore
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
      <Button
        pr={20}
        variant="icon"
        tabIndex={-1}
        ml="sm"
        onClick={() => setShowPassword(prevShowPassword => !prevShowPassword)}
      >
        {showPassword ? <Icons.VisibilityOn size="md" /> : <Icons.VisibilityOf size="md" />}
      </Button>
    </InputWrapper>
  );
};

PasswordInput.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
};

export default PasswordInput;
