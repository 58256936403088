import t from 'resources/translations';
import Event from 'models/Event';
import Participation from 'models/Participation';
import { Box, Button, Flex, Icons, Link, Stack, Ternary, Text } from 'components/elements';
import { ParticipationStatusLabel, AvatarWithLabel } from 'components/combinations';
import { TFlexProps } from 'resources/types/elementsTypes';
import { useMutation, useQueryClient } from 'react-query';
import { ParticipationService } from 'services';
import { mFetchedBillInEvents, mParticipationToEvents, useAppSelector } from 'utils';
import { IParticipationRes } from 'resources/types/participationsTypes';
import { usePermissions } from 'Permissions';
import { getPaymentLabelByType, isPaymentAvailable } from 'old/utils';
import { IBillRes } from 'resources/types/billsTypes';
import Bill from 'models/Bill';
import { useModal } from 'old/hooks';

type TMyEventParticipant = TFlexProps & {
  event: Event;
  participation: Participation;
  actions: Function;
};
const MyEventParticipant = ({ event, participation, actions, ...flexStyleProps }: TMyEventParticipant) => {
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const { member, bill } = participation;
  const isJoined = participation.status === 'joined';

  const queryClient = useQueryClient();
  const checkBillSetPayment = usePermissions('BILL_SET_PAYMENT');
  const checkBillReturnPayment = usePermissions('BILL_RETURN_PAYMENT');
  const checkSetPresence = usePermissions('PARTICIPATION_SET_PRESENCE');
  const checkReject = usePermissions('PARTICIPATION_REJECT');
  const checkAccept = usePermissions('PARTICIPATION_ACCEPT');
  const modalPayment = useModal('PAYMENT_EVENT');
  const modalReturnPayment = useModal('PAYMENT_RETURN');
  const mutateOnSuccess = (participation: IParticipationRes) =>
    mParticipationToEvents(queryClient, ['DASBOARD_EVENTS_TODAY', 'DASHBOARD_EVENTS'], participation);

  const setPresenceMutation = useMutation(async () => await ParticipationService.setPresence(event.id, participation), {
    onSuccess: mutateOnSuccess,
  });

  const setRejectMutation = useMutation(async () => await ParticipationService.setReject(event.id, participation.id), {
    onSuccess: mutateOnSuccess,
  });

  const setAcceptMutation = useMutation(async () => await ParticipationService.setAccept(event.id, participation.id), {
    onSuccess: mutateOnSuccess,
  });

  const onOpenPaymenModal = () => {
    const mutationCallback = (billRes: IBillRes) =>
      mFetchedBillInEvents(queryClient, ['DASBOARD_EVENTS_TODAY', 'DASHBOARD_EVENTS'], billRes);
    return modalPayment.onOpen({
      bill: participation.bill,
      onSubmitCb: mutationCallback,
      onChangeDueCb: mutationCallback,
    });
  };

  const onOpenReturnPaymenModal = (bill: Bill | null) => {
    if (bill) {
      const onSubmitCb = () => mFetchedBillInEvents(queryClient, ['DASBOARD_EVENTS_TODAY', 'DASHBOARD_EVENTS'], bill);
      return modalReturnPayment.onOpen({ bill, onSubmitCb });
    }
  };

  const renderActions = () => (
    <Stack alignItems="center" spacing={['sm', 'base']} isHorizontal={!isMobile}>
      {checkBillSetPayment(participation.bill, participation) && participation.status === 'joined' && (
        <Button variant="link" onClick={onOpenPaymenModal} width={['100%', 'initial']}>
          <Stack py="sm" spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
            <Icons.PaymentWallet size={12} />
            <Text>{t('buttons.settle')}</Text>
          </Stack>
        </Button>
      )}
      {checkBillReturnPayment(bill, participation) && participation.status === 'joined' && (
        <Button variant="link" onClick={() => onOpenReturnPaymenModal(participation.bill)} width={['100%', 'initial']}>
          <Stack py="sm" spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
            <Icons.Return size={12} />
            <Text>{t('buttons.return')}</Text>
          </Stack>
        </Button>
      )}
      {checkSetPresence(event, participation) && participation.status === 'joined' && (
        <Button
          variant="link"
          onClick={() => setPresenceMutation.mutateAsync()}
          disabled={setPresenceMutation.isLoading}
          width={['100%', 'initial']}
        >
          <Stack py="sm" spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
            {participation.attendance === 'absent' ? <Icons.Accept size={12} /> : <Icons.Cancel size={12} />}
            <Text>{t('buttons.changePresence')}</Text>
          </Stack>
        </Button>
      )}
      {checkAccept(event, participation) && ['invitation_requested', 'rejected'].includes(participation.status) && (
        <Button
          variant="link"
          onClick={() => setAcceptMutation.mutateAsync()}
          disabled={setAcceptMutation.isLoading}
          width={['100%', 'initial']}
        >
          <Stack spacing="sm" alignItems="center" justifyContent="center" isHorizontal>
            <Icons.Accept size={12} />
            {participation.status === 'rejected' ? (
              <Text>{t('buttons.inviteAgain')}</Text>
            ) : (
              <Text>{t('buttons.accept')}</Text>
            )}
          </Stack>
        </Button>
      )}
      {checkReject(event, participation) &&
        ['invitation_requested', 'invited', 'joined'].includes(participation.status) && (
          <Button
            variant="link"
            onClick={() => setRejectMutation.mutateAsync()}
            disabled={setRejectMutation.isLoading}
            width={['100%', 'initial']}
          >
            <Stack spacing="sm" py="sm" alignItems="center" justifyContent="center" isHorizontal>
              <Icons.Cancel size={12} />
              {participation.status === 'joined' ? (
                <Text>{t('buttons.remove')}</Text>
              ) : (
                <Text>{t('buttons.reject')}</Text>
              )}
            </Stack>
          </Button>
        )}
    </Stack>
  );

  if (isMobile) {
    return (
      <Flex flexDirection="column" w="full" py="lg" px="sm" {...flexStyleProps}>
        <Flex alignItems="center">
          <Flex>
            <AvatarWithLabel
              src={member.getAvatar('medium')}
              alt={member.getFullName()}
              title={member.getFullName()}
              squareSize={26}
            >
              <Link to={member.getProfileUrl()} fontSize="base">
                {member.getFullName()}
              </Link>
              <Box>
                {isJoined && (
                  <Ternary cond={participation.attendance === 'absent'}>
                    <Text fontSize="xs" color="orange">
                      {t('eventParticipationsManage.absent')}
                    </Text>
                    <Text fontSize="xs" color="new-green">
                      {t('eventParticipationsManage.attending')}
                    </Text>
                  </Ternary>
                )}
              </Box>
            </AvatarWithLabel>
          </Flex>
          <Box>
            <ParticipationStatusLabel status={participation.status} />
          </Box>
        </Flex>
        {isJoined && !!bill && (
          <Box mt="md">
            <Ternary cond={bill?.getDue() === 0}>
              <Ternary cond={bill.paymentType === 'unpaid'}>
                <Text>{t('paymentStatus.paid')}</Text>
                <Text>{`${t('message.paid')}, ${getPaymentLabelByType(bill.paymentType)}`}</Text>
              </Ternary>
              <Ternary cond={isPaymentAvailable({ bill })}>
                <Text color="grey">{t('message.unpaid')}</Text>
                <Text>{`${t('message.paid')}, ${getPaymentLabelByType(bill.paymentType)}`}</Text>
              </Ternary>
            </Ternary>
          </Box>
        )}
        {renderActions()}
      </Flex>
    );
  }

  return (
    <Flex key={participation.id} minH={56} px="md" alignItems="center" {...flexStyleProps}>
      <Flex maxW={250}>
        <AvatarWithLabel
          src={member.getAvatar('small')}
          alt={member.getFullName()}
          title={member.getFullName()}
          squareSize={26}
        >
          <Link to={member.getProfileUrl()} fontSize="sm">
            {member.getFullName()}
          </Link>
          {isJoined && (
            <Ternary cond={participation.attendance === 'absent'}>
              <Text fontSize="xs">{t('eventParticipationsManage.absent')}</Text>
              <Text fontSize="xs">{t('eventParticipationsManage.attending')}</Text>
            </Ternary>
          )}
        </AvatarWithLabel>
      </Flex>
      <Flex maxW={200}>
        <ParticipationStatusLabel status={participation.status} />
      </Flex>
      <Flex>
        {isJoined && !!bill && (
          <Ternary cond={bill?.getDue() === 0}>
            <Ternary cond={bill.paymentType === 'unpaid'}>
              <Text>{t('paymentStatus.paid')}</Text>
              <Text>{`${t('message.paid')}, ${getPaymentLabelByType(bill.paymentType)}`}</Text>
            </Ternary>
            <Ternary cond={isPaymentAvailable({ bill })}>
              <Text color="grey">{t('message.unpaid')}</Text>
              <Text>{`${t('message.paid')}, ${getPaymentLabelByType(bill.paymentType)}`}</Text>
            </Ternary>
          </Ternary>
        )}
      </Flex>
      <Flex flex="none" justifyContent="flex-end">
        {renderActions()}
      </Flex>
    </Flex>
  );
};

MyEventParticipant.defaultProps = {
  actions: null,
};

export default MyEventParticipant;
