import { useState } from 'react';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import t from 'resources/translations';
import Button from '@old/components/guide/Button';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import FlexRow from '@old/components/common/FlexRow';
import SelectOptionList from '@old/components/view/list/SelectOption';
import Option from '@old/components/view/listItem/SelectOption';
import { getErrorMessage, notify } from 'old/utils';

const InviteParticipantsModal = ({ event, onClose, cb }) => {
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const inviteParticipant = async () => {
    const invitedParticipantsIds = [...selectedParticipants.map(participant => participant.value)];
    try {
      await Model.Events.invite(event.id, invitedParticipantsIds);
      cb();
      setSelectedParticipants([]);
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const getFetchParamsForMembers = () => {
    if (event && event.isPast()) {
      return { with_status: ['pending', 'active', 'deleted'] };
    }
    return { with_status: ['pending', 'active'] };
  };

  if (!event) return null;

  return (
    <Modal header={t('general.inviteParticipants')} onClose={onClose} isOpen>
      <SelectOptionList
        name="membersSearch"
        onChange={setSelectedParticipants}
        loadOptions={(keyword, page) => Model.Members.fetchClientOptions(keyword, page, getFetchParamsForMembers())}
        optionComponent={Option.Member}
        value={selectedParticipants}
        customFilter={option => !option.isGuest}
        isOptionDisabled={optionData =>
          event
            .getParticipantsOptions(false)
            .map(participantOption => participantOption.value)
            .includes(optionData.value)
        }
        isMulti
      />
      <FlexRow noShrink>
        <ButtonOutline onClick={onClose} fluid>
          {t('general.cancel')}
        </ButtonOutline>
        <Button onClick={inviteParticipant} fluid async>
          {t('general.add')}
        </Button>
      </FlexRow>
    </Modal>
  );
};

InviteParticipantsModal.defaultProps = {
  event: null,
};

export default InviteParticipantsModal;
