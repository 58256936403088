import { useState, useRef } from 'react';
import { connect } from 'react-redux';

import CommentsList from '@old/components/view/list/Comment';
import RenderDate from '@old/components/old/RenderDate';
import ImageCircle from '@old/components/guide/ImageCircle';
import FlexColumn from '@old/components/common/FlexColumn';
import { getErrorMessage, notify } from 'old/utils';
import Spinner from '@old/components/common/Spinner';
import Box from '@old/components/common/Box';
import RowAligner from '@old/components/common/RowAligner';
import Subtitle from '@old/components/common/Subtitle';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Icon from '@old/components/icon';
import Input from '@old/components/input';
import Button from '@old/components/guide/Button';
import { ContextTrigger } from '@old/components/common/ContextMenu';
import { useEditPostButton, useDeletePostButton } from 'old/buttonHooks';
import { refreshCustomData } from 'store/actions';
import Model from '@old/model';
import t from 'resources/translations';
import { useOutsideClick, useKey } from '@old/hooks';
import { useOldLoggedMember } from 'utils/storeUtils';

const EventPost = ({ post, eventId, refreshPosts }) => {
  const loggedMember = useOldLoggedMember();
  const [active, setActive] = useState(false);
  const [editable, setEditable] = useState(false);
  const [postContent, setPostContent] = useState(post.content);
  const [loading, setLoading] = useState(false);

  const postRef = useRef();

  useOutsideClick(postRef, () => setEditable(false));
  const onEscapeCancelEdit = useKey(() => setEditable(false), 'esc');

  const editModeOn = () => {
    setEditable(true);
  };

  const editModeOff = () => {
    setEditable(false);
  };

  const onSubmit = () => {
    updatePost(post.id, postContent);
    editModeOff();
  };

  const onChange = value => {
    setPostContent(value);
  };

  const toggleComments = () => {
    setActive(!active);
  };

  const updatePost = async (postId, content) => {
    setLoading(true);
    try {
      await Model.Events.updatePost(eventId, postId, { content });
      refreshPosts('posts');
      notify(t('message.editingPostSuccess'));
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    setLoading(false);
  };

  const deletePost = async postId => {
    setLoading(true);
    try {
      await Model.Events.deletePost(eventId, postId);
      refreshPosts('posts');
      notify(t('message.deletePost'));
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
    setLoading(false);
  };

  const getShowMoreLabel = length => {
    return length
      ? `${t('eventPost.comments', { count: length })} (${length})`
      : t('eventPost.comments', { count: length });
  };

  const postEdit = useEditPostButton(editModeOn, post.author);
  const postDelete = useDeletePostButton(deletePost, post, loggedMember);

  const buttons = [postEdit, postDelete];

  return (
    <Box>
      <FlexColumn>
        {loading && <Spinner />}
        <ContextTrigger menuItems={buttons}>
          <FlexColumn>
            <RowAligner separated="small">
              <ImageCircle src={post.author.getAvatar('thumb')} small />
              <div className="flex-1">
                <Subtitle>{post.author.getName()}</Subtitle>
              </div>
              <RenderDate startDate={post.createdAt} />
            </RowAligner>
            <div ref={postRef}>
              {editable ? (
                <FlexColumn>
                  <Input.Textarea
                    value={postContent}
                    content={postContent}
                    onChange={onChange}
                    onKeyDown={onEscapeCancelEdit}
                  />
                  <div className="text-right mb-4">
                    <Button onClick={onSubmit}>{t('general.save')}</Button>
                  </div>
                </FlexColumn>
              ) : (
                post.content
              )}
            </div>
            <div className="flex justify-end">
              {active ? (
                <ButtonSimple onClick={toggleComments}>
                  <span className="text-highlight">{t('eventPost.collapse')}</span>
                  <Icon.ArrowUp small />
                </ButtonSimple>
              ) : (
                <ButtonSimple onClick={toggleComments}>
                  <div className="text-highlight">{getShowMoreLabel(post.comments.length)}</div>
                </ButtonSimple>
              )}
            </div>
          </FlexColumn>
        </ContextTrigger>
        {active && <CommentsList postId={post.id} eventId={eventId} comments={post.comments} />}
      </FlexColumn>
    </Box>
  );
};

export default connect(null, { refreshPosts: refreshCustomData })(EventPost);
