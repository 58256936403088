import { useFormik } from 'formik';
import FormField from 'components/combinations/forms/FormField';
import FormLabel from 'components/combinations/forms/FormLabel';
import { Box, Button, Icons, MotionBox, Span, Stack, Ternary, Text } from 'components/elements';
import { BILLS_STATUS_OPTIONS, DATE_FORMAT, PAYMENT_STATUS_COMPACT } from 'config/constans';
import DateInput from 'components/combinations/forms/DateInput';
import SelectMulti from 'components/combinations/forms/SelectMultiInput';
import { BillService, HorseService, MemberService, PlaceService } from 'services';
import { useEffect, useState } from 'react';
import CheckboxMulti from 'components/combinations/forms/CheckboxMulti';
import axios from 'axios';
import { mapBillFiltersToParams, pickOnlyExisting, useFilters, yup, useAppStore, useLoggedMember } from 'utils';
import { Card, CardContent, PageTitle } from 'components/combinations';
import _ from 'lodash';
import moment from 'moment';
import t from 'resources/translations';
import { TOption } from 'resources/types/commonTypes';
import { TBillFilters } from 'resources/types/billsTypes';
import SelectWithModal from 'components/combinations/forms/SelectWithModal';
import SelectModalGroups from 'components/combinations/select/SelectModalGroups';

type TBillsFiltersProps = {
  onClose: () => void;
  onAfterSubmit: () => void;
};

const BillsFilters = ({ onClose, onAfterSubmit }: TBillsFiltersProps) => {
  const loggedMember = useLoggedMember();
  const { isMobile } = useAppStore();
  const { filters, onChangeFilters } = useFilters<TBillFilters>('BILLS');
  const [isChecking, setIsChecking] = useState(false);
  const [countBillsFound, setCountBillsFound] = useState(0);
  const defaultValues = {
    start_after: '',
    end_before: '',
    payment_type: [],
    statuses: [],
    instructors: [],
    participants: [],
    places: [],
    horses: [],
    billable_type: [],
  } as TBillFilters;
  const initialValues = _.isEmpty(filters) ? defaultValues : filters;
  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onChangeFilters(pickOnlyExisting(values));
      onAfterSubmit();
      onClose();
    },
    validationSchema: yup.object().shape({
      start_after: yup.string().format(DATE_FORMAT, true),
      end_before: yup.string().format(DATE_FORMAT, true),
    }),
  });

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [isMobile]);

  useEffect(() => {
    setIsChecking(true);
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const getBillsCount = async () => {
      try {
        const fetchParams = mapBillFiltersToParams(formik.values);
        const { pagination } = await BillService.fetchAll({ per_page: 1, ...fetchParams }, source.token);
        setCountBillsFound(pagination.count || 0);
      } catch {
      } finally {
        setIsChecking(false);
      }
    };
    getBillsCount();
    return () => source.cancel();
  }, [formik.values]);

  const paymentStatusAddTransfer = [
    { label: t('paymentStatus.transfer'), value: 'transfer' },
    ...PAYMENT_STATUS_COMPACT,
  ];

  const isTicketSelected = !!formik.values.billable_type?.find(option => option.value === 'Ticket');
  const isTicketSelectDisabled =
    !_.isEmpty(formik.values.horses) ||
    !_.isEmpty(formik.values.places) ||
    (formik.values.statuses || []).find(({ value }) => value === 'overpaid');

  return (
    <Card maxW={900}>
      <Box px={[0, 'xl']} pt="lg">
        <PageTitle title={t('bills.advancedFilters')} loading={isChecking} back={onClose} />
      </Box>
      <CardContent alignItems="start" pb="md" px={['md', 'xl']}>
        <Button variant="link" py="sm" mb="lg" onClick={() => formik.resetForm({ values: defaultValues })}>
          <Stack spacing="sm" isHorizontal alignItems="center">
            <Icons.TrashCircle bgColor="teal-light-15" />
            <Text>{t('bills.clearFilters')}</Text>
          </Stack>
        </Button>
        <form style={{ width: '100%' }}>
          <Stack spacing="sm">
            <FormField>
              <FormLabel htmlFor="type">{t('labels.billType')}</FormLabel>
              <CheckboxMulti
                name="billable_type"
                options={[
                  {
                    value: 'Participation',
                    label: t('billsFilters.dueForEvent'),
                  },
                  {
                    value: 'Ticket',
                    label: t('billsFilters.dueForTicket'),
                  },
                ]}
                disabled={isTicketSelectDisabled ? ['Ticket'] : []}
                value={formik.values.billable_type as TOption[]}
                onChange={value => {
                  formik.setFieldTouched('billable_type');
                  formik.setFieldValue('billable_type', value);
                }}
              />
            </FormField>

            <Stack spacing="sm" isHorizontal={!isMobile}>
              <FormField errors={formik.errors.start_after}>
                <FormLabel info={t('billsFilters.dateRangeInfo')} htmlFor="start_after">
                  {t('labels.dateFrom')}
                </FormLabel>
                <DateInput
                  name="start_after"
                  placeholder={t('placeholders.date')}
                  value={formik.values.start_after}
                  onChange={(value: string) => {
                    formik.setFieldValue('start_after', value);
                    formik.setFieldTouched('start_after');
                  }}
                  pickerOptions={{ maxDate: moment(formik.values.end_before, DATE_FORMAT) }}
                />
              </FormField>
              <FormField errors={formik.errors.end_before}>
                <FormLabel info={t('billsFilters.dateRangeInfo')} htmlFor="end_before">
                  {t('labels.dateTo')}
                </FormLabel>
                <DateInput
                  name="end_before"
                  placeholder={t('placeholders.date')}
                  value={formik.values.end_before}
                  onChange={(value: string) => {
                    formik.setFieldValue('end_before', value);
                    formik.setFieldTouched('end_before');
                  }}
                  pickerOptions={{ minDate: moment(formik.values.start_after, DATE_FORMAT) }}
                />
              </FormField>
            </Stack>

            <FormField>
              <FormLabel htmlFor="payment_type">{t('labels.paymentType')}</FormLabel>
              <SelectMulti
                name="payment_type"
                placeholder={t('placeholders.paymentType')}
                value={formik.values.payment_type}
                onChange={values => {
                  formik.setFieldValue('payment_type', values);
                  formik.setFieldTouched('payment_type');
                }}
                options={paymentStatusAddTransfer.map(status => ({
                  key: status.value,
                  value: status.value,
                  label: status.label,
                }))}
              />
            </FormField>
            {(loggedMember.isClient() || loggedMember.isOwner()) && (
              <FormField>
                <FormLabel htmlFor="instructors">{t('labels.instructors')}</FormLabel>
                <SelectWithModal
                  name="instructors"
                  placeholder={t('billsFilters.selectInstructors')}
                  value={formik.values.instructors}
                  loadOptions={({ pageParam, keyword }) => {
                    return MemberService.fetchOptions(
                      keyword,
                      { page: pageParam },
                      { with_status: ['active'], with_role: 'instructor' }
                    );
                  }}
                  onChange={(values: TOption[]) => {
                    formik.setFieldTouched('instructors');
                    formik.setFieldValue('instructors', values);
                  }}
                  translations={{
                    title: t('placeholders.selectInstructors'),
                    searchLabel: t('labels.searchInstructor'),
                    searchPlaceholder: t('placeholders.searchInstructor'),
                  }}
                />
              </FormField>
            )}
            {(loggedMember.isInstructor() || loggedMember.isOwner()) && (
              <FormField>
                <FormLabel htmlFor="participants">{t('labels.clients')}</FormLabel>
                <SelectWithModal
                  name="participants"
                  placeholder={t('billsFilters.selectParticipants')}
                  value={formik.values.participants}
                  loadOptions={({ pageParam, keyword }) => {
                    return MemberService.fetchOptions(
                      keyword,
                      { page: pageParam },
                      { with_status: ['active', 'pending'], with_role: 'client' }
                    );
                  }}
                  onChange={(values: any) => {
                    formik.setFieldTouched('participants');
                    formik.setFieldValue('participants', values);
                  }}
                  translations={{
                    title: t('placeholders.selectClients'),
                    searchLabel: t('labels.searchClient'),
                    searchPlaceholder: t('placeholders.searchClient'),
                  }}
                  customModalComponent={SelectModalGroups}
                ></SelectWithModal>
              </FormField>
            )}
            <FormField>
              <FormLabel htmlFor="horses" disabled={isTicketSelected}>
                {t('labels.horses')}
              </FormLabel>
              <SelectWithModal
                name="horses"
                placeholder={t('billsFilters.selectHorses')}
                value={formik.values.horses}
                loadOptions={({ pageParam, keyword }) => {
                  return HorseService.fetchOptions(keyword, { page: pageParam });
                }}
                onChange={(values: TOption[]) => {
                  formik.setFieldTouched('horses');
                  formik.setFieldValue('horses', values);
                }}
                translations={{
                  title: t('placeholders.selectHorses'),
                }}
                disabled={isTicketSelected}
              />
            </FormField>
            <FormField>
              <FormLabel htmlFor="places" disabled={isTicketSelected}>
                {t('labels.places')}
              </FormLabel>
              <SelectWithModal
                name="places"
                placeholder={t('billsFilters.selectPlaces')}
                value={formik.values.places}
                loadOptions={({ pageParam, keyword }) => {
                  return PlaceService.fetchOptions(keyword, { page: pageParam });
                }}
                onChange={(values: TOption[]) => {
                  formik.setFieldTouched('places');
                  formik.setFieldValue('places', values);
                }}
                translations={{
                  title: t('placeholders.selectPlaces'),
                }}
                disabled={isTicketSelected}
              />
            </FormField>
            <FormField>
              <FormLabel htmlFor="statuses">{t('labels.selectStatus')}</FormLabel>
              <CheckboxMulti
                name="statuses"
                options={BILLS_STATUS_OPTIONS}
                disabled={isTicketSelected ? ['overpaid'] : []}
                value={formik.values.statuses as TOption[]}
                onChange={value => {
                  formik.setFieldTouched('statuses');
                  formik.setFieldValue('statuses', value);
                }}
              />
            </FormField>
            <Box>
              <Button
                onClick={formik.submitForm}
                disabled={!formik.isValid || !formik.dirty || _.isEmpty(formik.touched)}
                px="md"
              >
                <Stack spacing="sm" isHorizontal>
                  <Text fontSize="md">{t('buttons.showBills')}</Text>
                  <Box>
                    <Ternary cond={isChecking}>
                      <MotionBox
                        transition={{ ease: 'linear', duration: 2, repeat: Infinity }}
                        animate={{ rotate: 360 }}
                        display="flex"
                        flex="none"
                        width="auto"
                        alignItems="center"
                      >
                        <Icons.Spinner />
                      </MotionBox>
                      <Span>({countBillsFound})</Span>
                    </Ternary>
                  </Box>
                </Stack>
              </Button>
            </Box>
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};

export default BillsFilters;
