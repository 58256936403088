import { connect } from 'react-redux';
import { compact } from 'lodash';

import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_PLACE } from 'old/constants/formNames';
import { changeFieldState } from 'store/actions';
import { isLengthBetween, getChanges, mapFormStateToData, getInitValues } from 'old/utils';

const PlacesForm = ({ doc, onChangeField, initValues, ...props }) => {
  const formName = FORM_PLACE(doc ? doc.id : '');

  const initialValues = getInitValues({
    formName: 'place',
    customInitValues: doc ? Model.Place.mapToFormData(doc) : initValues,
  });

  const onSubmit = formState => {
    const values = doc
      ? getChanges(formState, mapFormStateToData({ formState: initialValues, initValue: true }))
      : formState;
    return props.onSubmit(Model.Place.mapToSaveData(values));
  };

  const setSlugAfterNameChange = ({ value }) => {
    let newSlug = '';
    const eventTypeNameString = value.trim();
    if (eventTypeNameString.indexOf(' ') !== -1) {
      const eventTypeNameCharsArray = compact(eventTypeNameString.split(' '));
      newSlug = `${eventTypeNameCharsArray[0][0]}${eventTypeNameCharsArray[1][0]}`;
    } else {
      newSlug = eventTypeNameString ? eventTypeNameString.slice(0, 2) : '';
    }

    onChangeField({
      formName,
      fieldName: 'placeSlug',
      fieldState: newSlug,
    });
  };

  const validations = {
    slug: [
      {
        condition: value => isLengthBetween({ value, min: 1, max: 2 }),
        errorHint: t('error.fieldMustHaveLengthBetween', { min: 1, max: 2 }),
      },
    ],
  };

  return (
    <Form formName={formName} initValues={initialValues}>
      <Field.Text
        name="placeName"
        label={t('labels.name')}
        afterChanges={setSlugAfterNameChange}
        placeholder={t('placeholders.name')}
        required
      />

      <Field.Text
        name="placeSlug"
        label={t('labels.slug')}
        validation={validations.slug}
        placeholder={t('placeholders.enterSlug')}
        required
      />

      <FormButton formName={formName} onClick={onSubmit} async>
        {doc ? t('general.saveChanges') : t('placesForm.addPlace')}
      </FormButton>
    </Form>
  );
};

PlacesForm.defaultProps = {
  doc: null,
  initValues: {},
};

export default connect(null, { onChangeField: changeFieldState })(PlacesForm);
