import t from 'resources/translations';
import { Modal, ModalHeader, ModalCloseButton, ModalContent } from 'components/combinations';
import { TStoreStateModals, TWrappedModal } from '.';
import PaymentsList from 'views/Bills/PaymentsList';
import Bill from 'models/Bill';
import { Box, Stack, Text } from 'components/elements';
import { TBoxProps } from 'resources/types/elementsTypes';
import { useShallowSelector } from 'old/hooks';

const boxStyles: TBoxProps = {
  fontWeight: 'bold',
  border: 'orange',
  borderWidth: 1,
  width: 20,
  height: 20,
  borderStyle: 'solid',
  borderRadius: '9999px',
  textAlign: 'center',
};

type THistoryPayments = {
  bill: Bill;
  isOptOut?: boolean;
};

const HistoryPayments = ({ onClose, isHidden }: TWrappedModal) => {
  const { bill, isOptOut } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['BILL_HISTORY_PAYMENTS'].props
  ) as THistoryPayments;

  return (
    <Modal isHidden={isHidden} onClose={onClose} isOpen>
      <ModalHeader>{t('bills.paymentsHistory')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent>
        {isOptOut && (
          <Stack pl="sm" isHorizontal spacing="sm" alignItems="center">
            <Box {...boxStyles}>
              <Text color="orange">!</Text>
            </Box>
            <Text color="orange">{t('eventRiderAction.rejectInvitation')}</Text>
          </Stack>
        )}
        <PaymentsList bill={bill} noLabel quickTransactionDetails />
      </ModalContent>
    </Modal>
  );
};

export default HistoryPayments;
