import React from 'react';

const ArrowUp = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      width="100%"
      className="icon"
    >
      <path d="M14.1,12.9L8,6.8l-6.1,6.1L0,11.1l8-8l8,8L14.1,12.9z" />
    </svg>
  );
};

export default ArrowUp;
