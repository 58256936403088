import styled from 'styled-components';
import { flexbox } from 'styled-system';
import { blockStyleProps, typographyStyleProps, baseStyledProps } from 'config/styledProps';
import { TFlexProps } from 'resources/types/elementsTypes';

const Flex = styled('div')<TFlexProps>(baseStyledProps, typographyStyleProps, blockStyleProps, flexbox, {
  display: 'flex',
});

Flex.defaultProps = {
  flex: 1,
};

export default Flex;
