/* eslint-disable max-len */
import React from 'react';

const MoreHorizontalOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,0c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S3.6,0,8,0z M11.1,7c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1
        C12.2,7.5,11.7,7,11.1,7z M8,7C7.5,7,7,7.5,7,8c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1S8.6,7,8,7z M4.9,7c-0.6,0-1,0.5-1,1
        c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1S5.5,7,4.9,7z"
      />
    </svg>
  );
};

export default MoreHorizontalOutline;
/* eslint-enable max-len */
