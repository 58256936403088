import moment from 'moment';
import truncate from 'lodash/truncate';

import { Member } from '@old/model/Member';
import { EventType } from '@old/model/EventType';
import BaseModel from '@old/model/BaseModel';
import config from '@old/config';
import { getObjectWithMinValueByKey, mapModelPictures } from 'old/utils/Common';

class Horses extends BaseModel {
  constructor(options) {
    super({
      modelName: 'horses',
      updateKey: 'horse',
      basePath: '/api/v1/farms/{farmId}/horses',
      ItemClass: Horse,
      ...options,
    });
  }

  mapToSelectOption = (horse) => {
    return {
      key: horse.id,
      value: horse.id,
      label: horse.getName(),
      image: horse.getAvatar('thumb'),
      skill: horse.getSkill(),
      fatigue: horse.fatigue,
      healthStatus: horse.getHealthStatus(),
    };
  };

  getAbsences = async (horseId, params) => {
    const response = await this.client.get(`${this.basePath}/${horseId}/absences`, { params });

    const absences = (response.data.absences || []).map(absence => ({
      id: absence.id,
      beginning: moment(absence.beginning),
      ending: moment(absence.ending),
      reason: absence.reason,
    }));

    return [absences, response.data.pagination];
  };

  addAbsence = async (horseId, startDate, endDate, reason) => {
    const response = await this.client.post(`${this.basePath}/${horseId}/absences`, {
      shift: {
        beginning: startDate,
        ending: endDate,
        reason,
      },
    });

    return response;
  };

  updateAbsence = async (horseId, absenceId, startDate, endDate, reason) => {
    const response = await this.client.patch(`${this.basePath}/${horseId}/absences/${absenceId}`, {
      shift: {
        beginning: startDate,
        ending: endDate,
        reason,
      },
    });

    return response;
  };

  deleteAbsence = async (horseId, absenceId) => {
    const response = await this.client.delete(`${this.basePath}/${horseId}/absences/${absenceId}`);

    return response;
  };

  getStatistics = async (horseId, params = {}) => {
    let { start, end } = params;
    if (!start || !end) {
      start = moment().subtract(7, 'days').startOf('day').toISOString();
      end = moment().subtract(1, 'days').endOf('day').toISOString();
    }

    try {
      const response = await this.client.get(`${this.basePath}/${horseId}/statistics`, {
        params: {
          'stats[start]': start,
          'stats[end]': end,
        },
      });
      return response.data.statistics;
    } catch (e) {
      return null;
    }
  };

  getEventTypeStatistics = async (params = {}) => {
    let { start, end } = params;

    if (!start || !end) {
      start = moment().startOf('week').format('YYYY-MM-DD');
      end = moment().endOf('week').format('YYYY-MM-DD');
    }
    try {
      const response = await this.client.get(`${this.basePath}/events`, {
        params: {
          'stats[start]': start,
          'stats[end]': end,
        },
      });
      return response.data;
    } catch (e) {
      return null;
    }
  };

  getWeeklyStatistics = async (horseId, params = {}) => {
    let { start, end } = params;
    if (!start || !end) {
      start = moment().subtract(7, 'days').startOf('day').toISOString();
      end = moment().subtract(1, 'days').endOf('day').toISOString();
    }

    try {
      const response = await this.client.get(`${this.basePath}/${horseId}/statistics/weekly`, {
        params: {
          'stats[start]': start,
          'stats[end]': end,
        },
      });
      return response.data.statistics;
    } catch (e) {
      return null;
    }
  };

  rate = async (horseId, params) => {
    const rate = {
      ...params,
      comment: params.comment?.trim(),
    };

    const response = await this.client.post(`${this.basePath}/${horseId}/rate`, { rate });
    return response.data;
  };

  toggleRating = async (horseId, rateId) => {
    const response = await this.client.post(`${this.basePath}/${horseId}/toggle_rate`, { rate_id: rateId });
    return response.data;
  };

  fetchRatings = async (horseId, { cancelToken, ...params }) => {
    const response = await this.client.get(`${this.basePath}/${horseId}/rates`, { cancelToken, params });
    return [response.data.rates.map(rating => ({
      ...rating,
      rater: new Member(rating.rater),
    })), response.data.pagination];
  };

  deleteRating = async (horseId, rateId) => {
    const response = await this.client.delete(`${this.basePath}/${horseId}/rate/${rateId}`);
    return response.data;
  };

  fetchOptions = async (keyword, page, fetchParams) => {
    const [horses, pagination] = await this.fetchAll({
      per_page: 20,
      keyword,
      page,
      sorted_by: 'fatigue_total_asc',
      ...fetchParams,
    });

    const mappedOptions = horses.map(this.mapToSelectOption);

    return {
      options: mappedOptions,
      hasMore: pagination.pages > page,
      additional: { page: page + 1 },
    };
  };
}

class Horse {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description || '';
    this.range = data.range;
    this.eventTypes = data.event_types ? data.event_types.map(eventType => new EventType(eventType)) : [];
    this.difficulty = data.difficulty;
    this.breed = data.breed;
    this.birthday = moment(data.birthday, 'YYYY-MM-DD');
    this.rating = data.rating;
    this.healthStatus = data.health_status;
    this.primaryPictureId = data.primary_picture_id || null;
    this.deleted = data.deleted;
    this.fatigue = data.fatigue && {
      fatigue_total: Math.round(data.fatigue.fatigue_total),
      hours_daily: Math.round(data.fatigue.hours_daily),
      hours_tridaily: Math.round(data.fatigue.hours_tridaily),
      hours_weekly: Math.round(data.fatigue.hours_weekly),
    };
    this.pictures = mapModelPictures(data.pictures, '/img/horse-avatar-placeholder.png');
    this.privileges = data.meta ? data.meta.privileges : [];
  }

  static mapToSaveData = (data) => {
    const horse = {
      name: data.horseName,
      range: data.range,
      event_type_ids: data.eventTypes,
      difficulty: data.difficulty,
      breed: data.breed,
      birthday: data.birthday && moment(data.birthday, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      description: data.horseDescription,
      health_status: data.healthStatus,
      primary_picture_id: data.primaryPictureId,
      pictures_attributes: data.pictures && data.pictures.map(picture => ({
        id: picture.id,
        description: picture.description,
        file: picture.file,
        _destroy: picture.destroy,
      })),
    };

    Object.keys(horse).forEach((fieldName) => {
      if (horse[fieldName] === undefined) delete horse[fieldName];
    });

    return horse;
  };

  static mapToFormData = (data) => {
    const horseData = {
      horseName: data.name,
      range: data.range,
      eventTypes: data.getEvenTypesOptions(),
      difficulty: config.difficulty.find(d => d.value === data.getSkill()),
      breed: data.breed,
      birthday: data.birthday.format(config.dateFormat),
      horseDescription: data.description,
      healthStatus: data.healthStatus,
    };
    return horseData;
  };

  update = async changes => this.model.update(this.id, changes);

  mapToOption = item => ({
    value: item.id,
    label: item.getName(),
  });

  getEvenTypesOptions() {
    return this.getEventTypes().map(this.mapToOption);
  }

  getEventTypes() {
    return this.eventTypes || [];
  }

  getName(limit = false) {
    return limit ? truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getAge() {
    return (parseInt(moment().format('YYYY'), 10) - parseInt(this.birthday.format('YYYY'), 10)) || 0;
  }

  getBirthday() {
    return this.birthday.format('LL');
  }

  getImage(size) {
    return this.getAvatar(size);
  }

  isDeleted() {
    return this.deleted || false;
  }

  getPermission(permissionKey) {
    return this.privileges.includes(permissionKey);
  }

  getAvatar(size) {
    if (!this.pictures || this.pictures.length === 0) {
      return '/img/horse-avatar-placeholder.png';
    }

    let mainPicture = null;

    if (!this.primaryPictureId) {
      mainPicture = getObjectWithMinValueByKey(this.pictures, 'id');
    } else {
      mainPicture = this.pictures.find(picture => picture.id === this.primaryPictureId);
    }

    return (mainPicture && mainPicture.url)
      ? (mainPicture.url[size] || mainPicture.url.medium)
      : '/img/horse-avatar-placeholder.png';
  }

  getSkill() {
    return this.difficulty || 'missing';
  }

  getSlugInfo() {
    const difficulty = config.difficulty.find(d => this.difficulty === d.value) || config.difficulty[0];

    const slugInfo = {
      color: difficulty.color,
      text: difficulty.slug,
    };

    return slugInfo;
  }

  getHealthStatus() {
    return this.healthStatus;
  }

  async updatePicture(picturesData) {
    return this.model.update(this.id, Horse.mapToSaveData(picturesData));
  }

  getProfileUrl() {
    return `/horses/${this.id}`;
  }
}

export { Horse, Horses };
