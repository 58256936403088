import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import AccordionContext from './AccordionContext';
import Collapse from './Collapse';
import Toggle from './Toogle';

const Accordion = ({
  element: Component,
  activeEventKey,
  onToggle,
  children,
  ...otherProps
}) => {
  const context = useMemo(() => {
    return {
      activeEventKey,
      onToggle,
    };
  }, [activeEventKey, onToggle]);
  return (
    <AccordionContext.Provider value={context}>
      <Component {...otherProps}>{children}</Component>
    </AccordionContext.Provider>
  );
};

Accordion.propTypes = {
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  activeEventKey: PropTypes.PropTypes.number.isRequired,
  onToggle: PropTypes.func,
  children: PropTypes.any.isRequired, // eslint-disable-line
};

Accordion.defaultProps = {
  element: 'div',
  onToggle: () => {},
};

Accordion.Toggle = Toggle;
Accordion.Collapse = Collapse;

export default Accordion;
