import { TBillFilters } from 'resources/types/billsTypes';
import { TTicketFilters } from 'resources/types/TicketTypes';
import { SET_FILTERS, RESET_FILTERS, TFilterType, TFilters } from 'store/actions/filtersActions';

export interface IFiltersStore {
  BILLS: TBillFilters;
  TICKETS: TTicketFilters;
}

export type TFiltersPayload = {
  type: 'SET_FILTERS' | 'RESET_FILTERS';
  filters: TFilters;
  filtersType: TFilterType;
};

const initialState: IFiltersStore = {
  BILLS: {},
  TICKETS: {},
};

const filtersReducer = (state = initialState, payload: TFiltersPayload) => {
  switch (payload.type) {
    case SET_FILTERS:
      return {
        ...state,
        [payload.filtersType]: payload.filters,
      };
    case RESET_FILTERS:
      return {
        ...state,
        [payload.filtersType]: {},
      };
    default:
      return state;
  }
};

export default filtersReducer;
