/* eslint-disable max-len */
import React from 'react';

const Add = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      width="100%"
      className="icon"
    >
      <path d="M6.4,0v6.4H0v3.2h6.4V16h3.2V9.6H16V6.4H9.6V0C9.6,0,6.4,0,6.4,0z" />
    </svg>
  );
};

export default Add;
/* eslint-enable max-len */
