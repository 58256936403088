import Measure from 'react-measure';

import { Grid } from '@elements';
import PlacesGridItem from '@old/components/view/gridItem/Place';
import { countGridColumns } from 'old/utils';

const PlacesGrid = ({ data }) => {
  return (
    <Measure bounds>
      {({ measureRef: itemRef, _, contentRect }) => {
        const columns = countGridColumns(contentRect.bounds.width);
        return (
          <div ref={itemRef}>
            <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} gridGap="md">
              {data.map(place => {
                return <PlacesGridItem key={place.id} place={place} />;
              })}
            </Grid>
          </div>
        );
      }}
    </Measure>
  );
};

export default PlacesGrid;
