import PropTypes from 'prop-types';
import FlexColumn from '@old/components/common/FlexColumn';
import Box from '@old/components/common/Box';
import Button from '@old/components/guide/Button';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import RowAligner from '@old/components/common/RowAligner';
import BoxWhite from '@old/components/common/BoxWhite';
import FlexRow from '@old/components/common/FlexRow';
import t from 'resources/translations';
import { useAppStore, useOldLoggedMember } from 'utils';

const EventRiderAction = ({ event, buttons }) => {
  const loggedMember = useOldLoggedMember();
  const { isMobile, isTablet } = useAppStore();
  const { memberRequestInvitation, isProposed } = event.getStatuses(loggedMember.id);
  const loggedMemberIsParticipant = event.memberPartcipates(loggedMember.id);
  const unjoinTimeout = event.unjoinTimeout / 3600;

  const [eventJoin, eventUnjoin, eventRejectInvitation, eventAcceptInvitation] = buttons;
  const fluid = !(isTablet || isMobile);

  const renderLabel = () => {
    if (eventJoin && unjoinTimeout > 0) {
      return t('eventRiderAction.eventJoinLabelWithUnjoinTimeout', { value: unjoinTimeout });
    }
    if (eventJoin && unjoinTimeout === 0) return t('eventRiderAction.eventJoinLabel');
    if (eventUnjoin && !event.canUnjoin()) return t('eventRiderAction.eventUnjoinLabel');
    if (event.canUnjoin()) return t('eventRiderAction.unjoinTimeoutLabel', { value: unjoinTimeout });
    if (eventRejectInvitation || eventAcceptInvitation) return t('eventRiderAction.eventInvitationLabel');
    if (memberRequestInvitation) return t('eventRiderAction.eventRequestLabel');
    if (isProposed) return t('eventRiderAction.proposalWaitingInstructorApprove');
    return loggedMemberIsParticipant ? t('eventRiderAction.tooLateToUnjoin') : t('eventRiderAction.tooLate');
  };

  const renderAction = () => {
    if (eventJoin)
      return (
        <Button fluid={fluid} onClick={eventJoin.onClick} async>
          {t('buttons.signUp')}
        </Button>
      );
    if (eventUnjoin) {
      return (
        <Button disabled={event.canUnjoin()} onClick={eventUnjoin.onClick} fluid={fluid} async>
          {t('buttons.signOff')}
        </Button>
      );
    }
    if (eventRejectInvitation || eventAcceptInvitation) {
      return (
        <RowAligner>
          {eventAcceptInvitation && (
            <Button onClick={eventAcceptInvitation.onClick} fluid={fluid} async>
              {t('buttons.accept')}
            </Button>
          )}
          {eventRejectInvitation && (
            <ButtonOutline onClick={eventRejectInvitation.onClick} fluid={fluid} async>
              {t('buttons.reject')}
            </ButtonOutline>
          )}
        </RowAligner>
      );
    }
    return null;
  };

  if (isTablet || isMobile) {
    return (
      <BoxWhite>
        <FlexRow alignCenter cols="1/none">
          <div>{renderLabel()}</div>
          {renderAction()}
        </FlexRow>
      </BoxWhite>
    );
  }

  return (
    <div>
      <FlexColumn>
        <Box>{renderLabel()}</Box>
        {renderAction()}
      </FlexColumn>
    </div>
  );
};

EventRiderAction.defaultProps = {
  buttons: [],
};

EventRiderAction.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      iconName: PropTypes.string,
      icon: PropTypes.node,
    })
  ),
};

export default EventRiderAction;
