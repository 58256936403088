import { AxiosResponse as ARes } from 'axios';
import HTTP from 'config/axios';
import { TPaymentType } from 'resources/types/billsTypes';

import { IResWithPagination } from 'resources/types/commonTypes';
import { IEventFetchParams, IEventRes } from 'resources/types/eventsTypes';
import { IParticipationRes } from 'resources/types/participationsTypes';
import { IProposalApproveParams, IProposalFetchParams, IProposalRes } from 'resources/types/proposalsTypes';

export interface IEventAddPaymentParams {
  participation: {
    payment_status: Exclude<TPaymentType, 'transfer' | 'unpaid'>;
    paid_at: string;
  };
}
class EventService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async fetch(eventId: number | string) {
    const response: ARes<IEventRes> = await HTTP.get(`${this.apiBaseURL}/events/${eventId}`);
    return response.data;
  }

  async fetchAll(params: IEventFetchParams) {
    const response: ARes<IResWithPagination<IEventRes[]>> = await HTTP.get(`${this.apiBaseURL}/events`, { params });
    return response.data;
  }

  async fetchProposal(eventId: number | string) {
    const response: ARes<IProposalRes> = await HTTP.get(`${this.apiBaseURL}/events/proposals/${eventId}`);
    return response.data;
  }

  async fetchAllProposals(params: IProposalFetchParams) {
    const response: ARes<IResWithPagination<IProposalRes[]>> = await HTTP.get(`${this.apiBaseURL}/events/proposals`, {
      params,
    });
    return response.data;
  }

  async rejectProposal(eventId: number) {
    const response: ARes<IProposalRes> = await HTTP.patch(`${this.apiBaseURL}/events/proposals/${eventId}/reject`);
    return response.data;
  }

  async approveProposal(eventId: number, params?: IProposalApproveParams) {
    const response: ARes<IEventRes> = await HTTP.patch(
      `${this.apiBaseURL}/events/proposals/${eventId}/approve`,
      params
    );
    return response.data;
  }

  async join(eventId: number) {
    const response: ARes<IParticipationRes> = await HTTP.put(`${this.apiBaseURL}/events/${eventId}/join`);
    return response.data;
  }

  async unjoin(eventId: number) {
    const response: ARes<IParticipationRes> = await HTTP.put(`${this.apiBaseURL}/events/${eventId}/unjoin`);
    return response.data;
  }

  async rejectInvitation(eventId: number) {
    const response: ARes<IParticipationRes> = await HTTP.put(`${this.apiBaseURL}/events/${eventId}/reject`);
    return response.data;
  }
}

export default new EventService();
