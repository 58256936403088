/**
 * @module Indicator
 */
import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
/**
 * Simple indicator with default color
 * @param  {Boolean} [props.active] specifies if indicator is active(only border or fully colored)
 */
const Indicator = ({ active }) => {
  return (
    <div className={cls('indicator', { active })} />
  );
};

Indicator.defaultProps = {
  active: false,
};

Indicator.propTypes = {
  active: PropTypes.bool,
};

export default Indicator;
