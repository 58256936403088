/* eslint-disable max-len */
import React from 'react';

const CheckList = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M13.5,1.2H11l0.2,0.8c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2l0,0H13v12.7H3V2.2h1.8c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2l0,0
        L5,1.2H2.5c-0.3,0-0.5,0.2-0.5,0.5l0,0v13.8c0,0,0,0,0,0c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0h0h11c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5
        c0,0,0,0,0,0v0V1.7c0,0,0,0,0,0C14.1,1.4,13.8,1.2,13.5,1.2L13.5,1.2L13.5,1.2z M11.7,5H8C7.7,5,7.5,5.3,7.5,5.6
        c0,0.3,0.2,0.5,0.5,0.5h3.7c0.3,0,0.5-0.2,0.5-0.5S12,5,11.7,5L11.7,5L11.7,5z M11.7,8.3H8c-0.3,0-0.4,0.3-0.4,0.5
        c0,0.3,0.2,0.5,0.4,0.5l0,0h3.7c0,0,0.1,0,0.1,0c0.3,0,0.5-0.2,0.5-0.5C12.3,8.6,12.1,8.3,11.7,8.3C11.8,8.3,11.7,8.3,11.7,8.3
        L11.7,8.3L11.7,8.3z M4,5.2C3.8,5.3,3.8,5.4,3.8,5.6c0,0.1,0,0.3,0.1,0.3l0,0l0.8,0.9C4.8,7,4.9,7,5.1,7c0,0,0,0,0,0h0
        c0.2,0,0.3-0.1,0.4-0.3l0,0L7,4.5C7,4.4,7.1,4.3,7.1,4.1c0-0.3-0.2-0.5-0.5-0.5C6.3,3.6,6.1,3.7,6,3.9l0,0L5,5.6L4.7,5.2
        C4.6,5.1,4.5,5,4.3,5C4.2,5,4,5.1,4,5.2L4,5.2L4,5.2z M5.2,8.1c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8C5.7,9.7,6,9.3,6,8.9
        l0,0C6,8.4,5.7,8.1,5.2,8.1L5.2,8.1z M11.7,11.6H8c-0.3,0-0.4,0.3-0.4,0.5c0,0.3,0.2,0.5,0.4,0.5l0,0h3.7c0,0,0.1,0,0.1,0
        c0.3,0,0.5-0.2,0.5-0.5C12.3,11.8,12.1,11.6,11.7,11.6C11.8,11.6,11.7,11.6,11.7,11.6L11.7,11.6L11.7,11.6z M5.2,11.3
        c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8l0,0C6,11.7,5.7,11.3,5.2,11.3L5.2,11.3z M5.7,2.5h4.6
        c0.1,0,0.2-0.1,0.3-0.1l0,0c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1l0,0l-0.4-1.8c0-0.2-0.2-0.3-0.4-0.3H6.1C5.9,0,5.8,0.1,5.7,0.3
        l0,0L5.4,2c0,0,0,0,0,0.1c0,0.1,0,0.2,0.1,0.2l0,0C5.5,2.4,5.6,2.5,5.7,2.5L5.7,2.5L5.7,2.5z"
      />
    </svg>
  );
};

export default CheckList;
/* eslint-enable max-len */
