import Ticket from 'models/Ticket';
import TicketOffer from 'models/TicketOffer';
import { useQuery, UseQueryResult } from 'react-query';
import { TCacheKey } from 'resources/types/cacheTypes';
import { ITicketOfferRes, ITIcketOffersFetchParams } from 'resources/types/TicketOfferTypes';
import {
  ITicketAuditsFetchParams,
  ITicketFetchParams,
  ITicketAudit,
  ITicketRes,
  TTicketAuditRes,
  ITicketChangesRes,
} from 'resources/types/TicketTypes';
import { TicketOfferService, TicketService } from 'services';
import { IQueryData } from './events';

export const useTicketOffers = (key: TCacheKey, params?: ITIcketOffersFetchParams, options = {}) => {
  const queryFn = async () => {
    const data = await TicketOfferService.fetchAll(params);
    return { data: data.ticket_offers, pagination: data.pagination } as IQueryData<ITicketOfferRes>;
  };

  const stateQuery = useQuery(key, queryFn, options);
  return [stateQuery, stateQuery.data?.data.map(tickerOffer => new TicketOffer(tickerOffer))] as [
    UseQueryResult<ITicketOfferRes>,
    TicketOffer[]
  ];
};

export const useTickets = (key: TCacheKey | [TCacheKey, object], params: ITicketFetchParams, options = {}) => {
  const queryFn = async () => {
    const data = await TicketService.fetchAll(params);

    return { data: data.tickets, pagination: data.pagination } as IQueryData<ITicketRes>;
  };

  const stateQuery = useQuery(key, queryFn, options);

  return [stateQuery, (stateQuery.data?.data || []).map(ticket => new Ticket(ticket))] as [
    UseQueryResult<IQueryData<ITicketRes>>,
    Ticket[]
  ];
};

export const useTicketAudits = (
  key: TCacheKey | [TCacheKey, object],
  id: string,
  params: ITicketAuditsFetchParams,
  options: any = {}
) => {
  const queryFn = async () => {
    const data = await TicketService.fetchHistory(id, params);
    return { data: data.audits, pagination: data.pagination } as IQueryData<TTicketAuditRes>;
  };

  const stateQuery = useQuery(key, queryFn, options);

  const mappedAudits = (stateQuery.data?.data || []).map(audit => {
    const mapChanges = (data: ITicketChangesRes, keyIndex: number) => {
      const getChange = (changeKey?: string | string[] | number | number[]) => {
        if (!changeKey) return null;
        return Array.isArray(changeKey) ? changeKey[keyIndex] : changeKey;
      };

      const changes = {
        activeAt: getChange(data.active_at),
        buyerId: getChange(data.buyer_id),
        count: getChange(data.count),
        countLimit: getChange(data.count_limit),
        description: getChange(data.description),
        expireAt: getChange(data.expire_at),
        farmId: getChange(data.farm_id),
        membershipId: getChange(data.membership_id),
        name: getChange(data.name),
        status: getChange(data.status),
      };
      return changes;
    };

    return {
      action: audit.action,
      associated: audit.associated,
      old: mapChanges(audit.changes, 0),
      new: audit.action !== 'create' ? mapChanges(audit.changes, 1) : {},
      comment:
        ((Array.isArray(audit?.changes?.count) &&
          audit?.changes?.count[0] > audit?.changes?.count[1] &&
          !!audit.associated) ||
          !!audit.changes.expire_at ||
          !!audit.changes.active_at) === false
          ? audit.comment
          : null,
      createdAt: audit.created_at,
      id: audit.id,
      model: audit.model,
      moderator: {
        fullName: audit?.moderator?.full_name,
        membershipId: audit?.moderator?.membership_id,
      },
      updatedAt: audit.updated_at,
    };
  });

  return [stateQuery, mappedAudits] as [UseQueryResult<IQueryData<TTicketAuditRes>>, ITicketAudit[]];
};
