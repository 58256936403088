import t from 'resources/translations';
import _ from 'lodash';
import Event from 'models/Event';
import Participation from 'models/Participation';
import MyEventParticipant from 'views/Dashboard/MyEventParticipant';
import { useCallback, useMemo } from 'react';
import { Box, Flex, Text } from 'components/elements';
import { Divider } from 'components/combinations';
import { TBoxProps } from 'resources/types/elementsTypes';

type TMyEventParticipants = TBoxProps & {
  event: Event;
};

const MyEventParticipants = ({ event, ...boxProps }: TMyEventParticipants) => {
  const sortByFirstName = (p: Participation) => p.member.getFirstName();

  const getExtraData = useCallback(() => {
    const sortedRequested = _.sortBy(event.getParticipationsByStatus('invitation_requested'), sortByFirstName);
    const sortedJoined = _.sortBy(event.getParticipationsByStatus('joined'), sortByFirstName);
    const sortedInvited = _.sortBy(event.getParticipationsByStatus('invited'), sortByFirstName);
    const sortedRejected = _.sortBy(event.getParticipationsByStatus('rejected'), sortByFirstName);
    const isNoParticipants =
      !sortedRequested.length && !sortedJoined.length && !sortedInvited.length && !sortedRejected.length;
    return { isNoParticipants, sortedRequested, sortedJoined, sortedInvited, sortedRejected };
  }, [event]);

  const { isNoParticipants, sortedRequested, sortedJoined, sortedInvited, sortedRejected } = useMemo(
    () => getExtraData(),
    [getExtraData]
  );
  let indexListItem = 1;
  return (
    <Box w="full" pl={[0, '2xl']} {...boxProps}>
      <Text px="sm" mt="md" fontWeight="medium">{`${t('labels.participants')}: `}</Text>
      <Divider color="grey-light" margin="sm" />
      <Box w="full">
        {isNoParticipants && (
          <Flex justifyContent="center" py="sm">
            <Text color="grey" fontWeight="medium">{`${t('dashboard.noParticipantsInEvent')}.`}</Text>
          </Flex>
        )}
        {sortedRequested.map(participation => {
          indexListItem += 1;
          return (
            <MyEventParticipant
              key={participation.id}
              event={event}
              participation={participation}
              bgColor="teal-light-10"
            />
          );
        })}
        {sortedJoined.map(participation => {
          indexListItem += 1;
          return (
            <MyEventParticipant
              key={participation.id}
              event={event}
              participation={participation}
              bgColor={indexListItem % 2 === 0 ? 'new-grey-light-15-filled' : 'white'}
            />
          );
        })}
        {sortedInvited.map(participation => {
          indexListItem += 1;
          return (
            <MyEventParticipant
              key={participation.id}
              event={event}
              participation={participation}
              bgColor={indexListItem % 2 === 0 ? 'new-grey-light-15-filled' : 'white'}
            />
          );
        })}
        {sortedRejected.map(participation => {
          indexListItem += 1;
          return (
            <MyEventParticipant
              key={participation.id}
              event={event}
              participation={participation}
              bgColor={indexListItem % 2 === 0 ? 'new-grey-light-15-filled' : 'white'}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default MyEventParticipants;
