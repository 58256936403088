import { Box } from 'components/elements';
import Participation from 'models/Participation';
import { useMutation, UseMutationResult } from 'react-query';
import { TBoxProps } from 'resources/types/elementsTypes';
import { IParticipationRes } from 'resources/types/participationsTypes';
import { ParticipationService } from 'services';

type TParticipationRejectActionProps = TBoxProps & {
  eventId: number;
  participation: Participation;
  onSuccess: (participation: IParticipationRes) => void;
  children: (reject: UseMutationResult<IParticipationRes, unknown, void, unknown>) => JSX.Element;
};

export const ParticipationRejectAction = ({
  eventId,
  participation,
  onSuccess,
  children,
  ...boxProps
}: TParticipationRejectActionProps) => {
  const mutationResult = useMutation(() => ParticipationService.setReject(eventId, participation.id), {
    onSuccess,
  });

  return (
    <Box lineHeight="normal" {...boxProps}>
      {children(mutationResult)}
    </Box>
  );
};

type TParticipationAcceptActionProps = TBoxProps & {
  eventId: number;
  participation: Participation;
  onSuccess: (participation: IParticipationRes) => void;
  children: (accept: UseMutationResult<IParticipationRes, unknown, void, unknown>) => JSX.Element;
};

export const ParticipationAcceptAction = ({
  eventId,
  participation,
  onSuccess,
  children,
  ...boxProps
}: TParticipationAcceptActionProps) => {
  const mutationResult = useMutation(() => ParticipationService.setAccept(eventId, participation.id), {
    onSuccess,
  });

  return (
    <Box lineHeight="normal" {...boxProps}>
      {children(mutationResult)}
    </Box>
  );
};

export const ParticipationTogglePresenceAction = ({
  eventId,
  participation,
  onSuccess,
  children,
  ...boxProps
}: TParticipationAcceptActionProps) => {
  const mutationResult = useMutation(() => ParticipationService.setPresence(eventId, participation), {
    onSuccess,
  });

  return (
    <Box lineHeight="normal" {...boxProps}>
      {children(mutationResult)}
    </Box>
  );
};
