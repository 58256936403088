/* eslint-disable max-len */
import React from 'react';

const Availability = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M10.9,10.9H5.1c-0.1,0-0.2-0.1-0.2-0.2V9.8c0-0.1,0.1-0.2,0.2-0.2h5.8c0.1,0,0.2,0.1,0.2,0.2v0.9C11.1,10.8,11,10.9,10.9,10.9z M14.2,1.2h-0.7V0h-1.2v1.2H3.9V0H2.6v1.2H1.8C0.8,1.2,0,2.1,0,3.1l0,0v11.1c0,1,0.9,1.8,1.8,1.8h12.3c1,0,1.8-0.9,1.8-1.8v-11C16,2.1,15.2,1.2,14.2,1.2L14.2,1.2L14.2,1.2z M1.8,2.5h0.7v1.2h1.2V2.5h8.2v1.2h1.2V2.5h0.9c0.4,0,0.6,0.3,0.6,0.6v1.5H1.2V3.1C1.2,2.8,1.5,2.5,1.8,2.5L1.8,2.5z M14.2,14.8H1.8c-0.4,0-0.6-0.3-0.6-0.6V5.9h13.5v8.2C14.8,14.5,14.5,14.8,14.2,14.8z" />
    </svg>
  );
};

export default Availability;
/* eslint-enable max-len */
