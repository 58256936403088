import Bill from 'models/Bill';
import { getErrorMessage, notify } from 'old/utils';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { IBillRes } from 'resources/types/billsTypes';
import { BillService } from 'services';
import { useKey } from './commonUtils';

/**
 * changes bill due ammount
 * @param {Bill} [bill]
 * @param {function} [onSuccessCb]
 * @param {boolean} [isTicketPayment]
 * @return filters
 */
export const useBillChangeDueAmount = (
  bill?: Bill,
  onSuccessCb: (billRes: IBillRes) => void = () => {},
  isTicketPayment?: boolean
) => {
  const [amount, setAmount] = useState(String(bill?.getDue()) || '0');
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (bill) {
      setAmount(String(bill.getDue()));
    }
  }, [bill]);

  const mutation = useMutation(
    async (billId: number) => {
      const parsedAmount = parseFloat(String(amount).replace(',', '.'));
      return await BillService.update(billId, { bill: { amount: parsedAmount } });
    },
    {
      onSuccess: onSuccessCb,
    }
  );

  const onSubmitDueAmount = async () => {
    try {
      if (bill?.id) {
        await mutation.mutateAsync(bill.id);
        setIsChanged(true);
      }
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };
  const onEnter = useKey(() => onSubmitDueAmount(), 'Enter');

  const onChange = (value: string) => {
    setIsChanged(false);
    setAmount(value);
  };

  const checkIsAmountsSame = useCallback(() => {
    const parsedAmount1 = parseFloat(String(amount).replace(',', '.'));
    const parsedAmount2 = parseFloat(String(bill?.getDue() || 0).replace(',', '.'));
    return parsedAmount1 === parsedAmount2;
  }, [bill, amount]);

  const isSame = checkIsAmountsSame();
  const isSaved = !mutation.isLoading && isSame && isChanged;
  const isReadyToSave = !mutation.isLoading && !isSame;

  const statuses = {
    isSaved,
    isReadyToSave,
    isChanged,
  };

  const inputProps = {
    value: isTicketPayment ? 0 : amount,
    onKeyUp: onEnter,
    onValueChange: onChange,
    onBlur: () => setAmount(prevAmount => String(prevAmount) || '0'),
  };

  return { setAmount, setIsChanged, statuses, mutation, inputProps, onSubmitDueAmount };
};
