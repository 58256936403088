/**
 * @module BoxWhite
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import FlexColumn from '@old/components/common/FlexColumn';

/**
 * Wrapper with white or transparent background and optionally centered content
 * @param {*}       props.children
 * @param {Boolean} [props.centerX]     specifies whether children should be centerted horizontally
 * @param {Boolean} [props.centerY]     specifies whether children should be centerted vertically
 * @param {Boolean} [props.fullHeight]  specifies whether component should have full possible height
 * @param {Boolean} [props.padding]     specifies whether padding should be added
 * @param {Object<string, string|number>} [props.backgroundStyle] custom styles
 */
const BoxWhite = ({ children, centerX, centerY, fullHeight, padding, backgroundStyle }) => {
  const className = cls('relative rounded px-4 md:px-6 bg-white', {
    'h-full': fullHeight,
    flex: centerX || centerY,
    'justify-center': centerX,
    'items-center': centerY,
    'py-4 md:py-6': padding,
  });

  const content = React.Children.count(children) > 1 ? <FlexColumn separated={false}>{children}</FlexColumn> : children;

  return <div className={className} style={backgroundStyle}>{content}</div>;
};

BoxWhite.defaultProps = {
  centerX: false,
  centerY: false,
  fullHeight: false,
  padding: false,
  backgroundStyle: null,
};

BoxWhite.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  centerX: PropTypes.bool,
  centerY: PropTypes.bool,
  fullHeight: PropTypes.bool,
  padding: PropTypes.bool,
  backgroundStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

export default BoxWhite;
