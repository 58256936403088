/* eslint-disable max-len */
import React from 'react';

const WarningDiamond = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M15.7,6.5c-0.2-0.5-0.5-0.9-0.9-1.3l0,0l-4.1-4.1C10.4,0.8,10,0.5,9.5,0.3l0,0C9,0.1,8.5,0,8,0c-1.1,0-2,0.4-2.8,1.1
        L1.1,5.2C0.4,5.9,0,6.9,0,8C0,8.5,0.1,9,0.3,9.5l0,0c0.2,0.5,0.5,0.9,0.8,1.3l4.1,4.1C5.9,15.6,6.9,16,8,16c0.5,0,1-0.1,1.5-0.3l0,0
        c0.5-0.2,0.9-0.5,1.3-0.9l0,0l4.1-4.1C15.6,10.1,16,9.1,16,8C16,7.5,15.9,7,15.7,6.5L15.7,6.5L15.7,6.5z M7.9,13.2
        c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8c0,0,0,0,0,0v0C8.7,12.8,8.3,13.2,7.9,13.2L7.9,13.2
        C7.9,13.2,7.9,13.2,7.9,13.2z M8.7,9.3c0,0.4-0.4,0.7-0.8,0.7c0,0,0,0,0,0H7.8c0,0,0,0,0,0C7.4,10,7.1,9.7,7,9.3v0L6.8,4.1
        c0,0,0,0,0-0.1c0-0.3,0.1-0.6,0.3-0.8l0,0C7.3,3,7.5,2.9,7.9,2.9l0,0c0,0,0,0,0,0c0.3,0,0.6,0.1,0.8,0.3v0C8.9,3.4,9,3.7,9,4
        C9,4,9,4,9,4.1v0L8.7,9.3z"
      />
    </svg>
  );
};

export default WarningDiamond;
/* eslint-enable max-len */
