import { compact } from 'lodash';

import Link from '@old/components/common/Link';
import RatingStars from '@old/components/old/RatingStars';
import t from 'resources/translations';
import {
  useMemberStatus,
  usePendingAcceptButton,
  usePendingRejectButton,
  useUserEditShiftButton,
  useMemberManagementButton,
  useUserEditButton,
  useGuestEditButton,
} from '@old/hooks';
import FlexColumn from '@old/components/common/FlexColumn';
import ImageCircle from '@old/components/guide/ImageCircle';
import Card from '@old/components/common/Card';
import Center from '@old/components/common/Center';
import Subtitle from '@old/components/common/Subtitle';
import MemberLink from '@old/components/custom/MemberLink';
import { ContextTrigger } from '@old/components/common/ContextMenu';
import store from 'store';
import { closeModal } from 'store/actions';
import { useOldLoggedMember } from 'utils/storeUtils';
import { Box, Icons, Stack, Text, Tooltip } from 'components/elements';

const UsersGridItem = ({ member }) => {
  const loggedMember = useOldLoggedMember();
  const status = useMemberStatus(member, loggedMember);
  const closeAfterChangeRole = (_, field) => {
    if (field === 'role') {
      store.dispatch(closeModal('manageMember'));
    }
  };

  const pendingAccept = usePendingAcceptButton(member);
  const pendingDelete = usePendingRejectButton(member);
  const editShift = useUserEditShiftButton(member);
  const memberManagement = useMemberManagementButton(member, status, false, closeAfterChangeRole);
  const editGuest = useGuestEditButton(member);
  const userEdit = useUserEditButton(member, status.isCurrentUser);
  const isMyProfile = loggedMember.id === member.id;
  const isContactDetailsHidden = !loggedMember.isClient() && !member.isClient() && member.privateProfile;
  const memberButtons = [pendingAccept, pendingDelete, editShift, memberManagement, editGuest, userEdit];

  const renderExtraContent = () => {
    if (member.isInstructor()) {
      return (
        <Link to={`/members/${member.id}/rating`}>
          <RatingStars rating={member.rating || 0} />
        </Link>
      );
    }

    if (member.isPending()) {
      return t('general.pending');
    }

    return t(`skills.${member.getSkill()}`).toUpperCase();
  };

  return (
    <ContextTrigger menuItems={compact(memberButtons)}>
      <Card footerContent={renderExtraContent()} fullHeight>
        {isContactDetailsHidden && (
          <Box position="absolute" right={10} top={10}>
            <Tooltip
              whiteSpace="nowrap"
              content={
                isMyProfile
                  ? t('instructorDetails.myContactDetailsHidden')
                  : t('instructorDetails.instructorContactDetailsHidden')
              }
            >
              <Box>
                <Icons.CrossedEyeCircle size="xs" bgColor="teal-dark" color="white" />
              </Box>
            </Tooltip>
          </Box>
        )}
        <MemberLink forceRender member={member}>
          <FlexColumn marginBottom>
            <Center>
              <ImageCircle src={member.getAvatar()} big />
            </Center>
            <Center>
              <Subtitle>{member.getName(40)}</Subtitle>
            </Center>
          </FlexColumn>
        </MemberLink>
        <Center>
          {member.phone && (
            <a href={`tel:${member.phone}`} title={member.phone} className="text-link block mb-1">
              {member.phone}
            </a>
          )}
        </Center>
        <Center>
          {member.email && (
            <a href={`mailto:${member.email}`} title={member.email} className="text-link block truncate mb-2">
              {member.email}
            </a>
          )}
        </Center>
        {member.privateProfile && !member.isClient() && loggedMember.isClient() && (
          <Stack isHorizontal spacing="sm" alignItems="center">
            <Icons.ContactDetails squareSize="md" color="teal-dark" />
            <Text fontSize="sm" color="teal-dark">
              {t('instructorDetails.noContactDetails')}
            </Text>
          </Stack>
        )}
      </Card>
    </ContextTrigger>
  );
};
export default UsersGridItem;
