import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { ResponsiveValue, variant } from 'styled-system';
import { isMobile as isMobileTouchDevice } from 'react-device-detect';
import { baseStyledProps, typographyStyleProps } from 'config/styledProps';
import theme from 'config/theme';
import { IBaseStyledProps, ITypographyStyledProps } from 'resources/types/styledTypes';
export interface ITooltipStyledProps
  extends IBaseStyledProps,
    Omit<ITypographyStyledProps, 'color, backgroundColor, borderRadius'> {
  variant?: ResponsiveValue<'dark'>;
}

const Tooltip = styled(Tippy).withConfig({
  shouldForwardProp: prop => !['px', 'py', 'fontSize', 'lineHeight', 'variant', 'disabled'].includes(prop),
})<ITooltipStyledProps>(
  baseStyledProps,
  typographyStyleProps,
  variant({
    variants: {
      dark: {
        color: 'white',
        backgroundColor: theme.colors['grey-dark'],
        borderRadius: theme.borderRadius.sm,
      },
    },
  })
);

Tooltip.defaultProps = {
  px: 16,
  py: 8,
  fontSize: 'sm',
  lineHeight: 'normal',
  variant: 'dark',
  disabled: isMobileTouchDevice,
};

export default Tooltip;
