import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changeFieldState } from 'store/actions';
import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';

const RadioField = ({ onChangeField, options, label, direction, ...props }) => {
  const { formName, name, value, dataTest } = props;

  const onChange = (chosenValue) => {
    onChangeField({ formName, fieldName: name, fieldState: chosenValue });
  };

  return (
    <FormField {...props}>
      <Input.Radio
        name={name}
        dataTest={dataTest}
        label={label}
        value={value}
        onChange={onChange}
        options={options}
        direction={direction}
      />
    </FormField>
  );
};

RadioField.defaultProps = {
  value: null,
  name: null,
  direction: null,
  label: '',
  dataTest: '',
};

RadioField.propTypes = {
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  options: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  name: PropTypes.string,
  dataTest: PropTypes.string,
  label: PropTypes.string,
  direction: PropTypes.oneOf([
    'col',
    'row',
  ]),
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(RadioField);
