/**
 * @module DragItem
 */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useDrag } from 'react-dnd';

const scrolledElement = document.getElementById('root');
const OFFSET = 100;
const PX_DIFF = 10;
let isScrolling = false;
/**
 * Wrapper that allows items inside to be dragged
 * @param  {Node} props.children
 * @param  {Object} [props.dragState] state, which will be available after dropping the item
 * @param  {String} [props.type] type of the dragged item
 * @param  {Function} [props.isActive] specifies whether you can drag the item
 */
const DragItem = ({ children, dragState, type, isActive }) => {
  const [{ isDragging, canDrag, draggingMonitor }, dragRef] = useDrag({
    type,
    item: {
      type,
      ...dragState,
    },
    canDrag: isActive,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag(),
      draggingMonitor: monitor,
    }),
  });
  const onDragOver = useCallback(() => {
    const goUp = () => {
      window.scrollTo(0, window.scrollY - PX_DIFF);
      if (isScrolling) {
        window.requestAnimationFrame(goUp);
      }
    };

    const goDown = () => {
      window.scrollTo(0, window.scrollY + PX_DIFF);
      if (isScrolling) {
        window.requestAnimationFrame(goDown);
      }
    };

    const clientRect = scrolledElement.getBoundingClientRect();
    const posY = draggingMonitor.getClientOffset() && draggingMonitor.getClientOffset().y;
    const isMouseOnTop = posY > clientRect.top && posY < OFFSET;
    const isMouseOnBottom = posY > (window.innerHeight - OFFSET) && posY <= window.innerHeight;

    if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
      isScrolling = true;
      if (isMouseOnTop) {
        window.requestAnimationFrame(goUp);
      } else {
        window.requestAnimationFrame(goDown);
      }
    } else if (!isMouseOnTop && !isMouseOnBottom) {
      isScrolling = false;
    }
  }, [draggingMonitor]);

  useEffect(() => {
    let interval;
    if (isDragging) {
      interval = setInterval(onDragOver, 50);
    } else {
      isScrolling = false;
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isDragging, onDragOver]);

  return (
    <div
      ref={dragRef}
      className={
        cls('drag-item', {
          'is-dragging is-draggable': isDragging,
          'is-draggable cursor-move': canDrag,
        })
      }
    >
      {children}
    </div>
  );
};

DragItem.defaultProps = {
  dragState: {},
  type: 'dragged-item',
  isActive: () => true,
};

DragItem.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.func,
  type: PropTypes.string,
  dragState: PropTypes.shape({}), // state, which will be available after dropping the item
};

export default DragItem;
