/* eslint-disable max-len */
import React from 'react';

const EventAdd = () => {
  return (
    <svg
      viewBox="0 0 26.69 26.41"
      width="100%"
      className="icon"
    >
      <path d="M2,23V9.6H24v5.9h2V5a3,3,0,0,0-3-3H21.8V.4a.45.45,0,0,0-.4-.4H20.2a.45.45,0,0,0-.4.4V2H6.3V.4A.45.45,0,0,0,5.9,0H4.6a.45.45,0,0,0-.4.4V2H3A3,3,0,0,0,0,5V23a3,3,0,0,0,3,3H16.5V24H3A1,1,0,0,1,2,23ZM2,5.1a1,1,0,0,1,1-1H4.2V5.7a.41.41,0,0,0,.4.4H5.8a.42.42,0,0,0,.4-.4V4.1H19.6V5.7a.41.41,0,0,0,.4.4h1.2a.41.41,0,0,0,.4-.4V4.1H23a1,1,0,0,1,1,1V7.6H2ZM22.45,20V15.74H20.26V20H16v2.19h4.24v4.24h2.19V22.17h4.24V20Z" />
    </svg>
  );
};

export default EventAdd;
/* eslint-enable max-len */
