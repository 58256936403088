/* eslint-disable max-len */
import React from 'react';

const BlockOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 50 50"
    >
      <path d="M27.5,30.3c0,1-0.5,1.7-1.3,2.1V35h-2.4v-2.6c-0.7-0.4-1.1-1.1-1.1-2.1c0-1.3,1.1-2.4,2.4-2.4C26.4,27.9,27.5,29,27.5,30.3z" />
      <path d="M28.8,19.9v3.9h-7.4v-3.9c0-2.1,1.7-3.7,3.7-3.7S28.8,17.9,28.8,19.9z" />
      <path d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0z M35,35.2c0,1.5-1.3,2.8-2.8,2.8H17.8
        c-1.5,0-2.8-1.3-2.8-2.8v-8.8c0-1.3,0.9-2.4,2.2-2.6v-3.9c0-4.3,3.6-7.9,7.9-7.9c4.3,0,7.7,3.6,7.7,7.9v3.9c1.3,0.2,2.2,1.3,2.2,2.6V35.2z"
      />
      <path d="M-38.8,19.5" />
      <path d="M30.7,27.9" />
    </svg>
  );
};

export default BlockOutline;
/* eslint-enable max-len */
