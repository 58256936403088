import AddEventForm from '@old/components/form/AddEvent';
import EditEventForm from '@old/components/form/EditEvent';
import EventTypesForm from '@old/components/form/EventType';
import HorsesForm from '@old/components/form/Horse';
import PlacesForm from '@old/components/form/Place';
import NewsForm from '@old/components/form/News';
import ProposalsForm from '@old/components/form/Proposal';
import Model from '@old/model';
import store from 'store';
import {
  fetchEvent,
  fetchEventType,
  fetchFarm,
  fetchGroup,
  fetchHorse,
  fetchPlace,
  fetchSingleNews,
  fetchUser,
} from 'store/actions';

/**
 * Returns form component for specified model name.
 * @memberof Utils
 * @function
 * @param  {String} name model name used in paths
 * @param  {Boolean} editForm specifies if edit form should be displayed for event form
 * @return {*} form component
 */
export const getFormByModelName = (name, options = {}) => {
  if (!name) return null;

  switch (name) {
    case 'events':
      if (options.editForm) return EditEventForm;
      return AddEventForm;
    case 'event-types':
      return EventTypesForm;
    case 'horses':
      return HorsesForm;
    case 'places':
      return PlacesForm;
    case 'news':
      return NewsForm;
    case 'proposals':
      return ProposalsForm;
    default:
      return null;
  }
};

/**
 * Returns model class for specified model name
 * @memberof Utils
 * @function
 * @param  {String} name model name used in paths
 * @return {*} model class
 */
export const getModelByName = name => {
  if (!name) return null;

  switch (name) {
    case 'users':
      return Model.Members;
    case 'members':
      return Model.Members;
    case 'horses':
      return Model.Horses;
    case 'places':
      return Model.Places;
    case 'event-types':
      return Model.EventTypes;
    case 'news':
      return Model.News;
    case 'events':
      return Model.Events;
    case 'proposals':
      return Model.Proposals;
    case 'farms':
      return Model.Farms;
    case 'groups':
      return Model.MemberGroups;
    case 'bills':
      return Model.Bills;
    default:
      return null;
  }
};

export const fetchByModelName = (id, modelName) => {
  if (!modelName) return null;

  switch (modelName) {
    case 'users':
    case 'members':
      return store.dispatch(fetchUser(id));
    case 'horses':
      return store.dispatch(fetchHorse(id));
    case 'places':
      return store.dispatch(fetchPlace(id));
    case 'event-types':
      return store.dispatch(fetchEventType(id));
    case 'news':
      return store.dispatch(fetchSingleNews(id));
    case 'events':
      return store.dispatch(fetchEvent(id));
    case 'farms':
      return store.dispatch(fetchFarm());
    case 'groups':
      return store.dispatch(fetchGroup(id));
    default:
      return null;
  }
};
