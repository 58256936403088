import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import FlexRow from '@old/components/common/FlexRow';
import FlexColumn from '@old/components/common/FlexColumn';
import Spinner from '@old/components/common/Spinner';
import t from 'resources/translations';
import config from '@old/config';

const HeatMap = ({ heatMapData, heatMapRanges, loading }) => {
  const mapHours = hourKey => <div style={{ height: 15, lineHeight: 1 }} key={hourKey}>{hourKey.split('-')[0]}</div>;

  return (
    <div>
      <FlexRow cols="none/1/1/1/1/1/1/1" separated="small" noShrink>
        <FlexColumn>
          {config.heatMapHours.map(mapHours)}
        </FlexColumn>
        {!loading && Object.keys(heatMapData).map((dayName, dayIndex) => {
          return (
            <FlexColumn key={dayName}>
              {Object.keys(heatMapData[dayName]).map((hourKey) => {
                const { time, value } = heatMapData[dayName][hourKey];
                return <HeatMapItem key={hourKey} time={time} count={value} heatMapRanges={heatMapRanges} />;
              })}
              <div
                className="text-center"
                style={{ borderTop: `1px solid ${config.color.black}` }}
                key={config.weekDays[dayIndex]}
              >
                {t(`week.short.${config.weekDays[dayIndex]}`)}
              </div>
            </FlexColumn>
          );
        })}
        {loading && <Spinner transparent />}
      </FlexRow>
    </div>
  );
};

const heatMapItemShape = (props, propName, componentName) => {
  if (props[propName] && !isEqual(Object.keys(props[propName]), config.heatMapHours)) {
    return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.
    Check /src/components/charts/HeatMap.js`);
  }

  return '';
};

HeatMap.defaultProps = {
  loading: false,
};

HeatMap.propTypes = {
  heatMapData: PropTypes.shape({
    monday: heatMapItemShape,
    tuesday: heatMapItemShape,
    wednesday: heatMapItemShape,
    thursday: heatMapItemShape,
    friday: heatMapItemShape,
    saturday: heatMapItemShape,
    sunday: heatMapItemShape,
  }).isRequired,
  heatMapRanges: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
  loading: PropTypes.bool,
};

export default HeatMap;

const HeatMapItem = ({ time, heatMapRanges }) => {
  const getColorIndex = (index = 0) => {
    if (time >= heatMapRanges[index]) {
      return index;
    } else {
      return getColorIndex(index + 1);
    }
  };
  const colorIndex = time && heatMapRanges.length > 0 && getColorIndex();
  const { heatMapColors } = config;

  return (
    <div
      style={{
        backgroundColor: colorIndex !== null ? heatMapColors[colorIndex] : 'transparent',
        height: 15,
      }}
      className="rounded"
    />
  );
};

HeatMapItem.defaultProps = {
  time: null,
};

HeatMapItem.propTypes = {
  time: PropTypes.number,
  heatMapRanges: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
};
