/**
 * @module BoxSwitcher
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BoxWhite from '@old/components/common/BoxWhite';
import Box from '@old/components/common/Box';

/**
 * Component return Box or BoxWhite depending on devices
 * @param {*} props.children
 * @param {Boolean} props.isMobile
 */
const BoxSwitcher = ({ children, isMobile }) => {
  return isMobile ? <BoxWhite>{children}</BoxWhite> : <Box>{children}</Box>;
};

BoxSwitcher.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(BoxSwitcher);
