/**
 * @module BoxSmall
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
/**
 * Wrapper with grey background
 * @param  {*} props.children
 * @param  {String} [props.className]
 * @param  {Boolean} [props.smallPadding] Adds smaller padding to the box
 */
const BoxSmall = ({ children, className, smallPadding }) => {
  return (
    <div
      className={cls('box-small', className, {
        'px-4': smallPadding,
        'px-6': !smallPadding,
      })}
    >
      {children}
    </div>
  );
};

BoxSmall.defaultProps = {
  className: '',
  smallPadding: false,
};

BoxSmall.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  className: PropTypes.string,
  smallPadding: PropTypes.bool,
};

export default BoxSmall;
