import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { getErrorMessage, notify } from 'old/utils';
import Model from '@old/model';
import Spinner from '@old/components/common/Spinner';
import t from 'resources/translations';
import { refreshCustomData } from 'store/actions';
import Post from '@old/components/view/listItem/Post';
import FlexColumn from '@old/components/common/FlexColumn';
import Button from '@old/components/guide/Button';
import Input from '@old/components/input';
import Icon from '@old/components/icon';
import ImageCircle from '@old/components/guide/ImageCircle';
import InputWithAction from '@old/components/common/InputWithAction';
import BoxWhite from '@old/components/common/BoxWhite';
import { useOldLoggedMember } from 'utils/storeUtils';

const EventPostsList = ({ eventId, refreshPostCounter, refreshPosts }) => {
  const loggedMember = useOldLoggedMember();
  const [posts, setPosts] = useState();
  const [newPost, setNewPost] = useState('');

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const fetchPosts = async () => {
      try {
        const [postsFromAPI] = await Model.Events.fetchPosts(eventId, { per_page: 999, cancelToken: source.token });
        setPosts(postsFromAPI);
      } catch (e) {
        if (!axios.isCancel(e)) {
          notify(getErrorMessage(e), { type: 'error' });
        }
      }
    };
    fetchPosts();
    return () => source.cancel();
  }, [eventId, refreshPostCounter]);

  const addPost = async () => {
    try {
      await Model.Events.createPost(eventId, { content: newPost });
      setNewPost('');
      refreshPosts('posts');
      notify(t('message.addPost'));
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  if (!posts) {
    return (
      <div className="h-64 relative">
        <Spinner />
      </div>
    );
  }

  return (
    <FlexColumn>
      <BoxWhite>
        <InputWithAction>
          <ImageCircle src={loggedMember.getAvatar('thumb')} />
          <Input.Text
            label={t('labels.message')}
            value={newPost}
            onChange={val => setNewPost(val)}
            placeholder={t('placeholders.writePost')}
          />
          <Button onClick={addPost} size="small" async="simple">
            <Icon.Send />
          </Button>
        </InputWithAction>
      </BoxWhite>
      <FlexColumn>
        {posts.map(post => (
          <Post key={post.id} post={post} eventId={eventId} />
        ))}
      </FlexColumn>
    </FlexColumn>
  );
};

EventPostsList.defaultProps = {
  refreshPostCounter: 0,
};

const mapStateToProps = ({ fetchingData }) => ({
  refreshPostCounter: fetchingData.posts,
});

export default connect(mapStateToProps, { refreshPosts: refreshCustomData })(EventPostsList);
