/* eslint-disable max-len */
import React from 'react';

const Dash = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 13 4"
    >
      <path d="M12.5,6H.5a.5.5,0,0,0,0,1h12a.5.5,0,0,0,0-1Z" transform="translate(0 -6)" />
    </svg>
  );
};

export default Dash;
/* eslint-enable max-len */
