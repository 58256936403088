import moment from 'moment';
import { Modal, ModalHeader, ModalCloseButton, ModalContent, ModalButtons } from 'components/combinations';
import { Box, Button, Flex, Icons, Span, Stack, Text } from 'components/elements';
import { getErrorMessage, notify, parseToCurrencyString } from 'old/utils';
import t from 'resources/translations';
import { BillService, TicketOfferService } from 'services';
import { TWrappedModal, TStoreStateModals } from 'views/modals';
import SpinnerDots from 'old/components/guide/SpinnerDots';
import ModalDescription from 'components/combinations/modals/ModalDescription';
import { useState } from 'react';
import { DATE_FORMAT } from 'config/constans';
import TicketOffer from 'models/TicketOffer';
import DateInput from 'components/combinations/forms/DateInput';
import { useShallowSelector } from 'old/hooks';
import { useLoggedMember } from 'utils/storeUtils';

type TPaymentModalProps = {
  ticketOffer: TicketOffer;
};

const PaymentBuyTicket = ({ onSubmit, onClose, isSubmitting, isHidden }: TWrappedModal) => {
  const loggedMember = useLoggedMember();
  const { ticketOffer } = useShallowSelector(
    ({ modal }: { modal: TStoreStateModals }) => modal['PAYMENT_BUY_TICKET'].props
  ) as TPaymentModalProps;
  const [activationDate, setActivationDate] = useState(moment().format(DATE_FORMAT));

  const onRedirectToBM = async () => {
    try {
      const { bill_id } = await TicketOfferService.buyTicket(ticketOffer.id, { activation_date: activationDate });
      if (bill_id) {
        const { redirect_url } = await BillService.addOnlinePayment(bill_id);
        window.location.href = redirect_url;
      }
    } catch (e) {
      notify(getErrorMessage(e, true), { type: 'error' });
    }
  };

  return (
    <Modal isHidden={isHidden} onClose={onClose} isBlocked={isSubmitting} isOpen>
      <ModalHeader>{t('bills.payForTicket')}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting}>
        <Flex px={[0, 'base']} pt={[0, 'base']}>
          <Stack alignItems="center" isHorizontal>
            <Box squareSize={50} padding={3} borderWidth={2} borderColor="teal-dark" borderRadius={9999}>
              <Icons.TicketCircle bgColor="teal-light" fill="white" size="mdWithBorder" />
            </Box>
            <Text fontSize="md" fontWeight="medium">
              {ticketOffer.getName()}
            </Text>
          </Stack>
        </Flex>
        <ModalDescription py="2xl" mb="md">
          <Stack fontSize="base" spacing="sm">
            <Text fontWeight="medium">
              {`${t('paymentTicketModal.periodValidity')}: `}
              <Span fontWeight="regular">
                {ticketOffer.validity} {t('tickets.days', { count: ticketOffer.validity })}
              </Span>
            </Text>
            <Text fontWeight="medium">
              {`${t('paymentTicketModal.numberRides')}: `}
              <Span fontWeight="regular">{ticketOffer.count}</Span>
            </Text>
            <Text fontWeight="medium">
              {`${t('paymentTicketModal.eventTypes')}: `}
              <Span fontWeight="regular">{ticketOffer.eventTypes.map(type => type.getName()).join(', ')}</Span>
            </Text>
          </Stack>
        </ModalDescription>
        <Stack spacing="sm">
          <Text fontWeight="medium">{t('paymentTicketModal.dateActiveTicket')}:</Text>
          <Flex>
            <DateInput
              name="start_after"
              placeholder={t('placeholders.date')}
              value={activationDate}
              onChange={(value: string) => {
                setActivationDate(value);
              }}
              pickerOptions={{ minDate: moment() }}
              pickerPosition="top"
              disabled={!ticketOffer.activable}
            />
          </Flex>
        </Stack>
        <Flex justifyContent="space-between" w="full" flexDirection={['column', 'row']}>
          <Text fontWeight="medium">{t('bills.paymentMethod')}:</Text>
          <Text fontSize="sm">{t('bills.onlinePayment')}</Text>
        </Flex>
        <Flex alignItems="flex-end" pb="sm" justifyContent="space-between">
          <Text fontSize="md" fontWeight="bold">
            {t('bills.toPay')}:
          </Text>
          <Text fontSize="lg" color="orange" fontWeight="bold">
            {parseToCurrencyString(ticketOffer.price)}
          </Text>
        </Flex>
      </ModalContent>
      <ModalButtons>
        <Button onClick={() => onSubmit(onRedirectToBM)}>
          {isSubmitting ? (
            <SpinnerDots label={t('bills.redirectInProgress')} inline />
          ) : loggedMember.isClient() ? (
            t('buttons.buyTicket')
          ) : (
            t('buttons.addPaymentForTicket')
          )}
        </Button>
        <Button onClick={onClose} variant="outline">
          {loggedMember.isClient() ? t('buttons.goBackToOfferList') : t('paymentTicketModal.goBackToTickets')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default PaymentBuyTicket;
