import { DIFFICULTY } from 'config/constans';
import _ from 'lodash';
import { getObjectWithMinValueByKey } from 'old/utils';
import { IImage, TImageSize, TPrivilege, TSkills } from 'resources/types/commonTypes';
import { IHorseFatique, IHorseRes } from 'resources/types/horsesTypes';
import EventType from './EventType';

class Horse {
  id: number;
  name: string;
  range: number;
  eventTypes: EventType[];
  description: string;
  birthday: Date | null;
  breed: string;
  rating: number | null;
  healthStatus: string;
  primaryPictureId: number | null;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  difficulty: TSkills;
  privileges: TPrivilege[];
  fatigue: IHorseFatique | null;
  pictures: IImage[];

  constructor(data: IHorseRes) {
    this.id = data.id;
    this.name = data.name;
    this.range = data.range;
    this.eventTypes = data.event_types.map(type => new EventType(type));
    this.description = data.description;
    this.birthday = data.birthday ? new Date(data.birthday) : null;
    this.breed = data.breed;
    this.rating = data.rating || null;
    this.healthStatus = data.health_status || '';
    this.primaryPictureId = data.primary_picture_id;
    this.deleted = data.deleted;
    this.difficulty = data.difficulty;
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
    this.privileges = data.meta.privileges || [];
    this.fatigue = data.fatigue
      ? {
          daily: data.fatigue.hours_daily,
          tridaily: data.fatigue.hours_tridaily,
          weekly: data.fatigue.hours_weekly,
          total: data.fatigue.fatigue_total,
        }
      : null;
    this.pictures = data.pictures || [];
    this.difficulty = data.difficulty;
  }

  getName(limit: number = 0) {
    return limit ? _.truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getUrl() {
    return `/horses/${this.id}`;
  }

  getSlugInfo() {
    const difficulty = DIFFICULTY.find(d => this.difficulty === d.value) || DIFFICULTY[0];
    const slugInfo = {
      color: difficulty.color,
      text: difficulty.slug,
    };

    return slugInfo;
  }

  getAvatar(size: TImageSize) {
    if (!this.pictures || this.pictures.length === 0) {
      return '/img/horse-avatar-placeholder.png';
    }

    let mainPicture = null;
    if (!this.primaryPictureId) {
      mainPicture = getObjectWithMinValueByKey(this.pictures, 'id') as IImage;
    } else {
      mainPicture = this.pictures.find(picture => picture.id === this.primaryPictureId) as IImage;
    }

    return mainPicture && mainPicture.url
      ? mainPicture.url[size] || mainPicture.url.medium
      : '/img/horse-avatar-placeholder.png';
  }

  getSkill() {
    return this.difficulty || 'missing';
  }

  getHealthStatus() {
    return this.healthStatus;
  }
}

export default Horse;
