import moment from 'moment';
import Model from '@old/model';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_HORSE } from 'old/constants/formNames';
import Option from '@old/components/view/listItem/SelectOption';
import config from '@old/config';
import { isNumberBetween, getChanges, mapFormStateToData, getInitValues } from 'old/utils';
import { useShallowSelector } from 'old/hooks';

const HorsesForm = ({ doc, initValues, ...props }) => {
  const formName = FORM_HORSE(doc ? doc.id : '');
  const minBirthdayDate = moment().subtract(50, 'years');
  const maxBirthdayDate = moment();
  const form = useShallowSelector(({ form }) => form[formName]);
  const initialValues = getInitValues({
    formName: 'horse',
    customInitValues: doc ? Model.Horse.mapToFormData(doc) : initValues,
  });

  const onSubmit = formState => {
    const values = doc
      ? getChanges(formState, mapFormStateToData({ formState: initialValues, initValue: true }))
      : formState;
    return props.onSubmit(Model.Horse.mapToSaveData(values));
  };

  const validations = {
    // number range from 1 to 24. The change also requires the necessary changes to this translation.
    range: [
      {
        condition: value => isNumberBetween({ value, min: 1, max: 24 }) || !value,
        errorHint: t('error.fieldMustHaveNumberBetween', { min: 1, max: 24 }),
      },
    ],
  };

  const horseRange = form?.range?.value || 0;

  return (
    <Form formName={formName} initValues={initialValues}>
      <Field.Text name="horseName" label={t('labels.name')} placeholder={t('placeholders.name')} required />

      <Field.DateValue
        name="birthday"
        label={t('labels.birthDate')}
        placeholder={t('placeholders.date')}
        minDate={minBirthdayDate}
        maxDate={maxBirthdayDate}
        required
      />

      <Field.AsyncMultiSelect
        name="eventTypes"
        label={t('labels.eventType')}
        placeholder={t('placeholders.selectEventType')}
        customOptionComponents={Option.EventType}
        loadOptions={Model.EventTypes.fetchOptions}
        required
      />

      <Field.Numeric
        name="range"
        label={t('labels.range')}
        validation={validations.range}
        placeholder={t('placeholders.minAndMaxRange')}
        required
      />
      {horseRange > 5 && horseRange <= 24 && (
        <ul className="errors-hint" style={{ marginTop: -16, paddingLeft: 12 }}>
          <li className="text-warning">{t('error.horseRangeMax')}</li>
        </ul>
      )}

      <Field.Select
        name="difficulty"
        label={t('labels.difficulty')}
        placeholder={t('placeholders.selectDifficulty')}
        options={config.difficulty.map(skill => ({ key: skill.value, value: skill.value, label: skill.label }))}
        required
      />

      <Field.Text name="breed" label={t('labels.breed')} placeholder={t('placeholders.addBreed')} required />

      <Field.Text
        name="healthStatus"
        label={t('labels.healthStatus')}
        placeholder={t('placeholders.reviewHealthStatus')}
      />

      <Field.Text name="horseDescription" label={t('labels.description')} placeholder={t('placeholders.description')} />

      <FormButton formName={formName} onClick={onSubmit} async>
        {doc ? t('general.saveChanges') : t('model.add.horses')}
      </FormButton>
    </Form>
  );
};

HorsesForm.defaultProps = {
  doc: null,
  initValues: {},
};

export default HorsesForm;
