import React from 'react';
import { connect } from 'react-redux';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import RowAligner from '@old/components/common/RowAligner';
import { setTab } from 'store/actions';
import { getTabsId } from 'old/utils';
/**
 * @module Tabs
 */
/**
 * Object containing tabs informations
 * @typedef   {Object[]} Tabs
 * @property  {String|Node} name tab name
 * @property  {Function|Node} view specifies what should be displayed when the tab is active
 * @property  {Object} props
 * @property  {String|String[]} permissionKey permission to check if logged user can see this tab
 * @property  {Boolean} active specifies wether the tab is active
 */
/**
 * Object containing current url
 * @typedef   {Object} Match
 * @property  {String} url current url
 */
/**
 * Component that displays multiple views with tabs navigation
 * @param  {Tabs} props.tabs
 * @param  {Function} props.setActiveTab function for setting active tab
 * @param  {Number} props.activeTab active tab index
 * @param  {Match} props.match
 */
const Tabs = ({ tabs, setActiveTab, activeTab, match }) => {
  const tabsId = getTabsId(match.url);

  let existingActiveTab = activeTab;

  // when the activeTab doesn't exist in tabs, redirect to the first one available
  if (!tabs[existingActiveTab]) existingActiveTab = 0;

  const ViewComponent = tabs[existingActiveTab].view;
  return (
    <div className="view-pager">
      <MenuTabs tabs={tabs} activeTab={existingActiveTab} onChange={setActiveTab} tabsId={tabsId} />
      <div className={cls('view', { 'mt-10': tabs.length > 1 })}>
        <ViewComponent {...tabs[existingActiveTab].props} />
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]).isRequired,
      view: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.elementType,
      ]).isRequired,
      props: PropTypes.shape({}),
      permissionKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.string,
        ),
      ]),
      active: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};
const mapStateToProps = ({ tabs }, { match }) => {
  return ({
    activeTab: tabs[getTabsId(match.url || '')] || 0,
  });
};

export default withRouter(connect(mapStateToProps, { setActiveTab: setTab })(Tabs));
/**
 * @module MenuTabs
 */
/**
 * Object containing tabs informations
 * @typedef   {Object[]} Tabs
 * @property  {String|Node} name tab name
 * @property  {Function|Node} view specifies what should be displayed when the tab is active
 * @property  {Object} props
 * @property  {String|String[]} permissionKey permission to check if logged user can see this tab
 * @property  {Boolean} active specifies wether the tab is active
 */
/**
 * Component that generates tabs menu
 * @param  {Tabs} props.tabs
 * @param  {Number} props.activeTab index of the active tab
 * @param  {Function} props.onChange function that activates on tab change
 * @param  {String} props.tabsId
 */
const MenuTabs = ({ tabs, activeTab, onChange, tabsId }) => {
  const activeIndex = activeTab || 0;
  const hideTabs = tabs.length < 2;
  if (hideTabs) return null;
  return (
    <div>
      <RowAligner separated={false}>
        {tabs.map((tab, index) => {
          const active = activeIndex === index;
          return (
            <div
              key={`tab-${index}`}
              className={cls('view-pager-tab', { active })}
              onClick={() => onChange(index, tabsId)}
            >
              {tab.name}
            </div>
          );
        })}
      </RowAligner>
      <LineIndicator length={tabs.length} activeIndex={activeIndex} />
    </div>
  );
};

MenuTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]).isRequired,
      view: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.node,
      ]).isRequired,
      props: PropTypes.shape({}),
      permissionKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.string,
        ),
      ]),
      active: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  activeTab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tabsId: PropTypes.string.isRequired,
};
/**
 * @module LineIndicator
 */
/**
 * Component that generates indicator on tabs, that highlights active tab
 * @param  {Number} props.length number of tabs
 * @param  {Number} props.activeIndex current tab index
 */
const LineIndicator = ({ length, activeIndex }) => {
  const widthTab = 100 / length;
  const posLeftOffset = 100 / length * activeIndex;
  return (
    <div className="view-pager-line">
      <div className="tab-indicator" style={{ left: `${posLeftOffset}%`, width: `${widthTab}%` }} />
    </div>
  );
};

LineIndicator.propTypes = {
  length: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
};
