/* eslint-disable max-len */
import React from 'react';

const CalendarRange = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 26 26"
    >
      <path d="M23.23,2.6H21.79V0H18.9V2.6H7.34V0H4.45V2.6H3A2.75,2.75,0,0,0,.13,5.2V23.4A2.76,2.76,0,0,0,3,26H23.23a2.77,2.77,0,0,0,2.89-2.6V5.2A2.77,2.77,0,0,0,23.23,2.6Zm0,20.8H3V9.1H23.23Zm-12.9-5.5h6v2.5l5-4-5-4v2.5h-6V12.4l-5,4,5,4Z" />
    </svg>
  );
};

export default CalendarRange;
