/**
 * @module SelectOptionItem
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import ImageCircleSelection from '@old/components/old/ImageCircleSelection';
import RowAligner from '@old/components/common/RowAligner';
import HorseFatigue from '@old/components/old/HorseFatigue';
import t from 'resources/translations';

const withOption = WrappedComponent => {
  const OptionWrapper = ({ innerProps, isSelected, isDisabled, data }) => (
    <div className="select-option-container" {...innerProps}>
      <div className={cls('select-option', { selected: isSelected, disabled: isDisabled })}>
        <WrappedComponent data={data} isSelected={isSelected} />
      </div>
      <div className="select-option-separator" />
    </div>
  );

  OptionWrapper.defaultProps = {
    isSelected: false,
    isDisabled: false,
  };

  OptionWrapper.propTypes = {
    data: PropTypes.shape({
      image: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      isDeleted: PropTypes.bool,
    }).isRequired,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    innerProps: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  OptionWrapper.displayName = 'OptionWrapper';
  return OptionWrapper;
};

const EventTypeOption = ({ isSelected, data }) => {
  const { image, label, slug, isDeleted } = data;
  return (
    <RowAligner>
      <ImageCircleSelection src={image} selected={isSelected} small />
      <div className="flex-1 words-break">{label}</div>
      {isDeleted && <div className="text-warning">{t('model.delete.eventTypes')}</div>}
      {!isDeleted && <div className="text-fade uppercase">{slug}</div>}
    </RowAligner>
  );
};

EventTypeOption.defaultProps = {
  isSelected: false,
};

EventTypeOption.propTypes = {
  data: PropTypes.shape({
    slug: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isDeleted: PropTypes.bool,
  }).isRequired,
  isSelected: PropTypes.bool,
};

const HorseOption = ({ isSelected, data }) => {
  const { image, label, fatigue, isDeleted } = data;
  return (
    <RowAligner>
      <ImageCircleSelection src={image} selected={isSelected} small />
      <div className="flex-1 words-break">{label}</div>
      {isDeleted && <div className="text-warning">{t('model.delete.horses')}</div>}
      {fatigue && !isDeleted && <HorseFatigue fatigue={fatigue} />}
    </RowAligner>
  );
};

HorseOption.defaultProps = {
  isSelected: false,
};

HorseOption.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isDeleted: PropTypes.bool,
    fatigue: PropTypes.shape({
      fatigue_total: PropTypes.number.isRequired,
      hours_daily: PropTypes.number.isRequired,
      hours_tridaily: PropTypes.number.isRequired,
      hours_weekly: PropTypes.number.isRequired,
    }),
  }).isRequired,
  isSelected: PropTypes.bool,
};

const PlaceOption = ({ isSelected, data }) => {
  const { image, label, slug, isDeleted } = data;
  return (
    <RowAligner>
      <ImageCircleSelection src={image} selected={isSelected} small />
      <div className="flex-1 words-break">{label}</div>
      {isDeleted && <div className="text-warning">{t('model.delete.places')}</div>}
      {!isDeleted && <div className="text-fade uppercase">{slug}</div>}
    </RowAligner>
  );
};

PlaceOption.defaultProps = {
  isSelected: false,
};

PlaceOption.propTypes = {
  data: PropTypes.shape({
    slug: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isDeleted: PropTypes.bool,
  }).isRequired,
  isSelected: PropTypes.bool,
};

const MemberOption = ({ isSelected, data }) => {
  const { image, label, skill, isDeleted } = data;
  return (
    <RowAligner>
      <ImageCircleSelection src={image} selected={isSelected} small />
      <div className="flex-1 words-break">{label}</div>
      {isDeleted && <div className="text-warning">{t('model.delete.users')}</div>}
      {!isDeleted && <div className="text-fade">{t(`skills.${skill}`)}</div>}
    </RowAligner>
  );
};

MemberOption.defaultProps = {
  isSelected: false,
};

MemberOption.propTypes = {
  data: PropTypes.shape({
    skill: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isDeleted: PropTypes.bool,
  }).isRequired,
  isSelected: PropTypes.bool,
};

/**
 * Components displays mixed options (once a member option and once a group option)
 * @param {Boolean} isSelected
 * @param {Option}  data
 */
const MembersAndGroupsOption = ({ isSelected, data }) => {
  const { isGroup, size, label, extendedLabel } = data;

  if (!isGroup) {
    return <MemberOption isSelected={isSelected} data={data} />;
  }

  return (
    <RowAligner>
      <div className={cls('image-circle-selection rounded-full', { selected: isSelected })}>
        <div className="image-circle small group-option-image">G</div>
      </div>
      <div className="flex-1 words-break">{label}</div>
      <div className="text-fade">{extendedLabel || size}</div>
    </RowAligner>
  );
};

MembersAndGroupsOption.defaultProps = {
  isSelected: false,
};

MembersAndGroupsOption.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      isGroup: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      size: PropTypes.number,
      extendedLabel: PropTypes.string,
    }),
    PropTypes.shape({
      skill: PropTypes.string,
      image: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ]).isRequired,
  isSelected: PropTypes.bool,
};

const DefaultOption = ({ data }) => {
  const { label } = data;
  return (
    <RowAligner>
      <div className="flex-1 words-break">{label}</div>
    </RowAligner>
  );
};

DefaultOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }).isRequired,
};

const DifficultyOption = ({ data }) => {
  const { label, slug, color } = data;

  return (
    <RowAligner>
      <div className="w-2 h-5" style={{ backgroundColor: color }} />
      <div className="flex-1 words-break">{label}</div>
      <div className="text-fade">{slug}</div>
    </RowAligner>
  );
};

DifficultyOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    color: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
};

const ParticipantPaymentOption = ({ data }) => {
  const { label, paidAmount, dueAmount } = data;
  const renderPrice = (amountDue, amountPaid) => {
    const dueAmountWithDot = amountDue.replace(/,/g, '.');
    if (parseFloat(dueAmountWithDot) === amountPaid) {
      return <div>0.00</div>;
    }
    return parseFloat(dueAmountWithDot) > amountPaid ? (
      <div className="text-red">{parseFloat(dueAmountWithDot - amountPaid).toFixed(2)}</div>
    ) : (
      <div className="text-green-light">{parseFloat(amountPaid - dueAmount).toFixed(2)}</div>
    );
  };
  return (
    <RowAligner>
      {label} ({renderPrice(dueAmount, paidAmount)})
    </RowAligner>
  );
};

ParticipantPaymentOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    paidAmount: PropTypes.string,
    dueAmount: PropTypes.string,
  }).isRequired,
};

const Option = {
  EventType: withOption(EventTypeOption),
  Horse: withOption(HorseOption),
  Place: withOption(PlaceOption),
  Member: withOption(MemberOption),
  MembersAndGroups: withOption(MembersAndGroupsOption),
  Default: withOption(DefaultOption),
  Difficulty: withOption(DifficultyOption),
  ParticipantPayment: withOption(ParticipantPaymentOption),
};

export default Option;
