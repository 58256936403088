/**
 * @module SwitchInput
 */

import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import RowAligner from '../common/RowAligner';

/**
 * Component that return Switch input
 * @param  {Function} props.onChange
 * @param  {String|Boolean|Numer} [props.value]
 * @param  {String} [props.name]
 */
const SwitchInput = ({ onChange, value, name, onLabel, offLabel }) => {
  const onClick = () => {
    onChange(!value, name);
  };

  const isChecked = value || false;

  return (
    <RowAligner>
      <>
        <input
          checked={isChecked}
          onChange={onClick}
          type="checkbox"
          id={name}
          className="switch-checkbox"
        />
        <label
          className={cls('switch-label', { active: isChecked })}
          htmlFor={name}
        >
          <span className="switch-button" />
        </label>
      </>
      <span className="text-md">
        {isChecked ? onLabel : offLabel}
      </span>
    </RowAligner>
  );
};

SwitchInput.defaultProps = {
  value: null,
  name: null,
  onLabel: '',
  offLabel: '',
};

SwitchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
};

export default SwitchInput;
