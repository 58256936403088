import { withRouter } from 'react-router';

import Box from '@old/components/common/Box';
import ImageCircle from '@old/components/guide/ImageCircle';
import { useEventTypeEditButton, useEventTypeDeleteButton } from 'old/buttonHooks';
import RowAligner from '@old/components/common/RowAligner';
import Subtitle from '@old/components/common/Subtitle';
import { ContextTrigger } from '@old/components/common/ContextMenu';
import Link from '@old/components/common/Link';
import ColorBar from '@old/components/common/ColorBar';
import FlexRow from '@old/components/common/FlexRow';

const EventTypesListItem = ({ eventType }) => {
  const editEventType = useEventTypeEditButton(eventType);
  const deleteEventType = useEventTypeDeleteButton(eventType);
  const eventTypeButtons = [editEventType, deleteEventType];

  return (
    <ContextTrigger menuItems={eventTypeButtons}>
      <FlexRow cols="none/1" stretched separated={false} noShrink>
        <ColorBar className="w-2 rounded-none" color={eventType.getColor()} vertical />
        <Box>
          <RowAligner>
            <Link to={`/event-types/${eventType.id}`}>
              <ImageCircle src={eventType.getImage('thumb')} />
            </Link>
            <div>
              <Link to={`/event-types/${eventType.id}`}>
                <Subtitle>{eventType.getName(40)}</Subtitle>
              </Link>
              <div className="leading-tight uppercase">{eventType.getSlug()}</div>
            </div>
          </RowAligner>
        </Box>
      </FlexRow>
    </ContextTrigger>
  );
};

export default withRouter(EventTypesListItem);
