import { DATE_TIME_FORMAT_WITH_COMMA } from 'config/constans';
import { ITransactionRes, TCurrency, TTransactionStatus } from 'resources/types/billsTypes';
import moment from 'moment';

class Transaction {
  id: number;
  redirectUrl: string;
  amount: number;
  currency: TCurrency;
  orderId: string;
  remoteId: string;
  status: TTransactionStatus;
  paidAt: Date | null;
  expireAt: Date;
  updatedAt: Date;
  createdAt: Date;
  discardedAt: Date | null;
  farmId: number;

  constructor(data: ITransactionRes) {
    this.id = data.id;
    this.redirectUrl = data.redirect_url;
    this.amount = parseFloat(data.amount);
    this.currency = data.currency;
    this.orderId = data.order_id;
    this.remoteId = data.remote_id;
    this.status = data.status;
    this.farmId = data.farm_id;
    this.paidAt = data.paid_at ? new Date(data.paid_at) : null;
    this.discardedAt = data.discarded_at ? new Date(data.discarded_at) : null;
    this.expireAt = new Date(data.expire_at);
    this.updatedAt = new Date(data.updated_at);
    this.createdAt = new Date(data.created_at);
  }

  getRedirect() {
    return this.redirectUrl || '';
  }

  getAmount() {
    return this.amount || 0;
  }

  getOrderId() {
    return this.orderId || null;
  }

  getRemoteId() {
    return this.remoteId || null;
  }

  getStatus() {
    return this.status;
  }

  isSuccess() {
    return this.status === 'success';
  }

  isExpired() {
    return moment(this.expireAt).isBefore(moment());
  }

  getUpdatedDate(format = DATE_TIME_FORMAT_WITH_COMMA) {
    return moment(this.updatedAt).format(format);
  }

  getCreatedDate(format = DATE_TIME_FORMAT_WITH_COMMA) {
    return moment(this.createdAt).format(format);
  }
}

export default Transaction;
