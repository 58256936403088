import { TReactNode } from 'resources/types/commonTypes';
import { Box, Flex, Icons, Span, Stack, Text } from 'components/elements';
import t from 'resources/translations';
import { TFlexProps } from 'resources/types/elementsTypes';
import { useLoggedMember } from 'utils';

const containerStyles = {
  flexDirection: 'column',
  bgColor: 'white',
  boxShadow: '0 2px 12px 0 rgba(37, 51, 66, 0.15)',
  radius: 8,
  pb: 'md',
} as TFlexProps;

type TTicketsList = TFlexProps & {
  children: TReactNode;
};

const TicketsUsers = ({ children, ...flexProps }: TTicketsList) => {
  const loggedMember = useLoggedMember();

  return (
    <Flex {...containerStyles} {...flexProps}>
      <Flex flexDirection="row" px="xl" py="md" justifyContent="space-between">
        {loggedMember?.isClient() ? (
          <Stack alignItems="center" isHorizontal>
            <Icons.MyTicketsCircle bgColor="grey-lighter-40" />
            <Text fontWeight="medium">{t('tickets.myTickets')}</Text>
          </Stack>
        ) : (
          <Stack alignItems="center" isHorizontal>
            <Icons.UsersCircle bgColor="grey-lighter-40" />
            <Text fontWeight="medium">{t('tickets.userTickets')}</Text>
          </Stack>
        )}
      </Flex>
      <Box>
        <Stack mx={['sm', 'md']} backgroundColor="grey-lighter-50" p={['sm', 'md']}>
          <Text>
            <Span fontWeight="medium">{t('tickets.payingForEventsWithATicket')} </Span>
            {` ${t('tickets.isOnlyPossibleForEvents').toLowerCase()} `}
            <Span fontWeight="medium">{t('tickets.validityPeriodOfSubscription').toLowerCase()}.</Span>
          </Text>
          <Stack spacing="sm" alignItems="center" isHorizontal>
            <Icons.InfoCircle
              border={{ borderWidth: 2, borderColor: 'teal-dark' }}
              bgColor="transparent"
              color="teal-dark"
              size="sm"
            />
            <Text color="teal-dark">{t('tickets.youCanRemoveUnapidTickets')}.</Text>
          </Stack>
        </Stack>
        {children}
      </Box>
    </Flex>
  );
};

export default TicketsUsers;
