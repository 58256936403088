import React from 'react';
import PropTypes from 'prop-types';

import InputWrapper from '@old/components/common/InputWrapper';
import InputWrapperNew from '@old/components/common/InputWrapperNew';

const TextInput = ({ value, name, onChange, placeholder, label, required, icon, newWrapper, ...props }) => {
  const onInputChange = (e) => {
    onChange(e.target.value, name);
  };

  const WrapperComponent = newWrapper ? InputWrapperNew : InputWrapper;

  return (
    <WrapperComponent label={label} icon={icon} required={required}>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onInputChange}
        placeholder={placeholder}
        {...props}
      />
    </WrapperComponent>
  );
};

TextInput.defaultProps = {
  label: '',
  value: '',
  name: '',
  placeholder: '',
  required: false,
  icon: null,
  newWrapper: false,
};

TextInput.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.node,
  newWrapper: PropTypes.bool,
};

export default TextInput;
