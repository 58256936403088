/**
 * @module ImageCircle
 */
import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
/**
 * Component that generates image in circular format width default size of 50px
 * @param  {String} props.src route to image
 * @param  {Boolean} [props.small] specifies size of the image circle - 26px
 * @param  {Boolean} [props.big] specifies size of the image circle - 120px
 * @param  {Boolean} [props.large] specifies size of the image circle - 190px
 */
const ImageCircle = ({ src, small, big, large }) => {
  return (
    <div
      className={cls('image-circle', { small, big, large })}
      style={{
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: 9999,
      }}
    />
  );
};

ImageCircle.defaultProps = {
  large: false,
  small: false,
  big: false,
};

ImageCircle.propTypes = {
  src: PropTypes.string.isRequired,
  big: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
};

export default ImageCircle;
