/* eslint-disable max-len */
import React from 'react';

const CoinCircle = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,0L8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7.4,6.8C7.6,7,7.9,7.1,8.3,7.3c0.4,0.2,0.8,0.3,1,0.5
        C9.7,8,9.8,8.1,10,8.3c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.2,0.1,0.5,0.1,0.9c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.7v1H7.6v-1
        c-0.7-0.1-1.3-0.3-1.7-0.8c-0.4-0.4-0.6-1-0.6-1.7h1.5c0,0.4,0.1,0.7,0.3,1s0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.7-0.2S9,10.2,9,9.8
        c0-0.3-0.1-0.5-0.2-0.7C8.6,9,8.3,8.9,7.9,8.7C7.6,8.5,7.2,8.4,7,8.2C6.7,8.1,6.5,7.9,6.2,7.8C6,7.6,5.8,7.3,5.7,7.1
        C5.6,6.8,5.5,6.5,5.5,6.2c0-0.6,0.2-1.1,0.6-1.5C6.5,4.3,7,4.1,7.6,4V2.9h0.9v1.2C9.1,4.2,9.6,4.4,10,4.9c0.4,0.4,0.6,1,0.6,1.7H9
        c0-0.4-0.1-0.7-0.2-1C8.6,5.4,8.3,5.3,8,5.3S7.5,5.4,7.3,5.5C7.1,5.7,7.1,5.9,7.1,6.2C7.2,6.5,7.3,6.7,7.4,6.8L7.4,6.8z"
      />
    </svg>
  );
};

export default CoinCircle;
/* eslint-enable max-len */
