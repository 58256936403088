import styled from 'styled-components';
import { flexbox } from 'styled-system';
import { blockStyleProps, typographyStyleProps, baseStyledProps } from 'config/styledProps';
import {
  IBaseStyledProps,
  ITypographyStyledProps,
  IBlockStyledProps,
  IFlexStyledProps,
} from 'resources/types/styledTypes';

type TListItemProps = IBaseStyledProps & ITypographyStyledProps & IBlockStyledProps & IFlexStyledProps;

const ListItem = styled('li')<TListItemProps>(baseStyledProps, typographyStyleProps, blockStyleProps, flexbox);

export default ListItem;
