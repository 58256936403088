import { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { withRouter } from 'react-router';

import t from 'resources/translations';
import Box from '@old/components/common/Box';
import FloatingButton from '@old/components/custom/FloatingButton';
import Message from '@old/components/common/Message';
import FlexColumn from '@old/components/common/FlexColumn';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import FlexRow from '@old/components/common/FlexRow';
import RowAligner from '@old/components/common/RowAligner';
import Spinner from '@old/components/common/Spinner';
import Error404 from '@old/components/error/Error404';
import { useAbsencesAddButton, useAbsencesDeleteButton, useAbsencesEditButton } from 'old/buttonHooks';
import { setPagination, fetchAbsences, fetchHorse } from 'store/actions';
import { getPaginationId } from 'old/utils';
import PagePagination from '@old/components/old/PagePagination';
import config from '@old/config';
import TextValue from '@old/components/common/TextValue';

const Absences = ({ match, loading, error, horse, absences, absencesPagination, pagination, history, ...actions }) => {
  const { minItemsPerPage } = config;
  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchHorse(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-line
    if (isEmpty(pagination) || pagination.current !== undefined) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const fetch = async () => {
        await actions.fetchAbsences(
          parseInt(match.params.id, 10),
          {
            page: getActivePage() || 1,
            per_page: minItemsPerPage,
          },
          source.token
        );
      };
      fetch();
      return () => source.cancel();
    }
  }, [history.location.state, match.params.id, pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (absencesPagination) {
      const pag = { ...absencesPagination };
      // When you delete last element on page, go back to previous one
      const lastPage = Math.ceil(pag.count / pag.per_page);
      if (pag.per_page * pag.current > pag.count) {
        if (lastPage && pag.current > lastPage) {
          pag.current = lastPage;
          history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
        }
        if (pag.current === 0) {
          pag.current = 1;
        }
      }
      actions.setPagination(pag, getPaginationKey());
    }
  }, [absencesPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  const absencesEdit = useAbsencesEditButton(match.params.id);
  const absencesAdd = useAbsencesAddButton(match.params.id);
  const absencesDelete = useAbsencesDeleteButton(match.params.id);
  const notDeletedHorse = horse && !horse.isDeleted();

  if (error) return <Error404 />;
  if (loading && (!horse || !absences.length)) return <Spinner />;
  if (!horse) return null;

  const formatDate = date => moment(date).format(config.dateTimeFormatWithComma);

  return (
    <div>
      {!absences.length && <Message info>{t('message.noAbsences')}</Message>}
      <FlexColumn>
        {absences.map(absence => {
          return (
            <Box key={absence.id}>
              <FlexRow cols="1/none" noShrink>
                <FlexColumn separated="small">
                  <TextValue
                    text={t('absences.absence')}
                    value={t('absences.fromTo', {
                      from: formatDate(absence.beginning),
                      to: formatDate(absence.ending),
                    })}
                  />
                  <TextValue
                    text={t('absences.reasonAbsence')}
                    value={absence.reason || t('absences.lackOfInformation')}
                  />
                </FlexColumn>
                <RowAligner>
                  {absencesEdit && notDeletedHorse && (
                    <ButtonSimple onClick={() => absencesEdit.onClick(absence)}>
                      <div className="teal-hover">{absencesEdit.icon}</div>
                    </ButtonSimple>
                  )}
                  {absencesDelete && notDeletedHorse && (
                    <ButtonSimple onClick={() => absencesDelete.onClick(absence.id)}>
                      <div className="teal-hover">{absencesDelete.icon}</div>
                    </ButtonSimple>
                  )}
                </RowAligner>
              </FlexRow>
            </Box>
          );
        })}
        <PagePagination paginationKey={getPaginationKey()} />
      </FlexColumn>
      <FloatingButton buttons={[notDeletedHorse && absencesAdd]} />
    </div>
  );
};

Absences.defaultProps = {
  horse: null,
  absences: [],
  absencesPagination: {},
};

const mapStateToProps = (
  { pagination, fetchingData: { horses, absences, absencesPagination, loading, error } },
  { match }
) => {
  const paginationKey = getPaginationId(`${window.location.pathname}`);
  return {
    horse: horses.find(horse => String(horse.id) === String(match.params.id)),
    absences,
    absencesPagination,
    loading: loading.horses || loading.absences,
    error: error.horses || error.absences,
    pagination: pagination[paginationKey] || {},
  };
};
export default withRouter(connect(mapStateToProps, { fetchHorse, fetchAbsences, setPagination })(Absences));
