import { Icons } from 'components/elements';
import { components } from 'react-select';

// MultiValue container used in select inputs
const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <Icons.Close squareSize={14} />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
