import { TPrivilege } from 'resources/types/commonTypes';
import { ITicketOfferRes } from 'resources/types/TicketOfferTypes';
import EventType from './EventType';
import _ from 'lodash';
class TicketOffer {
  id: number;
  count: number;
  price: number;
  name: string;
  description: string;
  activable: boolean;
  visibility: boolean;
  validity: number;
  createdAt: Date;
  updatedAt: Date;
  eventTypes: EventType[];
  privileges: TPrivilege[];

  constructor(data: ITicketOfferRes) {
    this.id = data.id;
    this.count = data.count;
    this.price = data.price;
    this.name = data.name;
    this.description = data.description;
    this.activable = data.activable;
    this.visibility = data.visibility;
    this.validity = data.validity;
    this.createdAt = new Date(data.created_at);
    this.updatedAt = new Date(data.updated_at);
    this.eventTypes = data.event_types.map(type => new EventType(type));
    this.privileges = data.meta.privileges;
  }
  getName(limit: number = 0) {
    return limit ? _.truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }
}

export default TicketOffer;
