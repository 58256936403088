import config from '@old/config';
import { flatten } from 'lodash';
import BaseModel from '@old/model/BaseModel';
import { Member } from '@old/model/Member';
import moment from 'moment';

class Transaction {
  constructor(data) {
    this.id = data.id;
    this.redirectUrl = data.redirect_url;
    this.amount = data.amount;
    this.currency = data.currency;
    this.orderId = data.order_id;
    this.remoteId = data.remote_id;
    this.status = data.status;
    this.paidAt = data.paid_at;
    this.expireAt = data.expire_at;
    this.updatedAt = data.updated_at;
    this.createdAt = data.created_at;
    this.discardedAt = data.discarded_at;
  }

  getAmount() {
    return this.amount || 0;
  }

  getOrderId() {
    return this.orderId || null;
  }

  getRemoteId() {
    return this.remoteId || null;
  }

  getRedirect() {
    return this.redirectUrl || '';
  }

  getCreatedDate(format = config.dateTimeFormatWithComma) {
    return moment(this.createdAt).format(format);
  }

  getUpdatedDate(format = config.dateTimeFormatWithComma) {
    return moment(this.updatedAt).format(format);
  }

  getStatus() {
    return this.status;
  }

  isSuccess() {
    return this.status === 'success';
  }

  isPending() {
    return this.status === 'pending';
  }

  isFailure() {
    return this.status === 'failure';
  }

  isExpired() {
    return moment(this.expireAt).isBefore(moment());
  }

  isDiscarded() {
    return !!this.discardedAt;
  }
}

class Payment {
  constructor(data) {
    this.id = data.id;
    this.currency = data.currency;
    this.paymentType = data.payment_type;
    this.status = data.status;
    this.amount = data.amount;
    this.payer = data.payer ? new Member(data.payer) : null;
    this.refunder = data.refunder ? new Member(data.refunder) : null;
    this.transactions = (data.transactions || []).map(t => new Transaction(t));
    this.updatedAt = data.updated_at;
    this.createdAt = data.created_at;
  }

  getCreatedDate(format = config.dateTimeFormatWithComma) {
    return moment(this.createdAt).format(format);
  }

  getUpdatedDate(format = config.dateTimeFormatWithComma) {
    return moment(this.updatedAt).format(format);
  }

  getCurrency() {
    return this.currency;
  }

  getPaymentType() {
    return config.paymentStatus.find(payment => payment.value === this.paymentType) || {};
  }

  getStatus() {
    return this.status || 'none';
  }

  getAmount() {
    return this.amount || 0;
  }

  getPayer() {
    return this.payer;
  }

  getRefunder() {
    return this.refunder;
  }

  getTransactions() {
    const transactions = this.transactions.sort((t, tn) => new Date(tn.updated_at) - new Date(t.updated_at));
    return transactions;
  }

  getLatestTransaction() {
    const latestTransaction = this.transactions.sort((t, tn) => new Date(tn.createdAt) - new Date(t.createdAt));
    return latestTransaction[0];
  }
}

class Bills extends BaseModel {
  constructor(options) {
    super({
      dataKey: 'bill',
      modelName: 'bills',
      updateKey: 'bill',
      basePath: '/api/v1/farms/{farmId}/bills',
      ItemClass: Bill,
      ...options,
    });
  }

  fetchAll = async ({ cancelToken, ...params }) => {
    const response = await this.client.get(this.basePath, { cancelToken, params });

    return [response.data.bills.map(item => this.parse(item)), response.data.pagination];
  };

  returnPayment = async (billId, paymentId) => {
    const returnPaymentPath = `${this.basePath}/${billId}/payments/${paymentId}/refund`;
    const response = await this.client.post(returnPaymentPath);
    return response.data;
  };

  cancelPayment = async (billId, paymentId) => {
    const cancelPaymentPath = `${this.basePath}/${billId}/payments/${paymentId}`;
    const response = await this.client.delete(cancelPaymentPath);
    return response.data;
  };

  onlinePayment = async billId => {
    const response = await this.client.post(`${this.basePath}/${billId}/payments`);
    return response.data;
  };

  fetchSummary = async params => {
    const response = await this.client.get(`${this.basePath}/summary`, { params });
    const summary = {
      dueAmount: response.data.due_amount,
      paidAmount: response.data.paid_amount,
      outstandingAmount: response.data.outstanding_amount,
      overpaidAmount: response.data.overpaid_amount,
    };

    return summary;
  };

  paymentStatus = async params => {
    const response = await this.client.get('/api/v1/payments/status', { params });
    return response.data;
  };
}

class Bill {
  constructor(data) {
    this.dueAmount = data.due_amount;
    this.paidAmount = data.paid_amount;
    this.billableId = data.billable_id;
    this.billableType = data.billable_type;
    this.createdAt = data.created_at;
    this.currency = data.currency;
    this.eventId = data.event_id;
    this.id = data.id;
    this.paidAt = data.paid_at;
    this.payments = (data.payments || []).map(p => new Payment(p));
    this.status = data.payment_status;
    this.billed = new Member(data.billed);
    this.eventName = data.event_name;
    this.membershipId = data.membership_id;
    this.instructors = data.instructors;
    this.eventStartAt = data.event_start_at;
    this.paymentType = data.payment_type;
  }

  static mapToFetchParams = data => {
    const filters = {
      start_after: data.start,
      end_before: data.end,
      status: (data.statuses || []).map(status => status.value),
      payment_type: (data.paymentType || []).map(paymentType => paymentType.value),
      place_id: (data.places || []).map(place => place.value),
      horse_id: (data.horses || []).map(horse => horse.value),
      user_id: (data.participants || []).map(participant => participant.value),
      instructor_id: (data.instructors || []).map(instructor => instructor.value),
    };

    Object.keys(filters).forEach(fieldName => {
      if (filters[fieldName] === undefined) delete filters[fieldName];
    });

    return filters;
  };

  getInstructors() {
    return this.instructors || [];
  }

  getDue() {
    return parseFloat(this.dueAmount);
  }

  getPaidAmount() {
    return parseFloat(this.paidAmount);
  }

  isBooked() {
    return this.status === 'booked';
  }

  isPaid() {
    return this.status === 'paid';
  }

  isUnpaid() {
    return this.status === 'unpaid';
  }

  isSettled() {
    return this.status === 'settled';
  }

  isOverpaid() {
    return this.status === 'overpaid';
  }

  getBookedPayments() {
    return this.payments.filter(p => p.status === 'booked');
  }

  getPaidAt() {
    return this.paidAt || '';
  }

  getPaymentType() {
    return this.paymentType || '';
  }

  getAllTransactions() {
    return flatten(this.payments.map(p => p.transactions));
  }

  getCurrentTransaction() {
    const transactions = this.getAllTransactions();
    const failureTransaction = transactions.find(t => t.isFailure() && !t.isExpired());
    if (failureTransaction) {
      return failureTransaction;
    }
    const pendingTransaction = transactions.find(t => t.isPending() && !t.isExpired());
    if (pendingTransaction) {
      return pendingTransaction;
    }
    return null;
  }
}

export { Bill, Bills };
