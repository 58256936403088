import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BoxWhite from '@old/components/common/BoxWhite';
import Subtitle from '@old/components/common/Subtitle';

const TitleList = ({ children, isMobile }) => {
  if (isMobile) {
    return <BoxWhite><Subtitle>{children}</Subtitle></BoxWhite>;
  }
  return <Subtitle>{children}</Subtitle>;
};

TitleList.defaultProps = {
  children: null,
};

TitleList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export default connect(mapStateToProps)(TitleList);
