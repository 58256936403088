/**
 * @module Form
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import FlexColumn from '@old/components/common/FlexColumn';
import { setInitValues, clearForm } from 'store/actions';
import { useHistory } from 'react-router';
import { useActions } from '@old/hooks';

/**
 * @typedef   {Object} HistoryAction
 * @property  {String} action specifies what history action was performed("POP", "PUSH" etc.)
 */
/**
 * Parent wrapper for forms
 * @param  {String} props.formName is the unique name of the form
 * @param  {Object} [props.initValues] is an object containing the initial state of the form {name: value}
 * @param  {Boolean} [props.refreshAlways] refreshes the form status each time the parent component is rerender
 * @param  {*} props.children
 * @param  {HistoryAction} props.history
 * @param  {Boolean} [props.noPadding] specifies whether there shouldn't be padding
 */

const Form = ({ formName, initValues, refreshAlways, children, noPadding, dependentFields }) => {
  const [formIsReady, setFormIsReady] = useState(false);
  const history = useHistory();
  const [setInitFormValues, clearFormByName] = useActions([setInitValues, clearForm]);

  useEffect(() => {
    const values = [];
    const dependedArray = [];
    if (history.action === 'PUSH' || refreshAlways) {
      clearFormByName(formName);
    }

    Object.keys(initValues).forEach(valueKey => {
      values[valueKey] = initValues[valueKey];
      dependedArray[valueKey] = dependentFields[valueKey];
      return true;
    });
    setInitFormValues({ formName, initValues: values, dependentFields: dependedArray });
    setFormIsReady(true);
  }, [formName, setInitFormValues]); // eslint-disable-line

  const onFormSubmit = e => {
    e.preventDefault();
  };

  return (
    <form className={cls('hm-form w-full flex', { 'no-padding': noPadding })} onSubmit={onFormSubmit}>
      <FlexColumn>
        {formIsReady && React.Children.map(children, child => child && React.cloneElement(child, { formName }))}
      </FlexColumn>
    </form>
  );
};

Form.defaultProps = {
  initValues: {},
  dependentFields: {},
  refreshAlways: false,
  noPadding: false,
};

Form.propTypes = {
  formName: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  initValues: PropTypes.shape({}),
  refreshAlways: PropTypes.bool,
  noPadding: PropTypes.bool,
  dependentFields: PropTypes.shape({}),
};

export default Form;
