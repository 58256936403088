import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changeFieldState } from 'store/actions';
import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';

const SwitchField = ({ onChangeField, ...props }) => {
  const { formName, name, value, onLabel, offLabel } = props;

  const onChange = (isChecked) => {
    onChangeField({ formName, fieldName: name, fieldState: isChecked });
  };

  return (
    <FormField {...props}>
      <Input.Switch onChange={onChange} name={name} value={value} onLabel={onLabel} offLabel={offLabel} />
    </FormField>
  );
};

SwitchField.defaultProps = {
  value: null,
  name: null,
  onLabel: '',
  offLabel: '',
};

SwitchField.propTypes = {
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  value: PropTypes.bool,
  name: PropTypes.string,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
};

const mapStateToProps = ({ form }, props) => {
  return {
    value: form[props.formName][props.name].value,
  };
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(SwitchField);
