import Checkbox from './Checkbox';
import DateValue from './Date';
import Email from './Email';
import Numeric from './Number';
import NumberRange from './NumberRange';
import Password from './Password';
import Phone from './Phone';
import Radio from './Radio';
import Tag from './Tag';
import Text from './Text';
import GoogleMap from './Map';
import DoubleTime from './DoubleTime';
import AsyncSelect from './AsyncSelect';
import AsyncMultiSelect from './AsyncMultiSelect';
import Select from './Select';
import Time from './Time';
import Textarea from './TextArea';
import Rating from './Rating';
import ImageUploader from './ImageUploader';
import Tel from './Tel';
import TextEditor from './TextEditor';
import AddAvatar from './AddAvatar';
import AddAvatarUser from './AddAvatarUser';
import MultiSelect from './MultiSelect';
import Switch from './Switch';

export { Checkbox };
export { DateValue };
export { Email };
export { Numeric };
export { NumberRange };
export { Password };
export { Phone };
export { Radio };
export { Tag };
export { Text };
export { GoogleMap };
export { DoubleTime };
export { AsyncSelect };
export { Select };
export { Time };
export { Tel };
export { Textarea };
export { Rating };
export { AsyncMultiSelect };
export { ImageUploader };
export { TextEditor };
export { AddAvatar };
export { AddAvatarUser };
export { MultiSelect };
export { Switch };

const Inputs = {
  Checkbox,
  DateValue,
  Email,
  Numeric,
  NumberRange,
  Password,
  Phone,
  Radio,
  Tag,
  Text,
  GoogleMap,
  DoubleTime,
  AsyncMultiSelect,
  AsyncSelect,
  Select,
  Time,
  Tel,
  Textarea,
  Rating,
  ImageUploader,
  TextEditor,
  AddAvatar,
  AddAvatarUser,
  MultiSelect,
  Switch,
};

export default Inputs;
