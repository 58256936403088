import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  useHorseEditButton,
  useHorseDeleteButton,
  useHorseHistoryRidingButton,
  useAddPictureButton,
  useAddEventButton,
  useAddProposalButton,
} from 'old/buttonHooks';
import EditableAvatar from '@old/components/common/editable/EditableAvatar';
import RowAligner from '@old/components/common/RowAligner';
import Message from '@old/components/common/Message';
import Link from '@old/components/common/Link';
import Allow from '@old/components/common/Allow';
import Center from '@old/components/common/Center';
import OverflowItem from '@old/components/common/OverflowItem';
import Subtitle from '@old/components/common/Subtitle';
import FloatingButton from '@old/components/custom/FloatingButton';
import Calendar from '@old/components/view/calendar/Calendar';
import RatingStars from '@old/components/old/RatingStars';
import t from 'resources/translations';
import FlexRow from '@old/components/common/FlexRow';
import Box from '@old/components/common/Box';
import FlexColumn from '@old/components/common/FlexColumn';
import Icon from '@old/components/icon';
import HorseStatisticsPieChart from '@old/components/view/charts/HorseStatisticsPieChart';
import { useStatistics } from '@old/hooks';
import config from '@old/config';
import GalleryViewer from '@old/components/modals/GalleryViewer';
import SelectDateRangeModal from '@old/components/modals/SelectDateRange';
import WorkedHoursChart from '@old/components/view/charts/WorkedHoursChart';
import { formatDuration, isAllowed } from '../../utils';
import { useOldLoggedMember } from 'utils/storeUtils';

const HorseView = ({ loading, match, isMobile, horse, ...actions }) => {
  const loggedMember = useOldLoggedMember();
  const [pieChartDateRange, pieChartSetDateRange] = useState({
    start: moment().subtract(30, 'days').startOf('day'),
    end: moment().endOf('day'),
  });

  const [pieChartStats, pieChartStatsReady] = useStatistics(
    match.params.id,
    'horses',
    pieChartDateRange,
    !loggedMember.isClient()
  );
  const dataPie =
    pieChartStatsReady && pieChartStats
      ? [
          {
            name: 'cancelled',
            color: config.color.tealLight,
            value: pieChartStats.total.cancelled_count || 0,
          },
          {
            name: 'worked',
            color: config.color.teal,
            value: pieChartStats.total.finished_count || 0,
          },
        ]
      : [];

  const horseEdit = useHorseEditButton(horse);
  const horseDelete = useHorseDeleteButton(horse, { customRedirect: '/horses' });
  const addPicture = useAddPictureButton(horse);
  const historyHorseRiding = useHorseHistoryRidingButton(horse);

  const addEvent = useAddEventButton({
    ...(horse
      ? {
          initState: {
            initValues: {
              horses: [{ value: horse.id, label: horse.getName() }],
              instructors: loggedMember.isInstructor()
                ? [
                    {
                      value: loggedMember.id,
                      label: loggedMember.getFullName(),
                    },
                  ]
                : [],
            },
          },
        }
      : {}),
    label: t('buttons.addEventWithHorse'),
  });

  const addProposal = useAddProposalButton({
    ...(horse
      ? {
          initState: {
            initValues: {
              horses: [{ value: horse.id, label: horse.getName() }],
              participations: loggedMember.isClient()
                ? [
                    {
                      value: loggedMember.id,
                      label: loggedMember.getFullName(),
                    },
                  ]
                : [],
            },
          },
        }
      : {}),
    label: t('buttons.addProposalWithHorse'),
  });

  // [API-3.0] [Start] look at this after API 3.0 is released
  const horseButtons =
    horse && !horse.isDeleted()
      ? [horseEdit, addProposal, horseDelete, addPicture, historyHorseRiding, addEvent]
      : [historyHorseRiding];
  // [API-3.0] [End] look at this after API 3.0 is released

  const eventTypes = (horse.eventTypes || []).map(eventType => eventType.getName()).join(', ');
  const finishedDuration = (pieChartStats && pieChartStats.total && pieChartStats.total.finished_duration) || null;
  const hoursWorked = finishedDuration ? formatDuration(moment.duration(finishedDuration, 'minutes')) : '0m';

  return (
    <>
      {horse.isDeleted() && (
        <Message deleted>
          <RowAligner>
            <Icon.WarningOutline className="fill-teal" />
            <span>{t('model.deleteNotify.horse')}</span>
          </RowAligner>
        </Message>
      )}
      <FlexRow cols="10/6" marginBottom stretched>
        <Box>
          <FlexRow cols="none/1" stretched>
            <Center>
              <EditableAvatar modelItem={horse} refreshAction={actions.fetchHorse} />
            </Center>
            <FlexColumn marginBottom>
              {isMobile && (
                <Center>
                  <div>
                    <FlexColumn separated="small">
                      <Subtitle>{t('horseDetails.rating')}</Subtitle>
                      <Link className="text-link" to={`/horses/${horse.id}/rating`}>
                        <RatingStars rating={horse.rating || 0} />
                      </Link>
                    </FlexColumn>
                  </div>
                </Center>
              )}
              <div>
                <div className="text-highlight">{t('horseDetails.breed')}</div>
                <div>{horse.breed || t('horseDetails.notApplicable')}</div>
              </div>
              <div>
                <div className="text-highlight">{t('labels.birthDate')}</div>
                <div>{horse.getBirthday()}</div>
              </div>
              <div>
                <div className="text-highlight">{t('general.level')}</div>
                <div>{t(`skills.${horse.getSkill()}`)}</div>
              </div>
              <div>
                <div className="text-highlight">{t('model.eventTypes')}</div>
                <div>{eventTypes || t('horseDetails.notApplicable')}</div>
              </div>
            </FlexColumn>
          </FlexRow>
          <FlexColumn>
            {horse.healthStatus && (
              <>
                <div className="text-highlight">{t('labels.healthStatus')}</div>
                {horse.healthStatus}
              </>
            )}
            {horse.description && (
              <React.Fragment>
                <div className="text-highlight">{t('general.description')}</div>
                {horse.description}
              </React.Fragment>
            )}
          </FlexColumn>
        </Box>
        <FlexColumn>
          {isAllowed('horse_statistics.index') && (
            <Box>
              <FlexColumn>
                <OverflowItem>
                  <Link className="text-link" to={`${match.url}/statistics`}>
                    <Icon.VisibilityOn />
                  </Link>
                </OverflowItem>
                <HorseStatisticsPieChart data={dataPie} />
                <WorkedHoursChart value={hoursWorked} />
                <Center>
                  <SelectDateRangeModal dateRange={pieChartDateRange} onSave={pieChartSetDateRange} />
                </Center>
              </FlexColumn>
            </Box>
          )}
          {!isMobile && (
            <Box centerX centerY fullHeight>
              <FlexColumn>
                <Center>
                  <Subtitle>{t('horseDetails.rating')}</Subtitle>
                </Center>
                <Center>
                  <Link className="text-link" to={`/horses/${horse.id}/rating`}>
                    <RatingStars rating={horse.rating || 0} />
                  </Link>
                </Center>
              </FlexColumn>
            </Box>
          )}
        </FlexColumn>
      </FlexRow>
      <FlexRow marginBottom>
        {horse.pictures.length > 0 ? (
          <Box>
            <GalleryViewer
              modelItem={horse}
              perView={isMobile ? 1 : 6}
              permissionKey="horses.update"
              refreshAction={actions.fetchHorse}
            />
          </Box>
        ) : (
          <GalleryViewer modelItem={horse} permissionKey="horses.update" refreshAction={actions.fetchHorse} />
        )}
      </FlexRow>
      <Allow permissionKey="events.index">
        <Calendar
          fetchParams={{ with_horse: [horse.id] }}
          defaultMode="week"
          addEventButton={addEvent}
          addProposalButton={addProposal}
          onlyDefaultMode
          extended
          displayedRange="week"
        />
      </Allow>
      <FloatingButton buttons={horseButtons} />
    </>
  );
};

HorseView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { isMobile } }) => {
  return {
    isMobile,
  };
};

export default connect(mapStateToProps)(HorseView);
