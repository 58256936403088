
import Model from '@old/model';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const OPEN_NOTIFICATIONS_WIDGET = 'OPEN_NOTIFICATIONS_WIDGET';
export const OPEN_NOTIFICATION = 'OPEN_NOTIFICATION';
export const OPEN_ALL_NOTIFICATION = 'OPEN_ALL_NOTIFICATION';
export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const subscribeNotifications = () => ({
  channel: 'NotificationChannel',
  received: RECEIVE_NOTIFICATION,
});

export const unsubscribeNotifications = () => ({
  channel: 'NotificationChannel',
  received: CLEAR_NOTIFICATIONS,
  leave: true,
});

export const toggleNotificationsWidget = (isOpen = null) => ({ type: OPEN_NOTIFICATIONS_WIDGET, isOpen });

export const setNotifications = params => async (dispatch) => {
  try {
    const [notifications] = await Model.Notifications.fetchAll(params);
    return dispatch({ type: SET_NOTIFICATIONS, notifications });
  } catch {
    return null;
  }
};

export const openNotification = id => async (dispatch) => {
  try {
    await Model.Notifications.open(id);
    return dispatch({ type: OPEN_NOTIFICATION, id });
  } catch {
    return null;
  }
};

export const openAllNotifications = () => async (dispatch) => {
  try {
    await Model.Notifications.openAll();
    return dispatch({ type: OPEN_ALL_NOTIFICATION });
  } catch {
    return null;
  }
};
