/**
 * @module AddGuestModal
 */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@old/components/common/Modal';
import Model from '@old/model';
import { getErrorMessage, notify } from 'old/utils';
import t from 'resources/translations';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_ADD_GUEST } from 'old/constants/formNames';
import config from '@old/config';

/**
 * A component that displays a modal window for adding guests
 * @param  {Function} onClose A function that closes the modal
 * @param  {Function} afterAddGuest A function that will be executed after guest added successed
 */

const AddGuestModal = ({ onClose, afterAddGuest }) => {
  const onSubmit = async formState => {
    try {
      const { data } = await Model.Members.addGuest({ ...formState });
      notify(t('message.addGuestSuccess'));
      afterAddGuest(data);
      onClose();
    } catch (e) {
      notify(getErrorMessage(e), { type: 'error' });
    }
  };

  const initValues = {
    nickname: '',
    phone: '',
    additional_info: '',
  };

  return (
    <Modal header={t('addGuestForm.addGuestTitle')} onClose={onClose} isOpen>
      <Form formName={FORM_ADD_GUEST} initValues={initValues} noPadding refreshAlways>
        <Field.Text name="nickname" label={t('labels.name')} placeholder={t('placeholders.name')} required />
        <Field.Phone
          name="phone"
          label={t('labels.phone')}
          placeholder={t('placeholders.phone')}
          defaultDirectionalValue={config.defaultDirectional.value}
          required
        />
        <Field.Text name="additional_info" label={t('labels.userNote')} placeholder={t('placeholders.userNote')} />
        <FormButton formName={FORM_ADD_GUEST} onClick={onSubmit} fluid async>
          {t('addGuestForm.addGuest')}
        </FormButton>
      </Form>
    </Modal>
  );
};

AddGuestModal.defaultProps = {
  afterAddGuest: () => {},
};

AddGuestModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  afterAddGuest: PropTypes.func,
};

export default AddGuestModal;
