import HorseListItem from '@old/components/view/listItem/Horse';
import FlexColumn from '@old/components/common/FlexColumn';

const HorsesList = ({ data }) => (
  <FlexColumn>
    {data.map(horse => (
      <HorseListItem key={horse.id} horse={horse} />
    ))}
  </FlexColumn>
);

export default HorsesList;
