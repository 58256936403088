import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import Input from '@old/components/input';
import { changeFieldState } from 'store/actions';

const TimeField = ({ onChangeField, label, placeholder, required, ...fieldProps }) => {
  const { name, formName } = fieldProps;

  const onChange = (value) => {
    onChangeField({ formName, fieldName: name, fieldState: value });
  };

  return (
    <FormField {...fieldProps}>
      <Input.Time
        onChange={onChange}
        value={fieldProps.value}
        label={label}
        placeholder={placeholder}
        required={required}
      />
    </FormField>
  );
};

TimeField.defaultProps = {
  value: '',
  validation: [],
};

TimeField.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  validation: PropTypes.arrayOf(PropTypes.shape({
    condition: PropTypes.func,
    errorHint: PropTypes.string,
  })),
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(TimeField);
