import { AvatarWithLabel, Card, CardContent, Divider, LinkMember, PageTitle } from 'components/combinations';
import { Box, Content, Flex, Icons, Span, Stack, Text } from 'components/elements';
import t from 'resources/translations';
import { useParams } from 'react-router';
import { useNewLayoutColor, useAppSelector, useLoggedMember, usePagination } from 'utils';
import { UseQueryResult } from 'react-query';
import { TicketService } from 'services';
import Ticket from 'models/Ticket';
import moment from 'moment';
import { DATE_FORMAT } from 'config/constans';
import TicketAudtitItem from './TicketAuditItem';
import Spinner from 'old/components/common/Spinner';
import Error404 from 'old/components/error/Error404';
import { ITicketRes } from 'resources/types/TicketTypes';
import { useQuery } from 'react-query';
import { useTicketAudits } from 'actions/ticketOffers';
import PagePagination from 'components/modules/PagePagination';
import { IQueryData } from 'actions/events';

const TicketDetails = () => {
  useNewLayoutColor();
  const { id } = useParams<{ id: string }>();
  const { page, setPage } = usePagination();
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const loggedMember = useLoggedMember();

  const ticketState = useQuery(['TICKET_DETAILS', { id }], () => TicketService.fetch(id)) as UseQueryResult<ITicketRes>;
  const [auditsState, audits] = useTicketAudits(
    ['TICKET_AUDITS', { id, page }],
    id,
    { page, per_page: 24 },
    {
      keepPreviousData: true,
      onSuccess: (data: IQueryData<ITicketRes>) => {
        if (data.pagination.pages !== 0 && page > data.pagination.pages) {
          setPage(data.pagination.pages);
        }
      },
    }
  );

  const ticket = ticketState.data ? new Ticket(ticketState.data) : null;
  const pagination = auditsState.data?.pagination;
  if (ticketState.isError) return <Error404 />;
  if (ticketState.isLoading) return <Spinner />;
  if (!ticket) return null;

  const daysToExpired = moment(ticket.expireAt).diff(ticket.activeAt, 'days') + 1;
  const eventTypeName = ticket.eventTypes.map(type => type.getName()).join(', ');
  return (
    <Content>
      <Stack spacing="xl">
        <PageTitle title={ticket.name} back />
        <Card>
          <CardContent p={['md', 'xl']}>
            <Stack isHorizontal={!isMobile} mb="sm">
              {loggedMember?.isClient() ? (
                <Box width="100%">
                  {ticket.count > 0 && ticket.status === 'paid' && (
                    <Stack isHorizontal alignItems="center" mb="sm" spacing={['sm', 'md']}>
                      <Icons.TicketCircle fill="white" bgColor="teal-light" size="md" />
                      <Text color="teal-dark" fontWeight="medium">
                        {t('ticketDetails.ticketActive')}
                      </Text>
                    </Stack>
                  )}
                  {ticket.status === 'unpaid' && (
                    <Stack isHorizontal alignItems="center" mb="sm" spacing={['sm', 'md']}>
                      <Icons.TicketCircle fill="white" bgColor="orange" size="md" />
                      <Text color="orange" fontWeight="medium">
                        {t('ticketDetails.ticketUnapid')}
                      </Text>
                    </Stack>
                  )}
                  {ticket.count === 0 && (
                    <Stack isHorizontal alignItems="center" mb="sm" spacing={['sm', 'md']}>
                      <Icons.TicketCircle fill="white" bgColor="grey" size="md" />
                      <Text color="cod-gray" fontWeight="medium">
                        {t('ticketDetails.ticketUsed')}
                      </Text>
                    </Stack>
                  )}
                </Box>
              ) : (
                <AvatarWithLabel
                  title={ticket.membership.getFullName()}
                  alt={ticket.membership.getFullName()}
                  src={ticket.membership.getAvatar('small')}
                >
                  <LinkMember member={ticket.membership}>{ticket.membership.getFullName()}</LinkMember>
                </AvatarWithLabel>
              )}
              <Flex alignSelf={['start', 'center']}>
                <Text color="teal-dark" whiteSpace="nowrap">
                  {t('ticketDetails.numberRidesCurrently')}:
                  <Span pl="xs" color="grey-dark" fontSize="md" fontWeight="medium">
                    {ticket.count}
                  </Span>
                </Text>
              </Flex>
            </Stack>
            <Divider variant="dashed" color="new-grey-light-70" borderWidth="1px" />
            <Stack spacing="lg">
              <Stack isHorizontal={!isMobile} w="100%">
                <Stack spacing="sm" flex={3}>
                  <Text fontWeight="medium">
                    {t('ticketDetails.ticketValidity')}:
                    <Span pl="xs" fontWeight="normal">
                      {`${daysToExpired} ${t('tickets.days', {
                        count: daysToExpired,
                      })}`}
                    </Span>
                  </Text>
                  <Text fontWeight="medium">
                    {t('ticketDetails.numberRides')}:
                    <Span pl="xs" fontWeight="normal">
                      {ticket.countLimit}
                    </Span>
                  </Text>
                </Stack>
                <Stack spacing="sm" flex={4}>
                  <Text fontWeight="medium">{t('tickets.periodValidityOfTickets')}:</Text>
                  <Text>
                    {moment(ticket.activeAt).format(DATE_FORMAT)} - {moment(ticket.expireAt).format(DATE_FORMAT)}
                  </Text>
                </Stack>
                <Stack spacing="sm" flex={3}>
                  <Text fontWeight="medium">{t('tickets.assignmentDate')}:</Text>
                  <Text>{moment(ticket.createdAt).format(DATE_FORMAT)}</Text>
                </Stack>
              </Stack>
              <Stack isHorizontal={!isMobile} spacing={['sm', 'xs']}>
                <Text fontWeight="medium">{t('ticketDetails.typesEventsAvailable')}: </Text>
                <Text>{eventTypeName}</Text>
              </Stack>
              {ticket.description && (
                <Text fontWeight="medium">
                  {t('ticketOffers.additionalRemarks')}
                  <Span fontWeight="normal">{`: ${ticket.description}`}</Span>
                </Text>
              )}
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardContent p={['sm', 'xl']}>
            <Flex px="sm" flexDirection="column">
              <Stack>
                <Text fontWeight="medium">{t('ticketDetails.historyTicket')}</Text>
                <Divider variant="solid" color="rgba(209, 208, 208, 0.7)" borderWidth="1px" />
              </Stack>
              {(audits || []).map((audit, index) => {
                return <TicketAudtitItem key={index} audit={audit} index={index} />;
              })}
            </Flex>
          </CardContent>
        </Card>
      </Stack>
      {!!audits.length && pagination && <PagePagination pagination={pagination} />}
    </Content>
  );
};

export default TicketDetails;
