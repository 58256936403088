import React, { useState } from 'react';
import Event from 'models/Event';
import MyProposalBar from 'views/Dashboard/MyProposalBar';
import MyProposalInfo from 'views/Dashboard/MyProposalInfo';
import { Button, Flex, Stack, Ternary, Text, Icons } from 'components/elements';
import { Accordion, AccordionContent, AccordionHeader, Divider } from 'components/combinations';
import { TFlexProps } from 'resources/types/elementsTypes';
import t from 'resources/translations';

const containerStyleProps: TFlexProps = {
  flexDirection: 'column',
  overflow: 'hidden',
  bgColor: 'white',
  boxShadow: '0 2px 12px 0 rgba(37, 51, 66, 0.15)',
  radius: 8,
};

type TMyProposalProps = TFlexProps & {
  proposals: Event[];
  onMoreProposals: Function;
  statusesProposals: {
    isFetching: boolean;
    isHasNextPage: boolean;
  };
};

const MyProposals = ({ proposals, statusesProposals, onMoreProposals, ...flexProps }: TMyProposalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flex {...containerStyleProps} {...flexProps}>
      <Flex flexDirection="row" p="md" mr="15px" justifyContent="space-between">
        <Stack alignItems="center" isHorizontal>
          <Icons.EventProposalCircle bgColor="grey-lighter-40" />
          <Text fontWeight="medium">{t('dashboard.myProposals')}</Text>
          <Icons.BellTwo squareSize={20} fill="orange" />
        </Stack>
        <Button variant="link" onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}>
          {isOpen ? <Icons.ArrowUp /> : <Icons.ArrowDown />}
        </Button>
      </Flex>
      {isOpen && (
        <>
          <Accordion px={[0, 'md']}>
            {(proposals || []).map((proposal, index) => {
              const bgColor = index % 2 === 0 ? 'new-grey-light-15' : 'white';
              return [
                <AccordionHeader key={`header-${proposal.id}`} position="relative" bgColor={bgColor}>
                  {accordionProps => {
                    return <MyProposalBar key={proposal.id} proposal={proposal} {...accordionProps} />;
                  }}
                </AccordionHeader>,
                <AccordionContent key={`content-${proposal.id}`} bgColor={bgColor}>
                  <MyProposalInfo proposal={proposal} />
                </AccordionContent>,
              ];
            })}
          </Accordion>
          {proposals.length !== 0 && <Divider color="new-grey-light-30" />}
          <Flex justifyContent="center" py="lg">
            <Ternary cond={statusesProposals.isFetching}>
              <Text color="grey" fontWeight="medium">{`${t('message.searching').toUpperCase()}...`}</Text>
              <Ternary cond={statusesProposals.isHasNextPage}>
                <Button onClick={() => onMoreProposals()} variant="link">
                  <Stack spacing="sm" alignItems="center" isHorizontal>
                    <Text fontWeight="medium">{t('buttons.showMore').toUpperCase()}</Text>
                    <Icons.LoadMore size={20} />
                  </Stack>
                </Button>
                <Text fontWeight="medium" color="grey">
                  {t('message.noResults').toUpperCase()}
                </Text>
              </Ternary>
            </Ternary>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default React.memo(MyProposals);
