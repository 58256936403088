/* eslint-disable max-len */
import React from 'react';

const Send = () => {
  return (
    <svg
      width="100%"
      className="icon"
      viewBox="0 0 16 16"
    >
      <g>
        <polygon points="0,14.9 16,8 0,1.1 0,6.5 11.4,8 0,9.5" />
      </g>
    </svg>
  );
};

export default Send;
/* eslint-enable max-len */
