import moment from 'moment';
import truncate from 'lodash/truncate';
import config from '@old/config';

import { EventType } from '@old/model/EventType';
import { Horse } from '@old/model/Horse';
import { Place } from '@old/model/Place';
import { Member } from '@old/model/Member';
import { Event } from '@old/model/Event';
import BaseModel from '@old/model/BaseModel';
import Participation from './Participation';

class Proposals extends BaseModel {
  constructor(options) {
    super({
      dataKey: 'events',
      modelName: 'proposals',
      updateKey: 'event',
      basePath: '/api/v1/farms/{farmId}/events/proposals',
      ItemClass: Proposal,
      ...options,
    });
  }

  approve = async (proposalId, params) => {
    const response = await this.client.patch(`${this.basePath}/${proposalId}/approve`, { event: params });
    return new Event(response.data);
  };

  reject = async (proposalId) => {
    const response = await this.client.patch(`${this.basePath}/${proposalId}/reject`);
    return new Event(response.data);
  };
}

class Proposal {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.visibility = data.visibility === 'public';
    this.startDate = moment(data.start_at);
    this.endDate = moment(data.end_at);
    this.difficulty = data.difficulty;
    this.special = data.special;
    this.attendees = { min: data.attendees_min, max: data.attendees_max };
    this.instructors = data.instructors
      ? data.instructors.map(instructor => new Member(instructor)) : [];
    this.creator = new Member(data.creator || {});
    this.places = data.places ? data.places.map(place => new Place(place)) : [];
    this.horses = data.horses ? data.horses.map(horse => new Horse(horse)) : [];
    this.type = data.type ? new EventType(data.type) : null;
    this.participations = data.participations ? data.participations.map(p => new Participation(p)) : [];
  }

  static mapToSaveData = (data) => {
    let placeIds;

    if (Array.isArray(data.places)) {
      placeIds = data.places;
    } else if (data.places) {
      placeIds = [data.places];
    }

    const event = {
      name: data.proposalName,
      description: data.proposalDescription,
      start_at: data.startDate && moment(data.startDate, 'DD-MM-YYYY HH:mm').toISOString(),
      end_at: data.endDate && moment(data.endDate, 'DD-MM-YYYY HH:mm').toISOString(),
      difficulty: data.difficulty,
      special: data.special,
      attendees_min: data.attendees && data.attendees[0],
      attendees_max: data.attendees && data.attendees[1],
      event_type_id: data.type,
      instructor_ids: data.instructors,
      horse_ids: data.horses,
      participant_ids: data.participations,
      place_ids: placeIds,
    };

    if (data.visibility !== undefined) {
      event.visibility = data.visibility === true ? 'public' : 'private';
    }

    Object.keys(event).forEach((fieldName) => {
      if (event[fieldName] === undefined) delete event[fieldName];
    });
    return event;
  };

  static mapToFormData = (data) => {
    const eventData = {
      proposalName: data.name,
      proposalDescription: data.description,
      startDate: data.getStartDateTime(),
      endDate: data.getEndDateTime(),
      type: {
        value: data.type.id,
        label: data.type.getName(),
        timeIntervals: data.type.timeIntervals,
      },
      places: data.places.map(place => ({ value: place.id, label: place.getName() }))[0],
      horses: data.horses.map(horse => ({ value: horse.id, label: horse.getName() })),
      participations: data.participations.filter(
        participation => ['invited', 'rejected'].includes(participation.status),
      ).map(
        participation => ({ value: participation.member.id, label: participation.member.getName() }),
      ),
      difficulty: config.difficulty.find(d => d.value === String(data.difficulty)),
      instructors: data.instructors.map(instructor => (
        { value: instructor.id, label: instructor.getName() }
      )),
      visibility: data.visibility,
      special: data.special,
      attendees: Object.values(data.attendees),
    };
    return eventData;
  };

  isReadyToConfirm = () => (
    this.type
    && this.name
    && this.difficulty
    && this.startDate
    && this.endDate
    && this.attendees.min
    && this.attendees.max
    && this.places.length > 0
    && this.instructors.length > 0
  );

  update = async changes => this.model.update(this.id, changes);

  getInstructors() {
    return this.instructors || [];
  }

  getInstructorsIds() {
    return this.getInstructors().map(instructor => instructor.id);
  }

  getCreatorId() {
    return this.creator.id;
  }

  getName(limit = false) {
    return limit ? truncate(this.name, { length: limit, omission: '...' }) : this.name;
  }

  getPlacesString() {
    return (this.places || []).map(place => place.name).join(', ');
  }

  getStartDateTime() {
    return this.getStartDate(config.dateTimeFormat);
  }

  getEndDateTime() {
    return this.getEndDate(config.dateTimeFormat);
  }

  getStartDate(format = 'DD-MM-YYYY, HH:mm') {
    return this.startDate.format(format);
  }

  getEndDate(format = 'DD-MM-YYYY, HH:mm') {
    return this.endDate.format(format);
  }

  getDateRange() {
    return this.startDate.isSame(this.endDate, 'day')
      ? `${this.getStartDate()} - ${this.getEndDate('HH:mm')}`
      : `${this.getStartDate()} - ${this.getEndDate()}`;
  }

  getDifficulty() {
    return this.difficulty || 'missing';
  }

  getPlace() {
    return this.places[0] || null;
  }

  getTypeName() {
    return this.type ? this.type.getName() : '';
  }

  getParticipations(withDeleted) {
    return (this.participations || []).filter(participation => (
      withDeleted || !participation.member.isDeleted()
    ));
  }

  getHorses() {
    return this.horses || [];
  }
}

export { Proposal, Proposals };
