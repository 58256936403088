/* eslint-disable max-len */
import React from 'react';

const Edit = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M0,12.7V16h3.3l9.8-9.8L9.8,2.8L0,12.7z M15.8,3.6C15.9,3.4,16,3.2,16,3s-0.1-0.5-0.2-0.6v0l-2.1-2.1C13.5,0.1,13.3,0,13,0s-0.5,0.1-0.6,0.2l0,0l-1.6,1.6l3.4,3.4L15.8,3.6L15.8,3.6z" />
    </svg>
  );
};

export default Edit;
/* eslint-enable max-len */
