import { useState } from 'react';
import { useFormik } from 'formik';
import { useLoggedMember, yup } from 'utils';
import moment from 'moment';
import DateInput from 'components/combinations/forms/DateInput';
import FormField from 'components/combinations/forms/FormField';
import FormLabel from 'components/combinations/forms/FormLabel';
import TextInput from 'components/combinations/forms/TextInput';
import t from 'resources/translations';
import { TUsersFormState } from 'resources/types/membersTypes';
import { Divider, Switch } from 'components/combinations';
import { Stack, Text, Icons, Button, Box } from 'components/elements';
import PhoneInput from 'components/combinations/forms/PhoneInput';
import PasswordInput from 'components/combinations/forms/PasswordInput';
import { AddAvatarUser, ImageUploader } from 'old/components/input';
import { DATE_FORMAT } from 'config/constans';
import { IImage } from 'resources/types/commonTypes';

type TUserForm = {
  initialValues: TUsersFormState;
  onSubmit: (values: TUsersFormState) => void;
};

const UsersForm = ({ initialValues, onSubmit }: TUserForm) => {
  const loggedMember = useLoggedMember();
  const validationSchema = yup.object().shape({
    firstname: yup.string().required(t('error.fieldIsRequired')),
    lastname: yup.string().required(t('error.fieldIsRequired')),
    birthday: yup.string().required(t('error.fieldIsRequired')).format(DATE_FORMAT, true),
    phone: yup
      .string()
      .required(t('error.fieldIsRequired'))
      .min(12, t('error.invalidPhoneNumber'))
      .max(13, t('error.invalidPhoneNumber')),
    email: yup.string().required(t('error.fieldIsRequired')).email(t('error.badEmailFormat')),
    password: yup.string().when('showPassword', {
      is: true,
      then: yup
        .string()
        .required(t('error.fieldIsRequired'))
        .min(6, t('error.fieldMustHaveLengthBetween', { min: 6, max: 128 }))
        .max(128, t('error.fieldMustHaveLengthBetween', { min: 6, max: 128 })),
    }),
    passwordConfirmation: yup.string().when('showPassword', {
      is: true,
      then: yup
        .string()
        .required(t('error.fieldIsRequired'))
        .oneOf([yup.ref('password'), null], t('error.passwordsMustBeSame')),
    }),
  });

  const [changePassword, setChangePassword] = useState(initialValues.showPassword);
  const { values, touched, errors, getFieldProps, setFieldValue, setFieldTouched, ...props } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const cropperOptions = {
    aspectRatio: 1,
  };
  const passwordChange = () => {
    values.showPassword = true;
    setChangePassword(true);
  };
  return (
    <form style={{ maxWidth: 790 }}>
      <FormField errors={touched.firstname ? errors.firstname : ''}>
        <FormLabel htmlFor="firstname" required>
          {t('labels.firstname')}
        </FormLabel>
        <TextInput {...getFieldProps('firstname')} placeholder={t('placeholders.firstname')} />
      </FormField>
      <FormField errors={touched.lastname ? errors.lastname : ''}>
        <FormLabel htmlFor="lastname" required>
          {t('labels.lastname')}
        </FormLabel>
        <TextInput {...getFieldProps('lastname')} placeholder={t('placeholders.lastname')} />
      </FormField>
      <FormField errors={touched.birthday ? errors.birthday : ''}>
        <FormLabel htmlFor="birthday" required>
          {t('labels.birthDate')}
        </FormLabel>
        <DateInput
          name="birthday"
          placeholder={t('placeholders.date')}
          value={values.birthday}
          onChange={(value: string) => {
            setFieldValue('birthday', value);
            setFieldTouched('birthday');
          }}
          pickerPosition="top"
          pickerOptions={{
            minDate: moment('01.01.1900', DATE_FORMAT),
            maxDate: moment().subtract(6, 'years'),
          }}
        />
      </FormField>
      <FormField>
        <Divider color="grey" />
      </FormField>
      {loggedMember.isInstructor() && (
        <FormField>
          <Stack alignItems="center" isHorizontal>
            <Switch
              id="privateProfile"
              name="privateProfile"
              checked={values.privateProfile === true}
              onChange={(value: boolean) => {
                setFieldValue('privateProfile', value);
                setFieldTouched('privateProfile');
              }}
              disabled={false}
            />
            <Text>{t('usersForm.hideContactDetails')}</Text>
          </Stack>
          <Stack isHorizontal spacing="sm" mt="md">
            <Icons.CrossedEyeCircle size="xs" bgColor="teal-dark" color="white" />
            <Text color="teal-dark">{t('usersForm.clientNotViewYourContact')}</Text>
          </Stack>
        </FormField>
      )}
      <FormField errors={touched.email ? errors.email : ''}>
        <FormLabel htmlFor="email" required>
          {t('labels.email')}
        </FormLabel>
        <TextInput {...getFieldProps('email')} placeholder={t('placeholders.email')} />
      </FormField>
      <FormField errors={touched.phone ? errors.phone : ''}>
        <PhoneInput
          name="phone"
          value={values.phone}
          onChange={(value: string) => {
            setFieldValue('phone', value);
            setFieldTouched('phone');
          }}
        />
      </FormField>
      <FormField>
        <Divider color="grey" />
      </FormField>
      <FormField errors={touched.password ? errors.password : ''}>
        <>
          {!changePassword && (
            <Button
              variant="outline"
              maxWidth={290}
              onClick={() => {
                passwordChange();
              }}
            >
              {t('labels.changePassword')}
            </Button>
          )}
          {changePassword && (
            <Box>
              <FormLabel htmlFor="password" required>
                {t('labels.password')}
              </FormLabel>
              <PasswordInput
                {...getFieldProps('password')}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldTouched('password');
                  setFieldValue('password', value);
                }}
                placeholder={t('placeholders.password')}
              />
            </Box>
          )}
        </>
      </FormField>
      {changePassword && (
        <FormField errors={touched.passwordConfirmation ? errors.passwordConfirmation : ''}>
          <FormLabel htmlFor="passwordConfirmation" required>
            {t('labels.confirmPassword')}
          </FormLabel>
          <PasswordInput
            {...getFieldProps('passwordConfirmation')}
            onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
              setFieldTouched('passwordConfirmation');
              setFieldValue('passwordConfirmation', value);
            }}
            placeholder={t('placeholders.repeatPassword')}
          />
        </FormField>
      )}
      <FormField>
        <Divider color="grey" />
      </FormField>
      <FormField>
        <Stack isHorizontal spacing="sm">
          <Icons.InfoCircle size="xs" bgColor="teal-dark" color="white" />
          <Text color="teal-dark">{t('usersForm.photoRequired')}</Text>
        </Stack>
      </FormField>
      <FormField>
        <FormLabel htmlFor="password" required>
          {t('labels.photoProfile')}
        </FormLabel>
        <ImageUploader
          {...getFieldProps('avatar')}
          /* @ts-ignore */
          onChange={(value: IImage | null) => {
            setFieldTouched('avatar');
            setFieldValue('avatar', value);
          }}
          /* @ts-ignore */
          placeholder={t('placeholders.dropFile')}
          /* @ts-ignore */
          cropperOptions={cropperOptions}
        />
        <Box mt="md">
          <AddAvatarUser
            {...getFieldProps('defaultAvatar')}
            onChange={(value: IImage | null) => {
              setFieldTouched('defaultAvatar');
              setFieldValue('defaultAvatar', value);
            }}
          />
        </Box>
      </FormField>
      <Button px="2xl" disabled={!(props.isValid && props.dirty)} onClick={props.submitForm}>
        {t('general.saveChanges')}
      </Button>
    </form>
  );
};

export default UsersForm;
