/**
 * @module ColorBar
 */

import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

/**
 * Component return div with color and position horizontally or vertically
 * @param {String} props.color color of component body
 * @param {Boolean} [props.vertical] if true, bar set vertical
 * @param {String} [props.className] optional tailwind className
 */
const ColorBar = ({ color, vertical, className }) => {
  return !vertical
    ? <div className={cls('color-bar', className)} style={{ backgroundColor: color }} />
    : <div className={cls('color-bar-vertical', className)} style={{ backgroundColor: color }} />;
};

ColorBar.defaultProps = {
  vertical: false,
  className: '',
};

ColorBar.propTypes = {
  color: PropTypes.string.isRequired,
  vertical: PropTypes.bool,
  className: PropTypes.string,
};

export default ColorBar;
