import { useMemo, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import axios from 'axios';

import Spinner from '@old/components/common/Spinner';
import PageTitle from '@old/components/guide/PageTitle';
import Error404 from '@old/components/error/Error404';
import EventsProvider from '@old/components/custom/EventsProvider';
import { fetchHorse } from 'store/actions';

const HorseEventHistory = ({ match, isMobile, loading, loadingEvents, error, horse, ...actions }) => {
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    actions.fetchHorse(match.params.id, source.token);
    return () => source.cancel();
  }, [match.params.id]); // eslint-disable-line

  const fetchParams = useMemo(
    () => ({
      with_horse: [horse ? horse.id : 0],
      end_before: moment().format('YYYY-MM-DD'),
      per_page: 20,
      with_status: ['finished'],
      sorted_by: 'start_at_desc',
    }),
    [horse]
  );

  if (error) return <Error404 />;
  if (loading && !horse) return <Spinner />;
  if (!horse) return null;

  return (
    <>
      <PageTitle title={horse.getName()} back={`/horses/${horse.id}`} loading={loading || loadingEvents} />
      <EventsProvider fetchParams={fetchParams} showDateLabels={isMobile} />
    </>
  );
};

HorseEventHistory.defaultProps = {
  horse: null,
};

const mapStateToProps = ({ fetchingData: { horses, loading, error }, app: { isMobile } }, { match }) => {
  return {
    horse: horses.find(horse => String(horse.id) === String(match.params.id)),
    loading: loading.horses,
    loadingEvents: loading.events,
    error: error.horses,
    isMobile,
  };
};

export default connect(mapStateToProps, { fetchHorse })(HorseEventHistory);
