import { useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { withRouter } from 'react-router';

import ProposalsList from '@old/components/view/list/Proposal';
import config from '@old/config';
import { getPaginationId } from 'old/utils';
import { setPagination, fetchProposals } from 'store/actions';
import Error404 from '@old/components/error/Error404';
import Spinner from '@old/components/common/Spinner';
import { useOldLoggedMember } from 'utils/storeUtils';

const ProposalEventsPage = ({ loading, error, proposals, proposalsPagination, pagination, history, ...actions }) => {
  const loggedMember = useOldLoggedMember();
  const fetchParams = {
    for: loggedMember.isClient() ? [loggedMember.id] : null,
    sorted_by: 'start_at_asc',
  };

  const { minItemsPerPage } = config;
  const getPaginationKey = () => {
    return getPaginationId(`${window.location.pathname}`);
  };

  const getActivePage = () => {
    if (history.action === 'POP') {
      return (
        history.location.state &&
        history.location.state[getPaginationKey()] &&
        history.location.state[getPaginationKey()].activePage
      );
    }

    return pagination.current;
  };

  useEffect(() => {
    // eslint-disable-line
    if (isEmpty(pagination) || pagination.current !== undefined) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const fetch = async () => {
        await actions.fetchProposals(
          {
            page: getActivePage() || 1,
            per_page: minItemsPerPage,
            ...fetchParams,
          },
          source.token
        );
      };
      fetch();
      return () => source.cancel();
    }
  }, [history.location.state, pagination.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (proposalsPagination) {
      const pag = { ...proposalsPagination };
      // When you delete last element on page, go back to previous one
      const lastPage = Math.ceil(pag.count / pag.per_page);
      if (pag.per_page * pag.current > pag.count) {
        if (lastPage && pag.current > lastPage) {
          pag.current = lastPage;
          history.push(history.location.pathname, { [getPaginationKey()]: { activePage: pag.current } });
        }
        if (pag.current === 0) {
          pag.current = 1;
        }
      }
      actions.setPagination(pag, getPaginationKey());
    }
  }, [proposalsPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Error404 />;
  if (loading && !proposals.length) return <Spinner />;

  return <ProposalsList data={proposals} paginationKey={getPaginationKey()} />;
};

ProposalEventsPage.defaultProps = {
  proposals: [],
  proposalsPagination: {},
};

const mapStateToProps = ({ pagination, fetchingData: { proposals, proposalsPagination, loading, error } }) => {
  const paginationKey = getPaginationId(`${window.location.pathname}`);
  return {
    proposals,
    proposalsPagination,
    loading: loading.proposals,
    error: error.proposals,
    pagination: pagination[paginationKey] || {},
  };
};

export default withRouter(connect(mapStateToProps, { fetchProposals, setPagination })(ProposalEventsPage));
