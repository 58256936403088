import { AxiosResponse as ARes } from 'axios';
import _ from 'lodash';
import HTTP from 'config/axios';
import { IResWithPagination } from 'resources/types/commonTypes';
import { IMemberGroupsFetchParams, IMemberGroupRes, TOptionGroups } from 'resources/types/memberGroupsTypes';
import MemberGroup from 'models/MemberGroup';

class MemberGroupService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async fetch(groupId: number | string) {
    const response: ARes<IMemberGroupRes> = await HTTP.get(`${this.apiBaseURL}/riding_groups/${groupId}`);
    return response.data;
  }

  async fetchAll(params: IMemberGroupsFetchParams) {
    const response: ARes<IResWithPagination<IMemberGroupRes[]>> = await HTTP.get(`${this.apiBaseURL}/riding_groups`, {
      params,
    });
    return response.data;
  }

  async fetchMineGroups(params: IMemberGroupsFetchParams) {
    const response: ARes<IResWithPagination<IMemberGroupRes[]>> = await HTTP.get(
      `${this.apiBaseURL}/riding_groups/mine`,
      {
        params,
      }
    );
    return response.data;
  }

  async fetchOptions(): Promise<TOptionGroups[]> {
    const { riding_groups } = await this.fetchAll({
      per_page: 9999,
      page: 1,
    });

    const mappedOptions = riding_groups.map(member_group => {
      const group = new MemberGroup(member_group);
      return {
        value: `G${group.id}`,
        label: group.getName(),
        type: 'riding_group',
        data: {
          members: group.getMembers().map(member => {
            return {
              value: member.id,
              label: member.getFullName(),
              type: 'member',
              data: {
                image: member.getAvatar('medium'),
                skill: member.getSkill(),
              },
            };
          }),
        },
      } as TOptionGroups;
    });

    const optionsData = mappedOptions.filter(option => {
      return !_.isEmpty(option.data.members);
    });

    return optionsData;
  }
}

export default new MemberGroupService();
