/* eslint-disable max-len */
import React from 'react';

const OkSms = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 32.8 30.3"
    >
      <path d="M39.12,13.38H11a2.31,2.31,0,0,0-2.32,2.31V34.86A2.32,2.32,0,0,0,11,37.17h3.41v6.51l7.74-6.51h17a2.3,2.3,0,0,0,2.31-2.31V15.67A2.3,2.3,0,0,0,39.12,13.38ZM17.84,28a2,2,0,1,1,2-2A2,2,0,0,1,17.84,28ZM25,28a2,2,0,1,1,2-2A2,2,0,0,1,25,28Zm7.18,0a2,2,0,1,1,2-2A2,2,0,0,1,32.21,28Z" transform="translate(-8.63 -13.38)" />
    </svg>
  );
};

export default OkSms;
/* eslint-enable max-len */
