import debounce from 'lodash/debounce';
import Pagination from 'react-js-pagination';
import { useHistory } from 'react-router';
import queryString from 'query-string';

import { Box, Icons } from 'components/elements';
import { APIPagination } from 'resources/types/commonTypes';

type TPagePagination = {
  pagination: APIPagination;
};

const PagePagination = ({ pagination }: TPagePagination) => {
  const history = useHistory();
  const onChange = debounce(
    page => {
      if (pagination.current !== page) {
        const locationQuery = queryString.parse(history.location.search);
        locationQuery.page = page;
        history.push({ pathname: history.location.pathname, search: queryString.stringify(locationQuery) });
        window.scrollTo({ top: 0 });
      }
    },
    250,
    { leading: true, trailing: false }
  );

  const { current, pages, count, per_page: perPage } = pagination;
  if (!count || !pages || pages < 2) return null;

  return (
    <Box textAlign="center">
      <Pagination
        activePage={current}
        itemsCountPerPage={perPage}
        totalItemsCount={count}
        onChange={onChange}
        pageRangeDisplayed={5}
        innerClass="pagination"
        itemClass="item-wrapper"
        linkClass="item"
        prevPageText={<Icons.ArrowLeft fill="teal" size="md" />}
        nextPageText={<Icons.ArrowRight fill="teal" size="md" />}
        lastPageText={<Icons.ArrowDoubleRight fill="teal" size="md" />}
        firstPageText={<Icons.ArrowDoubleLeft fill="teal" size="md" />}
        activeLinkClass="active"
        hideDisabled
        hideFirstLastPages={pages < 3}
      />
    </Box>
  );
};

export default PagePagination;
