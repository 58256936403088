import { AxiosResponse as ARes } from 'axios';
import HTTP from 'config/axios';
import EventType from 'models/EventType';
import { IResWithPagination, TFetchedOptions, TOption } from 'resources/types/commonTypes';
import { ITypeRes, ITypeFetchParams } from 'resources/types/eventTypesTypes';

class EventTypeService {
  apiBaseURL: string;
  farmId: number | null;

  constructor() {
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}`;
    this.farmId = null;
  }

  _refresh(farmId: number) {
    this.farmId = farmId;
    this.apiBaseURL = `/api/${process.env.REACT_APP_API_VERSION}/farms/${farmId}`;
  }

  async fetch(placeId: number | string) {
    const response: ARes<ITypeRes> = await HTTP.get(`${this.apiBaseURL}/event_types/${placeId}`);
    return response.data;
  }

  async fetchAll(params: ITypeFetchParams) {
    const response: ARes<IResWithPagination<ITypeRes[]>> = await HTTP.get(`${this.apiBaseURL}/event_types`, {
      params,
    });
    return response.data;
  }

  async fetchOptions(keyword: string, additional: { page: number }, params: ITypeFetchParams = {}) {
    const page = additional?.page || 1;
    const { event_types, pagination } = await this.fetchAll({
      per_page: 20,
      keyword,
      page,
      ...params,
    });

    const mappedOptions = event_types.map(type => {
      const eventType = new EventType(type);
      return {
        key: eventType.id,
        value: eventType.id,
        label: eventType.getName(),
        type: 'eventType',
        data: {
          image: eventType.getImage('thumb'),
          color: eventType.getColor(),
          slug: eventType.slug,
          isDeleted: eventType.deleted,
        },
      } as TOption;
    });

    return {
      options: mappedOptions,
      hasMore: pagination.pages > page,
      additional: { page: pagination.next },
    } as TFetchedOptions;
  }
}

export default new EventTypeService();
