import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@old/components/icon';
/**
 * Component that generates Spinner when sth is loading
 * @param  {Boolean} [props.transparent] specifies wether there should be transparent background
 * @param  {Boolean} [props.noDelay] specifies wether there should not be any delay before displaying it
 */
const Spinner = ({ transparent, noDelay }) => {
  const [displayLoader, setDisplayLoader] = useState(false);

  useEffect(() => {
    const delay = noDelay ? 0 : 200;
    const timer = setTimeout(enableLoader, delay);
    return () => clearTimeout(timer);
  });

  const enableLoader = () => {
    setDisplayLoader(true);
  };

  if (!displayLoader) return null;

  return (
    <div className={cls('hm-spinner-wrapper', { transparent })}>
      <Icon.Spinner className="fill-teal rotate big" />
    </div>
  );
};

Spinner.defaultProps = {
  transparent: false,
  noDelay: false,
};

Spinner.propTypes = {
  transparent: PropTypes.bool,
  noDelay: PropTypes.bool,
};

export default Spinner;
