import styled from 'styled-components';
import { flexbox, grid, GridProps } from 'styled-system';
import { blockStyleProps, typographyStyleProps, baseStyledProps } from 'config/styledProps';
import {
  IBaseStyledProps,
  ITypographyStyledProps,
  IBlockStyledProps,
  IFlexStyledProps,
} from 'resources/types/styledTypes';

type TGridProps = IBaseStyledProps &
  ITypographyStyledProps &
  IFlexStyledProps &
  Omit<IBlockStyledProps, 'display'> &
  GridProps;

const Grid = styled('div')<TGridProps>(baseStyledProps, typographyStyleProps, blockStyleProps, flexbox, grid, {
  display: 'grid',
});

export default Grid;
