import t from 'resources/translations';

import PendingApprovalSvg from '@old/assets/svg/PendingApproval.svg';

const PendingApproval = ({ currentFarm }) => {
  return (
    <div className="error-info">
      <img src={PendingApprovalSvg} alt="pending" className="pending-image" />
      <div className="text-error-page mb-4">{t('pendingApproval.notFullAccess')}</div>
      <div className="text-lg leading-normal">
        <div>{t('pendingApproval.waitForApprove')}</div>
        <div>{t('pendingApproval.ifProblems')}</div>
        <div>
          {t('pendingApproval.contactPhone')}
          <a className="text-teal" href={`tel:${currentFarm.phone}`}>
            {currentFarm.phone}
          </a>
          {t('pendingApproval.contactMail')}
          <a className="text-teal" href={`mailto:${currentFarm.email}`}>
            {currentFarm.email}
          </a>
        </div>
      </div>
    </div>
  );
};

PendingApproval.defaultProps = {
  currentFarm: null,
};

export default PendingApproval;
