import t from 'resources/translations';
import { Box, Flex, Stack, Text } from 'components/elements';
import { parseToCurrencyString } from 'old/utils';
import { TBillSummary } from 'resources/types/billsTypes';
import { TContentProps } from 'resources/types/elementsTypes';
import { useAppStore } from 'utils';

type TSummaryBillsProps = TContentProps & {
  summaryBills: TBillSummary;
};

const SummaryBills = ({ summaryBills }: TSummaryBillsProps) => {
  const { isMobile } = useAppStore();
  const { dueAmount, paidAmount, outstandingAmount, overpaidAmount } = summaryBills;
  return (
    <Box>
      <Stack isHorizontal={!isMobile} py="lg">
        <Stack w="full" alignItems="center">
          <Flex
            w="full"
            textAlign="center"
            py="md"
            flexDirection="column"
            backgroundColor="body-color"
            borderRadius={4}
          >
            <Text marginBottom={['xs', 'sm']}>{t('bills.amountDue')}</Text>
            <Text color="teal-dark" fontSize="md" fontWeight="medium">
              {parseToCurrencyString(dueAmount)}
            </Text>
          </Flex>
          <Flex
            w="full"
            textAlign="center"
            py="md"
            flexDirection="column"
            backgroundColor="body-color"
            borderRadius={4}
          >
            <Text marginBottom={['xs', 'sm']}>{t('bills.deposit')}</Text>
            <Text color="teal-dark" fontSize="md" fontWeight="medium">
              {parseToCurrencyString(paidAmount)}
            </Text>
          </Flex>
        </Stack>
        <Stack w="full" alignItems="center">
          <Flex
            w="full"
            textAlign="center"
            py="md"
            flexDirection="column"
            backgroundColor="body-color"
            borderRadius={4}
          >
            <Text marginBottom={['xs', 'sm']}>{t('bills.toPay')}</Text>
            <Text color={outstandingAmount > 0 ? 'orange' : 'teal-dark'} fontSize="md" fontWeight="medium">
              {parseToCurrencyString(outstandingAmount)}
            </Text>
          </Flex>
          <Flex
            w="full"
            textAlign="center"
            py="md"
            flexDirection="column"
            backgroundColor="body-color"
            borderRadius={4}
          >
            <Text marginBottom={['xs', 'sm']}>{t('bills.overpaid')}</Text>
            <Text color={overpaidAmount > 0 ? 'orange' : 'teal-dark'} fontSize="md" fontWeight="medium">
              {parseToCurrencyString(overpaidAmount)}
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  );
};

SummaryBills.defaultProps = {
  summaryBills: {
    dueAmount: 0.0,
    paidAmount: 0.0,
    outstandingAmount: 0.0,
    overpaidAmount: 0.0,
  },
};

export default SummaryBills;
