/* eslint-disable max-len */
import React from 'react';

const Calendar = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M14.1,1.2h-0.8V0h-1.2v1.2H3.9V0H2.6v1.2H1.9C0.8,1.2,0,2.1,0,3.1l0,0v11c0,1,0.8,1.9,1.9,1.9l0,0h12.3c1,0,1.9-0.8,1.9-1.9
        l0,0v-11C16,2.1,15.2,1.2,14.1,1.2L14.1,1.2L14.1,1.2z M1.9,2.5h0.8v1.2h1.2V2.5h8.3v1.2h1.2V2.5h0.8c0.3,0,0.6,0.3,0.6,0.6l0,0v1.5
        H1.2V3.1C1.2,2.8,1.5,2.5,1.9,2.5C1.9,2.5,1.9,2.5,1.9,2.5z M14.1,14.7H1.9c-0.3,0-0.6-0.3-0.6-0.6l0,0V5.8h13.5v8.3
        C14.8,14.5,14.5,14.7,14.1,14.7L14.1,14.7L14.1,14.7z M9.9,13.4h3.7V9.7H9.9V13.4z M11.1,10.9h1.2v1.3h-1.2V10.9z"
      />
    </svg>
  );
};

export default Calendar;
/* eslint-enable max-len */
