import { Box, Button, Icons, Text } from 'components/elements';
import _ from 'lodash';
import t from 'resources/translations';
import React from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { TFetchedOptions, TOption } from 'resources/types/commonTypes';
import SimpleBar from 'simplebar-react';
import { getMaxHeight, useInfinityScroll, useAppSelector } from 'utils';
import { Modal, ModalButtons, ModalCloseButton, ModalContent, ModalHeader } from '..';
import FormLabel from '../forms/FormLabel';
import TextInput from '../forms/TextInput';
import DefaultOption from './DefaultOption';

type TSelectModal = {
  selectedOptions: TOption[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<TOption[]>>;
  optionsState: UseInfiniteQueryResult<TFetchedOptions<TOption>, unknown>;
  onChange: (value: TOption[]) => void;
  search: {
    query: string;
    isTouched: boolean;
    searchQuery: any;
    setQuery: React.Dispatch<React.SetStateAction<string>>;
  };
  isOpen: boolean;
  isHidden: boolean;
  onClose: () => void;
  isSubmitting: boolean;
  placeholder: string;
  translations?: {
    title?: string;
    searchLabel?: string;
    searchPlaceholder?: string;
  };
  onSubmitAndClose: (cb: Function) => void;
};

const SelectModal = ({
  selectedOptions,
  setSelectedOptions,
  optionsState,
  search,
  onChange,
  placeholder,
  ...modalProps
}: TSelectModal) => {
  const isMobile = useAppSelector(({ app }) => app.isMobile);
  const { isOpen, isHidden, onClose, isSubmitting, onSubmitAndClose, translations } = modalProps;
  const onDeselect = (option: TOption) => {
    const copySelectedOptions = _.cloneDeep(selectedOptions);
    setSelectedOptions(copySelectedOptions.filter(({ value }) => value !== option.value));
  };

  const onSelect = (option: TOption) => {
    const copySelectedOptions = _.cloneDeep(selectedOptions);
    setSelectedOptions([...copySelectedOptions, option]);
  };

  const refMenu = useInfinityScroll(optionsState.fetchNextPage, optionsState.isFetching, optionsState.hasNextPage);
  const options: TOption[] = _.flatten(_.concat((optionsState.data?.pages || []).map(page => page.options)));

  return (
    <Modal isOpen={isOpen} isHidden={isHidden} onClose={onClose} isBlocked={isSubmitting}>
      <ModalHeader>{translations?.title || placeholder}</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalContent isMakingRequest={isSubmitting}>
        <Box w="full">
          <FormLabel htmlFor="search" fontWeight="normal">
            {translations?.searchLabel || t('general.search')}
          </FormLabel>
          <TextInput
            name="search"
            value={search.query}
            placeholder={translations?.searchPlaceholder || t('general.searchByName')}
            onChange={e => search.setQuery(e.target.value)}
          >
            <Button variant="icon" ml="sm" onClick={() => search.setQuery('')} px={20}>
              {search.query ? <Icons.Cancel squareSize={25} /> : <Icons.Search squareSize={24} />}
            </Button>
          </TextInput>
        </Box>
        <Box w="full">
          <SimpleBar
            style={{ height: 500, maxHeight: getMaxHeight(isMobile ? 340 : 530), paddingRight: 20 }}
            scrollableNodeProps={{ ref: refMenu }}
          >
            {options.map(option => {
              const isSelected = !!selectedOptions.find((o: any) => o.value === option.value);
              return (
                <DefaultOption
                  key={option.value}
                  onClick={() => {
                    if (isSelected) {
                      onDeselect(option);
                    } else {
                      onSelect(option);
                    }
                  }}
                  isDisabled={false}
                  isSelected={isSelected}
                  data={option}
                />
              );
            })}
            {optionsState.isFetching && (
              <Text py="md" color="grey" textAlign="center">
                {t('message.loadingList')}
              </Text>
            )}
          </SimpleBar>
        </Box>
      </ModalContent>
      <ModalButtons>
        <Button onClick={() => onSubmitAndClose(() => onChange(selectedOptions))}>{t('general.save')}</Button>
        <Button width="100%" variant="outline" onClick={onClose}>
          {t('general.close')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default SelectModal;
