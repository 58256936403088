import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import t from 'resources/translations';
import Link from '@old/components/common/Link';
import SelectDateRangeModal from '@old/components/modals/SelectDateRange';
import { useStatistics } from '@old/hooks';

const BasicStatistics = ({ id, type, link, suffixKey }) => {
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(7, 'days').startOf('day'),
    end: moment().subtract(1, 'days').endOf('day'),
  });
  const [stats, statsReady] = useStatistics(id, type, dateRange);

  if (!statsReady) return null;

  const numberOfHours = stats ? Math.round(stats.total.finished_duration / 60) : 0;

  const statistics = (
    <div>
      {t('basicStatistics.statHours', { hours: numberOfHours, count: numberOfHours })}
      <span className="text">
        {t(`basicStatistics.statHoursSuffix${suffixKey}`, { count: numberOfHours })}
      </span>
    </div>
  );

  return (
    <div>
      {link && <Link to={link} className="text-link">{statistics}</Link>}
      {!link && statistics}
      <SelectDateRangeModal dateRange={dateRange} onSave={setDateRange} />
    </div>
  );
};

BasicStatistics.defaultProps = {
  link: null,
};

BasicStatistics.propTypes = {
  type: PropTypes.oneOf(['members', 'places', 'horses']).isRequired,
  suffixKey: PropTypes.oneOf(['client', 'instructor', 'horse']).isRequired,
  id: PropTypes.number.isRequired,
  link: PropTypes.string,
};

export default BasicStatistics;
