import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import RowAligner from '@old/components/common/RowAligner';

const PlaceholderListItem = ({ children, className }) => (
  <div className={cls('placeholder-list-item', className)}>
    <RowAligner>
      {children}
    </RowAligner>
  </div>
);

PlaceholderListItem.defaultProps = {
  children: null,
  className: '',
};

PlaceholderListItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

export default PlaceholderListItem;
