import styled from 'styled-components';
import { blockStyleProps, typographyStyleProps, baseStyledProps } from 'config/styledProps';
import { TContentProps } from 'resources/types/elementsTypes';

const Content = styled('div')<TContentProps>(baseStyledProps, typographyStyleProps, blockStyleProps);

Content.defaultProps = {
  width: '100%',
  px: ['sm', 0, 0],
};

export default Content;
