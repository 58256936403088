/* eslint-disable max-len */
import React from 'react';

const Bold = () => {
  return (
    <svg width="100%" viewBox="0 0 26 26">
      <g>
        <path d="M2.9,26V0h8.9c3.2,0,5.7,0.6,7.6,1.8c1.8,1.2,2.7,3,2.7,5.3c0,1.2-0.3,2.3-0.9,3.3s-1.5,1.7-2.7,2.2c1.5,0.3,2.6,1,3.4,2.1
          c0.7,1,1.1,2.2,1.1,3.6c0,2.5-0.9,4.5-2.6,5.7c-1.6,1.4-4.1,2-7.3,2H2.9z M9,10.8h3c1.4,0,2.4-0.3,3.1-0.8c0.7-0.5,1-1.2,1-2.2
          c0-1.1-0.3-1.9-1-2.4s-1.8-0.8-3.2-0.8H9V10.8z M9,14.8v6.5h4.2c1.3,0,2.3-0.3,2.9-0.8s1-1.3,1-2.3c0-1.1-0.3-2-0.8-2.6
          c-0.6-0.6-1.4-0.9-2.7-0.9L9,14.8L9,14.8z"
        />
      </g>
    </svg>
  );
};

export default Bold;
/* eslint-enable max-len */
