/* eslint-disable max-len */
import React from 'react';

const Proposals = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M15.9,2.5c-0.2-0.7-0.9-1.2-1.7-1.2h-0.7V0h-1.3v1.2H3.9V0H2.6v1.2H1.9C0.9,1.2,0,2.1,0,3.1v11.1c0,1,0.9,1.8,1.8,1.8h12.3
        c0.8,0,1.5-0.5,1.7-1.2c0.1-0.2,0.1-0.4,0.1-0.7v-11C16,2.9,15.9,2.6,15.9,2.5L15.9,2.5z M7.9,13.7c-0.4,0-0.7-0.3-0.7-0.7
        s0.3-0.7,0.7-0.7c0.4,0,0.7,0.4,0.7,0.7C8.6,13.4,8.3,13.7,7.9,13.7z M8.6,10.8c-0.2,0.3-0.1,0.8-0.7,0.8c-0.4,0-0.6-0.3-0.6-0.6
        c0-1.1,1.6-1.4,1.6-2.2C8.9,8.3,8.6,8,8,8C6.8,8,7.3,9.2,6.4,9.2C6.1,9.2,5.8,9,5.8,8.7c0-0.9,1-1.7,2.2-1.7c1.2,0,2.3,0.6,2.3,1.8
        C10.3,10,8.9,10.4,8.6,10.8z M14.8,4.7H1.2V3.1c0-0.4,0.3-0.6,0.6-0.6h0.7v1.2h1.2V2.5h8.2v1.2h1.3V2.5h0.8c0.4,0,0.6,0.2,0.6,0.6
        C14.8,3.1,14.8,4.7,14.8,4.7z"
      />
    </svg>
  );
};

export default Proposals;
/* eslint-enable max-len */
