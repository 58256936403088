import { useQuery, UseQueryResult } from 'react-query';
import { TCacheKey } from 'resources/types/cacheTypes';
import { TOptionGroups } from 'resources/types/memberGroupsTypes';
import { MemberGroupService } from 'services';

export const useMemberGroupsOptions = (key: TCacheKey) => {
  const queryFn = async () => {
    const data = await MemberGroupService.fetchOptions();
    return data;
  };

  const stateQuery = useQuery(key, queryFn, {});
  return [stateQuery, stateQuery.data?.map(group => group)] as [UseQueryResult<TOptionGroups[]>, TOptionGroups[]];
};
