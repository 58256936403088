import { AnimatePresence } from 'framer-motion';
import { MotionBox } from '../../elements';
import { TMotionBoxProps } from 'resources/types/elementsTypes';
import { TReactNode } from 'resources/types/commonTypes';

type TAccordionContent = TMotionBoxProps & {
  index?: number;
  expandedIndex?: number;
  children: TReactNode;
};

const AccordionContent = ({ children, index, expandedIndex, ...motionBoxProps }: TAccordionContent) => {
  const isExpanded = expandedIndex === index;
  return (
    <AnimatePresence initial={false}>
      {isExpanded && (
        <MotionBox
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          overflow="hidden"
          variants={{
            open: { height: 'auto' },
            collapsed: { height: 0 },
          }}
          transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          {...motionBoxProps}
        >
          {children}
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

AccordionContent.defaultProps = {
  expandedIndex: 0,
  index: 0,
};

AccordionContent.displayName = 'AccordionContent';
export default AccordionContent;
