import { Link } from '../elements';
import { useLoggedMember } from 'utils/storeUtils';
import { ILinkStyledProps } from 'resources/types/styledTypes';
import Member from 'models/Member';

type TLinkMember = ILinkStyledProps & {
  children: any;
  forceRender: boolean;
  member?: Member;
};

const LinkMember = ({ member, forceRender, children, ...aStyleProps }: TLinkMember) => {
  const loggedMember = useLoggedMember();
  if (!member) return null;
  const isOwnProfile = loggedMember.id === member.id;
  const memberIsClientOrOwner = member?.isClient() || member?.isOwner();
  if (loggedMember.isClient() && memberIsClientOrOwner && !isOwnProfile) {
    return forceRender ? children : null;
  }

  return (
    <Link to={member.getProfileUrl()} forceRender {...aStyleProps}>
      {children}
    </Link>
  );
};

LinkMember.defaultProps = {
  forceRender: true,
  allowed: false,
};

export default LinkMember;
