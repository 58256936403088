import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormField from '@old/components/common/FormField';
import { changeFieldState } from 'store/actions';
import Input from '@old/components/input';

const ImageUploaderField = ({ onChangeField, label, placeholder, options, ...props }) => {
  const { formName, name, required } = props;
  const onChange = (value) => {
    onChangeField({ formName, fieldName: name, fieldState: value });
  };

  return (
    <FormField {...props}>
      <Input.ImageUploader
        value={props.value}
        placeholder={placeholder}
        onChange={onChange}
        label={label}
        required={required}
        {...options}
      />
    </FormField>
  );
};

ImageUploaderField.defaultProps = {
  value: null,
  placeholder: '',
  options: {},
  label: '',
  required: false,
};

ImageUploaderField.propTypes = {
  options: PropTypes.shape({}), // options are additional props from ImageUploader
  value: PropTypes.string,
  formName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChangeField: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
};

const mapStateToProps = ({ form }, props) => {
  const inputValue = form[props.formName][props.name].value;
  return ({ value: inputValue });
};

export default connect(mapStateToProps, { onChangeField: changeFieldState })(ImageUploaderField);
