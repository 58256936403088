/* eslint-disable max-len */
import React from 'react';

const DeleteOutline = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
    >
      <path d="M8,0c4.4,0,8,3.6,8,8s-3.6,8-8,8l-0.8-0.1C3,15.3,0.2,12.2,0,8C0,3.6,3.6,0,8,0z M10.8,5.7H5.2v5.6c0,0.5,0.4,0.9,0.9,0.9
        h3.7c0.3,0,0.5-0.2,0.8-0.4c0.2-0.4,0.1-0.2,0.2-0.5V5.7z M9,7.3L9.6,8l-1,1l1,1L9,10.6l-1-1l-1,1L6.4,9.9l1-1l-1-1L7,7.3l1,1L9,7.3
        L9,7.3z M9.2,3.8H6.8L6.4,4.3H4.8v0.9h6.5V4.3H9.6L9.2,3.8z"
      />
    </svg>
  );
};

export default DeleteOutline;
/* eslint-enable max-len */
