import t from 'resources/translations';
import Event from 'models/Event';
import { useCallback, useMemo } from 'react';
import { Icons, Box, Dot, Flex, Image, Link, Stack, Ternary, Text } from 'components/elements';
import { IconButton, ParticipationStatusLabel } from 'components/combinations';
import { useAppSelector, useLoggedMember } from 'utils';
import { ResponsiveValue, ThemeValue } from 'styled-system';
import { DefaultTheme } from 'styled-components';
import { TBoxProps, TFlexProps } from 'resources/types/elementsTypes';
import { IButtonStyledProps, ITypographyStyledProps } from 'resources/types/styledTypes';

type TStyles = ITypographyStyledProps & {
  headerContainer: IButtonStyledProps;
  toggleIcon: TFlexProps;
  eventName: TBoxProps;
  eventNameBox: TBoxProps;
};
const styles = (isExpanded = false) => {
  return {
    toggleIcon: {
      position: 'absolute',
      left: 0,
      top: 23,
      zIndex: 1,
    },
    headerContainer: {
      alignItems: 'center',
      py: 'xs',
      visibility: isExpanded ? 'hidden' : 'visible',
      position: isExpanded ? 'absolute' : 'relative',
      opacity: isExpanded ? 0 : 1,
      minH: 66,
    },
    eventName: {
      overflow: 'hidden',
      maxWidth: '100%',
      display: '-webkit-box',
      boxOrient: 'vertical',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
    },
    eventNameBox: {
      alignItems: 'center',
    },
  } as TStyles;
};

type TStylesMobile = {
  headerContainer: IButtonStyledProps;
  toggleIcon: TFlexProps;
};

const stylesMobile = (isExpanded = false) => {
  return {
    toggleIcon: {
      position: 'absolute',
      left: 25,
      top: 30,
      zIndex: 1,
    },
    headerContainer: {
      py: 'md',
      pl: 60,
      pr: 'sm',
      visibility: isExpanded ? 'hidden' : 'visible',
      position: isExpanded ? 'absolute' : 'relative',
      opacity: isExpanded ? 0 : 1,
    },
  } as TStylesMobile;
};

type TMyEventBar = {
  event: Event;
  isExpanded: boolean;
  setExpanded: Function;
  mobileBgColor: ResponsiveValue<ThemeValue<'colors', DefaultTheme>>;
  isShowBell: boolean;
};

const MyEventBar = ({ event, isExpanded, setExpanded, isShowBell, mobileBgColor }: TMyEventBar) => {
  const loggedMember = useLoggedMember();
  const isMobile = useAppSelector(({ app }) => app.isMobile);

  const getExtraData = useCallback(() => {
    const instructors = event.getInstructors();
    const joined = event.getParticipationsByStatus('joined');
    let loggedClientParticipation;
    if (loggedMember?.isClient()) {
      loggedClientParticipation = event.getParticipations().find(p => p.member.id === loggedMember?.id);
    }
    return {
      joined,
      instructors,
      isNoJoined: joined.length === 0,
      isWorkerMoreThan2: instructors.length > 2,
      isJoinedMoreThan2: joined.length > 2,
      isWorkerMoreThan6: instructors.length > 6,
      isJoinedMoreThan6: joined.length > 6,
      loggedClientParticipation,
    };
  }, [event, loggedMember]);

  const {
    instructors,
    joined,
    isNoJoined,
    isWorkerMoreThan2,
    isJoinedMoreThan2,
    isJoinedMoreThan6,
    loggedClientParticipation,
  } = useMemo(() => getExtraData(), [getExtraData]);
  if (isMobile) {
    return (
      <>
        <IconButton
          icon={isExpanded ? 'ArrowDown' : 'ArrowRight'}
          onClick={setExpanded}
          {...stylesMobile().toggleIcon}
        />
        <Flex {...stylesMobile(isExpanded).headerContainer} bgColor={mobileBgColor}>
          <Stack w="full" alignItems="center" isHorizontal>
            <Stack w="full" spacing="sm">
              <Flex justifyContent="space-between">
                <Flex flexDirection="column">
                  <Text fontSize="xs">{event.getStartDate('DD.MM.YYYY, HH:mm')}</Text>
                  <Flex {...styles().eventNameBox}>
                    <Link to={event.getUrl()} {...styles().eventName}>
                      {event.getName()}
                    </Link>
                  </Flex>
                </Flex>
                <Flex flex="none" alignItems="center" justifyContent="center">
                  {isShowBell && <Icons.BellTwo size="base" fill="orange" />}
                </Flex>
              </Flex>
              {/* @ts-ignore */}
              <Text>{t(`eventStatus.${event.status}`).toUpperCase()}</Text>
              <Flex>
                <Ternary cond={!!loggedMember?.isClient()}>
                  <ParticipationStatusLabel status={loggedClientParticipation?.getStatus()} />
                  <Box width="auto">
                    <Stack py="sm" spacing="sm" isHorizontal>
                      {isNoJoined && <Text color="grey">{t('dashboard.noJoinedParticipants')}</Text>}
                      {[...joined].splice(0, 6).map(({ member }) => (
                        <Image
                          key={member.id}
                          src={member.getAvatar('small')}
                          alt={member.getFullName()}
                          title={member.getFullName()}
                          squareSize={26}
                          radius="full"
                        />
                      ))}
                      {isJoinedMoreThan6 && (
                        <Dot size={26} bgColor="teal-dark">
                          <Text fontSize="sm" color="white">
                            +{joined.length}
                          </Text>
                        </Dot>
                      )}
                    </Stack>
                  </Box>
                </Ternary>
              </Flex>
            </Stack>
          </Stack>
        </Flex>
      </>
    );
  }

  return (
    <>
      <IconButton icon={isExpanded ? 'ArrowDown' : 'ArrowRight'} onClick={setExpanded} {...styles().toggleIcon} />
      <Flex {...styles(isExpanded).headerContainer}>
        <Flex flex={2} pl="2xl">
          <Stack spacing={0}>
            <Text>{event.getStartDate('DD.MM.YYYY')}</Text>
            <Text>{event.getStartDate('HH:mm')}</Text>
          </Stack>
        </Flex>
        <Flex flex={6} {...styles().eventNameBox}>
          <Text {...styles().eventName}>
            <Link to={event.getUrl()}>{event.getName()}</Link>
          </Text>
        </Flex>
        <Flex flex={3}>
          {/* @ts-ignore */}
          <Text>{t(`eventStatus.${event.status}`).toUpperCase()}</Text>
        </Flex>
        <Flex flex={3}>
          <Stack spacing="xs" isHorizontal>
            {[...instructors].splice(0, 2).map(instructor => (
              <Image
                key={instructor.id}
                src={instructor.getAvatar('small')}
                alt={instructor.getFullName()}
                title={instructor.getFullName()}
                squareSize={26}
                radius="full"
              />
            ))}
            {isWorkerMoreThan2 && (
              <Dot size={26} bgColor="teal-dark">
                <Text fontSize="sm" color="white">
                  +{instructors.length}
                </Text>
              </Dot>
            )}
          </Stack>
        </Flex>
        <Flex flex={3}>
          <Ternary cond={!!loggedMember?.isClient()}>
            <ParticipationStatusLabel status={loggedClientParticipation?.status} />
            <Box width="auto">
              <Stack spacing="xs" isHorizontal>
                {isNoJoined && <Text color="grey">{t('dashboard.noJoinedParticipants')}</Text>}
                {[...joined].splice(0, 2).map(({ member }) => (
                  <Image
                    key={member.id}
                    src={member.getAvatar('small')}
                    alt={member.getFullName()}
                    title={member.getFullName()}
                    squareSize={26}
                    radius="full"
                  />
                ))}
                {isJoinedMoreThan2 && (
                  <Dot size={26} bgColor="teal-dark">
                    <Text fontSize="sm" color="white">
                      +{joined.length}
                    </Text>
                  </Dot>
                )}
              </Stack>
            </Box>
          </Ternary>
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          {isShowBell && <Icons.BellTwo size="base" fill="orange" />}
        </Flex>
      </Flex>
    </>
  );
};

MyEventBar.defaultProps = {
  isExpanded: false,
  setExpanded: () => {},
  mobileBgColor: null,
};

export default MyEventBar;
