import { useContext, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import chunk from 'lodash/chunk';
import cls from 'classnames';

import t from 'resources/translations';
import { CalendarItem, mapEventToCalendarItem } from '@old/components/view/calendar/CalendarItem';
import HoursGrid from '@old/components/view/calendar/CalendarHoursGrid';
import { CalendarDataContext } from '@old/hooks';
import config from '@old/config';
import { getCalendarKey } from 'old/utils';
import RowAligner from '@old/components/common/RowAligner';
import FlexColumn from '@old/components/common/FlexColumn';
import ButtonSimple from '@old/components/guide/ButtonSimple';
import Indicator from '@old/components/common/Indicator';

const { hours, pxPer5Min } = config;
const moment = extendMoment(Moment);
const setTime = (h, date) => moment(date).hour(h).minute(0).second(0).millisecond(0);

const Day = ({ dayStart, isMobile, history }) => {
  const [pageIndex, setPageIndex] = useState(0);
  useEffect(() => {
    setPageIndex(0);
  }, [dayStart]);
  const hoursList = hours.map(h => h.split(':')[0]);
  const hoursRanges = hoursList.map((h, i) => {
    return moment.range(setTime(h, dayStart), setTime(hoursList[i + 1] || 24, dayStart).subtract(1, 'miliseconds'));
  });
  const calendarRange = moment.range(setTime(8, dayStart), setTime(21, dayStart));
  const dayRange = moment.range(dayStart, moment(dayStart).endOf('day'));
  const currentDayEvents = (useContext(CalendarDataContext) || []).filter(({ startDate, endDate }) => {
    const eventRange = moment.range(startDate, endDate);
    return eventRange.overlaps(dayRange);
  });

  const calendarItemsBefore = currentDayEvents
    .filter(({ startDate, endDate }) => {
      const eventRange = moment.range(startDate, endDate);
      const startOfDayRange = moment.range(dayStart, setTime(8, dayStart).subtract(1, 'milisecond'));
      return eventRange.overlaps(startOfDayRange);
    })
    .sort((itemA, itemB) => {
      return itemA.startDate.isAfter(itemB.startDate) ? 1 : -1;
    })
    .map(event => mapEventToCalendarItem(event, dayStart));

  const calendarItemsAfter = currentDayEvents
    .filter(({ startDate, endDate }) => {
      const eventRange = moment.range(startDate, endDate);
      const endOfDayRange = moment.range(setTime(21, dayStart), moment(dayStart).endOf('day'));
      return eventRange.overlaps(endOfDayRange);
    })
    .sort((itemA, itemB) => {
      return itemA.startDate.isAfter(itemB.startDate) ? 1 : -1;
    })
    .map(event => mapEventToCalendarItem(event, dayStart));

  const calendarItems = currentDayEvents
    .filter(({ startDate, endDate }) => {
      const eventRange = moment.range(startDate, endDate);
      return eventRange.overlaps(calendarRange);
    })
    .sort((itemA, itemB) => {
      return itemA.startDate.isAfter(itemB.startDate) ? 1 : -1;
    })
    .map(event => mapEventToCalendarItem(event, dayStart));

  const hoursRangesList = hoursRanges.map(hRange => ({
    range: hRange,
    isExpanded: calendarItems.some(item => item.range.overlaps(hRange)),
  }));

  const columns = useMemo(() => {
    const tmpColumns = [];

    calendarItems.forEach(calendarItem => {
      const pushToColumn = (item, recurrencyIndex) => {
        let isoverlapped = false;
        if (tmpColumns[recurrencyIndex || 0] && tmpColumns[recurrencyIndex || 0].length > 0) {
          const columnRanges = tmpColumns[recurrencyIndex || 0].map(columnItem => columnItem.range);
          const overlappedrange = columnRanges.find(range => range.overlaps(item.range));
          isoverlapped = !!overlappedrange;
        }

        if (isoverlapped) {
          pushToColumn(item, (recurrencyIndex || 0) + 1);
        } else if (tmpColumns[recurrencyIndex || 0]) {
          tmpColumns[recurrencyIndex || 0].push(item);
        } else {
          tmpColumns.push([item]);
        }
      };

      pushToColumn(calendarItem);
    });

    return tmpColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarItems.map(item => item.id)]);

  const getHeightOfElement = range => {
    let bigMinutes = 0;
    hoursRangesList.forEach(({ isExpanded, range: hRange }) => {
      if (isExpanded) {
        const inter = range.intersect(hRange);
        bigMinutes += inter ? inter.valueOf() / 1000 / 60 : 0;
      }
    });
    const startHours = range.start.hours();
    const endHours = range.end.hours();
    const extraPixels = endHours - startHours; // we need to add 1px for each crossed border line
    const durationInMinutes = range.valueOf() / 1000 / 60;

    return Math.ceil(durationInMinutes / 5) * pxPer5Min + extraPixels + Math.ceil(bigMinutes / 5) * pxPer5Min * 2;
  };

  const getPositionOfElement = range => {
    const height = getHeightOfElement(range);
    const top = getHeightOfElement(moment.range(setTime(8, range.start), range.start));
    return [height, top];
  };

  const { length } = hours;
  const numberOfExpandedHours = hoursRangesList.filter(item => item.isExpanded).length;
  const hourHeight = pxPer5Min * 12;
  const maxElementHeight = length * hourHeight + numberOfExpandedHours * hourHeight * 2 + length;
  const pagedColumns = useMemo(() => {
    const result = chunk(columns, isMobile ? 5 : 8);
    // we need ensure minimum three columns
    return result.map(item => {
      if (item.length < 3) {
        return [...item, ...Array(3 - item.length).fill([])];
      }
      return [...item];
    });
  }, [columns, isMobile]);
  const columnsCount = pagedColumns.length;
  return (
    <FlexColumn>
      {pagedColumns.length > 1 && (
        <RowAligner className={cls('centered', columnsCount > 5 && 'flex-col')}>
          <div>{t('day.eventList', { from: pageIndex + 1, to: columnsCount })}</div>
          <div>
            <RowAligner separated="small">
              {[...Array(pagedColumns.length)].map((_, index) => (
                <ButtonSimple key={index} onClick={() => setPageIndex(index)}>
                  <Indicator className="dots" active={pageIndex === index} />
                </ButtonSimple>
              ))}
            </RowAligner>
          </div>
        </RowAligner>
      )}

      <div style={{ position: 'relative' }}>
        <HoursGrid hoursList={hoursRangesList} extraItems={[[calendarItemsBefore, calendarItemsAfter]]} />
        <div
          className="flex absolute top-0 left-0 h-full"
          style={{ paddingLeft: 'calc(10% + 1px)', width: 'calc(100% - 1px)' }}
        >
          {(pagedColumns[pageIndex] || []).map((column, index) => {
            return (
              <div key={index} style={{ flex: '1 0', position: 'relative' }}>
                {column.map((calendarItem, itemIndex) => {
                  const [height, top] = getPositionOfElement(calendarItem.range);

                  if (height === 0) return null;

                  return (
                    <CalendarItem
                      index={itemIndex}
                      height={height}
                      top={top}
                      item={calendarItem}
                      key={calendarItem.id}
                      history={history}
                      maxElementHeight={maxElementHeight}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </FlexColumn>
  );
};

const mapStateToProps = ({ calendar, app: { isMobile } }, { location }) => {
  const calendarKey = getCalendarKey(location.pathname);
  const calendarState = calendar[calendarKey] || calendar.default;

  return {
    dayStart: calendarState.dayStart,
    isMobile,
  };
};

export default withRouter(connect(mapStateToProps)(Day));
