import Cancel from '@old/components/icon/Cancel';
import CancelOutline from '@old/components/icon/CancelOutline';
import Check from '@old/components/icon/Check';
import Checked from '@old/components/icon/Checked';
import ArrowLeft from '@old/components/icon/ArrowLeft';
import ArrowRight from '@old/components/icon/ArrowRight';
import Availability from '@old/components/icon/Availability';
import Bill from '@old/components/icon/Bill';
import Block from '@old/components/icon/Block';
import Calendar from '@old/components/icon/Calendar';
import CalendarShadow from '@old/components/icon/CalendarShadow';
import CalendarChecked from '@old/components/icon/CalendarChecked';
import CalendarList from '@old/components/icon/CalendarList';
import CalendarPlus from '@old/components/icon/CalendarPlus';
import Camera from '@old/components/icon/Camera';
import CheckList from '@old/components/icon/CheckList';
import ClockCircularOutline from '@old/components/icon/ClockCircularOutline';
import CoinCircle from '@old/components/icon/CoinCircle';
import Coin from '@old/components/icon/Coin';
import Delete from '@old/components/icon/Delete';
import DeleteOutline from '@old/components/icon/DeleteOutline';
import Doc from '@old/components/icon/Doc';
import Edit from '@old/components/icon/Edit';
import EditOutline from '@old/components/icon/EditOutline';
import EmptyCalendar from '@old/components/icon/EmptyCalendar';
import Events from '@old/components/icon/Events';
import EventType from '@old/components/icon/EventType';
import Farm from '@old/components/icon/Farm';
import Filter from '@old/components/icon/Filter';
import History from '@old/components/icon/History';
import HistoryOutline from '@old/components/icon/HistoryOutline';
import Home from '@old/components/icon/Home';
import Horse from '@old/components/icon/Horse';
import Info from '@old/components/icon/Info';
import Invite from '@old/components/icon/Invite';
import InviteHeart from '@old/components/icon/InviteHeart';
import InviteHorseShoe from '@old/components/icon/InviteHorseShoe';
import InviteUser from '@old/components/icon/InviteUser';
import Logout from '@old/components/icon/Logout';
import Mail from '@old/components/icon/Mail';
import MailOutline from '@old/components/icon/MailOutline';
import MoreHorizontal from '@old/components/icon/MoreHorizontal';
import MoreHorizontalOutline from '@old/components/icon/MoreHorizontalOutline';
import MoreVertical from '@old/components/icon/MoreVertical';
import News from '@old/components/icon/News';
import NewsOutline from '@old/components/icon/NewsOutline';
import Place from '@old/components/icon/Place';
import Add from '@old/components/icon/Add';
import AddOutline from '@old/components/icon/AddOutline';
import Proposals from '@old/components/icon/Proposals';
import ProposalsOutline from '@old/components/icon/ProposalsOutline';
import CodeQR from '@old/components/icon/CodeQR';
import Save from '@old/components/icon/Save';
import Schedule from '@old/components/icon/Schedule';
import Search from '@old/components/icon/Search';
import SelectFarm from '@old/components/icon/SelectFarm';
import Settings from '@old/components/icon/Settings';
import Sort from '@old/components/icon/Sort';
import Unchecked from '@old/components/icon/Unchecked';
import User from '@old/components/icon/User';
import Users from '@old/components/icon/Users';
import VisibilityOn from '@old/components/icon/VisibilityOn';
import VisibilityOff from '@old/components/icon/VisibilityOff';
import Warning from '@old/components/icon/Warning';
import WarningTriangle from '@old/components/icon/WarningTriangle';
import WarningDiamond from '@old/components/icon/WarningDiamond';
import WarningOutline from '@old/components/icon/WarningOutline';
import ZoomIn from '@old/components/icon/ZoomIn';
import ZoomOut from '@old/components/icon/ZoomOut';
import ArrowPaginationDoubleRight from '@old/components/icon/ArrowPaginationDoubleRight';
import ArrowPaginationDoubleLeft from '@old/components/icon/ArrowPaginationDoubleLeft';
import Star from '@old/components/icon/Star';
import Link from '@old/components/icon/Link';
import Send from '@old/components/icon/Send';
import ArrowUp from '@old/components/icon/ArrowUp';
import ArrowDown from '@old/components/icon/ArrowDown';
import Spinner from '@old/components/icon/Spinner';
import Drag from '@old/components/icon/Drag';
import HorseAlone from '@old/components/icon/HorseAlone';
import HorseAloneCircular from '@old/components/icon/HorseAloneCircular';
import UserCircular from '@old/components/icon/UserCircular';
import Human from '@old/components/icon/Human';
import Copy from '@old/components/icon/Copy';
import AddHorse from '@old/components/icon/AddHorse';
import AddUser from '@old/components/icon/AddUser';
import BlockOutline from '@old/components/icon/BlockOutline';
import UnsubscribeFarm from '@old/components/icon/UnsubscribeFarm';
import Www from '@old/components/icon/Www';
import Phone from '@old/components/icon/Phone';
import Policy from '@old/components/icon/Policy';
import Print from '@old/components/icon/Print';
import NumberUsers from '@old/components/icon/NumberUsers';
import EventLevel from '@old/components/icon/EventLevel';
import LinkAnchor from '@old/components/icon/LinkAnchor';
import List from '@old/components/icon/List';
import Underline from '@old/components/icon/Underline';
import Italic from '@old/components/icon/Italic';
import ListOrdered from '@old/components/icon/ListOrdered';
import Bold from '@old/components/icon/Bold';
import Quote from '@old/components/icon/Quote';
import Cake from '@old/components/icon/Cake';
import Sms from '@old/components/icon/Sms';
import InfoOutline from '@old/components/icon/InfoOutline';
import WrongSms from '@old/components/icon/WrongSms';
import OkSms from '@old/components/icon/OkSms';
import PhoneSmsSmall from '@old/components/icon/PhoneSmsSmall';
import PhoneSms from '@old/components/icon/PhoneSms';
import PhoneSmsLarge from '@old/components/icon/PhoneSmsLarge';
import CalendarRange from '@old/components/icon/CalendarRange';
import UncheckedOutline from '@old/components/icon/UncheckedOutline';
import EventAdd from '@old/components/icon/EventAdd';
import LinkAnchorCircular from '@old/components/icon/LinkAnchorCircular';
import RepeatEvent from '@old/components/icon/RepeatEvent';
import Shift from '@old/components/icon/Shift';
import Dash from '@old/components/icon/Dash';
import SelectedAvatar from '@old/components/icon/SelectedAvatar';
import withIconWrapper from '@old/components/hoc/withIconWrapper';
import EditFarmPolicy from '@old/components/icon/EditFarmPolicy';
import EventSettings from '@old/components/icon/EventSettings';
import PaymentSettings from '@old/components/icon/PaymentSettings';
import EventCost from '@old/components/icon/EventCost';

const Icons = {
  ArrowPaginationDoubleLeft: withIconWrapper(ArrowPaginationDoubleLeft),
  ArrowPaginationDoubleRight: withIconWrapper(ArrowPaginationDoubleRight),
  Cancel: withIconWrapper(Cancel),
  CancelOutline: withIconWrapper(CancelOutline),
  Check: withIconWrapper(Check),
  ArrowLeft: withIconWrapper(ArrowLeft),
  ArrowRight: withIconWrapper(ArrowRight),
  Availability: withIconWrapper(Availability),
  Bill: withIconWrapper(Bill),
  Block: withIconWrapper(Block),
  Calendar: withIconWrapper(Calendar),
  CalendarShadow: withIconWrapper(CalendarShadow),
  CalendarChecked: withIconWrapper(CalendarChecked),
  CalendarList: withIconWrapper(CalendarList),
  CalendarPlus: withIconWrapper(CalendarPlus),
  Camera: withIconWrapper(Camera),
  Checked: withIconWrapper(Checked),
  CheckList: withIconWrapper(CheckList),
  ClockCircularOutline: withIconWrapper(ClockCircularOutline),
  Coin: withIconWrapper(Coin),
  CoinCircle: withIconWrapper(CoinCircle),
  Delete: withIconWrapper(Delete),
  DeleteOutline: withIconWrapper(DeleteOutline),
  Doc: withIconWrapper(Doc),
  Edit: withIconWrapper(Edit),
  EditOutline: withIconWrapper(EditOutline),
  EmptyCalendar: withIconWrapper(EmptyCalendar),
  Events: withIconWrapper(Events),
  EventType: withIconWrapper(EventType),
  Farm: withIconWrapper(Farm),
  Filter: withIconWrapper(Filter),
  History: withIconWrapper(History),
  HistoryOutline: withIconWrapper(HistoryOutline),
  Home: withIconWrapper(Home),
  Horse: withIconWrapper(Horse),
  Info: withIconWrapper(Info),
  Invite: withIconWrapper(Invite),
  InviteHeart: withIconWrapper(InviteHeart),
  InviteHorseShoe: withIconWrapper(InviteHorseShoe),
  InviteUser: withIconWrapper(InviteUser),
  Link: withIconWrapper(Link),
  Logout: withIconWrapper(Logout),
  Mail: withIconWrapper(Mail),
  MailOutline: withIconWrapper(MailOutline),
  MoreHorizontal: withIconWrapper(MoreHorizontal),
  MoreHorizontalOutline: withIconWrapper(MoreHorizontalOutline),
  MoreVertical: withIconWrapper(MoreVertical),
  News: withIconWrapper(News),
  NewsOutline: withIconWrapper(NewsOutline),
  Place: withIconWrapper(Place),
  Add: withIconWrapper(Add),
  AddOutline: withIconWrapper(AddOutline),
  Proposals: withIconWrapper(Proposals),
  ProposalsOutline: withIconWrapper(ProposalsOutline),
  CodeQR: withIconWrapper(CodeQR),
  Save: withIconWrapper(Save),
  Schedule: withIconWrapper(Schedule),
  Search: withIconWrapper(Search),
  SelectFarm: withIconWrapper(SelectFarm),
  Settings: withIconWrapper(Settings),
  Sort: withIconWrapper(Sort),
  Star: withIconWrapper(Star),
  Unchecked: withIconWrapper(Unchecked),
  UncheckedOutline: withIconWrapper(UncheckedOutline),
  User: withIconWrapper(User),
  UserCircular: withIconWrapper(UserCircular),
  Users: withIconWrapper(Users),
  VisibilityOn: withIconWrapper(VisibilityOn),
  VisibilityOff: withIconWrapper(VisibilityOff),
  Warning: withIconWrapper(Warning),
  WarningTriangle: withIconWrapper(WarningTriangle),
  WarningDiamond: withIconWrapper(WarningDiamond),
  WarningOutline: withIconWrapper(WarningOutline),
  ZoomIn: withIconWrapper(ZoomIn),
  ZoomOut: withIconWrapper(ZoomOut),
  Send: withIconWrapper(Send),
  ArrowUp: withIconWrapper(ArrowUp),
  ArrowDown: withIconWrapper(ArrowDown),
  Spinner: withIconWrapper(Spinner),
  Drag: withIconWrapper(Drag),
  HorseAlone: withIconWrapper(HorseAlone),
  HorseAloneCircular: withIconWrapper(HorseAloneCircular),
  Human: withIconWrapper(Human),
  Copy: withIconWrapper(Copy),
  AddHorse: withIconWrapper(AddHorse),
  AddUser: withIconWrapper(AddUser),
  BlockOutline: withIconWrapper(BlockOutline),
  UnsubscribeFarm: withIconWrapper(UnsubscribeFarm),
  Www: withIconWrapper(Www),
  Phone: withIconWrapper(Phone),
  Policy: withIconWrapper(Policy),
  Print: withIconWrapper(Print),
  NumberUsers: withIconWrapper(NumberUsers),
  EventLevel: withIconWrapper(EventLevel),
  LinkAnchor: withIconWrapper(LinkAnchor),
  List: withIconWrapper(List),
  Underline: withIconWrapper(Underline),
  Italic: withIconWrapper(Italic),
  ListOrdered: withIconWrapper(ListOrdered),
  Bold: withIconWrapper(Bold),
  Quote: withIconWrapper(Quote),
  Cake: withIconWrapper(Cake),
  Sms: withIconWrapper(Sms),
  InfoOutline: withIconWrapper(InfoOutline),
  WrongSms: withIconWrapper(WrongSms),
  OkSms: withIconWrapper(OkSms),
  PhoneSmsSmall: withIconWrapper(PhoneSmsSmall),
  PhoneSms: withIconWrapper(PhoneSms),
  PhoneSmsLarge: withIconWrapper(PhoneSmsLarge),
  CalendarRange: withIconWrapper(CalendarRange),
  EventAdd: withIconWrapper(EventAdd),
  LinkAnchorCircular: withIconWrapper(LinkAnchorCircular),
  RepeatEvent: withIconWrapper(RepeatEvent),
  Shift: withIconWrapper(Shift),
  Dash: withIconWrapper(Dash),
  SelectedAvatar: withIconWrapper(SelectedAvatar),
  EditFarmPolicy: withIconWrapper(EditFarmPolicy),
  EventSettings: withIconWrapper(EventSettings),
  PaymentSettings: withIconWrapper(PaymentSettings),
  EventCost: withIconWrapper(EventCost),
};

export default Icons;
