import { User } from 'models/Member';
import { IUserRes } from 'resources/types/membersTypes';

class LocalStorageService {
  static _service: LocalStorageService;

  static _getService(): LocalStorageService {
    if (!LocalStorageService._service) {
      LocalStorageService._service = new LocalStorageService();
    }
    return LocalStorageService._service;
  }

  _setToken(token: string) {
    localStorage.setItem('auth_token', token);
  }

  _getToken(): string | null {
    return localStorage.getItem('auth_token');
  }

  _setUser(userRes: IUserRes) {
    localStorage.setItem('user', JSON.stringify(userRes));
  }

  _getUser(): User | null {
    const userString = localStorage.getItem('user');
    if (userString) {
      const userRes: IUserRes = JSON.parse(userString);
      return new User(userRes);
    }
    return null;
  }

  _setFarmId(farmId: number | string) {
    localStorage.setItem('farm_id', String(farmId));
  }

  _getFarmId(): number | null {
    const farmId: string | null = localStorage.getItem('farm_id');
    return farmId ? parseInt(farmId, 10) : null;
  }

  _clearToken() {
    localStorage.removeItem('auth_token');
  }

  _clearAll() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
    localStorage.removeItem('farm_id');
  }
}

export default LocalStorageService._getService();
